<template>
  <div
    class="metricTableGray pt-1"
    :class="$vuetify.theme.dark ? 'metricTableGrayDark' : ''"
    :style="agents.length === 0 ? 'min-height: 40px;' : ''"
  >
    <span class="pt-3 pb-1">{{ $t('salesByAdvisor') }} ({{ nameFlow }})</span>
    <DateRange :range="range" />
    <div class="KBSimpleTable smallTable"
         v-if="agents.length > 0">
      <table class="KBSimpleTable__table">
        <thead>
          <tr>
            <th
              class="KBSimpleTable__header smallTable__header"
              v-for="(label, labelIndex) in headers"
              :key="`KBSimpleTable-${label.text}-${labelIndex}`"
            >
              <div class="tooltip">
                <p>{{ label.text }}</p>
                <span
                  class="tooltiptext"
                  :style="{
                    width: label.text.length * 10 + 'px',
                    left:
                      labelIndex >= 5
                        ? '-' + labelIndex * 25 + 'px'
                        : '-' + labelIndex * 5 + 'px',
                  }"
                >{{ label.text }}</span
                >
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            class="KBSimpleTable__row"
            v-for="(item, itemIndex) in agents"
            :key="`KBSimpleTable-item-${itemIndex}`"
          >
            <td
              :class="
                $vuetify.theme.dark
                  ? 'KBSimpleTable__itemDark'
                  : 'KBSimpleTable__item'
              "
            >
              {{ item.name ? item.name : '-' }}
            </td>
            <td
              :class="
                $vuetify.theme.dark
                  ? 'KBSimpleTable__itemDark'
                  : 'KBSimpleTable__item'
              "
            >
              {{ item.deals }}
            </td>
            <td
              :class="
                $vuetify.theme.dark
                  ? 'KBSimpleTable__itemDark'
                  : 'KBSimpleTable__item'
              "
            >
              <div class="flex justify-between">
                {{ item.total }}
                <!-- <span v-if="item.variation > 0"
                      class="positive">
                  +{{ item.variation }}
                </span>
                <span v-else
                      class="negative">{{ item.variation }}</span> -->
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-else
         class="msgServiceError">{{ $t('noDataFound') }}</div>
  </div>
</template>

<script>
import Colors from '@/utils/Colors'
import DateRange from '@/modules/metrics/infrastructure/ui/components/generic/DateRange.vue'
import { CDPFieldsEnums } from '@/modules/CDP/shared/contacts/domain/enums/CDPFieldsEnums'
export default {
  name: 'SalesByAdvisor',
  components: {
    DateRange
  },
  data () {
    return {
      isLoading: true,
      currentStartDate: '',
      currentEndDate: '',
      currentFlow: '',
      currentPipeline: '',
      headers: [
        { text: this.$t(CDPFieldsEnums.NAME), value: CDPFieldsEnums.NAME },
        { text: this.$t('deals'), value: 'deals' },
        { text: this.$t('quantity'), value: 'total' }
      ],
      Colors
    }
  },
  computed: {
    agents () {
      const agents = []
      if (this.tableData) {
        this.tableData.map((agent) => {
          const agentName =
            typeof agent?.responsible === 'string'
              ? agent?.responsible
              : agent?.responsible?.name ||
                agent?.responsible?.email ||
                'No adviser name'

          agents.push({
            name: agentName,
            deals: agent?.totalCount,
            total: `$${this.formatNumber(agent?.totalAmount)}`
            // variation: agent?.countVariation === 'noData' ? 0 : agent?.countVariation
          })
        })
      }
      return agents
    }
  },
  methods: {
    formatNumber (number) {
      const formatter = Intl.NumberFormat('en', { notation: 'compact' })
      if (number > 1000000) {
        return formatter.format(number)
      } else return Number(number).toLocaleString('de-DE')
    }
  },
  props: {
    nameFlow: {
      type: [String, Number, Object],
      required: false
    },
    tableData: {
      type: Array,
      required: false
    },
    range: {
      type: Object,
      required: false
    }
  }
}
</script>

<style scoped lang="scss">
$analytics-red: #fc3473;
$analytics-green: #00cc99;
.msgServiceError {
  font-size: 12px;
  color: gray;
  padding-right: 10px;

  a {
    color: rgb(58, 246, 155);
  }
}
.card {
  width: 100%;
  height: 604px;
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  padding: 32px;
}

.positive {
  font-size: 14px;
  color: $analytics-green;
}

.negative {
  font-size: 14px;
  color: $analytics-red;
}
.metricTableGray {
  border-radius: 10px;
  background-color: #d9d9d923;
  color: #000000;
  border: 1px solid #00000015;
  width: 98%;
  display: flex;
  align-items: flex-start;
  overflow: hidden;
  flex-direction: column;
  padding: 10px;
  margin: 15px 15px 20px 0px;
  max-width: 1075px;
  min-width: 90%;
  justify-content: space-between;

  span {
    padding-bottom: 10px;
  }

  .KBSimpleTable {
    margin-top: 10px;
    width: 97%;
    &__itemDark {
      font-style: normal;
      font-weight: 300;
      font-size: 12px;
      padding: 10px;
      border-bottom: 1px solid rgba(207, 205, 205, 0.493);
      text-align: start;
      min-width: 36px;
      span {
        font-size: 12px;
        font-weight: 300;
      }
    }
    &__item {
      font-style: normal;
      font-weight: 300;
      font-size: 12px;
      padding: 10px;
      border-bottom: 1px solid #ebeff2;
      text-align: start;
      min-width: 36px;
      span {
        font-size: 12px;
        font-weight: 300;
      }
    }
  }
}
.metricTableGrayDark {
  background-color: black !important;
  span {
    color: white;
  }
}
</style>
