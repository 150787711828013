<template>
  <div class="KBSimpleTable"
       @click="closeModal = true">
    <table class="KBSimpleTable__table">
      <thead>
        <tr>
          <td COLSPAN="2"
              class="KBSimpleTable__header"
          >
            <span>
              {{ labels[0].text }}
            </span>

          </td>
          <td
            class="KBSimpleTable__header"
            :class="labelIndex == 0 ? 'display-none': ''"
            v-for="(label, labelIndex) in labels"
            :key="`KBSimpleTable-${label.text}-${labelIndex}`"
          >
            <span>
              {{ label.text }}
            </span>
          </td>
        </tr>
      </thead>
      <tbody>
        <tr class="KBSimpleTable__row"
            v-for="(item, itemIndex) in data"
            :key="`KBSimpleTable-item-${itemIndex}`">
          <td class="textAlignStart"
              :class="$vuetify.theme.dark? 'KBSimpleTable__itemDark': 'KBSimpleTable__item'"
              v-if="item.image">
            <div class="circular--portrait">
              <img :src="item.image">
            </div>
          </td>
          <td class="textAlignStart"
              :class="$vuetify.theme.dark? 'KBSimpleTable__itemDark': 'KBSimpleTable__item'"
              v-else>
            <KeybeAvatar
              name="n"
              :size="20"
              :isKeybeProfile="false" />
          </td>
          <td
            class="textAlignStart"
            :class="$vuetify.theme.dark? 'KBSimpleTable__itemDark': 'KBSimpleTable__item'"
            v-for="(label, labelIndex) in labels"
            :key="`KBSimpleTable-label-${labelIndex}`"
          >
            <span v-if="label.field == 'status'"
                  :class="item[label.field] == 'online' ? 'green-text':'red-text'">
              {{ item[label.field] }}
            </span>
            <span v-else
                  :class="!(label.field.includes(CDPFieldsEnums.NAME)) ? 'gray-text textAlignStart':'textAlignStart'">
              {{ item[label.field] }}
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import KeybeAvatar from '@/shared/infrastructure/ui/KeybeAvatar.vue'
import { CDPFieldsEnums } from '@/modules/CDP/shared/contacts/domain/enums/CDPFieldsEnums'
export default {
  name: 'BaseImgTable',
  props: ['labels', 'data'],
  components: { KeybeAvatar },
  methods: {
    positionClass (index, length) {
      if (index === 0) {
        return ''
      }
      if (index === length - 1) {
        return 'last-item'
      }
      return ''
    }
  },
  data () {
    return {
      CDPFieldsEnums

    }
  },
  computed: {
    closeModal: {
      get () {
        return this.$store.state.MetricsStore.closeModal || false
      },
      set (value) {
        this.$store.commit('MetricsStore/SET_EDITING_OBJECT', {
          key: 'closeModal',
          value
        })
      }
    }
  }
}
</script>

<style lang="scss">

.KBSimpleTable__table {
  overflow: scroll;
  border-collapse: collapse;
  width: 100%;
}

.KBSimpleTable {

  &__header {
    font-weight: 300;
    font-size: 11px;
    line-height: 0px;
    color: $simple-grey;
    height: 30px;
    text-align: start;
  }

  img{
    max-width: 20px;
    margin: 0;
  }
   &__itemDark{
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    padding: 10px;
    border-bottom: 1px solid rgba(207, 205, 205, 0.493);
    text-align: start;
    min-width: 36px;
    span{
          font-weight: 300;
        }
  }

  &__item{
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    padding: 10px;
    border-bottom: 1px solid #EBEFF2;
    text-align: start;
    min-width: 36px;
    span{
          font-weight: 300;
        }
  }

}

.display-none{
    display: none;
}

.last-item {
    padding-right: 32px;
}
.green-text{
    color: #3AF69B !important;
}

.red-text{
    color: #F7685B;
}

.gray-text{
    color: #8898AA;
}
.circular--portrait { position: relative; width: 20px; height: 20px; overflow: hidden; border-radius: 50%; }
.circular--portrait img { width: 100%; height: auto; }

</style>
