












export default {
  name: 'EyeIcon.vue',
  props: {
    color: {
      type: String,
      default: '#8898A9'
    }
  }

}
