import { GenericResponse } from '@/shared/infrastructure/http/GenericResponse'
import { IBillingController, billingDataGetter, billingIntentSetupGetter, dailyContactsPlansGetter, dataToGetBillingReport, dataToGetIntentSetup, dataToGetNewContactsPlans } from '../../domain/BillingController'
import BillingRepository from '../repository/Billing.repository'

export default class BillingController implements IBillingController {
    repository: BillingRepository

    constructor () {
      this.repository = new BillingRepository()
    }

    abort (): void {
      this.repository.abort()
    }

    async getIntentSetup (data: dataToGetIntentSetup): Promise<billingIntentSetupGetter> {
      const response: GenericResponse = await this.repository.getIntentSetup(data)

      return {
        status: response?.data?.success || response?.status || false,
        data: response?.data || ''
      }
    }

    async getBillingReport (data: dataToGetBillingReport): Promise<billingDataGetter> {
      const response: GenericResponse = await this.repository.getBillingReport(data)

      return {
        success: response?.data?.success || response?.status || false,
        message: response?.data?.message || '',
        downloadUrl: response?.data?.downloadUrl || '',
        result: response?.data?.result || null
      }
    }

    async getNewDailyContactsPlans (data: dataToGetNewContactsPlans): Promise<dailyContactsPlansGetter> {
      const response: GenericResponse = await this.repository.getNewDailyContactsPlans(data)
      if (response?.status) {
        return {
          status: true,
          data: response?.data || []
        }
      } else {
        return {
          status: false,
          message: response?.data?.message || ''
        }
      }
    }
}
