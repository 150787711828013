import KeybeRepository from '@/shared/infrastructure/repositories/KeybeRepository'
import { GenericResponse } from '@/shared/infrastructure/http/GenericResponse'
import { HeadersBuilder } from '@/shared/infrastructure/http/HeadersBuilder'
import { getOptions, nonGetOptions } from '@/shared/infrastructure/http/HttpClient'
import { dataToCreateStatus, dataToDeleteStatus, dataToGetStatuses, dataToUpdateStatus } from '../../domain/StatusController'
export default class StatusRepository extends KeybeRepository {
  getSuggestionsAbortController: AbortController

  constructor () {
    super()

    this.getSuggestionsAbortController = new AbortController()
  }

  dispose (): void {
    this.getSuggestionsAbortController.abort()
    this.getSuggestionsAbortController = new AbortController()
  }

  async getStatuses (data: dataToGetStatuses): Promise<GenericResponse> {
    const { app, pagination, search } = data

    const url = `${process.env.VUE_APP_CRM_STATUS}${app}/status`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    const query = {
      offset: pagination?.offset,
      limit: pagination?.limit,
      search: search
    }

    if (!search) delete query.search
    const requestOptions: getOptions = {
      headers,
      query,
      url,
      abortController: this.getSuggestionsAbortController
    }

    return await this.client.get(requestOptions)
  }

  async createStatus (data: dataToCreateStatus): Promise<GenericResponse> {
    const { app, createInput } = data

    const url = `${process.env.VUE_APP_CRM_STATUS}${app}/status`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    const requestOptions: nonGetOptions = {
      headers,
      data: createInput,
      url,
      abortController: this.getSuggestionsAbortController
    }

    return await this.client.post(requestOptions)
  }

  async updateStatus (data: dataToUpdateStatus): Promise<GenericResponse> {
    const { app, updateInput, id } = data

    const url = `${process.env.VUE_APP_CRM_STATUS}${app}/status/${id}`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    const response = await fetch(url, {
      method: 'PATCH',
      body: JSON.stringify(updateInput),
      headers: headers || undefined,
      signal: this.getSuggestionsAbortController?.signal
    })
    const parsedResponse = await response.json()
    return parsedResponse
  }

  async deleteStatus (data: dataToDeleteStatus): Promise<GenericResponse> {
    const { app, id } = data

    const url = `${process.env.VUE_APP_CRM_STATUS}${app}/status/${id}`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    const requestOptions: nonGetOptions = {
      headers,
      url,
      abortController: this.getSuggestionsAbortController
    }

    return await this.client.delete(requestOptions)
  }
}
