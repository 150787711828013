import CampaignsService from '@/services/CampaignsService'
import moment from 'moment'

export const campaignEmail = async (
  { campaignSender, campaignSubject, campaignAttachments, segment, campaignName, html }
) => {
  try {
    const email = {
      from: '',
      fromName: campaignSender,
      html: html,
      subject: campaignSubject,
      template: '',
      attachments: campaignAttachments
    }

    const campaign = {
      date: moment.now(),
      listId: segment,
      name: campaignName,
      status: 'draft',
      type: 'email',
      all: segment,
      email
    }

    const campaignCreated = await CampaignsService.createCampaignV6(campaign)
    if (campaignCreated?.response) {
      return campaignCreated.response
    }
    await CampaignsService.linkCampaignV6({
      campaignId: campaignCreated?.campaign._id,
      createFromUser: segment === 'all'
    })
    return campaignCreated
  } catch (error) {
    console.error(error)
  }
}
