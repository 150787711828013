import axios from 'axios'
import { StatusCodes } from '@/models/apis/StatusCodes'
import link from '@/utils/links'
import { EventBus } from '@/eventBus'

function interceptorSetup (store, router, i18n) {
  axios.interceptors.response.use(
    (response) => {
      return response
    },
    (error) => {
      if (error?.response?.status === StatusCodes.HTTP_401_UNAUTHORIZED) {
        if (store.getters['UserStore/getIsLoggedIn']) {
          EventBus.$emit('toast', 'error', i18n.t('invalidToken'))
          store.dispatch('UserStore/logout')
          setTimeout(() => {
            router.push(link.signin)
          }, 300)
        }
      }
      return Promise.reject(error)
    }
  )
}

export default interceptorSetup
