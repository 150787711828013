<template>
  <svg width="38"
       height="44"
       viewBox="0 0 38 44"
       fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <path d="M28.1713 14.6116L3.96922 0.3675C2.73135 -0.36158 1.26812 0.0554923 0.512695 1.07907L21.0106 21.7591L28.1729 14.61L28.1713 14.6116Z"
          fill="url(#paint0_linear_29805_38068)"/>
    <path d="M0 40.7549C0 41.3312 0.176161 41.8454 0.468173 42.264L21.009 21.7606L0.511021 1.08057C0.193617 1.51037 0 2.04524 0 2.64856V40.7549Z"
          fill="url(#paint1_linear_29805_38068)"/>
    <path d="M28.0488 28.8618L36.3442 23.9795C38.0788 22.9575 38.0788 20.4424 36.3442 19.4204L28.171 14.6113L21.0088 21.7604L28.0488 28.8634V28.8618Z"
          fill="url(#paint2_linear_29805_38068)"/>
    <path d="M0.467773 42.2622C1.2105 43.3287 2.70706 43.776 3.96874 43.0326L28.0486 28.8617L21.0086 21.7588L0.467773 42.2622Z"
          fill="url(#paint3_linear_29805_38068)"/>
    <defs>
      <linearGradient id="paint0_linear_29805_38068"
                      x1="-32.2799"
                      y1="-28.6811"
                      x2="17.7627"
                      y2="12.3078"
                      gradientUnits="userSpaceOnUse">
        <stop stop-color="#32A071"/>
        <stop offset="0.07"
              stop-color="#2DA771"/>
        <stop offset="0.48"
              stop-color="#15CF74"/>
        <stop offset="0.8"
              stop-color="#06E775"/>
        <stop offset="1"
              stop-color="#00F076"/>
      </linearGradient>
      <linearGradient id="paint1_linear_29805_38068"
                      x1="35.3287"
                      y1="21.4661"
                      x2="-1.94569"
                      y2="21.6845"
                      gradientUnits="userSpaceOnUse">
        <stop stop-color="#00A1FF"/>
        <stop offset="0.26"
              stop-color="#00BEFF"/>
        <stop offset="0.51"
              stop-color="#00D2FF"/>
        <stop offset="0.76"
              stop-color="#00DFFF"/>
        <stop offset="1"
              stop-color="#00E3FF"/>
      </linearGradient>
      <linearGradient id="paint2_linear_29805_38068"
                      x1="40.5482"
                      y1="22.7156"
                      x2="6.9021"
                      y2="20.0843"
                      gradientUnits="userSpaceOnUse">
        <stop stop-color="#FFE000"/>
        <stop offset="0.41"
              stop-color="#FFBD00"/>
        <stop offset="0.78"
              stop-color="#FFA500"/>
        <stop offset="1"
              stop-color="#FF9C00"/>
      </linearGradient>
      <linearGradient id="paint3_linear_29805_38068"
                      x1="36.1678"
                      y1="17.561"
                      x2="-28.7163"
                      y2="60.5022"
                      gradientUnits="userSpaceOnUse">
        <stop stop-color="#FF3A44"/>
        <stop offset="1"
              stop-color="#C31162"/>
      </linearGradient>
    </defs>
  </svg>

</template>

<script>
export default {
  name: 'GooglePlayIcon',
  props: {
    width: {
      type: Number,
      default: 16
    },
    height: {
      type: Number,
      default: 18
    },
    color: {
      type: String,
      default: '#B6BEC7'
    }
  }

}
</script>

<style>

</style>
