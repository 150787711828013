import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import SpacesController from '@/modules/Spaces/infrastructure/Spaces.controller'
// import { SpaceUser } from '@/modules/Spaces/domain/Spaces'
import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'
import ModalWarning from '@/modules/Spaces/infrastructure/ui/SpacesRules/ModalWarning/ModalWarning.vue'
import Colors from '@/shared/domain/colors/Color'
import { mapState, mapGetters } from 'vuex'
import KeybeAvatar from '@/shared/infrastructure/ui/KeybeAvatar.vue'
import KBSwitch from '@/shared/infrastructure/ui/inputs/select/KBSwitch.vue'
import { SpaceUser } from '@/modules/Spaces/domain/Spaces'
import SpacesUseCases from '@/modules/Spaces/application/SpacesUseCases'
import { spaceUpdateDTO } from '@/modules/Spaces/domain/SpacesController'
import { debounce } from 'lodash'
import { ActivityActionTypeEnum, ActivityLogModuleTypeEnum, ActivitySubmoduleTypeEnum, dataToWriteActivityLog } from '@/modules/Config/ActivityLogs/domain/ActivityLogsController'
import { CDPFieldsEnums } from '@/modules/CDP/shared/contacts/domain/enums/CDPFieldsEnums'
import ActivityLogsUseCases from '@/modules/Config/ActivityLogs/application/ActivityLogsUseCases'
import ActivityLogsController from '@/modules/Config/ActivityLogs/infrastructure/controllers/ActivityLogs.controller'
import { TeamMember } from '@/modules/Config/TeamConfiguration/domain/models/TeamMember'
import BikyStoreController from '@/modules/bky/infrastructure/persistence/BikyStore.controller'
import TeamUseCases from '@/modules/Config/TeamConfiguration/application/TeamUseCases'
import TeamController from '@/modules/Config/TeamConfiguration/infrastructure/controllers/Team.controller'

export default {
  name: 'AdvisorConfigCard',
  props: {
    limit: {
      type: Number,
      required: true
    },
    adviser: {
      type: Object,
      required: true
    },
    search: {
      type: String,
      required: false
    },
    getConfigInbox: {
      type: Function,
      required: true
    },
    inboxUser: {
      type: Object,
      required: true
    },
    inbox: {
      type: Object,
      required: true
    }
  },
  components: {
    KeybeAvatar,
    KBSwitch,
    KeybeIcon,
    ModalWarning
  },
  data () {
    return {
      loading: false,
      showConfig: false,
      awaitingUpdate: false,
      KeybeIconType,
      showWarning: false,
      messageWarning: '',
      SpacesController: new SpacesController(),
      activityLogsController: new ActivityLogsController(),
      teamController: new TeamController(),
      IsBikyAgent: false,
      Colors
    }
  },
  computed: {
    ...mapState('UserStore', ['user', 'token', 'isUserReadonly']),
    ...mapState('AppStore', ['selectedApp']),
    ...mapGetters('UserStore', ['isAdminOrAbove'])
  },
  mounted () {
    this.getConfig()
  },
  methods: {
    getConfig (): void {
      if (this.inboxUser.maxActiveConversationsNumber === undefined) {
        this.inboxUser.maxActiveConversationsNumber = 0
        this.showWarning = true
        this.messageWarning = this.$t('messageWarning', { propsConfig: this.$t('limitNotAssigned') })
        // this.saveConfig()
      }
    },
    getSliderBackground (): string {
      const percent: number = (this.inboxUser.maxActiveConversationsNumber / this.limit) * 100
      return `linear-gradient(to right, rgba(58, 246, 155, 1) 0%, rgba(58, 246, 155, 1) ${percent}%, #B6BEC7 ${percent}%, #B6BEC7 100%)`
    },
    getAdviserPhoto (adviser): string {
      return adviser?.photo || adviser?.image || ''
    },
    getAdviserName (adviser): string {
      if (adviser?.lastName) {
        return `${adviser?.name} ${adviser?.lastName}`
      }
      return adviser?.name || ''
    },
    saveConfig: debounce(async function (): Promise<void> {
      const dataToUpdateInbox: spaceUpdateDTO = {
        inboxId: this.inbox?._id || '',
        name: this.inbox?.name || '',
        userId: this.user?.userId,
        token: this.token,
        users: this.inbox?.users,
        isAdmin: this.isAdminOrAbove
      }

      if (!this.isAdminOrAbove) {
        delete dataToUpdateInbox.isAdmin
      }

      const userIndex: number | undefined = dataToUpdateInbox.users.findIndex(user => user.userId === this.inboxUser.userId)

      if (userIndex !== -1) {
        dataToUpdateInbox.users[userIndex].maxActiveConversationsNumber = parseInt(this.inboxUser.maxActiveConversationsNumber.toString())
      }
      await SpacesUseCases.updateInbox(this.SpacesController, dataToUpdateInbox)
      this.writeActivityLog()
      // this.getConfigInbox(this.inboxUser)
      // this.getConfig()
    }, 500),
    async writeActivityLog (): Promise<void> {
      const data: dataToWriteActivityLog = {
        token: this.token,
        updateInput: {
          appUUID: this.selectedApp?.uuid,
          module: ActivityLogModuleTypeEnum.CHAT,
          submodule: ActivitySubmoduleTypeEnum.SPACES,
          action: ActivityActionTypeEnum.SPACE_UPDATE_ADVISOR,
          object: this.inbox.name,
          user: {
            id: this.user.uuid,
            name: this.user.name + ' ' + this.user.lastName
          },
          payload: {
            field: CDPFieldsEnums.MAX_ACTIVE_CONVERSATIONS_NUMBER,
            newValue: {
              adviser: this.getAdviserName(this.adviser),
              number: this.inboxUser.maxActiveConversationsNumber.toString()
            },
            oldValue: ''
          }
        }
      }
      await ActivityLogsUseCases.writeActivityLog(this.activityLogsController, data)
    },
    deleteUser (adviser: SpaceUser): void {
      if (this.isUserReadonly) return
      this.$emit('deleteUser', adviser)
    },
    closeModal (): void {
      this.showWarning = false
      this.messageWarning = ''
      this.inboxUser.maxActiveConversationsNumber = 0
      this.saveConfig()
      this.getConfig()
    },
    name (member: TeamMember): string {
      if (this.isBiky(member)) {
        this.IsBikyAgent = true
        this.bikyConfig = BikyStoreController.getConfig()
        return this.bikyConfig?.name || 'Biky Montes'
      }
      return member?.name + ' ' + member.lastName
    },
    isBiky (member: TeamMember): boolean {
      return TeamUseCases.isUserBiky(this.teamController, member)
    },
    image (member: TeamMember): string {
      if (this.isBiky(member)) {
        this.bikyConfig = BikyStoreController.getConfig()
        return this.bikyConfig?.image || `${process.env.VUE_APP_STORAGE_URL}/files/ai/montes_profile_5.png`
      }
      return member?.photo || ''
    }
  },
  watch: {
    'inboxUser.maxActiveConversationsNumber' (newValue) {
      if (newValue === undefined) {
        newValue = 0
      }
      this.$el.style.setProperty('--slider-value', `${newValue}`)
      this.$el.style.setProperty('--slider-max', `${this.limit}`)
      this.$el.style.setProperty('--slider-percent', `${(newValue / this.limit) * 100}%`)
      this.getSliderBackground()
      this.getConfig()
    },
    // 'inboxUser.hasConversationsLimit' (newValue) {
    //   this.getConfig()
    // },
    limit (newLimit: number) {
      if (this.inboxUser?.maxActiveConversationsNumber > newLimit) {
        this.inboxUser.maxActiveConversationsNumber = newLimit
        this.saveConfig()
      }
    },
    search () {
      this.showConfig = false
    }
  },
  beforeDestroy () {
    SpacesUseCases.dispose(this.SpacesController)
  }
}
