<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       fill="none"
       viewBox="0 0 16 15"><path :fill="color"
                                 d="M2.97 5.713a5.306 5.306 0 0 1 1.26-2.002 5.318 5.318 0 0 1 7.546 0l.57.576h-1.144c-.59 0-1.067.479-1.067 1.071 0 .593.477 1.072 1.067 1.072h3.731c.59 0 1.067-.479 1.067-1.072v-3.75a1.068 1.068 0 1 0-2.134 0v1.179l-.583-.59a7.445 7.445 0 0 0-10.562 0A7.462 7.462 0 0 0 .96 5.003a1.067 1.067 0 1 0 2.01.713v-.003ZM.768 8.616a1.061 1.061 0 0 0-.727.744c-.01.04-.02.083-.027.127a.983.983 0 0 0-.013.17v3.737a1.068 1.068 0 1 0 2.134 0V12.22l.587.586a7.447 7.447 0 0 0 10.558 0 7.484 7.484 0 0 0 1.764-2.803 1.072 1.072 0 0 0-.65-1.366 1.067 1.067 0 0 0-1.36.653 5.306 5.306 0 0 1-1.26 2.003 5.318 5.318 0 0 1-7.545 0l-.004-.004-.57-.572h1.147c.59 0 1.067-.48 1.067-1.072 0-.592-.477-1.071-1.067-1.071h-3.72c-.054 0-.107.003-.16.01a.783.783 0 0 0-.154.033Z"/></svg>
</template>

<script>
export default {
  name: 'RefreshIcon',
  props: {
    color: {
      type: String
    }
  }
}
</script>

      <style>

      </style>
