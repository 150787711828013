<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       fill="none"
       viewBox="0 0 38 24">
    <path :fill="color"
          d="M37.655 21.151h-2.881V1.481C34.774.664 34.109 0 33.296 0H4.712c-.82 0-1.478.663-1.478 1.482v19.67H.344a.346.346 0 0 0-.344.343v1.032C0 23.345.665 24 1.486 24h35.036c.821 0 1.478-.663 1.478-1.473v-1.032a.346.346 0 0 0-.345-.344ZM3.915 1.481c0-.433.354-.785.789-.785h28.584c.435 0 .788.352.788.786v19.67H3.916V1.481Zm18.43 20.358a.544.544 0 0 1-.525.442h-5.632a.536.536 0 0 1-.525-.442h6.69-.008Zm14.965.688a.787.787 0 0 1-.788.785H1.486a.787.787 0 0 1-.788-.785v-.688h14.686a.805.805 0 0 0 .796.696h5.632c.41 0 .738-.303.796-.696h14.694v.688h.008Z"/>
  </svg>
</template>

<script>
export default {
  name: 'LaptopIcon',
  props: {
    color: {
      type: String,
      default: '#8898A9'
    }
  }
}
</script>

<style scoped>

</style>
