import { orderArray } from '@/modules/metrics/infrastructure/ui/components/utils/orderArray'

export const setDataSeries = (arr, {
  lunes, martes, miercoles, jueves, viernes, sabado, domingo
}) => {
  const data = arr
  data.forEach(element => {
    switch (element.name) {
      case 'Lunes':
        element.data = orderArray(lunes)
        break
      case 'Martes':
        element.data = orderArray(martes)
        break
      case 'Miercoles':
        element.data = orderArray(miercoles)
        break
      case 'Jueves':
        element.data = orderArray(jueves)
        break
      case 'Viernes':
        element.data = orderArray(viernes)
        break
      case 'Sabado':
        element.data = orderArray(sabado)
        break
      case 'Domingo':
        element.data = orderArray(domingo)
        break
      default:
        console.log('invalid day')
    }
  })
  return data
}
