
export default {
  name: 'Sending',
  props: {
    progress: {
      type: Number,
      default: 0
    }
  },
  mounted () {
  },
  data () {
    return {
    }
  }
}
