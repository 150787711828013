<template>
  <svg width="23"
       height="19"
       viewBox="0 0 23 19"
       fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <path d="M19.8063 9.22893V9.35581L22.3909 6.67586C23.203 5.83381 23.203 4.47268 22.3909 3.63063L19.4948 0.631539C18.6827 -0.210513 17.3701 -0.210513 16.558 0.631539L15.071 2.17338C14.9709 2.16184 14.867 2.15415 14.7632 2.15415H10.61C9.2343 2.15415 8.1033 3.23075 7.95498 4.61494H7.94015V9.22893C7.94015 10.0787 8.60391 10.7669 9.42342 10.7669C10.2429 10.7669 10.9067 10.0787 10.9067 9.22893V6.46054V6.45669V5.84534H11.5H16.5431C16.5431 5.84534 16.5431 5.84534 16.5468 5.84534H16.8398C18.4788 5.84534 19.8063 7.22184 19.8063 8.92133V9.22893ZM12.0933 7.07574V9.22893C12.0933 10.7592 10.8993 11.9973 9.42342 11.9973C7.94756 11.9973 6.75353 10.7592 6.75353 9.22893V4.66877C5.42229 4.90716 4.31354 5.9107 3.93531 7.29105L3.32346 9.50577L0.609069 12.3203C-0.203023 13.1623 -0.203023 14.5235 0.609069 15.3655L3.50516 18.3685C4.31725 19.2105 5.62995 19.2105 6.44204 18.3685L7.84002 16.9189C7.8734 16.9189 7.90677 16.9227 7.94015 16.9227H13.8732C14.8559 16.9227 15.6532 16.0961 15.6532 15.0772C15.6532 14.8618 15.6161 14.6542 15.553 14.462H15.6532C16.6358 14.462 17.4331 13.6353 17.4331 12.6164C17.4331 12.1242 17.2477 11.6782 16.9436 11.3475C17.8966 11.1553 18.616 10.2863 18.6197 9.24046V9.22508C18.616 8.04083 17.689 7.07958 16.5431 7.07958H12.0933V7.07574Z"
          :fill="color"/>
  </svg>
</template>
<script>
export default {
  name: 'HealthIcon',
  props: {
    width: {
      type: Number,
      default: 15
    },
    height: {
      type: Number,
      default: 14
    },
    color: {
      type: String,
      default: 'black'
    }
  }
}
</script>
