// components

export default {
  name: 'ProgressBillingCard',
  props: ['dataBilling'],
  components: {},
  data: function () {
    return {
      porcentaje: 0,
      procentajeLimit: 0
    }
  },
  computed: {
    classColor () {
      return this.porcentaje < 70 ? '__success' : this.porcentaje < 90 ? '__warning' : '__error'
    }
  },
  watch: {
    dataBilling () {
      if (this.dataBilling) {
        this.callSetPercentage()
      }
    }
  },
  methods: {
    callSetPercentage () {
      const current = this.dataBilling?.current ? this.dataBilling.current : 0
      const total = this.dataBilling?.total ? this.dataBilling.total : 0
      const porcentaje = (current / total) * 100
      this.porcentaje = Math.round(porcentaje)
      this.porcentaje > 90 ? this.alertPercentaje = true : this.alertPercentaje = false
      this.porcentaje >= 100 ? this.procentajeLimit = 100 : this.procentajeLimit = this.porcentaje
    }
  },
  created () {
    if (this.dataBilling) {
      this.callSetPercentage()
    }
  }
}
