import { ISmartOMeterController, dataToGetAiConfig, dataToGetTier, dataToUpdateTier } from '../domain/SmartOMeter/SmartOMeterController'
import { SmartOMeterFeature } from '../domain/SmartOMeter/SmartOMeterFeatures'
import { SmartOMeterTier } from '../domain/SmartOMeter/SmartOMeterTiers'

export default class SmartOMeterUseCases {
  static hasPermission (controller: ISmartOMeterController, feature: SmartOMeterFeature, tier?: SmartOMeterTier): boolean {
    return controller.hasPermission(feature, tier)
  }

  static getTier (controller: ISmartOMeterController, data: dataToGetTier): Promise<SmartOMeterTier | null> {
    return controller.getTier(data)
  }

  static updateTier (controller: ISmartOMeterController, data: dataToUpdateTier): Promise<boolean> {
    return controller.updateTier(data)
  }

  static getAiConfig (controller: ISmartOMeterController, data: dataToGetAiConfig): Promise<SmartOMeterTier | null> {
    return controller.getAiConfig(data)
  }
}
