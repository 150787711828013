import { ConsumptionsTabs } from '@/modules/consumptions/infrastructure/enums/ConsumptionsTabs'
import { mapGetters, mapState } from 'vuex'
import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import FeesServices from '@/modules/configuration/application/services/FeesServices'

import KeybeAvatar from '@/shared/infrastructure/ui/KeybeAvatar.vue'
import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'
// import BannerSlidebar from '@/shared/infrastructure/ui/BannerSlidebar/BannerSlidebar.vue'

export default {
  name: 'ConsumptionsSidebar',
  components: {
    KeybeAvatar,
    KeybeIcon
    // BannerSlidebar
  },
  props: {
    selectedTab: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      ConsumptionsTabs,
      KeybeIconType,
      hasFees: false,
      isFeesAdmin: false
    }
  },
  computed: {
    ...mapGetters('UserStore', ['isSuperAdmin']),
    ...mapState('AppStore', ['selectedApp']),
    ...mapState('UserStore', ['isUserReadonly']),
    ...mapState('BillingStore', ['appConfig']),
    inactiveApp (): boolean {
      const status = this.selectedApp?.status
      return !(status === 'active' || status == null || status === true || status === false || status === 200)
    },
    appName (): string {
      return this.selectedApp?.name || ''
    },
    balanceComputed () {
      let value = this.appConfig.balanceUSD
      if (value) value = this.appConfig.balanceUSD
      return value
    }
  },
  methods: {
    async getFeesUser () {
      const feesData = await FeesServices.isCommissionAgent()
      if (feesData?.status) {
        this.hasFees = true
        const user = feesData.data
        this.isFeesAdmin = user.superadmin
      }
    },
    isSelectedTab (tab: ConsumptionsTabs) {
      return this.selectedTab === tab
    },
    selectTab (tab: ConsumptionsTabs) {
      if (
        (tab === ConsumptionsTabs.PAYMENTS_METHODS ||
        tab === ConsumptionsTabs.PACKAGES) &&
        this.isUserReadonly
      ) return
      if (this.selectedTab === tab) return
      this.$emit('selectTab', tab)
    },
    formatCurrency (value, currency) {
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency
      })
      return formatter.format(value)
    }
  },
  mounted () {
    this.getFeesUser()
  }
}
