import { mapState } from 'vuex'
import AppsUseCases from '@/modules/Apps/application/AppsUseCases'
import KBSwitch from '@/shared/infrastructure/ui/inputs/select/KBSwitch.vue'
import AppsController from '@/modules/Apps/infrastructure/controllers/Apps.controller'
import { EventBus } from '@/eventBus'
import EmitToast from '@/utils/EmitToast'
import { AppFields } from '@/modules/Apps/domain/Apps'
import { ActivityActionTypeEnum, ActivityLogModuleTypeEnum, dataToWriteActivityLog } from '@/modules/Config/ActivityLogs/domain/ActivityLogsController'
import { CDPFieldsEnums } from '@/modules/CDP/shared/contacts/domain/enums/CDPFieldsEnums'
import ActivityLogsUseCases from '@/modules/Config/ActivityLogs/application/ActivityLogsUseCases'
import ActivityLogsController from '@/modules/Config/ActivityLogs/infrastructure/controllers/ActivityLogs.controller'
export default {
  components: {
    KBSwitch
  },
  props: {
    fields: {
      type: Array
    },
    appFields: {
      type: Array
    }
  },
  computed: {
    ...mapState('AppStore', ['selectedApp']),
    ...mapState('UserStore', ['token', 'user', 'isUserReadonly'])
  },
  mounted () {
    this.localFields = this.fields
    this.localFields.forEach((field: AppFields) => {
      field.active = true
      this.appFields.forEach((appField: AppFields) => {
        if (field.field === appField.field && appField.hidden) {
          field.active = false
          this.rerender++
        }
      })
    })
  },
  data () {
    return {
      rerender: 0,
      localFields: [],
      appsController: new AppsController(),
      activityLogsController: new ActivityLogsController()
    }
  },
  methods: {
    async setFieldStatus (index: number) {
      try {
        this.fields[index].active = !this.fields[index].active
        const data = {
          app: this.selectedApp?.uuid,
          token: this.token,
          updateInput: {
            field: this.fields[index].field,
            hidden: !this.fields[index].active,
            order: this.fields[index].order
          }
        }

        const response = await AppsUseCases.hideField(this.appsController, data)
        if (response?.status) {
          EventBus.$emit('toast', 'success', this.$t('fieldsUpdated'))
          this.writeActivityLog(this.fields[index])
        } else EmitToast.emitErrors(response)
      } catch (e) {
        console.error(e)
      }
    },
    async writeActivityLog (field: AppFields): Promise<void> {
      const data: dataToWriteActivityLog = {
        token: this.token,
        updateInput: {
          appUUID: this.selectedApp?.uuid,
          module: ActivityLogModuleTypeEnum.CDP,
          action: ActivityActionTypeEnum.FUNNELS_FIELDS_UPDATE,
          user: {
            id: this.user.uuid,
            name: this.user.name + ' ' + this.user.lastName
          },
          payload: {
            field: CDPFieldsEnums.HIDDEN,
            newValue: !field.active ? 'active' : 'inactive',
            oldValue: field.active ? 'active' : 'inactive'
          }
        }
      }
      await ActivityLogsUseCases.writeActivityLog(this.activityLogsController, data)
    }
  }
}
