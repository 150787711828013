






























import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import { mapState } from 'vuex'
import Colors from '@/shared/domain/colors/Color'
import KBText from '@/shared/infrastructure/ui/inputs/text/KBText.vue'
import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'
import AdvisorConfigCard from '@/modules/configuration/infrastructure/ui/components/Autoassign/Advisors/OldConfig/AdvisorConfigCardOld.vue'

export default {
  name: 'AutoassignAdvisorsConfig',
  components: {
    KBText,
    KeybeIcon,
    AdvisorConfigCard
  },
  props: {
    limit: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      KeybeIconType,
      Colors,
      search: '',
      render: 0
    }
  },
  computed: {
    ...mapState('AppStore', ['advisers']),
    advisersComputed () {
      return this.advisers.filter(adviser => {
        let name
        if (adviser?.lastName) {
          name = `${adviser?.name} ${adviser?.lastName}`
        } else {
          name = adviser?.name || ''
        }
        return name.toLowerCase().includes(this.search.toLowerCase())
      })
    }
  }
}
