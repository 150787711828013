











































































import ProductVariationsConfig from './ProductVariationsConfig'
export default ProductVariationsConfig
