


























import PartnersCommissionsList from './PartnersCommissionsList'
export default PartnersCommissionsList
