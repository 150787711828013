import KeybeRepository from '@/shared/infrastructure/repositories/KeybeRepository'
import { dataToGetCommissions, dataToGetPartnerUtmInformation } from '../../domain/PartnersController'
import { GenericResponse } from '@/shared/infrastructure/http/GenericResponse'
import { HeadersBuilder } from '@/shared/infrastructure/http/HeadersBuilder'
import { getOptions, nonGetOptions } from '@/shared/infrastructure/http/HttpClient'

export default class PartnersRepository extends KeybeRepository {
  getSuggestionsAbortController: AbortController
  getCommissionsAbortController: AbortController

  constructor () {
    super()

    this.getSuggestionsAbortController = new AbortController()
  }

  abortGetSuggestions () {
    this.getSuggestionsAbortController.abort()
    this.getSuggestionsAbortController = new AbortController()
  }

  abortGetCommissions () {
    this.getCommissionsAbortController.abort()
    this.getCommissionsAbortController = new AbortController()
  }

  async getCommissions (data: dataToGetCommissions): Promise<GenericResponse> {
    const { email } = data
    const url = `${process.env.VUE_APP_FEES_URL}/fees/commissions`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)

    const requestOptions: getOptions = {
      headers,
      url,
      query: {
        partner: email
      },
      abortController: this.getCommissionsAbortController
    }

    return await this.client.get(requestOptions)
  }

  async getPartnerUtmInformation (data: dataToGetPartnerUtmInformation): Promise<GenericResponse> {
    const url = `${process.env.VUE_APP_FEES_URL}/user/check`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    delete data.token
    const requestOptions: nonGetOptions = {
      headers,
      url,
      data: { email: data.email },
      abortController: this.getSuggestionsAbortController
    }

    return await this.client.post(requestOptions)
  }
}
