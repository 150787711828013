import { TokenModel } from '@/shared/domain/TokenModel'

export enum ActivityLogModuleTypeEnum {
  BRAND = 'brand',
  TEAM = 'team',
  BIKY = 'biky',
  CDP = 'cdp',
  CHAT = 'chat',
  OUTBOUND = 'outbound',
  ANALYTICS = 'analytics',
  API = 'api'
}

export type ActivityLogModuleType =
  ActivityLogModuleTypeEnum.BRAND |
  ActivityLogModuleTypeEnum.TEAM |
  ActivityLogModuleTypeEnum.BIKY |
  ActivityLogModuleTypeEnum.CDP |
  ActivityLogModuleTypeEnum.CHAT |
  ActivityLogModuleTypeEnum.OUTBOUND |
  ActivityLogModuleTypeEnum.ANALYTICS |
  ActivityLogModuleTypeEnum.API

export enum ActivityActionTypeEnum {
  TEAM_MEMBER_UPDATE = 'TEAM_MEMBER_UPDATE',
  TEAM_MEMBER_READONLY = 'TEAM_MEMBER_READONLY',
  TEAM_MEMBER_CREATE = 'TEAM_MEMBER_CREATE',
  TYPIFICATION_UPDATE = 'TYPIFICATION_UPDATE',
  TYPIFICATION_CREATE = 'TYPIFICATION_CREATE',
  WHATSAPP_LINE_CONNECTED = 'WHATSAPP_LINE_CONNECTED',
  LINE_CONNECTED_DISWHATSAPP = 'LINE_CONNECTED_DISWHATSAPP',
  FACEBOOK_CONNECTED = 'FACEBOOK_CONNECTED',
  FACEBOOK_DISCONNECTED = 'FACEBOOK_DISCONNECTED',
  INSTAGRAM_CONNECTED = 'INSTAGRAM_CONNECTED',
  INSTAGRAM_DISCONNECTED = 'INSTAGRAM_DISCONNECTED',
  SPACE_CREATE = 'SPACE_CREATE',
  SPACE_UPDATE = 'SPACE_UPDATE',
  ADD_ADVISER_TO_SPACE = 'ADD_ADVISER_TO_SPACE',
  REMOVE_ADVISER_FROM_SPACE = 'REMOVE_ADVISER_FROM_SPACE',
  SPACE_UPDATE_ADVISOR = 'SPACE_UPDATE_ADVISOR',
  QUICK_REPLY_CREATE = 'QUICK_REPLY_CREATE',
  QUICK_REPLY_UPDATE = 'QUICK_REPLY_UPDATE',
  TEMPLATE_CREATE = 'TEMPLATE_CREATE',
  TEMPLATE_DELETE = 'TEMPLATE_DELETE',
  WEB_WIDGET_UPDATE = 'WEB_WIDGET_UPDATE',
  STATUS_CREATE = 'STATUS_CREATE',
  STATUS_UPDATE = 'STATUS_UPDATE',
  CDP_FIELDS_UPDATE = 'CDP_FIELDS_UPDATE',
  FUNNELS_FIELDS_UPDATE = 'FUNNELS_FIELDS_UPDATE',
  API_KEY_DELETE = 'API_KEY_DELETE',
  API_KEY_CREATE = 'API_KEY_CREATE',
  DOMAIN_CREATE = 'DOMAIN_CREATE',
  DOMAIN_DELETE = 'DOMAIN_DELETE',
  SPACE_DELETE = 'SPACE_DELETE',
  QUICK_REPLY_DELETE = 'QUICK_REPLY_DELETE',
  STATUS_DELETE = 'STATUS_DELETE',
  TYPIFICATION_DELETE = 'TYPIFICATION_DELETE',
  APP_UPDATE = 'APP_UPDATE',
  SPACE_SET_AS_MAIN = 'SPACE_SET_AS_MAIN',
  CATALOG_PRODUCT_CREATE = 'CATALOG_PRODUCT_CREATE',
  CATALOG_CATEGORY_CREATE = 'CATALOG_CATEGORY_CREATE',
  CATALOG_VARIATION_CREATE = 'CATALOG_VARIATION_CREATE',
  CATALOG_PRODUCT_UPDATE = 'CATALOG_PRODUCT_UPDATE',
  CATALOG_PRODUCT_DELETE = 'CATALOG_PRODUCT_DELETE',
  ADVISOR_STATUS = 'ADVISOR_STATUS',
  PRIVACY_SECURITY_UPDATE = 'PRIVACY_SECURITY_UPDATE',
  WIDGET_WEB_UPDATE = 'WIDGET_WEB_UPDATE',
  WIDGET_WEB_FORM_UPDATE = 'WIDGET_WEB_FORM_UPDATE',
  SEND_CAMPAIGN = 'SEND_CAMPAIGN',
  SCHEDULE_CAMPAIGN = 'SCHEDULE_CAMPAIGN',
  ARCHIVE_CAMPAIGN = 'ARCHIVE_CAMPAIGN',
  EXPORT_CAMPAIGN = 'EXPORT_CAMPAIGN',
  TEMPLATE_UPDATE = 'TEMPLATE_UPDATE',
  IMPORT_STARTED = 'IMPORT_STARTED',
  IMPORT_CREATED = 'IMPORT_CREATED',
  CHAT_FILTER_CREATED = 'CHAT_FILTER_CREATED',
  CHAT_FILTER_UPDATED = 'CHAT_FILTER_UPDATED',
  CHAT_FILTER_DELETED = 'CHAT_FILTER_DELETED'
}

export type ActivityActionType =
  ActivityActionTypeEnum.TEAM_MEMBER_UPDATE |
  ActivityActionTypeEnum.TEAM_MEMBER_READONLY |
  ActivityActionTypeEnum.TYPIFICATION_UPDATE |
  ActivityActionTypeEnum.TYPIFICATION_CREATE |
  ActivityActionTypeEnum.TEAM_MEMBER_CREATE |
  ActivityActionTypeEnum.WHATSAPP_LINE_CONNECTED |
  ActivityActionTypeEnum.LINE_CONNECTED_DISWHATSAPP |
  ActivityActionTypeEnum.FACEBOOK_CONNECTED |
  ActivityActionTypeEnum.FACEBOOK_DISCONNECTED |
  ActivityActionTypeEnum.INSTAGRAM_CONNECTED |
  ActivityActionTypeEnum.INSTAGRAM_DISCONNECTED |
  ActivityActionTypeEnum.SPACE_CREATE |
  ActivityActionTypeEnum.SPACE_UPDATE |
  ActivityActionTypeEnum.ADD_ADVISER_TO_SPACE |
  ActivityActionTypeEnum.REMOVE_ADVISER_FROM_SPACE |
  ActivityActionTypeEnum.SPACE_UPDATE_ADVISOR |
  ActivityActionTypeEnum.QUICK_REPLY_CREATE |
  ActivityActionTypeEnum.QUICK_REPLY_UPDATE |
  ActivityActionTypeEnum.TEMPLATE_CREATE |
  ActivityActionTypeEnum.TEMPLATE_DELETE |
  ActivityActionTypeEnum.WEB_WIDGET_UPDATE |
  ActivityActionTypeEnum.STATUS_CREATE |
  ActivityActionTypeEnum.STATUS_UPDATE |
  ActivityActionTypeEnum.CDP_FIELDS_UPDATE |
  ActivityActionTypeEnum.FUNNELS_FIELDS_UPDATE |
  ActivityActionTypeEnum.API_KEY_DELETE |
  ActivityActionTypeEnum.API_KEY_CREATE |
  ActivityActionTypeEnum.DOMAIN_CREATE |
  ActivityActionTypeEnum.DOMAIN_DELETE |
  ActivityActionTypeEnum.SPACE_DELETE |
  ActivityActionTypeEnum.QUICK_REPLY_DELETE |
  ActivityActionTypeEnum.STATUS_DELETE |
  ActivityActionTypeEnum.TYPIFICATION_DELETE |
  ActivityActionTypeEnum.APP_UPDATE |
  ActivityActionTypeEnum.SPACE_SET_AS_MAIN |
  ActivityActionTypeEnum.CATALOG_PRODUCT_CREATE |
  ActivityActionTypeEnum.CATALOG_CATEGORY_CREATE |
  ActivityActionTypeEnum.CATALOG_VARIATION_CREATE |
  ActivityActionTypeEnum.CATALOG_PRODUCT_UPDATE |
  ActivityActionTypeEnum.CATALOG_PRODUCT_DELETE |
  ActivityActionTypeEnum.ADVISOR_STATUS |
  ActivityActionTypeEnum.PRIVACY_SECURITY_UPDATE |
  ActivityActionTypeEnum.WIDGET_WEB_UPDATE |
  ActivityActionTypeEnum.WIDGET_WEB_FORM_UPDATE |
  ActivityActionTypeEnum.SEND_CAMPAIGN |
  ActivityActionTypeEnum.SCHEDULE_CAMPAIGN |
  ActivityActionTypeEnum.ARCHIVE_CAMPAIGN |
  ActivityActionTypeEnum.EXPORT_CAMPAIGN |
  ActivityActionTypeEnum.TEMPLATE_UPDATE |
  ActivityActionTypeEnum.IMPORT_STARTED |
  ActivityActionTypeEnum.IMPORT_CREATED |
  ActivityActionTypeEnum.CHAT_FILTER_CREATED |
  ActivityActionTypeEnum.CHAT_FILTER_UPDATED |
  ActivityActionTypeEnum.CHAT_FILTER_DELETED

export enum ActivitySubmoduleTypeEnum {
  CHANNELS = 'channels',
  SPACES = 'spaces',
  TYPIFICATIONS = 'typifications',
  QUICK_REPLIES = 'quick_replies',
  TEMPLATES = 'templates',
  CONTACT_STATUS = 'contact_status',
  CONTACT_FIELDS = 'contact_fields',
  SALES_FIELDS = 'sales_fields'
}

export type ActivitySubmoduleType =
  ActivitySubmoduleTypeEnum.CHANNELS |
  ActivitySubmoduleTypeEnum.SPACES |
  ActivitySubmoduleTypeEnum.TYPIFICATIONS |
  ActivitySubmoduleTypeEnum.QUICK_REPLIES |
  ActivitySubmoduleTypeEnum.TEMPLATES |
  ActivitySubmoduleTypeEnum.CONTACT_STATUS |
  ActivitySubmoduleTypeEnum.CONTACT_FIELDS |
  ActivitySubmoduleTypeEnum.SALES_FIELDS

export interface dataToGetActivityLogs extends TokenModel {
  params: {
    appUUID: string,
    limit?: number,
    offset?: number,
  }
}

export interface dataToWriteActivityLog extends TokenModel {
  updateInput: {
    appUUID: string,
    module: ActivityLogModuleType,
    submodule?: ActivitySubmoduleType,
    action?: ActivityActionType,
    object?: any,
    user: {
      id: string,
      name: string
    },
    payload?: {
      field: string,
      newValue: any,
      oldValue: any
    }
  }
}

export interface activityLogsGetter {}

export interface activityLogCreator {}

export interface IActivityLogsController {
  getActivityLogs(data: dataToGetActivityLogs): Promise<any>;
  writeActivityLog(data: dataToWriteActivityLog): Promise<any>;
  abortAll(): void;
}
