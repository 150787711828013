import {
  ICatalogImporterController,
  dataToUploadCSV,
  fileUploadResponse,
  CSVExampleResponse,
  dataToGetCSVExample,
  dataToStartCSVImport,
  importsResponse
} from '../../domain/models/CatalogImporterController'
import CatalogImporterRepository from '../repositories/CatalogImporter.repository'
export default class CatalogImporterController implements ICatalogImporterController {
  repository: CatalogImporterRepository

  constructor () {
    this.repository = new CatalogImporterRepository()
  }

  async uploadCSVFile (data: dataToUploadCSV): Promise<fileUploadResponse> {
    const response = await this.repository.uploadCSVFile(data)
    if (response.status) {
      return {
        status: true,
        data: {
          path: response?.data?.fileUploadData?.storage?.folder || '',
          name: response?.data?.fileUploadData?.file?.internalName || ''
        }
      }
    } else {
      return { status: false, message: response.message }
    }
  }

  async getCSVFileExample (data: dataToGetCSVExample): Promise<CSVExampleResponse> {
    const response = await this.repository.getCSVFileExample(data)
    if (response.status) {
      return { status: true, url: response?.data?.s3Url || '' }
    } else {
      return { status: false, message: response.message }
    }
  }

  async startCSVImport (data: dataToStartCSVImport): Promise<fileUploadResponse> {
    const response = await this.repository.startCSVImport(data)
    return response
  }

  async getAllImports (data: dataToGetCSVExample): Promise<importsResponse> {
    const response = await this.repository.getAllImports(data)
    if (response.status) {
      return { status: true, data: response?.data || [] }
    } else {
      return { status: false, message: response.message }
    }
  }
}
