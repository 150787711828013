













































































import AdvisersController from '@/components/conversations/Controllers/AdvisersController'
import KeybeAvatar from '@/components/shared/components/KeybeAvatar.vue'
import { CDPFieldsEnums } from '@/modules/CDP/shared/contacts/domain/enums/CDPFieldsEnums'
export default {
  name: 'KBSelectAdvisor',
  props: ['value', 'itemText', 'itemValue', 'returnObject', 'nameFlow', 'idFlow'],
  components: {
    KeybeAvatar
  },
  computed: {
    closeModal: {
      get () {
        return this.$store.state.MetricsStore.closeModal || false
      },
      set (value) {
        this.$store.commit('MetricsStore/SET_EDITING_OBJECT', {
          key: 'closeModal',
          value
        })
      }
    },
    advisorFilterArrayId: {
      get () {
        return this.$store.state.MetricsStore.advisorFilterArrayId || []
      },
      set (value) {
        this.$store.commit('MetricsStore/SET_EDITING_OBJECT', {
          key: 'advisorFilterArrayId',
          value
        })
      }
    },
    advisersComputed () {
      let advisersFiltered = this.items.filter((adviser) => adviser.name.toLowerCase().includes(this.advisersSearch.toLowerCase()) || (adviser.lastName && adviser.lastName.toLowerCase().includes(this.advisersSearch.toLowerCase())))
      advisersFiltered = advisersFiltered.filter((adviser) => !this.advisorFilterArray.includes(adviser.userId))
      return advisersFiltered || []
    },
    loading: {
      get () {
        return this.$store.state.MetricsStore.loading || false
      },
      set (value) {
        this.$store.commit('MetricsStore/SET_EDITING_OBJECT', {
          key: 'loading',
          value
        })
      }
    },
    advisorFilter: {
      get () {
        return this.$store.state.MetricsStore.advisorFilter || null
      },
      set (value) {
        this.$store.commit('MetricsStore/SET_EDITING_OBJECT', {
          key: 'advisorFilter',
          value
        })
      }
    },
    advisorFilterArray: {
      get () {
        return this.$store.state.MetricsStore.advisorFilterArray || []
      },
      set (value) {
        this.$store.commit('MetricsStore/SET_EDITING_OBJECT', {
          key: 'advisorFilterArray',
          value
        })
      }
    }
  },
  created () {
    if (this.items.length === 0) {
      this.getAdvisers()
    }
  },
  mounted () {
    this.closeModal = false
    this.advisorFilterArrayId = []
    this.advisorFilterArray = []
    this.advisorFilter = null
    setTimeout(() => {
      const search = document.getElementById('InternalGroupSearch')
      if (search) search.focus()
    }, 200)
  },
  watch: {
    closeModal () {
      if (this.closeModal && this.modal) {
        this.modal = false
      }
    }
  },
  data () {
    return {
      advisersSearch: '',
      updated: 0,
      modal: false,
      items: [],
      showSelector: false,
      CDPFieldsEnums
    }
  },
  methods: {
    validateParameters (obj, param) {
      if (obj && param) {
        if (Object.prototype.hasOwnProperty.call(obj, param)) {
          return obj[param]
        } else {
          return ''
        }
      }
    },
    removeItemAll (arr, value) {
      var i = 0
      while (i < arr.length) {
        if (arr[i] === value) {
          arr.splice(i, 1)
        } else {
          ++i
        }
      }
      return arr
    },
    focus (id) {
      document.getElementById(id).focus()
    },
    async removeUser (advisor) {
      this.advisorFilterArray = this.removeItemAll(this.advisorFilterArray, advisor)
      this.advisorFilterArrayId = this.removeItemAll(this.advisorFilterArrayId, advisor.id)
    },
    async addUser (item) {
      if (this.advisorFilterArray.length > 0) {
        const found = this.advisorFilterArray.find(element => element.id === item.userId)
        if (found) {
          return
        }
      }
      this.advisorFilter = { id: item.userId, name: item.name, lastName: item.lastName }
      this.advisorFilterArray.push(this.advisorFilter)
      if (this.advisorFilterArray.length > 0) {
        const arrayUUID = []
        this.advisorFilterArray.forEach(element => {
          arrayUUID.push(element.id)
        })
        const uniq = arrayUUID.filter((element, index) => {
          return arrayUUID.indexOf(element) === index
        })
        this.advisorFilterArrayId = uniq
      }
    },
    async getAdvisers () {
      this.loading = true
      const data = await AdvisersController.getAdvisers()
      if (data && data.length > 0) {
        data.forEach(element => {
          if (this.validateParameters(element, 'userId')) {
            this.items.push(element)
          }
          // else if (this.validateParameters(element, 'uuid')) {
          //   Object.assign(element, { UUID: element.uuid })
          //   this.items.push(element)
          // }
        })
      }
      this.loading = false
    }
  }
}
