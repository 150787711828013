


































import KBButton from '@/shared/infrastructure/ui/buttons/KBButton.vue'
export default {
  name: 'AlertAutoassign',
  components: {
    KBButton
  },
  props: {
    showPopup: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      selectedAction: null
    }
  },
  methods: {
    confirmAction () {
      this.selectedAction = 'confirm'
      this.$emit('confirmAction', this.selectedAction)
    },
    rejectAction () {
      this.selectedAction = 'reject'
      this.$emit('rejectAction', this.selectedAction)
    }
  }
}
