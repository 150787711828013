import LottieAnimation from 'lottie-web-vue'
import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import Colors from '@/shared/domain/colors/Color'
const KeybeIcon = () => import('@/shared/infrastructure/ui/icons/KeybeIcon.vue')
export default {
  name: 'TemplateSentToApproval',
  components: {
    KeybeIcon,
    LottieAnimation
  },
  data () {
    return {
      KeybeIconType,
      Colors
    }
  },
  methods: {
    goToList () {
      this.$emit('goToList')
    }
  }
}
