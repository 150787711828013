
import GupshupService from '@/components/configuration/Services/GupshupService'
import { mapState } from 'vuex'
export default {
  name: 'TemplatePreview',
  props: ['provider', 'templateMediaUrl', 'messagePreview', 'buttonsLabels', 'detail'],
  data () {
    return {
      videoType: false,
      profilePhotoUrl: '',
      phoneBackground: `${process.env.VUE_APP_STORAGE_URL}/files/bluebird/phone_outlined.svg`,
      verifiedAccount: `${process.env.VUE_APP_STORAGE_URL}/files/bluebird/verified-account.svg`
    }
  },
  mounted () {
    this.getProfilePhoto()
  },
  watch: {
    templateMediaUrl () {
      if (this.templateMediaUrl) {
        const url = this.templateMediaUrl.split(/[#?]/)[0].split('.').pop().trim()
        url === 'mp4' ? this.videoType = true : this.videoType = false
      }
    }
  },
  computed: {
    ...mapState('AppStore', ['selectedApp']),
    appName () {
      return this.selectedApp?.name || ''
    },
    messagePreviewCrop: {
      get () {
        return this.$store.state.CampaingsStore.messagePreviewCrop
      },
      set (value) {
        this.$store.commit('CampaingsStore/SET_EDITING_OBJECT', {
          key: 'messagePreviewCrop',
          value
        })
      }
    },
    preview () {
      let msg = null
      if (this.messagePreviewCrop) {
        msg = this.messagePreviewCrop
      } else {
        msg = this.messagePreview
      }
      return msg ? msg.replaceAll(null, '') : ''
    }
  },
  methods: {
    async getGupshupBusinessId () {
      if (this.provider?.config) {
        const { appName, apiKey } = this.provider?.config
        if (appName && apiKey) {
          const getBotNameResponse = await GupshupService.getBotName(
            appName,
            apiKey
          )
          const gupshupBusinessId = getBotNameResponse?.containerDetails?.gupshupBusinessId
          if (!gupshupBusinessId) {
            throw new Error("Couldn't find the gupshup business id")
          }
          return gupshupBusinessId
        }
      }
    },
    async getProfilePhoto () {
      try {
        let gupshupBusinessId = await this.getGupshupBusinessId()
        if (this.provider?.config?.apiKey && gupshupBusinessId) {
          gupshupBusinessId = this.provider?.config?.appID || gupshupBusinessId
          const getProfilePhotoResponse = await GupshupService.getProfilePhoto(
            gupshupBusinessId,
            this.provider?.config?.apiKey
          )
          const profilePhotoUrl = getProfilePhotoResponse?.image
          if (!profilePhotoUrl) {
            // throw new Error("Couldn't find the profile photo")
          } else {
            this.profilePhotoUrl = profilePhotoUrl
          }
        }
      } catch (error) {
        console.error(error)
      }
    }
  }
}
