import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'
import KeybeSelect from '@/modules/DesignSystem/infrastructure/components/KeybeSelect/KeybeSelect/KeybeSelect.vue'
export default {
  components: {
    KeybeIcon,
    KeybeSelect
  },
  props: {
    hasNext: {
      type: Boolean,
      default: false
    },
    hasPrev: {
      type: Boolean,
      default: false
    },
    itemsPerPage: {
      type: Number,
      default: 10
    }
  },
  data () {
    return {
      KeybeIconType,
      itemsPerPageOptions: [
        '10',
        '25',
        '50',
        '100'
      ]
    }
  },
  methods: {
    nextPage () {
      this.$emit('next')
    },
    prevPage () {
      this.$emit('prev')
    },
    changeItemsPerPage (items: number) {
      this.$emit('changeItemsPerPage', items)
    }
  }
}
