import { mapState } from 'vuex'
import QuickResponsesUseCases from '../../../application/QuickResponsesUseCases'
import { dataToGetQuickResponses, quickResponsesGetter } from '../../../domain/QuickResponsesController'
import QuickResponsesController from '../../controllers/QuickResponses.controller'
import { QuickResponse, QuickResponseTypeEnum } from '../../../domain/QuickResponse'
import CreateQuickResponse from '../CreateQuickResponse/CreateQuickResponse.vue'
import KBTabs from '@/shared/infrastructure/ui/menus/KBTabs.vue'
import QuickResponseCard from '../QuickResponseCard/QuickResponseCard.vue'
import KBButtonLoader from '@/shared/infrastructure/ui/progress/KBButtonLoader/KBButtonLoader.vue'
import KBSearch from '@/shared/infrastructure/ui/inputs/text/KBSearch.vue'
export default {
  components: {
    CreateQuickResponse,
    QuickResponseCard,
    KBButtonLoader,
    KBTabs,
    KBSearch
  },
  props: {
    userResponses: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState('AppStore', ['selectedApp']),
    ...mapState('UserStore', ['user', 'token']),
    selectedType (): string {
      const labelMap: {[key: number]: QuickResponseTypeEnum} = {
        0: QuickResponseTypeEnum.ALL,
        1: QuickResponseTypeEnum.TEXT,
        2: QuickResponseTypeEnum.IMAGE,
        3: QuickResponseTypeEnum.VIDEO,
        4: QuickResponseTypeEnum.PDF
      }
      return labelMap[this.selectedTabIndex]?.toString() || QuickResponseTypeEnum.ALL
    },
    filteredQuickResponses (): QuickResponse[] {
      if (this.selectedType === QuickResponseTypeEnum.ALL) {
        return this.quickResponses.filter((quickResponse: QuickResponse) => {
          if (quickResponse?.name) {
            return quickResponse?.name?.toLowerCase().includes(this.search.toLowerCase())
          }
          return quickResponse?.value?.toLowerCase().includes(this.search.toLowerCase())
        })
      }

      const filteredByType: QuickResponse[] = this.quickResponses.filter((quickResponse: QuickResponse) => {
        return quickResponse?.type === this.selectedType
      })

      return filteredByType.filter((quickResponse: QuickResponse) => {
        if (quickResponse.name) {
          return quickResponse.name.toLowerCase().includes(this.search.toLowerCase())
        }
        return quickResponse.value.toLowerCase().includes(this.search.toLowerCase())
      })
    }
  },
  mounted () {
    this.getQuickResponses()
  },
  data () {
    return {
      quickResponsesController: new QuickResponsesController(),
      quickResponses: [],
      selectedTabIndex: 0,
      search: '',
      loading: false,
      tabs: [
        this.$t(QuickResponseTypeEnum.ALL),
        this.$t(QuickResponseTypeEnum.TEXT),
        this.$t(QuickResponseTypeEnum.IMAGE),
        this.$t(QuickResponseTypeEnum.VIDEO),
        this.$t(QuickResponseTypeEnum.PDF)
      ]
    }
  },
  methods: {
    async getQuickResponses (): Promise<void> {
      this.loading = true
      const data: dataToGetQuickResponses = {
        app: this.selectedApp?.uuid,
        uuid: this.user?.userId,
        token: this.token
      }
      const response: quickResponsesGetter = await QuickResponsesUseCases.getQuickResponses(this.quickResponsesController, data)
      if (response?.status) {
        this.quickResponses = response.data
        // if (!this.userResponses) this.quickResponses = response.data
        // else {
        //   this.quickResponses = response.data.filter((quick: QuickResponse) => quick?.user === this.user?.userId)
        // }
      }
      setTimeout(() => {
        this.loading = false
      }, 500)
    }
  },
  beforeDestroy () {
    QuickResponsesUseCases.abort(this.quickResponsesController)
  }
}
