<template>
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 14"><path :fill="color" d="M1.632 13.01c.68 0 1.304-.001 1.926.001.096 0 .195.008.288.029a.47.47 0 0 1 .373.46.465.465 0 0 1-.382.472c-.08.017-.165.026-.247.026-.986.001-1.973 0-2.958.002-.25 0-.484-.033-.59-.293-.105-.255-.002-.466.18-.657.834-.875 1.664-1.753 2.493-2.632.338-.36.39-.746.165-1.14-.234-.405-.772-.538-1.153-.261-.224.162-.416.367-.626.548-.285.245-.605.222-.773-.081-.06-.111-.089-.319-.026-.405.46-.638.989-1.207 1.863-1.19.74.015 1.305.353 1.636 1.025.333.678.302 1.347-.194 1.929-.572.67-1.186 1.305-1.781 1.956l-.194.212v-.001ZM10.733 2.491H6.358c-.402 0-.603-.17-.6-.499.002-.243.127-.404.367-.46a1.2 1.2 0 0 1 .267-.028 7612 7612 0 0 1 8.707 0c.103 0 .209.014.308.04.188.05.325.257.321.46-.004.212-.138.402-.333.45a1.45 1.45 0 0 1-.329.037c-1.444.002-2.889.001-4.333.001ZM10.719 7.495H6.344c-.377 0-.574-.162-.586-.471-.01-.261.121-.435.379-.493a1.03 1.03 0 0 1 .226-.024c2.916-.001 5.832-.002 8.749 0 .212 0 .417.023.54.237.174.306.03.648-.313.726a1.25 1.25 0 0 1-.267.025h-4.354ZM10.743 12.498H6.348c-.38 0-.575-.16-.59-.468-.011-.262.118-.437.376-.495.086-.02.178-.025.267-.025l8.687-.001c.227 0 .447.018.573.252.161.3.014.635-.317.71a1.2 1.2 0 0 1-.268.026c-1.444.002-2.889 0-4.333 0ZM2.726 5.005c.338 0 .644-.007.948.002.454.012.682.407.457.776-.105.173-.277.208-.45.208-.966.006-1.932.007-2.898 0-.34-.002-.526-.185-.53-.484-.002-.305.193-.493.54-.5.305-.007.61-.002.935-.002V.991c-.153 0-.298.004-.442-.001C.938.977.748.795.754.486.76.19.953.012 1.291.004c.271-.007.542-.003.813-.002.442.002.621.177.622.62.002 1.362 0 2.723 0 4.085v.298Z"/></svg>
</template>

<script>
export default {
  name: 'NumlistIcon',
  props: {
    color: {
      type: String,
      default: '#B6BEC7'
    }
  }
}
</script>

<style>

</style>
