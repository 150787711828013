<template>
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 15"><path :fill="color" d="M8.012 11.51h-.253c-.89 0-1.78.006-2.67-.003-.595-.006-1.159-.139-1.637-.52-.669-.533-.953-1.242-.95-2.082.002-.877 0-1.753 0-2.629V6c-.317 0-.614.005-.91-.001C1.21 5.99.856 5.894.546 5.652c-.327-.258-.518-.6-.527-1a80.81 80.81 0 0 1-.003-3.276C.028.705.566.145 1.236.042c.165-.025.332-.039.498-.04C2.59-.002 3.445.001 4.3.001c1.068 0 1.684.614 1.685 1.682v2.65C5.983 5.388 5.367 6 4.307 6h-.814c-.004.086-.012.16-.012.234 0 .877-.002 1.753 0 2.629.003 1.033.634 1.655 1.673 1.655h2.858c0-.293.007-.562-.002-.831-.034-1.066.679-1.68 1.67-1.674.883.006 1.766.001 2.65.002 1.042 0 1.666.62 1.669 1.655v2.692c-.003 1.03-.623 1.65-1.652 1.652-.911 0-1.823.005-2.733-.002-.518-.003-.974-.17-1.298-.593-.223-.29-.311-.63-.305-.997.005-.297 0-.595 0-.913Zm-5.024-6.5h1.398c.424-.002.615-.182.619-.61.005-.932.006-1.865 0-2.796-.003-.424-.202-.61-.62-.612H1.59c-.418 0-.606.173-.609.592a169.21 169.21 0 0 0 0 2.838c.003.422.185.586.61.588H2.99h-.001Zm8.016 3.996c-.474 0-.946-.002-1.42 0-.388.002-.582.163-.587.547-.015.973-.014 1.947 0 2.92.005.389.192.548.583.549.946.002 1.892.002 2.838 0 .39 0 .592-.175.597-.565.012-.96.012-1.92 0-2.88-.005-.396-.21-.57-.614-.572-.466-.002-.932 0-1.398 0Z"/></svg>
</template>

<script>
export default {
  name: 'MomentsIcon',
  props: {
    color: {
      type: String,
      default: '#B6BEC7'
    }
  }

}
</script>

<style>

</style>
