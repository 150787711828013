














































import op from '@/utils/operations'
import { EventBus } from '@/eventBus'
import { mapActions, mapState } from 'vuex'
import KeybeAiConfigController from '@/modules/ai/infrastructure/controllers/KeybeAiConfig.controller'
import KeybeAiConfigUseCases from '@/modules/ai/application/KeybeAiConfigUseCases'
import { KeybeAiConfigStatesEnum } from '@/modules/ai/domain/KeybeAiConfig'
import KBSwitch from '@/shared/infrastructure/ui/inputs/select/KBSwitch.vue'

export default {
  name: 'KeybeAiConfig',
  components: {
    KBSwitch
  },
  data () {
    return {
      op,
      sentimentalAnalysis: false,
      messageSuggestions: false,
      keybeAiConfigController: null
    }
  },
  methods: {
    ...mapActions('ConversationStore', ['setSentimentalAnalysisEnabled', 'setMessageSuggestionsEnabled']),
    async getConfig () {
      try {
        const [sentimentalResponse, answersResponse] = await Promise.all([
          KeybeAiConfigUseCases.getSentimentsConfig(this.keybeAiConfigController, {
            appId: this.selectedApp?.uuid || '',
            advisorName: this.user?.name || '',
            advisorId: this.user?.userId || '',
            token: this.token || ''
          }),
          KeybeAiConfigUseCases.getMessageSuggestionsConfig(this.keybeAiConfigController, {
            appId: this.selectedApp?.uuid || '',
            advisorName: this.user?.name || '',
            advisorId: this.user?.userId || '',
            token: this.token || ''
          })
        ])
        this.sentimentalAnalysis = this.op.areEqual(sentimentalResponse.state, KeybeAiConfigStatesEnum.ENABLED)
        this.setSentimentalAnalysisEnabled(this.op.areEqual(sentimentalResponse.state, KeybeAiConfigStatesEnum.ENABLED))

        this.messageSuggestions = this.op.areEqual(answersResponse.state, KeybeAiConfigStatesEnum.ENABLED)
        this.setMessageSuggestionsEnabled(this.op.areEqual(answersResponse.state, KeybeAiConfigStatesEnum.ENABLED))
      } catch (error) {
        EventBus.$emit('toast', 'error', this.$t(error.message || 'unexpectedError'))
      }
    },
    async toggleSentimentalAnalysis (value) {
      try {
        const response = await KeybeAiConfigUseCases.setSentimentsConfig(this.keybeAiConfigController, {
          appId: this.selectedApp?.uuid || '',
          advisorName: this.user?.name || '',
          advisorId: this.user?.userId || '',
          state: value ? KeybeAiConfigStatesEnum.ENABLED : KeybeAiConfigStatesEnum.DISABLED,
          token: this.token || ''
        })
        this.sentimentalAnalysis = this.op.areEqual(response.state, KeybeAiConfigStatesEnum.ENABLED)
        this.setSentimentalAnalysisEnabled(this.op.areEqual(response.state, KeybeAiConfigStatesEnum.ENABLED))
        EventBus.$emit('refresh-conversations')
      } catch (error) {
        EventBus.$emit('toast', 'error', this.$t(error.message || 'unexpectedError'))
      }
    },
    async toggleMessageSuggestions (value) {
      try {
        const response = await KeybeAiConfigUseCases.setMessageSuggestionsConfig(this.keybeAiConfigController, {
          appId: this.selectedApp?.uuid || '',
          advisorName: this.user?.name || '',
          advisorId: this.user?.userId || '',
          state: value ? KeybeAiConfigStatesEnum.ENABLED : KeybeAiConfigStatesEnum.DISABLED,
          token: this.token || ''
        })
        this.messageSuggestions = this.op.areEqual(response.state, KeybeAiConfigStatesEnum.ENABLED)
        this.setMessageSuggestionsEnabled(this.op.areEqual(response.state, KeybeAiConfigStatesEnum.ENABLED))
      } catch (error) {
        EventBus.$emit('toast', 'error', this.$t(error.message || 'unexpectedError'))
      }
    }
  },
  computed: {
    ...mapState('UserStore', ['user', 'token']),
    ...mapState('AppStore', ['selectedApp']),
    ...mapState('CompanyStore', ['selectedCompany'])
  },
  async created () {
    this.keybeAiConfigController = new KeybeAiConfigController()
    await this.getConfig()
  },
  beforeDestroy () {
    this.keybeAiConfigController = null
  }
}
