<template>
  <svg width="18"
       height="19"
       viewBox="0 0 18 19"
       fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <path d="M16.7391 1.27135C16.6083 1.27135 16.5119 1.27135 16.4165 1.27135C15.0595 1.27135 13.7024 1.27349 12.3454 1.27135C11.9017 1.27135 11.668 1.10306 11.6133 0.7622C11.5394 0.304502 11.8181 0.00437331 12.3379 0.00222953C13.472 -0.00205803 14.605 0.00115764 15.739 0.00115764C16.23 0.00115764 16.7209 -0.000986139 17.2118 0.00115764C17.752 0.00330142 17.9932 0.22947 17.9943 0.760056C17.9996 2.39362 17.9996 4.02825 17.9943 5.66181C17.9921 6.22777 17.6449 6.5161 17.1657 6.37462C16.9374 6.30709 16.7991 6.16024 16.7638 5.92549C16.7455 5.80223 16.7402 5.67682 16.7402 5.55248C16.7391 4.23941 16.7402 2.92742 16.7402 1.61435C16.7402 1.50931 16.7402 1.40533 16.7402 1.27242L16.7391 1.27135Z"
          :fill="color"/>
    <path d="M16.7402 16.7437C16.7402 16.6022 16.7402 16.4972 16.7402 16.3922C16.7402 15.0534 16.7391 13.7135 16.7402 12.3747C16.7402 11.9181 16.871 11.7198 17.2043 11.6362C17.5634 11.5461 17.8721 11.7209 17.9622 12.0789C17.9922 12.1978 17.9954 12.3265 17.9954 12.4508C17.9975 14.0136 17.9975 15.5754 17.9965 17.1382C17.9965 17.8188 17.7982 18.0161 17.1207 18.0171C15.5322 18.0171 13.9436 18.0182 12.354 18.0171C11.8588 18.0171 11.6026 17.7953 11.6016 17.3826C11.6016 16.9753 11.8642 16.748 12.3562 16.7469C13.517 16.7437 14.6768 16.7469 15.8377 16.7469C16.1228 16.7469 16.4079 16.7469 16.7402 16.7469V16.7437Z"
          :fill="color"/>
    <path d="M1.25599 1.30631C1.25599 1.4553 1.25599 1.56142 1.25599 1.66646C1.25599 2.99775 1.25706 4.32904 1.25599 5.66033C1.25599 6.12231 1.11879 6.32811 0.772565 6.41815C0.41241 6.51248 0.0801238 6.30239 0.0211698 5.93902C0.0029477 5.8254 0.000803917 5.70749 0.000803917 5.59172C-0.000267973 4.02784 -0.000267973 2.46502 0.000803917 0.901134C-0.000267973 0.221556 0.1916 0.0339752 0.879754 0.0339752C2.45222 0.0339752 4.02468 0.026472 5.59607 0.0425504C5.80402 0.0446941 6.0677 0.102576 6.20705 0.236562C6.56827 0.584927 6.37748 1.20769 5.89834 1.27522C5.78365 1.2913 5.66789 1.30416 5.55319 1.30416C4.23155 1.30631 2.90884 1.30524 1.5872 1.30524C1.48966 1.30524 1.39319 1.30524 1.25599 1.30524V1.30631Z"
          :fill="color"/>
    <path d="M1.27665 16.743C2.19312 16.743 3.05921 16.743 3.92422 16.743C4.51376 16.743 5.1033 16.7366 5.69284 16.7452C6.24915 16.7527 6.55035 17.1782 6.34884 17.6573C6.23522 17.9285 5.99619 18.01 5.74 18.0121C4.06035 18.0196 2.38177 18.0207 0.702121 18.0121C0.241208 18.01 0.025758 17.7935 0.0236142 17.3293C0.0150391 15.6497 0.016111 13.97 0.0236142 12.2904C0.025758 11.8552 0.272293 11.6001 0.64531 11.6001C1.01511 11.6001 1.27129 11.8777 1.27344 12.3215C1.27987 13.6796 1.27665 15.0376 1.27665 16.3957C1.27665 16.5008 1.27665 16.6058 1.27665 16.7441V16.743Z"
          :fill="color"/>
  </svg>

</template>

<script>
export default {
  name: 'ExpandIcon',
  props: {
    color: {
      type: String,
      default: '#B6BEC7'
    }
  }
}
</script>

<style>

</style>
