







export default {
  name: 'WarningIcon',
  props: {
    color: {
      type: String,
      default: '#FC0'
    }
  }
}
