import KBButton from '@/shared/infrastructure/ui/buttons/KBButton.vue'
import { mapState } from 'vuex'
export default {
  name: 'ModalWarning',
  components: {
    KBButton
  },
  props: {
    dataUndefined: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      message: ''
    }
  },
  computed: {
    ...mapState('UserStore', ['isUserReadonly'])
  },
  mounted () {
    this.message = this.dataUndefined
  },
  methods: {
    closeModal ():void {
      this.$emit('closeModal')
    }
  }
}
