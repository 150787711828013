import { Product, ProductVariation } from '../../domain/models/Product'
import { IVariatonsController, dataToAddVariationToProduct, dataToGetVariations, dataToGetVariationsCreated, variationDTO } from '../../domain/models/VariationsController'
import VariationRepository from '../repositories/Variation.repository'

export default class VariationController implements IVariatonsController {
  repository: VariationRepository

  constructor () {
    this.repository = new VariationRepository()
  }

  async getVariations (data: dataToGetVariations): Promise<[]> {
    const response = await this.repository.getVariationOptions(data)
    if (!response.status) {
      return []
    }
    return response?.data?.data || []
  }

  async getVariationsCreated (data: dataToGetVariationsCreated): Promise<[]> {
    const response = await this.repository.getVariationsCreated(data)
    if (!response.status) {
      return []
    }
    return response?.data?.data || []
  }

  async addVariationToProduct (data: dataToAddVariationToProduct): Promise<Product> {
    const response = await this.repository.createVariation(data)
    if (!response.status) {
      throw new Error('Error creating variation')
    }
    return response?.data?.data || {}
  }

  async addVariation (data: variationDTO): Promise<ProductVariation> {
    const response = await this.repository.createVariationOption(data)
    if (!response.status) {
      throw new Error('Error creating variation')
    }
    return response?.data?.data || {}
  }

  async updateVariationInProduct (data: dataToAddVariationToProduct): Promise<Product> {
    const response = await this.repository.updateVariation(data)
    if (!response.status) {
      throw new Error('Error updating variation')
    }
    return response?.data?.data || {}
  }
}
