import KeybeRepository from '@/shared/infrastructure/repositories/KeybeRepository'
import { HeadersBuilder } from '@/shared/infrastructure/http/HeadersBuilder'
import { getOptions, nonGetOptions } from '@/shared/infrastructure/http/HttpClient'
import { dataToGetUser, dataToUpdateUser, dataToUploadImage } from '../../domain/UserController'
import { GenericResponse } from '@/models/apis/GenericResponse'

export default class UserRepository extends KeybeRepository {
  getSuggestionsAbortController: AbortController

  constructor () {
    super()

    this.getSuggestionsAbortController = new AbortController()
  }

  abortGetSuggestions () {
    this.getSuggestionsAbortController.abort()
    this.getSuggestionsAbortController = new AbortController()
  }

  async updateUser (data: dataToUpdateUser): Promise<GenericResponse> {
    const { uuid, updateInput } = data
    const url = `${process.env.VUE_APP_AUTH_SERVICE}user/${uuid}`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    const requestOptions: nonGetOptions = {
      headers,
      url,
      data: updateInput,
      abortController: this.getSuggestionsAbortController
    }

    return await this.client.put(requestOptions)
  }

  async getUser (data: dataToGetUser): Promise<any> {
    const { uuid } = data
    const url = `${process.env.VUE_APP_AUTH_SERVICE}user/${uuid}`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    const requestOptions: getOptions = {
      headers,
      url,
      abortController: this.getSuggestionsAbortController
    }

    return await this.client.get(requestOptions)
  }

  async uploadUserImage (data: dataToUploadImage): Promise<GenericResponse> {
    const { img } = data
    const url = `${process.env.VUE_APP_AUTH_SERVICE}user/image`
    const headers = HeadersBuilder.buildFormDataAutorizationHeader(data)

    const fd = new FormData()
    fd.append('file', img)

    const requestOptions: nonGetOptions = {
      headers,
      url,
      data: fd,
      abortController: this.getSuggestionsAbortController,
      isFormData: true
    }

    return await this.client.post(requestOptions)
  }
}
