


























































































import ConversationsConfigController from '@/components/configuration/controllers/ConversationsConfigController'
import { mapState } from 'vuex'
import { WpProviders } from '@/components/conversations/models/WpProviders'

import GupshupFormConfig from '@/modules/configuration/infrastructure/ui/components/OldChannels/GupshupFormConfig.vue'
import FacebookFormConfig from '@/modules/configuration/infrastructure/ui/components/OldChannels/FacebookFormConfig.vue'
import InstagramFormConfig from '@/modules/configuration/infrastructure/ui/components/OldChannels/InstagramFormConfig.vue'
import WatsonConfigForm from '@/modules/configuration/infrastructure/ui/components/OldChannels/WatsonConfigForm.vue'
import ChatConfigController from '@/modules/ChatConfig/infrastructure/ChatConfig.controller'

export default {
  name: 'OldChannelsConfig',
  components: {
    GupshupFormConfig,
    FacebookFormConfig,
    InstagramFormConfig,
    WatsonConfigForm
  },
  data () {
    return {
      showNewWhatsappConfig: false,
      showFacebookConfig: false,
      showInstagramConfig: false,
      editingConfig: '',
      hasFacebook: false,
      hasInstagram: false,
      facebookConfig: undefined,
      instagramConfig: undefined,
      watsonConfig: undefined,
      hasWatson: false,
      showWatsonConfig: false,
      whatsappConfigs: [],
      newWhatsappConfig: {
        apiKey: '',
        appName: '',
        phone: ''
      },
      editingFacebook: '',
      editingInstagram: '',
      chatConfigController: new ChatConfigController()
    }
  },
  created () {
    this.getChannels()
  },
  computed: {
    ...mapState('CompanyStore', ['selectedCompany']),
    ...mapState('ConversationStore', ['channels']),
    ...mapState('AppStore', ['selectedApp']),
    ...mapState('UserStore', ['token'])
  },
  methods: {
    async getChannels (force = false) {
      this.$emit('start-loading')
      if (!this.channels || Object.keys(this.channels).length === 0 || force) {
        await ConversationsConfigController.getChannels()
      }
      // Facebook Messenger
      if (this.channels?.facebookMessenger) {
        this.hasFacebook = true
        this.facebookConfig = this.channels.facebookMessenger
      }
      // Instagram
      if (this.channels?.instagram) {
        this.hasInstagram = true
        this.instagramConfig = this.channels.instagram
      }
      // WhatsApp
      if (this.channels?.whatsapp) {
        this.whatsappConfigs = this.channels?.whatsapp?.accounts
      }
      await this.getBotConfigs()
      this.$emit('stop-loading')
    },
    async getBotConfigs () {
      const chatConfig = await this.chatConfigController.getAndSetConfig({
        appUUID: this.selectedApp?.uuid || '',
        token: this.token || ''
      })

      const botConfig = chatConfig?.botConfig

      if (!botConfig) {
        return
      }
      if (botConfig?.config) {
        this.watsonConfig = botConfig?.config
        this.watsonConfig.name = botConfig?.name
        this.hasWatson = botConfig?.config?.appKey
      }
    },
    isBSP (config) {
      return config?.provider === WpProviders.WpKeybe
    },
    getConfigLabel (config) {
      return `${config?.userHost} - ${this.isBSP(config) ? `BSP (${this.$t('notEditable')})` : 'GUPSHUP'}`
    },
    canEditWhatsappConfig (config) {
      return !this.isBSP(config)
    },
    isEditingConfig (config) {
      return this.editingConfig === config?.userHost
    },
    showEditConfig (config) {
      if (this.isBSP(config)) return
      if (this.isEditingConfig(config)) {
        this.editingConfig = ''
      } else {
        this.editingConfig = config?.userHost
      }
    },
    canEditFacebookConfig (config) {
      return this.hasFacebook && config?.connectionMethod === 'old'
    },
    canEditInstagramConfig (config) {
      return this.hasInstagram && config?.connectionMethod === 'old'
    },
    facebookLabel (config) {
      return this.canEditFacebookConfig(config) ? this.$t('facebookMessenger') : `${this.$t('autoManagedFacebook')} (${this.$t('notEditable')})`
    },
    instagramLabel (config) {
      return this.canEditInstagramConfig(config) ? this.$t('instagram') : `${this.$t('autoManagedInstagram')} (${this.$t('notEditable')})`
    },
    handleEditFacebookConfig (config) {
      if (!this.canEditFacebookConfig(config)) {
        return
      }
      if (this.editingFacebook === config?._id) {
        this.editingFacebook = ''
      } else {
        this.editingFacebook = config?._id
      }
    },
    handleEditInstagramConfig (config) {
      if (!this.canEditInstagramConfig(config)) {
        return
      }
      if (this.editingInstagram) {
        this.editingInstagram = ''
      } else {
        this.editingInstagram = config?._id
      }
    }
  },
  watch: {
    selectedCompany: {
      async handler () {
        await this.getChannels(true)
        this.whatsappConfig = false
      }
    }
  }
}
