export const SegmentsStore = {
  namespaced: true,
  state: {
    segments: [],
    lastPage: null,
    scrollPosition: null,
    contextMenuSegment: {}
  },
  mutations: {
    SET_SEGMENTS (state, segments) {
      state.segments = segments
    },
    SET_LAST_PAGE (state, lastPage) {
      state.lastSavedPage = lastPage
    },
    SET_SCROLL_POSITION (state, position) {
      state.scrollPosition = position
    },
    SET_CONTEXT_MENU_SEGMENT (state, segment) {
      state.contextMenuSegment = segment
    }
  },
  actions: {
    setContextMenuSegment (context, segment) {
      context.commit('SET_CONTEXT_MENU_SEGMENT', segment)
    }
  }
}
