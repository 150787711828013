import { BikyConfigUseCases } from '@/modules/bky/application/BikyConfigUseCases'
import { KnowledgeBaseTypeEnum } from '@/modules/bky/domain/BikyConfig'
import { getKnowledgeBaseDTO } from '@/modules/bky/domain/BikyConfigController'
import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'
import KBSearch from '@/shared/infrastructure/ui/inputs/text/KBSearch.vue'
import KBTabs from '@/shared/infrastructure/ui/menus/KBTabs.vue'
import { debounce } from 'lodash'
import { mapState } from 'vuex'
import BikyConfigController from '../../../controllers/BikyConfig.controller'
import BikyConfigHeader from '../BikyConfigHeader/BikyConfigHeader.vue'
import BikyConfigKnowledgeBaseItem from '../BikyConfigKnowledgeBaseItem/BikyConfigKnowledgeBaseItem.vue'
export default {
  name: 'BikyConfigKnowledgeBase',
  components: {
    BikyConfigHeader,
    KBTabs,
    KeybeIcon,
    BikyConfigKnowledgeBaseItem,
    KBSearch
  },
  data () {
    return {
      KeybeIconType,
      tabs: [
        this.$t('knowledgeBaseAll'),
        this.$t('knowledgeBasePdf'),
        this.$t('knowledgeBaseWeb'),
        this.$t('knowledgeBaseCatalog'),
        this.$t('knowledgeBaseUser')
      ],
      selectedTab: this.$t('knowledgeBaseAll'),
      selectedTabIndex: 0,
      bikyConfigController: new BikyConfigController(),
      documents: [],
      count: 0,
      offset: 0,
      loading: false,
      search: ''
    }
  },
  computed: {
    ...mapState('AppStore', ['selectedApp']),
    ...mapState('UserStore', ['token', 'user']),
    isAllTabActive (): boolean {
      return this.selectedTab === this.$t('knowledgeBaseAll')
    },
    isPdfTabActive (): boolean {
      return this.selectedTab === this.$t('knowledgeBasePdf')
    },
    isWebTabActive (): boolean {
      return this.selectedTab === this.$t('knowledgeBaseWeb')
    },
    isCatalogTabActive (): boolean {
      return this.selectedTab === this.$t('knowledgeBaseCatalog')
    },
    isUserTabActive (): boolean {
      return this.selectedTab === this.$t('knowledgeBaseUser')
    },
    knowledgeBaseTotal (): number {
      return this.count || 0
    },
    knowledgeBaseDescription (): string {
      const descriptions = {
        [this.$t('knowledgeBaseAll')]: this.$t('knowledgeBaseAllDescrition'),
        [this.$t('knowledgeBasePdf')]: this.$t('knowledgeBasePdfDescrition'),
        [this.$t('knowledgeBaseWeb')]: this.$t('knowledgeBaseWebDescrition'),
        [this.$t('knowledgeBaseCatalog')]: this.$t('knowledgeBaseCatalogDescription'),
        [this.$t('knowledgeBaseUser')]: this.$t('knowledgeBaseUserDescrition')
      }

      return descriptions[this.selectedTab] || ''
    }
  },
  methods: {
    onTabChange (index): void {
      this.selectedTabIndex = index
      this.selectedTab = this.tabs[index]
      this.documents = []
      this.offset = 0
      this.getKnowledgeBase()
    },
    async getKnowledgeBase () {
      if (this.loading) return
      if (this.offset === 0) {
        this.documents = []
        this.$emit('start-loading')
      }
      this.loading = true
      const types = {
        [this.$t('knowledgeBaseAll')]: undefined,
        [this.$t('knowledgeBasePdf')]: KnowledgeBaseTypeEnum.PDF,
        [this.$t('knowledgeBaseWeb')]: KnowledgeBaseTypeEnum.WEB,
        [this.$t('knowledgeBaseUser')]: KnowledgeBaseTypeEnum.USER,
        [this.$t('knowledgeBaseCatalog')]: KnowledgeBaseTypeEnum.MY_KEYBE
      }

      const type = types[this.selectedTab]

      const data: getKnowledgeBaseDTO = {
        appUUID: this.selectedApp?.uuid,
        token: this.token,
        limit: 20,
        offset: this.offset,
        search: this.search
      }

      if (type) {
        data.type = type
      }

      const response = await BikyConfigUseCases.getKnowledgeBase(this.bikyConfigController, data)
      const { documents, count } = response
      this.count = count
      this.documents = this.documents.concat(documents)
      this.$emit('stop-loading')
      this.loading = false
    },
    onScroll () {
      // calculate if the ref list is scrolled to the bottom
      const list = this.$refs.list
      const scrollHeight = list.scrollHeight
      const scrollTop = list.scrollTop
      const clientHeight = list.clientHeight
      const scrolledToBottom = Math.ceil(scrollTop + clientHeight) >= scrollHeight - 40
      if (scrolledToBottom && this.count > this.documents?.length && !this.loading) {
        this.offset += 20
        this.getKnowledgeBase()
      }
    },
    async onSave (data) {
      if (this.loading) return

      this.loading = true
      this.$emit('start-loading')
      const { _id, title, description } = data
      const text = `TITLE: ${title}\nCONTENT: ${description}`
      let saved
      if (!_id) {
        saved = await BikyConfigUseCases.createKnowledgeBase(this.bikyConfigController, {
          appUUID: this.selectedApp?.uuid,
          token: this.token,
          text,
          type: KnowledgeBaseTypeEnum.USER,
          typeId: this.user?.userId
        })
      } else {
        saved = await BikyConfigUseCases.updateKnowledgeBase(this.bikyConfigController, {
          token: this.token,
          text,
          knowledgeBaseDocumentId: _id
        })
      }

      if (!saved) {
        this.$emit('toast', 'error', this.$t('errorSavingData'))
      }

      this.documents = []
      this.offset = 0
      this.loading = false
      setTimeout(async () => {
        await this.getKnowledgeBase()
        this.$emit('stop-loading')
      }, 500)
    },
    async onDelete (data) {
      if (this.loading) return
      this.$emit('start-loading')
      this.loading = true
      const {
        _id
      } = data

      const deleted = await BikyConfigUseCases.deleteKnowledgeBase(this.bikyConfigController, {
        token: this.token,
        documentId: _id
      })

      if (!deleted) {
        this.$emit('toast', 'error', this.$t('errorDeletingData'))
      } else {
        this.documents = []
        this.offset = 0
        await this.getKnowledgeBase()
      }

      this.loading = false
      this.$emit('stop-loading')
    },
    async addManually () {
      const data = {
        text: '',
        type: KnowledgeBaseTypeEnum.USER
      }

      this.selectedTabIndex = this.tabs.length - 1
      this.selectedTab = this.tabs[this.selectedTabIndex]
      this.documents = []
      this.offset = 0
      await this.getKnowledgeBase()

      this.documents.unshift(data)
    },
    handleSearch: debounce(async function () {
      this.documents = []
      this.offset = 0
      await this.getKnowledgeBase()
    }, 500)
  },
  mounted () {
    this.documents = []
    this.offset = 0
    this.getKnowledgeBase()
  },
  beforeDestroy () {
    BikyConfigUseCases.abortAll(this.bikyConfigController)
  },
  watch: {
    search: 'handleSearch'
  }
}
