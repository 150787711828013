import KeybeRepository from '@/shared/infrastructure/repositories/KeybeRepository'
import { HeadersBuilder } from '@/shared/infrastructure/http/HeadersBuilder'
import { GenericResponse } from '@/shared/infrastructure/http/GenericResponse'
import { dataToGetPlan } from '../../domain/PlanController'

export default class PlanRepository extends KeybeRepository {
      // abort controllers
      getBookingAbortController: AbortController
      baseUrl: string

      constructor () {
        super()

        // instance abort controllers
        this.getBookingAbortController = new AbortController()

        this.baseUrl = process.env.VUE_APP_BILLING_URL
      }

      async setBooking (data: dataToGetPlan): Promise<GenericResponse> {
        const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
        const url = `${this.baseUrl}package/plan/booking`

        const request = {
          url,
          data,
          headers,
          abortController: this.getBookingAbortController
        }

        return await this.client.post(request)
      }
}
