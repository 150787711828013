import operation from '@/utils/operations'
import { mapState } from 'vuex'
import Colors from '@/utils/Colors'
import { Socials } from '@/modules/microsites/infrastructure/utils/socials'
import { Modes } from '@/modules/microsites/infrastructure/utils/modes'
import { EventBus } from '@/eventBus'
import * as utils from '@/modules/microsites/infrastructure/utils'

// components
import KeybeTextfield from '@/shared/infrastructure/ui/inputs/text/KBText.vue'
import KBIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'
import SocialTypePicker from '@/modules/microsites/infrastructure/ui/components/SocialTypePicker.vue'
import EditIconDialog from '@/modules/microsites/infrastructure/ui/dialogs/EditIconDialog.vue'

export default {
  components: {
    EditIconDialog,
    KeybeTextfield,
    SocialTypePicker,
    KBIcon
  },
  data () {
    return {
      localValue: [],
      countryPhonePicker: {
        country: 'Colombia',
        code: 'co',
        number: '57',
        phone: '',
        valid: false
      },
      whatsAppOptions: [],
      whatsappOption: '',
      whatsappOptionText: this.$t('letsTalk'),
      whatsappButtonType: false,
      whatsAppEditMode: false,
      buttonEditMode: false,
      trackingIndex: 300,
      buttonIndex: 300,
      socialIndex: 300,
      editIconDialogOpen: false,
      whatsAppObject: {},
      selectedButton: {},
      Socials,
      Colors,
      editIcon: false,
      editIconId: null
    }
  },
  computed: {
    ...mapState('UserStore', ['user']),
    planetIcon (): string {
      return (
        process.env.VUE_APP_STORAGE_URL +
        '/files/bluebird/Microsites/planet-black.svg'
      )
    },
    whatsAppIcon (): string {
      return (
        process.env.VUE_APP_STORAGE_URL +
        '/files/bluebird/conversation-module/whatsapp.svg'
      )
    }
  },
  mounted () {
    EventBus.$on('setSelectedIcon', this.setSelectedIcon.bind(this))

    if (this.value.config?.socialLinks) {
      this.localValue = this.setOrder([
        ...JSON.parse(JSON.stringify(this.value.config?.socialLinks))
      ])
    }

    this.localValue.forEach(
      (item: {
        id: string,
        icon: string,
        description: string,
        tipoIcon: boolean
      }) => {
        if (!item.icon) {
          item.icon = this.planetIcon
          item.id = Socials.Plt
        }
        if (!item.tipoIcon) item.tipoIcon = false
        if (!item.description) item.description = ''
      }
    )
    this.getIcons()
  },
  beforeDestroy () {
    EventBus.$off('setSelectedIcon', this.setSelectedIcon.bind(this))
  },
  methods: {
    setSelectedIcon (image: { link: string; icon: string }) {
      const buttonToEdit = this.localValue[this.trackingIndex]
      if (image) {
        const { link, icon } = image
        buttonToEdit.icon = link
        buttonToEdit.id = icon
      }
    },
    checkLink (index) {
      if (!this.localValue[index].url) {
        EventBus.$emit('toast', 'error', this.$t('mustEnterALink'))
        return
      }
      this.whatsAppEditMode = false
    },
    getIcons (): void {
      this.localValue.forEach(
        (item: { icon: string; url: string; id: string }) => {
          if (!item.icon) {
            const icon = utils.recognizeSocial(item.url)
            item.icon = icon.link
            item.id = icon.icon
          }
        }
      )
    },
    toggleEditIcon (index, item) {
      this.trackingIndex = index
      this.selectedButton = item
    },
    toggleType (type, index) {
      this.localValue[index].tipoIcon = type
      this.buttonEditMode = true
      this.buttonIndex = index + 1
    },
    toggleEditButton (index) {
      if (index) {
        this.buttonEditMode = true
        this.buttonIndex = index
      } else {
        if (!this.localValue[this.buttonIndex - 1].url) {
          EventBus.$emit('toast', 'error', this.$t('mustEnterALink'))
          return
        }
        if (
          !this.localValue[this.buttonIndex - 1].tipoIcon &&
          !this.localValue[this.buttonIndex - 1].description
        ) {
          EventBus.$emit('toast', 'error', this.$t('mustEnterATextForLink'))
          return
        }
        this.buttonEditMode = false
        this.buttonIndex = null
      }
    },
    deleteButton (index) {
      this.localValue.splice(index, 1)
    },
    checkID (url: string, index: number): void {
      // Check Wtsp
      if (
        this.areEqual(utils.recognizeSocial(url), Socials.Ws) &&
        !this.areEqual(this.getWtsp(this.localValue), '')
      ) {
        this.localValue[index].id = 'link'
      } else {
        this.localValue[index].id = utils.recognizeSocial(url).icon
        this.localValue[index].icon = utils.recognizeSocial(url).link
      }
    },
    areEqual (a: any, b: any): boolean {
      return operation.areEqual(a, b)
    },
    getPhone (json = false) {
      if (!this.user.countryCode) return ''
      if (!this.user.phone) return ''
      return json ? this.user : `+${this.user.countryCode}${this.user.phone}`
    },
    checkIcon () {
      const changes = []
      this.value.config.socialLinks.forEach((link, index) => {
        if (!this.areEqual(link.id, '') && this.areEqual(link.url, '')) {
          changes.push(index)
        }
      })
      if (changes.length > 0) {
        const temp = [...this.value.config.socialLinks]
        changes.forEach((index) => temp.splice(index, 1))
        this.localValue = [...temp]
      }
    },
    // addSocial () {
    //   console.log('Current State of Socials', this.value)
    //   // const link = {
    //   //   icon: this.planetIcon,
    //   //   tipoIcon: false,
    //   //   description: '',
    //   //   id: Socials.Plt,
    //   //   url: ''
    //   // }
    //   // this.localValue.push(link)
    // },
    getIcon (item) {
      if (item.icon) return item.icon.link
      else {
        const icon = utils.recognizeSocial(item.url)
        return icon.link
      }
    },
    getPlaceholder (id) {
      const icons = {
        whatsapp: 'addYourWhatsApp',
        facebook: 'pasteTheLinkOfTheProfileFbHere',
        link: 'pasteTheLinkOfTheProfileHere'
      }

      return this.$t(icons[id] ?? icons.link)
    },
    setFocusLastItem () {
      // this.$refs[`item-${this.localValue.length - 1}`][0].focus()
    },
    setIfExits (linksNow, linksEntry) {
      linksEntry.forEach((elem) => {
        const index = linksNow.findIndex((link) =>
          this.areEqual(link.id, elem.id)
        )

        if (index > -1) {
          linksNow[index] = {
            ...linksNow[index],
            url: elem.url
          }
        } else {
          linksNow.push(elem)
        }
      })

      return linksNow
    },
    setOrder (links: { id: string; url: string }[]) {
      return links.sort(
        (a: { id: string; url: string }, b: { id: string; url: string }) => {
          if (this.areEqual(a.id, Socials.Ws)) return -1
          if (this.areEqual(a.url, '') && !this.areEqual(b.url, '')) return 1
          if (this.areEqual(b.url, '') && !this.areEqual(a.url, '')) return -1
          return 0
        }
      )
    }
  },
  watch: {
    localValue: {
      handler (newValue) {
        this.$emit(Modes.Edit, newValue)
      },
      deep: true
    },
    value: {
      handler () {
        this.localValue = this.value
      },
      immediate: true,
      deep: true
    }
  },
  props: {
    value: {
      required: true,
      type: Array
    },
    mode: {
      required: true,
      type: String,
      default: Modes.Create
    }
  }
}
