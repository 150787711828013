<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       fill="none"
       viewBox="0 0 20 36">
    <path :fill="color"
          d="M10.01 34.026c.913 0 1.643-.736 1.643-1.656a1.643 1.643 0 1 0-3.286 0c0 .92.73 1.656 1.643 1.656Zm0-2.794c.614 0 1.129.502 1.129 1.138 0 .636-.498 1.138-1.129 1.138-.63 0-1.128-.502-1.128-1.138 0-.636.498-1.137 1.128-1.137ZM11.353 2.576H8.664a.263.263 0 0 0-.266.268c0 .15.117.267.266.267h2.689c.15 0 .265-.117.265-.267a.263.263 0 0 0-.265-.268Z"/>
    <path :fill="color"
          d="M17.012 0H2.988C1.344 0 0 1.355 0 3.011V32.99A2.998 2.998 0 0 0 2.988 36h14.024C18.672 36 20 34.645 20 32.989V3.01A2.998 2.998 0 0 0 17.012 0Zm1.594 32.989a1.6 1.6 0 0 1-1.594 1.606H2.988a1.6 1.6 0 0 1-1.594-1.606v-2.794h17.212v2.794Zm0-3.33H1.394V3.012a1.6 1.6 0 0 1 1.594-1.606h14.024a1.6 1.6 0 0 1 1.594 1.606V29.66Z"/>
  </svg>
</template>

<script>
export default {
  name: 'PhoneIcon',
  props: {
    color: {
      type: String,
      default: '#01CFE4'
    }
  }
}
</script>

<style scoped>

</style>
