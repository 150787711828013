<template>
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 18"><path :fill="color" d="M.003 11.88c.008-.29.16-.492.36-.67.438-.389.874-.781 1.317-1.165.107-.093.125-.185.12-.324a19.44 19.44 0 0 1 0-1.403c.004-.137-.007-.231-.115-.326C1.24 7.598.804 7.194.363 6.795 0 6.465-.06 6.183.117 5.725a8.967 8.967 0 0 1 1.406-2.4c.26-.322.548-.408.984-.27.559.175 1.115.355 1.671.542.138.046.237.032.364-.05.387-.25.782-.487 1.188-.702a.446.446 0 0 0 .26-.345c.107-.545.223-1.087.338-1.63.097-.462.316-.663.784-.746A7.923 7.923 0 0 1 9.888.12c.464.08.684.284.781.75.117.55.233 1.1.342 1.652.029.149.098.236.236.312.418.227.819.486 1.234.717a.45.45 0 0 0 .314.042c.554-.164 1.101-.349 1.652-.524.51-.162.747-.07 1.088.329.598.7 1.005 1.505 1.351 2.347.16.39.09.733-.224 1.02-.444.407-.887.815-1.34 1.213-.113.1-.135.2-.13.345.014.452.013.904 0 1.357-.004.135.01.23.119.326.435.383.859.777 1.287 1.166.396.36.453.665.259 1.153-.33.829-.793 1.576-1.333 2.28-.296.386-.578.483-1.046.336a116.19 116.19 0 0 1-1.67-.542.367.367 0 0 0-.345.042c-.412.251-.841.477-1.254.726a.454.454 0 0 0-.188.259c-.124.54-.232 1.085-.341 1.63-.11.537-.31.73-.843.825a7.943 7.943 0 0 1-2.8-.013c-.364-.065-.61-.262-.687-.64-.12-.59-.248-1.177-.363-1.769-.033-.17-.114-.254-.272-.333-.39-.194-.768-.416-1.136-.649-.144-.09-.256-.128-.426-.07-.538.188-1.083.357-1.626.529-.484.152-.748.064-1.066-.336a9.314 9.314 0 0 1-1.363-2.344c-.05-.12-.066-.254-.098-.383l.003.005ZM5.688 8.86c-.08 1.38.86 2.854 2.653 2.95 1.517.08 2.881-.93 2.962-2.717.062-1.371-.85-2.793-2.658-2.902-1.53-.092-2.87.986-2.957 2.67Z"/></svg>
</template>

<script>
export default {
  name: 'ToolIcon',
  props: {
    color: {
      type: String,
      default: '#B6BEC7'
    }
  }
}
</script>

<style>

</style>
