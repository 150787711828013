<template>
  <div>
    <span class="textCard">
      {{ dataText ? dataText : '' }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'BasicCard',
  props: {
    dataText: {
      type: String
    }
  },
  data () {
    return {
    }
  },
  watch: {
  }

}
</script>
<style lang='scss'>
  .textCard{
    font-family: Poppins;
    font-size: 28px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: -0.01em;
    text-align: left;

    &__subText{
      font-family: Poppins;
      font-size: 14px;
      font-weight: 600;
      line-height: 14px;
      letter-spacing: -0.01em;
      text-align: left;
    }
  }
</style>
