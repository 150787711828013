// vuex module to manage the state of the floating windows
// has a maximazed window and a group of minimized windows
// only one maximazed window is allowed
// the minimized windows are stored in an array

import { FloatingWindowType } from '@/shared/infrastructure/enums/Layout/FloatingWindowType'

export const FloatingWindowsStore = {
  namespaced: true,
  state: {
    maximazedWindow: null,
    minimizedWindows: []
  },
  mutations: {
    SET_MAXIMAZED_WINDOW (state, window: FloatingWindowType) {
      state.maximazedWindow = window
    },
    SET_MINIMIZED_WINDOWS (state, windows: Array<FloatingWindowType>) {
      state.minimizedWindows = windows
    },
    ADD_MINIMIZED_WINDOW (state, window : FloatingWindowType) {
      state.minimizedWindows.push(window)
    },
    REMOVE_MINIMIZED_WINDOW (state, index: number) {
      state.minimizedWindows.splice(index, 1)
    }
  },
  actions: {
    setMaximazedWindow ({ commit, state, dispatch }, window: FloatingWindowType) {
      if (!window) {
        commit('SET_MAXIMAZED_WINDOW', null)
        return
      }

      dispatch('removeMinimizedWindow', window)

      if (state.maximazedWindow) {
        dispatch('addMinimizedWindow', state.maximazedWindow)
        commit('SET_MAXIMAZED_WINDOW', null)
      }

      commit('SET_MAXIMAZED_WINDOW', window)
    },
    // add a new window to the minimized windows
    addMinimizedWindow ({ commit, state }, window: FloatingWindowType) {
      // fist validate if the window is already minimized
      const index = state.minimizedWindows.indexOf(window)
      if (index !== -1) {
        return
      }
      commit('ADD_MINIMIZED_WINDOW', window)
    },
    // remove a window from the minimized windows
    removeMinimizedWindow ({ commit, state }, window: FloatingWindowType) {
      const index = state.minimizedWindows.indexOf(window)
      if (index === -1) {
        return
      }
      commit('REMOVE_MINIMIZED_WINDOW', index)
    },
    // set the minimized windows
    setMinimizedWindows ({ commit }, windows: Array<FloatingWindowType>) {
      commit('SET_MINIMIZED_WINDOWS', windows)
    }
  }
}
