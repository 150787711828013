import { Inbox } from '../domain/Inbox'
import { IInboxController, addUserToInboxDTO, dataToGetInbox, defaultInboxDTO, inboxCreateDTO, inboxDeleteDTO, inboxUpdateDTO, removeUserFromInboxDTO } from '../domain/InboxController'

export default class InboxUseCases {
  static getInbox (controller: IInboxController, data: dataToGetInbox): Promise<Inbox[]> {
    return controller.getInbox(data)
  }

  static createInbox (controller: IInboxController, inbox: inboxCreateDTO): Promise<Inbox> {
    return controller.createInbox(inbox)
  }

  static updateInbox (controller: IInboxController, inbox: inboxUpdateDTO): Promise<Inbox> {
    return controller.updateInbox(inbox)
  }

  static deleteInbox (controller: IInboxController, inbox: inboxDeleteDTO): Promise<boolean> {
    return controller.deleteInbox(inbox)
  }

  static addUserToInbox (controller: IInboxController, inbox: addUserToInboxDTO): Promise<Inbox> {
    return controller.addUserToInbox(inbox)
  }

  static removeUserFromInbox (controller: IInboxController, inbox: removeUserFromInboxDTO): Promise<Inbox> {
    return controller.removeUserFromInbox(inbox)
  }

  static setDefaultInbox (controller: IInboxController, data: defaultInboxDTO): Promise<boolean> {
    return controller.setDefaultInbox(data)
  }
}
