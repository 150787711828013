








































import { mapState } from 'vuex'
import { EventBus } from '@/eventBus'
import GupshupService from '@/components/configuration/Services/GupshupService'
import KBText from '@/shared/infrastructure/ui/inputs/text/KBText.vue'
import KBPhone from '@/shared/infrastructure/ui/inputs/phone/KBPhone.vue'
import KBButton from '@/shared/infrastructure/ui/buttons/KBButton.vue'
import KBSwitch from '@/shared/infrastructure/ui/inputs/select/KBSwitch.vue'
import { CDPFieldsEnums } from '@/modules/CDP/shared/contacts/domain/enums/CDPFieldsEnums'
export default {
  name: 'GupshupFormConfig',
  props: ['config', 'edit'],
  components: {
    KBText,
    KBPhone,
    KBButton,
    KBSwitch
  },
  data () {
    return {
      loading: false,
      localConfig: {
        apiKey: '',
        appName: '',
        country: '',
        number: '',
        phone: '',
        enable: true
      },
      phone: '',
      phoneObject: {
        code: '',
        countryName: '',
        number: '',
        phone: ''
      },
      CDPFieldsEnums
    }
  },
  methods: {
    copy (data) {
      navigator.clipboard.writeText(data).then(() => {
        EventBus.$emit('toast', 'success', this.$t('copiedToClipboard'))
      }).catch(() => {
        EventBus.$emit('toast', 'error', this.$t('errorCopyingToClipboard'))
      })
    },
    updatePhone (value) {
      const phone = `+${value.number}${value.phone}`
      const number = value.phone
      this.localConfig.phone = phone
      this.localConfig.number = number
      this.localConfig.country = value.code
      this.phoneObject = value
    },
    async createGupshupInfo () {
      if (this.loading) return
      if (!this.gupshupConfigValid) {
        EventBus.$emit('toast', 'error', this.$t('mustCompleteAllFields'))
        return
      }
      this.loading = true
      const response = await GupshupService.createGupshupInfo(this.gupshupConfig)
      if (response?.status) {
        EventBus.$emit('toast', 'success', this.$t('configurationSavedCorrectly'))
        this.$emit('refresh')
      } else {
        EventBus.$emit('toast', 'error', this.$t('errorSavingConfiguration'))
      }
      this.loading = false
    },
    async updateGupshupInfo () {
      if (this.loading) return
      if (!this.gupshupConfigValid) {
        EventBus.$emit('toast', 'error', this.$t('mustCompleteAllFields'))
        return
      }
      this.loading = true
      const response = await GupshupService.updateGupshupInfo(this.gupshupConfig)
      if (response.status) {
        EventBus.$emit('toast', 'success', this.$t('configurationSavedCorrectly'))
        this.$emit('refresh')
      } else {
        EventBus.$emit('toast', 'error', this.$t('errorSavingConfiguration'))
      }
      this.loading = false
    }
  },
  computed: {
    ...mapState('AppStore', ['selectedApp']),
    ...mapState('CompanyStore', ['selectedCompany']),
    gupshupBluebirdUrl () {
      return `${process.env.VUE_APP_CONVERSATIONS_API_URL}/message/gp/company/${this.selectedCompany?.uuid}/app/${this.selectedApp?.uuid}/line/${this.config?.phone}`
    },
    gupshupConfigValid () {
      return !!this.gupshupConfig.apiKey && !!this.gupshupConfig.appName && !!this.gupshupConfig.number && !!this.gupshupConfig.phone
    },
    gupshupConfig () {
      return {
        appName: this.localConfig?.appName,
        apiKey: this.localConfig?.apiKey,
        phone: this.localConfig?.phone,
        country: this.localConfig?.country,
        number: this.localConfig?.number,
        enable: this.localConfig?.enable
      }
    }
  },
  watch: {
    config: {
      immediate: true,
      handler () {
        if (!this.config) {
          this.localConfig = {
            apiKey: '',
            appName: '',
            country: '',
            number: '',
            phone: '',
            enable: false
          }
          this.phoneObject = {
            code: '',
            countryName: '',
            number: '',
            phone: ''
          }
          this.phone = ''
          return
        }
        this.localConfig = { ...this.config }
        this.phoneObject = {
          code: this.config.country,
          number: this.config.number
        }
        this.phone = this.config.phone
      }
    }
  }
}
