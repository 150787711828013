<template>
  <svg :width="width" :height="height" viewBox="0 0 18 9" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M2.19174 5.14337C2.30127 5.26281 2.36463 5.33491 2.43121 5.4027C3.24734 6.23448 4.0624 7.06626 4.87961 7.89481C5.06216 8.07989 5.17706 8.29187 5.08686 8.5469C5.00632 8.77394 4.84202 8.93965 4.58967 8.98915C4.31583 9.04295 4.13005 8.888 3.95716 8.71584C3.25164 8.01426 2.55148 7.30837 1.84918 6.60464C1.33051 6.08491 0.809688 5.56626 0.293163 5.0433C-0.0977215 4.64839 -0.0977215 4.35356 0.293163 3.95973C1.51306 2.73411 2.73404 1.50957 3.95716 0.28719C4.29543 -0.0506875 4.56819 -0.0851209 4.87531 0.155913C5.14163 0.365741 5.19103 0.684249 4.99344 0.961868C4.93223 1.04903 4.85813 1.12866 4.78296 1.20505C3.99904 2.00563 3.21405 2.80405 2.43014 3.60355C2.36356 3.67135 2.30127 3.74344 2.19174 3.86288L2.55793 3.86288C7.41713 3.86288 12.2774 3.86288 17.1366 3.86396C17.2612 3.86396 17.3879 3.86934 17.5092 3.89516C17.84 3.96618 17.9989 4.1674 18 4.4999C18.0011 4.82163 17.8325 5.03684 17.5082 5.10571C17.3868 5.13153 17.2601 5.13907 17.1366 5.13907C12.286 5.14014 7.43539 5.14014 2.58477 5.14014L2.19174 5.14014L2.19174 5.14337Z" :fill="color"/>
  </svg>
</template>

<script>
export default {
  name: 'ArrowRightIcon',
  props: {
    width: {
      type: Number,
      default: 16
    },
    height: {
      type: Number,
      default: 18
    },
    color: {
      type: String,
      default: '#B6BEC7'
    }
  }

}
</script>

<style>

</style>
