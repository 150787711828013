import { mapState } from 'vuex'
// import { EventBus } from '@/eventBus'
// import RolesUseCases from '@/modules/roles/application/RolesUseCases'
// import RolesController from '../../../controllers/Roles.controller'
// import EmitToast from '@/utils/EmitToast'
import KeybeText from '@/modules/DesignSystem/infrastructure/components/KeybeText/KeybeText.vue'
import KeybeButton from '@/modules/DesignSystem/infrastructure/components/KeybeButton/KeybeButton.vue'
import { CDPFieldsEnums } from '@/modules/CDP/shared/contacts/domain/enums/CDPFieldsEnums'
export default {
  components: {
    KeybeText,
    KeybeButton
  },
  props: {
    role: {
      type: Object
    },
    roles: {
      type: Array
    }
  },
  computed: {
    ...mapState('AppStore', ['selectedApp']),
    isUpdate () {
      return this.role && Object.keys(this.role).length > 0
    },
    roleName () {
      return this.role?.name || ''
    },
    roleCode () {
      return this.role?.code || ''
    }
  },
  mounted () {
    // this.rolesController = new RolesController()
    if (this.isUpdate) {
      if (this.roleName) this.name = this.roleName
      if (this.roleCode) this.code = this.roleCode
    }
  },
  data () {
    return {
      name: '',
      loading: false,
      code: '',
      rolesController: null,
      CDPFieldsEnums
    }
  },
  methods: {
    submit () {
      if (this.isUpdate) this.updateRole()
      else this.createRole()
    },
    nameExists () {
      if (this.isUpdate) {
        const found = this.roles.find(role => role.name === this.name)
        if (found && found?._id === this.role?._id) return false
        else if (found && found?._id !== this.role?._id) return true
        else if (!found) return false
      } else return this.roles.find(role => role.name === this.name)
    }
    // async createRole () {
    //   try {
    //     this.loading = true
    //     if (this.nameExists()) {
    //       EventBus.$emit('toast', 'error', this.$t('aRolWithNameExists'))
    //       this.loading = false
    //       return
    //     }
    //     if (!this.name || !this.code) {
    //       EventBus.$emit('toast', 'error', this.$t('mustCompleteAllFields'))
    //       this.loading = false
    //       return
    //     }
    //     const variables = {
    //       app: this.selectedApp?.uuid,
    //       createRoleInput: {
    //         name: this.name,
    //         code: this.code
    //       },
    //       token: ''
    //     }
    //     const response = await RolesUseCases.createRole(this.rolesController, variables)
    //     if (response?.status) this.$emit('close')
    //     else EmitToast.emitErrors(response)
    //     this.loading = false
    //   } catch (error) {
    //     this.loading = false
    //     this.$emit('close')
    //     console.error(error)
    //   }
    // },
    // async updateRole () {
    //   try {
    //     this.loading = true
    //     if (this.nameExists()) {
    //       EventBus.$emit('toast', 'error', this.$t('aRolWithNameExists'))
    //       this.loading = false
    //       return
    //     }
    //     if (!this.name || !this.code) {
    //       EventBus.$emit('toast', 'error', this.$t('mustCompleteAllFields'))
    //       this.loading = false
    //       return
    //     }
    //     const variables = {
    //       app: this.selectedApp?.uuid,
    //       updateItemInput: {
    //         id: this.role?._id || '',
    //         name: this.name,
    //         code: this.code
    //       },
    //       token: ''
    //     }
    //     const response = await RolesUseCases.updateRole(this.rolesController, variables)
    //     if (response?.status) this.$emit('close')
    //     else EmitToast.emitErrors(response)
    //     this.loading = false
    //   } catch (error) {
    //     this.loading = false
    //     this.$emit('close')
    //     console.error(error)
    //   }
    // }
  },
  watch: {
    roleName () {
      if (this.roleName) this.name = this.roleName
    },
    roleCode () {
      this.code = this.roleCode
    }
  }
}
