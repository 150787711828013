export enum CurrencyEnum {
  USD = 'USD',
  MXN = 'MXN',
  CAD = 'CAD',
  EUR = 'EUR',
  COP = 'COP',
  DOP = 'DOP',
  ARS = 'ARS',
  PEN = 'PEN',
  CRC = 'CRC',
}

export enum AmountEnum {
  MIN_USD = 10,
  MIN_COP = 55000,
  TRANSACTION_PAY = 4,
}

export const currencyType = [
  { code: 'USD', name: 'USD' },
  { code: 'MXN', name: 'MXN' },
  { code: 'CAD', name: 'CAD' },
  { code: 'EUR', name: 'EUR' },
  { code: 'COP', name: 'COP' },
  { code: 'DOP', name: 'DOP' },
  { code: 'ARS', name: 'ARS' },
  { code: 'PEN', name: 'PEN' },
  { code: 'CRC', name: 'CRC' }
]
