import KeybeRepository from '@/shared/infrastructure/repositories/KeybeRepository'
import { HeadersBuilder } from '@/shared/infrastructure/http/HeadersBuilder'
import { getOptions, nonGetOptions } from '@/shared/infrastructure/http/HttpClient'
import { dataToGetFunnelByName, dataToGetUtms } from '../../domain/FunnelsController'
import { GenericResponse } from '@/shared/infrastructure/http/GenericResponse'
// import operations from '@/utils/operations'

export default class FunnelsRepository extends KeybeRepository {
  getSuggestionsAbortController: AbortController
  getFunnelsAbortController: AbortController
  getUtmsAbortController: AbortController

  constructor () {
    super()

    this.getSuggestionsAbortController = new AbortController()
    this.getFunnelsAbortController = new AbortController()
    this.getUtmsAbortController = new AbortController()
  }

  async getFunnels (data) {
    const { app /* person, business, responsible */ } = data
    const url = `${process.env.VUE_APP_FUNNELS_HTTP}/${app}/funnel`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    // const filters = { person, business, responsible }
    // const formattedFilters = operations.transformObject(filters, 'filters')

    // const query = { ...formattedFilters }
    const requestOptions: getOptions = {
      headers,
      // query,
      url
    }

    return await this.client.get(requestOptions)
  }

  async createFunnel (data) {
    const { app, createInput } = data
    const url = `${process.env.VUE_APP_FUNNELS_HTTP}/${app}/funnel`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    const requestOptions: nonGetOptions = {
      headers,
      data: createInput,
      url,
      abortController: this.getSuggestionsAbortController
    }

    return await this.client.post(requestOptions)
  }

  async updateFunnel (data) {
    const { app, id, updateInput } = data
    const url = `${process.env.VUE_APP_FUNNELS_HTTP}/${app}/funnel/${id}`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    const requestOptions: nonGetOptions = {
      headers,
      data: updateInput,
      url,
      abortController: this.getSuggestionsAbortController
    }

    return await this.client.patch(requestOptions)
  }

  async deleteFunnel (data) {
    const { app, id } = data
    const url = `${process.env.VUE_APP_FUNNELS_HTTP}/${app}/funnel/${id}`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    const requestOptions: nonGetOptions = {
      headers,
      url,
      abortController: this.getSuggestionsAbortController
    }

    return await this.client.delete(requestOptions)
  }

  async getFunnelByName (data: dataToGetFunnelByName): Promise<GenericResponse> {
    const { app, name } = data
    const url = `${process.env.VUE_APP_FUNNELS_HTTP}/${app}/funnel/name/${name}`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    const requestOptions: getOptions = {
      headers,
      url,
      abortController: this.getSuggestionsAbortController
    }

    return await this.client.get(requestOptions)
  }

  async getUtms (data: dataToGetUtms): Promise<GenericResponse> {
    const { app, group } = data.data

    const url = `${process.env.VUE_APP_FUNNELS_HTTP}/utms/${app}/${group}`

    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    const requestOptions: getOptions = {
      headers,
      url,
      query: data.data
    }

    return await this.client.get(requestOptions)
  }

  abortGetFunnels () {
    this.getFunnelsAbortController.abort()
    this.getFunnelsAbortController = new AbortController()
  }

  abortGetUtms () {
    this.getUtmsAbortController.abort()
    this.getUtmsAbortController = new AbortController()
  }

  abortAll () {
    this.abortGetFunnels()
    this.abortGetUtms()
    // TODO: ABORT REST
  }
}
