export enum AdminTypes {
  SUPER_ADMIN = 'superadmin',
  ADMIN = 'admin',
  TOKEN = 'token',
  PARTNER = 'partner',
  COORDINATOR = 'coordinator',
  AGENT = 'agent',
  CUSTOM = 'custom',
  TRAINER = 'trainer',
  OWNER = 'owner',
}
