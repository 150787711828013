


























































import BikyConfigUrl from './BikyConfigUrl'

export default BikyConfigUrl
