import { render, staticRenderFns } from "./BikyConfigSkills.vue?vue&type=template&id=912aaa92&scoped=true&"
import script from "./BikyConfigSkills.vue?vue&type=script&lang=ts&"
export * from "./BikyConfigSkills.vue?vue&type=script&lang=ts&"
import style0 from "./BikyConfigSkills.vue?vue&type=style&index=0&id=912aaa92&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "912aaa92",
  null
  
)

export default component.exports