import { mapState, mapGetters } from 'vuex'
import { EventBus } from '@/eventBus'
import VariationController from '../../controllers/Variation.controller'
import VariationUseCases from '@/modules/catalog/application/VariationUseCases'

// components
import KBButton from '@/shared/infrastructure/ui/buttons/KBButton.vue'
import KeybeText from '@/modules/DesignSystem/infrastructure/components/KeybeText/KeybeText.vue'
import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'
import KeybeSelect from '@/modules/DesignSystem/infrastructure/components/KeybeSelect/KeybeSelect/KeybeSelect.vue'
import { ActivityActionTypeEnum, ActivityLogModuleTypeEnum, dataToWriteActivityLog } from '@/modules/Config/ActivityLogs/domain/ActivityLogsController'
import { CDPFieldsEnums } from '@/modules/CDP/shared/contacts/domain/enums/CDPFieldsEnums'
import ActivityLogsUseCases from '@/modules/Config/ActivityLogs/application/ActivityLogsUseCases'
import ActivityLogsController from '@/modules/Config/ActivityLogs/infrastructure/controllers/ActivityLogs.controller'
import { Product } from '@/modules/catalog/domain/models/Product'
import { formatCurrency } from '@/utils/currencyFormatter'

export default {
  name: 'ProductVariationsConfig',
  components: {
    KBButton,
    KeybeText,
    KeybeIcon,
    KeybeSelect
  },
  props: {
    product: {
      type: Object
    },
    value: {
      type: Array,
      required: true
    }
  },
  computed: {
    ...mapState('AppStore', ['selectedApp', 'getCurrencyApp']),
    ...mapState('UserStore', ['token', 'user']),
    ...mapGetters('AppStore', ['getCurrencyApp'])
  },
  mounted () {
    this.variationController = new VariationController()
    this.getVariationOptions()
    this.getVariationOptionsCreated()
    EventBus.$on('createVariations', (product: Product) => { this.createVariations(product) })
    EventBus.$on('updateVariations', () => { this.updateVariations() })

    this.productVariations = this.value
    this.currency = this.getCurrencyApp
  },
  data () {
    return {
      variations: [],
      variationsCreated: [],
      variationsCreatedDetail: [],
      renderKey: 0,
      openCreateVariation: null,
      productVariations: [],
      item: '',
      variationController: null,
      activityLogsController: new ActivityLogsController(),
      currency: null
    }
  },
  methods: {
    formatCurrency,
    setVariation (variation, index) {
      if (variation === this.$t('createVariation')) this.openCreateVariation = index
      else {
        this.productVariations[index].label = variation
      }
    },
    setVariationItem (item, index, label) {
      if (item) {
        this.item = ''
        this.renderKey++
        if (this.productVariations[index]) {
          const exists = this.productVariations[index].items.filter(val => val === item)
          if (exists.length === 0) this.productVariations[index].items.push(item)
          else EventBus.$emit('toast', 'error', this.$t('variationValueExists'))
        }
        if (!this.variations.includes(label)) {
          this.createVariationOption(label)
          this.variations.push(label)
        }
      }
    },
    removeVariation (index) {
      if (this.productVariations[index]?.items?.length > 0) this.productVariations[index].items = []
      if (this.productVariations[index]?.label) this.productVariations[index].label = ''
      this.productVariations.splice(index, 1)
    },
    removeVariationItem (itemI, variationI) {
      if (this.productVariations[variationI] && this.productVariations[variationI].items.length > 0) {
        this.productVariations[variationI].items.splice(itemI, 1)
      }
      this.renderKey++
    },
    addVariation () {
      if (this.productVariations.length < 3) this.productVariations.push({ label: '', items: [] })
    },
    async createVariationOption (name) {
      await VariationUseCases.addVariation(this.variationController, {
        variation: {
          appUUID: this.selectedApp?.uuid,
          option: name
        },
        token: this.token
      })
      this.writeActivityLog(name)
    },
    async writeActivityLog (variation: string): Promise<void> {
      const data: dataToWriteActivityLog = {
        token: this.token,
        updateInput: {
          appUUID: this.selectedApp?.uuid,
          module: ActivityLogModuleTypeEnum.CHAT,
          action: ActivityActionTypeEnum.CATALOG_VARIATION_CREATE,
          user: {
            id: this.user.uuid,
            name: this.user.name + ' ' + this.user.lastName
          },
          payload: {
            field: CDPFieldsEnums.NAME,
            newValue: variation,
            oldValue: ''
          }
        }
      }
      await ActivityLogsUseCases.writeActivityLog(this.activityLogsController, data)
    },
    async getVariationOptions () {
      try {
        this.variations = []
        const variations = await VariationUseCases.getVariations(this.variationController, { appUUID: this.selectedApp?.uuid, token: this.token })

        variations.forEach(item => {
          this.variations.push(item?.option)
        })

        this.variations.unshift(this.$t('createVariation'))
        if (this.productVariations.length === 0) this.productVariations = [{ label: '', items: [] }]
        this.renderKey++
      } catch (e) {
        console.error(e)
      }
    },
    async getVariationOptionsCreated () {
      try {
        this.variationsCreated = []
        this.variationsCreated = await VariationUseCases.getVariationsCreated(this.variationController, { appUUID: this.selectedApp?.uuid, token: this.token, productId: this.product.id })
        this.variationsCreatedDetail = this.variationsCreated[0]
        this.renderKey++
      } catch (e) {
        console.error(e)
      }
    },
    async createVariations (product: Product) {
      try {
        const options = {}
        for (let i = 0; i < this.productVariations.length; i++) {
          if (this.productVariations[i]?.label) {
            if (i === 0) {
              // @ts-ignore
              options.option1 = this.productVariations[i]
            } else if (i === 1) {
              // @ts-ignore
              options.option2 = this.productVariations[i]
            } else {
              // @ts-ignore
              options.option3 = this.productVariations[i]
            }
          }
        }

        const data = {
          productId: product?.id || '',
          ...options,
          subProducts: [product],
          appUUID: this.selectedApp?.uuid
        }
        await VariationUseCases.addVariationToProduct(this.variationController, { token: this.token, data })
        this.close()
      } catch (e) {
        console.error(e)
      }
    },
    async updateVariations () {
      try {
        const options = {
          option1: {},
          option2: {},
          option3: {}
        }
        for (let i = 0; i < this.productVariations.length; i++) {
          if (this.productVariations[i]?.label) {
            if (i === 0) {
              // @ts-ignore
              options.option1 = this.productVariations[i]
            } else if (i === 1) {
              // @ts-ignore
              options.option2 = this.productVariations[i]
            } else {
              // @ts-ignore
              options.option3 = this.productVariations[i]
            }
          }
        }
        const data = {
          productId: this.product.id,
          ...options,
          subProducts: [this.product],
          appUUID: this.selectedApp?.uuid
        }
        await VariationUseCases.updateVariationInProduct(this.variationController, { token: this.token, data })
        this.close()
      } catch (e) {
        console.error(e)
      }
    },
    close () {
      this.variations = []
      this.renderKey = 0
      this.openCreateVariation = null
      this.productVariations = []
      this.item = ''
      this.$emit('close')
    }
  },
  destroyed () {
    EventBus.$off('createVariations')
    EventBus.$off('updateVariations')
  },
  watch: {
    productVariations: {
      handler (val) {
        this.$emit('input', val)
      }
    }
  }
}
