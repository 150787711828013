import { Component } from '@/modules/createTemplates/domain/Template'
import KeybeButton from '@/modules/DesignSystem/infrastructure/components/KeybeButton/KeybeButton.vue'
import { TemplateButton, TemplateButtonTypesEnum, TemplateComponentsEnum } from '@/modules/templates/domain/Template'
export default {
  components: {
    KeybeButton
  },
  props: {
    message: {
      type: Object
    },
    cards: {
      type: Array
    },
    isPreview: {
      type: Boolean,
      default: false
    },
    template: {
      type: Object
    }
  },
  computed: {
    messageComponents (): Component {
      if (this.isPreview) {
        const components = this.template?.components || ''
        const parsedComponents = components.length ? JSON.parse(components) : []
        return parsedComponents
      }
      const components: string = this.message.typeOptions?.templateData?.template?.components || ''
      const parsedComponents = components.length ? JSON.parse(components) : []
      return parsedComponents
    },
    carrouselCards (): Component[] {
      const foundCarrousel = this.messageComponents.find((component: any) => component.type === TemplateComponentsEnum.CAROUSEL)
      return foundCarrousel ? foundCarrousel.cards : []
    }
  },
  methods: {
    cardMessage (index: number, card: any): string {
      const foundBody = this.carrouselCards[index]?.components?.find((component: any) => component.type === TemplateComponentsEnum.BODY)
      let text = foundBody?.text || ''
      const bodyParams = card?.bodyCardParams || []
      bodyParams.forEach((param: string, index: number) => {
        text = text.replace(`{{${index + 1}}}`, param)
      })
      return text
    },
    cardImage (card: any): string {
      return card?.headerCardParams[0] || ''
    },
    cardButtons (index: number): TemplateButton[] {
      const buttons = this.carrouselCards[index]?.components?.find((component: any) => component.type === 'BUTTONS')?.buttons || []
      return buttons
    },
    openLink (button): void {
      if (button.type === TemplateButtonTypesEnum.URL) {
        window.open(button.url, '_blank')
      }
    }
  }
}
