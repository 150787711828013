import { EventBus } from '@/eventBus'
import { mapState } from 'vuex'
import KBSearch from '@/shared/infrastructure/ui/inputs/text/KBSearch.vue'

import AppsUseCases from '@/modules/Apps/application/AppsUseCases'
import AppsController from '@/modules/Apps/infrastructure/controllers/Apps.controller'
import { EntityTypeEnum } from '@/modules/CDP/shared/contacts/domain/Contacts'
import LoadingNext from '@/modules/metrics/infrastructure/ui/components/generic/LoadingNext.vue'
import { primaries, demographics, transactional, brand, personality, habeasData } from '@/modules/templates/domain/CustomParams'
import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'
import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import { CDPFieldsEnums } from '@/modules/CDP/shared/contacts/domain/enums/CDPFieldsEnums'

export default {
  name: 'ParamsSelector',
  components: {
    LoadingNext,
    KBSearch,
    KeybeIcon
  },
  props: {
    templates: {
      type: Array,
      default: () => []
    },
    params: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      seeMore: false,
      search: '',
      loading: true,
      customExtradataFields: [],
      extradataFields: [],
      appsController: new AppsController(),
      typeEntity: EntityTypeEnum.PEOPLE,
      extendedFields: []
    }
  },
  created () {
    this.customizationItems()
    this.getExtendedFields()
  },
  watch: {
    search () {
      if (this.search) {
        this.seeMore = true
      } else {
        this.seeMore = false
      }
    }
  },
  computed: {
    ...mapState('AppStore', ['selectedApp']),
    ...mapState('UserStore', ['token']),
    category () {
      return this.selectedApp?.categories.toString()
    },
    iconColor () {
      return this.$vuetify.theme.dark ? 'white' : 'black'
    },
    paramsFiltered () {
      if (!this.search) {
        return this.params.length ? this.params : this.customExtradataFields
      } else {
        if (this.params.length) {
          return this.params.filter(params => {
            return params.label.toLowerCase().includes(this.search.toLowerCase())
          })
        }
        return this.customExtradataFields.filter(params => {
          return params.label.toLowerCase().includes(this.search.toLowerCase())
        })
      }
    },
    paramsExtraFiltered () {
      if (!this.search) {
        return this.extradataFields
      }
      const searchLower = this.search.toLowerCase()

      return this.extradataFields.map(group => {
        const filteredItems = group.items.filter(item => this.$t(item.label).toLowerCase().includes(searchLower))
        return {
          ...group,
          items: filteredItems,
          active: filteredItems.length > 0
        }
      }).filter(group => group.active)
    }
  },
  methods: {
    concatData () {
      const vue = this
      // Eliminar elementos que tienen "children"
      const filteredArray2 = this.extendedFields.filter(parent => !parent.children)
      // Procesar los elementos "children" y crear elementos adicionales
      const processedArray2 = []
      this.extendedFields.forEach(parent => {
        if (parent.children) {
          parent.children.forEach(child => {
            processedArray2.push({
              label: vue.$t(child.field),
              value: `{{${parent.field}.${child.field}}}`
            })
          })
        }
      })
      // Concatenar y transformar los arrays
      const concatenatedArray = filteredArray2.map(item => (
        {
          label: vue.$t(item.field),
          value: `{{${item.field}}}`
        }
      )).concat(this.customExtradataFields, processedArray2)
      this.customExtradataFields = concatenatedArray
    },
    async getExtendedFields () {
      try {
        const data = {
          module: 'cdp',
          typeEntity: this.typeEntity,
          category: this.category,
          token: this.token
        }
        const response = await AppsUseCases.getExtendedFields(this.appsController, data)
        if (response?.status) {
          this.extendedFields = response?.data[0]?.fields
          this.concatData()
          this.loading = false
        }
      } catch (e) {
        this.loading = false
      }
    },
    selectParams (customizedField) {
      if (!customizedField) {
        EventBus.$emit('toast', 'error', this.$t('mustSelectATemplate'))
        return
      }
      this.search = ''
      this.$emit('selectParams', customizedField)
    },
    customizationItems () {
      const nameValue = this.$route.name === 'CreateTrigger' || this.$route.name === 'EditTrigger' ? '{{NAME}}' : '{{name}}'
      if (this.customExtradata &&
          Array.isArray(this.customExtradata) &&
          this.customExtradata.length) {
        this.customExtradataFields = this.customExtradata.map(extraData => {
          if (typeof extraData.name === 'string' && extraData.name.split(' ').length > 1) {
            return { label: extraData.name, value: `{{[${extraData.name}]}}` }
          } else {
            return { label: extraData.name, value: `{{${extraData.name}}}` }
          }
        })
      }
      this.customExtradataFields.unshift(...[
        { label: this.$t(CDPFieldsEnums.NAME), value: nameValue },
        { label: this.$t(CDPFieldsEnums.PHONE), value: '{{phone}}' },
        { label: this.$t('email'), value: '{{email}}' },
        { label: this.$t('unsubscribeLink'), value: '{{UNSUBSCRIBE}}' }
      ])
      this.extradataFields.unshift(...[
        { label: this.$t('primaries'), items: primaries, active: false, icon: KeybeIconType.PRIMARY_DETAILS },
        { label: this.$t('demographics'), items: demographics, active: false, icon: KeybeIconType.DEMOGRAPHIC_DETAILS },
        { label: this.$t(CDPFieldsEnums.BRAND), items: brand, active: false, icon: KeybeIconType.BRAND_DETAILS },
        { label: this.$t('transactional'), items: transactional, active: false, icon: KeybeIconType.TRANSACTIONAL_DETAILS },
        { label: this.$t(CDPFieldsEnums.PERSONALITY), items: personality, active: false, icon: KeybeIconType.PERSONALITY_DETAILS },
        { label: this.$t('habeasData'), items: habeasData, active: false, icon: KeybeIconType.HABEAS_DETAILS }
      ])
    }
  }
}
