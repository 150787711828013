import { EventBus } from '@/eventBus'
import { EntityTypeEnum } from '@/modules/CDP/shared/contacts/domain/Contacts'
import { ImportsTabsEnum } from '@/modules/imports/domain/enums/ImportsTabsEnum'
import Importer from '@/modules/imports/infrastructure/components/Importer/ImporterContainer/ImporterContainer.vue'
import ImportsList from '@/modules/imports/infrastructure/components/ImportsList/ImportsList.vue'
import ImportDetail from '@/modules/imports/infrastructure/components/ImportDetail/ImportDetail.vue'
import ImportsSidebar from '@/modules/imports/infrastructure/components/ImportsSidebar/ImportsSidebar.vue'
export default {
  name: 'ImportsScreen',
  components: {
    ImportsSidebar
  },
  mounted () {
    EventBus.$on('selectImportsTab', (entity: string, tab: string) => {
      this.typeEntity = entity
      if (tab) this.selectedTab = tab
    })
  },
  computed: {
    selectedTabComponent () {
      if (this.selectedTab === ImportsTabsEnum.IMPORTS_NEW) return Importer
      else if (
        (this.selectedTab === ImportsTabsEnum.IMPORTS_PEOPLE ||
        this.selectedTab === ImportsTabsEnum.IMPORTS_BUSINESS) &&
        !this.showDetails
      ) return ImportsList
      else return ImportDetail
    }
  },
  data () {
    return {
      typeEntity: EntityTypeEnum.PEOPLE,
      selectedTab: ImportsTabsEnum.IMPORTS_NEW,
      showDetails: false,
      loading: false,
      selectedImport: {}
    }
  },
  methods: {
    selectTab (e) {
      this.selectedTab = e
      if (e === ImportsTabsEnum.IMPORTS_PEOPLE) this.typeEntity = EntityTypeEnum.PEOPLE
      else this.typeEntity = EntityTypeEnum.BUSINESS
    },
    openDetails (item) {
      this.selectedImport = item
      this.showDetails = true
    },
    closeDetails () {
      this.selectedImport = {}
      this.showDetails = false
    }
  },
  destroyed () {
    EventBus.$off('selectImportsTab')
  }
}
