export interface VecindarioConfig {
  _id: string;
  appUUID: string;
  vecindarioId: string;
  vecindarioSlug: string;
  vecindarioTitle: string;
  active: boolean;
  createdAt?: Date;
  updatedAt?: Date;
}

export interface UpdateVecindarioConfigDto {
  _id: string;
  active?: boolean;
  vecindarioId?: string;
  vecindarioSlug?: string;
  vecindarioTitle?: string;
}

export enum VecindarioCurrencies {
  COP = 'COP',
  ARS = 'ARS',
  CLP = 'CLP',
  MXN = 'MXN',
  BRL = 'BRL',
  USD = 'USD',
  EUR = 'EUR',
  DOP = 'DOP',
  PAB = 'PAB',
  GTQ = 'GTQ',
}

export type VecindarioCurrency = VecindarioCurrencies.ARS | VecindarioCurrencies.BRL | VecindarioCurrencies.CLP | VecindarioCurrencies.COP | VecindarioCurrencies.DOP | VecindarioCurrencies.EUR | VecindarioCurrencies.GTQ | VecindarioCurrencies.MXN | VecindarioCurrencies.PAB | VecindarioCurrencies.USD;

export enum VecindarioLanguages {
  ES = 'es',
  EN = 'en',
}

export type VecindarioLanguage = VecindarioLanguages.ES | VecindarioLanguages.EN;

export interface CreateVecindarioProjectDto {
  title: string;
  language: VecindarioLanguage;
  currency: VecindarioCurrency;
  directorMail: string;
  lat: string;
  lng: string;
  appUUID: string;
}

export enum VecindarioRoles {
  LIDER = 'lider',
  ASESOR = 'asesor',
}

export type VecindarioRole = VecindarioRoles.LIDER | VecindarioRoles.ASESOR;

export interface CreateVecindarioUserDto {
  role: VecindarioRole;
  email: string;
  modules: string[];
  name: string;
  lastName: string;
  phone: string;
  slug: string;
  appUUID: string;
  uuid: string;
}

export interface CreateVecindarioConfigDto {
  title: string;
  language: VecindarioLanguage;
  currency: VecindarioCurrency;
  lat?: string;
  lng?: string;
  address?: string;
  appUUID: string;
}
