export enum BikyConfigTabs {
  BASIC = 'basic',
  INFO = 'info',
  KNOWLEDGE_BASE = 'knowledge-base',
  CHAT = 'chat',
  ERRORS = 'errors',
  SMARTOMETER = 'smartometer',
  PERSONALITY = 'personality',
  APPEARANCE = 'appearance',
  SKILLS = 'skills',
  SCHEDULING = 'scheduling',
}
