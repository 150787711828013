// currencyFormatter.js

import { CurrencyEnum } from '@/components/configuration/Billing/CurrencyEnum'

/**
 * Función para formatear monedas según el tipo especificado.
 * @param {number} amount - Cantidad a formatear.
 * @param {string} currencyCode - Código de la moneda (USD, MXN, etc.).
 * @returns {string} Monto formateado según la moneda.
 */

export function formatCurrency (amount: number, currencyCode: CurrencyEnum | string) {
  let options
  switch (currencyCode) {
    case CurrencyEnum.USD:
    case CurrencyEnum.MXN:
    case CurrencyEnum.CAD:
    case CurrencyEnum.ARS:
    case CurrencyEnum.PEN:
    case CurrencyEnum.CRC:
      break
    case CurrencyEnum.DOP:
      options = {
        style: 'currency',
        currency: currencyCode,
        currencyDisplay: 'narrowSymbol' // Usa símbolos más cortos para la moneda
      }
      break
    case CurrencyEnum.EUR:
      options = {
        style: 'currency',
        currency: currencyCode,
        currencyDisplay: 'symbol' // Muestra el símbolo de la moneda
      }
      break
    case CurrencyEnum.COP:
      options = {
        style: 'currency', // Cambiado de 'decimal' a 'currency' para incluir el símbolo de la moneda
        currency: 'COP',
        currencyDisplay: 'narrowSymbol',
        minimumFractionDigits: 0, // No se muestran los decimales
        maximumFractionDigits: 0
      }
      break
    default:
      throw new Error('Unsupported currency code')
  }
  if (currencyCode === CurrencyEnum.COP) return `COP ${new Intl.NumberFormat('es-CO', options).format(amount)}`
  else if (currencyCode === CurrencyEnum.CRC) return `₡ ${new Intl.NumberFormat('es-CO', options).format(amount)}`
  return `${currencyCode} ${new Intl.NumberFormat('en-US', options).format(amount)}`
}
