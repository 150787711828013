







export default {
  name: 'RetryIcon',
  props: {
    color: {
      type: String,
      default: '#F7685B'
    }
  }

}
