import CampaignsService from '@/services/CampaignsService'
import moment from 'moment'
import { EventBus } from '@/eventBus'
import { textCamelize } from '@/utils/utils'
import Colors from '@/utils/Colors'
import { ClientsDashboardController } from '@/components/dashboard/controllers/ClientsDashboardController'
import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import TitleDetail from '@/modules/metrics/infrastructure/ui/components/generic/TitleDetail.vue'
import '@/assets/css/countries.scss'

import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'
import Loader from '@/shared/infrastructure/ui/Loader.vue'
import CustomPagination from '@/components/shared/components/CustomPagination.vue'
import KBButton from '@/shared/infrastructure/ui/buttons/KBButton.vue'
import countries from '@/utils/countries'
import { CDPFieldsEnums } from '@/modules/CDP/shared/contacts/domain/enums/CDPFieldsEnums'

export default {
  name: 'segmentsMetrics',
  components: {
    TitleDetail,
    KeybeIcon,
    Loader,
    CustomPagination,
    KBButton
  },
  data () {
    return {
      countries,
      KeybeIconType,
      loading: false,
      loadingExport2Segment: false,
      itemData: [],
      selectedResponseStatusFilter: '',
      totalItemsTable: null,
      headers: [
        { text: this.$t(CDPFieldsEnums.NAME), field: CDPFieldsEnums.NAME },
        { text: this.$t('cellphone'), field: CDPFieldsEnums.PHONE },
        { text: this.$t('email'), field: CDPFieldsEnums.EMAIL },
        { text: this.$t('status'), field: CDPFieldsEnums.STATUS }
      ],
      pageNumber: 1,
      itemsPerPage: 25,
      count: 1,
      arrayStatus: ['pending', 'queued', 'sent', 'rejected', 'errored', 'canceled'],
      arrayReponseStatus: [
        'none',
        'onDelivery', 'rejected', 'sent', 'delivered', // all channels handle these statuses
        'deferral', 'bounce', 'hard_bounce', 'soft_bounce', 'open', 'click', 'spam', 'unsub', 'blacklist', 'whitelist', 'unknown', 'queued', 'invalid', 'delayed', // only for email
        'sending', 'read', 'responsed' // wpp
      ],
      Colors
    }
  },
  mounted () {
    this.getCampaignUsers()
  },
  computed: {
    summaryData: {
      get () {
        return this.$store.state.CampaingsStore.summaryData || null
      },
      set (value) {
        this.$store.commit('CampaingsStore/SET_EDITING_OBJECT', {
          key: 'summaryData',
          value
        })
      }
    },
    campaignUsersComputed () {
      const campaignUsers = []
      this.itemData &&
        this.itemData.map((user, i) => {
          let userStatus = this.$t('pending')
          if (user?.status === 'sending') userStatus = this.$t('sending')
          if (user?.status === 'sent') userStatus = this.$t('sent')
          if (user?.status === 'canceled') userStatus = this.$t('canceled')
          if (user?.responseStatus === 'received') {
            userStatus = this.$t('campaignDelivered')
          }
          if (user?.responseStatus === 'rejected') {
            userStatus = this.$t('rejected')
          }
          try {
            if (user?.info && user?.info.message) {
              if (user?.type === 'whatsApp') {
                user.info.parameters.forEach((param) => {
                  user.message =
                    user.info.message.replace(/{{(.+?)}}/g, '') + param
                })
              } else if (user?.type === 'sms') {
                user.message = user.info.message
              }
            } else if (
              user?.info &&
              user?.info.mailData &&
              user?.info.mailData.info.html
            ) {
              user.message = user.info.mailData.info.html
            }
            user.phoneNumber = user.phone?.number
            user.countryCode = user.phone?.countryCode
          } catch (e) {
            console.log(e.message)
          }
          const countryInfo = countries.filter(c => c.number.toLowerCase() === user.countryCode)[0]
          campaignUsers.push({
            name: user.name
              ? user?.name + ' ' + user?.lastName
              : this.$t('unavailable'),
            phone: user?.phone?.number
              ? user?.phone.number
              : this.$t('unavailable'),
            countryCode: user?.phone?.countryCode
              ? user?.phone.countryCode
              : '-',
            country: countryInfo?.code
              ? countryInfo.code
              : '-',
            email: user.email ? user?.email : this.$t('unavailable'),
            status: userStatus,
            codeStatus: user.status,
            rejectedReason: user?.rejectedReason ? user?.rejectedReason : '',
            type: user?.type,
            message: user?.message ? user?.message : '',
            index: i
          })
        })
      return campaignUsers
    },
    responseOptions () {
      const responseOptions = [
        // {text: '', value: 'sent'},
        { text: 'Pendiente', value: 'pending', disabled: false },
        { text: 'Rechazado', value: 'rejected', disabled: false }
      ]
      if (this.summaryData.status === 'sent') {
        if (this.summaryData.type === 'whatsApp') {
          responseOptions.push(
            { text: 'Entregado (✓✓)', value: 'delivered', disabled: false },
            { text: 'Visto', value: 'read', disabled: false },
            { text: 'Responde', value: 'responsed', disabled: false }

          )
        }
        if (this.summaryData.type === 'email') {
          responseOptions.push(
            { text: 'Entregado', value: 'delivered', disabled: false },
            { text: 'Abierto', value: 'opened', disabled: false },
            { text: 'Hicieron clic en el contenido', value: 'clicked', disabled: false }
          )
        }
        if (this.summaryData.type === 'sms') {
          responseOptions.push(
            { text: 'Enviado', value: 'sent', disabled: false }
          )
        }
      }
      return responseOptions
    }
  },
  methods: {
    setPagination (pagination) {
      this.pageNumber = pagination.page
      this.itemsPerPage = pagination.items
      this.getCampaignUsers()
    },
    statusClass (status: string): string {
      if (status === this.$t('sent')) return 'textKBGreen'
      if (status === this.$t('sending')) return 'textKBBlue'
      if (status === this.$t('pending')) return 'textKBYellow'
      if (status === this.$t('rejected')) return 'textKBRed'
      if (status === this.$t('canceled')) return 'textKBRed'

      return 'textKBYellow'
    },
    async exportCampaign () {
      try {
        await CampaignsService.exportCampaign({
          campaignId: this.summaryData.id ? this.summaryData.id : this.summaryData._id,
          filename: this.name
        })
        EventBus.$emit(
          'toast',
          'success',
          this.$t('successfullCampaignExport')
        )
      } catch {
        console.error('Error al exportar campaña')
        EventBus.$emit(
          'toast',
          'error',
          this.$t('unsuccesfullCampaignExport')
        )
      }
    },
    async getCampaignUsers () {
      this.loading = true

      let status, responseStatus
      if (this.arrayStatus.indexOf(this.selectedResponseStatusFilter) > -1) {
        status = this.selectedResponseStatusFilter
      }
      if (this.arrayReponseStatus.indexOf(this.selectedResponseStatusFilter) > -1) {
        responseStatus = this.selectedResponseStatusFilter
      }
      const pagination = {
        itemsPerPage: this.itemsPerPage,
        page: this.pageNumber,
        search: '',
        status,
        responseStatus
      }
      try {
        const response = await CampaignsService.getCampaignUsersV6(
          this.summaryData.id ? this.summaryData.id : this.summaryData._id,
          pagination
        )
        this.totalItemsTable = response?.campaignsUsers?.count
        this.itemData = response?.campaignsUsers?.rows
        this.loading = false
      } catch (e) {
        console.error(e)
      }
    },
    async getAdvisor () {
      this.loading = true
      const params = {
        endReason: null,
        advisorId: null,
        startDate: moment().subtract(1, 'weeks').format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD')
      }
      const response =
        await ClientsDashboardController.getAdvisorsPerformanceDetails(params)
      if (response) {
        this.itemData = response
        this.loading = false
      } else {
        this.loading = false
        if (response?.data?.error) {
          EventBus.$emit(
            'toast',
            'error',
            this.$t(textCamelize(response.data.error))
          )
        } else {
          EventBus.$emit('toast', 'error', this.$t('unexpectedError'))
        }
      }
    },
    async exportFilterToSegment () {
      try {
        if (!this.selectedResponseStatusFilter) throw new Error('No filter is selected')

        this.loadingExport2Segment = true
        let status, responseStatus
        if (this.arrayStatus.indexOf(this.selectedResponseStatusFilter) > -1) {
          status = this.selectedResponseStatusFilter
        }
        if (this.arrayReponseStatus.indexOf(this.selectedResponseStatusFilter) > -1) {
          responseStatus = this.selectedResponseStatusFilter
        }
        const response = await CampaignsService.createSegmentFromFilterV6({
          campaignId: this.summaryData.id ? this.summaryData.id : this.summaryData._id,
          status,
          responseStatus
          // segmentName
        })
        // console.log('response', response)
        if (response.code === 200) {
          EventBus.$emit('getSegments')
          EventBus.$emit(
            'toast',
            'success',
            this.$t('successfullSegmentFromCampaignFilter')
          )
        } else {
          throw new Error(`Error creating segment from campaign: ${response.message}`)
        }
      } catch (e) {
        console.log('Error creating segment from campaign')
        console.error(e)
        EventBus.$emit(
          'toast',
          'error',
          this.$t('unsuccesfullSegmentFromCampaignFilter')
        )
      } finally {
        this.loadingExport2Segment = false
      }
    }
  }
}
