
import {
  ISegmentsController,
  dataToCreateSegment,
  dataToGetSegmentByName,
  dataToGetSegments,
  dataToUpdateDeleteSegment,
  segmentCreator,
  segmentGetter,
  segmentsGetter,
  dataToGetSegmentById
} from '../../domain/SegmentsController'
import SegmentsRepository from '../repository/Segments.repository'

export default class SegmentsController implements ISegmentsController {
  repository: SegmentsRepository

  constructor () {
    this.repository = new SegmentsRepository()
  }

  async getSegments (data: dataToGetSegments): Promise<segmentsGetter> {
    const response = await this.repository.getSegments(data)
    return response
  }

  async getSegmentsById (data: dataToGetSegmentById): Promise<segmentGetter> {
    const response = await this.repository.getSegmentsById(data)
    return response
  }

  async getSegmentByName (data: dataToGetSegmentByName): Promise<segmentGetter> {
    const response = await this.repository.getSegmentByName(data)
    return response
  }

  async createSegment (data: dataToCreateSegment): Promise<segmentCreator> {
    const response = await this.repository.createSegment(data)
    return response
  }

  async updateSegment (data: dataToUpdateDeleteSegment): Promise<segmentCreator> {
    const response = await this.repository.updateSegment(data)
    return response
  }

  async deleteSegment (data: dataToUpdateDeleteSegment): Promise<segmentCreator> {
    const response = await this.repository.deleteSegment(data)
    return response
  }

  abortGetSuggestions () {
    this.repository.abortGetSuggestions()
  }
}
