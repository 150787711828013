import { IChannelsController, channelDisconnect, dataToDisconnectChannel, dataToMigrateChannel, dataToUpdateWhatsAppBusinessProfile, dataToUpdateWhatsAppBusinessProfilePhoto, dataTogGetWhatsAppBusinessProfile, whatsappBusinessProfile } from '../domain/ChannelsController'

export default class ChannelsUseCases {
  static abort (controller: IChannelsController) : void {
    controller.abort()
  }

  static async disconnectChannel (controller: IChannelsController, data: dataToDisconnectChannel) : Promise<channelDisconnect> {
    return await controller.disconnectChannel(data)
  }

  static async getWhatsAppBusinessProfile (controller: IChannelsController, data: dataTogGetWhatsAppBusinessProfile) : Promise<whatsappBusinessProfile> {
    return await controller.getWhatsAppBusinessProfile(data)
  }

  static async updateWhatsAppBusinessProfile (controller: IChannelsController, data: dataToUpdateWhatsAppBusinessProfile) : Promise<whatsappBusinessProfile> {
    return await controller.updateWhatsAppBusinessProfile(data)
  }

  static async updateWhatsAppBusinessProfilePhoto (controller: IChannelsController, data: dataToUpdateWhatsAppBusinessProfilePhoto) : Promise<whatsappBusinessProfile> {
    return await controller.updateWhatsAppBusinessProfilePhoto(data)
  }

  static async migrateChannel (controller: IChannelsController, data: dataToMigrateChannel) : Promise<whatsappBusinessProfile> {
    return await controller.migrateChannel(data)
  }
}
