import Colors from '@/utils/Colors'
import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'
import KBButtonLoader from '@/shared/infrastructure/ui/progress/KBButtonLoader/KBButtonLoader.vue'
export default {
  name: 'KeybeButton',
  components: {
    KeybeIcon,
    KBButtonLoader
  },
  props: {
    text: {
      type: String
    },
    color: {
      type: String,
      default: Colors.kbGreen
    },
    textColor: {
      type: String,
      default: Colors.black
    },
    icon: {
      type: String
    },
    loading: {
      type: Boolean
    },
    disabled: {
      type: Boolean,
      default: false
    },
    transparent: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    iconColor (): string {
      return this.textColor || this.defaultTextColor
    },
    defaultTextColor (): string {
      return this.$vuetify.theme.dark ? Colors.white : Colors.black
    },
    buttonStyle (): string {
      const style: string = `background-color: ${this.color}; color: ${this.textColor || this.defaultTextColor};`
      if (this.transparent) {
        if (this.mouseover) {
          return style
        }
        return `background-color: transparent; color: ${this.textColor || this.defaultTextColor};`
      }
      if (this.disabled) {
        return `filter: grayscale(100%); ${style}`
      }
      return style
    }
  },
  data () {
    return {
      mouseover: false,
      colors: Colors
    }
  },
  methods: {
    click (): void {
      if (!this.disabled) {
        this.$emit('click')
      }
    }
  }
}
