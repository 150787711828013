// filters.ts

// Define una función que formatea el valor como moneda USD
export function currencyToUSD (value: any): string {
  if (!value) {
    return 'USD $0.00'
  }
  if (typeof value !== 'number') {
    return value.toString()
  }

  const currency = 'USD'
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency
  })

  return `USD $${formatter.format(value).replace('$', '')}`
}
