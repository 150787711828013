import { EventBus } from '@/eventBus'
import SmartOMeterUseCases from '@/modules/ai/application/SmartOMeterUseCases'
import { SmartOMeterFeatures } from '@/modules/ai/domain/SmartOMeter/SmartOMeterFeatures'
import SmartOMeterController from '@/modules/ai/infrastructure/controllers/SmartOMeter.controller'
import KnowledgeBaseFiles from '@/modules/ai/infrastructure/ui/KnowledgeBaseFiles/KnowledgeBaseFiles.vue'
import { BikyConfigUseCases } from '@/modules/bky/application/BikyConfigUseCases'
import { BikyConfig, BikyConfigLanguageTypeEnum, BikyConfigSlangEnum, BikyDefaultLanguageEnum, BikyPronunciation, defaultConfig } from '@/modules/bky/domain/BikyConfig'
import BikyConfigFileList from '@/modules/bky/infrastructure/ui/Config/BikyConfigFileList/BikyConfigFileList.vue'
import BikyConfigHeader from '@/modules/bky/infrastructure/ui/Config/BikyConfigHeader/BikyConfigHeader.vue'
import BikyConfigUrl from '@/modules/bky/infrastructure/ui/Config/BikyConfigUrl/BikyConfigUrl.vue'
import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import DragAndDropBox from '@/shared/infrastructure/ui/DragAndDropBox/DragAndDropBox.vue'
import KBTextSelect from '@/shared/infrastructure/ui/KBTextSelect/KBTextSelect.vue'
import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'
import KBSwitch from '@/shared/infrastructure/ui/inputs/select/KBSwitch.vue'
import KBText from '@/shared/infrastructure/ui/inputs/text/KBText.vue'
import { mapState } from 'vuex'
import BikyConfigController from '../../../controllers/BikyConfig.controller'
import BikyStoreController from '../../../persistence/BikyStore.controller'
import { CDPFieldsEnums } from '@/modules/CDP/shared/contacts/domain/enums/CDPFieldsEnums'
export default {
  name: 'BikyConfigBasic',
  components: {
    BikyConfigHeader,
    KBText,
    KBSwitch,
    DragAndDropBox,
    BikyConfigFileList,
    BikyConfigUrl,
    KBTextSelect,
    KeybeIcon,
    KnowledgeBaseFiles
  },
  data () {
    return {
      loading: false,
      basicConfig: {
        ...defaultConfig
      },
      bikyConfigController: new BikyConfigController(),
      KeybeIconType,
      useEmojis: true,
      useSlang: false,
      slang: null,
      slangs: [
        { label: this.$t('americanEnglish'), value: BikyConfigSlangEnum.EN_US },
        { label: this.$t('classyBritishEnglish'), value: BikyConfigSlangEnum.EN_GB_CLASSY },
        { label: this.$t('pleasantBritishEnglish'), value: BikyConfigSlangEnum.EN_GB_PLEASANT },
        { label: this.$t('politeBritishEnglish'), value: BikyConfigSlangEnum.EN_GB_POLITE },
        { label: this.$t('casualBritishEnglish'), value: BikyConfigSlangEnum.EN_GB_CASUAL },
        { label: this.$t('introspectiveAmericanEnglish'), value: BikyConfigSlangEnum.EN_US_INTROSPECTIVE },
        { label: this.$t('narrativeAmericanEnglish'), value: BikyConfigSlangEnum.EN_US_NARRATIVE },
        { label: this.$t('neutralAmericanEnglish'), value: BikyConfigSlangEnum.EN_US_NEUTRAL }
      ],
      files: [],
      slags: [
        { label: this.$t('spanish'), value: BikyDefaultLanguageEnum.ES },
        { label: this.$t('english'), value: BikyDefaultLanguageEnum.EN }
      ],
      languages: [
        { label: this.$t('spanish'), value: BikyDefaultLanguageEnum.ES },
        { label: this.$t('english'), value: BikyDefaultLanguageEnum.EN },
        { label: this.$t('portuguese'), value: BikyDefaultLanguageEnum.PT }
      ],
      languageTypes: [
        {
          label: this.$t(BikyConfigLanguageTypeEnum.VERY_INFORMAL),
          value: BikyConfigLanguageTypeEnum.VERY_INFORMAL
        },
        {
          label: this.$t(BikyConfigLanguageTypeEnum.INFORMAL),
          value: BikyConfigLanguageTypeEnum.INFORMAL
        },
        {
          label: this.$t(BikyConfigLanguageTypeEnum.FORMAL),
          value: BikyConfigLanguageTypeEnum.FORMAL
        },
        {
          label: this.$t(BikyConfigLanguageTypeEnum.VERY_FORMAL),
          value: BikyConfigLanguageTypeEnum.VERY_FORMAL
        }
      ],
      selectedLanguageType: BikyConfigLanguageTypeEnum.VERY_INFORMAL,
      selectedLanguage: BikyDefaultLanguageEnum.ES,
      urls: [],
      basePronunciations: [],
      pronunciations: [],
      pronunciationsToDelete: [],
      baseWebsites: [],
      websites: [],
      websitesToDelete: [],
      smartOMeterController: new SmartOMeterController(),
      urlsRenderKey: 0,
      emojisOptions: [
        { label: this.$t('useOcasional'), value: true },
        { label: this.$t('dontUse'), value: false }
      ],
      messageTextOptions: [
        {
          label: this.$t('veryShortMessages'),
          value: 15
        },
        {
          label: this.$t('shortMessages'),
          value: 25
        },
        {
          label: this.$t('mediumMessages'),
          value: 40
        },
        {
          label: this.$t('freeMessages'),
          value: 0
        }
      ],
      messageTextNumber: 40,
      CDPFieldsEnums
    }
  },
  computed: {
    ...mapState('AppStore', ['selectedApp']),
    ...mapState('UserStore', ['token', 'user']),
    ...mapState('BillingStore', ['tier']),
    hasBiky (): boolean {
      return SmartOMeterUseCases.hasPermission(this.smartOMeterController, SmartOMeterFeatures.BIKY_CONFIG, this.tier) || this.basicConfig?.active
    },
    configHadChanged () {
      if (!this.hasBiky) {
        return false
      }

      if (!this.basicConfig) {
        return false
      }

      return this.basicConfig.useEmojis !== this.useEmojis ||
        this.basicConfig.slang !== this.slang ||
        this.basicConfig.languageType !== this.selectedLanguageType ||
        this.basicConfig.defaultLanguage !== this.selectedLanguage ||
        this.basicConfig.wordsLimit !== this.messageTextNumber
    },
    editingPronunciations (): {
      toAdd: BikyPronunciation[],
      toUpdate: BikyPronunciation[],
      } {
      const toAdd: BikyPronunciation[] = []
      const toUpdate: BikyPronunciation[] = []
      this.pronunciations.forEach((p: BikyPronunciation) => {
        const basePronunciation = this.basePronunciations.find((bp: BikyPronunciation) => {
          if (!p._id) {
            return false
          }

          return bp._id === p._id
        })
        if (!basePronunciation) {
          toAdd.push(p)
        } else if (basePronunciation.pronunciation !== p.pronunciation || basePronunciation.name !== p.name) {
          toUpdate.push(p)
        }
      })
      return {
        toAdd,
        toUpdate
      }
    },
    websitesToAdd () {
      return this.websites.filter((w: any) => !w?._id)
    },
    showSaveButton () {
      return this.configHadChanged || this.pronunciationsToDelete.length > 0 || this.editingPronunciations.toAdd.length > 0 || this.editingPronunciations.toUpdate.length > 0 || this.websitesToDelete.length > 0 || this.websitesToAdd.length > 0
    },
    isEnglish (): boolean {
      return this.selectedLanguage === BikyDefaultLanguageEnum.EN
    },
    isEnglishSlang (): boolean {
      return this.slang === BikyConfigSlangEnum.EN_US || this.slang === BikyConfigSlangEnum.EN_GB_CASUAL || this.slang === BikyConfigSlangEnum.EN_GB_CLASSY || this.slang === BikyConfigSlangEnum.EN_GB_PLEASANT || this.slang === BikyConfigSlangEnum.EN_GB_POLITE || this.slang === BikyConfigSlangEnum.EN_US_INTROSPECTIVE || this.slang === BikyConfigSlangEnum.EN_US_NARRATIVE || this.slang === BikyConfigSlangEnum.EN_US_NEUTRAL
    },
    isBritishSlang (): boolean {
      return this.slang === BikyConfigSlangEnum.EN_GB_CASUAL || this.slang === BikyConfigSlangEnum.EN_GB_CLASSY || this.slang === BikyConfigSlangEnum.EN_GB_PLEASANT || this.slang === BikyConfigSlangEnum.EN_GB_POLITE
    },
    isAmericanSlang (): boolean {
      return this.slang === BikyConfigSlangEnum.EN_US || this.slang === BikyConfigSlangEnum.EN_US_INTROSPECTIVE || this.slang === BikyConfigSlangEnum.EN_US_NARRATIVE || this.slang === BikyConfigSlangEnum.EN_US_NEUTRAL
    }
  },
  methods: {
    async getFiles () {
      this.files = await BikyConfigUseCases.getFiles(this.bikyConfigController, {
        token: this.token,
        appUUID: this.selectedApp?.uuid
      })
    },
    async getConfig () {
      const config: BikyConfig | null = await BikyConfigUseCases.getBikyConfig(this.bikyConfigController, {
        appUUID: this.selectedApp?.uuid,
        token: this.token
      })

      if (!config) {
        this.basicConfig = {
          ...defaultConfig
        }
        return
      }

      this.basicConfig = {
        ...config
      }

      BikyStoreController.setConfig(config)

      this.useEmojis = config?.useEmojis || false
      this.useSlang = !!config?.slang || false
      this.selectedLanguageType = config?.languageType || BikyConfigLanguageTypeEnum.VERY_INFORMAL
      this.selectedLanguage = config?.defaultLanguage || BikyDefaultLanguageEnum.ES
      this.slang = config?.slang
      this.messageTextNumber = config?.wordsLimit || 40
    },
    async getPronunciations () {
      const pronunciations: BikyPronunciation[] = await BikyConfigUseCases.getPronunciations(this.bikyConfigController, {
        appUUID: this.selectedApp?.uuid,
        token: this.token
      })

      // save base pronunciations in a different array
      this.basePronunciations = pronunciations.map((p: BikyPronunciation) => ({ ...p }))
      this.pronunciations = pronunciations
    },
    async getWebsites () {
      const websites = await BikyConfigUseCases.getWebsites(this.bikyConfigController, {
        appUUID: this.selectedApp?.uuid,
        token: this.token
      })

      this.baseWebsites = websites.map((w: any) => ({ ...w }))
      this.websites = websites
      this.urlsRenderKey++
    },
    addPronunciation () {
      this.pronunciations.push({
        name: '',
        pronunciation: ''
      })
    },
    removePronunciation (pronunciation, index) {
      if (!pronunciation?._id) {
        this.pronunciations.splice(index, 1)
        return
      }

      this.pronunciationsToDelete.push(pronunciation)
      this.pronunciations.splice(index, 1)
    },
    addWebsite () {
      this.websites.unshift({
        url: ''
      })
      this.urlsRenderKey++
    },
    removeWebsite (website, index) {
      if (!website?._id) {
        this.websites.splice(index, 1)
        return
      }

      this.websitesToDelete.push(website)
      this.websites.splice(index, 1)
    },
    async updateConfig (): Promise<void> {
      if (this.loading) {
        return
      }
      this.loading = true
      this.$emit('start-loading')

      await this.saveBasicConfig()
      await this.savePronunciations()
      await this.saveWebsites()

      await this.getConfig()

      this.$emit('stop-loading')
      this.loading = false
      this.urlsRenderKey++

      this.init()
    },
    async saveBasicConfig (): Promise<boolean> {
      if (!this.configHadChanged) {
        return false
      }

      if (!this.isEnglish && this.selectedLanguage !== BikyDefaultLanguageEnum.PT) {
        this.slang = BikyConfigSlangEnum.ES_CO
      }

      if (this.isEnglish && !this.isEnglishSlang) {
        this.slang = BikyConfigSlangEnum.EN_US
      }

      const newConfig = {
        useEmojis: this.useEmojis,
        slang: this.slang,
        languageType: this.selectedLanguageType,
        defaultLanguage: this.selectedLanguage,
        wordsLimit: this.messageTextNumber
      }

      const updated = await BikyConfigUseCases.updateBikyConfig(this.bikyConfigController, {
        token: this.token,
        appUUID: this.selectedApp?.uuid,
        updatedBy: this.user?.userId,
        ...newConfig
      })

      if (!updated) {
        EventBus.$emit('toast', 'error', this.$t('errorUpdatingConfig'))
      }

      return updated
    },
    async savePronunciations () {
      // compare basePronunciations with pronunciations, build 3 lists, toAdd, toUpdate, toDelete
      const toAdd: BikyPronunciation[] = this.editingPronunciations.toAdd
      const toUpdate: BikyPronunciation[] = this.editingPronunciations.toUpdate
      const toDelete: BikyPronunciation[] = []

      this.pronunciationsToDelete.forEach((p: BikyPronunciation) => {
        if (p._id) {
          toDelete.push(p)
        }
      })

      const promises = []
      toAdd.forEach((p: BikyPronunciation) => {
        promises.push(BikyConfigUseCases.createPronunciation(this.bikyConfigController, {
          token: this.token,
          appUUID: this.selectedApp?.uuid,
          ...p
        }))
      })

      toUpdate.forEach((p: BikyPronunciation) => {
        promises.push(BikyConfigUseCases.updatePronunciation(this.bikyConfigController, {
          token: this.token,
          pronunciationId: p._id,
          name: p.name,
          pronunciation: p.pronunciation
        }))
      })

      toDelete.forEach((p: BikyPronunciation) => {
        promises.push(BikyConfigUseCases.deletePronunciation(this.bikyConfigController, {
          token: this.token,
          pronunciationId: p._id
        }))
      })

      const results = await Promise.all(promises)

      return results.every((r: boolean) => r)
    },
    async saveWebsites () {
      const toAdd = this.websitesToAdd
      const toDelete = this.websitesToDelete

      // validate urls
      // /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/
      const invalidUrls = toAdd.filter((w: any) => {
        const regex = /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_+.~#?&/=]*)$/
        return !regex.test(w.url)
      })

      if (invalidUrls.length > 0) {
        EventBus.$emit('toast', 'error', this.$t('invalidUrls'))
        return false
      }

      const promises = []
      toAdd.forEach((w: any) => {
        promises.push(BikyConfigUseCases.createWebsite(this.bikyConfigController, {
          token: this.token,
          appUUID: this.selectedApp?.uuid,
          ...w
        }))
      })

      toDelete.forEach((w: any) => {
        promises.push(BikyConfigUseCases.deleteWebsite(this.bikyConfigController, {
          token: this.token,
          websiteId: w._id
        }))
      })

      const results = await Promise.all(promises)

      this.websitesToDelete = []
      this.websitesToAdd = []
      this.urlsRenderKey++

      return results.every((r: boolean) => r)
    },
    async init () {
      this.pronunciationsToDelete = []
      this.basePronunciations = []
      this.pronunciations = []
      this.files = []
      this.urls = []
      this.documents = []
      this.basicConfig = null
      this.urlsRenderKey++
      this.$emit('start-loading')
      await this.getConfig()
      await this.getFiles()
      await this.getPronunciations()
      await this.getWebsites()
      this.$emit('stop-loading')
    },
    async refreshFiles () {
      this.$emit('start-loading')
      await this.getFiles()
      this.$emit('stop-loading')
    },
    sendDropDataEvent (e) {
      EventBus.$emit('BikyConfigBasic:drop-data', e)
    },
    async playTest (): Promise<void> {
      const spanishDemo: string = 'Hola [name] soy Biky y estoy aquí para ser la mejor compañera de tu equipo comercial y aumentar tu conversión de ventas'
      const americanDemo: string = 'Hello [name], I am Biky, and it is my distinct pleasure to join your sales team as a committed partner, dedicated to substantially elevating your sales conversion rates'
      const britishDemo: string = 'Greetings [name], I am Biky, and it is my pleasure to join forces with your sales team as a dedicated partner, with the intention of significantly enhancing your sales conversion rates.'

      let demo: string = spanishDemo
      if (this.isBritishSlang) {
        demo = britishDemo
      }
      if (this.isAmericanSlang) {
        demo = americanDemo
      }

      this.$emit('start-loading')
      const url: string = await BikyConfigUseCases.getAudioDemoUrl(this.bikyConfigController, {
        token: this.token,
        appUUID: this.selectedApp?.uuid,
        text: demo.replace('[name]', this.user?.name || ''),
        language: this.selectedLanguage,
        slang: this.slang
      })
      this.$emit('stop-loading')

      const audio = new Audio(url)
      audio.play()
    }
  },
  mounted () {
    this.init()
    // events
    EventBus.$on('BikyConfig:save', this.updateConfig)
  },
  beforeDestroy () {
    EventBus.$off('BikyConfig:save', this.updateConfig)
    BikyConfigUseCases.abortAll(this.bikyConfigController)
  },
  watch: {
    useSlang (value) {
      if (!value) {
        this.slang = ''
      }
    },
    showSaveButton (value) {
      EventBus.$emit('BikyConfig:show-save-button', value)
    },
    selectedLanguage (value) {
      if (value === BikyDefaultLanguageEnum.EN) {
        if (!this.isEnglishSlang) {
          this.slang = BikyConfigSlangEnum.EN_US
        }
      } else if (value === BikyDefaultLanguageEnum.PT) {
        this.slang = BikyConfigSlangEnum.PT_BR
      } else {
        this.slang = BikyConfigSlangEnum.ES_CO
      }
    }
  }
}
