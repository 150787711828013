<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       fill="none"
       viewBox="0 0 12 14">
    <path :fill="color"
          d="M.001 5.16C.195 2.888 1.321 1.284 3.544.397 4.479.023 5.469-.076 6.49.055c2.085.268 3.574 1.28 4.46 3.017.632 1.24.663 2.515.135 3.81-.499 1.22-1.21 2.335-2 3.405-.839 1.137-1.728 2.242-2.608 3.353-.363.456-1.043.485-1.422.05-1.577-1.805-3.019-3.697-4.16-5.765C.567 7.33.362 6.676.144 6.038.04 5.736.037 5.405 0 5.16h.001Zm7.65.095c0-.961-.874-1.767-1.914-1.765-1.035.003-1.914.82-1.907 1.772.007.934.904 1.753 1.915 1.749 1.02-.005 1.905-.822 1.905-1.757v.001Z"/>
  </svg>
</template>

<script>
export default {
  name: 'LocationIcon',
  props: {
    color: {
      type: String,
      default: '#8898A9'
    }
  }
}
</script>

<style scoped>

</style>
