







export default {
  name: 'SendIcon',
  props: {
    color: {
      type: String,
      default: '#01CFE4'
    }
  }
}
