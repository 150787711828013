<template>
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14"><rect width="13" height="13" x=".5" y=".5" :fill="color" :stroke="color" rx="2.5"/></svg>
</template>

<script>
export default {
  name: 'SelectedIcon',
  props: {
    color: {
      type: String,
      default: '#3AF69B'
    }
  }
}
</script>

<style>

</style>
