import { IAppConfigController, appConfigGetter, dataToGetAppConfig } from '../domain/AppDataController'
import { IRPlanItemController, IRPaymentItemController, dataToGetPlan, planGetter, dataToDeletePaymentMethod, dataToCreditCardsMethod } from '../domain/PlanController'

export default class PlanUseCases {
  static async setBooking (controller:IRPlanItemController, data: dataToGetPlan): Promise<planGetter> {
    return await controller.setBooking(data)
  }

  static async removePaymentMethods (controller:IRPaymentItemController, data: dataToDeletePaymentMethod): Promise<planGetter> {
    return await controller.removePaymentMethods(data)
  }

  static async getAppConfig (controller:IAppConfigController, data: dataToGetAppConfig): Promise<appConfigGetter> {
    return await controller.getAppConfig(data)
  }

  static async getActiveBikyPlan (controller:IRPaymentItemController, data: dataToCreditCardsMethod): Promise<planGetter> {
    return await controller.getActiveBikyPlan(data)
  }
}
