import { EventBus } from '@/eventBus'
import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import TaskController from '../../../controllers/Task.controller'
import { dataToCreateTask } from '@/modules/tasks/domain/TaskController'
import { mapState } from 'vuex'

import CreateTaskHeader from '@/modules/tasks/infrastructure/ui/components/CreateTaskHeader/CreateTaskHeader.vue'
import CreateTaskForm from '@/modules/tasks/infrastructure/ui/components/CreateTaskForm/CreateTaskForm.vue'

export default {
  name: 'CreateTask',
  components: {
    CreateTaskHeader,
    // KeybeIcon,
    CreateTaskForm
  },
  data () {
    return {
      taskController: new TaskController(),
      KeybeIconType,
      createInput: {
        title: '',
        responsible: {
          uuid: '',
          phone: '',
          name: '',
          email: ''
        },
        priority: 'medium',
        inactive: false,
        description: '',
        date: '',
        contact: {
          uuid: '',
          name: ''
        }
      }
    }
  },
  computed: {
    ...mapState('AppStore', ['selectedApp']),
    ...mapState('UserStore', ['token'])
  },
  methods: {
    async createTask () {
      if (!this.createInput.title || !this.createInput.title.trim()) {
        EventBus.$emit('toast', 'error', this.$t('youMustEnterATaskTitle'))
        return
      }
      if (!this.createInput.date) {
        EventBus.$emit('toast', 'error', this.$t('youMustEnterDateAndTime'))
        return
      }

      if (!this.createInput?.responsible?.uuid) {
        EventBus.$emit('toast', 'error', this.$t('youMustEnterAResponsible'))
        return
      }

      if (!this.createInput?.contact?.uuid) {
        EventBus.$emit('toast', 'error', this.$t('youMustEnterAContact'))
        return
      }

      const data: dataToCreateTask = {
        app: this.selectedApp?.uuid,
        task: {
          ...this.createInput
        },
        token: this.token
      }

      this.$emit('start-loading')
      const result = await this.taskController.createTask(data)
      this.$emit('stop-loading')

      if (result.success) {
        this.$emit('taskCreated')
      } else {
        EventBus.$emit('toast', 'error', this.$t(result.message))
      }
    }
  }
}
