import KeybeRepository from '@/shared/infrastructure/repositories/KeybeRepository'
import { HeadersBuilder } from '@/shared/infrastructure/http/HeadersBuilder'
import { getOptions, nonGetOptions } from '@/shared/infrastructure/http/HttpClient'
import { GenericResponse } from '@/models/apis/GenericResponse'
import { dataToGetCSVExample, dataToStartCSVImport, dataToUploadCSV } from '../../domain/models/CatalogImporterController'

export default class CatalogImporterRepository extends KeybeRepository {
  getProductsAbortController: AbortController

  constructor () {
    super()

    this.getProductsAbortController = new AbortController()
  }

  async uploadCSVFile (data: dataToUploadCSV): Promise<GenericResponse> {
    const { component, appUUID, subcomponent, file } = data
    let url = `${process.env.VUE_APP_UTILS_PATH}/document/upload`

    if (component) {
      url = url.concat(`?component=${component}`)
    }
    if (appUUID) {
      url = url.concat(`&appUUID=${appUUID}`)
    }
    if (subcomponent) {
      url = url.concat(`&subcomponent=${subcomponent}`)
    }
    const headers = HeadersBuilder.buildFormDataAutorizationHeader(data)

    const fd = new FormData()
    if (file) fd.append('file', file)

    const requestOptions: nonGetOptions = {
      headers,
      url,
      data: fd,
      abortController: this.getProductsAbortController,
      isFormData: true
    }
    return await this.client.post(requestOptions)
  }

  async getCSVFileExample (data: dataToGetCSVExample): Promise<GenericResponse> {
    const url = `${process.env.VUE_APP_MICROSITES_URL}/microsites/product/importer/template`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    const requestOptions: getOptions = {
      headers,
      url,
      abortController: this.getProductsAbortController
    }
    return await this.client.get(requestOptions)
  }

  async startCSVImport (data: dataToStartCSVImport): Promise<GenericResponse> {
    const { s3Path, appUUID } = data
    const url = `${process.env.VUE_APP_MICROSITES_URL}/microsites/scripts/importProductsFromS3Csv/${appUUID}`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    const requestOptions: nonGetOptions = {
      headers,
      url,
      data: {
        s3Path
      },
      abortController: this.getProductsAbortController
    }
    return await this.client.post(requestOptions)
  }

  async getAllImports (data: dataToGetCSVExample): Promise<GenericResponse> {
    const { appUUID } = data
    const url = `${process.env.VUE_APP_MICROSITES_URL}/microsites/product/import/all/${appUUID}`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    const requestOptions: getOptions = {
      headers,
      url,
      abortController: this.getProductsAbortController
    }
    return await this.client.get(requestOptions)
  }

  abortGetProducts () {
    this.getProductsAbortController.abort()
    this.getProductsAbortController = new AbortController()
  }

  abortAll () {
    this.abortGetProducts()
    // TODO: ABORT REST
  }
}
