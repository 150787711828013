












export default {
  name: 'CortexGreenIcon'
}
