<template>
  <div class="ImporterSegmentSelection">
    <div class="ImporterSegmentSelection__header">
      <KeybeButton
        class="ImporterSegmentSelection__header--left"
        :text="$t('people')"
        :color="buttonColor(EntityTypeEnum.PEOPLE)"
        :textColor="buttonTextColor(EntityTypeEnum.PEOPLE)"
        :key="`${rerender}-people`"
        @click="selectEntityType(EntityTypeEnum.PEOPLE)"/>
      <KeybeButton
        :text="$t('business')"
        :color="buttonColor(EntityTypeEnum.BUSINESS)"
        :textColor="buttonTextColor(EntityTypeEnum.BUSINESS)"
        :key="`${rerender}-business`"
        @click="selectEntityType(EntityTypeEnum.BUSINESS)"/>
    </div>
    <div class="ImporterSegmentSelection__container">
      <div
        v-if="biggerScreen"
        class="ImporterSegmentSelection__container__image">
        <lottie-animation
          style="width: 120%"
          ref="anim"
          :loop="true"
          :animationData="require('@/shared/assets/animations/Yoga_Meditar.json')"
        />
      </div>
      <div class="ImporterSegmentSelection__container__inputs"
           :class="{ 'ImporterSegmentSelection__container__inputs--small': !biggerScreen }">
        <ImporterSegmentSelection
          :typeEntity="typeEntity"
          @segment="$emit('segment', $event)"
          @segmentName="$emit('segmentName', $event)"
          @createSegment="$emit('createSegment', $event)"
          @hasSelectedSegment="$emit('hasSelectedSegment', $event)"/>
        <span >{{$t('statusDefault')}}</span>
        <KeybeAutocomplete
          class="mb-5 mt-2"
          :items="statuses"
          :itemText="CDPFieldsEnums.NAME"
          :returnObject="true"
          :placeholder="$t('selectHere')"
          @input="setDefaultStatus"/>
        <span >{{$t('whoIsTheResponsible')}}</span>
        <KeybeAutocomplete
          class="mb-5 mt-2"
          :items="administrators"
          :itemText="CDPFieldsEnums.FULL_NAME"
          :returnObject="true"
          :placeholder="$t('selectHere')"
          @input="setResponsible"/>
        <ImporterFileUpload
          @fileUploadData="$emit('fileUploadData', $event)"
          @headers="$emit('headers', $event)"
          @persons="$emit('persons', $event)"/>
      </div>
    </div>
  </div>
</template>
<script>
import ImporterSegmentSelectionContainer from './ImporterSegmentSelectionContainer'
export default ImporterSegmentSelectionContainer
</script>
<style scoped lang="scss">
@import './ImporterSegmentSelectionContainer.scss';
</style>
