import KeybeButton from '@/modules/DesignSystem/infrastructure/components/KeybeButton/KeybeButton.vue'
import KBSearch from '@/shared/infrastructure/ui/inputs/text/KBSearch.vue'
export default {
  components: {
    KeybeButton,
    KBSearch
  },
  props: {
    roles: {
      type: Array
    },
    create: {
      type: Boolean
    }
  },
  methods: {
    toggleCreate () {
      this.$emit('toggleCreate')
    }
  }
}
