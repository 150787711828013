import { TemplateComponentsEnum } from '@/modules/templates/domain/Template'
import TemplateService from '@/modules/templates/domain/Template.service'
import MessageSequenceCards from '@/modules/conversations/infrastructure/ui/components/chat/messages/MessageSequenceCards/MessageSequenceCards.vue'
// @ts-ignore
import pdf from 'vue-pdf'

export default {
  name: 'TemplatePreview',
  components: {
    pdf,
    MessageSequenceCards
  },
  props: {
    template: {
      type: Object || null,
      required: true
    },
    templateData: {
      type: Object,
      required: true
    },
    fullHeight: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    sequenceCards (): any[] {
      return this.templateData.cardsParams
    },
    templateIsSequence (): boolean {
      return this.template?.components?.includes(TemplateComponentsEnum.CAROUSEL)
    },
    templateHeader () {
      return TemplateService.getTemplateComponent(this.template, TemplateComponentsEnum.HEADER)
    },
    isImageHeader () {
      return TemplateService.isImageHeader(this.templateHeader)
    },
    isDocumentHeader () {
      return TemplateService.isDocumentHeader(this.templateHeader)
    },
    isTextHeader () {
      return TemplateService.isTextHeader(this.templateHeader)
    },
    isMediaHeader () {
      return this.isImageHeader || this.isDocumentHeader || this.isVideoHeader
    },
    isVideoHeader () {
      return TemplateService.isVideoHeader(this.templateHeader)
    },
    templateMediaUrl () {
      return this.templateData.mediaParams[0]
    },
    templateMessage () {
      return TemplateService.getTemplateMessagePreview(this.template, this.templateData.templateParams)
    },
    templateButtons () {
      const buttonsComponent = TemplateService.getTemplateComponent(this.template, TemplateComponentsEnum.BUTTONS)
      if (!buttonsComponent) {
        return []
      }

      const buttons = [...buttonsComponent?.buttons] || []
      // if button is editable, replace the param with a value in the templateData.buttonParams then remove the param
      const params = [...this.templateData.buttonParams] || []

      if (params.length === 0) {
        return buttons
      }

      buttons.forEach((button) => {
        if (TemplateService.canEditButton(button)) {
          button.url = TemplateService.buildUrl(button, params[0])
          params.shift()
        }
      })

      return buttons
    },
    templateFooter () {
      return TemplateService.getTemplateComponent(this.template, TemplateComponentsEnum.FOOTER)
    },
    canEditHeader () {
      return this.isTextHeader && TemplateService.getParams(this.templateHeader.text).length > 0
    },
    templateHeaderText () {
      if (!this.isTextHeader) {
        return ''
      }

      if (this.canEditHeader) {
        return TemplateService.getHeaderPreview(this.templateHeader, this.templateData.headerParams)
      }

      return this.templateHeader?.text
    },
    templateFooterText () {
      if (!this.templateFooter) {
        return ''
      }
      return this.templateFooter?.text || ''
    }
  },
  data () {
    return {
      loadingPreview: false
    }
  },
  methods: {
    openButtonUrl (button) {
      if (button?.url) {
        window.open(button.url, '_blank')
      }
    }
  }
}
