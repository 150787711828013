export const TriggersStore = {
  namespaced: true,
  state: {
    journeyId: null,
    actionType: '',
    extradataField: '',
    extradataValue: '',
    extradataFieldName: ''
  },
  mutations: {
    SET_ACTION_TYPE_NAME (state, actionType: string) {
      state.actionType = actionType
    },
    SET_JOURNEY_ID (state, journeyId: string) {
      state.journeyId = journeyId
    },
    SET_EXTRADATA_VALUE_FIELD_CHANGE (state, extradataField: string) {
      state.extradataField = extradataField
    },
    SET_EXTRADATA_VALUE_CHANGE (state, extradataValue: string) {
      state.extradataValue = extradataValue
    },
    SET_EXTRADATA_VALUE_FIELD_NAME_CHANGE (state, extradataFieldName: string) {
      state.extradataFieldName = extradataFieldName
    }
  }
}
