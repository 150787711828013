<template>
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 262 341"><path fill="#E9E9E0" d="M185.632 0H8.909C3.989 0 0 3.988 0 11.728v323.183C0 337.012 3.988 341 8.909 341H252.93c4.92 0 8.908-3.988 8.908-6.089V79.027c0-4.238-.566-5.602-1.565-6.607L189.419 1.565A5.378 5.378 0 0 0 185.632 0Z"/><path fill="#D9D7CA" d="M188.768.92v72.153h72.152L188.768.92Z"/><path fill="#B6BEC7" d="M128.235 241.518c1.116-1.048 1.789-1.644 2.422-2.281 7.464-7.5 14.929-15.01 22.384-22.52 3.616-3.637 6.751-4.099 9.373-1.376 2.904 3.01 2.552 5.876-1.205 9.718a36514.711 36514.711 0 0 1-33.757 34.51c-3.918 4.007-6.39 4.048-10.228.133a15915.501 15915.501 0 0 1-34.279-35.053c-3.797-3.894-3.576-7.706.492-10.202 1.839-1.13 3.657-1.171 5.426.041.964.668 1.858 1.459 2.692 2.291 8.068 8.096 16.115 16.202 24.765 24.914V238.2c0-46.664-.02-93.317.07-139.98 0-2.075.513-4.716 1.809-6.123 3.225-3.514 9.112-1.675 9.745 2.887.161 1.181.291 2.373.291 3.565.011 46.406.011 92.802.011 139.209v3.76h-.011Z"/></svg>
</template>

<script>
export default {
  name: 'FileIcon'
}
</script>

<style>

</style>
