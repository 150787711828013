
import KeybeAvatar from '@/shared/infrastructure/ui/KeybeAvatar.vue'
import DateRange from '@/modules/metrics/infrastructure/ui/components/generic/DateRange.vue'
import LoadingNext from '@/modules/metrics/infrastructure/ui/components/generic/LoadingNext.vue'
import MetricsService from '@/modules/metrics/application/services/MetricsService'
import { CDPFieldsEnums } from '@/modules/CDP/shared/contacts/domain/enums/CDPFieldsEnums'

export default {
  name: 'ConversationsTable',
  props: [
    'dateString', 'range', 'dataComponent', 'advisors'
  ],
  components: {
    KeybeAvatar,
    DateRange,
    LoadingNext
  },
  data () {
    return {
      msgServiceError: false,
      loadingTable: false,
      dataListEmpty: false,
      dataList: [],
      headers: [
        { text: '', field: '' },
        { text: this.$t(CDPFieldsEnums.NAME), field: 'advisorName' },
        { text: this.$t('totalConversations'), field: 'totalConversations' },
        { text: this.$t('totalSessions'), field: 'totalSessions' },
        { text: this.$t('avgSessionTime'), field: 'avgSessionTime' },
        { text: this.$t('medianSessionTime'), field: 'medianSessionTime' }
      ]
    }
  },
  mounted () {
    this.getData()
  },
  watch: {
    range () {
      if (this.range) {
        this.getData()
      }
    }
  },
  methods: {
    async getData () {
      this.loadingTable = true
      try {
        this.dataListEmpty = false
        let params
        if (this.range) {
          const { dateFrom, dateTo } = this.range
          params = {
            periodStart: dateFrom,
            periodEnd: dateTo
          }
        }
        const response = await MetricsService.getDataChat(params, this.advisors)
        if (response) {
          const { result } = response.data
          if (result.length > 0) {
            this.dataList = result
            this.loadingTable = false
          } else {
            this.loadingTable = false
            this.dataListEmpty = true
          }
        } else {
          this.loadingTable = false
          this.dataListEmpty = true
        }
      } catch (error) {
        this.loadingTable = false
        this.dataListEmpty = true
      }
    }
  }
}
