<template>
  <div
    class="CDPFieldsConfigCard"
    v-if="!loading">
    <div
      class="CDPFieldsConfigCard__card"
      v-for="(field, index) in fields"
      :key="index">
      <KBSwitch
        :value="field.active"
        color="green"
        :disabled="isUserReadonly"
        :key="`${index}-${field.field}`"
        @input="setFieldStatus(index)"/>
      <span>{{ $t(field.field) }}</span>
    </div>
  </div>
</template>
<script lant="ts">
import CDPFieldsConfigCard from './CDPFieldsConfigCard'
export default CDPFieldsConfigCard
</script>
<style scoped lang="scss">
@import './CDPFieldsConfigCard.scss'
</style>
