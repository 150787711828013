import KeybeRepository from '@/shared/infrastructure/repositories/KeybeRepository'
import { GenericResponse } from '@/shared/infrastructure/http/GenericResponse'
import { HeadersBuilder } from '@/shared/infrastructure/http/HeadersBuilder'
import { getOptions, nonGetOptions } from '@/shared/infrastructure/http/HttpClient'
import { dataToCreateAPIKey, dataToDeleteAPIKey, dataToGetAPIKeys } from '../../domain/APIKeysController'

export default class APIKeysRepository extends KeybeRepository {
  getSuggestionsAbortController: AbortController

  constructor () {
    super()

    this.getSuggestionsAbortController = new AbortController()
  }

  async getAPIKeys (data: dataToGetAPIKeys): Promise<GenericResponse> {
    const { app } = data

    const url = `${process.env.VUE_APP_AUTH_SERVICE}apikey/${app}`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    const requestOptions: getOptions = {
      headers,
      url,
      abortController: this.getSuggestionsAbortController
    }

    return await this.client.get(requestOptions)
  }

  async deleteAPIKey (data: dataToDeleteAPIKey): Promise<GenericResponse> {
    const { app, id } = data

    const url = `${process.env.VUE_APP_AUTH_SERVICE}apikey/${app}/${id}`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    const requestOptions: nonGetOptions = {
      headers,
      url,
      abortController: this.getSuggestionsAbortController
    }

    return await this.client.delete(requestOptions)
  }

  async createAPIKey (data: dataToCreateAPIKey): Promise<GenericResponse> {
    const { app, createInput } = data

    const url = `${process.env.VUE_APP_AUTH_SERVICE}apikey/${app}`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    const requestOptions: nonGetOptions = {
      headers,
      url,
      data: createInput,
      abortController: this.getSuggestionsAbortController
    }

    return await this.client.post(requestOptions)
  }
}
