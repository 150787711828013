var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"KBExpansionPanel",class:{ 'KBExpansionPanel--elevated': _vm.elevated }},[_c('div',{staticClass:"KBExpansionPanel__header handle",class:{ 'KBExpansionPanel__header--elevated--dark': _vm.$vuetify.theme.dark,
                 'KBExpansionPanel__header--elevated': _vm.elevated,
                 'KBExpansionPanel__header--open': _vm.open,
                 'KBExpansionPanel__header--open--elevated': _vm.open && _vm.elevated,
                 'KBExpansionPanel__header--open-dark': _vm.open && _vm.$vuetify.theme.dark,
                 'KBExpansionPanel__header--open-dark--elevated': _vm.open && _vm.$vuetify.theme.dark && _vm.elevated,
                 'KBExpansionPanel__header--outlined': _vm.outlinedClass },style:({ background: _vm.headerBackgroundColor, marginTop: _vm.marginTop, borderTop: _vm.borderTop, borderRadius: _vm.borderRadius, height: _vm.height, marginBottom: _vm.marginBottom }),on:{"click":_vm.openPanel}},[_vm._t("title"),(_vm.showSelector)?_c('span',{staticClass:"mdi mdi-menu-down"}):_vm._e()],2),_c('div',{staticClass:"KBExpansionPanel__content",class:{ 'KBExpansionPanel__content--open': _vm.open,
                 'KBExpansionPanel__content--open--elevated': _vm.open && _vm.elevated,
                 'KBExpansionPanel__content--open-dark': _vm.open && _vm.$vuetify.theme.dark,
                 'KBExpansionPanel__content--open-dark--elevated': _vm.open && _vm.$vuetify.theme.dark && _vm.elevated },style:({ background: _vm.contentBackgroundColor,position: _vm.position, zIndex: _vm.zIndex, overflow: _vm.overflow, height: _vm.heightContent, borderRadius: _vm.borderRadius})},[_vm._t("content")],2)])}
var staticRenderFns = []

export { render, staticRenderFns }