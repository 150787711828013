import { BikyConfig } from '../../domain/BikyConfig'

export const BikyStore = {
  namespaced: true,
  state: {
    config: null
  },
  mutations: {
    SET_CONFIG (state, config): void {
      state.config = config
    }
  },
  actions: {
    setConfig ({ commit }, config): void {
      commit('SET_CONFIG', config)
    }
  },
  getters: {
    getConfig (state: {[key: string]: any }): BikyConfig {
      return state.config
    },
    getBikyImage (state: {[key: string]: any }): string {
      return state.config?.image || `${process.env.VUE_APP_STORAGE_URL}/files/ai/montes_profile_5.png`
    },
    isBikyActive (state: {[key: string]: any }) : boolean {
      return state.config && state?.config?.active
    },
    getBikyLines (state: {[key: string]: any }) : Array<string> {
      return state?.config?.lines || []
    }
  }
}
