import { mapState } from 'vuex'

import AppsUseCases from '@/modules/Apps/application/AppsUseCases'
import AppsController from '@/modules/Apps/infrastructure/controllers/Apps.controller'
import { EntityTypeEnum } from '@/modules/CDP/shared/contacts/domain/Contacts'
import LoadingNext from '@/modules/metrics/infrastructure/ui/components/generic/LoadingNext.vue'
import { CDPFieldsEnums } from '@/modules/CDP/shared/contacts/domain/enums/CDPFieldsEnums'

export default {
  name: 'ButtonCustomizeField',
  components: {
    LoadingNext
  },
  props: {
    defaultTemplate: {
      type: Boolean
    }
  },
  computed: {
    ...mapState('AppStore', ['selectedApp']),
    ...mapState('UserStore', ['token']),
    category () {
      return this.selectedApp?.categories.toString()
    },
    changeParamsVisible: {
      get () {
        return this.$store.state.CampaingsStore.changeParamsVisible || false
      },
      set (value) {
        this.$store.commit('CampaingsStore/SET_EDITING_OBJECT', {
          key: 'changeParamsVisible',
          value
        })
      }
    },
    insertCustomFieldHtml: {
      get () {
        return this.$store.state.CampaingsStore.insertCustomFieldHtml
      },
      set (value) {
        this.$store.commit('CampaingsStore/SET_EDITING_OBJECT', {
          key: 'insertCustomFieldHtml',
          value
        })
      }
    }
  },
  created () {
    this.insertCustomFieldHtml = ''
    this.customizationItems()
    this.getExtendedFields()
  },
  watch: {
  },
  data () {
    return {
      selected: null,
      loading: true,
      customExtradata: [],
      customExtradataFields: [],
      appsController: new AppsController(),
      typeEntity: EntityTypeEnum.PEOPLE,
      extendedFields: []
    }
  },
  methods: {
    concatData () {
      const vue = this
      // Eliminar elementos que tienen "children"
      const filteredArray2 = this.extendedFields.filter(parent => !parent.children)
      // Procesar los elementos "children" y crear elementos adicionales
      const processedArray2 = []
      this.extendedFields.forEach(parent => {
        if (parent.children) {
          parent.children.forEach(child => {
            processedArray2.push({
              label: vue.$t(child.field),
              value: `{{${parent.field}.${child.field}}}`
            })
          })
        }
      })
      // Concatenar y transformar los arrays
      const concatenatedArray = filteredArray2.map(item => (
        {
          label: vue.$t(item.field),
          value: `{{${item.field}}}`
        }
      )).concat(this.customExtradataFields, processedArray2)
      this.customExtradataFields = concatenatedArray
    },
    async getExtendedFields () {
      try {
        const data = {
          module: 'cdp',
          typeEntity: this.typeEntity,
          category: this.category,
          token: this.token
        }
        const response = await AppsUseCases.getExtendedFields(this.appsController, data)
        if (response?.status) {
          this.extendedFields = response?.data[0]?.fields
          this.concatData()
          this.loading = false
        }
      } catch (e) {
        this.loading = false
      }
    },
    copyCustomizedField (customizedField) {
      // if (!this.defaultTemplate) {
      this.$emit('input', customizedField)
      // this.insertCustomFieldHtml = customizedField
      // this.changeParamsVisible = false
      // }
    },
    customizationItems () {
      const nameValue = this.$route.name === 'CreateTrigger' || this.$route.name === 'EditTrigger' ? '{{NAME}}' : '{{name}}'
      if (this.customExtradata &&
          Array.isArray(this.customExtradata) &&
          this.customExtradata.length) {
        this.customExtradataFields = this.customExtradata.map(extraData => {
          if (typeof extraData.name === 'string' && extraData.name.split(' ').length > 1) {
            return { label: extraData.name, value: `{{[${extraData.name}]}}` }
          } else {
            return { label: extraData.name, value: `{{${extraData.name}}}` }
          }
        })
      }
      this.customExtradataFields.unshift(...[
        { label: this.$t(CDPFieldsEnums.NAME), value: nameValue },
        { label: this.$t(CDPFieldsEnums.PHONE), value: '{{phone}}' },
        { label: this.$t('email'), value: '{{email}}' },
        { label: this.$t('unsubscribeLink'), value: '{{UNSUBSCRIBE}}' }
      ])
    }
  },
  beforeDestroy () {
    this.insertCustomFieldHtml = ''
  }
}
