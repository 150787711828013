import { EventBus } from '@/eventBus'
import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import Colors from '@/utils/Colors'
import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'

export default {
  components: {
    KeybeIcon
  },
  props: {
    value: {
      type: String
    }
  },
  data () {
    return {
      KeybeIconType,
      Colors
    }
  },
  methods: {
    copy () {
      const string = this.value
      navigator.clipboard.writeText(string).then(() => {
        EventBus.$emit('toast', 'success', this.$t('copiedToClipboard'))
      }).catch(() => {
        EventBus.$emit('toast', 'error', this.$t('errorCopying'))
      })
    }
  }
}
