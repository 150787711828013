import { mapActions, mapState } from 'vuex'
import { FloatingWindowType } from '../../../enums/Layout/FloatingWindowType'
import LottieAnimation from 'lottie-web-vue'
import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import { EventBus } from '@/eventBus'
// modules
import { OutboundTabs } from '@/modules/outbound/infrastructure/enums/OutboundTabs'
import OnboardingScreen from '@/modules/onboarding/infrastructure/ui/OnboardingScreen.vue'
import ConsumptionScreen from '@/modules/consumptions/infrastructure/ui/ConsumptionsScreen.vue'
import MicrositesManager from '@/modules/microsites/infrastructure/ui/MicrositesManager.vue'
import MetricsScreen from '@/modules/metrics/infrastructure/ui/MetricsScreen/MetricsScreen.vue'
import OutboundScreen from '@/modules/outbound/infrastructure/ui/OutboundScreen/OutboundScreen.vue'
import AiModuleScreen from '@/modules/ai/infrastructure/ui/AiModuleScreen.vue'
import ConfigurationScreen from '@/modules/configuration/infrastructure/ui/Screens/ConfigurationScreen/ConfigurationScreen.vue'
import ImportsScreen from '@/modules/imports/infrastructure/Screens/ImportsScreen.vue'
import TasksScreen from '@/modules/tasks/infrastructure/ui/TasksScreen.vue'
import PartnersScreen from '@/modules/Partners/infrastructure/components/PartnersScreen/PartnersScreen.vue'
import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'
import ChatFiltersConfig from '@/modules/ChatFilters/infrastructure/ui/ChatFiltersConfig/ChatFiltersConfig.vue'
import Loader from '@/shared/infrastructure/ui/Loader.vue'
import WhatsappMigrateScreen from '@/modules/WhatsappMigrate/WhatsappMigrateScreen/WhatsappMigrateScreen.vue'
import { ConsumptionsTabs } from '@/modules/consumptions/infrastructure/enums/ConsumptionsTabs'
import BikyConfig from '@/modules/bky/infrastructure/ui/Config/BikyConfig/BikyConfig.vue'
import KBDriveScreen from '@/modules/KBDrive/infrastructure/Screens/KBDriveScreen/KBDriveScreen.vue'
import CatalogScreen from '@/modules/catalog/infrastructure/ui/CatalogScreen/CatalogScreen.vue'
export default {
  name: 'KeybeFloatingContainer',
  components: {
    LottieAnimation,
    OnboardingScreen,
    OutboundScreen,
    MetricsScreen,
    PartnersScreen,
    KeybeIcon,
    AiModuleScreen,
    ConfigurationScreen,
    ImportsScreen,
    TasksScreen,
    Loader,
    WhatsappMigrateScreen,
    ChatFiltersConfig,
    BikyConfig
  },
  data () {
    return {
      OutboundTabs,
      loading: false,
      KeybeIconType,
      noBalance: false,
      plans: false,
      changePlan: false,
      addAccountBalance: false,
      width: 0,
      totalHeight: 0,
      heightWithoutHeader: 0,
      isExpanded: false,
      segment: null,
      type: null
    }
  },
  mounted () {
    this.setMaximazedWindow(null)
    this.setMinimizedWindows([])

    EventBus.$on('setTypeForm', this.setValue.bind(this))

    this.calculateSize()
    window.addEventListener('resize', () => this.calculateSize())

    EventBus.$on('Consumptions-no-balance', (val) => {
      this.noBalance = val
      if (val) {
        this.setMaximazedWindow(FloatingWindowType.BILLING)
        setTimeout(() => {
          EventBus.$emit('setConsumptionsTab', ConsumptionsTabs.PAYMENTS_METHODS)
        }, 500)
      } else {
        this.close()
      }
    })

    EventBus.$on('Consumptions-plans', (val) => {
      this.plans = val

      if (val) {
        this.setMaximazedWindow(FloatingWindowType.BILLING)
        setTimeout(() => {
          EventBus.$emit('setConsumptionsTab', ConsumptionsTabs.PLANS)
        }, 500)
      } else {
        this.close()
      }
    })

    EventBus.$on('Consumptions-add-balance', (val) => {
      this.addAccountBalance = val

      if (val) {
        this.setMaximazedWindow(FloatingWindowType.BILLING)
      } else {
        this.close()
      }
    })

    EventBus.$on('Consumptions-change-plan', (val) => {
      this.changePlan = val

      if (val) {
        this.setMaximazedWindow(FloatingWindowType.BILLING)
      } else {
        this.close()
      }
    })

    if (this.failedChunkComponent) {
      setTimeout(() => {
        this.setMaximazedWindow(this.failedChunkComponent)
        this.setFailedChunkComponent('')
      }, 500)
    }
    const urlParams = new URLSearchParams(window.location.search)
    const bspMigrate = urlParams.get('bspMigrate')

    if (bspMigrate) {
      this.setMaximazedWindow(FloatingWindowType.WHATSAPP_MIGRATE)
    }
  },
  beforeDestroy () {
    EventBus.$off('Consumptions-plans')
    EventBus.$off('Consumptions-add-balance')
    EventBus.$off('setTypeForm', this.setValue.bind(this))
    window.removeEventListener('resize', () => this.calculateSize())
  },
  computed: {
    ...mapState('FloatingWindowsStore', ['maximazedWindow']),
    ...mapState('AppStore', ['failedChunkComponent']),
    windowComponent () {
      const componentsTable = {
        [FloatingWindowType.ONBOARDING]: OnboardingScreen,
        [FloatingWindowType.BILLING]: ConsumptionScreen,
        [FloatingWindowType.MY_KEYBE]: MicrositesManager,
        [FloatingWindowType.METRICS]: MetricsScreen,
        [FloatingWindowType.CAMPAIGNS]: OutboundScreen,
        [FloatingWindowType.AI]: AiModuleScreen,
        [FloatingWindowType.CONFIGURATION]: ConfigurationScreen,
        [FloatingWindowType.IMPORTS]: ImportsScreen,
        [FloatingWindowType.TASKS]: TasksScreen,
        [FloatingWindowType.WHATSAPP_MIGRATE]: WhatsappMigrateScreen,
        [FloatingWindowType.CHAT_FILTERS]: ChatFiltersConfig,
        [FloatingWindowType.BIKY_CONFIG]: BikyConfig,
        [FloatingWindowType.KB_DRIVE]: KBDriveScreen,
        [FloatingWindowType.PARTNERS]: PartnersScreen,
        [FloatingWindowType.CATALOG]: CatalogScreen
      }
      return componentsTable[this.maximazedWindow]
    },
    currentSelectedTab: {
      get () {
        return this.$store.state.CampaingsStore.currentSelectedTab || null
      },
      set (value) {
        this.$store.commit('CampaingsStore/SET_EDITING_OBJECT', {
          key: 'currentSelectedTab',
          value
        })
      }
    },
    templateMediaUrl: {
      get () {
        return this.$store.state.CampaingsStore.templateMediaUrl
      },
      set (value) {
        this.$store.commit('CampaingsStore/SET_EDITING_OBJECT', {
          key: 'templateMediaUrl',
          value
        })
      }
    },
    onDetail: {
      get () {
        return this.$store.state.CommissionStore.onDetail
      },
      set (value) {
        this.$store.commit('CommissionStore/SET_EDITING_OBJECT', {
          key: 'onDetail',
          value
        })
      }
    },
    onCreate: {
      get () {
        return this.$store.state.CommissionStore.onCreate || false
      },
      set (value) {
        this.$store.commit('CommissionStore/SET_EDITING_OBJECT', {
          key: 'onCreate',
          value
        })
      }
    },
    onCategory: {
      get () {
        return this.$store.state.CommissionStore.onCategory || false
      },
      set (value) {
        this.$store.commit('CommissionStore/SET_EDITING_OBJECT', {
          key: 'onCategory',
          value
        })
      }
    }
  },
  methods: {
    ...mapActions('FloatingWindowsStore', [
      'setMaximazedWindow',
      'addMinimizedWindow',
      'setMinimizedWindows'
    ]),
    ...mapActions('TasksStore', ['setFilters']),
    ...mapActions('AppStore', ['setFailedChunkComponent']),
    minimize () {
      this.isExpanded = false
      const window = this.maximazedWindow
      this.setMaximazedWindow(null)
      setTimeout(() => {
        this.addMinimizedWindow(window)
      }, 100)
    },
    setValue (item) {
      this.setMaximazedWindow(FloatingWindowType.CAMPAIGNS)
      const { type, setSegment } = item
      this.type = type
      if (setSegment) {
        this.segment = setSegment
      }
      let typeOutbound = ''
      if (type === 'whatsapp') {
        typeOutbound = OutboundTabs.NEW_WHATSAPP
      }
      if (type === 'sms') {
        typeOutbound = OutboundTabs.NEW_SMS
      }
      if (type === 'email') {
        typeOutbound = OutboundTabs.NEW_EMAIL
      }
      setTimeout(() => {
        this.selectTab(typeOutbound)
      }, 100)
    },
    selectTab (tab: OutboundTabs) {
      this.currentSelectedTab = tab
    },
    close () {
      this.isExpanded = false
      this.setMaximazedWindow(null)
      this.noBalance = false
      this.plans = false
      this.templateMediaUrl = null
      this.onDetail = false
      this.onCreate = false
      this.onCategory = false
      this.changePlan = false
    },
    expand () {
      this.isExpanded = !this.isExpanded
      this.calculateSize(this.isExpanded)
      if (!this.isExpanded) {
        this.setInitialPosition()
      }
    },
    dragElement (elmnt: HTMLElement) {
      let pos1 = 0
      let pos2 = 0
      let pos3 = 0
      let pos4 = 0
      if (document.getElementById(elmnt.id + '__header')) {
        // if present, the header is where you move the DIV from:
        document.getElementById(elmnt.id + '__header').onmousedown =
          dragMouseDown
      } else {
        // otherwise, move the DIV from anywhere inside the DIV:
        elmnt.onmousedown = dragMouseDown
      }

      function dragMouseDown (e) {
        e = e || window.event
        e.preventDefault()
        // get the mouse cursor position at startup:
        pos3 = e.clientX
        pos4 = e.clientY
        document.onmouseup = closeDragElement
        // call a function whenever the cursor moves:
        document.onmousemove = elementDrag
      }

      function elementDrag (e) {
        e = e || window.event
        e.preventDefault()
        // calculate the new cursor position:
        pos1 = pos3 - e.clientX
        pos2 = pos4 - e.clientY
        pos3 = e.clientX
        pos4 = e.clientY
        // set the element's new position:
        elmnt.style.top = elmnt.offsetTop - pos2 + 'px'
        elmnt.style.left = elmnt.offsetLeft - pos1 + 'px'
      }

      function closeDragElement () {
        // stop moving when mouse button is released:
        document.onmouseup = null
        document.onmousemove = null
      }
    },
    calculateSize (fullscreen = false) {
      if (fullscreen) {
        const clientHeight = document.documentElement.clientHeight
        const clientWidth = document.documentElement.clientWidth
        document.documentElement.style.setProperty(
          '--floating-screen-height',
          `${clientHeight - 30}px`
        )
        document.documentElement.style.setProperty(
          '--floating-screen-width',
          `${clientWidth - 50}px`
        )

        // set top and left to 0
        const floatingWindow = document.getElementById('KeybeFloatingBox')
        floatingWindow.style.top = '0px'
        floatingWindow.style.left = '0px'

        EventBus.$emit('FloatingWindow-resize')
        return
      }

      const clientHeight = document.documentElement.clientHeight
      this.totalHeight = clientHeight * 0.8097
      this.heightWithoutHeader = clientHeight * 0.8097 - 30
      document.documentElement.style.setProperty(
        '--floating-screen-height',
        `${this.heightWithoutHeader}px`
      )

      const clientWidth = document.documentElement.clientWidth
      this.width = clientWidth * 0.8
      document.documentElement.style.setProperty(
        '--floating-screen-width',
        `${this.width}px`
      )

      EventBus.$emit('FloatingWindow-resize')
    },
    setInitialPosition () {
      // set initial position
      const floatingWindow = document.getElementById('KeybeFloatingBox')
      // calculate position to place floating window in center of the screen
      const windowWidth = window.innerWidth
      const windowHeight = window.innerHeight
      const left = windowWidth / 2 - this.width / 2
      const top = windowHeight / 2 - this.totalHeight / 2
      floatingWindow.style.left = `${left}px`
      floatingWindow.style.top = `${top}px`
    }
  },
  watch: {
    maximazedWindow (value: FloatingWindowType) {
      if (value) {
        setTimeout(() => {
          this.isExpanded = false
          this.calculateSize()
          this.setInitialPosition()
          this.dragElement(document.getElementById('KeybeFloatingBox'))
        }, 100)
      }
    },
    isExpanded (value: boolean) {
      if (value) {
        // document.getElementById(elmnt.id + '__header').onmousedown remove this event
        document.getElementById('KeybeFloatingBox__header').onmousedown = null
      } else {
        this.dragElement(document.getElementById('KeybeFloatingBox'))
      }
    }
  }
}
