import KeybeRepository from '@/shared/infrastructure/repositories/KeybeRepository'
import { GenericResponse } from '@/models/apis/GenericResponse'
import { HeadersBuilder } from '@/shared/infrastructure/http/HeadersBuilder'
import { getOptions, nonGetOptions } from '@/shared/infrastructure/http/HttpClient'
import { getAllFormsDTO } from '@/modules/Config/WidgetTesseract/domain/WidgetTesseractController'

export default class WidgetTesseractRepository extends KeybeRepository {
    baseUrl: string

  // abort controllers
  getAllFormsAbortController: AbortController
  getFormByUrlAbortController: AbortController
  getFormByIdAbortController: AbortController
  getConfigWidgetAbortController: AbortController
  createFormAbortController: AbortController
  updateFormAbortController: AbortController
  deleteFormAbortController: AbortController

  constructor () {
    super()

    this.baseUrl = process.env.VUE_APP_TESSERACT_FORM
    this.getAllFormsAbortController = new AbortController()
    this.getFormByUrlAbortController = new AbortController()
    this.getFormByIdAbortController = new AbortController()
    this.getConfigWidgetAbortController = new AbortController()
    this.createFormAbortController = new AbortController()
    this.updateFormAbortController = new AbortController()
    this.deleteFormAbortController = new AbortController()
  }

  async getAllForms (data: getAllFormsDTO): Promise<GenericResponse> {
    const url = `${this.baseUrl}/forms?appUUID=${data.appUUID}`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    const requestOptions: getOptions = {
      headers,
      url,
      abortController: this.getConfigWidgetAbortController
    }

    return await this.client.get(requestOptions)
  }

  async getFormByUrl (data: any): Promise<GenericResponse> {
    const url = `${this.baseUrl}/forms`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    const requestOptions: getOptions = {
      headers,
      url,
      abortController: this.getConfigWidgetAbortController
    }

    return await this.client.get(requestOptions)
  }

  async getFormById (data: any): Promise<GenericResponse> {
    const url = `${this.baseUrl}/forms`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    const requestOptions: getOptions = {
      headers,
      url,
      abortController: this.getConfigWidgetAbortController
    }

    return await this.client.get(requestOptions)
  }

  async createForm (data: any): Promise<GenericResponse> {
    const url = `${this.baseUrl}/forms`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    const requestOptions: nonGetOptions = {
      headers,
      url,
      data: data,
      abortController: this.getConfigWidgetAbortController
    }

    return await this.client.post(requestOptions)
  }

  async updateForm (data: any): Promise<GenericResponse> {
    const url = `${this.baseUrl}/forms`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    const requestOptions: nonGetOptions = {
      headers,
      url,
      data: data,
      abortController: this.getConfigWidgetAbortController
    }

    return await this.client.put(requestOptions)
  }

  async deleteForm (data: any): Promise<GenericResponse> {
    const url = `${this.baseUrl}/forms`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    const requestOptions: getOptions = {
      headers,
      url,
      abortController: this.getConfigWidgetAbortController
    }

    return await this.client.delete(requestOptions)
  }

  abortAll (): void {
    this.getAllFormsAbortController.abort()
    this.getFormByUrlAbortController.abort()
    this.getFormByIdAbortController.abort()
    this.getConfigWidgetAbortController.abort()
    this.createFormAbortController.abort()
    this.updateFormAbortController.abort()
    this.deleteFormAbortController.abort()
  }
}
