

















import { OnboardingSteps } from '@/modules/onboarding/domain/enums/OnboardingSteps'

import OnboardingHeader from '@/modules/onboarding/infrastructure/ui/components/OnboardingHeader.vue'
import OnboardingWelcome from '@/modules/onboarding/infrastructure/ui/components/OnboardingWelcome.vue'
import OnboardingCatalog from '@/modules/onboarding/infrastructure/ui/components/OnboardingCatalog.vue'
import OnboardingChannels from '@/modules/onboarding/infrastructure/ui/components/OnboardingChannels.vue'
import OnboardingTeam from '@/modules/onboarding/infrastructure/ui/components/OnboardingTeam.vue'

export default {
  name: 'OnboardingScreen',
  components: {
    OnboardingHeader
  },
  data () {
    return {
      lipsum: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque lobortis eu enim a bibendum.',
      step: OnboardingSteps.WELCOME,
      OnboardingSteps
    }
  },
  computed: {
    title () {
      const titles = {
        [OnboardingSteps.WELCOME]: this.$t('welcomeToKeybe'),
        [OnboardingSteps.CATALOG]: this.$t('whatDoYouSell'),
        [OnboardingSteps.CHANNELS]: this.$t('whereDoYouSell'),
        [OnboardingSteps.TEAM]: this.$t('whoSellsIt')
      }
      return titles[this.step] || ''
    },
    subtitle () {
      const subtitles = {
        [OnboardingSteps.WELCOME]: this.$t('welcomeToKeybeSubtitle'),
        [OnboardingSteps.CATALOG]: this.$t('whatDoYouSellSubtitle'),
        [OnboardingSteps.CHANNELS]: this.$t('whereDoYouSellSubtitle'),
        [OnboardingSteps.TEAM]: this.$t('whoSellsItSubtitle')
      }
      return subtitles[this.step] || ''
    },
    showBackArrow () {
      return this.step !== OnboardingSteps.WELCOME
    },
    showOmitButton () {
      return this.step !== OnboardingSteps.WELCOME
    },
    stepComponent () {
      const components = {
        [OnboardingSteps.WELCOME]: OnboardingWelcome,
        [OnboardingSteps.CATALOG]: OnboardingCatalog,
        [OnboardingSteps.CHANNELS]: OnboardingChannels,
        [OnboardingSteps.TEAM]: OnboardingTeam
      }
      return components[this.step] || OnboardingWelcome
    }
  }
}
