<template>
  <div>
    <!-- <span class="inputLabel">{{ $t('yourMessage') }}</span> -->
    <KeybeEmojiPicker v-if="openEmojiPicker"
                      :open="openEmojiPicker"
                      @close="openEmojiPicker = false"
                      @select="onSelectEmoji"
    />
    <div v-if="changeParamsVisible"
         style="position:relative">
      <ButtonCustomizeField class="pl-1 mb-3"
                            :defaultTemplate="defaultTemplate"
                            :campaignType="campaignType"/>
    </div>
    <div
      id="campaings--TemplateEditor_message"
      class="campaings--TemplateEditor_message"
      v-if="defaultTemplate">
      <label
        v-show="showTemplateLabel"
        for="campaings--TemplateEditor_message_textarea"
        class="campaings--TemplateEditor_message_label text--text">{{templateLabel}} </label>
      <textarea
        @scroll="handleScroll"
        @keydown.enter.prevent
        @keyup.enter.prevent
        id="campaings--TemplateEditor_message_textarea"
        class="campaings--TemplateEditor_message_textarea"
        :style="`color: ${$vuetify.theme.dark ? '#fff' : '#000'};`"
        :dark="$vuetify.theme.dark"
        :class="$vuetify.theme.dark ? 'backgroundColorDark' : 'backgroundColor'"
        v-model="message"
        @input="getMessagePreview"
        @keydown.delete="getMessagePreview"
        :placeholder="$t('messageHere')"/>
    </div>
    <div
      class="mb-4"
      id="TemplateComposer__templateInput"
      :class="[(lengthContent ? 'smallText':''), ($vuetify.theme.dark ? 'TemplateComposer__templateInputDark' : 'TemplateComposer__templateInput')]"
      v-if="!defaultTemplate"
      v-html="parseTemplateMessage(parsedTemplateMessage ? parsedTemplateMessage : template.templateMessage)">
    </div>
    <MediaSelector
      class="pb-3"
      v-if="showMediaSelector"
      :type="isBSP?fileType:template.type"
      :url="templateMediaUrl"
      @fileUploaded="templateMediaUrl = $event"/>
    <div
      v-if="buttonsParams.length > 0"
      class="OutBoundTemplateComposer__buttonsText">
      <div
        v-for="(buttonParam, i) in buttonsParams"
        :key="buttonParam"
        class="OutBoundTemplateComposer__buttonsText--button">
        <span class="inputLabel">{{buttonsLabels[i]}}</span>
        <div class="OutBoundTemplateComposer__buttonsText--buttonInput">
          <span>{{processButtonLinkLabel(buttonsParams[i])}}</span>
          <input
            :placeholder="$t('addAnUrl')"
            type="text"
            v-model="buttonsParamsLinksInputs[i]">
        </div>
        <strong
          @click="validateButtonUrl({
            label: buttonParam,
            param: buttonsParamsLinks[i]
          })"
          class="checkBtn" >{{$t('check')}}</strong>
      </div>
    </div>
  </div>
</template>
<script>
import CampaingsMessages from './CampaingsMessages'
export default CampaingsMessages
</script>

<style lang="scss">
  @import './CampaingsMessages.scss';
</style>
