<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       fill="none"
       viewBox="0 0 10 10"><path :fill="color"
                                 d="M3.128 8.107h-.17c-.547 0-1.093-.002-1.64.001-.44.003-.807-.14-1.073-.505a1.216 1.216 0 0 1-.242-.732C0 4.995-.003 3.119.004 1.243.007.543.567.005 1.288.003 3.762 0 6.237-.001 8.71.004c.757.003 1.283.542 1.285 1.304a839.45 839.45 0 0 1-.001 5.497c-.002.528-.223.955-.716 1.18a1.57 1.57 0 0 1-.618.118c-.868.012-1.736.003-2.604.008a.397.397 0 0 0-.223.074c-.76.568-1.515 1.14-2.27 1.712-.156.118-.243.14-.335.044a.387.387 0 0 1-.097-.24c-.008-.475-.004-.95-.004-1.427v-.167.001Zm.94.308.165-.12c.438-.33.874-.662 1.314-.987a.406.406 0 0 1 .223-.075c.968-.005 1.936-.003 2.905-.003.297 0 .413-.112.413-.408.002-1.825.002-3.648 0-5.473 0-.295-.118-.411-.411-.411h-7.32c-.3 0-.412.107-.412.407v5.472c0 .313.104.413.418.413h2.705v1.185Z"/></svg>
</template>

<script>
export default {
  name: 'ChatOutlinedIcon',
  props: {
    color: {
      type: String,
      default: '#fff'
    }
  }
}
</script>

<style>

</style>
