import KeybeRepository from '@/shared/infrastructure/repositories/KeybeRepository'
import { GenericResponse } from '@/shared/infrastructure/http/GenericResponse'
import { HeadersBuilder } from '@/shared/infrastructure/http/HeadersBuilder'
import { getOptions } from '@/shared/infrastructure/http/HttpClient'
import { dataToGetCities, dataToGetCountries, dataToGetStates } from '../../domain/LocationController'

export default class LocationRepository extends KeybeRepository {
  getSuggestionsAbortController: AbortController

  constructor () {
    super()

    this.getSuggestionsAbortController = new AbortController()
  }

  abortGetSuggestions () {
    this.getSuggestionsAbortController.abort()
    this.getSuggestionsAbortController = new AbortController()
  }

  async getCountries (data: dataToGetCountries): Promise<GenericResponse> {
    const { app } = data
    const url = `${process.env.VUE_APP_CONTACTS_HTTP}/${app}/demographics/countries`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    delete data.token
    const requestOptions: getOptions = {
      headers,
      url,
      query: data.query,
      abortController: this.getSuggestionsAbortController
    }

    return await this.client.get(requestOptions)
  }

  async getStates (data: dataToGetStates): Promise<GenericResponse> {
    const { app, countryCode } = data
    const url = `${process.env.VUE_APP_CONTACTS_HTTP}/${app}/demographics/provinces/${countryCode}`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    const requestOptions: getOptions = {
      headers,
      url,
      query: data.query,
      abortController: this.getSuggestionsAbortController
    }

    return await this.client.get(requestOptions)
  }

  async getCities (data: dataToGetCities): Promise<GenericResponse> {
    const { app, countryCode, provinceCode } = data
    const url = `${process.env.VUE_APP_CONTACTS_HTTP}/${app}/demographics/cities/${countryCode}/${provinceCode}`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    const requestOptions: getOptions = {
      headers,
      url,
      query: data.query,
      abortController: this.getSuggestionsAbortController
    }

    return await this.client.get(requestOptions)
  }
}
