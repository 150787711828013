import AdvisersService from '@/components/conversations/Services/AdvisersService'

export default class AdvisersController {
  static async getAdvisers () {
    const response = await AdvisersService.getAdvisers()
    if (!response.status) {
      return []
    }

    const advisers = response.data.data.sort(function (a, b) {
      if (!a.name || !b.name) return 0
      const nameA = a.name.toUpperCase() // ignore upper and lowercase
      const nameB = b.name.toUpperCase() // ignore upper and lowercase
      if (nameA < nameB) {
        return -1
      }
      if (nameA > nameB) {
        return 1
      }
      return 0
    })
    return advisers
  }
}
