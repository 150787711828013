<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       fill="none"
       viewBox="0 0 10 10"><path :fill="color"
                                 d="M1.643 5.619H.523c-.45-.002-.659-.37-.429-.76.324-.549.653-1.096.979-1.645.295-.496.742-.726 1.308-.735.47-.007.938-.003 1.407 0 .089.002.14-.028.19-.1.362-.54.779-1.033 1.305-1.423A4.756 4.756 0 0 1 7.44.074a6.28 6.28 0 0 1 1.493-.05c.173.014.347.034.514.076.265.068.419.247.462.52.127.793.121 1.585-.081 2.364-.241.927-.684 1.744-1.41 2.384-.263.232-.549.44-.829.65-.07.054-.1.105-.1.193.004.473 0 .947.002 1.42.003.581-.248 1.015-.746 1.311-.542.32-1.085.638-1.621.968-.33.204-.758.007-.75-.415.014-.708.005-1.416.003-2.124 0-.312-.047-.614-.204-.89-.326-.572-.825-.85-1.476-.862-.351-.006-.703 0-1.055 0Zm2.22-.664c.11.066.216.117.31.185.326.244.601.537.803.893.035.06.06.104.148.076.546-.176 1.086-.362 1.59-.64.596-.33 1.125-.743 1.542-1.28.722-.93.883-2.005.774-3.141-.003-.033-.058-.083-.093-.088a5.188 5.188 0 0 0-1.324.022c-.96.141-1.763.571-2.403 1.308-.672.774-1.074 1.681-1.347 2.665Z"/><path :fill="color"
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              d="M.008 9.981a7.534 7.534 0 0 1 .116-1.713C.2 7.854.327 7.463.55 7.104c.58-.927 1.718-1.108 2.539-.479.77.591.892 1.774.182 2.524-.268.283-.616.467-.99.58-.729.223-1.478.291-2.238.266-.008 0-.016-.006-.036-.014Zm1.532-1.24c.23-.007.483-.054.71-.2.19-.122.28-.351.235-.601a.531.531 0 0 0-.445-.432.566.566 0 0 0-.588.241c-.145.227-.193.48-.202.742-.01.27-.008.27.29.25ZM6.414 2.789a.795.795 0 0 1 .772-.777c.409-.001.772.364.773.778a.755.755 0 0 1-.774.773.746.746 0 0 1-.77-.774Z"/></svg>
</template>

<script>
export default {
  name: 'RocketIcon',
  props: {
    color: {
      type: String,
      default: '#fff'
    }
  }
}
</script>

<style>

</style>
