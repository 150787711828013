import { VecindarioConfigUseCases } from '@/modules/bky/application/VecindarioConfigUseCases'
import { BikySkill } from '@/modules/bky/domain/BikySkill'
import { VecindarioConfig, VecindarioCurrencies, VecindarioLanguages } from '@/modules/bky/domain/VecindarioConfig'
import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import ContextListMenu from '@/shared/infrastructure/ui/ContextListMenu.vue'
import { mapState } from 'vuex'
import VecindarioConfigController from '../../../controllers/VecindarioConfig.controller'
import BikyConfigSkillBubble from '../BikyConfigSkillBubble/BikyConfigSkillBubble.vue'

export default {
  name: 'BikyConfigSchedule',
  components: {
    BikyConfigSkillBubble,
    ContextListMenu
  },
  data () {
    return {
      config: null,
      vecindarioConfigController: new VecindarioConfigController(),
      configUrl: '',
      scheduleUrl: '',
      menu: false
    }
  },
  methods: {
    async getConfig (): Promise<void> {
      const config: VecindarioConfig = await VecindarioConfigUseCases.getConfig(this.vecindarioConfigController, {
        appUUID: this.selectedApp?.uuid,
        token: this.token
      })

      this.config = config

      if (this.config) {
        await this.getConfigUrl()
        await this.getScheduleUrl()
      }
    },
    async createConfig (): Promise<void> {
      await VecindarioConfigUseCases.createVecindarioConfig(this.vecindarioConfigController, {
        token: this.token,
        data: {
          title: this.selectedApp?.name,
          language: VecindarioLanguages.ES,
          currency: VecindarioCurrencies.COP,
          appUUID: this.selectedApp?.uuid
        }
      })
      await this.getConfig()
    },
    async getConfigUrl (): Promise<void> {
      const url: string = await VecindarioConfigUseCases.getConfigUrl(this.vecindarioConfigController, {
        token: this.token,
        data: {
          slug: this.config?.vecindarioSlug,
          email: this.user?.email
        }
      })

      this.configUrl = url
    },
    async getScheduleUrl (): Promise<void> {
      const url: string = await VecindarioConfigUseCases.getCalendarUrl(this.vecindarioConfigController, {
        token: this.token,
        data: {
          slug: this.config?.vecindarioSlug,
          email: this.user?.email
        }
      })

      this.scheduleUrl = url
    },
    async openConfig (): Promise<void> {
      if (!this.config) {
        this.$emit('start-loading')
        await this.createConfig()
        this.$emit('stop-loading')
      }
      window.open(this.configUrl, '_blank')
    },
    async openSchedule (): Promise<void> {
      if (!this.config) {
        this.$emit('start-loading')
        await this.createConfig()
        this.$emit('stop-loading')
      }
      window.open(this.scheduleUrl, '_blank')
    },
    async updateConfig (data: { _id: string, active: boolean }): Promise<void> {
      await VecindarioConfigUseCases.updateVecindarioConfig(this.vecindarioConfigController, {
        token: this.token,
        data
      })
      await this.getConfig()
    }
  },
  computed: {
    ...mapState('AppStore', ['selectedApp', 'advisers']),
    ...mapState('UserStore', ['user', 'token']),
    menuItems (): {
        text: string,
        callback: () => void
    }[] {
      return [
        {
          text: this.config?.active ? this.$t('disable') : this.$t('enable'),
          callback: () => {
            this.$emit('start-loading')
            if (!this.config) {
              // this.createConfig()
              this.$emit('configSkill', this.skill)
            } else {
              this.updateConfig({
                _id: this.config?._id,
                active: !this.config?.active
              })
            }
            this.$emit('stop-loading')
          }
        },
        {
          text: this.$t('openConfig'),
          callback: () => {
            this.openConfig()
          }
        },
        {
          text: this.$t('openSchedule'),
          callback: () => {
            this.openSchedule()
          }
        }
      ]
    },
    skill (): BikySkill {
      return {
        title: this.$t('scheduling'),
        icon: KeybeIconType.SCHEDULE,
        active: this.config?.active
      }
    }
  },
  async mounted () {
    this.$emit('start-loading')
    await this.getConfig()
    this.$emit('stop-loading')
  },
  async beforeDestroy () {
    VecindarioConfigUseCases.abortAll(this.vecindarioConfigController)
  }
}
