export enum VerticalWhatsAppEnum {
  AUTO = 'AUTO',
  BEAUTY = 'BEAUTY',
  APPAREL = 'APPAREL',
  EDU = 'EDU',
  ENTERTAIN = 'ENTERTAIN',
  EVENT_PLAN = 'EVENT_PLAN',
  FINANCE = 'FINANCE',
  GROCERY = 'GROCERY',
  GOVT = 'GOVT',
  HOTEL = 'HOTEL',
  HEALTH = 'HEALTH',
  NONPROFIT = 'NONPROFIT',
  PROF_SERVICES = 'PROF_SERVICES',
  RETAIL = 'RETAIL',
  TRAVEL = 'TRAVEL',
  RESTAURANT = 'RESTAURANT',
  OTHER = 'OTHER',
}

export interface WhatsAppBusiness {
  about?: string,
  address?: string,
  description?: string,
  email?: string,
  profile_picture_url?: string,
  websites?: string[],
  vertical?: VerticalWhatsAppEnum,
  messaging_product?: string
}
