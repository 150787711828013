import { ChatFilter } from '../../domain/ChatFilter'
import { IChatFilterController, chatFilterCreateDTO, chatFilterUpdateDTO, dataToGetChatFilters, deleteFilterDTO, favoriteActionsDTO } from '../../domain/ChatFilterController'
import ChatFilterRepository from '../repositories/ChatFilter.respository'

export default class ChatFilterController implements IChatFilterController {
  repository: ChatFilterRepository

  constructor () {
    this.repository = new ChatFilterRepository()
  }

  async getChatFilters (data: dataToGetChatFilters): Promise<ChatFilter[]> {
    const response = await this.repository.getChatFilters(data)
    if (!response.status) {
      return []
    }

    return response.data
  }

  async createChatFilter (data: chatFilterCreateDTO): Promise<ChatFilter> {
    const response = await this.repository.createChatFilter(data)
    if (!response.status) {
      return null
    }
    return response.data
  }

  async updateChatFilter (data: chatFilterUpdateDTO): Promise<ChatFilter> {
    const response = await this.repository.updateChatFilter(data)
    if (!response.status) {
      return null
    }
    return response.data
  }

  async deleteChatFilter (data: deleteFilterDTO): Promise<boolean> {
    const response = await this.repository.deleteChatFilter(data)
    return response?.status || false
  }

  async favoriteChatFilter (data: favoriteActionsDTO): Promise<ChatFilter> {
    const response = await this.repository.favoriteChatFilter(data)
    if (!response.status) {
      return null
    }
    return response.data
  }

  async unfavoriteChatFilter (data: favoriteActionsDTO): Promise<ChatFilter> {
    const response = await this.repository.unfavoriteChatFilter(data)
    if (!response.status) {
      return null
    }
    return response.data
  }
}
