<template>
  <svg :width="width"
       :height="height"
       viewBox="0 0 13 11"
       fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <path d="M3.43281 2.09687L2.77012 3.92857H10.2299L9.56719 2.09687C9.45293 1.78259 9.1457 1.57143 8.80039 1.57143H4.19961C3.8543 1.57143 3.54707 1.78259 3.43281 2.09687ZM1.00547 4.04643L1.89922 1.57879C2.24199 0.633482 3.16367 0 4.19961 0H8.80039C9.83633 0 10.758 0.633482 11.1008 1.57879L11.9945 4.04643C12.5836 4.28214 13 4.84442 13 5.5V9.03571V10.2143C13 10.6489 12.6369 11 12.1875 11H11.375C10.9256 11 10.5625 10.6489 10.5625 10.2143V9.03571H2.4375V10.2143C2.4375 10.6489 2.07441 11 1.625 11H0.8125C0.363086 11 0 10.6489 0 10.2143V9.03571V5.5C0 4.84442 0.416406 4.28214 1.00547 4.04643ZM3.25 6.28571C3.25 6.07733 3.1644 5.87748 3.01202 5.73013C2.85965 5.58278 2.65299 5.5 2.4375 5.5C2.22201 5.5 2.01535 5.58278 1.86298 5.73013C1.7106 5.87748 1.625 6.07733 1.625 6.28571C1.625 6.4941 1.7106 6.69395 1.86298 6.8413C2.01535 6.98865 2.22201 7.07143 2.4375 7.07143C2.65299 7.07143 2.85965 6.98865 3.01202 6.8413C3.1644 6.69395 3.25 6.4941 3.25 6.28571ZM10.5625 7.07143C10.778 7.07143 10.9847 6.98865 11.137 6.8413C11.2894 6.69395 11.375 6.4941 11.375 6.28571C11.375 6.07733 11.2894 5.87748 11.137 5.73013C10.9847 5.58278 10.778 5.5 10.5625 5.5C10.347 5.5 10.1403 5.58278 9.98798 5.73013C9.8356 5.87748 9.75 6.07733 9.75 6.28571C9.75 6.4941 9.8356 6.69395 9.98798 6.8413C10.1403 6.98865 10.347 7.07143 10.5625 7.07143Z"
          :fill="color"/>
  </svg>
</template>
<script>
export default {
  name: 'AutoIcon',
  props: {
    width: {
      type: Number,
      default: 13
    },
    height: {
      type: Number,
      default: 11
    },
    color: {
      type: String,
      default: '#B6BEC7'
    }
  }
}
</script>
