
import { EventBus } from '@/eventBus'
import TemplateService from '@/services/TemplateService'
import EmailTemplateCreatorHomeModal from '@/modules/outbound/infrastructure/ui/components/forms/EmailTemplateCreatorHomeModal.vue'
import Loader from '@/shared/infrastructure/ui/Loader.vue'
import KeybeSelect from '@/modules/DesignSystem/infrastructure/components/KeybeSelect/KeybeSelect/KeybeSelect.vue'
import KeybeButton from '@/modules/DesignSystem/infrastructure/components/KeybeButton/KeybeButton.vue'
import { mapState } from 'vuex'

export default {
  components: {
    EmailTemplateCreatorHomeModal,
    Loader,
    KeybeButton,
    KeybeSelect
  },
  props: {
    emailTemplates: {
      type: Boolean,
      default: false
    },
    actionDisabled: {
      type: Boolean,
      default: false
    },
    count: {
      type: Number,
      default: 0
    }
  },
  computed: {
    ...mapState('UserStore', ['isUserReadonly'])
  },
  created () {
    this.getTemplates()
  },
  data () {
    return {
      templateSelect: null,
      activeModal: false,
      idTemplate: null,
      modal: false,
      tab: null,
      options: {
        limit: 500,
        itemsPerPage: 500,
        page: 1,
        sortBy: ['updatedAt'],
        sortDesc: [true]
      },
      searchTemplateList: '',
      templateList: [],
      totalTemplateList: 0,
      loadingTemplateList: false
    }
  },
  methods: {
    selectTemplate (templateId) {
      if (this.idTemplate !== templateId) {
        this.idTemplate = templateId
        this.activeModal = true
        this.templateSelect = templateId
        // document.querySelector('iframe').style.width = '368px'
        // document.querySelector('iframe').style.minWidth = '368px'
      }
    },
    previewTemplate (templateId) {
      if (this.idTemplate !== templateId) {
        this.idTemplate = templateId
        this.activeModal = true
      }
    },
    async getTemplates () {
      try {
        // if (this.emailTemplates) {
        //   this.options.itemsPerPage = 500
        //   this.options.limit = 500
        // }
        this.loadingTemplateList = true
        const pagination = {
          itemsPerPage: this.options.itemsPerPage,
          limit: this.options.limit,
          descending: this.options.sortDesc[0],
          page: this.options.page,
          sortBy: this.options.sortBy[0]
        }
        const response = await TemplateService.getTemplatesV6(
          pagination,
          this.searchTemplateList
        )
        this.templateList = response.rows
        this.selectTemplate(response.rows[0]._id)
        this.totalTemplateList = response.count
        this.loadingTemplateList = false
      } catch (e) {
        this.templateList = []
        this.totalTemplateList = 0
        this.loadingTemplateList = false
      }
    },
    editTemplate (templateId) {
      if (!this.actionDisabled) {
        EventBus.$emit('campaignTemplateEditor', templateId)
        if (templateId) {
          this.$emit('campaignTemplateEditor', templateId)
        } else {
          this.$emit('campaignTemplateEditor')
        }
      } else {
        EventBus.$emit('toast', 'error', this.$t('msgErrorForm'))
      }
    },
    createTemplate () {
      this.$emit('campaignTemplateEditor')
      EventBus.$emit('campaignTemplateEditor')
    }
  },
  watch: {
    searchTemplateList () {
      this.options.page = 1
      this.getTemplates()
    },
    count () {
      if (this.count) {
        this.getTemplates()
      }
    }
  }
}
