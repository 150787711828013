import ConversationsService from '@/services/conversationsService'
import store from '@/store'

export default class PushNotificationsController {
  static async setOneSignalStatus (data) {
    const oneSignalUserId = store.getters['ConversationStore/getOneSignalUserId']
    if (!oneSignalUserId) {
      console.log('====================================')
      console.log('No one signal user id')
      console.log('====================================')
      return false
    }
    const response = await ConversationsService.setOneSignalStatus(data)
    if (!response.status) {
      return false
    }
    store.commit('ConversationStore/SET_ONE_SIGNAL_REGISTERED',
      response?.data?.data?.item?.active || false
    )
    return true
  }
}
