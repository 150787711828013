















export default {
  name: 'ContextListMenu',
  props: {
    items: {
      type: Array,
      required: true
    }
  }
}
