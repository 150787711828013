<template>
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 19 18"><path :fill="color" d="M.016 10.057c0-1.781-.023-3.564.008-5.345.021-1.31 1.133-2.471 2.442-2.545 1.108-.062 2.221-.04 3.332-.051a55.87 55.87 0 0 1 1.49 0c.488.01.754.395.566.814-.119.266-.36.306-.613.307-1.238.002-2.476 0-3.714.002-.291 0-.587-.019-.873.024-.996.147-1.54.81-1.542 1.854-.003 1.893 0 3.785 0 5.677v4.187c0 .435.081.847.346 1.204.352.472.845.686 1.415.687 3.375.009 6.749.01 10.124.001 1.058-.003 1.76-.759 1.765-1.868.005-1.38.001-2.76.002-4.14 0-.102 0-.205.012-.306a.523.523 0 0 1 .517-.489.539.539 0 0 1 .545.402.75.75 0 0 1 .032.21c-.003 1.552.028 3.107-.022 4.659-.045 1.428-1.083 2.47-2.505 2.62-.219.022-.44.036-.66.036-3.233.002-6.465.005-9.698 0-.95 0-1.782-.291-2.387-1.072-.434-.56-.6-1.201-.598-1.9.006-1.656.002-3.312.002-4.968h.014Z"/><path :fill="color" d="M17.994 2.684c-.005.641-.257 1.177-.704 1.625-2.57 2.582-5.138 5.164-7.71 7.744a2.594 2.594 0 0 1-1.274.686c-.973.229-1.948.454-2.922.678-.583.134-.945-.225-.811-.812.199-.867.407-1.732.605-2.6.119-.517.26-1.025.608-1.444.076-.09.152-.181.235-.264C8.552 5.788 11.085 3.282 13.614.77c.415-.413.878-.703 1.477-.758.525-.049 1.016.05 1.437.349.489.347.941.755 1.209 1.305.151.31.216.665.319.999l-.06.018h-.002Zm-12.176 9.49c.882-.213 1.7-.405 2.513-.614.114-.03.22-.124.308-.211 1.991-1.976 3.98-3.955 5.968-5.934.06-.058.116-.12.16-.17l-2.023-2.021c-.007.006-.027.018-.043.035-1.99 1.989-3.98 3.977-5.965 5.97-.114.114-.227.259-.268.409-.223.818-.425 1.643-.65 2.535Zm9.885-7.696c.268-.307.535-.628.818-.934.374-.406.492-.992.208-1.433a3.226 3.226 0 0 0-.684-.738c-.457-.369-1.022-.369-1.474.01-.358.3-.666.66-.986.98l2.118 2.114Z"/></svg>
</template>

<script>
export default {
  name: 'WriteIcon',
  props: {
    color: {
      type: String,
      default: '#B6BEC7'
    }
  }

}
</script>

<style>

</style>
