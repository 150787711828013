import CollapsedScreen from './Screens/CollapsedScreen/CollapsedScreen.vue'
import ExpandedScreen from './Screens/ExpandedScreen/ExpandedScreen.vue'
import ConfigFormBiky from './ConfigFormBiky/ConfigFormBiky.vue'

export default {
  name: 'CreateFormBiky',
  props: {
    dataTocreate: {
      type: Object,
      required: true
    },
    selectedType: {
      type: String,
      required: true
    }
  },
  components: {
    CollapsedScreen,
    ExpandedScreen,
    ConfigFormBiky
  },
  methods: {
    changeTypeBiky (value: string):void {
      this.$emit('changeTypeBiky', value)
    },
    updateButtonColor (value: string):void {
      this.$emit('updateButtonColor', value)
    },
    updateTypography (value: string): void {
      this.$emit('updateTypography', value)
    },
    updateTextColor (value: string): void {
      this.$emit('updateTextColor', value)
    },
    updateTextSize (value: string): void {
      this.$emit('updateTextSize', value)
    },
    updateButtonText (value: string): void {
      this.$emit('updateButtonText', value)
    },
    updateMessage (value: string):void {
      this.$emit('updateMessage', value)
    },
    updateFieldColor (value: string):void {
      this.$emit('updateFieldColor', value)
    },
    updateBackgroundColor (value:string):void {
      this.$emit('updateBackgroundColor', value)
    },
    updateButtonTextExpanded (value:string):void {
      this.$emit('updateButtonTextExpanded', value)
    },
    updatebuttonTextColor (value: string): void {
      this.$emit('updatebuttonTextColor', value)
    },
    updateFormId (value: string): void {
      this.$emit('updateFormId', value)
    }
  }
}
