export const orderDataEndingReason = (item) => {
  const advisors = []
  const reasons = []
  const seriesComputed = []
  const endReasons = item
  endReasons.forEach(advisor => {
    // We fill an array with all of the advisors names
    if (!advisors.includes(advisor.advisor_name)) {
      if (advisor.advisor_name === undefined) return
      if (advisor.advisor_name === null) return
      advisors.push(advisor.advisor_name)
    }
    // We fill an array with all of the end reasons for each advisor if it isn't already in the array
    advisor.data_ended.forEach(reason => {
      if (!reasons.includes(reason.Raison)) {
        reasons.push(reason.Raison)
      }
    })
  })
  // Here we create the series for the chart
  for (let index = 0; index < reasons.length; index++) {
    const element = reasons[index]
    seriesComputed.push({
      name: element,
      type: 'bar',
      stack: 'end reasons',
      data: []
    })
  }
  // Here we fill the series with the data
  advisors.forEach(advisor => {
    const advisorData = []
    reasons.forEach(reason => {
      const advisorReason = endReasons.find(advisorReason => advisorReason.advisor_name === advisor && advisorReason.data_ended.find(advisorReason => advisorReason.Raison === reason))

      if (advisorReason) {
        advisorData.push(advisorReason.data_ended.find(advisorReason => advisorReason.Raison === reason).total_count)
      } else {
        advisorData.push(0)
      }
    })
    seriesComputed.forEach((series, index) => {
      series.data.push(advisorData[index])
    })
  })

  return {
    seriesComputed,
    advisors
  }
}
