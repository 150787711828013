import store from '@/store'

export default class CompanyStoreController {
  static getSelectedCompany () {
    return store.getters['CompanyStore/getSelectedCompany']
  }

  static getSelectedCompanyUUID () {
    return store.getters['CompanyStore/getSelectedCompany']?.uuid || ''
  }

  static setAdministrators (administrators) {
    return store.dispatch('CompanyStore/setAdministrators', administrators)
  }

  static getAdministrators () {
    return store.getters['CompanyStore/getAdministrators']
  }
}
