






































import { mapActions, mapState } from 'vuex'
import { EventBus } from '@/eventBus'
import Colors from '@/shared/domain/colors/Color'
import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import { OnboardingSteps } from '@/modules/onboarding/domain/enums/OnboardingSteps'
import { FloatingWindowType } from '@/shared/infrastructure/enums/Layout/FloatingWindowType'
import { ConfigurationTabsEnum } from '@/modules/configuration/application/enums/ConfigurationTabsEnum'
import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'

export default {
  name: 'OnboardingWelcome',
  components: {
    KeybeIcon
  },
  data () {
    return {
      KeybeIconType,
      Colors,
      OnboardingSteps
    }
  },
  computed: {
    ...mapState('UserStore', ['user']),
    ...mapState('AppStore', ['selectedApp']),
    userEmail () {
      return this.user?.email
    },
    selectedAppName () {
      return this.selectedApp?.name
    }
  },
  methods: {
    ...mapActions('FloatingWindowsStore', ['setMaximazedWindow']),
    openCatalogConfig () {
      this.setMaximazedWindow(FloatingWindowType.CONFIGURATION)
      setTimeout(() => {
        EventBus.$emit('setConfigurationTab', ConfigurationTabsEnum.CATALOG)
      }, 500)
    },
    goToKBFlows () {
      const url = `${process.env.VUE_APP_MAKE_REDIRECTION_URL}?name=${encodeURIComponent(this.selectedAppName)}&email=${encodeURIComponent(this.userEmail)}`
      window.open(url, '_blank')
    }
  }
}
