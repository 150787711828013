import KBSelect from '@/shared/infrastructure/ui/inputs/select/KBSelect.vue'
import LottieAnimation from 'lottie-web-vue'
import CampaignsService from '@/services/CampaignsService'
import KBButton from '@/shared/infrastructure/ui/buttons/KBButton.vue'

export default {
  name: 'Scheduled',
  components: {
    KBButton,
    LottieAnimation,
    KBSelect
  },
  data () {
    return {
      formDate: {
        DD: null,
        MM: null,
        YY: null,
        HH: null,
        Mm: null

      },
      itemsDD: [],
      itemsMM: [
        1, 2, 3, 4, 5, 6,
        7, 8, 9, 10, 11, 12
      ],
      itemsYY: [],
      itemsHH: [],
      itemsMn: [],
      diffDateValue: null
    }
  },
  computed: {
    summaryData: {
      get () {
        return this.$store.state.CampaingsStore.summaryData || null
      },
      set (value) {
        this.$store.commit('CampaingsStore/SET_EDITING_OBJECT', {
          key: 'summaryData',
          value
        })
      }
    }
  },
  watch: {
    summaryData () {
      if (this.summaryData) {
        this.setValues()
      }
    }
  },
  mounted () {
    this.getYears()
    this.getDays()
    this.getHours()
    this.getMn()
    this.setValues()
    // this.diffDate()
  },
  methods: {
    // diffDate () {
    //   if (this.summaryData) {
    //     const fechaInicio = new Date().getTime()
    //     if (this.summaryData?.schedule) {
    //       const { nextRunAt } = this.summaryData?.schedule
    //       const fechaFin = new Date(nextRunAt).getTime()
    //       const diff = fechaFin - fechaInicio
    //       this.diffDateValue = diff / (1000 * 60 * 60 * 24)
    //       // const hoursSpan = diff.getHours()
    //       // const minutesSpan = diff.getMinutes()
    //       // const secondsSpan = diff.getSeconds()
    //       // this.diffDateValue = { hoursSpan, minutesSpan, secondsSpan }
    //     }
    //     this.diffDateValue = 0
    //   }
    // },
    async cancelShipment () {
      try {
        const response = await CampaignsService.cancelCampaign(this.summaryData.id ? this.summaryData.id : this.summaryData._id)
        if (response) {
          this.summaryData = response.data?.displayCampaign
        }
      } catch (e) {
        console.log(e)
      }
    },
    setValues () {
      if (this.summaryData.schedule) {
        console.log(this.summaryData.schedule)
        const { nextRunAt } = this.summaryData.schedule
        const scheduleDate = new Date(nextRunAt)
        if (scheduleDate) {
          this.formDate.DD = scheduleDate.getDate()
          this.formDate.MM = (scheduleDate.getMonth() + 1) < 10 ? '0' + +(scheduleDate.getMonth() + 1) : scheduleDate.getMonth() + 1
          this.formDate.YY = scheduleDate.getFullYear()
          this.formDate.HH = scheduleDate.getHours()
          this.formDate.Mm = scheduleDate.getMinutes()
        }
      }
    },
    getYears () {
      const currentYear = new Date().getFullYear()
      let startYear = currentYear
      const stopYear = currentYear + 10
      while (startYear <= stopYear) {
        this.itemsYY.push(startYear++)
      }
    },
    getHours () {
      const HH = 24
      let startHH = 0
      while (this.itemsHH.length < HH) {
        const insert = (startHH++).toString()
        this.itemsHH.push(insert)
      }
    },
    getMn () {
      const Mn = 60
      let startMn = 0
      while (this.itemsMn.length < Mn) {
        const insert = (startMn++).toString()
        this.itemsMn.push(insert)
      }
    },
    getDays () {
      const year = +this.formDate.YY || new Date().getFullYear()
      const month = this.formDate.MM ? +this.formDate.MM : 2
      const days = new Date(year, month, 0).getDate()
      let startDay = 1
      while (this.itemsDD.length < days) {
        this.itemsDD.push(startDay++)
      }
    }
  }
}
