import Colors from '@/utils/Colors'
import { mapState } from 'vuex'
import extradataTypes from '@/utils/extradataTypes'
import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import KeybeText from '@/modules/DesignSystem/infrastructure/components/KeybeText/KeybeText.vue'
import KeybeSelect from '@/modules/DesignSystem/infrastructure/components/KeybeSelect/KeybeSelect/KeybeSelect.vue'
import KeybeButton from '@/modules/DesignSystem/infrastructure/components/KeybeButton/KeybeButton.vue'
import KBIconButton from '@/shared/infrastructure/ui/buttons/KBIconButton.vue'
import EmitToast from '@/utils/EmitToast'
import { ExtradataTypeEnum } from '@/modules/Apps/domain/enums/ExtradataTypeEnum'
import ExtraDataUseCases from '@/modules/ExtraData/application/ExtraDataUseCases'
import ExtraDataController from '../../controllers/ExtraData.controller'
import { CDPFieldsEnums } from '@/modules/CDP/shared/contacts/domain/enums/CDPFieldsEnums'
export default {
  components: {
    KeybeText,
    KeybeSelect,
    KeybeButton,
    KBIconButton
  },
  props: {
    extradata: {
      type: Object
    },
    typeEntity: {
      type: String
    },
    isEdit: {
      type: Boolean
    }
  },
  computed: {
    ...mapState('AppStore', ['selectedApp']),
    ...mapState('UserStore', ['token', 'isUserReadonly']),
    showOptions () {
      if (this.input.typeField &&
            (this.input.typeField === ExtradataTypeEnum.CHECKBOX ||
            this.input.typeField === ExtradataTypeEnum.SELECT ||
            this.input.typeField === ExtradataTypeEnum.SELECT_DEPENDANT ||
            this.input.typeField === ExtradataTypeEnum.RADIO)
      ) return true
      else return false
    }
  },
  mounted () {
    if (this.isEdit) this.input = this.extradata
    extradataTypes.forEach(type => {
      this.fieldTypes.push({
        text: this.$t(type.text),
        value: type.value
      })
    })
  },
  data () {
    return {
      rerender: 0,
      options: [],
      loading: false,
      option: '',
      input: {
        name: '',
        typeField: '',
        typeEntity: this.typeEntity,
        options: [],
        order: null,
        showFilter: true,
        readonly: false,
        internal: true
      },
      fieldTypes: [],
      extraDataController: new ExtraDataController(),
      Colors,
      KeybeIconType,
      CDPFieldsEnums
    }
  },
  methods: {
    submit () {
      if (this.isEdit) this.updateExtradata()
      else this.createExtradata()
    },
    validate () {
      if (!this.input.name) {
        EmitToast.emitErrors(this.$t('extradataNameRequired'))
        return false
      }
      if (!this.input.typeField) {
        EmitToast.emitErrors(this.$t('extradataTypeFieldRequired'))
        return false
      }
      if (this.showOptions && this.input?.options?.length === 0) {
        EmitToast.emitErrors(this.$t('extradataOptionsRequired'))
        return false
      }

      if (this.input.order) this.input.order = +this.input.order
      return true
    },
    async createExtradata () {
      try {
        if (!this.validate()) return
        this.loading = true
        const data = {
          app: this.selectedApp?.uuid,
          token: this.token,
          createInput: this.input
        }
        const response = await ExtraDataUseCases.createExtraData(this.extraDataController, data)
        if (response?.status) {
          EmitToast.emitSuccess(this.$t('extradataCreated'))
          this.$emit('close')
        } else EmitToast.emitErrors(response)
        this.loading = false
      } catch (e) {
        this.loading = false
        console.error(e)
      }
    },
    async updateExtradata () {
      try {
        if (!this.validate()) return
        this.loading = true
        const data = {
          app: this.selectedApp?.uuid,
          token: this.token,
          id: this.input?._id,
          updateInput: this.input
        }
        await ExtraDataUseCases.updateExtraData(this.extraDataController, data)
        EmitToast.emitSuccess(this.$t('extradataUpdated'))
        this.$emit('close')
        this.loading = false
      } catch (e) {
        console.error(e)
        this.loading = false
      }
    },
    addOption () {
      if (!this.input.options) this.input.options = []
      const found = this.input.options.find(op => op.name === this.option)
      if (!found) {
        this.input.options.push({
          name: this.option
        })
        this.option = ''
        this.rerender++
      } else EmitToast.emitErrors(this.$t('thisOptionAlreadyExists'))
    },
    removeOption (index) {
      this.input.options.splice(index, 1)
    }
  }
}
