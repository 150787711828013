import { render, staticRenderFns } from "./KBDriveScreen.vue?vue&type=template&id=140f6dda&scoped=true&"
import script from "./KBDriveScreen.vue?vue&type=script&lang=ts&"
export * from "./KBDriveScreen.vue?vue&type=script&lang=ts&"
import style0 from "./KBDriveScreen.vue?vue&type=style&index=0&id=140f6dda&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "140f6dda",
  null
  
)

export default component.exports