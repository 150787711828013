










































export default {
  name: 'PeopleComissionsIcon'
}
