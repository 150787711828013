import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import { EventBus } from '@/eventBus'
import { mapState } from 'vuex'
import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'
import KBExpansionPanel from '@/shared/infrastructure/ui/menus/KBExpansionPanel.vue'
import WidgetTesseractUseCases from '@/modules/Config/WidgetTesseract/application/WidgetTesseractUseCases'
import { getAllFormsDTO } from '@/modules/Config/WidgetTesseract/domain/WidgetTesseractController'
import WidgetTesseractController from '@/modules/Config/WidgetTesseract/infrastructure/WidgetTesseract.controller'
import { formScript } from '@/modules/Config/WidgetTesseract/infrastructure/ui/CreateFormLanding/formScript'

export default {
  name: 'AutoConnectFormsConfig',
  components: {
    KeybeIcon,
    KBExpansionPanel
  },
  data () {
    return {
      KeybeIconType,
      formsConfig: [],
      widgetTesseractController: new WidgetTesseractController()
    }
  },
  computed: {
    ...mapState('AppStore', ['selectedApp']),
    ...mapState('UserStore', ['token'])
  },
  mounted () {
    this.getFormsConfig()
  },
  methods: {
    generateCode (appKey, containerId, formId) {
      return formScript(appKey, containerId, formId)
    },
    async copyToClipboard (text) {
      try {
        await navigator.clipboard.writeText(text)
      } catch (error) {
        EventBus.$emit('toast', 'error', this.$t('scriptCopyError'))
      }
    },
    async copyHtml (index: number): Promise<void> {
      const formIndex = this.formsConfig[index]
      if (!formIndex) {
        return
      }
      let containerId = formIndex?.landingConfig?.desktop?.containerId
      if (!containerId) {
        containerId = `keybe-form-container-${index}`
      }
      const htmlText: string = `<div id="${containerId}"></div>`
      await this.copyToClipboard(htmlText)
      EventBus.$emit('toast', 'success', this.$t('scriptCopied'))
    },
    async copyScript (index: number): Promise<void> {
      const formIndex = this.formsConfig[index]
      if (!formIndex) {
        return
      }
      let containerId = formIndex?.landingConfig?.desktop?.containerId
      if (!containerId) {
        containerId = `keybe-form-container-${index}`
      }
      const scriptText = this.generateCode(formIndex?.apiKey, containerId, formIndex?._id)
      await this.copyToClipboard(scriptText)
      EventBus.$emit('toast', 'success', this.$t('scriptCopied'))
    },
    async getFormsConfig () {
      const dataGetForm:getAllFormsDTO = { appUUID: this.selectedApp?.uuid || '', token: this.token }
      const response = await WidgetTesseractUseCases.getAllForms(this.widgetTesseractController, dataGetForm)
      this.formsConfig = response
    }
  }
}
