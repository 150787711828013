


































export default {
  name: 'WizardTitleDecorationIcon.vue',
  props: {
    color: {
      type: String,
      default: '#3AF69B'
    }
  }
}
