import { BikyKnowledgeFragment, KnowledgeBaseTypeEnum } from '@/modules/bky/domain/BikyConfig'
import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'

export default {
  name: 'BikyConfigKnowledgeBaseItem',
  components: {
    KeybeIcon
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      KeybeIconType,
      isEditing: false,
      editingTitle: '',
      editingDescription: ''
    }
  },
  computed: {
    knowledgeFragment (): BikyKnowledgeFragment {
      return this.item
    },
    textAreaId (): string {
      return `BikyConfigKnowledgeBaseItem__container__content__description__textarea__${this.knowledgeFragment._id}`
    },
    title (): string {
      const rawText = this.knowledgeFragment.text
      const titleIndex = rawText.indexOf('TITLE: ')
      const descriptionIndex = rawText.indexOf('CONTENT: ')

      const title = rawText.substring(titleIndex + 7, descriptionIndex - 1)
      return title
    },
    description (): string {
      const rawText = this.knowledgeFragment.text
      const descriptionIndex = rawText.indexOf('CONTENT: ')
      const description = rawText.substring(descriptionIndex + 9, rawText.length)
      return description
    },
    icon (): KeybeIconType {
      const icons = {
        [KnowledgeBaseTypeEnum.PDF]: KeybeIconType.PDF,
        [KnowledgeBaseTypeEnum.WEB]: KeybeIconType.LINK,
        [KnowledgeBaseTypeEnum.USER]: KeybeIconType.DEFAULT_USER,
        [KnowledgeBaseTypeEnum.MY_KEYBE]: KeybeIconType.SHOPPING_CART
      }
      return icons[this.knowledgeFragment.type]
    }
  },
  methods: {
    edit () {
      this.editingTitle = this.title
      this.editingDescription = this.description
      this.isEditing = true
      setTimeout(() => {
        this.$refs.description.focus()
      }, 100)
    },
    save () {
      this.$emit('save', {
        _id: this.knowledgeFragment?._id,
        title: this.editingTitle,
        description: this.editingDescription
      })
      this.isEditing = false
    },
    remove () {
      this.$emit('delete', {
        _id: this.knowledgeFragment?._id
      })
    }
  },
  watch: {
    item: {
      inmediate: true,
      handler () {
        this.isEditing = false

        console.log(this.item)

        if (!this.item?.text) {
          this.editingTitle = ''
          this.editingDescription = ''
          setTimeout(() => {
            this.isEditing = true
          }, 100)
        }
      }
    }
  },
  mounted () {
    if (!this.item?.text) {
      this.editingTitle = ''
      this.editingDescription = ''
      setTimeout(() => {
        this.isEditing = true
      }, 100)
    }
  }
}
