import { mapState } from 'vuex'
import KeybeAutocomplete from '@/modules/DesignSystem/infrastructure/components/KeybeAutocomplete/KeybeAutocomplete/KeybeAutocomplete.vue'
import KeybeButton from '@/modules/DesignSystem/infrastructure/components/KeybeButton/KeybeButton.vue'
import KeybeText from '@/modules/DesignSystem/infrastructure/components/KeybeText/KeybeText.vue'
import { dataToGetWhatsAppProducts } from '@/modules/templates/domain/TemplateController'
import TemplateUseCases from '@/modules/templates/application/TemplateUseCases'
import TemplateController from '../../Template.controller'
import Loader from '@/shared/infrastructure/ui/Loader.vue'
import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'
import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import Colors from '@/utils/Colors'
export default {
  components: {
    KeybeAutocomplete,
    KeybeButton,
    KeybeText,
    Loader,
    KeybeIcon
  },
  props: {
    userHost: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapState('AppStore', ['selectedApp']),
    ...mapState('UserStore', ['token'])
  },
  mounted () {
    this.loading = true
    this.getProducts()
  },
  data () {
    return {
      Colors,
      KeybeIconType,
      templateController: new TemplateController(),
      loading: false,
      loadingDelete: false,
      products: [],
      mpmDetails: {
        catalogId: '',
        thumbnailProductRetailerId: '',
        sections: []
      }
    }
  },
  methods: {
    removeProduct (sectionIndex: number, productIndex: number): void {
      this.loadingDelete = true
      if (this.mpmDetails.sections[sectionIndex].productItems.length === 1) return
      this.mpmDetails.sections[sectionIndex].productItems.splice(productIndex, 1)
      setTimeout(() => {
        this.loadingDelete = false
      }, 200)
      this.$emit('updateMpmDetails', this.mpmDetails)
    },
    removeSection (sectionIndex: number): void {
      if (this.mpmDetails?.sections && this.mpmDetails?.sections?.length === 1) return
      this.mpmDetails.sections.splice(sectionIndex, 1)
      this.$emit('updateMpmDetails', this.mpmDetails)
    },
    async getProducts (): Promise<void> {
      const data: dataToGetWhatsAppProducts = {
        token: this.token,
        appUUID: this.selectedApp?.uuid,
        userHost: this.userHost
      }
      const response = await TemplateUseCases.getWhatsAppProducts(this.templateController, data)

      if (response.status) {
        this.products = response?.data?.products || []
        this.mpmDetails.catalogId = response?.data?.catalogId
        this.products.forEach((product: any) => {
          product.name = product.product_catalog.name
        })
        this.addSection()
      }
      this.loading = false
    },
    addSection (): void {
      if (!this.mpmDetails.sections) this.mpmDetails.sections = []
      this.mpmDetails.sections.push({
        title: '',
        productItems: []
      })
      this.addProduct(this.mpmDetails?.sections?.length - 1 || 0)
      this.$emit('updateMpmDetails', this.mpmDetails)
    },
    addProduct (index): void {
      if (!this.mpmDetails.sections[index].productItems) this.mpmDetails.sections[index].productItems = []
      this.mpmDetails.sections[index].productItems.push({
        productRetailerId: ''
      })
      this.$emit('updateMpmDetails', this.mpmDetails)
    }
  }
}
