import { RestResponseFactory } from '@/shared/infrastructure/http/RestResponse'
import store from '@/store'

export default class httpClient {
  // replace axios with fetch request
  static async get (url: string, query?: { [key: string]: any }) {
    try {
      const token = store.getters['UserStore/getToken']
      const parsedUrl = new URL(url)
      if (query) {
        Object.keys(query).forEach((key) => parsedUrl.searchParams.append(key, query[key]))
      }

      const response = await fetch(parsedUrl.toString(), {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'content-type': 'application/json',
          Accept: 'application/json'
        }
      })
      const data = await response.json()
      if (!data?.status) {
        data.status = response.status
      }
      const restResponse = new RestResponseFactory().process({ response: data })
      return restResponse
    } catch (e) {
      return new RestResponseFactory().process({ response: { status: e?.code } })
    }
  }

  static async post (url: string, data: any) {
    try {
      const token = store.getters['UserStore/getToken']
      const response = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          Authorization: `Bearer ${token}`,
          'content-type': 'application/json',
          Accept: 'application/json'
        }
      })
      const parsedResponse = await response.json()
      let restResponse
      if (parsedResponse?.statusCode) {
        restResponse = new RestResponseFactory().process({ response: parsedResponse })
      } else {
        restResponse = new RestResponseFactory().process({
          response: {
            statusCode: response.status,
            data: parsedResponse
          }
        })
      }
      return restResponse
    } catch (e) {
      return new RestResponseFactory().process({ response: { status: e?.code } })
    }
  }

  static async put (url: string, data: any) {
    try {
      const token = store.getters['UserStore/getToken']
      const response = await fetch(url, {
        method: 'PUT',
        body: JSON.stringify(data),
        headers: {
          Authorization: `Bearer ${token}`,
          'content-type': 'application/json',
          Accept: 'application/json'
        }
      })
      const parsedResponse = await response.json()
      const restResponse = new RestResponseFactory().process({ response: parsedResponse })
      return restResponse
    } catch (e) {
      return new RestResponseFactory().process({ response: { status: e?.code } })
    }
  }

  static async patch (url: string, data: any) {
    try {
      const token = store.getters['UserStore/getToken']
      const response = await fetch(url, {
        method: 'PATCH',
        body: JSON.stringify(data),
        headers: {
          Authorization: `Bearer ${token}`,
          'content-type': 'application/json',
          Accept: 'application/json'
        }
      })
      const parsedResponse = await response.json()
      const restResponse = new RestResponseFactory().process({ response: parsedResponse })
      return restResponse
    } catch (e) {
      return new RestResponseFactory().process({ response: { status: e?.code } })
    }
  }

  static async delete (url: string, data: any) {
    try {
      const token = store.getters['UserStore/getToken']
      const response = await fetch(url, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
          'content-type': 'application/json',
          Accept: 'application/json'
        },
        body: JSON.stringify(data)
      })
      const parsedResponse = await response.json()
      const restResponse = new RestResponseFactory().process({ response: parsedResponse })
      return restResponse
    } catch (e) {
      return new RestResponseFactory().process({ response: { status: e?.code } })
    }
  }
}
