














export default {
  name: 'GoogleMeetIcon'
}
