var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[(_vm.migrate)?_c('facebook-login',{attrs:{"label":_vm.$t('migrateWhatsappAccount'),"appId":_vm.whatsappAppId,"loginParams":{
      scope: _vm.whatsappPermissions.join(','),
      auth_type: 'rerequest',
      extras: {
        feature: 'whatsapp_embedded_signup',
        version: 2,
        setup: {},
        featureType: '',
        sessionInfoVersion: '3',
      },
    }}}):_c('facebook-login',{attrs:{"label":_vm.$t('addNewWhatsappAccount'),"appId":_vm.whatsappAppId,"loginParams":{
      scope: _vm.whatsappPermissions.join(','),
      auth_type: 'rerequest',
      extras: {
        feature: 'whatsapp_embedded_signup',
        version: 2
      }
    },"buttonText":_vm.$t('addNewWhatsappAccount'),"another-line":_vm.anotherLine},on:{"gotAccessToken":_vm.gotAccessToken}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }