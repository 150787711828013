import { mapState } from 'vuex'
import KBButton from '@/shared/infrastructure/ui/buttons/KBButton.vue'
import KBExpansionPanel from '@/shared/infrastructure/ui/menus/KBExpansionPanel.vue'
import QuickResponsesConfiguration from '@/modules/Config/QuickResponsesConfiguration/infrastructure/components/QuickResponsesConfiguration/QuickResponsesConfiguration'
import { EventBus } from '@/eventBus'
export default {
  components: {
    KBButton,
    KBExpansionPanel,
    QuickResponsesConfiguration
  },
  computed: {
    ...mapState('OnboardingStore', ['onboardingStep'])
  },
  mounted () {
    EventBus.$on('openQuickResponses--general-config', () => this.forceOpenResponses(this))
  },
  data () {
    return {
      open: false,
      forceOpen: false
    }
  },
  methods: {
    setOpen (e) {
      this.open = e
    },
    forceOpenResponses () {
      this.forceOpen = true
    }
  },
  beforeDestroy () {
    EventBus.$off('openQuickResponses--general-config', () => this.forceOpenResponses(this))
  }
}
