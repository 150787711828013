import { LoggedUser } from '../domain/LoggedUser'
import { IUserController, dataToGetUser, dataToUpdateUser, dataToUploadImage, userGetter, userGetterError, userImageUpdater } from '../domain/UserController'

export default class UserUseCases {
  static abort (controller: IUserController) : void {
    controller.abort()
  }

  static async getUser (controller: IUserController, data: dataToGetUser) : Promise<userGetter> {
    return await controller.getUser(data)
  }

  static async uploadUserImage (controller: IUserController, data: dataToUploadImage) : Promise<userImageUpdater> {
    return await controller.uploadUserImage(data)
  }

  static async updateUser (controller: IUserController, data: dataToUpdateUser) : Promise<LoggedUser | userGetterError> {
    return await controller.updateUser(data)
  }
}
