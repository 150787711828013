import { CreateBikyMessageErrorDto, CreateWebsiteDto, IBikyConfigController, UpdateBikyMessageErrorDto, createFileDTO, createKnowledgeBaseDTO, createPronunciationDTO, dataToGetTestAudio, deleteBikyMessageErrorDTO, deleteFileDTO, deleteKnowledgeBaseDTO, deletePronunciationDTO, deleteWebsiteDTO, getBikyMessageErrorsDTO, getConfigDTO, getFilesDTO, getKnowledgeBaseDTO, getWebsitesDTO, updateConfigDTO, updateKnowledgeBaseDTO, updatePronunciationDTO } from '@/modules/bky/domain/BikyConfigController'
import { BikyFile, BikyKnowledgeFragment, BikyMessageError, BikyWebsite } from '../domain/BikyConfig'

export class BikyConfigUseCases {
  static getBikyConfig (controller:IBikyConfigController, data: getConfigDTO) {
    return controller.getBikyConfig(data)
  }

  static getPronunciations (controller:IBikyConfigController, data: getConfigDTO) {
    return controller.getPronunciations(data)
  }

  static createBikyDefaultConfig (controller: IBikyConfigController, data: getConfigDTO): Promise<boolean> {
    return controller.createBikyDefaultConfig(data)
  }

  static updateBikyConfig (controller: IBikyConfigController, data: updateConfigDTO): Promise<boolean> {
    return controller.updateBikyConfig(data)
  }

  static createPronunciation (controller: IBikyConfigController, data: createPronunciationDTO): Promise<boolean> {
    return controller.createPronunciation(data)
  }

  static updatePronunciation (controller: IBikyConfigController, data: updatePronunciationDTO): Promise<boolean> {
    return controller.updatePronunciation(data)
  }

  static deletePronunciation (controller: IBikyConfigController, data: deletePronunciationDTO): Promise<boolean> {
    return controller.deletePronunciation(data)
  }

  static getWebsites (controller: IBikyConfigController, data: getWebsitesDTO): Promise<BikyWebsite[]> {
    return controller.getWebsites(data)
  }

  static createWebsite (controller: IBikyConfigController, data: CreateWebsiteDto): Promise<boolean> {
    return controller.createWebsite(data)
  }

  static deleteWebsite (controller: IBikyConfigController, data: deleteWebsiteDTO): Promise<boolean> {
    return controller.deleteWebsite(data)
  }

  static getFiles (controller: IBikyConfigController, data: getFilesDTO): Promise<BikyFile[]> {
    return controller.getFiles(data)
  }

  static createFile (controller: IBikyConfigController, data: createFileDTO): Promise<boolean> {
    return controller.createFile(data)
  }

  static deleteFile (controller: IBikyConfigController, data: deleteFileDTO): Promise<boolean> {
    return controller.deleteFile(data)
  }

  static getKnowledgeBase (controller: IBikyConfigController, data: getKnowledgeBaseDTO): Promise<{
    documents: BikyKnowledgeFragment[],
    count: number,
}> {
    return controller.getKnowledgeBase(data)
  }

  static createKnowledgeBase (controller: IBikyConfigController, data: createKnowledgeBaseDTO): Promise<boolean> {
    return controller.createKnowledgeBase(data)
  }

  static updateKnowledgeBase (controller: IBikyConfigController, data: updateKnowledgeBaseDTO): Promise<boolean> {
    return controller.updateKnowledgeBase(data)
  }

  static deleteKnowledgeBase (controller: IBikyConfigController, data: deleteKnowledgeBaseDTO): Promise<boolean> {
    return controller.deleteKnowledgeBase(data)
  }

  static getBikyMessageErrors (controller: IBikyConfigController, data: getBikyMessageErrorsDTO): Promise<BikyMessageError[]> {
    return controller.getBikyMessageErrors(data)
  }

  static createBikyMessageError (controller: IBikyConfigController, data: CreateBikyMessageErrorDto): Promise<boolean> {
    return controller.createBikyMessageError(data)
  }

  static updateBikyMessageError (controller: IBikyConfigController, data: UpdateBikyMessageErrorDto): Promise<boolean> {
    return controller.updateBikyMessageError(data)
  }

  static deleteBikyMessageError (controller: IBikyConfigController, data: deleteBikyMessageErrorDTO): Promise<boolean> {
    return controller.deleteBikyMessageError(data)
  }

  static getAudioDemoUrl (controller: IBikyConfigController, data: dataToGetTestAudio): Promise<string> {
    return controller.getAudioDemoUrl(data)
  }

  static getMyKeybeCatalogConfig (controller: IBikyConfigController, data: getConfigDTO): Promise<any> {
    return controller.getMyKeybeCatalogConfig(data)
  }

  static syncMyKeybe (controller: IBikyConfigController, data: getConfigDTO): Promise<boolean> {
    return controller.syncMyKeybe(data)
  }

  static unSyncMyKeybe (controller: IBikyConfigController, data: getConfigDTO): Promise<boolean> {
    return controller.unSyncMyKeybe(data)
  }

  static abortAll (controller: IBikyConfigController): void {
    controller.abortAll()
  }
}
