<template>
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 19 2"><rect width="19" height="1.5" :fill="color" rx=".75"/></svg>
</template>

<script>
export default {
  name: 'MinusIcon',
  props: {
    color: {
      type: String,
      default: '#B6BEC7'
    }
  }
}
</script>

<style>

</style>
