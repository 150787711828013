import { ITeamController, MemberGetter, dataToChangeReadonly, dataToCreateTeamMember, dataToDeleteTeamMember, dataToGetTeamMember, dataToGetTeamMembers, dataToGetTermsByTeamMember, dataToInviteTeamMember, dataToTransferOwnership, dataToUpdateTeamMember, dataToUpdateUserApps, teamMemberCreate, teamMemberGetter, teamMemberInvite } from '../../domain/TeamController'
import { TeamMember } from '../../domain/models/TeamMember'
import TeamRepository from '../repository/Team.repository'
import { BikyUserEnum } from '../../domain/enums/BikyUserEnum'
import { UserApp } from '@/modules/Config/GeneralUserConfiguration/domain/LoggedUser'
export default class TeamController implements ITeamController {
  repository: TeamRepository

  constructor () {
    this.repository = new TeamRepository()
  }

  abortAll (): void {
    this.repository.abortAll()
  }

  async getTeamMembers (data: dataToGetTeamMembers) : Promise<teamMemberGetter> {
    const response = await this.repository.getTeamMembers(data)
    if (response.status) return { team: response.data.users, count: response.data.count, status: true }
    return { status: false, message: response.message }
  }

  async getTeamMembersFilterBiky (data: dataToGetTeamMembers) : Promise<teamMemberGetter> {
    const response = await this.repository.getTeamMembers(data)
    if (response.status) {
      const users: TeamMember[] = response.data.users.filter(
        (user: TeamMember) => !this.isUserBiky(user)
      )
      const count: number = response.data.count - 1
      return { team: users, count: count - 1, status: true }
    }
    return { status: false, message: response.message }
  }

  async inviteTeamMember (data: dataToInviteTeamMember) : Promise<teamMemberInvite> {
    const response = await this.repository.inviteTeamMember(data)
    if (response.status) return { status: true }
    return { status: false, message: response.message }
  }

  async createTeamMember (data: dataToCreateTeamMember) : Promise<teamMemberCreate> {
    const response = await this.repository.createTeamMember(data)
    if (response.status) return { status: true, data: response?.data?.data?.user }
    return { status: false, message: response.message }
  }

  async deleteTeamMember (data: dataToDeleteTeamMember) : Promise<teamMemberCreate> {
    const response = await this.repository.deleteTeamMember(data)
    if (response.status) return { status: true }
    return { status: false, message: response.message }
  }

  async updateTeamMember (data: dataToUpdateTeamMember) : Promise<teamMemberCreate> {
    const response = await this.repository.updateTeamMember(data)
    if (response.status) {
      return { status: true, data: response?.data?.user }
    }
    return { status: false, message: response.message }
  }

  async transferOwnership (data: dataToTransferOwnership) : Promise<teamMemberCreate> {
    const response = await this.repository.transferOwnership(data)
    if (response.status) return { status: true }
    return { status: false, message: response.message }
  }

  async changeReadonly (data: dataToChangeReadonly): Promise<any> {
    const updateData: dataToUpdateTeamMember = {
      token: data.token,
      uuid: data.member.uuid,
      updateInput: {
        appUUID: data.appUUID,
        uuid: data.member.uuid
      }
    }
    const response = await this.updateTeamMember(updateData)
    const findUpdatedUserApp = response.data.userApps.find((userApp: any) => userApp.appUUID === data.appUUID)
    const updateData2: dataToUpdateUserApps = {
      token: data.token,
      appUUID: data.appUUID,
      id: findUpdatedUserApp.id,
      updateInput: {
        readonly: data.member.ua_readonly === 0 ? 1 : 0,
        role: data.member.type
      }
    }
    const response2 = await this.updateUserApps(updateData2)
    if (response.status && response2.status) return { status: true }
    else {
      if (!response.status) return { status: false, message: response.message }
      return { status: false, message: response2.message }
    }
  }

  async getTeamMember (data: dataToGetTeamMember): Promise<MemberGetter> {
    const response = await this.repository.getTeamMember(data)
    if (response.status) return { status: true, data: response.data.user }
    return { status: false, message: response.message }
  }

  async updateUserApps (data: dataToUpdateUserApps): Promise<any> {
    const response = await this.repository.updateUserApps(data)
    if (response.status) return { status: true }
    return { status: false, message: response.message }
  }

  isUserBiky (member: TeamMember): boolean {
    return member?.name === BikyUserEnum.Name && member?.lastName === BikyUserEnum.LastName && member?.email === BikyUserEnum.Email
  }

  areTermsAcceptedByApp (data: dataToGetTermsByTeamMember): boolean {
    const userApps = data?.user?.userApps
    const currentAppExists: boolean = userApps?.some((userApp: any) => userApp.appUUID === data.appUUID) || false

    if (currentAppExists) {
      const currentApp: UserApp = userApps.find((userApp: UserApp) => userApp.appUUID === data.appUUID)
      return currentApp.accept_terms === 1
    } else return false
  }
}
