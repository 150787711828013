import LottieAnimation from 'lottie-web-vue'
import { ConsumptionsTabs } from '@/modules/consumptions/infrastructure/enums/ConsumptionsTabs'
import { formatCurrency } from '@/modules/consumptions/application/FormatCurrency'
import Colors from '@/shared/domain/colors/Color'
import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import { EventBus } from '@/eventBus'
import { mapState, mapActions } from 'vuex'
import BillingValidations from '@/components/configuration/Billing/BillingValidations'
import BillingService from '@/components/configuration/Services/BillingService'
import { PaymentService } from '@/components/configuration/Services/PaymentService'
import Loader from '@/shared/infrastructure/ui/Loader.vue'
import ChargeSVG from '@/shared/assets/ChargeSVG.vue'
import BillingAddCard from '@/modules/consumptions/infrastructure/ui/components/BillingAddCard/BillingAddCard.vue'
import BillingPlansCardsInfo from '@/modules/consumptions/infrastructure/ui/components/BillingPlansCardsInfo/BillingPlansCardsInfo.vue'
import KBCashCard from '@/modules/consumptions/infrastructure/ui/components/KBCashCard.vue'
import CreditCardSelector from '@/modules/consumptions/infrastructure/ui/components/CreditCardSelector/CreditCardSelector.vue'

const minValueToRecharge: number = 0.5

export default {
  name: 'BillingAddPayment',
  components: {
    ChargeSVG,
    KBCashCard,
    CreditCardSelector,
    BillingPlansCardsInfo,
    LottieAnimation,
    Loader,
    BillingAddCard
  },
  props: {
    appInactive: {
      type: Boolean,
      defaul: false
    },
    noBalance: {
      type: Boolean
    },
    creditCards: {
      type: Array,
      required: true
    }
  },
  watch: {
    creditCards () {
      this.validateCommission()
    }
  },
  data () {
    return {
      ConsumptionsTabs,
      KeybeIconType,
      Colors,
      selectedCard: null,
      amount: minValueToRecharge,
      amountError: false,
      cardError: false,
      error: '',
      changeToPro: false,
      loading: false,
      showPlanToggle: false,
      applyPayment: false,
      paySuccess: false,
      disabledAction: false,
      newCommission: false,
      cardsOption: []
    }
  },
  methods: {
    formatCurrency,
    ...mapActions('BillingStore', ['setAppConfig']),
    loadAddBalance () {
      if (this.appInactive) {
        EventBus.$emit('Consumptions-plans', true)
      }
      if (!this.disabledAction) {
        this.changeOption = ConsumptionsTabs.PLANS
      }
    },
    validateCommission () {
      if (this.creditCards.length > 0) {
        this.cardsOption = this.creditCards
        this.selectedCard = this.creditCards[0]
        this.newCommission = false
      } else {
        this.newCommission = true
      }
    },
    async getCreditCards () {
      const response = await PaymentService.getCreditCards()
      if (response.status) {
        this.cardsOption = response.data.map((card) => ({
          id: card.id,
          tokenResponse: {
            brand: card.card.brand,
            last4: card.card.last4
          }
        }))
        this.selectedCard = this.cardsOption[0]
      }
      this.loading = false
    },
    callSuccess () {
      this.paySuccess = true
      this.disabledAction = true
    },
    async updateBillingInfo () {
      const appConfig = await BillingService.getAppConfig()
      this.setAppConfig(appConfig)
    },
    addCreditCard () {
      EventBus.$emit('addCreditCard')
    },
    async showSuccess (message = 'paymentSuccess.recharge') {
      EventBus.$emit('toast', 'success', this.$t(message))
      await this.updateBillingInfo()
      this.stopLoading()
      this.paySuccess = true
      this.applyPayment = false
    },
    showError (message = 'paymentError.noRecharge') {
      EventBus.$emit('toast', 'error', this.$t(message))
    },
    startLoading () {
      this.loading = true
      this.$emit('start-loading')
    },
    stopLoading () {
      this.$emit('stop-loading')
      this.loading = false
    },
    onAddBalance () {
      this.applyPayment = false
      this.loading = false
      this.getCreditCards()
    },
    addPayment () {
      this.applyPayment = true
    },
    handleChangeToPro (e: any) {
      this.changeToPro = e
    }
  },
  computed: {
    ...mapState('BillingStore', ['appConfig']),
    changeOption: {
      get () {
        return this.$store.state.BillingStore.changeOption || null
      },
      set (value) {
        this.$store.commit('BillingStore/SET_EDITING_OBJECT', {
          key: 'changeOption',
          value
        })
      }
    },
    changeOptionSave: {
      get () {
        return this.$store.state.BillingStore.changeOptionSave || null
      },
      set (value) {
        this.$store.commit('BillingStore/SET_EDITING_OBJECT', {
          key: 'changeOptionSave',
          value
        })
      }
    },
    balanceComputed () {
      return this.appConfig?.balanceUSD || 0
    },
    title () {
      return this.$t('paymentMethod')
    }
  },
  async mounted () {
    this.changeOption = null
    if (this.changeOptionSave) {
      this.paySuccess = true
      this.changeOptionSave = false
    }
    this.validateCommission()
    const isPro = BillingValidations.isProfessional()
    if (!isPro) {
      this.showPlanToggle = true
    }
  }
}
