












import operation from '@/utils/operations'
import MicroHome from '@/modules/microsites/infrastructure/ui/components/Microsite/MicroHome.vue'
export default {
  components: {
    MicroHome
  },
  data () {
    return {
      localValue: null,
      window: 0,
      selectPage: 0,
      pages: [
        {
          name: 'Home',
          icon: 'mdi-information-outline'
        }
      ]
    }
  },
  mounted () {
    this.localValue = { ...JSON.parse(JSON.stringify(this.value)) }
  },
  watch: {
    value: {
      handler (val) {
        this.localValue = { ...JSON.parse(JSON.stringify(val)) }
      }
    }
  },
  methods: {
    areEqual (a, b): boolean {
      return operation.areEqual(a, b)
    }
  },
  props: {
    value: {
      required: true,
      type: Object
    }
  }
}
