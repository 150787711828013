import { IMicrositeConfigController, configDTO, dataToGetConfig } from '../domain/MicrositeConfigController'

export default class MicrositeConfigUseCases {
  static getMicrositeConfig (controller: IMicrositeConfigController, data: dataToGetConfig) {
    return controller.getConfig(data)
  }

  static createMicrositeConfig (controller: IMicrositeConfigController, data: configDTO) {
    return controller.createConfig(data)
  }

  static updateMicrositeConfig (controller: IMicrositeConfigController, data: configDTO) {
    return controller.updateConfig(data)
  }
}
