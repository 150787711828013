import { CreateBikyMessageErrorDto, CreateWebsiteDto, UpdateBikyMessageErrorDto, createFileDTO, createKnowledgeBaseDTO, createPronunciationDTO, dataToGetTestAudio, deleteBikyMessageErrorDTO, deleteFileDTO, deleteKnowledgeBaseDTO, deletePronunciationDTO, deleteWebsiteDTO, getBikyMessageErrorsDTO, getConfigDTO, getFilesDTO, getKnowledgeBaseDTO, getWebsitesDTO, updateConfigDTO, updateKnowledgeBaseDTO, updatePronunciationDTO } from '@/modules/bky/domain/BikyConfigController'
import { GenericResponse } from '@/shared/infrastructure/http/GenericResponse'
import { HeadersBuilder } from '@/shared/infrastructure/http/HeadersBuilder'
import { getOptions, nonGetOptions } from '@/shared/infrastructure/http/HttpClient'
import KeybeRepository from '@/shared/infrastructure/repositories/KeybeRepository'

export class BikyConfigRepository extends KeybeRepository {
  baseUrl: string

  // abort controllers
  // base config
  getBikyConfigAbortController: AbortController
  createBikyDefaultConfigAbortController: AbortController
  updateBikyConfigAbortController: AbortController
  // pronunciations
  getPronunciationsAbortController: AbortController
  createPronunciationAbortController: AbortController
  updatePronunciationAbortController: AbortController
  deletePronunciationAbortController: AbortController
  // websites
  getWebsitesAbortController: AbortController
  createWebsiteAbortController: AbortController
  deleteWebsiteAbortController: AbortController
  // files
  getFilesAbortController: AbortController
  createFileAbortController: AbortController
  deleteFileAbortController: AbortController
  // knowledge base
  getKnowledgeBaseAbortController: AbortController
  createKnowledgeBaseAbortController: AbortController
  updateKnowledgeBaseAbortController: AbortController
  deleteKnowledgeBaseAbortController: AbortController
  // biky message errors
  getBikyMessageErrorsAbortController: AbortController
  createBikyMessageErrorAbortController: AbortController
  updateBikyMessageErrorAbortController: AbortController
  deleteBikyMessageErrorAbortController: AbortController
  // my keybe catalog config
  getMyKeybeCatalogConfigAbortController: AbortController
  syncMyKeybeAbortController: AbortController
  unsyncMyKeybeAbortController: AbortController

  getAudioDemoUrlAbortController: AbortController

  constructor () {
    super()
    this.baseUrl = process.env.VUE_APP_CONVERSATIONS_API_URL

    // abort controllers
    // base config
    this.getBikyConfigAbortController = new AbortController()
    this.createBikyDefaultConfigAbortController = new AbortController()
    this.updateBikyConfigAbortController = new AbortController()
    // pronunciations
    this.getPronunciationsAbortController = new AbortController()
    this.createPronunciationAbortController = new AbortController()
    this.updatePronunciationAbortController = new AbortController()
    this.deletePronunciationAbortController = new AbortController()
    // websites
    this.getWebsitesAbortController = new AbortController()
    this.createWebsiteAbortController = new AbortController()
    this.deleteWebsiteAbortController = new AbortController()
    // files
    this.getFilesAbortController = new AbortController()
    this.createFileAbortController = new AbortController()
    this.deleteFileAbortController = new AbortController()
    // knowledge base
    this.getKnowledgeBaseAbortController = new AbortController()
    this.createKnowledgeBaseAbortController = new AbortController()
    this.updateKnowledgeBaseAbortController = new AbortController()
    this.deleteKnowledgeBaseAbortController = new AbortController()
    // biky message errors
    this.getBikyMessageErrorsAbortController = new AbortController()
    this.createBikyMessageErrorAbortController = new AbortController()
    this.updateBikyMessageErrorAbortController = new AbortController()
    this.deleteBikyMessageErrorAbortController = new AbortController()

    // my keybe catalog config
    this.getMyKeybeCatalogConfigAbortController = new AbortController()
    this.syncMyKeybeAbortController = new AbortController()
    this.unsyncMyKeybeAbortController = new AbortController()

    this.getAudioDemoUrlAbortController = new AbortController()
  }

  async getBikyConfig (data: getConfigDTO): Promise<GenericResponse> {
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    const params = { ...data }
    delete params.token
    const url = `${this.baseUrl}/ai/config`
    const requestData: getOptions = {
      url,
      query: params,
      headers,
      abortController: this.getBikyConfigAbortController
    }
    return await this.client.get(requestData)
  }

  async createBikyDefaultConfig (data: getConfigDTO): Promise<GenericResponse> {
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    const params = { ...data }
    delete params.token
    const url = `${this.baseUrl}/ai/config`
    const requestData: nonGetOptions = {
      url,
      data: params,
      headers,
      abortController: this.createBikyDefaultConfigAbortController
    }
    return await this.client.post(requestData)
  }

  async updateBikyConfig (data: updateConfigDTO): Promise<GenericResponse> {
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    const params = { ...data }
    delete params.token
    const url = `${this.baseUrl}/ai/config`
    const requestData: nonGetOptions = {
      url,
      data: params,
      headers,
      abortController: this.updateBikyConfigAbortController
    }
    return await this.client.put(requestData)
  }

  async getPronunciations (data: getConfigDTO): Promise<GenericResponse> {
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    const params = { ...data }
    delete params.token
    const url = `${this.baseUrl}/ai/config/pronunciation`
    const requestData: getOptions = {
      url,
      query: params,
      headers,
      abortController: this.getPronunciationsAbortController
    }
    return await this.client.get(requestData)
  }

  async createPronunciation (data: createPronunciationDTO): Promise<GenericResponse> {
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    const params = { ...data }
    delete params.token
    const url = `${this.baseUrl}/ai/config/pronunciation`
    const requestData: nonGetOptions = {
      url,
      data: params,
      headers,
      abortController: this.createPronunciationAbortController
    }
    return await this.client.post(requestData)
  }

  async updatePronunciation (data: updatePronunciationDTO): Promise<GenericResponse> {
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    const params = { ...data }
    delete params.token
    const url = `${this.baseUrl}/ai/config/pronunciation`
    const requestData: nonGetOptions = {
      url,
      data: params,
      headers,
      abortController: this.updatePronunciationAbortController
    }
    return await this.client.put(requestData)
  }

  async deletePronunciation (data: deletePronunciationDTO): Promise<GenericResponse> {
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    const params = { ...data }
    delete params.token
    const url = `${this.baseUrl}/ai/config/pronunciation?pronunciationId=${params.pronunciationId}`
    const requestData: nonGetOptions = {
      url,
      headers,
      abortController: this.deletePronunciationAbortController
    }
    return await this.client.delete(requestData)
  }

  // websites
  async getWebsites (data: getWebsitesDTO): Promise<GenericResponse> {
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    const params = { ...data }
    delete params.token
    const url = `${this.baseUrl}/ai/config/websites`
    const requestData: getOptions = {
      url,
      query: params,
      headers,
      abortController: this.getWebsitesAbortController
    }
    return await this.client.get(requestData)
  }

  async createWebsite (data: CreateWebsiteDto): Promise<GenericResponse> {
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    const params = { ...data }
    delete params.token
    const url = `${this.baseUrl}/ai/config/websites`
    const requestData: nonGetOptions = {
      url,
      data: params,
      headers,
      abortController: this.createWebsiteAbortController
    }
    return await this.client.post(requestData)
  }

  async deleteWebsite (data: deleteWebsiteDTO): Promise<GenericResponse> {
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    const params = { ...data }
    delete params.token
    const url = `${this.baseUrl}/ai/config/websites?websiteId=${params.websiteId}`
    const requestData: nonGetOptions = {
      url,
      headers,
      abortController: this.deleteWebsiteAbortController
    }
    return await this.client.delete(requestData)
  }

  // files
  async getFiles (data: getFilesDTO): Promise<GenericResponse> {
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    const params = { ...data }
    delete params.token
    const url = `${this.baseUrl}/ai/config/files`
    const requestData: getOptions = {
      url,
      query: params,
      headers,
      abortController: this.getFilesAbortController
    }
    return await this.client.get(requestData)
  }

  async createFile (data: createFileDTO): Promise<GenericResponse> {
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    const params = { ...data }
    delete params.token
    const url = `${this.baseUrl}/ai/config/files`
    const requestData: nonGetOptions = {
      url,
      data: params,
      headers,
      abortController: this.createFileAbortController
    }
    return await this.client.post(requestData)
  }

  async deleteFile (data: deleteFileDTO): Promise<GenericResponse> {
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    const params = { ...data }
    delete params.token
    const url = `${this.baseUrl}/ai/config/files?fileId=${params.fileId}`
    const requestData: nonGetOptions = {
      url,
      headers,
      abortController: this.deleteFileAbortController
    }
    return await this.client.delete(requestData)
  }

  // knowledge base
  async getKnowledgeBase (data: getKnowledgeBaseDTO): Promise<GenericResponse> {
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    const params = { ...data }
    delete params.token
    const url = `${this.baseUrl}/ai/knowledge-base`
    const requestData: getOptions = {
      url,
      headers,
      query: params,
      abortController: this.getKnowledgeBaseAbortController
    }
    return await this.client.get(requestData)
  }

  async createKnowledgeBase (data: createKnowledgeBaseDTO): Promise<GenericResponse> {
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    const url = `${this.baseUrl}/ai/knowledge-base`
    const body = { ...data }
    delete body.token
    const requestData: nonGetOptions = {
      url,
      headers,
      data: body,
      abortController: this.createKnowledgeBaseAbortController
    }
    return await this.client.post(requestData)
  }

  async updateKnowledgeBase (data: updateKnowledgeBaseDTO): Promise<GenericResponse> {
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    const url = `${this.baseUrl}/ai/knowledge-base`
    const body = { ...data }
    delete body.token
    const requestData: nonGetOptions = {
      url,
      headers,
      data: body,
      abortController: this.updateKnowledgeBaseAbortController
    }
    return await this.client.put(requestData)
  }

  async deleteKnowledgeBase (data: deleteKnowledgeBaseDTO): Promise<GenericResponse> {
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    const url = `${this.baseUrl}/ai/knowledge-base?documentId=${data.documentId}`
    const requestData: nonGetOptions = {
      url,
      headers,
      abortController: this.deleteKnowledgeBaseAbortController
    }
    return await this.client.delete(requestData)
  }

  // biky message errors
  async getBikyMessageErrors (data: getBikyMessageErrorsDTO): Promise<GenericResponse> {
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    const params = { ...data }
    delete params.token
    const url = `${this.baseUrl}/ai/biky-message-errror`
    const requestData: getOptions = {
      url,
      headers,
      query: params,
      abortController: this.getBikyMessageErrorsAbortController
    }
    return await this.client.get(requestData)
  }

  async createBikyMessageError (data: CreateBikyMessageErrorDto): Promise<GenericResponse> {
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    const url = `${this.baseUrl}/ai/biky-message-errror`
    const body = { ...data }
    delete body.token
    const requestData: nonGetOptions = {
      url,
      headers,
      data: body,
      abortController: this.createBikyMessageErrorAbortController
    }
    return await this.client.post(requestData)
  }

  async updateBikyMessageError (data: UpdateBikyMessageErrorDto): Promise<GenericResponse> {
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    const url = `${this.baseUrl}/ai/biky-message-errror`
    const body = { ...data }
    delete body.token
    const requestData: nonGetOptions = {
      url,
      headers,
      data: body,
      abortController: this.updateBikyMessageErrorAbortController
    }
    return await this.client.put(requestData)
  }

  async deleteBikyMessageError (data: deleteBikyMessageErrorDTO): Promise<GenericResponse> {
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    const url = `${this.baseUrl}/ai/biky-message-errror?bikyMessageErrorId=${data.bikyMessageErrorId}`
    const requestData: nonGetOptions = {
      url,
      headers,
      abortController: this.deleteBikyMessageErrorAbortController
    }
    return await this.client.delete(requestData)
  }

  async getAudioDemoUrl (data: dataToGetTestAudio): Promise<GenericResponse> {
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    const params = {
      ...data
    }
    delete params.token
    const url = `${this.baseUrl}/ai/textToAudio/demo`
    const requestData: getOptions = {
      url,
      headers,
      query: params
    }
    return await this.client.get(requestData)
  }

  // my keybe catalog config
  async getMyKeybeCatalogConfig (data: getConfigDTO): Promise<GenericResponse> {
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    const params = { ...data }
    delete params.token
    const url = `${this.baseUrl}/ai/catalog`
    const requestData: getOptions = {
      url,
      query: params,
      headers,
      abortController: this.getMyKeybeCatalogConfigAbortController
    }
    return await this.client.get(requestData)
  }

  async syncMyKeybe (data: getConfigDTO): Promise<GenericResponse> {
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    const params = { ...data }
    delete params.token
    const url = `${this.baseUrl}/ai/catalog/my-keybe`
    const requestData: nonGetOptions = {
      url,
      data: params,
      headers,
      abortController: this.syncMyKeybeAbortController
    }
    return await this.client.post(requestData)
  }

  async unsyncMyKeybe (data: getConfigDTO): Promise<GenericResponse> {
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    const params = { ...data }
    delete params.token
    const url = `${this.baseUrl}/ai/catalog/my-keybe`
    const requestData: nonGetOptions = {
      url,
      data: params,
      headers,
      abortController: this.unsyncMyKeybeAbortController
    }
    return await this.client.delete(requestData)
  }

  // abort controllers
  // base config
  abortGetBikyConfig (): void {
    this.getBikyConfigAbortController.abort()
    this.getBikyConfigAbortController = new AbortController()
  }

  abortCreateBikyDefaultConfig (): void {
    this.createBikyDefaultConfigAbortController.abort()
    this.createBikyDefaultConfigAbortController = new AbortController()
  }

  abortUpdateBikyConfig (): void {
    this.updateBikyConfigAbortController.abort()
    this.updateBikyConfigAbortController = new AbortController()
  }

  // pronunciations
  abortGetPronunciations (): void {
    this.getPronunciationsAbortController.abort()
    this.getPronunciationsAbortController = new AbortController()
  }

  abortCreatePronunciation (): void {
    this.createPronunciationAbortController.abort()
    this.createPronunciationAbortController = new AbortController()
  }

  abortUpdatePronunciation (): void {
    this.updatePronunciationAbortController.abort()
    this.updatePronunciationAbortController = new AbortController()
  }

  abortDeletePronunciation (): void {
    this.deletePronunciationAbortController.abort()
    this.deletePronunciationAbortController = new AbortController()
  }

  // websites
  abortGetWebsites (): void {
    this.getWebsitesAbortController.abort()
    this.getWebsitesAbortController = new AbortController()
  }

  abortCreateWebsite (): void {
    this.createWebsiteAbortController.abort()
    this.createWebsiteAbortController = new AbortController()
  }

  abortDeleteWebsite (): void {
    this.deleteWebsiteAbortController.abort()
    this.deleteWebsiteAbortController = new AbortController()
  }

  // files
  abortGetFiles (): void {
    this.getFilesAbortController.abort()
    this.getFilesAbortController = new AbortController()
  }

  abortCreateFile (): void {
    this.createFileAbortController.abort()
    this.createFileAbortController = new AbortController()
  }

  abortDeleteFile (): void {
    this.deleteFileAbortController.abort()
    this.deleteFileAbortController = new AbortController()
  }

  // knowledge base
  abortGetKnowledgeBase (): void {
    this.getKnowledgeBaseAbortController.abort()
    this.getKnowledgeBaseAbortController = new AbortController()
  }

  abortCreateKnowledgeBase (): void {
    this.createKnowledgeBaseAbortController.abort()
    this.createKnowledgeBaseAbortController = new AbortController()
  }

  abortUpdateKnowledgeBase (): void {
    this.updateKnowledgeBaseAbortController.abort()
    this.updateKnowledgeBaseAbortController = new AbortController()
  }

  abortDeleteKnowledgeBase (): void {
    this.deleteKnowledgeBaseAbortController.abort()
    this.deleteKnowledgeBaseAbortController = new AbortController()
  }

  // biky message errors
  abortGetBikyMessageErrors (): void {
    this.getBikyMessageErrorsAbortController.abort()
    this.getBikyMessageErrorsAbortController = new AbortController()
  }

  abortCreateBikyMessageError (): void {
    this.createBikyMessageErrorAbortController.abort()
    this.createBikyMessageErrorAbortController = new AbortController()
  }

  abortUpdateBikyMessageError (): void {
    this.updateBikyMessageErrorAbortController.abort()
    this.updateBikyMessageErrorAbortController = new AbortController()
  }

  abortDeleteBikyMessageError (): void {
    this.deleteBikyMessageErrorAbortController.abort()
    this.deleteBikyMessageErrorAbortController = new AbortController()
  }

  abortgetAudioDemoUrl (): void {
    this.getAudioDemoUrlAbortController.abort()
    this.getAudioDemoUrlAbortController = new AbortController()
  }

  abortGetMyKeybeCatalogConfig (): void {
    this.getMyKeybeCatalogConfigAbortController.abort()
    this.getMyKeybeCatalogConfigAbortController = new AbortController()
  }

  abortSyncMyKeybe (): void {
    this.syncMyKeybeAbortController.abort()
    this.syncMyKeybeAbortController = new AbortController()
  }

  abortUnsyncMyKeybe (): void {
    this.unsyncMyKeybeAbortController.abort()
    this.unsyncMyKeybeAbortController = new AbortController()
  }

  abortAll (): void {
    // base config
    this.abortGetBikyConfig()
    this.abortCreateBikyDefaultConfig()
    this.abortUpdateBikyConfig()
    // pronunciations
    this.abortGetPronunciations()
    this.abortCreatePronunciation()
    this.abortUpdatePronunciation()
    this.abortDeletePronunciation()
    // websites
    this.abortGetWebsites()
    this.abortCreateWebsite()
    this.abortDeleteWebsite()
    // files
    this.abortGetFiles()
    this.abortCreateFile()
    this.abortDeleteFile()
    // knowledge base
    this.abortGetKnowledgeBase()
    this.abortCreateKnowledgeBase()
    this.abortUpdateKnowledgeBase()
    this.abortDeleteKnowledgeBase()
    // biky message errors
    this.abortGetBikyMessageErrors()
    this.abortCreateBikyMessageError()
    this.abortUpdateBikyMessageError()
    this.abortDeleteBikyMessageError()
    // my keybe catalog config
    this.abortGetMyKeybeCatalogConfig()
    this.abortSyncMyKeybe()
    this.abortUnsyncMyKeybe()
  }
}
