import { UserStates } from '@/components/conversations/enums/UserStates'
import AdvisersService from '@/components/conversations/Services/AdvisersService'
import { mapState } from 'vuex'
import { EventBus } from '@/eventBus'
import Colors from '@/utils/Colors'

import KBSwitch from '@/shared/infrastructure/ui/switch/KBSwitch.vue'
import { ActivityActionTypeEnum, ActivityLogModuleTypeEnum, dataToWriteActivityLog } from '@/modules/Config/ActivityLogs/domain/ActivityLogsController'
import { CDPFieldsEnums } from '@/modules/CDP/shared/contacts/domain/enums/CDPFieldsEnums'
import ActivityLogsUseCases from '@/modules/Config/ActivityLogs/application/ActivityLogsUseCases'
import ActivityLogsController from '@/modules/Config/ActivityLogs/infrastructure/controllers/ActivityLogs.controller'

export default {
  components: { KBSwitch },
  name: 'AdvisorSwitch',
  props: {
    borderless: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      Colors,
      adviserStatus: UserStates.Offline,
      isOnline: false,
      activityLogsController: new ActivityLogsController()
    }
  },
  created () {
    this.adviserStatus = AdvisersService.getAdviserStatus(this.user.userId)
    this.isOnline = this.adviserStatus === UserStates.Online
  },
  computed: {
    ...mapState('UserStore', ['user']),
    ...mapState('AppStore', ['advisers', 'selectedApp']),
    label () {
      return this.isOnline ? 'Online' : 'Offline'
    },
    currentAdviserExist () {
      return this.advisers.find((a) => a.userId === this.user?.userId)
    }
  },
  methods: {
    async handleStatusChange (status) {
      if (!this.currentAdviserExist) {
        EventBus.$emit('toast', 'error', this.$t('youAreNotAnAdvisor'))
        setTimeout(() => {
          this.isOnline = false
        }, 100)
        return
      }
      const uptated = await AdvisersService.updateAdviserStatus(status)
      if (!uptated) {
        EventBus.$emit('toast', 'error', this.$t('errorUpdateAdviserStatus'))
      } else {
        this.writeActivityLog(status)
      }
      this.$emit('change')
    },
    async writeActivityLog (status): Promise<void> {
      const data: dataToWriteActivityLog = {
        token: this.token,
        updateInput: {
          appUUID: this.selectedApp?.uuid,
          module: ActivityLogModuleTypeEnum.CHAT,
          action: ActivityActionTypeEnum.ADVISOR_STATUS,
          user: {
            id: this.user.uuid,
            name: this.user.name + ' ' + this.user.lastName
          },
          payload: {
            field: CDPFieldsEnums.ADVISOR_STATUS,
            newValue: status ? UserStates.Online : UserStates.Offline,
            oldValue: status ? UserStates.Offline : UserStates.Online
          }
        }
      }
      await ActivityLogsUseCases.writeActivityLog(this.activityLogsController, data)
    }
  },
  watch: {
    advisers () {
      this.adviserStatus = AdvisersService.getAdviserStatus(this.user.userId)
      this.isOnline = this.adviserStatus === UserStates.Online
    }
  }
}
