export enum FiltersType {
    ALL = 'all',
    MINE = 'mine',
    WAITING = 'waiting',
    WITH_BOT = 'withBot',
    WIZARD = 'wizard',
    ENDED = 'ended',
    WHATSAPP = 'whatsapp',
    FACEBOOK = 'facebook',
    INSTAGRAM = 'instagram',
    WEB = 'web',
    OLDER = 'older',
    NEWER = 'newer',
    CLOSED_BY_AI = 'closedByAi',
    CLOSED_BY_USER = 'closedByUser',
    ADVISOR = 'advisor',
    INTERNAL = 'internal',
    OPEN = 'open',
    BY_USER_ID = 'byUserId',
    UNANSWERED = 'unanswered',
    FAVORITE = 'favorite',
    SMS = 'sms'
}

export enum FiltersTypeUpperCase {
    ALL = 'ALL',
    MINE = 'MINE',
    WAITING = 'WAITING',
    WITH_BOT = 'WITH_BOT',
    WIZARD = 'WIZARD',
    ENDED = 'ENDED',
    WHATSAPP = 'WHATSAPP',
    FACEBOOK = 'FACEBOOK',
    INSTAGRAM = 'INSTAGRAM',
    WEB = 'WEB',
    OLDER = 'OLDER',
    NEWER = 'NEWER',
    CLOSED_BY_AI = 'CLOSED_BY_AI',
    CLOSED_BY_ME = 'CLOSED_BY_ME',
    ADVISOR = 'ADVISOR',
    INTERNAL = 'INTERNAL',
    OPEN = 'OPEN',
    BY_USER_ID = 'BY_USER_ID',
    UNASSIGNED = 'UNASSIGNED',
    FAVORITE = 'FAVORITE',
    SMS = 'SMS',
    SPAM = 'SPAM'
}

export interface FiltersConfig {
    all: boolean,
    mine: boolean,
    waiting: boolean,
    withBot: boolean,
    wizard: boolean,
    ended: boolean,
    whatsapp: boolean,
    facebook: boolean,
    instagram: boolean,
    web: boolean,
    older: boolean,
    newer: boolean,
    closedByAi: boolean,
    closedByUser: boolean,
    advisor: boolean,
    open: boolean,
    byUserId: string,
    internal: boolean,
    unanswered: boolean,
    favorite: boolean
}
