




export default {
  name: 'TrashIcon',
  props: {
    color: {
      type: String,
      default: '#000'
    },
    width: {
      type: Number,
      default: 16
    },
    height: {
      type: Number,
      default: 18
    }
  }
}
