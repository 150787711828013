
import moment from 'moment'
import ApexCharts from 'apexcharts'
import { EventBus } from '@/eventBus'
import { textCamelize } from '@/utils/utils'
import MetricsService from '@/modules/metrics/application/services/MetricsService'
import LoadingNext from '@/modules/metrics/infrastructure/ui/components/generic/LoadingNext.vue'
import LottieAnimation from 'lottie-web-vue'

export default {
  name: 'MetricsGraph',
  props: {
    range: {
      type: Object
    },
    dataComponent: {
      type: Object
    }
  },
  components: {
    LottieAnimation,
    LoadingNext
  },
  data () {
    return {
      chartStatus: null,
      itemData: [],
      itemDataCSAT: 0,
      loadingCSAT: true,
      loadingConversationState: true,
      chartCSAT: null
    }
  },
  beforeDestroy () {
    if (this.chartCSAT) {
      this.chartCSAT.destroy()
    }
    if (this.chartStatus) {
      this.chartStatus.destroy()
    }
  },
  watch: {
    range () {
      if (this.range) {
        this.getCSAT()
      }
    },
    dataComponent () {
      if (this.dataComponent) {
        try {
          this.setStateConversation(this.dataComponent)
          this.loadingConversationState = false
        } catch (error) {
          this.loadingConversationState = false
        }
      }
    }
  },

  methods: {
    async getCSAT () {
      let params = {}
      if (this.range) {
        const { dateFrom, dateTo } = this.range
        params = {
          periodStart: dateFrom,
          periodEnd: dateTo
        }
      } else {
        params = {
          periodStart: moment().subtract(1, 'weeks').format('YYYY-MM-DD'),
          periodEnd: moment().format('YYYY-MM-DD')
        }
      }
      this.loadingCSAT = true

      const response = await MetricsService.getNewCSAT(params)
      if (response) {
        if (response?.data) {
          const { data } = response
          if (data?.result[0]) {
            this.itemDataCSAT = data?.result[0].CSAT.toFixed(2)
          }
        }
        // else {
        //   this.itemDataCSAT = response?.data?.csat?.satisfaction?.toFixed(2)
        // }

        this.setCSAT()
        this.loadingCSAT = false
      } else {
        this.loadingCSAT = false
        if (response?.data?.error) {
          EventBus.$emit('toast', 'error', this.$t(textCamelize(response.data.error)))
        } else {
          EventBus.$emit('toast', 'error', this.$t('unexpectedError'))
        }
      }
    },
    setCSAT () {
      if (this.chartCSAT) {
        this.chartCSAT.destroy()
      }
      const optionsCSAT = {
        series: [Math.round(this.itemDataCSAT)],
        chart: {
          height: 218,
          type: 'radialBar'
        },
        stroke: {
          lineCap: 'round',
          width: [2]
        },
        fill: {
          type: 'solid',
          colors: ['#3AF69B']
        },
        plotOptions: {
          radialBar: {
            dataLabels: {
              name: {
                show: false,
                fontSize: '10px',
                color: '#636363',
                offsetY: 20
              },
              value: {
                offsetY: 5,
                fontWeight: 600,
                fontSize: '18px',
                color: '#636363'
              }
            }
          }
        },

        labels: ['CSAT']
      }

      this.chartCSAT = new ApexCharts(document.querySelector('#MetricsCSATGraph__graph'), optionsCSAT)

      this.chartCSAT.render()
    }

  },
  computed: {
    closeModal: {
      get () {
        return this.$store.state.MetricsStore.closeModal || false
      },
      set (value) {
        this.$store.commit('MetricsStore/SET_EDITING_OBJECT', {
          key: 'closeModal',
          value
        })
      }
    },
    loading: {
      get () {
        return this.$store.state.MetricsStore.loading || false
      },
      set (value) {
        this.$store.commit('MetricsStore/SET_EDITING_OBJECT', {
          key: 'loading',
          value
        })
      }
    }
  },
  mounted () {
    this.getCSAT()
  }
}
