<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       fill="none"
       viewBox="0 0 12 10"><path :fill="color"
                                 d="M3.322 5.62c.045.227.075.438.132.641.05.18.197.268.377.296.059.01.121.008.181.008h5.416c.283 0 .451.102.527.316.107.303-.108.593-.456.614H3.978c-.426 0-.801-.119-1.102-.436a1.433 1.433 0 0 1-.366-.734l-.994-5.187C1.48.952 1.454.93 1.27.93 1.014.93.757.935.502.928.096.918-.123.546.072.213.164.061.313.006.47.003c.342-.006.686-.005 1.026.02.337.024.596.203.768.492.053.089.112.11.208.11 2.6-.002 5.2-.002 7.8 0 .332 0 .612.111.777.423.1.189.115.39.061.594-.256.963-.51 1.927-.773 2.89-.187.685-.728 1.087-1.453 1.087H3.32Zm-.17-.93H8.913c.285 0 .437-.125.518-.418.239-.865.475-1.731.712-2.597.01-.035.015-.071.023-.112H2.547l.605 3.127ZM8.13 9.05c.004-.524.43-.93.97-.925.486.005.889.433.885.941A.942.942 0 0 1 9.02 10c-.492-.005-.896-.435-.892-.95ZM4.36 9.073c.004.456-.32.918-.928.916a.91.91 0 0 1-.94-.928c-.004-.48.336-.918.94-.925.532-.005.927.401.929.937Z"/></svg>
</template>

<script>
export default {
  name: 'ShopingCartIcon',
  props: {
    color: {
      type: String,
      default: '#fff'
    }
  }
}
</script>

<style>

</style>
