<template>
  <svg width="8"
       height="11"
       viewBox="0 0 8 11"
       fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <path d="M0.291667 0.0469081C0.46875 -0.0368864 0.677083 -0.00680635 0.825 0.124257L1.66667 0.867665L2.50833 0.124257C2.69583 -0.0411836 2.97292 -0.0411836 3.15833 0.124257L4 0.867665L4.84167 0.124257C5.02917 -0.0411836 5.30625 -0.0411836 5.49167 0.124257L6.33333 0.867665L7.175 0.124257C7.32292 -0.00680635 7.53125 -0.0368864 7.70833 0.0469081C7.88542 0.130703 8 0.313332 8 0.515298V10.4847C8 10.6867 7.88542 10.8693 7.70833 10.9531C7.53125 11.0369 7.32292 11.0068 7.175 10.8757L6.33333 10.1323L5.49167 10.8757C5.30417 11.0412 5.02708 11.0412 4.84167 10.8757L4 10.1323L3.15833 10.8757C2.97083 11.0412 2.69375 11.0412 2.50833 10.8757L1.66667 10.1323L0.825 10.8757C0.677083 11.0068 0.46875 11.0369 0.291667 10.9531C0.114583 10.8693 0 10.6867 0 10.4847V0.515298C0 0.313332 0.114583 0.130703 0.291667 0.0469081ZM2 3.09359C1.81667 3.09359 1.66667 3.24829 1.66667 3.43736C1.66667 3.62644 1.81667 3.78114 2 3.78114H6C6.18333 3.78114 6.33333 3.62644 6.33333 3.43736C6.33333 3.24829 6.18333 3.09359 6 3.09359H2ZM1.66667 7.56263C1.66667 7.75171 1.81667 7.90641 2 7.90641H6C6.18333 7.90641 6.33333 7.75171 6.33333 7.56263C6.33333 7.37356 6.18333 7.21886 6 7.21886H2C1.81667 7.21886 1.66667 7.37356 1.66667 7.56263ZM2 5.15623C1.81667 5.15623 1.66667 5.31093 1.66667 5.5C1.66667 5.68907 1.81667 5.84377 2 5.84377H6C6.18333 5.84377 6.33333 5.68907 6.33333 5.5C6.33333 5.31093 6.18333 5.15623 6 5.15623H2Z"
          :fill="color"/>
  </svg>
</template>
<script>
export default {
  name: 'TicketIcon',
  props: {
    color: {
      type: String,
      default: '#000'
    }
  }
}
</script>
