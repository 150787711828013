import axios from 'axios'
import store from '@/store/index'
import { RestResponseFactory } from '@/models/apis/RestResponse'
import { StatusCodes } from '@/models/apis/StatusCodes'
import { GenericResponse } from '@/models/apis/GenericResponse'

export default class ConversationsService {
  static baseUrl: string = process.env.VUE_APP_CONVERSATIONS_API_URL

  static async setOneSignalStatus (data: {
        appUUID?: string,
        companyUUID?: string,
        oneSignalPlayerId?: string,
        device?: string,
        active: boolean,
        phone?: string,
        email?: string,
    }) {
    try {
      const url = `${ConversationsService.baseUrl}/config/notifier`
      const user = store.getters['UserStore/getUser']
      const token = store.getters['UserStore/getToken']
      const selectedApp = store.getters['AppStore/getSelectedApp']
      const oneSignalUserId = store.getters['ConversationStore/getOneSignalUserId']

      const postData = {
        ...data,
        userId: user?.userId,
        email: user?.email || data.email || '',
        oneSignalPlayerId: oneSignalUserId,
        device: 'web',
        appUUID: data.appUUID || selectedApp?.uuid
      }
      const response = await axios.post(url, postData,
        {
          headers: {
            Authorization: 'Bearer ' + token
          }
        })
      // store.commit('ConversationStore/SET_ONE_SIGNAL_REGISTERED',
      //   response.data.code === 0 ? data.active : false
      // )
      return new RestResponseFactory().process({ response })
    } catch (error) {
      return new RestResponseFactory().process({ response: { status: StatusCodes.HTTP_500_INTERNAL_SERVER_ERROR } })
    }
  }

  // KB 6 function

  static async getSentimentsConfig (): Promise<GenericResponse> {
    const appId = store.getters['AppStore/getSelectedApp']?.uuid
    const url = `${ConversationsService.baseUrl}/configurations/app/${appId}/sentiments`
    try {
      const response = await axios.get(url)
      return new RestResponseFactory().process({ response })
    } catch (error) {
      return new RestResponseFactory().process({ response: { status: StatusCodes.HTTP_500_INTERNAL_SERVER_ERROR } })
    }
  }

  static async setSentimentsConfig (data): Promise<GenericResponse> {
    const appId = store.getters['AppStore/getSelectedApp']?.uuid
    const url = `${ConversationsService.baseUrl}/configurations/app/${appId}/sentiments`
    const user = store.getters['UserStore/getUser']
    const advisorId = user?.userId
    const advisorName = user?.name
    try {
      const response = await axios.put(url, { ...data, advisorId, advisorName })
      return new RestResponseFactory().process({ response })
    } catch (error) {
      return new RestResponseFactory().process({ response: { status: StatusCodes.HTTP_500_INTERNAL_SERVER_ERROR } })
    }
  }

  // Action Suggestions Funcions
  static async getActionSuggestionsConfig (): Promise<GenericResponse> {
    const appId = store.getters['AppStore/getSelectedApp']?.uuid
    const url = `${ConversationsService.baseUrl}/configurations/app/${appId}/action-suggestions`
    try {
      const response = await axios.get(url)
      return new RestResponseFactory().process({ response })
    } catch (error) {
      return new RestResponseFactory().process({ response: { status: StatusCodes.HTTP_500_INTERNAL_SERVER_ERROR } })
    }
  }

  static async setActionSuggestionsConfig (data): Promise<GenericResponse> {
    const appId = store.getters['AppStore/getSelectedApp']?.uuid
    const url = `${ConversationsService.baseUrl}/configurations/app/${appId}/action-suggestions`
    try {
      const response = await axios.put(url, data)
      return new RestResponseFactory().process({ response })
    } catch (error) {
      return new RestResponseFactory().process({ response: { status: StatusCodes.HTTP_500_INTERNAL_SERVER_ERROR } })
    }
  }

  // Message Suggestions Funcions
  static async getMessageSuggestionsConfig (): Promise<GenericResponse> {
    const appId = store.getters['AppStore/getSelectedApp']?.uuid
    const url = `${ConversationsService.baseUrl}/configurations/app/${appId}/message-suggestions`
    try {
      const response = await axios.get(url)
      return new RestResponseFactory().process({ response })
    } catch (error) {
      return new RestResponseFactory().process({ response: { status: StatusCodes.HTTP_500_INTERNAL_SERVER_ERROR } })
    }
  }

  static async setMessageSuggestionsConfig (data): Promise<GenericResponse> {
    const appId = store.getters['AppStore/getSelectedApp']?.uuid
    const url = `${ConversationsService.baseUrl}/configurations/app/${appId}/message-suggestions`
    const user = store.getters['UserStore/getUser']
    const advisorId = user?.userId
    const advisorName = user?.name

    try {
      const response = await axios.put(url, { ...data, advisorId, advisorName })
      return new RestResponseFactory().process({ response })
    } catch (error) {
      return new RestResponseFactory().process({ response: { status: StatusCodes.HTTP_500_INTERNAL_SERVER_ERROR } })
    }
  }

  // function to update the state of an action suggestion in a message
  static async updateActionSuggestionState (messageId, actionSuggestionId, state): Promise<GenericResponse> {
    const url = `${ConversationsService.baseUrl}/message/${messageId}/action-suggestions/${actionSuggestionId}`
    try {
      const response = await axios.put(url, { state })
      return new RestResponseFactory().process({ response })
    } catch (error) {
      return new RestResponseFactory().process({ response: { status: StatusCodes.HTTP_500_INTERNAL_SERVER_ERROR } })
    }
  }
}
