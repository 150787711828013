













import Vue from 'vue'
export default Vue.extend({
  props: ['value', 'small', 'color', 'borderless', 'disabled'],
  data () {
    return {
      switchValue: this.value,
      colorClasses: {
        green: 'KBSwitch__green'
      }
    }
  },
  methods: {
    setValue () {
      if (!this.disabled) this.$emit('input', !this.switchValue)
    }
  },
  watch: {
    value (val) {
      this.switchValue = val
    }
  }
})
