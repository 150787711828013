<template>
  <svg width="15"
       height="17"
       viewBox="0 0 15 17"
       fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <path d="M2.47433 9.76504L6.58594 5.68769C6.82701 5.44863 7.15848 5.3125 7.5 5.3125C7.84152 5.3125 8.17299 5.44863 8.41406 5.68769L12.5257 9.76504C12.74 9.97754 12.8571 10.2631 12.8571 10.5619C12.8571 11.1828 12.3482 11.6875 11.7221 11.6875L9.64286 11.6875L9.64286 15.9375C9.64286 16.5252 9.16406 17 8.57143 17L6.42857 17C5.83594 17 5.35714 16.5252 5.35714 15.9375L5.35714 11.6875L3.2779 11.6875C2.65179 11.6875 2.14286 11.1828 2.14286 10.5619C2.14286 10.2631 2.26339 9.97754 2.47433 9.76504ZM12.8571 5.3125L12.8571 3.1875C12.8571 2.5998 12.3783 2.125 11.7857 2.125L3.21429 2.125C2.62165 2.125 2.14286 2.5998 2.14286 3.1875L2.14286 5.3125C2.14286 5.9002 1.66406 6.375 1.07143 6.375C0.478794 6.375 -4.85188e-07 5.9002 -5.10877e-07 5.3125L-6.03764e-07 3.1875C-6.80685e-07 1.42773 1.43973 -6.29327e-08 3.21429 -1.40501e-07L11.7857 -5.1517e-07C13.5603 -5.92738e-07 15 1.42773 15 3.1875L15 5.3125C15 5.90019 14.5212 6.375 13.9286 6.375C13.3359 6.375 12.8571 5.90019 12.8571 5.3125Z"
          fill="black"/>
  </svg>
</template>

<script>
export default {
  name: 'UploadIcon',
  props: {
    width: {
      type: Number,
      default: 14
    },
    height: {
      type: Number,
      default: 18
    },
    color: {
      type: String,
      default: '#B6BEC7'
    }
  }

}
</script>

<style>

</style>
