import { GenericResponse } from '@/models/apis/GenericResponse'
import { configDTO, dataToGetConfig } from '../../domain/MicrositeConfigController'
import axios from 'axios'
import { RestResponseFactory } from '@/models/apis/RestResponse'

export default class MicrositeConfigRepository {
  async getConfig ({ token, appUUID }: dataToGetConfig): Promise<GenericResponse> {
    try {
      const response = await axios.get(
        `${process.env.VUE_APP_MICROSITES_URL}/microsites/config/${appUUID}`,
        {
          headers: {
            Authorization: 'Bearer ' + token
          }
        }
      )
      return new RestResponseFactory().process({ response })
    } catch (e) {
      return new RestResponseFactory().process({ response: { status: 500 } })
    }
  }

  async createConfig ({ token, appUUID, config }: configDTO) : Promise<GenericResponse> {
    const data = {
      appUUID,
      ...config
    }
    try {
      const response = await axios.post(
        `${process.env.VUE_APP_MICROSITES_URL}/microsites/config`,
        data,
        {
          headers: {
            Authorization: 'Bearer ' + token
          }
        }
      )
      return new RestResponseFactory().process({ response })
    } catch (e) {
      return new RestResponseFactory().process({ response: { status: 500 } })
    }
  }

  async updateConfig ({ token, appUUID, config }: configDTO) : Promise<GenericResponse> {
    const data = {
      appUUID,
      ...config
    }
    try {
      const response = await axios.put(
        `${process.env.VUE_APP_MICROSITES_URL}/microsites/config/update`,
        data,
        {
          headers: {
            Authorization: 'Bearer ' + token
          }
        }
      )
      return new RestResponseFactory().process({ response })
    } catch (e) {
      return new RestResponseFactory().process({ response: { status: 500 } })
    }
  }
}
