import { InboxAccessEnum } from '@/modules/inbox/domain/Inbox'
import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'
import KBSwitch from '@/shared/infrastructure/ui/inputs/select/KBSwitch.vue'
import KBText from '@/shared/infrastructure/ui/inputs/text/KBText.vue'
import { mapGetters, mapState } from 'vuex'
import KBButton from '@/shared/infrastructure/ui/buttons/KBButton.vue'
import ConversationsConfigService from '@/components/configuration/Services/ConversationsConfigService'
import ChatConfigController from '@/modules/ChatConfig/infrastructure/ChatConfig.controller'
import Loader from '@/shared/infrastructure/ui/Loader.vue'
import SignInOnboardingTutorialBox from '@/modules/SignInOnboarding/infrastructure/components/SignInOnboardingTutorialBox/SignInOnboardingTutorialBox.vue'

import OnboardingUseCases from '@/modules/SignInOnboarding/application/OnboardingUseCases'
import OnboardingController from '@/modules/SignInOnboarding/infrastructure/controllers/Onboarding.controller'

export default {
  name: 'InboxConfigList',
  props: {
    inboxList: {
      type: Array,
      required: true
    },
    selectedInbox: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    config: {
      type: Object,
      required: true
    }
  },
  components: {
    KeybeIcon,
    KBSwitch,
    KBText,
    KBButton,
    Loader,
    SignInOnboardingTutorialBox
  },
  mounted () {
    this.getOnboardingStep()
  },
  data () {
    return {
      KeybeIconType,
      hovered: '',
      isCreating: false,
      newInbox: '',
      editingInbox: null,
      editingInboxName: '',
      boxPosition: 'margin-top: 10px;margin-left: 215px',
      arrowPosition: 'left',
      headerText: this.$t('helloKBSpaces'),
      subtitleText: this.$t('kbSpacesTutorial'),
      showTutorial: false,
      rerender: 0,
      onboardingStep: null,
      chatConfigController: new ChatConfigController(),
      onboardingController: new OnboardingController()
    }
  },
  methods: {
    closeTutorial (): void {
      this.showTutorial = false
      setTimeout(() => {
        this.getOnboardingStep()
      }, 400)
    },
    async getOnboardingStep (): Promise<void> {
      const step: number = await OnboardingUseCases.getOnboardingStep(this.onboardingController)
      this.onboardingStep = step
      if (this.onboardingStep === 10) {
        this.nextStep()
      }
      if (this.onboardingStep === 11) {
        this.showTutorial = true
        this.rerender++
        setTimeout(() => {
          this.showTutorial = false
        }, 15000)
      }
      this.rerender++
    },
    async nextStep (): Promise<void> {
      // eslint-disable-next-line no-undef
      const newStep: number = +structuredClone(this.onboardingStep) + 1
      await OnboardingUseCases.setOnboardingStep(this.onboardingController, newStep)
      this.onboardingStep = newStep
      if (this.onboardingStep === 11) {
        this.showTutorial = true
        this.rerender++
        setTimeout(() => {
          this.showTutorial = false
        }, 15000)
      }
    },
    cancelNewInbox () {
      this.newInbox = ''
      this.isCreating = false
    },
    addNewInbox () {
      if (this.loading) return

      this.$emit('createInbox', this.newInbox)
    },
    isInboxHovered (inbox) {
      return this.hovered === inbox?._id
    },
    isSelectedInbox (inbox) {
      return this.selectedInboxId === inbox?._id
    },
    hoverInbox (inbox) {
      this.hovered = inbox?._id
    },
    getInboxName (inbox) {
      return inbox?.name || ''
    },
    isInboxActive (inbox) {
      return inbox?.active || false
    },
    canEditInbox (inbox) {
      const user = inbox?.users?.find(user => user?.userId === this.user?.userId)
      const appUUID = this.selectedApp?.uuid
      const isAdmin = this.user?.userApps?.some((app: {[key: string]: any}) => app.role === 'admin' && app.appUUID === appUUID) || false
      if (this.isSuperAdmin || isAdmin) return true
      return ((user?.access === InboxAccessEnum.EDIT || user?.access === InboxAccessEnum.DELETE) && !inbox?.default) || false
    },
    canDeleteInbox (inbox) {
      const user = inbox?.users?.find(user => user?.userId === this.user?.userId)
      const appUUID = this.selectedApp?.uuid
      const isAdmin = this.user?.userApps?.some((app: {[key: string]: any}) => app.role === 'admin' && app.appUUID === appUUID) || false
      if (this.isSuperAdmin || isAdmin) return true
      return (user?.access === InboxAccessEnum.DELETE && !inbox?.default) || false
    },
    isEditingInbox (inbox) {
      return this.editingInbox?._id === inbox?._id
    },
    setEditingInbox (inbox) {
      this.editingInbox = inbox
      this.editingInboxName = inbox?.name || ''
    },
    cancelEditingInbox () {
      this.editingInbox = null
      this.editingInboxName = ''
    },
    updateInbox () {
      if (this.loading) return
      this.$emit('updateInbox', {
        ...this.editingInbox,
        name: this.editingInboxName
      })
    },
    async updateUseInbox (value) {
      await ConversationsConfigService.updateConversationsConfigV5({
        inbox: {
          ...this.config,
          useInbox: value
        }
      })

      await this.chatConfigController.getAndSetConfig({
        appUUID: this.selectedApp?.uuid || '',
        token: this.token || ''
      })
    },
    setAsMain (inbox) {
      this.$emit('setAsMain', inbox)
    }
  },
  computed: {
    ...mapGetters('ChatConfigStore', ['hasInboxConfig', 'getConfig']),
    ...mapState('AppStore', ['selectedApp']),
    ...mapState('UserStore', ['user', 'token']),
    ...mapGetters('UserStore', ['isSuperAdmin', 'isAdminOrAbove']),
    selectedInboxId (): string {
      return this.selectedInbox?._id || ''
    }
  },
  watch: {
    loading (value) {
      if (!value) {
        this.isCreating = false
        this.newInbox = ''
        this.editingInbox = null
        this.editingInboxName = ''
      }
    }
  }
}
