<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       fill="none"
       viewBox="0 0 11 13"><path :fill="color"
                                 d="M1.969 4.713v-.276c.005-.584-.038-1.17.108-1.745.254-1.004.82-1.77 1.74-2.263A3.483 3.483 0 0 1 5.481 0c.665 0 1.298.173 1.867.528 1.141.712 1.64 1.78 1.679 3.093.009.3 0 .6.002.902 0 .058.004.117.007.167.254.025.497.023.727.08.758.183 1.222.76 1.228 1.55.011 1.546.013 3.092 0 4.637-.008.942-.702 1.612-1.643 1.612-2.565.003-5.131.003-7.697 0-.954 0-1.638-.67-1.644-1.621-.01-1.54-.009-3.08 0-4.62.004-.786.45-1.352 1.2-1.555.15-.04.312-.045.469-.06.09-.007.182 0 .294 0h-.001Zm1.55-.012h3.972c-.02-.513-.014-1.016-.068-1.511a1.738 1.738 0 0 0-.615-1.142c-.481-.415-1.044-.557-1.663-.452-.692.116-1.184.506-1.459 1.153-.266.628-.13 1.291-.168 1.953L3.52 4.7Zm2.756 3.948c0-.3.018-.601-.003-.9-.029-.409-.357-.685-.775-.685-.42 0-.755.274-.77.687-.023.599-.021 1.2 0 1.799.015.395.359.67.772.669.41-.001.745-.279.772-.67.02-.3.004-.6.004-.9Z"/></svg>
</template>

<script>
export default {
  name: 'LockIcon',
  props: {
    color: {
      type: String,
      default: '#8898A9'
    }
  }
}
</script>

<style>

</style>
