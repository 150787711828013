import KBText from '@/modules/DesignSystem/infrastructure/components/KeybeText/KeybeText.vue'
import KBButton from '@/modules/DesignSystem/infrastructure/components/KeybeButton/KeybeButton.vue'
import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'
import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import { mapState } from 'vuex'
import { EventBus } from '@/eventBus'
import ShortenerUseCases from '@/shared/application/ShortenerUseCases'
import ShortenerController from '@/shared/infrastructure/controllers/Shortener.controller'
import Colors from '@/utils/Colors'
import PartnersUseCases from '@/modules/Partners/application/PartnersUseCases'
import PartnersController from '../../../controller/Partners.controller'
export default {
  components: {
    KBText,
    KBButton,
    KeybeIcon
  },
  computed: {
    ...mapState('UserStore', ['user']),
    ...mapState('AppStore', ['selectedApp'])
  },
  mounted () {
    this.createUrl()
  },
  data () {
    return {
      KeybeIconType,
      urlSingUp: 'https://keybe.app/signup',
      urlSignUpBiky: 'https://biky.app/signup',
      utm_source: 'Referral-partner',
      utm_medium: '',
      utm_campaign: 'referral-ui',
      utm_term: '',
      utm_content: '',
      urlFormart: '',
      urlFormatAutopilot: '',
      utm_partner: '',
      shortenerController: new ShortenerController(),
      Colors,
      partnerController: new PartnersController()
    }
  },
  methods: {
    async createUrl (): Promise<void> {
      this.urlSingUp = await PartnersUseCases.getPartnerUrl(this.partnerController, 'keybe')
      this.urlSignUpBiky = await PartnersUseCases.getPartnerUrl(this.partnerController, 'biky')
      this.urlFormart = await this.getShortURL(this.urlSingUp)
      this.urlFormatAutopilot = await this.getShortURL(this.urlSignUpBiky)
    },
    async getShortURL (urlSingUp: string): Promise<string> {
      const response = await ShortenerUseCases.shortenUrl(this.shortenerController, urlSingUp)
      return response
    },
    copyToClipboard (url: string): void {
      navigator.clipboard.writeText(url).then(() => {
        EventBus.$emit('toast', 'success', this.$t('UrlCopied'))
      }).catch(() => {
        EventBus.$emit('toast', 'error', this.$t('errorCopying'))
      })
    }
  }
}
