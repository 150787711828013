



















































import ConversationsConfigController from '@/components/configuration/controllers/ConversationsConfigController'
import WebWidgetService from '@/modules/configuration/domain/services/WebWidget.service'
import ConversationsConfigService from '@/components/configuration/Services/ConversationsConfigService'

import KBSwitch from '@/shared/infrastructure/ui/switch/KBSwitch.vue'
import WebWidgetCollapsedConfig from '@/modules/configuration/infrastructure/ui/components/WebWidget/WebWidgetCollapsedConfig.vue'
import WebWidgetExpandedConfig from '@/modules/configuration/infrastructure/ui/components/WebWidget/WebWidgetExpandedConfig.vue'
import WebWidgetScript from '@/modules/configuration/infrastructure/ui/components/WebWidget/WebWidgetScript.vue'
import WebWidgetForm from './WebWidgetForm.vue'
import { mapState } from 'vuex'
import { ActivityActionTypeEnum, ActivityLogModuleTypeEnum, dataToWriteActivityLog } from '@/modules/Config/ActivityLogs/domain/ActivityLogsController'
import ActivityLogsUseCases from '@/modules/Config/ActivityLogs/application/ActivityLogsUseCases'
import ActivityLogsController from '@/modules/Config/ActivityLogs/infrastructure/controllers/ActivityLogs.controller'
import { CDPFieldsEnums } from '@/modules/CDP/shared/contacts/domain/enums/CDPFieldsEnums'
export default {
  name: 'WebWidgetConfig',
  components: {
    KBSwitch,
    WebWidgetCollapsedConfig,
    WebWidgetExpandedConfig,
    WebWidgetScript,
    WebWidgetForm
  },
  computed: {
    ...mapState('UserStore', ['isUserReadonly', 'user', 'token']),
    ...mapState('AppStore', ['selectedApp'])
  },
  data () {
    return {
      config: null,
      chatConfig: null,
      allowMultimediaMessages: false,
      allowMessagesOutsideBusinessHours: false,
      loading: false,
      awaitingUpdate: false,
      activityLogsController: new ActivityLogsController(),
      CDPFieldsEnums
    }
  },
  methods: {
    setConfig (field: string, value: boolean): void {
      this.saveConfig()
      this.writeActivityLog(field, value)
    },
    async getConfig () {
      this.$emit('start-loading')
      this.loading = true
      const chatConfig = await ConversationsConfigController.getConfig()
      const config = WebWidgetService.buildWebWidgetConfig(chatConfig)
      this.config = config.widgetWebSetup
      this.chatConfig = chatConfig
      this.allowMessagesOutsideBusinessHours = !chatConfig?.workingHours?.blockMessageComposer || false
      this.allowMultimediaMessages = !config.disableMultimedia || false
      this.$emit('stop-loading')
      setTimeout(() => {
        this.loading = false
      }, 500)
    },
    async saveConfig () {
      if (this.loading) {
        return
      }

      this.config.disableMultimedia = !this.allowMultimediaMessages
      const dataToUpdate = {
        widgetWebSetup: this.config,
        workingHours: {
          ...this.chatConfig?.workingHours,
          blockMessageComposer: !this.allowMessagesOutsideBusinessHours
        }
      }
      await ConversationsConfigService.updateConversationsConfigV5(dataToUpdate)
    },
    async writeActivityLog (field: string, value: boolean): Promise<void> {
      const data: dataToWriteActivityLog = {
        token: this.token,
        updateInput: {
          appUUID: this.selectedApp?.uuid,
          module: ActivityLogModuleTypeEnum.CHAT,
          action: ActivityActionTypeEnum.WIDGET_WEB_UPDATE,
          user: {
            id: this.user.uuid,
            name: this.user.name + ' ' + this.user.lastName
          },
          payload: {
            field: field,
            newValue: value ? 'active' : 'inactive',
            oldValue: value ? 'inactive' : 'active'
          }
        }
      }
      await ActivityLogsUseCases.writeActivityLog(this.activityLogsController, data)
    },
    handleChange () {
      if (this.awaitingUpdate || this.loading) {
        return
      }
      this.awaitingUpdate = true
      setTimeout(() => {
        this.saveConfig()
        this.awaitingUpdate = false
      }, 800)
    }

  },
  mounted () {
    this.getConfig()
  },
  watch: {
    config: {
      handler () {
        this.handleChange()
      },
      deep: true
    }
  }
}
