import { render, staticRenderFns } from "./MessageSequenceCards.vue?vue&type=template&id=05d5fb13&scoped=true&"
import script from "./MessageSequenceCards.vue?vue&type=script&lang=ts&"
export * from "./MessageSequenceCards.vue?vue&type=script&lang=ts&"
import style0 from "./MessageSequenceCards.vue?vue&type=style&index=0&id=05d5fb13&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05d5fb13",
  null
  
)

export default component.exports