











































import ConversationsConfigController from '@/components/configuration/controllers/ConversationsConfigController'
import ConversationsConfigService from '@/components/configuration/Services/ConversationsConfigService'
import KBText from '@/shared/infrastructure/ui/inputs/text/KBText.vue'

export default {
  name: 'AutomaticClosingConfig.vue',
  components: {
    KBText
  },
  data () {
    return {
      closeTimeout: '0',
      agentReassignTimeout: '0',
      botReassignTimeout: '0',
      awaitingCloseTimeout: false,
      awaitingAgentReassignTimeout: false,
      awaitingBotReassignTimeout: false,
      chatConfig: null,
      loading: false,
      maxTimeAutoClosing: 1440
    }
  },
  methods: {
    async getChatConfig ():Promise<void> {
      if (!this.chatConfig) {
        this.$emit('start-loading')
        this.loading = true
        this.chatConfig = await ConversationsConfigController.getConfig()
        if (this.chatConfig?.autoClosingAfterMinutes) {
          if (this.chatConfig.autoClosingAfterMinutes > this.maxTimeAutoClosing) {
            setTimeout(() => {
              this.closeTimeout = this.maxTimeAutoClosing.toString()
              this.saveConfig()
            }, 500)
          } else {
            this.closeTimeout = this.chatConfig.autoClosingAfterMinutes.toString()
          }
        }
        if (this.chatConfig?.reassignForAdviserInactiveMinutes) {
          this.agentReassignTimeout = this.chatConfig.reassignForAdviserInactiveMinutes.toString()
        }
        if (this.chatConfig?.reassignForUserInactiveMinutesWithBot) {
          this.botReassignTimeout = this.chatConfig.reassignForUserInactiveMinutesWithBot.toString()
        }
        setTimeout(() => {
          this.loading = false
        }, 1000)
        this.$emit('stop-loading')
      }
    },
    async saveConfig ():Promise<void> {
      if (this.loading) {
        return
      }
      let autoClosingAfterMinutes:number = parseFloat(this.closeTimeout)

      if (autoClosingAfterMinutes > this.maxTimeAutoClosing) {
        autoClosingAfterMinutes = this.maxTimeAutoClosing
        this.closeTimeout = this.maxTimeAutoClosing.toString()
      }
      const dataToUpdate:Object = {
        autoClosingAfterMinutes,
        reassignForAdviserInactiveMinutes: parseFloat(this.agentReassignTimeout),
        reassignForUserInactiveMinutesWithBot: parseFloat(this.botReassignTimeout)
      }
      await ConversationsConfigService.updateConversationsConfigV5(dataToUpdate)
    }
  },
  mounted () {
    this.getChatConfig()
  },
  watch: {
    closeTimeout () {
      if (!this.awaitingCloseTimeout) {
        this.awaitingCloseTimeout = true
        setTimeout(() => {
          this.awaitingCloseTimeout = false
          this.saveConfig()
        }, 800)
      }
    },
    agentReassignTimeout () {
      if (!this.awaitingAgentReassignTimeout) {
        this.awaitingAgentReassignTimeout = true
        setTimeout(() => {
          this.awaitingAgentReassignTimeout = false
          this.saveConfig()
        }, 800)
      }
    },
    botReassignTimeout () {
      if (!this.awaitingBotReassignTimeout) {
        this.awaitingBotReassignTimeout = true
        setTimeout(() => {
          this.awaitingBotReassignTimeout = false
          this.saveConfig()
        }, 800)
      }
    }
  }
}
