import KeybeButton from '@/modules/DesignSystem/infrastructure/components/KeybeButton/KeybeButton.vue'
import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'
import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import Colors from '@/utils/Colors'
export default {
  components: {
    KeybeButton,
    KeybeIcon
  },
  props: {
    downloadUrl: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      KeybeIconType,
      Colors
    }
  },
  methods: {
    downloadFile (): void {
      const link: any = document.createElement('a')
      link.download = name
      link.href = this.downloadUrl
      link.click()
      this.$emit('close')
    }
  }
}
