<template>
  <svg width="18"
       height="19"
       viewBox="0 0 18 19"
       fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <path d="M12.8605 5.13881C12.9913 5.13881 13.0877 5.13881 13.1831 5.13881C14.5402 5.13881 15.8972 5.13666 17.2542 5.13881C17.6979 5.13881 17.9316 5.3071 17.9863 5.64796C18.0602 6.10565 17.7816 6.40578 17.2617 6.40793C16.1276 6.41221 14.9946 6.409 13.8606 6.409C13.3697 6.409 12.8787 6.41114 12.3878 6.409C11.8476 6.40685 11.6064 6.18069 11.6053 5.6501C11.6 4.01654 11.6 2.38191 11.6053 0.748347C11.6075 0.182389 11.9548 -0.105949 12.4339 0.0355406C12.6622 0.103069 12.8005 0.249918 12.8359 0.484662C12.8541 0.60793 12.8594 0.733341 12.8594 0.85768C12.8605 2.17074 12.8594 3.48274 12.8594 4.7958C12.8594 4.90085 12.8594 5.00482 12.8594 5.13774L12.8605 5.13881Z"
          :fill="color"/>
    <path d="M12.8594 12.8866C12.8594 13.0281 12.8594 13.1332 12.8594 13.2382C12.8594 14.577 12.8605 15.9169 12.8594 17.2557C12.8594 17.7123 12.7286 17.9106 12.3953 17.9942C12.0362 18.0842 11.7275 17.9095 11.6375 17.5515C11.6074 17.4325 11.6042 17.3039 11.6042 17.1796C11.6021 15.6167 11.6021 14.055 11.6032 12.4922C11.6032 11.8115 11.8015 11.6143 12.4789 11.6132C14.0674 11.6132 15.656 11.6122 17.2456 11.6132C17.7408 11.6132 17.997 11.8351 17.998 12.2478C17.998 12.6551 17.7354 12.8824 17.2434 12.8834C16.0826 12.8866 14.9228 12.8834 13.7619 12.8834C13.4768 12.8834 13.1917 12.8834 12.8594 12.8834L12.8594 12.8866Z"
          :fill="color"/>
    <path d="M5.13854 5.16635C5.13854 5.01735 5.13854 4.91124 5.13854 4.80619C5.13854 3.47491 5.13747 2.14362 5.13854 0.812331C5.13854 0.350346 5.27575 0.144543 5.62197 0.0545043C5.98212 -0.0398221 6.31441 0.170269 6.37336 0.533639C6.39158 0.64726 6.39373 0.765168 6.39373 0.880932C6.3948 2.44482 6.3948 4.00763 6.39373 5.57152C6.3948 6.2511 6.20293 6.43868 5.51478 6.43868C3.94231 6.43868 2.36985 6.44618 0.798461 6.43011C0.590515 6.42796 0.32683 6.37008 0.187484 6.23609C-0.173743 5.88773 0.0170537 5.26496 0.496189 5.19743C0.610881 5.18135 0.726645 5.16849 0.841337 5.16849C2.16298 5.16635 3.48569 5.16742 4.80733 5.16742C4.90487 5.16742 5.00134 5.16742 5.13854 5.16742L5.13854 5.16635Z"
          :fill="color"/>
    <path d="M5.15303 12.8751C4.23657 12.8751 3.37048 12.8751 2.50547 12.8751C1.91593 12.8751 1.32639 12.8816 0.736847 12.873C0.180537 12.8655 -0.120665 12.44 0.0808507 11.9608C0.194471 11.6896 0.433503 11.6082 0.689684 11.606C2.36934 11.5985 4.04792 11.5974 5.72757 11.606C6.18848 11.6082 6.40393 11.8247 6.40607 12.2888C6.41465 13.9685 6.41358 15.6481 6.40607 17.3278C6.40393 17.763 6.15739 18.0181 5.78438 18.0181C5.41458 18.0181 5.15839 17.7404 5.15625 17.2967C5.14982 15.9386 5.15303 14.5805 5.15303 13.2224C5.15303 13.1174 5.15303 13.0123 5.15303 12.8741L5.15303 12.8751Z"
          :fill="color"/>
  </svg>
</template>

<script>
export default {
  name: 'MinimizeIcon',
  props: {
    color: {
      type: String,
      default: '#B6BEC7'
    }
  }
}
</script>

<style>

</style>
