
import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'
import KBText from '@/shared/infrastructure/ui/inputs/text/KBText.vue'

export default {
  name: 'DateTimePickerRow',
  components: {
    KBText,
    KeybeIcon
  },
  data () {
    return {
      date: null,
      day: null,
      month: null,
      year: null,
      KeybeIconType
    }
  },
  computed: {
    Commissiondate: {
      get () {
        return this.$store.state.CommissionStore.Commissiondate || null
      },
      set (value) {
        this.$store.commit('CommissionStore/SET_EDITING_OBJECT', {
          key: 'Commissiondate',
          value
        })
      }
    },
    todayDate () {
      const date = new Date()
      return {
        day: date.getDate(),
        month: (date.getMonth() + 1) < 10 ? '0' + +(date.getMonth() + 1) : date.getMonth() + 1,
        year: date.getFullYear()
      }
    }
  },
  watch: {
    day () {
      if (this.day > 31) {
        this.day = 31
      } else {
        if (this.day) this.Commissiondate.day = this.day
      }
    },
    month () {
      if (this.month > 12) {
        this.month = 12
      } else {
        if (this.month) this.Commissiondate.month = this.month
      }
    },
    year () {
      if (this.year) this.Commissiondate.year = this.year
    }
  },
  methods: {
  },
  created () {
    const currentdate = new Date()
    this.year = currentdate.getFullYear()
    this.month = String(currentdate.getMonth() + 1).padStart(2, '0')
    this.day = String(currentdate.getDate()).padStart(2, '0')
    this.Commissiondate = {
      day: this.day,
      month: this.month,
      year: this.year
    }
  }
}
