<template>
  <svg :width="width"
       :height="height"
       viewBox="0 0 18 12"
       fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <path d="M7.875 0.00267798C7.50094 0.00267798 7.2 0.289221 7.2 0.645392C7.2 1.00156 7.50094 1.28811 7.875 1.28811H9.49781L9.95906 2.09953L7.2 4.28744L5.92594 3.07431C5.58844 2.75296 5.13 2.57353 4.65187 2.57353H1.8C1.30219 2.57353 0.9 2.95648 0.9 3.43048V4.28744H3.6C6.08625 4.28744 8.1 6.20486 8.1 8.57219C8.1 8.86677 8.06906 9.15331 8.01 9.42914H9.99C9.93094 9.15331 9.9 8.86677 9.9 8.57219C9.9 7.17429 10.6031 5.93171 11.6916 5.14974L12.1247 5.91564C11.3175 6.54497 10.8 7.501 10.8 8.57219C10.8 10.4655 12.4116 12 14.4 12C16.3884 12 18 10.4655 18 8.57219C18 6.67887 16.3884 5.14439 14.4 5.14439C14.0203 5.14439 13.6547 5.20062 13.3116 5.30507L11.7619 2.57353H13.5C13.9978 2.57353 14.4 2.19058 14.4 1.71658V0.859629C14.4 0.385628 13.9978 0.00267798 13.5 0.00267798H12.9262C12.7153 0.00267798 12.5128 0.0723053 12.3497 0.200848L11.0166 1.25865L10.6228 0.562374C10.4259 0.216916 10.0462 0 9.63281 0H7.875V0.00267798ZM13.0134 7.47958L13.8066 8.87748C13.9837 9.19081 14.3944 9.30596 14.7206 9.13725C15.0469 8.96853 15.1706 8.57755 14.9934 8.2669L14.2003 6.869C14.265 6.86097 14.3325 6.85829 14.4 6.85829C15.3928 6.85829 16.2 7.62687 16.2 8.57219C16.2 9.51752 15.3928 10.2861 14.4 10.2861C13.4072 10.2861 12.6 9.51752 12.6 8.57219C12.6 8.15711 12.7547 7.77684 13.0134 7.47958ZM5.26781 9.21491C5.00063 9.84423 4.35375 10.2861 3.6 10.2861C2.60719 10.2861 1.8 9.51752 1.8 8.57219C1.8 7.62687 2.60719 6.85829 3.6 6.85829C4.35656 6.85829 5.00344 7.30016 5.26781 7.92948H7.13531C6.82031 6.34412 5.35781 5.14439 3.6 5.14439C1.61156 5.14439 0 6.67887 0 8.57219C0 10.4655 1.61156 12 3.6 12C5.35781 12 6.82031 10.8003 7.13812 9.21491H5.26781ZM3.6 9.42914C3.83869 9.42914 4.06761 9.33886 4.2364 9.17815C4.40518 9.01744 4.5 8.79947 4.5 8.57219C4.5 8.34492 4.40518 8.12695 4.2364 7.96624C4.06761 7.80553 3.83869 7.71524 3.6 7.71524C3.3613 7.71524 3.13239 7.80553 2.9636 7.96624C2.79482 8.12695 2.7 8.34492 2.7 8.57219C2.7 8.79947 2.79482 9.01744 2.9636 9.17815C3.13239 9.33886 3.3613 9.42914 3.6 9.42914Z"
          fill="black"/>
  </svg>

</template>
<script>
export default {
  name: 'MotoIcon',
  props: {
    width: {
      type: Number,
      default: 18
    },
    height: {
      type: Number,
      default: 12
    },
    color: {
      type: String,
      default: '#B6BEC7'
    }
  }
}
</script>
