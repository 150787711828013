<template>
  <svg width="6" height="14" viewBox="0 0 6 14" fill="none" xmlns="http://www.w3.org/2000/svg">
  <circle cx="0.6" cy="0.6" r="0.6" :fill="color"/>
  <circle cx="4.6" cy="0.6" r="0.6" :fill="color"/>
  <circle cx="0.6" cy="6.6" r="0.6" :fill="color"/>
  <circle cx="4.6" cy="6.6" r="0.6" :fill="color"/>
  <circle cx="0.6" cy="12.6" r="0.6" :fill="color"/>
  <circle cx="4.6" cy="12.6" r="0.6" :fill="color"/>
  </svg>
</template>

<script>
export default {
  name: 'AddIcon',
  props: {
    width: {
      type: Number,
      default: 16
    },
    height: {
      type: Number,
      default: 18
    },
    color: {
      type: String,
      default: '#B6BEC7'
    }
  }

}
</script>

<style>

</style>
