<template>
  <svg :width="width"
       :height="height"
       viewBox="0 0 17 15"
       fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <path d="M16.9955 7.35084C16.9955 7.86871 16.5528 8.27437 16.051 8.27437H15.1065L15.1272 12.8834C15.1331 13.9047 14.2859 14.7333 13.2381 14.7333H3.78105C2.73912 14.7333 1.892 13.9076 1.892 12.892V8.27437H0.944525C0.41323 8.27437 0 7.87159 0 7.35084C0 7.09191 0.0885492 6.86175 0.295164 6.66035L7.86317 0.230163C8.06978 0.0287704 8.30591 0 8.51253 0C8.71914 0 8.95528 0.0575408 9.13237 0.201393L16.6709 6.66035C16.907 6.86175 17.0251 7.09191 16.9955 7.35084ZM10.3898 6.44458C10.3898 5.95623 10.1907 5.48789 9.83648 5.14257C9.48222 4.79726 9.00173 4.60327 8.50072 4.60327C7.99972 4.60327 7.51923 4.79726 7.16496 5.14257C6.8107 5.48789 6.61167 5.95623 6.61167 6.44458C6.61167 6.93292 6.8107 7.40126 7.16496 7.74658C7.51923 8.09189 7.99972 8.28588 8.50072 8.28588C9.00173 8.28588 9.48222 8.09189 9.83648 7.74658C10.1907 7.40126 10.3898 6.93292 10.3898 6.44458ZM7.5562 9.20654C6.25157 9.20654 5.19489 10.2365 5.19489 11.5082C5.19489 11.7613 5.4074 11.9685 5.66715 11.9685H11.3343C11.594 11.9685 11.8066 11.7613 11.8066 11.5082C11.8066 10.2365 10.7499 9.20654 9.44525 9.20654H7.5562Z"
          :fill="color"/>
  </svg>

</template>
<script>
export default {
  name: 'PrimaryIcon',
  props: {
    width: {
      type: Number,
      default: 7
    },
    height: {
      type: Number,
      default: 11
    },
    color: {
      type: String,
      default: '#B6BEC7'
    }
  }
}
</script>
