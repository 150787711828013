


























import CatalogPaginator from './CatalogPaginator'
export default CatalogPaginator
