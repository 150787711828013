<template>
  <div class="KBSimpleTable newBackground">
    <table class="KBSimpleTable__table">
      <thead>
        <tr>
          <td
            class="KBSimpleTable__header"
            :class="positionClass(labelIndex, labels.length)"
            v-for="(label, labelIndex) in labels"
            :key="`KBSimpleTable-${label.text}-${labelIndex}`"
          >
            {{ label.text }}
          </td>
        </tr>
      </thead>
      <tbody>
        <tr class="KBSimpleTable__row"
            v-for="(item, itemIndex) in data"
            :key="`KBSimpleTable-item-${itemIndex}`">
          <td
            class="KBSimpleTable__item"
            v-for="(label, labelIndex) in labels"
            :key="`KBSimpleTable-label-${labelIndex}`"
            :class="positionClass(labelIndex, labels.length)"
          >
            <template v-if="filter && filter.includes(label.field)">
              USD {{ formatCurrency(item[label.field], 'USD') }}
            </template>
            <template v-else>
              {{ item[label.field] }}
            </template>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'KBSimpleTable',
  props: ['labels', 'data', 'filter'],
  methods: {
    positionClass (index, length) {
      if (index === 0) {
        return 'first-item'
      }
      if (index === length - 1) {
        return 'last-item'
      }
      return ''
    },
    formatCurrency (value, currency) {
      if (typeof value === 'string') {
        if (value.includes('$')) {
          return value
        }
      }
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency
      })
      return formatter.format(value)
    }
  }
}
</script>

<style lang="scss">

.KBSimpleTable__table {
  overflow: scroll;
  border-collapse: collapse;
  width: 100%;
}

.KBSimpleTable__header {
  font-weight: 300;
  font-size: 11px;
  line-height: 0px;
  color: $simple-grey;
  height: 30px;
  text-align: center;
}

.KBSimpleTable__item {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  padding: 10px;
  border-bottom: 1px solid #EBEFF2;
  text-align: start;
  min-width: 66px;
}

  .first-item {
    padding-left: 32px;
    max-width: 125px;

    // text elipsis
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .last-item {
    padding-right: 32px;
  }
</style>
