export interface GetPlanPricing {
    plan: string,
    tier: string,
    appUUID?: string,
    amount?: number
}

export enum PRICING_MODEL_TYPE {
    BOOKING = 'BOOKING',
    BUY = 'BUY'
}

export interface PlanPricing {
    amount: number;
    totalPrice: number;
    discount: number;
    totalCarrier: number;
    type: PRICING_MODEL_TYPE;
    hasCredit: boolean;
    appName: string;
    appUUID: string;
    appCountry: string;
    plan: string;
    dailyChats: number;
    basePrice: number;
    suggestedCarrier: number;
    soCost: number;
    soSuggestedRetail: number;
    baseSuggestedPrice: number;
    taxesAmount: number;
    suggestedPriceAfterTax: number;
    taxes: number;
    suggestCarrier: boolean;
    tierName: string;
    tierLabel: string;
    planId: number;
    tierId: number;
    signature?: string;
    method?: string;
}

export interface OutPlanPricing {
    active: PlanPricing;
    booking: PlanPricing;
}

export const tiers: { [key: string]: string } = {
  essential: 'Tier 1',
  cortex: 'Tier 2',
  tesseract: 'Tier 3',
  'tesseract-plus': 'Tier 4'
}
