import SmartOMeterUseCases from '@/modules/ai/application/SmartOMeterUseCases'
import { SmartOMeterFeatures } from '@/modules/ai/domain/SmartOMeter/SmartOMeterFeatures'
import SmartOMeterController from '@/modules/ai/infrastructure/controllers/SmartOMeter.controller'
import { BikyConfigTabs } from '@/modules/bky/domain/BikyConfigTabs'
import Colors from '@/shared/domain/colors/Color'
import { FloatingWindowType } from '@/shared/infrastructure/enums/Layout/FloatingWindowType'
import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import BikyStoreController from '../../../persistence/BikyStore.controller'
import { CDPFieldsEnums } from '@/modules/CDP/shared/contacts/domain/enums/CDPFieldsEnums'

export default {
  name: 'BikyConfigSidebar',
  props: {
    selectedTab: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      KeybeIconType,
      smartOMeterController: new SmartOMeterController()
    }
  },
  components: { KeybeIcon },
  computed: {
    ...mapState('BillingStore', ['tier']),
    ...mapState('AppStore', ['selectedApp']),
    ...mapGetters('UserStore', ['isSuperAdmin']),
    hasBiky (): boolean {
      return SmartOMeterUseCases.hasPermission(this.smartOMeterController, SmartOMeterFeatures.BIKY_CONFIG, this.tier) || BikyStoreController.isBikyActive()
    },
    bikyAvatar (): string {
      return BikyStoreController.getBikyImage()
    },
    itemsGroups (): any[] {
      const itemsGroups = [[
        {
          name: BikyConfigTabs.INFO,
          label: this.$t(CDPFieldsEnums.GENERAL),
          icon: KeybeIconType.DOCUMENT,
          class: 'pink',
          color: Colors.black,
          disabled: false
        },
        {
          name: BikyConfigTabs.PERSONALITY,
          label: this.$t(CDPFieldsEnums.PERSONALITY),
          icon: KeybeIconType.MASKS,
          class: 'yellow',
          color: Colors.black,
          disabled: !this.hasBiky
        }
      ],
      [
        {
          name: BikyConfigTabs.APPEARANCE,
          label: this.$t('appearance'),
          icon: KeybeIconType.GLASSES,
          class: 'grey',
          color: Colors.black,
          disabled: true,
          soon: true
        },
        {
          name: BikyConfigTabs.KNOWLEDGE_BASE,
          label: this.$t('knowledge'),
          icon: KeybeIconType.BRAIN,
          class: 'blue',
          color: Colors.white,
          disabled: !this.hasBiky
        },
        {
          name: BikyConfigTabs.SKILLS,
          label: this.$t(CDPFieldsEnums.SKILLS),
          icon: KeybeIconType.TOOL,
          class: 'dark-grey',
          color: Colors.white,
          disabled: !this.hasBiky,
          soon: false
        }
      ],
      [
        {
          name: BikyConfigTabs.CHAT,
          label: this.$t('chatSimulation'),
          icon: KeybeIconType.CHAT_FILLED,
          color: Colors.black,
          class: 'grey',
          disabled: true,
          soon: true
        },
        {
          name: BikyConfigTabs.ERRORS,
          label: this.$t('errors'),
          icon: KeybeIconType.CLOSE_BOLD,
          color: Colors.white,
          class: 'rojo-bird',
          disabled: !this.hasBiky
        }
      ]
      ]

      if (this.isSuperAdmin) {
        itemsGroups.unshift([
          // @ts-ignore
          {
            name: BikyConfigTabs.BASIC,
            label: this.$t('configuration'),
            icon: KeybeIconType.TOOL,
            class: 'green',
            color: Colors.black
          }
        ])
      }

      return itemsGroups
    }
  },
  methods: {
    ...mapActions('FloatingWindowsStore', [
      'setMaximazedWindow'
    ]),
    isSelectedTab (tabName: string): boolean {
      return this.selectedTab === tabName
    },
    getColor (tab: any): string {
      if (this.isSelectedTab(tab.name)) {
        return this.$vuetify.theme.dark ? Colors.white : Colors.black
      }

      return tab.color
    },
    goBackToConfig (): void {
      this.setMaximazedWindow(null)
      this.setMaximazedWindow(FloatingWindowType.CONFIGURATION)
    },
    handleTabClick (tab): void {
      if (tab?.disabled) {
        return
      }
      this.$emit('selectTab', tab?.name)
    }
  }
}
