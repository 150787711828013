<template>
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 58 58"><path :fill="color" d="M28.985 57.333a28.277 28.277 0 0 1-15.644-4.707l-8.079 2.609h-.425c-.383 0-.75-.154-1.02-.425a1.39 1.39 0 0 1-.335-1.447l2.552-7.772a28.333 28.333 0 1 1 22.951 11.742Zm.017-51.04a22.685 22.685 0 1 0-.004 45.37 22.685 22.685 0 0 0 .004-45.37Zm7.923 36.122a21.655 21.655 0 0 1-7.129-1.953 29.516 29.516 0 0 1-11.91-10.549 13.518 13.518 0 0 1-2.835-7.202 7.767 7.767 0 0 1 2.609-5.842 3.59 3.59 0 0 1 2.325-.85h.936c.72 0 1.049 0 1.53 1.19.483 1.192 1.96 4.821 2.128 5.161a1.339 1.339 0 0 1 0 1.305c-.202.403-.46.777-.766 1.108-.15.176-.312.338-.465.497-.206.201-.4.413-.584.635a1.135 1.135 0 0 0-.284 1.503 21.426 21.426 0 0 0 3.885 4.82 17.88 17.88 0 0 0 5.671 3.488 1.557 1.557 0 0 0 1.702-.283 21.745 21.745 0 0 0 1.843-2.493 1.361 1.361 0 0 1 1.73-.482c.635.193 3.918 1.818 4.763 2.24l.363.176c.381.13.724.352.998.646a5.651 5.651 0 0 1-.453 3.346 6.56 6.56 0 0 1-4.68 3.375c-.453.11-.919.165-1.386.164h.009Z"/></svg>
</template>

<script>
export default {
  name: 'WhatsappIcon',
  props: {
    color: {
      type: String,
      default: '#000'
    }
  }
}
</script>

<style>

</style>
