import { mapActions, mapState } from 'vuex'

// Components
import KeybeAvatar from '@/shared/infrastructure/ui/KeybeAvatar.vue'
import KBButton from '@/shared/infrastructure/ui/buttons/KBButton.vue'
import NotificationCenterMedia from '@/modules/NotificationsCenter/infrastructure/ui/components/NotificationCenterMedia/NotificationCenterMedia.vue'
import DateTimeUtil from '@/utils/datetimeUltil'
import { FloatingWindowType } from '@/shared/infrastructure/enums/Layout/FloatingWindowType'
import { Task } from '@/modules/tasks/domain/Task'
import {
  MetadataEventTypeEnum,
  MetadataOriginEnum,
  ViewedBy
} from '@/modules/NotificationsCenter/domain/Notification'
import {
  dataToMarkNotificationAsViewed,
  notificationViewed
} from '@/modules/NotificationsCenter/domain/NotificationController'
import NotificationsUseCases from '@/modules/NotificationsCenter/application/NotificationUseCases'
import NotificationController from '../../../controllers/Notification.controller'
import { EventBus } from '@/eventBus'
import { ConfigurationTabsEnum } from '@/modules/configuration/application/enums/ConfigurationTabsEnum'

export default {
  components: {
    KeybeAvatar,
    KBButton,
    NotificationCenterMedia
  },
  props: {
    notification: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    ...mapState('UserStore', ['token', 'user']),
    userImage () {
      return this.user?.photo
    },
    kbLogo (): string {
      return (
        this.notification?.icon ||
        `${process.env.VUE_APP_STORAGE_URL}/files/notifications/keybe-default.png`
      )
    },
    notificationDescription (): string {
      const labelMap: {[key: string]: string} = {
        [MetadataEventTypeEnum.TASK_CREATED]: this.taskNotificationDescription(),
        [MetadataEventTypeEnum.TASK_EXPIRED]: this.taskNotificationDescription(),
        [MetadataEventTypeEnum.CHAT_CHANNEL]: this.channelNotificationDescription(),
        [MetadataEventTypeEnum.SALESCARD_ASSIGNED]: this.funnelNotificationDescription,
        [MetadataEventTypeEnum.SALESCARD_CHANGED_STEP]: this.salescardStepNotificationDescription,
        [MetadataEventTypeEnum.SALESCARD_WON]: this.salescardStepNotificationDescription,
        [MetadataEventTypeEnum.SALESCARD_LOST]: this.salescardStepNotificationDescription
      }
      return labelMap[this.notification?.metadata?.eventType] || ''
    },
    dateFormatted (): string {
      return this.toLocaleDateFormatted(
        this.notification?.publishAt,
        'yyyy-MM-dd HH:mm:ss'
      )
    },
    haveMedia (): boolean {
      return this.notification?.blocks?.length > 0 || false
    },
    notificationIsNotViewed (): boolean {
      const viewedByList: ViewedBy[] = this.notification?.viewedBy || []
      const viewed: boolean =
        viewedByList.some((el: ViewedBy) => el.user === this.user?.userId) ||
        this.notification?.viewed ||
        false
      return !viewed
    },
    funnelNotificationDescription (): string {
      return `
        ${this.$t('theSalescard')}
        [${this.notification?.payload?.name}]
        ${this.$t('onStep')}
        [${this.notification?.payload?.step?.name}]
        ${this.$t('ofFunnel')}
        [${this.notification?.payload?.funnel?.name}]
        ${this.$t('wasAssignedToYou')}
      `
    },
    salescardStepNotificationDescription (): string {
      return `
        ${this.$t('theSalescard')}
        [${this.notification?.payload?.name}]
        ${this.$t('ofFunnel')}
        [${this.notification?.payload?.funnel?.name}]
        ${this.$t('changedToStep')}
        [${this.notification?.payload?.step?.name}]
      `
    }
    // salePriority () {
    //   switch (this.notification?.priority) {
    //     case PriorityEnum.EMERGENCY:
    //       return { color: '#FF4938', text: `${this.$t('emergency-pipelines')}` }
    //     case PriorityEnum.HIGHEST:
    //       return { color: '#FF4938', text: `${this.$t('highest-pipelines')}` }
    //     case PriorityEnum.HIGH:
    //       return { color: '#FF5018', text: `${this.$t('high-pipelines')}` }
    //     case PriorityEnum.MEDIUM:
    //       return { color: '#EBAA05', text: `${this.$t('medium-pipelines')}` }
    //     case PriorityEnum.LOW:
    //       return { color: '#0FAEF6', text: `${this.$t('low-pipelines')}` }
    //     case PriorityEnum.LOWEST:
    //       return { color: '#0FAEF6', text: `${this.$t('lowest-pipelines')}` }
    //     default:
    //       return { color: '#EBAA05', text: `${this.$t('medium-pipelines')}` }
    //   }
    // }
  },
  data () {
    return {
      notificationController: new NotificationController()
    }
  },
  methods: {
    ...mapActions('FloatingWindowsStore', ['setMaximazedWindow']),
    ...mapActions('TasksStore', ['setTask']),
    async markNotificationAsViewed (): Promise<void> {
      if (this.notificationIsNotViewed) {
        const data: dataToMarkNotificationAsViewed = {
          id: this.notification?._id || '',
          token: this.token || ''
        }

        const markAsViewed: notificationViewed =
          await NotificationsUseCases.markNotificationAsViewed(
            this.notificationController,
            data
          )

        if (markAsViewed.success) {
          EventBus.$emit('update-task-reminder')
          this.$emit('markAsViewed')
        }
      }

      if (this.notification?.metadata?.origin === MetadataOriginEnum.TASK) {
        return this.showTask()
      } else if (
        this.notification?.metadata?.origin === MetadataOriginEnum.CHAT_CHANNEL
      ) {
        return this.showChannel()
      }
    },
    showTask (): void {
      if (this.notification?.payload) {
        const task: Task = this.notification?.payload
        this.setTask(task)
        this.setMaximazedWindow(FloatingWindowType.TASKS)
        this.$emit('close')
      }
    },
    showChannel (): void {
      this.setMaximazedWindow(FloatingWindowType.CONFIGURATION)

      setTimeout(() => {
        EventBus.$emit('setConfigurationTab', ConfigurationTabsEnum.CONNECT_CHANNELS)
        this.$emit('close')
      }, 500)
    },
    toLocaleDateFormatted (
      utcDate?: string,
      format: string = 'yyyy-MM-dd HH:mm:ss'
    ): string {
      return DateTimeUtil.toLocaleDateFormatted(utcDate, format)
    },
    taskNotificationDescription (): string {
      const description: string = this.notification?.payload?.description || ''
      if (this.notification?.title === '$taskexpired$') {
        return (
          description +
          ' ' +
          this.toLocaleDateFormatted(
            this.notification?.payload?.date,
            'yyyy-mm-dd hh:mm a'
          )
        )
      }

      return description
    },
    channelNotificationDescription (): string {
      return (
        this.notification?.payload?.description ||
        this.$t(`${this.notification?.title}Alert`) ||
        ''
      )
    }
  }
}
