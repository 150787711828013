import { EventBus } from '@/eventBus'
import KBButton from '@/shared/infrastructure/ui/buttons/KBButton.vue'
export default {
  components: {
    KBButton
  },
  methods: {
    cancel (): void {
      this.$emit('cancel')
    },
    deleteUser (): void {
      EventBus.$emit('deleteUserAgreement--configuration')
    }
  }
}
