import { GenericResponse } from '@/models/apis/GenericResponse'
import { Inbox } from '../domain/Inbox'
import { IInboxController, addUserToInboxDTO, dataToGetInbox, defaultInboxDTO, inboxCreateDTO, inboxDeleteDTO, inboxUpdateDTO, removeUserFromInboxDTO } from '../domain/InboxController'
import InboxRepository from './Inbox.repository'

export default class InboxController implements IInboxController {
  repository: InboxRepository

  constructor () {
    this.repository = new InboxRepository()
  }

  async setDefaultInbox (data: defaultInboxDTO): Promise<boolean> {
    const response: GenericResponse = await this.repository.setDefaultInbox(data)
    return response.status || false
  }

  async getInbox (data: dataToGetInbox): Promise<Inbox[]> {
    const response: GenericResponse = await this.repository.getInbox(data)
    if (!response.status) {
      return []
    }

    return response.data
  }

  async createInbox (inbox: inboxCreateDTO): Promise<Inbox> {
    const response: GenericResponse = await this.repository.createInbox(inbox)
    if (!response.status) {
      return null
    }

    return response.data
  }

  async updateInbox (inbox: inboxUpdateDTO): Promise<Inbox> {
    const response: GenericResponse = await this.repository.updateInbox(inbox)

    if (!response.status) {
      return null
    }
    return response.data
  }

  async deleteInbox (inbox: inboxDeleteDTO): Promise<boolean> {
    const response: GenericResponse = await this.repository.deleteInbox(inbox)
    return response.status
  }

  async addUserToInbox (inbox: addUserToInboxDTO): Promise<Inbox> {
    const response: GenericResponse = await this.repository.addUserToInbox(inbox)
    if (!response.status) {
      return null
    }

    return response.data
  }

  async removeUserFromInbox (inbox: removeUserFromInboxDTO): Promise<Inbox> {
    const response: GenericResponse = await this.repository.removeUserFromInbox(inbox)
    if (!response.status) {
      return null
    }

    return response.data
  }
}
