import KBAutocompleteSmall from '@/shared/infrastructure/ui/inputs/select/KBAutocompleteSmall/KBAutocompleteSmall.vue'
export default {
  props: {
    value: {
      type: String,
      default: ''
    },
    values: {
      type: Object,
      default: () => ({})
    },
    format: {
      type: Boolean,
      default: false
    },
    autofill: {
      type: Boolean,
      default: false
    },
    startYear: {
      type: Number,
      default: 1980
    },
    stopYear: {
      type: Number,
      default: new Date().getFullYear()
    },
    returnObject: {
      type: Boolean,
      default: false
    },
    KBDateClass: {
      type: String,
      default: 'KBDate KBDate--big-padding'
    },
    placeholder: {
      type: String
    },
    showPlaceholder: {
      type: Boolean,
      default: false
    }
  },
  components: {
    KBAutocompleteSmall
  },
  mounted () {
    this.setValues()
    this.getYears()
    this.getDays()
  },
  data () {
    return {
      day: null,
      month: null,
      year: null,
      date: '',
      rerender: 0,
      years: [],
      months: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      days: []
    }
  },
  methods: {
    setYear (e: string | number) {
      if (e) {
        this.year = +e
        this.verify()
      }
    },
    setMonth (e: string | number) {
      if (e) {
        this.month = +e
        this.day = null
        this.verify()
      }
    },
    checkLeapYear (year: number): boolean {
      if ((year % 4 === 0 && year % 100 !== 0) || year % 400 === 0) {
        return true
      } else {
        return false
      }
    },
    setDay (e: string | number) {
      if (e) {
        this.day = +e
        this.verify()
      }
    },
    getYears () {
      const currentYear = new Date().getFullYear()
      let startYear = this.startYear || 1980
      const stopYear = this.stopYear || currentYear
      while (startYear <= stopYear) {
        this.years.push(startYear++)
      }
      this.years = this.years.reverse()
    },
    getDays (e: number) {
      const leapYear = this.checkLeapYear(this.year)

      const daysByMonth: { [key: number]: number } = {
        1: 31,
        2: leapYear ? 29 : 28,
        3: 31,
        4: 30,
        5: 31,
        6: 30,
        7: 31,
        8: 31,
        9: 30,
        10: 31,
        11: 30,
        12: 31
      }

      const totalDays = daysByMonth[e] || 0

      this.days = []

      for (let i = 1; i <= totalDays; i++) {
        this.days.push(i)
      }

      this.setMonth(e)
    },
    setValues () {
      if (this.values && Object.keys(this.values).length > 0) {
        this.day = this.values.day
        this.month = this.values.month
        this.year = this.values.year
        return
      }
      if (this.autofill && this.value) {
        const date = new Date(this.value)
        if (!this.day) this.day = date.getDate() + 1
        if (!this.month) {
          this.month =
            date.getMonth() + 1 < 10
              ? '0' + +(date.getMonth() + 1)
              : date.getMonth() + 1
        }
        if (!this.year) this.year = date.getFullYear()
      }
      if (!this.value) {
        this.day = null
        this.month = null
        this.year = null
      }
    },
    verify () {
      if (this.day && this.month > 0 && this.year) {
        if (this.returnObject) {
          this.$emit('input', {
            day: this.day.toString(),
            month: this.month.toString(),
            year: this.year.toString()
          })
          this.$emit('save', {
            day: this.day.toString(),
            month: this.month.toString(),
            year: this.year.toString()
          })
        } else {
          this.date = new Date(`${this.year}-${this.month}-${+this.day}`)
          if (this.format) {
            this.date = new Date(this.date).toISOString().split('T')[0]
          }
          this.$emit('input', this.date)
          this.$emit('save', this.date)
        }
      }
    }
  },
  watch: {
    value () {
      this.setValues()
      this.rerender++
    }
  }
}
