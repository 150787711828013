





































import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import Colors from '@/shared/domain/colors/Color'

import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'
import KeybeAvatar from '@/shared/infrastructure/ui/KeybeAvatar.vue'
import KBText from '@/shared/infrastructure/ui/inputs/text/KBText.vue'

export default {
  name: 'UserSearch',
  components: {
    KBText,
    KeybeAvatar,
    KeybeIcon
  },
  props: {
    users: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      search: '',
      clearInput: false,
      KeybeIconType,
      Colors
    }
  },
  methods: {
    handleCallback (user) {
      this.$emit('callback', user)
      this.search = ''
      this.clearInput = true
      this.$nextTick(() => {
        this.clearInput = false
      })
    },
    getUserName (user) {
      if (user?.fullName) {
        return user.fullName
      }
      if (user?.lastName) {
        return `${user?.name} ${user?.lastName}`
      }
      return user?.name || ''
    },
    getUserPhoto (user) {
      return user?.image || user?.photo || ''
    }
  },
  computed: {
    usersComputed () {
      return this.users.filter((user) => {
        const name = this.getUserName(user)
        return name.toLowerCase().includes(this.search.toLowerCase())
      })
    }
  }
}
