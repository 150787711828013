<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       fill="none"
       viewBox="0 0 192 57">
    <g clip-path="url(#a)">
      <path fill="url(#b)"
            d="M119.637.046v.489h1.878V5.39h.565V.535h1.878v-.49h-4.321ZM72.609 4.009 70.29.046h-.466V5.39h.542V1.138l2.099 3.574h.267l2.1-3.597V5.39h.542V.046h-.466l-2.298 3.963ZM79.847.046 77.403 5.39h.588l.642-1.428h2.977l.641 1.428h.596L80.404.046h-.557Zm-1.008 3.46L80.12.633l1.283 2.871h-2.565ZM64.158 1.45c0-.535.435-.97 1.412-.97.474 0 1.008.145 1.466.443l.19-.45C66.8.175 66.174 0 65.57 0c-1.359 0-1.97.68-1.97 1.466 0 1.978 3.29 1.077 3.29 2.543 0 .527-.434.947-1.434.947-.703 0-1.382-.275-1.764-.65l-.221.436c.397.413 1.183.695 1.985.695 1.366 0 1.992-.672 1.992-1.458 0-1.948-3.29-1.062-3.29-2.528ZM115.713.046 113.27 5.39h.588l.641-1.428h2.977l.642 1.428h.595L116.27.046h-.557Zm-1.008 3.46 1.283-2.872 1.282 2.871h-2.565Zm-4.023-1.078h-3.344V.046h-.565V5.39h.565V2.925h3.344V5.39h.557V.046h-.557v2.382ZM90.626.535h1.878V5.39h.565V.535h1.878v-.49h-4.32v.49Zm11.856-.031c.611 0 1.146.19 1.581.634l.359-.36C103.948.26 103.246 0 102.467 0c-1.611 0-2.802 1.153-2.802 2.719 0 1.565 1.191 2.718 2.795 2.718.786 0 1.488-.267 1.962-.786l-.359-.36c-.435.451-.97.642-1.581.642-1.29 0-2.259-.947-2.259-2.214 0-1.268.969-2.215 2.259-2.215Zm-13.42 1.375c0-1.146-.825-1.833-2.184-1.833h-2V5.39h.564V3.696h1.436c.153 0 .29-.008.427-.023l1.222 1.718h.618L87.84 3.56c.778-.252 1.221-.84 1.221-1.68Zm-2.2 1.336h-1.42V.535h1.42c1.07 0 1.634.488 1.634 1.344 0 .855-.565 1.336-1.633 1.336Z"/>
      <path fill="#49FF9B"
            d="M7.517 30.968.875 37.802v7.102H.11V18.177h.764v18.594l18.093-18.594h1.03L8.09 30.433l12.788 14.471h-1.03L7.516 30.968ZM48.514 37.914c0 4.506-3.168 6.987-9.657 6.987H27.52V18.174h10.573c5.497 0 8.97 2.291 8.97 6.759 0 3.436-2.06 5.498-5.267 6.261 4.466.611 6.718 2.864 6.718 6.72ZM28.283 18.9v12.065h9.963c5.077 0 8.054-2.062 8.054-6.032 0-3.971-2.977-6.033-8.054-6.033h-9.963Zm19.468 19.014c0-4.314-3.054-6.223-8.742-6.223H28.283v12.485h10.726c5.688 0 8.742-1.947 8.742-6.262ZM54.43 26.042c0-.534.458-.992.992-.992.535 0 .993.458.993.992 0 .535-.458.993-.993.993a1.01 1.01 0 0 1-.992-.993Zm0 17.984c0-.535.458-.993.992-.993.535 0 .993.458.993.993 0 .534-.458.992-.993.992a1.01 1.01 0 0 1-.992-.992ZM137.529 18.177l-12.025 26.727h-.763l-12.024-26.727h.878l11.528 25.696 11.528-25.696h.878ZM159.097 37.189c0 4.734-3.55 7.827-8.512 7.827-6.947 0-10.421-5.193-10.421-13.478 0-8.973 4.581-13.478 11.375-13.478 2.138 0 4.123.305 5.688 1.26l-.382.649c-1.336-.84-3.244-1.184-5.306-1.184-6.413 0-10.611 4.238-10.611 12.677 0 1.145.076 2.978.534 4.772.687-4.314 4.352-6.834 8.856-6.834 5.344 0 8.779 3.054 8.779 7.789Zm-.763 0c0-4.277-3.054-7.064-8.016-7.064-4.963 0-8.207 3.17-8.207 7.14 0 3.36 2.863 7.026 8.474 7.026 4.581 0 7.749-2.826 7.749-7.102ZM164.328 44.023c0-.534.458-.992.993-.992.534 0 .992.458.992.992 0 .535-.458.993-.992.993a1.01 1.01 0 0 1-.993-.993ZM171.615 31.54c0-8.17 4.123-13.478 10.192-13.478s10.192 5.307 10.192 13.478-4.123 13.478-10.192 13.478-10.192-5.307-10.192-13.478Zm19.621 0c0-7.827-3.818-12.752-9.429-12.752-5.611 0-9.428 4.925-9.428 12.752 0 7.828 3.817 12.753 9.428 12.753 5.611 0 9.429-4.925 9.429-12.753Z"/>
      <path fill="#3AF69B"
            d="M4.145 54.358h.135v1.955c-.425.458-1.035.687-1.71.687C1.094 57 0 55.847 0 54.304c0-1.542 1.094-2.695 2.57-2.695.675 0 1.285.221 1.71.68l-.084.106c-.455-.465-1-.641-1.626-.641-1.392 0-2.427 1.107-2.427 2.55 0 1.444 1.035 2.551 2.427 2.551.603 0 1.15-.168 1.575-.603v-1.894ZM19.127 56.977l-1.264-1.886a2.816 2.816 0 0 1-.583.053h-1.54v1.833h-.142v-5.345h1.682c1.221 0 1.952.649 1.952 1.756 0 .863-.448 1.451-1.222 1.665l1.293 1.924h-.178.002ZM17.295 55c1.163 0 1.797-.589 1.797-1.612 0-1.023-.631-1.611-1.797-1.611H15.74v3.222h1.555ZM33.568 56.832v.145H30.26v-5.345h3.201v.145h-3.059v2.413h2.741v.145h-2.74v2.497h3.165ZM47.888 56.832v.145H44.58v-5.345h3.201v.145h-3.058v2.413h2.74v.145h-2.74v2.497h3.165ZM62.806 51.632v5.345h-.12l-3.641-5.063v5.063h-.143v-5.345h.12l3.642 5.063v-5.063h.142ZM90.524 51.632v5.345h-.143V51.96l-2.265 4.261h-.084l-2.264-4.245v5.001h-.143v-5.345h.12l2.328 4.383 2.329-4.383h.122ZM101.467 54.304c0-1.542 1.092-2.695 2.547-2.695 1.456 0 2.547 1.153 2.547 2.695 0 1.543-1.094 2.696-2.547 2.696s-2.547-1.153-2.547-2.696Zm4.955 0c0-1.458-1.036-2.55-2.408-2.55-1.372 0-2.407 1.092-2.407 2.55 0 1.459 1.035 2.551 2.407 2.551s2.408-1.092 2.408-2.55ZM117.463 54.74v-3.108h.142v3.1c0 1.428.647 2.123 1.774 2.123s1.774-.695 1.774-2.123v-3.1h.142v3.108c0 1.512-.73 2.26-1.916 2.26s-1.916-.748-1.916-2.26ZM136.624 51.632v5.345h-.119l-3.642-5.063v5.063h-.142v-5.345h.119l3.642 5.063v-5.063h.142ZM149.021 51.777h-1.845v-.145h3.832v.145h-1.845v5.2h-.142v-5.2ZM164.328 55.366h-3.039l-.694 1.611h-.163l2.305-5.345h.143l2.305 5.345h-.163l-.694-1.611Zm-.064-.145-1.456-3.383-1.455 3.383h2.911ZM175.773 51.632h.144v5.345h-.144v-5.345ZM191.287 51.632v5.345h-.12l-3.642-5.063v5.063h-.142v-5.345h.119l3.642 5.063v-5.063h.143Z"/>
      <mask id="c"
            width="58"
            height="32"
            x="59"
            y="13"
            maskUnits="userSpaceOnUse"
            style="mask-type:luminance">
        <path fill="#fff"
              d="M116.837 13.137H59.75v31.624h57.087V13.137Z"/>
      </mask>
      <g fill="#49FF9B"
         mask="url(#c)">
        <path d="M96.543 46.75c-2.782-1.263-5.181-3.882-7.34-8.008-.157-.303-.315-.611-.47-.92-1.016-1.998-2.067-4.062-3.787-5.088-3.148-1.878-8.616-.112-11.47 3.709-.592.794-1.07 1.718-1.534 2.614-.203.395-.414.802-.633 1.2-1.296 2.33-3.01 4.41-4.96 6.014l-.45-.547c1.88-1.548 3.537-3.556 4.789-5.812.213-.386.422-.789.623-1.178.476-.922.967-1.873 1.596-2.714 3.054-4.088 8.965-5.943 12.403-3.894 1.896 1.13 2.993 3.289 4.057 5.376.155.305.31.61.468.911 2.082 3.981 4.372 6.496 7.003 7.69l-.292.646h-.003Z"/>
        <path d="m73.502 46.658-.102-.703c.334-.048.715-.435.998-1.008.11-.221.201-.453.298-.697.142-.359.29-.728.499-1.074.814-1.357 2.47-1.953 3.616-1.3.465.264.804.679 1.13 1.078a10.07 10.07 0 0 0 2.977 2.52l-.349.619a10.772 10.772 0 0 1-2.987-2.457l-.191-.231c-.298-.367-.58-.713-.931-.911-.805-.459-2.047.03-2.655 1.048-.178.296-.308.624-.448.97-.099.247-.2.502-.32.748-.189.382-.713 1.28-1.532 1.4l-.003-.002Z"/>
        <path d="M88.013 46.52a17.298 17.298 0 0 1-4.09-4.187c-.305-.44-.6-.904-.883-1.352-.672-1.058-1.364-2.153-2.242-2.96-1.076-.99-2.804-1.705-4.43-.74-1.334.788-2.123 2.357-2.96 4.014-.43.852-.873 1.733-1.402 2.51a9.388 9.388 0 0 1-2 2.148l-.436-.56a8.745 8.745 0 0 0 1.85-1.988c.504-.738.937-1.599 1.357-2.431.845-1.678 1.72-3.411 3.232-4.307 1.616-.957 3.687-.631 5.272.83.947.87 1.665 2.006 2.362 3.103.28.44.57.898.868 1.326a16.539 16.539 0 0 0 3.921 4.017l-.414.577h-.005Z"/>
        <path d="M93.358 46.885c-2.894-1.528-5.207-4.05-7.276-6.547l-.4-.484c-1.542-1.873-3.137-3.813-5.214-4.788-2.097-.985-5.234-.74-7.176 1.632l-.55-.45c2.17-2.656 5.683-2.928 8.026-1.826 2.222 1.044 3.869 3.044 5.462 4.979l.4.483c2.02 2.442 4.274 4.9 7.058 6.372l-.33.629Z"/>
        <path d="m61.293 45.444-.318-.637c2.913-1.456 5.443-4.078 7.889-6.613l.22-.231c.495-.512 1.052-1.092 1.703-1.48.774-.46 1.57-.554 2.181-.259l-.31.639c-.387-.188-.95-.102-1.51.23-.57.338-1.093.88-1.554 1.358l-.222.232c-2.486 2.578-5.059 5.246-8.082 6.756l.003.005ZM105.498 45.785l-5.031-12.165c-1.82-4.398-3.7-8.947-6.194-12.887-.44-.695-.924-1.408-1.575-1.808-1.563-.96-3.754.072-5.232 1.517-1.22 1.192-2.201 2.732-3.153 4.22-.318.497-.644 1.011-.977 1.505-1.754 2.607-4.184 5.307-6.673 7.407l-.458-.542c2.44-2.062 4.823-4.706 6.54-7.262.328-.489.654-.998.97-1.492.972-1.522 1.977-3.097 3.255-4.345 1.674-1.636 4.211-2.772 6.1-1.613.778.478 1.318 1.265 1.801 2.03 2.527 3.992 4.418 8.569 6.25 12.995l5.031 12.165-.656.272.002.003Z"/>
        <path d="M91.364 42.153c-.038-2.979-.285-7.293-1.97-10.353-1.624-2.947-4.733-4.457-7.56-3.668l-.191-.684c3.145-.876 6.588.773 8.372 4.011 1.763 3.197 2.018 7.629 2.059 10.686l-.71.01v-.002Z"/>
        <path d="M96.708 46.157c-.239-3.826-.565-7.876-1.626-11.63-1.14-4.022-3.026-7.105-5.453-8.92-1.965-1.466-3.576-1.835-5.227-1.196l-.258-.662c2.466-.957 4.553.277 5.907 1.288 2.555 1.91 4.53 5.121 5.71 9.293 1.082 3.821 1.42 8.082 1.652 11.778l-.707.046.002.002Z"/>
        <path d="M101.295 46.745c-1.367-3.022-2.044-6.499-2.7-9.864-.071-.359-.14-.718-.211-1.074-.716-3.622-1.565-7.407-3.39-10.406-1.967-3.233-4.917-5-7.897-4.712l-.066-.707c3.258-.308 6.456 1.58 8.569 5.05 1.885 3.098 2.753 6.954 3.48 10.637.072.36.14.718.212 1.077.649 3.324 1.318 6.76 2.649 9.706l-.646.293ZM110.944 46.044a.403.403 0 0 1-.191-.048c-.17-.092-.231-.247-.269-.341l-.011-.026c-1.773-1.328-3.282-3.148-4.366-5.266-.173-.339-.339-.69-.499-1.03-.598-1.266-1.217-2.577-2.232-3.287-.262-.183-.555-.334-.863-.494-.74-.382-1.577-.812-1.939-1.769l.664-.252c.265.703.914 1.036 1.601 1.39.318.163.644.33.944.542 1.171.817 1.83 2.215 2.466 3.564.158.336.323.682.491 1.01 1.041 2.034 2.492 3.78 4.194 5.048a.42.42 0 0 1 .084.079c.059 0 .115.017.163.045.219.125.234.41.122.614a.411.411 0 0 1-.359.221Zm-.206-.633a.256.256 0 0 0-.058.07c-.056.103.015.27.15.347.173.097.374.025.448-.102l-.542-.315h.002ZM114.689 46.057c-2.874-1.59-4.545-4.796-6.159-7.893-.702-1.35-1.43-2.747-2.244-3.979-1.932-2.927-5.469-5.918-8.604-7.272l.282-.652c3.301 1.426 6.884 4.455 8.915 7.532.834 1.265 1.57 2.678 2.282 4.042 1.639 3.144 3.186 6.112 5.874 7.601l-.344.621h-.002Z"/>
      </g>
    </g>
    <defs>
      <linearGradient id="b"
                      x1="63.471"
                      x2="123.958"
                      y1="2.719"
                      y2="2.719"
                      gradientUnits="userSpaceOnUse">
        <stop stop-color="#00D897"/>
        <stop offset=".09"
              stop-color="#00E39B"/>
        <stop offset=".24"
              stop-color="#00E6B4"/>
        <stop offset=".42"
              stop-color="#00E8C8"/>
        <stop offset=".69"
              stop-color="#00E6E6"/>
        <stop offset=".84"
              stop-color="#00E1F0"/>
        <stop offset="1"
              stop-color="#00DCF0"/>
      </linearGradient>
      <clipPath id="a">
        <path fill="#fff"
              d="M0 0h192v57H0z"/>
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'LogoV6Icon',
  props: {
    color: {
      type: String,
      default: '#01CFE4'
    }
  }
}
</script>

<style>

</style>
