import store from '@/store'
import axios, { AxiosRequestConfig } from 'axios'
import { GupshupConfigParams } from '@/components/configuration/Models/GupshupModels'
import { RestResponseFactory } from '@/models/apis/RestResponse'

const GS_CONTAINER_DETAILS_URL = 'https://whatsapp-support.gupshup.io/wabamanager/containerDetails/'
const GS_CONTAINER_DETAILS_IMAGE = 'https://api.gupshup.io/wa/app/'

export default class GupshupService {
  static async getFreelines () {
    const url = `${process.env.VUE_APP_GUPSHUP_API}/free-lines`
    const token = store.getters['UserStore/getToken']
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    return new RestResponseFactory().process({ response })
  }

  static async createFreeline (data) {
    try {
      const url = `${process.env.VUE_APP_GUPSHUP_API}/free-lines`
      const token = store.getters['UserStore/getToken']
      const response = await axios.post(url, data, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      return new RestResponseFactory().process({ response })
    } catch (e) {
      console.error(e)
    }
  }

  static async updateGupshupInfo (data: GupshupConfigParams) {
    try {
      const appId = store.getters['AppStore/getSelectedApp']?.uuid
      const companyId = store.getters['CompanyStore/getSelectedCompany']?.uuid
      const url = `${process.env.VUE_APP_GUPSHUP_API}/gupshup-wp`
      data.appUUID = appId
      data.companyUUID = companyId
      const token = store.getters['UserStore/getToken']
      const response = await axios.put(url, data, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      return new RestResponseFactory().process({ response })
    } catch (error) {
      console.error(error)
    }
  }

  static async createGupshupInfo (data) {
    try {
      const appId = store.getters['AppStore/getSelectedApp']?.uuid
      const companyId = store.getters['CompanyStore/getSelectedCompany']?.uuid
      const url = `${process.env.VUE_APP_GUPSHUP_API}/gupshup-wp`
      data.appUUID = appId
      data.companyUUID = companyId
      const token = store.getters['UserStore/getToken']
      const response = await axios.post(url, data, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      return new RestResponseFactory().process({ response })
    } catch (error) {
      console.error(error)
    }
  }

  static async updateTemplateGupshup () {
    try {
      const appId = store.getters['AppStore/getSelectedApp']?.uuid
      const companyId = store.getters['CompanyStore/getSelectedCompany']?.uuid
      const token = store.getters['UserStore/getV4Token']
      const url = `${process.env.VUE_APP_V4_CONVERSATIONS_API}configurations/company/${companyId}/app/${appId}/templates-gupshup`
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      return response.data
    } catch (e) {
      console.log(e)
    }
  }

  static async bindLine () {
    const appId = store.getters['AppStore/getSelectedApp']?.uuid
    const companyId = store.getters['CompanyStore/getSelectedCompany']?.uuid
    const appName = store.getters['AppStore/getSelectedApp']?.name
    const url = `${process.env.VUE_APP_GUPSHUP_API}/bind-line`
    const data = {
      appUUID: appId,
      companyUUID: companyId,
      appName
    }
    const token = store.getters['UserStore/getToken']
    const response = await axios.post(url, data, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    return new RestResponseFactory().process({ response })
  }

  static async getBotName (appName: string, apiKey: string) {
    const url = `${GS_CONTAINER_DETAILS_URL}botName?botname=${appName}`
    const config: AxiosRequestConfig = {
      headers: {
        authorization: apiKey,
        apikey: apiKey
      }
    }
    const response = await axios.get(url, config)
    return response.data
  }

  static async getProfilePhoto (gupshupBusinessId: string, apiKey: string) {
    const url = `${GS_CONTAINER_DETAILS_IMAGE}${gupshupBusinessId}/business/profile/photo`
    const config: AxiosRequestConfig = {
      headers: {
        authorization: apiKey,
        apikey: apiKey
      }
    }
    const response = await axios.get(url, config)
    return response.data.message
  }

  static async updateProfilePhoto (photoFile: File, gupshupBusinessId: string, apiKey: string) {
    const url = `${GS_CONTAINER_DETAILS_IMAGE}${gupshupBusinessId}/business/profile/photo`
    const data = new FormData()
    data.append('image', photoFile)
    const config: AxiosRequestConfig = {
      headers: {
        'Content-Type': 'multipart/form-data',
        authorization: apiKey,
        apikey: apiKey
      }
    }
    const response = await axios.put(url, data, config)
    return response.data
  }
}
