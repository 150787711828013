import { WebWidgetPositionEnum, WebWidgetThemeEnum } from '@/modules/configuration/domain/models/WebWidget/WebWidget'
import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import KeybeText from '@/modules/DesignSystem/infrastructure/components/KeybeText/KeybeText.vue'
import KeybeSelect from '@/modules/DesignSystem/infrastructure/components/KeybeSelect/KeybeSelect/KeybeSelect.vue'
import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'
import KeybeAvatar from '@/shared/infrastructure/ui/KeybeAvatar.vue'
import KBColorPicker from '@/shared/infrastructure/ui/inputs/KBColorPicker.vue'
import KBSelect from '@/shared/infrastructure/ui/inputs/select/KBSelect.vue'
import KBText from '@/shared/infrastructure/ui/inputs/text/KBText.vue'
import UserService from '@/services/UserService'

export default {
  name: 'WebWidgetCollapsedConfig',
  props: {
    config: {
      type: Object,
      required: true
    },
    dataTocreate: {
      type: Object,
      required: true
    }
  },
  components: {
    KBSelect,
    KBColorPicker,
    KBText,
    KeybeAvatar,
    KeybeIcon,
    KeybeText,
    KeybeSelect
  },
  data () {
    return {
      modeOptions: [
        {
          text: this.$t('light'),
          value: WebWidgetThemeEnum.LIGHT
        },
        {
          text: this.$t('dark'),
          value: WebWidgetThemeEnum.DARK
        }
      ],
      positionOptions: [
        {
          text: this.$t('left'),
          value: WebWidgetPositionEnum.LEFT
        },
        {
          text: this.$t('right'),
          value: WebWidgetPositionEnum.RIGHT
        }
      ],
      bubbleSizeOptions: [{
        text: this.$t('small'),
        value: '60'
      },
      {
        text: this.$t('medium'),
        value: '80'
      },
      {
        text: this.$t('large'),
        value: '120'
      }, {
        text: this.$t('extraLarge'),
        value: '170'
      }],
      logo: '',
      rerender: 0,
      KeybeIconType
    }
  },
  methods: {
    openFileInput () {
      this.$refs.imageInput.click()
    },
    async onFilePicked (event) {
      const img = event.target.files[0]
      const response = await UserService.uploadImage(img)
      if (response.status) {
        this.config.logo = response?.data?.data?.info?.location || ''
        this.rerender++
      }
    }
  }
}
