import { KeybeSelectTypeEnum } from '@/modules/DesignSystem/domain/models/KeybeSelectTypeEnum'
import { Component as VueComponent } from 'vue'
import KeybeAutocompleteTextValue from '../KeybeAutocompleteTextValue/KeybeAutocompleteTextValue.vue'
import KeybeAutocompleteTextObject from '../KeybeAutocompleteTextObject/KeybeAutocompleteTextObject.vue'
import KeybeAutocompleteString from '../KeybeAutocompleteString/KeybeAutocompleteString.vue'
import KeybeAutocompleteMultiple from '../KeybeAutocompleteMultiple/KeybeAutocompleteMultiple.vue'

export default {
  props: {
    value: {
      type: [String, Number, Array, Object]
    },
    itemText: {
      type: String
    },
    itemValue: {
      type: String
    },
    returnObject: {
      type: Boolean
    },
    items: {
      type: Array,
      required: true
    },
    loading: {
      type: Boolean
    },
    disabled: {
      type: Boolean
    },
    type: {
      type: String
    },
    placeholder: {
      type: String
    },
    multiple: {
      type: Boolean,
      default: false
    },
    autoSearch: {
      type: String
    },
    scrollable: {
      type: Boolean,
      default: false
    },
    customZIndex: {
      type: String,
      default: ''
    }
  },
  computed: {
    selectComponent (): VueComponent {
      let type: string = ''
      if (this.multiple) type = KeybeSelectTypeEnum.MULTIPLE
      else if (!this.itemText && !this.itemValue) type = KeybeSelectTypeEnum.NONE
      else if ((this.itemText && !this.itemValue) || this.returnObject) type = KeybeSelectTypeEnum.TEXT_OBJECT
      else if (this.itemText && this.itemValue) type = KeybeSelectTypeEnum.TEXT_VALUE
      const componentMap: { [key: string]: any } = {
        [KeybeSelectTypeEnum.TEXT_VALUE]: KeybeAutocompleteTextValue,
        [KeybeSelectTypeEnum.TEXT_OBJECT]: KeybeAutocompleteTextObject,
        [KeybeSelectTypeEnum.NONE]: KeybeAutocompleteString,
        [KeybeSelectTypeEnum.MULTIPLE]: KeybeAutocompleteMultiple
      }

      return componentMap[type] || KeybeAutocompleteString
    }
  }
}
