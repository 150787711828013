export default {
  components: {},
  props: {
    block: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    mediaBackground (): string {
      return `url(${this.block?.file?.url || ''})`
    }
  },
  data () {
    return {}
  }
}
