































































































import { WebWidgetTextAlignEnum } from '@/modules/configuration/domain/models/WebWidget/WebWidget'

import KBColorPicker from '@/shared/infrastructure/ui/inputs/KBColorPicker.vue'
import KBSelect from '@/shared/infrastructure/ui/inputs/select/KBSelect.vue'
import KBText from '@/shared/infrastructure/ui/inputs/text/KBText.vue'
import { CDPFieldsEnums } from '@/modules/CDP/shared/contacts/domain/enums/CDPFieldsEnums'
import { mapState } from 'vuex'
import { ActivityActionTypeEnum, ActivityLogModuleTypeEnum, dataToWriteActivityLog } from '@/modules/Config/ActivityLogs/domain/ActivityLogsController'
import ActivityLogsUseCases from '@/modules/Config/ActivityLogs/application/ActivityLogsUseCases'
import ActivityLogsController from '@/modules/Config/ActivityLogs/infrastructure/controllers/ActivityLogs.controller'
import { debounce } from 'lodash'
export default {
  name: 'WebWidgetExpandedConfig',
  props: ['value'],
  components: {
    KBSelect,
    KBColorPicker,
    KBText
  },
  data () {
    return {
      activityLogsController: new ActivityLogsController(),
      CDPFieldsEnums,
      config: null,
      languages: [{
        text: this.$t('spanish'),
        value: 'es'
      }, {
        text: this.$t('english'),
        value: 'en'
      }],
      fontSizeOptions: [{
        text: this.$t('small'),
        value: '12'
      },
      {
        text: this.$t('medium'),
        value: '14'
      },
      {
        text: this.$t('large'),
        value: '16'
      }, {
        text: this.$t('extraLarge'),
        value: '18'
      }],
      fontAlignOptions: [{
        text: this.$t('left'),
        value: WebWidgetTextAlignEnum.LEFT
      }, {
        text: this.$t('center'),
        value: WebWidgetTextAlignEnum.CENTER
      }, {
        text: this.$t('right'),
        value: WebWidgetTextAlignEnum.RIGHT
      }],
      oldValues: {}
    }
  },
  created () {
    this.config = this.value
    // eslint-disable-next-line no-undef
    this.oldValues = structuredClone(this.value)
  },
  watch: {
    value () {
      this.config = this.value
    },
    config () {
      this.$emit('input', this.config)
    }
  },
  computed: {
    ...mapState('UserStore', ['isUserReadonly', 'user', 'token']),
    ...mapState('AppStore', ['selectedApp']),
    textStyle () {
      return {
        color: this.config.welcomeForm.color,
        fontSize: `${this.config.welcomeForm.fontSize}px`,
        textAlign: this.config.welcomeForm.textAlign
      }
    }
  },
  methods: {
    writeActivityLog: debounce(async function (field: string, isWelcomeForm: boolean) {
      const data: dataToWriteActivityLog = {
        token: this.token,
        updateInput: {
          appUUID: this.selectedApp?.uuid,
          module: ActivityLogModuleTypeEnum.CHAT,
          action: ActivityActionTypeEnum.WIDGET_WEB_UPDATE,
          user: {
            id: this.user.uuid,
            name: this.user.name + ' ' + this.user.lastName
          },
          payload: {
            field: field,
            newValue: isWelcomeForm ? this.config.welcomeForm[field] : this.config[field],
            oldValue: isWelcomeForm ? this.oldValues.welcomeForm[field] : this.oldValues[field]
          }
        }
      }
      await ActivityLogsUseCases.writeActivityLog(this.activityLogsController, data)
    }, 600)
  }
}
