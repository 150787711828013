export const SalesStore = {
  namespaced: true,
  state: {
    selectedSalesView: '',
    selectedSalesFunnel: {},
    selectedCard: {}
  },
  mutations: {
    SET_SELECTED_VIEW (state: any, view: string) {
      state.selectedSalesView = view
    },
    SET_SELECTED_FUNNEL (state: any, funnel: string) {
      state.selectedSalesFunnel = funnel
    },
    SET_SELECTED_CARD (state: any, card: any) {
      state.selectedCard = card
    }
  }
}
