import {
  IExtraDataController,
  dataToGetExtraData,
  extraDataGetter,
  dataToCreateExtraData,
  extraDataCreatorUpdator,
  dataToUpdateExtraData,
  dataToDeleteExtraData
} from '@/modules/ExtraData/domain/ExtraDataController'

export default class ExtraDataUseCases {
  static getExtraDataFields (controller: IExtraDataController, data: dataToGetExtraData) : Promise<extraDataGetter> {
    return controller.getExtraDataFields(data)
  }

  static createExtraData (controller: IExtraDataController, data: dataToCreateExtraData) : Promise<extraDataCreatorUpdator> {
    return controller.createExtraData(data)
  }

  static updateExtraData (controller: IExtraDataController, data: dataToUpdateExtraData) : Promise<extraDataCreatorUpdator> {
    return controller.updateExtraData(data)
  }

  static deleteExtraData (controller: IExtraDataController, data: dataToDeleteExtraData) : Promise<extraDataCreatorUpdator> {
    return controller.deleteExtraData(data)
  }
}
