import Colors from '@/utils/Colors'
import { TemplateTypes } from '@/components/conversations/enums/TemplateTypes'
import { EventBus } from '@/eventBus'
import { UploadFilesService } from '@/components/shared/services/UploadFilesService'
export default {
  name: 'FilePicker',
  props: ['selectedHeaderType'],
  data () {
    return {
      uploadProgress: 0,
      Colors,
      url: '',
      fileName: ''
    }
  },
  methods: {
    async onFilePicked (event) {
      const file = event.target.files[0]
      if (!file) {
        return
      }

      const extension = file.name.split('.').pop().toLowerCase()

      const allowedExtensions = this.accept.split(',').map(ext => ext.trim())
      if (!allowedExtensions.includes('.' + extension)) {
        EventBus.$emit('toast', 'error', this.$t('UnsupportedFileExtension', { extensions: allowedExtensions.join(', ') }))
        return
      }

      const response = await UploadFilesService.uploadFile({
        totalFiles: [file],
        onUploadProgress: this.updateProgress,
        roomId: 'media_templates'
      })

      if (!response.status) {
        EventBus.$emit('toast', 'error', this.$t('errorUploadingFile'))
        return
      }

      this.uploadProgress = 100

      this.fileName = file.name
      const url = response.data.data.urls[0]
      this.$emit('fileUploaded', url)
    },
    pickFile () {
      if (this.uploadProgress > 0 && this.uploadProgress < 100) {
        return
      }
      this.$refs.FilePicker__input.click()
    },
    updateProgress (progressEvent) {
      this.uploadProgress = Math.round(
        (progressEvent.loaded / progressEvent.total) * 100 - 4
      )
      if (this.uploadProgress < 0) {
        this.uploadProgress = 0
      }

      console.log(this.uploadProgress)
    }
  },
  computed: {
    isImage () {
      return this.selectedHeaderType === TemplateTypes.IMAGE
    },
    isVideo () {
      return this.selectedHeaderType === TemplateTypes.VIDEO
    },
    isDocument () {
      return this.selectedHeaderType === TemplateTypes.DOCUMENT
    },
    accept () {
      if (this.isImage) {
        return '.jpg, .jpeg, .png'
      }
      if (this.isVideo) {
        return '.mp4'
      }
      if (this.isDocument) {
        return '.pdf'
      }
      return '*'
    },
    showUploadProgress () {
      return this.uploadProgress > 0 && this.uploadProgress < 100
    }
  }

}
