import SpacesController from '@/modules/Spaces/infrastructure/Spaces.controller'
import SpacesUseCases from '@/modules/Spaces/application/SpacesUseCases'
// import { GenericResponse } from '@/models/apis/GenericResponse'
import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import { mapState, mapGetters } from 'vuex'
import Colors from '@/shared/domain/colors/Color'
import { Spaces, SpaceUser, SpacesAccessEnum } from '@/modules/Spaces/domain/Spaces'
import { addUserToSpaceDTO, removeUserFromSpaceDTO } from '@/modules/Spaces/domain/SpacesController'
import KBText from '@/shared/infrastructure/ui/inputs/text/KBText.vue'
import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'
import KeybeAvatar from '@/shared/infrastructure/ui/KeybeAvatar.vue'
import { EventBus } from '@/eventBus'
import AdvisorConfigCard from '@/modules/configuration/infrastructure/ui/components/Autoassign/Advisors/AdvisorConfigCard.vue'
import { TeamMember } from '@/modules/Config/TeamConfiguration/domain/models/TeamMember'
import { ActivityActionTypeEnum, ActivityLogModuleTypeEnum, ActivitySubmoduleTypeEnum, dataToWriteActivityLog } from '@/modules/Config/ActivityLogs/domain/ActivityLogsController'
import { CDPFieldsEnums } from '@/modules/CDP/shared/contacts/domain/enums/CDPFieldsEnums'
import ActivityLogsUseCases from '@/modules/Config/ActivityLogs/application/ActivityLogsUseCases'
import ActivityLogsController from '@/modules/Config/ActivityLogs/infrastructure/controllers/ActivityLogs.controller'
import BikyStoreController from '@/modules/bky/infrastructure/persistence/BikyStore.controller'
import TeamUseCases from '@/modules/Config/TeamConfiguration/application/TeamUseCases'
import TeamController from '@/modules/Config/TeamConfiguration/infrastructure/controllers/Team.controller'
import Dialog from '@/components/shared/components/Dialog.vue'
import DeleteUserWarning from '@/modules/Spaces/infrastructure/ui/DeleteUserWarning/DeleteUserWarning.vue'

export default {
  name: 'AutoassignAdvisorsConfig',
  components: {
    KBText,
    KeybeIcon,
    KeybeAvatar,
    AdvisorConfigCard,
    Dialog
  },
  props: {
    limit: {
      type: Number,
      required: true
    },
    inbox: {
      type: Object,
      required: true
    },
    getConfig: {
      type: Function,
      required: true
    }
  },
  data () {
    return {
      KeybeIconType,
      Colors,
      search: '',
      render: 0,
      showAdvisorsList: false,
      menuOpen: false,
      SpacesController: new SpacesController(),
      activityLogsController: new ActivityLogsController(),
      teamController: new TeamController(),
      bikyConfig: {},
      showWarningDeleteUser: false
    }
  },
  computed: {
    ...mapState('AppStore', ['advisers', 'selectedApp']),
    ...mapState('UserStore', ['user', 'token', 'isUserReadonly']),
    ...mapGetters('UserStore', ['isAdminOrAbove']),
    filteredAdvisers (): SpaceUser {
      const advisers: SpaceUser = this.advisers.filter(adviser => {
        const matchingUser: SpaceUser = this.inbox?.users.find(user => user.userId === adviser.userId)
        return matchingUser
      })
      return advisers
    },
    advisersComputed (): string {
      return this.filteredAdvisers.filter(adviser => {
        let name: string
        if (adviser?.lastName) {
          name = `${adviser?.name} ${adviser?.lastName}`
        } else {
          name = adviser?.name || ''
        }
        return name.toLowerCase().includes(this.search.toLowerCase())
      })
    },
    countAdvisers (): number {
      return this.filteredAdvisers?.length
    },
    dialogBackground () {
      return Colors.rojoBird
    },
    DeleteUserWarningDialog () {
      return DeleteUserWarning
    }
  },
  mounted () {
    EventBus.$on('deleteUserAgreement--configuration', this.deleteUser.bind(this))
  },
  methods: {
    openAdvisorMenu (): void {
      if (this.isUserReadonly) return
      this.menuOpen = !this.menuOpen
    },
    getInboxUser (userId): SpaceUser {
      return this.inbox.users.find(user => user.userId === userId) || {}
    },
    close (): void {
      this.showAdvisorsList = false
    },
    toggleAdvisorsList (): void {
      this.showAdvisorsList = !this.showAdvisorsList
    },
    async saveConfig (advisor: SpaceUser): Promise<void> {
      if (this.isUserReadonly) return
      const updatedInbox: Spaces = { ...this.inbox }

      const index: number = updatedInbox.users.findIndex(
        (user) => user.userId === advisor.userId
      )
      if (index === -1) {
        const newAdvisor: SpaceUser = {
          userId: advisor.userId,
          access: SpacesAccessEnum.EDIT,
          maxActiveConversationsNumber: advisor.maxActiveConversationsNumber || 0,
          hasConversationsLimit: advisor.hasConversationsLimit || true
        }
        updatedInbox.users.push(newAdvisor)

        const dataToAddAdviser: addUserToSpaceDTO = {
          inboxId: this.inbox._id,
          userId: this.user?.userId,
          token: this.token,
          appUUID: this.selectedApp?.uuid,
          permission: {
            userId: advisor.userId,
            access: SpacesAccessEnum.EDIT
          },
          isAdmin: this.isAdminOrAbove
        }
        await SpacesUseCases.addUserToInbox(this.SpacesController, dataToAddAdviser)
        await this.writeActivityLogAddAdvisor(advisor)
        // if (response) {
        //   this.getConfig()
        // }
        this.showAdvisorsList = false
      } else {
        EventBus.$emit('toast', 'error', this.$t('errorAddingAdviserToInbox'))
      }
    },
    async writeActivityLog (data) {
      await ActivityLogsUseCases.writeActivityLog(this.activityLogsController, data)
    },
    async writeActivityLogAddAdvisor (member: TeamMember): Promise<void> {
      const data: dataToWriteActivityLog = {
        token: this.token,
        updateInput: {
          appUUID: this.selectedApp?.uuid,
          module: ActivityLogModuleTypeEnum.CHAT,
          submodule: ActivitySubmoduleTypeEnum.SPACES,
          action: ActivityActionTypeEnum.ADD_ADVISER_TO_SPACE,
          object: this.inbox.name,
          user: {
            id: this.user.uuid,
            name: this.user.name + ' ' + this.user.lastName
          },
          payload: {
            field: CDPFieldsEnums.NAME,
            newValue: member.name + ' ' + member.lastName,
            oldValue: ''
          }
        }
      }
      this.writeActivityLog(data)
    },
    async writeActivityLogRemoveAdvisor (member: TeamMember): Promise<void> {
      const data: dataToWriteActivityLog = {
        token: this.token,
        updateInput: {
          appUUID: this.selectedApp?.uuid,
          module: ActivityLogModuleTypeEnum.CHAT,
          submodule: ActivitySubmoduleTypeEnum.SPACES,
          action: ActivityActionTypeEnum.REMOVE_ADVISER_FROM_SPACE,
          object: this.inbox.name,
          user: {
            id: this.user.uuid,
            name: this.user.name + ' ' + this.user.lastName
          },
          payload: {
            field: CDPFieldsEnums.NAME,
            newValue: member.name + ' ' + member.lastName,
            oldValue: ''
          }
        }
      }
      this.writeActivityLog(data)
    },
    checkDeleteUser (advisor): void {
      this.selectedUserToDelete = advisor
      this.showWarningDeleteUser = true
    },
    cancelDeletion (): void {
      this.showWarningDeleteUser = false
      this.selectedUserToDelete = {}
    },
    async deleteUser (): Promise<void> {
      if (!this.selectedUserToDelete) return
      this.showWarningDeleteUser = false
      const updatedInbox: Spaces = { ...this.inbox }
      const dataToRemoveAdviser: removeUserFromSpaceDTO = {
        inboxId: this.inbox?._id,
        appUUID: this.selectedApp?.uuid,
        removeUser: this.selectedUserToDelete?.userId,
        userId: this.user?.userId,
        token: this.token,
        isAdmin: this.isAdminOrAbove
      }

      if (!this.isAdminOrAbove) {
        delete dataToRemoveAdviser.isAdmin
      }
      const index: number = updatedInbox.users.findIndex(
        (user) => user.userId === this.selectedUserToDelete?.userId
      )
      const owner: SpaceUser[] | undefined = this.inbox?.users.filter(e => e.access === 'DELETE')

      if (owner[0]?.userId !== this.selectedUserToDelete?.userId) {
        if (index !== -1) {
          updatedInbox.users.splice(index, 1)
        }
        await SpacesUseCases.removeUserFromInbox(this.SpacesController, dataToRemoveAdviser)

        // if (response) {
        //   this.getConfig()
        // }
        await this.writeActivityLogRemoveAdvisor(this.selectedUserToDelete)
      } else {
        EventBus.$emit('toast', 'error', this.$t('errorRemovingAdviserToInbox'))
      }
    },
    updateLimitUser (advisor: SpaceUser): void {
      // const updatedInbox: Spaces = { ...this.inbox }
      this.getConfig()
    },
    name (member: TeamMember): string {
      if (this.isBiky(member)) {
        this.bikyConfig = BikyStoreController.getConfig()
        return this.bikyConfig?.name || 'Biky Montes'
      }
      return member?.name + ' ' + member.lastName
    },
    isBiky (member: TeamMember): boolean {
      return TeamUseCases.isUserBiky(this.teamController, member)
    },
    image (member: TeamMember): string {
      if (this.isBiky(member)) {
        this.bikyConfig = BikyStoreController.getConfig()
        return this.bikyConfig?.image || `${process.env.VUE_APP_STORAGE_URL}/files/ai/montes_profile_5.png`
      }
      return member?.photo || ''
    },
    getAdviserPhoto (adviser): string {
      return adviser?.photo || adviser?.image || ''
    }
  },
  beforeDestroy () {
    SpacesUseCases.dispose(this.SpacesController)
    EventBus.$off('deleteUserAgreement--configuration', this.deleteUser.bind(this))
  }
}
