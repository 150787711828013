


















import WaitingTime from './WaitingTime'
export default WaitingTime
