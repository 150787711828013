















import TypeDocuments from './TypeDocuments'
export default TypeDocuments
