import {
  IImporterController,
  dataToCreateImporter,
  dataToDetectHeaders,
  dataToGetImports,
  dataToStartImporter,
  headerDetector,
  importer,
  impotersGetter
} from '../../domain/ImporterController'
import ImporterRepository from '../repository/Importer.repository'

export default class ImporterController implements IImporterController {
  repository: ImporterRepository

  constructor () {
    this.repository = new ImporterRepository()
  }

  async createImporter (data: dataToCreateImporter): Promise<importer> {
    const response = await this.repository.createImporter(data)
    return response
  }

  async getImports (data: dataToGetImports): Promise<impotersGetter> {
    const response = await this.repository.getImports(data)
    return response
  }

  async startImport (data: dataToStartImporter): Promise<importer> {
    const response = await this.repository.startImport(data)
    return response?.data?._id || null
  }

  async headerDetect (data: dataToDetectHeaders): Promise<headerDetector> {
    const response = await this.repository.headerDetect(data)
    return response
  }

  abortGetSuggestions () {
    this.repository.abortGetSuggestions()
  }
}
