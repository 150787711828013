//
// Uses cases
//
import CategoryUseCases from '@/modules/catalog/application/CategoryUseCases'

//
// Controllers
//
import CategoryController from '@/modules/catalog/infrastructure/controllers/Category.controller'

//
// Components
//
import Category from '@/modules/microsites/infrastructure/ui/components/Categories/Category/Category.vue'

//
// Vue dependencies
//
import { mapState } from 'vuex'
import { dataToDeleteCategory } from '@/modules/catalog/domain/models/CategoryController'
import KeybeButton from '@/modules/DesignSystem/infrastructure/components/KeybeButton/KeybeButton.vue'
export default {
  props: {
    isCatalog: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState('AppStore', ['selectedApp']),
    ...mapState('UserStore', ['token', 'isUserReadonly'])
  },
  components: {
    Category,
    KeybeButton
  },
  mounted () {
    this.getCategories()
  },
  data () {
    return {
      loading: true,
      categoryController: new CategoryController(),
      categories: []
    }
  },
  methods: {
    async getCategories () {
      try {
        this.loading = true
        this.$emit('start-loading')
        this.categories = await CategoryUseCases.getCategories(this.categoryController, {
          token: this.token,
          appUUID: this.selectedApp?.uuid
        })
        this.loading = false
        this.$emit('stop-loading')
      } catch (e) {
        console.error(e)
        this.loading = false
        this.$emit('stop-loading')
      }
    },
    async deleteCategory (category: {[key: string]: any}): Promise<void> {
      if (this.isUserReadonly) return
      this.loading = true
      this.$emit('start-loading')
      try {
        const data: dataToDeleteCategory = {
          id: category.id || '',
          token: this.token,
          appUUID: this.selectedApp?.uuid
        }
        await CategoryUseCases.deleteCategory(this.categoryController, data)
      } catch (e) {
        console.error(e)
      } finally {
        this.loading = false
        this.$emit('stop-loading')
      }

      await this.getCategories()
    }
  }
}
