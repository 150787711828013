import BillingService from '@/components/configuration/Services/BillingService'
import BillingPackagesCards from '@/modules/consumptions/infrastructure/ui/components/BillingPackagesCards/BillingPackagesCards.vue'
import BillingPackagesPayment from '@/modules/consumptions/infrastructure/ui/components/BillingPackagesPayment/BillingPackagesPayment.vue'
import FeesServices from '@/modules/configuration/application/services/FeesServices'
import KBButton from '@/shared/infrastructure/ui/buttons/KBButton.vue'
import KBAutocomplete from '@/shared/infrastructure/ui/inputs/select/KBAutocomplete.vue'
import KBText from '@/shared/infrastructure/ui/inputs/text/KBText.vue'
import DatePickerRow from '@/modules/configuration/infrastructure/ui/components/DatePickerRow/DatePickerRow.vue'
import Loader from '@/shared/infrastructure/ui/Loader.vue'
import { TYPE_BUY_PACKAGE } from '@/components/configuration/Services/PaymentService'

export default {
  name: 'BillingPackages',
  components: {
    BillingPackagesCards,
    BillingPackagesPayment,
    KBButton,
    KBAutocomplete,
    KBText,
    DatePickerRow,
    Loader
  },
  props: [],
  data () {
    return {
      itemDetail: null,
      detail: false,
      status: true,
      loading: false,
      isFeesAdmin: false,
      custom: false,
      chargeTypes: [],
      error: '',
      model: {
        chargeType: null,
        total: 5000,
        dueDate: '',
        unitPrice: 0.001
      },
      dataCards: [],
      packages: {},
      activePackages: []
    }
  },
  computed: {
    Commissiondate: {
      get () {
        return this.$store.state.CommissionStore.Commissiondate || null
      },
      set (value) {
        this.$store.commit('CampaingsStore/SET_EDITING_OBJECT', {
          key: 'Commissiondate',
          value
        })
      }
    }
  },
  methods: {
    onDetail (item) {
      if (!this.status) return
      this.detail = true
      this.itemDetail = item
    },
    async createCustom () {
      this.custom = !this.custom
    },
    transformModel () {
      if (!this.model?.chargeType || !this.model?.total || !this.model?.unitPrice) return false
      const month = this.Commissiondate.month < 9 ? `0${this.Commissiondate.month}` : this.Commissiondate.month
      const day = this.Commissiondate.day < 9 ? `0${this.Commissiondate.day}` : this.Commissiondate.day
      const date = `${this.Commissiondate.year}-${month}-${day}`
      const chargeType = this.chargeTypes.find(data => data.type === this.model.chargeType)
      const model = {
        ...chargeType,
        chargeType: {
          SKU: chargeType.SKU,
          type: chargeType.type
        },
        total: parseFloat(this.model.total),
        amountUSD: parseFloat(this.model.unitPrice),
        dueDate: date
      }
      return { model, id: chargeType.id }
    },
    async buyPackage () {
      const model = this.transformModel()
      if (!model.model) return
      const output = model.model
      const item = {
        chargeTypes: [output],
        name: `package-${output.total}-${output.SKU}`,
        status: true,
        amount: output.total * output.amountUSD,
        type: TYPE_BUY_PACKAGE.CUSTOM,
        id: model.model.id
      }
      this.onDetail(item)
    },
    async getPackages () {
      const response = await BillingService.getPackages()
      if (response.status) {
        this.dataCards = response.data
      }
    },
    async getActivePackages () {
      const response = await BillingService.getActivePackages()
      if (response.status) {
        const elements = response.data
        this.packages = elements
        this.activePackages = Object.keys(elements)
      }
    },
    async getChargeTypes () {
      const response = await BillingService.getChargeTypes()
      if (response.status) {
        this.chargeTypes = response.data
      }
    },
    updatePackage () {
      this.detail = false
      this.getPackages()
    },
    async getFeesUser () {
      const feesData = await FeesServices.isCommissionAgent()
      if (feesData?.status) {
        const user = feesData.data
        this.isFeesAdmin = user.superadmin
      }
    }
  },
  mounted () {
    this.getPackages()
    this.getActivePackages()
    this.getFeesUser()
    this.getChargeTypes()
  }
}
