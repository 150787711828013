export enum SmartOMeterFeatures {
  CORTEX_SUGGESTIONS = 'CORTEX_SUGGESTIONS',
  KNOWLEDGE_BASE = 'KNOWLEDGE_BASE',
  NEURAL_SUGGESTIONS = 'NEURAL_SUGGESTIONS',
  SENTIMET_ANALYSIS = 'SENTIMET_ANALYSIS',
  ACTION_SUGGESTIONS = 'ACTION_SUGGESTIONS',
  TESSERACT_SUGGESTIONS = 'TESSERACT_SUGGESTIONS',
  UNASWERED_WAITING_TIMER = 'UNASWERED_WAITING_TIMER',
  BIKY_SIDEBAR_ITEM = 'BIKY_SIDEBAR_ITEM',
  BIKY_CONFIG = 'BIKY_CONFIG',
  BIKY_ERRORS_REPORT = 'BIKY_ERRORS_REPORT',
}

export type SmartOMeterFeature = SmartOMeterFeatures.ACTION_SUGGESTIONS | SmartOMeterFeatures.CORTEX_SUGGESTIONS | SmartOMeterFeatures.KNOWLEDGE_BASE | SmartOMeterFeatures.NEURAL_SUGGESTIONS | SmartOMeterFeatures.SENTIMET_ANALYSIS | SmartOMeterFeatures.TESSERACT_SUGGESTIONS | SmartOMeterFeatures.UNASWERED_WAITING_TIMER | SmartOMeterFeatures.BIKY_CONFIG | SmartOMeterFeatures.BIKY_ERRORS_REPORT | SmartOMeterFeatures.BIKY_SIDEBAR_ITEM
