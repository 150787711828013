





















































































import NewTemplateSequence from './NewTemplateSequence'
export default NewTemplateSequence
