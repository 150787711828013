import { EventBus } from '@/eventBus'
import { Task, TaskStatusEnum } from '@/modules/tasks/domain/Task'
import TaskController from '@/modules/tasks/infrastructure/controllers/Task.controller'
import TaskUseCases from '@/modules/tasks/application/TaskUseCases'
import { mapState, mapActions } from 'vuex'
import { taskGetter } from '@/modules/tasks/domain/TaskController'
import TasksSection from '@/modules/tasks/infrastructure/ui/components/TasksSection/TasksSection.vue'

export default {
  name: 'TasksBoard',
  components: {
    TasksSection
  },
  data () {
    return {
      taskController: null,
      taskStored: null,
      createdTaskOffset: 1,
      createdTaskLimit: 10,
      processTaskOffset: 1,
      processTaskLimit: 10,
      doneTaskOffset: 1,
      doneTaskLimit: 10,
      expiredTaskOffset: 1,
      expiredTaskLimit: 10,
      defaultSize: 1,
      createdTasks: [],
      processTasks: [],
      doneTasks: [],
      expiredTasks: [],
      createdEditedTasks: [],
      processEditedTasks: [],
      doneEditedTasks: [],
      expiredEditedTasks: [],
      canGetMoreCreatedTasks: true,
      canGetMoreProcessTasks: true,
      canGetMoreDoneTasks: true,
      canGetMoreExpiredTasks: true,
      loadingCreatedTasks: false,
      loadingProcessTasks: false,
      loadingDoneTasks: false,
      loadingExpiredTasks: false,
      searchTaskData: {},
      rerender: 0
    }
  },
  async mounted () {
    this.taskController = new TaskController()

    this.validateTaskStored()
    // this.$emit('start-loading')

    // this.searchTaskData = {
    //   responsible: this.user?.userId
    // }

    // if (this.usersSearch) {
    //   if (this.usersSearch === 'all') this.searchTaskData.responsible = undefined
    //   else if (this.usersSearch === 'mine') this.searchTaskData.responsible = this.user?.uuid
    //   else this.searchTaskData.responsible = this.usersSearch
    // }

    // if (this.stringSearch) {
    //   this.searchTaskData.search = this.stringSearch
    // } else delete this.searchTaskData.search

    // if (this.dateSearch && (this.dateSearch.dateInit || this.dateSearch.dateEnd)) {
    //   this.searchTaskData.dateInit = this.dateSearch?.dateInit?.substring(0, 10)
    //   this.searchTaskData.dateEnd = this.dateSearch?.dateEnd?.substring(0, 10)
    // }

    // await this.getAllTasks(this.searchTaskData)
    // this.$emit('stop-loading')

    EventBus.$on('TaskOptions:task-updated', (data) => {
      this.processUpdatedTask(data)
    })

    EventBus.$on('TasksBoard:getTasks', async (data) => {
      this.$emit('start-loading')
      this.searchTaskData = {
        ...this.searchTaskData,
        ...data
      }
      await this.getAllTasks(data)
      this.$emit('stop-loading')
    })
  },
  beforeDestroy () {
    this.taskController = null
    EventBus.$off('TaskOptions:task-updated')
    EventBus.$off('TasksBoard:getTasks')
  },
  methods: {
    ...mapActions('TasksStore', ['setTask']),
    async getCreatedTasks (customData = {}) {
      this.loadingCreatedTasks = true
      const data: taskGetter = await TaskUseCases.getTasks(
        this.taskController,
        {
          app: this.selectedApp?.uuid,
          token: this.token,
          limit: this.createdTaskLimit,
          offset: this.createdTaskOffset,
          status: TaskStatusEnum.CREATED,
          ...customData
        }
      )
      this.canGetMoreCreatedTasks = data?.canGetMoreTasks || false
      this.loadingCreatedTasks = false
      return data?.tasks || []
    },
    async getProcessTasks (customData = {}) {
      this.loadingProcessTasks = true
      const data: taskGetter = await TaskUseCases.getTasks(
        this.taskController,
        {
          app: this.selectedApp?.uuid,
          token: this.token,
          limit: this.processTaskLimit,
          offset: this.processTaskOffset,
          status: TaskStatusEnum.PROCESS,
          ...customData
        }
      )
      this.canGetMoreProcessTasks = data?.canGetMoreTasks || false
      this.loadingProcessTasks = false
      return data?.tasks || []
    },
    async getDoneTasks (customData = {}) {
      this.loadingDoneTasks = true
      const data: taskGetter = await TaskUseCases.getTasks(
        this.taskController,
        {
          app: this.selectedApp?.uuid,
          token: this.token,
          limit: this.doneTaskLimit,
          offset: this.doneTaskOffset,
          status: TaskStatusEnum.DONE,
          ...customData
        }
      )
      this.canGetMoreDoneTasks = data?.canGetMoreTasks || false
      this.loadingDoneTasks = false
      return data?.tasks || []
    },
    async getExpiredTasks (customData = {}) {
      this.loadingExpiredTasks = true
      const data: taskGetter = await TaskUseCases.getTasks(
        this.taskController,
        {
          app: this.selectedApp?.uuid,
          token: this.token,
          limit: this.expiredTaskLimit,
          offset: this.expiredTaskOffset,
          status: TaskStatusEnum.EXPIRED,
          ...customData
        }
      )
      this.canGetMoreExpiredTasks = data?.canGetMoreTasks || false
      this.loadingExpiredTasks = false
      return data?.tasks || []
    },
    async getAllTasks (data = {}) {
      this.createdTasks = await this.getCreatedTasks(data)
      this.processTasks = await this.getProcessTasks(data)
      this.doneTasks = await this.getDoneTasks(data)
      this.expiredTasks = await this.getExpiredTasks(data)
      this.rerender++
    },
    async getMoreCreatedTasks () {
      this.createdTaskOffset += this.defaultSize
      const newTasks = await this.getCreatedTasks()
      // validate if one of the updated tasks is in the created tasks, to remove it from the list
      this.createdEditedTasks.forEach((task) => {
        const index = newTasks.findIndex((t) => t._id === task._id)
        if (index !== -1) {
          newTasks.splice(index, 1)
        }
      })
      this.createdTasks = this.createdTasks.concat(newTasks)
    },
    async getMoreProcessTasks () {
      this.processTaskOffset += this.defaultSize
      const newTasks = await this.getProcessTasks()
      // validate if one of the updated tasks is in the process tasks, to remove it from the list
      this.processEditedTasks.forEach((task) => {
        const index = newTasks.findIndex((t) => t._id === task._id)
        if (index !== -1) {
          newTasks.splice(index, 1)
        }
      })
      this.processTasks = this.processTasks.concat(newTasks)
    },
    async getMoreDoneTasks () {
      this.doneTaskOffset += this.defaultSize
      const newTasks = await this.getDoneTasks(this.searchTaskData)
      // validate if one of the updated tasks is in the done tasks, to remove it from the list
      this.doneEditedTasks.forEach((task) => {
        const index = newTasks.findIndex((t) => t._id === task._id)
        if (index !== -1) {
          newTasks.splice(index, 1)
        }
      })
      this.doneTasks = this.doneTasks.concat(newTasks)
    },
    async getMoreExpiredTasks () {
      this.expiredTaskOffset += this.defaultSize
      const newTasks = await this.getExpiredTasks()
      // validate if one of the updated tasks is in the expired tasks, to remove it from the list
      this.expiredEditedTasks.forEach((task) => {
        const index = newTasks.findIndex((t) => t._id === task._id)
        if (index !== -1) {
          newTasks.splice(index, 1)
        }
      })
      this.expiredTasks = this.expiredTasks.concat(newTasks)
    },
    validateTaskStored (): void {
      if (this.task) {
        // eslint-disable-next-line no-undef
        this.taskStored = structuredClone(this.task)
        this.setTask(null)
      }
    },
    removeTaskFromSection (task: Task) {
      switch (task.status) {
        case TaskStatusEnum.CREATED:
          this.createdTasks = this.createdTasks.filter(
            (t) => t._id !== task._id
          )
          break
        case TaskStatusEnum.PROCESS:
          this.processTasks = this.processTasks.filter(
            (t) => t._id !== task._id
          )
          break
        case TaskStatusEnum.DONE:
          this.doneTasks = this.doneTasks.filter((t) => t._id !== task._id)
          break
        case TaskStatusEnum.EXPIRED:
          this.expiredTasks = this.expiredTasks.filter(
            (t) => t._id !== task._id
          )
          break
      }
    },
    processUpdatedTask (data: { task: Task; oldTask: Task }) {
      const { task, oldTask } = data
      const statusChanged = task.status !== oldTask.status

      switch (task.status) {
        case TaskStatusEnum.CREATED:
          if (statusChanged) {
            this.removeTaskFromSection(oldTask)
            this.createdTasks.push(task)
            this.createdEditedTasks.push(task)
          } else {
            const index = this.createdTasks.findIndex((t) => t._id === task._id)
            this.createdTasks.splice(index, 1, task)
          }
          break
        case TaskStatusEnum.PROCESS:
          if (statusChanged) {
            this.removeTaskFromSection(oldTask)
            this.processTasks.push(task)
            this.processEditedTasks.push(task)
          } else {
            const index = this.processTasks.findIndex((t) => t._id === task._id)
            this.processTasks.splice(index, 1, task)
          }
          break
        case TaskStatusEnum.DONE:
          if (statusChanged) {
            this.removeTaskFromSection(oldTask)
            this.doneTasks.push(task)
            this.doneEditedTasks.push(task)
          } else {
            const index = this.doneTasks.findIndex((t) => t._id === task._id)
            this.doneTasks.splice(index, 1, task)
          }
          break
        case TaskStatusEnum.EXPIRED:
          if (statusChanged) {
            this.removeTaskFromSection(oldTask)
            this.expiredTasks.push(task)
            this.expiredEditedTasks.push(task)
          } else {
            const index = this.expiredTasks.findIndex((t) => t._id === task._id)
            this.expiredTasks.splice(index, 1, task)
          }
          break
      }
    }
  },
  computed: {
    ...mapState('AppStore', ['selectedApp']),
    ...mapState('UserStore', ['user', 'token']),
    ...mapState('TasksStore', [
      'dateSearch',
      'usersSearch',
      'stringSearch',
      'task'
    ]),
    allTasks () {
      return this.createdTasks.concat(
        this.processTasks,
        this.doneTasks,
        this.expiredTasks
      )
    }
  }
}
