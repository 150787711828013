
































import KBText from './KBText'
export default KBText
