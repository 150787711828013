










































import { EventBus } from '@/eventBus'
import { mapState } from 'vuex'
import EmitToast from '@/utils/EmitToast'
import { webAgentScript } from '@/components/conversations/components/channels/templateScripts'
import APIKeysUseCases from '@/modules/APIKeysConfig/application/APIKeysUseCases'
import APIKeysController from '@/modules/APIKeysConfig/infrastructure/controllers/APIKeys.controller'
import { ConfigurationTabsEnum } from '@/modules/configuration/application/enums/ConfigurationTabsEnum'
import KBSelect from '@/shared/infrastructure/ui/inputs/select/KBSelect.vue'
import { codemirror } from 'vue-codemirror'
import 'codemirror/lib/codemirror.css'
import 'codemirror/theme/material.css'
import 'codemirror/mode/javascript/javascript'

export default {
  name: 'WebWidgetScript',
  async mounted () {
    await this.getAPIKeys()
  },
  components: {
    codemirror,
    KBSelect
  },
  data () {
    return {
      apiKeysController: new APIKeysController(),
      apiKeys: [],
      cmOptions: {
        mode: 'javascript',
        theme: 'material',
        lineNumbers: true,
        readOnly: true
      },
      selectedApiKey: null
    }
  },
  methods: {
    async copyScript (appKey) {
      const scriptText = this.generateCode(appKey)
      await this.copyToClipboard(scriptText)
      EventBus.$emit('toast', 'success', this.$t('scriptCopied'))
    },
    async goToDemo (appKey) {
      const demoUrl = `${process.env.VUE_APP_CHAT_DEMO_URL}?apiKey=${appKey}`
      window.open(demoUrl, '_blank')
    },
    async copyToClipboard (text) {
      try {
        await navigator.clipboard.writeText(text)
      } catch (error) {
        EventBus.$emit('toast', 'error', this.$t('scriptCopyError'))
      }
    },
    async getAPIKeys () {
      try {
        this.apiKeys = []
        this.loading = true
        const variables = {
          app: this.selectedApp?.uuid,
          token: this.token
        }
        const response = await APIKeysUseCases.getAPIKeys(this.apiKeysController, variables)
        if (response?.status) {
          Object.entries(response.data).forEach(data => {
            const [key, value] = data
            if (key !== 'status') {
              this.apiKeys.push({ text: value.key, value: value })
            }
          })
          if (this.apiKeys.length > 0) {
            this.selectedApiKey = this.apiKeys[0].value
          }
        } else {
          EmitToast.emitErrors(response)
        }
        this.loading = false
      } catch (e) {
        console.error(e)
        this.loading = false
      }
    },
    generateCode (appKey) {
      return webAgentScript(appKey)
    },
    goToAPIKeys () {
      EventBus.$emit('setConfigurationTab', ConfigurationTabsEnum.API_KEYS)
    }
  },
  computed: {
    ...mapState('AppStore', ['selectedApp']),
    ...mapState('UserStore', ['token', 'isUserReadonly'])
  }
}
