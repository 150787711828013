import { dataToCreateTask, dataToDeleteTask, dataToGetTasks, dataToUpdateTask, ITaskController, taskGetter } from '../../domain/TaskController'
import TaskRepository from '../repository/Task.repository'

export default class TaskController implements ITaskController {
  repository: TaskRepository

  constructor () {
    this.repository = new TaskRepository()
  }

  async getTasks (data: dataToGetTasks) : Promise<taskGetter> {
    const response = await this.repository.getTasks(data)
    const canGetMoreTasks = response?.data?.paginator?.hasNextPage
    const tasks = response?.data?.data || []
    return {
      tasks,
      canGetMoreTasks
    }
  }

  async createTask (data: dataToCreateTask) : Promise<{
    success: boolean;
    message: string;
  }> {
    const response = await this.repository.createTask(data)
    return { success: response.status, message: response.message }
  }

  async updateTask (data: dataToUpdateTask) : Promise<{
    success: boolean;
    message: string;
  }> {
    const response = await this.repository.updateTask(data)
    return { success: response.status, message: response.message }
  }

  async deleteTask (data: dataToDeleteTask) : Promise<{
    success: boolean;
    message: string;
  }> {
    const response = await this.repository.deleteTask(data)
    // @ts-ignore
    if (response?._id) return { success: true, message: '' }
    else return { success: response.status, message: response.message }
  }
}
