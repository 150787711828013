
import { EventBus } from '@/eventBus'
import Colors from '@/utils/Colors'
import { mapState } from 'vuex'
import { webAgentScript } from '@/components/conversations/components/channels/templateScripts'
import APIKeysController from '@/modules/APIKeysConfig/infrastructure/controllers/APIKeys.controller'
import { ConfigurationTabsEnum } from '@/modules/configuration/application/enums/ConfigurationTabsEnum'
import KeybeButton from '@/modules/DesignSystem/infrastructure/components/KeybeButton/KeybeButton.vue'
import ConversationsConfigService from '@/components/configuration/Services/ConversationsConfigService'

export default {
  name: 'WebWidgetScript',
  components: {
    KeybeButton
  },
  props: {
    selectedApiKey: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      Colors,
      apiKeysController: new APIKeysController(),
      apiKeys: []
    }
  },
  methods: {
    async copyScript (appKey) {
      const scriptText = this.generateCode(appKey)
      await this.copyToClipboard(scriptText)
      EventBus.$emit('toast', 'success', this.$t('scriptCopied'))
    },
    async saveConfig () {
      this.config.disableMultimedia = !this.allowMultimediaMessages
      const dataToUpdate = {
        widgetWebSetup: this.config
      }
      await ConversationsConfigService.updateConversationsConfigV5(dataToUpdate)
    },
    async goToDemo (appKey) {
      const demoUrl = `${process.env.VUE_APP_CHAT_DEMO_URL}?apiKey=${appKey}`
      window.open(demoUrl, '_blank')
    },
    async copyToClipboard (text) {
      try {
        await navigator.clipboard.writeText(text)
      } catch (error) {
        EventBus.$emit('toast', 'error', this.$t('scriptCopyError'))
      }
    },
    generateCode (appKey) {
      return webAgentScript(appKey)
    },
    goToAPIKeys () {
      EventBus.$emit('setConfigurationTab', ConfigurationTabsEnum.API_KEYS)
    }
  },
  computed: {
    ...mapState('AppStore', ['selectedApp']),
    ...mapState('UserStore', ['token'])
  }
}
