













































































import moment from 'moment'
import { EventBus } from '@/eventBus'
const FORMAT_DATE = 'YYYY-MM-DD'

export default {
  props: ['value', 'itemText', 'itemValue', 'returnObject', 'small', 'hasEmptyOption', 'showSelectorIcon'],
  computed: {
    items () {
      return [
        this.$t('thisWeek'),
        this.$t('lastWeek'),
        this.$t('last2Week'),
        this.$t('last3Week'),
        this.$t('thisMonth'),
        this.$t('lastMonth'),
        this.$t('last2Month'),
        this.$t('last3Month'),
        this.$t('last6Month'),
        this.$t('last12Month'),
        this.$t('lastCut'),
        this.$t('custom')
      ]
    },
    itemToShow () {
      return this.selectedItem
    }
  },
  created () {
    if (this.hasEmptyOption) {
      this.items.unshift(this.$t('noDateFilter'))
    }
  },
  watch: {
    datePicker: {
      handler: function (newValue) {
        this.callCustomDatePicker()
      },
      deep: true
    },
    value: {
      handler (val) {
        this.selectedItem = val
      },
      immediate: true
    }
  },
  data () {
    return {
      selectedItem: null,
      showSelector: false,
      showDatePicker: false,
      datePicker: [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')]
    }
  },
  methods: {
    colorIcon (item) {
      if (item === this.$t('thisWeek')) return '#29cbd0'
      if (item === this.$t('lastWeek')) return '#fcce0f'
      if (item === this.$t('last2Week')) return '#007d87'
      if (item === this.$t('last3Week')) return '#fb407b'
      if (item === this.$t('thisMonth')) return '#88116d'
      if (item === this.$t('lastMonth')) return '#00cfe4'
      if (item === this.$t('last2Month')) return '#39f69b'
      if (item === this.$t('last3Month')) return '#ff99cc'
      if (item === this.$t('last6Month')) return '#e4d893'
      if (item === this.$t('last12Month')) return '#007d87'
      if (item === this.$t('lastCut')) return '#fcce0f'
      return '#000000'
    },
    callCustomDatePicker () {
      if (this.datePicker != null && this.datePicker.length === 2) {
        // if (this.datePicker[0] > this.datePicker[1]) {
        //   EventBus.$emit('toast', 'error', this.$t('invalidDate'))
        //   return
        // }
        const dateFrom = this.datePicker[0]
        const dateTo = this.datePicker[1]
        EventBus.$emit('metricsDatePicker', this.datePicker)
        this.$emit('input', this.selectedItem)
        this.$emit('change-date', { dateString: this.selectedItem, dateFrom, dateTo })
      }
    },
    close () {
      this.showSelector = false
      if (this.showDatePicker) {
        this.showDatePicker = false
      }
    },
    selectItem (item) {
      // validate if is custom date to show date picker
      if (item === this.$t('custom')) {
        this.showDatePicker = true
        return
      }

      this.showDatePicker = false

      if (this.returnObject) this.selectedItem = item
      else if (this.itemValue) this.selectedItem = item[this.itemValue]
      else this.selectedItem = item
      this.showSelector = false

      this.$emit('input', this.selectedItem)
      switch (this.selectedItem) {
        case this.$t('thisWeek'): {
          const dateFrom = moment().startOf('week').format(FORMAT_DATE)
          const dateTo = moment().format(FORMAT_DATE)
          this.$emit('change-date', { dateString: this.selectedItem, dateFrom, dateTo })
          break
        }
        case this.$t('lastWeek'): {
          const dateFrom = moment().startOf('week').subtract(1, 'week').format(FORMAT_DATE)
          const dateTo = moment().startOf('week').subtract(1, 'week').endOf('week').format(FORMAT_DATE)
          this.$emit('change-date', { dateString: this.selectedItem, dateFrom, dateTo })
          break
        }
        case this.$t('last2Week'): {
          const dateFrom = moment().startOf('week').subtract(1, 'week').format(FORMAT_DATE)
          const dateTo = moment().format(FORMAT_DATE)
          this.$emit('change-date', { dateString: this.selectedItem, dateFrom, dateTo })
          break
        }
        case this.$t('last3Week'): {
          const dateFrom = moment().startOf('week').subtract(2, 'week').format(FORMAT_DATE)
          const dateTo = moment().format(FORMAT_DATE)
          this.$emit('change-date', { dateString: this.selectedItem, dateFrom, dateTo })
          break
        }
        case this.$t('thisMonth'): {
          const dateFrom = moment().startOf('month').format(FORMAT_DATE)
          const dateTo = moment().format(FORMAT_DATE)
          this.$emit('change-date', { dateString: this.selectedItem, dateFrom, dateTo })
          break
        }
        case this.$t('lastMonth'): {
          const dateFrom = moment().startOf('month').subtract(1, 'month').format(FORMAT_DATE)
          const dateTo = moment().startOf('month').subtract(1, 'month').endOf('month').format(FORMAT_DATE)
          this.$emit('change-date', { dateString: this.selectedItem, dateFrom, dateTo })
          break
        }
        case this.$t('last2Month'): {
          const dateFrom = moment().startOf('month').subtract(1, 'month').format(FORMAT_DATE)
          const dateTo = moment().format(FORMAT_DATE)
          this.$emit('change-date', { dateString: this.selectedItem, dateFrom, dateTo })
          break
        }
        case this.$t('last3Month'): {
          const dateFrom = moment().startOf('month').subtract(2, 'month').format(FORMAT_DATE)
          const dateTo = moment().format(FORMAT_DATE)
          this.$emit('change-date', { dateString: this.selectedItem, dateFrom, dateTo })
          break
        }
        case this.$t('last6Month'): {
          const dateFrom = moment().startOf('month').subtract(5, 'month').format(FORMAT_DATE)
          const dateTo = moment().format(FORMAT_DATE)
          this.$emit('change-date', { dateString: this.selectedItem, dateFrom, dateTo })
          break
        }
        case this.$t('last12Month'): {
          const dateFrom = moment().startOf('month').subtract(11, 'month').format(FORMAT_DATE)
          const dateTo = moment().format(FORMAT_DATE)
          this.$emit('change-date', { dateString: this.selectedItem, dateFrom, dateTo })
          break
        }
        case this.$t('lastCut'): {
          const dateFrom = moment().startOf('month').subtract(1, 'month').set('date', 24).format(FORMAT_DATE)
          const dateTo = moment().startOf('month').set('date', 25).format(FORMAT_DATE)
          this.$emit('change-date', { dateString: this.selectedItem, dateFrom, dateTo })
          break
        }
        case this.$t('custom'): {
          this.$emit('change-date', { dateString: this.selectedItem })
          break
        }
        case this.$t('noDateFilter'):
          this.$emit('change-date', { dateString: this.$t('noDateFilter') })
          break
        default:
          this.$emit('change-date', { dateString: this.$t('thisMonth') })
          break
      }
    },
    isSelected (item) {
      if (this.itemValue) {
        const found = this.items.filter(i => i[this.itemValue] === this.selectedItem)[0]
        if (found) return true
      } else {
        return this.selectedItem === item
      }
    },
    findCustomData () {
      let [startDate, endDate] = this.datePicker
      if (!endDate) endDate = startDate
      if (moment(startDate).isBefore(endDate)) {
        this.$emit('change-date', { dateString: this.$t('custom'), dateFrom: startDate, dateTo: endDate })
        this.showDatePicker = false
        this.showSelector = false
        this.selectedItem = this.$t('custom')
        return
      }
      this.$emit('change-date', { dateString: this.$t('custom'), dateFrom: endDate, dateTo: startDate })
      this.showDatePicker = false
      this.showSelector = false
      this.selectedItem = this.$t('custom')
    }
  }
}
