import KeybeTextarea from '@/modules/DesignSystem/infrastructure/components/KeybeTextarea/KeybeTextarea.vue'
import KeybeButton from '@/modules/DesignSystem/infrastructure/components/KeybeButton/KeybeButton.vue'
import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'
import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import Colors from '@/utils/Colors'
export default {
  components: {
    KeybeTextarea,
    KeybeButton,
    KeybeIcon
  },
  props: {
    value: {
      type: Array,
      required: true
    }
  },
  mounted () {
    this.pdfs = this.value
    console.log(this.value)
  },
  data () {
    return {
      pdfs: [],
      selectedIndex: -1,
      KeybeIconType,
      Colors
    }
  },
  methods: {
    addPDF () {
      this.pdfs.push({
        description: '',
        file: null,
        url: ''
      })
    },
    removePDF (index: number): void {
      this.pdfs.splice(index, 1)
      this.$emit('input', this.pdfs)
    },
    setSelectedIndex (index: number): void {
      if (this.pdfs[index].url) return
      this.selectedIndex = index
      this.openFileInput()
    },
    openFileInput () {
      if (this.$refs.fileInput) this.$refs.fileInput.click()
    },
    onFilePicked (e: Event): void {
      // @ts-ignore
      this.pdfs[this.selectedIndex].file = e?.target?.files[0] || null
      this.$emit('input', this.pdfs)
    },
    openUrl (url: string): void {
      window.open(url, '_blank')
    }
  },
  watch: {
    value: {
      handler (pdfs) {
        this.pdfs = pdfs
      },
      immediate: true
    }
  }
}
