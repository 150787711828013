<template>
  <svg :width="width"
       :height="height"
       viewBox="0 0 17 14"
       fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <path d="M14.6395 6.80026V6.89376L16.5498 4.91905C17.1501 4.29859 17.1501 3.29566 16.5498 2.6752L14.4092 0.465344C13.809 -0.155115 12.8387 -0.155115 12.2385 0.465344L11.1394 1.60144C11.0654 1.59294 10.9887 1.58727 10.9119 1.58727H7.8422C6.82535 1.58727 5.9894 2.38055 5.87977 3.40049H5.8688V6.80026C5.8688 7.42639 6.35941 7.93352 6.96514 7.93352C7.57086 7.93352 8.06147 7.42639 8.06147 6.80026V4.7604V4.75756V4.30709H8.5H12.2275C12.2275 4.30709 12.2275 4.30709 12.2303 4.30709H12.4468C13.6582 4.30709 14.6395 5.32136 14.6395 6.57361V6.80026ZM8.93853 5.2137V6.80026C8.93853 7.92786 8.05599 8.84013 6.96514 8.84013C5.87428 8.84013 4.99174 7.92786 4.99174 6.80026V3.44015C4.00778 3.6158 3.18827 4.35526 2.90871 5.37236L2.45647 7.00425L0.450181 9.07811C-0.15006 9.69857 -0.15006 10.7015 0.450181 11.322L2.59077 13.5347C3.19101 14.1551 4.16127 14.1551 4.76151 13.5347L5.7948 12.4666C5.81947 12.4666 5.84414 12.4694 5.8688 12.4694H10.2541C10.9805 12.4694 11.5697 11.8603 11.5697 11.1095C11.5697 10.9508 11.5423 10.7978 11.4957 10.6562H11.5697C12.2961 10.6562 12.8853 10.0471 12.8853 9.29627C12.8853 8.93362 12.7483 8.60498 12.5235 8.36133C13.2279 8.21967 13.7597 7.57938 13.7624 6.80876V6.79743C13.7597 5.92482 13.0744 5.21653 12.2275 5.21653H8.93853V5.2137Z"
          :fill="color"/>
  </svg>

</template>
<script>
export default {
  name: 'PrimaryIcon',
  props: {
    width: {
      type: Number,
      default: 17
    },
    height: {
      type: Number,
      default: 14
    },
    color: {
      type: String,
      default: '#B6BEC7'
    }
  }
}
</script>
