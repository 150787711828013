
import ModalSVG from '@/shared/assets/ModalSVG.vue'
import { TemplateTypes } from '@/components/conversations/enums/TemplateTypes'
import { CampaignType } from '@/modules/outbound/infrastructure/enums/CampaignTypes'
import SegmentsApolloProvider from '@/modules/people/Providers/SegmentsApolloProvider'
import { mapActions, mapState } from 'vuex'
import { EventBus } from '@/eventBus'
import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import CampaignsService from '@/services/CampaignsService'
import { hasParams } from '@/components/conversations/utils/TemplateUtils'
import TemplatesService from '@/modules/conversations/domain/services/Templates.service'
import TemplatesRepository from '@/modules/conversations/infrastructure/http/Templates.repository'
import { WpProviders } from '@/components/conversations/models/WpProviders'
import ConversationsConfigController from '@/components/configuration/controllers/ConversationsConfigController'
import { EntityTypeEnum } from '@/modules/CDP/shared/contacts/domain/Contacts'
import SegmentsUseCases from '@/modules/CDP/shared/segments/application/SegmentsUseCases'
import SegmentsController from '@/modules/CDP/shared/segments/infrastructure/controllers/Segments.controller'
import operations from '@/utils/operations'

import KBButton from '@/shared/infrastructure/ui/buttons/KBButton.vue'
import KBAutocomplete from '@/shared/infrastructure/ui/inputs/select/KBAutocomplete.vue'
import KBSelect from '@/shared/infrastructure/ui/inputs/select/KBSelect.vue'
import KBText from '@/shared/infrastructure/ui/inputs/text/KBText.vue'
import Loader from '@/shared/infrastructure/ui/Loader.vue'
import TemplatePreview from '@/modules/outbound/infrastructure/ui/components/TemplatePreview/TemplatePreview.vue'
import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'
import TemplateSelector from '@/modules/outbound/infrastructure/ui/components/forms/TemplateSelector/TemplateSelector.vue'
import NewWhatsappForm from '@/modules/outbound/infrastructure/ui/components/forms/NewWhatsappForm/NewWhatsappForm.vue'
import CampaingsMessages from '../forms/CampaingsMessages/CampaingsMessages.vue'
import { CDPFieldsEnums } from '@/modules/CDP/shared/contacts/domain/enums/CDPFieldsEnums'
import { SalesFieldsEnum } from '@/modules/Sales/shared/domain/SalesFieldsEnum'
export default {
  name: 'NewWhatsappPage',
  props: {
    disabledAction: {
      type: Boolean,
      default: false
    },
    setSegment: {
      type: String
    }
  },
  components: {
    CampaingsMessages,
    // DateTimePickerRow,
    NewWhatsappForm,
    ModalSVG,
    KeybeIcon,
    TemplatePreview,
    KBText,
    KBSelect,
    KBAutocomplete,
    TemplateSelector,
    KBButton,
    Loader
  },
  mounted () {
    this.changeParamsVisible = false
    this.openEmojiPicker = false
    this.changeTemplate = false
    this.buttonsParams = []
    this.buttonsLabels = []
    this.buttonsParamsLinks = []
    this.getSegments(true)
    this.getChannels()
  },
  watch: {
    // segment () {
    //   if (this.segment) {
    //     this.search = this.segment
    //   } else {
    //     this.search = ''
    //   }
    //   this.getSegments()
    // },
    changeTemplate () {
      if (this.changeTemplate) {
        this.changeParamsVisible = false
        this.openEmojiPicker = false
      }
    },
    openEmojiPicker () {
      if (this.openEmojiPicker) {
        this.changeParamsVisible = false
        this.changeTemplate = false
      }
    },
    changeParamsVisible () {
      if (this.changeParamsVisible) {
        this.openEmojiPicker = false
        this.changeTemplate = false
      }
    },
    programmingDate: {
      handler (val) {
        if (this.programmingDate) {
          this.schedule = true
          const { hours, day, month, year } = this.programmingDate
          this.scheduleDate = `${year}-${month}-${day}T${hours}:00.000Z`
        }
      },
      deep: true
    },
    selectedProvider () {
      if (this.selectedProvider) {
        this.loadingTemplates = true
        this.getTemplates({ userHost: this.selectedProvider })
        this.loadingTemplates = false
      }
    },
    currentTemplate () {
      if (this.currentTemplate._id) {
        this.isDefaultTemplate = false
        this.selectedTemplate = this.currentTemplate
        window.scrollTo(0, 0)
        this.changeTemplate = !this.changeTemplate
        this.changeParamsVisible = false
      }
    }
  },
  data () {
    return {
      items: [],
      segments: [],
      offset: 1,
      limit: 10,
      search: '',
      segmentsController: new SegmentsController(),
      isDefaultTemplate: false,
      selectedTemplate: {},
      selectedProvider: {},
      changeTemplate: false,
      creatingCampaign: false,
      schedule: false,
      scheduleDate: '',
      time: '',
      provider: {},
      showTemplateLabel: true,
      campaingsName: null,
      segment: null,
      segmentValue: null,
      peopleListSearch: '',
      KeybeIconType,
      providers: [],
      loading: false,
      messageText: null,
      parsedTemplateMessage: '',
      loadingTemplates: false,
      templatesRepository: null,
      rerender: 0,
      loadingLine: false,
      CampaignType,
      segmentSearch: '',
      SegmentsApolloProvider: SegmentsApolloProvider.getInstance().provider.defaultClient,
      CDPFieldsEnums,
      SalesFieldsEnum
    }
  },
  created () {
    this.outboundName = null
    this.summaryMenu = false
    this.templatesRepository = new TemplatesRepository(this.$store)
    this.changeParamsVisible = false
  },
  computed: {
    ...mapState('UserStore', ['token']),
    ...mapState('ConversationStore', ['wpProviders', 'channels']),
    ...mapState('CampaingsStore', ['templates']),
    ...mapState('AppStore', ['selectedApp']),
    hasSomeGupsupConfig () {
      const whatsapp = this.channels?.whatsapp?.accounts || []
      return whatsapp.some((account) => account?.provider === WpProviders.Gupshup)
    },
    selectedAppId () {
      return this.selectedApp?.uuid
    },
    openEmojiPicker: {
      get () {
        return this.$store.state.CampaingsStore.openEmojiPicker || false
      },
      set (value) {
        this.$store.commit('CampaingsStore/SET_EDITING_OBJECT', {
          key: 'openEmojiPicker',
          value
        })
      }
    },
    outboundName: {
      get () {
        return this.$store.state.CampaingsStore.outboundName || null
      },
      set (value) {
        this.$store.commit('CampaingsStore/SET_EDITING_OBJECT', {
          key: 'outboundName',
          value
        })
      }
    },
    currentSelectedTab: {
      get () {
        return this.$store.state.CampaingsStore.currentSelectedTab || null
      },
      set (value) {
        this.$store.commit('CampaingsStore/SET_EDITING_OBJECT', {
          key: 'currentSelectedTab',
          value
        })
      }
    },
    summaryData: {
      get () {
        return this.$store.state.CampaingsStore.summaryData || false
      },
      set (value) {
        this.$store.commit('CampaingsStore/SET_EDITING_OBJECT', {
          key: 'summaryData',
          value
        })
      }
    },
    summaryMenu: {
      get () {
        return this.$store.state.CampaingsStore.summaryMenu || false
      },
      set (value) {
        this.$store.commit('CampaingsStore/SET_EDITING_OBJECT', {
          key: 'summaryMenu',
          value
        })
      }
    },
    buttonsParamsLinks: {
      get () {
        return this.$store?.state?.CampaingsStore?.buttonsParamsLinks || []
      },
      set (value) {
        this.$store.commit('CampaingsStore/SET_EDITING_OBJECT', {
          key: 'buttonsParamsLinks',
          value
        })
      }
    },
    changeParamsVisible: {
      get () {
        return this.$store.state.CampaingsStore.changeParamsVisible || false
      },
      set (value) {
        this.$store.commit('CampaingsStore/SET_EDITING_OBJECT', {
          key: 'changeParamsVisible',
          value
        })
      }
    },
    buttonsParams: {
      get () {
        return this.$store?.state?.CampaingsStore?.buttonsParams || []
      },
      set (value) {
        this.$store.commit('CampaingsStore/SET_EDITING_OBJECT', {
          key: 'buttonsParams',
          value
        })
      }
    },
    buttonsLabels: {
      get () {
        return this.$store?.state?.CampaingsStore?.buttonsLabels || []
      },
      set (value) {
        this.$store.commit('CampaingsStore/SET_EDITING_OBJECT', {
          key: 'buttonsLabels',
          value
        })
      }
    },
    showMediaSelector () {
      if (!this.isTextTemplate) {
        return true
      }
      return this.isImageHeader
    },
    isImageHeader () {
      return this.header?.format === TemplateTypes.IMAGE || this.header?.format === TemplateTypes.VIDEO || this.header?.format === TemplateTypes.DOCUMENT
    },
    isBSP () {
      return this.selectedTemplate?.provider === WpProviders.WpKeybe
    },
    header () {
      if (!this.isBSP) {
        return false
      }

      if (!this.selectedTemplate?.components) {
        return false
      }

      const components = JSON.parse(this.selectedTemplate?.components)

      return components.find((component) => component.type === 'HEADER')
    },
    isTextTemplate () {
      return this.selectedTemplate?.type === TemplateTypes.TEXT
    },
    insertCustomField: {
      get () {
        return this.$store?.state?.CampaingsStore?.insertCustomField
      },
      set (value) {
        this.$store.commit('CampaingsStore/SET_EDITING_OBJECT', {
          key: 'insertCustomField',
          value
        })
      }
    },
    templateMediaUrl: {
      get () {
        return this.$store?.state?.CampaingsStore?.templateMediaUrl
      },
      set (value) {
        this.$store.commit('CampaingsStore/SET_EDITING_OBJECT', {
          key: 'templateMediaUrl',
          value
        })
      }
    },
    programmingDate: {
      get () {
        return this.$store?.state?.CampaingsStore?.programmingDate
      }
    },
    currentTemplate: {
      get () {
        return this.$store?.state?.CampaingsStore?.currentTemplate || null
      },
      set (value) {
        this.$store.commit('CampaingsStore/SET_EDITING_OBJECT', {
          key: 'currentTemplate',
          value
        })
      }
    },
    templateParams: {
      get () {
        return this.$store?.state?.CampaingsStore?.templateParams || null
      },
      set (value) {
        this.$store.commit('CampaingsStore/SET_EDITING_OBJECT', {
          key: 'templateParams',
          value
        })
      }
    },
    lengthContent: {
      get () {
        return this.$store?.state?.CampaingsStore?.lengthContent
      },
      set (value) {
        this.$store.commit('CampaingsStore/SET_EDITING_OBJECT', {
          key: 'lengthContent',
          value
        })
      }
    },
    messagePreview: {
      get () {
        return this.$store?.state?.CampaingsStore?.messagePreview || ''
      },
      set (value) {
        this.$store.commit('CampaingsStore/SET_EDITING_OBJECT', {
          key: 'messagePreview',
          value
        })
      }
    },
    message: {
      get () {
        return this.$store?.state?.CampaingsStore?.message || ''
      },
      set (value) {
        this.$store.commit('CampaingsStore/SET_EDITING_OBJECT', {
          key: 'message',
          value
        })
      }
    },
    wsNumbers () {
      const numbers = []
      this.providers.map(agent => {
        numbers.push(agent?.userHost)
      })
      return numbers
    }
  },
  methods: {
    ...mapActions('CampaingsStore', ['setTemplates']),
    async getChannels () {
      this.loadingLine = true
      if (!this.channels || Object.keys(this.channels).length === 0 || this.wpProviders.length === 0) {
        await ConversationsConfigController.getChannels()
      }

      setTimeout(async () => {
        this.providers = this.wpProviders
        if (this.providers.length > 0) {
          this.provider = this.providers[0]
          this.selectedProvider = this.providers[0]?.userHost
          this.templateMediaUrl = null
          await this.getTemplates(this.providers[0])
        }
        this.loadingLine = false
      }, 600)
    },
    setScheduleDate (time) {
      this.schedule = true
      this.scheduleDate = time
    },
    async getTemplates (provider) {
      this.loadingTemplates = true
      if (!this.templates) this.templates = []
      if (this.templates && this.templates.length > 0 && this.wpProviders.length === 1) {
        this.loadingTemplates = false
        const defaultTemplate = TemplatesService.findDefaultTemplate(this.templates)
        if (!defaultTemplate) {
          this.selectedTemplate = this.templates[0]
          this.isDefaultTemplate = false
        } else {
          this.selectedTemplate = defaultTemplate
          this.isDefaultTemplate = true
        }
      }
      const response = await this.templatesRepository.getTemplates(provider?.userHost)
      if (!response.status) {
        EventBus.$emit('toast', 'error', this.$t('errorGettingTemplates'))
        this.$emit('input', false)
        return
      }
      const templates = response?.data?.items
      // console.log('Filter', templates.filter(res => res.type !== 'TEXT'))
      if (templates && templates.length > 0) {
        this.setTemplates(templates)
        this.loadingTemplates = false
        const defaultTemplate = TemplatesService.findDefaultTemplate(this.templates)
        if (!defaultTemplate) {
          this.selectedTemplate = templates[0]
          this.isDefaultTemplate = false
        } else {
          this.selectedTemplate = defaultTemplate
          this.isDefaultTemplate = true
        }
      }
    },
    onSelectEmoji (emoji) {
      const selRange = this.$refs.messageEditor.selectionEnd
      const firstPart = this.message.substring(0, selRange)
      const lastPart = this.message.substring(selRange)
      this.message = firstPart + emoji.data + lastPart
      this.messageText = this.message
      this.$refs.messageEditor.focus()
      setTimeout(() => {
        this.$refs.messageEditor.selectionEnd = selRange + emoji.data.length
      }, 50)
    },
    searchPersons (search) {
      const obj = this.items.find(o => o.name.toLowerCase().includes(search.toLowerCase()))
      if (obj) {
        return
      }
      this.segmentSearch = search
      this.getSegments()
    },
    formatName (data) {
      this.items = data.map(segment => {
        if (!segment?.name) return
        return {
          name: operations.checkTranslation(segment.name),
          _id: segment._id

        }
      })
    },
    async getSegments (mounted) {
      try {
        const data = {
          offset: this.offset,
          limit: this.limit,
          search: this.segmentSearch,
          typeEntity: EntityTypeEnum.PEOPLE,
          token: this.token,
          app: this.selectedApp?.uuid
        }
        const response = await SegmentsUseCases.getSegments(this.segmentsController, data)
        if (response?.status) {
          if (mounted) this.segments = []
          if (this.offset > 1) this.segments = this.segments.concat(response?.data?.data)
          else this.segments = response?.data?.data.reverse()
          this.formatName(this.segments)
        }
        this.setSegmentIn()
      } catch (e) {
        console.error(e)
        this.loading = false
      }
    },
    findElement (array, id) {
      const result = array.find(elemento => elemento.id === id)
      if (!result) return
      return {
        id: result._id,
        name: result.name
      }
    },
    async getSegmentsById (id) {
      if (!id) {
        return
      }
      try {
        const data = {
          typeEntity: EntityTypeEnum.PEOPLE,
          token: this.token,
          app: this.selectedApp?.uuid,
          id: id
        }
        const response = await SegmentsUseCases.getSegmentsById(this.segmentsController, data)
        if (response?.status) {
          this.segments.push(response?.data)
          this.formatName(this.segments)
          this.segment = response?.data?._id
        }
      } catch (e) {
        console.error(e)
        this.loading = false
      }
    },
    setSegmentIn () {
      const exists = this.findElement(this.segments, this.setSegment)
      if (exists) {
        this.segment = exists.id
      } else {
        this.getSegmentsById(this.setSegment)
      }
      this.loading = false
    },
    async createCampaign () {
      this.creatingCampaign = true
      try {
        this.isDefaultTemplate ? this.message = this.message.replace(/\s\s+/g, ' ') : this.templateParams.map(param => param.value.replace(/\s\s+/g, ' '))
        this.isDefaultTemplate ? this.message = this.message.replace(/\n/g, ' ') : this.templateParams.map(param => param.value.replace(/\n/g, ' '))
        const params = this.isDefaultTemplate ? [this.message] : this.templateParams.map(param => param.value)
        const userHost = this.selectedProvider
        if (this.showMediaSelector) {
          if (!this.templateMediaUrl) {
            EventBus.$emit('toast', 'error', this.$t('allParametersMustBeFilled'))
            return
          }
        }
        if (this.buttonsParams.length > 0) {
          for (let i = 0; i < this.buttonsParamsLinks.length; i++) {
            if (!this.buttonsParamsLinks[i]) {
              EventBus.$emit('toast', 'error', this.$t('allParametersMustBeFilled'))
              return
            }
          }
        }

        const cleanButtonsParamsLinks = []
        if (this.buttonsParams.length > 0) {
          for (let i = 0; i < this.buttonsParamsLinks.length; i++) {
            if (this.buttonsParamsLinks[i] !== null) {
              cleanButtonsParamsLinks.push(this.buttonsParamsLinks[i])
            }
          }
        }

        const whatsApp = {
          buttonParams: [],
          templateParams: [],
          eventActivation: '',
          parameters: [...params],
          payload: '',
          userHost: userHost,
          message: this.selectedTemplate.templateMessage,
          template: this.selectedTemplate.templateName,
          templateId: this.selectedTemplate.templateId,
          mediaParams: null
        }

        if (this.isBSP) {
          whatsApp.buttonParams = cleanButtonsParamsLinks
        } else {
          whatsApp.templateParams = []
          whatsApp.templateParams.push(...cleanButtonsParamsLinks)
        }

        if (this.showMediaSelector) {
          if (!this.isTextTemplate && hasParams(this.selectedTemplate?.templateMessage)) {
            whatsApp.parameters = [' ', ...whatsApp.parameters]
          }
          whatsApp.mediaParams = [this.templateMediaUrl]
        }

        let paramsEmpty = false

        if (params.length > 0) {
          params.forEach(param => {
            if (!param) paramsEmpty = true
          })
        }

        if (paramsEmpty) {
          EventBus.$emit('toast', 'error', this.$t('paramsEmpty'))
          this.creatingCampaign = false
          return
        }

        const campaign = {
          date: new Date().toISOString(),
          listId: this.segment,
          name: this.campaingsName,
          status: 'draft',
          type: 'whatsapp',
          whatsApp
        }
        const campaignCreated = await CampaignsService.createCampaignV6(campaign)
        if (campaignCreated?.campaign && campaignCreated?.campaign?._id) {
          await CampaignsService.linkCampaignV6({
            campaignId: campaignCreated?.campaign._id,
            createFromUser: this.segment === 'all'
          })
          if (campaignCreated?.campaign?.status === 'inReview') {
            EventBus.$emit('toast', 'error', this.$t('campaignError'))
          }

          // if (campaignCreated?.campaign?.totalUsers < 1) {
          //   EventBus.$emit('toast', 'error', this.$t('campaignEmpty'))
          //   return
          // }
          EventBus.$emit('toast', 'success', this.$t('resumeSubtitle'))
          this.goToDetail(campaignCreated.campaign)
        } else {
          EventBus.$emit('toast', 'error', this.$t('unexpectedError'))
        }
      } catch (error) {
        EventBus.$emit('toast', 'error', this.$t('createCampaignError'))
      } finally {
        this.creatingCampaign = false
      }
    },
    goToDetail (item) {
      this.currentSelectedTab = 'summary'
      this.summaryMenu = true
      this.summaryData = item
      this.outboundName = item.name
    },
    returnM () {
      this.insertCustomField = null
      this.message = ''
      this.templateMediaUrl = null
      this.currentTemplate = null
      this.lengthContent = false
      this.$parent.returnMethod()
    }
  },
  beforeDestroy () {
    this.currentTemplate = null
    this.message = ''
    this.messagePreview = null
    this.templatesRepository = null
  }
}
