<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 14 15"><path
      :fill="color"
      d="M6.144 5.258c0-1.215-.019-2.432.007-3.648C6.169.806 6.854.12 7.657.034A5.58 5.58 0 0 1 8.22.002c.9-.002 1.8.002 2.7-.002.302-.001.554.099.769.314.668.673 1.34 1.342 2.012 2.01.195.195.296.42.296.7-.002 1.897.01 3.794-.006 5.69-.009 1.04-.782 1.783-1.819 1.787a622.65 622.65 0 0 1-4.212 0c-.859-.002-1.537-.503-1.763-1.31a2.18 2.18 0 0 1-.06-.577c-.005-1.119-.003-2.237-.003-3.356h.011ZM10.512.892C10.473.88 10.457.87 10.44.87 9.565.87 8.69.866 7.814.873c-.294.002-.549.132-.675.393a1.541 1.541 0 0 0-.143.645c-.01 1.819-.007 3.636-.007 5.455 0 .437-.005.875.002 1.313.01.593.328.95.872.954 1.465.008 2.93.008 4.396-.005.171-.001.36-.069.51-.158.303-.18.37-.492.37-.822v-4.98c0-.054-.008-.106-.013-.173h-.211c-.487 0-.973.002-1.46 0-.61-.004-.933-.322-.942-.934-.006-.438-.001-.875-.001-1.313V.892Zm2.159 1.728-1.29-1.288V2.62h1.29Z"/><path
        :fill="color"
        d="M.859 8.767v3.356c0 .266.035.52.222.731a.843.843 0 0 0 .664.286c1.46.002 2.92.005 4.378-.002.546-.003.866-.357.886-.943.004-.14-.002-.28.01-.42.023-.225.17-.363.393-.38.195-.015.394.115.427.322.178 1.111-.392 2.11-1.515 2.258-.162.021-.326.03-.49.032-1.319.002-2.638.002-3.957 0-.35 0-.692-.06-.996-.246-.496-.302-.796-.737-.863-1.321a2.735 2.735 0 0 1-.014-.31C.003 9.88.008 7.63 0 5.38c-.002-.622.218-1.125.701-1.511.322-.258.705-.357 1.106-.36.972-.01 1.945-.006 2.918-.003.39.002.605.293.461.623-.09.207-.275.241-.47.242-.881.002-1.763 0-2.645 0h-.219c-.656.002-.99.327-.992.984C.855 6.492.858 7.63.858 8.767Z"/></svg>
</template>

<script>
export default {
  name: 'CopyIcon',
  props: {
    color: {
      type: String,
      default: '#B6BEC7'
    }
  }
}
</script>

<style>

</style>
