import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import { mapGetters, mapState } from 'vuex'
import InboxController from '../../Inbox.controller'
import { addUserToInboxDTO, removeUserFromInboxDTO } from '@/modules/inbox/domain/InboxController'
import InboxUseCases from '@/modules/inbox/application/InboxUseCases'
import { InboxAccessEnum } from '@/modules/inbox/domain/Inbox'
import { EventBus } from '@/eventBus'
import KeybeAvatar from '@/shared/infrastructure/ui/KeybeAvatar.vue'
import KBSwitch from '@/shared/infrastructure/ui/inputs/select/KBSwitch.vue'
import KBSearch from '@/shared/infrastructure/ui/inputs/text/KBSearch.vue'
import KBSelectWithIcon from '@/shared/infrastructure/ui/inputs/select/KBSelectWithIcon.vue'
import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'
import KBText from '@/shared/infrastructure/ui/inputs/text/KBText.vue'
import Loader from '@/shared/infrastructure/ui/Loader.vue'

export default {
  name: 'InboxConfigDetail',
  components: {
    KeybeAvatar,
    KBSwitch,
    KBSearch,
    KBSelectWithIcon,
    KeybeIcon,
    KBText,
    Loader
  },
  props: {
    inbox: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      KeybeIconType,
      inboxName: '',
      inboxUsers: [],
      searchValue: '',
      inboxController: new InboxController(),
      selectedUserHost: this.$t('allLines'),
      showSelector: true,
      loading: false
    }
  },
  computed: {
    ...mapState('AppStore', ['advisers', 'selectedApp']),
    ...mapState('UserStore', ['user', 'token']),
    ...mapState('ConversationStore', ['channels']),
    ...mapGetters('UserStore', ['isAdminOrAbove']),
    advisersComputed () {
      if (!this.canEditInbox) {
        return this.advisers.filter(this.isAdviserInInbox)
      }

      if (!this.searchValue) {
        return this.advisers
      }

      return this.advisers.filter(adviser => {
        const adviserName = this.getAdviserName(adviser) || ''
        return adviserName.toLowerCase().includes(this.searchValue.toLowerCase())
      })
    },
    canEditInbox () {
      if (!this.inbox) {
        return false
      }

      if (this.isAdminOrAbove) {
        return true
      }

      const user = this.inbox?.users?.find(user => user?.userId === this.user?.userId)
      return ((user?.access === InboxAccessEnum.EDIT || user?.access === InboxAccessEnum.DELETE) && !this.inbox?.default) || false
    },
    canDeleteInbox () {
      if (!this.inbox) {
        return false
      }

      const user = this.inbox?.users?.find(user => user?.userId === this.user?.userId)
      return user?.access === InboxAccessEnum.DELETE || false
    },
    userHosts () {
      const whatsappAccounts = this.channels?.whatsapp?.accounts || []
      const userHosts = whatsappAccounts.map(account => {
        return account?.userHost
      })
      userHosts.unshift(this.$t('allLines'))
      return userHosts
    }
  },
  methods: {
    getAdviserName (adviser) {
      if (adviser?.lastName) {
        return `${adviser?.name} ${adviser?.lastName}`
      }
      return adviser?.name || ''
    },
    getAdviserPhoto (adviser) {
      return adviser?.image || ''
    },
    isAdviserInInbox (adviser) {
      return this.inboxUsers.some(user => user.userId === adviser.userId)
    },
    async onAdviserSwitchChange (adviser) {
      if (this.isAdviserInInbox(adviser)) {
        this.inboxUsers = this.inboxUsers.filter(user => user.userId !== adviser.userId)
        const dataToRemoveAdviser: removeUserFromInboxDTO = {
          inboxId: this.inbox._id,
          appUUID: this.selectedApp?.uuid,
          removeUser: adviser.userId,
          userId: this.user?.userId,
          token: this.token,
          isAdmin: this.isAdminOrAbove
        }

        if (!this.isAdminOrAbove) {
          delete dataToRemoveAdviser.isAdmin
        }

        this.loading = true
        const updatedInbox = await InboxUseCases.removeUserFromInbox(this.inboxController, dataToRemoveAdviser)
        this.loading = false
        if (!updatedInbox) {
          EventBus.$emit('toast', 'error', this.$t('errorRemovingAdviserToInbox'))
          this.inboxUsers.push({
            userId: adviser.userId,
            access: InboxAccessEnum.EDIT
          })
        }
        this.$emit('updateLocalInbox', updatedInbox)
      } else {
        this.inboxUsers.push({
          userId: adviser.userId,
          access: InboxAccessEnum.EDIT
        })

        const dataToAddAdviser: addUserToInboxDTO = {
          inboxId: this.inbox._id,
          userId: this.user?.userId,
          token: this.token,
          appUUID: this.selectedApp?.uuid,
          permission: {
            userId: adviser.userId,
            access: InboxAccessEnum.EDIT
          },
          isAdmin: this.isAdminOrAbove
        }

        // if (!this.isAdminOrAbove) {
        //   delete dataToAddAdviser.isAdmin
        // }
        this.loading = true
        const updatedInbox = await InboxUseCases.addUserToInbox(this.inboxController, dataToAddAdviser)
        this.loading = false
        if (!updatedInbox) {
          EventBus.$emit('toast', 'error', this.$t('errorAddingAdviserToInbox'))
          this.inboxUsers = this.inboxUsers.filter(user => user.userId !== adviser.userId)
        }
        this.$emit('updateLocalInbox', updatedInbox)
      }
    },
    updateInbox () {
      this.$emit('updateInbox', {
        ...this.inbox,
        name: this.inboxName,
        users: this.inboxUsers
      })
    },
    itsMe (adviser) {
      return adviser?.userId === this.user?.userId
    }
  },
  beforeDestroy () {
    this.inboxController = null
  },
  watch: {
    inbox: {
      handler (inbox) {
        this.inboxName = inbox?.name || ''
        this.inboxUsers = inbox?.users || []
        this.showSelector = false
        this.selectedUserHost = inbox?.line || this.$t('allLines')
        setTimeout(() => {
          this.showSelector = true
        }, 100)
      },
      immediate: true
    },
    selectedUserHost: {
      handler (userHost) {
        if (userHost === this.$t('allLines')) {
          const inboxToUpdate = {
            ...this.inbox,
            line: ''
          }
          this.$emit('updateInbox', inboxToUpdate)
        } else {
          const inboxToUpdate = {
            ...this.inbox,
            line: userHost
          }
          this.$emit('updateInbox', inboxToUpdate)
        }
      }
    }
  }
}
