import KeybeRepository from '@/shared/infrastructure/repositories/KeybeRepository'
import { HeadersBuilder } from '@/shared/infrastructure/http/HeadersBuilder'
import { getOptions, nonGetOptions } from '@/shared/infrastructure/http/HttpClient'
import operations from '@/utils/operations'
import { dataToGetDeleteCard, dataToGetCards } from '../../domain/models/CardsController'
import { GenericResponse } from '@/shared/infrastructure/http/GenericResponse'

export default class CardsRepository extends KeybeRepository {
  getSuggestionsAbortController: AbortController

  constructor () {
    super()

    this.getSuggestionsAbortController = new AbortController()
  }

  async getCards (data: dataToGetCards): Promise<GenericResponse> {
    const { app, funnel, step, offset, limit, search, filters } = data

    const url = `${process.env.VUE_APP_FUNNELS_HTTP}/${app}/funnel/${funnel}/steps/${step}/cards`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    let formattedFilters = {}
    if (filters) {
      formattedFilters = operations.transformObject(filters)
    } else formattedFilters = {}
    const query = {
      offset,
      limit,
      search,
      ...formattedFilters
    }

    if (!query.search) delete query.search
    const requestOptions: getOptions = {
      headers,
      query,
      url,
      abortController: this.getSuggestionsAbortController
    }

    return await this.client.get(requestOptions)
  }

  async getCard (data) {
    const { app, funnel, step, id } = data

    const url = `${process.env.VUE_APP_FUNNELS_HTTP}/${app}/funnel/${funnel}/steps/${step}/cards/${id}`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    const requestOptions: getOptions = {
      headers,
      url,
      abortController: this.getSuggestionsAbortController
    }

    return await this.client.get(requestOptions)
  }

  async getCardsFromContact (data) {
    const { app, filters } = data
    const url = `${process.env.VUE_APP_FUNNELS_HTTP}/${app}/cards-filter`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    let formattedFilters = {}
    if (filters) {
      formattedFilters = operations.transformObject(filters)
    } else formattedFilters = {}
    const query = formattedFilters

    const requestOptions: getOptions = {
      headers,
      query,
      url,
      abortController: this.getSuggestionsAbortController
    }

    return await this.client.get(requestOptions)
  }

  async createCard (data) {
    const { app, funnel, step, createInput } = data
    const url = `${process.env.VUE_APP_FUNNELS_HTTP}/${app}/funnel/${funnel}/steps/${step}/cards`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    const requestOptions: nonGetOptions = {
      headers,
      data: createInput,
      url,
      abortController: this.getSuggestionsAbortController
    }

    return await this.client.post(requestOptions)
  }

  async updateCard (data) {
    const { app, funnel, step, id, updateInput } = data
    const url = `${process.env.VUE_APP_FUNNELS_HTTP}/${app}/funnel/${funnel}/steps/${step}/cards/${id}`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    const response = await fetch(url, {
      method: 'PATCH',
      body: JSON.stringify(updateInput),
      headers: headers || undefined,
      signal: this.getSuggestionsAbortController?.signal
    })
    const parsedResponse = await response.json()
    return parsedResponse
    // const requestOptions: nonGetOptions = {
    //   headers,
    //   data: updateInput,
    //   url,
    //   abortController: this.getSuggestionsAbortController
    // }

    // return await this.client.patch(requestOptions)
  }

  async deleteCard (data: dataToGetDeleteCard): Promise<GenericResponse> {
    const { app, funnel, step, id } = data
    const url = `${process.env.VUE_APP_FUNNELS_HTTP}/${app}/funnel/${funnel}/steps/${step}/cards/${id}`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    const requestOptions: nonGetOptions = {
      headers,
      url,
      abortController: this.getSuggestionsAbortController
    }

    return await this.client.delete(requestOptions)
  }

  async moveContacts (data) {
    const { app, funnel, step } = data
    const url = `${process.env.VUE_APP_FUNNELS_HTTP}/${app}/move-contacts/${funnel}/${step}/to`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    const requestOptions: nonGetOptions = {
      headers,
      url,
      abortController: this.getSuggestionsAbortController
    }

    return await this.client.post(requestOptions)
  }

  async getTerminationReason (data) {
    const { app } = data

    const url = `${process.env.VUE_APP_FUNNELS_HTTP}/${app}/funnel/closeReason`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    const requestOptions: getOptions = {
      headers,
      url,
      abortController: this.getSuggestionsAbortController
    }

    return await this.client.get(requestOptions)
  }
}
