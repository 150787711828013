












































import BikyConfig from './BikyConfig'

export default BikyConfig
