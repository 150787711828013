import { GenericResponse } from '@/shared/infrastructure/http/GenericResponse'
import { FileKnowledgeDocument, IKnowledgeBaseController, deleteFileDTO, getFilesDTO, uploadFileDTO } from '../domain/KnoledgeBase'
import KnowledgeBaseRepository from './KnowledgeBase.repository'

export default class KnowledgeBaseController implements IKnowledgeBaseController {
  repository: KnowledgeBaseRepository

  constructor () {
    this.repository = new KnowledgeBaseRepository()
  }

  async getFiles (data: getFilesDTO): Promise<FileKnowledgeDocument[]> {
    const response: GenericResponse = await this.repository.getFiles(data)

    if (!response.status) {
      return []
    }

    return response.data.files
  }

  async uploadFile (data: uploadFileDTO): Promise<boolean> {
    const response: GenericResponse = await this.repository.uploadFile(data)
    return response.status
  }

  async deleteFile (data: deleteFileDTO): Promise<boolean> {
    const response: GenericResponse = await this.repository.deleteFile(data)
    return response.status
  }
}
