











































































































import AutoassignConfig from './AutoassignConfig'
export default AutoassignConfig
