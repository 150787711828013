<template>
  <svg :width="width" :height="height" viewBox="0 0 3 15" fill="none" xmlns="http://www.w3.org/2000/svg">
  <circle cx="1.2722" cy="1.27273" r="1.27273" transform="rotate(90 1.2722 1.27273)" :fill="color"/>
  <circle cx="1.2722" cy="7.27273" r="1.27273" transform="rotate(90 1.2722 7.27273)" :fill="color"/>
  <circle cx="1.2722" cy="13.2727" r="1.27273" transform="rotate(90 1.2722 13.2727)" :fill="color"/>
  </svg>
</template>

<script>
export default {
  name: 'AddIcon',
  props: {
    width: {
      type: Number,
      default: 16
    },
    height: {
      type: Number,
      default: 18
    },
    color: {
      type: String,
      default: '#B6BEC7'
    }
  }

}
</script>

<style>

</style>
