import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'
import KBSearch from '@/shared/infrastructure/ui/inputs/text/KBSearch.vue'
import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import { EventBus } from '@/eventBus'
import { Contact } from '@/modules/CDP/shared/contacts/domain/Contact'
import DocumentsController from '../../controllers/Documents.controller'
import DocumentsUseCases from '@/modules/KBDrive/application/DocumentsUseCases'
import EmitToast from '@/utils/EmitToast'
import { dataToGetDocumentsData, documentsDataGetter } from '@/modules/KBDrive/domain/DocumentsController'
import { FileData, ResponseData } from '@/modules/KBDrive/domain/Documents'
import { mapState } from 'vuex'
import DocumentList from '../../components/DocumentList/DocumentList.vue'
export default {
  components: {
    KeybeIcon,
    KBSearch,
    DocumentList
  },
  computed: {
    ...mapState('AppStore', ['selectedApp']),
    ...mapState('UserStore', ['token']),
    documentsFiltered (): FileData[] {
      return this.documents.filter((document: FileData) => {
        const name: string = document.name.toLowerCase()
        return name.includes(this.search.toLowerCase())
      })
    }
  },
  mounted () {
    EventBus.$on('openContactDocuments--drive', this.getDocuments.bind(this))
  },
  data () {
    return {
      KeybeIconType,
      loading: false,
      documents: [],
      contact: {},
      search: '',
      documentsController: new DocumentsController()
    }
  },
  methods: {
    async getDocuments (contact: Contact): Promise<void> {
      this.documentsController.cancelGetDocuments()
      this.loading = true
      const data: dataToGetDocumentsData = {
        userId: contact?.uuid || '',
        app: this.selectedApp?.uuid,
        token: this.token
      }
      const response: documentsDataGetter = await DocumentsUseCases.getDocuments(this.documentsController, data)
      if (response?.status) {
        const documents: ResponseData[] = response?.data?.data || []
        this.documents = documents
        this.contact = contact
      } else EmitToast.emitErrors(response)
      this.loading = false
    }
  },
  beforeDestroy () {
    EventBus.$off('openContactDocuments--drive', this.getDocuments.bind(this))
  }
}
