export const OnboardingStore = {
  namespaced: true,
  state: {
    onboardingStep: 11,
    onboardingProgress: 0,
    onboardingStarted: false
  },
  mutations: {
    SET_ONBOARDING_STEP (state: any, view: string) {
      state.onboardingStep = view
    },
    SET_ONBOOARDING_PROGRESS (state: any, progress: number) {
      state.onboardingProgress = progress
    },
    SET_ONBOARDING_STARTED (state: any, card: any) {
      state.onboardingStarted = card
    }
  },
  actions: {
    setOnboardingStep (context: any, step: number) {
      context.commit('SET_ONBOARDING_STEP', step)
      if (context.state.onboardingProgress <= 100) {
        if (context.state.onboardingStep < 2) context.commit('SET_ONBOOARDING_PROGRESS', context.state.onboardingProgress += 5)
        else if (context.state.onboardingStep > 2 && context.state.onboardingStep < 12) context.commit('SET_ONBOOARDING_PROGRESS', context.state.onboardingProgress += 10)
      }
    }
  }
}
