<template>
  <div class="contentDataTimePicker">
    <KBText
      class="KBform smalForm"
      v-model="day"
      type="number"
      :placeholder="$t('days')"
      @input="() => { if(day > 31 || day < 1) { day = 1 }}"/>
    <span>/</span>
    <KBText
      class="KBform smalForm"
      v-model="month"
      type="number"
      :placeholder="$t('month')"
      @input="() => { if(day > 12 || day < 1) { day = 1 }}"/>
    <span>/</span>
    <KBText
      class="KBform smalForm"
      v-model="year"
      type="number"
      :placeholder="$t('year')"
      @input="() => { if(year.length < 4) { year = 2023 }}"/>
  </div>
</template>

<script>
import DatePickerRow from './DatePickerRow'
export default DatePickerRow
</script>

<style lang='scss'>
    @import './DatePickerRow.scss';
</style>
