












































































































import KBDailyContactList from './KBDailyContactList'
export default KBDailyContactList
