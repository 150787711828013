








































import Vue from 'vue'
import Colors from '@/utils/Colors'
export default Vue.extend({
  computed: {
    pageCount () {
      return this.count < this.itemsPerPage ? 1 : Math.ceil(this.count / this.itemsPerPage)
    },
    itemsRange () {
      const start = this.currentPage * this.itemsPerPage - this.itemsPerPage + 1
      const end = this.currentPage * this.itemsPerPage
      return `${start}-${end}`
    }
  },
  mounted () {
    if (this.paginationSetted && this.paginationSetted.page !== null) {
      this.itemsPerPage = this.paginationSetted.limit
      this.currentPage = this.paginationSetted.page
    }
  },
  data () {
    return {
      itemsPerPage: 25,
      items: [25, 50, 100, 200, 500],
      currentPage: 1,
      Colors
    }
  },
  methods: {
    goBack () {
      if (!this.disabled) {
        if (this.currentPage !== 1) {
          this.currentPage--
          this.sendPagination()
        }
      }
    },
    goForward () {
      if (!this.disabled) {
        if (this.currentPage < this.pageCount) {
          this.currentPage++
          this.sendPagination()
        }
      }
    },
    changeItems (items) {
      this.itemsPerPage = items
      this.currentPage = 1
      this.$emit('changeItems')
      this.sendPagination()
    },
    sendPagination () {
      const pagination = {
        page: this.currentPage,
        items: this.itemsPerPage
      }
      this.$emit('getItems', pagination)
    }
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    count: {
      type: Number
    },
    text: {
      type: String
    },
    showTotal: {
      type: Boolean,
      default: false
    },
    selectedUsers: {
      type: Number
    },
    paginationSetted: {
      type: Object
    },
    disabled: {
      type: Boolean
    }
  }
})
