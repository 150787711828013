export default class FilesService {
    static defaultTemplates: string[] = ['Hola {{1}}.', 'Hola {{1}}', '¡Hola! {{1}}']

    static findDefaultTemplate (templates) {
      return templates?.find(template => FilesService.defaultTemplates.includes(template?.templateMessage))
    }

    static isDefaultTemplate (template) {
      return FilesService.defaultTemplates.includes(template?.templateMessage)
    }

    static findProviderByUserHost (userHost, providers) {
      return providers?.find(provider => provider?.userHost === userHost)
    }

    static getTemplateByMessage (message, templates) {
      return templates?.find(template => template?.templateMessage === message)
    }
}
