export default {
  name: 'KBTextSelect',
  props: {
    value: {
      type: String,
      required: true
    },
    items: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      selectedItem: ''
    }
  },
  mounted () {
    this.selectedItem = this.value
  },
  watch: {
    selectedItem (val) {
      this.$emit('input', val)
    },
    value (val) {
      this.selectedItem = val
    }
  }
}
