export enum NotificationsSoundKeys {
  KB_YEAH = 'kbYeah',
  KB_CASH = 'kbCash',
  KB_CLASSIC = 'kbClassic',
  KB_FEELS = 'kbFeels',
  KB_HAPPY = 'kbHappy',
  KB_SKY = 'kbSky',
  KB_SYNTH = 'kbSynth',
  KB_WHITE = 'kbWhite'
}
