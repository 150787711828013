






























































































import TagsConfiguration from './TagsConfiguration'
export default TagsConfiguration
