import { EventBus } from '@/eventBus'
import { BikyConfigUseCases } from '@/modules/bky/application/BikyConfigUseCases'
import { BikyConfig, defaultConfig } from '@/modules/bky/domain/BikyConfig'
import { BikyConfigTabs } from '@/modules/bky/domain/BikyConfigTabs'
import { BikySkill } from '@/modules/bky/domain/BikySkill'
import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import { mapState } from 'vuex'
import BikyConfigController from '../../../controllers/BikyConfig.controller'
import BikyConfigHeader from '../BikyConfigHeader/BikyConfigHeader.vue'
import BikyConfigMyKeybeCatalog from '../BikyConfigMyKeybeCatalog/BikyConfigMyKeybeCatalog.vue'
import BikyConfigSchedule from '../BikyConfigSchedule/BikyConfigSchedule.vue'
import BikyConfigScheduleBasic from '../BikyConfigScheduleBasic/BikyConfigScheduleBasic.vue'
import BikyConfigSkillBubble from '../BikyConfigSkillBubble/BikyConfigSkillBubble.vue'

export default {
  name: 'BikyConfigSkills',
  components: {
    BikyConfigHeader,
    BikyConfigSkillBubble,
    BikyConfigSchedule,
    BikyConfigMyKeybeCatalog,
    BikyConfigScheduleBasic
  },
  data () {
    return {
      BikyConfigTabs,
      configSkill: null,
      manageSalesCard: false,
      bikyConfigController: new BikyConfigController()
    }
  },
  computed: {
    ...mapState('AppStore', ['selectedApp']),
    ...mapState('UserStore', ['token', 'user']),
    skills (): BikySkill[] {
      return [{
        title: this.$t('saveDataIntoCdp'),
        icon: KeybeIconType.SAVE,
        active: true
      }, {
        title: this.$t('scheduling'),
        icon: KeybeIconType.SCHEDULE,
        active: false
      },
      {
        title: this.$t('myKeybeCatalog'),
        icon: KeybeIconType.SHOPPING_CART,
        active: false
      }, {
        title: this.$t('sendVoiceNotes'),
        icon: KeybeIconType.MICROPHONE,
        active: true
      }, {
        title: this.$t('receiveVoiceNotes'),
        icon: KeybeIconType.MICROPHONE,
        active: true
      }, {
        title: this.$t('transferToAnotherAdvisor'),
        icon: KeybeIconType.CHAT_OUTLINED,
        active: true
      }, {
        title: this.$t('sendMultimediaFiles'),
        icon: KeybeIconType.VIDEO_CAMERA,
        active: false,
        disabled: true
      }, {
        title: this.$t('manageSalesCard'),
        icon: KeybeIconType.SAVE,
        active: this.manageSalesCard,
        callback: () => {
          this.toggleManageSalesCard()
        }
      }]
    }
  },
  methods: {
    getComponent (skill: BikySkill): any {
      const components: {
        [key: string]: any
      } = {
        [this.$t('saveDataIntoCdp')]: BikyConfigSkillBubble,
        [this.$t('scheduling')]: BikyConfigSchedule,
        [this.$t('sendVoiceNotes')]: BikyConfigSkillBubble,
        [this.$t('receiveVoiceNotes')]: BikyConfigSkillBubble,
        [this.$t('transferToAnotherAdvisor')]: BikyConfigSkillBubble,
        [this.$t('sendMultimediaFiles')]: BikyConfigSkillBubble,
        [this.$t('myKeybeCatalog')]: BikyConfigMyKeybeCatalog,
        [this.$t('manageSalesCard')]: BikyConfigSkillBubble
      }

      return components[skill.title]
    },
    getConfigComponent (skill: BikySkill): any {
      const components: {
        [key: string]: any
      } = {
        [this.$t('scheduling')]: BikyConfigScheduleBasic
      }

      return components[skill.title]
    },
    setConfigSkill (skill: BikySkill): void {
      this.configSkill = skill
    },
    handleCallback (skill: BikySkill): void {
      if (skill.callback) {
        skill.callback()
      }
    },
    async getConfig () {
      const config: BikyConfig | null = await BikyConfigUseCases.getBikyConfig(this.bikyConfigController, {
        appUUID: this.selectedApp?.uuid,
        token: this.token
      })

      if (!config) {
        this.basicConfig = {
          ...defaultConfig
        }
        return
      }

      this.manageSalesCard = config?.skills?.manageSalesCard || false
    },
    async toggleManageSalesCard (): Promise<boolean> {
      this.$emit('start-loading')
      const newConfig = {
        skills: {
          manageSalesCard: !this.manageSalesCard
        }
      }

      const updated = await BikyConfigUseCases.updateBikyConfig(this.bikyConfigController, {
        token: this.token,
        appUUID: this.selectedApp?.uuid,
        updatedBy: this.user?.userId,
        ...newConfig
      })

      if (!updated) {
        EventBus.$emit('toast', 'error', this.$t('errorUpdatingConfig'))
        this.$emit('stop-loading')
        return false
      }

      this.getConfig()
      this.$emit('stop-loading')

      return updated
    }
  },
  mounted () {
    this.getConfig()
  }
}
