<template>
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18"><path :fill="color" d="M15.091 3.59c-.104-.11-.165-.178-.228-.241-.779-.775-1.556-1.549-2.336-2.32-.174-.172-.283-.37-.197-.607a.61.61 0 0 1 .474-.412c.261-.05.438.094.603.255.673.653 1.341 1.31 2.011 1.965.495.484.992.967 1.484 1.453.373.368.373.643 0 1.01-1.163 1.14-2.328 2.28-3.495 3.418-.322.315-.583.347-.876.122-.254-.195-.3-.492-.112-.75.058-.081.129-.155.2-.226.748-.746 1.497-1.489 2.245-2.233.063-.063.123-.13.227-.242H.835c-.119 0-.24-.006-.355-.03C.164 4.686.013 4.5.012 4.19c-.001-.3.16-.5.469-.564.116-.024.237-.03.354-.03 4.627-.002 9.254-.002 13.881-.002h.375V3.59ZM2.103 14.4c.104.111.164.178.227.241l2.245 2.232c.363.36.41.673.138.935-.3.29-.586.254-.97-.12-1.147-1.121-2.294-2.243-3.44-3.366-.4-.39-.404-.637-.013-1.02 1.163-1.14 2.328-2.28 3.495-3.418.388-.378.768-.356 1.017.05.113.183.117.364-.004.54a1.985 1.985 0 0 1-.229.269c-.807.804-1.615 1.606-2.484 2.469h.349c4.652 0 9.304-.002 13.956.007.207 0 .47.051.61.18.351.322.168.909-.287.972-.118.016-.236.029-.355.029l-13.88.001h-.375V14.4Z"/></svg>
</template>

<script>
export default {
  name: 'TransferIcon',
  props: {
    color: {
      type: String,
      default: '#B6BEC7'
    }
  }
}
</script>

<style>

</style>
