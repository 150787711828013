// import { Languages } from '@/components/shared/enums/Languages'
import PushNotificationsController from '@/controllers/PushNotificationsController'
// import V6RedirectionController from '@/controllers/V6RedirectionController'
import { EventBus } from '@/eventBus'
import AuthService from '@/services/AuthService'
import UserService from '@/services/UserService'
import {
  FloatingWindowType,
  getFloatingWindowIcon
} from '@/shared/infrastructure/enums/Layout/FloatingWindowType'
import Colors from '@/utils/Colors'
import link from '@/utils/links'
import LottieAnimation from 'lottie-web-vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import { KeybeIconType } from '../../../enums/icons/KeybeIconType'

// components
import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'
import KBListMenu from '@/shared/infrastructure/ui/menus/KBListMenu.vue'
import KBMenu from '@/shared/infrastructure/ui/menus/KBMenu/KBMenu.vue'
import VideoCampaign from '@/shared/infrastructure/ui/players/VideoCampaign/VideoCampaign.vue'
import BikyWidget from '../BikyWidget/BikyWidget.vue'
// import StatusAvatar from '@/components/shared/components/StatusAvatar.vue'
import OnboardingUseCases from '@/modules/SignInOnboarding/application/OnboardingUseCases'
import OnboardingController from '@/modules/SignInOnboarding/infrastructure/controllers/Onboarding.controller'
import { VecindarioConfigUseCases } from '@/modules/bky/application/VecindarioConfigUseCases'
import { VecindarioConfig } from '@/modules/bky/domain/VecindarioConfig'
import VecindarioConfigController from '@/modules/bky/infrastructure/controllers/VecindarioConfig.controller'
import AdviserStatusController from '@/modules/conversations/infrastructure/controllers/AdviserStatus.controller'
import KeybeAppSelector from '@/shared/infrastructure/ui/KeybeAppSelector/KeybeAppSelector.vue'
import moment from 'moment'
import { User } from '@/components/models/User'
import { UserType, UserTypes } from '@/modules/Permissions/domain/UserTypes'
import AppsUseCases from '@/modules/Apps/application/AppsUseCases'
import AppsController from '@/modules/Apps/infrastructure/controllers/Apps.controller'
import FeesServices from '@/modules/configuration/application/services/FeesServices'

export default {
  name: 'KeybeSidebar',
  mounted () {
    this.getFeesUser()
    EventBus.$on(
      'startConfigOnboarding--wizard',
      this.startConfigOnboarding.bind(this)
    )
    EventBus.$on(
      'closeConfigOnboarding--wizard',
      this.stopConfigOnboarding.bind(this)
    )
    this.getOnboardingStep()
    this.getConfig()
    this.getFlowsActive()
  },
  data () {
    return {
      link,
      Colors,
      KeybeIconType,
      getFloatingWindowIcon,
      FloatingWindowType,
      campaignOpenedAt: null,
      menuOpen: false,
      showConfigOnboarding: false,
      rerender: 0,
      onboardingController: new OnboardingController(),
      vecindarioConfig: null,
      vecindarioConfigController: new VecindarioConfigController(),
      gettingVecindarioUrl: false,
      vecindarioScheduleUrl: '',
      appsController: new AppsController(),
      app: null,
      loading: false,
      intervalId: null,
      hasFlows1: false,
      isFeesAdmin: false
    }
  },
  components: {
    // StatusAvatar,
    KeybeIcon,
    KBMenu,
    KBListMenu,
    LottieAnimation,
    VideoCampaign,
    KeybeAppSelector,
    BikyWidget
  },
  computed: {
    ...mapState('BillingStore', ['appConfig']),
    ...mapState('FloatingWindowsStore', ['minimizedWindows']),
    ...mapState('ConversationStore', ['conversationQuery']),
    ...mapState('LayoutStore', ['listBoxWidth']),
    ...mapState('UserStore', ['user', 'token', 'isUserReadonly']),
    ...mapState('AppStore', ['selectedApp', 'language']),
    ...mapState('CompanyStore', ['selectedCompany']),
    ...mapState('ConversationStore', ['wpProviders']),
    ...mapGetters('AppStore', ['isCDPApp', 'isKeybeApp', 'isGWMApp']),
    ...mapGetters('UserStore', [
      'isLoggedIn',
      'getToken',
      'isAgent',
      'isSuperAdmin',
      'isAdmin',
      'isAdminOrAbove',
      'isTrainer',
      'isCoordinatorOrAbove',
      'isCoordinator',
      'getUser',
      'getUserType'
    ]),
    isConversations (): boolean {
      return this.$route.path === link.conversations
    },
    validateGWMUser (): boolean {
      if (this.isGWMApp) {
        return !this.isAgent && !this.isCoordinator
      }

      return true
    },
    userRole (): UserType {
      if (this.isSuperAdmin) {
        return UserTypes.SUPER_ADMIN
      }

      return this.getUserType || UserTypes.AGENT
    },
    isNotAgentNorTrainer () {
      return !this.isAgent && !this.isTrainer
    },
    inactiveApp (): boolean {
      const status = this.selectedApp?.status
      return status !== 'active'
    },
    userEmail () {
      return this.user?.email
    },
    userFullName (): string {
      return `${this.user?.name || ''} ${this.user?.lastName ?? ''}`.trim()
    },
    selectedAppName () {
      return this.selectedApp?.name || ''
    },
    selectedAppDefaultCountryCode () {
      return this.selectedApp?.defaultCountryCode || ''
    },
    selectedAppID () {
      return this.selectedApp?.uuid
    },
    selectedCompanyID () {
      return this.selectedCompany?.uuid
    },
    firstUserHost () {
      if (this.wpProviders?.length > 0) {
        const userHost = this.wpProviders[0]?.userHost
        return userHost
      } else {
        return '+'
      }
    },
    isInConversations () {
      return this.$route.path === link.conversations
    },
    isInPeople () {
      return (
        this.$route.path === link.people ||
        this.$route.path === link.businesses ||
        this.$route.path.includes('/segment') ||
        this.$route.path.includes('/business')
      )
    },
    isInPipelines () {
      return this.$route.path === '/sales' || this.$route.path.includes('sales')
    },
    modulesItems () {
      let baseItems = [
        {
          text: this.$t('microsites'),
          icon: KeybeIconType.PLANET,
          height: 15,
          width: 14,
          showBeta: false,
          callback: () => {
            EventBus.$emit('closeKBMenu')
            this.setMaximazedWindow(FloatingWindowType.MY_KEYBE)
          }
        },
        {
          text: `${this.$t('metrics')}2.0`,
          icon: KeybeIconType.METRICS,
          height: 14,
          width: 14,
          callback: () => {
            EventBus.$emit('closeKBMenu')
            this.goToMetrics2()
          }
        },
        {
          text: this.$t('imports'),
          icon: KeybeIconType.ARROW_UP,
          height: 14,
          width: 14,
          callback: () => {
            EventBus.$emit('closeKBMenu')
            this.setMaximazedWindow(FloatingWindowType.IMPORTS)
          }
        },
        {
          text: this.$t('catalog'),
          icon: KeybeIconType.SHOPPING_CART,
          height: 14,
          width: 14,
          callback: () => {
            EventBus.$emit('closeKBMenu')
            this.setMaximazedWindow(FloatingWindowType.CATALOG)
          }
        }
      ]

      if (this.isKeybeApp) {
        baseItems = [
          {
            text: this.$t('microsites'),
            icon: KeybeIconType.PLANET,
            height: 15,
            width: 14,
            callback: () => {
              EventBus.$emit('closeKBMenu')
              this.setMaximazedWindow(FloatingWindowType.MY_KEYBE)
              // this.$router.push(link.micrositeHome)
            }
          },
          {
            text: `${this.$t('metrics')}2.0`,
            icon: KeybeIconType.METRICS,
            height: 14,
            width: 14,
            callback: () => {
              EventBus.$emit('closeKBMenu')
              this.goToMetrics2()
            }
          },
          {
            text: this.$t('imports'),
            icon: KeybeIconType.ARROW_DOWN,
            height: 14,
            width: 14,
            callback: () => {
              EventBus.$emit('closeKBMenu')
              this.setMaximazedWindow(FloatingWindowType.IMPORTS)
            }
          },
          {
            text: this.$t('catalog'),
            icon: KeybeIconType.SHOPPING_CART,
            height: 14,
            width: 14,
            callback: () => {
              EventBus.$emit('closeKBMenu')
              this.setMaximazedWindow(FloatingWindowType.CATALOG)
            }
          }
        ]

        if (this.isCoordinatorOrAbove) {
          baseItems.push({
            text: this.$t('partners'),
            icon: KeybeIconType.HAND_SHAKE_OUTLINE,
            height: 12,
            width: 14,
            callback: () => {
              EventBus.$emit('closeKBMenu')
              this.setMaximazedWindow(FloatingWindowType.PARTNERS)
            }
          })
        }
      }

      const flowsItem: any = {
        text: 'Flows',
        icon: KeybeIconType.MOMENTS,
        height: 15,
        width: 14,
        callback: () => {
          EventBus.$emit('closeKBMenu')
          if (!this.isUserReadonly) this.goToKBFlows()
        }
      }

      const flowsItemSecond: any = {
        text: 'Flows 2.0',
        animation: true,
        icon: KeybeIconType.MOMENTS,
        showBeta: true,
        height: 15,
        width: 14,
        callback: () => {
          EventBus.$emit('closeKBMenu')
          if (!this.isUserReadonly) this.goToKBFlowsV2()
        }
      }

      const consumptionItem: any = {
        text: this.$t('tabConsumptions'),
        icon: KeybeIconType.CASH,
        height: 12,
        width: 14,
        callback: () => {
          EventBus.$emit('closeKBMenu')
          if (this.isFeesAdmin) this.setMaximazedWindow(FloatingWindowType.BILLING)
          else {
            const token = this.token
            const app = this.selectedApp.uuid
            const url: string = `${process.env.VUE_APP_BILLING_APP}?token=${token}&app=${app}&section=general`
            window.open(url, '_blank')
          }
        }
      }

      // Validate if is admin or above to show flows
      if (this.isAdminOrAbove) {
        const flows: any[] = this.hasFlows1 ? [flowsItem, flowsItemSecond] : [flowsItemSecond]
        baseItems.splice(1, 0, ...flows)
        baseItems.push(consumptionItem)
      }

      const tasksItem: any = {
        text: this.$t('tasks'),
        icon: KeybeIconType.TASKS,
        height: 12,
        width: 14,
        callback: () => {
          EventBus.$emit('closeKBMenu')
          this.setMaximazedWindow(FloatingWindowType.TASKS)
        }
      }

      if (this.isCDPApp) {
        baseItems.push(tasksItem)
      }

      const vecindarioItem: any = {
        text: this.$t('scheduling'),
        icon: KeybeIconType.SCHEDULE,
        width: 14,
        height: 14,
        callback: () => {
          if (!this.isUserReadonly) this.openSchedule()
        }
      }

      if (this.hasVecindarioConfig && this.isCoordinatorOrAbove) {
        baseItems.push(vecindarioItem)
      }

      return baseItems
    },
    profileItems () {
      return [
        {
          text: 'advisorStatus'
        },
        {
          text: this.$t('changePhoto'),
          icon: KeybeIconType.EXTERNAL,
          height: 14,
          width: 14,
          callback: () => {
            this.openFileInput()
          }
        },
        {
          text: this.$t('logout'),
          icon: KeybeIconType.EXTERNAL,
          height: 14,
          width: 14,
          callback: () => this._logout()
        }
      ]
    },
    showSideMenuMinimizedIcon () {
      if (this.listBoxWidth !== 0) {
        return false
      }
      return true
    },
    hasVecindarioConfig (): boolean {
      return this.vecindarioConfig?.active
    }
  },
  methods: {
    ...mapActions('FloatingWindowsStore', ['setMaximazedWindow']),
    ...mapActions('ConversationStore', ['setConversationQuery']),
    ...mapActions('UserStore', ['logout']),
    ...mapActions('LayoutStore', ['setListBoxWidth']),
    async getFeesUser () {
      const feesData = await FeesServices.isCommissionAgent()
      if (feesData?.status) {
        const user = feesData.data
        this.isFeesAdmin = user?.superadmin || false
      }
    },
    async getFlowsActive (): Promise<void> {
      this.hasFlows1 = await AppsUseCases.appHasFlows1(this.appsController, {
        uuid: this.selectedAppID,
        token: this.token
      })
    },
    async getOnboardingStep (): Promise<void> {
      const step: number = await OnboardingUseCases.getOnboardingStep(
        this.onboardingController
      )
      this.showConfigOnboarding = step === 2
      this.rerender++
    },
    goTo (link: string): void {
      EventBus.$emit('closeDrawer--details')
      if (this.$route.path === link) return
      if (link === this.link.conversations) {
        if (this.conversationQuery) {
          link = `${link}?${this.conversationQuery}`
          this.$router.push(link)
          return
        }
        this.$router.push(link)
        return
      }

      const query = this.$route.query
      let conversationQuery = ''
      if (query?.room) {
        conversationQuery = `room=${query.room}`
      }
      if (query?.quickFilter) {
        conversationQuery = `${conversationQuery}&quickFilter=${query.quickFilter}`
      }
      this.setConversationQuery(conversationQuery)
      this.$router.push(link)
    },
    openMenu () {
      EventBus.$emit('openSideMenu')
    },
    openFileInput () {
      this.$refs.imageInput.click()
    },
    async onFilePicked (event) {
      const img = event.target.files[0]
      this.imgUrl = URL.createObjectURL(img)
      const response = await UserService.uploadImage(img)
      if (response.status) {
        const photo = response?.data?.data?.info?.location
        const patchResponse = await UserService.updateProfile({ photo })
        if (patchResponse.status) {
          this.$store.commit(
            'UserStore/SET_KEYBE_USER',
            patchResponse.data.data.user
          )
          EventBus.$emit('toast', 'success', this.$t('profilePicUpdated'))
        } else {
          EventBus.$emit('toast', 'error', this.$t('errorUpdatingProfilePic'))
        }
      } else {
        EventBus.$emit('toast', 'error', this.$t('errorUploadingPicture'))
      }
    },
    async _logout () {
      await PushNotificationsController.setOneSignalStatus({ active: false })
      // Set adviser status to false
      await AdviserStatusController.inactiveIfIsAdviser(this.isAgent)
      await AuthService.logout()
      this.setListBoxWidth(null)
      this.logout()
      await this.$router.push(link.signin)
    },
    getStyleForMinimizedIcon (window) {
      const styles = {
        [FloatingWindowType.MY_KEYBE]: 'height: 14px; width: 14px;',
        [FloatingWindowType.MOMENTS]: 'height: 14px; width: 14px;',
        [FloatingWindowType.CAMPAIGNS]: 'height: 14px; width: 14px;',
        [FloatingWindowType.EXTENSION]: 'height: 14px; width: 14px;',
        [FloatingWindowType.BILLING]: 'height: 10px; width: 14px;',
        [FloatingWindowType.BILLING_KB]: 'height: 10px; width: 14px;',
        [FloatingWindowType.METRICS]: 'height: 13px; width: 14px;',
        [FloatingWindowType.ONBOARDING]: 'height: 14px; width: 16px;',
        [FloatingWindowType.TASK_CENTER]: 'height: 14px; width: 16px;'
      }
      return styles[window] || 'height: 14px; width: 14px;'
    },
    showListBox () {
      EventBus.$emit('toggleListBox', false)
    },
    goToKBFlows () {
      setTimeout(() => {
        const url = `${
          process.env.VUE_APP_MAKE_REDIRECTION_URL
        }?name=${encodeURIComponent(
          this.selectedAppName
        )}&email=${encodeURIComponent(this.userEmail)}&appUUID=${
          this.selectedAppID
        }&companyUUID=${this.selectedCompanyID}&userHost=${encodeURIComponent(
          this.firstUserHost
        )}&v6=${this.isCDPApp}`
        window.open(url, '_blank')
        this.$emit('cancel')
      }, 500)
    },
    goToKBFlowsV2 () {
      setTimeout(() => {
        const url = `${process.env.VUE_APP_FLOWS_V2_URL}/brand?appUUID=${
          this.selectedAppID ?? ''
        }&name=${encodeURIComponent(this.selectedAppName)}&companyUUID=${
          this.selectedCompanyID
        }&userHost=${encodeURIComponent(
          this.firstUserHost
        )}&email=${encodeURIComponent(
          this.userEmail
        )}&userName=${encodeURIComponent(this.userFullName)}&userUUID=${
          this.user.uuid
        }&token=${this.token}&language=${this.language}&categories=${(
          this.selectedApp?.categories || []
        ).join(',')}&defaultCountryCode=${this.selectedAppDefaultCountryCode}&role=${this.userRole}`
        window.open(url, '_blank')
        this.$emit('cancel')
      }, 500)
    },
    goToCampaigns () {
      this.hideVideoCampaign()
      this.setMaximazedWindow(FloatingWindowType.CAMPAIGNS)
    },
    goToMetrics () {
      const preUserParam: User = this.getUser

      const userParam = {
        customId: preUserParam.customId,
        email: preUserParam.email,
        isSuperAdmin: preUserParam.isSuperAdmin,
        lastName: preUserParam.lastName,
        name: preUserParam.name,
        photo: preUserParam.photo,
        role: this.getUserType || '',
        uuid: preUserParam.uuid || ''
      }
      const user = encodeURIComponent(JSON.stringify(userParam))
      const app = this.selectedApp?.uuid || ''
      const company = this.selectedCompany.uuid
      const url = `${process.env.VUE_APP_ANALYTICS_PROYECT}/?token=${this.getToken}&user=${user}&companyId=${company}&appId=${app}&appName=${this.selectedAppName}&lang=${this.language}`
      window.open(url, '_blank')
    },
    goToMetrics2 () {
      const url = `https://bi.keybe.app/login/?token=${this.getToken}`
      window.open(url, '_blank')
    },
    hideVideoCampaign () {
      this.campaignOpenedAt = moment().format('YYYY-MM-DDTHH:mm:ss')
    },
    showVideoCampaign () {
      if (this.campaignOpenedAt) {
        const now = moment()
        const openedAt = moment(this.campaignOpenedAt)
        const duration = moment.duration(now.diff(openedAt))
        const minutes = duration.asMinutes()
        if (minutes < 1) return
        else {
          this.campaignOpenedAt = null
        }
      }
      setTimeout(() => {
        const component = this.$refs['campaign-video']
        if (component && !component.isOpen) {
          this.$refs['campaign-video'].open()
        }
      }, 100)
    },
    startConfigOnboarding (): void {
      this.showConfigOnboarding = true
    },
    stopConfigOnboarding (): void {
      this.showConfigOnboarding = false
    },
    async getConfig (): Promise<void> {
      const config: VecindarioConfig = await VecindarioConfigUseCases.getConfig(
        this.vecindarioConfigController,
        {
          appUUID: this.selectedApp?.uuid,
          token: this.token
        }
      )

      this.vecindarioConfig = config
      if (this.vecindarioConfig && this.vecindarioConfig?.active) {
        await this.getScheduleUrl()
      }
    },
    async getScheduleUrl (): Promise<void> {
      const url: string = await VecindarioConfigUseCases.getCalendarUrl(
        this.vecindarioConfigController,
        {
          token: this.token,
          data: {
            slug: this.vecindarioConfig?.vecindarioSlug,
            email: this.user?.email
          }
        }
      )

      this.vecindarioScheduleUrl = url
    },
    openSchedule (): void {
      window.open(this.vecindarioScheduleUrl, '_blank')
    }
  },
  beforeDestroy () {
    if (this.intervalId) {
      clearInterval(this.intervalId)
    }
    EventBus.$off(
      'startConfigOnboarding--wizard',
      this.startConfigOnboarding.bind(this)
    )
    EventBus.$off(
      'closeConfigOnboarding--wizard',
      this.stopConfigOnboarding.bind(this)
    )
    VecindarioConfigUseCases.abortAll(this.vecindarioConfigController)
  },
  watch: {
    selectedAppID () {
      this.getFlowsActive()
    },
    inactiveApp: {
      handler (val, oldVal) {
        if (val === false && oldVal === true) {
          console.log('IS ACTIVE')
        }
      },
      immediate: true
    }
  }
}
