









import { EventBus } from '@/eventBus'
import { ConfigurationTabsEnum } from '@/modules/configuration/application/enums/ConfigurationTabsEnum'

export default {
  name: 'WebChannelConfig',
  props: {
    option: {
      type: String,
      required: true,
      default: ''
    }
  },
  methods: {
    goToConfigForm (option) {
      EventBus.$emit('setConfigurationTab', ConfigurationTabsEnum.WIDGET_TESSERACT)
    }
  }
}
