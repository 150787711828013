<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       fill="none"
       viewBox="0 0 13 10"><path :fill="color"
                                 d="M5.635 10c-1.334 0-2.668.002-4-.002-.522-.002-.976-.167-1.306-.594A1.605 1.605 0 0 1 0 8.392c0-1.438-.004-2.877.002-4.314.003-.729.36-1.26 1.009-1.496.188-.069.4-.1.6-.102 1.22-.01 2.441-.006 3.661-.004.339 0 .553.144.647.425a.61.61 0 0 1-.48.806 1.359 1.359 0 0 1-.22.016H1.662c-.315 0-.42.106-.42.426v4.17c0 .337.097.432.439.432h7.923c.332 0 .439-.105.439-.436 0-.772.002-1.543-.001-2.315-.001-.245.055-.46.273-.6a.63.63 0 0 1 .648-.025.525.525 0 0 1 .312.478c.008.606.007 1.212.008 1.817.001.236.004.471 0 .706-.008.519-.18.965-.596 1.294-.306.244-.67.328-1.052.328-1.334.002-2.668.001-4 .001h-.001Z"/><path :fill="color"
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                       d="m9.226 1.526 1.77 1.712c-.044.047-.07.08-.1.108-.903.884-1.8 1.773-2.712 2.647a1.774 1.774 0 0 1-.575.347c-.42.165-.854.297-1.282.441-.206.07-.4.049-.554-.112-.146-.15-.155-.331-.09-.523.148-.432.28-.87.446-1.295.076-.194.189-.39.332-.537.906-.93 1.826-1.846 2.74-2.767.015-.015.032-.028.025-.021ZM9.738 1.038c.243-.249.492-.538.775-.786.375-.33.826-.335 1.214-.02.215.175.412.377.586.593.288.36.24.853-.09 1.216-.24.264-.484.526-.738.803L9.738 1.038ZM3.122 6.249a.601.601 0 0 1-.627.61c-.364-.006-.616-.273-.608-.64a.599.599 0 0 1 .619-.605c.362.002.622.27.616.634v.001ZM5.004 6.241a.602.602 0 0 1-.618.62c-.362 0-.622-.268-.616-.634a.6.6 0 0 1 .624-.613.6.6 0 0 1 .611.627Z"/></svg>
</template>

<script>
export default {
  name: 'ExtradataIcon',
  props: {
    color: {
      type: String,
      default: '#fff'
    }
  }
}
</script>

<style>

</style>
