import { EventBus } from '@/eventBus'
import KeybeText from '@/modules/DesignSystem/infrastructure/components/KeybeText/KeybeText.vue'
import { currencyToUSD } from '@/utils/currencyToUSD'
import Loader from '@/shared/infrastructure/ui/Loader.vue'
import { debounce } from 'lodash'
import KeybeAutocomplete from '@/modules/DesignSystem/infrastructure/components/KeybeAutocomplete/KeybeAutocomplete/KeybeAutocomplete.vue'
import FeesUseCases from '@/modules/Fees/application/FeesUseCases'
import { dataToGetInvoices } from '@/modules/Fees/domain/FeesController'
import FeesController from '@/modules/Fees/infrastructure/controllers/Fees.controller'

export default {
  name: 'KBInvoicesList',
  components: {
    KeybeAutocomplete,
    KeybeText,
    Loader
  },
  props: {
  },
  data () {
    return {
      rerender: 0,
      totalItems: 0,
      itemsPerPage: 8,
      currentPage: 1,
      loading: false,
      status: null,
      itemStatus: [
        { id: 'PENDING', label: this.$t('pending') },
        { id: 'SENT', label: this.$t('sent') },
        { id: 'APPROVED', label: this.$t('approved') },
        { id: 'REJECTED', label: this.$t('rejected') },
        { id: 'PAID', label: this.$t('paid') }
      ],
      columns: [
        { key: 'id', label: 'id' },
        { key: 'plan.label', label: 'plan' },
        { key: 'appConfig.appName', label: 'brand' },
        { key: 'createdAt', label: 'createdAt', filter: 'date' },
        { key: 'dateIni', label: 'ingressDate' },
        { key: 'dateEnd', label: 'cutDate' },
        { key: 'status', label: 'status' },
        { key: 'total', label: 'invoice' },
        { key: 'payedTotal', label: 'biller' },
        { key: 'quickbooks', label: 'quickbooks' }
      ],
      data: [],
      searchQuery: '',
      feesController: new FeesController()
    }
  },
  mounted () {
    this.calculateSize(true)
  },
  beforeDestroy () {
    window.removeEventListener('resize', () => this.calculateSize())
  },
  computed: {
    totalPages () {
      return Math.ceil(this.totalItems / this.itemsPerPage)
    },
    searchPersons (search: string) {
      return this.itemStatus.find((o: { [key: string]: any }) =>
        o.name.toLowerCase().includes(search.toLowerCase())
      )
    }
  },
  async created () {
    await this.getCompany()
  },
  filters: {
    currencyUSD (value) {
      return currencyToUSD(value)
    }
  },
  watch: {
    status () {
      this.getCompany()
    }
  },
  methods: {
    debouncedSearch: debounce(async function () {
      this.getCompany()
    }, 300),
    async prevPage () {
      if (this.currentPage > 1) {
        this.currentPage--
        await this.getCompany()
      }
    },
    async nextPage () {
      if (this.currentPage * this.itemsPerPage < this.totalItems) {
        this.currentPage++
        await this.getCompany()
      }
    },
    getValue (item, key) {
      const keys = key.split('.')
      let value = item
      for (const k of keys) {
        if (value && k in value) {
          value = value[k]
        } else {
          return null // Retorna null o algún valor por defecto si la clave no existe
        }
      }
      return value
    },
    formatDate (dateString) {
      const date = new Date(dateString)
      const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`
      return formattedDate
    },
    async getCompany () {
      if (this.loading) return
      try {
        this.loading = true
        this.data = []
        const pagination = {
          page: this.currentPage,
          limit: this.itemsPerPage
        }
        const searchMain = this.searchQuery ? this.searchQuery.trim() : ''
        const data: dataToGetInvoices = {
          token: this.token,
          search: searchMain,
          pagination: pagination,
          status: this.status,
          filterDate: ''
        }
        const response = await FeesUseCases.getInvoices(this.feesController, data)

        if (response) {
          const { data } = response
          this.data = data
          // this.totalItems = count
        }
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
      }
    },
    calculateSize (fullscreen = false) {
      if (fullscreen) {
        const clientHeight = document.documentElement.clientHeight
        const clientWidth = document.documentElement.clientWidth
        document.documentElement.style.setProperty(
          '--floating-screen-height',
          `${clientHeight - 30}px`
        )
        document.documentElement.style.setProperty(
          '--floating-screen-width',
          `${clientWidth - 50}px`
        )

        // set top and left to 0
        const floatingWindow = document.getElementById('KeybeFloatingBox')
        floatingWindow.style.top = '0px'
        floatingWindow.style.left = '0px'

        EventBus.$emit('FloatingWindow-resize')
        return
      }

      const clientHeight = document.documentElement.clientHeight
      this.totalHeight = clientHeight * 0.8097
      this.heightWithoutHeader = clientHeight * 0.8097 - 30
      document.documentElement.style.setProperty(
        '--floating-screen-height',
        `${this.heightWithoutHeader}px`
      )

      const clientWidth = document.documentElement.clientWidth
      this.width = clientWidth * 0.8
      document.documentElement.style.setProperty(
        '--floating-screen-width',
        `${this.width}px`
      )

      EventBus.$emit('FloatingWindow-resize')
    }
  }
}
