import axios from 'axios'
import store from '@/store/index'
import { RestResponseFactory } from '@/models/apis/RestResponse'

export default class TemplateService {
  static async getTemplates (pagination: { page: any; sortBy: any; descending: any; itemsPerPage: any }, search = '') {
    try {
      const token = store.getters['UserStore/getV4Token']
      const companyId = store.getters['CompanyStore/getSelectedCompany'].uuid
      const appId = store.getters['AppStore/getSelectedApp'].uuid
      const response = await axios.get(
        `${process.env.VUE_APP_BASE_URL}/companies/${companyId}/apps/${appId}/email/templates`,
        {
          headers: {
            Authorization: 'Bearer ' + token
          },
          params: {
            page: pagination.page || 1,
            sortBy: pagination.sortBy || '',
            descending: pagination.descending ?? '',
            limit: pagination.itemsPerPage || 200,
            name: search
          }
        }
      )
      return new RestResponseFactory().process({ response }).data.templates
    } catch (error) {
      return new RestResponseFactory().process({ response: { status: 500 } })
    }
  }

  static async getTemplateById (templateId: any) {
    try {
      const token = store.getters['UserStore/getV4Token']
      const companyId = store.getters['CompanyStore/getSelectedCompany'].uuid
      const appId = store.getters['AppStore/getSelectedApp'].uuid
      const response = await axios.get(
        `${process.env.VUE_APP_BASE_URL}/companies/${companyId}/apps/${appId}/email/templates/${templateId}`,
        {
          headers: {
            Authorization: 'Bearer ' + token
          }
        }
      )
      return new RestResponseFactory().process({ response }).data.template
    } catch (error) {
      return new RestResponseFactory().process({ response: { status: 500 } })
    }
  }

  static async updateTemplate (data: any) {
    try {
      const token = store.getters['UserStore/getV4Token']
      const companyId = store.getters['CompanyStore/getSelectedCompany'].uuid
      const appId = store.getters['AppStore/getSelectedApp'].uuid
      const { templateId } = data
      const response = await axios.put(
        `${process.env.VUE_APP_BASE_URL}/companies/${companyId}/apps/${appId}/email/templates/${templateId}`,
        data,
        {
          headers: {
            Authorization: 'Bearer ' + token
          }
        }
      )
      console.log('updateTemplate', response)
      return new RestResponseFactory().process({ response }).data
    } catch (error) {
      return new RestResponseFactory().process({ response: { status: 500 } })
    }
  }

  static async saveTemplate (data: any) {
    try {
      const token = store.getters['UserStore/getV4Token']
      const companyId = store.getters['CompanyStore/getSelectedCompany'].uuid
      const appId = store.getters['AppStore/getSelectedApp'].uuid
      console.log('saveTemplates', data)
      const response = await axios.post(
        `${process.env.VUE_APP_BASE_URL}/companies/${companyId}/apps/${appId}/email/templates`,
        data,
        {
          headers: {
            Authorization: 'Bearer ' + token
          }
        }
      )
      console.log('updateTemplate', response)
      return new RestResponseFactory().process({ response }).data
    } catch (error) {
      return new RestResponseFactory().process({ response: { status: 500 } })
    }
  }

  static async getTemplatesV6 (pagination: { page: any; sortBy: any; descending: any; itemsPerPage: any }, search = '') {
    try {
      const token = store.getters['UserStore/getV4Token']
      const companyId = store.getters['CompanyStore/getSelectedCompany'].uuid
      const appId = store.getters['AppStore/getSelectedApp'].uuid
      const response = await axios.get(
        `${process.env.VUE_APP_CAMPAIGNS_URL}/companies/${companyId}/apps/${appId}/email/templates`,
        {
          headers: {
            Authorization: 'Bearer ' + token
          },
          params: {
            page: pagination.page || 1,
            sortBy: pagination.sortBy || '',
            descending: pagination.descending ?? '',
            limit: pagination.itemsPerPage || 200,
            name: search
          }
        }
      )
      return new RestResponseFactory().process({ response }).data.templates
    } catch (error) {
      return new RestResponseFactory().process({ response: { status: 500 } })
    }
  }

  static async getTemplateByIdV6 (templateId: any) {
    try {
      const token = store.getters['UserStore/getV4Token']
      const companyId = store.getters['CompanyStore/getSelectedCompany'].uuid
      const appId = store.getters['AppStore/getSelectedApp'].uuid
      const response = await axios.get(
        `${process.env.VUE_APP_CAMPAIGNS_URL}/companies/${companyId}/apps/${appId}/email/templates/${templateId}`,
        {
          headers: {
            Authorization: 'Bearer ' + token
          }
        }
      )
      return new RestResponseFactory().process({ response }).data.template
    } catch (error) {
      return new RestResponseFactory().process({ response: { status: 500 } })
    }
  }

  static async updateTemplateV6 (data: any) {
    try {
      const token = store.getters['UserStore/getV4Token']
      const companyId = store.getters['CompanyStore/getSelectedCompany'].uuid
      const appId = store.getters['AppStore/getSelectedApp'].uuid
      const { templateId } = data
      const response = await axios.put(
        `${process.env.VUE_APP_CAMPAIGNS_URL}/companies/${companyId}/apps/${appId}/email/templates/${templateId}`,
        data,
        {
          headers: {
            Authorization: 'Bearer ' + token
          }
        }
      )
      console.log('updateTemplate', response)
      return new RestResponseFactory().process({ response }).data
    } catch (error) {
      return new RestResponseFactory().process({ response: { status: 500 } })
    }
  }

  static async saveTemplateV6 (data: any) {
    try {
      const token = store.getters['UserStore/getV4Token']
      const companyId = store.getters['CompanyStore/getSelectedCompany'].uuid
      const appId = store.getters['AppStore/getSelectedApp'].uuid
      console.log('saveTemplates', data)
      const response = await axios.post(
        `${process.env.VUE_APP_CAMPAIGNS_URL}/companies/${companyId}/apps/${appId}/email/templates`,
        data,
        {
          headers: {
            Authorization: 'Bearer ' + token
          }
        }
      )
      console.log('updateTemplate', response)
      return new RestResponseFactory().process({ response }).data
    } catch (error) {
      return new RestResponseFactory().process({ response: { status: 500 } })
    }
  }
}
