export enum SegmentType {
  Static = 'static',
  Dynamic = 'dynamic'
}

export interface Segment {
  totalUsers: Number,
  name: String,
  status: String,
  type: String,
  query: String,
  importedFrom: String,
  _id: String,
  appName: String,
  appUUID: String,
  companyId: String,
  companyName: String,
  userId: String,
  userIdHash: String,
  createdAt: String,
  updatedAt: String,
  id: String
}
