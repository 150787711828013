<template>
  <div class="OnboardingTeam">
    <OnboardingTeamUpdateUser @back="$emit('back')"/>
    <!-- TO DO: must have Invite your team section-->
    <!--<div class="OnboardingTeam__item">
      <div class="OnboardingTeam__item--header"
           @click="toggleTab(TabsType.YOU)">
        <h2>{{$t('yourData')}}</h2>
        <span class="mdi"
              :class="{
                'mdi-menu-down': isOpen(TabsType.YOU),
                'mdi-menu-right': !isOpen(TabsType.YOU)
              }"/>
      </div>
      <v-expand-transition>
        <OnboardingTeamUpdateUser v-if="isOpen(TabsType.YOU)"/>
      </v-expand-transition>
    </div>
    <div class="OnboardingTeam__item">
      <div class="OnboardingTeam__item--header"
           @click="toggleTab(TabsType.TEAM)">
        <h2>{{$t('yourTeam')}}</h2>
        <span class="mdi"
              :class="{
                'mdi-menu-down': isOpen(TabsType.TEAM),
                'mdi-menu-right': !isOpen(TabsType.TEAM)
              }"/>
      </div>
      <v-expand-transition>
        <OnboardingTeamInviteTeam v-if="isOpen(TabsType.TEAM)"/>
      </v-expand-transition>
    </div>-->
  </div>
</template>

<script>
import OnboardingTeamUpdateUser from '@/modules/onboarding/infrastructure/ui/components/team/OnboardingTeamUpdateUser.vue'

const TabsType = {
  YOU: 'you',
  TEAM: 'team'
}
export default {
  name: 'OnboardingTeam',
  components: {
    OnboardingTeamUpdateUser
    // OnboardingTeamInviteTeam
  },
  data () {
    return {
      TabsType,
      selectedTab: ''
    }
  },
  methods: {
    isOpen (type) {
      return this.selectedTab === type
    },
    toggleTab (type) {
      if (this.selectedTab === type) this.selectedTab = ''
      else this.selectedTab = type
    }
  }
}
</script>

<style scoped lang="scss">
  .OnboardingTeam {
    padding: 0 120px;
    &__item {
      &--header {
        display: flex;
        cursor: pointer;
        align-items: center;
        justify-content: space-between;
        padding: 30px 50px;
        border-top: 0.5px solid rgba(136, 152, 169, 0.2);
        h2 {
          font-style: normal;
          font-weight: 600 !important;
          font-size: 18px !important;
        }
      }
    }
  }
</style>
