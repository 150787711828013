<template>
  <svg :width="width"
       :height="height"
       viewBox="0 0 7 11"
       fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <path d="M2.44908 1.03125C2.44908 0.757746 2.55968 0.495443 2.75657 0.302046C2.95345 0.108649 3.22047 0 3.49891 0C3.77734 0 4.04437 0.108649 4.24125 0.302046C4.43813 0.495443 4.54873 0.757746 4.54873 1.03125C4.54873 1.30475 4.43813 1.56706 4.24125 1.76045C4.04437 1.95385 3.77734 2.0625 3.49891 2.0625C3.22047 2.0625 2.95345 1.95385 2.75657 1.76045C2.55968 1.56706 2.44908 1.30475 2.44908 1.03125ZM3.32394 7.5625V10.3125C3.32394 10.6928 3.01117 11 2.62405 11C2.23693 11 1.92416 10.6928 1.92416 10.3125V5.51934L1.29864 6.54199C1.09961 6.86641 0.668744 6.97168 0.338486 6.77617C0.00822752 6.58066 -0.0989424 6.15742 0.100088 5.83301L1.37519 3.74902C1.75575 3.12812 2.43814 2.74785 3.17521 2.74785H3.82479C4.56186 2.74785 5.24425 3.12812 5.62481 3.74902L6.89991 5.83301C7.09894 6.15742 6.99177 6.58066 6.66151 6.77617C6.33126 6.97168 5.90039 6.86641 5.70136 6.54199L5.07365 5.51934V10.3125C5.07365 10.6928 4.76089 11 4.37376 11C3.98664 11 3.67388 10.6928 3.67388 10.3125V7.5625H3.32394Z"
          :fill="color"/>
  </svg>
</template>
<script>
export default {
  name: 'PrimaryIcon',
  props: {
    width: {
      type: Number,
      default: 7
    },
    height: {
      type: Number,
      default: 11
    },
    color: {
      type: String,
      default: '#B6BEC7'
    }
  }
}
</script>
