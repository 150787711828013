import { RestResponseFactory } from '@/models/apis/RestResponse'
import KeybeRepository from '@/shared/infrastructure/repositories/KeybeRepository'
import axios from 'axios'
import {
  dataToSyncWithShopify,
  dataToGetProductVariations,
  dataToGetProductsPaginator,
  dataToGenerateLongDescription,
  dataToArchiveProducts,
  dataToDeleteProducts,
  dataToExportCatalogCSV,
  dataToUploadProductPDFs
} from '@/modules/catalog/domain/models/ProductsController'
import { RestResponse } from '@/shared/infrastructure/http/RestResponse'

import { HeadersBuilder } from '@/shared/infrastructure/http/HeadersBuilder'
import { getOptions, nonGetOptions } from '@/shared/infrastructure/http/HttpClient'
import { GenericResponse } from '@/models/apis/GenericResponse'

export default class ProductRepository extends KeybeRepository {
    getProductsAbortController: AbortController

    constructor () {
      super()

      this.getProductsAbortController = new AbortController()
    }

    async getProducts (data: dataToGetProductsPaginator): Promise<GenericResponse> {
      const { appUUID, limit, category, product } = data

      let url = `${process.env.VUE_APP_MICROSITES_URL}/microsites/product/all/${appUUID}`
      if (data?.product) {
        url = url.concat(`?product=${product}`)
      }
      if (data?.category) {
        url = url.concat(`?category=${category}`)
      }
      if (data.getArchived) {
        url = url.concat(`?getArchived=${data.getArchived}`)
      }
      const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
      const query: {
        limit: number,
        nextProduct?: any,
        prevProduct?: any,
        product?: string,
        getUncategorized?: boolean,
      } = {
        limit
      }

      if (data.nextProduct) query.nextProduct = data.nextProduct
      if (data.prevProduct) query.prevProduct = data.prevProduct
      if (data.getUncategorized) query.getUncategorized = data.getUncategorized
      const requestOptions: nonGetOptions = {
        headers,
        data: query,
        url
      }

      return await this.client.post(requestOptions)
    }

    async getProductVariations (data: dataToGetProductVariations): Promise<GenericResponse> {
      const { appUUID, productId } = data
      const url = `${process.env.VUE_APP_MICROSITES_URL}/microsites/product/variation/${appUUID}/${productId}`

      const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
      const requestOptions: getOptions = {
        headers,
        abortController: this.getProductsAbortController,
        url
      }

      return this.client.get(requestOptions)
    }

    // async getProducts ({
    //   token,
    //   appUUID
    // }) {
    //   try {
    //     const response = await axios.get(
    //             `${process.env.VUE_APP_MICROSITES_URL}/microsites/product/all/${appUUID}`,
    //             {
    //               headers: {
    //                 Authorization: 'Bearer ' + token
    //               }
    //             }
    //     )
    //     return new RestResponseFactory().process({ response })
    //   } catch (e) {
    //     return new RestResponseFactory().process({ response: { status: 500, message: e?.response?.data?.error || '' } })
    //   }
    // }

    async createProduct ({
      token,
      product
    }) {
      try {
        const response = await axios.post(
                `${process.env.VUE_APP_MICROSITES_URL}/microsites/product`,
                product,
                {
                  headers: {
                    Authorization: 'Bearer ' + token
                  }
                }
        )
        return new RestResponseFactory().process({ response })
      } catch (e) {
        return new RestResponseFactory().process({ response: { status: 500, message: e?.response?.data?.error || '' } })
      }
    }

    async updateProduct ({
      token,
      product
    }) {
      try {
        const response = await axios.put(
                `${process.env.VUE_APP_MICROSITES_URL}/microsites/product/update`,
                product,
                {
                  headers: {
                    Authorization: 'Bearer ' + token
                  }
                }
        )
        return new RestResponseFactory().process({ response })
      } catch (e) {
        return new RestResponseFactory().process({ response: { status: 500 } })
      }
    }

    async changeProductStatus ({ id, status, token, appUUID }) {
      try {
        const response = await axios.get(
                `${process.env.VUE_APP_MICROSITES_URL}/microsites/product/published/${appUUID}/${id}/${status}`,
                {
                  headers: {
                    Authorization: 'Bearer ' + token
                  }
                }
        )
        return new RestResponseFactory().process({ response })
      } catch (e) {
        return new RestResponseFactory().process({ response: { status: 500 } })
      }
    }

    async deleteProduct ({
      token,
      appUUID,
      id
    }) {
      try {
        const response = await axios.delete(
                `${process.env.VUE_APP_MICROSITES_URL}/microsites/product/${appUUID}/${id}`,
                {
                  headers: {
                    Authorization: 'Bearer ' + token
                  }
                }
        )
        return new RestResponseFactory().process({ response })
      } catch (e) {
        return new RestResponseFactory().process({ response: { status: 500 } })
      }
    }

    async syncWithShopify (data: dataToSyncWithShopify): Promise<RestResponse> {
      const { token, appUUID, shopifyDomain, apiKey, updateIfExists, onlyActive } = data
      try {
        const response = await axios.post(
                `${process.env.VUE_APP_MICROSITES_URL}/microsites/product/import/${appUUID}/shopify`, {
                  shopifyDomain,
                  apiKey,
                  updateIfExists,
                  onlyActive
                },
                {
                  headers: {
                    Authorization: 'Bearer ' + token
                  }
                }
        )
        return new RestResponseFactory().process({ response })
      } catch (e) {
        return new RestResponseFactory().process({ response: { status: 500, message: e.message || '' } })
      }
    }

    async generateLongDescription (data: dataToGenerateLongDescription): Promise<GenericResponse> {
      const { longDescription, appId, productId } = data
      const url = `${process.env.VUE_APP_MICROSITES_URL}/microsites/product/description/${appId}/${productId}`
      const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
      const requestOptions: nonGetOptions = {
        headers,
        data: {
          longDescription: longDescription
        },
        url,
        abortController: this.getProductsAbortController
      }
      return await this.client.post(requestOptions)
    }

    async getDiscountTypes (token: string): Promise<GenericResponse> {
      const url = `${process.env.VUE_APP_API_GW_STORE}/items/discount/types`
      const headers = HeadersBuilder.buildSimpleAutorizationHeader({ token })
      const requestOptions: getOptions = {
        headers,
        url
      }

      return this.client.get(requestOptions)
    }

    async archiveProducts (data: dataToArchiveProducts): Promise<any> {
      const { appId, productIds, type } = data
      const url = `${process.env.VUE_APP_MICROSITES_URL}/microsites/product/batch/archive/${appId}?action=${type}`
      const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
      const response = await fetch(url, {
        method: 'PUT',
        body: JSON.stringify({ productIds: productIds }),
        headers: headers || undefined,
        signal: this.getProductsAbortController?.signal
      })
      const parsedResponse = await response.json()
      return parsedResponse
    }

    async deleteProducts (data: dataToDeleteProducts): Promise<any> {
      const { appId, productIds } = data
      const url = `${process.env.VUE_APP_MICROSITES_URL}/microsites/product/batch/delete/${appId}`
      const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
      const response = await fetch(url, {
        method: 'DELETE',
        body: JSON.stringify({ productIds: productIds }),
        headers: headers || undefined,
        signal: this.getProductsAbortController?.signal
      })
      const parsedResponse = await response.json()
      return parsedResponse
    }

    async exportCatalogCSV (data: dataToExportCatalogCSV): Promise<any> {
      const { appId } = data
      const url = `${process.env.VUE_APP_MICROSITES_URL}/microsites/product/export/${appId}`
      const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
      const response = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(data.data),
        headers: headers || undefined,
        signal: this.getProductsAbortController?.signal
      })
      const parsedResponse = await response.json()
      return parsedResponse
    }

    async uploadProductPDFs (data: dataToUploadProductPDFs): Promise<any> {
      const { appId, productId, pdf } = data
      const url = `${process.env.VUE_APP_MICROSITES_URL}/microsites/product/pdf/${appId}/${productId}`
      const headers = HeadersBuilder.buildFormDataAutorizationHeader(data)
      const formData = new FormData()
      formData.append('file', pdf.file)
      formData.append('description', pdf.description)
      const response = await fetch(url, {
        method: 'POST',
        body: formData,
        headers: headers || undefined
      })
      const parsedResponse = await response.json()
      return parsedResponse
    }

    abortGetProducts () {
      this.getProductsAbortController.abort()
      this.getProductsAbortController = new AbortController()
    }

    abortAll () {
      this.abortGetProducts()
      // TODO: ABORT REST
    }
}
