import { EventBus } from '@/eventBus'
import { BikyConfigUseCases } from '@/modules/bky/application/BikyConfigUseCases'
import { BikyConfig, defaultConfig } from '@/modules/bky/domain/BikyConfig'
import BikyConfigHeader from '@/modules/bky/infrastructure/ui/Config/BikyConfigHeader/BikyConfigHeader.vue'
import KBSwitch from '@/shared/infrastructure/ui/inputs/select/KBSwitch.vue'
import { mapState } from 'vuex'
import BikyConfigController from '../../../controllers/BikyConfig.controller'
import BikyStoreController from '../../../persistence/BikyStore.controller'

export default {
  name: 'BikyConfigEnabled',
  components: {
    BikyConfigHeader,
    KBSwitch
  },
  data () {
    return {
      loading: false,
      basicConfig: {
        ...defaultConfig
      },
      bikyConfigController: new BikyConfigController(),
      active: false,
      lines: [],
      audioLimit: 2
    }
  },
  methods: {
    async getConfig (): Promise<void> {
      const config: BikyConfig | null = await BikyConfigUseCases.getBikyConfig(this.bikyConfigController, {
        appUUID: this.selectedApp?.uuid,
        token: this.token
      })

      if (!config) {
        this.basicConfig = {
          ...defaultConfig
        }
        return
      }

      this.basicConfig = {
        ...config
      }

      BikyStoreController.setConfig(config)

      this.active = this.basicConfig.active || false
      this.audioLimit = this.basicConfig.audioMessagesLimit || 2
      if (!this.basicConfig.lines) {
        this.lines = []
      } else {
        this.lines = [...this.basicConfig.lines]
      }
    },
    async saveConfig (): Promise<boolean> {
      if (!this.configHadChanged || this.loading) {
        return false
      }

      const newConfig = {
        active: this.active,
        lines: this.lines,
        audioMessagesLimit: this.audioLimit
      }

      this.$emit('start-loading')
      this.loading = true

      const updated = await BikyConfigUseCases.updateBikyConfig(this.bikyConfigController, {
        token: this.token,
        appUUID: this.selectedApp?.uuid,
        updatedBy: this.user?.userId,
        ...newConfig
      })

      if (!updated) {
        EventBus.$emit('toast', 'error', this.$t('errorUpdatingConfig'))
      }

      await this.getConfig()

      this.loading = false
      this.$emit('stop-loading')
    },
    isLineActive (line: string): boolean {
      return this.lines.includes(line)
    },
    toggleLine (line: string): void {
      const index = this.lines.indexOf(line)
      if (index > -1) {
        this.lines.splice(index, 1)
      } else {
        this.lines.push(line)
      }
    },
    decreaseAudioLimit (): void {
      if (this.audioLimit > 0) {
        this.audioLimit--
      }
    },
    increaseAudioLimit (): void {
      this.audioLimit++
    }
  },
  mounted () {
    this.getConfig()
    EventBus.$on('BikyConfig:save', this.saveConfig)
  },
  beforeDestroy () {
    EventBus.$off('BikyConfig:save', this.saveConfig)
  },
  computed: {
    ...mapState('AppStore', ['selectedApp']),
    ...mapState('UserStore', ['token', 'user']),
    ...mapState('ConversationStore', ['channels']),
    userHosts (): string[] {
      return this.channels?.whatsapp?.accounts?.map((a: any) => a.userHost) || []
    },
    configHadChanged (): boolean {
      const activeHasChanged = this.basicConfig.active !== this.active
      const baseLines = this.basicConfig?.lines || []
      const linesHasChanged = baseLines.length !== this.lines.length || baseLines.some((line, index) => line !== this.lines[index])
      const audioLimitHasChanged = this.basicConfig.audioMessagesLimit !== this.audioLimit
      return activeHasChanged || linesHasChanged || audioLimitHasChanged
    },
    showSaveButton (): boolean {
      return this.configHadChanged
    }
  },
  watch: {
    showSaveButton (value) {
      EventBus.$emit('BikyConfig:show-save-button', value)
    }
  }
}
