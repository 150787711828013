<template>
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 15 24"><path :fill="color" d="M8.193 16.365h-1.53v6.072c0 .166-.006.334-.037.496-.11.566-.55.908-1.11.882-.6-.028-.983-.389-1.043-.988a4.835 4.835 0 0 1-.018-.466c0-4.7-.002-9.401.007-14.102 0-.262-.074-.397-.312-.52C2.145 6.698.898 5.058.368 2.869a37.496 37.496 0 0 1-.344-1.517C-.136.537.527-.128 1.326.042c.505.109.708.507.843.945.233.763.383 1.557.681 2.292.768 1.898 2.623 3.056 4.67 2.993 2.403-.075 4.305-1.63 4.861-3.98.107-.453.2-.911.322-1.362.246-.921 1.201-1.225 1.853-.6a.97.97 0 0 1 .284.914c-.242 1.252-.491 2.503-1.14 3.63-.711 1.238-1.685 2.204-2.965 2.841-.26.13-.343.282-.341.568.008 4.7.007 9.402.006 14.102 0 .156 0 .313-.023.466-.088.608-.516.979-1.103.965-.619-.015-1.033-.39-1.062-1.03-.033-.684-.017-1.369-.018-2.054v-4.366l-.001-.001Z"/><path :fill="color" d="M5.209 2.177C5.22.935 6.149.004 7.415 0c1.236-.004 2.2.863 2.228 2.156.027 1.22-.968 2.215-2.194 2.23a2.201 2.201 0 0 1-2.24-2.209Z"/></svg>
</template>

<script>
export default {
  name: 'PeopleIcon',
  props: {
    color: {
      type: String,
      default: '#B6BEC7'
    }
  }
}
</script>

<style>

</style>
