import { mapState } from 'vuex'
import AppsUseCases from '@/modules/Apps/application/AppsUseCases'
import KBSwitch from '@/shared/infrastructure/ui/inputs/select/KBSwitch.vue'
import AppsController from '@/modules/Apps/infrastructure/controllers/Apps.controller'
import { EventBus } from '@/eventBus'
import EmitToast from '@/utils/EmitToast'
import { AppFields } from '@/modules/Apps/domain/Apps'
import { ActivityActionTypeEnum, ActivityLogModuleTypeEnum, dataToWriteActivityLog } from '@/modules/Config/ActivityLogs/domain/ActivityLogsController'
import { CDPFieldsEnums } from '@/modules/CDP/shared/contacts/domain/enums/CDPFieldsEnums'
import ActivityLogsUseCases from '@/modules/Config/ActivityLogs/application/ActivityLogsUseCases'
import ActivityLogsController from '@/modules/Config/ActivityLogs/infrastructure/controllers/ActivityLogs.controller'
export default {
  components: {
    KBSwitch
  },
  props: {
    fields: {
      type: Array
    },
    appFields: {
      type: Array
    }
  },
  computed: {
    ...mapState('AppStore', ['selectedApp']),
    ...mapState('UserStore', ['token', 'user', 'isUserReadonly'])
  },
  async mounted () {
    this.loading = true
    this.localFields = this.fields
    this.localFields.forEach((field: AppFields) => {
      field.active = true
      this.appFields.forEach((appField: AppFields) => {
        if (!this.locationFields.includes(appField.field) && field.field === appField.field && appField.hidden) {
          field.active = false
        }
      })
    })

    await this.checkLocationFields()
    this.loading = false
  },
  data () {
    return {
      localFields: [],
      appsController: new AppsController(),
      activityLogsController: new ActivityLogsController(),
      locationFields: [
        CDPFieldsEnums.COUNTRY,
        CDPFieldsEnums.PROVINCE,
        CDPFieldsEnums.CITY,
        CDPFieldsEnums.BIRTH_PLACE_COUNTRY,
        CDPFieldsEnums.BIRTH_PLACE_PROVINCE,
        CDPFieldsEnums.BIRTH_PLACE_CITY,
        CDPFieldsEnums.DEATH_PLACE_COUNTRY,
        CDPFieldsEnums.DEATH_PLACE_PROVINCE,
        CDPFieldsEnums.DEATH_PLACE_CITY,
        CDPFieldsEnums.PRINCIPAL_LOCATION,
        CDPFieldsEnums.BIRTH_PLACE,
        CDPFieldsEnums.DEATH_PLACE,
        CDPFieldsEnums.ESTABLISHMENT_PLACE
      ],
      loading: false
    }
  },
  methods: {
    checkLocationFields (): void {
      const principal = this.appFields.filter(
        (field: AppFields) =>
          field.field === CDPFieldsEnums.COUNTRY ||
          field.field === CDPFieldsEnums.PROVINCE ||
          field.field === CDPFieldsEnums.CITY
      )

      const allPrincipalAreHidden: boolean = principal.every((field: AppFields) => field.hidden)

      if (allPrincipalAreHidden) {
        this.localFields.forEach((field: AppFields) => {
          if (field.field === CDPFieldsEnums.PRINCIPAL_LOCATION) {
            field.active = false
          }
        })
      }

      const birthPlace = this.appFields.filter(
        (field: AppFields) =>
          field.field === CDPFieldsEnums.BIRTH_PLACE_COUNTRY ||
          field.field === CDPFieldsEnums.BIRTH_PLACE_PROVINCE ||
          field.field === CDPFieldsEnums.BIRTH_PLACE_CITY
      )

      const allBirthPlaceAreHidden: boolean = birthPlace.every((field: AppFields) => field.hidden)

      if (allBirthPlaceAreHidden) {
        this.localFields.forEach((field: AppFields) => {
          if (field.field === CDPFieldsEnums.BIRTH_PLACE) {
            field.active = false
          }
        })
      }

      const deathPlace = this.appFields.filter(
        (field: AppFields) =>
          field.field === CDPFieldsEnums.DEATH_PLACE_COUNTRY ||
          field.field === CDPFieldsEnums.DEATH_PLACE_PROVINCE ||
          field.field === CDPFieldsEnums.DEATH_PLACE_CITY
      )

      const allDeathPlaceAreHidden: boolean = deathPlace.every((field: AppFields) => field.hidden)

      if (allDeathPlaceAreHidden) {
        this.localFields.forEach((field: AppFields) => {
          if (field.field === CDPFieldsEnums.DEATH_PLACE) {
            field.active = false
          }
        })
      }

      const establishmentPlace = this.appFields.filter(
        (field: AppFields) =>
          field.field === CDPFieldsEnums.ESTABLISHMENT_COUNTRY ||
          field.field === CDPFieldsEnums.ESTABLISHMENT_PROVINCE ||
          field.field === CDPFieldsEnums.ESTABLISHMENT_CITY
      )

      const allEstablishmentPlaceAreHidden: boolean = establishmentPlace.every((field: AppFields) => field.hidden)

      if (allEstablishmentPlaceAreHidden) {
        this.localFields.forEach((field: AppFields) => {
          if (field.field === CDPFieldsEnums.ESTABLISHMENT_PLACE) {
            field.active = false
          }
        })
      }
    },
    async setLocationFieldStatus (index: number): Promise<void> {
      let locationFields = []
      switch (this.fields[index].field) {
        case CDPFieldsEnums.PRINCIPAL_LOCATION:
          locationFields = [
            CDPFieldsEnums.COUNTRY,
            CDPFieldsEnums.PROVINCE,
            CDPFieldsEnums.CITY
          ]
          break
        case CDPFieldsEnums.BIRTH_PLACE:
          locationFields = [
            CDPFieldsEnums.BIRTH_PLACE_COUNTRY,
            CDPFieldsEnums.BIRTH_PLACE_PROVINCE,
            CDPFieldsEnums.BIRTH_PLACE_CITY
          ]
          break
        case CDPFieldsEnums.DEATH_PLACE:
          locationFields = [
            CDPFieldsEnums.DEATH_PLACE_COUNTRY,
            CDPFieldsEnums.DEATH_PLACE_PROVINCE,
            CDPFieldsEnums.DEATH_PLACE_CITY
          ]
          break
        case CDPFieldsEnums.ESTABLISHMENT_PLACE:
          locationFields = [
            CDPFieldsEnums.ESTABLISHMENT_COUNTRY,
            CDPFieldsEnums.ESTABLISHMENT_PROVINCE,
            CDPFieldsEnums.ESTABLISHMENT_CITY
          ]
          break
      }

      locationFields.forEach(async (field: string) => {
        const data = {
          app: this.selectedApp?.uuid,
          token: this.token,
          updateInput: {
            field: field,
            hidden: !this.fields[index].active,
            order: this.fields[index].order
          }
        }

        const response = await AppsUseCases.hideField(this.appsController, data)
        if (response?.status) {
          this.writeActivityLog(this.fields[index])
        } else EmitToast.emitErrors(response)
      })
    },
    async setFieldStatus (index: number): Promise<void> {
      try {
        const locationFields: string[] = [
          CDPFieldsEnums.PRINCIPAL_LOCATION,
          CDPFieldsEnums.BIRTH_PLACE,
          CDPFieldsEnums.DEATH_PLACE,
          CDPFieldsEnums.ESTABLISHMENT_PLACE
        ]

        this.fields[index].active = !this.fields[index].active

        if (locationFields.includes(this.fields[index].field)) {
          this.setLocationFieldStatus(index)
          return
        }

        const data = {
          app: this.selectedApp?.uuid,
          token: this.token,
          updateInput: {
            field: this.fields[index].field,
            hidden: !this.fields[index].active,
            order: this.fields[index].order
          }
        }

        const response = await AppsUseCases.hideField(this.appsController, data)
        if (response?.status) {
          EventBus.$emit('toast', 'success', this.$t('fieldsUpdated'))
          this.writeActivityLog(this.fields[index])
        } else EmitToast.emitErrors(response)
      } catch (e) {
        console.error(e)
      }
    },
    async writeActivityLog (field: AppFields): Promise<void> {
      const data: dataToWriteActivityLog = {
        token: this.token,
        updateInput: {
          appUUID: this.selectedApp?.uuid,
          module: ActivityLogModuleTypeEnum.CDP,
          action: ActivityActionTypeEnum.CDP_FIELDS_UPDATE,
          user: {
            id: this.user.uuid,
            name: this.user.name + ' ' + this.user.lastName
          },
          payload: {
            field: CDPFieldsEnums.HIDDEN,
            newValue: !field.active ? 'active' : 'inactive',
            oldValue: field.active ? 'active' : 'inactive'
          }
        }
      }
      await ActivityLogsUseCases.writeActivityLog(this.activityLogsController, data)
    }
  }
}
