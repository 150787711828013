import {
  IImporterController,
  dataToCreateImporter,
  dataToDetectHeaders,
  dataToGetImports,
  dataToStartImporter,
  headerDetector,
  importer,
  impotersGetter
} from '../domain/ImporterController'

export default class ImporterUseCases {
  static createImporter (controller: IImporterController, data: dataToCreateImporter): Promise<importer> {
    return controller.createImporter(data)
  }

  static getImports (controller: IImporterController, data: dataToGetImports): Promise<impotersGetter> {
    return controller.getImports(data)
  }

  static startImport (controller: IImporterController, data: dataToStartImporter): Promise<importer> {
    return controller.startImport(data)
  }

  static headerDetect (controller: IImporterController, data: dataToDetectHeaders): Promise<headerDetector> {
    return controller.headerDetect(data)
  }
}
