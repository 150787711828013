import { mapState, mapActions } from 'vuex'
import { FloatingWindowType } from '@/shared/infrastructure/enums/Layout/FloatingWindowType'
import { BikyWidgetConfig } from '@/modules/bky/domain/BikyConfig'

export default {
  name: 'BikyWidget',
  async mounted () {
    this.loadKeybeScript()
    window.addEventListener('howToStart', this.howToStart)
  },
  computed: {
    ...mapState('UserStore', ['user', 'token']),
    ...mapState('AppStore', ['language'])
  },
  methods: {
    ...mapActions('FloatingWindowsStore', ['setMaximazedWindow']),
    loadKeybeScript () {
      const script: HTMLScriptElement = document.createElement('script')
      script.src = `${process.env.VUE_APP_STORAGE_URL}/keybejs/v6/${process.env.VUE_APP_KEYBEJS}`
      script.async = true
      script.onload = () => {
        this.setupChat()
      }
      document.body.appendChild(script)
    },
    setupChat () {
      const configChat: BikyWidgetConfig = {
        apiKey: process.env.VUE_APP_BIKY_API_KEY,
        user: {
          id: this.user.id,
          name: this.user.name,
          email: this.user.email,
          phone: this.user.phone,
          userToken: this.token,
          userId: this.user.userId
        },
        containerId: 'keybe-webchat',
        theme: this.$vuetify.theme.isDark ? 'dark' : 'light',
        language: this.language
      }
      // @ts-ignore
      window.keybe.loadBikyWidget(configChat)
    },
    howToStart () {
      this.setMaximazedWindow(FloatingWindowType.ONBOARDING)
    }
  },
  beforeDestroy () {
    window.removeEventListener('howToStart', this.howToStart)
  },
  watch: {
    '$vuetify.theme.isDark': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.loadKeybeScript()
      }
    },
    language: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.loadKeybeScript()
      }
    }
  }
}
