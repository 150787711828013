<template>
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 15 15"><rect width="13" height="13" x="1" y="1" :stroke="color" stroke-width="2" rx="3"/></svg>
</template>

<script>
export default {
  name: 'UncheckedBoxIcon',
  props: {
    color: {
      type: String,
      default: '#D5D5D5'
    }
  }

}
</script>

<style>

</style>
