


























import { mapGetters, mapState, mapActions } from 'vuex'
// TODO: migrate to new Structure
import ConversationsConfigController from '@/components/configuration/controllers/ConversationsConfigController'
import FacebookProvider from '@/components/configuration/Providers/FacebookProvider'

import AutoConnectConfig from '@/modules/configuration/infrastructure/ui/components/Channels/AutoConnectConfig/AutoConnectConfig.vue'
import SignInOnboardingTutorialBox from '@/modules/SignInOnboarding/infrastructure/components/SignInOnboardingTutorialBox/SignInOnboardingTutorialBox.vue'
import OnboardingController from '@/modules/SignInOnboarding/infrastructure/controllers/Onboarding.controller'
import OnboardingUseCases from '@/modules/SignInOnboarding/application/OnboardingUseCases'
export default {
  name: 'ChannelsConfig.vue',
  components: {
    AutoConnectConfig,
    SignInOnboardingTutorialBox
  },
  computed: {
    ...mapState('AppStore', ['selectedApp']),
    ...mapState('CompanyStore', ['selectedCompany']),
    ...mapGetters('UserStore', ['isSuperAdmin', 'isAdmin']),
    ...mapState('ConversationStore', ['channels'])
  },
  created () {
    FacebookProvider.setBusySDK(false)
    this.getChannels()
    this.getOnboardingStep()
  },
  data () {
    return {
      boxPosition: 'margin-top: 80px;margin-left: 390px',
      arrowPosition: 'bottom',
      headerText: this.$t('connectYourChannels'),
      subtitleText: this.$t('connectYourChannelTutorial'),
      showTutorial: true,
      onboardingStep: null,
      rerender: 0,
      onboardingController: new OnboardingController(),
      linkHelp: `${process.env.VUE_APP_DOCS_URL}/ayudas/configura/insignia-o-verificacion-de-whatsapp/`
    }
  },
  methods: {
    ...mapActions('OnboardingStore', ['setOnboardingStep']),
    closeTutorial (): void {
      this.showTutorial = false
      setTimeout(() => {
        this.getOnboardingStep()
      }, 400)
    },
    async getOnboardingStep (): Promise<void> {
      const step: number = await OnboardingUseCases.getOnboardingStep(this.onboardingController)
      this.onboardingStep = step
      if (this.onboardingStep === 9) {
        this.nextStep()
      }
      if (this.onboardingStep === 10) {
        setTimeout(() => {
          this.showTutorial = false
        }, 5000)
      }
      this.rerender++
    },
    async nextStep (): Promise<void> {
      // eslint-disable-next-line no-undef
      const newStep: number = +structuredClone(this.onboardingStep) + 1
      await OnboardingUseCases.setOnboardingStep(this.onboardingController, newStep)
      this.onboardingStep = newStep
      setTimeout(() => {
        this.showTutorial = false
      }, 5000)
    },
    async getChannels (force = false) {
      this.$emit('start-loading')
      if (!this.channels || Object.keys(this.channels).length === 0 || force) {
        await ConversationsConfigController.getChannels()
      }
      this.$emit('stop-loading')
    }
  },
  watch: {
    selectedCompany: {
      async handler () {
        await this.getChannels(true)
        this.whatsappConfig = false
      }
    }
  }
}
