export enum ExtradataType {
    Text = 'text',
    TextLong = 'textLong',
    Money = 'money',
    Numeric = 'numeric',
    Number = 'number',
    Email = 'email',
    Phone = 'phone',
    Radio = 'radio',
    DateTime = 'dateTime',
    Date = 'date',
    Checkbox = 'checkbox',
    Select = 'select',
    Country = 'country',
    Value = 'value',
    Readonly = 'readonly',
    Code = 'code',
    Multiple = 'multiple'
}
