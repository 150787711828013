import KeybeText from '@/modules/DesignSystem/infrastructure/components/KeybeText/KeybeText.vue'
import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import KBIconButton from '@/shared/infrastructure/ui/buttons/KBIconButton.vue'
import Colors from '@/utils/Colors'

export default {
  name: 'AddOptions',
  components: {
    KeybeText,
    KBIconButton
  },
  props: {
    field: {
      type: Object,
      required: true
    },
    fieldIndex: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      option: '',
      KeybeIconType,
      Colors
    }
  },
  methods: {
    addOption (): void {
      if (!this.options) this.options = []
      const found: boolean = this.options.find(op => op.name === this.option)
      if (!found) {
        this.options.push({
          name: this.option
        })
      }
      this.$emit('updateOptions', this.options)
      this.option = ''
    },
    removeOption (index: number): void {
      this.options.splice(index, 1)
      this.$emit('updateOptions', this.options)
    }
  }
}
