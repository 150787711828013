






















import BikyConfigSchedule from './BikyConfigSchedule'

export default BikyConfigSchedule
