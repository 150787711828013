<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       fill="none"
       viewBox="0 0 8 15"><path :fill="color"
                                d="M6.91 3.273V10.8c0 1.446-1.303 2.618-2.91 2.618-1.607 0-2.91-1.171-2.91-2.618V2.618c0-.903.815-1.636 1.82-1.636 1.003 0 1.817.733 1.817 1.636v6.873c0 .36-.327.654-.727.654-.4 0-.727-.294-.727-.654V3.273H2.182V9.49c0 .903.814 1.636 1.818 1.636s1.818-.733 1.818-1.636V2.618C5.818 1.172 4.516 0 2.91 0 1.302 0 0 1.172 0 2.618V10.8c0 1.99 1.79 3.6 4 3.6s4-1.61 4-3.6V3.273H6.91Z"/></svg>
</template>

<script>
export default {
  name: 'ClipIcon',
  props: {
    color: {
      type: String,
      default: '#B6BEC7'
    }
  }
}
</script>

<style>

</style>
