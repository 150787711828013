import {
  ITeamController,
  MemberGetter,
  dataToChangeReadonly,
  dataToCreateTeamMember,
  dataToDeleteTeamMember,
  dataToGetTeamMember,
  dataToGetTeamMembers,
  dataToGetTermsByTeamMember,
  dataToInviteTeamMember,
  dataToTransferOwnership,
  dataToUpdateTeamMember,
  dataToUpdateUserApps,
  teamMemberCreate,
  teamMemberGetter,
  teamMemberInvite
} from '../domain/TeamController'
import { TeamMember } from '@/modules/Config/TeamConfiguration/domain/models/TeamMember'
export default class TeamUseCases {
  static abortAll (controller: ITeamController) : void {
    controller.abortAll()
  }

  static getTeamMembers (controller: ITeamController, data: dataToGetTeamMembers) : Promise<teamMemberGetter> {
    return controller.getTeamMembers(data)
  }

  static getTeamMembersFilterBiky (controller: ITeamController, data: dataToGetTeamMembers) : Promise<teamMemberGetter> {
    return controller.getTeamMembersFilterBiky(data)
  }

  static inviteTeamMember (controller: ITeamController, data: dataToInviteTeamMember) : Promise<teamMemberInvite> {
    return controller.inviteTeamMember(data)
  }

  static createTeamMember (controller: ITeamController, data: dataToCreateTeamMember) : Promise<teamMemberCreate> {
    return controller.createTeamMember(data)
  }

  static deleteTeamMember (controller: ITeamController, data: dataToDeleteTeamMember) : Promise<teamMemberCreate> {
    return controller.deleteTeamMember(data)
  }

  static updateTeamMember (controller: ITeamController, data: dataToUpdateTeamMember) : Promise<teamMemberCreate> {
    return controller.updateTeamMember(data)
  }

  static transferOwnership (controller: ITeamController, data: dataToTransferOwnership) : Promise<teamMemberCreate> {
    return controller.transferOwnership(data)
  }

  static isUserBiky (controller: ITeamController, member: TeamMember) : boolean {
    return controller.isUserBiky(member)
  }

  static async changeReadonly (controller: ITeamController, data: dataToChangeReadonly) : Promise<teamMemberCreate> {
    return controller.changeReadonly(data)
  }

  static async updateUserApps (controller: ITeamController, data: dataToUpdateUserApps) : Promise<any> {
    return controller.updateUserApps(data)
  }

  static async getTeamMember (controller: ITeamController, data: dataToGetTeamMember) : Promise<MemberGetter> {
    return controller.getTeamMember(data)
  }

  static areTermsAcceptedByApp (controller: ITeamController, data: dataToGetTermsByTeamMember) : boolean {
    return controller.areTermsAcceptedByApp(data)
  }
}
