import KeybeText from '@/modules/DesignSystem/infrastructure/components/KeybeText/KeybeText.vue'
import KeybeButton from '@/modules/DesignSystem/infrastructure/components/KeybeButton/KeybeButton.vue'
import ChatConfigController from '@/modules/ChatConfig/infrastructure/ChatConfig.controller'
import { ChatConfig, FinishOptions } from '@/modules/ChatConfig/domain/ChatConfig'
import { DataToGetConfig, dataToDeleteFinishOptions, dataToUpdateFinishOptions } from '@/modules/ChatConfig/domain/ChatConfigController'
import { mapState } from 'vuex'
import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'
import KBButtonLoader from '@/shared/infrastructure/ui/progress/KBButtonLoader/KBButtonLoader.vue'
import SignInOnboardingTutorialBox from '@/modules/SignInOnboarding/infrastructure/components/SignInOnboardingTutorialBox/SignInOnboardingTutorialBox.vue'

import OnboardingUseCases from '@/modules/SignInOnboarding/application/OnboardingUseCases'
import OnboardingController from '@/modules/SignInOnboarding/infrastructure/controllers/Onboarding.controller'
import EmitToast from '@/utils/EmitToast'
import { ActivityLogModuleTypeEnum, ActivityActionTypeEnum, dataToWriteActivityLog, ActivitySubmoduleTypeEnum } from '../ActivityLogs/domain/ActivityLogsController'
import { CDPFieldsEnums } from '@/modules/CDP/shared/contacts/domain/enums/CDPFieldsEnums'
import ActivityLogsUseCases from '../ActivityLogs/application/ActivityLogsUseCases'
import ActivityLogsController from '../ActivityLogs/infrastructure/controllers/ActivityLogs.controller'
import Colors from '@/utils/Colors'
export default {
  components: {
    KeybeText,
    KeybeButton,
    KeybeIcon,
    KBButtonLoader,
    SignInOnboardingTutorialBox
  },
  computed: {
    ...mapState('AppStore', ['selectedApp']),
    ...mapState('UserStore', ['token', 'user', 'isUserReadonly'])
  },
  mounted () {
    this.getChatConfig()
    this.getOnboardingStep()
  },
  data () {
    return {
      typificationOptions: [],
      typificationObjects: [],
      loading: false,
      selectedEdit: null,
      selectedTag: '',
      newTag: '',
      rerender: 0,
      showTutorial: false,
      onboardingStep: null,
      ChatConfigController: new ChatConfigController(),
      onboardingController: new OnboardingController(),
      activityLogsController: new ActivityLogsController(),
      tagsUrl: `${process.env.VUE_APP_KEYBE_CO_URL}/docs/ayudas/chats/kb-smart-chat/#cmo-puedo-cerrar-una-conversacin`,
      KeybeIconType,
      Colors
    }
  },
  methods: {
    closeTutorial (): void {
      this.showTutorial = false
      setTimeout(() => {
        this.getOnboardingStep()
      }, 400)
    },
    async getOnboardingStep (): Promise<void> {
      const step: number = await OnboardingUseCases.getOnboardingStep(this.onboardingController)
      this.onboardingStep = step
      if (this.onboardingStep === 11) {
        this.nextStep()
      }
      if (this.onboardingStep === 12) {
        this.showTutorial = true
        this.rerender++
        setTimeout(() => {
          this.showTutorial = false
        }, 10000)
      }
      this.rerender++
    },
    async nextStep (): Promise<void> {
      // eslint-disable-next-line no-undef
      const newStep: number = +structuredClone(this.onboardingStep) + 1
      await OnboardingUseCases.setOnboardingStep(this.onboardingController, newStep)
      this.onboardingStep = newStep
      if (this.onboardingStep === 12) {
        this.showTutorial = true
        this.rerender++
        setTimeout(() => {
          this.showTutorial = false
        }, 10000)
      }
    },
    async getChatConfig (): Promise<void> {
      this.loading = true
      const data: DataToGetConfig = {
        appUUID: this.selectedApp?.uuid,
        token: this.token
      }
      const response: ChatConfig = await this.ChatConfigController.getConfig(data)
      if (response.finishOptions) {
        const labels: string[] = response.finishOptions.map((option: FinishOptions) => option.label)
        this.typificationObjects = response.finishOptions
        this.typificationOptions = labels
      }
      setTimeout(() => {
        this.loading = false
      }, 1000)
    },
    openEdit (index: number): void {
      this.selectedEdit = index
      this.selectedTag = this.typificationOptions[index]
    },
    tagExists (tags: string) {
      return this.typificationOptions.includes(tags)
    },
    async save (tag: string, update: boolean): Promise<void> {
      if (!tag) {
        EmitToast.emitErrors(this.$t('tagMustNotBeEmpty'))
        return
      }
      if (this.tagExists(tag)) {
        EmitToast.emitErrors(this.$t('thisTagExists'))
        return
      }
      this.loading = true
      const dataToUpdate: dataToUpdateFinishOptions = {
        appUUID: this.selectedApp?.uuid,
        label: tag
      }
      const response = await this.ChatConfigController.createFinishOptions(dataToUpdate)
      if (response?.data?._id) {
        if (update) await this.writeActivityLogUpdate()
        else await this.writeActivityLogCreate()
        this.getChatConfig()
      } else this.loading = false
      this.newTag = ''
      this.rerender++
    },
    async writeActivityLogCreate (): Promise<void> {
      const data: dataToWriteActivityLog = {
        token: this.token,
        updateInput: {
          appUUID: this.selectedApp?.uuid,
          module: ActivityLogModuleTypeEnum.CHAT,
          submodule: ActivitySubmoduleTypeEnum.TYPIFICATIONS,
          action: ActivityActionTypeEnum.TYPIFICATION_CREATE,
          user: {
            id: this.user.uuid,
            name: this.user.name + ' ' + this.user.lastName
          },
          payload: {
            field: CDPFieldsEnums.NAME,
            newValue: this.newTag,
            oldValue: ''
          }
        }
      }
      this.writeActivityLog(data)
    },
    async writeActivityLogUpdate (): Promise<void> {
      const data: dataToWriteActivityLog = {
        token: this.token,
        updateInput: {
          appUUID: this.selectedApp?.uuid,
          module: ActivityLogModuleTypeEnum.CHAT,
          submodule: ActivitySubmoduleTypeEnum.TYPIFICATIONS,
          action: ActivityActionTypeEnum.TYPIFICATION_UPDATE,
          user: {
            id: this.user.uuid,
            name: this.user.name + ' ' + this.user.lastName
          },
          payload: {
            field: CDPFieldsEnums.NAME,
            newValue: this.selectedTag,
            oldValue: this.typificationOptions[this.selectedEdit]
          }
        }
      }
      this.writeActivityLog(data)
    },
    async writeActivityLogDelete (name: string): Promise<void> {
      const data: dataToWriteActivityLog = {
        token: this.token,
        updateInput: {
          appUUID: this.selectedApp?.uuid,
          module: ActivityLogModuleTypeEnum.CHAT,
          submodule: ActivitySubmoduleTypeEnum.TYPIFICATIONS,
          action: ActivityActionTypeEnum.TYPIFICATION_DELETE,
          user: {
            id: this.user.uuid,
            name: this.user.name + ' ' + this.user.lastName
          },
          payload: {
            field: CDPFieldsEnums.NAME,
            newValue: name,
            oldValue: ''
          }
        }
      }
      this.writeActivityLog(data)
    },
    async writeActivityLog (data): Promise<void> {
      await ActivityLogsUseCases.writeActivityLog(this.activityLogsController, data)
    },
    async deleteOption (index: number) {
      this.loading = true
      const id: string = this.typificationObjects[index]?._id || ''

      const dataDelete: dataToDeleteFinishOptions = {
        appUUID: this.selectedApp?.uuid,
        optionId: id
      }

      await this.ChatConfigController.deleteFinishOptions(dataDelete)
      await this.writeActivityLogDelete(this.typificationOptions[index])
      this.getChatConfig()
    },
    async updateOption (index: number) {
      if (!this.selectedTag) {
        EmitToast.emitErrors(this.$t('tagMustNotBeEmpty'))
        return
      }
      if (this.tagExists(this.selectedTag)) {
        EmitToast.emitErrors(this.$t('thisTagExists'))
        return
      }
      this.loading = true
      await this.deleteOption(index)
      await this.save(this.selectedTag, true)
      this.selectedTag = ''
      this.selectedEdit = null
    }
  }
}
