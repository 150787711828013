






















































































import BikyConfigErrorsItem from './BikyConfigErrorsItem'

export default BikyConfigErrorsItem
