


















































import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import Colors from '@/shared/domain/colors/Color'
import AdvisersRepository from '@/modules/conversations/infrastructure/http/Advisers.repository'
import KeybeAvatar from '@/shared/infrastructure/ui/KeybeAvatar.vue'
import KBSwitch from '@/shared/infrastructure/ui/inputs/select/KBSwitch.vue'

export default {
  name: 'AdvisorConfigCard',
  props: {
    limit: {
      type: Number,
      required: true
    },
    adviser: {
      type: Object,
      required: true
    },
    search: {
      type: String,
      required: false
    }
  },
  components: {
    KeybeAvatar,
    KBSwitch
  },
  data () {
    return {
      loading: false,
      showConfig: false,
      awaitingUpdate: false,
      config: {
        isActiveForAutoassign: false,
        maxActiveConversationsNumber: 0,
        receiveRoomsEvenOfflineStatus: false,
        hasConversationsLimit: true,
        tags: [],
        groups: []
      },
      KeybeIconType,
      Colors
    }
  },
  created () {
    this.loading = true
    this.config.isActiveForAutoassign = this.adviser?.isActiveForAutoassign || false
    this.config.maxActiveConversationsNumber = this.adviser?.maxActiveConversationsNumber || 0
    this.config.receiveRoomsEvenOfflineStatus = this.adviser?.receiveRoomsEvenOfflineStatus || false
    this.config.tags = this.adviser?.tags || []
    this.config.groups = this.adviser?.groups || []

    if (this.config.maxActiveConversationsNumber === -1) {
      this.config.hasConversationsLimit = false
    }
    setTimeout(() => {
      this.loading = false
    }, 500)
  },
  methods: {
    getAdviserPhoto (adviser) {
      return adviser?.photo || adviser?.image || ''
    },
    getAdviserName (adviser) {
      if (adviser?.lastName) {
        return `${adviser?.name} ${adviser?.lastName}`
      }
      return adviser?.name || ''
    },
    handleConversationLimitChange () {
      this.config.hasConversationsLimit = this.config.maxActiveConversationsNumber > -1

      if (!this.awaitingUpdate) {
        this.awaitingUpdate = true
        setTimeout(() => {
          this.saveConfig()
          this.awaitingUpdate = false
        }, 800)
      }
    },
    handleLimitSwitchChange () {
      if (!this.config.hasConversationsLimit) {
        this.config.maxActiveConversationsNumber = -1
      } else {
        this.config.maxActiveConversationsNumber = 0
      }
      this.saveConfig()
    },
    async saveConfig () {
      if (this.loading) {
        return
      }
      const newAdviser = {
        ...this.adviser,
        isActiveForAutoassign: this.config.isActiveForAutoassign,
        maxActiveConversationsNumber: this.config.maxActiveConversationsNumber,
        receiveRoomsEvenOfflineStatus: this.config.receiveRoomsEvenOfflineStatus,
        tags: this.config.tags,
        groups: this.config.groups
      }
      const response = await AdvisersRepository.updateAdviser(newAdviser)
      if (response.status) {
        await this.$store.dispatch('AppStore/updateAdviser', newAdviser)
      }
    },
    removeTag (tag) {
      this.config.tags = this.config.tags.filter(t => t !== tag)
      this.saveConfig()
    },
    removeGroup (group) {
      this.config.groups = this.config.groups.filter(g => g !== group)
      this.saveConfig()
    }
  },
  watch: {
    limit (newLimit) {
      if (newLimit < this.config.maxActiveConversationsNumber) {
        this.config.maxActiveConversationsNumber = newLimit
        this.saveConfig()
      }
    },
    'adviser.tags' () {
      this.config.tags = this.adviser.tags
    },
    'adviser.groups' () {
      this.config.groups = this.adviser.groups
    },
    search () {
      this.showConfig = false
    }
  }
}
