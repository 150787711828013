<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       fill="none"
       viewBox="0 0 11 11"><path :fill="color"
                                 d="M2.377 1.14c.381-.31.901-.345 1.334-.134.072-.127.167-.242.286-.339C4.529.234 5.333.338 5.789.9l2.142 2.63.006-.076A1.198 1.198 0 0 1 9.3 2.344c.7.09 1.224.735 1.17 1.439l-.187 2.47a4.848 4.848 0 0 1-1.758 3.413l-.128.104-.12.098a.422.422 0 0 1-.073.05c-1.463 1.026-3.515.761-4.793-.622a.492.492 0 0 1-.064-.066L1.07 6.432c-.456-.561-.395-1.37.137-1.803a1.2 1.2 0 0 1 .267-.164l-.026-.032c-.457-.561-.395-1.37.137-1.803.119-.096.25-.166.39-.21a1.242 1.242 0 0 1 .402-1.28ZM3.86 2.472 3.86 2.47l-.414-.509a.32.32 0 0 0-.448-.058.32.32 0 0 0-.034.45l.412.508.002.002 1.76 2.162a.479.479 0 0 1-.052.676c-.2.163-.5.124-.672-.088l-1.76-2.161-.001-.002a.32.32 0 0 0-.448-.058.32.32 0 0 0-.035.45l.724.89h.001L3.93 6.005a.479.479 0 0 1-.051.676c-.2.164-.5.125-.672-.087l-.93-1.141V5.45a.32.32 0 0 0-.449-.058.32.32 0 0 0-.034.451L4.03 8.592c.01.008.018.019.028.027.953 1.067 2.519 1.27 3.613.468a.227.227 0 0 1 .031-.02l.074-.06.128-.104a3.94 3.94 0 0 0 1.43-2.773l.186-2.472a.342.342 0 0 0-.293-.36.3.3 0 0 0-.34.278l-.094 1.231a.45.45 0 0 1-.327.404.492.492 0 0 1-.51-.171L5.065 1.489a.32.32 0 0 0-.448-.059.319.319 0 0 0-.036.45l1.761 2.163a.479.479 0 0 1-.051.676c-.2.163-.5.124-.672-.087L3.86 2.472Z"/></svg>
</template>

<script>
export default {
  name: 'HandIcon',
  props: {
    color: {
      type: String,
      default: '#000'
    }
  }
}
</script>

<style>

</style>
