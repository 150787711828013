


















































































































import ChatFiltersConfigList from './ChatFiltersConfigList'

export default ChatFiltersConfigList
