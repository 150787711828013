





























































































import { formatCurrency } from '@/modules/consumptions/application/FormatCurrency'
import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import Colors from '@/shared/domain/colors/Color'
import { EventBus } from '@/eventBus'
import BillingService from '@/components/configuration/Services/BillingService'
import { mapState } from 'vuex'

import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'
import KBCashCard from '@/modules/consumptions/infrastructure/ui/components/KBCashCard.vue'

export default {
  name: 'PaymentMethods',
  components: {
    KeybeIcon,
    KBCashCard
  },
  props: {
    creditCards: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      KeybeIconType,
      Colors,
      selectedCards: []
    }
  },
  methods: {
    formatCurrency,
    getCardIcon (card) {
      const icons = {
        visa: KeybeIconType.VISA,
        mastercard: KeybeIconType.MASTER_CARD,
        masterCard: KeybeIconType.MASTER_CARD,
        amex: KeybeIconType.AMEX,
        diners: KeybeIconType.DINERS_CLUB
      }
      return icons[card.tokenResponse.brand.toLowerCase()]
    },
    isExpired (card) {
      const month = card.tokenResponse.expirationMonth
      const year = card.tokenResponse.expirationYear
      const date = new Date()
      const currentMonth = date.getMonth() + 1
      const currentYear = date.getFullYear()
      return currentYear > year || (currentYear === year && currentMonth > month)
    },
    isMain (card) {
      return card?.main
    },
    addCreditCard () {
      EventBus.$emit('addCreditCard')
    },
    addBalance () {
      EventBus.$emit('addBalance')
    },
    async setAsMain (card) {
      await BillingService.setCardAsMain(card)
      this.$emit('refreshCards')
    },
    async deleteCards () {
      const promises = this.selectedCards.map(card => BillingService.deleteCreditCard(card))
      await Promise.all(promises)
      this.$emit('refreshCards')
      this.selectedCards = []
    },
    isChecked (card) {
      const cardId = card?.id
      return this.selectedCards.some(selectedCard => selectedCard.id === cardId)
    },
    toggleCardSelection (card) {
      const cardId = card?.id
      if (this.isChecked(card)) {
        this.selectedCards = this.selectedCards.filter(selectedCard => selectedCard.id !== cardId)
      } else {
        this.selectedCards.push(card)
      }
    }
  },
  computed: {
    ...mapState('BillingStore', ['appConfig']),
    balanceComputed () {
      return this.appConfig?.balanceUSD || 0
    }
  }
}
