import { PartnersTabsEnum } from '@/modules/Partners/domain/ParnersTabsEnum'
import PartnersSidebar from '../PartnersSidebar/PartnersSidebar.vue'
import PartnersCommissionsContainer from '../PartnersCommissions/PartnersCommissionsContainer/PartnersCommissionsContainer.vue'
import { Component as VueComponent } from 'vue'
import PartnersContract from '../PartnersContract/PartnersContract.vue'
export default {
  components: {
    PartnersSidebar
  },
  computed: {
    selectedTabComponent (): VueComponent {
      const components: {[key: string]: VueComponent} = {
        [PartnersTabsEnum.GENERAL]: PartnersCommissionsContainer,
        [PartnersTabsEnum.CONTRACT]: PartnersContract
      }
      return components[this.selectedTab]
    }
  },
  data () {
    return {
      selectedTab: PartnersTabsEnum.GENERAL
    }
  },
  methods: {
    selectTab (tab: string): void {
      this.selectedTab = tab
    }
  }
}
