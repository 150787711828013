import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import Colors from '@/shared/domain/colors/Color'
import { EventBus } from '@/eventBus'
// TODO: Migrate to new structure
import UserService from '@/services/UserService'
import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'
import KBButton from '@/shared/infrastructure/ui/buttons/KBButton.vue'
import { CDPFieldsEnums } from '@/modules/CDP/shared/contacts/domain/enums/CDPFieldsEnums'
export default {
  name: 'ChangePassword',
  components: {
    KeybeIcon,
    KBButton
  },
  data () {
    return {
      Colors,
      KeybeIconType,
      actualPassword: '',
      newPassword: '',
      confirmNewPassword: '',
      showActualPassword: false,
      showNewPassword: false,
      showConfirmNewPassword: false,
      step: 1,
      loading: false,
      error: false,
      CDPFieldsEnums
    }
  },
  methods: {
    async savePassword (): Promise<void> {
      this.error = false
      if (this.loading) {
        return
      }
      if (!this.actualPassword && this.step === 1) {
        this.error = true
        return
      }
      if (!this.newPassword && this.step === 2) {
        this.error = true
        return
      }
      if (!this.confirmNewPassword && this.step === 3) {
        this.error = true
        return
      }
      if (this.step < 3) {
        this.step++
        return
      }

      if (this.newPassword !== this.confirmNewPassword) {
        EventBus.$emit('toast', 'error', this.$t('passwordChangeNotEqualMessage'))
        this.step = 2
        this.newPassword = ''
        this.confirmNewPassword = ''
        return
      }
      this.loading = true
      const response = await UserService.changePasswordLogged(this.actualPassword, this.newPassword)
      if (response.status) {
        EventBus.$emit('toast', 'success', this.$t('passwordChanged'))
        this.$emit('close')
      } else {
        this.step = 1
        this.actualPassword = ''
        this.newPassword = ''
        this.confirmNewPassword = ''
        EventBus.$emit('toast', 'error', this.$t('errorChangingPassword'))
      }
      this.loading = false
    }
  },
  computed: {
    buttonPlaceholder (): string {
      if (this.step < 3) {
        return this.$t('next')
      }
      return this.$t('change')
    }
  }
}
