








































































































































































































import SpacesConfig from './SpacesConfig'

export default SpacesConfig
