export const ExtensionStore = {
  namespaced: true,
  state: {
    integration_ksuid: ''

  },
  mutations: {
    SET_INTEGRATION_KSUID (state, integrationId: string) {
      state.integration_ksuid = integrationId
    }
  },
  getters: {
    getIntegrationId (state) {
      return state.integration_ksuid
    }
  }
}
