import { Message, MessageSendersEnum, MessageTypesEnum } from '@/modules/conversations/domain/models/Message/Message'
import { Room } from '@/modules/conversations/domain/models/Room/Room'
import { BikyAdviserEnum } from '@/modules/conversations/domain/enums/BikyAdviserEnum'

export default class BikyService {
  static allowedAppUUIDs = ['4xqa4b8khxrofef', 'j6a11lpv9y5qj', '3c1ds1lq76q00q', '3c1ds1lq76ty8k', '3if8lnn1l5bf1tae', 'd09y1lqct8iec', '4m3wtp1lndepg6o']
  static allowedUserHosts = ['+573104944299', '+573207925847', '+573207924574', '+573207925839']

  static isBikyApp (appUUID: string): boolean {
    return BikyService.allowedAppUUIDs.includes(appUUID)
  }

  static isBikyRoom (room: Room, lines: string[]): boolean {
    if (!lines.length) {
      return false
    }

    return lines.includes(room?.userHost)
  }

  static isBikyMessage (message: Message): boolean {
    return message?.sender === MessageSendersEnum.ADVISOR && message?.advisorId === BikyAdviserEnum.UserId
  }

  static hasPreviousUserMessage (message: Message, messages: Message[]): boolean {
    const allowedTypes = [MessageTypesEnum.AUDIO, MessageTypesEnum.TEXT, MessageTypesEnum.IMAGE, MessageTypesEnum.CHAT, MessageTypesEnum.PDF]
    const allowedSender = MessageSendersEnum.USER

    const messageIndex = messages.findIndex((m) => m._id === message._id)
    const previousMessages = messages.slice(0, messageIndex).reverse()

    const previousUserMessage = previousMessages.find((m) => m.sender === allowedSender)
    if (!previousUserMessage) {
      return false
    }

    return allowedTypes.includes(previousUserMessage.type)
  }

  static showBikyActions (room: Room, message: Message, messages: Message[], isBikyActive: boolean, bikyLines: string[], isBikyPlan: boolean): boolean {
    if (!isBikyActive && !isBikyPlan) {
      return false
    }
    const isBikyRoom = BikyService.isBikyRoom(room, bikyLines)
    const isBikyMessage = BikyService.isBikyMessage(message)
    return (isBikyRoom || isBikyPlan) && isBikyMessage && BikyService.hasPreviousUserMessage(message, messages)
  }
}
