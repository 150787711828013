import {
  ISegmentsController,
  dataToCreateSegment,
  dataToGetSegmentByName,
  dataToGetSegments,
  dataToUpdateDeleteSegment,
  segmentCreator,
  segmentGetter,
  segmentsGetter,
  dataToGetSegmentById
} from '../domain/SegmentsController'

export default class SegmentsUseCases {
  static getSegments (controller: ISegmentsController, data: dataToGetSegments): Promise<segmentsGetter> {
    return controller.getSegments(data)
  }

  static getSegmentsById (controller: ISegmentsController, data: dataToGetSegmentById): Promise<segmentGetter> {
    return controller.getSegmentsById(data)
  }

  static getSegmentByName (controller: ISegmentsController, data: dataToGetSegmentByName): Promise<segmentGetter> {
    return controller.getSegmentByName(data)
  }

  static createSegment (controller: ISegmentsController, data: dataToCreateSegment): Promise<segmentCreator> {
    return controller.createSegment(data)
  }

  static updateSegment (controller: ISegmentsController, data: dataToUpdateDeleteSegment): Promise<segmentCreator> {
    return controller.updateSegment(data)
  }

  static deleteSegment (controller: ISegmentsController, data: dataToUpdateDeleteSegment): Promise<segmentCreator> {
    return controller.deleteSegment(data)
  }
}
