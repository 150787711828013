import { debounce } from 'lodash'
import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import Colors from '@/shared/domain/colors/Color'
import { mapActions, mapGetters, mapState } from 'vuex'
import { EventBus } from '@/eventBus'
import { AdminTypes } from '@/components/configuration/administrators/enums/AdminTypes'
import moment from 'moment'

import KBAutocomplete from '@/shared/infrastructure/ui/inputs/select/KBAutocomplete.vue'
import KBSearch from '@/shared/infrastructure/ui/inputs/text/KBSearch.vue'
import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'
import KBDateRangeMenu from '@/shared/infrastructure/ui/KBDateRangeMenu.vue'
import { CDPFieldsEnums } from '@/modules/CDP/shared/contacts/domain/enums/CDPFieldsEnums'
export default {
  name: 'TasksHeader',
  components: {
    KBSearch,
    KeybeIcon,
    KBAutocomplete,
    KBDateRangeMenu
  },
  data () {
    return {
      searchBar: '',
      KeybeIconType,
      Colors,
      userSearch: 'mine',
      dateSelector: this.$t('noDateFilter'),
      currentSearchData: {},
      rerender: 0,
      CDPFieldsEnums
    }
  },
  computed: {
    ...mapState('CompanyStore', ['administrators']),
    ...mapState('UserStore', ['user', 'isUserReadonly']),
    ...mapGetters('UserStore', ['isAdmin', 'isSuperAdmin']),
    ...mapActions('AppStore', ['isCDPApp']),
    ...mapState('TasksStore', ['dateSearch', 'usersSearch', 'stringSearch']),
    adminsComputed () {
      return [
        {
          name: this.$t('all'),
          lastName: '',
          userId: 'all'
        },
        {
          name: this.$t('mine'),
          lastName: '',
          userId: 'mine'
        },
        ...this.administrators
      ]
    },
    isCoordinatorOrAbove () {
      return (
        this.user?.type === AdminTypes.COORDINATOR ||
        this.isAdmin ||
        this.isSuperAdmin
      )
    }
  },
  mounted () {
    // if (this.usersSearch) {
    //   this.userSearch = this.usersSearch
    // }
    // if (this.stringSearch) {
    //   this.searchBar = this.stringSearch
    // }
    // if (this.dateSearch.dateSelector) this.dateSelector = this.dateSearch.dateSelector
    this.rerender++
  },
  created () {
    if (this.usersSearch && typeof this.usersSearch === 'string') {
      this.userSearch = this.usersSearch
    }
    if (this.stringSearch) {
      this.searchBar = this.stringSearch
    }
    if (this.dateSearch) {
      this.dateSelector = this.dateSearch
    }
    setTimeout(() => {
      this.sendGetTasksEvent()
    }, 200)
  },
  methods: {
    ...mapActions('TasksStore', ['setFilters']),
    updateInput: debounce(function (e: { [key: string]: any }) {
      this.searchBar = e
      this.sendGetTasksEvent()
    }, 300),
    createTask () {
      if (this.isUserReadonly) return
      this.$emit('createTask')
    },
    changeView () {
      this.$emit('changeView')
    },
    async handleDateChange (data) {
      const { dateString, dateFrom, dateTo } = data
      this.dateSelector = dateString
      if (dateString === this.$t('noDateFilter')) {
        this.sendGetTasksEvent({
          dateInit: undefined,
          dateEnd: undefined
        })
        return
      }
      const beginingOfDateFrom = moment(dateFrom).startOf('day')
      const endOfDateTo = moment(dateTo).endOf('day')
      this.sendGetTasksEvent({
        dateInit: beginingOfDateFrom,
        dateEnd: endOfDateTo
      })
    },
    saveSearchParams (data) {
      const filters = {
        date: {
          ...data,
          dateSelector: this.dateSelector
        },
        searchBar: this.searchBar,
        userSearch: this.userSearch
      }
      this.setFilters(filters)
    },
    sendGetTasksEvent (data = {}) {
      this.setFilters({
        date: this.dateSelector,
        searchBar: this.searchBar,
        userSearch: this.userSearch
      })
      if (this.userSearch === 'mine') {
        this.currentSearchData = {
          ...this.currentSearchData,
          responsible: this.user?.userId || this.user?.uuid || '',
          search: this.searchBar,
          ...data
        }
        EventBus.$emit('TasksBoard:getTasks', this.currentSearchData)
        return
      }

      if (this.userSearch === 'all') {
        this.currentSearchData = {
          ...this.currentSearchData,
          search: this.searchBar,
          responsible: undefined,
          ...data
        }
        EventBus.$emit('TasksBoard:getTasks', this.currentSearchData)
        return
      }

      EventBus.$emit('TasksBoard:getTasks', {
        responsible: this.userSearch,
        search: this.searchBar,
        ...data
      })
    }
  },
  watch: {
    userSearch () {
      this.sendGetTasksEvent()
    }
  }
}
