<template>
  <footer class="LoginFooter">
    <div class="LoginFooter__logo">
      <KeybeIcon
        :icon="KeybeIconType.LOGO_V6"
      />
    </div>
    <div class="LoginFooter__terms">
      <span class="LoginFooter__terms--text">
        <a href="https://keybe.ai/"
           target="_blank">{{ $t('aboutKeybe') }}</a>
        -
        <a href="https://keybe.ai/legales/"
           target="_blank">{{ $t('termsAndConditions') }}</a>
      </span>
    </div>
  </footer>
</template>

<script>
import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'

// components
import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'

export default {
  name: 'LoginFooter',
  components: {
    KeybeIcon
  },
  data () {
    return {
      KeybeIconType
    }
  }
}
</script>

<style lang='scss'>
.LoginFooter {
  margin-top: auto;
  margin-bottom: 65px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 1;

  &__logo {
    height: 57px;
    width: 192px;
  }

  &__terms {
    margin-top: 16px;

    &--text {
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      line-height: 21px;

      a {
        text-decoration: none;
        transition: color 0.2s ease;
      }

      a:hover {
        color: $kb-green;
      }
    }
  }
}
</style>
