import { EventBus } from '@/eventBus'
import moment from 'moment'
import MetricsService from '@/modules/metrics/application/services/MetricsService'
import DateRange from '@/modules/metrics/infrastructure/ui/components/generic/DateRange.vue'
import MetricsCard from '@/modules/metrics/infrastructure/ui/components/metrics/MetricsCard.vue'
import TitleDetail from '@/modules/metrics/infrastructure/ui/components/generic/TitleDetail.vue'
import TrapexiumGraph from '@/modules/metrics/infrastructure/ui/components/sales/TrapexiumGraph.vue'
import KBDateRangeMenu from '@/shared/infrastructure/ui/KBDateRangeMenu.vue'
import KBSelectFlow from '@/shared/infrastructure/ui/KBSelectFlow.vue'
import NewDeals from '@/modules/metrics/infrastructure/ui/components/sales/NewDeals.vue'
import SalesTable from '@/modules/metrics/infrastructure/ui/components/sales/SalesTable.vue'
import SalesByAdvisor from '@/modules/metrics/infrastructure/ui/components/sales/SalesByAdvisor.vue'
import { mapGetters } from 'vuex'
import operations from '@/utils/operations'
import Loader from '@/shared/infrastructure/ui/Loader.vue'

const FORMAT_DATE = 'YYYY-MM-DD'

export default {
  name: 'SalesPage',
  components: {
    TitleDetail,
    MetricsCard,
    TrapexiumGraph,
    KBDateRangeMenu,
    KBSelectFlow,
    SalesTable,
    DateRange,
    NewDeals,
    SalesByAdvisor,
    Loader
  },
  data () {
    return {
      loading: false,
      loadingCall: false,
      dateFrom: null,
      dateTo: null,
      tableData: [],
      resultDate: null,
      selectedItem: null,
      idFlow: null,
      dataRequests: [],
      dataRequestGraph: {},
      firstPipeline: {},
      chartSingle: null,
      flowSelector: null,
      dateSelector: this.$t('thisMonth'),
      nameFlow: '',
      averageTicket: {
        value: '0',
        descipcion: this.$t('averageTicketMsg'),
        text: this.$t('averageTicket')
      },
      averageWon: {
        value: '0',
        descipcion: this.$t('averageWonMsg'),
        text: this.$t('winningPercentage')
      },
      dealClosed: {
        value: '',
        descipcion: this.$t('dealsClosedmsg'),
        text: this.$t('dealsClosed'),
        cash: true
      },
      dealBenchmark: {
        value: '',
        descipcion: this.$t('dealsBenchmarkmsg'),
        text: this.$t('dealsBenchmark')
      }
    }
  },
  computed: {
    ...mapGetters('AppStore', ['getSelectedApp']),
    appUUID () {
      return this.getSelectedApp.uuid
    },
    disabledCards () {
      const name = operations.checkTranslation(this.nameFlow)
      if (name === operations.checkTranslation('$funnel_car_new$') || name === operations.checkTranslation('$funnel_sales$')) {
        return true
      } else {
        return false
      }
    },
    flowFilter: {
      get () {
        return this.$store.state.MetricsStore.flowFilter || null
      },
      set (value) {
        this.$store.commit('MetricsStore/SET_EDITING_OBJECT', {
          key: 'flowFilter',
          value
        })
      }
    }
  },
  watch: {
    flowFilter: {
      handler: function (newVal, oldVal) {
        if (!this.loading) {
          if (newVal._id !== oldVal._id) {
            this.loading = true
            const { _id, name } = this.flowFilter
            this.idFlow = _id
            this.nameFlow = name
            this.callRequestSales()
            this.loading = false
          }
        }
      }
    },
    dateSelector () {
      if (this.dateSelector) {
        if (!this.loading) {
          this.loading = true
          if (this.dateSelector !== this.$t('custom')) {
            this.validateDate(this.dateSelector)
          }
          this.loading = false
        }
      }
    }
  },
  mounted () {
    this.validateDate(this.$t('thisMonth'))
    this.getPipeline()
    EventBus.$on('metricsDatePicker', this.datePickerListener.bind(this))
  },
  beforeDestroy () {
    EventBus.$off('metricsDatePicker', this.datePickerListener.bind(this))
  },
  methods: {
    formatName (name) {
      return operations.checkTranslation(name)
    },
    datePickerListener (datePicker: string[]): void {
      if (datePicker) {
        this.dateFrom = datePicker[0]
        this.dateTo = datePicker[1]
        this.validateDate(this.$t('custom'))
      }
    },
    validateDate (selectedItem) {
      switch (selectedItem) {
        case this.$t('thisWeek'): {
          const dateFrom = moment().startOf('week').format(FORMAT_DATE)
          const dateTo = moment().format(FORMAT_DATE)
          this.resultDate = { dateString: selectedItem, dateFrom, dateTo }
          this.callRequestSales({ dateString: selectedItem, dateFrom, dateTo })
          break
        }
        case this.$t('lastWeek'): {
          const dateFrom = moment()
            .startOf('week')
            .subtract(1, 'week')
            .format(FORMAT_DATE)
          const dateTo = moment()
            .startOf('week')
            .subtract(1, 'week')
            .endOf('week')
            .format(FORMAT_DATE)
          this.resultDate = { dateString: selectedItem, dateFrom, dateTo }
          this.callRequestSales({ dateString: selectedItem, dateFrom, dateTo })
          break
        }
        case this.$t('last2Week'): {
          const dateFrom = moment()
            .startOf('week')
            .subtract(1, 'week')
            .format(FORMAT_DATE)
          const dateTo = moment().format(FORMAT_DATE)
          this.resultDate = { dateString: selectedItem, dateFrom, dateTo }
          this.callRequestSales({ dateString: selectedItem, dateFrom, dateTo })
          break
        }
        case this.$t('last3Week'): {
          const dateFrom = moment()
            .startOf('week')
            .subtract(2, 'week')
            .format(FORMAT_DATE)
          const dateTo = moment().format(FORMAT_DATE)
          this.resultDate = { dateString: selectedItem, dateFrom, dateTo }
          this.callRequestSales({ dateString: selectedItem, dateFrom, dateTo })
          break
        }
        case this.$t('thisMonth'): {
          const dateFrom = moment().startOf('month').format(FORMAT_DATE)
          const dateTo = moment().format(FORMAT_DATE)
          this.resultDate = { dateString: selectedItem, dateFrom, dateTo }
          this.callRequestSales({ dateString: selectedItem, dateFrom, dateTo })
          break
        }
        case this.$t('lastMonth'): {
          const dateFrom = moment()
            .startOf('month')
            .subtract(1, 'month')
            .format(FORMAT_DATE)
          const dateTo = moment()
            .startOf('month')
            .subtract(1, 'month')
            .endOf('month')
            .format(FORMAT_DATE)
          this.resultDate = { dateString: selectedItem, dateFrom, dateTo }
          this.callRequestSales({ dateString: selectedItem, dateFrom, dateTo })
          break
        }
        case this.$t('last2Month'): {
          const dateFrom = moment()
            .startOf('month')
            .subtract(1, 'month')
            .format(FORMAT_DATE)
          const dateTo = moment().format(FORMAT_DATE)
          this.resultDate = { dateString: selectedItem, dateFrom, dateTo }
          this.callRequestSales({ dateString: selectedItem, dateFrom, dateTo })
          break
        }
        case this.$t('last3Month'): {
          const dateFrom = moment()
            .startOf('month')
            .subtract(2, 'month')
            .format(FORMAT_DATE)
          const dateTo = moment().format(FORMAT_DATE)
          this.resultDate = { dateString: selectedItem, dateFrom, dateTo }
          this.callRequestSales({ dateString: selectedItem, dateFrom, dateTo })
          break
        }
        case this.$t('last6Month'): {
          const dateFrom = moment()
            .startOf('month')
            .subtract(5, 'month')
            .format(FORMAT_DATE)
          const dateTo = moment().format(FORMAT_DATE)
          this.resultDate = { dateString: selectedItem, dateFrom, dateTo }
          this.callRequestSales({ dateString: selectedItem, dateFrom, dateTo })
          break
        }
        case this.$t('last12Month'): {
          const dateFrom = moment()
            .startOf('month')
            .subtract(11, 'month')
            .format(FORMAT_DATE)
          const dateTo = moment().format(FORMAT_DATE)
          this.resultDate = { dateString: selectedItem, dateFrom, dateTo }
          this.callRequestSales({ dateString: selectedItem, dateFrom, dateTo })
          break
        }
        case this.$t('custom'): {
          const dateFrom = moment(this.dateFrom).format(FORMAT_DATE)
          const dateTo = moment(this.dateTo).format(FORMAT_DATE)
          this.resultDate = { dateString: selectedItem, dateFrom, dateTo }
          this.callRequestSales({
            dateString: selectedItem,
            dateFrom,
            dateTo
          })
          break
        }
        case this.$t('noDateFilter'):
          break
        default:
          this.resultDate = { dateString: this.$t('thisMonth') }
          break
      }
    },
    async getPipeline () {
      const app = this.getSelectedApp?.uuid
      const { data } = await MetricsService.getAppFunnels(app)
      if (data) {
        this.firstPipeline = data[0]
        const { name, _id } = this.firstPipeline
        this.nameFlow = this.formatName(name)
        this.idFlow = _id
        this.flowFilter = { _id, name }
        this.callRequestSales()
      }
      this.loading = false
    },
    async getTableData (dataRange: any) {
      const { dateFrom, dateTo, pipelineId } = dataRange
      const response = await MetricsService.getSalesByUser({
        startDate: dateFrom,
        endDate: dateTo,
        pipelineId
      })
      if (response) {
        this.tableData = response.data
      }
    },
    async callRequestSales (dateRange: any = {}) {
      if (this.loadingCall) {
        return
      }
      if (!this.idFlow) {
        return
      }
      if (!this.resultDate) {
        return
      }
      try {
        this.loadingCall = true
        const { dateFrom, dateTo } = dateRange
        const params = {
          dateFrom: dateFrom || this.resultDate.dateFrom,
          dateTo: dateTo || this.resultDate.dateTo,
          pipelineId: this.idFlow
        }
        await this.getNewDeal(params)
        await this.getDealsClosed(params)
        await this.getTableData(params)
        await this.getPotencial(params)
        await this.getDataOldChart(params)
        this.loadingCall = false
      } catch (error) {
        this.loadingCall = false
      }
    },
    async getPotencial (dataRange) {
      this.loading = true
      const { dateFrom, dateTo, pipelineId } = dataRange
      const params = {
        periodStart: dateFrom,
        periodEnd: dateTo,
        pipelineId: pipelineId || null
      }
      try {
        const response: any = await MetricsService.getPotencialG(params)
        if (response) {
          const { data } = response
          this.averageTicket.value = data.ticket_avg
          this.averageWon.value = data.win_pct
          this.loading = false
        }
      } catch (err) {
        console.log(err)
      }
    },
    async getDataOldChart (dataRange) {
      try {
        const { dateFrom, dateTo, pipelineId } = dataRange
        const periodStart = dateFrom
        const periodEnd = dateTo
        const data = await MetricsService.getFunnelCRM({
          startDate: periodStart,
          endDate: periodEnd,
          pipelineId
        })
        if (data) {
          this.dataRequests = data.data
        }
      } catch (error) {
        console.log(error)
      }
    },
    async getNewDeal (dataRange) {
      const { dateFrom, dateTo, pipelineId } = dataRange
      const params = {
        periodStart: dateFrom,
        periodEnd: dateTo,
        pipelineId
      }
      try {
        const data = await MetricsService.getDeals(params)
        if (data) {
          this.processData(data.data)
          // this.loading = false
        } else {
          // this.loading = false
        }
      } catch (error) {
        // this.loading = false
      }
    },
    async getDealsClosed (dataRange) {
      const { dateFrom, dateTo, pipelineId } = dataRange
      const params = {
        startDate: dateFrom,
        endDate: dateTo,
        pipelineId
      }
      try {
        const response = await MetricsService.getCRMDealsWon(params)
        if (response) {
          // const { actualPeriodAmount } = response.data
          const dResponse = response.data
          this.dealBenchmark.value = `${dResponse.count_lost} - ${dResponse.count_won}`
          this.dealClosed.value = {
            actualPeriodCount: dResponse.count_won,
            actualPeriodAmount: dResponse.amount_won,
            amountVariation: dResponse.amount_won_var
          }
          this.loading = false
        } else {
          this.loading = false
        }
      } catch (error) {
        this.loading = false
      }
    },
    processData (deals) {
      const previousCount = []
      const actualCount = []
      const axis = []
      deals.forEach((deal) => {
        previousCount.push(deal.previousCount)
        actualCount.push(deal.actualCount)
        axis.push(deal.createdAt)
      })
      deals = {
        actualCount: actualCount,
        previousCount: previousCount
      }
      this.dataRequestGraph = { axis, deals }
    }
  }
}
