<template>
  <div class="metricTableGray pt-1"
       :class="$vuetify.theme.dark ? 'metricTableGrayDark' : ''"
       :style="agents.length === 0 ? 'min-height: 40px;': ''">
    <span class="pt-3 pb-1">{{$t('salesPerformance')}}</span>
    <DateRange :range="range"/>
    <div class="KBSimpleTable smallTable"
         v-if="agents.length > 0">
      <table class="KBSimpleTable__table ">
        <thead>
          <tr>
            <th
              class="KBSimpleTable__header smallTable__header"
              v-for="(label, labelIndex) in headers"
              :key="`KBSimpleTable-${label.text}-${labelIndex}`"
            >
              <div class="tooltip"><p> {{ label.text }}</p>
                <span class="tooltiptext"
                      :style="{width:label.text.length*10 + 'px', left:labelIndex>= 5 ? '-'+labelIndex*25+'px': '-'+labelIndex*5+'px'}">{{ label.text }}</span>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            class="KBSimpleTable__row"
            v-for="(item, itemIndex) in agents"
            :key="`KBSimpleTable-item-${itemIndex}`">
            <td :class="$vuetify.theme.dark? 'KBSimpleTable__itemDark': 'KBSimpleTable__item'">
              {{ item.state }}
            </td>
            <td :class="$vuetify.theme.dark? 'KBSimpleTable__itemDark': 'KBSimpleTable__item'">
              <div class="flex justify-between">{{ item.total }}
                <span v-if="item.variation > 0"
                      class="positive">
                  +{{ item.variation }}
                </span>
                <span v-else
                      class="negative">{{ item.variation }}</span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-else
         class="msgServiceError">{{ $t('noDataFound') }}</div>
  </div>
</template>

<script>
import moment from 'moment'
import MetricsService from '@/modules/metrics/application/services/MetricsService'
import DateRange from '@/modules/metrics/infrastructure/ui/components/generic/DateRange.vue'
export default {
  name: 'SalesTable',
  props: [
    'dateString', 'range', 'idFlow'
  ],
  components: {
    DateRange
  },
  data () {
    return {
      loading: false,
      tableData: [],
      headers: [
        { text: this.$t('status'), field: 'state' },
        { text: this.$t('people'), field: 'total' }
      ]
    }
  },
  mounted () {
    if (this.range) {
      this.getLeads({
        startDate: this.range.dateFrom,
        endDate: this.range.dateTo
      })
    } else {
      this.getLeads({
        startDate: moment().subtract(1, 'Month').format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD')
      })
    }
  },
  watch: {
    range () {
      if (this.range) {
        this.getLeads({
          startDate: this.range.dateFrom,
          endDate: this.range.dateTo
        })
      }
    }
  },
  computed: {
    agents () {
      const agents = []
      if (this.tableData) {
        this.tableData.map(agent => {
          agents.push({
            // state: agent?.state,
            state: agent?.status,
            deals: agent?.actualPeriodCount || 0,
            total: `${this.formatNumber(agent?.actualPeriodCount)}`,
            variation: agent?.countVariation === 'noData' ? 0 : agent?.countVariation
          })
        })
      }
      return agents
    }
  },
  methods: {
    async getLeads (weeks) {
      // if (this.loading) return
      try {
        // this.loading = true
        this.tableData = []
        const { data } = await MetricsService.getPeopleStatus({
          startDate: weeks.startDate,
          endDate: weeks.endDate,
          pipelineId: this.idFlow
        })
        this.tableData = data
        // this.loading = false
      } catch (error) {
        // this.loading = false
      }
    },
    formatNumber (number) {
      const formatter = Intl.NumberFormat('en', { notation: 'compact' })
      if (number > 1000000) {
        return formatter.format(number)
      } else return number
    }
  }
}
</script>
<style lang='scss'>
$analytics-red: #fc3473;
$analytics-green: #00cc99;
.positive {
  font-size: 14px;
  color: $analytics-green;
}

.negative {
  font-size: 14px;
  color: $analytics-red;
}
.smallTable{
  table-layout: fixed;
  width: 100%;
  overflow-x: scroll;
}
.metricTableGray {
  border-radius: 10px;
  background-color: #d9d9d923;
  color: #000000;
  border: 1px solid #00000015;
  width: 98%;
  display: flex;
  align-items: flex-start;
  overflow: hidden;
  flex-direction: column;
  padding: 10px;
  margin: 15px 15px 20px 0px;
  max-width: 1075px;
  min-width: 90%;
  justify-content: space-between;

  span{
    padding-bottom: 10px;
  }

  .KBSimpleTable{
    margin-top: 10px;
    width: 97%;
     &__itemDark{
      font-style: normal;
      font-weight: 300;
      font-size: 12px;
      padding: 10px;
      border-bottom: 1px solid rgba(207, 205, 205, 0.493);
      text-align: start;
      min-width: 36px;
      span{
          font-size: 12px;
          font-weight: 300;
          }
    }
    &__item{
      font-style: normal;
      font-weight: 300;
      font-size: 12px;
      padding: 10px;
      border-bottom: 1px solid #EBEFF2;
      text-align: start;
      min-width: 36px;
      span{
        font-size: 12px;
        font-weight: 300;
          }
    }
  }
}
.KBSimpleTable__header{
  p{
  font-size:1.5rem;
  width:50px;

  white-space:nowrap;
  overflow:hidden;
  text-overflow:ellipsis;
  margin: 0;
  font-size: 11px;
  height: 100%;
  padding-top: 15px;
  }
}
.tooltip {
  height: 100%;
  position: relative;
  display: inline-block;
}
/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 10px 0;
  border-radius: 6px;
  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}
.tooltip:hover .tooltiptext {
  visibility: visible;
}
.metricTableGrayDark {
  background-color: black !important;
  span {
    color: white;
  }
  .KBSimpleTable__itemDark{
    color: white;
  }
}
</style>
