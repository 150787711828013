import KeybeText from '@/modules/DesignSystem/infrastructure/components/KeybeText/KeybeText.vue'
import KeybeSelect from '@/modules/DesignSystem/infrastructure/components/KeybeSelect/KeybeSelect/KeybeSelect.vue'
import { funnelsGetter } from '@/modules/Sales/funnels/domain/FunnelsController'
import { Funnel } from '@/modules/Sales/funnels/domain/Funnels'
import FunnelsUseCases from '@/modules/Sales/funnels/application/FunnelsUseCases'
import { dataToGetSteps } from '@/modules/Sales/steps/domain/StepsController'
import StepsUseCases from '@/modules/Sales/steps/application/StepsUseCases'
import EmitToast from '@/utils/EmitToast'
import { EventBus } from '@/eventBus'
import { Step } from '@/modules/Sales/steps/domain/models/Steps'
import { currencyToUSD } from '@/utils/currencyToUSD'
import operations from '@/utils/operations'
import FunnelsController from '@/modules/Sales/funnels/infrastructure/controller/Funnels.controller'
import StepsController from '@/modules/Sales/steps/infrastructure/controllers/Steps.controller'
import KeybeMoney from '@/modules/DesignSystem/infrastructure/components/KeybeMoney/KeybeMoney.vue'
import { mapState } from 'vuex'

export default {
  name: 'SalesCard',
  components: {
    KeybeText,
    KeybeSelect,
    KeybeMoney
  },
  props: {
    dataTocreate: {
      type: Object,
      required: true
    }
  },
  async mounted () {
    await this.getFunnels()
  },
  data () {
    return {
      funnelsController: new FunnelsController(),
      stepsController: new StepsController(),
      steps: [],
      selectedStep: '',
      selectedFunnel: '',
      funnels: [],
      amount: this.dataTocreate.salesCardData.amount,
      selectedResponsible: {}
    }
  },
  computed: {
    ...mapState('AppStore', ['selectedApp', 'advisers']),
    ...mapState('UserStore', ['token']),
    generateUrl () {
      const nameApp = this.selectedApp?.name.replace(/\s/g, '')
      return `Keybe.ai/${nameApp}`
    },
    cardAmount: {
      get () {
        return currencyToUSD(this.dataTocreate.salesCardData.amount)
      },
      set (newAmount) {
        this.dataTocreate.salesCardData.amount = newAmount
        this.amount = newAmount
      }
    }
  },
  methods: {
    async getFunnels (): Promise<void> {
      try {
        const response: funnelsGetter = await FunnelsUseCases.getFunnels(
          this.funnelsController,
          {
            app: this.selectedApp?.uuid,
            token: this.token
          }
        )
        if (response?.status) {
          this.funnels = response.data
          this.funnels.forEach((funnel: Funnel) => {
            funnel.name = operations.checkTranslation(funnel.name)
          })
        } else EmitToast.emitErrors(this.$t('unexpectedError'))
      } catch (e) {
        console.error(e)
      }
    },
    async getSteps (): Promise<void> {
      try {
        this.dataTocreate.salesCardData.funnelId = this.selectedFunnel
        const data: dataToGetSteps = {
          app: this.selectedApp?.uuid,
          funnelId: this.selectedFunnel,
          token: this.token
        }
        if (data?.funnelId) {
          const response = await StepsUseCases.getSteps(
            this.stepsController,
            data
          )
          if (response?.status) {
            this.steps = response.data
            this.steps.forEach((step: Step) => {
              step.name = operations.checkTranslation(step.name)
            })
            this.$emit('update:dataTocreate', this.dataTocreate)
          } else {
            EventBus.$emit('toast', 'error', this.$t('didntFindFlowSteps'))
          }
        }
      } catch (e) {
        console.error(e)
      }
    },
    selectStep () {
      this.dataTocreate.salesCardData.stepId = this.selectedStep
      this.$emit('update:dataTocreate', this.dataTocreate)
    }
  },
  watch: {
    amount (newAmount) {
      this.dataTocreate.salesCardData.amount = newAmount
    },
    selectedResponsible (newResponsible) {
      const dataResposible = {
        id: newResponsible?.uuid,
        name: newResponsible?.name,
        email: newResponsible?.email,
        phone: newResponsible?.phone
      }
      this.dataTocreate.salesCardData.responsible = dataResposible
    }
  }
}
