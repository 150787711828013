export enum AdviserStatusEnum {
  ONLINE = 'online',
  OFFLINE = 'offline',
}

export type AdviserStatus =
  | AdviserStatusEnum.ONLINE
  | AdviserStatusEnum.OFFLINE;

export interface Adviser {
  _id: String;
  status: AdviserStatus;
  companyUUID: string;
  appUUID: string;
  userId: string;
  inactive: boolean;
  name: string;
  lastName: string;
  uuid?: string;
  email?: string;
  createdAt?: Date;
  updatedAt?: Date;
  deletedAt?: Date;
  inactiveAt?: Date;
  country?: string;
  image?: string;
  autoAssign?: boolean;
  phone?: string;
  isActiveForAutoassign?: boolean;
  tags?: Array<string>;
  groups?: Array<string>;
  maxActiveConversationsNumber?: number;
  receiveRoomsEvenOfflineStatus?: boolean;
  countryCode?: string;
  customId?: string;
}
