/* eslint-disable camelcase */

import moment from 'moment'
import { mapState } from 'vuex'
import { EventBus } from '@/eventBus'
import MetricsService from '@/modules/metrics/application/services/MetricsService'
import { textCamelize } from '@/utils/utils'
import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'

import KBSelectAdvisor from '@/shared/infrastructure/ui/KBSelectAdvisor.vue'
import HeatMap from '@/modules/metrics/infrastructure/ui/components/conversations/HeatMap/HeatMap.vue'
import ConversationsTable from '@/modules/metrics/infrastructure/ui/components/conversations/ConversationsTable/ConversationsTable.vue'
import NewProspect from '@/modules/metrics/infrastructure/ui/components/conversations/NewProspect/NewProspect.vue'
import FirstRespondeTime from '@/modules/metrics/infrastructure/ui/components/conversations/FirstRespondeTime/FirstRespondeTime.vue'
import WaitingTime from '@/modules/metrics/infrastructure/ui/components/conversations/WaitingTime/WaitingTime.vue'
import TitleDetail from '@/modules/metrics/infrastructure/ui/components/generic/TitleDetail.vue'
import KBDateRangeMenu from '@/shared/infrastructure/ui/KBDateRangeMenu.vue'
import MetricsCard from '@/modules/metrics/infrastructure/ui/components/metrics/MetricsCard.vue'

export default {
  name: 'ConversationsPage',
  props: [
    'dateString', 'range', 'dataComponent'
  ],
  components: {
    KeybeIcon,
    WaitingTime,
    FirstRespondeTime,
    HeatMap,
    TitleDetail,
    ConversationsTable,
    NewProspect,
    KBDateRangeMenu,
    MetricsCard,
    KBSelectAdvisor
  },
  mounted () {
    this.loading = true
    this.dateSelector = this.dateString
    this.callServices()
  },
  data () {
    return {
      KeybeIconType,
      loadingDownload: false,
      advisors: [],
      idSelect: null,
      nameSelect: '',
      cardDaily: {
        value: '0',
        descipcion: '',
        text: this.$t('totalDaily'),
        subtext: this.$t('totalDailyDesc'),
        loading: false
      },
      cardChat: {
        value: '0',
        descipcion: '',
        text: this.$t('sessions'),
        subtext: this.$t('sessionsDesc'),
        loading: false
      },
      cardChatEnd: {
        value: '0',
        subtext: this.$t('closedSessionsDesc'),
        text: this.$t('closedSessions'),
        loading: false
      },
      cardChatEndIA: {
        value: '0',
        subtext: this.$t('sessionsClosedbyAIDesc'),
        text: this.$t('sessionsClosedbyAI'),
        loading: false
      },
      AverageSessionTime: {
        value: '0',
        descipcion: 'H : M',
        text: this.$t('averageSessionTime'),
        loading: false
      },
      AverageWaitingTime: {
        value: '0',
        descipcion: 'H : M',
        text: this.$t('averageWaitingTime'),
        loading: false
      },
      AverageAdvisorTime: {
        value: '0',
        descipcion: 'H : M',
        text: this.$t('averageAdvisorTime'),
        loading: false
      },
      AverageFirstResponseTime: {
        value: '0',
        descipcion: 'H : M',
        text: this.$t('averageFirstResponseTime'),
        loading: false
      },
      dateSelector: '',
      dataComponentChild: null
    }
  },
  computed: {
    ...mapState('UserStore', ['user']),
    advisorFilterArrayId: {
      get () {
        return this.$store.state.MetricsStore.advisorFilterArrayId || []
      },
      set (value) {
        this.$store.commit('MetricsStore/SET_EDITING_OBJECT', {
          key: 'advisorFilterArrayId',
          value
        })
      }
    },
    advisorFilterArray: {
      get () {
        return this.$store.state.MetricsStore.advisorFilterArray || []
      },
      set (value) {
        this.$store.commit('MetricsStore/SET_EDITING_OBJECT', {
          key: 'advisorFilterArray',
          value
        })
      }
    },
    advisorFilter: {
      get () {
        return this.$store.state.MetricsStore.advisorFilter || null
      },
      set (value) {
        this.$store.commit('MetricsStore/SET_EDITING_OBJECT', {
          key: 'advisorFilter',
          value
        })
      }
    },
    loading: {
      get () {
        return this.$store.state.MetricsStore.loading || false
      },
      set (value) {
        this.$store.commit('MetricsStore/SET_EDITING_OBJECT', {
          key: 'loading',
          value
        })
      }
    },
    bottomPage: {
      get () {
        return this.$store.state.MetricsStore.bottomPage || null
      },
      set (value) {
        this.$store.commit('MetricsStore/SET_EDITING_OBJECT', {
          key: 'bottomPage',
          value
        })
      }
    }
  },
  methods: {
    validateWeek (params) {
      const { periodStart, periodEnd } = params
      const fechaInicio = new Date(periodStart)
      const fechaFin = new Date(periodEnd)
      const diferenciaEnTiempo = fechaFin.getTime() - fechaInicio.getTime()
      const unaSemanaEnMilisegundos = 7 * 24 * 60 * 60 * 1000
      if (diferenciaEnTiempo > unaSemanaEnMilisegundos) {
        return false
      } else {
        return true
      }
    },
    async getFileMetrics () {
      if (!this.loadingDownload) {
        this.loadingDownload = true
        let params = {
          periodStart: moment().subtract(1, 'months').format('YYYY-MM-DD'),
          periodEnd: moment().format('YYYY-MM-DD')
        }
        if (this.range) {
          const { dateFrom, dateTo } = this.range
          params = {
            periodStart: dateFrom,
            periodEnd: dateTo
          }
        }
        const condition = this.validateWeek(params)
        const response = await MetricsService.getFileChatsV2(params, condition)
        if (response) {
          if (response?.code === 500) {
            this.loadingDownload = false
            EventBus.$emit('toast', 'error', this.$t('unexpectedError'))
            return
          }
          try {
            const message: string = this.$t('successfullCampaignExportEmail').toString().replace('{}', this.user.email)

            if (condition) {
              // const fileURL = response.data?.result?.download // Ojo lo cambiaron
              const fileURL = response.data?.downloadUrl
              if (fileURL) {
                window.open(fileURL, '_blank')
                // const link = document.createElement('a')
                // link.href = fileURL
                // link.target = '_blank'
                // link.download = `Metrics ${params.periodStart} - ${params.periodEnd}.xlsx`
                // document.body.appendChild(link)
                // link.click()
              }

              EventBus.$emit(
                'toast',
                'success',
                message
              )
            } else {
              EventBus.$emit(
                'toast',
                'success',
                message
              )
            }
            this.loadingDownload = false
          } catch (error) {
            console.log('error', error)
            this.loadingDownload = false
          }
        } else {
          this.loadingDownload = false
          if (response?.data?.error) {
            EventBus.$emit('toast', 'error', this.$t(textCamelize(response.data.error)))
          } else {
            EventBus.$emit('toast', 'error', this.$t('unexpectedError'))
          }
        }
      }
    },
    async getDataDailyChat () {
      let params = {
        periodStart: moment().subtract(1, 'weeks').format('YYYY-MM-DD'),
        periodEnd: moment().format('YYYY-MM-DD')
      }
      if (this.range) {
        const { dateFrom, dateTo } = this.range
        params = {
          periodStart: dateFrom,
          periodEnd: dateTo
        }
      }
      this.getTotalDailyChat(params)
      // this.getTotalChat(params)
      // this.getTotalChatEnd(params)
      // this.getTotalChatEndIA(params)
      this.getDataChat(params)
    },
    async getTotalDailyChat (params) {
      try {
        this.cardDaily.loading = true
        const response = await MetricsService.TotalDailyChat(params)
        if (response.status) {
          this.cardDaily.value = response?.data ? response.data?.totalCount : 0
        } else {
          if (response?.data?.error) {
            EventBus.$emit('toast', 'error', this.$t(textCamelize(response.data.error)))
          } else {
            EventBus.$emit('toast', 'error', this.$t('unexpectedError'))
          }
        }
        this.cardDaily.loading = false
      } catch (error) {
        console.log(error)
        this.cardDaily.loading = false
      }
    },
    async updateCardValue (serviceCall, cardToUpdate, type) {
      try {
        const response = await serviceCall() // Llamada al servicio

        if (response && response.data && response.data[type]) {
          const typeCard = response.data[type]
          if (Array.isArray(typeCard) && typeCard.length > 0) {
            if (type === 'count_closed_sessions_IA') {
              cardToUpdate.value = typeCard[0].count_closed_sessions || 0
            } else if (type === 'mean_session_time') {
              cardToUpdate.value = typeCard[0].median_session_time || 0
            } else {
              cardToUpdate.value = typeCard[0][type] || 0
            }
          } else {
            cardToUpdate.value = 0
          }
        } else {
          cardToUpdate.value = 0
        }
      } catch (error) {
        console.error('Error:', error)
        cardToUpdate.value = 0 // Valor predeterminado en caso de error
      }
    },
    convertirAHorasYMinutos (minutos) {
      if (!minutos) {
        return 0
      }
      var horas = Math.floor(minutos / 60)
      var minutosRestantes = Math.floor(minutos % 60)
      return horas + 'H ' + minutosRestantes + 'm'
    },
    convertirFormatoHora (hora) {
      if (!hora) {
        return 0
      }
      var tiempo = hora.split(':')
      var horas = parseInt(tiempo[0])
      var minutos = parseInt(tiempo[1])
      var segundos = parseInt(tiempo[2])
      var totalMinutos = horas * 60 + minutos + Math.round(segundos / 60)
      var horasConvertidas = Math.floor(totalMinutos / 60)
      var minutosConvertidos = totalMinutos % 60

      return horasConvertidas + 'H ' + minutosConvertidos + 'M'
    },
    async getDataChat (params) {
      try {
        this.cardChat.loading = true
        this.cardChatEnd.loading = true
        this.cardChatEndIA.loading = true
        this.updateCardValue(() => MetricsService.getDataCountConversations(params, this.advisors), this.cardChat, 'count_conversations')
        this.updateCardValue(() => MetricsService.getDataCountClosedSessions(params, this.advisors), this.cardChatEnd, 'count_closed_sessions')
        this.updateCardValue(() => MetricsService.getDataCountClosedSessionsIA(params, this.advisors), this.cardChatEndIA, 'count_closed_sessions_IA')
        this.updateCardValue(() => MetricsService.getDataMeanSessionTime(params, this.advisors), this.AverageSessionTime, 'mean_session_time')
        this.updateCardValue(() => MetricsService.getDataMeanWaitingTime(params, this.advisors), this.AverageWaitingTime, 'mean_waiting_time')
        this.cardChat.loading = false
        this.cardChatEnd.loading = false
        this.cardChatEndIA.loading = false
      } catch (error) {
        console.log(error)
        this.cardChat.loading = false
        this.cardChatEnd.loading = false
        this.cardChatEndIA.loading = false
      }
    },
    async getGlobalTimes () {
      this.AverageSessionTime.loading = true
      try {
        let params = {
          periodStart: moment().subtract(1, 'weeks').format('YYYY-MM-DD'),
          periodEnd: moment().format('YYYY-MM-DD')
        }
        if (this.range) {
          const { dateFrom, dateTo } = this.range
          params = {
            periodStart: dateFrom,
            periodEnd: dateTo
          }
        }
        const response = await MetricsService.getGlobalTimes(params)
        if (response.status) {
          const { Times } = response.data
          this.asignDataTwo(Times)
        } else {
          if (response?.data?.error) {
            EventBus.$emit('toast', 'error', this.$t(textCamelize(response.data.error)))
          } else {
            EventBus.$emit('toast', 'error', this.$t('unexpectedError'))
          }
        }
      } catch (error) {
        this.AverageSessionTime.loading = false
      }
    },
    async getAverageTimeCard () {
      this.AverageFirstResponseTime.loading = true
      try {
        let params = {
          periodStart: moment().subtract(1, 'weeks').format('YYYY-MM-DD'),
          periodEnd: moment().format('YYYY-MM-DD')
        }
        if (this.range) {
          const { dateFrom, dateTo } = this.range
          params = {
            periodStart: dateFrom,
            periodEnd: dateTo
          }
        }
        const response = await MetricsService.getAverageTimeCard(params)
        if (response.status) {
          const { range_first_answer_time } = response.data
          this.AverageFirstResponseTime.value = range_first_answer_time[0]?.mean_first_answer_time
        } else {
          if (response?.data?.error) {
            EventBus.$emit('toast', 'error', this.$t(textCamelize(response.data.error)))
          } else {
            EventBus.$emit('toast', 'error', this.$t('unexpectedError'))
          }
        }
        this.AverageFirstResponseTime.loading = false
      } catch (error) {
        this.AverageFirstResponseTime.loading = false
      }
    },
    asignDataTwo (item) {
      // this.AverageSessionTime.value = item.AverageSessionTime
      // this.AverageWaitingTime.value = item.AverageWaitingTime
      this.AverageAdvisorTime.value = item.AverageAdvisorTime
      // this.AverageFirstResponseTime.value = item.AverageFirstResponseTime
      this.AverageSessionTime.loading = false
    },
    callServices () {
      this.getGlobalTimes()
      this.getDataDailyChat()
      this.getAverageTimeCard()
    }
  },
  watch: {
    range: {
      handler: function (newVal, oldVal) {
        if ((newVal?.dateFrom && newVal?.dateTo) && (oldVal?.dateFrom && oldVal?.dateTo)) {
          if (newVal.dateFrom !== oldVal.dateFrom || newVal.dateTo !== oldVal.dateTo) {
            this.callServices()
          }
        }
      }
    },
    advisorFilterArray () {
      if (!this.loading) {
        if (this.advisorFilterArray.length === 0) {
          this.advisors = []
          return
        }
        if (this.advisorFilterArray.length > 0) {
          this.advisors = this.advisorFilterArrayId
        }
      }
    },
    dateSelector () {
      if (this.dateSelector) {
      }
    },
    dataComponent () {
      if (this.dataComponent) {
        this.dataComponentChild = this.dataComponent
      }
    }
  }
}
