























import TeamConfigurationContainer from './TeamConfigurationContainer'
export default TeamConfigurationContainer
