import { Task, TaskPriorityEnum, TaskStatusEnum } from '@/modules/tasks/domain/Task'
import { dataToUpdateTask } from '@/modules/tasks/domain/TaskController'
import TaskUseCases from '@/modules/tasks/application/TaskUseCases'
import TaskController from '@/modules/tasks/infrastructure/controllers/Task.controller'
import { mapState } from 'vuex'
import { EventBus } from '@/eventBus'

import KBSmallSelector from '@/shared/infrastructure/ui/inputs/select/KBSmallSelector.vue'
import { CDPFieldsEnums } from '@/modules/CDP/shared/contacts/domain/enums/CDPFieldsEnums'
export default {
  name: 'TaskOptions',
  components: {
    KBSmallSelector
  },
  props: {
    task: {
      required: true
    }
  },
  data () {
    return {
      CDPFieldsEnums,
      taskController: null,
      priority: {
        value: TaskPriorityEnum.MEDIUM,
        label: this.$t(`priority-${TaskPriorityEnum.MEDIUM}`)
      },
      status: {
        value: TaskStatusEnum.CREATED,
        label: this.$t('taskAssigned')
      },
      priorityOptions: [
        {
          value: TaskPriorityEnum.HIGHEST,
          label: this.$t(`priority-${TaskPriorityEnum.HIGHEST}`)
        },
        {
          value: TaskPriorityEnum.HIGH,
          label: this.$t(`priority-${TaskPriorityEnum.HIGH}`)
        },
        {
          value: TaskPriorityEnum.MEDIUM,
          label: this.$t(`priority-${TaskPriorityEnum.MEDIUM}`)
        },
        {
          value: TaskPriorityEnum.LOW,
          label: this.$t(`priority-${TaskPriorityEnum.LOW}`)
        },
        {
          value: TaskPriorityEnum.LOWEST,
          label: this.$t(`priority-${TaskPriorityEnum.LOWEST}`)
        }
      ],
      statusOptions: [
        {
          value: TaskStatusEnum.CREATED,
          label: this.$t('taskAssigned')
        },
        {
          value: TaskStatusEnum.PROCESS,
          label: this.$t('inProgress')
        },
        {
          value: TaskStatusEnum.EXPIRED,
          label: this.$t('expired')
        },
        {
          value: TaskStatusEnum.DONE,
          label: this.$t('completed')
        }
      ]
    }
  },
  created () {
    this.taskController = new TaskController()
  },
  beforeDestroy () {
    this.taskController = null
  },
  computed: {
    ...mapState('AppStore', ['selectedApp']),
    ...mapState('UserStore', ['token', 'isUserReadonly'])
  },
  methods: {
    getTaskStatusLabel (status: TaskStatusEnum): string {
      const labels = {
        [TaskStatusEnum.CREATED]: this.$t('taskAssigned'),
        [TaskStatusEnum.PROCESS]: this.$t('inProgress'),
        [TaskStatusEnum.EXPIRED]: this.$t('expired'),
        [TaskStatusEnum.DONE]: this.$t('completed')
      }
      return labels[status]
    },
    updatePriority ({ value }) {
      const data: dataToUpdateTask = {
        app: this.selectedApp?.uuid,
        token: this.token,
        task: {
          id: this.task?._id,
          priority: value
        }
      }
      const newTask = {
        ...this.task,
        priority: value
      }
      this.updateTask(data, newTask)
    },
    updateStatus ({ value }) {
      const data: dataToUpdateTask = {
        app: this.selectedApp?.uuid,
        token: this.token,
        task: {
          id: this.task?._id,
          status: value
        }
      }
      const newTask = {
        ...this.task,
        status: value
      }
      this.updateTask(data, newTask)
    },
    async updateTask (data: dataToUpdateTask, newTask) {
      try {
        const { success } = await TaskUseCases.updateTask(this.taskController, data)
        if (!success) {
          EventBus.$emit('toast', 'error', this.$t('errorUpdatingTask'))
          return
        }
        EventBus.$emit('TaskOptions:task-updated', { task: newTask, oldTask: this.task })
      } catch (error) {
        EventBus.$emit('toast', 'error', this.$t('errorUpdatingTask'))
      }
    }
  },
  watch: {
    task: {
      immediate: true,
      handler (task: Task) {
        this.priority = {
          value: task.priority,
          label: this.$t(`priority-${task.priority}`)
        }

        this.status = {
          value: task.status,
          label: this.getTaskStatusLabel(task.status)
        }
      }
    }
  }
}
