import CompanyStoreController from '@/shared/infrastructure/storeControllers/CompanyStore.controller'

// components
import TasksHeader from '@/modules/tasks/infrastructure/ui/components/TasksHeader/TasksHeader.vue'
import TasksBoard from '@/modules/tasks/infrastructure/ui/components/TasksBoard/TasksBoard.vue'
import CreateTask from '@/modules/tasks/infrastructure/ui/components/CreateTask/CreateTask.vue'
import { dataToGetTeamMembers, teamMemberGetter } from '@/modules/Config/TeamConfiguration/domain/TeamController'
import TeamUseCases from '@/modules/Config/TeamConfiguration/application/TeamUseCases'
import { TeamMember } from '@/modules/Config/TeamConfiguration/domain/models/TeamMember'
import TeamController from '@/modules/Config/TeamConfiguration/infrastructure/controllers/Team.controller'
import { mapState } from 'vuex'

export default {
  name: 'TasksScreen',
  components: {
    TasksHeader,
    TasksBoard,
    CreateTask
  },
  computed: {
    ...mapState('AppStore', ['selectedApp']),
    ...mapState('UserStore', ['token'])
  },
  data () {
    return {
      createTask: false,
      teamController: new TeamController()
    }
  },
  async created () {
    this.$emit('start-loading')
    await this.getAppAdmins()
    this.$emit('stop-loading')
  },
  methods: {
    async getAppAdmins () {
      try {
        const data: dataToGetTeamMembers = {
          page: 0,
          limit: 200,
          appId: this.selectedApp?.uuid,
          token: this.token,
          search: ''
        }
        const response: teamMemberGetter = await TeamUseCases.getTeamMembersFilterBiky(this.teamController, data)
        const teamMembers: TeamMember[] = response?.team || []
        const administrators = teamMembers
        CompanyStoreController.setAdministrators(administrators)
      } catch (e) {
        console.error(e)
      }
    }
  }
}
