<template>
  <svg width="327"
       height="61"
       viewBox="0 0 327 61"
       fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <path d="M89.7483 1V10.48"
          stroke="black"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"/>
    <path d="M85 5.74001H94.4883"
          stroke="black"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"/>
    <path d="M320.748 13V22.48"
          stroke="black"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"/>
    <path d="M316 17.74H325.488"
          stroke="black"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"/>
    <path d="M292.1 49H287V54.1H292.1V49Z"
          :fill="color"/>
    <path d="M5.1 18H0V23.1H5.1V18Z"
          :fill="color"/>
    <path d="M114 58H111V61H114V58Z"
          :fill="color"/>
    <path d="M224 8H221V11H224V8Z"
          :fill="color"/>
  </svg>
</template>
<script>
export default {
  name: 'ChatSparkle',
  props: {
    width: {
      type: Number,
      default: 18
    },
    height: {
      type: Number,
      default: 14
    },
    color: {
      type: String,
      default: '#B6BEC7'
    }
  }

}
</script>
