import httpClient from '@/shared/infrastructure/http/http'

export default class TemplatesRepository {
    store: {[key: string]: any}
    baseUrl: string

    constructor (store: {[key: string]: any}) {
      this.store = store
      this.baseUrl = process.env.VUE_APP_CONVERSATIONS_API_URL
    }

    async getTemplates (userHost: string, forceRefresh: number = 0) {
      const appId = this.store?.getters['AppStore/getSelectedApp']?.uuid || ''
      const url = `${this.baseUrl}/template`

      return await httpClient.get(url, {
        appUUID: appId,
        userHost,
        forceRefresh
      })
    }

    async sendTemplate (data: {[key: string]: any}) {
      const { appUUID, companyUUID } = data
      const companyId = this.store?.getters['CompanyStore/getSelectedCompany']?.uuid || ''
      const appId = this.store?.getters['AppStore/getSelectedApp']?.uuid || ''

      const url = `${this.baseUrl}/message/proactivity`

      if (!appUUID || !companyUUID) {
        data.appUUID = appId
        data.companyUUID = companyId
      }

      return await httpClient.post(url, data)
    }
}
