<template>
  <div class="ImportsList">
    <div class="ImportsList__search"
         v-if="!loadingGet">
      <span>{{count}} {{$t('imports')}}</span>
      <span @click="getImports('people')"
            class="mdi mdi-reload ml-10 cursor-pointer"/>
    </div>
    <div class="ImportsList__table"
         v-if="!loadingGet">
      <table>
        <thead>
          <tr>
              <th
                v-for="(header, index) in headers"
                :key="`${header.value}-${index}`">
                <small>{{header.text}}</small>
              </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, index) in imports"
            :key="index">
            <td
              v-for="(header, index) in headers"
              :key="`${header.value}-${index}`"
              class="cursor-pointer"
              @mouseover="hoverItem = item"
              :class="highlightClass(item)">
              <div v-if="header.value === 'title'"
                   class="title"
                   @click="openDetails(item)">
                <div/>
                <span>{{importName(item)}}</span>
              </div>
              <span
                v-if="header.value === 'status'"
                :key="`${rerender}-status`"
                @click="openDetails(item)">
                {{$t(`${item.status}-importer`)}}
              </span>
              <span v-if="header.value === 'size'"
                    @click="openDetails(item)">
                {{importSize(item)}}
              </span>
              <span v-if="header.value === 'imported'"
                    @click="openDetails(item)"
                    class="mr-3">
                {{importAmount(item)}}
              </span>
              <span v-if="header.value === 'responsible'"
                    @click="openDetails(item)">
                {{importResponsible(item)}}
              </span>
              <span v-if="header.value === 'startedAt'"
                    @click="openDetails(item)">
                {{startedAt(item)}}
              </span>
              <span v-if="header.value === 'finishedAt'"
                    @click="openDetails(item)">
              </span>
              <div v-if="header.value === 'start'">
                <KeybeButton
                  :text="buttonText(item.status)"
                  :disabled="item.status !== 'created'"
                  :loading="showLoader(item)"
                  :key="`${rerender}-button`"
                  @click="startImport(item)"/>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <Loader :absolute="false"
            :fullHeight="false"
            v-else/>
    <CustomPagination
      v-if="pagination.limit"
      class="ImportsList__footer"
      :count="count"
      :key="rerender"
      :text="$t('imports')"
      :disabled="loading"
      :paginationSetted="pagination"
      @getItems="setPagination($event)"
      @changeItems="offset = 1"/>
  </div>
</template>
<script>
import ImportsList from './ImportsList'
export default ImportsList
</script>
<style scoped lang="scss">
@import './ImportsList.scss'
</style>
