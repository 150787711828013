<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       fill="none"
       viewBox="0 0 12 18"><path :fill="color"
                                 d="M2.272 6.18c0-1.018-.03-2.034.006-3.05C2.335 1.502 3.648.17 5.27.018 6.308-.08 7.21.226 7.955.93a3.177 3.177 0 0 1 1.021 2.346c.016 1.962.043 3.927-.013 5.888-.046 1.62-1.16 2.856-2.753 3.141-1.97.352-3.85-1.07-3.932-3.076-.041-1.015-.007-2.033-.007-3.05Zm1.085-.015h.03c0 1.016-.043 2.034.01 3.048.078 1.522 1.827 2.493 3.19 1.805.896-.453 1.294-1.216 1.3-2.192.011-1.767.006-3.534.002-5.301-.003-.803-.286-1.485-.943-1.974a2.209 2.209 0 0 0-2.68.024c-.64.498-.913 1.165-.909 1.964.005.876.001 1.751.001 2.627Z"/><path :fill="color"
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            d="M5.061 16.886c.006-.107.013-.183.013-.26 0-.602-.008-1.204.005-1.806.004-.19-.066-.242-.238-.287-.512-.133-1.035-.25-1.52-.452-1.012-.42-1.775-1.15-2.371-2.06C.29 11.015.012 9.9.004 8.71c-.003-.477-.011-.955.01-1.431.02-.417.391-.638.767-.473.25.108.318.331.32.579.005.438.003.876.003 1.314 0 .975.21 1.896.761 2.711.971 1.439 2.32 2.204 4.072 2.08 2.308-.162 4.037-1.947 4.172-4.223.038-.64.02-1.282.038-1.922.013-.454.332-.688.735-.554.231.076.347.259.35.48.014 1.17.093 2.346-.276 3.489a5.504 5.504 0 0 1-1.204 2.043 5.42 5.42 0 0 1-2.81 1.643c-.205.049-.417.069-.621.12-.057.013-.14.088-.14.135-.009.718-.006 1.436-.006 2.185h.274c.634 0 1.268-.005 1.9.002.495.005.767.401.568.817-.124.26-.366.294-.616.294H2.95c-.086 0-.173-.006-.256-.025a.548.548 0 0 1-.323-.877c.137-.182.34-.21.547-.21.617-.003 1.235-.001 1.853-.001h.289Z"/></svg>
</template>

<script>
export default {
  name: 'MicrophoneIcon',
  props: {
    color: {
      type: String,
      default: '#01CFE4'
    }
  }

}
</script>

<style>

</style>
