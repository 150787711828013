







import ChartDetail from './ChartDetail'
export default ChartDetail
