import MicrositesService from '@/services/MicrositesService'
import { MicrositesTabs } from '@/modules/microsites/infrastructure/enums/MicrositesTabs'

// components
import MicrositesSidebar from '@/modules/microsites/infrastructure/ui/components/MicrositesSidebar.vue'
import Preview from '@/modules/microsites/infrastructure/ui/components/MicrositesPreview.vue'
import CatalogConfig from '@/modules/catalog/infrastructure/ui/CatalogConfig/CatalogConfig.vue'
import Categories from '@/modules/microsites/infrastructure/ui/components/Categories/Categories.vue'

export default {
  data () {
    return {
      MicrositesTabs,
      selectedTab: MicrositesTabs.HOME,
      main: {
        mode: 'edit',
        original: {
          config: null,
          products: null,
          catalog: null,
          microsite: null,
          whatsapp: null
        },
        config: {
          name: '',
          photoProfile: '',
          greeting: 'Hello Stranger!!!',
          description: '',
          background: '#3AF69B',
          initialPrice: 0,
          finalPrice: 0,
          currency: 'COP', // COP, USD
          paymentMethods: '', // "Efectivo, transferencia bancolombia, nequi, tarjetas de credito"
          address: '',
          webSite: '',
          urlKeybe: '', // freshshake.keybe.ai
          whatsappKeybe: '',
          socialLinks: []
        }
      }
    }
  },
  methods: {
    async init () {
      await this._getConfig()
    },
    async _getConfig () {
      try {
        const response = await MicrositesService.getConfig()

        if (!('data' in response)) {
          throw new Error()
        }

        const { data } = response

        if (data.length === 0) {
          throw new Error()
        }

        this.main.config = this.fixDataRecived(data[0])
        this.main.mode = 'edit'
        this.main.original.config = this.fixDataRecived(data[0])

        return true
      } catch (e) {
        console.error(e)
        return false
      }
    },
    fixDataRecived (config) {
      return {
        ...config,
        initialPrice: 0,
        finalPrice: 0
      }
    }
  },
  components: { MicrositesSidebar, Preview, CatalogConfig, Categories },
  computed: {
    selectedTabComponent () {
      const components = {
        [MicrositesTabs.HOME]: Preview,
        [MicrositesTabs.CATALOG]: CatalogConfig,
        [MicrositesTabs.CATEGORY]: Categories
      }

      return components[this.selectedTab]
    },
    baseMicrositesUrl () {
      return process.env.VUE_APP_MICROSITES_BASE_URL
    }
  },
  async created () {
    this.currentSelectedTab = this.selectedTab
  },
  mounted () {
    this.init()
  }
}
