const emojiPattern = /\p{Extended_Pictographic}/u
const accentPattern = /[^\w\s\\?\-._!"`'#%&,:;<>=@{}~$()*+\\/\\?[\]^|]/
const emojiDetectionRegex = new RegExp(emojiPattern)
const accentDetectionRegex = new RegExp(accentPattern)

const doubleBracketsRegex = '{{(.*?)}}'
const bracketsDetectionRegex = new RegExp(doubleBracketsRegex)

export default class ValidateRegex {
  static hasEmoji (string): boolean {
    return emojiDetectionRegex.test(string)
  }

  static hasAccent (string): boolean {
    return accentDetectionRegex.test(string)
  }

  static isBetweenBrackets (string): boolean {
    return bracketsDetectionRegex.test(string)
  }
}
