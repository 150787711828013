<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       fill="none"
       viewBox="0 0 9 18">
    <rect width="9"
          height="1"
          y="17"
          :fill="color"
          rx=".5"/>
    <path :fill="color"
          d="M4.995 13.165c.093-.092.149-.143.201-.199.622-.653 1.243-1.306 1.863-1.96.301-.316.562-.357.78-.12.242.262.213.512-.1.846-.936 1.002-1.872 2.003-2.81 3.004-.325.348-.531.352-.85.011-.953-1.015-1.904-2.032-2.853-3.05-.317-.34-.298-.671.04-.888.154-.099.305-.102.452.003.08.058.155.127.224.2.672.704 1.341 1.41 2.061 2.168v-.304c0-4.062-.001-8.122.006-12.183 0-.18.043-.41.15-.533a.465.465 0 0 1 .812.251c.013.103.024.207.024.31V13.165Z"/>
  </svg>
</template>

<script>
export default {
  name: 'DownloadIcon',
  props: {
    color: {
      type: String,
      default: '#B6BEC7'
    }
  }
}
</script>

<style scoped>

</style>
