









export default {
  name: 'PencilRuleIcon',
  props: {
    color: {
      type: String,
      default: '#000'
    }
  }
}
