import { CDPFieldsEnums } from '@/modules/CDP/shared/contacts/domain/enums/CDPFieldsEnums'

export const BusinessesStore = {
  namespaced: true,
  state: {
    tableFields: [CDPFieldsEnums.NAME, 'email', CDPFieldsEnums.PHONE, 'createdAt'],
    orderedFields: [
      CDPFieldsEnums.STATUS, CDPFieldsEnums.REGISTER_CHANNEL, CDPFieldsEnums.ALIAS, CDPFieldsEnums.CATEGORY,
      CDPFieldsEnums.DOCUMENT_TYPE, CDPFieldsEnums.DOCUMENT, CDPFieldsEnums.COUNTRY, CDPFieldsEnums.CITY, CDPFieldsEnums.ZONE,
      CDPFieldsEnums.LANGUAGE, CDPFieldsEnums.RESPONSIBLE
    ]
  },
  mutations: {
    SET_TABLE_FIELDS (state: any, fields: string[]) {
      state.tableFields = fields
    },
    SET_ORDERED_FIELDS (state: any, fields: string[]) {
      state.orderedFields = fields
    }
  },
  actions: {
    setTableFields ({ commit }, fields: string[]) {
      commit('SET_TABLE_FIELDS', fields)
    },
    setOrderedFields ({ commit }, fields: string[]) {
      commit('SET_ORDERED_FIELDS', fields)
    }
  }
}
