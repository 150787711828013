import { render, staticRenderFns } from "./ConversationsTable.vue?vue&type=template&id=120ab696&scoped=true&"
import script from "./ConversationsTable.vue?vue&type=script&lang=ts&"
export * from "./ConversationsTable.vue?vue&type=script&lang=ts&"
import style0 from "./ConversationsTable.vue?vue&type=style&index=0&id=120ab696&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "120ab696",
  null
  
)

export default component.exports