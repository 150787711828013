import { BikyConfigUseCases } from '@/modules/bky/application/BikyConfigUseCases'
import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'
import KBText from '@/shared/infrastructure/ui/inputs/text/KBText.vue'
import { mapState } from 'vuex'
import BikyConfigController from '../../../controllers/BikyConfig.controller'

export default {
  name: 'BikyConfigChatCorrectPronunciation',
  components: {
    KBText,
    KeybeIcon
  },
  props: {
    word: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      KeybeIconType,
      pronunciation: '',
      bikyConfigController: new BikyConfigController(),
      loading: false
    }
  },
  methods: {
    close () {
      this.$emit('close')
    },
    async save () {
      if (this.loading) {
        return
      }

      this.loading = true
      await BikyConfigUseCases.createPronunciation(this.bikyConfigController, {
        token: this.token,
        appUUID: this.selectedApp?.uuid,
        name: this.word,
        pronunciation: this.pronunciation
      })
      this.loading = false
      this.$emit('close')
    }
  },
  computed: {
    ...mapState('AppStore', ['selectedApp']),
    ...mapState('UserStore', ['token', 'user'])
  }
}
