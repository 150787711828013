import { BikySkill } from '@/modules/bky/domain/BikySkill'
import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'
import Colors from '@/utils/Colors'

export default {
  name: 'BikyConfigSkillBubble',
  components: {
    KeybeIcon
  },
  props: {
    skill: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      KeybeIconType
    }
  },
  computed: {
    skillComputed (): BikySkill {
      return this.skill
    },
    isActive (): boolean {
      return this.skillComputed?.active
    },
    isDisabled (): boolean {
      return this.skillComputed?.disabled
    },
    icon (): KeybeIconType {
      return this.skillComputed?.icon
    },
    title (): string {
      return this.skillComputed?.title
    },
    color (): string {
      if (this.isActive) {
        return Colors.black
      }
      return this.$vuetify.theme.dark ? Colors.white : Colors.black
    }
  }
}
