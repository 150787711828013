import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import moment from 'moment'
import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'
import FacebookMessengerConfig from '@/components/configuration/conversations/channels/FacebookMessengerConfig.vue'
import KBButton from '@/shared/infrastructure/ui/buttons/KBButton.vue'
import KBExpansionPanel from '@/shared/infrastructure/ui/menus/KBExpansionPanel.vue'
import { ChannelsEnum } from '@/modules/Config/Channels/shared/domain/ChannelsEnum'
export default {
  name: 'AutoConnectFacebookConfig',
  props: {
    configs: {
      required: true,
      type: Array
    }
  },
  components: {
    KeybeIcon,
    FacebookMessengerConfig,
    KBButton,
    KBExpansionPanel
  },
  computed: {
    sortedConfigs () {
      return [...this.configs].sort((a, b) => {
        const percentageA = this.getTokenPercentage(a)
        const percentageB = this.getTokenPercentage(b)
        return percentageB - percentageA
      })
    }
  },
  data () {
    return {
      KeybeIconType,
      ChannelsEnum,
      labelToken: 'Inactive'
    }
  },
  methods: {
    isConnectedWithManualMethod (config) {
      return config?.connectionMethod === 'old'
    },
    getTokenPercentage (config) {
      if (!config || this.isConnectedWithManualMethod(config)) {
        return 0
      }

      const today = moment()
      const dateStart = moment(config.updatedAt)
      const dateEnd = moment(config.expiresAt)

      if (dateEnd.isBefore(today)) {
        return -1
      }

      const daysDifference = dateEnd.diff(dateStart, 'days')
      const difference = today.diff(dateStart, 'days')

      const rawPercentage = (difference / daysDifference) * 100
      const percentage = Math.min(rawPercentage, 100)

      return Math.round(percentage)
    },
    isDisabled (config) {
      return config?.enable === false || false
    },
    getIndicatorClass (config) {
      const percentage = this.getTokenPercentage(config)

      if (this.isDisabled(config)) {
        return 'AutoConnectConfig__item--indicator--red'
      }

      if (percentage === -1) {
        return 'AutoConnectConfig__item--indicator--red'
      }

      if (percentage > 70 || this.isConnectedWithManualMethod(config)) {
        return 'AutoConnectConfig__item--indicator--green'
      }

      return 'AutoConnectConfig__item--indicator--yellow'
    },
    getDaysUntilExpiration (config) {
      if (!config) {
        return 0
      }
      const today = moment()
      const dateEnd = moment(config?.expiresAt)

      const daysDifference = moment(dateEnd).diff(today, 'days')

      return daysDifference
    },
    isExpired (config) {
      return this.getDaysUntilExpiration(config) < 0
    },
    getTokenExpirationLabel (config) {
      if (!config) {
        return ''
      }

      if (this.isDisabled(config)) {
        return this.$t('disconectedChannel')
      }
      if (this.isConnectedWithManualMethod(config)) {
        return this.$t('active')
      }
      if (this.isExpired(config)) {
        return this.$t('tokenExpired')
      }
      if (this.getDaysUntilExpiration(config) === 0) {
        return this.$t('tokenExpiresToday')
      }
      if (this.getDaysUntilExpiration(config) === 1) {
        this.labelToken = this.$t('active')
        return `${this.$t('tokenExpiresIn')} ${this.getDaysUntilExpiration(config)} ${this.$t('day')}`
      }
      this.labelToken = this.$t('active')
      return `${this.$t('tokenExpiresIn')} ${this.getDaysUntilExpiration(config)} ${this.$t('days')}`
    },
    goToPage (config) {
      window.open(`https://facebook.com/${config.page_id[0]}`, '_blank')
    },
    getGradientStyle (config) {
      const percentage = this.getTokenPercentage(config)
      const adjustedPercentage = Math.min(percentage, 65)
      return {
        background: `linear-gradient(to right, #0FAEF6 ${adjustedPercentage}%, rgba(182, 190, 199, 0.1) ${adjustedPercentage}%)`
      }
    }
  }
}
