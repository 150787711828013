import { EventBus } from '@/eventBus'
import TaskUseCases from '@/modules/tasks/application/TaskUseCases'
import { Task, TaskStatusEnum } from '@/modules/tasks/domain/Task'
import { dataToUpdateTask } from '@/modules/tasks/domain/TaskController'
import Draggable from 'vuedraggable'
import { mapState } from 'vuex'
import TaskController from '../../../controllers/Task.controller'

import TaskCard from '@/modules/tasks/infrastructure/ui/components/TaskCard/TaskCard.vue'

export default {
  name: 'TasksSection',
  components: {
    TaskCard,
    Draggable
  },
  props: {
    tasks: {
      required: true,
      type: Array
    },
    allTasks: {
      required: true,
      type: Array
    },
    taskStored: {
      required: false,
      type: Object,
      default: () => ({})
    },
    title: {
      required: true,
      type: String
    },
    canGetMoreTasks: {
      required: true,
      type: Boolean
    },
    isLoading: {
      required: true,
      type: Boolean
    }
  },
  data () {
    return {
      taskController: new TaskController(),
      expandedTask: null,
      draggingTask: '',
      taskStatus: {
        [`TasksSection__container-${this.$t('tasksAssigned')}`]:
          TaskStatusEnum.CREATED,
        [`TasksSection__container-${this.$t('inProgress')}`]:
          TaskStatusEnum.PROCESS,
        [`TasksSection__container-${this.$t('tasksExpired')}`]:
          TaskStatusEnum.EXPIRED,
        [`TasksSection__container-${this.$t('tasksCompleted')}`]:
          TaskStatusEnum.DONE
      }
    }
  },
  mounted () {
    this.validateTaskStored()
  },
  computed: {
    ...mapState('AppStore', ['selectedApp']),
    ...mapState('UserStore', ['token'])
  },
  methods: {
    validateTaskStored (): void {
      if (this.taskStored) {
        const exist: boolean = this.tasks.some(
          (task: Task) => task._id === this.taskStored._id
        )
        if (exist) {
          this.onTaskCardClick(this.taskStored)

          setTimeout(() => this.setScrollToCard(), 1000)
        }
      }
    },
    setScrollToCard (): void {
      const taskCard: HTMLElement = document.getElementById(this.taskStored._id)
      if (taskCard) {
        taskCard.scrollIntoView({ behavior: 'smooth', block: 'center' })
      }
    },
    handleDragEnd (event: any) {
      const newStatus = this.taskStatus[event?.to?.id]
      this.updateStatus({ value: newStatus })
    },
    handleDragStart (event: any) {
      this.draggingTask = event?.clone?.id
    },
    onTaskCardClick (task: Task) {
      if (!task || !task?._id) {
        return
      }
      if (this.isTaskCardExpanded(task)) {
        this.expandedTask = null
        return
      }
      this.expandedTask = task._id
    },
    isTaskCardExpanded (task: Task) {
      return this.expandedTask === task._id
    },
    handleScroll () {
      const bottomOfWindow =
        this.$refs.tasksSectionContainer.offsetHeight +
          this.$refs.tasksSectionContainer?.scrollTop >=
        this.$refs.tasksSectionContainer?.scrollHeight - 1
      if (bottomOfWindow && !this.isLoading && this.canGetMoreTasks) {
        this.$emit('loadMoreTasks')
      }
    },
    updateStatus ({ value }) {
      const actualTask = this.allTasks.find(
        (task: Task) => task._id === this.draggingTask
      )
      if (!actualTask) {
        return
      }
      if (actualTask.status === value) {
        return
      }
      const data: dataToUpdateTask = {
        app: this.selectedApp?.uuid,
        token: this.token,
        task: {
          id: actualTask?._id,
          status: value
        }
      }
      const newTask = {
        ...actualTask,
        status: value
      }
      this.updateTask(data, newTask, actualTask)
    },
    async updateTask (data: dataToUpdateTask, newTask: Task, oldTask: Task) {
      try {
        this.$emit('removeTask', oldTask)
        const { success } = await TaskUseCases.updateTask(
          this.taskController,
          data
        )
        if (!success) {
          EventBus.$emit('toast', 'error', this.$t('errorUpdatingTask'))
          return
        }
        EventBus.$emit('TaskOptions:task-updated', {
          task: newTask,
          oldTask: oldTask
        })
      } catch (error) {
        EventBus.$emit('toast', 'error', this.$t('errorUpdatingTask'))
      }
    }
  },
  watch: {
    tasks () {
      this.expandedTask = null
    }
  }
}
