









export default {
  name: 'MainInbox'
}
