
import KBDateRangeMenu from '@/shared/infrastructure/ui/KBDateRangeMenu.vue'
import TitleDetail from '@/modules/metrics/infrastructure/ui/components/generic/TitleDetail.vue'
import ColumnGraph from '@/modules/metrics/infrastructure/ui/components/ending/ColumnGraph/ColumnGraph.vue'
import csat from '@/modules/metrics/infrastructure/ui/components/ending/csat/csat.vue'
import KBSelectAdvisor from '@/shared/infrastructure/ui/KBSelectAdvisor.vue'

export default {
  name: 'EndingPage',
  props: [
    'dateString', 'range', 'dataComponent'
  ],
  components: {
    csat,
    TitleDetail,
    ColumnGraph,
    KBDateRangeMenu,
    KBSelectAdvisor
    // WordCloud
  },
  data () {
    return {
      advisors: [],
      idSelect: null,
      nameSelect: '',
      dateSelector: '',
      dataComponentChild: null
    }
  },
  watch: {
    dataComponent () {
      if (this.dataComponent) {
        this.dataComponentChild = this.dataComponent
      }
    }
  },
  mounted () {
    this.dateSelector = this.dateString
  },
  computed: {
    bottomPage: {
      get () {
        return this.$store.state.MetricsStore.bottomPage || null
      },
      set (value) {
        this.$store.commit('MetricsStore/SET_EDITING_OBJECT', {
          key: 'bottomPage',
          value
        })
      }
    }
  },
  methods: {
  }
}
