











































































import Vue from 'vue'
import Colors from '@/utils/Colors'
import { EventBus } from '@/eventBus'
import operation from '@/utils/operations'
import MicrositesService from '@/services/MicrositesService'
import * as utils from '@/modules/microsites/infrastructure/utils'
import KBSearch from '@/shared/infrastructure/ui/inputs/text/KBSearch.vue'
export default Vue.extend({
  components: {
    KBSearch
  },
  computed: {
    filteredIcons () {
      const myArr = Object.entries(utils.icons)
      const filter = this.searchValue.toLowerCase()
      const filteredArr = myArr.filter(([key, value]) => key.includes(filter)).flat()
      return filteredArr.filter(item => typeof item === 'object')
    },
    examineIcon () {
      return process.env.VUE_APP_STORAGE_URL + '/files/bluebird/Microsites/image-examine.svg'
    },
    closeIcon () {
      return process.env.VUE_APP_STORAGE_URL + '/files/bluebird/icons/shared/close.svg'
    },
    planetIcon () {
      return {
        icon: 'planet',
        link: process.env.VUE_APP_STORAGE_URL + '/files/bluebird/Microsites/planet-black.svg'
      }
    }
  },
  mounted () {
    if (this.data && this.data.icon) this.selectedIcon = { link: this.data.icon }
    else this.selectedIcon = this.planetIcon
  },
  data () {
    return {
      searchValue: '',
      Colors,
      utils,
      operation,
      loading: false,
      selectGalleryOpen: false,
      selectedIcon: {},
      selectedIconIndex: 999
    }
  },
  methods: {
    selectIcon (icon, index) {
      this.selectedIcon = icon
      this.selectedIconIndex = index
      this.selectGalleryOpen = false
      console.log('this.selectedIcon', this.selectedIcon)
      EventBus.$emit('setSelectedIcon', this.selectedIcon)
    },
    async onFilePicked (event) {
      this.loading = true
      const img = event.target.files[0]
      const response = await MicrositesService.uploadImage(img)
      if (response.status) {
        this.selectedIcon.link = response.data.url
        EventBus.$emit('setSelectedIcon', this.selectedIcon)
        EventBus.$emit('toast', 'success', this.$t('successUploadingPicture'))
      } else {
        EventBus.$emit('toast', 'error', this.$t('errorUploadingPicture'))
      }
      this.loading = false
    },
    submit () {
      EventBus.$emit('setSelectedIcon', this.selectedIcon)
      this.$emit('cancel')
    }
  },
  props: {
    data: {
      type: Object
    }
  }
})
