
import OutBoundDetail from '@/modules/outbound/infrastructure/ui/components/OutBoundDetail/OutBoundDetail.vue'
import OutboundPage from '@/modules/outbound/infrastructure/ui/components/OutboundPage/OutboundPage.vue'

export default {
  name: 'OutboundAll',
  components: {
    OutboundPage,
    OutBoundDetail
  },
  data () {
    return {
      detail: false,
      detailData: null,
      loading: false
    }
  },
  watch: {
  },
  methods: {
    visibleDetail (item) {
      this.detail = true
      this.detailData = item
    }
  }
}
