































































import FacebookMessengerConfig from './FacebookMessengerConfig'
export default FacebookMessengerConfig
