<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       fill="none"
       viewBox="0 0 35 17">
    <mask id="a"
          width="35"
          height="17"
          x="0"
          y="0"
          maskUnits="userSpaceOnUse"
          style="mask-type:luminance">
      <path fill="#fff"
            d="M31.643 11.78c0 .918.751 1.661 1.679 1.661A1.67 1.67 0 0 0 35 11.781a1.67 1.67 0 0 0-1.678-1.662 1.67 1.67 0 0 0-1.68 1.662Zm0-6.7a1.67 1.67 0 0 0 1.679 1.662C34.25 6.742 35 6 35 5.081a1.67 1.67 0 0 0-1.678-1.661 1.67 1.67 0 0 0-1.68 1.66ZM10.796.578 3.958 7.234c-.233.23-.385.51-.457.805a1.689 1.689 0 0 0 .447 1.639l6.84 6.657a1.733 1.733 0 0 0 2.432 0 1.69 1.69 0 0 0 0-2.408L7.603 8.46l5.627-5.477a1.69 1.69 0 0 0 0-2.407 1.725 1.725 0 0 0-1.216-.499c-.44 0-.881.167-1.217.499M0 1.772V15.17c0 .94.77 1.702 1.72 1.702s1.721-.762 1.721-1.702V1.772C3.441.832 2.67.07 1.721.07.77.07 0 .832 0 1.772Zm18.857 8.384 5.267.005c.87.065 1.556.783 1.556 1.66 0 .92-.754 1.665-1.684 1.665h-5.139v-3.33Zm0-6.746 5.259.005c.877.058 1.57.78 1.57 1.662 0 .882-.753 1.665-1.683 1.665h-5.146V3.41ZM17.12 0c-.905 0-1.647.692-1.715 1.57l-.001.018a1.661 1.661 0 0 0-.004.11v13.499c0 .35.107.676.289.947.31.46.838.763 1.438.763.063 0 6.954 0 6.999-.002l.036-.002a5.142 5.142 0 0 0 3.934-2.028 5.02 5.02 0 0 0 1.028-3.05 5.02 5.02 0 0 0-1.29-3.368l.004-.004a5.022 5.022 0 0 0 1.294-3.371 5.02 5.02 0 0 0-1.029-3.05A5.145 5.145 0 0 0 23.997 0H17.12L17.121 0Z"/>
    </mask>
    <g mask="url(#a)">
      <path fill="url(#b)"
            d="M35 0H0v17h35V0Z"/>
    </g>
    <defs>
      <linearGradient id="b"
                      x1="18.258"
                      x2="18.258"
                      y1="15.451"
                      y2="-1.455"
                      gradientUnits="userSpaceOnUse">
        <stop stop-color="#00D995"/>
        <stop offset=".09"
              stop-color="#00E498"/>
        <stop offset=".12"
              stop-color="#00E49C"/>
        <stop offset=".24"
              stop-color="#00E7B3"/>
        <stop offset=".42"
              stop-color="#00E9C8"/>
        <stop offset=".47"
              stop-color="#00E8CE"/>
        <stop offset=".69"
              stop-color="#00E7E7"/>
        <stop offset=".84"
              stop-color="#00E2F1"/>
        <stop offset="1"
              stop-color="#00DDF1"/>
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'KBLogoWithGradient'
}
</script>

<style scoped>

</style>
