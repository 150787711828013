
import ApexCharts from 'apexcharts'
import { orderDataEndingReason } from '@/modules/metrics/infrastructure/ui/components/utils/orderDataEndingReason'
import LoadingNext from '@/modules/metrics/infrastructure/ui/components/generic/LoadingNext.vue'
import DateRange from '@/modules/metrics/infrastructure/ui/components/generic/DateRange.vue'
import MetricsService from '@/modules/metrics/application/services/MetricsService'

export default {
  components: {
    DateRange,
    LoadingNext
  },
  props: {
    range: {
      type: [String, Number, Object]
    },
    id: {
      type: [String, Number],
      default: 0
    },
    dataComponent: {
      type: [Object, String, Array]
    }
  },
  name: 'ColumnGraph',
  computed: {
    advisorFilterArrayId: {
      get () {
        return this.$store.state.MetricsStore.advisorFilterArrayId || []
      },
      set (value) {
        this.$store.commit('MetricsStore/SET_EDITING_OBJECT', {
          key: 'advisorFilterArrayId',
          value
        })
      }
    },
    advisorFilterArray: {
      get () {
        return this.$store.state.MetricsStore.advisorFilterArray || []
      },
      set (value) {
        this.$store.commit('MetricsStore/SET_EDITING_OBJECT', {
          key: 'advisorFilterArray',
          value
        })
      }
    },
    advisorFilter: {
      get () {
        return this.$store.state.MetricsStore.advisorFilter || null
      },
      set (value) {
        this.$store.commit('MetricsStore/SET_EDITING_OBJECT', {
          key: 'advisorFilter',
          value
        })
      }
    }
  },
  watch: {
    range () {
      if (this.range) {
        this.validateAdvisor()
      }
    },
    advisorFilterArray () {
      this.validateAdvisor()
    }
  },
  data () {
    return {
      serviceEmpty: false,
      chart: null,
      loadingEnding: false
    }
  },
  mounted () {
    this.validateAdvisor()
  },
  methods: {
    validateAdvisor () {
      if (this.loadingEnding) {
        return
      }
      if (this.advisorFilterArrayId.length === 0) {
        this.getEndingReason(this.range)
        return
      }
      if (this.advisorFilterArrayId) {
        this.getEndingReason(this.range, this.advisorFilterArrayId)
      }
    },
    async getEndingReason (dataRange, advisorList) {
      const { dateFrom, dateTo } = dataRange
      try {
        if (this.chart) {
          this.chart.destroy()
        }
        this.loadingEnding = true
        const params = {
          periodStart: dateFrom,
          periodEnd: dateTo
        }
        const response = await MetricsService.getBarEndReason(params, advisorList)
        if (response) {
          const { histogram } = response.data
          this.setEndingReason(histogram)
          this.loadingEnding = false
        } else {
          this.serviceEmpty = true
          this.loadingEnding = false
        }
      } catch (error) {
        this.serviceEmpty = true
        this.loadingEnding = false
      }
    },
    setEndingReason (item) {
      const { seriesComputed, advisors } = orderDataEndingReason(item)
      if (this.chart) {
        this.chart.destroy()
      }
      const options = {
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 10,
            borderRadiusOnAllStackedSeries: true
          }
        },
        tooltip: {
          enabled: true,
          enabledOnSeries: undefined,
          shared: true,
          followCursor: false,
          intersect: false,
          inverseOrder: false,
          custom: undefined,
          fillSeriesColor: true,
          theme: false,
          style: {
            fontSize: '12px',
            fontFamily: undefined
          },
          y: {
            formatter: undefined,
            title: {
              formatter: (seriesName) => seriesName + ':'
            }
          },
          onDatasetHover: {
            highlightDataSeries: false
          }
        },
        xaxis: {
          type: 'string',
          categories: advisors
        },
        chart: {
          type: 'bar',
          height: 520,
          stacked: true,
          toolbar: {
            show: false
          },
          zoom: {
            enabled: false
          }
        },
        dataLabels: {
          enabled: false
        },
        series: seriesComputed
      }
      this.chart = new ApexCharts(document.querySelector(`#columnGraph${this.id.toString()}`), options)

      this.chart.render()
    }
  }
}
