<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       fill="none"
       viewBox="0 0 14 10"><path :fill="color"
                                 d="M6.997 9.796c-.223 0-.447.003-.67 0-.439-.008-.7-.253-.72-.691a11.135 11.135 0 0 1-.002-.803c.002-.105-.028-.165-.123-.218-.691-.385-1.127-.962-1.248-1.748-.23-1.503.872-2.678 2.089-2.807.561-.059 1.13-.092 1.681.064 1.731.49 2.287 2.517 1.318 3.794a2.336 2.336 0 0 1-.797.69c-.107.056-.14.126-.136.241.008.253.005.506.001.759-.007.448-.27.71-.72.718-.224.004-.448.001-.672.001ZM5.59 6.834V5.057c-.448.447-.446 1.363 0 1.777Zm2.812-1.777v1.779c.459-.382.459-1.39 0-1.779Z"/><path :fill="color"
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    d="M9.48 3.572c.282-.202.602-.329.946-.375.59-.078 1.186-.107 1.768.041 1.651.42 2.354 2.45 1.326 3.809a2.297 2.297 0 0 1-.788.68c-.106.053-.145.122-.137.24.01.145.005.291 0 .436-.011.401-.248.669-.647.684-.495.018-.992.018-1.487 0-.387-.014-.618-.264-.646-.654a3.192 3.192 0 0 1-.003-.423.346.346 0 0 1 .07-.169c.799-1.12.833-2.532.075-3.676-.136-.206-.308-.386-.477-.594v.001Zm3.126 2.909c.465-.362.462-1.426 0-1.764v1.764ZM4.56 3.567c-.407.379-.695.785-.862 1.267-.363 1.054-.244 2.047.395 2.967.067.098.105.19.096.308-.007.097 0 .194-.003.292-.012.41-.261.679-.672.69-.481.013-.963.013-1.445 0-.394-.012-.634-.26-.662-.654a3.455 3.455 0 0 1-.005-.467c.008-.123-.037-.189-.145-.25C.616 7.361.192 6.825.05 6.103A2.436 2.436 0 0 1 2.29 3.16c.562-.028 1.13-.06 1.674.138.194.07.377.17.594.27h.002ZM1.39 4.71c-.475.455-.43 1.388 0 1.764V4.71ZM5.607 1.37A1.394 1.394 0 0 1 7.009 0c.778-.002 1.404.664 1.378 1.436a1.386 1.386 0 0 1-1.408 1.347A1.394 1.394 0 0 1 5.606 1.37ZM1.578 1.216C1.608.462 2.104.015 2.804.002a1.178 1.178 0 0 1 1.202 1.219c-.015.716-.484 1.206-1.208 1.208-.716.004-1.194-.48-1.22-1.213ZM9.991 1.268C9.947.591 10.456.02 11.174.005c.633-.014 1.182.378 1.24 1.148.051.69-.455 1.258-1.183 1.277-.638.015-1.192-.392-1.24-1.162Z"/></svg>
</template>

<script>
export default {
  name: 'TeamIcon',
  props: {
    color: {
      type: String,
      default: '#fff'
    }
  }
}
</script>

<style>

</style>
