import { Status } from '../domain/Status'
import {
  IStatusController,
  dataToCreateStatus,
  dataToDeleteStatus,
  dataToGetStatuses,
  dataToUpdateStatus,
  statusGetter
} from '../domain/StatusController'

export default class StatusUseCases {
  static dispose (controller: IStatusController): void {
    controller.dispose()
  }

  static getStatuses (controller: IStatusController, data: dataToGetStatuses) : Promise<Status[]> {
    return controller.getStatuses(data)
  }

  static createStatus (controller: IStatusController, data: dataToCreateStatus) : Promise<statusGetter> {
    return controller.createStatus(data)
  }

  static updateStatus (controller: IStatusController, data: dataToUpdateStatus) : Promise<statusGetter> {
    return controller.updateStatus(data)
  }

  static deleteStatus (controller: IStatusController, data: dataToDeleteStatus) : Promise<statusGetter> {
    return controller.deleteStatus(data)
  }
}
