<template>
  <div class="contentDataTimePicker">
    <div
      class="dialogPicker"
      :class="$vuetify.theme.dark ? 'dialogPicker__dark': ''"
      v-if="dialogKBTime">
      <a
        @click="dialogKBTime = !dialogKBTime"
        class="closeModal">
        <KeybeIcon
          :icon="KeybeIconType.ARROW_LEFT"
          :color="$vuetify.theme.dark ? '#ffffff': '#000000'"/>
      </a>
      <KBTime
        :values="hours"
        @input="time = $event"/>
    </div>
    <a @click="dialogKBTime = !dialogKBTime">
      <KBText
        disabled
        class="KBform smalForm"
        v-model="hours"
        placeholder="Hora"
      />
    </a>
    <span>/</span>
    <KBText
      class="KBform smalForm"
      v-model="day"
      type="number"
      :placeholder="$t('days')"
      @input="() => { if(day > 31 || day < 1) { day = 1 }}"/>
    <span>/</span>
    <KBText
      class="KBform smalForm"
      v-model="month"
      type="number"
      :placeholder="$t('month')"
      @input="() => { if(day > 12 || day < 1) { day = 1 }}"/>
  </div>
</template>

<script>
import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'
import KBTime from '@/shared/infrastructure/ui/pickers/KBTime.vue'
import KBText from '@/shared/infrastructure/ui/inputs/text/KBText.vue'
export default {
  name: 'DateTimePickerRow',
  components: {
    KBTime,
    KBText,
    KeybeIcon
  },
  data () {
    return {
      dialogKBTime: false,
      hours: null,
      date: null,
      day: null,
      month: null,
      time: null,
      KeybeIconType
    }
  },
  computed: {
    programmingDate: {
      get () {
        return this.$store.state.CampaingsStore.programmingDate || null
      },
      set (value) {
        this.$store.commit('CampaingsStore/SET_EDITING_OBJECT', {
          key: 'programmingDate',
          value
        })
      }
    },
    todayDate () {
      const date = new Date()
      return {
        day: date.getDate(),
        month: (date.getMonth() + 1) < 10 ? '0' + +(date.getMonth() + 1) : date.getMonth() + 1,
        year: date.getFullYear()
      }
    }
  },
  watch: {
    time () {
      if (this.time) {
        this.programmingDate.hours = this.hours
        this.hours = this.time
        this.dialogKBTime = false
      }
    },
    hours () {
      if (this.hours) {
        this.programmingDate.hours = this.hours
      }
    },
    day () {
      if (this.day > 31) {
        this.day = 31
      } else {
        if (this.day) this.programmingDate.day = this.day
      }
    },
    month () {
      if (this.month > 12) {
        this.month = 12
      } else {
        if (this.month) this.programmingDate.month = this.month
      }
    }
  },
  methods: {
  },
  created () {
    const currentdate = new Date()
    const min = currentdate.getMinutes().toString()
    const hours = currentdate.getHours().toString()
    const year = currentdate.getFullYear()
    this.month = String(currentdate.getMonth() + 1).padStart(2, '0')
    this.day = String(currentdate.getDate()).padStart(2, '0')
    this.hours = (hours.length === 1 ? `0${hours}` : hours) + ':' + (min.length === 1 ? `0${min}` : min)
    this.programmingDate = {
      hours: this.hours,
      day: this.day,
      month: this.month,
      year: year
    }
  }
}
</script>

<style lang='scss'>
.contentDataTimePicker{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    span{
        color: #ccd5db;
    }
    .KBform{
        margin:0 5px;
        width: 99px;
    }
    .dialogPicker{
        position: absolute;
        left: -50px;
        top: 50px;
        z-index: 9;
        .KBTime{
            background-color: #E2E6E9;
            .KBTime__containerAM{
                span{
                    color: black;
                }
            }
            .KBTime__containerMIN{
                span{
                    color: black;
                }
            }
        }
        &__dark{
            .KBTime{
            background-color: #2E3032;
            .KBTime__containerAM{
                span{
                    color: rgb(255, 255, 255);
                }
            }
            .KBTime__containerMIN{
                span{
                    color: rgb(255, 255, 255);
                }
            }
        }
        }
    }
}
.KBText{
    /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
    appearance: textfield;
  }
}
.closeModal{
    position: absolute;
    width: 30px;
    height: 30px;
    left: 5px;
}
</style>
