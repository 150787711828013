

















































































import UpdateTeamMember from './UpdateTeamMember'
export default UpdateTeamMember
