export default {
  name: 'PlanHeader',
  computed: {
    title (): string {
      return this.$t('plans')
    },
    content (): string {
      return this.$t('plansContent')
    },
    buttonText (): string {
      return this.$t('knowMoreHere')
    }
  },
  data () {
    return {
      planHelp: `${process.env.VUE_APP_HELPDOCS}/consumos/consumos-facturacion/`,
      plansLink: `${process.env.VUE_APP_KEYBE_CO_URL}docs/ayudas/consumos/consumos-facturacion/`
    }
  }
}
