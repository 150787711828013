import OutboundRepository from '@/modules/outbound/infrastructure/repository/Outbound.repository'
import { IROutboundController, dataToGetOutbound, outboundGetter, dataToGetOutboundDuplicate } from '../../domain/OutboundController'

export default class OutboundController implements IROutboundController {
    repository: OutboundRepository

    constructor () {
      this.repository = new OutboundRepository()
    }

    async getOutbound (data: dataToGetOutbound): Promise<outboundGetter> {
      const response = await this.repository.getOutbound(data)
      if (!response.status) {
        return {
          count: 0,
          rows: []
        }
      }

      const outbound = response?.data?.campaigns?.rows || []
      const totalOutbound = response?.data?.campaigns?.count ? response?.data?.campaigns?.count : 0

      return {
        rows: outbound,
        count: totalOutbound
      }
    }

    async getOutboundDuplicate (data: dataToGetOutboundDuplicate): Promise<outboundGetter> {
      const response = await this.repository.getDuplicate(data)
      if (!response.status) {
        return {
          count: 0,
          rows: []
        }
      }

      const outbound = response?.data?.campaignUsers?.rows || []
      const totalOutbound = response?.data?.campaignUsers?.count ? response?.data?.campaignUsers?.count : 0

      return {
        rows: outbound,
        count: totalOutbound
      }
    }

    async removeOutboundDuplicate (data: dataToGetOutboundDuplicate): Promise<outboundGetter> {
      const response = await this.repository.removeDuplicate(data)
      if (!response.status) {
        return {
          count: 0,
          rows: []
        }
      }

      const outbound = response?.data?.campaignUsers?.rows || []
      const totalOutbound = response?.data?.campaignUsers?.count ? response?.data?.campaignUsers?.count : 0

      return {
        rows: outbound,
        count: totalOutbound
      }
    }

    cancelOutboundRequest () {
      this.repository.cancelGetOutbound()
    }
}
