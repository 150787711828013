<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       viewBox="0 0 103 88"
       fill="none"><path fill="#fff"
                         d="M74.736 25.66h-48.69a2.66 2.66 0 0 1-2.666-2.652 2.66 2.66 0 0 1 2.665-2.652h48.683a2.66 2.66 0 0 1 2.666 2.652 2.66 2.66 0 0 1-2.666 2.652h.008ZM74.736 37.315h-48.69a2.66 2.66 0 0 1-2.666-2.652 2.66 2.66 0 0 1 2.665-2.652h48.683a2.66 2.66 0 0 1 2.666 2.652 2.66 2.66 0 0 1-2.666 2.652h.008ZM62.437 49.666H26.045a2.66 2.66 0 0 1-2.665-2.652 2.66 2.66 0 0 1 2.665-2.653h36.392a2.66 2.66 0 0 1 2.666 2.653 2.66 2.66 0 0 1-2.666 2.652Z"/><path fill="#000"
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                     d="M16.563 87.972c-.353 0-.706-.075-1.044-.224-.9-.404-1.479-1.3-1.479-2.287V69.256C6.172 68.516 0 61.904 0 53.887V16.142C0 7.624 6.96.698 15.52.698h71.147c8.559 0 15.519 6.926 15.519 15.444v37.745c0 8.517-6.96 15.444-15.52 15.444H38.916l-.27.201-20.43 17.82c-.466.41-1.066.62-1.66.62h.008ZM15.519 5.719c-5.773 0-10.474 4.677-10.474 10.423v37.745c0 5.746 4.7 10.423 10.474 10.423h1.044a2.517 2.517 0 0 1 2.523 2.51v13.105l16.307-14.226c.045-.037.083-.074.128-.104l1.014-.77a2.521 2.521 0 0 1 1.531-.515h48.6c5.774 0 10.474-4.677 10.474-10.423V16.142c0-5.746-4.7-10.423-10.474-10.423H15.52Z"/></svg>
</template>

<script>
export default {
  name: 'SmartometerEmptyIconActive',
  props: {
    color: {
      type: String,
      default: '#000000'
    }
  }
}
</script>

<style>

</style>
