import { render, staticRenderFns } from "./BikyConfigSchedule.vue?vue&type=template&id=bdfea080&scoped=true&"
import script from "./BikyConfigSchedule.vue?vue&type=script&lang=ts&"
export * from "./BikyConfigSchedule.vue?vue&type=script&lang=ts&"
import style0 from "./BikyConfigSchedule.vue?vue&type=style&index=0&id=bdfea080&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bdfea080",
  null
  
)

export default component.exports