import KeybeRepository from '@/shared/infrastructure/repositories/KeybeRepository'
import { GenericResponse } from '@/shared/infrastructure/http/GenericResponse'
import { HeadersBuilder } from '../../../../shared/infrastructure/http/HeadersBuilder'

export default class KeybeAiConfigRepository extends KeybeRepository {
  baseUrl: string

  constructor () {
    super()

    this.baseUrl = process.env.VUE_APP_CONVERSATIONS_API_URL
  }

  // message suggestions
  async getMessageSuggestionsConfig (data): Promise<GenericResponse> {
    const { appId } = data
    const url = `${this.baseUrl}/configurations/app/${appId}/message-suggestions`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    const requestData = {
      url,
      headers
    }
    return await this.client.get(requestData)
  }

  async setMessageSuggestionsConfig (params): Promise<GenericResponse> {
    const {
      data,
      advisorId,
      advisorName,
      appId
    } = params
    const url = `${this.baseUrl}/configurations/app/${appId}/message-suggestions`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(params)
    const requestData = {
      url,
      data: {
        ...data,
        advisorId,
        advisorName
      },
      headers
    }
    return await this.client.put(requestData)
  }

  // action suggestions
  async getActionSuggestionsConfig (data): Promise<GenericResponse> {
    const { appId } = data
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    const url = `${this.baseUrl}/configurations/app/${appId}/action-suggestions`
    const requestData = {
      url,
      headers
    }
    return await this.client.get(requestData)
  }

  async setActionSuggestionsConfig (params): Promise<GenericResponse> {
    const {
      data,
      appId
    } = params
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(params)
    const url = `${this.baseUrl}/configurations/app/${appId}/action-suggestions`
    const requestData = {
      url,
      data,
      headers
    }
    return await this.client.put(requestData)
  }

  // sentiment analysis
  async getSentimentsConfig (data): Promise<GenericResponse> {
    const {
      appId
    } = data
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    const url = `${this.baseUrl}/configurations/app/${appId}/sentiments`
    const requestData = {
      url,
      headers
    }
    return await this.client.get(requestData)
  }

  async setSentimentsConfig (params): Promise<GenericResponse> {
    const {
      data,
      advisorId,
      advisorName,
      appId
    } = params
    const url = `${this.baseUrl}/configurations/app/${appId}/sentiments`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(params)
    const requestData = {
      url,
      data: {
        ...data,
        advisorId,
        advisorName
      },
      headers
    }
    return await this.client.put(requestData)
  }
}
