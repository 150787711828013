

























import ChatFiltersConfig from './ChatFiltersConfig'

export default ChatFiltersConfig
