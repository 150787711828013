<template>
  <div class="KBTime">
    <div class="KBTime__header">
      <span v-if="!selectedHour">{{$t('hour')}}</span>
      <span v-else>{{selectedHour}} : {{selectedMinute ? selectedMinute : '00'}}</span>
    </div>
    <div
      class="KBTime__containerAM"
      v-if="hourPicker">
      <div
        class="circleAM"
        :class="{ 'circleAM--selected': selectedHour === hour.hour }"
        v-for="hour in hoursAM"
        :key="hour.hour"
        :style="`transform: rotate(${hour.transform}deg) translateX(115px)`">
        <span
          @click="pickHour(hour)"
          :style="`transform: ${hour.spanTransform}`">{{hour.hour}}</span>
      </div>
      <div
        class="KBTime__arrow"
        :style="`transform: rotate(${transformArrow}deg) scaleY(1); height: calc(50% - ${heightArrow}px)`"></div>
      <div class="KBTime__containerPM">
        <div
          class="circlePM"
          :class="{ 'circlePM--selected': selectedHour === hour.hour }"
          v-for="hour in hoursPM"
          :key="hour.hour"
          :style="`transform: rotate(${hour.transform}deg) translateX(70px)`">
          <span
            :style="`transform: ${hour.spanTransform}`"
            @click="pickHour(hour)">{{hour.hour}}</span>
        </div>
      </div>
    </div>
    <div
      class="KBTime__containerMIN"
      v-else>
      <div
        class="circleMIN"
        :class="{ 'circleMIN--selected': selectedMinute === minute.minute }"
        v-for="minute in minutes"
        :key="minute.minute"
        @click="pickMinute(minute.minute)"
        :style="`transform: rotate(${minute.transform}deg) translateX(115px)`">
        <span :style="`transform: ${minute.spanTransform}`">{{minute.minute}}</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['value'],
  mounted () {
    if (this.value) {
      const array = this.value.split(':')
      this.selectedHour = array[0]
      this.selectedMinute = array[1]

      const arrowSmall = this.hoursPM.filter(h => h.hour === this.selectedHour)[0]
      if (arrowSmall) this.heightArrow = '68'

      let arrowPosition = this.hoursPM.filter(h => h.hour === this.selectedHour)[0]
      if (arrowPosition) this.transformArrow = +arrowPosition.transform + 86
      else {
        arrowPosition = this.hoursAM.filter(h => h.hour === this.selectedHour)[0]
        this.transformArrow = +arrowPosition.transform + 86
      }
    }
  },
  data () {
    return {
      hourPicker: true,
      hoursAM: [
        { hour: '1', transform: '300', spanTransform: 'rotate(60deg)' },
        { hour: '2', transform: '330', spanTransform: 'rotate(30deg)' },
        { hour: '3', transform: '360', spanTransform: 'rotate(0deg)' },
        { hour: '4', transform: '30', spanTransform: 'rotate(330deg)' },
        { hour: '5', transform: '60', spanTransform: 'rotate(300deg)' },
        { hour: '6', transform: '90', spanTransform: 'rotate(270deg)' },
        { hour: '7', transform: '120', spanTransform: 'rotate(240deg)' },
        { hour: '8', transform: '150', spanTransform: 'rotate(210deg)' },
        { hour: '9', transform: '180', spanTransform: 'rotate(180deg)' },
        { hour: '10', transform: '210', spanTransform: 'rotate(150deg)' },
        { hour: '11', transform: '240', spanTransform: 'rotate(120deg)' },
        { hour: '12', transform: '270', spanTransform: 'rotate(90deg)' }
      ],
      hoursPM: [
        { hour: '13', transform: '300', spanTransform: 'rotate(60deg)' },
        { hour: '14', transform: '330', spanTransform: 'rotate(30deg)' },
        { hour: '15', transform: '360', spanTransform: 'rotate(0deg)' },
        { hour: '16', transform: '30', spanTransform: 'rotate(330deg)' },
        { hour: '17', transform: '60', spanTransform: 'rotate(300deg)' },
        { hour: '18', transform: '90', spanTransform: 'rotate(270deg)' },
        { hour: '19', transform: '120', spanTransform: 'rotate(240deg)' },
        { hour: '20', transform: '150', spanTransform: 'rotate(210deg)' },
        { hour: '21', transform: '180', spanTransform: 'rotate(180deg)' },
        { hour: '22', transform: '210', spanTransform: 'rotate(150deg)' },
        { hour: '23', transform: '240', spanTransform: 'rotate(120deg)' },
        { hour: '00', transform: '270', spanTransform: 'rotate(90deg)' }
      ],
      minutes: [
        { minute: '05', transform: '300', spanTransform: 'rotate(60deg)' },
        { minute: '10', transform: '330', spanTransform: 'rotate(30deg)' },
        { minute: '15', transform: '360', spanTransform: 'rotate(0deg)' },
        { minute: '20', transform: '30', spanTransform: 'rotate(330deg)' },
        { minute: '25', transform: '60', spanTransform: 'rotate(300deg)' },
        { minute: '30', transform: '90', spanTransform: 'rotate(270deg)' },
        { minute: '35', transform: '120', spanTransform: 'rotate(240deg)' },
        { minute: '40', transform: '150', spanTransform: 'rotate(210deg)' },
        { minute: '45', transform: '180', spanTransform: 'rotate(180deg)' },
        { minute: '50', transform: '210', spanTransform: 'rotate(150deg)' },
        { minute: '55', transform: '240', spanTransform: 'rotate(120deg)' },
        { minute: '00', transform: '270', spanTransform: 'rotate(90deg)' }
      ],
      selectedHour: '12',
      transformArrow: '357',
      heightArrow: '22',
      selectedMinute: ''
    }
  },
  methods: {
    pickHour (hour) {
      this.selectedHour = hour.hour
      this.transformArrow = +hour.transform + 86
      if (this.hoursPM.includes(hour)) this.heightArrow = '68'
      this.hourPicker = false
    },
    pickMinute (minute) {
      this.selectedMinute = minute
      this.$emit('input', `${this.selectedHour}:${this.selectedMinute}`)
    }
  }
}
</script>
<style scoped lang="scss">
  .KBTime {
    width: 350px;
    height: 315px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);

    &__header {
      display: flex;
      justify-content: center;
      text-align: center;
      margin-bottom: 20px;
      margin-left: -20px;
      margin-top: 0;
      padding-top: 10px;

      span {
        font-weight: 700;
        font-size: 16px;
        color: $verde-bird;
      }
    }
    &__containerAM {
      position: relative;
      border-radius: 50%;
      width: 230px;
      height: 230px;
      margin-left: 55px;
      padding-top: 45px;
      .circleAM {
        position: absolute;
        left: calc(50% - 25px);
        top: calc(50% - 25px);
        height: 50px;
        width: 50px;
        border-radius: 50%;
        cursor: pointer;

        span {
          z-index: 2;
          width: 35px;
          height: 35px;
          border-radius: 35px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: transparent;
          transition: background 0.4s;
        }
        span:hover {
          background: rgba(255, 255, 255, 0.05);
        }
      }
      .circleAM--selected {
        span {
          background: $verde-bird;
        }
      }
      .circleAM--selected:hover {
        span {
          background: $verde-bird;
        }
      }
    }

    &__containerPM {
      position: relative;
      border-radius: 50%;
      width: 140px;
      height: 140px;
      margin-left: 45px;
      .circlePM {
        position: absolute;
        left: calc(50% - 25px);
        top: calc(50% - 25px);
        height: 50px;
        width: 50px;
        border-radius: 50%;
        cursor: pointer;

        span {
          z-index: 2;
          width: 35px;
          height: 35px;
          border-radius: 35px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: transparent;
          transition: background 0.4s;
        }
        span:hover {
          background: rgba(255, 255, 255, 0.05);
        }
      }
      .circlePM--selected {
        span {
          background: $verde-bird;
        }
      }
      .circlePM--selected:hover {
        span {
          background: $verde-bird;
        }
      }
    }

    &__containerMIN {
      position: relative;
      border-radius: 50%;
      width: 230px;
      height: 230px;
      margin-left: 55px;
      padding-top: 45px;
      .circleMIN {
        position: absolute;
        left: calc(50% - 25px);
        top: calc(50% - 25px);
        height: 50px;
        width: 50px;
        border-radius: 50%;
        cursor: pointer;

        span {
          width: 35px;
          height: 35px;
          border-radius: 35px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: transparent;
          transition: background 0.4s;
        }
        span:hover {
          background: rgba(255, 255, 255, 0.05);
        }
      }

      .circleMIN--selected {
        span {
          background: $verde-bird;
        }
      }
      .circleMIN--selected:hover {
        span {
          background: $verde-bird;
        }
      }
    }

    &__arrow {
      width: 2px;
      bottom: 50%;
      left: calc(50% - 1px);
      transform-origin: center bottom;
      position: absolute;
      will-change: transform;
      background: $verde-bird;
      border-color: $verde-bird;
    }

    &__arrow:before {
      content: "";
      position: absolute;
      left: 50%;
      transform: translate(-50%,-50%);
      background: #0000;
      border: 2px solid;
      border-color: inherit;
      border-radius: 100%;
      width: 10px;
      height: 10px;
      top: -4px;
    }

    &__arrow:after {
      content: "";
      position: absolute;
      left: 50%;
      transform: translate(-50%,-50%);
      height: 8px;
      width: 8px;
      top: 100%;
      border-radius: 100%;
      border-style: solid;
      border-color: inherit;
      background-color: inherit;
    }
  }
</style>
