import KeybeRepository from '@/shared/infrastructure/repositories/KeybeRepository'
import { chatFilterCreateDTO, chatFilterUpdateDTO, dataToGetChatFilters, deleteFilterDTO, favoriteActionsDTO } from '../../domain/ChatFilterController'
import { GenericResponse } from '@/models/apis/GenericResponse'
import { HeadersBuilder } from '@/shared/infrastructure/http/HeadersBuilder'
import { getOptions, nonGetOptions } from '@/shared/infrastructure/http/HttpClient'

export default class ChatFilterRepository extends KeybeRepository {
  baseUrl: string
  constructor () {
    super()

    this.baseUrl = process.env.VUE_APP_CONVERSATIONS_API_URL
  }

  async getChatFilters (data: dataToGetChatFilters): Promise<GenericResponse> {
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    const query = {
      appUUID: data.appUUID,
      userId: data.userId,
      isSuperAdmin: data.isSuperAdmin
    }

    if (!data.isSuperAdmin) {
      delete query.isSuperAdmin
    }

    const url = `${this.baseUrl}/config/filter`
    const requestData: getOptions = {
      url,
      query,
      headers
    }
    return await this.client.get(requestData)
  }

  async createChatFilter (data: chatFilterCreateDTO): Promise<GenericResponse> {
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    const url = `${this.baseUrl}/config/filter`
    const requestData: nonGetOptions = {
      url,
      headers,
      data
    }
    return await this.client.post(requestData)
  }

  async updateChatFilter (data: chatFilterUpdateDTO): Promise<GenericResponse> {
    const url = `${this.baseUrl}/config/filter`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    const requestData: nonGetOptions = {
      url,
      headers,
      data
    }
    return await this.client.put(requestData)
  }

  async deleteChatFilter (data: deleteFilterDTO): Promise<GenericResponse> {
    const url = `${this.baseUrl}/config/filter`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    const requestData: nonGetOptions = {
      url,
      headers,
      data
    }
    return await this.client.delete(requestData)
  }

  async favoriteChatFilter (data: favoriteActionsDTO): Promise<GenericResponse> {
    const url = `${this.baseUrl}/config/filter/favorite`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    const requestData: nonGetOptions = {
      url,
      headers,
      data
    }
    return await this.client.post(requestData)
  }

  async unfavoriteChatFilter (data: favoriteActionsDTO): Promise<GenericResponse> {
    const url = `${this.baseUrl}/config/filter/favorite`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    const requestData: nonGetOptions = {
      url,
      headers,
      data
    }
    return await this.client.delete(requestData)
  }
}
