













export default {
  name: 'KeybeListBoxLoader',
  props: {
    items: {
      type: Number,
      default: 10
    }
  }
}
