<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       fill="none"
       viewBox="0 0 23 23">
    <rect width="23"
          height="23"
          :fill="color"
          rx="11.5"/>
    <path fill="#F6F9FC"
          d="m8.045 17.912-3.486 1.917c-.204.112-.388.25-.559.401A11.571 11.571 0 0 0 11.522 23c2.845 0 5.452-1.027 7.478-2.73a3.088 3.088 0 0 0-.614-.416l-3.733-1.881a1.436 1.436 0 0 1-.787-1.284v-1.476c.105-.12.225-.275.353-.458a8.684 8.684 0 0 0 1.16-2.357 1.18 1.18 0 0 0 .832-1.125V9.697a1.18 1.18 0 0 0-.39-.873V6.545S16.284 3 11.521 3C6.76 3 7.224 6.545 7.224 6.545v2.279a1.18 1.18 0 0 0-.39.873v1.576c0 .415.216.78.54.992a7.844 7.844 0 0 0 1.413 2.948v1.44c0 .524-.285 1.007-.742 1.259Z"/>
  </svg>
</template>

<script>
export default {
  name: 'DefaultUserIcon',
  props: {
    color: {
      type: String,
      default: '#B6BEC7'
    }
  }
}
</script>

<style>

</style>
