import { render, staticRenderFns } from "./PartnersCommissionsHeader.vue?vue&type=template&id=a7e10408&scoped=true&"
import script from "./PartnersCommissionsHeader.vue?vue&type=script&lang=ts&"
export * from "./PartnersCommissionsHeader.vue?vue&type=script&lang=ts&"
import style0 from "./PartnersCommissionsHeader.vue?vue&type=style&index=0&id=a7e10408&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a7e10408",
  null
  
)

export default component.exports