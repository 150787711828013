






































































import BikyConfigChatMessage from './BikyConfigChatMessage'

export default BikyConfigChatMessage
