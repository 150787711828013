export enum ConfigurationTabsEnum {
    GENERAL = 'general',
    TEAM = 'team',
    COMPANY_INFO = 'companyInfo',
    APP_INFO = 'appInfo',
    CONNECT_CHANNELS = 'connectChannels',
    CATALOG = 'catalog',
    SCHEDULE = 'schedule',
    AUTOASSIGN = 'autoassign',
    AUTOMATIC_CLOSING = 'automaticClosing',
    TAGS = 'tags',
    QUICK_RESPONSES = 'quickResponses',
    MY_QUICK_RESPONSES = 'myQuickResponses',
    WEB_WIDGET = 'webWidget',
    STATUSES = 'statuses',
    ROLES = 'roles',
    ROLES_V6 = 'rolesV6',
    TASK_TYPES = 'taskTypes',
    EXTRADATA = 'extraData',
    DOMAINS = 'outbound',
    PIPELINES = 'pipelines',
    BRANDS = 'brands',
    OLD_CHANNELS = 'oldChannels',
    KEYBE_AI = 'KeybeAI',
    COMMISSIONS = 'commissions',
    // WELCOME_MESSAGE = 'welcomeMessage',
    API_KEYS ='apiKeys',
    CDP_FIELDS = 'cdpFields',
    FUNNEL_FIELDS = 'funnelFields',
    PRIVACY_SECURITY = 'privacySecurity',
    SMARTOMETER = 'smartometer',
    INBOX = 'inbox',
    BIKY = 'biky',
    PERMISSION_TYPES = 'permissionTypes',
    TEMPLATES = 'templates',
    SPACES = 'spaces',
    ACTIVITY_LOGS = 'activityLogs',
    WIDGET_TESSERACT = 'CreateFormConfig',
    NEW_CHANNELS = 'newChannels',
}
