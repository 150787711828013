import { IActivityLogsController, dataToGetActivityLogs, dataToWriteActivityLog } from '../domain/ActivityLogsController'

export default class ActivityLogsUseCases {
  static getActivityLogs (controller: IActivityLogsController, data: dataToGetActivityLogs): Promise<any> {
    return controller.getActivityLogs(data)
  }

  static writeActivityLog (controller: IActivityLogsController, data: dataToWriteActivityLog): Promise<any> {
    return controller.writeActivityLog(data)
  }

  static abortAll (controller: IActivityLogsController): void {
    controller.abortAll()
  }
}
