import { SmartOMeterFeatures } from './SmartOMeterFeatures'
import { SmartOMeterTier, SmartOMeterTiers } from './SmartOMeterTiers'

/**
 * `SmartOMeterPermissions` is an object that maps SmartOMeter feature keys to an array of tiers that have access to those features.
 * Each key in this object is a string that represents a feature of the SmartOMeter.
 * The value associated with each key is an array of `SmartOMeterTier` enums, representing the tiers that have access to the corresponding feature.
 *
 * For example, the `TESSERACT_SUGGESTIONS` feature is only available to the `TIER_4`.
 *
 * Here's a breakdown of the features and their access tiers:
 *
 * - `TESSERACT_SUGGESTIONS`: Available to `TIER_4`
 * - `CORTEX_SUGGESTIONS`: Available to `TIER_3`
 * - `KNOWLEDGE_BASE`: Available to `TIER_3` and `TIER_4`
 * - `NEURAL_SUGGESTIONS`: Available to `TIER_2`
 * - `SENTIMET_ANALYSIS`: Available to `TIER_2`, `TIER_3`, and `TIER_4`
 * - `ACTION_SUGGESTIONS`: Available to `TIER_1`, `TIER_2`, and `TIER_3`
 * - `UNASWERED_WAITING_TIMER`: Available to `TIER_3` and `TIER_4`
 */

export const SmartOMeterPermissions: {
  [key: string]: SmartOMeterTier[]
} = {
  [SmartOMeterFeatures.TESSERACT_SUGGESTIONS]: [SmartOMeterTiers.TIER_3, SmartOMeterTiers.TIER_4, SmartOMeterTiers.TIER_5, SmartOMeterTiers.TIER_6],
  [SmartOMeterFeatures.CORTEX_SUGGESTIONS]: [SmartOMeterTiers.TIER_2],
  [SmartOMeterFeatures.KNOWLEDGE_BASE]: [SmartOMeterTiers.TIER_3, SmartOMeterTiers.TIER_4, SmartOMeterTiers.TIER_5, SmartOMeterTiers.TIER_6],
  [SmartOMeterFeatures.SENTIMET_ANALYSIS]: [
    SmartOMeterTiers.TIER_2,
    SmartOMeterTiers.TIER_3,
    SmartOMeterTiers.TIER_4,
    SmartOMeterTiers.TIER_5,
    SmartOMeterTiers.TIER_6
  ],
  [SmartOMeterFeatures.ACTION_SUGGESTIONS]: [
    SmartOMeterTiers.TIER_1,
    SmartOMeterTiers.TIER_2,
    SmartOMeterTiers.TIER_3,
    SmartOMeterTiers.TIER_4,
    SmartOMeterTiers.TIER_5,
    SmartOMeterTiers.TIER_6
  ],
  [SmartOMeterFeatures.UNASWERED_WAITING_TIMER]: [SmartOMeterTiers.TIER_3, SmartOMeterTiers.TIER_4],
  [SmartOMeterFeatures.BIKY_CONFIG]: [SmartOMeterTiers.TIER_4, SmartOMeterTiers.TIER_3, SmartOMeterTiers.TIER_5, SmartOMeterTiers.TIER_6],
  [SmartOMeterFeatures.BIKY_ERRORS_REPORT]: [SmartOMeterTiers.TIER_4, SmartOMeterTiers.TIER_3, SmartOMeterTiers.TIER_5, SmartOMeterTiers.TIER_6],
  [SmartOMeterFeatures.BIKY_SIDEBAR_ITEM]: [SmartOMeterTiers.TIER_4, SmartOMeterTiers.TIER_3, SmartOMeterTiers.TIER_2, SmartOMeterTiers.TIER_5, SmartOMeterTiers.TIER_6],
  [SmartOMeterFeatures.NEURAL_SUGGESTIONS]: [SmartOMeterTiers.TIER_2, SmartOMeterTiers.TIER_3, SmartOMeterTiers.TIER_4, SmartOMeterTiers.TIER_5, SmartOMeterTiers.TIER_6]
}
