export const ChatConfigStore = {
  namespaced: true,
  state: {
    config: null
  },
  mutations: {
    SET_CONFIG (state, config) {
      state.config = config
    }
  },
  actions: {
    setConfig ({ commit }, config) {
      commit('SET_CONFIG', config)
    }
  },
  getters: {
    getConfig (state) {
      return state.config
    },
    hasInboxConfig (state) {
      return state.config && state?.config?.inbox?.useInbox
    }
  }
}
