import VideoPlayer from '@/shared/infrastructure/ui/players/VideoPlayer.vue'

export default {
  components: {
    VideoPlayer
  },
  props: {
    block: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    mediaBackground (): string {
      return `${this.block?.file?.url || ''}`
    }
  },
  data () {
    return {}
  }
}
