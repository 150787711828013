<template>
  <div v-click-outside="close"
       :key="`select-${rerender}`"
       class="KBSelect"
       :class="{ 'KBSelect--open': showSelector,
                 'w-full': !transparent,
                 'w-auto': transparent }">
    <div class="KBSelect__container--seamless"
         :class="{ 'cursor-default': disabled }"
         v-if="transparent"
         @click="openSelector">
      <div v-if="isStatus && selectedStatusColor"
           :style="{ background: selectedStatusColor }"
           class="status-dot mr-2"/>
      <div
        class="KBSelect__iconContainer"
        v-if="icon"
        :style="{ background: iconContainerColor || 'black' }"
      >
        <div
          class="KBSelect__iconContainer--icon"
        >
          <KeybeIcon
            :icon="icon"
            :color="iconColor || 'white'"
          />
        </div>
      </div>
      <span class="seamless-text"
            v-if="selectedItem">{{ $t(itemToShow) }}</span>
      <span v-else
            class="seamless-text">{{ placeholder }}</span>
      <span class="mdi mdi-menu-down"
            v-if="!loading"/>
      <lottie-animation
        v-else
        ref="anim"
        class="loaderAnimation"
        :loop="true"
        style="width: 30px"
        :animationData="require('@/shared/assets/animations/circleLoader.json')"
      />
    </div>
    <div class="KBSelect__container"
         v-else
         @click="openSelector"
         :class="{ 'KBSelect__container--transparent': seamless,
                   'KBSelect__container--transparent-open': showSelector && seamless,
                   'cursor-default': disabled }">
      <div v-if="isStatus && selectedStatusColor"
           :style="{ background: selectedStatusColor }"
           class="status-dot mr-2"/>
      <div
        class="KBSelect__iconContainer"
        v-if="icon"
        :style="{ background: iconContainerColor || 'black' }"
      >
        <div
          class="KBSelect__iconContainer--icon"
        >
          <KeybeIcon
            :icon="icon"
            :color="iconColor || 'white'"
          />
        </div>
      </div>
      <span v-if="selectedItem"
            :class="{ 'selected--dark': $vuetify.theme.dark }">{{ $t(itemToShow) }}</span>
      <span v-else
            class="placeholder">{{ placeholder }}</span>
      <span class="mdi mdi-menu-down"
            v-if="!loading"/>
      <lottie-animation
        v-else
        ref="anim"
        class="loaderAnimation"
        :loop="true"
        style="width: 30px"
        :animationData="require('@/shared/assets/animations/circleLoader.json')"
      />
    </div>
    <div ref="list"
         class="KBSelect__list newBackground"
         :class="{ 'KBSelect__list--open': showSelector }">
      <div v-for="(item, index) in items"
           :key="index"
           class="list-item"
           :class="{ 'list-item--selected': isSelected(item) }"
           @click="selectItem(item)">
        <div v-if="isStatus"
             :style="{ background: item.mainColor ? item.mainColor : item.color ? item.color : '' }"
             class="status-dot mr-2"/>
        <div
          class="KBSelect__iconContainer"
          v-if="icon"
          :style="{ background: iconContainerColor || 'black' }"
        >
          <div
            class="KBSelect__iconContainer--icon"
          >
            <KeybeIcon
              :icon="icon"
              :color="iconColor || 'white'"
            />
          </div>
        </div>
        {{ isExtra ? item.name ? $t(item.name) : $t(item) : itemText ? $t(item[itemText]) : $t(item) }}
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import LottieAnimation from 'lottie-web-vue'
import KeybeIcon from '../../icons/KeybeIcon.vue'

// Vue.directive('click-outside', {
//   bind: function (el, binding, vnode) {
//     this.event = function (event) {
//       if (!(el === event.target || el.contains(event.target))) {
//         vnode.context[binding.expression](event)
//       }
//     }
//     document.body.addEventListener('click', this.event)
//   },
//   unbind: function (el) {
//     document.body.removeEventListener('click', this.event)
//   }
// })
export default {
  props: [
    'items', 'value', 'placeholder',
    'itemText', 'itemValue', 'returnObject',
    'seamless', 'transparent', 'disabled',
    'isStatus', 'statusColor', 'loading', 'isExtra', 'bottom',
    'icon', 'iconColor', 'iconContainerColor'],
  components: {
    LottieAnimation,
    KeybeIcon
  },
  computed: {
    ...mapGetters('AppStore', ['isCDPApp']),
    itemToShow () {
      if (this.itemValue) {
        const item = this.items.filter(item => item[this.itemValue] === this.selectedItem)
        if (item && item[0]) return item[0][this.itemText]
        else return this.placeholder
      } else if (this.returnObject) {
        return this.selectedItem[this.itemText] || this.placeholder
      } else if (this.itemText) return this.selectedItem[this.itemText] || this.placeholder
      else return this.selectedItem
    }
  },
  mounted () {
    setTimeout(() => {
      if (this.value) {
        if (this.returnObject) {
          if (this.isStatus && this.value) {
            let filter = []
            if (this.isCDPApp) filter = this.items.filter(item => item._id === this.value)
            else filter = this.items.filter(item => item.id === this.value)
            if (filter?.length > 0) this.selectedItem = filter[0]
            this.selectedStatusColor = this.statusColor
          } else if (this.isStatus && !this.value.id) this.selectedItem = null
          else if (!this.isStatus) this.selectedItem = this.value
        } else if (this.itemValue) this.selectedItem = this.value
        else this.selectedItem = this.value
        this.rerender++
      }
    }, 600)
  },
  data () {
    return {
      rerender: 0,
      selectedItem: null,
      showSelector: false,
      selectedStatusColor: ''
    }
  },
  methods: {
    selectItem (item) {
      if (this.returnObject) this.selectedItem = item
      else if (this.itemValue) this.selectedItem = item[this.itemValue]
      else this.selectedItem = item
      if (this.isStatus) {
        this.selectedStatusColor = this.isCDPApp ? item.color : item.mainColor
      }
      this.showSelector = false
      this.$emit('input', this.selectedItem)
      this.$emit('save', this.selectedItem)
    },
    isSelected (item) {
      if (this.itemValue) {
        const found = this.items.filter(i => i[this.itemValue] === this.selectedItem)[0]
        if (found) return true
      } else {
        return this.selectedItem === item
      }
    },
    openSelector () {
      if (!this.disabled) this.showSelector = !this.showSelector
    },
    close () {
      this.showSelector = false
    }
  },
  watch: {
    showSelector () {
      this.$emit('open', this.showSelector)
      if (!this.bottom) {
        if (!this.showSelector) return
        const list = this.$refs.list
        if (list) {
          // get list position
          const listPos = list?.getBoundingClientRect()
          // get window height
          const windowHeight = window?.innerHeight
          // get list height
          const listMaxHeight = 212
          // get list top position
          const listTop = listPos?.top
          // if list go out of window
          // console.log('listTop', listTop)
          // console.log('listMaxHeight', listMaxHeight)
          // console.log('windowHeight', windowHeight)
          const listBottom = listTop + listMaxHeight

          if (listBottom > windowHeight) {
            // set list position to top
            list.style.bottom = '39px'
          } else {
            list.style.bottom = 'auto'
          }
        }
      }
    }
  }
}
</script>
<style scoped lang="scss">
.KBSelect {
  box-shadow: rgba(182, 190, 199, 0);
  transition: box-shadow 0.5s;
  max-height: 265px;
  position: relative;

  &__iconContainer {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;

    &--icon {
      width: 16px;
    }
  }

  &__container {
    background: rgba(182, 190, 199, 0.2);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 20px;
    height: 41px;
    cursor: pointer;
    border-radius: 5px;

    .placeholder {
      color: $simple-grey;
    }
  }

  &__container--transparent {
    background: rgba(182, 190, 199, 0.18);
    padding: 0 30px;
    cursor: pointer;
    transition: background 0.4s ease;
  }

  &__container--transparent:hover {
    background: rgba(182, 190, 199, 0.2);
  }

  &__container--transparent-open {
    padding: 0 30px;
    cursor: pointer;
    background: rgba(182, 190, 199, 0.2);
  }

  &__container--seamless {
    display: flex;
    align-items: center;
    width: auto !important;

    .placeholder {
      color: #B6BEC7;
    }

    .seamless-text {
      margin-right: 5px;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  &__list {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.4s ease;
    position: absolute;
    z-index: 10;
    width: inherit;

    .list-item {
      height: 53px;
      padding: 0px 30px;
      cursor: pointer;
      display: flex;
      align-items: center;
      width: auto;
      white-space: nowrap;
    }

    .list-item--selected,
    .list-item:hover {
      background: rgba(182, 190, 199, 0.2);
    }
  }

  &__list--open {
    overflow: overlay;
    overscroll-behavior: contain;
    max-height: 212px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  }
}

.KBSelect--open {
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
}

.selected--dark {
  color: $simple-grey !important;
}

.status-dot {
  height: 10px;
  width: 10px;
  min-height: 10px;
  min-width: 10px;
  border-radius: 50%;
}
</style>
