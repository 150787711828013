<template>
  <div v-click-outside="close"
       class="d-flex flex-column relative w-full">
    <div
      class="KBAutocomplete"
      :class="{
        'KBAutocomplete--open': showSelector,
        'KBAutotomplete--outlined': outlined,
        'KBAutocomplete--small': small,
      }"
      :key="rerenderLocal"
    >
      <div
        class="KBAutocomplete__container"
        :class="{
          'KBAutocomplete__container--outlined': outlined,
          'KBAutocomplete__container--seamless': seamless,
          'KBAutocomplete__container--focused': focused,
          'KBAutocomplete__conainer--small': small,
        }"
      >
        <span v-if="icon"
              :class="`mdi ${icon} icon`" />
        <input
          class="search"
          type="text"
          :placeholder="placeholder"
          v-model="search"
          autocomplete="text"
          @keypress="keyType"
          @focus="focused = true"
          @blur="focused = false"
          @click="showSelector = true"
          :disabled="disabled"
        />
        <div v-if="!loading"
             class="d-flex items-center"
             @click="openSelector">
          <KBIconButton
            :icon="'mdi-close'"
            :size="'22px'"
            class="mr-2"
            v-if="search"
            @click="clearSearch"
          />
          <span class="mdi mdi-magnify search-icon" />
        </div>
        <lottie-animation
          v-else
          ref="anim"
          class="loaderAnimation"
          :loop="true"
          style="width: 30px"
          :animationData="
            require('@/shared/assets/animations/circleLoader.json')
          "
        />
      </div>
    </div>
    <div
      class="KBAutocomplete__list newBackground"
      :class="{
        'KBAutocomplete__list--small': small,
      }"
      :style="`top: ${outlined ? '40px' : small ? '30px' : '53px'}; ${
        customZIndex ? `z-index: ${customZIndex}` : ''
      }`"
      v-if="showSelector"
    >
      <div
        v-for="(item, index) in itemsComputed"
        :key="index"
        class="list-item"
        :class="{ 'list-item--selected': isSelected(item) }"
        @click="selectItem(item)"
      >
        {{
          itemText
            ? multipleText
              ? item[itemText[0]] && item[itemText[1]]
                ? item[itemText[0]] +
                  " " +
                  (item[itemText[1]] ? item[itemText[1]] : "")
                : item[itemText[0]]
              : item[itemText]
            : item
        }}
      </div>
    </div>
  </div>
</template>
<script>
import KBAutocomplete from './KBAutocomplete'

export default KBAutocomplete
</script>
<style scoped lang="scss">
@import "./KBAutocomplete.scss";
</style>
