export enum GraphqlEventTypes {
    CLOSE_SESSION = 'closeSession',
    TASK_NOTIFICATIONS = 'tasks-notification'
}

export interface onUser {
    userUUID: string;
    deviceToken: string;
    message: string;
    type: string;
    appUUID: string;
    action: string;
    payload: string;
}

export interface EventSuscriptionGetter {
    data: {
        onUser: onUser
    },
    status: boolean
}
