<template>
  <div class="contentList">
    <Loader v-if="loadingTemplateList"/>
    <div class="optionPreveiw"
         v-else>
      <span class="inputLabel pt-3 noselect">{{ $t('chooseTemplate') }}</span>
      <div class="d-flex items-center">
        <KeybeButton
          class="my-2"
          style="width: 100%;"
          color="black"
          textColor="white"
          :disabled="isUserReadonly"
          @click="editTemplate()"
          :text="$t('createTemplate')"/>
        <KeybeSelect
          class="mx-3"
          style="min-width: 290px;"
          :value="templateSelect"
          :items="templateList"
          :itemText="'name'"
          :itemValue="'_id'"
          @input="selectTemplate"/>
        <KeybeButton
          class="my-2"
          style="width: 100%;"
          color="#3af69b"
          textColor="black"
          :disabled="isUserReadonly"
          @click="editTemplate(templateSelect)"
          :text="$t('edit')"/>
      </div>
    </div>
    <div class="Contentpreview"
         v-if="!loadingTemplateList">
      <div class="preview">
        <EmailTemplateCreatorHomeModal class="mt-2"
                                       :id="idTemplate"
                                       :activeModal="true" />
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from '@/eventBus'
import TemplateService from '@/services/TemplateService'
import EmailTemplateCreatorHomeModal from '@/modules/outbound/infrastructure/ui/components/forms/EmailTemplateCreatorHomeModal.vue'
import Loader from '@/shared/infrastructure/ui/Loader.vue'
import KeybeSelect from '@/modules/DesignSystem/infrastructure/components/KeybeSelect/KeybeSelect/KeybeSelect.vue'
import KeybeButton from '@/modules/DesignSystem/infrastructure/components/KeybeButton/KeybeButton.vue'
import { mapState } from 'vuex'
export default {
  components: {
    EmailTemplateCreatorHomeModal,
    KeybeButton,
    KeybeSelect,
    Loader
  },
  props: {
    emailTemplates: {
      type: Boolean,
      default: false
    },
    actionDisabled: {
      type: Boolean,
      default: false
    },
    count: {
      type: Number,
      default: 0
    }
  },
  computed: {
    ...mapState('UserStore', ['isUserReadonly'])
  },
  created () {
    this.getTemplates()
  },
  data () {
    return {
      templateSelect: null,
      activeModal: false,
      idTemplate: null,
      modal: false,
      tab: null,
      options: {
        limit: 500,
        itemsPerPage: 500,
        page: 1,
        sortBy: ['updatedAt'],
        sortDesc: [true]
      },
      searchTemplateList: '',
      templateList: [],
      totalTemplateList: 0,
      loadingTemplateList: false
    }
  },
  methods: {
    selectTemplate (templateId) {
      if (this.idTemplate !== templateId) {
        this.idTemplate = templateId
        this.activeModal = true
        this.templateSelect = templateId
        // document.querySelector('iframe').style.width = '368px'
        // document.querySelector('iframe').style.minWidth = '368px'
      }
    },
    previewTemplate (templateId) {
      if (this.idTemplate !== templateId) {
        this.idTemplate = templateId
        this.activeModal = true
      }
    },
    async getTemplates () {
      try {
        // if (this.emailTemplates) {
        //   this.options.itemsPerPage = 500
        //   this.options.limit = 500
        // }
        this.loadingTemplateList = true
        const pagination = {
          itemsPerPage: this.options.itemsPerPage,
          limit: this.options.limit,
          descending: this.options.sortDesc[0],
          page: this.options.page,
          sortBy: this.options.sortBy[0]
        }
        const response = await TemplateService.getTemplatesV6(
          pagination,
          this.searchTemplateList
        )
        this.templateList = response.rows
        this.totalTemplateList = response.count
        this.loadingTemplateList = false
      } catch (e) {
        this.templateList = []
        this.totalTemplateList = 0
        this.loadingTemplateList = false
      }
    },
    editTemplate (templateId) {
      if (!this.actionDisabled) {
        EventBus.$emit('campaignTemplateEditor', templateId)
        if (templateId) {
          this.$emit('campaignTemplateEditor', templateId)
        } else {
          this.$emit('campaignTemplateEditor')
        }
      } else {
        EventBus.$emit('toast', 'error', this.$t('msgErrorForm'))
      }
    },
    createTemplate () {
      this.$emit('campaignTemplateEditor')
      EventBus.$emit('campaignTemplateEditor')
    }
  },
  watch: {
    searchTemplateList () {
      this.options.page = 1
      this.getTemplates()
    },
    count () {
      if (this.count) {
        this.getTemplates()
      }
    }
  }
}
</script>
  <style lang='scss' scoped>
  .opacityContent{
    opacity: 0.5;
  }
  .emailTemplate{
    height: 50vh;
    overflow: scroll;
    margin-top: 5%
  }
  .v-card__title.card__templateName{
    font-size: 10px !important;
    height: 56px;
    word-break: break-word !important;
    padding-left: 0;
    width: 55px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  .v-card__title.card__templateName p{
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
  }
  @media screen and (min-width: 1280px) {
    .row__margin{
      margin-right: 240px;
      margin-left: 240px;
    }
  }
  @media screen and (min-width: 768px) and (max-width: 958px) {
    .button__position{
      right: 64px;
    }
  }
  @media screen and (min-width: 958px) and (max-width: 1280px) {
    .button__position{
      right: 0;
    }
  }
  @media screen and (max-width: 768px) {
    .button__position{
      right: 20px;
    }
  }
  .contentList{
    height: 100%;
    overflow: hidden;
    width: 100%;
    display: flex;
    flex-direction: column;
    max-width: 1400px;
    margin-bottom: 20px;

  }
  .contentListTemplate{
        overflow-y: scroll;
        height: 130%;
        width: 100%;
        display: flex;
        justify-content: start;
        align-items: start;
        flex-direction: column;
    .child-flex {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 33.3333333333%;
        flex: 0 0 33.3333333333%;
        max-width: 33.3333333333%;
        .cardImg{
          cursor: pointer;
          width: 100%;
          margin: 5px;
          .contentTitle{
            max-width: 215px;
            height: 30px;
            overflow-y: scroll;
              span{
                font-size: 10px !important;
                padding-left: 10px;
                font-weight: 300;
              }
          }
        }
    }
    }
.v-card-outlined{
  display: flex;
  justify-content: start;
  align-items: center;
  border-radius: 10px;
  padding: 10px;
  cursor: pointer;
  width: 100%;
  transition: 1s;
  height: 46px;
  background-color: #e2e6e9;
  overflow: hidden !important;
}

.v-card-outlined-dark{
  padding: 10px;
  cursor: pointer;
  width: 100%;
  transition: 1s;
  height: 46px;
  background-color: #36383a;
  overflow-y: hidden !important;
}

.v-card-outlined-dark:hover{
  border: 1px solid rgba(83, 68, 68, 0.473);
  cursor: pointer;
  background-color: #2e343b !important;
}

.v-card-outlined:hover{
  // overflow-y: hidden !important;
  // height: 500px;
  border: 1px solid rgba(128, 128, 128, 0.473);
  // padding: 10px;
  cursor: pointer;
  background-color: #e2e6e9;
}
.card__templateName{
  width: 100%;
  height: 100%;
  max-width: 650px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 12px !important;
  /* height: 56px; */
  word-break: break-word !important;
  margin-left: 10px;
  transition: height 0.5s;
  -webkit-transition: height 0.5s;
  transition: 1.5s;
  .option{
    display: flex;
    justify-content: space-between;
    align-items: center;
    .KBButton{
      height: 30px;
    }
    div{
      display: flex;
      padding-right: 5px;
    }
  }
}

.containerFloating {
  height: 64vh !important;
  overflow-y: auto !important;
}

.card__templateName:hover .emailTemplate_container {
  height: 400px;
}

.card__templateName p{
  margin: 0;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
}
.selectitem{
  background-color: #3AF69B;
  height: 46px;
  display: flex;
  justify-content:center;
  align-items: center;
  border-radius: 10px;
  .card__templateName{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content:center;
    align-items: stretch;
  }
  .KBButton{
    span {
      color: black !important;
    }
  }
  p{
    padding-left: 10px;
    font-weight: 400;
    color:black !Important;
  }
}
.selectitem:hover{
  border: solid #EEF1F5 1px;
  background-color: #3AF69B;
}
.preview{
    height: 100%;
    min-height: 1000px;
    .emailTemplate_container {
      width: 670px !important;
      zoom: 105%
    }
  }
  .optionPreveiw{
    margin-right:20px;

}

  // ::v-deep .container__editor {
  //   height: 570px !important;
  // }
</style>
