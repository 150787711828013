import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'
import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import KBButton from '@/shared/infrastructure/ui/buttons/KBButton.vue'
import Colors from '@/shared/domain/colors/Color'
import { EventBus } from '@/eventBus'

export default {
  name: 'OldPlan',
  components: {
    KeybeIcon,
    KBButton
  },
  data () {
    return {
      KeybeIconType,
      Colors
    }
  },
  methods: {
    changePlan () {
      console.log('changePlan')

      EventBus.$emit('Consumptions-change-plan', true)
    }
  }
}
