import Colors from '@/utils/Colors'
import countries from '@/utils/countries'
import KeybeAutocomplete from '@/modules/DesignSystem/infrastructure/components/KeybeAutocomplete/KeybeAutocomplete/KeybeAutocomplete.vue'
import { CDPFieldsEnums } from '@/modules/CDP/shared/contacts/domain/enums/CDPFieldsEnums'
export default {
  components: {
    KeybeAutocomplete
  },
  props: {
    importer: {
      type: Object
    }
  },
  mounted () {
    countries.forEach(country => {
      this.countries.push({
        text: `(+${country.number}) ${country.country}`,
        number: country.number
      })
    })
  },
  data () {
    return {
      Colors,
      countries: [],
      CDPFieldsEnums
    }
  },
  methods: {
    emptyHeader (header) {
      return !header.field
    },
    setCountryCode (code) {
      this.$emit('countryCode', code)
    }
  }
}
