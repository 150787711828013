import {
  IQuickResponsesController,
  dataToCreateQuickResponse,
  dataToGetQuickResponses,
  quickResponseCreator,
  quickResponsesGetter,
  dataToDeleteQuickResponse
} from '../../domain/QuickResponsesController'
import QuickResponsesRepository from '../repositories/QuickResponses.repository'

export default class QuickResponsesController implements IQuickResponsesController {
  repository: QuickResponsesRepository

  constructor () {
    this.repository = new QuickResponsesRepository()
  }

  abort (): void {
    this.repository.abortGetSuggestions()
  }

  async getQuickResponses (data: dataToGetQuickResponses): Promise<quickResponsesGetter> {
    const response = await this.repository.getQuickResponses(data)
    if (response.status) return { data: response.data, status: response.status }
    return { message: response.message, status: response.status }
  }

  async createQuickResponse (data: dataToCreateQuickResponse): Promise<quickResponseCreator> {
    const response = await this.repository.createQuickResponse(data)
    if (response.status) return { status: response.status }
    return { message: response.message, status: response.status }
  }

  async deleteQuickResponse (data: dataToDeleteQuickResponse): Promise<quickResponseCreator> {
    const response = await this.repository.deleteQuickResponse(data)
    if (response.status) return { status: response.status }
    return { message: response.message, status: response.status }
  }
}
