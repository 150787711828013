<template>
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 11 14"><path :fill="color" d="M10.225 12.26h.145V4.453c-.002-.373-.158-.681-.413-.94C8.943 2.485 7.926 1.46 6.909.433a1.409 1.409 0 0 0-1.037-.43c-1.352 0-2.704-.01-4.056.009C1.536.016 1.234.08.983.2.33.52.01 1.078.008 1.814c-.002 1.368 0 8.935 0 10.46H0l.008.057c0 .046.01.091.015.136h.004c.01.082.022.16.045.234.233.799.891 1.295 1.734 1.296 2.253.004 4.506.004 6.759 0 .456-.002.878-.133 1.222-.453.365-.34.56-.761.578-1.277H9.53c-.018.098-.026.182-.051.262-.124.4-.427.604-.901.604H1.892c-.096 0-.192.001-.288-.01-.237-.029-.447-.122-.57-.336-.074-.13-.125-.272-.183-.416V1.886c.001-.694.32-1.017 1-1.017H5.2v.24c0 .905.003 1.811-.002 2.717-.002.332.068.642.27.905.281.368.676.51 1.123.513.907.004 1.815.001 2.722.001h.22v7.015h.693Zm-.802-7.882c-.967 0-1.935.004-2.902-.001-.292-.002-.424-.132-.465-.423a1.228 1.228 0 0 1-.01-.164V.863c.097.07.172.112.231.171 1.035 1.043 2.067 2.087 3.099 3.132.05.05.087.114.149.197-.058.008-.08.015-.102.015Z"/></svg>
</template>

<script>
export default {
  name: 'DocumentIcon',
  props: {
    color: {
      type: String,
      default: '#B6BEC7'
    }
  }

}
</script>

<style>

</style>
