










































































































































































































































































































import BikyConfigBasic from './BikyConfigBasic'

export default BikyConfigBasic
