






















































































import MicrositesService from '@/services/MicrositesService'
import operation from '@/utils/operations'
import MicroLink from '@/modules/microsites/infrastructure/ui/components/Microsite/MicroLink.vue'
import PhotoUpload from '@/modules/microsites/infrastructure/ui/components/MicrositePhoto.vue'
import Link from '@/utils/links'

export default {
  components: {
    MicroLink,
    PhotoUpload
  },
  data () {
    return {
      links: [],
      linksSocial: [],
      typesLinks: [
        'link',
        'whatsapplink'
      ],
      showButtons: false
    }
  },
  mounted () {
    this.links = this.getLinks()
    this.linksSocial = this.getLinksSocial()
    this.showButton()
  },
  computed: {
    cssVars () {
      return {
        '--main-color': this.color
      }
    },
    imgKb () {
      return `${process.env.VUE_APP_STORAGE_URL}/files/bluebird/Microsites/kb.svg`
    },
    moneyImg () {
      return `${process.env.VUE_APP_STORAGE_URL}/files/bluebird/Microsites/money-sign.svg`
    },
    iconPointImg () {
      return `${process.env.VUE_APP_STORAGE_URL}/files/bluebird/microsites/icon-point.svg`
    },
    baseMicrositesUrl () {
      return process.env.VUE_APP_MICROSITES_BASE_URL
    }
  },
  methods: {
    showButton () {
      this.links.forEach(link => {
        if (this.areEqual(link.id, 'whatsappKeybe') && !this.value.config.whatsappKeybe) link.show = false
        else link.show = true
      })
    },
    areEqual (a, b): boolean {
      return operation.areEqual(a, b)
    },
    getLink (link: {url: string, id: string}) {
      if (this.areEqual(link.id, 'whatsapp')) {
        return `${Link.whatsappLink}${link.url}`
      }
      return link.url
    },
    getLinksIdentified () {
      const links = [...JSON.parse(JSON.stringify(this.value.config.socialLinks))].filter(link => this.areEqual(link.url, ''))
      return links
    },
    getLinks () {
      // Links are main buttons
      // console.log('Getting links', this.value.config.socialLinks.filter(link => !link.tipoIcon))
      return this.value.config.socialLinks.filter(link => !link.tipoIcon)
    },
    getLinksSocial () {
      // Links social are secondary buttons
      return this.value.config.socialLinks.filter(link => link.tipoIcon)
    },
    formatMoney (price, currency) {
      const formaterByCode = {
        COP: 'es-ES',
        USD: 'en-US'
      }
      currency = currency.toUpperCase()
      const formatter = new Intl.NumberFormat(formaterByCode[currency] ?? 'en-US', {
        style: 'currency',
        currency
      })

      return formatter.format(price)
    },
    editSocials () {
      this.$emit('switchMSP', 'MicrositesIconSelector')
    },
    editConfig (editInfo) {
      // Look if you can lift this into Microsite
      const newConfig = { ...this.value.config }
      if (editInfo.editing === 'mainButton') {
        const mainButtons = this.value.config.socialLinks.filter(link => !link.tipoIcon)
        const buttonToEdit = mainButtons.findIndex(button => button.description === editInfo.ogDescription)
        console.log('Found button to edit', buttonToEdit)
        newConfig.socialLinks[buttonToEdit] = { ...newConfig.socialLinks[buttonToEdit], ...editInfo.newData }
      }
      MicrositesService.editConfig(newConfig)
    }
  },
  watch: {
    'value.config.socialLinks': {
      handler () {
        this.links = this.getLinks()
        this.linksSocial = this.getLinksSocial()
      },
      deep: true
    }
  },
  filters: {
    filterSiteUrl (val) {
      if (val.description) return val.description
      return val.url.replace('https://', '').replace('http://', '')
    }
  },
  props: {
    value: {
      required: true,
      type: Object
    },
    color: {
      required: true,
      type: String,
      default: '#FFFFFF'
    }
  }
}
