





















import InboxConfig from './InboxConfig'

export default InboxConfig
