import AutoConnectFacebookConfig from '@/modules/configuration/infrastructure/ui/components/NewChannels/AutoConnectConfig/AutoConnectFacebookConfig/AutoConnectFacebookConfig.vue'
import AutoConnectInstagramConfig from '@/modules/configuration/infrastructure/ui/components/NewChannels/AutoConnectConfig/AutoConnectInstagramConfig/AutoConnectInstagramConfig.vue'
import AutoConnectWhatsappConfig from '@/modules/configuration/infrastructure/ui/components/NewChannels/AutoConnectConfig/AutoConnectWhatsappConfig/AutoConnectWhatsappConfig.vue'
import AutoConnectWebConfig from '@/modules/configuration/infrastructure/ui/components/NewChannels/AutoConnectConfig/AutoConnectWebConfig.vue'
import AutoConnectFormsConfig from '@/modules/configuration/infrastructure/ui/components/NewChannels/AutoConnectConfig/AutoConnectFormsConfig/AutoConnectFormsConfig.vue'
import WhatsappBspConfig from '@/components/configuration/conversations/channels/WhatsappBspConfig.vue'
import ChannelRocketIcon from '@/shared/infrastructure/ui/icons/SvgComponents/ChannelRocketIcon.vue'
import KeybeButton from '@/modules/DesignSystem/infrastructure/components/KeybeButton/KeybeButton.vue'
import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import FacebookMessengerConfig from '@/components/configuration/conversations/channels/FacebookMessengerConfig.vue'
import InstagramConfig from '@/components/configuration/conversations/channels/InstagramConfig.vue'
import WebChannelConfig from '@/components/configuration/conversations/channels/WebChannelConfig.vue'

export default {
  name: 'AutoConnectConfig',
  props: {
    channels: {
      type: Object,
      required: true
    }
  },
  components: {
    KeybeButton,
    ChannelRocketIcon,
    AutoConnectFacebookConfig,
    AutoConnectInstagramConfig,
    AutoConnectWhatsappConfig,
    AutoConnectWebConfig,
    WhatsappBspConfig,
    FacebookMessengerConfig,
    InstagramConfig,
    AutoConnectFormsConfig,
    WebChannelConfig
  },
  data () {
    return {
      menu: false,
      hasWhatsapp: false,
      hasInstagram: false,
      hasFacebook: false,
      whatsappConfigs: [],
      facebookConfig: [],
      instagramConfig: [],
      KeybeIconType
    }
  },
  computed: {
    areAllEmpty () {
      return this.whatsappConfigs.length === 0 && this.facebookConfig.length === 0 && this.instagramConfig.length === 0
    }
  },
  methods: {
    handleOutsideClick (e) {
      if (this.$refs.menu && !this.$refs.menu.contains(e.target) && this.menu) {
        this.openOrCloseMenu()
      }
    },
    openOrCloseMenu () {
      this.menu = !this.menu
    },
    isWhatsapp (option) {
      return option === this.typeOptions.WHATSAPP
    },
    isInstagram (option) {
      return option === this.typeOptions.INSTAGRAM
    },
    isFacebook (option) {
      return option === this.typeOptions.FACEBOOK_MESSENGER
    }
  },
  watch: {
    channels: {
      immediate: true,
      handler () {
        // Facebook Messenger
        if (this.channels?.facebookMessenger) {
          this.hasFacebook = true
          this.facebookConfig = this.channels.facebookMessenger
        }
        // Instagram
        if (this.channels?.instagram) {
          this.hasInstagram = true
          this.instagramConfig = this.channels.instagram
        }
        // WhatsApp
        if (this.channels?.whatsapp) {
          this.whatsappConfigs = this.channels?.whatsapp?.accounts
        }
      }
    }
  },
  mounted () {
    this.boundHandleOutsideClick = this.handleOutsideClick.bind(this)
    document.addEventListener('click', this.boundHandleOutsideClick)
  },
  beforeDestroy () {
    document.removeEventListener('click', this.boundHandleOutsideClick)
  }
}
