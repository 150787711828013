import { GenericResponse } from '@/shared/infrastructure/http/GenericResponse'
import { VecindarioConfig } from '../../domain/VecindarioConfig'
import { IVecindarioConfigController, dataToCreateConfig, dataToCreateUser, dataToGetConfig, dataToGetConfigUrl, dataToUpdateConfig } from '../../domain/VecindarioConfigController'
import { VecindarioConfigRepository } from '../repositories/VecindarioConfig.repository'

export default class VecindarioConfigController implements IVecindarioConfigController {
  repository: VecindarioConfigRepository;

  constructor () {
    this.repository = new VecindarioConfigRepository()
  }

  async getConfig (data: dataToGetConfig): Promise<VecindarioConfig> {
    const response: GenericResponse = await this.repository.getConfig(data)
    if (!response.status) {
      return null
    }

    return response?.data?.config || null
  }

  async createVecindarioConfig (data: dataToCreateConfig): Promise<VecindarioConfig> {
    const response: GenericResponse = await this.repository.createVecindarioConfig(data)
    if (!response.status) {
      return null
    }

    return response?.data?.data?.config || null
  }

  async updateVecindarioConfig (data: dataToUpdateConfig): Promise<VecindarioConfig> {
    const response: GenericResponse = await this.repository.updateVecindarioConfig(data)
    if (!response.status) {
      return null
    }

    return response?.data?.data?.config || null
  }

  async getConfigUrl (data: dataToGetConfigUrl): Promise<string> {
    const response: GenericResponse = await this.repository.getConfigUrl(data)
    if (!response.status) {
      return null
    }

    return response?.data?.data?.url || null
  }

  async getCalendarUrl (data: dataToGetConfigUrl): Promise<string> {
    const response: GenericResponse = await this.repository.getCalendarUrl(data)
    if (!response.status) {
      return null
    }

    return response?.data?.data?.url || null
  }

  async getUserConfigUrl (data: dataToCreateUser): Promise<string> {
    const response: GenericResponse = await this.repository.getUserSchedulingUrl(data)
    if (!response.status) {
      return null
    }

    return response?.data?.data?.url || null
  }

  abortAll (): void {
    this.repository.abortAll()
  }
}
