<template>
  <svg :width="width"
       :height="height"
       viewBox="0 0 15 11"
       fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <path d="M4.76823 4.99174C5.64583 5.5 6.40625 6.17277 7.0026 6.9683C7.1849 7.21138 7.35156 7.46674 7.5 7.72946C7.64844 7.46429 7.8151 7.21138 7.9974 6.9683C8.59375 6.17277 9.35417 5.5 10.2318 4.99174C11.3958 4.31652 12.763 3.92857 14.2188 3.92857H14.4766C14.7656 3.92857 15 4.14955 15 4.4221C15 8.05603 11.8776 11 8.02344 11H7.5H6.97656C3.1224 11 0 8.05603 0 4.4221C0 4.14955 0.234375 3.92857 0.523438 3.92857H0.78125C2.23698 3.92857 3.60417 4.31652 4.76823 4.99174ZM7.85156 0.1375C8.26042 0.552455 9.44271 1.90045 10.0495 4.17902C9.0599 4.70937 8.1901 5.42634 7.5 6.2808C6.8099 5.42634 5.9401 4.71183 4.95052 4.17902C5.55469 1.90045 6.73698 0.552455 7.14844 0.1375C7.23958 0.0466517 7.36719 0 7.5 0C7.63281 0 7.76042 0.0466517 7.85156 0.1375Z"
          :fill="color"/>
  </svg>

</template>
<script>
export default {
  name: 'BeautyIcon',
  props: {
    width: {
      type: Number,
      default: 15
    },
    height: {
      type: Number,
      default: 11
    },
    color: {
      type: String,
      default: '#B6BEC7'
    }
  }
}
</script>
