import { mapState, mapGetters } from 'vuex'
import { EventBus } from '@/eventBus'
import KeybeAvatar from '@/shared/infrastructure/ui/KeybeAvatar.vue'
import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'
import KBIconButton from '@/shared/infrastructure/ui/buttons/KBIconButton.vue'
import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import { dataToUploadImage, userImageUpdater } from '../../../domain/UserController'
import UserUseCases from '../../../application/UserUseCases'
import EmitToast from '@/utils/EmitToast'
import UserController from '../../controller/User.controller'
export default {
  components: {
    KeybeAvatar,
    KBIconButton,
    KeybeIcon
  },
  props: {
    showTutorial: {
      type: Boolean
    }
  },
  computed: {
    ...mapState('UserStore', ['user', 'token']),
    ...mapState('AppStore', ['selectedApp']),
    ...mapGetters('UserStore',
      ['isAdmin',
        'isSuperAdmin',
        'isAgent',
        'isCoordinator']
    ),
    appName (): string {
      return this.selectedApp?.name || ''
    },
    userName (): string {
      if (this.user?.lastName) {
        return `${this.user?.name} ${this.user?.lastName}`
      }

      return this.user?.name || ''
    },
    userImg (): string {
      return this.user?.photo || ''
    },
    userType (): string {
      return this.user?.type || ''
    },
    userPhone (): string {
      return this.user?.phone || ''
    },
    userEmail (): string {
      return this.user?.email || ''
    },
    userIcon (): string {
      if (this.isAdmin) return KeybeIconType.ADMIN_USER
      if (this.isSuperAdmin) return KeybeIconType.SUPERADMIN_USER
      if (this.isAgent) return KeybeIconType.AGENT_USER
      if (this.isCoordinator) return KeybeIconType.COORDINATOR_USER
    }
  },
  mounted () {
    EventBus.$on('openUserImageUpload--general-config', this.startImageUpload.bind(this))
  },
  data () {
    return {
      userController: new UserController(),
      KeybeIconType
    }
  },
  methods: {
    startImageUpload (): void {
      this.openFileInput()
    },
    openFileInput (): void {
      if (this.$refs?.imageInput) this.$refs.imageInput.click()
    },
    async onFilePicked (event: any): Promise<void> {
      if (event?.target?.files && event?.target?.files?.length > 0) {
        const img: any = event.target.files[0]
        URL.createObjectURL(img)
        const data: dataToUploadImage = {
          img,
          uuid: this.user?.uuid,
          appId: this.selectedApp?.uuid,
          token: this.token
        }
        const response: userImageUpdater = await UserUseCases.uploadUserImage(this.userController, data)
        if (response.statusCode === 200) {
          EmitToast.emitSuccess(this.$t('profilePicUpdated'))
          this.$emit('nextStep')
        } else EmitToast.emitErrors(this.$t('errorUpdatingProfilePic'))
      }
    }
  },
  beforeDestroy () {
    EventBus.$off('openUserImageUpload--general-config', this.openFileInput.bind(this))
  }
}
