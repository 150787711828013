






















































import AdvisorConfigCard from './AdvisorConfigCard'

export default AdvisorConfigCard
