import { Task } from '@/modules/tasks/domain/Task'

import TaskCardHeader from '@/modules/tasks/infrastructure/ui/components/TaskCardHeader/TaskCardHeader.vue'
import TaskCardContent from '@/modules/tasks/infrastructure/ui/components/TaskCardContent/TaskCardContent.vue'
import TaskCardExpandedContent from '@/modules/tasks/infrastructure/ui/components/TaskCardExpandedContent/TaskCardExpandedContent.vue'

export default {
  name: 'TaskCard',
  props: {
    task: {
      required: true
    },
    expanded: {
      default: false,
      type: Boolean
    }
  },
  components: {
    TaskCardHeader,
    TaskCardContent,
    TaskCardExpandedContent
  },
  data () {
    return {
      showExpandedContent: false
    }
  },
  computed: {
    taskComputed () :Task {
      return this.task
    },
    taskCardClasses (): string {
      const classes = ['TaskCard', 'blackWhite']
      if (this.expanded) {
        classes.push('TaskCard__expanded')
      }
      return classes.join(' ')
    }
  },
  watch: {
    expanded (value: boolean) {
      if (!value) {
        this.showExpandedContent = false
        return
      }

      setTimeout(() => {
        this.showExpandedContent = true
      }, 300)
    }
  }
}
