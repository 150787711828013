import { VEmojiPicker } from 'v-emoji-picker'
import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'
import Colors from '@/shared/domain/colors/Color'
import TemplateSelector from '../TemplateSelector/TemplateSelector.vue'
import ParamsSelector from '../ParamsSelector/ParamsSelector.vue'
import MediaSelector from '../MediaSelector/MediaSelector.vue'
import TemplateService from '@/modules/templates/domain/Template.service'
import {
  TemplateComponentObjectType,
  TemplateComponentsEnum,
  TemplateHeaderFormatEnum,
  Template,
  TemplateButton
} from '@/modules/templates/domain/Template'
import KeybeText from '@/modules/DesignSystem/infrastructure/components/KeybeText/KeybeText.vue'
import { Component, TemplateButtonTypesEnum } from '@/modules/createTemplates/domain/Template'
import KeybeButton from '@/modules/DesignSystem/infrastructure/components/KeybeButton/KeybeButton.vue'
import TemplateComposerCard from '../TemplateComposerCard/TemplateComposerCard.vue'
import TemplateComposerProducts from '../TemplateComposerProducts/TemplateComposerProducts.vue'
export default {
  name: 'TemplateComposer',
  components: {
    KeybeIcon,
    VEmojiPicker,
    TemplateSelector,
    ParamsSelector,
    MediaSelector,
    KeybeText,
    KeybeButton,
    TemplateComposerCard,
    TemplateComposerProducts
  },
  props: {
    idTextArea: {
      type: String,
      required: true
    },
    idTemplateInput: {
      type: String,
      required: true
    },
    outboundType: {
      type: [Boolean, String],
      default: false
    },
    templates: {
      type: Array,
      default: () => []
    },
    params: {
      type: Array,
      default: () => []
    },
    userHost: {
      type: String
    }
  },
  data () {
    return {
      text: '',
      KeybeIconType,
      Colors,
      openEmojiPicker: false,
      selectedTemplate: null,
      selectedParam: null,
      isDefaultTemplate: false,
      showTemplateLabel: true,
      templateParams: [],
      headerParams: [],
      cardsParams: [],
      selectingTemplate: false,
      selectingParam: false,
      buttons: [],
      templateVariables: {
        templateMessage: '',
        templateId: '',
        templateParams: [], // body params
        buttonParams: [], // button params
        mediaParams: [], // media params
        headerParams: [], // header params
        cardsParams: [], // cards params for sequence templates
        mpmDetails: {}, // mpm details
        valid: false,
        error: ''
      },
      currentInput: null,
      currentCaretPosition: null,
      clipboardText: null,
      mpmDetails: {},
      TemplateService,
      TemplateButtonTypesEnum
    }
  },
  beforeDestroy () {
    this.destroyParamsEventListener()
  },
  methods: {
    setMPMDetails (mpmDetails: {[key: string]: any}): void {
      this.mpmDetails = mpmDetails
      this.handleTemplateEditing()
    },
    setCardMessage (index: number, e: string[]): void {
      if (this.cardsParams.length === 0) {
        while (this.cardsParams.length <= index) {
          this.cardsParams.push({
            headerCardParams: [],
            bodyCardParams: [],
            buttonCardParams: []
          })
        }
        this.cardsParams[index].bodyCardParams = e
      } else {
        if (!this.cardsParams[index]) {
          this.cardsParams[index] = {
            headerCardParams: [],
            bodyCardParams: [],
            buttonCardParams: []
          }
        }
        this.cardsParams[index].bodyCardParams = e
      }
      this.templateVariables.cardsParams = this.cardsParams
      this.handleTemplateEditing()
    },
    setButtonUrl (index: number, e: string): void {
      if (this.cardsParams.length === 0) {
        while (this.cardsParams.length <= index) {
          this.cardsParams.push({
            headerCardParams: [],
            bodyCardParams: [],
            buttonCardParams: []
          })
        }
        if (!this.cardsParams[index].buttonCardParams.length) {
          this.cardsParams[index].buttonCardParams = e
        }
      } else {
        if (!this.cardsParams[index]) {
          this.cardsParams[index] = {
            headerCardParams: [],
            bodyCardParams: [],
            buttonCardParams: []
          }
        }
        this.cardsParams[index].buttonCardParams = e
      }
      this.templateVariables.cardsParams = this.cardsParams
      this.handleTemplateEditing()
    },
    cardButtons (index: number): TemplateButton[] {
      const cardButtons = this.templateCarousel?.cards[index]?.components?.find((component: Component) => component.type === TemplateComponentsEnum.BUTTONS)?.buttons || []
      return cardButtons
    },
    cardLabel (index: number): string {
      const cardBody = this.templateCarousel?.cards[index]?.components?.find((component: Component) => component.type === TemplateComponentsEnum.BODY)
      return cardBody?.text?.replace('{{1}}', '') || ''
    },
    onSelectParams (param: string): void {
      this.modifyElement(param)
      this.selectingParam = false
    },
    onSelectEmoji (emoji: { [key: string]: string }): void {
      this.modifyElement(emoji.data)
      this.openEmojiPicker = false
    },
    modifyElement (param: string): void {
      if (this.isDefaultTemplate) {
        this.modifyTextArea(param)
      } else {
        this.modifySpanItem(param)
      }

      this.handleTemplateEditing()
    },
    modifyTextArea (param: string): void {
      const textarea: HTMLTextAreaElement = this.$refs[this.idTextArea]
      if (!textarea) return

      // get caret position/selection
      const val = textarea.value
      const start = textarea.selectionStart
      const end = textarea.selectionEnd

      const newValue =
        val.substring(0, start) + param + val.substring(end)
      this.text = newValue
    },
    modifySpanItem (param: string): void {
      const input: HTMLSpanElement = document.getElementById(this.currentInput)
      if (!input) return

      // Get caret position/selection
      const val = input.textContent

      // Get selection start on span element with content editable
      const position: number = this.currentCaretPosition || input.textContent.length || 0

      const newValue: string = val.substring(0, position) + param + val.substring(position)

      input.textContent = newValue

      if (input.style.display === 'inline-block') {
        input.style.display = 'inline'
      }

      this.editTemplate(
        newValue,
        input,
        this.currentInput.replace(this.idTemplateInput + 'Param', '')
      )
    },
    handleScroll (e: { [key: string]: any }): void {
      const component = e.srcElement
      this.showTemplateLabel = component.scrollTop < 10
    },
    parseTemplateMessage (templateMessage: string): string {
      for (let i = 0; i < this.templateParams.length; i++) {
        templateMessage = templateMessage.replace(
          this.templateParams[i].name,
          `<span class="TemplateComposer--textarea" style="color: #B6BEC7; outline: none;" id="${
            this.idTemplateInput + 'Param' + i
          }" placeholder="${this.$t('yourTextHere')}" contenteditable="true"></span>`
        )
      }
      templateMessage = templateMessage.replace(/\n/g, '<br />')
      return `<span>${templateMessage}</span>`
    },
    createParamsEventListener (): void {
      this.templateParams.forEach(
        (_: { [key: string]: any }, index: number) => {
          const input = document.getElementById(this.idTemplateInput + 'Param' + index)
          input.addEventListener('input', (event: {[key: string]: any}) => {
            this.onInputEvent(input, index, event)
          })

          input.addEventListener('paste', (event: ClipboardEvent) => {
            this.onPasteEvent(event)
          })

          input.addEventListener('keyup', (event: KeyboardEvent) => {
            this.onKeyupEvent(event, index)
          })

          input.addEventListener('click', (event: MouseEvent) => {
            this.onClickEvent(event, index)
          })

          input.addEventListener('focus', (event: FocusEvent) => {
            this.onFocusEvent(input, event, index)
          })

          input.addEventListener('blur', (event: FocusEvent) => {
            this.onBlurEvent(input, event)
          })
        }
      )
    },
    destroyParamsEventListener (): void {
      this.templateParams.forEach(
        (_: { [key: string]: any }, index: number) => {
          const input = document.getElementById(this.idTemplateInput + 'Param' + index)
          if (!input) return

          input.removeEventListener('input', (event: {[key: string]: any}) => {
            this.onInputEvent(input, index, event)
          })

          input.removeEventListener('paste', (event: ClipboardEvent) => {
            this.onPasteEvent(event)
          })

          input.removeEventListener('keyup', (event: KeyboardEvent) => {
            this.onKeyupEvent(event, index)
          })

          input.removeEventListener('click', (event: MouseEvent) => {
            this.onClickEvent(event, index)
          })

          input.removeEventListener('focus', (event) => {
            this.onFocusEvent(input, event, index)
          })

          input.removeEventListener('blur', (event: FocusEvent) => {
            this.onBlurEvent(input, event)
          })
        })
    },
    onInputEvent (input: HTMLSpanElement, index: number, event: {[key: string]: any}) {
      if (input.style.display === 'inline-block') {
        input.style.display = input.textContent === '' ? 'inline-block' : 'inline'
      }

      if (event?.inputType === 'insertFromPaste') {
        input.textContent = event?.target?.textContent || ''

        // Set offset to the end of the pasted clipboard text
        let offset: number = event?.target?.textContent?.length || 0

        if (this.clipboardText) {
          offset = this.clipboardText.length + (this.currentCaretPosition || 0)
        }

        if (offset > input.textContent.length) {
          offset = input.textContent.length
        }

        // Create a new range and set the cursor position
        const range: Range = document.createRange()
        range.setStart(input.childNodes[0], offset)
        range.collapse(true)

        // Remove all ranges and add the new one
        const selection: Selection = window.getSelection()
        selection.removeAllRanges()
        selection.addRange(range)
        this.setCaretPosition(selection)
        this.clipboardText = null
      } else {
        const selection: Selection = window.getSelection()
        this.setCaretPosition(selection)
      }

      if (input.textContent === '') {
        input.style.display = 'inline-block'
      }

      this.editTemplate(
        event?.target?.value || event?.target?.textContent || '',
        input,
        index
      )
    },
    onPasteEvent (event: ClipboardEvent): void {
      const text = event.clipboardData.getData('text/plain')
      if (text) {
        this.clipboardText = text
      }
    },
    onClickEvent (event: MouseEvent, index: number): void {
      this.currentInput = `${this.idTemplateInput}Param${index}`
      const selection: Selection = event.view.getSelection()
      this.setCaretPosition(selection)
    },
    onKeyupEvent (event: KeyboardEvent, index: number): void {
      this.currentInput = `${this.idTemplateInput}Param${index}`
      const selection: Selection = event.view.getSelection()
      this.setCaretPosition(selection)
    },
    onFocusEvent (input: HTMLSpanElement, event: FocusEvent, index: number): void {
      this.currentInput = `${this.idTemplateInput}Param${index}`

      input.style.display = input.textContent === '' ? 'inline-block' : 'inline'

      const selection: Selection = event.view.getSelection()
      this.setCaretPosition(selection)
    },
    onBlurEvent (input: HTMLSpanElement, _: FocusEvent): void {
      input.style.display = input.textContent === '' ? 'inline-block' : 'inline'
    },
    setCaretPosition (selection: Selection): void {
      if (!selection) return

      if (selection.type === 'Caret') {
        this.currentCaretPosition = selection?.anchorOffset
      }
    },
    editTemplate (text: string, _: HTMLSpanElement, index: number): void {
      this.templateParams[index].value = text
      if (text.length === 0) {
        this.handleTemplateEditing()
        return
      }
      // let newWidth = text.length * 7.5
      // get punctuation signs and add 2px
      // eslint-disable-next-line no-useless-escape
      // const punctuation = input.textContent.match(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g)
      // if there is punctuation signs reduce matches in text.length
      // if (punctuation) {
      //   newWidth -= punctuation.length * 2
      // }
      // input.style.width = `${newWidth}px`
      this.handleTemplateEditing()
    },
    validateTemplateButtons (): void {
      this.buttons = []

      if (!this.templateButtons) return

      const buttons = this.templateButtons?.buttons
      if (buttons?.length === 0) {
        return
      }

      buttons.forEach((button) => {
        this.buttons.push({
          ...button,
          param: ''
        })
      })
    },
    validateTemplateMPM (): boolean {
      if (!this.mpmDetails.catalogId) {
        return false
      }

      if (this.mpmDetails.sections.length === 0) {
        return false
      }

      return this.mpmDetails.sections.every((section: {[key: string]: any}) => {
        if (!section.title) {
          return false
        }

        if (section.productItems.length === 0) {
          return false
        }

        return section.productItems.every((product: {[key: string]: any}) => {
          return !!product.productRetailerId
        })
      })
    },
    validateTemplateText (): boolean {
      if (this.isDefaultTemplate) {
        return !!this.text
      }

      if (this.templateParams?.length === 0) {
        return true
      }

      return this.templateParams.every((param: {[key: string]: any}) => param.value.length > 0)
    },
    validateTemplateButtonsParams (): boolean {
      if (this.buttons?.length === 0) {
        return true
      }

      let valid = true
      this.buttons.forEach((button: {[key: string]: any}) => {
        if (this.canEditButton(button)) {
          if (!button.param) {
            valid = false
          }
        }
      })
      return valid
    },
    validateTemplateMedia (): boolean {
      if (!this.showMediaSelector) return true

      return !!this.templateVariables.mediaParams.length
    },
    validateTemplateHeader (): boolean {
      if (!this.canEditHeader) return true

      if (this.headerParams?.length === 0) {
        return true
      }

      return this.headerParams.every((param) => param?.param && param?.param?.length > 0)
    },
    validateTemplateCards (): boolean {
      return this.cardsParams.every(card => {
        return card.bodyCardParams && card.bodyCardParams.every(param => param.trim().length > 0) &&
          card.headerCardParams && card.headerCardParams.every(param => param.trim().length > 0)
      })
    },
    cleanMessage (text: string): string {
      let newText = text.replace(/\n/g, ' ')
      newText = newText.replace(/  +/g, ' ')
      return newText
    },
    handleTemplateEditing (): void {
      if (!this.selectedTemplate) return

      const baseData: {[key: string]: any} = {
        templateMessage: this.templateVariables.templateMessage,
        templateId: this.templateVariables.templateId,
        templateParams: [],
        buttonParams: [],
        mediaParams: [],
        headerParams: [],
        cardsParams: [],
        mpmDetails: {},
        valid: true,
        error: ''
      }

      if (this.isMPMTemplate) {
        baseData.mpmDetails = this.mpmDetails

        if (!this.validateTemplateMPM()) {
          baseData.error = this.$t('allFieldsAreRequired')
          baseData.valid = false
        }
      }

      if (!this.validateTemplateText()) {
        baseData.error = this.$t('allFieldsAreRequired')
        baseData.valid = false
      }

      if (this.templateIsSequence) {
        if (!this.validateTemplateCards()) {
          baseData.error = this.$t('allFieldsAreRequired')
          baseData.valid = false
        }
      } else {
        if (!this.validateTemplateButtonsParams()) {
          baseData.error = this.$t('allFieldsAreRequired')
          baseData.valid = false
        }

        if (!this.validateTemplateMedia()) {
          baseData.error = this.$t('allFieldsAreRequired')
          baseData.valid = false
        }

        if (!this.validateTemplateHeader()) {
          baseData.error = this.$t('allFieldsAreRequired')
          baseData.valid = false
        }
      }

      baseData.templateParams = this.isDefaultTemplate
        ? [this.cleanMessage(this.text)]
        : this.templateParams.map((param: {[key: string]: any}) => this.cleanMessage(param.value))
      const buttonParams = []
      this.buttons.forEach((button: {[key: string]: any}) => {
        if (this.canEditButton(button)) {
          buttonParams.push(button.param)
        }
      })
      baseData.cardsParams = this.templateVariables.cardsParams
      baseData.buttonParams = buttonParams
      baseData.mediaParams = this.templateVariables.mediaParams

      if (this.canEditHeader) {
        baseData.headerParams = this.headerParams.map(
          (param: {[key: string]: any}) => param?.param || ''
        )
      }

      this.templateVariables = baseData
      this.$emit('input', baseData)
    },
    onFileUploaded (url: string): void {
      this.templateVariables.mediaParams = [url]
      this.handleTemplateEditing()
    },
    onFileUploadedCard (url: string, index: number): void {
      if (this.cardsParams.length === 0) {
        while (this.cardsParams.length <= index) {
          this.cardsParams.push({
            headerCardParams: [],
            bodyCardParams: [],
            buttonCardParams: [[], ['TEST']]
          })
        }
        this.cardsParams[index].headerCardParams = [url]
      } else {
        if (!this.cardsParams[index]) {
          this.cardsParams[index] = {
            headerCardParams: [],
            bodyCardParams: [],
            buttonCardParams: [[], ['TEST']]
          }
        }
        this.cardsParams[index].headerCardParams = [url]
      }
      this.templateVariables.cardsParams = this.cardsParams
      this.handleTemplateEditing()
    },
    canEditButton (button: TemplateButton) {
      return TemplateService.canEditButton(button)
    }
  },
  watch: {
    templates: {
      immediate: true,
      handler (templates: Template[]): void {
        if (templates.length === 0) {
          this.selectedTemplate = null
          this.isDefaultTemplate = false
          return
        }

        const defaultTemplate = TemplateService.findDefaultTemplate(templates)
        if (defaultTemplate) {
          this.isDefaultTemplate = true
          this.selectedTemplate = defaultTemplate
        } else {
          this.selectedTemplate = templates[0]
          this.isDefaultTemplate = false
        }
      }
    },
    selectedTemplate: {
      immediate: true,
      handler (template: Template): void {
        if (!template) return
        this.destroyParamsEventListener()

        this.isDefaultTemplate = false
        this.isDefaultTemplate = TemplateService.isDefaultTemplate(template)
        this.destroyParamsEventListener()
        this.isDefaultTemplate = TemplateService.isDefaultTemplate(template)
        this.templateParams = TemplateService.getParams(
          template.templateMessage
        )
        if (!this.isDefaultTemplate) {
          setTimeout(() => {
            this.createParamsEventListener()
          }, 500)
        }

        this.validateTemplateButtons()
        if (this.isTextHeader) {
          this.templateVariables.headerParams = []
          this.headerParams = TemplateService.getParams(
            this.templateHeader?.text || ''
          )
        }
        this.templateVariables = {
          templateMessage: template.templateMessage,
          templateId: template.templateId,
          templateParams: [],
          buttonParams: [],
          mediaParams: [],
          valid: false,
          error: ''
        }

        this.currentInput = null
        this.currentCaretPosition = null
        this.clipboardText = null
        this.$emit('selectTemplate', template)
        this.handleTemplateEditing()
      }
    }
  },
  computed: {
    isMPMTemplate (): boolean {
      const component = TemplateService.getTemplateComponent(
        this.selectedTemplate,
        TemplateComponentsEnum.BUTTONS
      )
      const isMPM = component?.buttons?.some((button: any) => button.type === TemplateButtonTypesEnum.MPM)
      return isMPM
    },
    templateLabel (): string {
      return this.selectedTemplate?.templateMessage?.replace('{{1}}', '') || ''
    },
    templateComponents (): TemplateComponentObjectType[] {
      return TemplateService.getTemplateComponents(this.selectedTemplate)
    },
    templateHeader (): TemplateComponentObjectType[] {
      return TemplateService.getTemplateComponent(
        this.selectedTemplate,
        TemplateComponentsEnum.HEADER
      )
    },
    templateCarousel (): TemplateComponentObjectType[] {
      return TemplateService.getTemplateComponent(
        this.selectedTemplate,
        TemplateComponentsEnum.CAROUSEL
      )
    },
    isImageHeader (): boolean {
      return TemplateService.isImageHeader(this.templateHeader)
    },
    isDocumentHeader (): boolean {
      return TemplateService.isDocumentHeader(this.templateHeader)
    },
    isVideoHeader (): boolean {
      return TemplateService.isVideoHeader(this.templateHeader)
    },
    isTextHeader (): boolean {
      return TemplateService.isTextHeader(this.templateHeader)
    },
    canEditHeader (): boolean {
      return (
        this.isTextHeader &&
        TemplateService.getParams(this.templateHeader?.text || '').length > 0
      )
    },
    mediaHeaderAccept (): string {
      if (this.isImageHeader) {
        return 'image/*'
      }
      if (this.isDocumentHeader) {
        return '.pdf'
      }
      if (this.isVideoHeader) {
        return '.mp4'
      }
      return ''
    },
    showMediaSelector (): boolean {
      return this.isImageHeader || this.isDocumentHeader || this.isVideoHeader
    },
    templateButtons (): TemplateComponentObjectType[] {
      return TemplateService.getTemplateComponent(
        this.selectedTemplate,
        TemplateComponentsEnum.BUTTONS
      )
    },
    mediaLabel (): string {
      const labels = {
        [TemplateHeaderFormatEnum.IMAGE]: this.$t('templateImage'),
        [TemplateHeaderFormatEnum.VIDEO]: this.$t('templateVideo'),
        [TemplateHeaderFormatEnum.DOCUMENT]: this.$t('templateFile')
      }

      return labels[this.templateHeader?.format || ''] || ''
    },
    templateIsSequence (): boolean {
      return this.selectedTemplate?.components.includes(TemplateComponentsEnum.CAROUSEL)
    }
  }
}
