import {
  IExtraDataController,
  dataToGetExtraData,
  extraDataGetter,
  dataToCreateExtraData,
  extraDataCreatorUpdator,
  dataToUpdateExtraData,
  dataToDeleteExtraData
} from '../../domain/ExtraDataController'
import ExtraDataRepository from '../repository/ExtraData.repository'

export default class ExtraDataController implements IExtraDataController {
  repository: ExtraDataRepository

  constructor () {
    this.repository = new ExtraDataRepository()
  }

  async getExtraDataFields (data: dataToGetExtraData) : Promise<extraDataGetter> {
    const response = await this.repository.getExtraDataFields(data)
    return response
  }

  async createExtraData (data: dataToCreateExtraData) : Promise<extraDataCreatorUpdator> {
    const response = await this.repository.createExtraData(data)
    return response
  }

  async updateExtraData (data: dataToUpdateExtraData) : Promise<extraDataCreatorUpdator> {
    const response = await this.repository.updateExtraData(data)
    return response
  }

  async deleteExtraData (data: dataToDeleteExtraData) : Promise<extraDataCreatorUpdator> {
    const response = await this.repository.deleteExtraData(data)
    return response
  }
}
