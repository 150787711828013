import { mapState } from 'vuex'

export default {
  name: 'VideoCampaign',
  data: function () {
    return {
      urlVideo: '',
      urlRedirect: '',
      linkStorage: process.env.VUE_APP_STORAGE_URL + '/files/bluebird'
    }
  },
  computed: {
    ...mapState('AppStore', ['selectedApp', 'language']),
    videoElement () {
      return this.$refs.video
    }
  },
  created () {
    setTimeout(() => {
      this.urlVideo =
        this.language === 'es' ? 'campaign-promotion-esp' : 'campaign-promotion-en'
    }, 300)
  }
}
