






















import QuickResponses from './QuickResponses'
export default QuickResponses
