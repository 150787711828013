<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       xml:space="preserve"
       style="enable-background:new 0 0 512 512"
       viewBox="0 0 512 512"><path :fill="color"
                                   d="M88 478.2c-18.4-3.2-34.8-11.4-40.4-32.1-6.9-25.7 9.2-53.7 22.6-74.5 20.9-32.5 50.8-59.4 85.7-76.2 51.1-24.6 112.3-27.2 165.2-7.1 53 20.1 97 62.7 119 114.9 4.5 10.8 8.2 22.2 7.3 33.9-1.2 15.6-11.2 30.2-25.2 37-12.1 5.8-26 5.9-39.4 6-86.7.1-173.4.2-260.1.2-11.1-.1-23.3-.1-34.7-2.1z"/><circle cx="246.5"
                                                                                                                                                                                                                                                                                                                                         cy="143.9"
                                                                                                                                                                                                                                                                                                                                         r="115"
                                                                                                                                                                                                                                                                                                                                         :fill="color"/></svg>
</template>
<script>
export default {
  name: 'PersonIcon',
  props: {
    color: {
      type: String,
      default: 'white'
    }
  }

}
</script>
