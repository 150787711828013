import Colors from '@/shared/domain/colors/Color'
import AudioRecorder from '@/shared/infrastructure/ui/AudioRecorder.vue'
import KeybeAvatar from '@/shared/infrastructure/ui/KeybeAvatar.vue'
import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'
import { VEmojiPicker } from 'v-emoji-picker'
import { KeybeIconType } from '../../enums/icons/KeybeIconType'

export default {
  name: 'KBMessageComposer',
  components: {
    KeybeIcon,
    KeybeAvatar,
    AudioRecorder,
    VEmojiPicker
  },
  data () {
    return {
      KeybeIconType,
      Colors,
      messageText: '',
      openEmojiPicker: false,
      audio: null,
      isRecording: false,
      lastSelection: null,
      caretPosition: 0,
      isUploadingAudio: false
    }
  },
  mounted () {
    this.handleResize()
  },
  computed: {
    placeholder () {
      return this.$t('write')
    }
  },
  methods: {
    updateMessage () {
      const text = this.$refs['KBMessageComposer--textarea']?.innerText || ''
      this.messageText = text

      this.$nextTick(() => {
        this.handleResize()
        this.updateCaretPosition()
      })
    },
    updateCaretPosition () {
      const textarea = this.$refs['KBMessageComposer--textarea']
      this.caretPosition = this.getCaretCharacterOffsetWithin(textarea)
    },
    handleResize (quickResponse = false) {
      const textarea = this.$refs['KBMessageComposer--textarea']
      const editor = this.$refs.KBMessageComposer

      const actualHeight = editor?.style?.height || '40px'
      const actualHeightNumber = parseInt(actualHeight.replace('px', ''))

      if (this.$refs && textarea && editor) {
        if (textarea.scrollHeight > 20 && textarea.scrollHeight <= 200) {
          editor.style.height = (20 + textarea.scrollHeight) + 'px'
        } else if (textarea.scrollHeight >= 200) {
          editor.style.height = '220px'
          textarea.scrollTop = textarea.scrollHeight
        } else {
          editor.style.height = '40px'
        }

        if (this.messageText.length === 0) {
          editor.style.height = '40px'
        }
        const heightString = editor.style.height
        const height = parseInt(heightString.substring(0, heightString.length - 2))
        const messageEditorHeight = getComputedStyle(document.documentElement).getPropertyValue('--kb-message-editor-height')
        const messageEditorHeightInt = parseInt(messageEditorHeight.substring(0, messageEditorHeight.length - 2))

        const value = height + messageEditorHeightInt - actualHeightNumber

        document.documentElement.style.setProperty('--kb-message-editor-height', (value || 80) + 'px')
      }
    },
    handleShortcuts (e) {
      if (e.which === 13 && !e.shiftKey) {
        e.preventDefault()
        e.stopPropagation()
        this.send()
      } else if (e.which === 79 && (e.ctrlKey || e.metaKey)) {
        e.preventDefault()
        e.stopPropagation()
        // this.openInput()
      }
    },
    pasteText (event) {
      this.$nextTick(() => {
        this.handleResize()
      })
    },
    getCaretCharacterOffsetWithin (element) {
      var caretOffset = 0
      var doc = element.ownerDocument || element.document
      var win = doc.defaultView || doc.parentWindow
      var sel
      if (typeof win.getSelection !== 'undefined') {
        sel = win.getSelection()
        if (sel.rangeCount > 0) {
          var range = win.getSelection().getRangeAt(0)
          var preCaretRange = range.cloneRange()
          preCaretRange.selectNodeContents(element)
          preCaretRange.setEnd(range.endContainer, range.endOffset)
          caretOffset = preCaretRange.toString().length
        }
      } else if ((sel = doc.selection) && sel.type !== 'Control') {
        var textRange = sel.createRange()
        var preCaretTextRange = doc.body.createTextRange()
        preCaretTextRange.moveToElementText(element)
        preCaretTextRange.setEndPoint('EndToEnd', textRange)
        caretOffset = preCaretTextRange.text.length
      }
      return caretOffset
    },
    onSelectEmoji (emoji) {
      const textarea = this.$refs['KBMessageComposer--textarea']
      const selRange = this.caretPosition
      const firstPart = this.messageText.substring(0, selRange)
      const lastPart = this.messageText.substring(selRange)
      this.messageText = firstPart + emoji.data + lastPart
      textarea.innerText = this.messageText
      textarea.focus()
      setTimeout(() => {
        const range = document.createRange()
        range.selectNodeContents(textarea)
        this.caretPosition = selRange + emoji.data.length
        range.setStart(textarea.firstChild, this.caretPosition)
        range.setEnd(textarea.firstChild, this.caretPosition)

        const sel = window.getSelection()
        sel.removeAllRanges()
        sel.addRange(range)
      }, 50)
    },
    startRecording () {
      this.resetInput()
      this.$nextTick(() => {
        this.isRecording = true
      })
    }
  },
  watch: {
    placeholder: {
      immediate: true,
      handler (placeholder: string) {
        document.documentElement.style.setProperty('--kb-message-editor-placeholder', `'${placeholder}'`)
      }
    },
    isRecording (value) {
      if (!value) {
        this.$nextTick(() => {
          setTimeout(() => {
            this.handleResize()
            this.$refs['KBMessageComposer--textarea'].focus()
          }, 200)
        })
      }
    },
    openEmojiPicker (value) {
      if (value) {
        this.lastSelection = window.getSelection()
      }
    }
  }
}
