<template>
  <div class="FunnelsFieldsConfigCard">
    <div
      class="FunnelsFieldsConfigCard__card"
      v-for="(field, index) in fields"
      :key="index">
      <KBSwitch
        :value="field.active"
        color="green"
        :key="`${rerender}-${index}-${field.field}`"
        :disabled="isUserReadonly"
        @input="setFieldStatus(index)"/>
      <span>{{ $t(field.field) }}</span>
    </div>
  </div>
</template>
<script lant="ts">
import FunnelsFieldsConfigCard from './FunnelsFieldsConfigCard'
export default FunnelsFieldsConfigCard
</script>
<style scoped lang="scss">
@import './FunnelsFieldsConfigCard.scss'
</style>
