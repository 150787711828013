import MetricsService from '@/modules/metrics/application/services/MetricsService'
import { textCamelize } from '@/utils/utils'

import KBSelectAdvisor from '@/shared/infrastructure/ui/KBSelectAdvisor.vue'
import TitleDetail from '@/modules/metrics/infrastructure/ui/components/generic/TitleDetail.vue'
import MetricsCard from '@/modules/metrics/infrastructure/ui/components/metrics/MetricsCard.vue'
import MetricsGraph from '@/modules/metrics/infrastructure/ui/components/metrics/MetricsGraph/MetricsGraph.vue'
import MetricsTable from '@/modules/metrics/infrastructure/ui/components/metrics/MetricsTable.vue'
import moment from 'moment'
import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'
import { EventBus } from '@/eventBus'

export default {
  name: 'MetricsPage',
  components: {
    TitleDetail,
    KeybeIcon,
    MetricsCard,
    MetricsGraph,
    MetricsTable,
    KBSelectAdvisor
  },
  data () {
    return {
      KeybeIconType,
      loadingDownload: false,
      dataProactivo: null,
      dataCards: {},
      dataPie: {},
      dataTable: [],
      itemPotencialData: [],
      itemData: [],
      dataGraph: {},
      conversacionesAbiertas: {
        value: '0',
        descipcion: this.$t('totalConversationExceptBot'),
        text: this.$t('openConversations'),
        potencial: '0'
      },
      sinAsesor: {
        value: '0',
        descipcion: this.$t('openChatWithoutAdviser'),
        text: this.$t('withoutAdvisor'),
        potencial: '0'
      },
      sinRespuesta: {
        value: '0',
        descipcion: this.$t('openChatWithUserLastMessage'),
        text: this.$t('withoutAnswer'),
        potencial: '0'
      },
      enAtención: {
        value: '0',
        descipcion: this.$t('openChatWithAdviserLastMessage'),
        text: this.$t('inAttention'),
        potencial: '0'
      },
      // ----
      tableStateInfo: []
    }
  },
  computed: {
    closeModal: {
      get () {
        return this.$store.state.MetricsStore.closeModal || false
      },
      set (value) {
        this.$store.commit('MetricsStore/SET_EDITING_OBJECT', {
          key: 'closeModal',
          value
        })
      }
    },
    advisorFilterArrayId: {
      get () {
        return this.$store.state.MetricsStore.advisorFilterArrayId || []
      },
      set (value) {
        this.$store.commit('MetricsStore/SET_EDITING_OBJECT', {
          key: 'advisorFilterArrayId',
          value
        })
      }
    },
    advisorFilterArray: {
      get () {
        return this.$store.state.MetricsStore.advisorFilterArray || []
      },
      set (value) {
        this.$store.commit('MetricsStore/SET_EDITING_OBJECT', {
          key: 'advisorFilterArray',
          value
        })
      }
    },
    advisers: {
      get () {
        return this.$store.state.AppStore.advisers || null
      }
    }
  },
  methods: {
    async getFileMetrics () {
      if (!this.loadingDownload) {
        this.loadingDownload = true
        const response = await MetricsService.getFileRealTime()
        if (response) {
          if (response?.code === 500) {
            this.loadingDownload = false
            EventBus.$emit('toast', 'error', this.$t('unexpectedError'))
            return
          }
          try {
            const fileURL = response.data?.result?.download
            if (fileURL) {
              const link = document.createElement('a')
              link.href = fileURL
              link.target = '_blank'
              link.download = 'Metrics-RealTime.xlsx'
              document.body.appendChild(link)
              link.click()
            } else {
              EventBus.$emit(
                'toast',
                'success',
                this.$t('successfullCampaignExport')
              )
            }
            this.loadingDownload = false
          } catch (error) {
            console.log('error', error)
            this.loadingDownload = false
          }
        } else {
          this.loadingDownload = false
          if (response?.data?.error) {
            EventBus.$emit('toast', 'error', this.$t(textCamelize(response.data.error)))
          } else {
            EventBus.$emit('toast', 'error', this.$t('unexpectedError'))
          }
        }
      }
    },
    async getRealTimeMetricsProactivity (advisorList) {
      this.loading = true
      try {
        const response: any = await MetricsService.getRealTimeProactivity(advisorList)
        if (response) {
          const { data } = response
          if (data.summary) {
            this.dataProactivo = data.summary[0].totalProactivities
          }
          this.loading = false
        }
      } catch (err) {
        console.log(err)
      }
    },
    async getRealTimeMetricsSessions (advisorList) {
      this.loading = true
      try {
        const response: any = await MetricsService.getRealTimeSessions(advisorList)
        if (response) {
          const { data } = response
          if (data.summary) {
            this.dataCards = data.summary[0].statistics
            this.dataPie = data.summary[0].statistics_pie
            if (this.dataCards) this.asignDataCards()
            if (this.dataPie) this.asignDataPie()
          }
          this.loading = false
        }
      } catch (err) {
        console.log(err)
      }
    },
    async getRealTimeMetricsAdvisor (advisorList) {
      this.loading = true
      try {
        const response: any = await MetricsService.getRealTimeAdvisor(advisorList)
        if (response) {
          const { data } = response
          if (data.advisors) {
            this.dataTable = data.advisors
          }
          this.loading = false
        }
      } catch (err) {
        console.log(err)
      }
    },
    getRealTimeMetrics (advisorList) {
      this.getRealTimeMetricsProactivity(advisorList)
      this.getRealTimeMetricsAdvisor(advisorList)
      this.getRealTimeMetricsSessions(advisorList)
    },
    asignDataCards () {
      const { totalOpenSessions, totalReadyForAdvisor, totalUnanswered, totalAnswered } = this.dataCards
      this.conversacionesAbiertas.value = totalOpenSessions
      this.sinAsesor.value = totalReadyForAdvisor
      this.sinRespuesta.value = totalUnanswered
      this.enAtención.value = totalAnswered
    },
    asignDataPie () {
      // eslint-disable-next-line camelcase
      const { totalWithAdvisor, totalWithBot, totalReadyForAdvisor } = this.dataPie
      this.dataGraph = {
        conversations_with_advisor: totalWithAdvisor,
        conversations_with_bot: totalWithBot,
        conversations_ended: totalReadyForAdvisor
      }
    },
    async getPotencial () {
      this.loading = true
      const params = {
        periodStart: moment().subtract(1, 'month').format('YYYY-MM-DD'),
        periodEnd: moment().format('YYYY-MM-DD')
      }
      try {
        const response: any = await MetricsService.getPotencialG(params)
        if (response) {
          const { data } = response
          this.itemPotencialData = data
          this.callPotencial()
          this.loading = false
        }
      } catch (err) {
        console.log(err)
      }
    },
    callPotencial () {
      this.conversacionesAbiertas.potencial = this.calculatePotencial(this.conversacionesAbiertas.value)
      this.sinAsesor.potencial = this.calculatePotencial(this.sinAsesor.value)
      this.sinRespuesta.potencial = this.calculatePotencial(this.sinRespuesta.value)
      this.enAtención.potencial = this.calculatePotencial(this.enAtención.value)
    },
    calculatePotencial (item) {
      const pct = this.itemPotencialData?.win_pct ? this.itemPotencialData?.win_pct : 0
      const avg = this.itemPotencialData?.ticket_avg ? this.itemPotencialData?.ticket_avg : 0
      const formatterPeso = new Intl.NumberFormat('es-CO', {
        style: 'currency',
        currency: 'COP',
        minimumFractionDigits: 0
      })
      const result = ((parseInt(item) * pct) / 100) * avg
      return formatterPeso.format(result) || '0'
    }
  },
  watch: {
    advisorFilterArray () {
      if (!this.loading) {
        if (this.advisorFilterArray.length === 0) {
          this.getRealTimeMetrics()
          return
        }
        if (this.advisorFilterArray.length > 0) {
          this.getRealTimeMetrics(this.advisorFilterArrayId)
        }
      }
    }
  },
  mounted () {
    this.getRealTimeMetrics()
    this.getPotencial()
  }
}
