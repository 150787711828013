import { IROutboundController, dataToGetOutbound, outboundGetter, dataToGetOutboundDuplicate } from '../domain/OutboundController'

export default class OutboundUseCases {
  static async getOutbound (controller:IROutboundController, data: dataToGetOutbound): Promise<outboundGetter> {
    return await controller.getOutbound(data)
  }

  static async getOutboundDuplicate (controller:IROutboundController, data: dataToGetOutboundDuplicate): Promise<outboundGetter> {
    return await controller.getOutboundDuplicate(data)
  }

  static async removeOutboundDuplicate (controller:IROutboundController, data: dataToGetOutboundDuplicate): Promise<outboundGetter> {
    return await controller.removeOutboundDuplicate(data)
  }
}
