import KeybeRepository from '@/shared/infrastructure/repositories/KeybeRepository'
import { GenericResponse } from '@/shared/infrastructure/http/GenericResponse'
import { HeadersBuilder } from '@/shared/infrastructure/http/HeadersBuilder'
import { getOptions, nonGetOptions } from '@/shared/infrastructure/http/HttpClient'
import {
  dataToCreateQuickResponse,
  dataToGetQuickResponses,
  dataToDeleteQuickResponse
} from '../../domain/QuickResponsesController'
export default class QuickResponsesRepository extends KeybeRepository {
  getSuggestionsAbortController: AbortController

  constructor () {
    super()

    this.getSuggestionsAbortController = new AbortController()
  }

  abortGetSuggestions () {
    this.getSuggestionsAbortController.abort()
    this.getSuggestionsAbortController = new AbortController()
  }

  async getQuickResponses (data: dataToGetQuickResponses): Promise<GenericResponse> {
    const { app, uuid } = data

    const url = `${process.env.VUE_APP_CONVERSATIONS_API_URL}/config/quickResponse?appUUID=${app}&userId=${uuid}`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    const requestOptions: getOptions = {
      headers,
      url,
      abortController: this.getSuggestionsAbortController
    }

    return await this.client.get(requestOptions)
  }

  async createQuickResponse (data: dataToCreateQuickResponse): Promise<GenericResponse> {
    const { createInput } = data
    const url = `${process.env.VUE_APP_CONVERSATIONS_API_URL}/config/quickResponse`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    const requestOptions: nonGetOptions = {
      headers,
      data: createInput,
      url,
      abortController: this.getSuggestionsAbortController
    }

    return await this.client.post(requestOptions)
  }

  async deleteQuickResponse (data: dataToDeleteQuickResponse): Promise<GenericResponse> {
    const url = `${process.env.VUE_APP_CONVERSATIONS_API_URL}/config/quickResponse`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    const requestOptions: nonGetOptions = {
      headers,
      data,
      url,
      abortController: this.getSuggestionsAbortController
    }

    return await this.client.delete(requestOptions)
  }
}
