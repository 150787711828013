import InboxController from '../../Inbox.controller'
import { mapGetters, mapState } from 'vuex'
import InboxUseCases from '@/modules/inbox/application/InboxUseCases'
import { defaultInboxDTO, inboxDeleteDTO, inboxUpdateDTO } from '@/modules/inbox/domain/InboxController'
import { Inbox } from '@/modules/inbox/domain/Inbox'
import { EventBus } from '@/eventBus'
import InboxConfigList from '../InboxConfigList/InboxConfigList.vue'
import InboxConfigDetail from '../InboxConfigDetail/InboxConfigDetail.vue'
import ChatConfigController from '@/modules/ChatConfig/infrastructure/ChatConfig.controller'

export default {
  name: 'InboxConfig',
  components: {
    InboxConfigList,
    InboxConfigDetail
  },
  data () {
    return {
      inboxController: new InboxController(),
      selectedInbox: {},
      inboxList: [],
      loading: false,
      chatConfigController: new ChatConfigController(),
      inboxConfig: {}
    }
  },
  computed: {
    ...mapState('UserStore', ['user', 'token']),
    ...mapState('AppStore', ['selectedApp']),
    ...mapGetters('UserStore', ['isSuperAdmin', 'isAdminOrAbove'])
  },
  methods: {
    async createInbox (data) {
      const inboxNameExists = this.inboxList.some(i => i?.name?.toLowerCase() === data?.toLowerCase())
      if (inboxNameExists) {
        EventBus.$emit('toast', 'error', this.$t('inboxNameAlreadyExists'))
        return
      }
      const dataToCreateInbox = {
        userId: this.user?.userId,
        token: this.token,
        appUUID: this.selectedApp?.uuid,
        name: data
      }
      this.loading = true
      const newInbox = await InboxUseCases.createInbox(this.inboxController, dataToCreateInbox)
      this.loading = false
      if (!newInbox) {
        EventBus.$emit('toast', 'error', this.$t('errorCreatingInbox'))
      }

      this.inboxList.push(newInbox)
      this.selectedInbox = newInbox

      EventBus.$emit('InboxRow__refreshInboxes')
    },
    async updateInbox (inbox: Inbox) {
      const index = this.inboxList.findIndex(i => i?._id === inbox?._id)
      this.inboxList.splice(index, 1, inbox)

      const dataToUpdateInbox : inboxUpdateDTO = {
        ...inbox,
        inboxId: inbox?._id || '',
        userId: this.user?.userId,
        token: this.token,
        line: inbox?.line,
        isAdmin: this.isAdminOrAbove
      }

      if (!this.isAdminOrAbove) {
        delete dataToUpdateInbox.isAdmin
      }

      this.loading = true
      await InboxUseCases.updateInbox(this.inboxController, dataToUpdateInbox)
      this.loading = false

      EventBus.$emit('InboxRow__refreshInboxes')
    },
    async deleteInbox (inbox) {
      const index = this.inboxList.findIndex(i => i?._id === inbox?._id)
      const dataToDeleteInbox: inboxDeleteDTO = {
        inboxId: inbox?._id || '',
        userId: this.user?.userId,
        token: this.token,
        isAdmin: this.isAdminOrAbove
      }
      // if (!this.isAdminOrAbove) {
      //   delete dataToDeleteInbox.isAdmin
      // }
      this.loading = true
      const deletedInbox = await InboxUseCases.deleteInbox(this.inboxController, dataToDeleteInbox)
      if (!deletedInbox) {
        EventBus.$emit('toast', 'error', this.$t('errorDeletingInbox'))
        this.loading = false
        return
      }
      this.inboxList.splice(index, 1)

      if (this.selectedInbox?._id === inbox?._id) {
        this.selectedInbox = this.inboxList[0] || {}
      }
      this.loading = false
      EventBus.$emit('InboxRow__refreshInboxes')
    },
    async getInboxes () {
      const data = {
        token: this.token,
        appUUID: this.selectedApp?.uuid
      }

      const chatConfig = await this.chatConfigController.getAndSetConfig({
        appUUID: this.selectedApp?.uuid || '',
        token: this.token || ''
      })
      this.inboxConfig = chatConfig?.inbox

      const inboxes = await InboxUseCases.getInbox(this.inboxController, data)

      this.inboxList = inboxes
      this.selectedInbox = inboxes[0]
    },
    updateLocalInbox (inbox: Inbox) {
      const index = this.inboxList.findIndex(i => i?._id === inbox?._id)
      this.inboxList.splice(index, 1, inbox)
    },
    async setAsMain (inbox: Inbox) {
      const data: defaultInboxDTO = {
        token: this.token,
        userId: this.user?.userId,
        appUUID: this.selectedApp?.uuid,
        inboxId: inbox?._id || '',
        isAdmin: this.isAdminOrAbove
      }

      // if (!this.isAdminOrAbove) {
      //   delete data.isAdmin
      // }

      this.loading = true
      const updated = await InboxUseCases.setDefaultInbox(this.inboxController, data)
      this.loading = false

      if (!updated) {
        EventBus.$emit('toast', 'error', this.$t('errorSettingDefaultInbox'))
        return
      }

      // search current default inbox
      const currentDefaultInbox = this.inboxList.find(i => i?.default)
      if (currentDefaultInbox) {
        currentDefaultInbox.default = false
      }

      const currentInbox = this.inboxList.find(i => i?._id === inbox?._id)
      currentInbox.default = true
    }
  },
  created () {
    this.getInboxes()
  },
  beforeDestroy () {
    this.inboxController = null
  }
}
