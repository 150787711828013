
















































































import WebWidgetExpandedConfig from './WebWidgetExpandedConfig'
export default WebWidgetExpandedConfig
