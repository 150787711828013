


















































































































import WelcomeMessageConfig from './WelcomeMessageConfig'

export default WelcomeMessageConfig
