<template>
  <svg :width="width"
       :height="height"
       viewBox="0 0 15 11"
       fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <path d="M1.5 1.5C1.5 0.672656 2.17266 0 3 0H12C12.8273 0 13.5 0.672656 13.5 1.5V7.5H12V1.5H3V7.5H1.5V1.5ZM0 8.7C0 8.45156 0.201563 8.25 0.45 8.25H14.55C14.7984 8.25 15 8.45156 15 8.7C15 9.69375 14.1938 10.5 13.2 10.5H1.8C0.80625 10.5 0 9.69375 0 8.7ZM6.58594 4.14844L5.85938 4.875L6.58594 5.60156C6.80625 5.82187 6.80625 6.17812 6.58594 6.39609C6.36563 6.61406 6.00938 6.61641 5.79141 6.39609L4.66641 5.27109C4.44609 5.05078 4.44609 4.69453 4.66641 4.47656L5.79141 3.35156C6.01172 3.13125 6.36797 3.13125 6.58594 3.35156C6.80391 3.57187 6.80625 3.92812 6.58594 4.14609V4.14844ZM9.21094 3.35156L10.3359 4.47656C10.5562 4.69687 10.5562 5.05312 10.3359 5.27109L9.21094 6.39609C8.99063 6.61641 8.63438 6.61641 8.41641 6.39609C8.19844 6.17578 8.19609 5.81953 8.41641 5.60156L9.14297 4.875L8.41641 4.14844C8.19609 3.92813 8.19609 3.57188 8.41641 3.35391C8.63672 3.13594 8.99297 3.13359 9.21094 3.35391V3.35156Z"
          :fill="color"/>
  </svg>

</template>
<script>
export default {
  name: 'PrimaryIcon',
  props: {
    width: {
      type: Number,
      default: 15
    },
    height: {
      type: Number,
      default: 11
    },
    color: {
      type: String,
      default: '#B6BEC7'
    }
  }
}
</script>
