import { render, staticRenderFns } from "./InvoicesDetails.vue?vue&type=template&id=08e8a640&"
import script from "./InvoicesDetails.vue?vue&type=script&lang=ts&"
export * from "./InvoicesDetails.vue?vue&type=script&lang=ts&"
import style0 from "./InvoicesDetails.vue?vue&type=style&index=0&id=08e8a640&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VExpandTransition } from 'vuetify/lib/components/transitions';
installComponents(component, {VBtn,VExpandTransition})
