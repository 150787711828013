<template>
  <svg :width="width" :height="height" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect x="0.5" y="0.5" width="13" height="13" rx="2.5" :stroke="color"/>
  <rect x="3.5" y="4.5" width="13" height="13" rx="2.5" :stroke="color"/>
  </svg>
</template>

<script>
export default {
  name: 'AddIcon',
  props: {
    width: {
      type: Number,
      default: 16
    },
    height: {
      type: Number,
      default: 18
    },
    color: {
      type: String,
      default: '#B6BEC7'
    }
  }

}
</script>

<style>

</style>
