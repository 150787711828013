import Colors from '@/utils/Colors'
import { debounce } from 'lodash'

export default {
  props: {
    items: {
      type: Array,
      required: true
    },
    value: {
      type: Object
    },
    itemText: {
      type: String,
      required: true
    },
    placeholder: {
      type: String
    },
    loading: {
      type: Boolean
    },
    disabled: {
      type: Boolean
    },
    autoSearch: {
      type: String
    },
    scrollable: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    labelStyle (): string {
      return this.selectedItem
        ? `color: ${this.$vuetify.theme.dark ? Colors.white : Colors.black}`
        : `color: ${Colors.simpleGrey}`
    },
    itemStyle (): string {
      return this.$vuetify.theme.dark
        ? `background-color: ${Colors.morningCoffee}; border-color: ${Colors.grey}`
        : `background-color: ${Colors.white}; border-color: ${Colors.light4}`
    },
    filteredItems (): any[] {
      return this.items.filter((item: any) => item[this.itemText] && item[this.itemText]?.toLowerCase().includes(this.inputSearch?.toLowerCase()))
    }
  },
  mounted () {
    this.setValue(true)
  },
  data () {
    return {
      selectedItem: {},
      inputSearch: '',
      uniqueId: Math.random().toString(36).substring(7),
      menu: false,
      Colors
    }
  },
  methods: {
    openMenu (): void {
      if (!this.disabled) {
        this.menu = !this.menu
      }
    },
    getNextItems () {
      const list = document.getElementById(
        `KeybeAutocomplete__list--text-obj-${this.uniqueId}`
      )
      const bottomOfWindow =
      list?.offsetHeight + list?.scrollTop >=
      list?.scrollHeight - 1
      if (bottomOfWindow && this.scrollable) {
        this.$emit('getNext')
      }
    },
    clear (): void {
      this.selectedItem = {}
      this.inputSearch = ''
      this.$emit('input', {})
      this.$emit('clear')
    },
    sendSearch: debounce(function () {
      if (!this.menu) this.menu = true
      this.$emit('search', this.inputSearch)
    }, 600),
    selectItem (item: any) {
      this.selectedItem = item
      this.setInputSearch(item[this.itemText])
      this.$emit('input', item)
    },
    setValue (mounted: boolean = false) {
      this.selectedItem = this.value
      if (this.autoSearch && mounted) this.inputSearch = this.autoSearch
      else this.setInputSearch(this.selectedItem ? this.selectedItem[this.itemText] : '')
    },
    itemLabel (item: any): string {
      return item[this.itemText]
    },
    setInputSearch (value: string) {
      this.inputSearch = value || ''
    }
  },
  watch: {
    value () {
      this.setValue()
    }
  }
}
