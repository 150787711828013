import { EventBus } from '@/eventBus'
import { BikyConfigUseCases } from '@/modules/bky/application/BikyConfigUseCases'
import { KnowledgeBaseTypeEnum } from '@/modules/bky/domain/BikyConfig'
import { MessageSendersEnum, MessageTypesEnum } from '@/modules/conversations/domain/models/Message/Message'
import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import KeybeAvatar from '@/shared/infrastructure/ui/KeybeAvatar.vue'
import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'
import { mapState } from 'vuex'
import BikyConfigController from '../../../controllers/BikyConfig.controller'
import BikyConfigChatAudioMessage from '../BikyConfigChatAudioMessage/BikyConfigChatAudioMessage.vue'
import BikyConfigChatCorrectResponse from '../BikyConfigChatCorrectResponse/BikyConfigChatCorrectResponse.vue'
import BikyConfigChatTextMessage from '../BikyConfigChatTextMessage/BikyConfigChatTextMessage.vue'
import BikyStoreController from '@/modules/bky/infrastructure/persistence/BikyStore.controller'
import BikyService from '@/modules/bky/domain/Biky.service'

export default {
  name: 'BikyConfigChatMessage',
  props: {
    message: {
      type: Object,
      required: true
    },
    prevMessage: {
      type: String,
      default: ''
    }
  },
  components: {
    KeybeAvatar,
    BikyConfigChatTextMessage,
    BikyConfigChatAudioMessage,
    KeybeIcon,
    BikyConfigChatCorrectResponse
  },
  data () {
    return {
      KeybeIconType,
      isFixingResponse: false,
      loading: false,
      bikyConfigController: new BikyConfigController()
    }
  },
  computed: {
    ...mapState('AppStore', ['selectedApp']),
    ...mapState('UserStore', ['token', 'user']),
    messageComponent () {
      const components = {
        [MessageTypesEnum.AUDIO]: BikyConfigChatAudioMessage
      }

      return components[this.message.type]
    },
    image () {
      if (this.isAdvisor) {
        return this.user?.photo || ''
      }

      return BikyStoreController.getBikyImage()
    },
    isAdvisor () {
      return this.message?.sender === MessageSendersEnum.ADVISOR
    },
    isBot () {
      return this.message?.sender === MessageSendersEnum.BOT
    },
    isBiky () {
      return BikyService.isBikyMessage(this.message)
    },
    isAudioMessage () {
      return this.message?.type === MessageTypesEnum.AUDIO
    },
    shouldShowActions () {
      return this.isBiky && this.prevMessage
    }
  },
  methods: {
    async saveGoodResponse () {
      if (this.loading) return
      this.loading = true

      const knowledgeFragment = {
        text: `TITLE: ${this.prevMessage} \nCONTENT: ${this.message?.text || ''}`,
        type: KnowledgeBaseTypeEnum.USER,
        typeId: this.user?.userId || '',
        appUUID: this.selectedApp?.uuid || ''
      }

      const created = await BikyConfigUseCases.createKnowledgeBase(this.bikyConfigController, {
        ...knowledgeFragment,
        token: this.token
      })

      this.loading = false

      if (!created) {
        EventBus.$emit('toast', 'error', this.$t('errorSavingMessage'))
        return
      }

      EventBus.$emit('toast', 'success', this.$t('messageSaved'))
    }
  }
}
