<template>
  <div class="KBSimpleTable newBackground">
    <table class="KBSimpleTable__table">
      <thead>
        <tr>
          <td
            class="KBSimpleTable__header"
            :class="positionClass(labelIndex, labels.length)"
            v-for="(label, labelIndex) in labels"
            :key="`KBSimpleTable-${label.text}-${labelIndex}`"
          >
            {{ label.text }}
          </td>
        </tr>
      </thead>
      <tbody>
        <tr class="KBSimpleTable__row"
            v-for="(item, itemIndex) in data"
            :key="`KBSimpleTable-item-${itemIndex}`">
          <td
            class="KBSimpleTable__item"
            v-for="(label, labelIndex) in labels"
            :key="`KBSimpleTable-label-${labelIndex}`"
            :class="positionClass(labelIndex, labels.length)"
          >
            <template v-if="filter && filter.includes(label.field)">
              {{ item[label.field] | currencyUSD }}
            </template>
            <template v-else>
              {{ customLogic(item, label) }}
            </template>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { currencyToUSD } from '@/utils/currencyToUSD'

export default {
  name: 'KBTableDailyContact',
  props: ['labels', 'data', 'filter'],
  filters: {
    currencyUSD (value) {
      return currencyToUSD(value)
    }
  },
  methods: {
    customLogic (item, label) {
      if (label.field === 'factor') {
        const value = (item[label.field] - 1) * 100
        return `${parseInt(value)}%`
      }
      if (label.field === 'tier') {
        const tierMapping = {
          'Tier 1': 'ESSENTIAL',
          'Tier 2': 'CORTEX',
          'Tier 3': 'TESSERACT',
          'Tier 4': 'TIER 4 '
        }

        const value = item[label.field]
        return tierMapping[value] || value
      }
      return item[label.field]
    },
    positionClass (index, length) {
      if (index === 0) {
        return 'first-item'
      }
      if (index === length - 1) {
        return 'last-item'
      }
      return ''
    }
  }
}
</script>

<style lang="scss">

.KBSimpleTable__table {
  overflow: scroll;
  border-collapse: collapse;
  width: 100%;
}

.KBSimpleTable__header {
  font-weight: 300;
  font-size: 11px;
  line-height: 0px;
  color: $simple-grey;
  height: 30px;
  text-align: center;
}

.KBSimpleTable__item {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  padding: 10px;
  border-bottom: 1px solid #EBEFF2;
  text-align: start;
  min-width: 66px;
}

  .first-item {
    padding-left: 32px;
    max-width: 125px;

    // text elipsis
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .last-item {
    padding-right: 32px;
  }
</style>
