import { render, staticRenderFns } from "./ImporterProgress.vue?vue&type=template&id=2237369d&scoped=true&"
import script from "./ImporterProgress.vue?vue&type=script&lang=ts&"
export * from "./ImporterProgress.vue?vue&type=script&lang=ts&"
import style0 from "./ImporterProgress.vue?vue&type=style&index=0&id=2237369d&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2237369d",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
installComponents(component, {VProgressLinear})
