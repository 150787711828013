<template>
  <div class="metric-card"
       :class="$vuetify.theme.dark? 'metric-card__dark':''">
    <span class="metric-card__title"
          :class="content ? '' : 'pb-4'">{{ title }}</span>
    <div class="metric-card__Cash my-3">
      <slot></slot>
    </div>
    <span class="metric-card__contentText">{{ content }}</span>
  </div>
</template>

<script>
export default {
  name: 'KBCard',
  props: {
    title: {
      type: String,
      default: 'title'
    },
    content: {
      type: String,
      default: ''
    }
  },
  data () {
    return {}
  },
  watch: {
  }

}
</script>
<style lang='scss' scoped>
.metric-card {
  max-width: 330px;
  text-align: center;
  margin: 15px 15px 0px 0px;
  flex-grow: 6;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 10px;
  background-color: #d9d9d923;
  color: #000000;
  border: 1px solid #00000015;
  min-height: 190px;
  min-width: 45%;

  &__dark{
    background-color: black;
    span{
      color: white;
    }
  }

  &__title {
    font-weight: 300;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #000000;
    max-width: 200px;
  }
  .metric-card__Cash{
    display: flex;
    justify-content: center;
    align-items: center;

    &__Variation{
      justify-content: start;
      align-items: start;
      display: flex;
    }
  }

  &__contentValue {
    font-weight: 600;
    font-size: 28px;
    line-height: 100%;
    letter-spacing: -0.01em;
    color: #333333;
  }

  &__contentValueSmall {
    font-weight: 600;
    font-size: 12px;
    line-height: 100%;
    letter-spacing: -0.01em;
    color: #333333;
  }

  &__contentText {
    padding: 0 10px;
    font-weight: 300;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #000000;
    max-width: 220px;

  }

}

</style>
