import { mapState } from 'vuex'
import ActivityLogsUseCases from '../../../application/ActivityLogsUseCases'
import { dataToGetActivityLogs } from '../../../domain/ActivityLogsController'
import ActivityLogsController from '../../controllers/ActivityLogs.controller'
import ActivityLogsItem from '../ActivityLogsItem/ActivityLogsItem.vue'
import EmitToast from '@/utils/EmitToast'
import Loader from '@/shared/infrastructure/ui/Loader.vue'
export default {
  components: {
    ActivityLogsItem,
    Loader
  },
  computed: {
    ...mapState('UserStore', ['token']),
    ...mapState('AppStore', ['selectedApp'])
  },
  mounted () {
    this.getActivityLogs()
  },
  data () {
    return {
      loading: false,
      offset: 0,
      activityLogs: [],
      activityLogsController: new ActivityLogsController()
    }
  },
  methods: {
    async getActivityLogs (): Promise<void> {
      this.loading = true
      const data: dataToGetActivityLogs = {
        token: this.token,
        params: {
          appUUID: this.selectedApp?.uuid,
          offset: this.offset,
          limit: 25
        }
      }
      const response = await ActivityLogsUseCases.getActivityLogs(this.activityLogsController, data)
      if (response?.status) {
        this.activityLogs = response?.data
      } else EmitToast.emitErrors(response)
      this.loading = false
    }
  }
}
