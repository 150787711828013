import { RestResponseFactory } from '@/shared/infrastructure/http/RestResponse'
import { StatusCodes } from '@/shared/infrastructure/http/StatusCodes'
import { GenericResponse } from './GenericResponse'

export interface getOptions {
    url: string,
    query?: { [key: string]: any },
    abortController?: AbortController,
    headers: { [key: string]: any },
    signal?: AbortSignal
}

export interface nonGetOptions {
    url: string,
    data?: any,
    abortController?: AbortController,
    headers?: { [key: string]: any },
    isFormData?: boolean,
    signal?: AbortSignal
}

export default class httpClient {
  async get ({ url, query, abortController, headers }: getOptions) {
    const parsedUrl = new URL(url)

    if (query) {
      Object.keys(query).forEach((key) => {
        // validate if element is an array and if it is, then add each element to the query
        if (Array.isArray(query[key])) {
          query[key].forEach((element) => {
            parsedUrl.searchParams.append(`${key}[]`, element)
          })
        } else {
          parsedUrl.searchParams.append(key, query[key])
        }
      })
    }

    try {
      const response: Response = await fetch(parsedUrl.toString(), {
        method: 'GET',
        headers: headers || undefined,
        signal: abortController?.signal
      })

      let data: {[key: string]: any} | {[key: string]: any}[] = await response.json()

      if (Array.isArray(data)) {
        const oldData = data
        data = {
          data: oldData,
          status: response.status
        }
      } else {
        if (!data?.status) {
          data.status = response.status
        }
        if (!data?.data) {
          data.data = {
            ...data
          }
        }
      }

      return new RestResponseFactory().process({ response: data })
    } catch (e) {
      return new RestResponseFactory().process({ response: { status: e?.code || StatusCodes.HTTP_500_INTERNAL_SERVER_ERROR } })
    }
  }

  async post ({ url, data, headers, abortController, isFormData }: nonGetOptions) {
    try {
      const response = await fetch(url, {
        method: 'POST',
        body: isFormData ? data : JSON.stringify(data),
        headers: headers || undefined,
        signal: abortController?.signal
      })
      const parsedResponse = await response.json()

      return new RestResponseFactory().process({
        response: {
          statusCode: parsedResponse?.statusCode || parsedResponse?.code || response.status,
          status: response.status,
          data: { ...parsedResponse }
        }
      })
    } catch (e) {
      return new RestResponseFactory().process({ response: { status: e?.code } })
    }
  }

  async put ({ url, data, headers, abortController, isFormData }: nonGetOptions) {
    try {
      const response = await fetch(url, {
        method: 'PUT',
        body: isFormData ? data : JSON.stringify(data),
        headers: headers || undefined,
        signal: abortController?.signal
      })
      const parsedResponse = await response.json()
      const restResponse = new RestResponseFactory().process({ response: parsedResponse })
      return restResponse
    } catch (e) {
      return new RestResponseFactory().process({ response: { status: e?.code } })
    }
  }

  async patch ({ url, data, headers, abortController }: nonGetOptions) {
    try {
      const response = await fetch(url, {
        method: 'PATCH',
        body: JSON.stringify(data),
        headers: headers || undefined,
        signal: abortController?.signal
      })
      const parsedResponse = await response.json()
      const restResponse = new RestResponseFactory().process({ response: parsedResponse })
      return restResponse
    } catch (e) {
      return new RestResponseFactory().process({ response: { status: e?.code } })
    }
  }

  async delete ({ url, data, headers, abortController }: nonGetOptions): Promise<GenericResponse> {
    try {
      const response = await fetch(url, {
        method: 'DELETE',
        headers: headers || undefined,
        signal: abortController?.signal,
        body: JSON.stringify(data)
      })
      const parsedResponse = await response.json()

      if (!parsedResponse?.status) {
        parsedResponse.status = response.status
      }

      if (!parsedResponse?.data) {
        parsedResponse.data = {
          ...parsedResponse
        }
      }

      return new RestResponseFactory().process({ response: parsedResponse })
    } catch (e) {
      return new RestResponseFactory().process({ response: { status: e?.code } })
    }
  }
}
