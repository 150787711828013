<template>
  <div class="containerFloating">
    <div class="contentEditEmail">
      <div class="contentTitle">
        <span class="containerFloating__header--title">
          {{ formBasic ? '': email.templateId ? 'Editar plantilla:': 'Plantilla en blanco' }}
        </span>
      </div>
      <div
        v-if="formBasic"
        style="padding-top: 0;"
        class="contentFormOutbound"
        :class="lengthContent ? 'contentForm__small': ''">
        <div class="OutboundEmail"
             style="width: 100%"
             v-if="step === 1">
          <div class="OutboundEmailForm">
            <div class="OutboundEmailForm__contentForm pb-2 mt-10">
              <div class="OutboundEmailForm__contentInput mr-2">
                <span class="inputLabel">{{ $t('labelCampaign') }}*</span>
                <KeybeText
                  v-model="campaignName"/>
              </div>
              <div class="OutboundEmailForm__contentInput">
                <div class="d-flex align-center"
                     style="min-height: 25px;">
                  <span class="inputLabel pr-2">{{ $t('segmentToSend') }}*</span>
                  <loaderSVG v-if="loadingSegment"/>
                </div>
                <KeybeAutocomplete
                  :disabled="segmentValue ? true : false"
                  class="KBform"
                  :itemValue="SalesFieldsEnum._ID"
                  :itemText="CDPFieldsEnums.NAME"
                  :items="items"
                  :key="`${rerender}-segments`"
                  v-model="segment"
                  @search="searchPersons($event)"
                  :autoSearch="segmentValue"/>
              </div>
            </div>
            <div class="OutboundEmailForm__contentForm pb-2 mt-2">
              <div class="OutboundEmailForm__contentInput mr-2">
                <span class="inputLabel">{{ $t('sender') }}*</span>
                <KeybeText
                  v-model="campaignSender"/>
              </div>
              <div class="OutboundEmailForm__contentInput">
                <span class="inputLabel">{{ $t('Adjuntos') }}*</span>
                <div class="attachments__lower-third">
                  <div
                    class="Outboundattachment-box"
                    ref="dragDiv"
                    @drop.prevent="dropHandler('dragDiv')"
                    @dropover.prevent="dragOverhandler('dragDiv')"
                    @dragleave.prevent="dragLeaveHandler('dragDiv')"
                  >
                    <label class="file-select">
                      <!-- We can't use a normal button element here, as it would become the target of the label. -->
                      <div
                        class="customize__button"
                        style="margin: 0 auto;"
                      >
                        <!-- Display the filename if a file has been selected. -->
                        <span class="mt-3">{{ $t('attachFile') }}</span>
                      </div>
                      <!-- Now, the file input that we hide. -->
                      <input type="file"
                             @change="handleFileChange" />
                    </label>
                  </div>
                </div>
                <div
                  class=""
                  v-for="file in campaignAttachments"
                  :key="file.name"
                >
                  <span
                    class="bg-black text-white mt-2 block pl-2"
                    style="font-size:11px;border-radius: 5px;"
                    v-if="campaignAttachments"
                  >{{ file.name }}</span
                  >
                </div>
              </div>
            </div>
            <div class="OutboundEmailForm__contentFormfullWidth mt-2 py-2"
                 v-if="listDomains.length > 0">
              <span class="inputLabel pb-3">{{ $t('domains') }}*</span>
              <KeybeAutocomplete
                style="width:100%; max-width:100%"
                class="KBform"
                :itemValue="'domain'"
                :itemText="'domain'"
                :items="listDomains"
                :key="`${rerender}-domain`"
                v-model="domain"/>
            </div>
            <div class="OutboundEmailForm__contentFormfullWidth mt-2 py-2"
                 v-if="domain">
              <span class="inputLabel pb-3">{{ $t('from') }}*</span>
              <div class="contentEmailFrom">
                <KeybeText
                  v-model="emailFrom"/>
                <span class="pl-2 inputLabel">
                  @{{ domain }}
                </span>
              </div>
            </div>
            <div class="OutboundEmailForm__contentFormfullWidth pb-2">
              <div class="OutboundEmailForm__contentFormfullWidth contentSubjectComposer my-5"
                   style="position: relative;">
                <span class="inputLabel py-2">{{ $t('subjectofEmail') }}*</span>
                <div class="contentSmsButton">
                  <div v-if="changeParamsVisible"
                       style="position:absolute; z-index: 9;top:85px;left: 8px;">
                    <ParamsSelector class="pl-1 mb-3"
                                    @selectParams="onParams"/>
                  </div>
                  <KeybeEmojiPicker v-if="openEmojiPicker"
                                    style="top:75px; left:70px"
                                    :open="openEmojiPicker"
                                    @close="openEmojiPicker = false"
                                    @select="onSelectEmoji"
                  />
                </div>
                <div class="contentSubjectComposer--header">
                  <a @click="changeParamsVisible = !changeParamsVisible"
                     class="contentSubjectComposer--header--button"
                     style="min-width: 100px;">
                    <span >
                      {{ !changeParamsVisible ? $t('copyCustomizedField'):$t('cancel')}}
                    </span>
                  </a>
                  <a class="contentSubjectComposer--header--button ml-2"
                     @click="openEmojiPicker = !openEmojiPicker">
                    <svg xmlns="http://www.w3.org/2000/svg"
                         width="15"
                         height="15"
                         viewBox="0 0 15 15"
                         fill="none">
                      <path d="M12.2937 2.10695C9.48486 -0.702204 4.91486 -0.702204 2.10653 2.10627C-0.702356 4.91475 -0.702085 9.48489 2.1068 12.2939C4.91486 15.1022 9.48486 15.1022 12.2935 12.2934C15.1021 9.48489 15.1019 4.91502 12.2937 2.10695ZM11.5078 11.5079C9.13253 13.8831 5.2676 13.8834 2.89225 11.5082C0.516482 9.13255 0.516754 5.26722 2.89225 2.89199C5.26747 0.516907 9.13226 0.516636 11.508 2.89227C13.8832 5.26749 13.883 9.13282 11.5078 11.5079ZM4.51311 5.25052C4.51311 4.78563 4.89015 4.40858 5.35504 4.40858C5.81979 4.40858 6.19684 4.78549 6.19684 5.25052C6.19684 5.71568 5.81979 6.09258 5.35504 6.09258C4.89015 6.09258 4.51311 5.71568 4.51311 5.25052ZM8.33065 5.25052C8.33065 4.78563 8.70797 4.40858 9.17286 4.40858C9.63761 4.40858 10.0147 4.78549 10.0147 5.25052C10.0147 5.71568 9.63774 6.09258 9.17286 6.09258C8.70797 6.09258 8.33065 5.71568 8.33065 5.25052ZM10.3211 8.69876C9.79931 9.90538 8.57735 10.685 7.20821 10.685C5.80961 10.685 4.58045 9.90144 4.07673 8.68871C3.99038 8.48111 4.08882 8.24269 4.29669 8.15634C4.34774 8.1353 4.40069 8.12525 4.45283 8.12525C4.6125 8.12525 4.76402 8.21961 4.82906 8.37643C5.2061 9.28421 6.13995 9.87048 7.20821 9.87048C8.25231 9.87048 9.18073 9.28381 9.57339 8.37548C9.66273 8.16897 9.9025 8.07379 10.1089 8.16327C10.3153 8.25274 10.4104 8.49238 10.3211 8.69876Z"
                            :fill="$vuetify.theme.dark  ? 'white': 'black'"/>
                    </svg>
                  </a>
                </div>
                <KeybeText
                  ref="messageEditor"
                  class="contentSubjectComposer__templateInput"
                  style="position: relative;max-width: 100%;width: 100%"
                  v-model="campaignSubject"/>
              </div>
            </div>
          </div>
        </div>
        <div class="contentTemplate"
             v-if="step === 2">
          <TemplateList  @campaignTemplateEditor="openEditor($event)"
                         :actionDisabled="actionDisabled"
                         :count="count"/>
          <!--  -->
        </div>
      </div>
      <div
        v-else
        class="contentTemplate">
        <EmailTemplateCreator
          :templateId="email.templateId"
          :campaignDetails="campaignDetails"
          @savedTemplate="submit($event)"/>
      </div>
      <div class="OutboundEmailBtn">
        <div class="contentBtnCreate">
          <KeybeButton v-if="actionDisabled"
                       class="w-full"
                       color="#ebecee"
                       :text="$t('Preparar envio')"
                       :disabled="true"/>
          <KeybeButton v-else
                       class="w-full"
                       color="#3af69b"
                       :text="$t('Preparar envio')"
                       @click="nextStep()"/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment'
import { mapState } from 'vuex'
import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import { campaignEmail } from '@/modules/outbound/infrastructure/ui/components/methods/campaignEmail'
import { EventBus } from '@/eventBus'
import SegmentsApolloProvider from '@/modules/people/Providers/SegmentsApolloProvider'
import TemplateList from '@/modules/outbound/infrastructure/ui/components/forms/TemplateList.vue'
import ParamsSelector from '@/modules/templates/infrastructure/ui/ParamsSelector/ParamsSelector.vue'
import KeybeEmojiPicker from '@/shared/infrastructure/ui/KeybeEmojiPicker.vue'
import EmailTemplateCreator from '@/modules/outbound/infrastructure/ui/components/forms/EmailTemplateCreator.vue'
import { EntityTypeEnum } from '@/modules/CDP/shared/contacts/domain/Contacts'
import SegmentsUseCases from '@/modules/CDP/shared/segments/application/SegmentsUseCases'
import SegmentsController from '@/modules/CDP/shared/segments/infrastructure/controllers/Segments.controller'
import operations from '@/utils/operations'
import { CDPFieldsEnums } from '@/modules/CDP/shared/contacts/domain/enums/CDPFieldsEnums'
import { SalesFieldsEnum } from '@/modules/Sales/shared/domain/SalesFieldsEnum'
import KeybeText from '@/modules/DesignSystem/infrastructure/components/KeybeText/KeybeText.vue'
import KeybeAutocomplete from '@/modules/DesignSystem/infrastructure/components/KeybeAutocomplete/KeybeAutocomplete/KeybeAutocomplete.vue'
import KeybeButton from '@/modules/DesignSystem/infrastructure/components/KeybeButton/KeybeButton.vue'
import LoaderSVG from '@/shared/infrastructure/ui/icons/SvgComponents/loaderSVG.vue'

export default {
  name: 'EmailForm',
  props: {
    listDomains: {
      type: Array,
      default: () => []
    },
    disabledAction: {
      type: Boolean,
      default: false
    },
    setSegment: {
      type: String
    },
    selectedSegment: {
      type: [String, Object]
    },
    validateDomain: {
      type: [String, Boolean]
    }
  },
  components: {
    LoaderSVG,
    KeybeEmojiPicker,
    ParamsSelector,
    TemplateList,
    KeybeText,
    KeybeAutocomplete,
    EmailTemplateCreator,
    KeybeButton
  },
  mounted () {
    this.getSegments(true)
  },
  watch: {
    emailFrom () {
      if (this.emailFrom) {
        this.validateEmail()
      }
    },
    changeParamsVisible () {
      if (this.changeParamsVisible) {
        this.openEmojiPicker = false
      }
    },
    openEmojiPicker () {
      if (this.openEmojiPicker) {
        this.changeParamsVisible = false
      }
    }
  },
  data () {
    return {
      CDPFieldsEnums,
      SalesFieldsEnum,
      changeParamsVisible: false,
      emailFrom: null,
      domain: null,
      items: [],
      segmentsController: new SegmentsController(),
      segments: [],
      offset: 1,
      limit: 100,
      segmentSearch: '',
      campaignAttachments: [],
      campaignDetails: null,
      step: 1,
      html: null,
      count: 0,
      email: {
        from: '',
        html: '',
        subject: '',
        templateId: ''
      },
      formBasic: true,
      campaignData: {},
      campaignName: null,
      campaignSubject: null,
      campaingsSubject: null,
      campaignSender: null,
      segment: null,
      segmentValue: null,
      KeybeIconType,
      userSegments: [],
      openEmojiPicker: false,
      lengthContent: false,
      rerender: 0,
      SegmentsApolloProvider: SegmentsApolloProvider.getInstance().provider.defaultClient,
      loadingSegment: false
    }
  },
  created () {
  },
  computed: {
    ...mapState('AppStore', ['selectedApp']),
    ...mapState('UserStore', ['token', 'user']),
    userName () {
      if (!this.user?.name) {
        return ''
      }
      if (!this.user?.lastName) {
        return this.user?.name
      }
      return `${this.user.name} ${this.user.lastName}`
    },
    hasDomain () {
      if (this.domain) {
        if (this.emailFrom) {
          return true
        } else {
          return false
        }
      } else {
        return true
      }
    },
    actionDisabled () {
      if (this.segment && this.campaignName && this.campaignSubject && this.campaignSender && this.hasDomain) {
        return false
      } else {
        return true
      }
    }
  },
  methods: {
    campaignEmail,
    onSelectEmoji (emoji) {
      if (this.campaignSubject) {
        this.campaignSubject = `${this.campaignSubject} ${emoji.data}`
      } else {
        this.campaignSubject = emoji.data
      }
      this.openEmojiPicker = false
    },
    formatName (data) {
      this.items = data.map(segment => {
        if (!segment?.name) return
        return {
          name: operations.checkTranslation(segment.name),
          _id: segment._id

        }
      })
    },
    searchPersons (search) {
      this.loadingSegment = true
      const obj = this.items.find(o => o.name.toLowerCase().includes(search.toLowerCase()))
      if (obj) {
        this.loadingSegment = false
        return
      }
      this.segmentSearch = search
      try {
        this.getSegments()
      } catch (error) {
        this.loadingSegment = false
      }
    },
    async getSegments (mounted) {
      try {
        const data = {
          offset: this.offset,
          limit: this.limit,
          search: this.segmentSearch,
          typeEntity: EntityTypeEnum.PEOPLE,
          token: this.token,
          app: this.selectedApp?.uuid
        }
        const response = await SegmentsUseCases.getSegments(this.segmentsController, data)
        if (response?.status) {
          if (mounted) this.segments = []
          if (this.offset > 1) this.segments = this.segments.concat(response?.data?.data)
          else this.segments = response?.data?.data.reverse()
          this.formatName(this.segments)
          this.setInit()
          this.loadingSegment = false
        }
        this.setSegmentIn()
      } catch (e) {
        console.error(e)
        this.loading = false
      }
    },
    findElement (array, id) {
      const result = array.find(elemento => elemento.id === id)
      if (!result) return
      return {
        id: result._id,
        name: result.name
      }
    },
    async getSegmentsById (id) {
      try {
        const data = {
          typeEntity: EntityTypeEnum.PEOPLE,
          token: this.token,
          app: this.selectedApp?.uuid,
          id: id
        }
        const response = await SegmentsUseCases.getSegmentsById(this.segmentsController, data)
        if (response?.status) {
          this.segments.push(response?.data)
          this.formatName(this.segments)
          this.segment = response?.data?._id
        }
      } catch (e) {
        console.error(e)
        this.loading = false
      }
    },
    setSegmentIn () {
      const exists = this.findElement(this.segments, this.setSegment)
      if (exists) {
        this.segment = exists.id
      } else {
        this.getSegmentsById(this.setSegment)
      }
      this.loading = false
    },
    setInit () {
      this.campaignSender = this.userName
      if (this.listDomains.length > 0) {
        this.domain = this.listDomains[0].domain
      }
    },
    validateFiles (filesToAttach) {
      let isImportationValid = true
      let totalSizeSum = this.campaignAttachments.reduce((a, b) => a + b, 0)
      for (const element of filesToAttach) {
        const size = element.size
        if (size > 5 * 10 ** 6) {
          isImportationValid = this.$t('sizeOfFileCantExceed', { size: 5, units: 'mb' })
          break
        }
        totalSizeSum += size
        if (totalSizeSum > 24 * 10 ** 6) {
          isImportationValid = this.$t('filesTotalSizeMustBeSmallerThan', { size: 24, units: 'mb' })
          break
        }
      }
      return isImportationValid
    },
    async handleFileChange (e) {
      let filesToAttach = [...e.target.files]
      const validationResult = this.validateFiles(filesToAttach)
      if (validationResult !== true) return console.log(validationResult)

      const existentFilesNames = this.campaignAttachments.map(file => file.name)
      filesToAttach = filesToAttach.filter(file => !existentFilesNames.includes(file.name))
      if (filesToAttach.length === 0) {
        return EventBus.$emit('toast', 'error', this.$t('fileWithSameNameAlreadyExists'))
      }
      this.campaignAttachments = [...this.campaignAttachments, ...filesToAttach]
    },
    onParams (customizedField) {
      if (this.campaignSubject) {
        this.campaignSubject = `${this.campaignSubject} ${customizedField}`
      } else {
        this.campaignSubject = customizedField
      }
      this.changeParamsVisible = false
    },
    formatText (item) {
      if (!item) return
      return item.replace(/\s/g, '')
    },
    validateEmail () {
      // La expresión regular permite letras, números, guiones, guiones bajos y puntos
      const regex = /^[A-Za-z0-9._]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/
      if (!regex.test(this.emailFrom)) {
        this.emailFrom = this.emailFrom.replace(/[^A-Za-z0-9._@-]+/g, '')
      }
    },
    nextStep () {
      if (!this.actionDisabled) {
        this.step = 2
        this.campaignDetails = {
          name: this.campaignName,
          status: 'draft',
          date: moment.now(),
          type: 'email',
          listId: this.segment,
          all: this.segment,
          email: {
            from: this.domain && this.emailFrom ? `${this.formatText(this.emailFrom)}@${this.domain}` : '',
            fromName: this.campaignSender,
            html: '',
            subject: this.campaignSubject,
            template: '',
            attachments: this.campaignAttachments
          }
        }
      } else {
        EventBus.$emit('toast', 'error', this.$t('msgErrorForm'))
      }
    },
    onOption () {
      this.formBasic = !this.formBasic
    },
    returnForm () {
      this.count++
      this.formBasic = !this.formBasic
      this.email = {
        from: '',
        html: '',
        subject: '',
        templateId: ''
      }
    },
    openEditor (templateId) {
      if (templateId) {
        this.email.templateId = templateId
      } else {
        this.email.templateId = ''
      }
      this.formBasic = false
      // this.nextStep()
    },
    returnM () {
      if (this.step === 1) {
        this.currentTemplate = null
        this.lengthContent = false
        this.$parent.returnMethod()
      }
      if (this.step === 2) {
        this.step = 1
      }
    }
  }
}
</script>
<style scoped lang='scss'>
.textRed{
    color: #F7685B
}

.textBlue{
    color: #01CFE4
}
.contentTemplate{
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  height: 99%;
  width: 98%;
  .unlayer-editor{
    min-height: 500px !important;
    iframe{
      min-height: 500px !important;
    }
  }
  .contentEdit{
    width: 100%;
    height: 95%;
    position: relative;
    .contentEditChild{
      position:absolute;
      display: flex;
      top: -45px;
      right: 0;
      .KBButton:hover{
          background-color: rgba(212, 217, 223, 0.233) !important;
        }
    }
  }

}
@media (max-width: 1600px) {
  .optionPreveiw{
    min-width: 300px !important;
    width: 35% !important;
  }
  .Contentpreview{
    width: 65% !important;
  }
}

@media (max-width: 1200px) {
  .optionPreveiw{
    width: 40% !important;
  }
  .Contentpreview{
    width: 60% !important;
  }
}

.optionPreveiw{
  min-width: 450px;
  width: 45%;
  margin-right:15px;
}
.Contentpreview{
  width: 50%;
  max-width: 650px;
  min-width: 550px;
}
.preview{
  height: 100%;
  overflow-x: scroll;
  max-width: 650px;
  width: 650px;
}
.contentFormOutbound{
    max-width:1700px;
    display: flex;
    overflow-y:hidden;
    justify-content: space-between;
    padding-top: 25px;
    &__response{
      height: 100%;
      width: 40%;
    }
    .contentTemplate{
      height: 68vh;
      width: 98%;
      overflow-y: hidden;
      min-height: 550px;
      .contentList{
          height: 100%;
          overflow: scroll;
          .Contentpreview{
            .preview{
        height: 100%;
        min-height: 500px;
      }
          }

      }
      .contentBtnTemplate{
        width: 100%;
        display: flex;
        justify-content: end;
        position: relative;
        .KBButton{
          position: absolute;
          top: -32px;
        }
        .KBButton:hover{
          background-color: rgba(212, 217, 223, 0.233) !important;
          position: absolute;
          top: -32px;
        }
      }
    }
}
.KBform{
    width: 30vw;
}
.btnImport{
    user-select: none;
    width: fit-content;
    display: flex;
    flex-direction: row;
    padding: 5px 8px 5px 0;
    margin-top: 2px;
    border-radius: 5px;
    svg{
        height: 18px;
        width: 15px;
    }
}
.btnBack{
    color:#B6BEC7;
    padding-left: 25px;
    user-select: none;
    cursor: pointer;
    width: fit-content;
}

.btnBack:hover{
    span{
        font-weight: 600;
    }
}
.btnImport:hover{
    cursor: pointer;
    background: rgba(218, 215, 215, 0.212);

    .filter-black{
        filter: invert(100%) sepia(93%) saturate(0%) hue-rotate(201deg) brightness(106%) contrast(106%);
    }
}
input[type="file"] {
    display: none;
}

.smalForm{
  height: 40px !important;
  .KBSelect__container{
   height: 40px !important;
  }
}
.smalForm:first-child{
  height: 40px !important;
}
.container__CampaingsMessages{
  position: relative;
  max-width: 30vw;
}

.TemplateComposer__preview {
    min-height: 700px !important;
}

.contentForm__small{
  overflow-y: scroll;
.TemplateComposer__preview--messageText{
  font-size: 14px !important;
}
}
.contentSchedule{
  width: 250px;
  position: absolute;
  top: 40px;
  background: #E2E6E9;
  z-index: 9;
}
.contentEmojis{
  position: absolute;
  right: 0px;
  bottom: -40px;
}
.contentMsg{
  position: relative;
  .KBTextarea{
    height: 210px;
}
}

.contentMsgBig{
  position: relative;
  .KBTextarea{
    height: 310px !important;
}
}

.pickerE{
  position: absolute;
  right: 0;
  bottom: 42px;
}
.textHelp{
  color:rgba(180, 180, 180, 0.712);
  width: 25vw;
  font-size: 12px;
  user-select: none;
}
.textError{
  color:rgba(255, 0, 0, 0.712);
  width: 25vw;
  font-size: 12px;
  user-select: none;
}
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
.counterSms{
  font-size: 12px;
  color: gray;
}
.positionEnd{
  display: flex;
    justify-content: start;
    align-items: start;
}
.btnDate{
  position: absolute;
  top: -30px;
  left: -10px;
  display: flex;
  justify-content: space-between;
  width: 80px;
  color: #B6BEC7;
  svg{
    fill: black;
  }
}
.contentPositionEnd{
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btnProgramming{
  background-color: #01cfe475;
  height: 40px;
  width: 40px;
  padding: 0 5px;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  svg{
    margin: 0;
  }
}
.filter-gray{
  filter: invert(88%) sepia(16%) saturate(147%) hue-rotate(171deg) brightness(84%) contrast(89%);
}
.contentTitle{
    display: flex;
    justify-content: start;
    align-items: start;
    flex-direction: column;
}
::-webkit-scrollbar {
    display: none;
}
.modalV6Ligth{
  position: absolute;
  border-radius: 5px;
  opacity: 1;
  left: 20%;
  z-index: 9;
  padding: 20px 40px;
  height: 500px;
  width: 70%;
  .childModalV6{
    justify-content: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 90%;
    h2{
      font-size: 22px;
      font-weight: 600;
    }
    span {
      padding:10px 0;
    }
    a{
      padding-top:10px;
      color: #01CFE4;
    }
  }
}
.modalV6{
  position: absolute;
    border-radius: 5px;
    opacity: 1;
    left: 20%;
    z-index: 9;
    padding: 20px 40px;
    height: 500px;
    width: 70%;
    .childModalV6{
      justify-content: center;
      display: flex;
      align-items: center;
      flex-direction: column;
      height: 90%;
      h2{
        font-size: 22px;
        font-weight: 600;
      }
      span {
        padding:10px 0;
      }
      a{
        padding-top:10px;
        color: #01CFE4;
      }
    }
}
.Outboundattachment-box {
  background: var(--v-input-base);
  display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    border-radius: 9px;
    min-height: 40px;
    min-width: 260px;
    cursor: pointer;
    text-align: center;
    width: 100%;
    overflow: auto;
    .file-select{
      width: 100%;
      padding-top: 5px;
      height: 40px !important;
      border-radius: 9px;
      span{
        font-size: 12px;
        color: rgb(177, 173, 173);
      }
    }
}
.OutboundEmail{
  display: flex;
  justify-content: start;
  align-items: start;
  .OutboundEmailBtn{
    width: 30%;
    padding-top: 23px;
  }
  .OutboundEmailForm{
    height: 100%;
    min-height: 400px;
    max-width: 540px;
    min-width: 520px;
    width: 70%;
    align-items: start !important;
    overflow-x: hidden;
    margin-right: 15px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    &__contentForm{
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
    &__contentFormfullWidth{
      flex-direction: column;
      display: flex;
      width: 100%;
    }

    &__contentInput{
      width: 50%;
      max-width: 260px;
    }
    .KBform  {
      max-width: 260px;
    }
}
.OutboundEmailAttach{
  width: 50%;
  max-width: 330px;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start !important;
  .textHelp {
    width: 100%;
  max-width: 330px;
  }
  .attachments__lower-third{
    width: 100%;
  max-width: 330px;
  }
}
}
.contentEditEmail{
  height: 100%;
  padding-left: 25px;
  max-height: 68vh;
  overflow: auto;
  .preview{
    height: 100%;
    min-height: 500px;
  }
  .containerFloating__header--title{
    padding: 0 !important;
  }

  .container__editor{
    height: 100%;
    overflow-y: hidden;
  }
  .unlayer-editor iframe {
    min-width: 600px;
    width: 100%;
    border: 0px;
}
  .contentBtnCreate {
    display: flex;
    justify-content: start;
    align-items: center;
    width: 100%;
    max-width: 540px;
    .KBButtonDisabled {
      background: #e3e7ec !important;
      cursor: default;
    }
    .KBButtonActive {
      background: #3af69b !important;
    }
    .KBButton {
      width: 100% !important;
      height: 53px;
      flex-shrink: 0;
      border-radius: 9px;
      border: none !important;
      max-width: 350px;
      span {
        color: var(--Double-dark, #000);
        text-align: center;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 0px; /* 0% */
      }
    }
  }
  .contentEmailFrom{
    display: flex;
    justify-content: start;
    align-items: center;
    width:100%
  }
  .contentSubjectComposer{
    width: 100%;
    border-radius: 10px;

    &--header{
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      background-color: rgba(182,190,199,.2);
      padding: 0 10px;
      height: 58px;
      width: 100%;
      border-radius: 9px 9px 0 0;
      margin-top: 3px;
    }

    &--header--button {
    height: 39px;
    border-radius: 3px;
    background-color: rgba(136,152,169,.2);
    padding: 0 15px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    cursor: pointer;
    }
    &__templateInput{
      width: 100%;
      outline: none;
      resize: none;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 300;
      position: relative;
      overflow: scroll;
      border-radius: 0 0 9px 9px !important;
    }
    }
    .emoji-picker{
      top: 45px !important;
      left: 0 !important;
      position: absolute !important;
      width: 410px !important;
    }
  }
</style>
