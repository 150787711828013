<template>
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 19"><path :fill="color" d="M16 15.2c1.06 0 2.078-.4 2.828-1.113A3.706 3.706 0 0 0 20 11.4V3.8a3.706 3.706 0 0 0-1.172-2.687A4.11 4.11 0 0 0 16 0H4C2.94 0 1.922.4 1.172 1.113A3.706 3.706 0 0 0 0 3.8v14.725c0 .126.053.247.146.336a.514.514 0 0 0 .704-.003l3-2.85c.284-.263.62-.47.99-.609A3.14 3.14 0 0 1 6 15.2h10Z"/></svg>
</template>

<script>
export default {
  name: 'ChatIcon',
  props: {
    color: {
      type: String,
      default: '#B6BEC7'
    }
  }

}
</script>

<style>

</style>
