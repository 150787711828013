// Components
import MediaImage from '@/modules/NotificationsCenter/infrastructure/ui/components/NotificationCenterMedia/MediaImage/MediaImage.vue'
import MediaVideo from '@/modules/NotificationsCenter/infrastructure/ui/components/NotificationCenterMedia/MediaVideo/MediaVideo.vue'

// Models
import {
  Block,
  FileType,
  FileTypeEnum
} from '@/modules/NotificationsCenter/domain/Notification'

export default {
  components: {
    MediaImage,
    MediaVideo
  },
  props: {
    blocks: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    mediaComponent () {
      const block: Block = this.getFirstBlock
      const type: FileType = block.type

      switch (type) {
        case FileTypeEnum.IMAGE:
          return MediaImage
        case FileTypeEnum.VIDEO:
          return MediaVideo
        default:
          return MediaImage
      }
    },
    getFirstBlock (): { [key: string]: any } {
      if (this.blocks.length > 0) {
        return this.blocks[0] || {}
      }

      return {}
    }
  },
  data () {
    return {}
  }
}
