







































































































import TeamList from './TeamList'
export default TeamList
