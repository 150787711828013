<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       fill="none"
       viewBox="0 0 17 13"><path :fill="color"
                                 fill-rule="evenodd"
                                 d="M6.093 8.703 4.56 7.354c-.753-.663-1.507-1.326-2.263-1.987a.863.863 0 0 1 .005-1.305A873.712 873.712 0 0 0 6.097.722c-.05-.29-.224-.51-.516-.642-.352-.158-.672-.074-.959.18a979.808 979.808 0 0 1-4.32 3.802c-.401.35-.401.96-.006 1.305.756.66 1.51 1.324 2.263 1.987.696.612 1.392 1.225 2.09 1.835a.998.998 0 0 0 .392.213c.49.127.956-.2 1.052-.7Z"
                                 clip-rule="evenodd"/><path :fill="color"
                                                            d="M9.114 6.871v.213c0 .483.008.966-.003 1.449-.013.59-.527 1.01-1.07.87a.998.998 0 0 1-.392-.214C6.195 7.918 4.75 6.637 3.296 5.367a.863.863 0 0 1 .005-1.305A979.229 979.229 0 0 0 7.622.259c.287-.253.607-.337.959-.179.351.158.531.444.532.828v1.667h.213c.92 0 1.84-.008 2.76.002.87.011 1.708.179 2.464.62 1.287.75 2.125 1.826 2.369 3.291.303 1.818-.248 3.382-1.565 4.682-.284.28-.638.495-.973.721-.259.174-.546.136-.723-.063-.186-.207-.162-.506.029-.753.196-.255.419-.508.54-.797.434-1.043-.027-2.36-.997-2.974a2.736 2.736 0 0 0-1.462-.44c-.84-.01-1.681-.003-2.522-.003-.036 0-.071.007-.132.013V6.87Z"/></svg>
</template>

<script>
export default {
  name: 'ForwardIcon',
  props: {
    color: {
      type: String,
      default: '#B6BEC7'
    }
  }
}
</script>

<style>

</style>
