export default {
  name: 'DragAndDropBox',
  data () {
    return {
      dragging: false
    }
  },
  methods: {
    onDrop (e) {
      this.$emit('dropData', e)
      this.dragging = false
    }
  }
}
