import { EventBus } from '@/eventBus'
import KBSearch from '@/shared/infrastructure/ui/inputs/text/KBSearch.vue'

export default {
  name: 'TemplateSelector',
  components: {
    KBSearch
  },
  props: {
    templates: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      search: ''
    }
  },
  computed: {
    templatesFiltered () {
      if (!this.search) {
        return this.templates
      } else {
        return this.templates.filter(template => {
          return template.templateMessage.toLowerCase().includes(this.search.toLowerCase())
        })
      }
    }
  },
  methods: {
    selectTemplate (template) {
      if (!template.templateMessage) {
        EventBus.$emit('toast', 'error', this.$t('mustSelectATemplate'))
        return
      }
      this.$emit('selectTemplate', template)
    },
    getParams (templateMessage) {
      const params = []
      const regex = /\{\{(.*?)\}\}/g
      let match
      while ((match = regex.exec(templateMessage)) !== null) {
        params.push({
          id: match[1],
          value: '',
          name: `{{${match[1]}}}`
        })
      }
      return params
    },
    parseTemplateMessage (templateMessage) {
      const params = this.getParams(templateMessage)
      for (let i = 0; i < params.length; i++) {
        templateMessage = templateMessage.replace(params[i].name, `<span style="color:#01CFE4;">${`${this.$t('customTextField')}`}</span>`)
      }
      return `<span style="font-weight: 400; font-size: 14px; line-height: 23px;">${templateMessage}</span>`
    }
  }
}
