<template>
  <svg :width="width"
       :height="height"
       viewBox="0 0 16 20"
       fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <rect width="14"
          height="4.28571"
          rx="2"
          transform="matrix(0.999784 -0.0207797 -0.0207797 -0.999784 0.903625 5.43219)"
          :fill="color"/>
    <rect width="14"
          height="4.28571"
          rx="2"
          transform="matrix(0.999784 -0.0207797 -0.0207797 -0.999784 1.04608 12.2879)"
          :fill="color"/>
    <rect width="14"
          height="4.28571"
          rx="2"
          transform="matrix(0.999784 -0.0207797 -0.0207797 -0.999784 1.1886 19.1436)"
          :fill="color"/>
  </svg>

</template>

<script>
export default {
  name: 'ListSaleIcon',
  props: {
    width: {
      type: Number,
      default: 16
    },
    height: {
      type: Number,
      default: 20
    },
    color: {
      type: String,
      default: '#B6BEC7'
    }
  }

}
</script>

<style>

</style>
