import { mapState } from 'vuex'
import Colors from '@/utils/Colors'
import LottieAnimation from 'lottie-web-vue'
import KBIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'
import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import { EventBus } from '@/eventBus'
import APIKeysUseCases from '@/modules/APIKeysConfig/application/APIKeysUseCases'
import APIKeysController from '../../controllers/APIKeys.controller'
import { ActivityActionTypeEnum, ActivityLogModuleTypeEnum, dataToWriteActivityLog } from '@/modules/Config/ActivityLogs/domain/ActivityLogsController'
import ActivityLogsUseCases from '@/modules/Config/ActivityLogs/application/ActivityLogsUseCases'
import ActivityLogsController from '@/modules/Config/ActivityLogs/infrastructure/controllers/ActivityLogs.controller'
import { CDPFieldsEnums } from '@/modules/CDP/shared/contacts/domain/enums/CDPFieldsEnums'
export default {
  components: {
    KBIcon,
    LottieAnimation
  },
  props: {
    apiKey: {
      type: Object
    }
  },
  computed: {
    ...mapState('AppStore', ['selectedApp']),
    ...mapState('UserStore', ['token', 'user', 'isUserReadonly']),
    creationDate () {
      return this.apiKey?.created_at || ''
    },
    expirationDate () {
      return this.apiKey?.expired_at || ''
    },
    creatorName () {
      if (this.apiKey?.user?.name && this.apiKey?.user?.lastName) {
        return this.apiKey?.user?.name + ' ' + this.apiKey?.user?.lastName
      } else if (this.apiKey?.user?.name) {
        return this.apiKey?.user?.name
      } else return this.$t('toBeDefined')
    },
    selectedAppUuid () {
      return this.selectedApp?.uuid || ''
    }
  },
  data () {
    return {
      loading: false,
      apiKeysController: new APIKeysController(),
      activityLogsController: new ActivityLogsController(),
      KeybeIconType,
      Colors
    }
  },
  methods: {
    formatDate (date) {
      return new Date(date).toLocaleDateString()
    },
    copy (copy) {
      navigator.clipboard
        .writeText(copy)
        .then(() => {
          EventBus.$emit('toast', 'success', this.$t('copiedToClipboard'))
        })
        .catch(() => {
          EventBus.$emit('toast', 'error', this.$t('errorCopying'))
        })
    },
    async deleteKey () {
      try {
        if (this.isUserReadonly) return
        this.loading = true
        const data = {
          app: this.selectedApp?.uuid,
          token: this.token,
          id: this.apiKey?.id
        }
        await APIKeysUseCases.deleteAPIKey(this.apiKeysController, data)
        setTimeout(() => {
          this.loading = false
          this.writeActivityLog()
          this.$emit('getAPIKeys')
        }, 500)
      } catch (e) {
        console.error(e)
        this.loading = false
      }
    },
    async writeActivityLog (): Promise<void> {
      const data: dataToWriteActivityLog = {
        token: this.token,
        updateInput: {
          appUUID: this.selectedApp?.uuid,
          module: ActivityLogModuleTypeEnum.API,
          action: ActivityActionTypeEnum.API_KEY_DELETE,
          user: {
            id: this.user.uuid,
            name: this.user.name + ' ' + this.user.lastName
          },
          payload: {
            field: CDPFieldsEnums.KEY,
            newValue: this.apiKey.ket,
            oldValue: ''
          }
        }
      }
      await ActivityLogsUseCases.writeActivityLog(this.activityLogsController, data)
    }
  }
}
