
































































































import BikyConfigScheduleBasic from './BikyConfigScheduleBasic'

export default BikyConfigScheduleBasic
