import KeybeSelect from '../KeybeSelect/KeybeSelect/KeybeSelect.vue'
export default {
  components: {
    KeybeSelect
  },
  props: {
    value: {
      type: String
    },
    startYear: {
      type: Number,
      default: 1980
    },
    stopYear: {
      type: Number,
      default: new Date().getFullYear()
    },
    autofill: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String
    },
    showPlaceholder: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean
    }
  },
  async mounted () {
    this.getYears()
    if (this.value) await this.setInitialValue(this.value)
    if (this.autofill) await this.setInitialValue(new Date().toISOString())
  },
  data () {
    return {
      years: [],
      months: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      days: [],
      year: null,
      day: null,
      month: null,
      date: ''
    }
  },
  methods: {
    setInitialValue (value: string) : void {
      if (value) {
        const valueTime = value?.toString()?.substring(0, 10) + 'T12:00:00.000Z'
        const date = new Date(valueTime)
        this.year = date.getFullYear()
        this.month = date.getMonth() + 1
        this.day = date.getDate()
      }

      if (this.autofill) this.verify()
      this.getDays(this.month)
    },
    checkLeapYear (year: number): boolean {
      if ((year % 4 === 0 && year % 100 !== 0) || year % 400 === 0) {
        return true
      } else {
        return false
      }
    },
    getYears () {
      const currentYear = new Date().getFullYear()
      let startYear = this.startYear || 1980
      const stopYear = this.stopYear || currentYear
      while (startYear <= stopYear) {
        this.years.push(startYear++)
      }
      this.years = this.years.reverse()
    },
    getDays (e: number) {
      this.verify()
      const leapYear = this.checkLeapYear(this.year)

      const daysByMonth: { [key: number]: number } = {
        1: 31,
        2: leapYear ? 29 : 28,
        3: 31,
        4: 30,
        5: 31,
        6: 30,
        7: 31,
        8: 31,
        9: 30,
        10: 31,
        11: 30,
        12: 31
      }

      const totalDays = daysByMonth[e] || 0

      this.days = []

      for (let i = 1; i <= totalDays; i++) {
        this.days.push(i)
      }
    },
    verify () {
      if (this.day && this.month > 0 && this.year) {
        this.date = new Date(`${this.year}-${this.month}-${+this.day}`).toISOString().substring(0, 10)
        this.$emit('input', this.date)
        this.$emit('save', this.date)
      }
    }
  },
  watch: {
    value () {
      if (this.value && !this.date) this.setInitialValue(this.value)
    }
  }
}
