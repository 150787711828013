































import { EventBus } from '@/eventBus'
import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import AppConfigService from '@/components/configuration/Services/AppConfigService'
import ConfigurationService from '@/services/ConfigurationService'
import KBTableBrands from './KBTableBrands.vue'
import KBButton from '@/shared/infrastructure/ui/buttons/KBButton.vue'
import KeybeText from '@/modules/DesignSystem/infrastructure/components/KeybeText/KeybeText.vue'
import CustomPagination from '@/components/shared/components/CustomPagination.vue'
import { CDPFieldsEnums } from '@/modules/CDP/shared/contacts/domain/enums/CDPFieldsEnums'

export default {
  name: 'BrandsConfig',
  components: {
    KBButton,
    KeybeText,
    KBTableBrands,
    CustomPagination
  },
  data () {
    return {
      search: '',
      KeybeIconType,
      CDPFieldsEnums,
      count: 1,
      itemsPerPage: 10,
      currentPage: 1,
      loading: false,
      creating: false,
      newDomainDialog: false,
      headers: [
        { text: '', field: 'profilePic' },
        { text: this.$t(CDPFieldsEnums.NAME), field: CDPFieldsEnums.NAME },
        { text: this.$t('email'), field: 'email' },
        { text: this.$t('status'), field: 'active' },
        { text: this.$t('createdAt'), field: 'createdAt' },
        { text: '', field: 'actions' }
      ],
      brands: []
    }
  },
  computed: {
    filteredList: function () {
      const temp = this.brands.filter((brand) =>
        brand.name.toLowerCase().includes(this.search.toLowerCase())
      )
      return temp
    }
  },
  async created () {
    await this.getBrands()
  },
  watch: {
  },
  methods: {
    setPagination (pagination) {
      this.currentPage = pagination.page
      this.itemsPerPage = pagination.items
      this.getBrands()
    },
    async getBrands () {
      try {
        this.loading = true
        const pagination = {
          page: this.currentPage,
          limit: this.itemsPerPage
        }
        const response = await ConfigurationService.getBrands(this.search, pagination)
        if (response) {
          this.brands = response.apps
          this.count = response.total
        }
        this.loading = false
      } catch (error) {
        console.error(error)
      }
    },
    async getWizardReport () {
      this.loading = true
      const report = await AppConfigService.getWizardReport()
      if (report.statusCode === 200) {
        EventBus.$emit('toast', 'success', this.$t('wizardReportSuccess'))
      } else {
        EventBus.$emit('toast', 'error', this.$t('wizardReportError'))
      }
      this.loading = false
    }
  }
}
