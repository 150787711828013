import Colors from '@/utils/Colors'
import ImporterUseCases from '@/modules/imports/application/ImporterUseCases'
import EmitToast from '@/utils/EmitToast'
import ImporterController from '../../../controllers/Importer.controller'
import { mapActions, mapState } from 'vuex'
import { EntityTypeEnum } from '@/modules/CDP/shared/contacts/domain/Contacts'
import { ImportsTabsEnum } from '@/modules/imports/domain/enums/ImportsTabsEnum'
import { EventBus } from '@/eventBus'
import countries from '@/utils/countries'
import timezoneWithCountry from '@/utils/timezoneWithCountry'
import { SegmentType } from '@/modules/people/domain/models/Segment'
import { FloatingWindowType } from '@/shared/infrastructure/enums/Layout/FloatingWindowType'
import CrystalBallSVG from '@/shared/assets/CrystalBallSVG.vue'
import SegmentsUseCases from '@/modules/CDP/shared/segments/application/SegmentsUseCases'
import SegmentsController from '@/modules/CDP/shared/segments/infrastructure/controllers/Segments.controller'
import { CDPFieldsEnums } from '@/modules/CDP/shared/contacts/domain/enums/CDPFieldsEnums'
import { ActivityActionTypeEnum, ActivityLogModuleTypeEnum, dataToWriteActivityLog } from '@/modules/Config/ActivityLogs/domain/ActivityLogsController'
import ActivityLogsUseCases from '@/modules/Config/ActivityLogs/application/ActivityLogsUseCases'
import ActivityLogsController from '@/modules/Config/ActivityLogs/infrastructure/controllers/ActivityLogs.controller'
export default {
  components: {
    CrystalBallSVG
  },
  props: {
    importer: {
      type: Object
    },
    createSegment: {
      type: Boolean
    },
    segmentName: {
      type: String
    },
    isCampaigns: {
      type: Boolean
    }
  },
  computed: {
    ...mapState('AppStore', ['selectedApp']),
    ...mapState('UserStore', ['token', 'user']),
    showButton () {
      return false
    }
  },
  mounted () {
    if (this.createSegment) this.create()
    else this.detectCountry()
  },
  data () {
    return {
      Colors,
      segmentsController: new SegmentsController(),
      loading: false,
      segmentStatic: {},
      importerController: new ImporterController(),
      activityLogsController: new ActivityLogsController()
    }
  },
  methods: {
    ...mapActions('FloatingWindowsStore', ['setMaximazedWindow']),
    async create () {
      this.loading = true
      if (!this.segmentName) {
        EventBus.$emit('toast', 'error', this.$t('mustAddSegmentName'))
        this.loading = false
        return
      }
      const createInput = {
        name: this.segmentName,
        type: SegmentType.Static,
        typeEntity: this.importer.typeEntity,
        filter: null,
        inactive: false
      }
      const data = {
        app: this.selectedApp?.uuid || '',
        typeEntity: this.importer.typeEntity,
        token: this.token,
        createInput: createInput
      }

      const response = await SegmentsUseCases.createSegment(this.segmentsController, data)
      if (response?.status) {
        setTimeout(() => {
          // @ts-ignore
          if (response?.data?._id) {
            // @ts-ignore
            const segmentId = response?.data?._id
            if (segmentId) {
              this.segmentStatic = { id: segmentId, name: this.segmentName }
              this.detectCountry()
            }
          } else {
            EmitToast.emitErrors(response)
            this.loading = false
          }
        }, 300)
      }
    },
    async writeActivityLog (name: string): Promise<void> {
      const data: dataToWriteActivityLog = {
        token: this.token,
        updateInput: {
          appUUID: this.selectedApp?.uuid,
          module: ActivityLogModuleTypeEnum.CDP,
          action: ActivityActionTypeEnum.IMPORT_CREATED,
          user: {
            id: this.user.uuid,
            name: this.user.name + ' ' + this.user.lastName
          },
          payload: {
            field: CDPFieldsEnums.NAME,
            newValue: name,
            oldValue: ''
          }
        }
      }
      await ActivityLogsUseCases.writeActivityLog(this.activityLogsController, data)
    },
    detectCountry () {
      if (!this.importer.defaultCountryCode) {
        if (this.selectedApp?.defaultCountryCode) {
          this.importer.defaultCountryCode = this.selectedApp?.defaultCountryCode
        } else {
          const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone
          if (currentTimezone) {
            for (const [key, value] of Object.entries(timezoneWithCountry)) {
              let zone = ''
              if (value.zones.length > 0) zone = value.zones.filter(zone => zone === currentTimezone)[0]
              if (zone) {
                const countryCode = key.toLowerCase()
                const countryInfo = countries.filter(c => c.code.toLowerCase() === countryCode)[0]
                if (countryInfo) {
                  this.importer.defaultCountryCode = countryInfo.number
                }
              }
            }
          }
        }
      }
      this.createImport()
    },
    async createImport () {
      if (this.importer) {
        this.loading = true
        const data = {
          app: this.selectedApp?.uuid,
          token: this.token,
          ...this.importer
        }
        if (this.importer.headers) {
          const headers = this.importer.headers.filter(header => header.field || header.field !== '')
          data.headers = headers
        }
        if (this.createSegment) data.segmentStatic = this.segmentStatic
        if (!data.segmentStatic || data.segmentStatic === null || data.segmentStatic === undefined) delete data.segmentStatic
        if (
          (!data.responsible) ||
          (data.responsible && !data?.responsible?.uuid) ||
          (data.responsible && data?.responsible?.uuid === null)
        ) delete data.responsible
        if (!data.categories || data?.categories?.length === 0) data.categories = [CDPFieldsEnums.KEYBE]
        if (!data.defaultStatusForContacts) {
          delete data.defaultStatusForContacts
        }
        const response = await ImporterUseCases.createImporter(this.importerController, data)
        if (response?.status) {
          setTimeout(() => {
            if (this.importer.typeEntity === EntityTypeEnum.BUSINESS) EventBus.$emit('selectImportsTab', EntityTypeEnum.BUSINESS, ImportsTabsEnum.IMPORTS_BUSINESS)
            else EventBus.$emit('selectImportsTab', EntityTypeEnum.PEOPLE, ImportsTabsEnum.IMPORTS_PEOPLE)
          })
          if (this.isCampaigns) {
            this.$emit('segment', data.segmentStatic)
            this.setMaximazedWindow(FloatingWindowType.IMPORTS)
            setTimeout(() => {
              EventBus.$emit('selectImportsTab', EntityTypeEnum.PEOPLE, ImportsTabsEnum.IMPORTS_PEOPLE)
            }, 300)
          }
          this.writeActivityLog(response?.data?.file?.name)
        } else EmitToast.emitErrors(response)
        this.loading = false
      }
    }
  }
}
