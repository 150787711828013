export enum ExtradataTypeEnum {
  TEXT = 'text',
  CHECKBOX = 'checkbox',
  SELECT = 'select',
  SELECT_DEPENDANT = 'selectDependant',
  NUMERIC = 'numeric',
  RADIO = 'radio',
  TEXTLONG = 'textLong',
  EMAIL = 'email',
  PHONE = 'phone',
  MONEY = 'money',
  DATE = 'date',
  DATE_TIME = 'dateTime',
  LABEL = 'label',
  TEXT_TAG = 'textTag',
  TEXT_ARRAY = 'textArray'
}
