export const fillArray = (arr) => {
  const temp = arr
  for (let i = 1; i < 25; i++) {
    if (temp.find(v => parseInt(v.x) === i)) {
    } else {
      temp.push({
        x: i,
        y: 0
      })
    }
  }
  return temp
}
