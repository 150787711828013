import { AuthFormFields } from '@/components/configuration/conversations/AuthFormFields'
import { AuthMethods } from '@/components/configuration/conversations/AuthMethods'
import {
  WebWidgetConfig,
  WebWidgetWelcomeForm,
  WebWidgetThemeEnum,
  WebWidgetPositionEnum,
  WebWidgetTextAlignEnum
} from '@/modules/configuration/domain/models/WebWidget/WebWidget'

export default class WebWidgetService {
  static buildWebWidgetConfig (config: {
        [key: string]: any
    }): WebWidgetConfig {
    const defaultWelcomeForm: WebWidgetWelcomeForm = {
      text: '',
      color: '#000000',
      fontSize: 14,
      textAlign: WebWidgetTextAlignEnum.LEFT
    }

    const defaultWebWidgetSetup = {
      bubbleSize: 60,
      showBubble: true
    }

    return {
      theme: config.theme || WebWidgetThemeEnum.LIGHT,
      mainColor: config.mainColor || '#000000',
      widgetWebSetup: config.widgetWebSetup || defaultWebWidgetSetup,
      position: config.position || WebWidgetPositionEnum.LEFT,
      logo: config.logo || '',
      hideBubbleMessage: config.hideBubbleMessage || false,
      bubbleMessage: config.bubbleMessage || '',
      disableMultimedia: config.disableMultimedia || false,
      isWhatsappButtonActive: config.isWhatsappButtonActive || false,
      widgetTitle: config.widgetTitle || '',
      welcomeForm: config.welcomeForm || defaultWelcomeForm,
      language: config.language || 'es',
      authMethod: config?.authMethod || AuthMethods.FORM,
      formFields: config?.formFields || [AuthFormFields.Name, AuthFormFields.Phone]
    }
  }
}
