import { dataToGetFunnels, dataToCreateFunnel, dataToUpdateFunnel, IFunnelController, funnelsGetter, dataToDeleteFunnel, dataToGetFunnelByName, funnelByNameGetter, dataToGetUtms, utmsGetter } from '../domain/FunnelsController'

export default class FunnelsUseCases {
  static async getFunnels (controller: IFunnelController, data: dataToGetFunnels) : Promise<funnelsGetter> {
    return await controller.getFunnels(data)
  }

  static async createFunnel (controller: IFunnelController, data: dataToCreateFunnel) {
    return await controller.createFunnel(data)
  }

  static async updateFunnel (controller: IFunnelController, data: dataToUpdateFunnel) {
    return await controller.updateFunnel(data)
  }

  static async deleteFunnel (controller: IFunnelController, data: dataToDeleteFunnel) {
    return await controller.deleteFunnel(data)
  }

  static async getFunnelByName (controller: IFunnelController, data: dataToGetFunnelByName): Promise<funnelByNameGetter> {
    return await controller.getFunnelByName(data)
  }

  static async getUtms (controller: IFunnelController, data: dataToGetUtms): Promise<utmsGetter> {
    return await controller.getUtms(data)
  }

  static abortAll (controller: IFunnelController): void {
    return controller.abortAll()
  }
}
