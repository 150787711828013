import { IUserSectionsController } from '../domain/UserSectionsController'
import { UserType } from '../domain/UserTypes'

export default class UserSectionUseCases {
  static isAgent (controller: IUserSectionsController, type: UserType) : boolean {
    return controller.isAgent(type)
  }

  static isCoordinator (controller: IUserSectionsController, type: UserType) : boolean {
    return controller.isCoordinator(type)
  }

  static getSections (controller: IUserSectionsController, type: UserType) : string[] {
    return controller.getSections(type)
  }

  static canEnterToSection (controller: IUserSectionsController, type: UserType, section: string) : boolean {
    return controller.canEnterToSection(type, section)
  }
}
