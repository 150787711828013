var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.tier)?_c('OldPlan'):_c('div',{staticClass:"SmartOMeterConfig"},[_c('span',{staticClass:"SmartOMeterConfig__title"},[_vm._v(" "+_vm._s(_vm.$t('smartOMeterTitle'))+" "),_c('div',{staticClass:"SmartOMeterConfig__title--beta"},[_vm._v(" BETA ")])]),_c('span',{staticClass:"SmartOMeterConfig__description"},[_vm._v(" "+_vm._s(_vm.$t('smartOMeterDescription'))+" ")]),_c('div',{staticClass:"SmartOMeterConfig__box blackWhite"},[_c('div',{staticClass:"SmartOMeterConfig__infoContainer"},[_c('div',{staticClass:"SmartOMeterConfig__infoContainer--pulse"}),_c('span',{staticClass:"SmartOMeterConfig__infoContainer--text"},[_vm._v(" "+_vm._s(_vm.$t('smartOMeterInfo'))+" ")])]),_c('div',{staticClass:"SmartOMeterConfig__sliderContainer"},[_c('div',{staticClass:"relative"},[_c('div',{staticClass:"SmartOMeterConfig__sliderContainer--dots"},_vm._l(((_vm.sliderLimit)),function(index){return _c('div',{key:("sliderDot-" + index),staticClass:"SmartOMeterConfig__sliderContainer--dot",class:{
              'SmartOMeterConfig__sliderContainer--dot--unselected': index > _vm.tierNumber,
              'SmartOMeterConfig__sliderContainer--dot--selected': index === _vm.tierNumber,
            }})}),0),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.tierNumber),expression:"tierNumber"}],staticClass:"SmartOMeterConfig__sliderContainer--slider",attrs:{"type":"range","min":1,"max":3},domProps:{"value":(_vm.tierNumber)},on:{"input":_vm.onTierChange,"__r":function($event){_vm.tierNumber=$event.target.value}}})])])]),_c('div',{staticClass:"SmartOMeterConfig__tierDescriptions"},[_c('div',{staticClass:"SmartOMeterConfig__tierDescriptions--tier",class:{
        'SmartOMeterConfig__tierDescriptions--tier--selected':  _vm.tierNumber === 1,
      }},[_c('div',{staticClass:"SmartOMeterConfig__tierDescriptions--tier--icon"},[_c('KeybeIcon',{attrs:{"icon":_vm.essentialIcon}})],1),_c('span',{staticClass:"SmartOMeterConfig__tierDescriptions--tier--title"},[_vm._v(" "+_vm._s(_vm.$t('essential'))+" ")]),_vm._l((_vm.tier1Perks),function(item,index){return _c('span',{key:("tier1Perks-" + index),staticClass:"SmartOMeterConfig__tierDescriptions--tier--item",style:(item.strong? 'font-weight: bold;' : '')},[_vm._v(" "+_vm._s(("⋅ " + (item.text)))+" ")])}),_c('span',{staticClass:"SmartOMeterConfig__tierDescriptions--tier--description mt-4"},[_vm._v(" "+_vm._s(_vm.$t('tier1Info'))+" ")])],2),_c('div',{staticClass:"SmartOMeterConfig__tierDescriptions--tier",class:{
        'SmartOMeterConfig__tierDescriptions--tier--selected':  _vm.tierNumber === 2,
      }},[_c('div',{staticClass:"SmartOMeterConfig__tierDescriptions--tier--icon"},[_c('KeybeIcon',{attrs:{"icon":_vm.cortexIcon}})],1),_c('span',{staticClass:"SmartOMeterConfig__tierDescriptions--tier--title"},[_vm._v(" "+_vm._s(_vm.$t('cortex'))+" ")]),_vm._l((_vm.tier3Perks),function(item,index){return _c('span',{key:("tier3Perks-" + index),staticClass:"SmartOMeterConfig__tierDescriptions--tier--item",style:(item.strong? 'font-weight: bold;' : '')},[_vm._v(" "+_vm._s(("⋅ " + (item.text)))+" ")])}),_c('span',{staticClass:"SmartOMeterConfig__tierDescriptions--tier--description mt-4"},[_vm._v(" "+_vm._s(_vm.$t('tier3Info'))+" ")])],2),_c('div',{staticClass:"SmartOMeterConfig__tierDescriptions--tier SmartOMeterConfig__tierDescriptions--tier--disabled",class:{
        'SmartOMeterConfig__tierDescriptions--tier--selected':  _vm.tierNumber === 3,
      }},[_c('div',{staticClass:"SmartOMeterConfig__tierDescriptions--tier--icon"},[_c('KeybeIcon',{attrs:{"icon":_vm.tesseractIcon}})],1),_c('span',{staticClass:"SmartOMeterConfig__tierDescriptions--tier--title"},[_vm._v(" "+_vm._s(_vm.$t('tesseract'))+" ")]),_vm._l((_vm.tier4Perks),function(item,index){return _c('span',{key:("tier4Perks-" + index),staticClass:"SmartOMeterConfig__tierDescriptions--tier--item",style:(item.strong? 'font-weight: bold;' : '')},[_vm._v(" "+_vm._s(("⋅ " + (item.text)))+" ")])}),_c('span',{staticClass:"SmartOMeterConfig__tierDescriptions--tier--description mt-4"},[_vm._v(" "+_vm._s(_vm.$t('tier4Info'))+" ")])],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }