import { EventBus } from '@/eventBus'
import { BikyConfigTabs } from '@/modules/bky/domain/BikyConfigTabs'
import BikyConfigBasic from '@/modules/bky/infrastructure/ui/Config/BikyConfigBasic/BikyConfigBasic.vue'
import BikyConfigSidebar from '@/modules/bky/infrastructure/ui/Config/BikyConfigSidebar/BikyConfigSidebar.vue'
import ConfigurationSidebar from '@/modules/configuration/infrastructure/ui/components/ConfigurationSidebar/ConfigurationSidebar.vue'
import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import BikyConfigChat from '../BikyConfigChat/BikyConfigChat.vue'
import BikyConfigEnabled from '../BikyConfigEnabled/BikyConfigEnabled.vue'
import BikyConfigErrors from '../BikyConfigErrors/BikyConfigErrors.vue'
import BikyConfigKnowledgeBase from '../BikyConfigKnowledgeBase/BikyConfigKnowledgeBase.vue'
import BikyConfigPersonality from '../BikyConfigPersonality/BikyConfigPersonality.vue'
import BikyConfigSkills from '../BikyConfigSkills/BikyConfigSkills.vue'

export default {
  name: 'BikyConfig',
  components: {
    ConfigurationSidebar,
    BikyConfigBasic,
    BikyConfigSidebar,
    BikyConfigKnowledgeBase,
    BikyConfigChat,
    BikyConfigErrors,
    BikyConfigEnabled,
    BikyConfigSkills,
    BikyConfigPersonality
  },
  data () {
    return {
      selectedTab: BikyConfigTabs.INFO,
      KeybeIconType,
      showFooter: false
    }
  },
  methods: {
    selectTab (tabName: string): void {
      this.selectedTab = tabName
    },
    save (): void {
      EventBus.$emit('BikyConfig:save')
    },
    setShowFooter (value: boolean): void {
      this.showFooter = value
    }
  },
  computed: {
    tabComponent () {
      const components = {
        [BikyConfigTabs.INFO]: BikyConfigBasic,
        [BikyConfigTabs.KNOWLEDGE_BASE]: BikyConfigKnowledgeBase,
        [BikyConfigTabs.CHAT]: BikyConfigChat,
        [BikyConfigTabs.ERRORS]: BikyConfigErrors,
        [BikyConfigTabs.BASIC]: BikyConfigEnabled,
        [BikyConfigTabs.SKILLS]: BikyConfigSkills,
        [BikyConfigTabs.PERSONALITY]: BikyConfigPersonality
      }

      return components[this.selectedTab]
    }
  },
  mounted () {
    EventBus.$on('BikyConfig:show-save-button', (value) => this.setShowFooter(value))
  },
  beforeDestroy () {
    EventBus.$off('BikyConfig:show-save-button')
  }
}
