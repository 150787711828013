import { EventBus } from '@/eventBus'
import KeybeText from '@/modules/DesignSystem/infrastructure/components/KeybeText/KeybeText.vue'
import Loader from '@/shared/infrastructure/ui/Loader.vue'
import KeybeAutocomplete from '@/modules/DesignSystem/infrastructure/components/KeybeAutocomplete/KeybeAutocomplete/KeybeAutocomplete.vue'
import FeesUseCases from '@/modules/Fees/application/FeesUseCases'
import { datatoCreateProducttV2, dataToGetInvoices, datatoUpdateProductV2 } from '@/modules/Fees/domain/FeesController'
import FeesController from '@/modules/Fees/infrastructure/controllers/Fees.controller'
import KeybeButton from '@/modules/DesignSystem/infrastructure/components/KeybeButton/KeybeButton.vue'
import Colors from '@/utils/Colors'
import { mapState } from 'vuex'

export default {
  name: 'KBProductsList',
  components: {
    KeybeAutocomplete,
    KeybeText,
    Loader,
    KeybeButton
  },
  data () {
    return {
      selectedDaily: null,
      selectedCountry: null,
      selectedPlan: null,
      typeOptions: [],
      countryOptions: [],
      planOptions: [],
      dailyOptions: [],
      rerender: 0,
      idItem: null,
      showForm: false,
      Colors,
      loading: true,
      status: null,
      showUpdate: false,
      formData: {
        type: '',
        label: '',
        description: '',
        status: 'draft',
        statusNew: 'published',
        createdBy: '',
        amountUSD: 0,
        sku: '',
        tierCategoryId: null,
        countryId: null,
        planId: null
      },
      columns: [
        { key: 'label', label: 'name' },
        { key: 'planLabel', label: 'plan' },
        { key: 'dailyContactLabel', label: 'DailyContact' },
        { key: 'countryName', label: 'country' },
        { key: 'status', label: 'feesStatus' },
        { key: 'actions', label: 'actions' }
      ],
      data: [],
      feesController: new FeesController(),
      collapsedGroups: {}
    }
  },
  watch: {
    selectedCountry () {
      this.rerender++
    },
    selectedPlan () {
      this.rerender++ // Forzar re-render
    },
    selectedDaily () {
      this.rerender++ // Forzar re-render
    }
  },
  mounted () {
    this.calculateSize(true)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.calculateSize)
  },
  computed: {
    ...mapState('UserStore', ['user', 'token']),
    filteredData () {
      return this.data.filter(item => {
        const matchesCountry = this.selectedCountry ? item.countryName === this.selectedCountry : true
        const matchesPlan = this.selectedPlan ? item.planLabel === this.selectedPlan : true
        const matchesDaily = this.selectedDaily ? item.dailyContactLabel === this.selectedDaily : true
        return matchesCountry && matchesPlan && matchesDaily
      })
    }
  },
  async created () {
    await this.getItems()
    await this.getItemsCountry()
    await this.getPlans()
    await this.getDailyContact()
    this.typeOptions = [
      { name: 'daily_contact' },
      { name: 'plan' }
    ]
  },
  methods: {
    async getDailyContact () {
      try {
        this.dailyOptions = []
        const data: dataToGetInvoices = {
          token: this.token,
          status: this.status
        }
        const response = await FeesUseCases.getDailyContactV2(this.feesController, data)

        if (response) {
          this.dailyOptions = response.data
        }
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
      }
    },
    async getPlans () {
      try {
        this.planOptions = []
        const data: dataToGetInvoices = {
          token: this.token,
          status: this.status
        }
        const response = await FeesUseCases.getPlansV2(this.feesController, data)

        if (response) {
          this.planOptions = response.data
        }
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
      }
    },
    toggleGroup (plan) {
      this.$set(this.collapsedGroups, plan, !this.collapsedGroups[plan])
    },
    groupedData () {
      const grouped = this.data.reduce((groups, item) => {
        const plan = item.planLabel || 'No Plan'
        if (!groups[plan]) {
          groups[plan] = []
        }
        groups[plan].push(item)
        return groups
      }, {})
      return Object.entries(grouped)
    },
    hiddenForm () {
      this.showForm = false
      this.showUpdate = false
      this.cleanForm()
    },
    cleanForm () {
      this.idItem = null
      this.formData = {
        label: null,
        description: null,
        status: false,
        statusNew: false,
        name: null,
        createdBy: null
      }
    },
    updateItem (item) {
      this.showUpdate = true
      this.showForm = true
      this.idItem = item.id
      this.formData = {
        type: item.type,
        label: item.label,
        description: item.description,
        status: item.status,
        createdBy: item.createdBy || 'BD',
        amountUSD: 0,
        sku: item.sku,
        tierCategoryId: item.tierCategoryId,
        countryId: item.countryId,
        planId: item.planId
      }
    },
    async updateItemRequest () {
      try {
        this.data = []
        const form = this.formData
        const data: datatoUpdateProductV2 = {
          id: this.idItem,
          token: this.token,
          type: form.type,
          label: form.label,
          description: form.description,
          status: form.status ? 'published' : 'draft',
          createdBy: this.user.uuid || 'BD',
          amountUSD: 0,
          sku: form.sku,
          tierCategoryId: form.tierCategoryId,
          countryId: form.countryId,
          planId: form.planId
        }
        const response = await FeesUseCases.patchProductsV2(this.feesController, data)
        if (response) {
          EventBus.$emit('toast', 'success', this.$t('itemSuccessfullyUpdated'))
          await this.getItems()
          this.hiddenForm()
        }
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
      }
    },
    async createdItem () {
      try {
        this.data = []
        const form = this.formData
        const data: datatoCreateProducttV2 = {
          type: form.type,
          token: this.token,
          label: form.label,
          description: form.description,
          status: form.status ? 'published' : 'draft',
          createdBy: this.user.uuid || 'BD',
          amountUSD: 0,
          sku: form.sku,
          tierCategoryId: form.tierCategoryId,
          countryId: form.countryId,
          planId: form.planId
        }
        const response = await FeesUseCases.postProductsV2(this.feesController, data)
        if (response) {
          EventBus.$emit('toast', 'success', this.$t('itemAddedSuccessfully'))
          await this.getItems()
          this.hiddenForm()
        }
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
      }
    },
    getValue (item, key) {
      const keys = key.split('.')
      let value = item
      for (const k of keys) {
        if (value && k in value) {
          value = value[k]
        } else {
          return null
        }
      }
      return value
    },
    async getItems () {
      try {
        this.data = []
        const data: dataToGetInvoices = {
          token: this.token,
          status: this.status
        }
        const response = await FeesUseCases.getProductsV2(this.feesController, data)

        if (response) {
          this.data = response.data
        }
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
      }
    },
    async getItemsCountry () {
      try {
        this.countryOptions = []
        const data: dataToGetInvoices = {
          token: this.token,
          status: this.status
        }
        const response = await FeesUseCases.getCountryV2(this.feesController, data)

        if (response) {
          this.countryOptions = response.data
        }
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
      }
    },
    calculateSize (fullscreen = false) {
      if (fullscreen) {
        const clientHeight = document.documentElement.clientHeight
        const clientWidth = document.documentElement.clientWidth
        document.documentElement.style.setProperty(
          '--floating-screen-height',
          `${clientHeight - 30}px`
        )
        document.documentElement.style.setProperty(
          '--floating-screen-width',
          `${clientWidth - 50}px`
        )

        // set top and left to 0
        const floatingWindow = document.getElementById('KeybeFloatingBox')
        floatingWindow.style.top = '0px'
        floatingWindow.style.left = '0px'

        EventBus.$emit('FloatingWindow-resize')
        return
      }

      const clientHeight = document.documentElement.clientHeight
      this.totalHeight = clientHeight * 0.8097
      this.heightWithoutHeader = clientHeight * 0.8097 - 30
      document.documentElement.style.setProperty(
        '--floating-screen-height',
        `${this.heightWithoutHeader}px`
      )

      const clientWidth = document.documentElement.clientWidth
      this.width = clientWidth * 0.8
      document.documentElement.style.setProperty(
        '--floating-screen-width',
        `${this.width}px`
      )

      EventBus.$emit('FloatingWindow-resize')
    }
  }
}
