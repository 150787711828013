
import KBButton from '@/shared/infrastructure/ui/buttons/KBButton.vue'
import { mapActions } from 'vuex'

export default {
  name: 'AlertModalFullScreen',
  components: {
    KBButton
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    msg: {
      type: String,
      default: ''
    },
    msgBtn: {
      type: String,
      default: ''
    }
  },
  mounted () {
  },
  computed: {
    showModalFScreen: {
      get () {
        return this.$store.state.AppStore.showModalFScreen
      },
      set (value) {
        this.$store.commit('AppStore/SHOW_MODAL_FULL_SCREEN', value)
      }
    },
    linkImage () {
      return `${process.env.VUE_APP_STORAGE_URL}/crm/prod/profile/c3dl1lwt74smj_KEB9NWZK78.png`
    }
  },
  methods: {
    ...mapActions('FloatingWindowsStore', ['setMaximazedWindow']),
    goTo () {
      this.showModalFScreen = false
      // this.setMaximazedWindow(FloatingWindowType.CONFIGURATION)
      // setTimeout(() => {
      //   EventBus.$emit('setConfigurationTab', ConfigurationTabsEnum.DOMAINS)
      // }, 300)
    }
  },
  data () {
    return {
    }
  }
}
