import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'
import ChatFiltersNewAnimation from '@/modules/ChatFilters/infrastructure/ui/ChatFiltersNewAnimation/ChatFiltersNewAnimation.vue'
import Colors from '@/shared/domain/colors/Color'

export default {
  name: 'ChatFiltersConfigList',
  components: {
    KeybeIcon,
    ChatFiltersNewAnimation
  },
  props: {
    filters: {
      type: Array,
      required: true
    },
    favorites: {
      type: Array,
      required: true
    },
    inbox: {
      type: Object,
      required: true
    },
    selectedFilter: {
      required: false,
      default: null
    }
  },
  data () {
    return {
      KeybeIconType,
      selected: 0,
      filterHovered: '',
      showMenu: '',
      Colors
    }
  },
  computed: {
    inboxName () {
      return this.inbox?.name || ''
    }
  },
  methods: {
    isInFavorites (filter) {
      return this.favorites.some(favorite => favorite._id === filter._id)
    },
    isSelected (filter) {
      return this.selectedFilter?._id === filter._id
    },
    handleFilterClick (filter) {
      if (this.isInFavorites(filter)) {
        this.$emit('removeFavorite', {
          index: this.favorites.findIndex(favorite => favorite._id === filter._id)
        })
      } else {
        this.$emit('addFavorite', {
          filter
        })
      }
    },
    toggleMenu (filter) {
      if (this.showMenu === filter._id) {
        this.showMenu = ''
      } else {
        this.showMenu = filter._id
      }
    },
    closeMenu () {
      this.showMenu = ''
    },
    deleteFilter (filter) {
      this.$emit('deleteFilter', filter)
    },
    editFilter (filter) {
      this.$emit('editFilter', filter)
    }
  }
}
