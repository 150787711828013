<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       fill="none"
       viewBox="0 0 9 10"><path :fill="color"
                                d="m1.197.944.894-.227c.434-.111.867-.219 1.322-.2.477.022.922.154 1.349.363.362.177.74.285 1.142.307.45.025.882-.046 1.299-.22.172-.072.339-.156.512-.226.354-.142.732.095.776.483.007.064.008.13.009.194V6.63c0 .42-.125.608-.507.757-.394.154-.784.318-1.206.383a3.148 3.148 0 0 1-1.726-.202c-.41-.17-.81-.362-1.254-.437-.497-.085-.984-.04-1.468.079-.345.084-.687.178-1.032.26-.083.02-.106.06-.106.142.003.569.003 1.138.001 1.707-.001.34-.132.551-.391.643-.399.14-.777-.114-.805-.543A1.854 1.854 0 0 1 0 9.288L0 .715C0 .475.043.256.252.112A.593.593 0 0 1 .878.069a.544.544 0 0 1 .318.493c.006.12.001.24.001.38v.002Zm2.431 3.502c.003.07.007.117.007.163 0 .374.002.747 0 1.12-.001.085.02.117.108.136.333.07.664.154.996.234.039.009.077.021.13.037v-1.35c.029.004.046.004.061.007.353.08.706.164 1.06.238.088.02.078.074.078.134v1.388c.31.05.666-.012 1.116-.177.086-.031.118-.073.116-.167-.006-.33-.002-.66-.002-.99v-.294c-.408.08-.802.145-1.207.103V3.843l1.2-.182V2.273l-1.224.195v1.311c-.086-.01-.158-.008-.225-.026-.289-.08-.576-.167-.866-.244-.083-.023-.114-.06-.112-.152.005-.33.005-.66-.001-.99-.001-.043-.036-.109-.071-.125a25.171 25.171 0 0 0-.853-.363c-.092-.037-.19-.057-.287-.085v1.383c.375.088.732.174 1.09.253.088.02.114.058.112.149-.004.356-.001.712-.002 1.068 0 .033-.007.065-.01.096-.02.001-.028.004-.036.002-.363-.095-.727-.187-1.089-.288-.03-.01-.066-.071-.067-.11-.01-.312-.012-.625-.016-.937v-.171c-.416-.063-.795.067-1.196.128V1.92c-.04.004-.065.003-.089.01-.352.09-.703.183-1.056.27-.075.017-.09.054-.09.125.002.408 0 .816.002 1.224 0 .033.009.064.015.107l1.2-.268c0 .398-.003.78.002 1.16.001.093-.027.128-.115.146-.335.07-.668.147-1.001.227-.038.01-.096.056-.097.086-.007.399-.005.797-.005 1.206.05-.01.087-.014.123-.023.32-.08.637-.173.96-.235.13-.025.152-.075.15-.192-.007-.326 0-.652-.005-.978-.001-.086.027-.122.108-.139.173-.036.344-.086.518-.118.185-.034.373-.053.571-.08l-.001-.002Z"/></svg>
</template>

<script>
export default {
  name: 'FlagIcon',
  props: {
    color: {
      type: String,
      default: '#fff'
    }
  }
}
</script>

<style>

</style>
