import { MicrositeConfig } from '../../domain/MicrositeConfig'
import { IMicrositeConfigController, configDTO, dataToGetConfig } from '../../domain/MicrositeConfigController'
import MicrositeConfigRepository from '../repositories/MicrositeConfig.repository'

export default class MicrositeConfigController implements IMicrositeConfigController {
  repository: MicrositeConfigRepository

  constructor () {
    this.repository = new MicrositeConfigRepository()
  }

  async getConfig (data: dataToGetConfig): Promise<MicrositeConfig> {
    const response = await this.repository.getConfig(data)
    if (!response.status) {
      throw new Error('Error getting config')
    }

    return response?.data?.data[0] || {}
  }

  async createConfig (data: configDTO): Promise<MicrositeConfig> {
    const response = await this.repository.createConfig(data)
    if (!response.status) {
      throw new Error('Error creating config')
    }
    return response?.data?.data
  }

  async updateConfig (data: configDTO): Promise<MicrositeConfig> {
    const response = await this.repository.updateConfig(data)
    if (!response.status) {
      throw new Error('Error updating config')
    }
    return response?.data?.data
  }
}
