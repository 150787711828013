import { BikyConfigUseCases } from '@/modules/bky/application/BikyConfigUseCases'
import { BikyCatalogConfig, BikyCatalogConfigStatusEnum } from '@/modules/bky/domain/BikyConfig'
import { BikySkill } from '@/modules/bky/domain/BikySkill'
import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import ContextListMenu from '@/shared/infrastructure/ui/ContextListMenu.vue'
import { mapState } from 'vuex'
import BikyConfigController from '../../../controllers/BikyConfig.controller'
import BikyConfigSkillBubble from '../BikyConfigSkillBubble/BikyConfigSkillBubble.vue'
import { EventBus } from '@/eventBus'

export default {
  name: 'BikyConfigMyKeybeCatalog',
  components: {
    BikyConfigSkillBubble,
    ContextListMenu
  },
  data () {
    return {
      config: null,
      bikyConfigController: new BikyConfigController(),
      menu: false
    }
  },
  methods: {
    async getConfig (): Promise<void> {
      const config: BikyCatalogConfig = await BikyConfigUseCases.getMyKeybeCatalogConfig(this.bikyConfigController, {
        appUUID: this.selectedApp?.uuid,
        token: this.token
      })

      this.config = config
    },
    async syncCatalog (): Promise<void> {
      this.$emit('start-loading')
      const synced = await BikyConfigUseCases.syncMyKeybe(this.bikyConfigController, {
        appUUID: this.selectedApp?.uuid,
        token: this.token
      })
      this.$emit('stop-loading')
      if (!synced) {
        EventBus.$emit('toast', 'error', this.$t('unexpectedError'))
        return
      }

      EventBus.$emit('toast', 'success', this.$t('syncingCatalog'))
      this.config.active = true
      this.config.status = BikyCatalogConfigStatusEnum.SYNCING
    },
    async unsyncCatalog (): Promise<void> {
      this.$emit('start-loading')
      await BikyConfigUseCases.unSyncMyKeybe(this.bikyConfigController, {
        appUUID: this.selectedApp?.uuid,
        token: this.token
      })
      await this.getConfig()
      this.$emit('stop-loading')
    }
  },
  computed: {
    ...mapState('AppStore', ['selectedApp', 'advisers']),
    ...mapState('UserStore', ['user', 'token']),
    isSynced (): boolean {
      return this.config?.status === BikyCatalogConfigStatusEnum.SYNCED
    },
    isSyncing (): boolean {
      return this.config?.status === BikyCatalogConfigStatusEnum.SYNCING
    },
    menuItems (): {
            text: string,
            callback: () => void
        }[] {
      if (!this.config?.active) {
        return [
          {
            text: this.$t('syncCatalog'),
            callback: () => {
              this.syncCatalog()
            }
          }
        ]
      }
      if (this.isSyncing) {
        return [
          {
            text: this.$t('syncingCatalog'),
            callback: () => {
            }
          }
        ]
      }
      return [
        {
          text: this.$t('syncCatalog'),
          callback: () => {
            this.syncCatalog()
          }
        },
        {
          text: this.$t('unsyncCatalog'),
          callback: () => {
            this.unsyncCatalog()
          }
        }
      ]
    },
    skill (): BikySkill {
      return {
        title: this.$t('myKeybeCatalog'),
        icon: KeybeIconType.SHOPPING_CART,
        active: this.config?.active
      }
    }
  },
  async mounted () {
    this.$emit('start-loading')
    await this.getConfig()
    this.$emit('stop-loading')
  },
  async beforeDestroy () {
    BikyConfigUseCases.abortAll(this.bikyConfigController)
  }
}
