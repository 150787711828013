<template>
  <svg width="24"
       height="24"
       viewBox="0 0 24 24"
       fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <path d="M-0.000115416 12.3208C-0.0483255 9.40306 1.93345 7.1816 4.49547 6.90758C6.03131 6.74386 7.37258 7.14541 8.5434 8.14843C8.6226 8.21564 8.81199 8.23804 8.90669 8.19151C10.4597 7.42805 12.0094 6.65596 13.5486 5.86493C13.6657 5.80461 13.7604 5.57367 13.7535 5.42718C13.6967 4.21391 14.0049 3.12128 14.6867 2.10965C16.3173 -0.309998 20.1327 -0.718443 22.2833 1.25484C23.9706 2.80418 24.513 5.28242 23.4575 7.41253C22.6741 8.99289 21.3552 9.94076 19.5783 10.1786C18.0546 10.3819 16.6995 9.98901 15.5097 9.00495C15.4013 8.91534 15.1499 8.88604 15.0225 8.94808C13.4918 9.69259 11.9698 10.4543 10.4529 11.2281C10.3461 11.2833 10.3013 11.4591 10.229 11.5797C10.2221 11.59 10.2307 11.609 10.229 11.6228C10.0603 12.4414 10.4339 12.8447 11.1846 13.1652C12.4691 13.715 13.7019 14.3888 14.9467 15.0299C15.1895 15.1557 15.3427 15.1351 15.5476 14.9627C17.3951 13.3979 20.0363 13.329 22.0043 14.7783C23.8329 16.126 24.5061 18.7542 23.5746 20.9171C22.3298 23.8055 18.7966 24.8929 16.1279 23.1937C14.4508 22.1252 13.6984 20.562 13.7656 18.5956C13.7759 18.3113 13.7036 18.1579 13.4436 18.0286C11.9749 17.31 10.5097 16.581 9.05305 15.8365C8.78961 15.7021 8.62776 15.7434 8.41254 15.9227C6.60466 17.4324 3.93417 17.5392 2.06086 16.1295C0.705818 15.1058 0.00677173 13.7098 -0.000115416 12.3208Z"
          :fill="color"/>
  </svg>
</template>

<script>
export default {
  name: 'ShareIcon',
  props: {
    color: {
      type: String,
      default: '#3AF69B'
    }
  }
}
</script>

<style>

</style>
