import KBCheck from '@/shared/infrastructure/ui/inputs/select/KBCheck.vue'
import KeybeText from '@/modules/DesignSystem/infrastructure/components/KeybeText/KeybeText.vue'

export default {
  name: 'FieldFormOptions',
  components: {
    KBCheck,
    KeybeText
  },
  props: {
    field: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      localField: { ...this.field }
    }
  },
  watch: {
    localField: {
      handler (newVal) {
        this.$emit('update-field', newVal)
      },
      deep: true
    }
  }
}
