<template>
  <svg width="140"
       height="28"
       viewBox="0 0 140 28"
       fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <path d="M8.03391 1.09131H11.5805L19.2594 22.2813H15.8156L13.8808 16.5542H5.73367L3.78418 22.2813H0.340332L8.03391 1.09131ZM6.61298 13.9106H12.9868L9.83658 4.5998H9.77786L6.61298 13.9106Z"
          :fill="color"/>
    <path d="M22.708 6.83318H25.8729V9.49153H25.9463C26.8696 7.65553 28.5695 6.54053 30.767 6.54053C34.6937 6.54053 37.2142 9.62395 37.2142 14.5581V14.5728C37.2142 19.4922 34.6791 22.5903 30.8111 22.5903C28.6283 22.5903 26.855 21.4737 25.9463 19.6835H25.8729V27.4231H22.708V6.83481V6.83318ZM33.9906 14.5712V14.5564C33.9906 11.267 32.4229 9.2561 29.9464 9.2561C27.47 9.2561 25.8582 11.3406 25.8582 14.5564V14.5712C25.8582 17.7723 27.5581 19.8568 29.9464 19.8568C32.3348 19.8568 33.9906 17.8295 33.9906 14.5712Z"
          :fill="color"/>
    <path d="M41.1641 6.83318H44.3289V9.49153H44.4024C45.3257 7.65553 47.0256 6.54053 49.2231 6.54053C53.1498 6.54053 55.6703 9.62395 55.6703 14.5581V14.5728C55.6703 19.4922 53.1351 22.5903 49.2671 22.5903C47.0843 22.5903 45.311 21.4737 44.4024 19.6835H44.3289V27.4231H41.1641V6.83481V6.83318ZM52.4467 14.5712V14.5564C52.4467 11.267 50.8789 9.2561 48.4025 9.2561C45.9261 9.2561 44.3143 11.3406 44.3143 14.5564V14.5712C44.3143 17.7723 46.0142 19.8568 48.4025 19.8568C50.7908 19.8568 52.4467 17.8295 52.4467 14.5712Z"
          :fill="color"/>
    <path d="M66.6426 16.641L66.6279 16.4203H69.8222L69.8515 16.6115C70.1452 18.5652 72.093 19.7832 74.9218 19.7832C77.7507 19.7832 79.537 18.5064 79.537 16.5232V16.5085C79.537 14.805 78.3787 13.8796 75.5809 13.2633L73.2807 12.7793C69.1337 11.8981 67.1271 9.98855 67.1271 6.84627V6.83156C67.1418 3.21842 70.3214 0.738281 74.7913 0.738281C79.2613 0.738281 82.2206 3.14649 82.4409 6.70077L82.4556 6.95091H79.2613L79.2466 6.74491C78.953 4.77649 77.2531 3.58791 74.7489 3.58791C72.1257 3.60263 70.4551 4.85006 70.4551 6.70077V6.71548C70.4551 8.30133 71.6575 9.27083 74.2938 9.84305L76.6087 10.327C80.9759 11.2523 82.8503 13.0295 82.8503 16.3042V16.3189C82.8503 20.1805 79.8029 22.6329 74.7913 22.6329C70.044 22.6329 66.9085 20.3718 66.6442 16.641H66.6426Z"
          :fill="color"/>
    <path d="M87.8083 18.2136V9.35898H85.5962V6.83306H87.8083V2.80957H91.0466V6.83306H93.9325V9.35898H91.0466V18.0223C91.0466 19.5935 91.7498 20.0627 93.0826 20.0627C93.4203 20.0627 93.6976 20.0332 93.9325 20.0038V22.4562C93.5655 22.515 92.9651 22.5886 92.3207 22.5886C89.2733 22.5886 87.8083 21.297 87.8083 18.212V18.2136Z"
          :fill="color"/>
    <path d="M96.9878 14.5561V14.5267C96.9878 9.59257 99.9031 6.53857 104.285 6.53857C108.667 6.53857 111.611 9.57785 111.611 14.5267V14.5561C111.611 19.5344 108.711 22.5884 104.3 22.5884C99.8884 22.5884 96.9878 19.5197 96.9878 14.5561ZM108.373 14.5561V14.5267C108.373 11.149 106.761 9.19692 104.285 9.19692C101.809 9.19692 100.226 11.1506 100.226 14.5267V14.5561C100.226 17.978 101.838 19.93 104.314 19.93C106.791 19.93 108.373 17.9616 108.373 14.5561Z"
          :fill="color"/>
    <path d="M115.56 6.83318H118.724V9.47681H118.798C119.295 7.67025 120.586 6.54053 122.344 6.54053C122.798 6.54053 123.194 6.6141 123.444 6.65824V9.60924C123.194 9.50624 122.638 9.43267 122.023 9.43267C119.987 9.43267 118.726 10.7684 118.726 13.0752V22.283H115.561V6.83481L115.56 6.83318Z"
          :fill="color"/>
    <path d="M125.56 14.615V14.6003C125.56 9.76914 128.345 6.53857 132.666 6.53857C136.988 6.53857 139.64 9.65142 139.64 14.2913V15.3638H128.723C128.782 18.2853 130.379 19.9889 132.899 19.9889C134.775 19.9889 136.02 19.0194 136.417 17.8603L136.461 17.7425H139.464L139.435 17.9044C138.937 20.3568 136.563 22.5884 132.826 22.5884C128.299 22.5884 125.558 19.4903 125.558 14.615H125.56ZM128.769 13.1174H136.505C136.24 10.4444 134.746 9.13807 132.681 9.13807C130.615 9.13807 129.046 10.5326 128.769 13.1174Z"
          :fill="color"/>
  </svg>
</template>

<script>
export default {
  name: 'AppleStoreText',
  props: {
    width: {
      type: Number,
      default: 16
    },
    height: {
      type: Number,
      default: 18
    },
    color: {
      type: String,
      default: '#B6BEC7'
    }
  }

}
</script>

<style>

</style>
