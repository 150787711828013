
<template>
  <svg width="45"
       height="30"
       viewBox="0 0 45 30"
       fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <path d="M4.8276 19.745C4.8276 12.402 8.44173 4.89912 12.7509 4.89912C15.0839 4.89912 17.0337 6.2657 20.0205 10.6091C17.184 15.0171 15.4671 17.7807 15.4671 17.7807C11.6914 23.78 10.384 25.1237 8.28019 25.1237C6.17633 25.1237 4.83136 23.1976 4.83136 19.764M29.7245 17.0232L27.0947 12.6075C26.3884 11.4427 25.7084 10.3692 25.0585 9.39094C27.414 5.70994 29.3526 3.87514 31.6631 3.87514C36.4606 3.87514 40.3039 11.0316 40.3039 19.8249C40.3039 23.1747 39.2219 25.1199 36.9753 25.1199C34.7287 25.1199 33.797 23.681 29.7095 17.0194M22.8795 6.33422C19.3254 1.76627 16.3575 0 12.7997 0C5.55268 0 0 9.56224 0 19.6764C0 26.0069 3.00551 30 8.08483 30C11.729 30 14.3476 28.2604 19.0061 20.0076L22.2859 14.1378C22.7555 14.8991 23.2476 15.7252 23.7661 16.6121L25.9488 20.335C30.2054 27.5676 32.576 29.9962 36.8701 29.9962C41.8029 29.9962 44.5455 25.9498 44.5455 19.4861C44.5455 8.89608 38.865 0 31.9674 0C28.3157 0 25.4604 2.78645 22.8757 6.33041"
          :fill="color"/>
  </svg>
</template>

<script>
export default {
  name: 'MetaIcon',
  props: {
    color: {
      type: String,
      default: '#FFF'
    }
  }

}
</script>

  <style>

  </style>
