import { EntityTypeEnum } from '@/modules/CDP/shared/contacts/domain/Contacts'
import { mapState } from 'vuex'

import ImporterSegmentSelectionContainer from '@/modules/imports/infrastructure/components/Importer/ImporterSegmentSelectionContainer/ImporterSegmentSelectionContainer.vue'
import ImporterFieldSelectionContainer from '@/modules/imports/infrastructure/components/Importer/ImporterFieldSelectionContainer/ImporterFieldSelectionContainer.vue'
import ImporterProgress from '@/modules/imports/infrastructure/components/Importer/ImporterProgress/ImporterProgress.vue'
import ImporterPreview from '@/modules/imports/infrastructure/components/Importer/ImporterPreview/ImporterPreview.vue'
import ImporterFooter from '@/modules/imports/infrastructure/components/Importer/ImporterFooter/ImporterFooter.vue'
export default {
  components: {
    ImporterSegmentSelectionContainer,
    ImporterFieldSelectionContainer,
    ImporterProgress,
    ImporterPreview,
    ImporterFooter
  },
  props: {
    isCampaigns: {
      type: Boolean
    }
  },
  computed: {
    ...mapState('AppStore', ['selectedApp']),
    showFooter () {
      if (this.step === 1) {
        return (this.importer?.fileUploadData?.storage &&
               this.importer?.fileUploadData?.file &&
               this.importer?.headers?.length > 0 &&
               this.hasSelectedSegment) || this.isCampaigns
      } else if (this.step === 2 || this.step === 3) {
        return true
      } else return false
    }
  },
  mounted () {
    this.importer.categories = this.selectedApp?.categories
  },
  data () {
    return {
      step: 1,
      hasSelectedSegment: false,
      hasErrors: true,
      createSegment: false,
      segmentName: '',
      importer: {
        fileUploadData: {},
        typeEntity: EntityTypeEnum.PEOPLE,
        headers: [],
        categories: [],
        defaultCountryCode: '',
        defaultStatusForContacts: '',
        responsible: {},
        segmentStatic: {}
      }
    }
  },
  methods: {
    previousStep () {
      if (this.step > 1) this.step--
      else this.$emit('closeImporter')
    }
  }
}
