import KeybeText from '@/modules/DesignSystem/infrastructure/components/KeybeText/KeybeText.vue'
import KeybeTextarea from '@/modules/DesignSystem/infrastructure/components/KeybeTextarea/KeybeTextarea.vue'
import KeybeButton from '@/modules/DesignSystem/infrastructure/components/KeybeButton/KeybeButton.vue'
import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'
import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import Colors from '@/utils/Colors'
export default {
  components: {
    KeybeText,
    KeybeTextarea,
    KeybeButton,
    KeybeIcon
  },
  props: {
    value: {
      type: Array,
      required: true
    }
  },
  mounted () {
    this.videos = this.value
  },
  data () {
    return {
      KeybeIconType,
      Colors,
      videos: [],
      loading: false
    }
  },
  methods: {
    addVideo () {
      this.videos.push({
        url: '',
        description: ''
      })
      this.emitChange()
    },
    addVideoUrl (index: number, url: string) {
      this.videos[index].url = url
      this.emitChange()
    },
    addVideoDescription (index: number, description: string) {
      this.videos[index].description = description
      this.emitChange()
    },
    removeVideo (index: number) {
      if (this.loading) return
      this.loading = true
      this.videos.splice(index, 1)
      this.emitChange()
      this.loading = false
    },
    emitChange () {
      this.$emit('input', this.videos)
    }
  }
}
