import { mapState } from 'vuex'
import Colors from '@/utils/Colors'
import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import KeybeAvatar from '@/shared/infrastructure/ui/KeybeAvatar.vue'
import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'
import { CatalogTabsEnum } from '@/modules/catalog/domain/models/CatalogTabsEnum'
export default {
  components: {
    KeybeAvatar,
    KeybeIcon
  },
  props: {
    selectedTab: {
      type: String
    }
  },
  computed: {
    ...mapState('AppStore', ['selectedApp']),
    ...mapState('UserStore', ['user']),
    itemsGroups () {
      const groups = [
        [
          {
            name: CatalogTabsEnum.CATALOG,
            class: 'green',
            label: this.$t(CatalogTabsEnum.CATALOG.toLowerCase()),
            icon: KeybeIconType.LINKED_LIST,
            color: Colors.black
          },
          {
            name: CatalogTabsEnum.ARCHIVED,
            class: 'green',
            label: this.$t(CatalogTabsEnum.ARCHIVED.toLowerCase()),
            icon: KeybeIconType.SHOPPING_CART,
            color: Colors.black
          },
          {
            name: CatalogTabsEnum.CATEGORIES,
            class: 'green',
            label: this.$t(CatalogTabsEnum.CATEGORIES.toLowerCase()),
            icon: KeybeIconType.CHECK,
            color: Colors.black
          },
          {
            name: CatalogTabsEnum.IMPORTS,
            class: 'green',
            label: this.$t(CatalogTabsEnum.IMPORTS.toLowerCase()),
            icon: KeybeIconType.ARROW_UP,
            color: Colors.black
          }
        ]
      ]
      return groups
    }
  },
  methods: {
    isSelectedTab (tabName) {
      return this.selectedTab === tabName
    },
    selectTab (tab) {
      this.$emit('selectTab', tab.name)
    }
  }
}
