import { KeybeTextTypeEnum } from '@/modules/DesignSystem/domain/models/KeybeTextTypeEnum'
import KeybeText from '../KeybeText/KeybeText.vue'
import { debounce } from 'lodash'
export default {
  components: {
    KeybeText
  },
  props: {
    value: {
      type: String
    },
    placeholder: {
      type: String
    },
    showPlaceholder: {
      type: Boolean,
      default: false
    },
    autofill: {
      type: Boolean,
      default: false
    },
    width: {
      type: Number,
      default: 90
    },
    disabled: {
      type: Boolean
    }
  },
  mounted () {
    if (this.value) this.setInitialValue(this.value)
    if (this.autofill) {
      const hour: number = new Date().getHours()
      const minute: number = new Date().getMinutes()
      this.setInitialValue(`${hour}:${minute}`)
    }
    window.addEventListener('keydown', this.handleKeySave)
  },
  data () {
    return {
      KeybeTextTypeEnum,
      hour: null,
      minute: null
    }
  },
  methods: {
    setInitialValue (value: string): void {
      const separate = value.split(':')
      this.hour = separate[0] || null
      this.minute = separate[1] || null
    },
    checkHour: debounce(function (): void {
      if (this.hour && this.hour.length > 2) {
        this.hour = this.hour.slice(0, 2)
      }

      if (this.hour && this.hour.length === 1) {
        this.hour = this.hour.padStart(2, '0')
      }

      if (this.hour && +this.hour > 23) {
        this.hour = '23'
      }
      this.verify()
    }, 500),
    checkMinute: debounce(function (): void {
      if (this.minute && this.minute.length > 2) {
        this.minute = this.minute.slice(0, 2)
      }

      if (this.minute && this.minute.length === 1) {
        this.minute = this.minute.padStart(2, '0')
      }

      if (this.minute && +this.minute > 59) {
        this.minute = '59'
      }
      this.verify()
    }, 500),
    verify (): void {
      if (this.hour && this.minute) {
        const time = `${this.hour}:${this.minute}`
        this.$emit('input', time)
      }
    },
    handleKeySave (e: KeyboardEvent): void {
      e.stopPropagation()
      if (e.key === 'Enter' && this.hour && this.minute) {
        this.$emit('save')
      }
    }
  },
  watch: {
    value () {
      if (this.value && !this.time) this.setInitialValue(this.value)
    }
  },
  beforeDestroy () {
    window.removeEventListener('keydown', this.handleKeySave)
  }
}
