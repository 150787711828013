






































































import { mapState } from 'vuex'
import { EventBus } from '@/eventBus'
import operation from '@/utils/operations'
import UserService from '@/services/UserService'
import KBAvatar from '@/shared/infrastructure/ui/KeybeAvatar.vue'
import KBText from '@/shared/infrastructure/ui/inputs/text/KBText.vue'
import KBButton from '@/shared/infrastructure/ui/buttons/KBButton.vue'
import KBPhone from '@/shared/infrastructure/ui/inputs/phone/KBPhone.vue'
import { dataToUploadImage, userImageUpdater } from '@/modules/Config/GeneralUserConfiguration/domain/UserController'
import UserUseCases from '@/modules/Config/GeneralUserConfiguration/application/UserUseCases'
import EmitToast from '@/utils/EmitToast'
import UserController from '@/modules/Config/GeneralUserConfiguration/infrastructure/controller/User.controller'
import { CDPFieldsEnums } from '@/modules/CDP/shared/contacts/domain/enums/CDPFieldsEnums'
export default {
  components: {
    KBText,
    KBPhone,
    KBAvatar,
    KBButton
    // KBSelect
  },
  computed: {
    ...mapState('UserStore', ['user', 'token']),
    ...mapState('AppStore', ['selectedApp'])
  },
  mounted () {
    if (this.user) {
      this.userForm.name = this.user?.name || ''
      this.userForm.lastName = this.user?.lastName || ''
      this.userForm.photo = this.user?.photo || ''
      this.userForm.phone = this.user?.phone || ''
      this.userForm.email = this.user?.email || ''
      this.userForm.identificationNumber = +this.user?.identificationNumber || ''
      this.userForm.identificationType = this.user?.identificationType || ''

      if (this.user?.countryCode && this.user?.phone && this.user?.country) {
        let phoneNumber = this.user.phone
        if (this.user.phone.includes(this.user?.countryCode)) {
          phoneNumber = this.user.phone.slice(this.user.countryCode.toString().length)
        }
        this.userPhone = {
          code: this.user?.country,
          number: this.user?.countryCode,
          phone: phoneNumber
        }
      }
    }
  },
  data () {
    return {
      documentTypes: [
        { text: this.$t('passport'), value: 'passport' },
        { text: this.$t('citizenCard'), value: 'citizen_card' },
        { text: this.$t('identification'), value: 'identification_card' },
        { text: 'SSN', value: 'snn' },
        { text: 'NIT', value: 'nit' },
        { text: 'EIN', value: 'ein' }
      ],
      userForm: {
        name: '',
        lastName: '',
        photo: '',
        phone: '',
        email: '',
        identificationNumber: null,
        identificationType: ''
      },
      userPhone: {},
      loading: false,
      userController: new UserController(),
      CDPFieldsEnums
    }
  },
  methods: {
    async onFilePicked (event: any): Promise<void> {
      const img: any = event.target.files[0]
      URL.createObjectURL(img)
      const data: dataToUploadImage = {
        img,
        uuid: this.user?.uuid,
        appId: this.selectedApp?.uuid,
        token: this.token
      }
      const response: userImageUpdater = await UserUseCases.uploadUserImage(this.userController, data)
      if (response.statusCode === 200) EmitToast.emitSuccess(this.$t('profilePicUpdated'))
      else EmitToast.emitErrors(this.$t('errorUpdatingProfilePic'))
    },
    setPhone (phone) {
      let phoneNumber = phone?.phone
      if (phone.phone.includes(phone?.number)) {
        phoneNumber = phone.phone.slice(phone.number.toString().length)
      }
      this.userForm.phone = phoneNumber
      this.userForm.countryCode = phone?.number
      this.userForm.country = phone?.code
    },
    async updateUser () {
      try {
        if (!this.userForm.name) {
          EventBus.$emit('toast', 'error', this.$t('youMustEnterAName'))
          return
        } else if (!this.userForm.lastName) {
          EventBus.$emit('toast', 'error', this.$t('youMustEnterALastname'))
          return
        } else if (!this.userForm.email && !this.userForm.phone) {
          EventBus.$emit('toast', 'error', this.$t('youMustEnterAPhoneNumberOrEmail'))
          return
        } else if (this.userForm.email && !operation.validateEmail(this.userForm.email)) {
          EventBus.$emit('toast', 'error', this.$t('youMustEnterAValidEmail'))
          return
        }
        this.loading = true
        if (this.userForm.identificationNumber) this.userForm.identificationNumber = +this.userForm.identificationNumber
        const patchResponse = await UserService.updateProfile(this.userForm)
        if (patchResponse?.status) {
          this.$store.commit('UserStore/SET_KEYBE_USER', patchResponse?.data?.data?.user)
          this.$emit('back')
        } else {
          EventBus.$emit('toast', 'error', this.$t('errorUpdatingUser'))
        }
        this.loading = false
      } catch (e) {
        console.error(e)
      }
    },
    openFileInput () {
      this.$refs.imageInput.click()
    }
  }
}
