<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="140" height="132" viewBox="0 0 140 132" fill="none">
  <g clip-path="url(#clip0_38902_1565)">
    <path d="M51.3964 132C46.0209 132 40.6454 129.942 36.5565 125.832C28.3712 117.606 28.3712 104.222 36.5565 95.996L37.7543 94.7921C38.2275 94.3165 38.9965 94.3165 39.4697 94.7921C39.943 95.2677 39.943 96.0406 39.4697 96.5162L38.2719 97.72C31.0331 104.995 31.0331 116.833 38.2719 124.108C45.5107 131.383 57.2895 131.383 64.5284 124.108L89.062 99.4515C97.6687 90.8016 97.6687 76.7344 89.062 68.0845L73.2386 52.1817C62.6059 41.4957 45.3037 41.4957 34.6784 52.1817L2.07035 84.9532C1.59713 85.4288 0.82814 85.4288 0.354917 84.9532C-0.118306 84.4776 -0.118306 83.7048 0.354917 83.2292L32.9629 50.4577C44.5421 38.8205 63.3749 38.8205 74.9541 50.4577L90.7774 66.3604C100.331 75.9615 100.331 91.5744 90.7774 101.175L66.2438 125.832C62.1549 129.942 56.7793 132 51.4038 132H51.3964Z" fill="#F7685B"/>
    <path d="M43.796 99.1245C39.6036 99.1245 36.1875 95.6987 36.1875 91.4778C36.1875 87.2569 39.5962 83.8311 43.796 83.8311C47.9959 83.8311 51.4046 87.2569 51.4046 91.4778C51.4046 95.6987 47.9959 99.1245 43.796 99.1245ZM43.796 86.276C40.9419 86.276 38.6128 88.6094 38.6128 91.4852C38.6128 94.3611 40.9345 96.6945 43.796 96.6945C46.6576 96.6945 48.9793 94.3611 48.9793 91.4852C48.9793 88.6094 46.6576 86.276 43.796 86.276Z" fill="#F7685B"/>
    <path d="M126.921 7.46832C126.921 7.46832 65.5422 8.18915 45.8887 78.0645L57.1573 89.8726C57.1573 89.8726 124.769 73.9699 126.921 7.46832Z" fill="#3AF69B"/>
    <path d="M43.1306 93.3802C42.8201 93.3802 42.5095 93.2613 42.2729 93.0235C41.7997 92.5479 41.7997 91.775 42.2729 91.2994L63.25 70.2172C63.7232 69.7416 64.4922 69.7416 64.9654 70.2172C65.4386 70.6928 65.4386 71.4656 64.9654 71.9412L43.9883 93.0235C43.7517 93.2613 43.4412 93.3802 43.1306 93.3802Z" fill="#3AC2D9"/>
    <path d="M31.9791 81.6984C31.9791 81.6984 31.9052 81.6984 31.8682 81.6984C31.2028 81.6389 30.7074 81.0444 30.7665 80.3756C30.7887 80.1452 33.0069 57.5768 52.5939 57.5768C53.2668 57.5768 53.8065 58.1192 53.8065 58.7955C53.8065 59.4717 53.2668 60.0142 52.5939 60.0142C45.3477 60.0142 39.8908 63.4177 36.3786 70.1429C33.6872 75.2927 33.1844 80.5391 33.1844 80.5911C33.1252 81.2228 32.6002 81.6984 31.9791 81.6984Z" fill="#3AC2D9"/>
    <path d="M45.4073 79.2832H33.1775C32.5046 79.2832 31.9648 78.7407 31.9648 78.0645C31.9648 77.3883 32.5046 76.8458 33.1775 76.8458H45.4073C46.0802 76.8458 46.62 77.3883 46.62 78.0645C46.62 78.7407 46.0802 79.2832 45.4073 79.2832Z" fill="#3AC2D9"/>
    <path d="M54.9972 104.824C54.3761 104.824 53.8511 104.349 53.792 103.717C53.7328 103.048 54.2208 102.454 54.8937 102.394C54.9454 102.394 60.1731 101.889 65.2898 99.1839C71.9741 95.6541 75.368 90.1699 75.368 82.8874C75.368 82.2111 75.9077 81.6686 76.5806 81.6686C77.2535 81.6686 77.7932 82.2111 77.7932 82.8874C77.7932 102.565 55.3373 104.802 55.1081 104.824C55.0711 104.824 55.0342 104.824 54.9972 104.824Z" fill="#3AC2D9"/>
    <path d="M57.3923 103.62C56.7195 103.62 56.1797 103.078 56.1797 102.402V90.1105C56.1797 89.4342 56.7195 88.8918 57.3923 88.8918C58.0652 88.8918 58.605 89.4342 58.605 90.1105V102.402C58.605 103.078 58.0652 103.62 57.3923 103.62Z" fill="#3AC2D9"/>
    <path d="M88.744 64.3094C98.41 64.3094 106.246 56.4342 106.246 46.7198C106.246 37.0053 98.41 29.1302 88.744 29.1302C79.078 29.1302 71.2422 37.0053 71.2422 46.7198C71.2422 56.4342 79.078 64.3094 88.744 64.3094Z" fill="#171717"/>
    <path d="M88.7438 65.5281C78.4216 65.5281 70.0293 57.0937 70.0293 46.7198C70.0293 36.3459 78.4216 27.9115 88.7438 27.9115C99.0659 27.9115 107.458 36.3459 107.458 46.7198C107.458 57.0937 99.0659 65.5281 88.7438 65.5281ZM88.7438 30.3489C79.7599 30.3489 72.4546 37.6909 72.4546 46.7198C72.4546 55.7487 79.7599 63.0907 88.7438 63.0907C97.7276 63.0907 105.033 55.7487 105.033 46.7198C105.033 37.6909 97.7276 30.3489 88.7438 30.3489Z" fill="#3AC2D9"/>
    <path d="M127.156 30.9806C126.69 30.9806 126.224 30.8022 125.869 30.4455L106.689 11.1691C105.979 10.4557 105.979 9.29641 106.689 8.58302C107.398 7.86963 108.552 7.86963 109.262 8.58302L128.442 27.8595C129.152 28.5729 129.152 29.7321 128.442 30.4455C128.087 30.8022 127.621 30.9806 127.156 30.9806Z" fill="white"/>
    <path d="M82.8056 81.3417C82.3398 81.3417 81.874 81.1633 81.5191 80.8066L53.9464 53.0958C53.2366 52.3824 53.2366 51.2231 53.9464 50.5097C54.6563 49.7963 55.8098 49.7963 56.5196 50.5097L84.0922 78.2206C84.802 78.934 84.802 80.0932 84.0922 80.8066C83.7373 81.1633 83.2715 81.3417 82.8056 81.3417Z" fill="white"/>
    <path d="M119.969 33.5072C119.658 33.5072 119.348 33.3883 119.111 33.1505L101.372 15.3231C100.899 14.8475 100.899 14.0747 101.372 13.5991C101.846 13.1235 102.615 13.1235 103.088 13.5991L120.826 31.4265C121.3 31.9021 121.3 32.6749 120.826 33.1505C120.59 33.3883 120.279 33.5072 119.969 33.5072Z" fill="#3AC2D9"/>
    <path d="M135.549 41.6964C134.876 41.6964 134.336 41.1539 134.336 40.4776V33.9754C134.336 33.2991 134.876 32.7567 135.549 32.7567C136.221 32.7567 136.761 33.2991 136.761 33.9754V40.4776C136.761 41.1539 136.221 41.6964 135.549 41.6964Z" fill="#F7685B"/>
    <path d="M138.788 38.4415H132.318C131.645 38.4415 131.105 37.899 131.105 37.2227C131.105 36.5465 131.645 36.004 132.318 36.004H138.788C139.461 36.004 140.001 36.5465 140.001 37.2227C140.001 37.899 139.461 38.4415 138.788 38.4415Z" fill="#F7685B"/>
    <path d="M57.6298 30.7279C57.297 30.7279 57.0234 30.4529 57.0234 30.1185V23.6163C57.0234 23.2819 57.297 23.0069 57.6298 23.0069C57.9625 23.0069 58.2361 23.2819 58.2361 23.6163V30.1185C58.2361 30.4529 57.9625 30.7279 57.6298 30.7279Z" fill="#171717"/>
    <path d="M60.8691 27.473H54.3993C54.0666 27.473 53.793 27.1981 53.793 26.8637C53.793 26.5293 54.0666 26.2543 54.3993 26.2543H60.8691C61.2019 26.2543 61.4754 26.5293 61.4754 26.8637C61.4754 27.1981 61.2019 27.473 60.8691 27.473Z" fill="#171717"/>
    <path d="M97.913 124.94C97.5802 124.94 97.3066 124.665 97.3066 124.331V117.829C97.3066 117.494 97.5802 117.219 97.913 117.219C98.2457 117.219 98.5193 117.494 98.5193 117.829V124.331C98.5193 124.665 98.2457 124.94 97.913 124.94Z" fill="#171717"/>
    <path d="M101.145 121.686H94.6747C94.3419 121.686 94.0684 121.411 94.0684 121.076C94.0684 120.742 94.3419 120.467 94.6747 120.467H101.145C101.477 120.467 101.751 120.742 101.751 121.076C101.751 121.411 101.477 121.686 101.145 121.686Z" fill="#171717"/>
    <path d="M87.8426 6.39082C87.6874 6.39082 87.5321 6.33137 87.4138 6.21247C87.1772 5.97467 87.1772 5.58825 87.4138 5.35045L89.2106 3.53725V1.12211C89.2106 0.78771 89.4841 0.512756 89.8169 0.512756C90.1496 0.512756 90.4232 0.78771 90.4232 1.12211V3.78991C90.4232 3.95339 90.3566 4.10945 90.2457 4.22091L88.2715 6.21247C88.1532 6.33137 87.9979 6.39082 87.8426 6.39082Z" fill="#3AF69B"/>
    <path d="M91.6795 6.55427C91.5094 6.55427 91.3393 6.47996 91.221 6.3462L89.3799 4.22088C89.1581 3.96822 89.1877 3.5818 89.4391 3.35887C89.6905 3.13593 90.075 3.16566 90.2968 3.41832L92.1379 5.54363C92.3597 5.79629 92.3302 6.18271 92.0788 6.40565C91.9678 6.50226 91.82 6.55427 91.6795 6.55427Z" fill="#3AF69B"/>
    <path d="M89.8388 4.46612C89.6466 4.46612 89.4543 4.37694 89.336 4.19859C89.1438 3.92364 89.2177 3.54465 89.4913 3.35144L91.5321 1.94695C91.8056 1.76117 92.1827 1.82805 92.375 2.103C92.5672 2.37795 92.4933 2.75694 92.2197 2.95016L90.1789 4.35465C90.0754 4.42896 89.9571 4.45868 89.8388 4.45868V4.46612Z" fill="#3AF69B"/>
    <path d="M89.839 4.46615C89.7207 4.46615 89.6024 4.429 89.4989 4.36212L87.4581 2.95762C87.1845 2.76441 87.1106 2.38542 87.3028 2.11047C87.4951 1.83552 87.8722 1.7612 88.1457 1.95441L90.1865 3.35891C90.4601 3.55212 90.534 3.93111 90.3418 4.20606C90.2235 4.37698 90.0312 4.47358 89.839 4.47358V4.46615Z" fill="#3AF69B"/>
    <path d="M32.9403 37.7132C32.785 37.7132 32.6297 37.6538 32.5114 37.5349C32.2748 37.2971 32.2748 36.9106 32.5114 36.6728L34.3082 34.8596V32.4445C34.3082 32.1101 34.5818 31.8351 34.9145 31.8351C35.2473 31.8351 35.5208 32.1101 35.5208 32.4445V35.1123C35.5208 35.2758 35.4543 35.4318 35.3434 35.5433L33.3692 37.5349C33.2509 37.6538 33.0956 37.7132 32.9403 37.7132Z" fill="#3AF69B"/>
    <path d="M36.7791 37.8767C36.609 37.8767 36.439 37.8024 36.3206 37.6686L34.4795 35.5433C34.2577 35.2907 34.2873 34.9043 34.5387 34.6813C34.7901 34.4584 35.1746 34.4881 35.3964 34.7408L37.2375 36.8661C37.4593 37.1187 37.4298 37.5052 37.1784 37.7281C37.0675 37.8247 36.9196 37.8767 36.7791 37.8767Z" fill="#3AF69B"/>
    <path d="M34.9365 35.7885C34.7442 35.7885 34.552 35.6993 34.4337 35.521C34.2414 35.246 34.3154 34.8671 34.5889 34.6738L36.6297 33.2694C36.9033 33.0761 37.2804 33.1505 37.4727 33.4254C37.6649 33.7004 37.591 34.0794 37.3174 34.2726L35.2766 35.6771C35.1731 35.7514 35.0548 35.7811 34.9365 35.7811V35.7885Z" fill="#3AF69B"/>
    <path d="M34.9366 35.7885C34.8183 35.7885 34.7 35.7514 34.5965 35.6845L32.5557 34.28C32.2822 34.0868 32.2082 33.7078 32.4005 33.4329C32.5927 33.1579 32.9698 33.0836 33.2434 33.2768L35.2842 34.6813C35.5578 34.8745 35.6317 35.2535 35.4394 35.5285C35.3211 35.6994 35.1289 35.796 34.9366 35.796V35.7885Z" fill="#3AF69B"/>
    <path d="M111.096 72.5283C111.758 72.5283 112.294 71.9893 112.294 71.3244C112.294 70.6596 111.758 70.1206 111.096 70.1206C110.435 70.1206 109.898 70.6596 109.898 71.3244C109.898 71.9893 110.435 72.5283 111.096 72.5283Z" fill="#171717"/>
    <path d="M113.256 2.4077C113.918 2.4077 114.454 1.86872 114.454 1.20385C114.454 0.538982 113.918 0 113.256 0C112.595 0 112.059 0.538982 112.059 1.20385C112.059 1.86872 112.595 2.4077 113.256 2.4077Z" fill="#171717"/>
    <path d="M18.315 85.7781C18.9766 85.7781 19.5129 85.2391 19.5129 84.5742C19.5129 83.9093 18.9766 83.3704 18.315 83.3704C17.6535 83.3704 17.1172 83.9093 17.1172 84.5742C17.1172 85.2391 17.6535 85.7781 18.315 85.7781Z" fill="#171717"/>
    <path d="M127.636 87.569C127.304 87.569 127.03 87.294 127.03 86.9596C127.03 86.2314 126.439 85.6443 125.721 85.6443H123.163C122.83 85.6443 122.557 85.3694 122.557 85.035C122.557 84.7006 122.83 84.4256 123.163 84.4256H125.721C127.111 84.4256 128.243 85.5626 128.243 86.9596C128.243 87.294 127.969 87.569 127.636 87.569Z" fill="#171717"/>
    <path d="M105.581 87.569C105.248 87.569 104.975 87.294 104.975 86.9596C104.975 85.5626 106.106 84.4256 107.496 84.4256H109.411C109.744 84.4256 110.017 84.7006 110.017 85.035C110.017 85.3694 109.744 85.6443 109.411 85.6443H107.496C106.771 85.6443 106.187 86.2388 106.187 86.9596C106.187 87.294 105.914 87.569 105.581 87.569Z" fill="#171717"/>
    <path d="M116.568 94.2719C120.856 94.2719 124.332 90.7785 124.332 86.4692C124.332 82.1598 120.856 78.6664 116.568 78.6664C112.281 78.6664 108.805 82.1598 108.805 86.4692C108.805 90.7785 112.281 94.2719 116.568 94.2719Z" fill="#3AF69B"/>
    <path d="M125.723 89.4937H107.504C106.114 89.4937 104.982 88.3567 104.982 86.9596C104.982 86.6252 105.256 86.3503 105.589 86.3503C105.921 86.3503 106.195 86.6252 106.195 86.9596C106.195 87.6879 106.787 88.275 107.504 88.275H125.723C126.448 88.275 127.032 87.6805 127.032 86.9596C127.032 86.6252 127.305 86.3503 127.638 86.3503C127.971 86.3503 128.244 86.6252 128.244 86.9596C128.244 88.3567 127.113 89.4937 125.723 89.4937Z" fill="#171717"/>
  </g>
  <defs>
    <clipPath id="clip0_38902_1565">
      <rect width="140" height="132" fill="white"/>
    </clipPath>
  </defs>
</svg>
</template>

<script>
export default {
  name: 'ChannelRocketIcon'
}
</script>

<style>

</style>
