<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.8913 11.5435C15.8913 12.5285 15.4762 13.4221 14.8044 14.067C14.1342 14.7135 13.2057 15.1129 12.1822 15.1129C10.1334 15.1129 8.473 13.5151 8.473 11.5435C8.473 10.5586 8.05791 9.66501 7.38609 9.02012C6.71594 8.37362 5.7874 7.97418 4.76384 7.97418C2.71506 7.97418 1.05469 6.37638 1.05469 4.40481C1.05469 3.41983 1.46978 2.52628 2.14159 1.88139C2.81174 1.2349 3.74028 0.835449 4.76384 0.835449C5.7874 0.835449 6.71594 1.2349 7.38609 1.88139C8.05791 2.52628 8.473 3.41983 8.473 4.40481C8.473 6.37638 10.1334 7.97418 12.1822 7.97418C13.2057 7.97418 14.1342 8.37362 14.8044 9.02012C15.4762 9.66501 15.8913 10.5586 15.8913 11.5435Z"
      fill="black"/>
    <path
      d="M10.6707 6.09238C9.9472 5.39615 9.58379 4.48176 9.58545 3.56896C9.58545 2.65617 9.9472 1.74177 10.6707 1.04554C12.1193 -0.348514 14.4682 -0.348514 15.9169 1.04554C16.6404 1.74177 17.0038 2.65617 17.0021 3.56896C17.0021 4.48176 16.6404 5.39615 15.9169 6.09238C15.1934 6.78861 14.2432 7.13833 13.2946 7.13672C12.3461 7.13672 11.3959 6.78861 10.6724 6.09238H10.6707Z"
      fill="black"/>
    <path
      d="M1.08524 14.9557C0.361747 14.2594 0 13.345 0 12.4322C0 11.5194 0.361747 10.605 1.08524 9.90882C2.53389 8.51477 4.88275 8.51477 6.3314 9.90882C7.0549 10.605 7.41831 11.5194 7.41664 12.4322C7.41664 13.345 7.0549 14.2594 6.3314 14.9557C5.60791 15.6519 4.6577 16.0016 3.70915 16C2.76061 16 1.8104 15.6519 1.08691 14.9557H1.08524Z"
      fill="black"/>
  </svg>
</template>
<script>
export default {
  name: 'BlobIcon',
  props: {
    width: {
      type: Number,
      default: 17
    },
    height: {
      type: Number,
      default: 16
    },
    color: {
      type: String,
      default: '#B6BEC7'
    }
  }
}
</script>
