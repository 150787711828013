












export default {
  name: 'TesseractGreenIcon'
}
