import CollapsedForm from '../CreateFormWhatsApp/Screens/CollapsedForm/CollapsedForm.vue'
import ExpandedForm from '../CreateFormWhatsApp/Screens/ExpandedForm/ExpandedForm.vue'
import { createFormDTO } from '@/modules/Config/WidgetTesseract/domain/WidgetTesseractController'
import WidgetTesseractUseCases from '@/modules/Config/WidgetTesseract/application/WidgetTesseractUseCases'
import WidgetTesseractController from '@/modules/Config/WidgetTesseract/infrastructure/WidgetTesseract.controller'
import KBColorPicker from '@/shared/infrastructure/ui/inputs/KBColorPicker.vue'
import KeybeText from '@/modules/DesignSystem/infrastructure/components/KeybeText/KeybeText.vue'
import KeybeButton from '@/modules/DesignSystem/infrastructure/components/KeybeButton/KeybeButton.vue'
import KeybeSelect from '@/modules/DesignSystem/infrastructure/components/KeybeSelect/KeybeSelect/KeybeSelect.vue'
import KBSwitch from '@/shared/infrastructure/ui/switch/KBSwitch.vue'
import APIKeysController from '@/modules/APIKeysConfig/infrastructure/controllers/APIKeys.controller'
import Template from '../Template/Template.vue'
import FieldsForm from '../FieldsForm/FieldsForm.vue'
import UserService from '@/services/UserService'
import SalesCard from '../SalesCard/SalesCard.vue'
import {
  modeOptions,
  positionOptions,
  textAlingOptions,
  sizeTextOptions,
  horizontalPositionOptions,
  lenguageOptions
} from '@/modules/Config/WidgetTesseract/domain/WidgetTesseract'
import { mapState } from 'vuex'
import Colors from '@/utils/Colors'

export default {
  name: 'CreateFormWhatsApp',
  components: {
    CollapsedForm,
    ExpandedForm,
    KBColorPicker,
    KBSwitch,
    KeybeText,
    KeybeButton,
    KeybeSelect,
    FieldsForm,
    SalesCard,
    Template
  },
  props: {
    dataTocreate: {
      type: Object,
      required: true
    },
    selectedType: {
      type: String,
      required: true
    },
    selectedApiKey: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      Colors,
      apiKeysController: new APIKeysController(),
      widgetTesseractController: new WidgetTesseractController(),
      modeOptions: [
        { name: this.$t('dark'), value: modeOptions.Dark },
        { name: this.$t('light'), value: modeOptions.Light }
      ],
      positionOptions: [
        { name: this.$t('left'), value: positionOptions.Left },
        { name: this.$t('center'), value: positionOptions.Center },
        { name: this.$t('right'), value: positionOptions.Right },
        { name: this.$t('top'), value: positionOptions.Top },
        { name: this.$t('bottom'), value: positionOptions.Bottom }
      ],
      textAlingOptions: [
        { name: this.$t('left'), value: textAlingOptions.Left },
        { name: this.$t('center'), value: textAlingOptions.Center },
        { name: this.$t('right'), value: textAlingOptions.Right },
        { name: this.$t('top'), value: textAlingOptions.Top },
        { name: this.$t('bottom'), value: textAlingOptions.Bottom }
      ],
      sizeTextOptions: [
        { name: this.$t('small'), value: sizeTextOptions.Small },
        { name: this.$t('medium'), value: sizeTextOptions.Medium },
        { name: this.$t('large'), value: sizeTextOptions.Large }
      ],
      horizontalPositionOptions: [
        { name: this.$t('left'), value: horizontalPositionOptions.Left },
        { name: this.$t('right'), value: horizontalPositionOptions.Right },
        { name: this.$t('center'), value: horizontalPositionOptions.Center }
      ],
      lenguageOptions: [{ name: this.$t('english'), value: lenguageOptions.English }, { name: this.$t('spanish'), value: lenguageOptions.Spanish }],
      urlForm: ''
    }
  },
  computed: {
    ...mapState('AppStore', ['selectedApp']),
    ...mapState('UserStore', ['token']),
    generateUrl () {
      const nameApp = this.selectedApp?.name.replace(/\s/g, '')
      return `Keybe.ai/${nameApp}`
    }
  },
  methods: {
    async createForm () {
      try {
        this.formatData()
        const data:createFormDTO = this.dataTocreate
        const response = await WidgetTesseractUseCases.createForm(this.widgetTesseractController, data)
        if (!response) {
          console.log('error')
        }
      } catch (error) {
        console.error(error)
      }
    },
    openFileInput () {
      this.$refs.imageInput.click()
    },
    async onFilePicked (event) {
      try {
        const img = event.target.files[0]
        const response = await UserService.uploadImage(img)
        if (response.status) {
          this.dataTocreate.widgetConfig.image = response?.data?.data?.info?.location || ''
        }
      } catch (error) {
        console.error(error)
      }
    },

    formatData () {
      this.dataTocreate.salesCardData.amount = parseInt(
        this.dataTocreate.salesCardData.amount
      )
      this.dataTocreate.appUUID = this.selectedApp?.uuid
      this.dataTocreate.companyUUID = this.selectedApp?.company.uuid
    }
  },
  beforeDestroy () {
    WidgetTesseractUseCases.abortAll(this.widgetTesseractController)
  }
}
