import KeybeRepository from '@/shared/infrastructure/repositories/KeybeRepository'
import { GenericResponse } from '@/shared/infrastructure/http/GenericResponse'
import { HeadersBuilder } from '@/shared/infrastructure/http/HeadersBuilder'
import { getOptions, nonGetOptions } from '@/shared/infrastructure/http/HttpClient'
import { dataToCreateTask, dataToDeleteTask, dataToGetTasks, dataToUpdateTask } from '../../domain/TaskController'

export default class TaskRepository extends KeybeRepository {
  getSuggestionsAbortController: AbortController

  constructor () {
    super()

    this.getSuggestionsAbortController = new AbortController()
  }

  async getTasks (data: dataToGetTasks): Promise<GenericResponse> {
    const { app, offset, limit, search, status, responsible, contact } = data
    const url = `${process.env.VUE_APP_CRM_TASK_HTTP}/${app}/tasks`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    const query = {
      offset,
      limit,
      search,
      status,
      responsible,
      contact
    }

    if (!search) delete query.search
    if (!status) delete query.status
    if (!responsible) delete query.responsible
    if (!contact) delete query.contact
    const requestOptions: getOptions = {
      headers,
      query,
      url,
      abortController: this.getSuggestionsAbortController
    }

    return await this.client.get(requestOptions)
  }

  async createTask (data: dataToCreateTask): Promise<GenericResponse> {
    const { app, task } = data

    const url = `${process.env.VUE_APP_CRM_TASK_HTTP}/${app}/tasks`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    const requestOptions: nonGetOptions = {
      headers,
      data: task,
      url,
      abortController: this.getSuggestionsAbortController
    }

    return await this.client.post(requestOptions)
  }

  async updateTask (data: dataToUpdateTask): Promise<GenericResponse> {
    const { app, task } = data

    const url = `${process.env.VUE_APP_CRM_TASK_HTTP}/${app}/tasks/${task.id}`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    const response = await fetch(url, {
      method: 'PATCH',
      body: JSON.stringify(task),
      headers: headers || undefined,
      signal: this.getSuggestionsAbortController?.signal
    })
    const parsedResponse = await response.json()
    return parsedResponse
  }

  async deleteTask (data: dataToDeleteTask): Promise<GenericResponse> {
    const { app, taskId } = data

    const url = `${process.env.VUE_APP_CRM_TASK_HTTP}/${app}/tasks/${taskId}`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    const response = await fetch(url, {
      method: 'DELETE',
      headers: headers || undefined,
      signal: this.getSuggestionsAbortController?.signal
    })
    const parsedResponse = await response.json()
    return parsedResponse
  }
}
