import VueHtml2pdf from 'vue-html2pdf'
import moment from 'moment'
import { EventBus } from '@/eventBus'
import Colors from '@/shared/domain/colors/Color'

import KBSimpleTable from '@/shared/infrastructure/ui/tables/KBSimpleTable.vue'
import KBText from '@/shared/infrastructure/ui/inputs/text/KBText.vue'
import BillingService from '@/components/configuration/Services/BillingService'
import Loader from '@/shared/infrastructure/ui/Loader.vue'
import KBButton from '@/shared/infrastructure/ui/buttons/KBButton.vue'
import { CDPFieldsEnums } from '@/modules/CDP/shared/contacts/domain/enums/CDPFieldsEnums'

export default {
  name: 'InvoicesDetails',
  components: {
    KBSimpleTable,
    KBText,
    Loader,
    KBButton,
    VueHtml2pdf
  },
  props: {
    invoices: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      showDialog: false,
      currentItem: null,
      showingItem: null,
      statusColor: {
        SENT: Colors.yellow2,
        PAID: Colors.kbGreen,
        PENDING: Colors.rojoBird
      },
      loading: false,
      email: null,
      invoice: null,
      keybe: [
        'KEYBE INC 30-1280531',
        '11930 N BAYSHORE DRIVE SUITE 1106',
        'NORTH MIAMI, FL 33181 US',
        '+1 2404066789',
        'fintech@keybe.ai',
        'https://www.keybe.ai'
      ],
      headers: [
        {
          text: this.$t(CDPFieldsEnums.CODE),
          field: 'id'
        },
        {
          text: this.$t('type'),
          field: 'type'
        },
        {
          text: this.$t('description'),
          field: 'description'
        },
        {
          text: this.$t('quantity'),
          field: 'quantity'
        },
        {
          text: this.$t('subtotal'),
          field: 'subTotal'
        },
        {
          text: this.$t('total'),
          field: 'total'
        }
      ]
    }
  },
  computed: {
    storageRoute (): string {
      return process.env.VUE_APP_STORAGE_URL
    }
  },
  methods: {
    generateReport () {
      this.$refs.html2Pdf.generatePdf()
    },
    toggleDialog (item) {
      if (item.quickbooks) {
        this.currentItem = item
        this.showDialog = true
      } else {
        this.generatePdf(item)
      }
    },
    toggleDetails (item) {
      if (this.showingItem === item) {
        this.showingItem = null
      } else {
        this.showingItem = item
      }
    },
    async generatePdf (invoice) {
      this.invoice = invoice
    },
    async sendInvoiceToEmail (id) {
      const email = this.email
      if (!this.email) return
      this.loading = true
      try {
        const model = { id, email: email.trim() }
        const response = await BillingService.sendInvoiceEmail(model)
        if (response.status) {
          EventBus.$emit('toast', 'success', this.$t('messageEmail'))
        } else {
          EventBus.$emit('toast', 'error', this.$t('messageErrorEmail'))
        }
      } catch {
        EventBus.$emit('toast', 'error', this.$t('messageErrorEmail'))
      } finally {
        this.loading = false
        this.showDialog = false
      }
    },
    formatCurrency (value, currency) {
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency
      })
      return formatter.format(value)
    },
    getDate (value) {
      const date = moment(value || Date.now()).format('YYYY-MM-DD')
      return `${this.$t('generationDate')}: ${date}`
    },
    calculateContainerHeight () {
      const headerHeight = document.querySelector('.InvoicesDetails__header').clientHeight
      const floatingScreenHeight = parseInt(getComputedStyle(document.documentElement).getPropertyValue('--floating-screen-height'))
      document.documentElement.style.setProperty('--consumptions-container-height', `${floatingScreenHeight - headerHeight}px`)
    }
  },
  mounted () {
    this.calculateContainerHeight()

    EventBus.$on('FloatingWindow-resize', this.calculateContainerHeight)
  },
  beforeDestroy () {
    EventBus.$off('FloatingWindow-resize')
  }
}
