// import Vue from 'vue'
import { debounce } from 'lodash'
import LottieAnimation from 'lottie-web-vue'

import KBIconButton from '@/shared/infrastructure/ui/buttons/KBIconButton.vue'

export default {
  props: [
    'items', 'icon', 'value', 'placeholder',
    'itemText', 'itemValue', 'returnObject',
    'outlined', 'seamless', 'autoSearch',
    'disabled', 'loading', 'rerender', 'small',
    'forceOpen'],
  components: {
    LottieAnimation,
    KBIconButton
  },
  computed: {
    itemsComputed () {
      if (this.ignoreSearch) {
        return this.items
      } else {
        if (this.multipleText) {
          if (this.itemText[0] && this.itemText[1]) {
            return this.items.filter(item => (item[this.itemText[0]] + ' ' + item[this.itemText[1]]).toLowerCase().includes(this.search.toLowerCase()))
          } else {
            return this.items.filter(item => (item[this.itemText[0]]).toLowerCase().includes(this.search.toLowerCase()))
          }
        } else {
          if (this.itemText) return this.items.filter(item => item[this.itemText].toLowerCase().includes(this.search.toLowerCase()))
          else return this.items.filter(item => item.toString().toLowerCase().includes(this.search.toString().toLowerCase()))
        }
      }
    },
    multipleText () {
      return Array.isArray(this.itemText)
    }
  },
  mounted () {
    if (this.value) this.setSearch()
    if (this.autoSearch) this.search = this.autoSearch
    if (this.forceOpen) this.showSelector = true
  },
  data () {
    return {
      selectedItem: null,
      showSelector: false,
      search: '',
      focused: false,
      rerenderLocal: 0,
      ignoreSearch: true
    }
  },
  methods: {
    close () {
      this.showSelector = false
    },
    keyType: debounce(function () {
      this.ignoreSearch = false
      this.sendSearch()
    }, 500),
    selectItem (item) {
      this.showSelector = false
      if (this.returnObject) this.selectedItem = item
      else if (this.itemValue) this.selectedItem = item[this.itemValue]
      else this.selectedItem = item
      this.showSelector = false
      if (this.multipleText) this.search = item[this.itemText[0]] + ' ' + item[this.itemText[1]]
      else if (this.itemText) this.search = item[this.itemText]
      else this.search = item
      this.$emit('input', this.selectedItem)
      this.$emit('save', this.selectedItem)
      this.showSelector = false
      this.ignoreSearch = true
    },
    isSelected (item) {
      if (this.itemValue) {
        const found = this.items.filter(i => i[this.itemValue] === this.selectedItem)[0]
        if (found) return true
      } else {
        return this.selectedItem === item
      }
    },
    setSearch () {
      if (this.returnObject) {
        if (this.multipleText) {
          if (this.itemText[0] && this.itemText[1]) {
            const search = this.items.filter(item => (item[this.itemText[0]] + ' ' + item[this.itemText[1]]) === (this.value[this.itemText[0]] + ' ' + this.value[this.itemText[1]]))

            if (search.length > 0) {
              this.search = search[0][this.itemText]
              if (!this.ignoreSearch) this.$emit('search', this.search)
            }
          } else if (this.itemText[0] && !this.itemText[1]) {
            const search = this.items.filter(item => (item[this.itemText[0]]) === (this.value[this.itemText[0]]))

            if (search.length > 0) {
              this.search = search[0][this.itemText]
              if (!this.ignoreSearch) this.$emit('search', this.search.toString())
            }
          }
        } else {
          if (this.itemValue) {
            const search = this.items.filter(item => item[this.itemValue] === this.value)
            if (search.length > 0) {
              this.search = search[0][this.itemText]
              if (!this.ignoreSearch) this.$emit('search', this.search.toString())
            }
          } else {
            const search = this.items.filter(item => item[this.itemText] === this.value[this.itemText])
            if (search.length > 0) {
              this.search = search[0][this.itemText]
              if (!this.ignoreSearch) this.$emit('search', this.search.toString())
            }
          }
        }
      } else {
        if (this.itemText && this.itemText[0] && this.itemText[1]) {
          const search = this.items.filter(item => item[this.itemValue].toString() === this.value.toString())
          if (search.length > 0) {
            if (this.multipleText) {
              this.search = search[0][this.itemText[0]] + ' ' + search[0][this.itemText[1]]
            } else {
              this.search = search[0][this.itemText]
            }
            this.$emit('search', this.search.toString())
          }
        } else if (this.itemText && this.itemText[0] && !this.itemText[1]) {
          const search = this.items.filter(item => item[this.itemValue].toString() === this.value.toString())
          if (search.length > 0) {
            if (this.multipleText) {
              this.search = search[0][this.itemText[0]]
            } else {
              this.search = search[0][this.itemText]
            }
            this.$emit('search', this.search.toString())
          }
        } else this.$emit('search', this.search.toString())
      }
      this.rerenderLocal = this.rerenderLocal++
    },
    openSelector () {
      if (!this.disabled) this.showSelector = !this.showSelector
      if (this.showSelector) this.ignoreSearch = true
    },
    sendSearch () {
      this.$emit('search', this.search.toString())
    },
    clearSearch () {
      this.search = ''
      this.sendSearch()
      this.$emit('clear')
    }
  },
  watch: {
    value () {
      this.ignoreSearch = true
      if (this.value) {
        this.setSearch()
      } else this.search = ''
    },
    autoSearch () {
      this.search = this.autoSearch
    },
    showSelector () {
      this.$emit('open', this.showSelector)
    },
    rerender: {
      immediate: true,
      deep: true,
      handler () {
        this.rerenderLocal++
        this.search = ''
      }
    }
  }
}
