import CampaignsService from '@/services/CampaignsService'

export const sendCampaign = async (campaignData, scheduleDate, schedule) => {
  campaignData.status = 'inReview'
  if (campaignData.status === 'inReview') {
    const scheduleDateF = scheduleDate || campaignData.createdAt
    const sendResponse = await CampaignsService.sendCampaign({
      campaignId: campaignData._id,
      scheduleDate: scheduleDateF,
      schedule: schedule
    })
    if (sendResponse.status) {
      return true
    } else {
      return false
    }
  }
}
