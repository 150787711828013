<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       fill="none"
       viewBox="0 0 26 26"
       :class="iconClass"><path :fill="color"
                                d="M12.014 12.006V1.258c0-.735.22-1.084.76-1.224.54-.14 1.054.167 1.163.718.042.216.04.443.04.665.002 3.342 0 6.682.002 10.024 0 .565.002.565.581.565 3.426 0 6.85-.002 10.276 0 .797 0 1.241.44 1.153 1.134-.052.412-.27.702-.695.78a4.451 4.451 0 0 1-.787.074c-3.3.005-6.6.004-9.9.004-.419 0-.629.216-.63.65v9.898c0 .194 0 .391-.022.583-.065.547-.409.86-.937.87-.518.01-.887-.305-.968-.838a3.982 3.982 0 0 1-.035-.582V14.473c0-.137-.015-.274-.026-.474h-.505c-3.285 0-6.572 0-9.858-.002-.277 0-.558-.015-.83-.06-.515-.084-.784-.41-.796-.918a.949.949 0 0 1 .717-.957c.186-.047.384-.053.578-.053 3.384-.004 6.767-.002 10.15-.002h.569Z"/></svg>
</template>

<script>
export default {
  name: 'PlusIcon',
  props: {
    color: {
      type: String,
      default: '#B6BEC7'
    },
    iconClass: {
      type: String,
      default: ''
    }
  }

}
</script>

<style>

</style>
