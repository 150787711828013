<template>
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 12 10"><path :fill="color" d="M6.24 9.999c-1.453 0-2.906.001-4.36-.002-.387 0-.759-.072-1.09-.292-.463-.309-.709-.748-.776-1.293C0 8.284.002 8.154.002 8.025c0-2.401 0-4.803-.002-7.205C0 .545.048.296.294.13c.24-.163.497-.165.748-.044a.623.623 0 0 1 .364.556c.005.08.007.158.007.238v7.235c.003.347.12.464.468.464h8.72c.288 0 .544.061.707.324.23.368.094.985-.457 1.076a1.73 1.73 0 0 1-.266.021H6.239h.002Z"/><path :fill="color" d="M5.353 4.297 3.416 6.169c-.229.222-.493.314-.802.21-.509-.168-.631-.806-.232-1.213.423-.432.854-.857 1.282-1.285L4.77 2.776c.366-.364.804-.364 1.165.002.363.366.724.735 1.084 1.104.037.04.066.087.096.127.206-.202.393-.38.574-.562.584-.588 1.165-1.178 1.75-1.765.273-.275.65-.325.95-.136.383.242.421.795.072 1.148-.534.54-1.072 1.074-1.609 1.611L7.715 5.442c-.358.356-.794.35-1.156 0-.41-.398-.827-.786-1.206-1.145Z"/></svg>
</template>

<script>
export default {
  name: 'MetricsIcon',
  props: {
    color: {
      type: String,
      default: '#B6BEC7'
    }
  }

}
</script>

<style>

</style>
