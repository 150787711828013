

























import { AuthFormFields } from '@/components/configuration/conversations/AuthFormFields'
import ActivityLogsUseCases from '@/modules/Config/ActivityLogs/application/ActivityLogsUseCases'
import { ActivityActionTypeEnum, ActivityLogModuleTypeEnum, dataToWriteActivityLog } from '@/modules/Config/ActivityLogs/domain/ActivityLogsController'
import ActivityLogsController from '@/modules/Config/ActivityLogs/infrastructure/controllers/ActivityLogs.controller'
import KBCheck from '@/shared/infrastructure/ui/inputs/select/KBCheck.vue'
import { mapState } from 'vuex'
export default {
  name: 'WebWidgetForm',
  components: {
    KBCheck
  },
  props: ['value'],
  computed: {
    ...mapState('UserStore', ['isUserReadonly', 'user', 'token']),
    ...mapState('AppStore', ['selectedApp'])
  },
  data () {
    return {
      loading: false,
      formFields: [
        {
          name: this.$t('identificationType'),
          value: AuthFormFields.IdentificationType
        },
        {
          name: this.$t('idDocument'),
          value: AuthFormFields.IdDocument
        },
        {
          name: this.$t('authName'),
          value: AuthFormFields.Name
        },
        {
          name: this.$t('authEmail'),
          value: AuthFormFields.Email
        },
        {
          name: this.$t('authPhone'),
          value: AuthFormFields.Phone
        },
        {
          name: this.$t('landlinePhone'),
          value: AuthFormFields.LandlinePhone
        }
      ],
      config: null,
      activityLogsController: new ActivityLogsController()
    }
  },
  methods: {
    isSelected (field) {
      return this.config?.formFields?.includes(field?.value) || false
    },
    toggleField (field, value) {
      this.loading = true
      if (field?.value === AuthFormFields.Phone) {
        return
      }
      if (this.isSelected(field)) {
        this.config.formFields = this.config?.formFields?.filter(item => item !== field?.value)
      } else {
        this.config.formFields.push(field?.value)
      }
      this.writeActivityLog(field, value)
      this.loading = false
    },
    async writeActivityLog (field: string, value: boolean): Promise<void> {
      const data: dataToWriteActivityLog = {
        token: this.token,
        updateInput: {
          appUUID: this.selectedApp?.uuid,
          module: ActivityLogModuleTypeEnum.CHAT,
          action: ActivityActionTypeEnum.WIDGET_WEB_FORM_UPDATE,
          user: {
            id: this.user.uuid,
            name: this.user.name + ' ' + this.user.lastName
          },
          payload: {
            field: field,
            newValue: value ? 'active' : 'inactive',
            oldValue: value ? 'inactive' : 'active'
          }
        }
      }
      await ActivityLogsUseCases.writeActivityLog(this.activityLogsController, data)
    }
  },
  watch: {
    value: {
      handler () {
        this.config = this.value
        if (!this.config?.formFields) this.config.formFields = []
        if (!this.isSelected(AuthFormFields.Phone)) {
          this.config.formFields.push(AuthFormFields.Phone)
          this.$emit('input', this.config)
        }
      },
      immediate: true
    },
    config () {
      this.$emit('input', this.config)
    }
  }
}
