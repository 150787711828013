



















































































































import BikyConfigKnowledgeBaseItem from './BikyConfigKnowledgeBaseItem'

export default BikyConfigKnowledgeBaseItem
