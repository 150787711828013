<template>
  <svg :width="width"
       :height="height"
       viewBox="0 0 17 18"
       fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <path d="M11.2801 6.66182L10.3929 5.26845C10.314 5.14401 10.1731 5.0672 10.0229 5.0672H4.49889C4.34871 5.0672 4.20783 5.14401 4.12887 5.26845L3.2402 6.66182C2.78193 7.38078 3.18756 8.38087 4.04372 8.49609C4.10565 8.50377 4.16912 8.50838 4.23105 8.50838C4.63513 8.50838 4.99432 8.33324 5.24048 8.06287C5.48665 8.33324 5.84583 8.50838 6.24992 8.50838C6.654 8.50838 7.01318 8.33324 7.25935 8.06287C7.50551 8.33324 7.8647 8.50838 8.26878 8.50838C8.67441 8.50838 9.03205 8.33324 9.27821 8.06287C9.52593 8.33324 9.88357 8.50838 10.2876 8.50838C10.3511 8.50838 10.4131 8.50377 10.475 8.49609C11.3342 8.3824 11.7414 7.38231 11.2816 6.66182H11.2801ZM10.5385 8.98307H10.5369C10.4549 8.99383 10.3713 8.99997 10.2861 8.99997C10.0941 8.99997 9.90988 8.97078 9.73803 8.91855V10.9664H4.78376V8.91702C4.61036 8.97078 4.42458 8.99997 4.2326 8.99997C4.14745 8.99997 4.0623 8.99383 3.98024 8.98307H3.97869C3.91522 8.97386 3.85329 8.9631 3.79291 8.94774V10.9664V11.9496C3.79291 12.4918 4.23724 12.9327 4.78376 12.9327H9.73803C10.2846 12.9327 10.7289 12.4918 10.7289 11.9496V10.9664V8.94774C10.667 8.9631 10.605 8.97539 10.5385 8.98307Z"
          :fill="color"/>
    <path d="M12.4667 1.6875C12.7783 1.6875 13.0333 1.94062 13.0333 2.25V15.75C13.0333 16.0594 12.7783 16.3125 12.4667 16.3125H2.26667C1.955 16.3125 1.7 16.0594 1.7 15.75V2.25C1.7 1.94062 1.955 1.6875 2.26667 1.6875H12.4667ZM2.26667 0C1.01646 0 0 1.00898 0 2.25V15.75C0 16.991 1.01646 18 2.26667 18H12.4667C13.7169 18 14.7333 16.991 14.7333 15.75V2.25C14.7333 1.00898 13.7169 0 12.4667 0H2.26667ZM17 2.8125C17 2.50312 16.745 2.25 16.4333 2.25C16.1217 2.25 15.8667 2.50312 15.8667 2.8125V5.0625C15.8667 5.37187 16.1217 5.625 16.4333 5.625C16.745 5.625 17 5.37187 17 5.0625V2.8125ZM16.4333 6.75C16.1217 6.75 15.8667 7.00313 15.8667 7.3125V9.5625C15.8667 9.87187 16.1217 10.125 16.4333 10.125C16.745 10.125 17 9.87187 17 9.5625V7.3125C17 7.00313 16.745 6.75 16.4333 6.75ZM17 11.8125C17 11.5031 16.745 11.25 16.4333 11.25C16.1217 11.25 15.8667 11.5031 15.8667 11.8125V14.0625C15.8667 14.3719 16.1217 14.625 16.4333 14.625C16.745 14.625 17 14.3719 17 14.0625V11.8125Z"
          :fill="color"/>
  </svg>

</template>
<script>
export default {
  name: 'OfficesPersons',
  props: {
    width: {
      type: Number,
      default: 7
    },
    height: {
      type: Number,
      default: 11
    },
    color: {
      type: String,
      default: '#B6BEC7'
    }
  }
}
</script>
