












export default {
  name: 'TesseractLightIcon'
}
