import { ChatConfig } from '../domain/ChatConfig'
import { DataToGetConfig, IChatConfigController } from '../domain/ChatConfigController'
import ChatConfigRepository from './ChatConfig.repository'
import ChatConfigStoreController from './persistence/ChatConfigStore.controller'

export default class ChatConfigController implements IChatConfigController {
  repository: ChatConfigRepository

  constructor () {
    this.repository = new ChatConfigRepository()
  }

  dispose (): void {
    this.repository.dispose()
  }

  async getConfig (data: DataToGetConfig): Promise<ChatConfig> {
    const response = await this.repository.getConfig(data)
    if (!response.status) {
      throw new Error('Error getting config')
    }
    return response.data
  }

  async getAndSetConfig (data: DataToGetConfig): Promise<ChatConfig> {
    const config = await this.getConfig(data)
    ChatConfigStoreController.setConfig(config)
    return config
  }

  async createFinishOptions (data: DataToGetConfig): Promise<ChatConfig> {
    const response = await this.repository.createFinishOptions(data)
    if (!response.status) {
      throw new Error('Error getting config')
    }
    return response.data
  }

  async deleteFinishOptions (data: DataToGetConfig): Promise<ChatConfig> {
    const response = await this.repository.deleteFinishOptions(data)
    if (!response.status) {
      throw new Error('Error getting config')
    }
    return response.data
  }
}
