<template>
  <svg :width="width" :height="height" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M12.315 0C12.5525 0 12.9248 0.153788 13.2266 0.442533C13.6891 0.885065 14.1602 1.32289 14.5764 1.80779C15.1965 2.53122 15.1236 3.44375 14.4377 4.13109C12.5917 5.98204 10.7449 7.83141 8.89814 9.68079C7.78505 10.795 6.66256 11.9005 5.56045 13.0249C5.06662 13.5286 4.50302 13.8754 3.81949 14.059C2.77382 14.3399 1.73833 14.6577 0.697364 14.9543C0.582136 14.9873 0.449664 15.0147 0.336787 14.9912C0.0788963 14.9378 -0.0543604 14.6954 0.0208904 14.431C0.369709 13.2132 0.724799 11.9978 1.07911 10.7816C1.21236 10.3242 1.47652 9.94835 1.80888 9.61488C4.83381 6.58699 7.85952 3.55909 10.8837 0.530411C11.2278 0.185173 11.637 0 12.315 0ZM9.28458 3.44454C9.28458 3.44454 9.24304 3.47827 9.20698 3.51437C6.962 5.75998 4.71388 8.00246 2.4783 10.2575C2.27763 10.4599 2.09891 10.7228 2.01033 10.9911C1.70698 11.9076 1.44831 12.8397 1.17474 13.7656C1.16847 13.786 1.18258 13.8119 1.18806 13.8441C2.09421 13.5773 2.99016 13.3207 3.8822 13.0476C4.24278 12.9378 4.5477 12.7259 4.81578 12.4568C7.01216 10.2457 9.21168 8.03777 11.4112 5.82903C11.4614 5.77803 11.5241 5.73958 11.6001 5.68074C10.8123 4.91964 10.0528 4.1868 9.2838 3.44375L9.28458 3.44454ZM12.235 4.93769C12.7947 4.39394 13.3591 3.88001 13.8804 3.32527C14.168 3.01848 14.1234 2.64656 13.8271 2.3382C13.4469 1.94275 13.0589 1.55436 12.6646 1.17459C12.3338 0.855249 11.9207 0.836418 11.5978 1.15655C11.0694 1.6799 10.5678 2.23071 10.1022 2.7211C10.7904 3.4359 11.5217 4.197 12.2358 4.93848L12.235 4.93769Z" :fill="color"/>
  </svg>
</template>

<script>
export default {
  name: 'AddIcon',
  props: {
    width: {
      type: Number,
      default: 16
    },
    height: {
      type: Number,
      default: 18
    },
    color: {
      type: String,
      default: '#B6BEC7'
    }
  }

}
</script>

<style>

</style>
