<template>
  <svg :width="width"
       :height="height"
       viewBox="0 0 27 13"
       fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <path d="M23.6962 5.58095C23.5321 5.40858 23.4387 5.30454 23.3389 5.20671C22.164 4.05295 20.9875 2.89919 19.8109 1.74698C19.2411 1.18796 19.1687 0.703475 19.5952 0.298184C20.0668 -0.150588 20.5159 -0.0962387 21.1178 0.484524C22.9205 2.22215 24.7231 3.96133 26.5241 5.70207C27.1518 6.30768 27.1583 6.68968 26.5451 7.28286C24.7167 9.05155 22.8867 10.8187 21.0534 12.5812C20.4434 13.1681 19.8463 13.134 19.4552 12.5051C19.2782 12.2209 19.2717 11.9398 19.4617 11.6665C19.5663 11.5175 19.6902 11.3793 19.8206 11.2504C21.0889 10.0034 22.3587 8.75961 23.7236 7.42262L23.1764 7.42262C15.866 7.42262 8.55729 7.42572 1.24694 7.41175C0.921822 7.41175 0.508183 7.33255 0.28768 7.13223C-0.262767 6.63377 0.0253321 5.72381 0.739949 5.62598C0.925042 5.60113 1.11174 5.58095 1.29845 5.58095C8.56856 5.57939 15.8371 5.57939 23.1072 5.5794L23.6962 5.5794L23.6962 5.58095Z"
          :fill="color"/>
  </svg>
</template>

<script>
export default {
  name: 'ArrowRightIcon',
  props: {
    width: {
      type: Number,
      default: 16
    },
    height: {
      type: Number,
      default: 18
    },
    color: {
      type: String,
      default: '#B6BEC7'
    }
  }

}
</script>

<style>

</style>
