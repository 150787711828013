
import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import Colors from '@/shared/domain/colors/Color'
import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'
import { EventBus } from '@/eventBus'
import PlanUseCases from '@/modules/consumptions/application/PlanUseCases'
import { dataToDeletePaymentMethod } from '@/modules/consumptions/domain/PlanController'
import PaymentController from '../../../controllers/Payment.controller'
import { mapState, mapGetters } from 'vuex'
import { PaymentService } from '@/components/configuration/Services/PaymentService'
import LoadingNext from '@/modules/metrics/infrastructure/ui/components/generic/LoadingNext.vue'

export default {
  name: 'CreditCardSelector',
  components: {
    KeybeIcon,
    LoadingNext
  },
  props: {
    cards: {
      type: Array,
      default: []
    },
    value: {
      type: [String, Array, Object]
    }
  },
  mounted () {
    if (this.value) this.selectedItem = this.value
    if (this.cards.length > 0) {
      this.cardsOption = this.cards
    } else {
      this.getCreditCards()
    }
  },
  watch: {
    value (val) {
      this.selectedItem = val
    }
  },
  data () {
    return {
      Colors,
      selectedItem: null,
      showSelector: false,
      KeybeIconType,
      PaymentController: new PaymentController(),
      loading: false,
      cardID: null,
      cardsOption: []
    }
  },
  methods: {
    async getCreditCards () {
      const response = await PaymentService.getCreditCards()
      if (response.status) {
        this.cardsOption = response.data.map((card) => ({
          id: card.id,
          tokenResponse: {
            brand: card.card.brand,
            last4: card.card.last4
          }
        }))
        this.selectedItem = this.cardsOption[0]
      }
      this.loading = false
    },
    async removePaymentMethod (item) {
      if (this.cardsOption.length === 1) {
        return
      }
      try {
        this.loading = true
        this.cardID = item.id
        const data:dataToDeletePaymentMethod = {
          token: this.token,
          cardID: this.cardID,
          appUUID: this.appUUID
        }
        const response = await PlanUseCases.removePaymentMethods(this.PaymentController, data)
        if (response.status) {
          this.loading = false
          EventBus.$emit('toast', 'success', this.$t('removePaymentMethod'))
          this.getCreditCards()
        } else {
          this.loading = false
          EventBus.$emit('toast', 'error', this.$t('NoRemovePaymentMethod'))
        }
      } catch (error) {
        this.loading = false
        EventBus.$emit('toast', 'error', error?.message)
      }
    },
    selectItem (item) {
      this.$emit('input', item)
      this.showSelector = false // esto oculta cuando se selecciona una TC
    },
    isSelected (item) {
      if (this.itemValue) {
        const found = this.items.filter(i => i[this.itemValue] === this.selectedItem)[0]
        if (found) return true
      } else {
        return this.selectedItem === item
      }
    },
    getIcon (card) {
      if (!card) return ''

      const icons = {
        visa: KeybeIconType.VISA,
        mastercard: KeybeIconType.MASTER_CARD,
        amex: KeybeIconType.AMEX,
        diners: KeybeIconType.DINERS_CLUB,
        discover: KeybeIconType.DINERS_CLUB,
        jcb: KeybeIconType.DINERS_CLUB,
        unionpay: KeybeIconType.DINERS_CLUB
      }
      return icons[card.tokenResponse.brand.toLowerCase()]
    },
    getLast4 (card) {
      return card.tokenResponse.last4 || ''
    }
  },
  computed: {
    ...mapGetters('AppStore', ['getSelectedApp']),
    ...mapState('UserStore', ['token']),
    last4 () {
      return this.selectedItem?.tokenResponse?.last4 || ''
    },
    appUUID () {
      return this.getSelectedApp.uuid ? this.getSelectedApp.uuid : null
    }
  }
}
