


















import ModalWarning from './ModalWarning'
export default ModalWarning
