






































































































































































































































import InboxConfigList from './InboxConfigList'

export default InboxConfigList
