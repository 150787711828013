<template>
  <svg width="8"
       height="6"
       viewBox="0 0 8 6"
       fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <path d="M2.88889 0.642857C2.88889 0.287946 3.1875 0 3.55556 0H4.44444C4.8125 0 5.11111 0.287946 5.11111 0.642857V1.5C5.11111 1.85491 4.8125 2.14286 4.44444 2.14286H4.33333V2.67857H6.44444C6.87361 2.67857 7.22222 3.01473 7.22222 3.42857V3.85714H7.33333C7.70139 3.85714 8 4.14509 8 4.5V5.35714C8 5.71205 7.70139 6 7.33333 6H6.44444C6.07639 6 5.77778 5.71205 5.77778 5.35714V4.5C5.77778 4.14509 6.07639 3.85714 6.44444 3.85714H6.55556V3.42857C6.55556 3.36964 6.50556 3.32143 6.44444 3.32143H4.33333V3.85714H4.44444C4.8125 3.85714 5.11111 4.14509 5.11111 4.5V5.35714C5.11111 5.71205 4.8125 6 4.44444 6H3.55556C3.1875 6 2.88889 5.71205 2.88889 5.35714V4.5C2.88889 4.14509 3.1875 3.85714 3.55556 3.85714H3.66667V3.32143H1.55556C1.49444 3.32143 1.44444 3.36964 1.44444 3.42857V3.85714H1.55556C1.92361 3.85714 2.22222 4.14509 2.22222 4.5V5.35714C2.22222 5.71205 1.92361 6 1.55556 6H0.666667C0.298611 6 0 5.71205 0 5.35714V4.5C0 4.14509 0.298611 3.85714 0.666667 3.85714H0.777778V3.42857C0.777778 3.01473 1.12639 2.67857 1.55556 2.67857H3.66667V2.14286H3.55556C3.1875 2.14286 2.88889 1.85491 2.88889 1.5V0.642857Z"
          :fill="color"/>
  </svg>

</template>
<script>
export default {
  name: 'OrganizationIcon',
  props: {
    color: {
      type: String,
      default: '#000'
    }
  }
}
</script>
