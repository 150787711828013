<template>
  <svg :width="width"
       :height="height"
       viewBox="0 0 11 12"
       fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <path d="M3.53571 3.375V4.5H7.46429V3.375C7.46429 2.33906 6.58527 1.5 5.5 1.5C4.41473 1.5 3.53571 2.33906 3.53571 3.375ZM1.96429 4.5V3.375C1.96429 1.51172 3.54799 0 5.5 0C7.45201 0 9.03571 1.51172 9.03571 3.375V4.5H9.42857C10.2953 4.5 11 5.17266 11 6V10.5C11 11.3273 10.2953 12 9.42857 12H1.57143C0.704687 12 0 11.3273 0 10.5V6C0 5.17266 0.704687 4.5 1.57143 4.5H1.96429Z"
          :fill="color"/>
  </svg>
</template>
<script>
export default {
  name: 'HabeasIcon',
  props: {
    width: {
      type: Number,
      default: 11
    },
    height: {
      type: Number,
      default: 12
    },
    color: {
      type: String,
      default: '#B6BEC7'
    }
  }
}
</script>
