











export default {
  name: 'BillIcon'
}
