import {
  dataToGetSteps,
  dataToCreateStep,
  dataToUpdateStep,
  IStepsController,
  stepsGetter,
  dataToDeleteStep,
  dataToGetStep,
  stepGetter,
  dataToGetStepAmount,
  stepsAmountGetter
} from '../domain/StepsController'

export default class StepsUseCases {
  static abortAll (controller: IStepsController) : void {
    controller.abortAll()
  }

  static async getStep (controller: IStepsController, data: dataToGetStep) : Promise<stepGetter> {
    return await controller.getStep(data)
  }

  static async getSteps (controller: IStepsController, data: dataToGetSteps) : Promise<stepsGetter> {
    return await controller.getSteps(data)
  }

  static async createStep (controller: IStepsController, data: dataToCreateStep) {
    return await controller.createStep(data)
  }

  static async updateStep (controller: IStepsController, data: dataToUpdateStep) {
    return await controller.updateStep(data)
  }

  static async deleteStep (controller: IStepsController, data: dataToDeleteStep) {
    return await controller.deleteStep(data)
  }

  static async getStepAmount (controller: IStepsController, data: dataToGetStepAmount): Promise<stepsAmountGetter> {
    return await controller.getStepAmount(data)
  }
}
