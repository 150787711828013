
































































































import { WebWidgetPositionEnum, WebWidgetThemeEnum } from '@/modules/configuration/domain/models/WebWidget/WebWidget'
import KeybeAvatar from '@/shared/infrastructure/ui/KeybeAvatar.vue'
import KBColorPicker from '@/shared/infrastructure/ui/inputs/KBColorPicker.vue'
import KBSelect from '@/shared/infrastructure/ui/inputs/select/KBSelect.vue'
import KBText from '@/shared/infrastructure/ui/inputs/text/KBText.vue'
import UserService from '@/services/UserService'
import { mapState } from 'vuex'
import { CDPFieldsEnums } from '@/modules/CDP/shared/contacts/domain/enums/CDPFieldsEnums'
import { ActivityActionTypeEnum, ActivityLogModuleTypeEnum, dataToWriteActivityLog } from '@/modules/Config/ActivityLogs/domain/ActivityLogsController'
import ActivityLogsUseCases from '@/modules/Config/ActivityLogs/application/ActivityLogsUseCases'
import ActivityLogsController from '@/modules/Config/ActivityLogs/infrastructure/controllers/ActivityLogs.controller'
export default {
  name: 'WebWidgetCollapsedConfig',
  props: ['value'],
  components: {
    KBSelect,
    KBColorPicker,
    KBText,
    KeybeAvatar
  },
  computed: {
    ...mapState('UserStore', ['isUserReadonly', 'user', 'token']),
    ...mapState('AppStore', ['selectedApp'])
  },
  data () {
    return {
      config: null,
      modeOptions: [
        {
          text: this.$t('light'),
          value: WebWidgetThemeEnum.LIGHT
        },
        {
          text: this.$t('dark'),
          value: WebWidgetThemeEnum.DARK
        }
      ],
      positionOptions: [
        {
          text: this.$t('left'),
          value: WebWidgetPositionEnum.LEFT
        },
        {
          text: this.$t('right'),
          value: WebWidgetPositionEnum.RIGHT
        }
      ],
      bubbleSizeOptions: [{
        text: this.$t('small'),
        value: '60'
      },
      {
        text: this.$t('medium'),
        value: '80'
      },
      {
        text: this.$t('large'),
        value: '120'
      }, {
        text: this.$t('extraLarge'),
        value: '170'
      }],
      logo: '',
      oldValues: {},
      rerender: 0,
      CDPFieldsEnums,
      activityLogsController: new ActivityLogsController()
    }
  },
  methods: {
    openFileInput () {
      this.$refs.imageInput.click()
    },
    async onFilePicked (event) {
      const img = event.target.files[0]
      const response = await UserService.uploadImage(img)
      if (response.status) {
        this.config.logo = response?.data?.data?.info?.location || ''
        this.writeActivityLog(CDPFieldsEnums.LOGO)
        this.rerender++
      }
    },
    async writeActivityLog (field: string): Promise<void> {
      const data: dataToWriteActivityLog = {
        token: this.token,
        updateInput: {
          appUUID: this.selectedApp?.uuid,
          module: ActivityLogModuleTypeEnum.CHAT,
          action: ActivityActionTypeEnum.WIDGET_WEB_UPDATE,
          user: {
            id: this.user.uuid,
            name: this.user.name + ' ' + this.user.lastName
          },
          payload: {
            field: field,
            newValue: this.config[field],
            oldValue: this.oldValues[field]
          }
        }
      }
      await ActivityLogsUseCases.writeActivityLog(this.activityLogsController, data)
    }
  },
  created () {
    this.config = this.value
    // eslint-disable-next-line no-undef
    this.oldValues = structuredClone(this.value)
  },
  watch: {
    value () {
      this.config = this.value
    },
    config () {
      this.$emit('input', this.config)
    }
  }
}
