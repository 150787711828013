import { IKeybeAiConfigController, dataToGetConfig, dataToSetConfig } from '../domain/KeybeAiConfigController'

export default class KeybeAiConfigUseCases {
  static getSentimentsConfig (controller: IKeybeAiConfigController, data: dataToGetConfig) {
    return controller.getSentimentsConfig(data)
  }

  static setSentimentsConfig (controller: IKeybeAiConfigController, data: dataToSetConfig) {
    return controller.setSentimentsConfig(data)
  }

  static getActionSuggestionsConfig (controller: IKeybeAiConfigController, data: dataToGetConfig) {
    return controller.getActionSuggestionsConfig(data)
  }

  static setActionSuggestionsConfig (controller: IKeybeAiConfigController, data: dataToSetConfig) {
    return controller.setActionSuggestionsConfig(data)
  }

  static getMessageSuggestionsConfig (controller: IKeybeAiConfigController, data: dataToGetConfig) {
    return controller.getMessageSuggestionsConfig(data)
  }

  static setMessageSuggestionsConfig (controller: IKeybeAiConfigController, data: dataToSetConfig) {
    return controller.setMessageSuggestionsConfig(data)
  }
}
