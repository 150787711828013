<template>
  <svg :width="width"
       :height="height"
       viewBox="0 0 15 14"
       fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <path d="M5.39062 1.4H9.60938C9.73828 1.4 9.84375 1.505 9.84375 1.63333V2.8H5.15625V1.63333C5.15625 1.505 5.26172 1.4 5.39062 1.4ZM3.75 1.63333V2.8H1.875C0.84082 2.8 0 3.63708 0 4.66667V12.1333C0 13.1629 0.84082 14 1.875 14H13.125C14.1592 14 15 13.1629 15 12.1333V4.66667C15 3.63708 14.1592 2.8 13.125 2.8H11.25V1.63333C11.25 0.732083 10.5146 0 9.60938 0H5.39062C4.48535 0 3.75 0.732083 3.75 1.63333ZM6.5625 6.06667C6.5625 5.81 6.77344 5.6 7.03125 5.6H7.96875C8.22656 5.6 8.4375 5.81 8.4375 6.06667V7.46667H9.84375C10.1016 7.46667 10.3125 7.67667 10.3125 7.93333V8.86667C10.3125 9.12333 10.1016 9.33333 9.84375 9.33333H8.4375V10.7333C8.4375 10.99 8.22656 11.2 7.96875 11.2H7.03125C6.77344 11.2 6.5625 10.99 6.5625 10.7333V9.33333H5.15625C4.89844 9.33333 4.6875 9.12333 4.6875 8.86667V7.93333C4.6875 7.67667 4.89844 7.46667 5.15625 7.46667H6.5625V6.06667Z"
          :fill="color"/>
  </svg>
</template>
<script>
export default {
  name: 'HealthIcon',
  props: {
    width: {
      type: Number,
      default: 15
    },
    height: {
      type: Number,
      default: 14
    },
    color: {
      type: String,
      default: '#B6BEC7'
    }
  }
}
</script>
