




























import KBInvoicesPartners from './KBInvoicesPartners'
export default KBInvoicesPartners
