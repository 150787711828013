import { WebWidgetTextAlignEnum } from '@/modules/configuration/domain/models/WebWidget/WebWidget'
import KeybeText from '@/modules/DesignSystem/infrastructure/components/KeybeText/KeybeText.vue'
import KeybeSelect from '@/modules/DesignSystem/infrastructure/components/KeybeSelect/KeybeSelect/KeybeSelect.vue'
import KBColorPicker from '@/shared/infrastructure/ui/inputs/KBColorPicker.vue'
import KBSelect from '@/shared/infrastructure/ui/inputs/select/KBSelect.vue'
import { CDPFieldsEnums } from '@/modules/CDP/shared/contacts/domain/enums/CDPFieldsEnums'

export default {
  name: 'WebWidgetExpandedConfig',
  props: {
    config: {
      type: Object,
      required: true
    }
  },
  components: {
    KBSelect,
    KBColorPicker,
    KeybeSelect,
    KeybeText
  },
  data () {
    return {
      CDPFieldsEnums,
      languages: [{
        text: this.$t('spanish'),
        value: 'es'
      }, {
        text: this.$t('english'),
        value: 'en'
      }],
      fontSizeOptions: [{
        text: this.$t('small'),
        value: '12'
      },
      {
        text: this.$t('medium'),
        value: '14'
      },
      {
        text: this.$t('large'),
        value: '16'
      }, {
        text: this.$t('extraLarge'),
        value: '18'
      }],
      fontAlignOptions: [{
        text: this.$t('left'),
        value: WebWidgetTextAlignEnum.LEFT
      }, {
        text: this.$t('center'),
        value: WebWidgetTextAlignEnum.CENTER
      }, {
        text: this.$t('right'),
        value: WebWidgetTextAlignEnum.RIGHT
      }]
    }
  }
  // watch: {
  //   value () {
  //     this.config = this.value
  //   },
  //   config () {
  //     this.$emit('input', this.config)
  //   }
  // },
//   computed: {
//     textStyle () {
//       return {
//         color: this.config?.welcomeForm?.color,
//         fontSize: `${this.config?.welcomeForm?.fontSize}px`,
//         textAlign: this.config?.welcomeForm?.textAlign
//       }
//     }
//   }
}
