import TemplateTextEditor from '../TemplateTextEditor/TemplateTextEditor.vue'
import KeybeText from '@/modules/DesignSystem/infrastructure/components/KeybeText/KeybeText.vue'
import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'
import KeybeCopy from '@/modules/DesignSystem/infrastructure/components/KeybeCopy/KeybeCopy.vue'

export default {
  name: 'ViewTemplateForm',
  components: {
    TemplateTextEditor,
    KeybeText,
    KeybeIcon,
    KeybeCopy
  },
  props: {
    selectedTemplate: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      KeybeIconType
    }
  },
  computed: {
    components () {
      return JSON.parse(this.selectedTemplate?.components)
    }
  },
  methods: {
    goBackToTemplates () {
      this.$emit('goBackToTemplates')
    }
  }
}
