import { EventBus } from '@/eventBus'
import CatalogSidebar from '../CatalogSidebar/CatalogSidebar.vue'
import { CatalogTabsEnum } from '@/modules/catalog/domain/models/CatalogTabsEnum'
import CatalogConfig from '../CatalogConfig/CatalogConfig'
import Categories from '@/modules/microsites/infrastructure/ui/components/Categories/Categories'
import CatalogImportsScreen from '../CatalogImports/CatalogImportsScreen/CatalogImportsScreen.vue'
export default {
  name: 'ImportsScreen',
  components: {
    CatalogSidebar
  },
  computed: {
    getArchived () {
      return this.selectedTab === CatalogTabsEnum.ARCHIVED
    },
    selectedTabComponent () {
      const components = {
        [CatalogTabsEnum.CATALOG]: CatalogConfig,
        [CatalogTabsEnum.ARCHIVED]: CatalogConfig,
        [CatalogTabsEnum.CATEGORIES]: Categories,
        [CatalogTabsEnum.IMPORTS]: CatalogImportsScreen
      }
      return components[this.selectedTab]
    }
  },
  data () {
    return {
      selectedTab: CatalogTabsEnum.CATALOG,
      loading: false
    }
  },
  methods: {
    selectTab (e) {
      this.selectedTab = e
    }
  },
  destroyed () {
    EventBus.$off('selectImportsTab')
  }
}
