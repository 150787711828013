import { render, staticRenderFns } from "./CreateTaskHeader.vue?vue&type=template&id=29a3d70f&scoped=true&"
import script from "./CreateTaskHeader.vue?vue&type=script&lang=ts&"
export * from "./CreateTaskHeader.vue?vue&type=script&lang=ts&"
import style0 from "./CreateTaskHeader.vue?vue&type=style&index=0&id=29a3d70f&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "29a3d70f",
  null
  
)

export default component.exports