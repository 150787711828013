import { LoggedUser, LoggedUserExtradata } from '@/modules/Config/GeneralUserConfiguration/domain/LoggedUser'
import { IOnboardingController } from '../domain/OnboardingController'

export default class OnboardingUseCases {
  static async setOnboardingStarted (controller: IOnboardingController, token: string) : Promise<LoggedUser> {
    return await controller.setOnboardingStarted(token)
  }

  static checkOnboardingStatus (controller: IOnboardingController) : string {
    return controller.checkOnboardingStatus()
  }

  static async setOnboardingStep (controller: IOnboardingController, step: number) : Promise<void> {
    return await controller.setOnboardingStep(step)
  }

  static async getOnboardingStep (controller: IOnboardingController) : Promise<number> {
    return await controller.getOnboardingStep()
  }

  static async updateUserOnboarding (controller: IOnboardingController, extradata: LoggedUserExtradata[]) : Promise<boolean> {
    return await controller.updateUserOnboarding(extradata)
  }

  static restartOnboarding (controller: IOnboardingController) : void {
    return controller.restartOnboarding()
  }

  static getOnboardingProgress (controller: IOnboardingController) : number {
    return controller.getOnboardingProgress()
  }

  static stopOnboarding (controller: IOnboardingController) : void {
    return controller.stopOnboarding()
  }
}
