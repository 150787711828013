<template>
<svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
<path :fill="color" d="M1.48501 7.33501L0.35 8.47003V1.8C0.35 1.41544 0.502767 1.04662 0.774695 0.774695C1.04662 0.502767 1.41544 0.35 1.8 0.35H7.2C7.58456 0.35 7.95338 0.502767 8.2253 0.774695C8.49723 1.04662 8.65 1.41544 8.65 1.8V5.4C8.65 5.78456 8.49723 6.15338 8.2253 6.4253C7.95338 6.69723 7.58456 6.85 7.2 6.85H2.70316C2.4795 6.8462 2.25728 6.88657 2.04924 6.96882C1.84011 7.0515 1.64946 7.17485 1.48834 7.33173L1.48832 7.33171L1.48501 7.33501Z" stroke="black" stroke-width="0.7"/>
</svg>
  </template>

<script>
export default {
  name: 'DialogIcon',
  props: {
    width: {
      type: Number,
      default: 16
    },
    height: {
      type: Number,
      default: 18
    },
    color: {
      type: String,
      default: '#B6BEC7'
    }
  }

}
</script>

<style>

</style>
