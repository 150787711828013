<template>
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 21 14"><path :fill="color" d="M.003 7C.003 5.495.01 3.986 0 2.479-.005 1.651.3.983.951.475c.428-.332.928-.467 1.453-.468 3.055-.008 6.11-.01 9.164 0 1.38.003 2.4 1.01 2.407 2.398.015 3.064.015 6.128 0 9.191-.008 1.407-1.043 2.4-2.454 2.402-3.022.003-6.046.003-9.068 0-1.154-.001-2.051-.653-2.36-1.73a2.959 2.959 0 0 1-.087-.793C-.002 9.985.002 8.494.002 7.003L.003 7Zm6.976 5.844h4.545c.861 0 1.313-.436 1.314-1.296.006-3.031.007-6.063-.007-9.094a1.681 1.681 0 0 0-.2-.782c-.226-.404-.633-.517-1.082-.516-3.022.004-6.044.002-9.067.002-.907 0-1.337.429-1.338 1.345-.002 2.998-.002 5.997.002 8.996 0 .184.016.375.069.55.159.524.56.793 1.168.794 1.532.003 3.063 0 4.595 0ZM19.826 11.663V2.339c-.091.056-.164.097-.232.144l-3.405 2.38c-.325.226-.573.24-.798.047-.274-.236-.29-.613-.015-.846.19-.163.403-.298.61-.442 1.034-.724 2.07-1.45 3.106-2.172.517-.36.988-.373 1.433-.024.337.263.458.633.457 1.05-.002 1.208 0 2.416 0 3.624v5.373c0 .647-.272 1.087-.778 1.276-.332.123-.658.108-.951-.097-1.271-.881-2.54-1.766-3.806-2.656-.287-.202-.345-.523-.168-.788.178-.269.522-.327.838-.122.346.225.68.47 1.018.706l2.468 1.728c.063.044.13.082.223.14v.003Z"/></svg>
</template>

<script>
export default {
  name: 'VideoCameraIcon',
  props: {
    color: {
      type: String,
      default: '#B6BEC7'
    }
  }
}
</script>

<style>

</style>
