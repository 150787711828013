<template>
  <div>
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <div class="d-flex items-center mr-3"
             v-on="on"
             v-bind="attrs">
          <small class="d-flex items-center mr-2"
                 style="font-style: normal">
            {{
              item.tipoIcon ?
                $t('secondaryButton') :
                $t('principalButton')
            }}
          </small>
          <v-icon :color="Colors.verdeBird">mdi-chevron-down</v-icon>
        </div>
      </template>
      <v-card class="menu-card" >
        <v-list>
          <v-list-item link
                       @click="toggleType(false)">
            <small>
              {{ $t('principalButton') }}
            </small>
          </v-list-item>
          <v-list-item link
                       @click="toggleType(true)">
            <small>
              {{ $t('secondaryButton') }}
            </small>
          </v-list-item>
        </v-list>
      </v-card>
    </v-menu>
  </div>
</template>
<script>
import Vue from 'vue'
import Colors from '@/utils/Colors'
export default Vue.extend({
  props: {
    item: {
      type: Object
    }
  },
  data () {
    return {
      Colors
    }
  },
  methods: {
    toggleType (type, index) {
      this.$emit('toggleType', type)
    }
  }
})
</script>
<style scoped>
  small {
    font-size: 12px;
    min-width: 115px;
  }
</style>
