import store from '@/store'
import axios from 'axios'
import { RestResponseFactory } from '@/models/apis/RestResponse'

export default class ClientsDashboardService {
  // Conversations
  static async getTotalConversations (weeks) {
    // console.log('Getting total conversations')
    const companyId: string = store.getters['CompanyStore/getSelectedCompany'].uuid
    const appId: string = store.getters['AppStore/getSelectedApp'].uuid
    const url: string = `${process.env.VUE_APP_ANALYTICS_URL}/analytics/company/${companyId}/app/${appId}/pipelines/total/conversations?periodWeeks=${weeks}`
    const token = store.getters['UserStore/getMetricsToken']
    // console.log('App info ', appId)
    const response = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    })

    return new RestResponseFactory().process({ response })
  }

  static async getClosedConversations (weeks) {
    // console.log('Getting closed conversations')
    const companyId: string = store.getters['CompanyStore/getSelectedCompany'].uuid
    const appId: string = store.getters['AppStore/getSelectedApp']?.uuid
    const url: string = `${process.env.VUE_APP_ANALYTICS_URL}/analytics/company/${companyId}/app/${appId}/pipelines/closed/conversations?periodWeeks=${weeks}`
    const token = store.getters['UserStore/getMetricsToken']

    const response = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    })

    return new RestResponseFactory().process({ response })
  }

  static async getOpenConversations (weeks) {
    // console.log('Getting open conversations')
    const companyId: string = store.getters['CompanyStore/getSelectedCompany'].uuid
    const appId: string = store.getters['AppStore/getSelectedApp']?.uuid
    const url: string = `${process.env.VUE_APP_ANALYTICS_URL}/analytics/company/${companyId}/app/${appId}/pipelines/open/conversations/advisor?periodWeeks=${weeks}`
    const token = store.getters['UserStore/getMetricsToken']

    const response = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    })

    return new RestResponseFactory().process({ response })
  }

  static async getConversationsPerAgent (weeks) {
    // console.log('Getting conversations per agent')
    const companyId: string = store.getters['CompanyStore/getSelectedCompany'].uuid
    const appId: string = store.getters['AppStore/getSelectedApp']?.uuid
    const url: string = `${process.env.VUE_APP_ANALYTICS_URL}/analytics/company/${companyId}/app/${appId}/pipelines/table/conversations/advisor?periodWeeks=${weeks}`
    const token = store.getters['UserStore/getMetricsToken']

    const response = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    })

    return new RestResponseFactory().process({ response })
  }

  static async getNewConversations (weeks) {
    // console.log('Getting new conversations')
    const companyId: string = store.getters['CompanyStore/getSelectedCompany'].uuid
    const appId: string = store.getters['AppStore/getSelectedApp']?.uuid
    const url: string = `${process.env.VUE_APP_ANALYTICS_URL}/analytics/company/${companyId}/app/${appId}/pipelines/new/user/conversations?periodStart=${weeks.periodStart + 'T00:00'}&periodEnd=${weeks.periodEnd + 'T23:59'}`
    const token = store.getters['UserStore/getMetricsToken']

    const response = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    })

    return new RestResponseFactory().process({ response })
  }

  static async getHistoricConversations (weeks) {
    const companyId: string = store.getters['CompanyStore/getSelectedCompany'].uuid
    const appId: string = store.getters['AppStore/getSelectedApp']?.uuid
    const url: string = `${process.env.VUE_APP_V6_ANALYTICS_URL}/company/${companyId}/app/${appId}/conversations/trends?from_date=${weeks.periodStart}&to_date=${weeks.periodEnd}`
    // const url: string = `${process.env.VUE_APP_ANALYTICS_URL}/analytics/company/${companyId}/app/${appId}/pipelines/conversations/historicalConversations?fromDate=${weeks.periodStart}&toDate=${weeks.periodEnd}`
    const token = store.getters['UserStore/getMetricsToken']

    const response = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    })

    return new RestResponseFactory().process({ response })
  }

  static async getNewUsers (weeks) {
    const companyId: string = store.getters['CompanyStore/getSelectedCompany'].uuid
    const appId: string = store.getters['AppStore/getSelectedApp']?.uuid
    const url: string = `${process.env.VUE_APP_V6_ANALYTICS_URL}/company/${companyId}/app/${appId}/conversations/new_users?from_date=${weeks.periodStart}&to_date=${weeks.periodEnd}`
    const token = store.getters['UserStore/getMetricsToken']

    const response = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    })
    return new RestResponseFactory().process({ response })
  }

  static async getConversationsVolume (weeks) {
    // console.log('Getting conversations volume')
    const companyId: string = store.getters['CompanyStore/getSelectedCompany'].uuid
    const appId: string = store.getters['AppStore/getSelectedApp']?.uuid
    const url: string = `${process.env.VUE_APP_ANALYTICS_URL}/analytics/company/${companyId}/app/${appId}/pipelines/conversations/histogram?periodWeeks=${weeks}`
    const token = store.getters['UserStore/getMetricsToken']

    const response = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    })

    return new RestResponseFactory().process({ response })
  }

  static async getConversationsPerChannel (weeks) {
    // console.log('Getting conversations per channel')
    const companyId: string = store.getters['CompanyStore/getSelectedCompany'].uuid
    const appId: string = store.getters['AppStore/getSelectedApp']?.uuid
    const url: string = `${process.env.VUE_APP_ANALYTICS_URL}/analytics/company/${companyId}/app/${appId}/pipelines/conversations/created/channel?periodStart=${weeks.periodStart}&periodEnd=${weeks.periodEnd}`
    const token = store.getters['UserStore/getMetricsToken']

    const response = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    })

    return new RestResponseFactory().process({ response })
  }

  // Events
  getAssistancePercentage

  static async getEventList () {
    // const companyId: string = store.getters['CompanyStore/getSelectedCompany'].uuid
    // const appId: string = store.getters['AppStore/getSelectedApp']?.uuid
    const url: string = `${process.env.VUE_APP_BASE_URL}/companies/dkcj1l4t1oz0m/apps/dkcj1l4t1oz18/events?page=1&sortBy=createdAt&descending=true&limit=10'`
    const token = store.getters['UserStore/getMetricsToken']

    const response = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    })
    console.log('Getting event list ', response)
    return new RestResponseFactory().process({ response })
  }

  static async getAssistancePercentage (eventId) {
    const companyId: string = store.getters['CompanyStore/getSelectedCompany'].uuid
    const appId: string = store.getters['AppStore/getSelectedApp']?.uuid
    const url: string = `${process.env.VUE_APP_ANALYTICS_URL}/analytics/company/${companyId}/app/${appId}/kblive/event/assistance?eventId=${eventId}`
    const token = store.getters['UserStore/getMetricsToken']

    const response = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    })

    return new RestResponseFactory().process({ response })
  }

  static async getPublicEvents () {
    const companyId: string = store.getters['CompanyStore/getSelectedCompany'].uuid
    const appId: string = store.getters['AppStore/getSelectedApp']?.uuid
    const url: string = `${process.env.VUE_APP_ANALYTICS_URL}/analytics/company/${companyId}/app/${appId}/kblive/current/public/events`
    const token = store.getters['UserStore/getMetricsToken']

    const response = await axios.get(url, {
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
    console.log('PUBLIC EVENTS RESPONSE', response)

    return new RestResponseFactory().process({ response })
  }

  static async getEventAssistanceTimeline (eventId) {
    const companyId: string = store.getters['CompanyStore/getSelectedCompany'].uuid
    const appId: string = store.getters['AppStore/getSelectedApp']?.uuid
    const url: string = `${process.env.VUE_APP_ANALYTICS_URL}/analytics/company/${companyId}/app/${appId}/kblive/timeline/checkin?eventId=${eventId}`
    const token = store.getters['UserStore/getMetricsToken']

    const response = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    })
    console.log('EVENTS TIMELINE RESPONSE', response)
    return new RestResponseFactory().process({ response })
  }

  static async getTicketRewards (eventId) {
    const companyId: string = store.getters['CompanyStore/getSelectedCompany'].uuid
    const appId: string = store.getters['AppStore/getSelectedApp']?.uuid
    const url: string = `${process.env.VUE_APP_ANALYTICS_URL}/analytics/company/${companyId}/app/${appId}/kblive/total/payment/event?eventId=${eventId}`
    const token = store.getters['UserStore/getMetricsToken']

    const response = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    })
    console.log('EVENTS REWARDING RESPONSE', response)
    return new RestResponseFactory().process({ response })
  }

  static async getEventsAssistants (eventId) {
    const companyId: string = store.getters['CompanyStore/getSelectedCompany'].uuid
    const appId: string = store.getters['AppStore/getSelectedApp']?.uuid
    const url: string = `${process.env.VUE_APP_ANALYTICS_URL}/analytics/company/${companyId}/app/${appId}/kblive/event/assistance?eventId=${eventId}`
    const token = store.getters['UserStore/getMetricsToken']

    const response = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    })
    console.log('EVENTS ASSISTANCE RESPONSE', response)
    return new RestResponseFactory().process({ response })
  }

  // V4 Metrics
  static async getCentralMetrics () {
    const companyId: string = store.getters['CompanyStore/getSelectedCompany'].uuid
    const appId: string = store.getters['AppStore/getSelectedApp']?.uuid
    const url: string = `${process.env.VUE_APP_V6_ANALYTICS_URL}/company/${companyId}/app/${appId}/conversations/advisors/current/state`
    // const url: string = `${process.env.VUE_APP_ANALYTICS_URL}/analytics/company/${companyId}/app/${appId}/pipelines/conversa/current/state`
    const token = store.getters['UserStore/getMetricsToken']

    const response = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    })
    // console.log('NEW METRICS RESPONSE', response)
    return new RestResponseFactory().process({ response })
  }

  static async getConversationsState (advisorList) {
    const companyId: string = store.getters['CompanyStore/getSelectedCompany'].uuid
    const appId: string = store.getters['AppStore/getSelectedApp']?.uuid
    let path = ''
    if (advisorList) {
      path = `company/${companyId}/app/${appId}/conversations/current/state?advisorList=${advisorList.toString()}`
    } else {
      path = `company/${companyId}/app/${appId}/conversations/current/state`
    }
    const url: string = `${process.env.VUE_APP_V6_ANALYTICS_URL}/${path}`
    // const url: string = `${process.env.VUE_APP_ANALYTICS_URL}/analytics/company/${companyId}/app/${appId}/pipelines/conversations/current/state`
    const token = store.getters['UserStore/getMetricsToken']

    const response = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    })
    // console.log('CONVERSATIONS STATE', response)
    return new RestResponseFactory().process({ response })
  }

  static async getAverageTalkVolume (data) {
    const companyId: string = store.getters['CompanyStore/getSelectedCompany'].uuid
    const appId: string = store.getters['AppStore/getSelectedApp']?.uuid
    const token = store.getters['UserStore/getMetricsToken']
    const { periodEnd, periodStart } = data
    const url: string = `${process.env.VUE_APP_V6_ANALYTICS_URL}/company/${companyId}/app/${appId}/conversations/average_volume?from_date=${periodStart}&to_date=${periodEnd}`
    // const url: string = `${process.env.VUE_APP_ANALYTICS_URL}/analytics/company/${companyId}/app/${appId}/pipelines/conversa/heatmapByDate?fromDate=${periodStart}&toDate=${periodEnd}`

    const response = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    })

    return new RestResponseFactory().process({ response })
  }

  static async getGlobalTimeseV5 (weeks) {
    const companyId: string = store.getters['CompanyStore/getSelectedCompany'].uuid
    const appId: string = store.getters['AppStore/getSelectedApp']?.uuid
    const token = store.getters['UserStore/getMetricsToken']
    const url: string = `${process.env.VUE_APP_V6_ANALYTICS_URL}/company/${companyId}/app/${appId}/conversations/global_times?from_date=${weeks.startDate}&to_date=${weeks.endDate}`
    const response = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    })
    return new RestResponseFactory().process({ response })
  }

  static async getConversationTimeV5 (weeks) {
    const companyId: string = store.getters['CompanyStore/getSelectedCompany'].uuid
    const appId: string = store.getters['AppStore/getSelectedApp']?.uuid
    const token = store.getters['UserStore/getMetricsToken']
    const url: string = `${process.env.VUE_APP_V6_ANALYTICS_URL}/company/${companyId}/app/${appId}/conversations/times?from_date=${weeks.startDate}&to_date=${weeks.endDate}`
    const response = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    })
    return new RestResponseFactory().process({ response })
  }

  static async getFirstTimeV5 (weeks) {
    const companyId: string = store.getters['CompanyStore/getSelectedCompany'].uuid
    const appId: string = store.getters['AppStore/getSelectedApp']?.uuid
    const token = store.getters['UserStore/getMetricsToken']
    const url: string = `${process.env.VUE_APP_V6_ANALYTICS_URL}/company/${companyId}/app/${appId}/conversations/first_response_times?from_date=${weeks.startDate}&to_date=${weeks.endDate}`
    const response = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    })

    return new RestResponseFactory().process({ response })
  }

  static async getWaitingTimeV5 (weeks) {
    const companyId: string = store.getters['CompanyStore/getSelectedCompany'].uuid
    const appId: string = store.getters['AppStore/getSelectedApp']?.uuid
    const token = store.getters['UserStore/getMetricsToken']
    const url: string = `${process.env.VUE_APP_V6_ANALYTICS_URL}/company/${companyId}/app/${appId}/conversations/waiting_times?from_date=${weeks.startDate}&to_date=${weeks.endDate}`
    const response = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    })

    return new RestResponseFactory().process({ response })
  }

  static async getV4Metrics (weeks) {
    const companyId: string = store.getters['CompanyStore/getSelectedCompany'].uuid
    const appId: string = store.getters['AppStore/getSelectedApp']?.uuid
    const token = store.getters['UserStore/getMetricsToken']
    const url: string = `${process.env.VUE_APP_V6_ANALYTICS_URL}/company/${companyId}/app/${appId}/conversations/advisors/statistics?from_date=${weeks.startDate}&to_date=${weeks.endDate}`
    // const url: string = `${process.env.VUE_APP_ANALYTICS_URL}/analytics/company/${companyId}/app/${appId}/statistics?fromDate=${weeks.startDate}&toDate=${weeks.endDate}`
    const response = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    })

    return new RestResponseFactory().process({ response })
  }

  static async getBarEndReason (weeks) {
    const companyId: string = store.getters['CompanyStore/getSelectedCompany'].uuid
    const appId: string = store.getters['AppStore/getSelectedApp']?.uuid
    const token = store.getters['UserStore/getMetricsToken']
    const url: string = `${process.env.VUE_APP_V6_ANALYTICS_URL}/company/${companyId}/app/${appId}/conversations/ended_reasons?from_date=${weeks.startDate}&to_date=${weeks.endDate}`
    // const url: string = `${process.env.VUE_APP_ANALYTICS_URL}/analytics/company/${companyId}/app/${appId}/pipelines/conversations/endedReasons?fromDate=${weeks.startDate}&toDate=${weeks.endDate}`
    const response = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    })

    return new RestResponseFactory().process({ response })
  }

  static async getConversationsReport (data) {
    const companyId: string = store.getters['CompanyStore/getSelectedCompany'].uuid
    const appId: string = store.getters['AppStore/getSelectedApp']?.uuid
    const token = store.getters['UserStore/getMetricsToken']
    const { fromDate, toDate, advisorId, endReason, addUserInfoForReport, addUserExtradataForReport } = data
    const request = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      params: {
        fromDate: fromDate + 'T00:00',
        toDate: toDate + 'T23:59',
        advisorId,
        endReason,
        addUserInfoForReport,
        addUserExtradataForReport
      }
    }
    const url = `${process.env.VUE_APP_ANALYTICS_URL}/analytics/company/${companyId}/app/${appId}/export/conversations/report`
    const response = await axios.get(url, request)

    return new RestResponseFactory().process({ response })
  }

  static async getMessagesReport (data) {
    const companyId: string = store.getters['CompanyStore/getSelectedCompany'].uuid
    const appId: string = store.getters['AppStore/getSelectedApp']?.uuid
    const token = store.getters['UserStore/getMetricsToken']
    const { fromDate, toDate } = data
    const request = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      params: {
        fromDate: fromDate + 'T00:00',
        toDate: toDate + 'T23:59'
      }
    }
    const url = `${process.env.VUE_APP_ANALYTICS_URL}/analytics/company/${companyId}/app/${appId}/export/messages/report`
    const response = await axios.get(url, request)

    return new RestResponseFactory().process({ response })
  }

  static async getAdvisorsPerformanceDetails (data) {
    const companyId: string = store.getters['CompanyStore/getSelectedCompany'].uuid
    const appId: string = store.getters['AppStore/getSelectedApp']?.uuid
    const token = store.getters['UserStore/getMetricsToken']
    const { endDate, startDate, advisorId, endReason } = data
    const request = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      params: {
        from_date: startDate + 'T00:00',
        to_date: endDate + 'T23:59',
        advisorId,
        endReason
      }
    }
    const url = `${process.env.VUE_APP_V6_ANALYTICS_URL}/company/${companyId}/app/${appId}/conversations/advisors/performance`
    // const url = `${process.env.VUE_APP_ANALYTICS_URL}/analytics/company/${companyId}/app/${appId}/conversationstypes/advisor/performance/details`
    const response = await axios.get(url, request)

    return new RestResponseFactory().process({ response })
  }

  static async getDeals (data) {
    const companyId: string = store.getters['CompanyStore/getSelectedCompany'].uuid
    const appId: string = store.getters['AppStore/getSelectedApp']?.uuid
    const token = store.getters['UserStore/getMetricsToken']
    const { periodEnd, periodStart, pipelineId } = data
    const request = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    }
    const url = `${process.env.VUE_APP_V6_ANALYTICS_URL}/company/${companyId}/app/${appId}/pipelines/historic_deals?from_date=${periodStart}&to_date=${periodEnd}&pipeline_id=${pipelineId}`
    // const url = `${process.env.VUE_APP_ANALYTICS_URL}/analytics/company/${companyId}/app/${appId}/pipelines/historicDeals/pipelinesFilter?initialDate=${periodStart}&finalDate=${periodEnd}${sendPipelineId}`
    const response = await axios.get(url, request)

    return new RestResponseFactory().process({ response })
  }

  static async getFunnelCRM (data) {
    const companyId: string = store.getters['CompanyStore/getSelectedCompany'].uuid
    const appId: string = store.getters['AppStore/getSelectedApp']?.uuid
    const token = store.getters['UserStore/getMetricsToken']
    const { endDate, startDate, pipelineId } = data
    const request = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    }
    const url = `${process.env.VUE_APP_V6_ANALYTICS_URL}/company/${companyId}/app/${appId}/pipelines/funnel/opportunities?from_date=${startDate}&to_date=${endDate}&pipeline_id=${pipelineId}`
    // const url = `${process.env.VUE_APP_ANALYTICS_URL}/analytics/company/${companyId}/app/${appId}/pipelines/funnel/opportunityFilter?initialDate=${startDate}T00:00&finalDate=${endDate}T23:59&pipelineId=${pipelineId}`
    const response = await axios.get(url, request)

    return new RestResponseFactory().process({ response })
  }

  static async getCRMBenchmark (data) {
    const companyId: string = store.getters['CompanyStore/getSelectedCompany'].uuid
    const appId: string = store.getters['AppStore/getSelectedApp']?.uuid
    const token = store.getters['UserStore/getMetricsToken']
    const { endDate, startDate, pipelineId } = data
    const sendPipelineId = pipelineId || ''
    const request = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      params: {
        pipeline_id: sendPipelineId,
        from_date: startDate,
        to_date: endDate
      }
    }
    const url = `${process.env.VUE_APP_V6_ANALYTICS_URL}/company/${companyId}/app/${appId}/pipelines/deals/lost_vs_win`
    // const url = `${process.env.VUE_APP_ANALYTICS_URL}/analytics/company/${companyId}/app/${appId}/pipelines/lost-win/deals/pipelinesFilter`
    const response = await axios.get(url, request)

    return new RestResponseFactory().process({ response })
  }

  static async getCRMDealsWon (data) {
    const companyId: string = store.getters['CompanyStore/getSelectedCompany'].uuid
    const appId: string = store.getters['AppStore/getSelectedApp']?.uuid
    const token = store.getters['UserStore/getMetricsToken']
    const { endDate, startDate, pipelineId } = data
    const sendPipelineId = pipelineId || ''
    const request = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      params: {
        pipeline_id: sendPipelineId,
        from_date: startDate,
        to_date: endDate
      }
    }
    const url = `${process.env.VUE_APP_V6_ANALYTICS_URL}/company/${companyId}/app/${appId}/pipelines/closed_deals`
    // const url = `${process.env.VUE_APP_ANALYTICS_URL}/analytics/company/${companyId}/app/${appId}/pipelines/closedDeals/pipelinesFilter`
    const response = await axios.get(url, request)

    return new RestResponseFactory().process({ response })
  }

  static async getSalesPerformance (data) {
    const companyId: string = store.getters['CompanyStore/getSelectedCompany'].uuid
    const appId: string = store.getters['AppStore/getSelectedApp']?.uuid
    const token = store.getters['UserStore/getMetricsToken']
    const { endDate, startDate } = data
    const request = {
      headers: {
        'Content-Type': 'application/json',
        authorization: 'Bearer ' + token
      }
    }
    const url = `${process.env.VUE_APP_V6_ANALYTICS_URL}/company/${companyId}/app/${appId}/pipelines/sales/performance?from_date=${startDate}&to_date=${endDate}`
    // const url = `${process.env.VUE_APP_ANALYTICS_URL}/analytics/company/${companyId}/app/${appId}/pipelines/sales/performance?fromDate=${startDate}T00:00&toDate=${endDate}T23:59`
    const response = await axios.get(url, request)

    return new RestResponseFactory().process({ response })
  }

  static async getSalesByAdvisor (data) {
    const companyId: string = store.getters['CompanyStore/getSelectedCompany'].uuid
    const appId: string = store.getters['AppStore/getSelectedApp']?.uuid
    const token = store.getters['UserStore/getMetricsToken']
    const { endDate, startDate, pipelineId } = data
    const request = {
      headers: {
        'Content-Type': 'application/json',
        authorization: 'Bearer ' + token
      }
    }
    const url = `${process.env.VUE_APP_V6_ANALYTICS_URL}/company/${companyId}/app/${appId}/pipelines/sales_by_user?from_date=${startDate}&to_date=${endDate}&pipeline_id=${pipelineId}`
    // const url = `${process.env.VUE_APP_ANALYTICS_URL}/analytics/company/${companyId}/app/${appId}/pipelines/salesByUser/pipelinesFilter?initialDate=${startDate}T00:00&finalDate=${endDate}T23:59${sendPipelineId}`
    const response = await axios.get(url, request)

    return new RestResponseFactory().process({ response })
  }

  static async getCSAT (data) {
    const appId: string = store.getters['AppStore/getSelectedApp']?.uuid
    const { periodEnd, periodStart, withData = null } = data
    const identifier = {
      appUUID: appId,
      dateInit: periodStart,
      dateEnd: periodEnd,
      withData
    }

    const url = `${process.env.VUE_APP_FLOWS_URL}/stats/general`
    const response = await axios.post(url, identifier, {
      headers: {
        'Content-Type': 'application/json'
      }
    })

    return new RestResponseFactory().process({ response })
  }

  static async getAppPipelines () {
    const companyId: string = store.getters['CompanyStore/getSelectedCompany'].uuid
    const appId: string = store.getters['AppStore/getSelectedApp']?.uuid
    const token = store.getters['UserStore/getMetricsToken']
    const request = {
      headers: {
        'Content-Type': 'application/json',
        authorization: 'Bearer ' + token
      }
    }
    const url = `${process.env.VUE_APP_V6_ANALYTICS_URL}/company/${companyId}/app/${appId}/pipelines`
    // const url = `${process.env.VUE_APP_ANALYTICS_URL}/analytics/company/${companyId}/app/${appId}/pipelines/funnel/getPipelines`
    const response = await axios.get(url, request)
    return new RestResponseFactory().process({ response })
  }
}
