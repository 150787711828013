export enum WpProviders {
    Gupshup = 'GP_WHATSAPP',
    Smooch = 'SM_WHATSAPP',
    Hibot = 'HB_WHATSAPP',
    WpKeybe = 'KB_WHATSAPP',
}

export enum WpProvidersNames {
    Gupshup = 'Gupshup',
    Smooch = 'Smooch',
    Hibot = 'Hibot',
}

export interface IWpProvider {
    provider: WpProviders;
    userHost: string;
}
