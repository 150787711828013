




































import FunnelsFieldsConfigContainer from './FunnelsFieldsConfigContainer'
export default FunnelsFieldsConfigContainer
