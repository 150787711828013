







































































































































import PartnersContract from './PartnersContract'
export default PartnersContract
