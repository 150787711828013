import { BikyWebsiteStateEnum } from '@/modules/bky/domain/BikyConfig'
import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'
import KBText from '@/shared/infrastructure/ui/inputs/text/KBText.vue'
export default {
  name: 'BikyConfigUrl',
  components: {
    KeybeIcon,
    KBText
  },
  props: {
    value: {
      type: String,
      required: true
    },
    state: {
      type: String,
      required: false
    },
    error: {
      type: String,
      required: false
    }
  },
  data () {
    return {
      KeybeIconType,
      url: ''
    }
  },
  mounted () {
    this.url = this.value
  },
  methods: {
    isPendingWebsite (): boolean {
      return this.state === BikyWebsiteStateEnum.PENDING
    },
    isSuccessWebsite (): boolean {
      return this.state === BikyWebsiteStateEnum.SUCCESS
    },
    isErrorWebsite (): boolean {
      return this.state === BikyWebsiteStateEnum.ERROR
    },
    getWebsiteError (): string {
      return this.error || ''
    },
    getStateIcon (): string {
      if (this.isPendingWebsite()) {
        return KeybeIconType.LOADING
      }
      if (this.isSuccessWebsite()) {
        return KeybeIconType.CHECK
      }
      if (this.isErrorWebsite()) {
        return KeybeIconType.WARNING
      }
      return ''
    },
    stateLabel (): string {
      if (this.isPendingWebsite()) {
        return this.$t('syncing')
      }
      if (this.isSuccessWebsite()) {
        return this.$t('synced')
      }
      if (this.isErrorWebsite()) {
        return `${this.$t('syncError')}: ${this.$t(this.getWebsiteError())}`
      }
      return ''
    }
  },
  watch: {
    url (newValue) {
      this.$emit('input', newValue)
    },
    value (newValue) {
      this.url = newValue
    }
  }
}
