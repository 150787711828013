export default {
  name: 'VideoPreview',
  props: ['video'],
  mounted () {
    setTimeout(() => {
      if (!this.video) return
      const canvas = document.getElementById('canvas')
      const video = document.getElementById('video')
      // @ts-ignore
      canvas.getContext('2d').drawImage(video, 0, 0, video?.videoWidth, video?.videoHeight)
    }, 500)
  }
}
