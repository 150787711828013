<template>
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14"><path :fill="color" d="M0 6.772c.012-.347.212-.58.54-.726 1.617-.721 3.233-1.445 4.85-2.168.316-.141.634-.279.949-.424a1.517 1.517 0 0 1 1.32-.003l5.83 2.609c.317.142.51.387.51.737 0 .342-.19.584-.499.723-1.954.876-3.91 1.75-5.864 2.624-.437.195-.868.18-1.305-.016A2556.67 2556.67 0 0 0 .534 7.535C.212 7.392.01 7.165 0 6.773v-.001Zm13.003.028c-.056-.035-.075-.049-.096-.058L7.276 4.22a.675.675 0 0 0-.582.012c-1.848.83-3.697 1.658-5.546 2.486-.04.019-.076.046-.133.08.083.04.141.071.201.098 1.816.813 3.633 1.623 5.446 2.442a.765.765 0 0 0 .68 0c1.358-.615 2.72-1.221 4.08-1.831l1.58-.707h.001Z"/><path :fill="color" d="m.988 10.3.59.266c1.716.768 3.432 1.535 5.146 2.306.185.083.36.087.544.005l5.597-2.505c.036-.016.07-.038.139-.076-.298-.137-.567-.256-.833-.384-.182-.087-.338-.196-.33-.434a.423.423 0 0 1 .482-.403.88.88 0 0 1 .208.062c.324.142.646.285.966.434.313.145.506.39.504.74-.003.342-.195.582-.504.72L7.65 13.649c-.443.198-.879.186-1.322-.013-1.93-.868-3.862-1.735-5.798-2.592-.633-.281-.638-.944-.315-1.27.034-.035.07-.074.111-.093.419-.197.833-.402 1.259-.58a.448.448 0 0 1 .565.24c.078.217-.023.447-.26.559-.28.132-.565.253-.9.402H.987ZM11.4 2.4c-.37 0-.704.003-1.039-.001-.278-.003-.437-.115-.48-.327-.062-.307.12-.528.453-.538.28-.008.56-.001.84-.002h.225V.72c0-.11-.002-.219.009-.328.023-.232.183-.383.404-.392a.407.407 0 0 1 .428.369c.023.21.014.425.016.638.002.169 0 .337 0 .524.357 0 .69-.002 1.024 0 .264.003.404.09.476.29.087.239-.042.503-.292.546-.196.033-.4.025-.599.03-.193.005-.388.001-.608.001 0 .286.002.559-.001.83-.002.122.003.246-.02.364a.402.402 0 0 1-.434.337c-.227-.018-.377-.149-.39-.382-.017-.303-.01-.608-.012-.913v-.236.002Z"/></svg>
</template>

<script>
export default {
  name: 'ExtensionsIcon',
  props: {
    color: {
      type: String,
      default: '#B6BEC7'
    }
  }
}
</script>

<style>

</style>
