import {
  IFunnelController,
  dataToCreateFunnel,
  dataToUpdateFunnel,
  dataToDeleteFunnel,
  dataToGetFunnels,
  funnelsGetter,
  funnelsUpdater,
  funnelGetter,
  dataToGetFunnelByName,
  funnelByNameGetter,
  dataToGetUtms,
  utmsGetter
} from '@/modules/Sales/funnels/domain/FunnelsController'
import FunnelsRepository from '../repository/Funnels.repository'

export default class FunnelsController implements IFunnelController {
  repository: FunnelsRepository

  constructor () {
    this.repository = new FunnelsRepository()
  }

  async getFunnels (data: dataToGetFunnels) : Promise<funnelsGetter> {
    const response = await this.repository.getFunnels(data)
    return response
  }

  async createFunnel (data: dataToCreateFunnel): Promise<funnelGetter> {
    const response = await this.repository.createFunnel(data)
    return response
  }

  async updateFunnel (data: dataToUpdateFunnel): Promise<funnelsUpdater> {
    const response = await this.repository.updateFunnel(data)
    return response
  }

  async deleteFunnel (data: dataToDeleteFunnel): Promise<funnelsUpdater> {
    const response = await this.repository.deleteFunnel(data)
    return response
  }

  async getFunnelByName (data: dataToGetFunnelByName): Promise<funnelByNameGetter> {
    const response = await this.repository.getFunnelByName(data)
    return response
  }

  async getUtms (data: dataToGetUtms): Promise<utmsGetter> {
    const response = await this.repository.getUtms(data)
    if (response.status) return { status: true, utms: response.data.map((utm) => (utm._id)) }
    else return { status: false, message: response.message }
  }

  abortAll (): void {
    this.repository.abortAll()
  }
}
