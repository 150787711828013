










































































































import BrandConfigurationContainer from './BrandConfigurationContainer'
export default BrandConfigurationContainer
