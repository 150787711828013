import TeamList from '../TeamList/TeamList.vue'
import UpdateTeamMember from '../UpdateTeamMember/UpdateTeamMember.vue'
import InviteTeam from '../InviteTeam/InviteTeam.vue'
import PermissionTypes from '../PermissionTypes/PermissionTypes.vue'
import { TeamMember } from '../../../domain/models/TeamMember'
import { EventBus } from '@/eventBus'
import { mapState } from 'vuex'
export default {
  components: {
    TeamList,
    UpdateTeamMember,
    InviteTeam,
    PermissionTypes
  },
  computed: {
    ...mapState('OnboardingStore', ['onboardingStep'])
  },
  mounted () {
    EventBus.$on('openInviteTeam--team-config', () => {
      this.openInvite()
    })
  },
  data () {
    return {
      showList: true,
      showUpdate: false,
      showInvite: false,
      showPermissions: false,
      updateMember: {}
    }
  },
  methods: {
    openUpdate (user: TeamMember): void {
      this.updateMember = user
      this.showUpdate = true
      this.showList = false
    },
    closeUpdate (): void {
      this.updateMember = {}
      this.showUpdate = false
      this.showList = true
    },
    openPermissions (): void {
      this.showList = false
      this.showPermissions = true
    },
    closePermissions (): void {
      this.showList = true
      this.showPermissions = false
    },
    openInvite (): void {
      this.showList = false
      this.showInvite = true
    },
    closeInvite (): void {
      this.showList = true
      this.showInvite = false
    }
  },
  beforeDestroy () {
    EventBus.$off('openInviteTeam--team-config', this.openInvite.bind(this))
  }
}
