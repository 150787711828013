import UserSectionController from '@/modules/Permissions/infrastructure/UserSection.controller'
import { EventBus } from '@/eventBus'
import { FiltersTypeUpperCase } from '@/modules/conversations/domain/models/Filters'
import { mapGetters, mapState } from 'vuex'
import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import KBSwitch from '@/shared/infrastructure/ui/inputs/select/KBSwitch.vue'
import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'
import KBButton from '@/shared/infrastructure/ui/buttons/KBButton.vue'
import KBText from '@/shared/infrastructure/ui/inputs/text/KBText.vue'

export default {
  name: 'ChatFiltersConfigRules',
  props: {
    inbox: {
      type: Object,
      required: true
    },
    selectedFilter: {
      required: false,
      default: null
    }
  },
  components: {
    KBSwitch,
    KeybeIcon,
    KBButton,
    KBText
  },
  data () {
    return {
      isCreating: false,
      newFilterName: '',
      KeybeIconType,
      adviserFilters: [
        {
          label: this.$t('selfConversations'),
          value: FiltersTypeUpperCase.MINE
        },
        /* {
          label: this.$t('waitingForAdvisor'),
          value: FiltersTypeUpperCase.UNASSIGNED
        }, */
        {
          label: this.$t('unansweredConversations'),
          value: FiltersTypeUpperCase.WAITING
        },
        {
          label: this.$t('closedByMe'),
          value: FiltersTypeUpperCase.CLOSED_BY_ME
        },
        {
          label: this.$t('favorites'),
          value: FiltersTypeUpperCase.FAVORITE
        }
      ],
      statusFilters: [
        {
          label: this.$t('allConversations'),
          value: FiltersTypeUpperCase.ALL
        },
        {
          label: this.$t('selfConversations'),
          value: FiltersTypeUpperCase.MINE
        },
        {
          label: this.$t('waitingForAdvisor'),
          value: FiltersTypeUpperCase.UNASSIGNED
        },
        {
          label: this.$t('unansweredConversations'),
          value: FiltersTypeUpperCase.WAITING
        },
        {
          label: this.$t('withBot'),
          value: FiltersTypeUpperCase.WITH_BOT
        },
        {
          label: this.$t('closedByMe'),
          value: FiltersTypeUpperCase.CLOSED_BY_ME
        },
        {
          label: this.$t('closedByAi'),
          value: FiltersTypeUpperCase.CLOSED_BY_AI
        },
        {
          label: this.$t('quickFilterFavorites'),
          value: FiltersTypeUpperCase.FAVORITE
        },
        {
          label: this.$t('spam'),
          value: FiltersTypeUpperCase.SPAM
        }
      ],
      channelFilters: [
        {
          label: this.$t('whatsapp'),
          value: FiltersTypeUpperCase.WHATSAPP
        },
        {
          label: this.$t('facebook'),
          value: FiltersTypeUpperCase.FACEBOOK
        },
        {
          label: this.$t('instagram'),
          value: FiltersTypeUpperCase.INSTAGRAM
        },
        {
          label: this.$t('web'),
          value: FiltersTypeUpperCase.WEB
        },
        {
          label: this.$t('sms'),
          value: FiltersTypeUpperCase.SMS
        }
      ],
      category: [],
      channels: [],
      userSectionController: new UserSectionController()
    }
  },
  methods: {
    createFilter () {
      const filter = {
        new: true,
        name: this.newFilterName,
        rules: this.category?.map(cat => {
          return {
            category: cat,
            channels: this.channels
          }
        }),
        appUUID: this.inbox?.appUUID,
        _id: `${this.inbox?.appUUID}-${this.newFilterName}`,
        favorite: false
      }
      this.isCreating = false
      this.newFilterName = ''
      if (this.category.length === 0) {
        EventBus.$emit('toast', 'error', this.$t('selectCategoryFilter'))
      } else {
        this.$emit('createFilter', filter)
        this.category = []
        this.channels = []
      }
    },
    isCategorySelected (filter) {
      return this.category?.includes(filter.value) || false
    },
    isChannelSelected (filter) {
      return this.channels?.includes(filter.value) || false
    },
    selectCategory (filter) {
      if (this.isCategorySelected(filter)) {
        this.category = this.category?.filter(item => item !== filter?.value)
      } else {
        this.category.push(filter.value)
      }
    },
    selectChannel (filter) {
      if (this.isChannelSelected(filter)) {
        this.channels = this.channels?.filter(item => item !== filter?.value)
      } else {
        this.channels.push(filter.value)
      }
    },
    updateFilter () {
      const filter = {
        ...this.selectedFilter,
        rules: this.category?.map(cat => {
          return {
            category: cat,
            channels: this.channels
          }
        })
      }
      this.$emit('updateFilter', filter)
    }
  },
  computed: {
    ...mapState('UserStore', ['user']),
    ...mapGetters('UserStore', ['isAgent']),
    filters () {
      if (this.isAgent) {
        return this.adviserFilters
      }
      return this.statusFilters
    },
    inboxName () {
      return this.inbox?.name || ''
    },
    totalParameters () {
      return this.category?.length + this.channels?.length || 0
    }
  },
  beforeDestroy () {
    this.userSectionController = null
  },
  watch: {
    selectedFilter (filter) {
      if (!filter) {
        this.category = []
        this.channels = []
      }

      if (filter?.rules) {
        this.category = filter?.rules?.map(rule => rule?.category)
        this.channels = filter?.rules[0]?.channels
      }
    }
  }
}
