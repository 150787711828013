import axios from 'axios'
import { dataToAddVariationToProduct, dataToGetVariations, dataToGetVariationsCreated, variationDTO } from '../../domain/models/VariationsController'
import { RestResponseFactory } from '@/models/apis/RestResponse'

export default class VariationRepository {
  async getVariationOptions ({ token, appUUID }: dataToGetVariations) {
    try {
      const response = await axios.get(
        `${process.env.VUE_APP_MICROSITES_URL}/microsites/product/variation/options/all/${appUUID}`,
        {
          headers: {
            Authorization: 'Bearer ' + token
          }
        }
      )
      return new RestResponseFactory().process({ response })
    } catch (e) {
      return new RestResponseFactory().process({ response: { status: 500 } })
    }
  }

  async getVariationsCreated ({ token, appUUID, productId }: dataToGetVariationsCreated) {
    try {
      const response = await axios.get(
        `${process.env.VUE_APP_MICROSITES_URL}/microsites/product/${appUUID}/${productId}`,
        {
          headers: {
            Authorization: 'Bearer ' + token
          }
        }
      )
      return new RestResponseFactory().process({ response })
    } catch (e) {
      return new RestResponseFactory().process({ response: { status: 500 } })
    }
  }

  async createVariationOption ({ token, variation }: variationDTO) {
    try {
      const response = await axios.post(
        `${process.env.VUE_APP_MICROSITES_URL}/microsites/product/variation/options`,
        variation,
        {
          headers: {
            Authorization: 'Bearer ' + token
          }
        }
      )
      return new RestResponseFactory().process({ response })
    } catch (e) {
      return new RestResponseFactory().process({ response: { status: 500 } })
    }
  }

  async createVariation ({ data, token }: dataToAddVariationToProduct) {
    try {
      const response = await axios.post(
        `${process.env.VUE_APP_MICROSITES_URL}/microsites/product/variation`,
        data,
        {
          headers: {
            Authorization: 'Bearer ' + token
          }
        }
      )

      return new RestResponseFactory().process({ response })
    } catch (e) {
      return new RestResponseFactory().process({ response: { status: 500 } })
    }
  }

  async updateVariation ({ data, token }: dataToAddVariationToProduct) {
    try {
      const response = await axios.put(
        `${process.env.VUE_APP_MICROSITES_URL}/microsites/product/variation/update`,
        data,
        {
          headers: {
            Authorization: 'Bearer ' + token
          }
        }
      )

      return new RestResponseFactory().process({ response })
    } catch (e) {
      return new RestResponseFactory().process({ response: { status: 500 } })
    }
  }
}
