import BikyConfigChatCorrectPronunciation from '../BikyConfigChatCorrectPronunciation/BikyConfigChatCorrectPronunciation.vue'

export default {
  name: 'BikyConfigChatTextMessage',
  components: {
    BikyConfigChatCorrectPronunciation
  },
  props: {
    message: {
      type: Object,
      required: true
    },
    isAudio: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      editingWord: '',
      editingWordIndex: -1
    }
  },
  computed: {
    text () {
      return this.message?.text
    },
    wordAndSpacesArray () {
      const words = this.text?.split(' ')
      const wordsAndSpaces = []
      for (let i = 0; i < words.length; i++) {
        wordsAndSpaces.push(words[i])
        if (i < words.length - 1) {
          wordsAndSpaces.push(' ')
        }
      }

      return wordsAndSpaces
    }
  },
  methods: {
    isEditing (index: number) {
      return this.editingWordIndex === index
    },
    setEditWord (word: string, index: number) {
      this.editingWord = word
      this.editingWordIndex = index
    },
    onCloseWordEditor () {
      this.editingWord = ''
      this.editingWordIndex = -1
    }
  }
}
