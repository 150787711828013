import { GenericResponse } from '@/models/apis/GenericResponse'
import { StatusGraphQLCodes } from '@/shared/infrastructure/graphql/StatusGrapqlCodes'

interface IOriginalError {
  statusCode: number;
  message: string[];
  error: string;
}

interface IExtensions {
  code: string;
  originalError: IOriginalError;
}

interface IGraphQLResponseError {
  message: string;
  extensions: IExtensions;
}

export interface IGraphQLResponse {
  data: any;
  errors: IGraphQLResponseError[];
}

export class GraphQLResponse extends GenericResponse {
  // Attributes
  errors: { [key: string]: any }[];

  constructor ({
    status,
    message,
    data,
    errors = []
  }: {
    status: boolean;
    message: string;
    data: any;
    errors?: { [key: string]: any }[];
  }) {
    super({ status, message, data })
    this.errors = errors
  }

  codeIsUnauthorized (): boolean {
    return this.errors.some(
      (element: { [key: string]: any }) =>
        element?.extensions?.code === StatusGraphQLCodes.UNAUTHENTICATED
    )
  }

  codeIsForbidden (): boolean {
    return this.errors.some(
      (element: { [key: string]: any }) =>
        element?.extensions?.code === StatusGraphQLCodes.FORBIDDEN
    )
  }
}

export class GraphQLResponseFactory {
  process ({ response }: { response: IGraphQLResponse }): GraphQLResponse {
    if (response?.errors && response?.errors.length > 0) {
      const errors: string[] = response?.errors.map(
        (error: IGraphQLResponseError) => {
          if (
            error?.extensions?.originalError?.message &&
            Array.isArray(error?.extensions?.originalError?.message)
          ) {
            return error?.extensions?.originalError?.message
              .map((message: string) => message)
              .join(', ')
          } else if (
            error?.extensions?.originalError?.message &&
            !Array.isArray(error?.extensions?.originalError?.message)
          ) return error?.extensions?.originalError?.message
          return error?.message
        }
      )
      return new GraphQLResponse({
        status: false,
        message: errors.join(', '),
        data: response?.data || {},
        errors: response?.errors || []
      })
    }
    return new GraphQLResponse({
      status: true,
      message: '',
      data: response?.data || {},
      errors: []
    })
  }
}
