import { IS3Controller } from '../domain/S3Controller'
import { S3Client } from '@aws-sdk/client-s3'
import { fromCognitoIdentityPool } from '@aws-sdk/credential-providers'
import { Upload } from '@aws-sdk/lib-storage'
const REGION = 'us-east-2'
const BUCKET = 'storage.keybeapi.com'

export default class S3Controller implements IS3Controller {
  getId (length: number): string {
    let result = ''
    const characters = 'abcdefghijklmnopqrstuvwxyz0123456789'
    const charactersLength = characters.length
    let counter = 0
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength))
      counter += 1
    }
    return result
  }

  async uploadFile ({
    file,
    route
  }): Promise<{
    url: string,
    name: string,
    extension: string,
    _id: string
  }> {
    const s3Client = new S3Client({
      region: REGION,
      credentials: fromCognitoIdentityPool({
        accountId: process.env.VUE_APP_AWS_ACCOUNT_ID,
        identityPoolId: process.env.VUE_APP_AWS_IDENTITY_POOL_ID,
        clientConfig: { region: REGION }
      })
    })
    const filaName = file?.name
    const extension = filaName?.split('.').pop()
    const nameWithoutExtension = filaName?.split('.').shift()
    const id = this.getId(8)
    const newName = `${id}.${extension}`
    const key = route + newName

    const input = {
      Bucket: BUCKET,
      Key: key,
      Body: file
    }
    console.log('Start....')

    try {
      const start = Date.now()
      const parallelUploads3 = new Upload({
        client: s3Client,
        params: input,
        queueSize: 10, // optional concurrency configuration
        partSize: 1024 * 1024 * 10, // optional size of each part, in bytes, at least 5MB
        leavePartsOnError: false // optional manually handle dropped parts
      })
      parallelUploads3.on('httpUploadProgress', (progress) => {
        console.log(progress)
      })
      await parallelUploads3.done()
      const s3Url = `s3://storage.keybeapi.com/${key}`
      const s3arn = `arn:aws:s3:::storage.keybeapi.com/${key}`
      const httpsURL = `https://storage.keybeapi.com/${key}`
      console.log({ s3Url, s3arn, Bucket: BUCKET, Key: key })
      const end = Date.now()
      console.log(`Execution time: ${end - start} ms`)
      return {
        url: httpsURL,
        name: nameWithoutExtension,
        extension,
        _id: id
      }
    } catch (e) {
      return null
    }
  }
}
