//
// Components
//
import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'

export default {
  props: {
    category: {
      type: Object,
      required: true
    }
  },
  components: {
    KeybeIcon
  },
  computed: {
    categoryName () {
      return this.category?.category || ''
    },
    deleteCategory (): void {
      this.$emit('delete-category', this.category)
    }
  }
}
