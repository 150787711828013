import TypeFilePDFIcon from '@/shared/infrastructure/ui/icons/SvgComponents/TypeFilePDFIcon.vue'
import TypeFilePNGIcon from '@/shared/infrastructure/ui/icons/SvgComponents/TypeFilePNGIcon.vue'
import TypeFileJPGIcon from '@/shared/infrastructure/ui/icons/SvgComponents/TypeFileJPGIcon.vue'
import TypeFileJPEGIcon from '@/shared/infrastructure/ui/icons/SvgComponents/TypeFileJPEGIcon.vue'
import TypeFileXLSXIcon from '@/shared/infrastructure/ui/icons/SvgComponents/TypeFileXLSXIcon.vue'
import TypeFileXLSIcon from '@/shared/infrastructure/ui/icons/SvgComponents/TypeFileXLSIcon.vue'
import TypeFileDOCIcon from '@/shared/infrastructure/ui/icons/SvgComponents/TypeFileDOCIcon.vue'
import TypeFileDOCXIcon from '@/shared/infrastructure/ui/icons/SvgComponents/TypeFileDOCXIcon.vue'
import TypeFilePPTIcon from '@/shared/infrastructure/ui/icons/SvgComponents/TypeFilePPTIcon.vue'
import TypeFilePPTXIcon from '@/shared/infrastructure/ui/icons/SvgComponents/TypeFilePPTXIcon.vue'
import TypeFilePPXIcon from '@/shared/infrastructure/ui/icons/SvgComponents/TypeFilePPXIcon.vue'
export default {
  components: {
    TypeFilePDFIcon,
    TypeFilePNGIcon,
    TypeFileJPGIcon,
    TypeFileJPEGIcon,
    TypeFileXLSXIcon,
    TypeFileXLSIcon,
    TypeFileDOCXIcon,
    TypeFilePPTIcon,
    TypeFilePPTXIcon,
    TypeFilePPXIcon,
    TypeFileDOCIcon
  },
  props: {
    type: {
      type: String
    }
  }
}
