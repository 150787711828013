import {
  Message,
  MessageSendersEnum
} from '@/modules/conversations/domain/models/Message/Message'
import WaveSurfer from 'wavesurfer.js'

export default {
  name: 'BikyConfigChatAudioMessage',
  props: {
    message: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      wavesurfer: null,
      progressString: '0:00',
      total: '0:00',
      stopped: true,
      playing: false,
      rateValue: 1
    }
  },
  beforeDestroy () {
    if (this.wavesurfer) {
      this.wavesurfer.destroy()
    }
  },
  mounted () {
    this.wavesurfer = WaveSurfer.create({
      container: `#${this.waveElementId}`,
      waveColor: '#8898AA',
      progressColor: '#3AF69B',
      backend: 'MediaElement',
      fillParent: true,
      barWidth: 1,
      barMinHeight: 1,
      height: 30,
      hideScrollbar: true,
      hideCursor: true
    })
    this.wavesurfer.load(this.audioUrl)

    // on ready event get duration
    this.wavesurfer.on('ready', () => {
      const duration = this.wavesurfer.getDuration()
      this.total = this.getTimeString(duration)
    })

    this.wavesurfer.on('finish', () => {
      this.stopped = true
      this.playing = false
    })

    this.wavesurfer.on('audioprocess', this.timeupdate)
  },
  computed: {
    messageComputed (): Message {
      return this.message
    },
    audioUrl (): string {
      return this.messageComputed?.media || ''
    },
    elementId (): string {
      return `BikyConfigChatAudioMessage--${
        this.messageComputed._id
      }`
    },
    waveElementId (): string {
      return `BikyConfigChatAudioMessage__wave--${
        this.parentMessageId ? this.parentMessageId : this.messageComputed._id
      }`
    },
    messageName (): string {
      return this.messageComputed?.adviser?.name || ''
    },
    messageImage (): string {
      if (this.messageComputed.sender === MessageSendersEnum.ADVISOR) {
        return this.messageComputed?.adviser?.image || ''
      }
      return ''
    },
    pipeRateValue (): string {
      const map: { [key: number]: string } = {
        0.75: '-0.5x',
        1: '1x',
        1.25: '0.5x',
        1.5: '2x'
      }

      return map[this.rateValue] || '1x'
    }
  },
  methods: {
    togglePlay (): void {
      this.playing = !this.playing
      this.stopped = false
      this.wavesurfer.playPause()
    },
    updateVisualAfterRate (): void {
      this.togglePlay()
      setTimeout(() => {
        this.togglePlay()
      }, 100)
    },
    timeupdate (e: any): void {
      this.progressString = this.getTimeString(e)
    },
    setRateValue (): void {
      const map: { [key: number]: number } = {
        0.75: 1,
        1: 1.25,
        1.25: 1.5,
        1.5: 0.75
      }

      this.rateValue = map[this.rateValue] || 1
    },
    setPlaybackRate (): void {
      if (this.wavesurfer) {
        this.setRateValue()
        this.wavesurfer.setPlaybackRate(this.rateValue, true)
        if (this.playing) this.updateVisualAfterRate()
      }
    },
    getTimeString (seconds: number): string {
      const roundedSeconds = Math.round(seconds)
      const minutes = Math.floor(roundedSeconds / 60)
      const secondsLeft = roundedSeconds - minutes * 60
      const secondsString = secondsLeft < 10 ? `0${secondsLeft}` : secondsLeft
      return `${minutes}:${secondsString}` || '0:00'
    }
  }
}
