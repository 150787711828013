import { BikyConfigUseCases } from '@/modules/bky/application/BikyConfigUseCases'
import { BikyMessageError } from '@/modules/bky/domain/BikyConfig'
import { getBikyMessageErrorsDTO } from '@/modules/bky/domain/BikyConfigController'
import { mapState } from 'vuex'
import BikyConfigController from '../../../controllers/BikyConfig.controller'
import BikyConfigErrorsItem from '../BikyConfigErrorsItem/BikyConfigErrorsItem.vue'
import BikyConfigHeader from '../BikyConfigHeader/BikyConfigHeader.vue'

export default {
  name: 'BikyConfigErrors',
  components: {
    BikyConfigHeader,
    BikyConfigErrorsItem
  },
  data () {
    return {
      bikyConfigController: new BikyConfigController(),
      loading: false,
      errors: [],
      selectedError: null
    }
  },
  computed: {
    ...mapState('AppStore', ['selectedApp']),
    ...mapState('UserStore', ['token', 'user']),
    totalErrors (): number {
      return this.errors.length || 0
    }
  },
  methods: {
    async getErrors (): Promise<void> {
      this.loading = true
      this.$emit('start-loading')
      const data: getBikyMessageErrorsDTO = {
        token: this.token,
        appUUID: this.selectedApp?.uuid
      }

      const errors = await BikyConfigUseCases.getBikyMessageErrors(this.bikyConfigController, data)
      this.errors = errors
      this.loading = false
      this.$emit('stop-loading')
    },
    removeError (index: number): void {
      this.errors.splice(index, 1)
    },
    selectError (error: BikyMessageError): void {
      if (this.isSelected(error)) {
        this.selectedError = null
        return
      }

      this.selectedError = error
    },
    isSelected (error: BikyMessageError): boolean {
      return this.selectedError?._id === error?._id
    }
  },
  mounted () {
    this.getErrors()
  },
  beforeDestroy () {
    this.$emit('stop-loading')
    BikyConfigUseCases.abortAll(this.bikyConfigController)
  }
}
