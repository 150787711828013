import LoadingNext from '@/modules/metrics/infrastructure/ui/components/generic/LoadingNext.vue'
import DateRange from '@/modules/metrics/infrastructure/ui/components/generic/DateRange.vue'
import RadialBarCharts from '@/modules/metrics/infrastructure/ui/components/conversations/RadialBarCharts/RadialBarCharts.vue'
export default {
  name: 'WaitingTime',
  props: [
    'range', 'dataComponent'
  ],
  components: {
    RadialBarCharts,
    DateRange,
    LoadingNext
  },
  data () {
    return {
      loadingTotalConversation: true,
      loadingNewDeals: true,
      loadingFirstTime: true,
      WaitingTime: [],
      series: [],
      chartMulti: null,
      chartSingle: null,
      msgServiceError: false,
      msgServiceErrorTwo: false
    }
  },
  watch: {
    loadingTotalConversation () {
      if (!this.loadingTotalConversation) {
        this.checkLoading()
      }
    },
    loadingNewDeals () {
      if (!this.loadingNewDeals) {
        this.checkLoading()
      }
    },
    loadingFirstTime () {
      if (!this.loadingFirstTime) {
        this.checkLoading()
      }
    }
  },
  computed: {
    closeModal: {
      get () {
        return this.$store.state.MetricsStore.closeModal || false
      },
      set (value) {
        this.$store.commit('MetricsStore/SET_EDITING_OBJECT', {
          key: 'closeModal',
          value
        })
      }
    },
    loading: {
      get () {
        return this.$store.state.MetricsStore.loading || false
      },
      set (value) {
        this.$store.commit('MetricsStore/SET_EDITING_OBJECT', {
          key: 'loading',
          value
        })
      }
    }
  },
  methods: {
    checkLoading () {
      if (!this.loadingTotalConversation && !this.loadingNewDeals && !this.loadingFirstTime) {
        this.loading = false
      }
    }
  },
  mounted () {
  }
}
