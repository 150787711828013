













































































































































































import KnowledgeBaseFiles from './KnowledgeBaseFiles'

export default KnowledgeBaseFiles
