
import { mapState, mapActions } from 'vuex'
import { EventBus } from '@/eventBus'
import { getButtonParams } from '@/components/conversations/utils/TemplateUtils'

export default {
  name: 'TemplateSelector',
  data () {
    return {
      selectedTemplate: {},
      search: '',
      whatsappIcon: `${process.env.VUE_APP_STORAGE_URL}/files/bluebird/conversation-module/whatsapp.svg`
    }
  },
  computed: {
    ...mapState('CampaingsStore', ['templates']),
    currentTemplate: {
      get () {
        return this.$store.state.CampaingsStore.currentTemplate || null
      },
      set (value) {
        this.$store.commit('CampaingsStore/SET_EDITING_OBJECT', {
          key: 'currentTemplate',
          value
        })
      }
    },
    templatesFiltered () {
      if (!this.search) {
        return this.templates
      } else {
        return this.templates.filter(template => {
          return template.templateMessage.toLowerCase().includes(this.search.toLowerCase())
        })
      }
    }
  },
  methods: {
    ...mapActions('CampaingsStore', ['setCurrentTemplate']),
    selectTemplate (template) {
      if (this.currentTemplate === template) {
        EventBus.$emit('toast', 'error', 'Seleccionaste la misma plantilla')
        return
      }
      if (!template.templateMessage) {
        EventBus.$emit('toast', 'error', this.$t('mustSelectATemplate'))
        return
      }
      this.setCurrentTemplate(template)
    },
    getParams (templateMessage) {
      const params = []
      const regex = /\{\{(.*?)\}\}/g
      let match
      while ((match = regex.exec(templateMessage)) !== null) {
        params.push({
          id: match[1],
          value: '',
          name: `{{${match[1]}}}`
        })
      }
      return params
    },
    parseTemplateMessage (templateMessage) {
      const data = getButtonParams(templateMessage)
      if (data) {
        this.parsedTemplateMessage = data.templateMessage
      }
      let template = templateMessage
      if (this.parsedTemplateMessage) {
        template = this.parsedTemplateMessage
      }
      const params = this.getParams(template)
      for (let i = 0; i < params.length; i++) {
        template = template.replace(params[i].name, `<span style="color:#01CFE4;">${`${this.$t('customTextField')}`}</span>`)
      }
      return `<span style="font-weight: 400; font-size: 14px; line-height: 23px;">${template}</span>`
    }
  }
}
