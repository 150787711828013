import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'
import KeybeButton from '@/modules/DesignSystem/infrastructure/components/KeybeButton/KeybeButton.vue'
import { dataToGetCSVExample, dataToStartCSVImport, dataToUploadCSV } from '@/modules/catalog/domain/models/CatalogImporterController'
import { mapState } from 'vuex'
import CatalogImporterUseCases from '@/modules/catalog/application/CatalogImporterUseCases'
import CatalogImporterController from '../../../controllers/CatalogImporter.controller'
import Loader from '@/shared/infrastructure/ui/Loader.vue'
import EmitToast from '@/utils/EmitToast'
export default {
  components: {
    KeybeIcon,
    KeybeButton,
    Loader
  },
  computed: {
    ...mapState('AppStore', ['selectedApp']),
    ...mapState('UserStore', ['token'])
  },
  data () {
    return {
      KeybeIconType,
      catalogImporterController: new CatalogImporterController(),
      dragging: false,
      loadingUpload: false,
      loadingExample: false
    }
  },
  methods: {
    openFileInput () {
      this.$refs.fileInput.click()
    },
    onFileSelect (e) {
      const files = e.target.files || []
      if (files.length === 1) this.uploadFile(files[0])
    },
    onDrop (e): void {
      const files = e.dataTransfer.files || []
      if (files.length === 1) this.uploadFile(files[0])
      this.dragging = false
    },
    onDragOver () {
      this.dragging = true
    },
    onDragLeave () {
      this.dragging = false
    },
    async uploadFile (file: File): Promise<void> {
      this.loadingUpload = true
      const data: dataToUploadCSV = {
        appUUID: this.selectedApp?.uuid,
        token: this.token,
        component: 'catalog',
        subcomponent: 'csv',
        file: file
      }
      const response = await CatalogImporterUseCases.uploadCSVFile(this.catalogImporterController, data)
      if (response.status) {
        const startData: dataToStartCSVImport = {
          appUUID: this.selectedApp?.uuid,
          token: this.token,
          s3Path: response.data.path + '/' + response.data.name || ''
        }
        const startResponse = await CatalogImporterUseCases.startCSVImport(this.catalogImporterController, startData)
        if (startResponse.status) {
          EmitToast.emitSuccess(this.$t('uploadSuccess'))
          setTimeout(async () => {
            await this.$emit('list')
          }, 1000)
        } else EmitToast.emitErrors(startResponse.message)
      } else EmitToast.emitErrors(response.message)
      setTimeout(() => {
        this.loadingUpload = false
      }, 1000)
    },
    async downloadExample (): Promise<void> {
      this.loadingExample = true
      const data: dataToGetCSVExample = {
        appUUID: this.selectedApp?.uuid,
        token: this.token
      }
      const response = await CatalogImporterUseCases.getCSVFileExample(this.catalogImporterController, data)
      if (response.status) {
        const url = response.url || ''
        const link = document.createElement('a')
        link.href = url
        link.download = 'CatalogImportExample.csv'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        EmitToast.emitSuccess(this.$t('downloadSuccess'))
      }
      this.loadingExample = false
    }
  }
}
