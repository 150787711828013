<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       fill="none"
       viewBox="0 0 8 10"><path :fill="color"
                                d="M2.804 9.988c-.054.003-.092.007-.13.007-.56 0-1.122.005-1.682 0-.319-.004-.6-.109-.799-.368-.149-.193-.194-.42-.193-.662.003-1.189.002-2.379.002-3.568L0 1.021C.001.671.108.373.41.17.584.052.782.002.99.002 2.827 0 4.666-.001 6.505.002c.602 0 .992.402.992 1.018.002 1.719 0 3.438 0 5.157 0 .929.006 1.858-.004 2.787a1.354 1.354 0 0 1-.101.504c-.15.353-.46.51-.822.522-.616.02-1.233.005-1.867.005-.003-.054-.008-.104-.008-.154 0-.443.003-.885-.001-1.328-.005-.56-.315-.92-.857-1.008-.318-.051-.742.163-.894.448-.096.18-.141.37-.14.574V9.99v-.001Zm-.933-8.12v.002c-.105 0-.209-.005-.313 0-.18.01-.29.1-.303.277-.017.224-.02.452-.006.676.013.209.122.297.333.302.183.005.365.003.548 0 .24-.001.358-.103.372-.34.011-.19.011-.382 0-.572-.013-.236-.134-.342-.37-.344H1.87ZM3.75 3.127h.26c.238-.003.357-.107.37-.343.01-.19.01-.383 0-.573-.012-.215-.125-.332-.321-.339a9.277 9.277 0 0 0-.625 0c-.18.007-.29.1-.301.28-.016.227-.018.459-.002.687.014.195.12.278.32.287.1.005.2.001.3.001H3.75Zm1.877-1.257c-.104 0-.209-.004-.313.002-.18.01-.29.1-.303.276-.017.224-.02.451-.006.675.014.214.125.3.346.304.178.003.356.002.534 0 .24-.002.36-.104.373-.34.011-.19.011-.383 0-.573-.013-.236-.134-.341-.37-.344h-.26ZM1.863 5.626h.274c.231-.003.35-.104.364-.334.013-.194.012-.39 0-.586-.012-.21-.122-.326-.311-.334a8.423 8.423 0 0 0-.639 0c-.176.007-.285.103-.297.282a4.86 4.86 0 0 0-.003.675c.014.204.12.288.325.297.096.004.191 0 .287 0ZM3.75 4.37c-.1 0-.2-.003-.3 0-.193.008-.306.098-.32.289-.017.22-.02.442-.004.662.014.215.124.301.344.305.183.003.365.003.548 0 .224-.003.338-.093.356-.312.017-.207.017-.417.002-.624-.017-.224-.137-.318-.365-.32h-.26Zm1.877 1.257h.26c.239-.003.358-.107.37-.343.011-.19.011-.382 0-.573-.011-.215-.124-.332-.32-.339a9.634 9.634 0 0 0-.625 0c-.18.007-.29.1-.301.279-.016.228-.018.459-.003.687.014.196.12.279.32.288.1.004.2 0 .3 0l-.001.001Z"/></svg>
</template>

<script>
export default {
  name: 'CompanyIcon',
  props: {
    color: {
      type: String,
      default: '#fff'
    }
  }
}
</script>

<style>

</style>
