import { ICatalogController, dataToCreateCatalog, dataToGetCatalog, dataToUpdateCatalog } from '../domain/CatalogController'

export default class CatalogUseCases {
  static getCatalog (controller: ICatalogController, data: dataToGetCatalog) {
    return controller.getCatalog(data)
  }

  static createCatalog (controller: ICatalogController, data: dataToCreateCatalog) {
    return controller.createCatalog(data)
  }

  static updateCatalog (controller: ICatalogController, data: dataToUpdateCatalog) {
    return controller.updateCatalog(data)
  }
}
