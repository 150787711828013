import operation from '@/utils/operations'
import moment from 'moment'
import FeesServices from '@/modules/configuration/application/services/FeesServices'
import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import { EventBus } from '@/eventBus'
// const KBSwitch from '@/shared/infrastructure/ui/inputs/select/KBSwitch.vue')
import KBButton from '@/shared/infrastructure/ui/buttons/KBButton.vue'
import KBText from '@/shared/infrastructure/ui/inputs/text/KBText.vue'
import KBSelect from '@/shared/infrastructure/ui/inputs/select/KBSelect.vue'
import CustomPagination from '@/components/shared/components/CustomPagination.vue'
import { CDPFieldsEnums } from '@/modules/CDP/shared/contacts/domain/enums/CDPFieldsEnums'
import { SalesFieldsEnum } from '@/modules/Sales/shared/domain/SalesFieldsEnum'
import { dataToGetTeamMembers, teamMemberGetter } from '@/modules/Config/TeamConfiguration/domain/TeamController'
import TeamUseCases from '@/modules/Config/TeamConfiguration/application/TeamUseCases'
import { TeamMember } from '@/modules/Config/TeamConfiguration/domain/models/TeamMember'
import TeamController from '@/modules/Config/TeamConfiguration/infrastructure/controllers/Team.controller'
export default {
  name: 'KBTableCommission',
  props: ['searchMain'],
  components: {
    KBButton,
    KBText,
    CustomPagination,
    KBSelect
  },
  data () {
    return {
      count: 0,
      itemsPerPage: 10,
      currentPage: 1,
      companies: [],
      users: [],
      categories: [],
      loading: false,
      userUpdate: {
        name: '',
        surname: '',
        email: '',
        phone: ''
      },
      visibleAlert: false,
      dataCreate: null,
      search: '',
      searchUser: '',
      dataCompany: [],
      administrators: [],
      visbleModal: false,
      activeItem: false,
      creating: false,
      KeybeIconType,
      newDomainDialog: false,
      selectItem: null,
      name: null,
      email: null,
      prefix: null,
      active: false,
      loadingResponsibles: false,
      customModalFees: false,
      editModalUsers: false,
      updateUserModal: false,
      teamController: new TeamController(),
      CDPFieldsEnums,
      SalesFieldsEnum
    }
  },
  async created () {
    await this.getBrands()
    await this.getCompany()
  },
  mounted () {
    this.getAppAdmins()
    this.getUsers()
    this.getCategories()
  },
  watch: {
    searchMain () {
      this.getBrands()
    },
    searchUser () {
      // if (this.searchUser && this.searchUser.length > 2) {
      //   this.getUSer()
      // }
    },
    onDetail () {
      this.getBrands()
    },
    search () {
      if (this.search && this.search.length > 2) {
        this.getCompany()
      } else {
        this.getCompany()
      }
    }
  },
  computed: {
    filteredList: function () {
      if (this.searchMain && this.companies.length > 0) {
        const temp = this.companies.length > 0 ? this.companies.filter((brand) =>
          brand.name.toLowerCase().includes(this.searchMain.toLowerCase())
        ) : []
        return temp
      } else {
        return this.companies
      }
    },
    onCategory: {
      get () {
        return this.$store.state.CommissionStore.onCategory || false
      },
      set (value) {
        this.$store.commit('CommissionStore/SET_EDITING_OBJECT', {
          key: 'onCategory',
          value
        })
      }
    },
    onDetail: {
      get () {
        return this.$store.state.CommissionStore.onDetail || false
      },
      set (value) {
        this.$store.commit('CommissionStore/SET_EDITING_OBJECT', {
          key: 'onDetail',
          value
        })
      }
    },
    idCompany: {
      get () {
        return this.$store.state.CommissionStore.idCompany || []
      },
      set (value) {
        this.$store.commit('CommissionStore/SET_EDITING_OBJECT', {
          key: 'idCompany',
          value
        })
      }
    }
  },
  methods: {
    async updateUser () {
      if (!this.userUpdate.email || !this.userUpdate.name) return
      this.loading = true
      try {
        const response = !this.userUpdate.create ? await FeesServices.updateUser(this.userUpdate) : await FeesServices.postCreateUser(this.userUpdate)
        if (response) {
          this.getUsers()
        }
      } catch {
      } finally {
        this.visibleAlert = false
        this.loading = false
      }
    },
    setPagination (pagination) {
      this.currentPage = pagination.page
      this.itemsPerPage = pagination.items
      if (!this.onDetail) {
        this.getBrands()
      }
    },
    async getCategories () {
      try {
        const response = await FeesServices.getCategoryFees('', { page: 1, limit: 100 })
        if (response) {
          const { data } = response.data
          this.categories = data
        }
      } catch {}
    },
    setSelect (e) {
      this.userUpdate.category = e
    },
    async getBrands () {
      try {
        this.loading = true
        this.companies = []
        const pagination = {
          page: this.currentPage,
          limit: this.itemsPerPage
        }
        const response = await FeesServices.getFees(this.searchMain, pagination)
        if (response) {
          const { data, total } = response.data
          this.companies = data
          this.count = total
        }
        this.loading = false
      } catch (error) {
        console.error(error)
        this.loading = false
      }
    },
    openModal (item) {
      this.dataCreate = item
      this.visbleModal = false
      this.visibleAlert = true
      this.updateUserModal = false
    },
    openModalUser (item) {
      this.userUpdate = item
      this.editModalUsers = false
      this.updateUserModal = true
      this.visibleAlert = true
    },
    openModalUserCreate () {
      this.userUpdate = {
        email: '',
        name: '',
        surname: '',
        phone: '',
        create: true,
        category: '',
        status: true
      }
      this.updateUserModal = true
      this.visibleAlert = true
    },
    async getUsers () {
      this.loading = true
      try {
        const response = await FeesServices.getFeesUser({ page: 1 })
        if (response) {
          this.users = response || []
        }
      } catch {
        this.loading = false
      } finally {
        this.loading = false
      }
    },
    async createApp () {
      try {
        if (!this.dataCreate) {
          return
        }
        this.loading = true
        const { appUUID, appName } = this.dataCreate
        const param = {
          name: appName,
          status: true,
          uuid: appUUID
        }
        await FeesServices.postCreateApp(param).then((response) => {
          if (response) {
            if (response.data) {
              EventBus.$emit('toast', 'success', this.$t('addCompanySuccess'))
              this.getBrands()
            }
            this.visibleAlert = false
            this.loading = false
          }
        })
      } catch (error) {
        this.loading = false
      }
    },
    async getCompany () {
      if (this.loading) {
        return
      }
      try {
        this.loading = true
        const pagination = {
          page: this.currentPage,
          limit: this.itemsPerPage
        }
        const response = await FeesServices.getCompany(this.search, pagination)
        if (response) {
          const { data } = response.data
          this.dataCompany = data
        }
        this.loading = false
      } catch (error) {
        this.loading = false
        console.error(error)
      }
    },
    async getAppAdmins () {
      try {
        this.loadingResponsibles = true
        const data: dataToGetTeamMembers = {
          page: 0,
          limit: 200,
          appId: this.selectedApp?.uuid,
          token: this.token,
          search: ''
        }
        const response: teamMemberGetter = await TeamUseCases.getTeamMembersFilterBiky(this.teamController, data)
        const teamMembers: TeamMember[] = response?.team || []
        this.administrators = this.administrators.concat(teamMembers)
        this.loadingResponsibles = false
      } catch (e) {
        this.loadingResponsibles = false
        console.error(e)
      }
    },
    callDetail (item) {
      if (item) {
        const { uuid } = item
        this.onDetail = true
        this.idCompany = uuid
      }
    },
    async saveConfig () {
      if (this.loading) {
      }
      // const response = await AdvisersService.updateAdviser(newAdviser)
      // if (response.status) {
      //   await this.$store.dispatch('AppStore/updateAdviser', newAdviser)
      // }
    },
    callModal (index, { name, email, prefix, inactive }) {
      this.name = name
      this.email = email
      this.prefix = prefix
      this.active = inactive
      this.selectItem = index
      this.newDomainDialog = !this.newDomainDialog
    },
    formatDate (date) {
      return moment(date).locale('es').format('ll')
    },
    areEqual (a, b) {
      return operation.areEqual(a, b)
    },
    positionClass (index, length) {
      if (index === 0) {
        return 'first-item'
      }
      return ''
    }
  }
}
