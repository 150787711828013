<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       fill="none"
       viewBox="0 0 690 522">
    <g stroke="#3AF69B"
       stroke-linecap="round"
       stroke-linejoin="round"
       clip-path="url(#a)">
      <path d="M-223.861 547.728c33.522-27.584 62.864-63.175 85.183-103.349 12.582-22.645 23.029-46.891 38.323-67.356 49.794-66.69 148.849-102.059 208.557-66.423 36.278 21.666 55.529 66.956 76.736 107.442 30.498 58.325 70.067 112.112 125.285 137.16"/>
      <path d="M-95.818 553.155c9.291-1.335 17.16-11.033 22.096-21.044 4.89-10.01 7.958-20.998 13.648-30.475 12.493-20.821 37.702-30.252 54.774-20.509 8.536 4.894 14.716 13.569 21.296 21.399 14.404 17.173 31.654 30.965 50.46 41.509"/>
      <path d="M162.176 551.82c-26.675-19.175-50.327-43.421-69.978-71.716-18.05-25.982-32.989-55.656-55.53-76.388-22.495-20.732-55.573-31.054-84.782-13.748-37.568 22.245-51.528 76.744-78.203 115.895-9.515 14.014-20.985 26.293-33.656 36.169"/>
      <path d="M-103.422 381.249c34.634-42.354 92.119-49.339 132.799-30.208 40.68 19.13 70.334 57.702 100.21 93.827 36.767 44.4 76.291 87.021 125.24 112.869"/>
      <path d="M-43.625 325.949C-2.1 290.847 40.047 245.023 71.79 197.731c23.341-34.746 43.97-72.695 72.957-101.035 28.987-28.34 69.889-45.201 98.965-27.36 12.36 7.607 21.252 20.509 29.521 33.544 43.703 69.136 76.291 147.837 108.702 226.183l87.895 212.614"/>
      <path d="M48.983 229.496c55.218-15.393 111.325 16.506 139.201 67.09 27.876 50.584 34.367 119.72 35.211 183.829"/>
      <path d="M93.265 164.675c36.278-14.058 68.422.178 97.276 21.711 47.837 35.725 79.27 94.718 97.542 159.182 18.273 64.465 24.275 134.625 28.632 204.562"/>
      <path d="M141.991 99.232c59.575-5.65 111.77 32.611 143.825 85.33 32.054 52.72 47.126 118.564 60.019 183.874s24.363 132.177 50.416 189.835M383.848 332.088c7.824 20.688 30.321 25.181 46.726 36.659 24.052 16.817 35.3 49.517 49.705 77.678 18.717 36.615 44.414 67.535 75.091 90.358 1.645 1.245 1.645 4.493 3.468 5.427 1.822.935 2.223-4.537.933-2.357M329.963 208.497c55.129 23.846 117.816 76.032 153.027 129.375 46.86 71.049 74.513 166.611 144.625 205.495"/>
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff"
              d="M0 0h690v522H0z"/>
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'LoginDecorationIcon.vue'
}
</script>

<style scoped>

</style>
