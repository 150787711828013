import KeybeAiConfigRepository from '@/modules/ai/infrastructure/repositories/KeybeAiConfig.repository'
import { IKeybeAiConfigController } from '../../domain/KeybeAiConfigController'
import { KeybeAiConfigStatesEnum } from '../../domain/KeybeAiConfig'
export default class KeybeAiConfigController implements IKeybeAiConfigController {
    repository: KeybeAiConfigRepository

    constructor () {
      this.repository = new KeybeAiConfigRepository()
    }

    async getSentimentsConfig ({ appId, advisorName, advisorId, token }) {
      const response = await this.repository.getSentimentsConfig({ appId, token })
      if (!response.status) {
        throw new Error('errorGettingSentimentsConfig')
      }
      if (!response?.data) {
        const putResponse = await this.repository.setSentimentsConfig({
          appId,
          data: {
            state: KeybeAiConfigStatesEnum.DISABLED
          },
          advisorId,
          advisorName,
          token
        })
        if (!putResponse.status) {
          throw new Error('errorSettingSentimentsConfig')
        }
        return putResponse?.data
      }
      return response.data
    }

    async setSentimentsConfig ({ appId, state, advisorName, advisorId, token }) {
      const response = await this.repository.setSentimentsConfig({
        appId,
        data: {
          state
        },
        advisorId,
        advisorName,
        token
      })
      if (!response.status) {
        throw new Error('errorSettingSentimentsConfig')
      }
      return response?.data
    }

    async getActionSuggestionsConfig ({ appId, token }) {
      const response = await this.repository.getActionSuggestionsConfig({ appId, token })
      if (!response.status) {
        throw new Error('errorGettingActionSuggestionsConfig')
      }

      if (!response?.data) {
        const putResponse = await this.repository.setActionSuggestionsConfig({
          appId,
          data: {
            state: KeybeAiConfigStatesEnum.ENABLED
          },
          token
        })
        if (!putResponse.status) {
          throw new Error('errorSettingActionSuggestionsConfig')
        }
        return putResponse?.data
      }
      return response?.data
    }

    async setActionSuggestionsConfig ({ appId, state, token }) {
      const response = await this.repository.setActionSuggestionsConfig({
        appId,
        data: {
          state
        },
        token
      })
      if (!response.status) {
        throw new Error('errorSettingActionSuggestionsConfig')
      }
      return response?.data
    }

    async getMessageSuggestionsConfig ({ appId, advisorName, advisorId, token }) {
      const response = await this.repository.getMessageSuggestionsConfig({ appId, token })
      if (!response.status) {
        throw new Error('errorGettingMessageSuggestionsConfig')
      }

      if (!response?.data) {
        const putResponse = await this.repository.setMessageSuggestionsConfig({
          appId,
          data: {
            state: KeybeAiConfigStatesEnum.DISABLED
          },
          advisorId,
          advisorName,
          token
        })

        if (!putResponse.status) {
          throw new Error('errorSettingMessageSuggestionsConfig')
        }
        return putResponse?.data
      }
      return response?.data
    }

    async setMessageSuggestionsConfig ({ appId, state, advisorName, advisorId, token }) {
      const response = await this.repository.setMessageSuggestionsConfig({
        appId,
        data: {
          state
        },
        advisorId,
        advisorName,
        token
      })
      if (!response.status) {
        throw new Error('errorSettingMessageSuggestionsConfig')
      }
      return response?.data
    }
}
