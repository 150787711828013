import { AdminTypes } from '@/components/configuration/administrators/enums/AdminTypes'
import { UserTypes } from '@/modules/Permissions/domain/UserTypes'
import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import KeybePhone from '@/modules/DesignSystem/infrastructure/components/KeybePhone/KeybePhone.vue'
import KeybeSelect from '@/modules/DesignSystem/infrastructure/components/KeybeSelect/KeybeSelect/KeybeSelect.vue'
import KeybeText from '@/modules/DesignSystem/infrastructure/components/KeybeText/KeybeText.vue'
import { CDPFieldsEnums } from '@/modules/CDP/shared/contacts/domain/enums/CDPFieldsEnums'
import { mapGetters, mapState } from 'vuex'
import AppsUseCases from '@/modules/Apps/application/AppsUseCases'
import AppsController from '@/modules/Apps/infrastructure/controllers/Apps.controller'
import KeybeAutocomplete from '@/modules/DesignSystem/infrastructure/components/KeybeAutocomplete/KeybeAutocomplete/KeybeAutocomplete.vue'
import KeybeDate from '@/modules/DesignSystem/infrastructure/components/KeybeDate/KeybeDate.vue'
import { debounce } from 'lodash'
import { citiesGetter, countriesGetter, dataToGetCities, dataToGetCountries, dataToGetStates, statesGetter } from '@/shared/infrastructure/ui/details/sections/Location/domain/LocationController'
import LocationUseCases from '@/shared/infrastructure/ui/details/sections/Location/application/LocationUseCases'
import LocationController from '@/shared/infrastructure/ui/details/sections/Location/infrastructure/controllers/Location.controller'
import Loader from '@/shared/infrastructure/ui/Loader.vue'
import KBSwitch from '@/shared/infrastructure/ui/inputs/select/KBSwitch.vue'
export default {
  components: {
    KeybeText,
    KeybePhone,
    KeybeSelect,
    KeybeAutocomplete,
    KeybeDate,
    Loader,
    KBSwitch
  },
  props: {
    userUpdate: {
      type: Object
    }
  },
  computed: {
    ...mapState('AppStore', ['selectedApp']),
    ...mapState('UserStore', ['user', 'token']),
    ...mapGetters('UserStore', ['isSuperAdmin', 'isAdmin', 'isAdminOrAbove', 'isAdmin', 'isCoordinator', 'isAgent']),
    ...mapGetters('AppStore', ['isKeybeApp']),
    isLoggedIn () {
      return this.userUpdate && this.user.uuid === this.userUpdate.uuid
    },
    canUpdateType () {
      if (this.userUpdate?.ua_role) {
        const isSelfAppOwner: boolean = AppsUseCases.isUserOwner(this.appsController, { appUUID: this.selectedApp?.uuid, user: this.user })
        const isUpdatingSuperAdmin: boolean = this.userUpdate.ua_role === AdminTypes.SUPER_ADMIN
        const isUpdatingAdmin: boolean = this.userUpdate.ua_role === AdminTypes.ADMIN
        return !this.userUpdate || (
          this.isAdminOrAbove && (
            this.isLoggedIn ||
            (
              this.isSuperAdmin &&
              !isUpdatingSuperAdmin
            ) ||
            (
              (
                isSelfAppOwner &&
                !isUpdatingSuperAdmin
              ) ||
              (
                this.isAdmin &&
                !isUpdatingAdmin &&
                !isUpdatingSuperAdmin
              )
            )
          )
        )
      } else {
        return !this.userUpdate || (
          this.isAdminOrAbove && (
            this.isLoggedIn ||
            (
              this.isSuperAdmin &&
              this.userUpdate.type !== (AdminTypes.SUPER_ADMIN)
            ) ||
            (
              this.isAdmin &&
              this.userUpdate.type !== (AdminTypes.ADMIN) &&
              this.userUpdate.type !== (AdminTypes.SUPER_ADMIN)
            )
          )
        )
      }
    },
    canEditUser () {
      if (this.userUpdate) {
        if (this.userUpdate?.ua_role) {
          return ((this.isSuperAdmin && this.userUpdate.uuid !== this.user.uuid) &&
               (this.isSuperAdmin && this.userUpdate.ua_role !== AdminTypes.SUPER_ADMIN)) ||
               ((this.isAdmin && this.userUpdate.uuid !== this.user.uuid) &&
               (this.isAdmin && this.userUpdate.ua_role !== AdminTypes.ADMIN && this.userUpdate.ua_role !== AdminTypes.SUPER_ADMIN))
        } else {
          return ((this.isSuperAdmin && this.userUpdate.uuid !== this.user.uuid) &&
               (this.isSuperAdmin && this.userUpdate.type !== AdminTypes.SUPER_ADMIN)) ||
               ((this.isAdmin && this.userUpdate.uuid !== this.user.uuid) &&
               (this.isAdmin && this.userUpdate.type !== AdminTypes.ADMIN && this.userUpdate.type !== AdminTypes.SUPER_ADMIN))
        }
      } else return true
    },
    typeOptions () {
      const isSelfAppOwner: boolean = AppsUseCases.isUserOwner(this.appsController, { appUUID: this.selectedApp?.uuid, user: this.user })
      const baseRoles = [
        { text: this.$t(AdminTypes.AGENT), value: AdminTypes.AGENT },
        { text: this.$t(AdminTypes.COORDINATOR), value: AdminTypes.COORDINATOR },
        { text: this.$t(AdminTypes.TRAINER), value: AdminTypes.TRAINER },
        { text: this.$t(AdminTypes.ADMIN), value: AdminTypes.ADMIN }
      ]

      if (this.isSuperAdmin || this.isKeybeApp) {
        baseRoles.push({ text: this.$t(AdminTypes.SUPER_ADMIN), value: AdminTypes.SUPER_ADMIN })
      }

      if (
        this.userUpdate.ua_role === AdminTypes.ADMIN &&
        isSelfAppOwner &&
        !this.isLoggedIn
      ) {
        baseRoles.push({ text: this.$t(`admintype-${AdminTypes.OWNER}`), value: AdminTypes.OWNER })
      }
      return baseRoles
    },
    isAdminOrCoordinator () {
      return this.memberInput && (this.memberInput.userType === UserTypes.ADMIN || this.memberInput.userType === UserTypes.COORDINATOR)
    },
    isViewAllSpaces () {
      return this.memberInput && this.memberInput.isViewAllSpaceChat !== 0
    }
  },
  async mounted () {
    this.loading = true
    this.getCountries()
    if (this.userUpdate) {
      await this.setUser()
    }

    setTimeout(() => {
      this.loading = false
    }, 1000)
  },
  data () {
    return {
      memberInput: {
        name: '',
        lastName: '',
        country: '',
        countryCode: null,
        email: '',
        type: '',
        isSuperAdmin: '',
        uuid: '',
        appUUID: '',
        userType: '',
        administratorId: '',
        address_country: '',
        address_city: '',
        address_province: '',
        address_zone: '',
        birthday: '',
        isViewAllSpaceChat: false
      },
      rerender: 0,
      countrySearch: '',
      stateSearch: '',
      citySearch: '',
      selectedCountry: '',
      selectedState: '',
      selectedCity: '',
      countries: [],
      states: [],
      cities: [],
      KeybeIconType,
      userPhone: '',
      CDPFieldsEnums,
      loading: false,
      loadingCountries: false,
      loadingStates: false,
      loadingCities: false,
      locationController: new LocationController(),
      appsController: new AppsController()
    }
  },
  methods: {
    setUser () {
      // eslint-disable-next-line no-undef
      this.memberInput = {
        name: this.userUpdate.name || '',
        lastName: this.userUpdate.lastName || '',
        country: this.userUpdate.country || '',
        countryCode: this.userUpdate.countryCode || '',
        email: this.userUpdate.email || '',
        isSuperAdmin: this.userUpdate.isSuperAdmin || 0,
        uuid: this.userUpdate.uuid || '',
        appUUID: this.userUpdate.appUUID || '',
        administratorId: this.userUpdate.administratorId || '',
        address_country: this.userUpdate.address_country || '',
        address_city: this.userUpdate.address_city || '',
        address_province: this.userUpdate.address_province || '',
        address_zone: this.userUpdate.address_zone || '',
        birthday: this.userUpdate.birthday || ''
      }
      const currentUserApp = this.userUpdate?.userApps?.find((userApp: any) => userApp.appUUID === this.selectedApp?.uuid)
      this.memberInput.userType = currentUserApp ? currentUserApp?.role : this.userUpdate?.ua_role || ''
      this.memberInput.type = this.memberInput.userType
      this.memberInput.isViewAllSpaceChat = currentUserApp?.isViewAllSpaceChat || 0
      if (this.userUpdate.phone) {
        this.userPhone = '+' + this.userUpdate.countryCode + this.userUpdate.phone
      }

      this.selectedCountry = this.userUpdate?.address_country || ''
      this.selectedState = this.userUpdate?.address_province || ''
      this.selectedCity = this.userUpdate?.address_city || ''
    },
    setCountrySearch: debounce(function (e: string): void {
      this.countrySearch = e
      this.getCountries()
    }, 600),
    setStateSearch: debounce(function (e: string): void {
      this.stateSearch = e
      this.getStates()
    }, 600),
    setCitySearch: debounce(function (e: string): void {
      this.citySearch = e
      this.getCities()
    }, 600),
    setCountrySelect (e: string): void {
      if (e) this.selectedCountry = e
      this.memberInput.address_country = this.selectedCountry
      this.stateSearch = ''
      this.citySearch = ''
      this.selectedState = ''
      this.selectedCity = ''
      this.getStates()
      this.$emit('input', this.memberInput)
    },
    setStateSelect (e: string): void {
      if (e) this.selectedState = e
      this.memberInput.address_province = this.selectedState
      this.citySearch = ''
      this.selectedCity = ''
      this.getCities()
      this.$emit('input', this.memberInput)
    },
    setCitySelect (e: string): void {
      if (e) this.selectedCity = e
      this.memberInput.address_city = this.selectedCity
      this.$emit('input', this.memberInput)
    },
    async getCountries (): Promise<void> {
      this.loadingCountries = true
      const data: dataToGetCountries = {
        token: this.token,
        app: this.selectedApp?.uuid || '',
        query: {
          limit: 20,
          offset: 0,
          search: this.countrySearch
        }
      }
      const response: countriesGetter = await LocationUseCases.getCountries(
        this.locationController,
        data
      )
      if (response?.status) {
        this.countries = response?.countries
        this.getStates()
      }
      this.loadingCountries = false
    },
    async getStates (): Promise<void> {
      this.loadingStates = true
      this.states = []
      const variables: dataToGetStates = {
        app: this.selectedApp?.uuid,
        token: this.token,
        countryCode: this.selectedCountry,
        query: {
          limit: 20,
          offset: 0,
          search: this.stateSearch
        }
      }
      const response: statesGetter = await LocationUseCases.getStates(
        this.locationController,
        variables
      )
      if (response?.status) {
        this.states = response?.states
      }
      this.loadingStates = false
    },
    async getCities (): Promise<void> {
      this.loadingCities = true
      this.cities = []

      const variables: dataToGetCities = {
        app: this.selectedApp?.uuid,
        token: this.token,
        countryCode: this.selectedCountry,
        provinceCode: this.selectedState,
        query: {
          limit: 20,
          offset: 0,
          search: this.citySearch
        }
      }
      const response: citiesGetter = await LocationUseCases.getCities(
        this.locationController,
        variables
      )
      if (response?.status) {
        this.cities = response?.cities
      }
      this.loadingCities = false
    },
    setPhone (e) {
      this.memberInput.country = e.code
      this.memberInput.countryCode = +e.number
      this.memberInput.phone = e.phone

      this.checkPhoneChange()
    },
    checkPhoneChange () {
      let changed = false
      if (this.userUpdate && this.userUpdate.phone !== this.memberInput.phone) changed = true
      else changed = false
      this.$emit('input', this.memberInput)
      this.$emit('phoneChange', changed)
    },
    checkEmailChange () {
      let changed = false
      if (this.userUpdate && this.userUpdate.email !== this.memberInput.email) changed = true
      else changed = false
      this.$emit('emailChange', changed)
      this.$emit('input', this.memberInput)
    },
    checkTypeChange () {
      let changed = false
      if (this.userUpdate && this.userUpdate.userType !== this.memberInput.userType) changed = true
      else if (this.userUpdate.userType === AdminTypes.OWNER) changed = true
      else changed = false
      this.$emit('typeChange', changed)
      this.$emit('input', this.memberInput)
    },
    setSelect (e) {
      if (e === AdminTypes.SUPER_ADMIN) this.memberInput.isSuperAdmin = 1
      else this.memberInput.isSuperAdmin = 0
      this.memberInput.userType = e
      this.memberInput.type = e
      this.memberInput.ua_role = e
      this.checkTypeChange()
    },
    setSpaces (e: boolean): void {
      this.memberInput.isViewAllSpaceChat = e ? 1 : 0
      this.$emit('input', this.memberInput)
    }
  }
}
