export const FormsStore = {
  namespaced: true,
  state: {
    segments: [],
    lastSavedPage: null,
    scrollPosition: null,
    formPhone: {},
    formEmail: {},
    formSendSMS: false,
    formSendEmail: false
  },
  mutations: {
    SET_FORMS (state, forms) {
      state.forms = forms
    },
    SET_LAST_PAGE (state, lastPage) {
      state.lastSavedPage = lastPage
    },
    SET_SCROLL_POSITION (state, position) {
      state.scrollPosition = position
    },
    SET_FORM_PHONE (state, phone) {
      state.formPhone = phone
    },
    SET_FORM_EMAIL (state, email) {
      state.formEmail = email
    },
    SET_SEND_EMAIL (state, sendEmail) {
      state.formSendEmail = sendEmail
    },
    SET_SEND_SMS (state, sendSMS) {
      state.formSendSMS = sendSMS
    }
  },
  actions: {
  }
}
