import operation from '@/utils/operations'
// TODO: Migrate to new structure
import DevicesService from '@/components/configuration/Services/DevicesService'
import moment from 'moment/moment'
import { EventBus } from '@/eventBus'
import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import Colors from '@/shared/domain/colors/Color'
import Loader from '@/shared/infrastructure/ui/Loader.vue'
import KBButton from '@/shared/infrastructure/ui/buttons/KBButton.vue'
import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'
import KBExpansionPanel from '@/shared/infrastructure/ui/menus/KBExpansionPanel.vue'
export default {
  name: 'SessionHistory',
  components: {
    Loader,
    KBButton,
    KeybeIcon,
    KBExpansionPanel
  },
  computed: {
    Colors () {
      return Colors
    }
  },
  created () {
    this._getDevices()
  },
  data () {
    return {
      logs: [],
      count: 0,
      page: 0,
      limit: 25,
      loadingLogs: false,
      loadingLocation: false,
      operation,
      isLoading: false
    }
  },
  methods: {
    async _getDevices () {
      try {
        this.isLoading = true
        const pagination = {
          page: 0,
          limit: 8
        }
        const response = await DevicesService.getDevices(pagination)
        if (operation.areEqual(response?.data?.status, 'SUCCESS')) {
          this.logs = response?.data?.data?.devices
          this.count = response?.data?.data?.total
          if (this.page === 0) {
            const response2 = await DevicesService.getActiveSessions(pagination)
            if (operation.areEqual(response2?.data?.status, 'SUCCESS')) {
              const devices = response2?.data?.data?.devices?.map(device => {
                device.active = true
                return device
              })
              this.logs = [...devices, ...this.logs]
              this.count = response2?.data?.data?.total + this.count
            }
          }
          this.getLocation()
        }
      } catch (e) {
        console.error(e)
        this.isLoading = false
      }
    },
    async getLocation () {
      this.isLoading = true
      for (const log of this.logs) {
        const response = await DevicesService.getLocationByIP(log.ip_client)
        if (response?.data?.city && response?.data?.country) log.location = response.data.city + ', ' + response.data.country
        else log.location = ' - '
      }
      this.isLoading = false
    },
    formatDevice (log) {
      if (log?.device?.device?.brand) return log.device.device.brand
      else return log?.device_type
    },
    formatDeviceIcon (log) {
      if (!log?.device) {
        return KeybeIconType.LAPTOP
      }
      const type = log?.device?.device?.type
      if (operation.areEqual(type, 'desktop')) return KeybeIconType.LAPTOP
      else return KeybeIconType.PHONE
    },
    formatDeviceClass (log) {
      if (!log?.device) {
        return 'SessionHistory__table__table--row--logo--pc'
      }
      const type = log?.device?.device?.type
      if (operation.areEqual(type, 'desktop')) return 'SessionHistory__table--row--logo--pc'
      else return 'SessionHistory__table__table--row--logo--phone'
    },
    formatDate (date) {
      return moment(date).format('DD/MM/YYYY')
    },
    formatBrowserIcon (browser) {
      if (operation.areEqual(browser, 'Opera')) return this.opera
      else if (operation.areEqual(browser, 'Chrome')) return this.chrome
      else if (operation.areEqual(browser, 'Chrome Mobile')) return this.chrome
      else if (operation.areEqual(browser, 'Chrome Mobile iOS')) return this.chrome
      else if (operation.areEqual(browser, 'Microsoft Edge')) return this.edge

      else return ''
    },
    setPagination (pagination) {
      this.page = (pagination.page - 1)
      this.limit = pagination.items
      this._getDevices()
    },
    async logout (log) {
      this.isLoading = true
      const response = await DevicesService.logoutDevice(log.uuid)
      if (operation.areEqual(response.data.status, 'SUCCESS')) {
        EventBus.$emit('toast', 'success', this.$t('loggedOutSuccessfully'))
        await this._getDevices()
      } else {
        EventBus.$emit('toast', 'error', this.$t('unexpectedError'))
        this.isLoading = false
      }
    }
  }
}
