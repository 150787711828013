<template>
  <svg :width="width"
       :height="height"
       viewBox="0 0 17 17"
       fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <path d="M10.625 3.1875H6.375L4.80117 0.826758C4.56543 0.471484 4.81777 0 5.24277 0H11.7572C12.1822 0 12.4346 0.471484 12.1988 0.826758L10.625 3.1875ZM6.375 4.25H10.625C10.7512 4.33301 10.8939 4.42598 11.0566 4.52891C12.9393 5.73418 17 8.33066 17 13.8125C17 15.5723 15.5723 17 13.8125 17H3.1875C1.42773 17 0 15.5723 0 13.8125C0 8.33066 4.06074 5.73418 5.94336 4.52891C6.10273 4.42598 6.24883 4.33301 6.375 4.25ZM9.16406 7.17188C9.16406 6.80664 8.86523 6.50781 8.5 6.50781C8.13477 6.50781 7.83594 6.80664 7.83594 7.17188V7.63672C7.58359 7.69316 7.33125 7.78281 7.09883 7.91895C6.6373 8.19453 6.23887 8.67598 6.24219 9.37656C6.24551 10.0506 6.64062 10.4756 7.0623 10.7279C7.42754 10.9471 7.88242 11.0865 8.24434 11.1928L8.30078 11.2094C8.71914 11.3355 9.02461 11.4352 9.23047 11.5646C9.39981 11.6709 9.42305 11.7439 9.42637 11.8369C9.42969 12.0029 9.3666 12.1025 9.23047 12.1855C9.06445 12.2885 8.80215 12.3516 8.51992 12.3416C8.15137 12.3283 7.80605 12.2121 7.35449 12.0594C7.27812 12.0328 7.19844 12.0062 7.11543 11.9797C6.7668 11.8635 6.3916 12.0527 6.27539 12.398C6.15918 12.7434 6.34844 13.1219 6.69375 13.2381C6.75684 13.258 6.82656 13.2812 6.89629 13.3078C7.17188 13.4041 7.49062 13.5137 7.83262 13.5867V14.0781C7.83262 14.4434 8.13144 14.7422 8.49668 14.7422C8.86191 14.7422 9.16074 14.4434 9.16074 14.0781V13.6199C9.42637 13.5635 9.69199 13.4705 9.93106 13.3211C10.4059 13.0256 10.7645 12.5209 10.7545 11.827C10.7445 11.1529 10.366 10.718 9.93769 10.4457C9.55586 10.2066 9.07773 10.0605 8.70586 9.94766L8.68262 9.94102C8.25762 9.81152 7.95547 9.71855 7.74297 9.59238C7.57031 9.48945 7.56699 9.42969 7.56699 9.36992C7.56699 9.24707 7.61348 9.1541 7.77285 9.06113C7.95215 8.95488 8.22441 8.8918 8.48672 8.89512C8.80547 8.89844 9.15742 8.96816 9.52266 9.06777C9.87793 9.16074 10.2398 8.95156 10.3361 8.59629C10.4324 8.24102 10.2199 7.8791 9.86465 7.78281C9.64883 7.72637 9.40977 7.66992 9.16406 7.62676V7.17188Z"
          :fill="color"/>
  </svg>
</template>
<script>
export default {
  name: 'OfficesBusiness',
  props: {
    width: {
      type: Number,
      default: 7
    },
    height: {
      type: Number,
      default: 11
    },
    color: {
      type: String,
      default: '#B6BEC7'
    }
  }
}
</script>
