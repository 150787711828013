import { Catalog } from '../../domain/Catalog'
import { ICatalogController, dataToCreateCatalog, dataToGetCatalog, dataToUpdateCatalog } from '../../domain/CatalogController'
import CatalogRepository from '../repositories/Catalog.repository'

export default class CatalogController implements ICatalogController {
  repository: CatalogRepository

  constructor () {
    this.repository = new CatalogRepository()
  }

  async getCatalog (data: dataToGetCatalog): Promise<Catalog> {
    const response = await this.repository.getCatalog(data)
    if (!response.status) {
      throw new Error('Error getting catalog')
    }
    return response?.data?.data[0] || {}
  }

  async createCatalog (data: dataToCreateCatalog): Promise<any> {
    const response = await this.repository.createCatalog(data)
    if (!response.status) {
      throw new Error('Error creating catalog')
    }
    return response
  }

  async updateCatalog (data: dataToUpdateCatalog): Promise<any> {
    const response = await this.repository.updateCatalog(data)
    if (!response.status) {
      throw new Error('Error updating catalog')
    }
    return response
  }
}
