














export default {
  name: 'MalletIcon',
  props: {
    color: {
      type: String,
      default: 'white'
    }
  }
}
