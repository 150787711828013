import {
  IAppsController,
  dataToGetExtendedFields,
  extendedFieldGetter,
  dataToGetAppFields,
  dataToHideAppField,
  appFieldCreator,
  dataToGetApps,
  dataToSelectApp,
  dataToUpdateApp,
  appUpdater,
  dataToGetApp,
  dataToSetDomain,
  dataToDeleteDomain,
  dataToCreateAppByCompany,
  appCreatorCompany,
  categoriesGetter,
  dataToVerifiedDomain,
  dataToCheckDomain,
  dataToCheckUserOwner
} from '../domain/AppsController'

export default class AppsUseCases {
  static abort (controller: IAppsController) : void {
    controller.abort()
  }

  static getExtendedFields (controller: IAppsController, data: dataToGetExtendedFields) : Promise<extendedFieldGetter> {
    return controller.getExtendedFields(data)
  }

  static getAppFields (controller: IAppsController, data: dataToGetAppFields) : Promise<any> {
    return controller.getAppFields(data)
  }

  static hideField (controller: IAppsController, data: dataToHideAppField) : Promise<appFieldCreator> {
    return controller.hideField(data)
  }

  static getCDPFields (controller: IAppsController, data: dataToGetAppFields) : Promise<any> {
    return controller.getCDPFields(data)
  }

  static getApps (controller: IAppsController, data: dataToGetApps) : Promise<any> {
    return controller.getApps(data)
  }

  static getApp (controller: IAppsController, data: dataToGetApp) : Promise<any> {
    return controller.getApp(data)
  }

  static setDomain (controller: IAppsController, data: dataToSetDomain) : Promise<any> {
    return controller.setDomain(data)
  }

  static deleteDomain (controller: IAppsController, data: dataToDeleteDomain) : Promise<any> {
    return controller.deleteDomain(data)
  }

  static verifiedDomain (controller: IAppsController, data: dataToVerifiedDomain) : Promise<any> {
    return controller.verifiedDomain(data)
  }

  static checkDomain (controller: IAppsController, data: dataToCheckDomain) : Promise<any> {
    return controller.checkDomain(data)
  }

  static selectApp (controller: IAppsController, data: dataToSelectApp) : Promise <any> {
    return controller.selectApp(data)
  }

  static updateAppInfo (controller: IAppsController, data: dataToUpdateApp) : Promise<appUpdater> {
    return controller.updateAppInfo(data)
  }

  static createAppByCompany (controller: IAppsController, data: dataToCreateAppByCompany) : Promise<appCreatorCompany> {
    return controller.createAppByCompany(data)
  }

  static getCategories (controller: IAppsController) : Promise<categoriesGetter> {
    return controller.getCategories()
  }

  static canVerifyProfile (controller: IAppsController, app: string) : Promise<boolean> {
    return controller.canVerifyProfile(app)
  }

  static isUserOwner (controller: IAppsController, data: dataToCheckUserOwner) : boolean {
    return controller.isUserOwner(data)
  }

  static isUserReadonly (controller: IAppsController, data: dataToCheckUserOwner) : boolean {
    return controller.isUserReadonly(data)
  }

  static async appHasFlows1 (controller: IAppsController, data: dataToGetApp) : Promise<boolean> {
    return controller.appHasFlows1(data)
  }

  static getServiceAgreementUrl (controller: IAppsController, language: string) : string {
    return controller.getServiceAgreementUrl(language)
  }
}
