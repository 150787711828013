import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import Colors from '@/shared/domain/colors/Color'
import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'

export default {
  name: 'CreateTaskHeader',
  components: {
    KeybeIcon
  },
  data () {
    return {
      KeybeIconType,
      Colors
    }
  },
  methods: {
    goBack () {
      this.$emit('back')
    },
    create () {
      this.$emit('create')
    }
  }
}
