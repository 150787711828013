import { EventBus } from '@/eventBus'
import MicrositesService from '@/services/MicrositesService'

// components
import KeybeText from '@/modules/DesignSystem/infrastructure/components/KeybeText/KeybeText.vue'
import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'
import KeybeSelect from '@/modules/DesignSystem/infrastructure/components/KeybeSelect/KeybeSelect/KeybeSelect.vue'
import { mapState } from 'vuex'
import { CDPFieldsEnums } from '@/modules/CDP/shared/contacts/domain/enums/CDPFieldsEnums'
import { ActivityActionTypeEnum, ActivityLogModuleTypeEnum, dataToWriteActivityLog } from '@/modules/Config/ActivityLogs/domain/ActivityLogsController'
import ActivityLogsUseCases from '@/modules/Config/ActivityLogs/application/ActivityLogsUseCases'
import ActivityLogsController from '@/modules/Config/ActivityLogs/infrastructure/controllers/ActivityLogs.controller'
import { FilterQuerys } from '@/components/conversations/enums/FilterQuerys'
export default {
  components: {
    KeybeText,
    KeybeIcon,
    KeybeSelect
  },
  props: {
    categories: {
      type: Array
    },
    selectedCategory: {
      type: Object
    },
    value: {
      type: Array,
      required: true
    }
  },
  computed: {
    ...mapState('UserStore', ['token', 'user']),
    ...mapState('AppStore', ['selectedApp'])
  },
  mounted () {
    this.localCategories = []
    // eslint-disable-next-line no-undef
    this.localCategories = structuredClone(this.categories)
    this.localCategories = this.localCategories.filter((category) => category.id !== FilterQuerys.ALL)
    this.localCategories.unshift({ category: this.$t('createCategory'), id: 'create' })
    if (this.selectedCategory) this.setCategory(this.selectedCategory)
    this.productCategories = this.value
  },
  data () {
    return {
      localCategories: [],
      openCreateCategory: false,
      productCategories: [],
      CDPFieldsEnums,
      activityLogsController: new ActivityLogsController()
    }
  },
  methods: {
    setCategory (category) {
      if (Object.keys(category).length > 0) {
        if (category?.id === 'create') this.openCreateCategory = true
        else this.productCategories.push(category?.category)
        this.emitChange()
      }
    },
    newCategory (e) {
      if (e) {
        this.openCreateCategory = false
        this.createCategory(e)
      } else this.openCreateCategory = false
    },
    removeCategory (b) {
      this.productCategories.splice(b, 1)
      this.emitChange()
    },
    emitChange () {
      this.$emit('input', this.productCategories)
    },
    async createCategory (category) {
      try {
        const response = await MicrositesService.createCategory({ category })
        if (response.status) {
          this.localCategories.push(category)
          this.productCategories.push(category)
          this.writeActivityLog(category)
          this.emitChange()
        } else EventBus.$emit('toast', 'error', response)
      } catch (e) {
        console.error(e)
        return false
      }
    },
    async writeActivityLog (category: string): Promise<void> {
      const data: dataToWriteActivityLog = {
        token: this.token,
        updateInput: {
          appUUID: this.selectedApp?.uuid,
          module: ActivityLogModuleTypeEnum.CHAT,
          action: ActivityActionTypeEnum.CATALOG_CATEGORY_CREATE,
          user: {
            id: this.user.uuid,
            name: this.user.name + ' ' + this.user.lastName
          },
          payload: {
            field: CDPFieldsEnums.NAME,
            newValue: category,
            oldValue: ''
          }
        }
      }
      await ActivityLogsUseCases.writeActivityLog(this.activityLogsController, data)
    }
  },
  watch: {
    value: {
      handler (value) {
        this.productCategories = value
      },
      immediate: true
    }
  }
}
