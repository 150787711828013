import { render, staticRenderFns } from "./ActivityLogs.vue?vue&type=template&id=a00dc998&scoped=true&"
import script from "./ActivityLogs.vue?vue&type=script&lang=ts&"
export * from "./ActivityLogs.vue?vue&type=script&lang=ts&"
import style0 from "./ActivityLogs.vue?vue&type=style&index=0&id=a00dc998&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a00dc998",
  null
  
)

export default component.exports