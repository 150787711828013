import { mapState } from 'vuex'
import LocationUseCases from '@/shared/infrastructure/ui/details/sections/Location/application/LocationUseCases'
import {
  citiesGetter,
  countriesGetter,
  dataToGetCities,
  dataToGetCountries,
  dataToGetStates,
  statesGetter
} from '@/shared/infrastructure/ui/details/sections/Location/domain/LocationController'
import LocationController from '@/shared/infrastructure/ui/details/sections/Location/infrastructure/controllers/Location.controller'
import KeybeAutocomplete from '@/modules/DesignSystem/infrastructure/components/KeybeAutocomplete/KeybeAutocomplete/KeybeAutocomplete.vue'
import { debounce } from 'lodash'
import { Country, State } from '@/shared/infrastructure/ui/details/sections/Location/domain/Location'
import { CDPFieldsEnums } from '@/modules/CDP/shared/contacts/domain/enums/CDPFieldsEnums'
import KBButtonLoader from '@/shared/infrastructure/ui/progress/KBButtonLoader/KBButtonLoader.vue'
import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'

export default {
  name: 'CitySelector',
  props: {
    fieldIndex: {
      type: Number,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    location: {
      type: Object,
      required: true
    }
  },
  components: {
    KeybeAutocomplete,
    KBButtonLoader,
    KeybeIcon
  },
  computed: {
    ...mapState('AppStore', ['selectedApp']),
    ...mapState('UserStore', ['token'])
  },
  async mounted () {
    this.initialLoad = true
    this.selectedCountry = this.countryValue = this.countrySearch = this.value?.country || ''
    this.selectedState = this.stateValue = this.stateSearch = this.value?.state || ''
    this.selectedCity = this.cityValue = this.citySearch = this.value?.city || ''
    await this.getCountries()
    this.initialLoad = false
  },
  data () {
    return {
      KeybeIconType,
      locationController: new LocationController(),
      countries: [],
      states: [],
      cities: [],
      selectedCountry: '',
      selectedState: '',
      selectedCity: '',
      countrySearch: '',
      stateSearch: '',
      citySearch: '',
      countryValue: '',
      stateValue: '',
      cityValue: '',
      loadingCountries: false,
      loadingStates: false,
      loadingCities: false,
      initialLoad: false,
      CDPFieldsEnums
    }
  },
  methods: {
    clearCountry (): void {
      this.selectedCountry = ''
      this.selectedState = ''
      this.selectedCity = ''
      this.countrySearch = ''
      this.stateSearch = ''
      this.citySearch = ''
      this.$emit('location', `${this.title}Country`, '')
      this.$emit('location', `${this.title}Province`, '')
      this.$emit('location', `${this.title}City`, '')
      this.getCountries()
    },
    clearState (): void {
      this.selectedState = ''
      this.selectedCity = ''
      this.stateSearch = ''
      this.citySearch = ''
      this.$emit('location', `${this.title}Province`, '')
      this.$emit('location', `${this.title}City`, '')
      this.getStates()
    },
    clearCity (): void {
      this.selectedCity = ''
      this.citySearch = ''
      this.$emit('location', `${this.title}City`, '')
      this.getCities()
    },
    setCountrySearch: debounce(function (e: string): void {
      if (!e) this.clearCountry()
      else if (e && e !== this.selectedCountry) {
        this.countrySearch = e
        this.selectedCountry = ''
        this.selectedState = ''
        this.selectedCity = ''
        this.stateSearch = ''
        this.citySearch = ''
        this.getCountries()
      }
    }, 600),
    setStateSearch: debounce(function (e: string): void {
      if (!e) this.clearState()
      else if (e && e !== this.selectedState) {
        this.stateSearch = e
        this.citySearch = ''
        this.selectedState = ''
        this.selectedCity = ''
        this.getStates()
      }
    }, 600),
    setCitySearch: debounce(function (e: string): void {
      if (!e) this.clearCity()
      else if (e && e !== this.selectedCity) {
        this.citySearch = e
        this.selectedCity = ''
        this.getCities()
      }
    }, 600),
    showField (field: string): boolean {
      const value: string = this.title
        ? `${this.title}${field}`
        : field.toLocaleLowerCase()
      return !this.hiddenAppFields || !this.hiddenAppFields.includes(value)
    },
    setCountrySelect (e: string): void {
      if (e) this.selectedCountry = e
      this.stateSearch = ''
      this.citySearch = ''
      this.selectedState = ''
      this.selectedCity = ''
      this.getStates()
    },
    setStateSelect (e: string): void {
      if (e) this.selectedState = e
      this.citySearch = ''
      this.selectedCity = ''
      this.getCities()
    },
    async getCountries (): Promise<void> {
      this.loadingCountries = true
      const data: dataToGetCountries = {
        token: this.token,
        app: this.selectedApp?.uuid || '',
        query: {
          limit: 20,
          offset: 0,
          search: this.countrySearch
        }
      }
      const response: countriesGetter = await LocationUseCases.getCountries(
        this.locationController,
        data
      )
      if (response?.status) {
        this.countries = response?.countries
        if (this.value?.country) {
          this.getStates()
          const found: Country = this.countries.find((country: Country) => country.name === this.value?.country)
          if (!found) {
            const foundCode: Country = this.countries.find((country: Country) => country.iso2 === this.value?.country)
            if (foundCode) {
              this.selectedCountry = foundCode.name
              this.$emit('location', `${this.title}Country`, foundCode.name)
            }
          }
        }
        if (this.value?.state) {
          this.getStates()
        }
      }
      this.loadingCountries = false
    },
    async getStates (): Promise<void> {
      this.loadingStates = true
      this.states = []
      if (this.selectedCountry !== this.value?.country) {
        this.$emit('location', `${this.title}Country`, this.selectedCountry)
      }

      const variables: dataToGetStates = {
        app: this.selectedApp?.uuid,
        token: this.token,
        countryCode: this.selectedCountry,
        query: {
          limit: 20,
          offset: 0,
          search: this.stateSearch
        }
      }
      const response: statesGetter = await LocationUseCases.getStates(
        this.locationController,
        variables
      )
      if (response?.status) {
        this.states = response?.states
        if (this.value?.state) {
          this.getCities()
          const found: State = this.states.find((state: State) => state.name === this.value?.state)
          if (!found) {
            const foundCode: State = this.states.find((state: State) => state.state_code === this.value?.state)
            if (foundCode) {
              this.selectedState = foundCode.name
              this.$emit('location', `${this.title}Province`, foundCode.name)
            }
          }
        }
        if (this.value?.city) {
          this.getCities()
        }
      }
      this.loadingStates = false
    },
    async getCities (): Promise<void> {
      this.loadingCities = true
      if (this.selectedState !== this.value?.state) {
        this.$emit(
          'location',
          `${this.title}Province`,
          this.selectedState
        )
      }
      this.cities = []

      const variables: dataToGetCities = {
        app: this.selectedApp?.uuid,
        token: this.token,
        countryCode: this.selectedCountry,
        provinceCode: this.selectedState,
        query: {
          limit: 20,
          offset: 0,
          search: this.citySearch
        }
      }
      const response: citiesGetter = await LocationUseCases.getCities(
        this.locationController,
        variables
      )
      if (response?.status) {
        this.cities = response?.cities
      }
      this.loadingCities = false
    },
    selectCity (e: string): void {
      if (e) this.selectedCity = e
      const data = {
        id: this.location?.id,
        city: this.selectedCity,
        state: this.selectedState,
        country: this.selectedCountry
      }
      this.$emit('updateLocation', data)
    },
    removeCity (): void {
      this.$emit('removeCity')
    }
  }
}
