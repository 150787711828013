import SpacesController from '../../Spaces.controller'
import { mapGetters, mapState } from 'vuex'
import SpacesUseCases from '@/modules/Spaces/application/SpacesUseCases'
import {
  defaultSpaceDTO,
  spaceDeleteDTO,
  spaceUpdateDTO,
  spaceCreateDTO,
  dataToGetSpaces
} from '@/modules/Spaces/domain/SpacesController'
import { Spaces, SpaceConfig } from '@/modules/Spaces/domain/Spaces'
import { EventBus } from '@/eventBus'
import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'
import KBText from '@/shared/infrastructure/ui/inputs/text/KBText.vue'
import SpacesConfigList from '../SpacesConfigList/SpacesConfigList.vue'
// import SpacesConfigDetail from '../SpacesConfigDetail/SpacesConfigDetail.vue'
import SpacesWorkingHours from '../SpacesRules/SpacesWorkingHours/SpacesWorkingHours.vue'
import AutoassignConfig from '../SpacesRules/AutoassignConfig/AutoassignConfig.vue'
import AutomaticClosingConfig from '../SpacesRules/AutomaticClosingConfig/AutomaticClosingConfig.vue'
import outOffService from '../SpacesRules/outOffService/outOffService.vue'
import WelcomeMessageConfig from '../SpacesRules/WelcomeMessage/WelcomeMessageConfig/WelcomeMessageConfig.vue'
import ChatConfigController from '@/modules/ChatConfig/infrastructure/ChatConfig.controller'
import ChatConfigUseCases from '@/modules/ChatConfig/application/ChatConfigUseCases'
// import ConversationsConfigService from '@/components/configuration/Services/ConversationsConfigService'
import KBExpansionPanel from '@/shared/infrastructure/ui/menus/KBExpansionPanel.vue'
import KBSwitch from '@/shared/infrastructure/ui/switch/KBSwitch.vue'
import Loader from '@/shared/infrastructure/ui/Loader.vue'
import OnboardingUseCases from '@/modules/SignInOnboarding/application/OnboardingUseCases'

import OnboardingController from '@/modules/SignInOnboarding/infrastructure/controllers/Onboarding.controller'
import SignInOnboardingTutorialBox from '@/modules/SignInOnboarding/infrastructure/components/SignInOnboardingTutorialBox/SignInOnboardingTutorialBox.vue'
import DeleteSpaceWarning from '../DeleteSpaceWarning/DeleteSpaceWarning.vue'
import Dialog from '@/components/shared/components/Dialog.vue'
import Colors from '@/utils/Colors'
import { ActivityLogModuleTypeEnum, ActivityActionTypeEnum, dataToWriteActivityLog, ActivitySubmoduleTypeEnum } from '@/modules/Config/ActivityLogs/domain/ActivityLogsController'
import { CDPFieldsEnums } from '@/modules/CDP/shared/contacts/domain/enums/CDPFieldsEnums'
import ActivityLogsUseCases from '@/modules/Config/ActivityLogs/application/ActivityLogsUseCases'
import ActivityLogsController from '@/modules/Config/ActivityLogs/infrastructure/controllers/ActivityLogs.controller'
import KBCheck from '@/shared/infrastructure/ui/inputs/select/KBCheck.vue'
import EmitToast from '@/utils/EmitToast'

export default {
  name: 'SpacesConfig',
  components: {
    SpacesConfigList,
    // SpacesConfigDetail,
    SpacesWorkingHours,
    AutoassignConfig,
    AutomaticClosingConfig,
    outOffService,
    WelcomeMessageConfig,
    KeybeIcon,
    KBExpansionPanel,
    Loader,
    KBText,
    KBSwitch,
    SignInOnboardingTutorialBox,
    Dialog,
    KBCheck
  },
  data () {
    return {
      KeybeIconType,
      SpacesController: new SpacesController(),
      selectedInbox: {},
      inboxList: [],
      loading: false,
      chatConfig: {},
      chatConfigController: new ChatConfigController(),
      inboxConfig: {},
      isCreating: false,
      newInbox: '',
      onboardingController: new OnboardingController(),
      activityLogsController: new ActivityLogsController(),
      boxPosition: 'margin-top: 10px;margin-left: 215px',
      arrowPosition: 'left',
      headerText: this.$t('helloKBSpaces'),
      subtitleText: this.$t('kbSpacesTutorial'),
      showTutorial: false,
      rerender: 0,
      onboardingStep: null,
      showDeleteInboxWarning: false,
      selectedInboxToDelete: {}
    }
  },
  computed: {
    ...mapState('UserStore', ['user', 'token', 'isUserReadonly']),
    ...mapState('AppStore', ['selectedApp']),
    ...mapGetters('UserStore', ['isSuperAdmin', 'isAdminOrAbove']),
    ...mapState('ConversationStore', ['channels']),
    userHosts () {
      return this.channels?.whatsapp?.accounts.map(account => account.userHost) || []
    },
    DeleteSpaceWarningDialog () {
      return DeleteSpaceWarning
    },
    dialogBackground () {
      return Colors.rojoBird
    }
  },
  methods: {
    openSpacesFAQ (): void {
      window.open(`${process.env.VUE_APP_KEYBE_CO_URL}docs/ayudas/chats/que-es-kbspaces-y-para-que-sirven/`, '_blank')
    },
    openCreate (): void {
      if (this.isUserReadonly) return
      this.isCreating = true
    },
    async getInboxes ():Promise<void> {
      this.loading = true
      const data : dataToGetSpaces = {
        token: this.token,
        appUUID: this.selectedApp?.uuid
      }

      this.chatConfig = await this.chatConfigController.getAndSetConfig({
        appUUID: this.selectedApp?.uuid || '',
        token: this.token || ''
      })
      // this.inboxConfig = this.chatConfig?.inbox
      const inboxes: Spaces[] = await SpacesUseCases.getInbox(this.SpacesController, data)

      this.inboxList = SpacesUseCases.processSpaces(inboxes, this.SpacesController)
      this.selectedInbox = inboxes[0]
      this.loading = false
    },
    isSelected (line, inbox) {
      const lineExist = inbox.line.some(i => i && i === line)
      return lineExist
    },
    toggleField (line, inbox) {
      const isSelected = this.isSelected(line, inbox)

      if (isSelected) {
        inbox.line.splice(inbox.line.indexOf(line), 1)
      } else {
        inbox.line.push(line)
      }
      this.updateInbox(inbox)
    },
    countSelectedLines (inbox) {
      if (inbox.line.filter(line => line).length === 0) {
        return this.$t('noLinesSelected')
      }

      if (inbox.line.filter(line => line).length === 1) {
        return `${inbox.line.filter(line => line).length} ${this.$t('lineSelected')}`
      }
      if (inbox.line.filter(line => line).length >= 2) {
        return `${inbox.line.filter(line => line).length} ${this.$t('linesSelected')}`
      }
    },
    async createInbox (data: string): Promise<void> {
      const inboxNameExists: boolean = this.inboxList.some(
        (i: Spaces) => i?.name?.toLowerCase() === data?.toLowerCase()
      )

      if (inboxNameExists && data.length <= 20) {
        EventBus.$emit('toast', 'error', this.$t('inboxNameAlreadyExists'))
        return
      }

      if (!data) {
        EventBus.$emit('toast', 'error', this.$t('inboxNameRequired'))
        return
      }
      const dataToCreateInbox: spaceCreateDTO = {
        userId: this.user?.userId,
        token: this.token,
        appUUID: this.selectedApp?.uuid,
        name: data
      }
      this.loading = true
      const newInbox: any = await SpacesUseCases.createInbox(
        this.SpacesController,
        dataToCreateInbox
      )

      if (!newInbox) {
        this.isCreating = false
        EventBus.$emit('toast', 'error', this.$t('errorCreatingInbox'))
        return
      }
      this.isCreating = false
      await this.writeActivityLogCreate(data)
      await this.getInboxes()
      this.loading = false
    },
    async writeActivityLogCreate (name: string): Promise<void> {
      const data: dataToWriteActivityLog = {
        token: this.token,
        updateInput: {
          appUUID: this.selectedApp?.uuid,
          module: ActivityLogModuleTypeEnum.CHAT,
          submodule: ActivitySubmoduleTypeEnum.SPACES,
          action: ActivityActionTypeEnum.SPACE_CREATE,
          object: name,
          user: {
            id: this.user.uuid,
            name: this.user.name + ' ' + this.user.lastName
          },
          payload: {
            field: CDPFieldsEnums.NAME,
            newValue: name,
            oldValue: ''
          }
        }
      }
      await this.writeActivityLog(data)
    },
    async writeActivityLog (data: dataToWriteActivityLog): Promise<void> {
      await ActivityLogsUseCases.writeActivityLog(this.activityLogsController, data)
    },
    async updateInbox (inbox: Spaces): Promise<void> {
      const index: number = this.inboxList.findIndex((i) => i?._id === inbox?._id)

      this.inboxList.splice(index, 1, inbox)

      const dataToUpdateInbox: spaceUpdateDTO = {
        name: inbox?.name,
        inboxId: inbox?._id || '',
        userId: this.user?.userId,
        token: this.token,
        line: inbox?.line,
        isAdmin: this.isAdminOrAbove
      }

      if (!this.isAdminOrAbove) {
        delete dataToUpdateInbox.isAdmin
      }

      this.loading = true
      await SpacesUseCases.updateInbox(this.SpacesController, dataToUpdateInbox)
      await this.getInboxes()
      this.loading = false
    },
    checkDeleteInbox (inbox: Spaces) {
      this.selectedInboxToDelete = inbox
      this.showDeleteInboxWarning = true
    },
    cancelDeletion () {
      this.showDeleteInboxWarning = false
      this.selectedInboxToDelete = {}
    },
    async writeActivityLogDelete (name: string): Promise<void> {
      const data: dataToWriteActivityLog = {
        token: this.token,
        updateInput: {
          appUUID: this.selectedApp?.uuid,
          module: ActivityLogModuleTypeEnum.CHAT,
          submodule: ActivitySubmoduleTypeEnum.SPACES,
          action: ActivityActionTypeEnum.SPACE_DELETE,
          user: {
            id: this.user.uuid,
            name: this.user.name + ' ' + this.user.lastName
          },
          payload: {
            field: CDPFieldsEnums.NAME,
            newValue: name,
            oldValue: ''
          }
        }
      }
      await this.writeActivityLog(data)
    },
    async deleteInbox (): Promise<void> {
      this.showDeleteInboxWarning = false
      const index: number = this.inboxList.findIndex((i) => i?._id === this.selectedInboxToDelete?._id)
      const dataToDeleteInbox: spaceDeleteDTO = {
        inboxId: this.selectedInboxToDelete?._id || '',
        userId: this.user?.userId,
        token: this.token,
        isAdmin: this.isAdminOrAbove
      }

      if (!this.isAdminOrAbove) {
        delete dataToDeleteInbox.isAdmin
      }
      this.loading = true
      const deletedInbox: boolean = await SpacesUseCases.deleteInbox(
        this.SpacesController,
        dataToDeleteInbox
      )

      if (!deletedInbox) {
        EventBus.$emit('toast', 'error', this.$t('errorDeletingInbox'))
        this.loading = false
        return
      }
      this.inboxList.splice(index, 1)
      this.writeActivityLogDelete(this.selectedInboxToDelete?.name || '')
      if (this.selectedInbox?._id === this.selectedInboxToDelete?._id) {
        this.selectedInbox = this.inboxList[0] || {}
      }
      this.loading = false
      this.selectedInboxToDelete = {}
      EventBus.$emit('InboxRow__refreshInboxes')
    },
    async setAsMain (inbox: Spaces): Promise<void> {
      const data: defaultSpaceDTO = {
        token: this.token,
        userId: this.user?.userId,
        appUUID: this.selectedApp?.uuid,
        inboxId: inbox?._id || '',
        isAdmin: this.isAdminOrAbove
      }

      if (!this.isAdminOrAbove) {
        delete data.isSuperAdmin
      }
      this.loading = true
      const updated: boolean = await SpacesUseCases.setDefaultInbox(
        this.SpacesController,
        data
      )
      if (!updated) {
        EventBus.$emit('toast', 'error', this.$t('errorSettingInbox'))
        this.loading = false
        return
      }
      await this.writeActivityLogMain(inbox)
      await this.getInboxes()
      this.loading = false
    },
    async writeActivityLogMain (inbox: Spaces): Promise<void> {
      const found: Spaces = this.inboxList.find((i: Spaces) => i.default)
      const data: dataToWriteActivityLog = {
        token: this.token,
        updateInput: {
          appUUID: this.selectedApp?.uuid,
          module: ActivityLogModuleTypeEnum.CHAT,
          submodule: ActivitySubmoduleTypeEnum.SPACES,
          action: ActivityActionTypeEnum.SPACE_SET_AS_MAIN,
          user: {
            id: this.user.uuid,
            name: this.user.name + ' ' + this.user.lastName
          },
          payload: {
            field: CDPFieldsEnums.NAME,
            newValue: inbox.name,
            oldValue: found.name
          }
        }
      }
      await this.writeActivityLog(data)
    },
    cancelNewInbox (): void {
      this.newInbox = ''
      this.isCreating = false
    },
    async addNewInbox (): Promise<void> {
      if (this.loading || this.isUserReadonly) return
      await this.createInbox(this.newInbox)
      this.isCreating = false
      this.newInbox = ''
    },
    async handleInboxUpdated (field: { [key: string]: any }, index: number): Promise<void> {
      const updatedInboxObject: spaceUpdateDTO = {
        name: this.inboxList[index]?.name,
        inboxId: this.inboxList[index]?._id || '',
        userId: this.user?.userId,
        token: this.token,
        isAdmin: this.isAdminOrAbove,
        ...field
      }
      if (!this.isAdminOrAbove) {
        delete updatedInboxObject.isAdmin
      }
      await SpacesUseCases.updateInbox(this.SpacesController, updatedInboxObject)
      await this.writeActivityLogUpdate(updatedInboxObject, index)
      SpacesUseCases.dispose(this.SpacesController)
      EmitToast.emitSuccess(this.$t('inboxUpdated'))
    },
    async writeActivityLogUpdate (updatedInboxObject: spaceUpdateDTO, index: number): Promise<void> {
      Object.keys(updatedInboxObject).forEach(async (key: string) => {
        if (
          key !== 'token' &&
          key !== 'userId' &&
          key !== 'inboxId' &&
          key !== 'isAdmin' &&
          key !== 'messageOnTransfer' &&
          key !== 'workingHours' &&
          key !== 'autoClose'
        ) {
          if (updatedInboxObject[key] !== this.inboxList[index][key]) {
            const data: dataToWriteActivityLog = {
              token: this.token,
              updateInput: {
                appUUID: this.selectedApp?.uuid,
                module: ActivityLogModuleTypeEnum.CHAT,
                submodule: ActivitySubmoduleTypeEnum.SPACES,
                action: ActivityActionTypeEnum.SPACE_UPDATE,
                object: this.inboxList[index].name,
                user: {
                  id: this.user.uuid,
                  name: this.user.name + ' ' + this.user.lastName
                },
                payload: {
                  field: key,
                  newValue: updatedInboxObject[key]?.toString() || '',
                  oldValue: this.inboxList[index][key]?.toString() || ''
                }
              }
            }
            await this.writeActivityLog(data)
          }
        }
      })
    },
    async modifyUseMemory (inbox: SpaceConfig): Promise<void> {
      if (this.isUserReadonly) return
      const dataToUpdateInbox: spaceUpdateDTO = {
        name: inbox.name,
        inboxId: inbox?._id || '',
        userId: this.user?.userId,
        token: this.token,
        isAdmin: this.isAdminOrAbove,
        useMemory: inbox.useMemory
      }
      if (!this.isAdminOrAbove) {
        delete dataToUpdateInbox.isAdmin
      }
      await SpacesUseCases.updateInbox(this.SpacesController, dataToUpdateInbox)
      await this.writeActivityLogUseMemory(inbox)
      SpacesUseCases.dispose(this.SpacesController)
    },
    async writeActivityLogUseMemory (inbox: SpaceConfig): Promise<void> {
      const data: dataToWriteActivityLog = {
        token: this.token,
        updateInput: {
          appUUID: this.selectedApp?.uuid,
          module: ActivityLogModuleTypeEnum.CHAT,
          submodule: ActivitySubmoduleTypeEnum.SPACES,
          action: ActivityActionTypeEnum.SPACE_UPDATE,
          object: inbox.name,
          user: {
            id: this.user.uuid,
            name: this.user.name + ' ' + this.user.lastName
          },
          payload: {
            field: CDPFieldsEnums.USE_MEMORY,
            newValue: inbox.useMemory ? 'active' : 'inactive',
            oldValue: inbox.useMemory ? 'inactive' : 'active'
          }
        }
      }
      await this.writeActivityLog(data)
    },

    async modifyUseMemoryOffline (inbox: SpaceConfig): Promise<void> {
      if (this.isUserReadonly) return
      const dataToUpdateInbox: spaceUpdateDTO = {
        name: inbox.name,
        inboxId: inbox?._id || '',
        userId: this.user?.userId,
        token: this.token,
        isAdmin: this.isAdminOrAbove,
        useMemoryOffline: inbox.useMemoryOffline
      }
      if (!this.isAdminOrAbove) {
        delete dataToUpdateInbox.isAdmin
      }
      await SpacesUseCases.updateInbox(this.SpacesController, dataToUpdateInbox)
      await this.writeActivityLogUseMemoryOffline(inbox)
      SpacesUseCases.dispose(this.SpacesController)
    },
    async writeActivityLogUseMemoryOffline (inbox: SpaceConfig): Promise<void> {
      const data: dataToWriteActivityLog = {
        token: this.token,
        updateInput: {
          appUUID: this.selectedApp?.uuid,
          module: ActivityLogModuleTypeEnum.CHAT,
          submodule: ActivitySubmoduleTypeEnum.SPACES,
          action: ActivityActionTypeEnum.SPACE_UPDATE,
          object: inbox.name,
          user: {
            id: this.user.uuid,
            name: this.user.name + ' ' + this.user.lastName
          },
          payload: {
            field: CDPFieldsEnums.USE_MEMORY_OFFLINE,
            newValue: inbox.useMemoryOffline ? 'active' : 'inactive',
            oldValue: inbox.useMemoryOffline ? 'inactive' : 'active'
          }
        }
      }
      await this.writeActivityLog(data)
    },
    closeTutorial (): void {
      this.showTutorial = false
      setTimeout(() => {
        this.getOnboardingStep()
      }, 400)
    },
    async getOnboardingStep (): Promise<void> {
      const step: number = await OnboardingUseCases.getOnboardingStep(this.onboardingController)
      this.onboardingStep = step
      if (this.onboardingStep === 10) {
        this.nextStep()
      }
      if (this.onboardingStep === 11) {
        this.showTutorial = true
        this.rerender++
        setTimeout(() => {
          this.showTutorial = false
        }, 10000)
      }
      this.rerender++
    },
    async nextStep (): Promise<void> {
      // eslint-disable-next-line no-undef
      const newStep: number = +structuredClone(this.onboardingStep) + 1
      await OnboardingUseCases.setOnboardingStep(this.onboardingController, newStep)
      this.onboardingStep = newStep
      if (this.onboardingStep === 11) {
        this.showTutorial = true
        this.rerender++
        setTimeout(() => {
          this.showTutorial = false
        }, 10000)
      }
    }
  },
  mounted () {
    this.$on('inbox-updated', this.handleInboxUpdated)
    this.getOnboardingStep()
    EventBus.$on('deleteSpaceAgreement--configuration', this.deleteInbox.bind(this))
  },
  created () {
    this.getInboxes()
  },
  beforeDestroy () {
    this.$off('inbox-updated', this.handleInboxUpdated)
    EventBus.$off('deleteSpaceAgreement--configuration', this.deleteInbox.bind(this))
    SpacesUseCases.dispose(this.SpacesController)
    ChatConfigUseCases.dispose(this.chatConfigController)
  },
  watch: {
    loading (value: boolean) {
      if (!value) {
        this.isCreating = false
        this.newInbox = ''
      }
    }
  }
}
