import KeybeRepository from '@/shared/infrastructure/repositories/KeybeRepository'
import {
  addUserToSpaceDTO,
  dataToGetSpaces,
  defaultSpaceDTO,
  spaceCreateDTO,
  spaceDeleteDTO,
  spaceUpdateDTO,
  removeUserFromSpaceDTO
} from '../domain/SpacesController'
import { HeadersBuilder } from '@/shared/infrastructure/http/HeadersBuilder'
import { getOptions, nonGetOptions } from '@/shared/infrastructure/http/HttpClient'
import { GenericResponse } from '@/shared/infrastructure/http/GenericResponse'

export default class SpacesRepository extends KeybeRepository {
  apiUrl: string
  abortController: AbortController

  constructor () {
    super()
    this.apiUrl = process.env.VUE_APP_CONVERSATIONS_API_URL
    this.abortController = new AbortController()
  }

  dispose (): void {
    this.abortController.abort()
    this.abortController = new AbortController()
  }

  async getInbox (data: dataToGetSpaces): Promise<GenericResponse> {
    const params = {
      appUUID: data.appUUID,
      userId: data.userId
    }

    if (!data.userId) {
      delete params.userId
    }

    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)

    const requestData: getOptions = {
      url: `${this.apiUrl}/inbox`,
      query: params,
      headers,
      abortController: this.abortController
    }

    return await this.client.get(requestData)
  }

  async createInbox (inbox: spaceCreateDTO): Promise<GenericResponse> {
    const data = {
      appUUID: inbox.appUUID,
      name: inbox.name,
      userId: inbox.userId
    }

    const headers = HeadersBuilder.buildSimpleAutorizationHeader(inbox)

    const requestData: nonGetOptions = {
      url: `${this.apiUrl}/inbox`,
      data,
      headers,
      abortController: this.abortController
    }

    return await this.client.post(requestData)
  }

  async updateInbox (inbox: spaceUpdateDTO): Promise<GenericResponse> {
    const data = {
      inboxId: inbox.inboxId,
      name: inbox.name,
      userId: inbox.userId,
      active: inbox.active,
      line: inbox.line,
      isAdmin: inbox.isAdmin,
      users: inbox.users,
      useMemory: inbox.useMemory,
      useMemoryOffline: inbox.useMemoryOffline,
      autoassignationType: inbox.autoassignationType,
      isAutoassignActive: inbox.isAutoassignActive,
      maxActiveConversationsNumberPerAdvisor: inbox.maxActiveConversationsNumberPerAdvisor,
      autoClose: inbox.autoClose,
      reassignForAdviserInactiveMinutes: inbox.reassignForAdviserInactiveMinutes,
      reassignForUserInactiveMinutesWithBot: inbox.reassignForUserInactiveMinutesWithBot,
      inactivitySeconds: inbox.inactivitySeconds,
      inactivityMessage: inbox.inactivityMessage,
      workingHours: inbox.workingHours,
      messageOnTransfer: inbox.messageOnTransfer
    }

    if (!data.isAdmin) {
      delete data.isAdmin
    }

    const headers = HeadersBuilder.buildSimpleAutorizationHeader(inbox)

    const requestData: nonGetOptions = {
      url: `${this.apiUrl}/inbox`,
      data,
      headers,
      abortController: this.abortController
    }

    return await this.client.put(requestData)
  }

  async deleteInbox (inbox: spaceDeleteDTO): Promise<GenericResponse> {
    const data = {
      inboxId: inbox.inboxId,
      userId: inbox.userId,
      isAdmin: inbox.isAdmin
    }

    if (!data.isAdmin) {
      delete data.isAdmin
    }

    const headers = HeadersBuilder.buildSimpleAutorizationHeader(inbox)

    const requestData: nonGetOptions = {
      url: `${this.apiUrl}/inbox`,
      data,
      headers,
      abortController: this.abortController
    }
    return await this.client.delete(requestData)
  }

  async addUserToInbox (inbox: addUserToSpaceDTO): Promise<GenericResponse> {
    const data = {
      inboxId: inbox.inboxId,
      userId: inbox.userId,
      permission: inbox.permission,
      appUUID: inbox.appUUID,
      isAdmin: inbox.isAdmin
    }

    if (!data.isAdmin) {
      delete data.isAdmin
    }

    const headers = HeadersBuilder.buildSimpleAutorizationHeader(inbox)

    const requestData: nonGetOptions = {
      url: `${this.apiUrl}/inbox/user`,
      data,
      headers,
      abortController: this.abortController
    }

    return await this.client.post(requestData)
  }

  async removeUserFromInbox (inbox: removeUserFromSpaceDTO): Promise<GenericResponse> {
    const data = {
      inboxId: inbox.inboxId,
      userId: inbox.userId,
      removeUser: inbox.removeUser,
      appUUID: inbox.appUUID,
      isAdmin: inbox.isAdmin
    }

    if (!data.isAdmin) {
      delete data.isAdmin
    }

    const headers = HeadersBuilder.buildSimpleAutorizationHeader(inbox)

    const requestData: nonGetOptions = {
      url: `${this.apiUrl}/inbox/user`,
      data,
      headers,
      abortController: this.abortController
    }

    return await this.client.delete(requestData)
  }

  async setDefaultInbox (data: defaultSpaceDTO): Promise<GenericResponse> {
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)

    delete data.token

    const requestData: nonGetOptions = {
      url: `${this.apiUrl}/inbox/default`,
      data,
      headers,
      abortController: this.abortController
    }

    return await this.client.post(requestData)
  }
}
