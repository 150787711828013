import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'
import KeybeButton from '@/modules/DesignSystem/infrastructure/components/KeybeButton/KeybeButton.vue'
export default {
  props: {
    template: {
      type: Object,
      required: true
    }
  },
  components: {
    KeybeIcon,
    KeybeButton
  },
  data () {
    return {
      KeybeIconType
    }
  }
}
