export enum Socials {
  WsKeybe = 'whatsappKeybe',
  Fb = 'facebook',
  Ig = 'instagram',
  Lkn = 'linkedin',
  Tw = 'twitter',
  Thr = 'threads',
  Ws = 'whatsapp',
  Yt = 'youtube',
  Tlg = 'telegram',
  Lnk = 'link',
  Tt = 'tiktok',
  Wsme = 'wa.me',
  Wslnk = 'wa.link',
  Ggl = 'google',
  Dsc = 'discord',
  Spt = 'spotify',
  Twt = 'twitch',
  Plt = 'planet'
}

export enum SocialsCapitalize {
  Fb = 'Facebook',
  Ig = 'Instagram',
  Lkn = 'Linkedin',
  Tw = 'Twitter',
  Thr = 'Threads',
  Ws = 'Whatsapp',
  Yt = 'Youtube',
  Tlg = 'Telegram',
  Tt = 'Tiktok',
  Ggl = 'Google',
  Dsc = 'Discord',
  Spt = 'Spotify',
  Twt = 'Twitch',
}
