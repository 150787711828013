import { IWidgetTesseractController, deleteFormDTO, getAllFormsDTO, createFormDTO, updateFormDTO, getFormByUrlDTO, getFormByIdDTO } from '@/modules/Config/WidgetTesseract/domain/WidgetTesseractController'
import { ConfigFormTesseract } from '@/modules/Config/WidgetTesseract/domain/WidgetTesseract'
import WidgetTesseractRepository from '@/modules/Config/WidgetTesseract/infrastructure/WidgetTesseract.repository'
import { GenericResponse } from '@/models/apis/GenericResponse'

export default class WidgetTesseractController implements IWidgetTesseractController {
    repository: WidgetTesseractRepository

    constructor () {
      this.repository = new WidgetTesseractRepository()
    }

    async getAllForms (data: getAllFormsDTO): Promise<ConfigFormTesseract[]> {
      const response = await this.repository.getAllForms(data)
      if (!response.status) {
        return []
      }
      return response.data.forms
    }

    async getFormByUrl (data: getFormByUrlDTO): Promise<ConfigFormTesseract> {
      const response = await this.repository.getFormByUrl(data)
      if (!response.status) {
        return null
      }
      return response.data
    }

    async getFormById (data: getFormByIdDTO): Promise<ConfigFormTesseract> {
      const response = await this.repository.getFormById(data)
      if (!response.status) {
        return null
      }
      return response.data
    }

    async createForm (data: createFormDTO): Promise<GenericResponse> {
      const response = await this.repository.createForm(data)
      if (!response.status) {
        return null
      }
      return response.data
    }

    async updateForm (data: updateFormDTO): Promise<GenericResponse> {
      const response = await this.repository.updateForm(data)
      if (!response.status) {
        return null
      }
      return response.data
    }

    async deleteForm (data: deleteFormDTO): Promise<GenericResponse> {
      const response = await this.repository.deleteForm(data)
      if (!response.status) {
        return null
      }
      return response.data
    }

    processDataDefault ():createFormDTO {
      const data:createFormDTO = {
        appUUID: '',
        companyUUID: '',
        token: '',
        type: '',
        name: '',
        url: '',
        apiKey: '',
        sourceUTM: 'test',
        sourceURL: 'test',
        title: 'prueba',
        formFields: [],
        formId: '',
        salesCardData: {
          funnelId: '',
          stepId: '',
          description: '',
          amount: 0
        },
        templateData: {
          templateMessage: '',
          templateId: '',
          userHost: '',
          inbox: ''
        },
        widgetConfig: {
          backgroundColor: '#FFFFFF',
          textColor: '#000000',
          textSize: 'Medium',
          language: 'es',
          fieldColor: '#EEF1F5',
          buttonColor: '#000000',
          buttonTextColor: '#FFFFFF',
          buttonText: 'Click here',
          image: `${process.env.VUE_APP_STORAGE_URL}/crm/prod/profile/3yp9vmj1lxc36spj_KEB9NWZK78.png`,
          typeBiky: 'Biky Formal',
          message: 'Hola, soy Biky Montes, asesora de ventas de [Nombre marca] te ayudaré en todo lo que necesites',
          messageWhatsapp: '',
          buttonTextExpanded: 'Iniciar Conversación',
          mode: 'dark',
          position: 'Left',
          size: 'Small',
          typography: 'Poppins'
        },
        landingConfig: {
          desktop: {
            title: 'BIENVENIDO A LA AVENTURA',
            message: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam lobortis accumsan ultrices.',
            backgroundColor: '#000000',
            backgroundImage: `${process.env.VUE_APP_STORAGE_URL}/files/bluebird/image+141.png`,
            titleColor: '#FFFFFF',
            messageColor: '#FFFFFF',
            titlePosition: '',
            titleTextAlign: 'Right',
            typography: 'Roboto',
            logo: `${process.env.VUE_APP_STORAGE_URL}/crm/prod/profile/1l9xzy1lvguvurl_KEB9NWZK78.png`,
            logoPositionX: 'Left',
            logoPositionY: 'Bottom',
            formMode: 'Light',
            formBackgroundColor: '#FFFFFF',
            formTextColor: '#000000',
            formFieldColor: '#000000',
            formTextSize: '',
            formLabelColor: '#000000',
            formButtonColor: '#3AF69B',
            formButtonTextColor: '#FFFFFF',
            formButtonText: 'Click here',
            formPosition: 'Left',
            formTitle: '',
            containerId: ''
          },
          mobile: {
            title: '',
            message: '',
            backgroundColor: '#000000',
            backgroundImage: `${process.env.VUE_APP_STORAGE_URL}/files/bluebird/image+141.png`,
            titleColor: '#FFFFFF',
            messageColor: '#FFFFFF',
            titlePosition: 'Left',
            titleTextAlign: 'Center',
            typography: 'Roboto',
            logo: `${process.env.VUE_APP_STORAGE_URL}/crm/prod/profile/1l9xzy1lvguvurl_KEB9NWZK78.png`,
            logoPositionX: 'Left',
            logoPositionY: 'Bottom',
            formMode: 'Light',
            formBackgroundColor: '#FFFFFF',
            formTextColor: '#000000',
            formLabelColor: '#000000',
            formFieldColor: '#000000',
            formTextSize: 'Medium',
            formButtonColor: '#000000',
            formButtonTextColor: '#FFFFFF',
            formButtonText: 'Click here',
            formTitle: ''
          }
        }
      }

      return data
    }

    abortAll (): void {
      this.repository.abortAll()
    }
}
