import { EventBus } from '@/eventBus'
import { BikyConfigUseCases } from '@/modules/bky/application/BikyConfigUseCases'
import { BikyMessageError, BikyMessageErrorStatusEnum, KnowledgeBaseTypeEnum } from '@/modules/bky/domain/BikyConfig'
import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import KeybeAvatar from '@/shared/infrastructure/ui/KeybeAvatar.vue'
import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'
import { mapState } from 'vuex'
import BikyConfigController from '../../../controllers/BikyConfig.controller'
import BikyStoreController from '@/modules/bky/infrastructure/persistence/BikyStore.controller'

export default {
  name: 'BikyConfigErrorsItem',
  components: {
    KeybeAvatar,
    KeybeIcon
  },
  props: {
    error: {
      type: Object,
      required: true
    },
    isSelected: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      KeybeIconType,
      text: '',
      loading: false,
      bikyConfigController: new BikyConfigController()
    }
  },
  computed: {
    ...mapState('AppStore', ['selectedApp']),
    ...mapState('UserStore', ['token', 'user']),
    errorComputed (): BikyMessageError {
      return this.error
    },
    bikyAvatar () {
      return BikyStoreController.getBikyImage()
    },
    previousMessageText () {
      return this.errorComputed?.previousMessage?.text || ''
    },
    bikyMessageText () {
      return this.errorComputed?.bikyMessage?.text || ''
    }
  },
  methods: {
    async save () {
      if (this.loading) return

      this.loading = true
      if (!this.text) {
        EventBus.$emit('toast', 'error', this.$t('writeTheCorrectAnswer'))
      }

      const knowledgeFragment = {
        text: `TITLE: ${this.previousMessageText} \nCONTENT: ${this.text}`,
        type: KnowledgeBaseTypeEnum.USER,
        typeId: this.user?.userId || '',
        appUUID: this.selectedApp?.uuid || ''
      }

      const created = await BikyConfigUseCases.createKnowledgeBase(this.bikyConfigController, {
        ...knowledgeFragment,
        token: this.token
      })

      const updated = await BikyConfigUseCases.updateBikyMessageError(this.bikyConfigController, {
        token: this.token,
        _id: this.errorComputed?._id,
        status: BikyMessageErrorStatusEnum.RESOLVED,
        fixedByAdviserId: this.user?.userId || ''
      })

      this.loading = false

      if (!created && !updated) {
        EventBus.$emit('toast', 'error', this.$t('correctAnswerNotSaved'))
        return
      }

      EventBus.$emit('toast', 'success', this.$t('correctAnswerSaved'))
      this.$emit('refresh')
    },
    select (): void {
      this.$emit('select', this.error)
    }
  }
}
