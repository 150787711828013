<template>
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 15"><path :fill="color" d="M4.38 11.709c0-.402-.006-.805.003-1.206.003-.147-.036-.223-.182-.283A448.208 448.208 0 0 1 .626 8.74c-.454-.19-.669-.523-.619-.965.037-.32.224-.541.499-.698L4.09 5.03c2.802-1.6 5.604-3.202 8.405-4.803.262-.15.521-.284.843-.202.364.093.68.423.661.796-.023.447-.094.893-.16 1.338-.223 1.474-.452 2.947-.679 4.42l-.715 4.636c-.055.355-.113.71-.16 1.065-.097.705-.68 1.039-1.338.76-.953-.4-1.909-.799-2.859-1.207-.14-.06-.208-.033-.298.084a86.4 86.4 0 0 1-1.265 1.607c-.36.447-.895.623-1.366.423-.446-.19-.758-.512-.77-1.034-.01-.401-.003-.803-.003-1.206h-.005l-.001.002Zm7.018.57L12.98 1.983l-.036-.017L5.598 9.86l5.8 2.42ZM.857 7.88c.07.038.106.06.142.074 1.206.502 2.413 1 3.617 1.508.126.052.185.005.261-.076 2.441-2.629 4.884-5.256 7.326-7.883.03-.032.053-.07.078-.104l-.022-.022L.856 7.88Zm4.396 2.774c-.007.063-.015.098-.015.133v1.973c0 .166.034.323.219.38.179.055.302-.038.407-.17l1.137-1.43c.028-.035.048-.076.077-.123l-1.826-.763h.001Z"/></svg>
</template>

<script>
export default {
  name: 'CampaingsIcon',
  props: {
    color: {
      type: String,
      default: '#B6BEC7'
    }
  }
}
</script>

<style>

</style>
