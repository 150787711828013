<template>
  <svg width="18"
       height="18"
       viewBox="0 0 18 18"
       fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <path d="M12.4281 12.1039C12.5026 12.1612 12.5772 12.2053 12.6353 12.2655C14.1297 13.8188 15.6231 15.3741 17.1156 16.9303C17.1847 17.0017 17.2519 17.0778 17.3074 17.1604C17.4637 17.398 17.4464 17.6131 17.2637 17.8207C17.0965 18.0113 16.8511 18.0573 16.6284 17.923C16.5257 17.861 16.4302 17.7803 16.3448 17.6929C14.8441 16.1564 13.3461 14.6171 11.8491 13.0769C11.7855 13.0112 11.7446 12.9229 11.6846 12.8318C9.79395 14.4152 7.70608 14.9468 5.40188 14.4209C3.74212 14.0414 2.41232 13.0825 1.39429 11.685C-0.749022 8.74262 -0.337265 4.54264 2.05329 2.12802C4.64653 -0.491329 8.59231 -0.720487 11.4346 1.59551C12.9962 2.86809 13.8624 4.55954 14.0606 6.60882C14.2578 8.65527 13.6979 10.4688 12.4281 12.1049V12.1039ZM1.06434 7.31883C1.12433 10.9093 3.77212 13.5174 7.08617 13.5033C10.5038 13.4883 13.0707 10.79 13.058 7.30286C13.0453 3.64948 10.4048 1.12499 7.05436 1.1278C3.69304 1.13062 1.11434 3.79505 1.06434 7.31883Z"
          :fill="color"/>
  </svg>
</template>

<script>
export default {
  name: 'SearchIcon',
  props: {
    color: {
      type: String,
      default: '#fff'
    }
  }
}
</script>

<style>

</style>
