<template>
  <div class="KBTabs">
    <div class="KBTabs__tab"
         :class="{'KBTabs__tab--selected': selected === index}"
         v-for="(tab, index) in items"
         :key="index"
         @click="select(index)">
      <span>{{ tab }}</span>
    </div>
  </div>
</template>
<script>
export default {
  props: ['items', 'value'],
  mounted () {
    if (this.value) this.selected = this.value
  },
  data () {
    return {
      selected: 0
    }
  },
  methods: {
    select (index) {
      this.selected = index
      this.$emit('input', this.selected)
    }
  },
  watch: {
    value () {
      if (this.value) this.selected = this.value
    }
  }
}
</script>
<style scoped lang="scss">
.KBTabs {
  display: flex;
  align-items: center;
  width: 100%;

  &__tab {
    margin-right: 20px;
    cursor: pointer;

    span {
      font-weight: 300;
      font-size: 14px;
      transition: color 0.4s;

      &:after {
        display: block;
        content: '';
        height: 2px;
        width: 100%;
        background: linear-gradient(90deg, #00BB97 0%, #00CC97 15%, #00E498 36%, #00F1A1 65%, #00FFAB 100%);
        transform: scaleX(0);
        transition: transform 250ms ease-in-out;
      }
    }
  }

  &__tab--selected {
    span:after {
      transform: scaleX(1);
      transform-origin: 0% 50%;
    }
  }
}
</style>
