import { LoggedUser, LoggedUserExtradata } from '@/modules/Config/GeneralUserConfiguration/domain/LoggedUser'

export enum onboardingStatusEnum {
  STARTED = 'started',
  NONE = 'none',
  FINISHED = 'finished'
}
export interface IOnboardingController {
  checkOnboardingStatus: () => string;
  setOnboardingStarted: (token: string) => Promise<LoggedUser>;
  setOnboardingStep: (step: number) => Promise<void>;
  getOnboardingStep: () => Promise<number>;
  updateUserOnboarding: (extradata: LoggedUserExtradata[]) => Promise<boolean>;
  restartOnboarding: () => void;
  getOnboardingProgress: () => number;
  stopOnboarding: () => void;
}
