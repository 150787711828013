import Colors from '@/utils/Colors'
import KBCheck from '@/shared/infrastructure/ui/inputs/select/KBCheck.vue'
import { debounce } from 'lodash'
export default {
  components: {
    KBCheck
  },
  props: {
    items: {
      type: Array,
      required: true
    },
    value: {
      type: Array
    },
    itemText: {
      type: String,
      required: true
    },
    itemValue: {
      type: String,
      required: true
    },
    placeholder: {
      type: String
    },
    loading: {
      type: Boolean
    },
    disabled: {
      type: Boolean
    },
    autoSearch: {
      type: String
    },
    scrollable: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    labelStyle (): string {
      return this.selectedItem
        ? `color: ${this.$vuetify.theme.dark ? Colors.white : Colors.black}`
        : `color: ${Colors.simpleGrey}`
    },
    itemStyle (): string {
      return this.$vuetify.theme.dark
        ? `background-color: ${Colors.morningCoffee}; border-color: ${Colors.grey}`
        : `background-color: ${Colors.white}; border-color: ${Colors.light4}`
    },
    filteredItems (): any[] {
      return this.items.filter((item: any) => item[this.itemText]?.toLowerCase().includes(this.inputSearch?.toLowerCase()))
    }
  },
  mounted () {
    this.setValue()
  },
  data () {
    return {
      selectedItems: [],
      inputSearch: '',
      uniqueId: Math.random().toString(36).substring(7),
      menu: false,
      Colors
    }
  },
  methods: {
    openMenu (): void {
      if (!this.disabled) {
        this.menu = !this.menu
      }
    },
    getNextItems () {
      const list = document.getElementById(
        `KeybeAutocomplete__list--multiple-${this.uniqueId}`
      )
      const bottomOfWindow =
      list?.offsetHeight + list?.scrollTop >=
      list?.scrollHeight - 1
      if (bottomOfWindow) {
        this.$emit('getNext')
      }
    },
    clear (): void {
      this.selectedItems = []
      this.$emit('input', [])
      this.$emit('clear')
    },
    sendSearch: debounce(function () {
      if (!this.menu) this.menu = true
      this.$emit('search', this.inputSearch)
    }, 600),
    selectedItemLabel (item: any): string {
      return item[this.itemText] || ''
    },
    isItemSelected (item: any): boolean {
      return this.selectedItems.some(selectedItem => selectedItem[this.itemValue] === item[this.itemValue])
    },
    selectItem (item: any) {
      if (this.isItemSelected(item)) {
        this.selectedItems = this.selectedItems.filter(selectedItem => selectedItem[this.itemValue] !== item[this.itemValue])
      } else {
        this.selectedItems.push(item)
      }
      const selectedItemsValues: string[] = this.selectedItems.map(selectedItem => selectedItem[this.itemValue])
      this.$emit('input', selectedItemsValues)
    },
    setValue () {
      this.selectedItems = this.items.filter(item => this.value?.includes(item[this.itemValue]))
    },
    itemLabel (item: any): string {
      return item[this.itemText]
    },
    clickInput (e) {
      if (this.menu) {
        e.preventDefault()
        e.stopPropagation()
      }
    }
  },
  watch: {
    value () {
      this.setValue()
    }
  }
}
