import { Task } from '../../domain/Task'

export const TasksStore = {
  namespaced: true,
  state: {
    dateSearch: '',
    usersSearch: '',
    stringSearch: '',
    task: null
  },
  mutations: {
    SET_DATE_SEARCH (state, dateSearch) {
      state.dateSearch = dateSearch
    },
    SET_SEARCH (state, stringSearch) {
      state.stringSearch = stringSearch
    },
    SET_USER_SEARCH (state, usersSearch) {
      state.usersSearch = usersSearch
    },
    SET_TASK (state: { [key: string]: any }, task: Task | null) {
      state.task = task
    }
  },
  actions: {
    setTask ({ commit }, task: Task | null) {
      commit('SET_TASK', task)
    },
    setFilters ({ commit }, filters: { [key: string]: any }) {
      if (filters) {
        commit('SET_DATE_SEARCH', filters?.date || '')
        commit('SET_SEARCH', filters?.searchBar || '')
        commit('SET_USER_SEARCH', filters?.userSearch || '')
      }
    }
  }
}
