


















































































































































import CreateFormConfig from './CreateFormConfig'
export default CreateFormConfig
