import { PartnersTabsEnum } from '@/modules/Partners/domain/ParnersTabsEnum'
import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import KeybeAvatar from '@/shared/infrastructure/ui/KeybeAvatar.vue'
import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'
import Colors from '@/utils/Colors'
import { CDPFieldsEnums } from '@/modules/CDP/shared/contacts/domain/enums/CDPFieldsEnums'
interface Item {
  name: PartnersTabsEnum,
  class: string,
  label: string,
  icon: string,
  color: string
}
export default {
  components: {
    KeybeAvatar,
    KeybeIcon
  },
  computed: {
    itemsGroups (): Item[][] {
      return [
        [
          {
            name: PartnersTabsEnum.GENERAL,
            class: 'black',
            label: this.$t(PartnersTabsEnum.GENERAL),
            icon: KeybeIconType.HAND_SHAKE_OUTLINE,
            color: Colors.kbGreen
          },
          {
            name: PartnersTabsEnum.CONTRACT,
            class: 'green',
            label: this.$t(PartnersTabsEnum.CONTRACT),
            icon: KeybeIconType.CONTRACT,
            color: Colors.black
          }
        ]
      ]
    }
  },
  data () {
    return {
      selectedTab: PartnersTabsEnum.GENERAL,
      CDPFieldsEnums
    }
  },
  methods: {
    isSelectedTab (tab: Item): boolean {
      return this.selectedTab === tab
    },
    selectTab (tab: Item): void {
      this.selectedTab = tab.name
      this.$emit('selectTab', tab.name)
    }
  }
}
