import { EventBus } from '@/eventBus'
import KeybeText from '@/modules/DesignSystem/infrastructure/components/KeybeText/KeybeText.vue'
import Loader from '@/shared/infrastructure/ui/Loader.vue'
import KeybeAutocomplete from '@/modules/DesignSystem/infrastructure/components/KeybeAutocomplete/KeybeAutocomplete/KeybeAutocomplete.vue'
import FeesUseCases from '@/modules/Fees/application/FeesUseCases'
import { datatoCreateDailyContactV2, dataToGetInvoices, datatoUpdateDailyContactV2 } from '@/modules/Fees/domain/FeesController'
import FeesController from '@/modules/Fees/infrastructure/controllers/Fees.controller'
import KeybeButton from '@/modules/DesignSystem/infrastructure/components/KeybeButton/KeybeButton.vue'
import Colors from '@/utils/Colors'
import { mapState } from 'vuex'

export default {
  name: 'KBPlansList',
  components: {
    KeybeAutocomplete,
    KeybeText,
    Loader,
    KeybeButton
  },
  data () {
    return {
      idItem: null,
      showForm: false,
      Colors,
      loading: true,
      status: null,
      showUpdate: false,
      formData: {
        label: '',
        dailyContact: 0,
        isDefault: false,
        description: '',
        status: 'draft',
        name: '',
        actions: 0,
        createdBy: ''
      },
      columns: [
        { key: 'id', label: 'id' },
        { key: 'label', label: 'plan' },
        { key: 'name', label: 'name' },
        { key: 'status', label: 'feesStatus' },
        { key: 'dailyContact', label: 'dailyContact' },
        { key: 'createdBy', label: 'createdBy' },
        { key: 'updatedBy', label: 'updatedBy' },
        { key: 'actions', label: 'actions' }
      ],
      data: [],
      feesController: new FeesController()
    }
  },
  mounted () {
    this.calculateSize(true)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.calculateSize)
  },
  computed: {
    ...mapState('UserStore', ['user', 'token'])
  },
  async created () {
    await this.getItems()
  },
  methods: {
    hiddenForm () {
      this.showForm = false
      this.showUpdate = false
      this.cleanForm()
    },
    cleanForm () {
      this.idItem = null
      this.formData = {
        label: null,
        dailyContact: 0,
        isDefault: false,
        description: null,
        status: false,
        name: null,
        createdBy: null
      }
    },
    updateItem (item) {
      this.showUpdate = true
      this.showForm = true
      this.idItem = item.id
      this.formData = {
        label: item.label,
        description: item.description,
        status: item.status === 'published',
        isDefault: item.isDefault,
        name: item.name,
        dailyContact: item.dailyContact,
        createdBy: item.createdBy || 'BD'
      }
    },
    async updateItemRequest () {
      try {
        this.data = []
        const form = this.formData
        const data: datatoUpdateDailyContactV2 = {
          id: this.idItem,
          token: this.token,
          label: form.label,
          description: form.description,
          status: form.status ? 'published' : 'draft',
          name: form.name,
          dailyContact: form.dailyContact,
          isDefault: form.isDefault,
          createdBy: this.user.uuid || 'BD'
        }
        const response = await FeesUseCases.patchDailyContactV2(this.feesController, data)
        if (response) {
          EventBus.$emit('toast', 'success', this.$t('itemSuccessfullyUpdated'))
          await this.getItems()
          this.hiddenForm()
        }
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
      }
    },
    async createdItem () {
      try {
        this.data = []
        const form = this.formData
        const data: datatoCreateDailyContactV2 = {
          token: this.token,
          label: form.label,
          description: form.description,
          status: form.status ? 'published' : 'draft',
          dailyContact: form.dailyContact,
          isDefault: form.isDefault,
          name: form.name,
          createdBy: this.user.uuid || 'BD'
        }
        const response = await FeesUseCases.postDailyContactV2(this.feesController, data)
        if (response) {
          EventBus.$emit('toast', 'success', this.$t('itemAddedSuccessfully'))
          await this.getItems()
          this.hiddenForm()
        }
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
      }
    },
    getValue (item, key) {
      const keys = key.split('.')
      let value = item
      for (const k of keys) {
        if (value && k in value) {
          value = value[k]
        } else {
          return null
        }
      }
      return value
    },
    async getItems () {
      try {
        this.data = []
        const data: dataToGetInvoices = {
          token: this.token,
          status: this.status
        }
        const response = await FeesUseCases.getDailyContactV2(this.feesController, data)

        if (response) {
          this.data = response.data
        }
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
      }
    },
    calculateSize (fullscreen = false) {
      if (fullscreen) {
        const clientHeight = document.documentElement.clientHeight
        const clientWidth = document.documentElement.clientWidth
        document.documentElement.style.setProperty(
          '--floating-screen-height',
          `${clientHeight - 30}px`
        )
        document.documentElement.style.setProperty(
          '--floating-screen-width',
          `${clientWidth - 50}px`
        )

        // set top and left to 0
        const floatingWindow = document.getElementById('KeybeFloatingBox')
        floatingWindow.style.top = '0px'
        floatingWindow.style.left = '0px'

        EventBus.$emit('FloatingWindow-resize')
        return
      }

      const clientHeight = document.documentElement.clientHeight
      this.totalHeight = clientHeight * 0.8097
      this.heightWithoutHeader = clientHeight * 0.8097 - 30
      document.documentElement.style.setProperty(
        '--floating-screen-height',
        `${this.heightWithoutHeader}px`
      )

      const clientWidth = document.documentElement.clientWidth
      this.width = clientWidth * 0.8
      document.documentElement.style.setProperty(
        '--floating-screen-width',
        `${this.width}px`
      )

      EventBus.$emit('FloatingWindow-resize')
    }
  }
}
