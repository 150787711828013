export const orderDataHeatMap = (arr, {
  lunes, martes, miercoles, jueves, viernes, sabado, domingo
}) => {
  const temp = arr
  if (!temp) { return [] }
  temp.forEach(element => {
    switch (element.day_of_the_week) {
      case 'Monday':
        lunes.push({
          x: element.hour_of_the_Day,
          y: element.total_count
        })
        break
      case 'Tuesday':
        martes.push({
          x: element.hour_of_the_Day,
          y: element.total_count
        })
        break
      case 'Wednesday':
        miercoles.push({
          x: element.hour_of_the_Day,
          y: element.total_count
        })
        break
      case 'Thursday':
        jueves.push({
          x: element.hour_of_the_Day,
          y: element.total_count
        })
        break
      case 'Friday':
        viernes.push({
          x: element.hour_of_the_Day,
          y: element.total_count
        })
        break
      case 'Saturday':
        sabado.push({
          x: element.hour_of_the_Day,
          y: element.total_count
        })
        break
      case 'Sunday':
        domingo.push({
          x: element.hour_of_the_Day,
          y: element.total_count
        })
        break
      default:
        console.log('invalid day')
    }
  })
  return temp
}
