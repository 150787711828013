export enum KeybeAiConfigStatesEnum {
  ENABLED = 'enabled',
  DISABLED = 'disabled',
}

export type KeybeAiConfigStates = KeybeAiConfigStatesEnum.ENABLED | KeybeAiConfigStatesEnum.DISABLED

export interface KeybeAiConfig {
  createAt: string,
  updateAt: string,
  state: KeybeAiConfigStates,
  _id: string
}

export interface MessageSuggestionsConfig extends KeybeAiConfig {
  availableFreeRequests: number
}
