import { IShortenerController } from '@/shared/domain/ShortenerController'
import ShortenerRepository from '../repositories/Shortener.repository'

export default class ShortenerController implements IShortenerController {
  repository: ShortenerRepository

  constructor () {
    this.repository = new ShortenerRepository()
  }

  async shortenUrl (url: string): Promise<string> {
    const response = await this.repository.shortenUrl(url)
    if (response.status) return response?.data?.shortUrl || ''
    else return response?.message || ''
  }
}
