<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       fill="none"
       viewBox="0 0 10 14">
    <path :fill="color"
          d="M6.234 14H2.98a.314.314 0 0 1-.23-.102.364.364 0 0 1-.095-.248v-.7c0-.093.034-.182.095-.247a.314.314 0 0 1 .23-.103h.95v-1.414a5.01 5.01 0 0 1-2.268-.924A5.493 5.493 0 0 1 .026 8.336a.385.385 0 0 1 0-.28.344.344 0 0 1 .064-.124.315.315 0 0 1 .105-.086l.593-.294a.299.299 0 0 1 .235-.015.326.326 0 0 1 .182.162c.341.644.835 1.18 1.43 1.553.596.372 1.273.569 1.962.57h.04a3.702 3.702 0 0 0 1.975-.603A4.085 4.085 0 0 0 8.03 7.622a.286.286 0 0 1 .1-.113.259.259 0 0 1 .258-.013l.683.343c.034.016.065.04.09.07a.28.28 0 0 1 .053.105.283.283 0 0 1-.052.231v.056a5.489 5.489 0 0 1-1.636 1.926 5.004 5.004 0 0 1-2.268.923v1.45h.976c.086 0 .169.037.23.103a.364.364 0 0 1 .095.247v.7a.364.364 0 0 1-.095.248.314.314 0 0 1-.23.102ZM4.607 8.4c-.69 0-1.352-.295-1.84-.82a2.911 2.911 0 0 1-.763-1.98V2.8c0-.743.275-1.455.763-1.98A2.513 2.513 0 0 1 4.607 0c.69 0 1.352.295 1.84.82.489.525.763 1.237.763 1.98v2.8c0 .743-.274 1.455-.762 1.98-.489.525-1.15.82-1.84.82Z"/>
  </svg>
</template>

<script>
export default {
  name: 'MicFilled',
  props: {
    color: {
      type: String,
      default: '#8898A9'
    }
  }
}
</script>

<style scoped>

</style>
