




























































































































































import ConfigExpandedBiky from './ConfigExpandedBiky'
export default ConfigExpandedBiky
