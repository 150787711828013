








export default {
  name: 'LinkIcon',
  props: {
    color: {
      type: String,
      default: '#8898A9'
    }
  }
}
