<template>
  <svg :width="width"
       :height="height"
       viewBox="0 0 16 11"
       fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <path d="M7.8 0C7.4675 0 7.2 0.262723 7.2 0.589286C7.2 0.915848 7.4675 1.17857 7.8 1.17857H8.4425L9.3075 2.75H5.5725L4.8875 1.81696C4.775 1.66228 4.5925 1.57143 4.4 1.57143H3C2.6675 1.57143 2.4 1.83415 2.4 2.16071C2.4 2.48728 2.6675 2.75 3 2.75H4.0925L4.645 3.50379L3.98 4.80759C3.73 4.74621 3.4675 4.71429 3.2 4.71429C1.4325 4.71429 0 6.12121 0 7.85714C0 9.59308 1.4325 11 3.2 11C4.8325 11 6.1775 9.79933 6.375 8.25H7.6C7.8125 8.25 8.0075 8.13951 8.1175 7.96027L10.2375 4.43683L10.78 5.42143C10.06 5.99844 9.6 6.875 9.6 7.85714C9.6 9.59308 11.0325 11 12.8 11C14.5675 11 16 9.59308 16 7.85714C16 6.12121 14.5675 4.71429 12.8 4.71429C12.4625 4.71429 12.1375 4.76585 11.8325 4.86161L9.385 0.4125C9.245 0.157143 8.975 0 8.68 0H7.8ZM11.465 6.67121L12.2725 8.13705C12.43 8.42433 12.795 8.52991 13.085 8.37522C13.375 8.22054 13.485 7.86205 13.3275 7.57723L12.52 6.11138C12.61 6.09665 12.705 6.08929 12.8 6.08929C13.795 6.08929 14.6 6.87991 14.6 7.85714C14.6 8.83437 13.795 9.625 12.8 9.625C11.805 9.625 11 8.83437 11 7.85714C11 7.40045 11.175 6.98549 11.465 6.67121ZM3.33 8.25H4.955C4.7725 9.03817 4.055 9.625 3.2 9.625C2.205 9.625 1.4 8.83437 1.4 7.85714C1.4 6.87991 2.205 6.08929 3.2 6.08929C3.2425 6.08929 3.285 6.09174 3.3275 6.0942L2.7225 7.28504C2.4975 7.72946 2.825 8.25246 3.33 8.25246V8.25ZM4.1725 7.07143L5.44 4.58415L7.2625 7.06897L7.26 7.07143H4.17H4.1725ZM6.4375 3.92857H9.1475L7.925 5.96161L6.435 3.92857H6.4375Z"
          :fill="color"/>
  </svg>
</template>
<script>
export default {
  name: 'BicycleIcon',
  props: {
    width: {
      type: Number,
      default: 16
    },
    height: {
      type: Number,
      default: 11
    },
    color: {
      type: String,
      default: '#B6BEC7'
    }
  }
}
</script>
