import { GenericResponse } from '@/shared/infrastructure/http/GenericResponse'
import KeybeRepository from '@/shared/infrastructure/repositories/KeybeRepository'
import { dataToGetTemplates, dataToGetWhatsAppProducts, dataToSendTemplates, dataToValidateUserGuest } from '../domain/TemplateController'
import { RestResponseFactory } from '@/shared/infrastructure/http/RestResponse'
import { HeadersBuilder } from '@/shared/infrastructure/http/HeadersBuilder'
import { StatusCodes } from '@/shared/infrastructure/http/StatusCodes'
import { nonGetOptions } from '@/shared/infrastructure/http/HttpClient'

export default class TemplateRepository extends KeybeRepository {
  baseUrl: string
  abortController: AbortController

  constructor () {
    super()

    this.baseUrl = process.env.VUE_APP_CONVERSATIONS_API_URL
    this.abortController = new AbortController()
  }

  dispose (): void {
    this.abortController.abort()
    this.abortController = new AbortController()
  }

  async sendTemplate (data: dataToSendTemplates): Promise<GenericResponse> {
    const url = `${this.baseUrl}/message/sms/proactivity`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    const requestOptions: nonGetOptions = {
      headers,
      url,
      data,
      abortController: this.abortController
    }

    return await this.client.post(requestOptions)
  }

  async validateUserGuest (data: dataToValidateUserGuest): Promise<GenericResponse> {
    try {
      const { userHost, userGuest, appId } = data
      const url = `${this.baseUrl}/rooms/roomByUser`
      const params = {
        appUUID: appId,
        userHost: userHost,
        userGuest: userGuest
      }
      const response = await this.client.get({
        url,
        query: params,
        abortController: this.abortController,
        headers: HeadersBuilder.buildSimpleAutorizationHeader(data)
      })
      return new RestResponseFactory().process({ response })
    } catch (e) {
      return new RestResponseFactory().process({ response: { status: StatusCodes.HTTP_500_INTERNAL_SERVER_ERROR } })
    }
  }

  async getTemplates (data: dataToGetTemplates): Promise<GenericResponse> {
    try {
      const { userHost, forceRefresh, appId } = data
      const url = `${this.baseUrl}/template`
      const params = {
        appUUID: appId,
        userHost: userHost,
        forceRefresh: forceRefresh
      }
      const response = await this.client.get({
        url,
        query: params,
        abortController: this.abortController,
        headers: HeadersBuilder.buildSimpleAutorizationHeader(data)
      })
      return new RestResponseFactory().process({ response })
    } catch (e) {
      return new RestResponseFactory().process({ response: { status: StatusCodes.HTTP_500_INTERNAL_SERVER_ERROR } })
    }
  }

  async getWhatsAppProducts (data: dataToGetWhatsAppProducts): Promise<GenericResponse> {
    const { appUUID, userHost } = data
    const url = `${this.baseUrl}/config/whatsapp/${appUUID}/phone/${userHost}/catalog?refresh=true`
    const response = await this.client.get({
      url,
      abortController: this.abortController,
      headers: HeadersBuilder.buildSimpleAutorizationHeader(data)
    })
    return new RestResponseFactory().process({ response })
  }
}
