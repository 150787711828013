import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import KBButton from '@/shared/infrastructure/ui/buttons/KBButton.vue'
import { QuickResponse, QuickResponseTypeEnum } from '../../../domain/QuickResponse'
import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'
import VideoPreview from '../VideoPreview/VideoPreview.vue'
import Colors from '@/utils/Colors'
import QuickResponsesUseCases from '../../../application/QuickResponsesUseCases'
import QuickResponsesController from '../../controllers/QuickResponses.controller'
import { dataToCreateQuickResponse, dataToDeleteQuickResponse, quickResponseCreator } from '../../../domain/QuickResponsesController'
import { mapState } from 'vuex'
import EmitToast from '@/utils/EmitToast'
import KBTextarea from '@/shared/infrastructure/ui/inputs/text/KBTextarea.vue'
import { ActivityActionTypeEnum, ActivityLogModuleTypeEnum, ActivitySubmoduleTypeEnum, dataToWriteActivityLog } from '@/modules/Config/ActivityLogs/domain/ActivityLogsController'
import { CDPFieldsEnums } from '@/modules/CDP/shared/contacts/domain/enums/CDPFieldsEnums'
import ActivityLogsUseCases from '@/modules/Config/ActivityLogs/application/ActivityLogsUseCases'
import ActivityLogsController from '@/modules/Config/ActivityLogs/infrastructure/controllers/ActivityLogs.controller'
export default {
  components: {
    KBButton,
    KeybeIcon,
    VideoPreview,
    KBTextarea
  },
  props: {
    quickResponse: {
      type: Object
    },
    quickResponses: {
      type: Array
    }
  },
  computed: {
    ...mapState('AppStore', ['selectedApp']),
    ...mapState('UserStore', ['token', 'user', 'isUserReadonly']),
    quickResponseIcon (): string {
      const labelMap: {[key: string]: string} = {
        [QuickResponseTypeEnum.TEXT]: KeybeIconType.ADD,
        [QuickResponseTypeEnum.IMAGE]: KeybeIconType.IMAGE,
        [QuickResponseTypeEnum.VIDEO]: KeybeIconType.VIDEO,
        [QuickResponseTypeEnum.PDF]: KeybeIconType.PDF
      }
      return labelMap[this.quickResponse.type] || KeybeIconType.ADD
    },
    isImage (): boolean {
      return this.quickResponse.type === QuickResponseTypeEnum.IMAGE
    },
    isPDF (): boolean {
      return this.quickResponse.type === QuickResponseTypeEnum.PDF
    },
    isVideo (): boolean {
      return this.quickResponse.type === QuickResponseTypeEnum.VIDEO
    },
    isText (): boolean {
      return this.quickResponse.type === QuickResponseTypeEnum.TEXT
    }
  },
  data () {
    return {
      KeybeIconType,
      Colors,
      loading: false,
      loadingDelete: false,
      isEdit: false,
      newValue: '',
      quickResponsesController: new QuickResponsesController(),
      activityLogsController: new ActivityLogsController()
    }
  },
  methods: {
    openFile (): void {
      window.open(this.quickResponse.value, '_blank').focus()
    },
    async deleteQuickResponse (isUpdate: boolean = false): Promise<boolean> {
      this.loadingDelete = true
      const data: dataToDeleteQuickResponse = {
        appUUID: this.selectedApp?.uuid,
        quickResponseId: this.quickResponse?._id,
        token: this.token
      }
      const response: quickResponseCreator = await QuickResponsesUseCases.deleteQuickResponse(this.quickResponsesController, data)
      if (!response.status) {
        EmitToast.emitErrors(response.message)
        this.loadingDelete = false
        return false
      }
      if (!isUpdate) this.$emit('getQuickResponses')
      this.loadingDelete = false
      this.writeActivityLogDelete()
      return true
    },
    async writeActivityLogDelete (): Promise<void> {
      const data: dataToWriteActivityLog = {
        token: this.token,
        updateInput: {
          appUUID: this.selectedApp?.uuid,
          module: ActivityLogModuleTypeEnum.CHAT,
          submodule: ActivitySubmoduleTypeEnum.QUICK_REPLIES,
          action: ActivityActionTypeEnum.QUICK_REPLY_DELETE,
          user: {
            id: this.user.uuid,
            name: this.user.name + ' ' + this.user.lastName
          },
          payload: {
            field: CDPFieldsEnums.NAME,
            newValue: this.quickResponse.name,
            oldValue: ''
          }
        }
      }
      this.writeActivityLog(data)
    },
    openEdit (): void {
      this.isEdit = true
      this.newValue = this.quickResponse?.value
    },
    checkResponseExists (): boolean {
      const filteredTextResponses: QuickResponse[] = this.quickResponses.filter(
        (quick: QuickResponse) => quick.type === QuickResponseTypeEnum.TEXT
      )
      const foundResponse: QuickResponse = filteredTextResponses.find(
        (quick: QuickResponse) => quick.value === this.newValue
      )

      if (foundResponse) return true
      return false
    },
    async updateQuickResponse (): Promise<void> {
      const responseExists: boolean = this.checkResponseExists()
      if (!responseExists) {
        this.loading = true
        const response: boolean = await this.deleteQuickResponse(true)
        this.isEdit = false
        if (response) {
          const data: dataToCreateQuickResponse = {
            token: this.token,
            createInput: {
              appUUID: this.selectedApp?.uuid,
              value: this.newValue,
              type: QuickResponseTypeEnum.TEXT,
              name: this.newValue
            }
          }
          await this.createResponse(data)
          await this.writeActivityLogUpdate()
        }
      } else EmitToast.emitErrors(this.$t('aQuickResponseWithThisValueAlreadyExists'))
    },
    async writeActivityLogUpdate (): Promise<void> {
      const data: dataToWriteActivityLog = {
        token: this.token,
        updateInput: {
          appUUID: this.selectedApp?.uuid,
          module: ActivityLogModuleTypeEnum.CHAT,
          submodule: ActivitySubmoduleTypeEnum.QUICK_REPLIES,
          action: ActivityActionTypeEnum.QUICK_REPLY_UPDATE,
          user: {
            id: this.user.uuid,
            name: this.user.name + ' ' + this.user.lastName
          },
          payload: {
            field: CDPFieldsEnums.NAME,
            newValue: this.newValue,
            oldValue: this.quickResponse.name
          }
        }
      }
      this.writeActivityLog(data)
    },
    async writeActivityLog (data): Promise<void> {
      await ActivityLogsUseCases.writeActivityLog(this.activityLogsController, data)
    },
    async createResponse (data: dataToCreateQuickResponse): Promise<void> {
      const response: quickResponseCreator = await QuickResponsesUseCases.createQuickResponse(this.quickResponsesController, data)
      if (!response.status) {
        EmitToast.emitErrors(response.message)
      }
      setTimeout(() => {
        this.loading = false
        this.$emit('getQuickResponses')
      }, 1000)
    }
  },
  beforeDestroy () {
    QuickResponsesUseCases.abort(this.quickResponsesController)
  }
}
