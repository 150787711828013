<template>
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 12 10"><path :fill="color" d="m1.254 4.558-.68.602a.824.824 0 0 1-.125.092c-.13.076-.277.055-.367-.046-.1-.112-.115-.297-.009-.404.175-.174.363-.335.549-.499L5.349.132c.197-.175.355-.176.551-.004l5.196 4.586c.136.12.178.236.13.366-.091.241-.307.287-.519.104-.21-.18-.416-.369-.623-.553l-.086-.073V8.338c0 .408-.1.78-.36 1.103-.255.315-.588.48-.98.532a3.905 3.905 0 0 1-.507.025c-1.754.002-3.508.003-5.263 0-.519 0-.972-.162-1.303-.588-.242-.311-.332-.669-.33-1.059.002-1.207 0-2.415 0-3.623v-.171.001ZM5.63.714c-.02.012-.027.016-.033.022C4.38 1.812 3.164 2.888 1.95 3.969a.278.278 0 0 0-.082.187 867.146 867.146 0 0 0-.002 4.196c0 .103.007.21.034.31.123.452.472.715.949.72.438.002.877 0 1.316 0h4.208c.498 0 .861-.273.974-.736.027-.109.036-.223.036-.335.002-1.373 0-2.746.004-4.119a.306.306 0 0 0-.115-.256c-.605-.53-1.205-1.064-1.807-1.596L5.63.714Z"/></svg>
</template>

<script>
export default {
  name: 'HomeIcon',
  props: {
    color: {
      type: String,
      default: '#B6BEC7'
    }
  }
}
</script>

<style>

</style>
