



























































































































































import AutoassignService from '@/modules/configuration/domain/services/Autoassign.service'
// TODO: migrate to new structure
// import ConversationsConfigController from '@/components/configuration/controllers/ConversationsConfigController'
import { AutoassignConfig, AutoassignType } from '@/modules/configuration/domain/models/Autoassign/Autoassign'
import ConversationsConfigService from '@/components/configuration/Services/ConversationsConfigService'
import KBSwitch from '@/shared/infrastructure/ui/switch/KBSwitch.vue'
import KBRadio from '@/shared/infrastructure/ui/inputs/select/KBRadio.vue'
import AutoassignTagsConfig from '@/modules/configuration/infrastructure/ui/components/Autoassign/Tags/AutoassignTagsConfig.vue'
import AutoassignGroupsConfig from '@/modules/configuration/infrastructure/ui/components/Autoassign/Groups/AutoassignGroupsConfig.vue'
import AutoassignAdvisorsConfig from '@/modules/configuration/infrastructure/ui/components/Autoassign/Advisors/OldConfig/AutoassignAdvisorsConfigOld.vue'
import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'
import { mapGetters, mapState } from 'vuex'
import ChatConfigController from '@/modules/ChatConfig/infrastructure/ChatConfig.controller'
import AlertAutoassign from './AlertAutoassign.vue'

export default {
  name: 'AutoassignConfig',
  components: {
    KBSwitch,
    KBRadio,
    AutoassignTagsConfig,
    AutoassignGroupsConfig,
    AutoassignAdvisorsConfig,
    AlertAutoassign,
    KeybeIcon
  },
  data () {
    return {
      limit: 0,
      config: null,
      inboxConfig: {},
      isBalancedMode: false,
      isLinearMode: false,
      AutoassignType,
      awaitingUpdate: false,
      useMemory: false,
      KeybeIconType,
      chatConfigController: new ChatConfigController(),
      showInboxConfig: false,
      showPopup: false
    }
  },
  methods: {
    async getAutoAssignConfig () {
      this.$emit('start-loading')
      const chatConfig = await this.chatConfigController.getAndSetConfig({
        appUUID: this.selectedApp?.uuid || '',
        token: this.token || ''
      })
      this.inboxConfig = chatConfig?.inbox
      if (this.inboxConfig) {
        if (!this.inboxConfig.useMemoryForAll) {
          this.inboxConfig.useMemoryForAll = false
          this.showInboxConfig = true
        }
        const rules = this.inboxConfig?.rules || []
        rules.forEach((rule) => {
          if (!rule.useMemory) {
            rule.useMemory = false
          }
        })
      }
      const autoassignConfig: AutoassignConfig = AutoassignService.buildAutoassignConfigFromObject(chatConfig)
      this.config = autoassignConfig
      this.limit = this.config.maxActiveConversationsNumberPerAdvisor || 0
      this.isBalancedMode = this.config.autoassignationType === AutoassignType.BALANCED
      this.isLinearMode = this.config.autoassignationType === AutoassignType.LINEAR
      this.$emit('stop-loading')
    },
    async saveConfig () {
      this.showInboxConfig = !this.inboxConfig.useMemoryForAll

      if (!this.awaitingUpdate) {
        this.awaitingUpdate = true
        setTimeout(async () => {
          this.config.maxActiveConversationsNumberPerAdvisor = parseInt(this.limit)

          this.config.inbox = this.inboxConfig

          await ConversationsConfigService.updateConversationsConfigV5(this.config)
          this.awaitingUpdate = false
        }, 1000)
      }
    },
    async saveChangeInAutoassign (selectedAction) {
      if (selectedAction === 'confirm') {
        await this.saveConfig()
        this.showPopup = false
      } else {
        return this.getAutoAssignConfig()
      }
    },
    changeConfig (selectedAction) {
      this.saveChangeInAutoassign(selectedAction)
      this.showPopup = false
    },
    revertChange (selectedAction) {
      this.saveChangeInAutoassign(selectedAction)
      this.showPopup = false
    }
  },
  mounted () {
    this.getAutoAssignConfig()
  },
  computed: {
    ...mapGetters('ChatConfigStore', ['hasInboxConfig', 'getConfig']),
    ...mapState('AppStore', ['selectedApp']),
    ...mapState('UserStore', ['token', 'isUserReadonly'])
  }
}
