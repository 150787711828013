import { VEmojiPicker } from 'v-emoji-picker'
import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'
import Colors from '@/shared/domain/colors/Color'

export default {
  name: 'TemplateTextEditor',
  props: {
    value: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      default: ''
    },
    small: {
      type: Boolean,
      default: false
    }
  },
  components: {
    KeybeIcon,
    VEmojiPicker
  },
  data () {
    return {
      text: '',
      KeybeIconType,
      selectedParam: '',
      paramOptions: ['username', 'adviserName'],
      grettingFormat: '',
      Colors,
      openEmojiPicker: false
    }
  },
  watch: {
    value (value) {
      this.text = value
    },
    text (value) {
      this.$emit('input', value)
    }
  },
  methods: {
    addParam () {
      const textarea = this.$refs.textarea
      if (!textarea) return

      // get caret position/selection
      const val = textarea.value
      const start = textarea.selectionStart
      const end = textarea.selectionEnd

      const paramToAdd = `{${this.selectedParam}}`
      const message = paramToAdd

      const newValue = val.substring(0, start) + message + val.substring(end)
      this.text = newValue
      this.openEmojiPicker = false
    },
    onSelectEmoji (emoji) {
      const textarea = this.$refs.textarea
      if (!textarea) return

      // get caret position/selection
      const val = textarea.value
      const start = textarea.selectionStart
      const end = textarea.selectionEnd

      const newValue = val.substring(0, start) + emoji.data + val.substring(end)
      this.text = newValue
    },
    saveGretting () {
      this.$emit('savegreetingMessage', this.text)
    }
  }
}
