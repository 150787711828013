<template>
  <div class="ImportDetail">
    <ImporterDownloadWarning
      v-if="showDownloadWarning"
      :downloadUrl="selectedDownloadUrl"
      @close="showDownloadWarning = false"/>
    <div class="d-flex justify-between mb-5">
      <KeybeButton
        :type="'flat'"
        :icon="'arrow-left'"
        :text="$t('goBack')"
        :color="$vuetify.theme.dark ? Colors.inputDark : Colors.inputLight"
        :textColor="$vuetify.theme.dark ? Colors.white : Colors.black"
        @click="$emit('closeImportDetail')"/>
      <KeybeButton
        :text="selectedImport.status !== 'created' ? $t('started') :$t('start')"
        :disabled="selectedImport.status !== 'created'"
        @click="$emit('startImport', selectedImport)"/>
    </div>

    <h4>{{$t('principalInformation')}}</h4>
    <div class="ImportDetail__container mt-5">
      <span>{{$t(CDPFieldsEnums.NAME)}}</span>
      <span>{{importName}}</span>
    </div>
    <div class="ImportDetail__container">
      <span>{{$t('journey')}}</span>
      <span>{{$t(selectedImport.status)}}</span>
    </div>
    <div class="ImportDetail__container">
      <span>{{$t('statusMessage')}}</span>
      <span>{{selectedImport.statusMessage || ' - '}}</span>
    </div>
    <div class="ImportDetail__container">
      <span>{{$t('segment')}}</span>
      <span>{{importSegment}}</span>
    </div>
    <div class="ImportDetail__container">
      <span>{{$t('responsible')}}</span>
      <span>{{selectedImport.statusMessage || ' - '}}</span>
    </div>
    <div class="ImportDetail__container">
      <span>{{$t('defaultCountry')}}</span>
      <span>{{selectedImport.defaultCountryCode || ' - '}}</span>
    </div>
    <div class="ImportDetail__container mb-5">
      <span>{{$t('file')}}</span>
      <div
        class="ImportDetail__button"
        @click="openDownloadWarning">
        <span>{{ $t('download') }}</span>
      </div>
    </div>

    <h4>{{$t('headers')}}</h4>
    <div class="d-flex justify-between mt-3 mb-5">
      <span>{{$t('field')}}</span>
      <span>{{$t('keybeField')}}</span>
    </div>
    <div class="ImportDetail__container"
         v-for="(header, index) in selectedImport.headers"
         :key="`${header.column}-${index}`">
      <span>{{header.column || ''}}</span>
      <span>{{$t(header.field) || ''}}</span>
    </div>

    <h4 class="my-5">{{$t('records')}}</h4>
    <div class="ImportDetail__container"
         v-for="(record, j) in Object.entries(selectedImport.records)"
         :key="j">
      <span>{{ record.length > 0 ? $t(record[0]) : ''}}</span>
      <div
        v-if="record[0] === ImportRecordEnum.WRONGS"
        class="d-flex items-center">
        <div
          v-if="errorsUrl"
          class="ImportDetail__container__link">
          <v-tooltip
            bottom
            max-width="300px">
            <template v-slot:activator="{ on, attrs }">
              <div
                v-on="on"
                v-bind="attrs"
                class="ImportDetail__button"
                @click="openDownloadWarning(ImportRecordEnum.WRONGS)">
                <span>{{ $t('download') }}</span>
              </div>
            </template>
            <span>{{ $t('importErrorInformation') }}</span>
          </v-tooltip>
        </div>
        <span class="ml-3">{{ record.length > 1 ? record[1] : ''}}</span>
      </div>
      <div
        v-else-if="record[0] === ImportRecordEnum.UPDATED"
        class="d-flex items-center">
        <div
          v-if="updatedUrl"
          class="ImportDetail__container__link">
          <v-tooltip
            bottom
            max-width="300px">
            <template v-slot:activator="{ on, attrs }">
              <div
                v-on="on"
                v-bind="attrs"
                class="ImportDetail__button"
                @click="openDownloadWarning(ImportRecordEnum.UPDATED)">
                <span>{{ $t('download') }}</span>
              </div>
            </template>
            <span>{{ $t('importUpdateInformation') }}</span>
          </v-tooltip>
        </div>
        <span class="ml-3">{{ record.length > 1 ? record[1] : ''}}</span>
      </div>
      <span v-else>{{ record.length > 1 ? record[1] : ''}}</span>
    </div>
  </div>
</template>
<script>
import ImportDetail from './ImportDetail'
export default ImportDetail
</script>
<style scoped lang="scss">
@import './ImportDetail.scss'
</style>
