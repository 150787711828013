import { render, staticRenderFns } from "./KBDateRangeMenu.vue?vue&type=template&id=cdff442e&scoped=true&"
import script from "./KBDateRangeMenu.vue?vue&type=script&lang=ts&"
export * from "./KBDateRangeMenu.vue?vue&type=script&lang=ts&"
import style0 from "./KBDateRangeMenu.vue?vue&type=style&index=0&id=cdff442e&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cdff442e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VExpandTransition } from 'vuetify/lib/components/transitions';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VDatePicker,VExpandTransition,VIcon})


/* vuetify-loader */
import installDirectives from "!../../../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import ClickOutside from 'vuetify/lib/directives/click-outside'
installDirectives(component, {ClickOutside})
