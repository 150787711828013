
import LottieAnimation from 'lottie-web-vue'
import { ConsumptionsTabs } from '@/modules/consumptions/infrastructure/enums/ConsumptionsTabs'
import { formatCurrency } from '@/modules/consumptions/application/FormatCurrency'
import Colors from '@/shared/domain/colors/Color'
import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import { EventBus } from '@/eventBus'
import { mapState, mapActions, mapGetters } from 'vuex'
import BillingValidations from '@/components/configuration/Billing/BillingValidations'
import BillingService from '@/components/configuration/Services/BillingService'
import { CURRENCY_TYPES, PAYMENT_TYPES, IPaymentIntent, PaymentService } from '@/components/configuration/Services/PaymentService'
import Loader from '@/shared/infrastructure/ui/Loader.vue'
import ChargeSVG from '@/shared/assets/ChargeSVG.vue'
import BillingPlansCardsInfo from '@/modules/consumptions/infrastructure/ui/components/BillingPlansCardsInfo/BillingPlansCardsInfo.vue'

import KBButton from '@/shared/infrastructure/ui/buttons/KBButton.vue'
import CreditCardSelector from '@/modules/consumptions/infrastructure/ui/components/CreditCardSelector/CreditCardSelector.vue'
// import ApplyPayment from '@/modules/consumptions/infrastructure/ui/components/ApplyPayment.vue'
import PlanUseCases from '@/modules/consumptions/application/PlanUseCases'
import { dataToCreditCardsMethod } from '@/modules/consumptions/domain/PlanController'
import PaymentController from '../../../controllers/Payment.controller'
import BillingAddCard from '@/modules/consumptions/infrastructure/ui/components/BillingAddCard/BillingAddCard.vue'
import { appUpdater } from '@/modules/Apps/domain/AppsController'
import AppsUseCases from '@/modules/Apps/application/AppsUseCases'
import EmitToast from '@/utils/EmitToast'
import link from '@/utils/links'
import AppsController from '@/modules/Apps/infrastructure/controllers/Apps.controller'

const minValueToRecharge: number = 20

export default {
  name: 'AddBalance',
  components: {
    ChargeSVG,
    // KeybeIcon,
    CreditCardSelector,
    BillingPlansCardsInfo,
    LottieAnimation,
    Loader,
    // ApplyPayment,
    BillingAddCard,
    KBButton
  },
  props: {
    paymentView: {
      type: Boolean,
      defaul: false
    },
    appInactive: {
      type: Boolean,
      defaul: false
    },
    noBalance: {
      type: Boolean
    },
    creditCards: {
      type: Array,
      required: true
    }
  },
  watch: {
    creditCards () {
      this.validateCommission()
    }
  },
  data () {
    return {
      appsController: new AppsController(),
      ConsumptionsTabs,
      KeybeIconType,
      Colors,
      selectedCard: null, // this.creditCards[0],
      amount: minValueToRecharge,
      amountError: false,
      cardError: false,
      error: '',
      changeToPro: false,
      loading: false,
      showPlanToggle: false,
      applyPayment: false,
      paySuccess: false,
      disabledAction: false,
      newCommission: false,
      cardsOption: [],
      planController: new PaymentController(),
      keybePlan: null,
      bikyPlan: null,
      bikyPlanBooking: null,
      keybePlanBooking: null
    }
  },
  methods: {
    formatCurrency,
    ...mapActions('BillingStore', ['setAppConfig']),
    async getActivePlan () {
      try {
        const data: dataToCreditCardsMethod = {
          token: this.token,
          appUUID: this.appUUID
        }
        const response = await PlanUseCases.getActiveBikyPlan(this.planController, data)
        if (response.status) {
          if (response?.data) {
            if (response.data.active === null || response.data.active === undefined) {
              this.keybePlan = 'On Demand'
            }
            const { active, booking } = response.data
            this.bikyPlan = active.tierLabel ? active.tierLabel.toLowerCase() : ''
            this.keybePlan = active.plan ? this.$t(`${active.plan.toLowerCase()}.title`) : 'On Demand'
            this.bikyPlanBooking = booking.tierLabel ? booking.tierLabel.toLowerCase() : ''
            this.keybePlanBooking = booking.plan ? this.$t(`${booking.plan.toLowerCase()}.title`) : 'On Demand'
          }
        } else {
          this.keybePlan = 'On Demand'
        }
      } catch (error) {
      }
    },
    loadAddBalance () {
      if (this.appInactive) {
        EventBus.$emit('Consumptions-plans', true)
      }
      if (!this.disabledAction) {
        this.changeOption = ConsumptionsTabs.PLANS
      }
    },
    validateCommission () {
      this.selectedCard = null
      if (this.creditCards.length > 0) {
        this.cardsOption = this.creditCards
        this.selectedCard = this.creditCards[0]
        this.newCommission = false
      } else {
        this.newCommission = true
      }
    },
    async getCreditCards () {
      if (this.paymentView) return
      const response = await PaymentService.getCreditCards()
      if (response.status) {
        this.cardsOption = response.data.map((card) => ({
          id: card.id,
          tokenResponse: {
            brand: card.card.brand,
            last4: card.card.last4
          }
        }))
        this.selectedCard = this.cardsOption[0]
      }
      this.loading = false
    },
    callSuccess () {
      this.paySuccess = true
      this.disabledAction = true
    },
    async updateBillingInfo () {
      const appConfig = await BillingService.getAppConfig()
      this.setAppConfig(appConfig)
    },
    addCreditCard () {
      EventBus.$emit('addCreditCard')
    },
    async showSuccess (message = 'paymentSuccess.recharge') {
      EventBus.$emit('toast', 'success', this.$t(message))
      await this.updateBillingInfo()
      this.stopLoading()
      this.paySuccess = true
      this.applyPayment = false
      this.setActiveApp()
    },
    showError (message = 'paymentError.noRecharge') {
      EventBus.$emit('toast', 'error', this.$t(message))
    },
    startLoading () {
      this.loading = true
      this.$emit('start-loading')
    },
    stopLoading () {
      this.$emit('stop-loading')
      this.loading = false
    },
    onAddBalance () {
      this.applyPayment = false
      this.loading = false
      this.getCreditCards()
    },
    goTo (link: string) {
      if (Object.keys(this.$route.query).length !== 0) {
        this.$router.replace({ path: this.$route.path, query: null })
      }
      if (this.$route.path === link) return

      this.$router.push(link)
    },
    async setActiveApp () {
      try {
        const app = {
          status: 'active',
          status_reason: 'add new balance',
          uuid: this.appUUID
        }
        const data = {
          app: app,
          token: this.token
        }
        const response: appUpdater = await AppsUseCases.updateAppInfo(this.appsController, data)
        if (response?.message) {
          EmitToast.emitErrors(response)
        } else {
          this.goTo(link.conversations)
          EventBus.$emit('toast', 'success', this.$t('appUpdated'))
        }
      } catch (e) {
        console.error(e)
      }
    },
    addPayment () {
      this.applyPayment = true
    },
    async pay () {
      if (this.loading) {
        return
      }
      this.startLoading()

      this.amountError = false
      this.cardError = false
      this.error = ''

      if (this.amount < minValueToRecharge) {
        this.amountError = true
        this.error = this.$t('balanceError').replace('{}', minValueToRecharge.toString())
        this.stopLoading()
        return
      }

      if (this.selectedCard) {
        const payment: IPaymentIntent = {
          type: PAYMENT_TYPES.BALANCE,
          total: this.amount * 100,
          currency: CURRENCY_TYPES.USD,
          uuid: '',
          description: `buy ${this.amount}${CURRENCY_TYPES.USD} to balance`,
          method: this.selectedCard.id
        }

        const response = await PaymentService.applyPayment(payment)
        if (response.status) {
          // Mover a otra vista
          setTimeout(() => {
            this.showSuccess()
          }, 3000)
          return
        }
        this.stopLoading()
        if (response.message) {
          this.showError(response.message)
        } else {
          this.showError()
        }
        return
      }

      this.applyPayment = true
    },
    handleChangeToPro (e: any) {
      this.changeToPro = e
    }
  },
  computed: {
    ...mapState('BillingStore', ['appConfig']),
    ...mapGetters('AppStore', ['getSelectedApp']),
    ...mapState('UserStore', ['token']),
    appUUID () {
      return this.getSelectedApp.uuid ? this.getSelectedApp.uuid : null
    },
    changeOption: {
      get () {
        return this.$store.state.BillingStore.changeOption || null
      },
      set (value) {
        this.$store.commit('BillingStore/SET_EDITING_OBJECT', {
          key: 'changeOption',
          value
        })
      }
    },
    changeOptionSave: {
      get () {
        return this.$store.state.BillingStore.changeOptionSave || null
      },
      set (value) {
        this.$store.commit('BillingStore/SET_EDITING_OBJECT', {
          key: 'changeOptionSave',
          value
        })
      }
    },
    planComputed () {
      if (!this.appConfig?.plan) {
        const data = {
          title: 'Plan On demand',
          subTitle: 'Daily Chats USD $0.1 + Carrier',
          cost: '0',
          id: 18
        }
        return data
      }
      const { label, id } = this.appConfig.plan
      // const titleCard = `${label} + ${this.bikyPlan}`
      const data = {
        title: label || '',
        subTitle: this.$t('currentPlan'),
        cost: '',
        id
      }
      return data || null
    },
    balanceComputed () {
      return this.appConfig?.balanceUSD || 0
    },
    title () {
      return this.noBalance ? this.$t('noBalanceTitle') : this.$t('addBalanceTitle')
    }
  },
  async mounted () {
    this.getActivePlan()
    this.changeOption = null
    if (this.changeOptionSave) {
      this.paySuccess = true
      this.changeOptionSave = false
    }
    this.validateCommission()
    const isPro = BillingValidations.isProfessional()
    if (!isPro) {
      this.showPlanToggle = true
    }
  }
}
