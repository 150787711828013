import { ProductDiscount } from '@/modules/catalog/domain/models/Product'
import { CDPFieldsEnums } from '@/modules/CDP/shared/contacts/domain/enums/CDPFieldsEnums'
import { KeybeTextTypeEnum } from '@/modules/DesignSystem/domain/models/KeybeTextTypeEnum'
import KeybeButton from '@/modules/DesignSystem/infrastructure/components/KeybeButton/KeybeButton.vue'
import KeybeSelect from '@/modules/DesignSystem/infrastructure/components/KeybeSelect/KeybeSelect/KeybeSelect.vue'
import KeybeText from '@/modules/DesignSystem/infrastructure/components/KeybeText/KeybeText.vue'
import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'
import Colors from '@/utils/Colors'
import KeybeMoney from '@/modules/DesignSystem/infrastructure/components/KeybeMoney/KeybeMoney.vue'
export default {
  components: {
    KeybeButton,
    KeybeSelect,
    KeybeText,
    KeybeIcon,
    KeybeMoney
  },
  props: {
    value: {
      type: Object
    },
    discountTypes: {
      type: Array
    },
    currency: {
      type: String
    }
  },
  mounted () {
    if (this.value && Object.keys(this.value).length > 0) {
      this.setInitialValue()
    }
  },
  data () {
    return {
      localDiscountPrices: {},
      localDiscountTypes: [],
      discounts: [],
      percentage: [
        'percentage_discounts',
        'quantity_discounts'
      ],
      validation: [
        'fixed_amount_discounts',
        'quantity_discounts'
      ],
      loading: false,
      CDPFieldsEnums,
      KeybeIconType,
      KeybeTextTypeEnum,
      Colors
    }
  },
  methods: {
    setInitialValue (): void {
      this.loading = true
      this.discounts = []
      Object.keys(this.value).forEach((key: string) => {
        const found = this.discountTypes.find((discount: ProductDiscount) => discount.key === key)
        let value = this.value[key].value
        if (this.percentage.includes(key)) {
          value = value * 100
        }
        this.discounts.push({
          key: key,
          displayName: this.value[key].displayName,
          longDescription: this.value[key].longDescription,
          displayNameTranslated: found.displayNameTranslated,
          longDescriptionTranslated: found.longDescriptionTranslated,
          value: value,
          validationValue: this.value[key].validationValue
        })
      })
      this.loading = false
    },
    addDiscount (): void {
      this.discounts.push({
        key: '',
        displayName: '',
        longDescription: '',
        displayNameTranslated: '',
        longDescriptionTranslated: '',
        value: 0,
        validationValue: 0
      })
    },
    removeDiscount (index: number): void {
      this.discounts.splice(index, 1)
      this.localDiscountTypes = this.discountTypes.filter((discount: ProductDiscount) => !this.discounts.some((d: ProductDiscount) => d.key === discount.key))
      this.setDiscountAmount()
    },
    setDiscountAmount (): void {
      this.localDiscountPrices = {}
      this.discounts.forEach((discount: ProductDiscount) => {
        let value = discount.value
        if (this.percentage.includes(discount.key)) {
          value = discount.value / 100
        }
        this.localDiscountPrices[discount.key] = {
          displayName: discount.displayName,
          longDescription: discount.longDescription,
          value: +value,
          key: discount.key,
          validationValue: +discount.validationValue
        }

        if (!discount.validationValue) delete this.localDiscountPrices[discount.key].validationValue
      })
      this.$emit('input', this.localDiscountPrices)
    },
    setDiscount (selectedDiscount: ProductDiscount, index: number): void {
      this.loading = true
      this.discounts[index] = {
        ...selectedDiscount,
        value: null
      }

      this.localDiscountTypes = this.localDiscountTypes.filter((discount: ProductDiscount) => discount.key !== selectedDiscount.key)
      setTimeout(() => {
        this.loading = false
      }, 100)
    }
  },
  watch: {
    discountTypes: {
      immediate: true,
      handler (): void {
        if (this.discountTypes) {
          this.loading = true
          this.localDiscountTypes = this.discountTypes
          this.loading = false
        }
      }
    },
    currency: {
      immediate: true,
      handler (): void {
        if (this.currency) {
          this.loading = true
          setTimeout(() => {
            this.loading = false
          }, 100)
        }
      }
    }
  }
}
