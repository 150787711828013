import { EventBus } from '@/eventBus'

export default class EmitToast {
  static emitErrors (response: {[key: string]: any} | string): void {
    if (typeof response === 'string') {
      EventBus.$emit('toast', 'error', response)
    } else if (response?.message && Array.isArray(response?.message)) {
      response.message.forEach(message => {
        EventBus.$emit('toast', 'error', message)
      })
    } else if (response?.message) {
      EventBus.$emit('toast', 'error', response?.message)
    }
  }

  static emitSuccess (message: string): void {
    EventBus.$emit('toast', 'success', message)
  }
}
