<template>
  <svg width="12"
       height="10"
       viewBox="0 0 12 10"
       fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <path d="M5.98769 9.00082C4.5283 9.00082 3.06891 9.00207 1.60952 9.0002C1.30363 9.0002 1.00588 8.94765 0.741898 8.78376C0.335296 8.53104 0.0832032 8.1701 0.0175213 7.69031C0.00438496 7.59273 0.00313388 7.49264 0.00250834 7.39381C0.0018828 5.46464 0.00563604 3.53672 6.17146e-06 1.60818C-0.00124491 1.07084 0.187669 0.634833 0.613037 0.304547C0.888275 0.0906124 1.2098 0.00428771 1.54822 0.00366216C4.51329 -9.10835e-05 7.47898 -0.00196771 10.4447 0.00303662C11.3317 0.00428771 11.9873 0.6536 11.9923 1.54625C12.0023 3.51608 12.0029 5.48653 11.9923 7.45636C11.9873 8.36089 11.3211 8.99895 10.4128 9.0002C8.93774 9.0027 7.46271 9.0002 5.98769 9.0002V9.00082ZM6.00207 0.743677C4.53768 0.743677 3.07392 0.743677 1.60952 0.743677C1.53133 0.743677 1.45251 0.743677 1.37557 0.753686C1.0334 0.797474 0.778805 1.04769 0.75566 1.39299C0.734392 1.71452 0.737519 2.03792 0.739396 2.3607C0.739396 2.40699 0.780056 2.46767 0.820091 2.49707C2.31576 3.59239 3.81205 4.68709 5.31335 5.77553C5.76687 6.10394 6.23478 6.10081 6.68892 5.77178C8.18521 4.68584 9.67775 3.59489 11.1684 2.50145C11.2172 2.46579 11.2547 2.38447 11.2572 2.32254C11.2673 2.07295 11.2654 1.82211 11.2597 1.57252C11.2572 1.46931 11.2491 1.36234 11.2191 1.26475C11.1127 0.916327 10.8525 0.744303 10.4415 0.744303C8.96151 0.743052 7.4821 0.744303 6.00207 0.744303V0.743677ZM0.745026 3.3697C0.740021 3.43976 0.735643 3.47479 0.735643 3.50982C0.735643 4.81282 0.734392 6.11583 0.736894 7.4182C0.736894 7.52642 0.747528 7.63839 0.778805 7.74161C0.884521 8.09316 1.14412 8.25831 1.57387 8.25831C4.51829 8.25831 7.46271 8.25831 10.4071 8.25831C10.9701 8.25831 11.2585 7.97869 11.2597 7.41633C11.2635 6.11895 11.261 4.82096 11.2604 3.52358C11.2604 3.48105 11.2529 3.43913 11.2472 3.37846C11.1828 3.42225 11.139 3.44977 11.0977 3.48042C9.7841 4.4375 8.47109 5.3952 7.15745 6.35228C6.38616 6.91402 5.60611 6.91277 4.83669 6.35103C3.91089 5.67544 2.98446 5.00049 2.05866 4.32615C1.63017 4.01401 1.20042 3.70186 0.7444 3.37033L0.745026 3.3697Z"
          :fill="color"/>
  </svg>

</template>

<script>
export default {
  name: 'SimpleMailIcon',
  props: {
    color: {
      type: String,
      default: '#8898A9'
    }
  }
}
</script>

<style scoped>

</style>
