<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       fill="none"
       viewBox="0 0 25 28"><path :fill="color"
                                 d="M19.081 1.79a1.443 1.443 0 0 0-2.645-.28l-1.32 2.44A11.5 11.5 0 0 1 8.28 9.492l-.375.111-1.378.41-4.135 1.226a2.878 2.878 0 0 0-1.938 3.574l1.227 4.134a2.877 2.877 0 0 0 3.574 1.938l1.635 5.512a1.436 1.436 0 0 0 1.787.969l2.756-.818a1.436 1.436 0 0 0 .97-1.787l-1.636-5.512.375-.11a11.5 11.5 0 0 1 8.753.916l2.435 1.32c.513.279 1.138.22 1.59-.149.454-.368.641-.968.475-1.527l-1.884-6.352c.688-.617.963-1.808.606-3.01-.356-1.201-1.236-2.05-2.15-2.192L19.081 1.79Zm-1.776 4.12 1.678 5.654 1.677 5.654a14.37 14.37 0 0 0-10.336-.836l-.375.111-1.226-4.134.374-.11a14.37 14.37 0 0 0 8.208-6.339Z"/></svg>
</template>

<script>
export default {
  name: 'MegaphoneIcon',
  props: {
    color: {
      type: String,
      default: '#CDD1D5'
    }
  }
}
</script>

<style>

</style>
