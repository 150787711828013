import KeybeRepository from '@/shared/infrastructure/repositories/KeybeRepository'
import { GenericResponse } from '@/shared/infrastructure/http/GenericResponse'
import { HeadersBuilder } from '@/shared/infrastructure/http/HeadersBuilder'
import {
  getOptions,
  nonGetOptions
} from '@/shared/infrastructure/http/HttpClient'
import {
  dataToCountNotifications,
  dataToCreateNotification,
  dataToGetAllNotifications,
  dataToGetNotifications,
  dataToMarkNotificationAsViewed
} from '../../domain/NotificationController'

export default class NotificationRepository extends KeybeRepository {
  notificationsAbortController: AbortController

  constructor () {
    super()

    this.notificationsAbortController = new AbortController()
  }

  abortController (): void {
    this.notificationsAbortController.abort()
    this.notificationsAbortController = new AbortController()
  }

  async getNotifications (
    data: dataToGetNotifications
  ): Promise<GenericResponse> {
    const { app, offset, limit, audience, userId } = data
    const url: string = `${process.env.VUE_APP_CRM_NOTIFICATION_MANAGER}/notifications`
    const headers: { [key: string]: string | number } = HeadersBuilder.buildSimpleAutorizationHeader(data)
    const query: { [key: string]: string | number } = {
      app,
      offset,
      limit,
      audience
    }

    if (userId) {
      query.userId = userId
    }

    const requestOptions: getOptions = {
      headers,
      query,
      url,
      abortController: this.notificationsAbortController
    }

    return await this.client.get(requestOptions)
  }

  async getAllNotifications (
    data: dataToGetAllNotifications
  ): Promise<GenericResponse> {
    const { offset, limit } = data
    const url: string = `${process.env.VUE_APP_CRM_NOTIFICATION_MANAGER}/notifications/all`
    const headers: { [key: string]: string | number } = HeadersBuilder.buildSimpleAutorizationHeader(data)
    const query: { [key: string]: string | number } = {
      offset,
      limit
    }

    const requestOptions: getOptions = {
      headers,
      query,
      url,
      abortController: this.notificationsAbortController
    }

    return await this.client.get(requestOptions)
  }

  async createNotification (
    data: dataToCreateNotification
  ): Promise<GenericResponse> {
    const url = `${process.env.VUE_APP_CRM_NOTIFICATION_MANAGER}/notifications`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    const requestOptions: nonGetOptions = {
      headers,
      url,
      abortController: this.notificationsAbortController
    }

    return await this.client.post(requestOptions)
  }

  async markNotificationAsViewed (
    data: dataToMarkNotificationAsViewed
  ): Promise<GenericResponse> {
    const { id } = data

    const url = `${process.env.VUE_APP_CRM_NOTIFICATION_MANAGER}/notifications/${id}/viewed`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    const requestOptions: nonGetOptions = {
      headers,
      url,
      abortController: this.notificationsAbortController
    }

    return await this.client.post(requestOptions)
  }

  async countNotifications (
    data: dataToCountNotifications
  ): Promise<GenericResponse> {
    const { app } = data

    // const url = `${process.env.VUE_APP_CRM_NOTIFICATION_MANAGER}/notifications/${app}/${userId}/counters`
    const url = `${process.env.VUE_APP_CRM_NOTIFICATION_MANAGER}/notifications/${app}/counters`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    const requestOptions: getOptions = {
      headers,
      url,
      abortController: this.notificationsAbortController
    }

    return await this.client.get(requestOptions)
  }
}
