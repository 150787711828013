import { Task, TaskStatusEnum } from '@/modules/tasks/domain/Task'
import Colors from '@/shared/domain/colors/Color'
import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'

import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'

export default {
  name: 'TaskStatus',
  components: {
    KeybeIcon
  },
  props: {
    task: {
      required: true
    }
  },
  data () {
    return {
      Colors,
      KeybeIconType
    }
  },
  computed: {
    taskComputed (): Task {
      return this.task
    },
    isExpired () {
      return this.taskComputed?.status === TaskStatusEnum.EXPIRED
    },
    isCompleted () {
      return this.taskComputed?.status === TaskStatusEnum.DONE
    },
    label () {
      if (this.isCompleted) {
        return this.$t('completed')
      }

      if (this.isExpired) {
        return this.$t('taskExpired')
      }

      return this.$t('expire')
    },
    indicatorClass () {
      const baseClass = 'TaskStatus__indicator'

      if (this.isCompleted) {
        return `${baseClass}--completed`
      }

      if (this.isExpired) {
        return `${baseClass}--expired`
      }

      return `${baseClass}--greenBorder`
    },
    indicatorLabel () {
      if (this.isCompleted) {
        return this.$t('completed')
      }

      if (this.isExpired) {
        const today = new Date()
        const taskDate = new Date(this.task.date)
        const diffTime = Math.abs(taskDate.getTime() - today.getTime())
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))

        if (diffDays === 0) {
          return this.$t('today')
        }

        if (diffDays === 1) {
          return `${diffDays} ${this.$t('day')}`
        }

        return `${diffDays} ${this.$t('days')}`
      }

      const today = new Date()
      const taskDate = new Date(this.task.date)
      const diffTime = Math.abs(taskDate.getTime() - today.getTime())
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))

      if (diffDays === 0) {
        return this.$t('today')
      }

      if (diffDays === 1) {
        return `${diffDays} ${this.$t('day')}`
      }

      return `${diffDays} ${this.$t('days')}`
    }
  }
}
