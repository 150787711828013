











































import GeneralUserConfigurationUserInfo from './GeneralUserConfigurationUserInfo'
export default GeneralUserConfigurationUserInfo
