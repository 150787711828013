<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       fill="none"
       viewBox="0 0 18 19">
    <path :fill="color"
          d="M12.86 5.139h.323c1.357 0 2.714-.002 4.071 0 .444 0 .678.168.732.509.074.458-.204.758-.724.76-1.134.004-2.267.001-3.401.001-.491 0-.982.002-1.473 0-.54-.002-.782-.228-.783-.759a755.574 755.574 0 0 1 0-4.902c.002-.566.35-.854.829-.712.228.067.367.214.402.449.018.123.023.248.023.373.002 1.313 0 2.625 0 3.938v.342h.002ZM12.86 12.887v4.369c0 .456-.131.655-.465.738-.359.09-.668-.084-.758-.442a1.532 1.532 0 0 1-.033-.372c-.002-1.563-.002-3.125 0-4.688 0-.68.198-.878.875-.879h4.767c.495 0 .751.222.752.635 0 .407-.263.634-.755.635-1.16.004-2.32 0-3.481 0h-.903v.004ZM5.139 5.166v-.36c0-1.331-.002-2.662 0-3.994 0-.462.137-.667.483-.757.36-.095.692.115.751.479.019.113.02.231.02.347.002 1.564.002 3.127 0 4.69.002.68-.19.868-.878.868-1.573 0-3.145.007-4.717-.009-.207-.002-.471-.06-.61-.194-.362-.348-.17-.971.308-1.039.115-.016.23-.029.345-.029 1.322-.002 2.645 0 3.966 0h.332v-.002ZM5.153 12.875H2.505c-.59 0-1.179.007-1.768-.002-.556-.007-.858-.433-.656-.912.113-.271.353-.353.609-.355 1.68-.008 3.358-.009 5.038 0 .46.002.676.219.678.683.009 1.68.008 3.36 0 5.039-.002.435-.249.69-.622.69-.37 0-.626-.278-.628-.721-.006-1.358-.003-2.716-.003-4.075v-.348.001Z"/>
  </svg>
</template>

<script>
export default {
  name: 'ShrinkIcon.vue',
  props: {
    color: {
      type: String,
      default: '#B6BEC7'
    }
  }
}
</script>

<style scoped>

</style>
