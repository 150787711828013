








export default {
  name: 'SimpleChatDarkIcon'
}
