import { CurrencyEnum } from '@/components/configuration/Billing/CurrencyEnum'
import store from '@/store'

export const formatCurrency = (value: number | string, currency?: CurrencyEnum) => {
  if (typeof value !== 'number') {
    return value?.toString() || ''
  }
  let cur = CurrencyEnum.USD
  if (currency) cur = currency
  else cur = store.getters['AppStore/getCurrencyApp'] || CurrencyEnum.USD

  if (cur === CurrencyEnum.COP) {
    const formatter = new Intl.NumberFormat('es-CO', {
      style: 'currency',
      currency: cur
    })

    const format = formatter.format(value).split(',')[0]

    return `${cur} $${format.replace('$ ', '')}`
  }

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: cur
  })

  if (cur === CurrencyEnum.USD) return `${cur} ${formatter?.format(value)}`
  else return `${cur} $${formatter?.format(value)?.split('$')[1] || '0.00'}`
}
