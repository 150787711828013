import ApexCharts from 'apexcharts'
export default {
  name: 'GraphExpensesUSD',
  props: ['totalConsumption', 'treeMapData', 'dateString'],
  components: {},
  data: function () {
    return {
      treeMapChart: null
    }
  },
  computed: {
  },
  watch: {
    dateString () {
      setTimeout(() => {
        if (this.treeMapData.length > 0) {
          this.buildTreeMapGraph(this.treeMapData)
        } else {
          if (this.treeMapChart) {
            this.treeMapChart.destroy()
          }
        }
      }, 1000)
    }
  },
  methods: {
    getTreeMapGraphData (rawData) {
      const labels = rawData.map((item) => item.x)
      const series = rawData.map((item) => item.y)

      return {
        series: series,
        labels: labels,
        dataLabels: {
          enabled: true
        },
        chart: {
          type: 'pie',
          top: 0,
          offsetY: 0,
          height: 500
        },
        legend: {
          position: 'right',
          top: 0,
          offsetY: 0,
          height: 500
        }
      }
    },
    buildTreeMapGraph (rawData) {
      if (this.treeMapChart) {
        this.treeMapChart.destroy()
      }

      const options = this.getTreeMapGraphData(rawData)

      this.treeMapChart = new ApexCharts(document.querySelector('#ConsumptionsGraph__graph'), options)
      this.treeMapChart.render()
    },
    formatCurrency (value, currency) {
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency
      })
      return formatter.format(value)
    }
  },
  mounted () {
    if (this.treeMapData.length > 0) {
      this.buildTreeMapGraph(this.treeMapData)
    } else {
      if (this.treeMapChart) {
        this.treeMapChart.destroy()
      }
    }
  }
}
