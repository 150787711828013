



































































import SalesCard from './SalesCard'
export default SalesCard
