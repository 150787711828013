import { mapGetters, mapState } from 'vuex'

import KBText from '@/shared/infrastructure/ui/inputs/text/KBText.vue'
import KBDate from '@/shared/infrastructure/ui/inputs/select/KBDate/KBDate.vue'
import KBTime from '@/shared/infrastructure/ui/inputs/text/KBTime/KBTime.vue'
import KBAutocomplete from '@/shared/infrastructure/ui/inputs/select/KBAutocomplete.vue'
import KBTextarea from '@/shared/infrastructure/ui/inputs/text/KBTextarea.vue'
import ContactsUseCases from '@/modules/CDP/shared/contacts/application/ContactsUseCases'
import { EntityTypeEnum } from '@/modules/CDP/shared/contacts/domain/Contacts'
import ContactsController from '@/modules/CDP/shared/contacts/infrastructure/controllers/Contacts.controller'
import { contactsGetter } from '@/modules/CDP/shared/contacts/domain/ContactsController'
import { Contact } from '@/modules/CDP/shared/contacts/domain/Contact'
import { DateTime } from 'luxon'
import { CDPFieldsEnums } from '@/modules/CDP/shared/contacts/domain/enums/CDPFieldsEnums'
export default {
  name: 'CreateTaskForm',
  components: {
    KBText,
    KBAutocomplete,
    KBTime,
    KBDate,
    KBTextarea
  },
  props: ['value'],
  data () {
    return {
      CDPFieldsEnums,
      contactsController: new ContactsController(),
      dateRaw: new Date(),
      date: '',
      time: '',
      users: [],
      types: [],
      selectedUser: null,
      responsible: null,
      createInput: {
        title: '',
        responsible: {
          uuid: '',
          phone: '',
          name: '',
          email: ''
        },
        priority: 'medium',
        inactive: false,
        description: '',
        date: '',
        contact: {
          uuid: '',
          name: ''
        }
      }
    }
  },
  computed: {
    ...mapGetters('AppStore', ['isCDPApp']),
    ...mapState('CompanyStore', ['administrators']),
    ...mapState('AppStore', ['selectedApp']),
    ...mapState('UserStore', ['token']),
    todayDate () {
      const date = this.dateRaw
      return {
        day: date.getDate(),
        month:
          date.getMonth() + 1 < 10
            ? '0' + +(date.getMonth() + 1)
            : date.getMonth() + 1,
        year: date.getFullYear()
      }
    },
    todayHour (): { [key: string]: string | number } {
      const date: Date = this.dateRaw
      let hour: string | number = date.getHours()
      let minute: string | number = date.getMinutes()
      if (minute < 10) minute = '0' + minute
      if (hour < 10) hour = '0' + hour
      return {
        hour,
        minute
      }
    }
  },
  methods: {
    async getUsers (search: string) {
      const variables = {
        app: this.selectedApp?.uuid,
        typeEntity: EntityTypeEnum.PEOPLE,
        token: this.token,
        limit: 20,
        offset: 0,
        search: search
      }

      if (!search) delete variables.search

      const response: contactsGetter = await ContactsUseCases.getContacts(
        this.contactsController,
        variables
      )
      this.users = (response?.contacts || []).map((user: Contact) => ({
        ...user,
        fullname: `${user.name} ${user.surname || ''}`
      }))
    },
    updateTime (time: string): void {
      this.time = time
      this.updateData()
    },
    updateDate (date: any): void {
      this.date = this.rawDateFormat(date)
      this.updateData()
    },
    rawDateFormat (obj: any): string {
      const month: string =
        obj.month.toString().length === 1 ? '0' + obj.month : obj.month
      const day: string =
        obj.day.toString().length === 1 ? '0' + obj.day : obj.day
      return obj.year + '-' + month + '-' + day
    },
    updateData () {
      this.createInput.responsible.uuid = this.responsible?.userId || this.responsible?.uuid || ''
      this.createInput.responsible.name = this.responsible?.name || ''
      this.createInput.responsible.email = this.responsible?.email || ''
      this.createInput.responsible.phone = this.responsible?.phone || ''

      const dateTime: DateTime = DateTime.fromISO(this.date + 'T' + this.time)
      const datetimeUTC: DateTime = dateTime.toUTC()

      this.createInput.date = datetimeUTC.toFormat('yyyy-MM-dd\'T\'HH:mm:ss')

      this.createInput.contact = {
        uuid: this.isCDPApp ? this.selectedUser?.uuid : this.selectedUser?.UUID,
        name: this.isCDPApp
          ? `${this.selectedUser?.name} ${this.selectedUser?.surname || ''}`
          : this.selectedUser?.fullName
      }
      this.$emit('input', this.createInput)
    }
  },
  async mounted (): Promise<void> {
    this.createInput = this.value
    await this.getUsers()

    this.date = this.rawDateFormat(this.todayDate)
    this.time = this.todayHour.hour + ':' + this.todayHour.minute + ':00'
  }
}
