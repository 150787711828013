<template>
  <div class="contentCreate"
       :class="$vuetify.theme.dark ? 'contentDark' : 'modeLight'">
    <div style="justify-content: start;align-items: start;width: 90px;">
      <KBButton :text="$t('goBack')"
                :type="'flat'"
                :color="$vuetify.theme.dark ? 'white' : 'black'"
                :icon="'arrow-left'"
                @click="onCreate = !onCreate"
                class="pa-0"/>
    </div>
    <div class="formPersonCommision">
      <span style="font-weight: 600;">
        {{ $t('createPerson') }}
      </span>
      <div class="d-flex">
        <div class="contentFormCommission mr-4">
          <span>
            {{ $t(CDPFieldsEnums.NAME) }}
          </span>
          <KBText
            class="KBform smalForm"
            v-model="name"/>
        </div>
        <div class="contentFormCommission">
          <span>
            {{ $t('email') }}
          </span>
          <KBText
            class="KBform smalForm"
            v-model="email"/>
        </div>
      </div>
      <div class="d-flex pb-2">
        <div class="contentFormCommission mr-4">
          <span>
            {{ $t(CDPFieldsEnums.PHONE) }}
          </span>
          <!-- <KBText
            class="KBform smalForm"
            v-model="phone"/> -->
          <div style="position: relative;">
            <vue-tel-input
              filled
              dense
              rounded
              hide-details="auto"
              height="53px"
              background-color="white"
              :preferred-countries="preferred"
              :valid-characters-only="true"
              :enabledCountryCode="true"
              required
              :value="localValue.phone"
              :auto-default-country="false"
              :defaultCountry='localValue.code'
              :disabled="readonly"
              @close="$emit('close')"
              @country-changed="onCountryChange"
              @input="onInput"
              @open="$emit('open')"
              v-bind="bindProps"
              :dropdownOptions="{
                showDialCodeInList:true,
                showFlags:true,
                showSearchBox:true,
              }"
              :inputOptions="{type: 'tel'}"
            />
          </div>
        </div>
        <div class="contentFormCommission">
          <span>
            {{ $t(CDPFieldsEnums.CATEGORY) }}
          </span>
          <KBAutocomplete
            :disabled="categoryData.length === 0 ? true : false"
            class="KBform smalForm"
            :itemValue="SalesFieldsEnum._ID"
            :itemText="CDPFieldsEnums.NAME"
            :items="categoryData"
            v-model="category"/>
        </div>
      </div>
      <div class="d-flex pb-2">
        <div class="contentFormCommission">
          <span>
            {{ $t('date') }}
          </span>
          <DatePickerRow />
        </div>
      </div>
      <div class="scrollContentitemCommission"
           v-if="monthArray.length > 0">
        <div class="contentitemCommission"
             v-for=" item in monthArray"
             :key="item.id">
          <div class="headerCommission"
               @click="item.active = ! item.active">
            <span style="font-weight: 600;">
              {{ $t('month') }} {{ item.m }}
            </span>
            <!-- <button @click="item.active = ! item.active">close</button> -->
          </div>
          <v-expand-transition>
            <div v-if="item.active">
              <div class="itemCommission pa-0">
                <span>
                  {{ $t('month') }}
                </span>
                <KBText
                  class="KBform smalForm itemFormText"
                  v-model="item.m"/>
              </div>
              <div class="itemCommission">
                <span>
                  {{ $t('percentage') }}
                </span>
                <div class="d-flex">
                  <v-slider
                    class="sliderCommission"
                    hint="Im a hint"
                    max="50"
                    min="0"
                    v-model="item.p"
                  ></v-slider>
                  {{ item.p }}%
                </div>
              </div>
              <div class="itemCommission">
                <span>
                  {{ $t('Extra') }}
                </span>
                <div class="d-flex">
                  <v-slider
                    class="sliderCommission"
                    hint="Im a hint"
                    max="36"
                    min="0"
                    v-model="item.x"
                  ></v-slider>
                  {{ item.x }}%
                </div>
              </div>
            </div>
          </v-expand-transition>
        </div>
      </div>
      <div v-else
           @click="insertMonth()"
           class="btnAddMonth">
        <span  >
          {{ $t('addMonth') }}
        </span>
      </div>
      <div class="d-flex justify-space-between py-3 mb-5 actionFormCommissionE">
        <KBButton :text="$t('addMonth')"
                  :type="'flat'"
                  :color="$vuetify.theme.dark ? 'white' : 'black'"
                  :icon="'add'"
                  @click="insertMonth()"/>
        <KBButton :text="$t('save')"
                  :type="'flat'"
                  :color="$vuetify.theme.dark ? 'white' : 'black'"
                  @click="createUser()"/>
      </div>
    </div>
  </div>
</template>

<script>
import operation from '@/utils/operations'
import moment from 'moment'
import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import FeesServices from '@/modules/configuration/application/services/FeesServices'
import { SpecialCountries } from '@/components/conversations/enums/SpecialCountries'
import { EventBus } from '@/eventBus'
import DatePickerRow from '@/modules/configuration/infrastructure/ui/components/DatePickerRow/DatePickerRow.vue'
import KBAutocomplete from '@/shared/infrastructure/ui/inputs/select/KBSelect.vue'
import KBButton from '@/shared/infrastructure/ui/buttons/KBButton.vue'
import KBText from '@/shared/infrastructure/ui/inputs/text/KBText.vue'
import { CDPFieldsEnums } from '@/modules/CDP/shared/contacts/domain/enums/CDPFieldsEnums'
import { SalesFieldsEnum } from '@/modules/Sales/shared/domain/SalesFieldsEnum'
export default {
  name: 'CreateUserCommission',
  props: [],
  components: { KBText, KBButton, KBAutocomplete, DatePickerRow },
  data () {
    return {
      date: null,
      readonly: false,
      preferred: ['co', 'usa', 'gb'],
      placeholderText: this.$t('phonePlaceholder'),
      phone: {
        number: '',
        valid: false,
        countryName: undefined,
        countryNumber: undefined,
        countryCode: undefined
      },
      dateToday: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      bindProps: {
        mode: 'international',
        autoFormat: true,
        enabledFlags: true
      },
      localValue: {},
      count: 1,
      activeItem: false,
      creating: false,
      KeybeIconType,
      newDomainDialog: false,
      selectItem: null,
      name: null,
      // phone: null,
      category: null,
      email: null,
      prefix: null,
      active: false,
      categoryData: [],
      monthArray: [],
      scheduleDate: null,
      CDPFieldsEnums,
      SalesFieldsEnum
    }
  },
  created () {
    this.Commissiondate = null
    this.getCategory()
    if (this.onCreateData) {
      this.setData()
    }
  },
  computed: {
    Commissiondate: {
      get () {
        return this.$store.state.CommissionStore.Commissiondate || null
      },
      set (value) {
        this.$store.commit('CampaingsStore/SET_EDITING_OBJECT', {
          key: 'Commissiondate',
          value
        })
      }
    },
    idCompany: {
      get () {
        return this.$store.state.CommissionStore.idCompany || []
      },
      set (value) {
        this.$store.commit('CommissionStore/SET_EDITING_OBJECT', {
          key: 'idCompany',
          value
        })
      }
    },
    onCreateData: {
      get () {
        return this.$store.state.CommissionStore.onCreateData || null
      },
      set (value) {
        this.$store.commit('CommissionStore/SET_EDITING_OBJECT', {
          key: 'onCreateData',
          value
        })
      }
    },
    onCreate: {
      get () {
        return this.$store.state.CommissionStore.onCreate || false
      },
      set (value) {
        this.$store.commit('CommissionStore/SET_EDITING_OBJECT', {
          key: 'onCreate',
          value
        })
      }
    },
    filteredList: function () {
      const temp = this.companies.length > 0 ? this.companies.filter((brand) =>
        brand.name.toLowerCase().includes(this.search.toLowerCase())
      ) : []
      return temp
    }
  },
  methods: {
    setScheduleDate (time) {
      this.scheduleDate = time
    },
    activateExtra (input) {
      console.log('input', input)
    },
    setData () {
      const { name, lastName, email, phone, countryCode, country } = this.onCreateData
      this.name = `${name} ${lastName}`
      this.email = email
      if (this.onCreateData?.surname) {
        this.name = `${name} ${this.onCreateData.surname}`
        this.phone.countryNumber = phone.substr(0, 2)
        this.phone.number = phone.substr(2)
        this.localValue.phone = phone.substr(2)
        return
      }
      this.phone.countryNumber = countryCode
      this.phone.countryCode = country
      this.localValue.phone = `+${countryCode}${phone}`
    },
    onCountryChange (country) {
      this.phone.countryName = country.name.toLowerCase()
      this.phone.countryCode = country.iso2.toLowerCase()
      this.phone.countryNumber = country.dialCode
    },
    onInput (formattedNumber, { valid }) {
      setTimeout(() => {
        if (formattedNumber[0] === '+') {
          formattedNumber = formattedNumber.trim()
          const tempPhone = formattedNumber.split(/\s+/)
          tempPhone.shift()
          formattedNumber = tempPhone.join('')
        }
        if (formattedNumber) formattedNumber = formattedNumber.replace(/(\()|-|\)|\s/g, '')
        const specialCountry = Object.values(SpecialCountries)
        if (specialCountry.includes(this.phone.countryName) && formattedNumber.length === 10) {
          formattedNumber = formattedNumber.slice(3, 10)
        }
        this.phone.number = formattedNumber
        this.phone.valid = valid

        const data = {
          country: this.phone.countryName,
          code: this.phone.countryCode,
          number: this.phone.countryNumber,
          phone: this.phone.number,
          valid: this.phone.valid
        }
        this.$emit('input', data)
      }, 100)
    },
    insertMonth () {
      const template = {
        id: this.count,
        active: this.count === 1,
        m: this.count,
        p: 0,
        x: 0
      }
      this.monthArray.push(template)
      this.count++
    },
    async addUserToCompany (id) {
      const params = {
        user_id: id,
        category_id: this.category,
        date: this.date
      }
      await FeesServices.addUserToApp(this.idCompany, params).then((response) => {
        if (response) {
          if (response.code === 200 || response.code === 201) {
            EventBus.$emit('toast', 'success', this.$t('personCreated'))
            this.onCreate = !this.onCreate
          }
        }
      })
    },
    async createUser () {
      let pricing = null
      const { day, month, year } = this.Commissiondate
      this.date = `${year}-${month}-${day}`
      if (!this.phone.number) {
        EventBus.$emit('toast', 'error', this.$t('phoneNumberInvalid'))
        return
      }
      if (!this.date) {
        EventBus.$emit('toast', 'error', this.$t('invalidDate'))
        return
      }
      if (!this.name || !this.phone.number || !this.email || !this.date) {
        EventBus.$emit('toast', 'error', this.$t('mustCompleteAllFields'))
        return
      }
      if (this.monthArray.length > 0) {
        pricing = this.monthArray.map((category) => ({
          M: parseInt(category.m),
          P: category.p,
          E: category.x
        })
        )
        const param = {
          name: this.name,
          status: true,
          surname: this.name,
          phone: `+${this.phone.countryNumber}${this.phone.number}`,
          email: this.email,
          pricing: pricing
        }
        await FeesServices.postCreateUser(param).then((response) => {
          if (response) {
            if (response.code === 200 || response.code === 201) {
              this.addUserToCompany(response.data._id)
            }
          }
        })
      } else {
        const param = {
          name: this.name,
          status: true,
          surname: this.name,
          phone: `+${this.phone.countryNumber}${this.phone.number}`,
          email: this.email
        }
        await FeesServices.postCreateUser(param).then((response) => {
          if (response) {
            if (response.code === 200 || response.code === 201) {
              this.addUserToCompany(response.data._id)
            }
          }
        })
      }
    },
    async getCategory () {
      try {
        this.loading = true
        const pagination = {
          page: 1,
          limit: 30
        }
        const response = await FeesServices.getCategoryFees(this.search, pagination)
        if (response) {
          const { data } = response.data
          this.categoryData = data
        }
        this.loading = false
      } catch (error) {
        console.error(error)
      }
    },
    async saveConfig () {
      if (this.loading) {
      }
      // const response = await AdvisersService.updateAdviser(newAdviser)
      // if (response.status) {
      //   await this.$store.dispatch('AppStore/updateAdviser', newAdviser)
      // }
    },
    callModal (index, { name, email, prefix, inactive }) {
      this.name = name
      this.email = email
      this.prefix = prefix
      this.active = inactive
      this.selectItem = index
      this.newDomainDialog = !this.newDomainDialog
    },
    formatDate (date) {
      return moment(date).locale('es').format('ll')
    },
    areEqual (a, b) {
      return operation.areEqual(a, b)
    },
    positionClass (index, length) {
      if (index === 0) {
        return 'first-item'
      }
      return ''
    }
  }
}
</script>

  <style scoped lang="scss">
  .config--avatar{
      width: 25px !important;
      height: 25px !important;
      span{
          padding-top: 1px;
          color: white;
          font-size: 8px !important;
      }
  }

  .KBSimpleTable__table {
    overflow: scroll;
    border-collapse: collapse;
    width: 100%;
  }

  .modeLight thead th {
      background-color: #F7F7F7;
  }

  .modeDark thead th {
      background-color: #0000 !important;
  }

  .KBSimpleTable__header {
    font-weight: 300;
    font-size: 11px;
    line-height: 0px;
    color: $simple-grey;
    height: 30px;
    text-align: start !important;
    padding-left: 8px;
  }

  .KBSimpleTable__item {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    padding: 10px;
    border-bottom: 1px solid #EBEFF2;
    text-align: start;
    min-width: 50px  !important;
  }

    .first-item {
      padding-left: 32px;
      max-width: 125px;

      // text elipsis
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .last-item {
      padding-right: 32px;
    }
    .textGray{
      color: #707683;
  }
  .smallFont{
      font-size: 11px;
  }
  .edit--content{
      position: relative;
  }
  .icon-edit{
      span{
          color: #707683 !important;
          font-size: 12px !important;
      }
  }
  table { border-collapse: collapse; width: 100%; }

  .KBSimpleTable{
    overflow: scroll;
    height: 455px;
  }
  .KBSimpleTable thead th {
    position: sticky;
    top: 0;
  }
  .dialog-edit__Light{
    background: white;
  }
  .dialog-edit__Dark{
    background: black;
  }
  .dialog-edit {
      z-index: 9;
      position: absolute;
      border-radius: 10px;
      padding: 10px 20px;
      width: 400px;
      box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1);
      &__postionTop{
          top: 40px;
          right: 0;
      }
      &__postionBottom{
          bottom: 40px;
          right: 0;
      }
  }
  @media screen and (min-height: 600px) {
  .KBSimpleTable{
    overflow: scroll;
    height: 335px;
  }
  }

  @media screen and (min-height: 700px) {
  .KBSimpleTable{
    overflow: scroll;
    height: 435px;
  }
  }
  @media screen and (min-height: 750px) {
  .KBSimpleTable{
    overflow: scroll;
    height: 465px;
  }
  }

  @media screen and (min-height: 800px) {
  .KBSimpleTable{
    overflow: scroll;
    height: 475px;
  }
  }
  @media screen and (min-height: 900px) {
  .KBSimpleTable{
    overflow: scroll;
    height: 515px;
  }
  }
  .selectItem{
      background-color: #cbd4e72e;
  }
  .KBSimpleTable__item{
      font-weight: 300 !important;
  }
  .contentFormCommission{
    margin-top: 10px;
    width: 48%;
    max-width: 500px;
    .KBform{
      width: 100%;
    }
  }
  .contentitemCommission{
    margin-top: 10px;
    padding: 15px;
    width: 98%;
    background-color: #e9edf2;
  }
  .itemCommission{
    padding: 10px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .headerCommission{
    cursor: pointer;
    display: flex;
    justify-content: space-between;
  }
  .sliderCommission{
    max-width: 350px;
    min-width: 250px;
    width: 30%;
    height: 25px;
  }
  .contentCreate {
    position: relative;
    height: 100%;
  }
  .scrollContentitemCommission{
    height: auto !important;
    padding-bottom: 50px;
    max-width: 1017px;
  }
  .itemFormText{
    width: 40px !important;
    padding: 0 10px !important;
  }
  .actionFormCommissionE{
    max-width: 1017px;
    height: 50px;
    width: 100%;
  }

::v-deep .vti__dropdown-list.above {
  top: 33px !important;
  bottom: unset !important;
}

::v-deep ul.vti__dropdown-list {
  margin-top: 10px;
  border: none !important;
  padding-top: 10px !important;
  width: 250px !important;
  margin-left: -8px;
}

::v-deep ul.vti__dropdown-list::-webkit-scrollbar-track {
  background-color: #e9edf2 !important;
}

::v-deep ul.vti__dropdown-list::-webkit-scrollbar {
  width: 6px;
  background-color: #e9edf2 !important;
}

::v-deep ul.vti__dropdown-list::-webkit-scrollbar-thumb {
  background-color: #e9edf2 !important;
}

::v-deep ul.vti__dropdown-list li.vti__dropdown-item {
  padding-right: 10px !important;
  margin-left: -25px;
}

::v-deep .vue-tel-input:focus-within {
  box-shadow: unset !important;
}

::v-deep .vti__dropdown:hover {
  background-color: unset !important;
}

.vue-tel-input {
  height: 40px;
  background-color: #e9edf2;
  border: unset;
}

::v-deep .vti__selection {
  flex-direction: row-reverse !important;
}
.btnAddMonth{
  max-width: 1017px;
  margin-top: 10px;
  cursor: pointer;
  color: #c7cfda;
  font-weight: 400;
  border: dashed 1px #c7cfda;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 98%;
}
.formPersonCommision{
  height: 100%;
  overflow-y: scroll;
}
</style>
