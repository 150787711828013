



































import QuickResponsesConfiguration from './QuickResponsesConfiguration'
export default QuickResponsesConfiguration
