














































































import ExtraDataConfig from './ExtraDataConfig'
export default ExtraDataConfig
