import { HeadersBuilder } from '@/shared/infrastructure/http/HeadersBuilder'
import KeybeRepository from '@/shared/infrastructure/repositories/KeybeRepository'

export default class SmartOMeterRepository extends KeybeRepository {
  // eslint-disable-next-line no-useless-constructor
  constructor () {
    super()
  }

  async getTier (data) {
    const { appUUID } = data
    const url = `${process.env.VUE_APP_BILLING_URL}tier/${appUUID}`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    const response = await this.client.get({
      url,
      headers
    })

    return response
  }

  async updateTier (data) {
    const { appUUID, level, user } = data
    const url = `${process.env.VUE_APP_BILLING_URL}tier/update`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    const response = await this.client.post({
      url,
      headers,
      data: { level, uuid: appUUID, user }
    })

    return response
  }
}
