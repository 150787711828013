import { EventBus } from '@/eventBus'
import ChatFilterController from '../../controllers/ChatFilter.controller'
import ChatFilterUseCases from '@/modules/ChatFilters/application/ChatFilterUseCases'
import { chatFilterCreateDTO } from '@/modules/ChatFilters/domain/ChatFilterController'
import { mapState } from 'vuex'
import ChatFiltersConfigList from '../ChatFiltersConfigList/ChatFiltersConfigList.vue'
import ChatFiltersConfigRules from '../ChatFiltersConfigRules/ChatFiltersConfigRules.vue'
import { ActivityActionTypeEnum, ActivityLogModuleTypeEnum, dataToWriteActivityLog } from '@/modules/Config/ActivityLogs/domain/ActivityLogsController'
import { CDPFieldsEnums } from '@/modules/CDP/shared/contacts/domain/enums/CDPFieldsEnums'
import ActivityLogsUseCases from '@/modules/Config/ActivityLogs/application/ActivityLogsUseCases'
import ActivityLogsController from '@/modules/Config/ActivityLogs/infrastructure/controllers/ActivityLogs.controller'

export default {
  name: 'ChatFiltersConfig',
  components: {
    ChatFiltersConfigList,
    ChatFiltersConfigRules
  },
  data () {
    return {
      chatFilterController: new ChatFilterController(),
      activityLogsController: new ActivityLogsController(),
      inbox: {},
      favoriteFilters: [],
      filters: [],
      oldValues: [],
      selectedFilter: null
    }
  },
  mounted () {
    EventBus.$on('ChatFiltersConfig--setInbox', (inbox) => {
      this.inbox = inbox
    })
  },
  methods: {
    async addFilterToFavorite ({ filter }) {
      const data = {
        filterId: filter._id,
        userId: this.user?.userId,
        token: this.token
      }

      if (this.favoriteFilters.length >= 3) {
        EventBus.$emit('toast', 'error', this.$t('favoriteFiltersLimitExceed'))
        return
      }

      this.$emit('start-loading')

      const updatedFilter = await ChatFilterUseCases.favoriteChatFilter(this.chatFilterController, data)
      this.favoriteFilters.push(updatedFilter)
      this.$emit('stop-loading')
      EventBus.$emit('InboxRow_refreshFilters')
    },
    async removeFilterFromFavorite ({ index }) {
      const data = {
        filterId: this.favoriteFilters[index]._id,
        userId: this.user?.userId,
        token: this.token
      }

      this.$emit('start-loading')
      await ChatFilterUseCases.unfavoriteChatFilter(this.chatFilterController, data)
      this.$emit('stop-loading')

      this.favoriteFilters.splice(index, 1)

      const findIndex = this.filters.findIndex(filter => filter._id === data.filterId)
      this.filters.splice(findIndex, 1, {
        ...this.filters[findIndex],
        favorite: false
      })
      EventBus.$emit('InboxRow_refreshFilters')
    },
    async getFilters () {
      this.filters = await ChatFilterUseCases.getChatFilters(this.chatFilterController, {
        userId: this.user?.userId,
        token: this.token,
        appUUID: this.selectedApp?.uuid
      })
      // eslint-disable-next-line no-undef
      this.oldValues = structuredClone(this.filters)
      const favorites = this.filters.filter(filter => filter.favorite)
      this.favoriteFilters = favorites
    },
    async createFilter (filter) {
      this.$emit('start-loading')
      const data: chatFilterCreateDTO = {
        appUUID: this.selectedApp?.uuid,
        userId: this.user?.userId,
        token: this.token,
        name: filter.name,
        rules: filter.rules,
        favorite: filter.favorite
      }

      const createdFilter = await ChatFilterUseCases.createChatFilter(this.chatFilterController, data)
      this.filters.push(createdFilter)
      EventBus.$emit('InboxRow_refreshFilters')
      this.writeActivityLog(filter.name, filter.rules, CDPFieldsEnums.CREATE)
      this.$emit('stop-loading')
    },
    async writeActivityLog (
      name: string,
      rules: { category: string }[],
      type: string,
      index: number
    ): Promise<void> {
      const data: dataToWriteActivityLog = {
        token: this.token,
        updateInput: {
          appUUID: this.selectedApp?.uuid,
          module: ActivityLogModuleTypeEnum.CHAT,
          action: type === CDPFieldsEnums.UPDATE
            ? ActivityActionTypeEnum.CHAT_FILTER_UPDATED
            : ActivityActionTypeEnum.CHAT_FILTER_CREATED,
          object: name,
          user: {
            id: this.user.uuid,
            name: this.user.name + ' ' + this.user.lastName
          },
          payload: {
            field: CDPFieldsEnums.RULES,
            newValue: rules.map(rule => rule.category).join(', '),
            oldValue: type === CDPFieldsEnums.UPDATE
              ? this.oldValues[index].rules.map(rule => rule.category).join(', ')
              : ''
          }
        }
      }
      await ActivityLogsUseCases.writeActivityLog(this.activityLogsController, data)
    },
    async deleteFilter (filter) {
      if (filter.favorite) {
        this.removeFilterFromFavorite({
          index: this.favoriteFilters.findIndex(favorite => favorite._id === filter._id)
        })
      }
      this.filters = this.filters.filter(f => f._id !== filter._id)

      const data = {
        filterId: filter._id,
        userId: this.user?.userId,
        token: this.token
      }
      await ChatFilterUseCases.deleteChatFilter(this.chatFilterController, data)
      EventBus.$emit('InboxRow_refreshFilters')
      this.writeActivityLogDelete(filter.name)
      this.selectedFilter = null
    },
    async writeActivityLogDelete (
      name: string
    ): Promise<void> {
      const data: dataToWriteActivityLog = {
        token: this.token,
        updateInput: {
          appUUID: this.selectedApp?.uuid,
          module: ActivityLogModuleTypeEnum.CHAT,
          action: ActivityActionTypeEnum.CHAT_FILTER_DELETED,
          user: {
            id: this.user.uuid,
            name: this.user.name + ' ' + this.user.lastName
          },
          payload: {
            field: CDPFieldsEnums.NAME,
            newValue: name,
            oldValue: ''
          }
        }
      }
      await ActivityLogsUseCases.writeActivityLog(this.activityLogsController, data)
    },
    async updateFilter (filter) {
      this.$emit('start-loading')
      const data = {
        filterId: filter._id,
        userId: this.user?.userId,
        token: this.token,
        name: filter.name,
        rules: filter.rules
      }

      const updatedFilter = await ChatFilterUseCases.updateChatFilter(this.chatFilterController, data)
      const findIndex = this.filters.findIndex(f => f._id === updatedFilter._id)
      this.writeActivityLog(filter.name, filter.rules, CDPFieldsEnums.UPDATE, findIndex)
      this.filters.splice(findIndex, 1, updatedFilter)
      EventBus.$emit('InboxRow_refreshFilters')
      this.$emit('stop-loading')
    },
    selectFilter (filter) {
      if (this.selectedFilter?._id === filter._id) {
        this.selectedFilter = null
        return
      }
      this.selectedFilter = filter
    }
  },
  computed: {
    ...mapState('AppStore', ['selectedApp']),
    ...mapState('UserStore', ['user', 'token']),
    ...mapState('ConversationStore', ['inboxes', 'chatFilters'])
  },
  created () {
    this.getFilters()
  },
  watch: {
    chatFilters () {
      this.filters = this.chatFilters
      this.favoriteFilters = this.filters.filter(filter => filter.favorite)
    }
  }
}
