import { GenericResponse } from '../http/GenericResponse'
import { RestResponseFactory } from '../http/RestResponse'
import KeybeRepository from './KeybeRepository'

export default class ShortenerUseCases extends KeybeRepository {
  async shortenUrl (urlString: string): Promise<GenericResponse> {
    const response = await fetch(`${process.env.VUE_APP_SHORTENER_URL}/create`, {
      method: 'POST',
      body: JSON.stringify({
        url: urlString,
        info: 'KB: Links'
      }),
      headers: {
        'Content-type': 'application/json'
      }
    })
    const parsedResponse = await response.json()

    return new RestResponseFactory().process({ response: { status: response.status, data: parsedResponse } })
  }
}
