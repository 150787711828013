







































import BikyConfigSkills from './BikyConfigSkills'

export default BikyConfigSkills
