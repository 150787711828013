import { FileKnowledgeDocument, IKnowledgeBaseController, deleteFileDTO, getFilesDTO, uploadFileDTO } from '../domain/KnoledgeBase'

export default class KnowledgeBaseUseCases {
  static getFiles (controller: IKnowledgeBaseController, data: getFilesDTO): Promise<FileKnowledgeDocument[]> {
    return controller.getFiles(data)
  }

  static uploadFile (controller: IKnowledgeBaseController, data: uploadFileDTO): Promise<boolean> {
    return controller.uploadFile(data)
  }

  static deleteFile (controller: IKnowledgeBaseController, data: deleteFileDTO): Promise<boolean> {
    return controller.deleteFile(data)
  }
}
