<template>
  <svg width="24"
       height="30"
       viewBox="0 0 24 30"
       fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <g id="Group 4479">
      <g id="Group 3719">
        <path id="Vector"
              d="M16.3313 0H0.78375C0.350893 0 0 0.350893 0 1.03179V29.4643C0 29.6491 0.350893 30 0.78375 30H22.252C22.6848 30 23.0357 29.6491 23.0357 29.4643V6.9525C23.0357 6.57964 22.9859 6.45964 22.898 6.37125L16.6645 0.137679C16.5761 0.0498214 16.4561 0 16.3313 0Z"
              fill="#E9E9E0"/>
        <g id="Group 3606">
          <path id="Vector_2"
                d="M16.6069 0.0810547V6.42873H22.9546L16.6069 0.0810547Z"
                fill="#D9D7CA"/>
          <path id="Vector_3"
                d="M22.252 29.9997H0.78375C0.350893 29.9997 0 29.6488 0 29.216V20.8926H23.0357V29.216C23.0357 29.6488 22.6848 29.9997 22.252 29.9997Z"
                fill="#185ABD"/>
          <g id="Group 3599">
            <g id="Group">
              <path id="Vector_4"
                    d="M15.3884 6.99805H7.58988C7.32252 6.99805 7.10254 7.21803 7.10254 7.48539V9.55702L11.4893 10.8365L15.8761 9.55702V7.48539C15.8761 7.21803 15.6561 6.99805 15.3888 6.99805H15.3884Z"
                    fill="#41A5EE"/>
              <path id="Vector_5"
                    d="M15.8755 9.55713H7.10205V12.116L11.4888 13.3955L15.8755 12.116V9.55713Z"
                    fill="#2B7CD3"/>
              <path id="Vector_6"
                    d="M15.8755 12.1155H7.10205V14.6745L11.4888 15.9539L15.8755 14.6745V12.1155Z"
                    fill="#185ABD"/>
              <path id="Vector_7"
                    d="M15.8755 14.6746H7.10205V16.7462C7.10205 17.0135 7.32198 17.2335 7.58929 17.2335H15.3879C15.6552 17.2335 15.8752 17.0135 15.8752 16.7462L15.8755 14.6746Z"
                    fill="#103F91"/>
              <path id="Vector_8"
                    d="M10.2704 8.82617H7.10205V15.7719H10.2704C10.5374 15.7711 10.7568 15.5516 10.7576 15.2846V9.31384C10.7568 9.04685 10.5374 8.82734 10.2704 8.82654V8.82617Z"
                    fill="black"
                    fill-opacity="0.098039"/>
              <path id="Vector_9"
                    d="M9.90483 9.19189H7.10205V16.1376H9.90483C10.1718 16.1368 10.3913 15.9173 10.3921 15.6503V9.67956C10.3913 9.41257 10.1718 9.19306 9.90483 9.19227V9.19189ZM9.90483 9.19189H7.10205V15.4065H9.90483C10.1718 15.4057 10.3913 15.1862 10.3921 14.9192V9.67956C10.3913 9.41257 10.1718 9.19306 9.90483 9.19227V9.19189ZM9.53925 9.19189H7.10205V15.4065H9.53925C9.80624 15.4057 10.0257 15.1862 10.0265 14.9192V9.67956C10.0257 9.41257 9.80624 9.19306 9.53925 9.19227V9.19189Z"
                    fill="black"
                    fill-opacity="0.2"/>
              <path id="Vector_10"
                    d="M4.66503 9.19189H9.53906C9.80642 9.19189 10.0264 9.41182 10.0264 9.67919V14.5533C10.0264 14.8206 9.80642 15.0405 9.53906 15.0405H4.66503C4.39772 15.0405 4.17773 14.8206 4.17773 14.5533V9.67919C4.17773 9.41182 4.39772 9.19189 4.66503 9.19189Z"
                    fill="#185ABD"/>
              <path id="Vector_11"
                    d="M8.54636 13.944H7.88832L7.12064 11.4216L6.31638 13.944H5.6584L4.92725 10.2883H5.58528L6.09703 12.8472L6.86477 10.3614H7.4131L8.14425 12.8472L8.65601 10.2883H9.27746L8.54636 13.944Z"
                    fill="white"/>
            </g>
          </g>
          <g id="DOC">
            <path d="M4.78955 23.1589C4.80738 23.1589 4.85974 23.16 4.94664 23.1622C5.03577 23.1622 5.14272 23.1622 5.2675 23.1622C5.39228 23.16 5.51929 23.16 5.64852 23.1622C5.77998 23.1622 5.89696 23.1633 5.99946 23.1656C6.58993 23.1745 7.07011 23.2725 7.43999 23.4597C7.80987 23.6469 8.0806 23.9165 8.25217 24.2685C8.42597 24.6184 8.51287 25.0451 8.51287 25.5486C8.51287 26.0723 8.4204 26.5168 8.23546 26.8822C8.05052 27.2454 7.76754 27.5217 7.38651 27.7111C7.00772 27.8983 6.52754 27.9918 5.94598 27.9918H4.78955V23.1589ZM5.51817 23.7605V27.3902H5.95267C6.31809 27.3902 6.63561 27.3312 6.90522 27.2131C7.17706 27.0928 7.38763 26.8989 7.53692 26.6315C7.68621 26.3619 7.76085 26.0021 7.76085 25.552C7.76085 25.1108 7.68955 24.7598 7.54694 24.4991C7.40434 24.2362 7.19935 24.0479 6.93196 23.9343C6.66681 23.8184 6.34706 23.7605 5.97272 23.7605H5.51817Z"
                  fill="white"/>
            <path d="M11.3451 28.0587C10.9061 28.0587 10.5262 27.9618 10.2054 27.7679C9.88674 27.5718 9.64053 27.29 9.46673 26.9223C9.29516 26.5524 9.20937 26.1068 9.20937 25.5854C9.20937 25.0595 9.29627 24.6117 9.47007 24.2418C9.6461 23.8697 9.89343 23.5856 10.2121 23.3895C10.5329 23.1912 10.9106 23.092 11.3451 23.092C11.7774 23.092 12.1517 23.1912 12.4681 23.3895C12.7867 23.5856 13.0318 23.8697 13.2034 24.2418C13.3772 24.6117 13.4641 25.0595 13.4641 25.5854C13.4641 26.1068 13.3783 26.5513 13.2068 26.919C13.0352 27.2866 12.7901 27.5685 12.4715 27.7646C12.155 27.9607 11.7796 28.0587 11.3451 28.0587ZM11.3451 27.4471C11.6259 27.4471 11.8654 27.3824 12.0637 27.2532C12.2642 27.124 12.4169 26.9223 12.5216 26.6482C12.6263 26.3742 12.6787 26.0232 12.6787 25.5954C12.6787 25.1565 12.6252 24.7977 12.5182 24.5192C12.4135 24.2407 12.2609 24.0357 12.0603 23.9042C11.862 23.7705 11.6236 23.7037 11.3451 23.7037C11.0666 23.7037 10.8259 23.7705 10.6232 23.9042C10.4226 24.0379 10.2678 24.244 10.1586 24.5225C10.0494 24.8011 9.99481 25.1587 9.99481 25.5954C9.99481 26.0255 10.0494 26.3775 10.1586 26.6516C10.2678 26.9234 10.4226 27.124 10.6232 27.2532C10.8259 27.3824 11.0666 27.4471 11.3451 27.4471Z"
                  fill="white"/>
            <path d="M16.2269 23.092C16.5745 23.092 16.8831 23.1633 17.1527 23.3059C17.4223 23.4486 17.6384 23.6402 17.8011 23.8808C17.966 24.1215 18.064 24.3877 18.0952 24.6796H17.3332C17.2998 24.4969 17.2351 24.332 17.1393 24.185C17.0457 24.0357 16.921 23.9187 16.765 23.834C16.609 23.7471 16.4241 23.7037 16.2102 23.7037C15.9361 23.7037 15.7033 23.7683 15.5116 23.8975C15.32 24.0268 15.1741 24.2284 15.0738 24.5025C14.9758 24.7766 14.9267 25.1297 14.9267 25.562C14.9267 26.2149 15.037 26.6928 15.2576 26.9958C15.4782 27.2966 15.7957 27.4471 16.2102 27.4471C16.4241 27.4471 16.609 27.4014 16.765 27.31C16.921 27.2164 17.0457 27.0928 17.1393 26.939C17.2351 26.783 17.2998 26.6137 17.3332 26.431H18.0952C18.0685 26.6427 18.0128 26.8466 17.9281 27.0426C17.8434 27.2365 17.7253 27.4103 17.5738 27.564C17.4245 27.7178 17.2385 27.8392 17.0157 27.9283C16.7928 28.0152 16.5299 28.0587 16.2269 28.0587C15.7902 28.0587 15.4158 27.9584 15.1039 27.7579C14.7919 27.5551 14.5524 27.2677 14.3853 26.8956C14.2204 26.5212 14.1379 26.0734 14.1379 25.552C14.1379 25.0328 14.2204 24.5905 14.3853 24.2251C14.5502 23.8574 14.7886 23.5767 15.1005 23.3828C15.4125 23.189 15.7879 23.092 16.2269 23.092Z"
                  fill="white"/>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'DocIcon',
  props: {
    width: {
      type: Number,
      default: 16
    },
    height: {
      type: Number,
      default: 18
    },
    color: {
      type: String,
      default: '#B6BEC7'
    }
  }

}
</script>

<style>

</style>
