import { IUserController, dataToGetUser, dataToUpdateUser, dataToUploadImage, userGetter, userImageUpdater, userUpdater } from '../../domain/UserController'
import { LoggedUser, LoggedUserExtradata } from '@/modules/Config/GeneralUserConfiguration/domain/LoggedUser'
import UserRepository from '../repository/User.repository'
import store from '@/store'
import { GenericResponse } from '@/shared/infrastructure/http/GenericResponse'
export default class UserController implements IUserController {
  repository: UserRepository

  constructor () {
    this.repository = new UserRepository()
  }

  abort (): void {
    this.repository.abortGetSuggestions()
  }

  async getUser (data: dataToGetUser): Promise<userGetter> {
    const response = await this.repository.getUser(data)
    if (response.status) {
      const user: LoggedUser = response?.data?.user
      store.commit('UserStore/SET_KEYBE_USER', user)
    }
    return response
  }

  async uploadUserImage (data: dataToUploadImage): Promise<userImageUpdater> {
    const { token, appId, uuid } = data
    const response = await this.repository.uploadUserImage(data)
    if (response.status) {
      const update: dataToUpdateUser = {
        // @ts-ignore
        token: token,
        uuid: uuid,
        updateInput: {
          appUUID: appId,
          photo: response?.data?.data?.info?.location
        }
      }
      if (data?.preventUpdate) return response.data
      this.updateUser(update)
    }

    return response.data
  }

  async updateUser (data: dataToUpdateUser): Promise<userUpdater> {
    const response: GenericResponse = await this.repository.updateUser(data)
    if (response.status) {
      const user: LoggedUser = response?.data?.user
      const userExtradata: LoggedUserExtradata[] = user.userData || []
      store.commit('UserStore/SET_KEYBE_USER', user)
      store.commit('UserStore/SET_USER_EXTRADATA', userExtradata)
      return {
        status: response.status,
        data: response.data
      }
    }
    return response
  }
}
