import store from '@/store'
import { AgentSections } from '../domain/AgentSections'
import { IUserSectionsController } from '../domain/UserSectionsController'
import { UserType, UserTypes } from '../domain/UserTypes'
import link from '@/utils/links'
import { CoordinatorSections } from '../domain/CoordinatorSections'

export default class UserSectionController implements IUserSectionsController {
  isCoordinator (type: UserType): boolean {
    return type === UserTypes.COORDINATOR
  }

  isAgent (type: UserType): boolean {
    return type === UserTypes.AGENT
  }

  getSections (type: UserType): string[] {
    const map: { [key: string]: string[] } = {
      [UserTypes.AGENT]: AgentSections,
      [UserTypes.COORDINATOR]: CoordinatorSections
    }

    let sections: string[] = map[type] || []

    const isGWMApp: boolean = store.getters['AppStore/isGWMApp'] || false

    if (
      isGWMApp &&
      (type === UserTypes.AGENT || type === UserTypes.COORDINATOR)
    ) {
      sections = [
        ...sections.filter(
          (section: string) =>
            section !== link.sales &&
            section !== link.pipeline &&
            section !== link.people
        )
      ]
    }

    return sections
  }

  canEnterToSection (type: UserType, section: string): boolean {
    const sections = this.getSections(type)
    if (!sections || sections.length === 0) return true
    const unProtectedRoutes = [
      link.signin,
      link.signup,
      link.gql,
      link.invitedWizard,
      link.assistedSignup,
      link.firstLogin
    ]
    const fullSections = [...sections, ...unProtectedRoutes]

    if (fullSections.includes(section)) return true

    const sectionParts = section.split('/')

    const firstPart = sectionParts[1]
    const route = `/${firstPart}`

    return fullSections.includes(route)
  }
}
