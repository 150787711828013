import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import TemplateController from '@/modules/templates/infrastructure/Template.controller'
import TemplateUseCases from '@/modules/templates/application/TemplateUseCases'
// import ConversationsConfigService from '@/components/configuration/Services/ConversationsConfigService'
import { mapState } from 'vuex'
import { EventBus } from '@/eventBus'
import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'
import WhatsappBspConfig from '@/components/configuration/conversations/channels/WhatsappBspConfig.vue'
import KBButton from '@/shared/infrastructure/ui/buttons/KBButton.vue'
import ContextListMenu from '@/shared/infrastructure/ui/ContextListMenu.vue'
import ChannelsUseCases from '@/modules/Config/Channels/shared/application/ChannelsUseCases'
import ChannelsController from '@/modules/Config/Channels/shared/infrastructure/controllers/Channels.controller'
import { channelDisconnect, dataToDisconnectChannel, updatePhotoDTO } from '@/modules/Config/Channels/shared/domain/ChannelsController'
import { ChannelsEnum } from '@/modules/Config/Channels/shared/domain/ChannelsEnum'
import EmitToast from '@/utils/EmitToast'
import Link from '@/utils/links'
import UserService from '@/services/UserService'
import { ActivityLogModuleTypeEnum, ActivityActionTypeEnum, dataToWriteActivityLog, ActivitySubmoduleTypeEnum } from '@/modules/Config/ActivityLogs/domain/ActivityLogsController'
import ActivityLogsUseCases from '@/modules/Config/ActivityLogs/application/ActivityLogsUseCases'
import ActivityLogsController from '@/modules/Config/ActivityLogs/infrastructure/controllers/ActivityLogs.controller'
import KBExpansionPanel from '@/shared/infrastructure/ui/menus/KBExpansionPanel.vue'
import KBSwitch from '@/shared/infrastructure/ui/switch/KBSwitch.vue'

export default {
  name: 'AutoConnectWhatsappConfig.vue',
  components: {
    KeybeIcon,
    WhatsappBspConfig,
    KBButton,
    ContextListMenu,
    KBExpansionPanel,
    KBSwitch
  },
  props: {
    configs: {
      required: true
    },
    migrate: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      KeybeIconType,
      showMenu: false,
      selectedChannelIndex: null,
      templateController: new TemplateController(),
      channelsController: new ChannelsController(),
      activityLogsController: new ActivityLogsController(),
      showCatalog: false,
      showCart: false,
      showModal: false,
      configData: null
    }
  },
  computed: {
    ...mapState('AppStore', ['selectedApp']),
    ...mapState('UserStore', ['token', 'user']),
    showModalFScreen: {
      get () {
        return this.$store.state.AppStore.showModalFScreen
      },
      set (value) {
        this.$store.commit('AppStore/SHOW_MODAL_FULL_SCREEN', value)
      }
    },
    showModalData: {
      get () {
        return this.$store.state.AppStore.showModalData
      },
      set (value) {
        this.$store.commit('AppStore/UPDATE_MODAL_FULL_SCREEN', value)
      }
    }
  },
  methods: {
    isDisabled (config: {enable: boolean}): boolean {
      return config?.enable === false || false
    },
    openWhatsapp (config: {[key: string]: any}): void {
      if (config?.userHost) {
        const text = `${this.$t('whatsappLineMessage')} ${this.selectedApp?.name}`
        window.open(`${Link.whatsappLink}${config.userHost.replace('+', '')}?text=${text}`, '_blank')
      }
    },
    openMenu (index: number): void {
      this.selectedChannelIndex = index
      this.showMenu = true
    },
    closeMenu (): void {
      this.showMenu = false
      this.selectedChannelIndex = null
    },
    callModal (config) {
      this.showModal = true
      this.showModalFScreen = true
      this.showModalData = {
        title: this.$t('alertDisconectChannel'),
        msg: this.$t('alertDisconectChannelMsg'),
        btn: this.$t('disconnect')
      }
      this.configData = config
    },
    async disconnectWhatsapp (config): Promise<void> {
      if (!this.showModal && this.showModalFScreen) {
        return
      }
      const id = config.config?._id
      const data: dataToDisconnectChannel = {
        appUUID: this.selectedApp?.uuid,
        token: this.token,
        type: ChannelsEnum.WHATSAPP,
        id: id
      }
      const response: channelDisconnect = await ChannelsUseCases.disconnectChannel(this.channelsController, data)
      if (!response.status) EmitToast.emitErrors(response.message)
      else {
        await this.writeActivityLog()
        this.$emit('refresh')
      }
    },
    openFileInput (): void {
      const inputElement = document.getElementById('imageProfile')
      if (inputElement) {
        inputElement.click()
      }
    },
    async changePhotoProfile (event): Promise<void> {
      try {
        const img: File = event.target.files[0]
        const userHost: string = this.configs[this.selectedChannelIndex]?.userHost
        const data: updatePhotoDTO = {
          userHost,
          img
        }
        const response = await UserService.updatePhotoProfile(data)
        if (response.data.status === 'SUCCESS') {
          EventBus.$emit('toast', 'success', this.$t('profileChanged'))
        } else {
          EventBus.$emit('toast', 'error', this.$t('error'))
        }
      } catch (error) {
        console.error(error)
      }
    },
    async writeActivityLog (): Promise<void> {
      const data: dataToWriteActivityLog = {
        token: this.token,
        updateInput: {
          appUUID: this.selectedApp?.uuid,
          module: ActivityLogModuleTypeEnum.CHAT,
          submodule: ActivitySubmoduleTypeEnum.CHANNELS,
          action: ActivityActionTypeEnum.LINE_CONNECTED_DISWHATSAPP,
          user: {
            id: this.user.uuid,
            name: this.user.name + ' ' + this.user.lastName
          }
        }
      }
      await ActivityLogsUseCases.writeActivityLog(this.activityLogsController, data)
    },
    async refreshTemplates (userHost) {
      await TemplateUseCases.getTemplates(this.templateController, {
        userHost,
        forceRefresh: 1,
        token: this.token,
        appId: this.selectedApp?.uuid
      })
      EventBus.$emit('toast', 'success', this.$t('templatesRefreshed'))
      this.selectedChannelIndex = null
    },
    formatTier (tier) {
      if (!tier) return this.$t('noTier')
      return tier?.replace(/_/g, ' ')
    },
    allowCatalog () {
      this.showCatalog = true
    },
    allowCart () {
      this.showCart = true
    }
  },
  watch: {
    showModalFScreen () {
      if (!this.showModalFScreen && this.showModal && this.configData) {
        this.disconnectWhatsapp(this.configData)
      }
    }
  },
  beforeDestroy () {
    this.templateController = null
    ChannelsUseCases.abort(this.channelsController)
    this.showModalFScreen = false
    this.showModalData = {}
    this.showModal = false
    this.configData = null
  }
}
