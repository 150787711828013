<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       width="13"
       height="8"
       fill="none"><path d="M5.53.012 4.49 1.072c-.31.087-.6.247-.843.467l-.54.491a1.123 1.123 0 0 1-.756.293H1.95v2.654a1.61 1.61 0 0 1 1.103.485l.723.739.142.145.549.56A.321.321 0 0 0 5 6.786a.493.493 0 0 1 .351-.318.476.476 0 0 1 .451.135l.22.22c.235.24.617.24.85 0a.608.608 0 0 0 .175-.402.492.492 0 0 1 .277-.424.48.48 0 0 1 .495.044.48.48 0 0 0 .628-.054.503.503 0 0 0 0-.703l-1.54-1.572-.727.684a1.445 1.445 0 0 1-1.97.02 1.519 1.519 0 0 1-.033-2.21L5.602.835A2.968 2.968 0 0 1 7.66 0c.733 0 1.442.276 1.988.771l.612.556h2.415A.33.33 0 0 1 13 1.66v4.314c0 .367-.29.663-.65.663h-.65a.646.646 0 0 1-.563-.332H9.413c-.07.14-.16.272-.274.388a1.448 1.448 0 0 1-1.28.417 1.583 1.583 0 0 1-.297.419 1.557 1.557 0 0 1-2.039.168 1.28 1.28 0 0 1-1.747-.085l-.546-.56-.143-.145-.723-.738a.648.648 0 0 0-.414-.193.657.657 0 0 1-.65.661H.65A.656.656 0 0 1 0 5.973V1.659a.33.33 0 0 1 .325-.332h2.027c.04 0 .08-.014.108-.041l.538-.49A3.06 3.06 0 0 1 5.052 0h.209c.09 0 .18.004.268.012Zm5.52 5.297V2.323h-.975a.476.476 0 0 1-.323-.127l-.75-.68a1.994 1.994 0 0 0-2.73.041L4.85 2.93a.51.51 0 0 0 .01.74.484.484 0 0 0 .66-.006l1.46-1.377a.479.479 0 0 1 .689.03.503.503 0 0 1-.028.702l-.017.017L9.135 4.58c.203.207.335.462.394.728h1.521v.002Zm-9.75.332a.335.335 0 0 0-.095-.235.322.322 0 0 0-.46 0 .335.335 0 0 0 0 .47.322.322 0 0 0 .46 0A.335.335 0 0 0 1.3 5.64Zm10.725.332a.322.322 0 0 0 .23-.098.335.335 0 0 0 0-.469.322.322 0 0 0-.46 0 .335.335 0 0 0 0 .47c.061.062.144.097.23.097Z"
                         :fill="color"/></svg>
</template>
<script>
export default {
  name: 'HealthIcon',
  props: {
    width: {
      type: Number,
      default: 15
    },
    height: {
      type: Number,
      default: 14
    },
    color: {
      type: String,
      default: 'black'
    }
  }
}
</script>
