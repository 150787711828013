import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import moment from 'moment'
import Colors from '@/shared/domain/colors/Color'
import { mapState } from 'vuex'
import KBCheck from '@/shared/infrastructure/ui/inputs/select/KBCheck.vue'
import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'
import NewTemplateForm from '@/modules/createTemplates/infrastructure/ui/NewTemplateForm/NewTemplateForm.vue'
import TemplateSentToApproval from '@/modules/createTemplates/infrastructure/ui/TemplateSentToApproval/TemplateSentToApproval.vue'
import ViewTemplateForm from '@/modules/createTemplates/infrastructure/ui/ViewTemplateForm/ViewTemplateForm.vue'
import TemplateUseCases from '@/modules/createTemplates/application/TemplateUseCases'
import TemplateController from '../../Template.controller'
import { ActivityActionTypeEnum, ActivityLogModuleTypeEnum, dataToWriteActivityLog } from '@/modules/Config/ActivityLogs/domain/ActivityLogsController'
import { CDPFieldsEnums } from '@/modules/CDP/shared/contacts/domain/enums/CDPFieldsEnums'
import ActivityLogsUseCases from '@/modules/Config/ActivityLogs/application/ActivityLogsUseCases'
import ActivityLogsController from '@/modules/Config/ActivityLogs/infrastructure/controllers/ActivityLogs.controller'
import KeybeSelect from '@/modules/DesignSystem/infrastructure/components/KeybeSelect/KeybeSelect/KeybeSelect.vue'
import KeybeButton from '@/modules/DesignSystem/infrastructure/components/KeybeButton/KeybeButton.vue'

export default {
  name: 'TemplatesList',
  components: {
    KBCheck,
    KeybeIcon,
    NewTemplateForm,
    TemplateSentToApproval,
    ViewTemplateForm,
    KeybeSelect,
    KeybeButton
  },
  data () {
    return {
      templateHovered: '',
      templateHoveredEye: '',
      Colors,
      KeybeIconType,
      selectedTemplate: '',
      isCreatingTemplate: false,
      isSentToApproval: false,
      selectedUserHost: '',
      templates: [],
      templateController: new TemplateController(),
      activityLogsController: new ActivityLogsController(),
      loading: false,
      isViewingTemplate: false,
      showDeleteTemplate: false,
      selectedTemplateToDelete: null
    }
  },
  computed: {
    ...mapState('ConversationStore', ['channels']),
    ...mapState('AppStore', ['selectedApp']),
    ...mapState('UserStore', ['token', 'user', 'isUserReadonly']),
    userHostList () {
      const whatsapp = this.channels?.whatsapp
      const list = whatsapp?.accounts?.map(account => account.userHost) || []
      this.selectedUserHost = list[0]
      return list
    },
    buttonColor (): string {
      return this.$vuetify.theme.dark ? Colors.inputDark : Colors.inputLight
    }
  },
  async mounted () {
    await this.getTemplates()
  },
  methods: {
    selectTemplate (value, template) {
      if (template.name === this.selectedTemplate) {
        this.selectedTemplate = ''
      }

      this.selectedTemplate = template.name
    },
    formatDate (date) {
      return moment(date || Date.now()).locale(this.$root.$i18n.locale).format('ddd MMM D')
    },
    newTemplate () {
      this.isCreatingTemplate = true
    },
    async getTemplates () {
      const data = {
        token: this.token,
        appId: this.selectedApp?.uuid || '',
        userHost: this.selectedUserHost,
        forceRefresh: 1,
        all: 1
      }
      try {
        this.$emit('start-loading')
        const response = await TemplateUseCases.getTemplates(this.templateController, data)
        this.templates = response
        this.loading = false
        this.$emit('stop-loading')
      } catch (error) {
        console.error('Error fetching templates', error)
      }
    },
    openDeleteModal (template): void {
      this.selectedTemplateToDelete = template
      this.showDeleteTemplate = true
    },
    closeDeleteModal (): void {
      this.showDeleteTemplate = false
      this.selectedTemplateToDelete = null
    },
    async deleteTemplate (template) {
      if (this.isUserReadonly) return
      this.showDeleteTemplate = false
      const templateId = template._id
      const formatPhone = this.selectedUserHost.replace('+', '')
      const data = {
        appUUID: this.selectedApp?.uuid || '',
        userHost: formatPhone,
        templateId: templateId
      }
      try {
        await this.templateController.deleteTemplate(data)
        await this.writeActivityLog(template.name)
        this.getTemplates()
      } catch (error) {
        console.error('Error deleting template', error)
      }
    },
    async writeActivityLog (templateName: string): Promise<void> {
      const data: dataToWriteActivityLog = {
        token: this.token,
        updateInput: {
          appUUID: this.selectedApp?.uuid,
          module: ActivityLogModuleTypeEnum.CHAT,
          action: ActivityActionTypeEnum.TEMPLATE_DELETE,
          user: {
            id: this.user.uuid,
            name: this.user.name + ' ' + this.user.lastName
          },
          payload: {
            field: CDPFieldsEnums.NAME,
            newValue: templateName,
            oldValue: ''
          }
        }
      }
      await ActivityLogsUseCases.writeActivityLog(this.activityLogsController, data)
    },
    async changeUserHost () {
      await this.getTemplates()
    },
    viewTemplate (template) {
      this.isViewingTemplate = true
      this.selectedTemplate = template
    },
    goBackToTemplates () {
      this.isViewingTemplate = false
    }
  }
}
