
export const MetricsStore = {
  namespaced: true,
  state: {
    bottomPage: false,
    currentSelectedTab: 'realtime',
    dateFilter: null,
    flowFilter: null,
    advisorFilter: null,
    loading: false,
    advisorFilterArray: [],
    advisorFilterArrayId: [],
    closeModal: false
  },
  mutations: {
    SET_EDITING_OBJECT (state, payload) {
      state[payload.key] = payload.value
    }
  }
}
