<template>
  <svg :width="width" :height="height" viewBox="0 0 18 18" :fill="color" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.40254 8.44218V8.10251C8.40254 5.65447 8.40254 3.20737 8.40348 0.759336C8.40348 0.642986 8.40817 0.522883 8.43726 0.412163C8.50482 0.156944 8.7272 -0.00725948 8.97022 0.000246962C9.24702 0.00869171 9.46658 0.186031 9.51819 0.457202C9.53883 0.56323 9.53883 0.674889 9.53883 0.783732C9.53977 3.22333 9.53883 5.66292 9.53883 8.10345C9.53883 8.20385 9.53883 8.30425 9.53883 8.44218C9.64955 8.44218 9.73869 8.44218 9.82877 8.44218C12.2993 8.44218 14.7699 8.44218 17.2404 8.44406C17.3559 8.44406 17.475 8.45625 17.5876 8.48347C17.8119 8.53695 17.9676 8.74619 17.977 8.97983C17.9864 9.23223 17.8456 9.44711 17.6064 9.51185C17.4882 9.54375 17.3605 9.55407 17.2367 9.55407C14.7736 9.55689 12.3115 9.55595 9.84847 9.55595H9.53977V9.87685C9.53977 12.3164 9.53977 14.756 9.53883 17.1966C9.53883 17.3139 9.53883 17.433 9.51631 17.5466C9.46377 17.8196 9.24983 17.9904 8.97022 17.9998C8.71594 18.0073 8.49825 17.8393 8.43444 17.5681C8.40911 17.463 8.40442 17.3523 8.40442 17.2435C8.40254 14.7954 8.40348 12.3483 8.40348 9.90031V9.55689H8.09759C5.65049 9.55689 3.20339 9.55689 0.756292 9.55595C0.647448 9.55595 0.536728 9.55126 0.4307 9.52968C0.139825 9.46681 0.00189432 9.29228 1.77076e-05 9.00235C-0.0018589 8.72086 0.145455 8.53226 0.426947 8.47314C0.532975 8.45063 0.643695 8.44406 0.7516 8.44406C3.1987 8.44218 5.6458 8.44312 8.0929 8.44312H8.40254V8.44218Z" :fill="color"/>
  </svg>
</template>

<script>
export default {
  name: 'AddIcon',
  props: {
    width: {
      type: Number,
      default: 16
    },
    height: {
      type: Number,
      default: 18
    },
    color: {
      type: String,
      default: '#B6BEC7'
    }
  }

}
</script>

<style>

</style>
