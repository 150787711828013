<template>
  <svg :width="width"
       :height="height"
       viewBox="0 0 10 10"
       fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <path d="M0.9375 5C0.9375 3.92256 1.36551 2.88925 2.12738 2.12738C2.88925 1.36551 3.92256 0.9375 5 0.9375C6.07744 0.9375 7.11075 1.36551 7.87262 2.12738C8.63449 2.88925 9.0625 3.92256 9.0625 5C9.0625 6.07744 8.63449 7.11075 7.87262 7.87262C7.11075 8.63449 6.07744 9.0625 5 9.0625C3.92256 9.0625 2.88925 8.63449 2.12738 7.87262C1.36551 7.11075 0.9375 6.07744 0.9375 5ZM10 5C10 3.67392 9.47322 2.40215 8.53553 1.46447C7.59785 0.526784 6.32608 0 5 0C3.67392 0 2.40215 0.526784 1.46447 1.46447C0.526784 2.40215 0 3.67392 0 5C0 6.32608 0.526784 7.59785 1.46447 8.53553C2.40215 9.47322 3.67392 10 5 10C6.32608 10 7.59785 9.47322 8.53553 8.53553C9.47322 7.59785 10 6.32608 10 5ZM6.53125 6.21289C6.23438 6.5332 5.72656 6.875 5 6.875C4.27344 6.875 3.76562 6.5332 3.46875 6.21289C3.29297 6.02344 2.99609 6.00977 2.80664 6.18555C2.61719 6.36133 2.60352 6.6582 2.7793 6.84766C3.20898 7.3125 3.95117 7.8125 4.99805 7.8125C6.04492 7.8125 6.78906 7.31445 7.2168 6.84766C7.39258 6.6582 7.38086 6.36133 7.18945 6.18555C6.99805 6.00977 6.70312 6.02148 6.52734 6.21289H6.53125ZM7.17969 4.0625C7.17969 3.89674 7.11384 3.73777 6.99663 3.62056C6.87942 3.50335 6.72045 3.4375 6.55469 3.4375C6.38893 3.4375 6.22996 3.50335 6.11275 3.62056C5.99554 3.73777 5.92969 3.89674 5.92969 4.0625C5.92969 4.22826 5.99554 4.38723 6.11275 4.50444C6.22996 4.62165 6.38893 4.6875 6.55469 4.6875C6.72045 4.6875 6.87942 4.62165 6.99663 4.50444C7.11384 4.38723 7.17969 4.22826 7.17969 4.0625ZM3.42969 3.4375C3.26393 3.4375 3.10496 3.50335 2.98775 3.62056C2.87054 3.73777 2.80469 3.89674 2.80469 4.0625C2.80469 4.22826 2.87054 4.38723 2.98775 4.50444C3.10496 4.62165 3.26393 4.6875 3.42969 4.6875C3.59545 4.6875 3.75442 4.62165 3.87163 4.50444C3.98884 4.38723 4.05469 4.22826 4.05469 4.0625C4.05469 3.89674 3.98884 3.73777 3.87163 3.62056C3.75442 3.50335 3.59545 3.4375 3.42969 3.4375Z"
          :fill="color"/>
  </svg>
</template>
<script>
export default {
  name: 'PersonalityIcon',
  props: {
    width: {
      type: Number,
      default: 10
    },
    height: {
      type: Number,
      default: 10
    },
    color: {
      type: String,
      default: '#B6BEC7'
    }
  }
}
</script>
