import { EventBus } from '@/eventBus'
import MicrositesService from '@/services/MicrositesService'
import Colors from '@/shared/domain/colors/Color'
// components
import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'
import KeybeText from '@/modules/DesignSystem/infrastructure/components/KeybeText/KeybeText.vue'
import KeybeButton from '@/modules/DesignSystem/infrastructure/components/KeybeButton/KeybeButton.vue'
import KeybeTextarea from '@/modules/DesignSystem/infrastructure/components/KeybeTextarea/KeybeTextarea.vue'
import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import Loader from '@/shared/infrastructure/ui/Loader.vue'

export default {
  components: {
    KeybeIcon,
    KeybeText,
    KeybeButton,
    KeybeTextarea,
    Loader
  },
  props: {
    value: {
      type: Array,
      required: true
    }
  },
  computed: {
    paginationDots () {
      return this.pictures.length < 6 ? 1 : Math.ceil(this.pictures.length / 6)
    },
    paginatedPictures () {
      return this.pictures.slice((this.selectedPage) * 6, (this.selectedPage + 1) * 6)
    },
    firstPicturesPage () {
      return this.pictures.slice(0, 6)
    },
    selectedImage (): { url: string, description: string } {
      return this.pictures[this.selectedImageIndex]
    }
  },
  data () {
    return {
      pictures: [],
      selectedPage: 0,
      hovered: null,
      Colors,
      description: '',
      selectedImageIndex: 0,
      hoveredImageIndex: null,
      loading: false,
      KeybeIconType
    }
  },
  mounted () {
    this.pictures = this.value
  },
  methods: {
    setDescription (e: string) {
      this.pictures[this.selectedImageIndex].description = e
      this.emitChange()
    },
    openFileInput () {
      if (this.$refs.imageInput) this.$refs.imageInput.click()
    },
    removeImage (index: number) {
      if (this.loading) return
      this.loading = true
      this.pictures.splice(index, 1)
      this.selectedImageIndex = 0
      this.emitChange()
      this.loading = false
    },
    async onFilePicked (event) {
      this.loading = true
      const imgs = event.target.files
      for (let i = 0; i < imgs.length; i++) {
        // validate size is less than 5MB
        if (imgs[i].size > 5000000) {
          EventBus.$emit('toast', 'error', this.$t('sizeOfFileCantExceed', { size: 5, units: 'mb' }))
          this.loading = false
          return
        }
        const response = await MicrositesService.uploadImage(imgs[i])
        if (response.status) {
          EventBus.$emit('toast', 'success', this.$t('successUploadingPicture'))
          this.pictures.push({
            url: response?.data?.url || '',
            description: ''
          })
        } else {
          EventBus.$emit('toast', 'error', this.$t('errorUploadingPicture'))
        }
      }

      if (this.$refs.imageInput) {
        this.$refs.imageInput.value = null
      }

      this.emitChange()

      this.loading = false
    },
    emitChange () {
      this.$emit('input', this.pictures)
    }
  },
  watch: {
    value: {
      handler (value) {
        this.pictures = value
      },
      immediate: true
    }
  }
}
