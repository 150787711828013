<template>
  <svg width="15"
       height="16"
       viewBox="0 0 15 16"
       fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <path d="M11.8661 8.89106C11.9631 8.79985 12.0217 8.7471 12.0768 8.69166C12.7527 8.01209 13.4286 7.33342 14.1019 6.65296C14.2523 6.50095 14.4246 6.40528 14.6318 6.48039C14.8163 6.54745 14.951 6.68426 14.9912 6.89439C15.0349 7.1224 14.909 7.27709 14.7691 7.42105C14.199 8.00852 13.6254 8.59151 13.0535 9.1763C12.6312 9.60818 12.2097 10.0418 11.7847 10.4719C11.4638 10.7974 11.2243 10.7974 10.9042 10.4719C9.90827 9.45617 8.9132 8.43951 7.91988 7.42105C7.64532 7.13939 7.61733 6.91227 7.8132 6.65654C7.98371 6.43479 8.24253 6.39365 8.46813 6.55818C8.53896 6.60915 8.60366 6.67084 8.66574 6.73344C9.3163 7.38618 9.96511 8.03981 10.6148 8.69255C10.6699 8.74799 10.7285 8.79985 10.8255 8.89106L10.8255 8.58615C10.8255 4.54004 10.8255 4.7651 10.8264 0.718994C10.8264 2.98983 10.8308 0.509759 10.8518 0.408718C10.9095 0.133315 11.073 0.00097803 11.3432 8.38752e-05C11.6046 -0.00081028 11.7795 0.139574 11.8355 0.409612C11.8564 0.510653 11.8626 0.616165 11.8626 0.718994C11.8634 4.75794 11.8634 4.52484 11.8634 8.56379L11.8634 8.89106L11.8661 8.89106Z"
          fill="#8898A9"/>
    <path d="M3.14265 1.83542C3.04559 1.92662 2.98701 1.97849 2.93192 2.03392C2.28224 2.68667 1.63255 3.3403 0.983745 3.99394C0.668959 4.31047 0.396147 4.35071 0.167927 4.11376C-0.0847756 3.85177 -0.0541717 3.60229 0.272856 3.26787C1.25131 2.26641 2.23065 1.26494 3.21085 0.264369C3.55187 -0.0843557 3.76697 -0.0879324 4.101 0.252745C5.09694 1.26852 6.09201 2.28519 7.08446 3.30364C7.41499 3.64253 7.39575 3.97427 7.04162 4.19155C6.8816 4.28991 6.72333 4.29348 6.56944 4.18797C6.48549 4.12985 6.40767 4.061 6.3351 3.98857C5.63295 3.28397 4.93255 2.57847 4.17969 1.82022L4.17969 2.12424C4.17969 6.18554 4.18144 10.2459 4.17357 14.3073C4.17357 14.4879 4.12898 14.7177 4.01618 14.8402C3.73549 15.146 3.22309 14.9859 3.16801 14.5889C3.15402 14.4861 3.14265 14.3824 3.14265 14.2786C3.14177 10.2397 3.14177 6.20163 3.14177 2.16268L3.14177 1.83542L3.14265 1.83542Z"
          fill="#8898A9"/>
    <circle cx="11.5"
            cy="14.5"
            r="1.5"
            fill="#3AF69B"/>
  </svg>
</template>
<script>
export default {
  name: 'RecentConversationsIcon',
  props: {
    width: {
      type: Number,
      default: 17
    },
    height: {
      type: Number,
      default: 14
    },
    color: {
      type: String,
      default: '#B6BEC7'
    }
  }
}
</script>
