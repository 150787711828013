
import ConversationsConfigService from '@/components/configuration/Services/ConversationsConfigService'
import KBSwitch from '@/shared/infrastructure/ui/switch/KBSwitch.vue'
import WebWidgetCollapsedConfig from '@/modules/Config/WidgetTesseract/infrastructure/ui/WebWidget/WebWidgetCollapsedConfig/WebWidgetCollapsedConfig.vue'
import WebWidgetExpandedConfig from '@/modules/Config/WidgetTesseract/infrastructure/ui/WebWidget/WebWidgetExpandedConfig/WebWidgetExpandedConfig.vue'
import WebWidgetScript from '@/modules/Config/WidgetTesseract/infrastructure/ui/WebWidget/WebWidgetScript/WebWidgetScript.vue'
import WebWidgetForm from '@/modules/Config/WidgetTesseract/infrastructure/ui/WebWidget/WebWidgetForm/WebWidgetForm.vue'

export default {
  name: 'WebWidgetConfig',
  components: {
    KBSwitch,
    WebWidgetCollapsedConfig,
    WebWidgetExpandedConfig,
    WebWidgetScript,
    WebWidgetForm
  },
  props: {
    config: {
      type: Object,
      required: true
    },
    chatConfig: {
      type: Object,
      required: true
    },
    allowMessagesOutsideBusinessHours: {
      type: Boolean,
      required: true
    },
    allowMultimediaMessages: {
      type: Boolean,
      required: true
    },
    selectedType: {
      type: String,
      required: true
    },
    dataTocreate: {
      type: Object,
      required: true
    }
  },
  methods: {
    async saveConfig () {
      if (this.loading) {
        return
      }

      this.config.disableMultimedia = !this.allowMultimediaMessages
      const dataToUpdate = {
        widgetWebSetup: this.config,
        workingHours: {
          ...this.chatConfig?.workingHours,
          blockMessageComposer: !this.allowMessagesOutsideBusinessHours
        }
      }
      await ConversationsConfigService.updateConversationsConfigV5(dataToUpdate)
    }
  }
}
