import { CDPFieldsEnums } from '@/modules/CDP/shared/contacts/domain/enums/CDPFieldsEnums'
import { SalesFieldsEnum } from '@/modules/Sales/shared/domain/SalesFieldsEnum'

export const PeopleFiltersStore = {
  namespaced: true,
  state: {
    peopleFilters: {
      registerChannel: [],
      responsible: [],
      createdAt: {
        init: '',
        end: ''
      },
      authRoles: [],
      documentType: '',
      document: '',
      taxCode: '',
      identifier: '',
      priority: '',
      taxGroup: '',
      description: '',
      disambiguatingDescription: '',
      alias: '',
      country: '',
      province: '',
      city: '',
      zone: '',
      birthDate: '',
      birthPlaceCountry: '',
      birthPlaceProvince: '',
      birthPlaceCity: '',
      deathDate: '',
      deathPlaceCountry: '',
      deathPlaceProvince: '',
      deathPlaceCity: '',
      nationality: '',
      childrenNumber: '',
      colleage: '',
      affiliation: '',
      award: '',
      duns: '',
      urlPublic: '',
      publicSocialmedia: '',
      establishmentDate: '',
      establishmentCountry: '',
      establishmentProvince: '',
      establishmentCity: '',
      nps: '',
      timeZone: '',
      category: '',
      subCategory: '',
      profession: [],
      tags: [],
      knows: [],
      hearAbout: '',
      preferredStore: '',
      preferredProduct: '',
      leadGenId: '',
      formId: '',
      source: {
        utmSource: '',
        utmMedium: '',
        utmCampaign: '',
        utmTerm: '',
        utmContent: '',
        utmPartner: '',
        channel: '',
        channelOther: ''
      },
      habeasDataStatus: '',
      headquarters: '',
      gender: '',
      occupations: [],
      educationalLevel: '',
      annualIncome: '',
      monthlyIncome: '',
      ethnicity: '',
      civilStatus: '',
      religion: [],
      socioeconomicStatus: '',
      preferredLanguage: '',
      credentials: [],
      status: [],
      statusAt: {
        init: '',
        end: ''
      },
      extendedData: [],
      personalityData: {
        interests: [],
        likes: [],
        dislikes: [],
        music: [],
        general: [],
        naturalTalents: [],
        personality: [],
        skills: [],
        psychology: [],
        colorGamut: [],
        colorFavorite: [],
        preferredContactTime: [],
        preferredGreeting: [],
        preferredGoodbye: [],
        preferredPronouns: [],
        preferredCulture: [],
        preferredChannel: '',
        treatmentPreferred: '',
        treatmentType: ''
      },
      funnelStep: '',
      emailEmpty: null,
      phoneEmpty: null,
      team: [],
      salesCards: {
        funnel: '',
        steps: '',
        responsible: [],
        business: '',
        priority: '',
        utmValue: '',
        utmPartner: '',
        utmSource: '',
        formId: '',
        leadGenId: '',
        quoteStatus: '',
        products: [],
        financing: '',
        financingDateInit: '',
        financingDateEnd: '',
        reason: '',
        createdAtInit: '',
        createdAtEnd: '',
        extendedData: []
      },
      address: {
        country: '',
        value: '',
        province: '',
        zone: '',
        postalCode: ''
      }
    },
    peopleFiltersButtons: [
      { name: CDPFieldsEnums.REGISTER_CHANNEL, value: [] },
      { name: CDPFieldsEnums.RESPONSIBLE, value: [] },
      { name: CDPFieldsEnums.CREATED_AT, value: '' },
      { name: CDPFieldsEnums.AUTH_ROLES, value: [] },
      { name: CDPFieldsEnums.STATUS, value: [] },
      { name: CDPFieldsEnums.HABEAS_DATA_STATUS, value: '' },
      { name: CDPFieldsEnums.DOCUMENT_TYPE, value: '' },
      { name: CDPFieldsEnums.DOCUMENT, value: '' },
      { name: CDPFieldsEnums.TAX_CODE, value: '' },
      { name: CDPFieldsEnums.PRIORITY, value: '' },
      { name: CDPFieldsEnums.TAX_GROUP, value: '' },
      { name: CDPFieldsEnums.IDENTIFIER, value: '' },
      { name: CDPFieldsEnums.DESCRIPTION, value: '' },
      { name: CDPFieldsEnums.DISIMBIGUATING_DESCRIPTION, value: '' },
      { name: CDPFieldsEnums.ALIAS, value: '' },
      { name: CDPFieldsEnums.COUNTRY, value: '' },
      { name: CDPFieldsEnums.PROVINCE, value: '' },
      { name: CDPFieldsEnums.CITY, value: '' },
      { name: CDPFieldsEnums.ZONE, value: '' },
      { name: CDPFieldsEnums.BIRTH_DATE, value: '' },
      { name: CDPFieldsEnums.BIRTH_PLACE_COUNTRY, value: '' },
      { name: CDPFieldsEnums.BIRTH_PLACE_PROVINCE, value: '' },
      { name: CDPFieldsEnums.BIRTH_PLACE_CITY, value: '' },
      { name: CDPFieldsEnums.DEATH_DATE, value: '' },
      { name: CDPFieldsEnums.DEATH_PLACE_COUNTRY, value: '' },
      { name: CDPFieldsEnums.DEATH_PLACE_PROVINCE, value: '' },
      { name: CDPFieldsEnums.DEATH_PLACE_CITY, value: '' },
      { name: CDPFieldsEnums.NATIONALITY, value: '' },
      { name: CDPFieldsEnums.CHILDREN_NUMBER, value: '' },
      { name: CDPFieldsEnums.COLLEAGE, value: '' },
      { name: CDPFieldsEnums.AFFILIATION, value: '' },
      { name: CDPFieldsEnums.AWARD, value: '' },
      { name: CDPFieldsEnums.DUNS, value: '' },
      { name: CDPFieldsEnums.URL_PUBLIC, value: '' },
      { name: CDPFieldsEnums.PUBLIC_SOCIAL_MEDIA, value: '' },
      { name: CDPFieldsEnums.ESTABLISHMENT_DATE, value: '' },
      { name: CDPFieldsEnums.ESTABLISHMENT_COUNTRY, value: '' },
      { name: CDPFieldsEnums.ESTABLISHMENT_PROVINCE, value: '' },
      { name: CDPFieldsEnums.ESTABLISHMENT_CITY, value: '' },
      { name: CDPFieldsEnums.NPS, value: '' },
      { name: CDPFieldsEnums.TIME_ZONE, value: '' },
      { name: CDPFieldsEnums.CATEGORY, value: '' },
      { name: CDPFieldsEnums.SUB_CATEGORY, value: '' },
      { name: CDPFieldsEnums.PROFESSION, value: [] },
      { name: CDPFieldsEnums.TAGS, value: [] },
      { name: CDPFieldsEnums.KNOWS, value: [] },
      { name: CDPFieldsEnums.HEAR_ABOUT, value: '' },
      { name: CDPFieldsEnums.PREFERRED_STORE, value: '' },
      { name: CDPFieldsEnums.PREFERRED_PRODUCT, value: '' },
      { name: CDPFieldsEnums.LEAD_GEN_ID, value: '' },
      { name: CDPFieldsEnums.FORM_ID, value: '' },
      { name: CDPFieldsEnums.UTM_SOURCE, value: '' },
      { name: CDPFieldsEnums.UTM_MEDIUM, value: '' },
      { name: CDPFieldsEnums.UTM_CAMPAIGN, value: '' },
      { name: CDPFieldsEnums.UTM_TERM, value: '' },
      { name: CDPFieldsEnums.UTM_CONTENT, value: '' },
      { name: CDPFieldsEnums.UTM_PARTNER, value: '' },
      { name: CDPFieldsEnums.CHANNEL, value: '' },
      { name: CDPFieldsEnums.CHANNEL_OTHER, value: '' },
      { name: CDPFieldsEnums.HEADQUARTERS, value: '' },
      { name: CDPFieldsEnums.GENDER, value: '' },
      { name: CDPFieldsEnums.OCCUPATIONS, value: [] },
      { name: CDPFieldsEnums.EDUCATIONAL_LEVEL, value: '' },
      { name: CDPFieldsEnums.ANNUAL_INCOME, value: '' },
      { name: CDPFieldsEnums.MONTHLY_INCOME, value: '' },
      { name: CDPFieldsEnums.ETHNICITY, value: '' },
      { name: CDPFieldsEnums.CIVIL_STATUS, value: '' },
      { name: CDPFieldsEnums.RELIGION, value: [] },
      { name: CDPFieldsEnums.SOCIOECONOMIC_STATUS, value: '' },
      { name: CDPFieldsEnums.PREFERRED_LANGUAGE, value: '' },
      { name: CDPFieldsEnums.CREDENTIALS, value: [] },
      { name: CDPFieldsEnums.EXTENDED_DATA, value: [] },
      { name: CDPFieldsEnums.INTERESTS, value: [] },
      { name: CDPFieldsEnums.LIKES, value: [] },
      { name: CDPFieldsEnums.DISLIKES, value: [] },
      { name: CDPFieldsEnums.MUSIC, value: [] },
      { name: CDPFieldsEnums.GENERAL, value: [] },
      { name: CDPFieldsEnums.NATURAL_TALENTS, value: [] },
      { name: CDPFieldsEnums.PERSONALITY, value: [] },
      { name: CDPFieldsEnums.SKILLS, value: [] },
      { name: CDPFieldsEnums.PSYCHOLOGY, value: [] },
      { name: CDPFieldsEnums.COLOR_GAMUT, value: [] },
      { name: CDPFieldsEnums.COLOR_FAVORITE, value: [] },
      { name: CDPFieldsEnums.PREFERRED_CONTACT_TIME, value: [] },
      { name: CDPFieldsEnums.PREFERRED_GREETING, value: [] },
      { name: CDPFieldsEnums.PREFERRED_GOODBYE, value: [] },
      { name: CDPFieldsEnums.PREFERRED_PRONOUNS, value: [] },
      { name: CDPFieldsEnums.PREFERRED_CULTURE, value: [] },
      { name: CDPFieldsEnums.PREFERRED_CHANNEL, value: '' },
      { name: CDPFieldsEnums.TREATMENT_PREFERRED, value: '' },
      { name: CDPFieldsEnums.TREATMENT_TYPE, value: '' },
      { name: CDPFieldsEnums.FUNNEL_STEP, value: '' },
      { name: 'email', value: '' },
      { name: CDPFieldsEnums.PHONE, value: '' },
      { name: 'isInTeam', value: '' },
      { name: 'teamStatus', value: '' },
      { name: 'isInBrandTeam', value: '' },
      { name: SalesFieldsEnum.SALES_FUNNEL, value: '' },
      { name: SalesFieldsEnum.SALES_STEP, value: '' },
      { name: SalesFieldsEnum.SALES_RESPONSIBLE, value: [] },
      { name: SalesFieldsEnum.SALES_BUSINESS, value: '' },
      { name: SalesFieldsEnum.SALES_PRIORITY, value: '' },
      { name: SalesFieldsEnum.SALES_UTM_VALUE, value: '' },
      { name: SalesFieldsEnum.SALES_UTM_PARTNER, value: '' },
      { name: SalesFieldsEnum.SALES_UTM_SOURCE, value: '' },
      { name: SalesFieldsEnum.SALES_FORM_ID, value: '' },
      { name: SalesFieldsEnum.SALES_LEAD_GEN_ID, value: '' },
      { name: SalesFieldsEnum.SALES_QUOTE_STATUS, value: '' },
      { name: SalesFieldsEnum.SALES_PRODUCTS, value: [] },
      { name: SalesFieldsEnum.SALES_FINANCING, value: '' },
      { name: SalesFieldsEnum.SALES_DATE_INIT, value: '' },
      { name: SalesFieldsEnum.SALES_DATE_END, value: '' },
      { name: SalesFieldsEnum.SALES_REASON, value: '' },
      { name: SalesFieldsEnum.SALES_CREATED_AT_INIT, value: '' },
      { name: SalesFieldsEnum.SALES_CREATED_AT_END, value: '' },
      { name: SalesFieldsEnum.SALES_EXTEDED_DATA, value: [] },
      { name: CDPFieldsEnums.ADDRESS_COUNTRY, value: '' },
      { name: CDPFieldsEnums.ADDRESS_PROVINCE, value: '' },
      { name: CDPFieldsEnums.ADDRESS_CITY, value: '' },
      { name: CDPFieldsEnums.ADDRESS_ZONE, value: '' },
      { name: CDPFieldsEnums.ADDRESS_POSTAL_CODE, value: '' },
      { name: CDPFieldsEnums.ADDRESS_VALUE, value: '' },
      { name: CDPFieldsEnums.STATUS_AT, value: '' }
    ]
  },
  mutations: {
    SET_ACTIVE_FILTERS (state: any, filters: any) {
      state.peopleFilters = filters
    },
    SET_ACTIVE_FILTERS_BUTTONS (state: any, buttons: any) {
      state.peopleFiltersButtons = buttons
    },
    SET_FILTERS_ACTIVE (state: any, active: boolean) {
      state.filtersActivePeople = active
    },
    CLEAR_FILTERS (state: any) {
      state.peopleFilters = {
        registerChannel: [],
        responsible: [],
        createdAt: {
          init: '',
          end: ''
        },
        authRoles: [],
        documentType: '',
        document: '',
        taxCode: '',
        priority: '',
        taxGroup: '',
        identifier: '',
        description: '',
        disambiguatingDescription: '',
        alias: '',
        city: '',
        zone: '',
        birthDate: '',
        birthPlaceCountry: '',
        birthPlaceProvince: '',
        birthPlaceCity: '',
        deathDate: '',
        deathPlaceCountry: '',
        deathPlaceProvince: '',
        deathPlaceCity: '',
        nationality: '',
        childrenNumber: '',
        colleage: '',
        affiliation: '',
        award: '',
        duns: '',
        urlPublic: '',
        publicSocialmedia: '',
        establishmentDate: '',
        establishmentCountry: '',
        establishmentProvince: '',
        establishmentCity: '',
        nps: '',
        timeZone: '',
        category: '',
        subCategory: '',
        profession: [],
        tags: [],
        knows: [],
        hearAbout: '',
        preferredStore: '',
        preferredProduct: '',
        leadGenId: '',
        formId: '',
        source: {
          utmSource: '',
          utmMedium: '',
          utmCampaign: '',
          utmTerm: '',
          utmContent: '',
          utmPartner: '',
          channel: '',
          channelOther: ''
        },
        habeasDataStatus: '',
        headquarters: '',
        gender: '',
        occupations: [],
        educationalLevel: '',
        annualIncome: '',
        monthlyIncome: '',
        ethnicity: '',
        civilStatus: '',
        religion: [],
        socioeconomicStatus: '',
        preferredLanguage: '',
        credentials: [],
        status: [],
        extendedData: [],
        personalityData: {
          interests: [],
          likes: [],
          dislikes: [],
          music: [],
          general: [],
          naturalTalents: [],
          personality: [],
          skills: [],
          psychology: [],
          colorGamut: [],
          colorFavorite: [],
          preferredContactTime: [],
          preferredGreeting: [],
          preferredGoodbye: [],
          preferredPronouns: [],
          preferredCulture: [],
          preferredChannel: '',
          treatmentPreferred: '',
          treatmentType: ''
        },
        funnelStep: '',
        emailEmpty: null,
        phoneEmpty: null,
        team: [],
        salesCards: {
          funnel: '',
          steps: '',
          responsible: [],
          business: '',
          priority: '',
          utmValue: '',
          utmPartner: '',
          utmSource: '',
          formId: '',
          leadGenId: '',
          quoteStatus: '',
          products: [],
          financing: '',
          financingDateInit: '',
          financingDateEnd: '',
          reason: '',
          createdAtInit: '',
          createdAtEnd: '',
          extendedData: []
        },
        address: {
          country: '',
          value: '',
          province: '',
          zone: '',
          postalCode: ''
        },
        statusAt: {
          init: '',
          end: ''
        }
      }

      state.peopleFiltersButtons = [
        { name: CDPFieldsEnums.REGISTER_CHANNEL, value: [] },
        { name: CDPFieldsEnums.RESPONSIBLE, value: [] },
        { name: CDPFieldsEnums.CREATED_AT, value: '' },
        { name: CDPFieldsEnums.AUTH_ROLES, value: [] },
        { name: CDPFieldsEnums.DOCUMENT_TYPE, value: '' },
        { name: CDPFieldsEnums.DOCUMENT, value: '' },
        { name: CDPFieldsEnums.TAX_CODE, value: '' },
        { name: CDPFieldsEnums.PRIORITY, value: '' },
        { name: CDPFieldsEnums.TAX_GROUP, value: '' },
        { name: CDPFieldsEnums.IDENTIFIER, value: '' },
        { name: CDPFieldsEnums.DESCRIPTION, value: '' },
        { name: CDPFieldsEnums.DISIMBIGUATING_DESCRIPTION, value: '' },
        { name: CDPFieldsEnums.ALIAS, value: '' },
        { name: CDPFieldsEnums.CITY, value: '' },
        { name: CDPFieldsEnums.ZONE, value: '' },
        { name: CDPFieldsEnums.BIRTH_DATE, value: '' },
        { name: CDPFieldsEnums.BIRTH_PLACE_COUNTRY, value: '' },
        { name: CDPFieldsEnums.BIRTH_PLACE_PROVINCE, value: '' },
        { name: CDPFieldsEnums.BIRTH_PLACE_CITY, value: '' },
        { name: CDPFieldsEnums.DEATH_DATE, value: '' },
        { name: CDPFieldsEnums.DEATH_PLACE_COUNTRY, value: '' },
        { name: CDPFieldsEnums.DEATH_PLACE_PROVINCE, value: '' },
        { name: CDPFieldsEnums.DEATH_PLACE_CITY, value: '' },
        { name: CDPFieldsEnums.NATIONALITY, value: '' },
        { name: CDPFieldsEnums.CHILDREN_NUMBER, value: '' },
        { name: CDPFieldsEnums.COLLEAGE, value: '' },
        { name: CDPFieldsEnums.AFFILIATION, value: '' },
        { name: CDPFieldsEnums.AWARD, value: '' },
        { name: CDPFieldsEnums.DUNS, value: '' },
        { name: CDPFieldsEnums.URL_PUBLIC, value: '' },
        { name: CDPFieldsEnums.PUBLIC_SOCIAL_MEDIA, value: '' },
        { name: CDPFieldsEnums.ESTABLISHMENT_DATE, value: '' },
        { name: CDPFieldsEnums.ESTABLISHMENT_COUNTRY, value: '' },
        { name: CDPFieldsEnums.ESTABLISHMENT_PROVINCE, value: '' },
        { name: CDPFieldsEnums.ESTABLISHMENT_CITY, value: '' },
        { name: CDPFieldsEnums.NPS, value: '' },
        { name: CDPFieldsEnums.TIME_ZONE, value: '' },
        { name: CDPFieldsEnums.CATEGORY, value: '' },
        { name: CDPFieldsEnums.SUB_CATEGORY, value: '' },
        { name: CDPFieldsEnums.PROFESSION, value: [] },
        { name: CDPFieldsEnums.TAGS, value: [] },
        { name: CDPFieldsEnums.KNOWS, value: [] },
        { name: CDPFieldsEnums.HEAR_ABOUT, value: '' },
        { name: CDPFieldsEnums.PREFERRED_STORE, value: '' },
        { name: CDPFieldsEnums.PREFERRED_PRODUCT, value: '' },
        { name: CDPFieldsEnums.LEAD_GEN_ID, value: '' },
        { name: CDPFieldsEnums.FORM_ID, value: '' },
        { name: CDPFieldsEnums.UTM_SOURCE, value: '' },
        { name: CDPFieldsEnums.UTM_MEDIUM, value: '' },
        { name: CDPFieldsEnums.UTM_CAMPAIGN, value: '' },
        { name: CDPFieldsEnums.UTM_TERM, value: '' },
        { name: CDPFieldsEnums.UTM_CONTENT, value: '' },
        { name: CDPFieldsEnums.UTM_PARTNER, value: '' },
        { name: CDPFieldsEnums.CHANNEL, value: '' },
        { name: CDPFieldsEnums.CHANNEL_OTHER, value: '' },
        { name: CDPFieldsEnums.HABEAS_DATA_STATUS, value: '' },
        { name: CDPFieldsEnums.HEADQUARTERS, value: '' },
        { name: CDPFieldsEnums.GENDER, value: '' },
        { name: CDPFieldsEnums.OCCUPATIONS, value: [] },
        { name: CDPFieldsEnums.EDUCATIONAL_LEVEL, value: '' },
        { name: CDPFieldsEnums.ANNUAL_INCOME, value: '' },
        { name: CDPFieldsEnums.MONTHLY_INCOME, value: '' },
        { name: CDPFieldsEnums.ETHNICITY, value: '' },
        { name: CDPFieldsEnums.CIVIL_STATUS, value: '' },
        { name: CDPFieldsEnums.RELIGION, value: [] },
        { name: CDPFieldsEnums.SOCIOECONOMIC_STATUS, value: '' },
        { name: CDPFieldsEnums.PREFERRED_LANGUAGE, value: '' },
        { name: CDPFieldsEnums.CREDENTIALS, value: [] },
        { name: CDPFieldsEnums.STATUS, value: [] },
        { name: CDPFieldsEnums.EXTENDED_DATA, value: [] },
        { name: CDPFieldsEnums.INTERESTS, value: [] },
        { name: CDPFieldsEnums.LIKES, value: [] },
        { name: CDPFieldsEnums.DISLIKES, value: [] },
        { name: CDPFieldsEnums.MUSIC, value: [] },
        { name: CDPFieldsEnums.GENERAL, value: [] },
        { name: CDPFieldsEnums.NATURAL_TALENTS, value: [] },
        { name: CDPFieldsEnums.PERSONALITY, value: [] },
        { name: CDPFieldsEnums.SKILLS, value: [] },
        { name: CDPFieldsEnums.PSYCHOLOGY, value: [] },
        { name: CDPFieldsEnums.COLOR_GAMUT, value: [] },
        { name: CDPFieldsEnums.COLOR_FAVORITE, value: [] },
        { name: CDPFieldsEnums.PREFERRED_CONTACT_TIME, value: [] },
        { name: CDPFieldsEnums.PREFERRED_GREETING, value: [] },
        { name: CDPFieldsEnums.PREFERRED_GOODBYE, value: [] },
        { name: CDPFieldsEnums.PREFERRED_PRONOUNS, value: [] },
        { name: CDPFieldsEnums.PREFERRED_CULTURE, value: [] },
        { name: CDPFieldsEnums.PREFERRED_CHANNEL, value: '' },
        { name: CDPFieldsEnums.TREATMENT_PREFERRED, value: '' },
        { name: CDPFieldsEnums.TREATMENT_TYPE, value: '' },
        { name: CDPFieldsEnums.FUNNEL_STEP, value: '' },
        { name: CDPFieldsEnums.EMAIL, value: '' },
        { name: CDPFieldsEnums.PHONE, value: '' },
        { name: SalesFieldsEnum.SALES_FUNNEL, value: '' },
        { name: SalesFieldsEnum.SALES_STEP, value: '' },
        { name: SalesFieldsEnum.SALES_RESPONSIBLE, value: [] },
        { name: SalesFieldsEnum.SALES_BUSINESS, value: '' },
        { name: SalesFieldsEnum.SALES_PRIORITY, value: '' },
        { name: SalesFieldsEnum.SALES_UTM_VALUE, value: '' },
        { name: SalesFieldsEnum.SALES_UTM_PARTNER, value: '' },
        { name: SalesFieldsEnum.SALES_UTM_SOURCE, value: '' },
        { name: SalesFieldsEnum.SALES_FORM_ID, value: '' },
        { name: SalesFieldsEnum.SALES_LEAD_GEN_ID, value: '' },
        { name: SalesFieldsEnum.SALES_QUOTE_STATUS, value: '' },
        { name: SalesFieldsEnum.SALES_PRODUCTS, value: [] },
        { name: SalesFieldsEnum.SALES_FINANCING, value: '' },
        { name: SalesFieldsEnum.SALES_DATE_INIT, value: '' },
        { name: SalesFieldsEnum.SALES_DATE_END, value: '' },
        { name: SalesFieldsEnum.SALES_REASON, value: '' },
        { name: SalesFieldsEnum.SALES_CREATED_AT_INIT, value: '' },
        { name: SalesFieldsEnum.SALES_CREATED_AT_END, value: '' },
        { name: SalesFieldsEnum.SALES_EXTEDED_DATA, value: [] },
        { name: CDPFieldsEnums.ADDRESS_COUNTRY, value: '' },
        { name: CDPFieldsEnums.ADDRESS_PROVINCE, value: '' },
        { name: CDPFieldsEnums.ADDRESS_CITY, value: '' },
        { name: CDPFieldsEnums.ADDRESS_ZONE, value: '' },
        { name: CDPFieldsEnums.ADDRESS_POSTAL_CODE, value: '' },
        { name: CDPFieldsEnums.ADDRESS_VALUE, value: '' },
        { name: CDPFieldsEnums.STATUS_AT, value: '' }
      ]
    }
  },
  actions: {
    setActiveFiltersPeople ({ commit }, filters: any) {
      commit('SET_ACTIVE_FILTERS', filters)
    },
    setFiltersActivePeople ({ commit }, active: boolean) {
      commit('SET_FILTERS_ACTIVE', active)
    },
    clearPeopleFilters ({ commit }) {
      commit('CLEAR_FILTERS')
    }
  },
  getters: {
    getPeopleFiltersButtons (state) {
      return state.peopleFiltersButtons
    }
  }
}
