<template>
  <svg :width="width" :height="height" viewBox="0 0 59 49" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M6.64522 27.513C11.7452 32.2282 16.8916 36.9003 22.0312 41.5662C23.867 43.2329 25.7019 44.8987 27.5336 46.5654C27.9875 46.9801 28.6791 47.5119 29.6067 47.4998C30.5181 47.4879 31.2018 46.9538 31.6538 46.5418C38.5827 40.2362 45.5121 33.927 52.4323 27.6115L52.4324 27.6114C54.8402 25.4135 56.5894 22.7066 57.177 19.364C58.0921 14.1711 57.1766 9.17771 52.8614 5.29481L52.8613 5.29469C48.7873 1.62958 43.8978 0.727148 38.6957 2.12578C36.1137 2.81952 34.0649 4.30586 32.312 5.95047L32.3118 5.95068C31.4528 6.757 30.5883 7.55301 29.6989 8.37196C29.6335 8.43217 29.568 8.49251 29.5024 8.55298C28.5866 7.65904 27.7067 6.79876 26.8263 5.93807L26.8262 5.93795C23.9202 3.09765 20.4054 1.50111 16.1736 1.50111L16.1728 1.50111C10.205 1.5045 4.5771 5.08875 2.57255 10.7093L3.98316 11.2125L2.57251 10.7095C1.46461 13.8152 1.18809 16.851 1.86897 19.7301C2.55055 22.6122 4.16415 25.221 6.64522 27.513ZM6.64522 27.513L7.66352 26.4116L6.64571 27.5135C6.64555 27.5133 6.64538 27.5131 6.64522 27.513Z" :stroke="color" stroke-width="3"/>
  </svg>

</template>

<script>
export default {
  name: 'HeartIcon',
  props: {
    width: {
      type: Number,
      default: 16
    },
    height: {
      type: Number,
      default: 18
    },
    color: {
      type: String,
      default: '#B6BEC7'
    }
  }

}
</script>

<style>

</style>
