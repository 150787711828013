import ModalSVG from '@/shared/assets/ModalSVG.vue'
import SegmentsApolloProvider from '@/modules/people/Providers/SegmentsApolloProvider'
import { mapActions, mapState, mapGetters } from 'vuex'
import { EventBus } from '@/eventBus'
import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import CampaignsService from '@/services/CampaignsService'
import TemplateUseCases from '@/modules/templates/application/TemplateUseCases'
import TemplatesRepository from '@/modules/conversations/infrastructure/http/Templates.repository'
// import ConversationsConfigController from '@/components/configuration/controllers/ConversationsConfigController'
import { EntityTypeEnum } from '@/modules/CDP/shared/contacts/domain/Contacts'
import SegmentsUseCases from '@/modules/CDP/shared/segments/application/SegmentsUseCases'
import SegmentsController from '@/modules/CDP/shared/segments/infrastructure/controllers/Segments.controller'
import operations from '@/utils/operations'
import TemplateComposer from '@/modules/templates/infrastructure/ui/TemplateComposer/TemplateComposer.vue'
import TemplatePreview from '@/modules/templates/infrastructure/ui/TemplatePreview/TemplatePreview.vue'
import TemplateController from '@/modules/templates/infrastructure/Template.controller'
import ConversationStoreController from '@/modules/conversations/infrastructure/persistence/controllers/ConversationStore.controller'
import InboxUseCases from '@/modules/inbox/application/InboxUseCases'
import { dataToGetInbox } from '@/modules/inbox/domain/InboxController'
import InboxController from '@/modules/inbox/infrastructure/Inbox.controller'

import Loader from '@/shared/infrastructure/ui/Loader.vue'
// import TemplatePreview from '@/modules/outbound/infrastructure/ui/components/TemplatePreview/TemplatePreview.vue'
import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'
import TemplateSelector from '@/modules/outbound/infrastructure/ui/components/forms/TemplateSelector/TemplateSelector.vue'
import { CDPFieldsEnums } from '@/modules/CDP/shared/contacts/domain/enums/CDPFieldsEnums'
import { SalesFieldsEnum } from '@/modules/Sales/shared/domain/SalesFieldsEnum'
import KeybeText from '@/modules/DesignSystem/infrastructure/components/KeybeText/KeybeText.vue'
import KeybeAutocomplete from '@/modules/DesignSystem/infrastructure/components/KeybeAutocomplete/KeybeAutocomplete/KeybeAutocomplete.vue'
import KeybeSelect from '@/modules/DesignSystem/infrastructure/components/KeybeSelect/KeybeSelect/KeybeSelect.vue'
import KeybeButton from '@/modules/DesignSystem/infrastructure/components/KeybeButton/KeybeButton.vue'
import LoaderSVG from '@/shared/infrastructure/ui/icons/SvgComponents/loaderSVG.vue'
import KeybeDate from '@/modules/DesignSystem/infrastructure/components/KeybeDate/KeybeDate.vue'

export default {
  name: 'NewWhatsappForm',
  props: {
    disabledAction: {
      type: Boolean,
      default: false
    },
    setSegment: {
      type: String
    },
    itemsSegments: {
      type: Array
    }
  },
  components: {
    LoaderSVG,
    TemplateComposer,
    ModalSVG,
    KeybeIcon,
    TemplatePreview,
    KeybeText,
    TemplateSelector,
    Loader,
    KeybeAutocomplete,
    KeybeSelect,
    KeybeButton,
    KeybeDate
  },
  mounted () {
    // this.getChannels()
    this.getInboxes()
    this.callSetitems()
  },
  watch: {
    setSegment () {
      this.callSetitems()
    },
    selectedProvider () {
      if (this.selectedProvider) {
        this.loadingTemplates = true
        this.getTemplates(this.selectedProvider)
        this.loadingTemplates = false
      }
    },
    selectedInboxObject () {
      if (!this.selectedInboxObject) return
      this.callPhoneLine()
    }
  },
  data () {
    return {
      initYear: this.getYear(),
      endYear: this.getYear() + 5,
      expirationDate: this.getCurrentDate(),
      inboxList: [],
      inboxController: new InboxController(),
      templates: [],
      selectedInboxObject: null,
      rerender: 0,
      templateController: new TemplateController(),
      items: [],
      segments: [],
      offset: 1,
      limit: 10,
      search: '',
      segmentsController: new SegmentsController(),
      selectedTemplate: {},
      selectedProvider: {},
      creatingCampaign: false,
      schedule: false,
      scheduleDate: '',
      time: '',
      provider: {},
      campaingsName: null,
      segment: null,
      segmentValue: null,
      peopleListSearch: '',
      KeybeIconType,
      providers: [],
      loading: false,
      loadingTemplates: false,
      templatesRepository: null,
      loadingLine: false,
      segmentSearch: '',
      SegmentsApolloProvider:
        SegmentsApolloProvider.getInstance().provider.defaultClient,
      templateVariables: {
        templateMessage: '',
        templateId: '',
        templateParams: [],
        buttonParams: [],
        mediaParams: [],
        headerParams: [],
        valid: false,
        error: ''
      },
      CDPFieldsEnums,
      SalesFieldsEnum,
      loadingSegment: false
    }
  },
  created () {
    this.outboundName = null
    this.summaryMenu = false
    this.templatesRepository = new TemplatesRepository(this.$store)
    this.changeParamsVisible = false
  },
  computed: {
    ...mapGetters('UserStore', ['isSuperAdmin']),
    ...mapState('UserStore', ['token', 'user']),
    ...mapState('ConversationStore', [
      'channels',
      'wpProviders',
      'selectedInbox',
      'inboxes'
    ]),
    ...mapState('AppStore', ['selectedApp']),
    ...mapGetters('ChatConfigStore', ['hasInboxConfig']),
    onInboxesOk () {
      if (this.showInboxSelect) {
        if (this.selectedInboxObject) {
          return true
        } else {
          return false
        }
      } else {
        return true
      }
    },
    showInboxSelect (): boolean {
      if (!this.hasInboxConfig) return false

      this.selectedInboxObject = this.selectedInbox || null
      return true
    },
    // inboxesComputed () {
    //   let inboxes = this.inboxes.filter((inbox) => inbox._id !== 'team')
    //   inboxes = inboxes.filter((inbox) => inbox.key !== 'todo')
    //   return inboxes
    // },
    userHosts () {
      return (
        this.channels?.whatsapp?.accounts.map((account) => account.userHost) ||
        []
      )
    },
    templateComposerValid () {
      return !this.templateVariables?.valid
    },
    canSendTemplate () {
      return (
        this.selectedTemplate &&
        this.templateVariables?.valid &&
        this.countryPhonePicker?.valid
      )
    },
    selectedAppId () {
      return this.selectedApp?.uuid
    },
    outboundName: {
      get () {
        return this.$store.state.CampaingsStore.outboundName || null
      },
      set (value) {
        this.$store.commit('CampaingsStore/SET_EDITING_OBJECT', {
          key: 'outboundName',
          value
        })
      }
    },
    currentSelectedTab: {
      get () {
        return this.$store.state.CampaingsStore.currentSelectedTab || null
      },
      set (value) {
        this.$store.commit('CampaingsStore/SET_EDITING_OBJECT', {
          key: 'currentSelectedTab',
          value
        })
      }
    },
    summaryData: {
      get () {
        return this.$store.state.CampaingsStore.summaryData || false
      },
      set (value) {
        this.$store.commit('CampaingsStore/SET_EDITING_OBJECT', {
          key: 'summaryData',
          value
        })
      }
    },
    summaryMenu: {
      get () {
        return this.$store.state.CampaingsStore.summaryMenu || false
      },
      set (value) {
        this.$store.commit('CampaingsStore/SET_EDITING_OBJECT', {
          key: 'summaryMenu',
          value
        })
      }
    }
  },
  methods: {
    ...mapActions('CampaingsStore', ['setTemplates']),
    getYear () {
      const today = new Date()
      today.setDate(today.getDate() + 1)

      const year = today.getFullYear()
      return year
    },
    getCurrentDate () {
      const today = new Date()
      today.setDate(today.getDate() + 1) // Suma un día a la fecha actual

      this.year = today.getFullYear()
      const month = String(today.getMonth() + 1).padStart(2, '0')
      const day = String(today.getDate()).padStart(2, '0')
      return `${this.year}-${month}-${day}`
    },
    callPhoneLine () {
      this.providers = this.selectedInboxObject.line
      this.loadingLine = true
      if (this.providers.length > 0) {
        this.provider = this.providers[0]
        this.selectedProvider = this.providers[0]
        this.getTemplates(this.providers[0])
      }
      this.loadingLine = false
    },
    async getInboxes () {
      const dataToGetInboxes: dataToGetInbox = {
        appUUID: this.selectedApp?.uuid,
        token: this.token,
        userId: this.user?.userId
      }

      if (this.isSuperAdmin) {
        delete dataToGetInboxes.userId
      }

      const inboxList = await InboxUseCases.getInbox(this.inboxController, dataToGetInboxes)

      this.inboxList = inboxList
      if (this.inboxList.length > 0) {
        this.selectedInboxObject = this.inboxList[0]
        // this.callPhoneLine()
      }
      ConversationStoreController.setInboxes(inboxList)
    },
    callSetitems () {
      this.formatName(this.itemsSegments)
      this.segment = this.setSegment
    },
    onInput (value) {
      this.templateVariables = value
    },
    onSelectTemplate (template) {
      this.selectedTemplate = template
    },
    setScheduleDate (time) {
      this.schedule = true
      this.scheduleDate = time
    },
    async getTemplates (providers) {
      this.templates = await TemplateUseCases.getTemplates(
        this.templateController,
        {
          token: this.token,
          appId: this.selectedApp?.uuid,
          userHost: providers,
          forceRefresh: 0
        }
      )
    },
    searchPersons (search: string) {
      this.loadingSegment = true
      const obj = this.segments.find((o: { [key: string]: any }) =>
        o.name.toLowerCase().includes(search.toLowerCase())
      )
      if (obj) {
        this.loadingSegment = false
        return
      }
      this.segmentSearch = search
      try {
        this.getSegments()
      } catch (error) {
        this.loadingSegment = false
      }
    },
    clearData () {
      this.segmentSearch = ''
      this.segments = []
      this.segment = null
      this.getSegments()
    },
    formatName (data) {
      this.items = data.map((segment) => {
        if (!segment?.name) return
        return {
          name: operations.checkTranslation(segment.name),
          _id: segment._id
        }
      })
    },
    async getSegments (mounted) {
      try {
        const data = {
          offset: this.offset,
          limit: this.limit,
          search: this.segmentSearch,
          typeEntity: EntityTypeEnum.PEOPLE,
          token: this.token,
          app: this.selectedApp?.uuid
        }
        const response = await SegmentsUseCases.getSegments(
          this.segmentsController,
          data
        )
        if (response?.status) {
          if (mounted) this.segments = []
          if (this.offset > 1) { this.segments = this.segments.concat(response?.data?.data) } else this.segments = response?.data?.data.reverse()
          this.formatName(this.segments)
        }
        this.loadingSegment = false
        this.setSegmentIn()
      } catch (e) {
        console.error(e)
        this.loadingSegment = false
        this.loading = false
      }
    },
    setSegmentIn () {
      const exists = this.findElement(this.segments, this.setSegment)
      if (exists) {
        this.segment = exists.id
      }
      this.loading = false
    },
    findElement (array, id) {
      const result = array.find((elemento) => elemento.id === id)
      if (!result) return
      return {
        id: result._id,
        name: result.name
      }
    },
    dateToUTC (localDate) {
      const date = new Date(localDate + 'T00:00:00')
      date.setDate(date.getDate() + 1)
      const utcDate = date.toISOString()
      return utcDate
    },
    validateDate () {
      const today = new Date()
      today.setHours(0, 0, 0, 0)
      const dateString = this.expirationDate
      const expirationDate = new Date(dateString)
      expirationDate.setMinutes(expirationDate.getMinutes() + expirationDate.getTimezoneOffset())
      return expirationDate < today
    },
    async createCampaign () {
      if (this.creatingCampaign === true) {
        return
      }
      if (!this.expirationDate) {
        EventBus.$emit('toast', 'error', this.$t('allFieldsRequired'))
        return
      }
      const validation = this.validateDate()
      if (validation) {
        EventBus.$emit('toast', 'error', this.$t('invalidDate'))
        return
      }
      this.creatingCampaign = true
      try {
        const whatsApp = {
          mediaParams: this.templateVariables.mediaParams,
          buttonParams: this.templateVariables.buttonParams,
          templateParams: this.templateVariables.templateParams,
          templateId: this.templateVariables.templateId,
          message: this.templateVariables.templateMessage,
          valid: false,
          error: '',
          headerParams: this.templateVariables.headerParams,
          template: this.selectedTemplate.templateName,
          eventActivation: '',
          parameters: this.templateVariables.templateParams,
          payload: '',
          userHost: this.selectedProvider,
          ...this.selectedTemplate,
          ...(this.showInboxSelect
            ? { inbox: this.selectedInboxObject?.key }
            : {})
        }
        const campaign = {
          date: new Date().toISOString(),
          listId: this.segment,
          name: this.campaingsName,
          status: 'draft',
          type: 'whatsapp',
          whatsApp,
          expiresAt: this.dateToUTC(this.expirationDate)
        }
        const campaignCreated = await CampaignsService.createCampaignV6(
          campaign
        )
        if (campaignCreated?.response) {
          const { response } = campaignCreated
          if (response?.status === 400) {
            if (response?.errorDetails) {
              const { errorDetails } = response
              this.creatingCampaign = false
              return EventBus.$emit('toast', 'error', errorDetails[0]?.msg)
            }
            if (response?.errorMessage) {
              const { errorMessage } = response
              EventBus.$emit('toast', 'error', errorMessage)
            }
            this.creatingCampaign = false
            return
          }
        }
        if (campaignCreated?.campaign && campaignCreated?.campaign?._id) {
          await CampaignsService.linkCampaignV6({
            campaignId: campaignCreated?.campaign._id,
            createFromUser: this.segment === 'all'
          })
          if (campaignCreated?.campaign?.status === 'inReview') {
            EventBus.$emit('toast', 'error', this.$t('campaignError'))
          }
          // EventBus.$emit('toast', 'success', this.$t('resumeSubtitle'))
          this.goToDetail(campaignCreated.campaign)
        } else {
          EventBus.$emit('toast', 'error', this.$t('unexpectedError'))
        }
      } catch (error) {
        this.creatingCampaign = false
        EventBus.$emit('toast', 'error', this.$t('createCampaignError'))
      } finally {
        this.creatingCampaign = false
      }
    },
    goToDetail (item) {
      this.currentSelectedTab = 'summary'
      this.summaryMenu = true
      this.summaryData = item
      this.outboundName = item.name
    },
    returnM () {
      this.$parent.returnMethod()
    }
  },
  beforeDestroy () {
    this.templatesRepository = null
  }
}
