




























































































































import CatalogUpdateProduct from './CatalogUpdateProduct'
export default CatalogUpdateProduct
