<template>
  <svg width="76"
       height="67"
       fill="none"
       xmlns="http://www.w3.org/2000/svg"><path d="M38.012.814c5.926 0 11.847.018 17.773-.012 1.443-.006 2.457.456 3.31 1.683 5.103 7.292 10.269 14.536 15.412 21.799 1.437 2.033 1.408 2.693-.342 4.545-6.887 7.286-13.79 14.56-20.69 21.84-4.453 4.698-8.889 9.413-13.348 14.104-1.626 1.712-2.763 1.66-4.548-.21-6.032-6.351-12.047-12.725-18.068-19.088a21392.356 21392.356 0 0 0-15.864-16.75C.192 27.187.09 26.2 1.305 24.487c5.166-7.326 10.35-14.635 15.488-21.98.842-1.203 1.861-1.706 3.31-1.694 5.974.035 11.947.018 17.92.018l-.011-.018ZM8.092 28.777C18.158 39.44 27.996 49.869 37.958 60.42 47.944 49.834 57.794 39.4 67.82 28.777H8.091ZM23.937 5.436l14.02 17.27c4.696-5.79 9.28-11.434 14.01-17.27h-28.03Zm9.196 18.597L19.29 6.96c-4.082 5.785-8.006 11.335-12.059 17.073h25.903Zm9.638 0h25.914C64.605 18.26 60.651 12.674 56.61 6.96L42.772 24.033Z"
                                                fill="#3AF69B"/></svg>
</template>
<script>
export default {
  name: 'DialogIcon',
  props: {
    width: {
      type: Number,
      default: 16
    },
    height: {
      type: Number,
      default: 18
    },
    color: {
      type: String,
      default: '#B6BEC7'
    }
  }
}
</script>
