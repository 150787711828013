export const ConversationsFilterStore = {
  namespaced: true,
  state: {
    // Quick Filters
    quickFilterSelected: true,
    onlySelfConversations: true,
    allConversations: false,
    onlyWaitingForAdvisor: false,
    // Advanced Filters
    advancedFilterSelected: false,
    // State
    advanceAllConversations: false,
    selfConversations: true,
    conversationsWithoutAdvisor: true,
    conversationsWithBot: false,
    endedConversations: false,
    state: ['readyForAdvisor'],
    // channels
    wizardFilter: true,
    whatsappFilter: true,
    facebookFilter: true,
    webFilter: true,
    instagramFilter: true,
    channel: ['hibot_wp', 'smooch_wp', 'gupshup_wp', 'keybe_wp', 'facebookMessenger', 'instagram', 'web', 'wizard'],
    // order
    olderFirst: false,
    newerFirst: true,
    // advisors
    advisorId: [],
    owner: '',
    customId: '',
    advisers: [],
    order: -1,
    orderBy: 'updatedAt',
    offset: 0,
    size: 15
  },
  mutations: {
    SET_ALL_FILTERS_PARAMS (state, payload) {
      state = payload
    },
    SET_QUICK_FILTER_SELECTED (state, value) {
      state.quickFilterSelected = value
    },
    SET_ONLY_SELF_CONVERSATIONS (state, value) {
      state.onlySelfConversations = value
    },
    SET_ALL_CONVERSATIONS (state, value) {
      state.allConversations = value
    },
    SET_ONLY_WAITING_FOR_ADVISOR (state, value) {
      state.onlyWaitingForAdvisor = value
    },
    SET_DEFAULT_FILTERS (state, context) {
      // Quick Filters
      state.quickFilterSelected = true
      state.onlySelfConversations = true
      state.allConversations = false
      state.onlyWaitingForAdvisor = false
      // Advanced Filters
      state.advancedFilterSelected = false
      // State
      state.advanceAllConversations = false
      state.selfConversations = true
      state.conversationsWithoutAdvisor = true
      state.conversationsWithBot = false
      state.endedConversations = false
      state.state = ['readyForAdvisor']
      // channels
      state.wizardFilter = true
      state.whatsappFilter = true
      state.facebookFilter = true
      state.webFilter = true
      state.instagramFilter = true
      state.channel = ['hibot_wp', 'smooch_wp', 'gupshup_wp', 'keybe_wp', 'facebookMessenger', 'instagram', 'web', 'wizard']
      // order
      state.olderFirst = false
      state.newerFirst = true
      // advisors
      state.advisorId = []
      state.owner = context.rootState.UserStore.user.userId
      state.customId = context.rootState.UserStore.user.customId || ''
      state.advisers = context.rootState.AppStore.advisers.map((adviser) => {
        adviser.value = false
        return adviser
      })
      state.order = -1
      state.orderBy = 'updatedAt'
      state.offset = 0
      state.size = 15
    }
  },
  actions: {
    setQuickFilterSelected (context, selected) {
      context.commit('SET_QUICK_FILTER_SELECTED', selected)
    },
    setOnlySelfConversations (context, selected) {
      context.dispatch('setQuickFilterSelected', true)
      context.commit('SET_ONLY_SELF_CONVERSATIONS', selected)
    },
    setAllConversations (context, selected) {
      context.dispatch('setQuickFilterSelected', true)
      context.commit('SET_ALL_CONVERSATIONS', selected)
    },
    setOnlyWaitingForAdvisor (context, selected) {
      context.dispatch('setQuickFilterSelected', true)
      context.commit('SET_ONLY_WAITING_FOR_ADVISOR', selected)
    },
    setDefaultFilters (context) {
      context.commit('SET_DEFAULT_FILTERS', context)
    },
    setAllFiltersParams (context, payload) {
      context.commit('SET_ALL_FILTERS_PARAMS', payload)
    }
  },
  getters: {
    getFilters (state) {
      return state
    }
  }
}
