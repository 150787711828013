import KeybeButton from '@/modules/DesignSystem/infrastructure/components/KeybeButton/KeybeButton.vue'
import KBSearch from '@/shared/infrastructure/ui/inputs/text/KBSearch.vue'
import Colors from '@/utils/Colors'
import { mapState } from 'vuex'
export default {
  components: {
    KeybeButton,
    KBSearch
  },
  props: {
    apiKeys: {
      type: Array
    },
    create: {
      type: Boolean
    }
  },
  computed: {
    ...mapState('UserStore', ['isUserReadonly']),
    buttonColor (): string {
      if (this.create) return this.$vuetify.theme.dark ? Colors.inputDark : Colors.inputLight
      return Colors.kbGreen
    },
    textColor (): string {
      if (this.create) return this.$vuetify.theme.dark ? Colors.white : Colors.black
      return Colors.black
    }
  },
  data () {
    return {
      Colors
    }
  },
  methods: {
    toggleCreate () {
      this.$emit('toggleCreate')
    }
  }
}
