

































import BikyConfigChatTextMessage from './BikyConfigChatTextMessage'

export default BikyConfigChatTextMessage
