import { Socials } from './socials'
const baseUrl = 'https://storage.keybeapi.com/microsites/production/4xqa4b8khxrofef'

export const icons = {
  apple: {
    icon: 'fa-brands fa-apple',
    link: baseUrl + '/dd66a53792b424119949c93d53daa215-apple.svg'
  },
  appstore: {
    icon: 'fa-brands fa-app-store',
    link: baseUrl + '/c5037c7198dd7c51755ee56882b19429-app-store.svg'
  },
  behance: {
    icon: 'fa-brands fa-behance',
    link: baseUrl + '/35b3e4a2ccc572771f275d8e7e84f69f-behance.svg'
  },
  bicycle: {
    icon: 'fa-solid fa-bicycle',
    link: baseUrl + '/99b385a611071102197efbeb0f69b139-bicycle-solid.svg'
  },
  blogger: {
    icon: 'fa-brands fa-blogger',
    link: baseUrl + '/277f26d5fd9bcf59cd13a7daebc3f7c7-blogger.svg'
  },
  blog: {
    icon: 'fa-brands fa-blog',
    link: baseUrl + '/9825f752cac8965d1b9c84eb25491c53-blog-solid.svg'
  },
  bolt: {
    icon: 'fa-solid fa-bolt',
    link: baseUrl + '/5e91077d88b7435250ffb32708370155-bolt-solid.svg'
  },
  bomb: {
    icon: 'fa-solid fa-bomb',
    link: baseUrl + '/6690bcf9fe62de62134d7060c76f7a75-bomb-solid.svg'
  },
  burger: {
    icon: 'fa-solid fa-burger',
    link: baseUrl + '/f2f1195fb66898b50fa6c1ab30b27ac2-burger-solid.svg'
  },
  camera: {
    icon: 'fa-solid fa-camera',
    link: baseUrl + '/7af78cf10b85456db972029bf2199c8c-camera-solid.svg'
  },
  cart: {
    icon: 'fa-solid fa-cart-shopping',
    link: baseUrl + '/e95c90660f6dea3cf0111211e9163b07-cart-shopping-solid.svg'
  },
  comments: {
    icon: 'fa-solid fa-comments',
    link: baseUrl + '/71aaa2fd60135043266e3fae8eda3d44-comments-solid.svg'
  },
  cookie: {
    icon: 'fa-solid fa-cookie-bite',
    link: baseUrl + '/20e001bcff2a25eb914f5b2bd0d51535-cookie-bite-solid.svg'
  },
  discord: {
    icon: 'fa-brands fa-discord',
    link: baseUrl + '/2145e703c04ec5a681f264bd7b96f38b-discord.svg'
  },
  dropbox: {
    icon: 'fa-brands fa-dropbox',
    link: baseUrl + '/79b2f5474f0cbf0fa60549bc35e7d831-dropbox.svg'
  },
  dumbbell: {
    icon: 'fa-solid fa-dumbbell',
    link: baseUrl + '/796eb3a5dcfca8677ef15b23cfd6f34a-dumbbell-solid.svg'
  },
  earth: {
    icon: 'fa-solid fa-earth-americas',
    link: baseUrl + '/442b610547ae1292ba47ed74c8564493-earth-americas-solid.svg'
  },
  ethereum: {
    icon: 'fa-brands fa-ethereum',
    link: baseUrl + '/73a0aad829719b436e3463bbb03d5b31-ethereum.svg'
  },
  eye: {
    icon: 'fa-solid fa-eye',
    link: baseUrl + '/31eb50a0f171eb793058ad623c91fb73-eye-solid.svg'
  },
  facebook: {
    icon: 'fa-brands fa-facebook',
    link: baseUrl + '/7f214776818cdfd0d796ce5896f794b7-facebook.svg'
  },
  smile: {
    icon: 'fa-solid fa-face-smile',
    link: baseUrl + '/10443063ef79096fe544c139d74b7a48-face-smile-solid.svg'
  },
  fire: {
    icon: 'fa-solid fa-fire',
    link: baseUrl + '/131aaf0a829760c8bb04a774db9b0bff-fire-solid.svg'
  },
  gear: {
    icon: 'fa-solid fa-gear',
    link: baseUrl + '/7c3a570900890c9a0ac7ba754785418b-gear-solid.svg'
  },
  ghost: {
    icon: 'fa-solid fa-ghost',
    link: baseUrl + '/f103627fa579bbf7cdd05e705d23cd0a-ghost-solid.svg'
  },
  gift: {
    icon: 'fa-solid fa-gift',
    link: baseUrl + '/1d32fbc4da3ce3885b1c032a0e8831ce-gift-solid.svg'
  },
  github: {
    icon: 'fa-brands fa-github',
    link: baseUrl + '/c0e80e7a754e100e2ced768bfc47c51f-github.svg'
  },
  google: {
    icon: 'fa-brands fa-google',
    link: baseUrl + '/85be3f94517f3c76dcaa52ed5e51b04b-google.svg'
  },
  googlePlay: {
    icon: 'fa-brands fa-google-play',
    link: baseUrl + '/710e2b643231597e9759f7749f8adf90-google-play.svg'
  },
  heart: {
    icon: 'fa-solid fa-heart',
    link: baseUrl + '/15efb0fff42da92c719f1819ece62ea6-heart-solid.svg'
  },
  house: {
    icon: 'fa-solid fa-house',
    link: baseUrl + '/e330832be9ec5b9f4ac30260bff73d03-house-solid.svg'
  },
  instagram: {
    icon: 'fa-brands fa-instagram',
    link: baseUrl + '/f2a80537d42abe632a62e0135f809366-instagram.svg'
  },
  key: {
    icon: 'fa-solid fa-key',
    link: baseUrl + '/a71f53f1306e48160fb88f760dccfb23-key-solid.svg'
  },
  leaf: {
    icon: 'fa-solid fa-leaf',
    link: baseUrl + '/1787f4294e1bfd97e04eb44d3998ba35-leaf.svg'
  },
  lemon: {
    icon: 'fa-solid fa-lemon',
    link: baseUrl + '/43b09c977cbf75c9b1baac000ead3f22-lemon-solid.svg'
  },
  linkedin: {
    icon: 'fa-brands fa-linkedin',
    link: baseUrl + '/355b9ef66bf1166c94b4aba83f076878-linkedin.svg'
  },
  location: {
    icon: 'fa-solid fa-location-dot',
    link: baseUrl + '/96229b190aa0251979ef89f5438e1eb7-location-dot-solid.svg'
  },
  messenger: {
    icon: 'fa-brands fa-facebook-messenger',
    link: baseUrl + '/708b8316ee50dcf3086b1a6adcbb368b-messenger.svg'
  },
  meteor: {
    icon: 'fa-solid fa-meteor',
    link: baseUrl + '/f9d3678f1647357a3d8b6d678adee06f-meteor-solid.svg'
  },
  minus: {
    icon: 'fa-solid fa-minus',
    link: baseUrl + '/8b42f8e9e0449781c8ff0912443d144e-minus-solid.svg'
  },
  money: {
    icon: 'fa-solid fa-money-bill',
    link: baseUrl + '/48a91f6682449d45774c6c6dbbd57765-money-bill-solid.svg'
  },
  moon: {
    icon: 'fa-solid fa-moon',
    link: baseUrl + '/d4f5e29e5c7e047c43b4eb5085736f3d-moon.svg'
  },
  mug: {
    icon: 'fa-solid fa-mug-hot',
    link: baseUrl + '/3d9af1da2abb02973972956fe00cdab6-mug-hot-solid.svg'
  },
  paypal: {
    icon: 'fa-brands fa-paypal',
    link: baseUrl + '/bd3334fadd9ee30356bb09f41ad368e7-paypal.svg'
  },
  pepper: {
    icon: 'fa-solid fa-pepper-hot',
    link: baseUrl + '/a48e1e3229bca8a2b53db7a872e027f0-pepper-hot-solid.svg'
  },
  phone: {
    icon: 'fa-brands fa-phone',
    link: baseUrl + '/7a651281381c37187763b74ed830d5ac-phone-solid.svg'
  },
  pinterest: {
    icon: 'fa-brands fa-pinterest',
    link: baseUrl + '/ebc7916671b00c11938191645dcb01d4-pinterest.svg'
  },
  plane: {
    icon: 'fa-solid fa-plane',
    link: baseUrl + '/ee3551b1a45b89d7989fa970cebf8807-plane-solid.svg'
  },
  plus: {
    icon: 'fa-solid fa-plus',
    link: baseUrl + '/321c31159b663096f0c17d9e81137b93-plus-solid.svg'
  },
  poo: {
    icon: 'fa-solid fa-poo',
    link: baseUrl + '/7e5af8b7dcc54a49e20e7946ae54802f-poo-solid.svg'
  },
  share: {
    icon: 'fa-solid fa-share',
    link: baseUrl + '/4698e2565903d0a1ea46df86a3f7d0b4-share-solid.svg'
  },
  skull: {
    icon: 'fa-solid fa-skull',
    link: baseUrl + '/e8bf63e2b9d359839403d251fa1697ea-skull.svg'
  },
  slack: {
    icon: 'fa-brands fa-slack',
    link: baseUrl + '/38e8cf80d9af261ac07546f43f9a54ee-slack.svg'
  },
  snapchat: {
    icon: 'fa-brands fa-snapchat',
    link: baseUrl + '/4aa266cbd191c0a69f9d4991cdeb831a-snapchat.svg'
  },
  soundcloud: {
    icon: 'fa-brands fa-soundcloud',
    link: baseUrl + '/7ba39d762bf0fb8cbac7df4e157c898a-soundcloud.svg'
  },
  spotify: {
    icon: 'fa-brands fa-spotify',
    link: baseUrl + '/478c2938f1cc7a93ad3f5b698236dcb4-spotify.svg'
  },
  squarespace: {
    icon: 'fa-brands fa-squarespace',
    link: baseUrl + '/853129c052055d777181cce21e8bb9bb-squarespace.svg'
  },
  star: {
    icon: 'fa-solid fa-star',
    link: baseUrl + '/cf781c3a446a044099c3e0edda206123-star-solid.svg'
  },
  tag: {
    icon: 'fa-solid fa-tag',
    link: baseUrl + '/63d1c391a6e1bd7ef28a04bf52c0851b-tag-solid.svg'
  },
  telegram: {
    icon: 'fa-brands fa-telegram',
    link: baseUrl + '/96f8c165519c1ac8658043c73df60e98-telegram.svg'
  },
  thumbsDown: {
    icon: 'fa-solid fa-thumbs-down',
    link: baseUrl + '/9c50bbb55e963cc940612904389877aa-thumbs-down-solid.svg'
  },
  thumbsUp: {
    icon: 'fa-solid fa-thumbs-up',
    link: baseUrl + '/afef43475436ab015f4d2b6449cc45bf-thumbs-up-solid.svg'
  },
  thumbtack: {
    icon: 'fa-brands fa-thumbtack',
    link: baseUrl + '/5caa29115d8d153228f30f6132e66659-thumbtack-solid.svg'
  },
  ticket: {
    icon: 'fa-solid fa-ticket',
    link: baseUrl + '/120a80b68e6a6e727bf55a2acbba7a6f-ticket-solid.svg'
  },
  tiktok: {
    icon: 'fa-brands fa-tiktok',
    link: baseUrl + '/705f584f9137e6e6265fd597f8948c20-tiktok.svg'
  },
  tree: {
    icon: 'fa-solid fa-tree',
    link: baseUrl + '/dc4a5ddb5e6fdd2f35e5c867ac613cdf-tree-solid.svg'
  },
  truck: {
    icon: 'fa-solid fa-truck-fast',
    link: baseUrl + '/0121112fdfc6d94afa26a4248a64a822-truck-fast-solid.svg'
  },
  twitch: {
    icon: 'fa-brands fa-twitch',
    link: baseUrl + '/77f765858b9c81543ed45daa437bbf92-twitch.svg'
  },
  twitter: {
    icon: 'fa-brands fa-twitter',
    link: baseUrl + '/d4733a2276956e2080114fabdfc35191-twitter-x.svg'
  },
  threads: {
    icon: 'fa-brands fa-threads',
    link: baseUrl + '/d4733a2276956e2080114fabdfc35191-threads.svg'
  },
  video: {
    icon: 'fa-solid fa-video',
    link: baseUrl + '/e924b8a2a7e21385d53dc0947f4db252-video-solid.svg'
  },
  vimeo: {
    icon: 'fa-brands fa-vimeo',
    link: baseUrl + '/ac7f63e78d8841a1be0cad70d9245bfe-vimeo.svg'
  },
  wand: {
    icon: 'fa-solid fa-wand-magic-sparkles',
    link: baseUrl + '/71e2792f83964befdc0a50c1cf0fd7f1-wand-magic-sparkles-solid.svg'
  },
  whatsapp: {
    icon: 'fa-brands fa-whatsapp',
    link: baseUrl + '/96cdb6be91066b96317363a5855a13d2-whatsapp.svg'
  },
  youtube: {
    icon: 'fa-brands fa-youtube',
    link: baseUrl + '/151d957d3aa9d2fc07196bec6f0efce8-youtube.svg'
  },
  planet: {
    icon: 'planet',
    link: process.env.VUE_APP_STORAGE_URL + '/files/bluebird/Microsites/planet-black.svg'
  }
}

export const recognizeSocial = (txt: string) => {
  if (txt.includes(Socials.Fb)) return icons.facebook
  else if (txt.includes(Socials.Ws)) return icons.whatsapp
  else if (txt.includes(Socials.Wsme)) return icons.whatsapp
  else if (txt.includes(Socials.Wslnk)) return icons.whatsapp
  else if (txt.includes(Socials.Ggl)) return icons.google
  else if (txt.includes(Socials.Ig)) return icons.instagram
  else if (txt.includes(Socials.Lkn)) return icons.linkedin
  else if (txt.includes(Socials.Yt)) return icons.youtube
  else if (txt.includes(Socials.Tw)) return icons.twitter
  else if (txt.includes(Socials.Thr)) return icons.threads
  else if (txt.includes(Socials.Tt)) return icons.tiktok
  else if (txt.includes(Socials.Tlg)) return icons.telegram
  else if (txt.includes(Socials.Dsc)) return icons.discord
  else if (txt.includes(Socials.Spt)) return icons.spotify
  else if (txt.includes(Socials.Twt)) return icons.twitch
  else return icons.planet
}
