<template>
  <svg width="12"
       height="12"
       viewBox="0 0 12 12"
       fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <path d="M3.13244 4.3075C2.80001 4.56462 2.48111 4.81939 2.06454 4.8947C1.43027 5.01002 0.863663 4.86293 0.430618 4.38281C0.0122828 3.91741 -0.0959784 3.35139 0.0828879 2.74889C0.143491 2.54531 0.243514 2.34821 0.355306 2.16699C0.731278 1.55861 1.12608 0.961406 1.50323 0.354202C1.6562 0.107673 1.86096 0 2.1475 0C4.71694 0.00353025 7.28579 0.00353025 9.85463 0C10.1371 0 10.3412 0.107084 10.4907 0.344788C10.8931 0.983176 11.3056 1.61509 11.7051 2.25524C12.1822 3.01896 12.074 3.96095 11.4268 4.51637C10.8972 4.9706 10.283 5.03944 9.63458 4.80585C9.34628 4.7023 9.0868 4.54579 8.86499 4.29573C8.47783 4.71466 7.99242 4.92059 7.4317 4.93412C6.86274 4.94765 6.40557 4.68759 6.00018 4.3022C5.59715 4.68347 5.13762 4.93765 4.56455 4.93765C3.99618 4.93765 3.54018 4.68759 3.13244 4.30868V4.3075ZM5.99665 0.700167C4.74694 0.700167 3.49723 0.700167 2.24752 0.69899C2.16515 0.69899 2.10455 0.708404 2.05277 0.7896C1.66327 1.40681 1.26435 2.01754 0.874842 2.63475C0.724218 2.87304 0.651259 3.13251 0.710096 3.41964C0.804237 3.87975 1.21728 4.31632 1.79271 4.2316C2.1322 4.18159 2.41521 4.03802 2.65174 3.79502C2.81177 3.63028 3.0077 3.58674 3.22952 3.61145C3.41486 3.63204 3.53607 3.74854 3.66257 3.86681C4.04266 4.22277 4.481 4.31986 4.97817 4.16041C5.21058 4.08568 5.37886 3.92211 5.55831 3.77031C5.81249 3.55556 6.14434 3.55085 6.40852 3.75207C6.52913 3.84386 6.63798 3.95389 6.76684 4.03214C7.28284 4.34457 7.91064 4.28691 8.35781 3.83386C8.64435 3.54379 9.09798 3.54555 9.36452 3.81974C9.63223 4.0951 9.9576 4.20571 10.3395 4.22395C10.6001 4.23631 10.7984 4.14746 10.972 3.9833C11.3579 3.61792 11.4115 3.09133 11.112 2.6171C10.7331 2.01695 10.3453 1.42152 9.96642 0.821373C9.90994 0.731939 9.8511 0.698402 9.74461 0.698402C8.4949 0.702521 7.24519 0.700756 5.99607 0.700756L5.99665 0.700167Z"
          :fill="color"/>
    <path d="M1.75624 7.76075H10.2453C10.2453 7.69544 10.2453 7.63778 10.2453 7.58071C10.2453 7.08059 10.2442 6.58047 10.2459 6.08035C10.2459 5.94208 10.2524 5.80558 10.3912 5.71791C10.6066 5.582 10.8619 5.67967 10.919 5.92737C10.9331 5.98857 10.9343 6.05388 10.9343 6.11742C10.9349 7.3383 10.9361 8.55918 10.9343 9.77947C10.9337 10.3902 10.6878 10.8697 10.1259 11.1345C9.92055 11.2316 9.67402 11.2834 9.44573 11.2839C7.14635 11.2945 4.84698 11.2916 2.54761 11.2898C1.8498 11.2898 1.30731 10.8939 1.12021 10.2425C1.07902 10.099 1.06902 9.94245 1.06843 9.79241C1.06431 8.56212 1.06314 7.33124 1.07079 6.10095C1.07137 5.98268 1.10197 5.83265 1.17728 5.75263C1.36615 5.55199 1.68387 5.66496 1.74153 5.93679C1.75448 5.99857 1.75448 6.06329 1.75507 6.12683C1.75624 6.61224 1.75566 7.09765 1.75566 7.58306C1.75566 7.64014 1.75566 7.6978 1.75566 7.76134L1.75624 7.76075ZM1.76625 8.4721C1.76213 8.51152 1.75683 8.53506 1.75683 8.55918C1.75683 8.98575 1.75389 9.41232 1.75801 9.8389C1.75919 9.93068 1.77154 10.026 1.79861 10.1131C1.90098 10.439 2.14104 10.5932 2.53643 10.5932C4.84581 10.5932 7.15459 10.5932 9.46397 10.5932C9.50809 10.5932 9.55222 10.5932 9.59635 10.5891C9.95055 10.5579 10.2088 10.3249 10.2253 9.96893C10.2477 9.48469 10.2371 8.99928 10.2394 8.51446C10.2394 8.50152 10.2277 8.48916 10.22 8.47269H1.76625V8.4721Z"
          :fill="color"/>
  </svg>

</template>

<script>
export default {
  name: 'StoreIcon',
  props: {
    color: {
      type: String,
      default: '#8898A9'
    }
  }
}
</script>

<style scoped>

</style>
