import axios from 'axios'
import store from '@/store/index'
import { RestResponseFactory } from '@/models/apis/RestResponse'

export default class ActivitiesService {
  static CancelToken = axios.CancelToken
  static source = this.CancelToken.source()

  static async getActivities () {
    try {
      const token = store.getters['UserStore/getToken']
      const appId = store.getters['AppStore/getSelectedApp'].uuid
      const response = await axios.get(
        `${process.env.VUE_APP_MICROSITES_URL}/${appId}/`,
        {
          headers: {
            Authorization: 'Bearer ' + token
          }
        }
      )
      return new RestResponseFactory().process({ response }).data
    } catch (e) {
      return new RestResponseFactory().process({ response: { status: 500 } })
    }
  }

  static async createConfig (data) {
    try {
      const token = store.getters['UserStore/getToken']
      const appUUID = store.getters['AppStore/getSelectedApp'].uuid
      data = {
        ...data,
        appUUID
      }
      const response = await axios.post(
        `${process.env.VUE_APP_MICROSITES_URL}/microsites/config`,
        data,
        {
          headers: {
            Authorization: 'Bearer ' + token
          }
        }
      )
      return new RestResponseFactory().process({ response }).data
    } catch (e) {
      return new RestResponseFactory().process({ response: { status: 500 } })
    }
  }

  static async editConfig (data) {
    try {
      const token = store.getters['UserStore/getToken']
      const appUUID = store.getters['AppStore/getSelectedApp'].uuid
      data = {
        ...data,
        appUUID
      }
      const response = await axios.put(
        `${process.env.VUE_APP_MICROSITES_URL}/microsites/config/update`,
        data,
        {
          headers: {
            Authorization: 'Bearer ' + token
          }
        }
      )
      return new RestResponseFactory().process({ response }).data
    } catch (e) {
      return new RestResponseFactory().process({ response: { status: 500 } })
    }
  }

  static async getConfig () {
    try {
      const token = store.getters['UserStore/getToken']
      const appUUID = store.getters['AppStore/getSelectedApp'].uuid

      const response = await axios.get(
        `${process.env.VUE_APP_MICROSITES_URL}/microsites/config/${appUUID}`,
        {
          headers: {
            Authorization: 'Bearer ' + token
          }
        }
      )
      return new RestResponseFactory().process({ response }).data
    } catch (e) {
      return new RestResponseFactory().process({ response: { status: 500 } })
    }
  }

  static async createCatalog (data) {
    try {
      const token = store.getters['UserStore/getToken']
      const appUUID = store.getters['AppStore/getSelectedApp'].uuid
      data = {
        ...data,
        appUUID
      }
      const response = await axios.post(
        `${process.env.VUE_APP_MICROSITES_URL}/microsites/catalog`,
        data,
        {
          headers: {
            Authorization: 'Bearer ' + token
          }
        }
      )
      return new RestResponseFactory().process({ response }).data
    } catch (e) {
      return new RestResponseFactory().process({ response: { status: 500 } })
    }
  }

  static async editCatalog (data) {
    try {
      const token = store.getters['UserStore/getToken']
      const appUUID = store.getters['AppStore/getSelectedApp'].uuid
      data = {
        ...data,
        appUUID
      }
      const response = await axios.put(
        `${process.env.VUE_APP_MICROSITES_URL}/microsites/catalog/update`,
        data,
        {
          headers: {
            Authorization: 'Bearer ' + token
          }
        }
      )
      return new RestResponseFactory().process({ response }).data
    } catch (e) {
      return new RestResponseFactory().process({ response: { status: 500 } })
    }
  }

  static async getCatalog () {
    try {
      const token = store.getters['UserStore/getToken']
      const appUUID = store.getters['AppStore/getSelectedApp'].uuid

      const response = await axios.get(
        `${process.env.VUE_APP_MICROSITES_URL}/microsites/front/catalog/${appUUID}`,
        {
          headers: {
            Authorization: 'Bearer ' + token
          }
        }
      )
      return new RestResponseFactory().process({ response }).data
    } catch (e) {
      return new RestResponseFactory().process({ response: { status: 500 } })
    }
  }

  static async getProducts () {
    try {
      const token = store.getters['UserStore/getToken']
      const appUUID = store.getters['AppStore/getSelectedApp'].uuid

      const response = await axios.get(
        `${process.env.VUE_APP_MICROSITES_URL}/microsites/product/all/${appUUID}`,
        {
          headers: {
            Authorization: 'Bearer ' + token
          }
        }
      )
      return new RestResponseFactory().process({ response })
    } catch (e) {
      return e?.response?.data?.error || new RestResponseFactory().process({ response: { status: 500 } })
    }
  }

  static async createProduct (data) {
    try {
      const token = store.getters['UserStore/getToken']
      const appUUID = store.getters['AppStore/getSelectedApp'].uuid
      data = {
        ...data,
        appUUID
      }
      const response = await axios.post(
        `${process.env.VUE_APP_MICROSITES_URL}/microsites/product`,
        data,
        {
          headers: {
            Authorization: 'Bearer ' + token
          }
        }
      )
      return new RestResponseFactory().process({ response })
    } catch (e) {
      return e?.response?.data?.error || new RestResponseFactory().process({ response: { status: 500 } })
    }
  }

  static async editProduct (data) {
    try {
      const token = store.getters['UserStore/getToken']
      const appUUID = store.getters['AppStore/getSelectedApp'].uuid
      data = {
        ...data,
        appUUID
      }
      const response = await axios.put(
        `${process.env.VUE_APP_MICROSITES_URL}/microsites/product/update`,
        data,
        {
          headers: {
            Authorization: 'Bearer ' + token
          }
        }
      )
      return new RestResponseFactory().process({ response }).data
    } catch (e) {
      return new RestResponseFactory().process({ response: { status: 500 } })
    }
  }

  static async toggleProduct (id, status) {
    try {
      const token = store.getters['UserStore/getToken']
      const appUUID = store.getters['AppStore/getSelectedApp'].uuid

      const response = await axios.get(
        `${process.env.VUE_APP_MICROSITES_URL}/microsites/product/published/${appUUID}/${id}/${status}`,
        {
          headers: {
            Authorization: 'Bearer ' + token
          }
        }
      )
      return new RestResponseFactory().process({ response })
    } catch (e) {
      return new RestResponseFactory().process({ response: { status: 500 } })
    }
  }

  static async toggleCategory (id, status) {
    try {
      const token = store.getters['UserStore/getToken']
      const appUUID = store.getters['AppStore/getSelectedApp'].uuid

      const response = await axios.get(
        `${process.env.VUE_APP_MICROSITES_URL}/microsites/category/published/${appUUID}/${id}/${status}`,
        {
          headers: {
            Authorization: 'Bearer ' + token
          }
        }
      )
      return new RestResponseFactory().process({ response })
    } catch (e) {
      return new RestResponseFactory().process({ response: { status: 500 } })
    }
  }

  static async uploadImage (file) {
    try {
      const token = store.getters['UserStore/getToken']
      const appId = store.getters['AppStore/getSelectedApp'].uuid

      const data = new FormData()
      /* eslint-enable */
      data.append('urlImage', file)
      data.append('appUUID', appId)

      const response = await axios.post(
        `${process.env.VUE_APP_MICROSITES_URL}/microsites/images/upload`,
        data,
        {
          headers: {
            Authorization: 'Bearer ' + token
          }
        }
      )
      return new RestResponseFactory().process({ response })
    } catch (e) {
      return new RestResponseFactory().process({ response: { status: 500 } })
    }
  }

  static async checkDomainNameKb (name: string) {
    try {
      this.source.cancel('Operation canceled')
      this.source = this.CancelToken.source()

      const response = await axios.get(
        `${process.env.VUE_APP_MICROSITES_URL}/microsites/front/config/url/${name}`,
        {
          cancelToken: this.source.token
        }
      )
      return response
    } catch (e) {
      return e
    }
  }

  static async getAllCategories () {
    try {
      const token = store.getters['UserStore/getToken']
      const appId = store.getters['AppStore/getSelectedApp'].uuid

      const response = await axios.get(
        `${process.env.VUE_APP_MICROSITES_URL}/microsites/category/all/${appId}`,
        {
          headers: {
            Authorization: 'Bearer ' + token
          }
        }
      )

      // El backend no devuelve correctamente las respuestas
      if ('response' in response) throw new Error()

      return new RestResponseFactory().process({ response })
    } catch (e) {
      return e?.response?.data?.error || new RestResponseFactory().process({ response: { status: 500 } })
    }
  }

  static async createCategory (data) {
    try {
      const token = store.getters['UserStore/getToken']
      const appUUID = store.getters['AppStore/getSelectedApp'].uuid
      data = {
        ...data,
        appUUID
      }
      const response = await axios.post(
        `${process.env.VUE_APP_MICROSITES_URL}/microsites/category`,
        data,
        {
          headers: {
            Authorization: 'Bearer ' + token
          }
        }
      )

      // El backend no devuelve correctamente las respuestas
      if ('response' in response) throw new Error()

      return new RestResponseFactory().process({ response })
    } catch (e) {
      return e?.response?.data?.error || new RestResponseFactory().process({ response: { status: 500 } })
    }
  }

  static async updateCategory (data) {
    try {
      const token = store.getters['UserStore/getToken']
      const appUUID = store.getters['AppStore/getSelectedApp'].uuid
      data = {
        ...data,
        appUUID
      }
      const response = await axios.put(
        `${process.env.VUE_APP_MICROSITES_URL}/microsites/category/update`,
        data,
        {
          headers: {
            Authorization: 'Bearer ' + token
          }
        }
      )

      // El backend no devuelve correctamente las respuestas
      if ('response' in response) throw new Error()

      return new RestResponseFactory().process({ response }).data
    } catch (e) {
      return new RestResponseFactory().process({ response: { status: 500 } })
    }
  }

  static async deleteProducts (productId:string) {
    try {
      const token = store.getters['UserStore/getToken']
      const appUUID = store.getters['AppStore/getSelectedApp'].uuid

      const response = await axios.delete(
        `${process.env.VUE_APP_MICROSITES_URL}/microsites/product/${appUUID}/${productId}`,
        {
          headers: {
            Authorization: 'Bearer ' + token
          }
        }
      )
      return new RestResponseFactory().process({ response }).data
    } catch (e) {
      return new RestResponseFactory().process({ response: { status: 500 } })
    }
  }

  static async getVariationOptions () {
    try {
      const token = store.getters['UserStore/getToken']
      const appId = store.getters['AppStore/getSelectedApp'].uuid

      const response = await axios.get(
        `${process.env.VUE_APP_MICROSITES_URL}/microsites/product/variation/options/all/${appId}`,
        {
          headers: {
            Authorization: 'Bearer ' + token
          }
        }
      )

      // El backend no devuelve correctamente las respuestas
      if ('response' in response) throw new Error()

      return new RestResponseFactory().process({ response })
    } catch (e) {
      return new RestResponseFactory().process({ response: { status: 500 } })
    }
  }

  static async createVariationOption (data) {
    try {
      const token = store.getters['UserStore/getToken']
      const appUUID = store.getters['AppStore/getSelectedApp'].uuid
      data = {
        ...data,
        appUUID
      }
      const response = await axios.post(
        `${process.env.VUE_APP_MICROSITES_URL}/microsites/product/variation/options`,
        data,
        {
          headers: {
            Authorization: 'Bearer ' + token
          }
        }
      )

      // El backend no devuelve correctamente las respuestas
      if ('response' in response) throw new Error()

      return new RestResponseFactory().process({ response }).data
    } catch (e) {
      return new RestResponseFactory().process({ response: { status: 500 } })
    }
  }

  static async createVariation (data) {
    try {
      const token = store.getters['UserStore/getToken']
      const appUUID = store.getters['AppStore/getSelectedApp'].uuid
      data = {
        ...data,
        appUUID
      }
      const response = await axios.post(
        `${process.env.VUE_APP_MICROSITES_URL}/microsites/product/variation`,
        data,
        {
          headers: {
            Authorization: 'Bearer ' + token
          }
        }
      )

      // El backend no devuelve correctamente las respuestas
      if ('response' in response) throw new Error()

      return new RestResponseFactory().process({ response }).data
    } catch (e) {
      return new RestResponseFactory().process({ response: { status: 500 } })
    }
  }
}
