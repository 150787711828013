import KeybeButton from '@/modules/DesignSystem/infrastructure/components/KeybeButton/KeybeButton.vue'
import { CDPFieldsEnums } from '@/modules/CDP/shared/contacts/domain/enums/CDPFieldsEnums'
import { ImportRecordEnum } from '@/modules/imports/domain/Importer'
import ImporterDownloadWarning from '../ImporterDownloadWarning/ImporterDownloadWarning.vue'
import Colors from '@/utils/Colors'
export default {
  components: {
    KeybeButton,
    ImporterDownloadWarning
  },
  props: {
    selectedImport: {
      type: Object
    }
  },
  data () {
    return {
      CDPFieldsEnums,
      ImportRecordEnum,
      showDownloadWarning: false,
      selectedDownloadUrl: '',
      Colors
    }
  },
  computed: {
    importUrl () {
      return this.selectedImport?.storage?.url || ''
    },
    importName () {
      return this.selectedImport?.file?.name || ''
    },
    importSegment () {
      if (this.selectedImport.typeEntity === 'people') return this.selectedImport?.segmentStatic?.name || this.$t('allPeople')
      else return this.selectedImport?.segmentStatic?.name || this.$t('allBusinesses')
    },
    importResponsible (item) {
      return this.selectedImport?.responsible?.name || ' - '
    },
    errorsUrl (): string {
      return this.selectedImport?.additionalInfo?.wrongsUrl || ''
    },
    updatedUrl (): string {
      return this.selectedImport?.additionalInfo?.updatedUrl || ''
    }
  },
  methods: {
    openDownloadWarning (type?: string): void {
      if (type === ImportRecordEnum.WRONGS) this.selectedDownloadUrl = this.errorsUrl
      else if (type === ImportRecordEnum.UPDATED) this.selectedDownloadUrl = this.updatedUrl
      else this.selectedDownloadUrl = this.importUrl
      this.showDownloadWarning = true
    }
  }
}
