import {
  IContactsController,
  dataToGetContact,
  contactGetter,
  dataToUpdateContact,
  contactsUpdater,
  contactCreator,
  contactDeletor,
  dataToCreateContact,
  dataToDeleteContact,
  dataToGetContacts,
  contactsGetter,
  dataToGetContactRoom,
  dataToGetTransactionalData,
  transactionalGetter,
  dataToCreateTransactionalData,
  transactionalCreator,
  dataToUpdateTransactionalData,
  dataToBlacklistContact,
  contactBlacklister,
  dataToValidatePhone,
  dataToGetVerificationLogs,
  verificationLogsGetter,
  dataToGetContactLists,
  listsGetter,
  dataToGetEnrichment,
  enrichmentResponse,
  dataToMergeContacts,
  contactMerge,
  dataToBulkUpdate
} from '@/modules/CDP/shared/contacts/domain/ContactsController'
import ContactsRepository from '../repository/Contacts.repository'
import { GenericResponse } from '@/shared/infrastructure/http/GenericResponse'
import { Contact } from '../../domain/Contact'
import { Paginator } from '@/shared/domain/Paginator'
export default class ContactsController implements IContactsController {
  repository: ContactsRepository

  constructor () {
    this.repository = new ContactsRepository()
  }

  abortAll (): void {
    this.repository.abortAll()
  }

  canAskMorePeople (
    data: Contact[],
    paginator: Paginator,
    offset: number
  ): boolean {
    return data.length < paginator.totalDocs && offset < paginator.totalPages
  }

  async getContacts (data: dataToGetContacts): Promise<contactsGetter> {
    const response = await this.repository.getContacts(data)
    if (response.status) {
      return {
        status: response.status,
        contacts: response?.data?.data,
        total: response?.data?.paginator?.totalDocs,
        pagination: response?.data?.paginator
      }
    } else {
      return {
        status: response.status,
        message: response.message
      }
    }
  }

  async getContactsElastic (data: dataToGetContacts): Promise<contactsGetter> {
    let response = null
    const hasFilters: boolean = data.filters && Object.keys(data.filters).length > 0
    if (hasFilters || !data.search) {
      response = await this.repository.getContacts(data)
      if (response.status) {
        return {
          status: response.status,
          contacts: response?.data?.data,
          total: response?.data?.paginator?.totalDocs,
          pagination: response?.data?.paginator
        }
      } else {
        return {
          status: response.status,
          message: response.message
        }
      }
    } else {
      response = await this.repository.getContactsElasticSearch(data)
      if (response.status) {
        return {
          status: response.status,
          contacts: response?.data?.data,
          total: response?.data?.total,
          pagination: {
            limit: data.limit,
            page: data.offset
          }
        }
      } else {
        return {
          status: response.status,
          message: response.message
        }
      }
    }
  }

  async updateContact (data: dataToUpdateContact): Promise<contactsUpdater> {
    const response = await this.repository.updateContact(data)
    return response
  }

  async getContact (data: dataToGetContact): Promise<contactGetter> {
    const response = await this.repository.getContact(data)
    return response
  }

  async deleteContact (data: dataToDeleteContact): Promise<contactDeletor> {
    const response = await this.repository.deleteContact(data)
    return response
  }

  async createContact (data: dataToCreateContact): Promise<contactCreator> {
    const response = await this.repository.createContact(data)
    if (!response?.status) return { status: false, message: response?.data?.message }
    else return { status: true, data: response?.data }
  }

  async getContactRoom (
    data: dataToGetContactRoom
  ): Promise<{ [key: string]: any }> {
    const response = await this.repository.getContactRoom(data)
    if (response.status) {
      return {
        success: response.status,
        id: response?.data?.room?._id,
        message: ''
      }
    }

    return { success: response.status, id: '', message: response.message }
  }

  async getTransactionalData (
    data: dataToGetTransactionalData
  ): Promise<transactionalGetter> {
    const response = await this.repository.getTransactionalData(data)
    if (response.status) return { status: response.status, data: response.data.data[0] }
    else return { status: response.status, message: response.message }
  }

  async createTransactionalData (
    data: dataToCreateTransactionalData
  ): Promise<transactionalCreator> {
    const response = await this.repository.createTransactionalData(data)
    return response
  }

  async updateTransactionalData (
    data: dataToUpdateTransactionalData
  ): Promise<transactionalCreator> {
    const response = await this.repository.updateTransactionalData(data)
    return response
  }

  async blacklistContact (
    data: dataToBlacklistContact
  ): Promise<contactBlacklister> {
    const response: GenericResponse = await this.repository.blacklistContact(data)
    return response
  }

  async validatePhone (data: dataToValidatePhone): Promise<boolean> {
    const response = await this.repository.validatePhone(data)
    return response.status
  }

  async getVerificationLogs (data: dataToGetVerificationLogs): Promise<verificationLogsGetter> {
    const response = await this.repository.getVerificationLogs(data)
    if (response.status) return { status: response.status, data: response.data.data }
    return { status: response.status, message: response.message }
  }

  async getContactLists (data: dataToGetContactLists): Promise<listsGetter> {
    const response = await this.repository.getContactLists(data)
    if (response.status) return { status: response.status, data: response?.data }
    return { status: response.status, message: response.message }
  }

  async getDataEnrichment (data: dataToGetEnrichment): Promise<enrichmentResponse> {
    const response = await this.repository.getDataEnrichment(data)
    if (response.status) return { status: response.status, data: response.data }
    else return { status: response.status, message: response.message }
  }

  async mergeContacts (data: dataToMergeContacts): Promise<contactMerge> {
    const response = await this.repository.mergeContacts(data)
    if (!response?.status) return { status: false, message: response?.data?.message }
    else return { status: true, data: response?.data }
  }

  async getHabeasDataPDF (data: dataToGetContact): Promise<contactGetter> {
    const response = await this.repository.getHabeasDataPDF(data)
    if (!response?.status) return { status: false, message: response?.message }
    else return { status: true, data: response?.data }
  }

  async bulkUpdateContacts (data: dataToBulkUpdate): Promise<contactsUpdater> {
    const response = await this.repository.bulkUpdateContacts(data)
    return response
  }
}
