<template>
  <img
    v-if="showImage && !isKeybeProfile"
    :style="{
      minWidth: size+'px',
      minHeight: size+'px',
      maxWidth: size+'px',
      maxHeight: size+'px',
      objectFit: objectFit,
      ...additionalStyles
    }"
    :src="imgUrl"
    alt=""
    class="KeybeAvatar-img"
    @error="imgUrl=''"/>
  <div
    :style="style"
    v-else-if="isKeybeProfile">
    <KeybeIcon
      :icon="KeybeIconType.LOGO"
      :color="Colors.kbGreen"
    />
  </div>
  <KeybeIcon v-else
             :icon="KeybeIconType.DEFAULT_USER"
             :style="style"/>
</template>

<script>
import Colors from '@/shared/domain/colors/Color'
import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'

export default {
  name: 'KeybeAvatar',
  components: {
    KeybeIcon
  },
  props: {
    size: {
      type: Number,
      default: 40
    },
    objectFit: {
      type: String,
      default: 'cover'
    },
    additionalStyles: {
      type: Object,
      default: () => ({})
    },
    name: {
      type: String,
      default: '',
      required: true
    },
    img: {
      type: String,
      default: null,
      required: false
    },
    isKeybeProfile: {
      type: Boolean,
      default: false,
      required: false
    },
    small: {
      type: Boolean,
      default: false,
      required: false
    },
    fontSize: {
      type: Number,
      default: 16
    }
  },
  data () {
    return {
      Colors,
      colors: [Colors.yellow2, Colors.automate2, Colors.blue2, Colors.green1, Colors.dark2],
      keybeLogo: `${process.env.VUE_APP_STORAGE_URL}/files/bluebird/conversation-module/LogoKeybeChat.svg`,
      imgUrl: '',
      KeybeIconType
    }
  },
  methods: {
    randomBackgroundColor (seed, colors) {
      return colors[seed % (colors.length)]
    }
  },
  computed: {
    backgroundColor () {
      return this.randomBackgroundColor(this.name.length, this.colors)
    },
    style () {
      return {
        display: 'flex',
        backgroundColor: 'transparent',
        borderRadius: `${this.size / 2}px`,
        minHeight: `${this.size}px`,
        minWidth: `${this.size}px`,
        maxHeight: `${this.size}px`,
        maxWidth: `${this.size}px`,
        lineHeight: `${(this.size + Math.floor(this.size / 20))}px`,
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        userSelect: 'none',
        fontWeight: 400,
        fontSize: `${this.fontSize}px`
      }
    },
    nameInitials () {
      // remove all - and . from name
      const name = this.name.replace(/[-.]/g, ' ')
      return name.split(' ').map(word => word[0]).join('').slice(0, 2).toUpperCase()
    },
    showImage () {
      if (this.isKeybeProfile) {
        return true
      }
      if (this.imgUrl) {
        return true
      }
      return false
    }
  },
  mounted () {
    if (this.img) {
      this.imgUrl = this.img
    }
  },
  watch: {
    img (newImg) {
      this.imgUrl = newImg
    }
  }
}
</script>

<style scoped>
.KeybeAvatar-img {
  object-fit: cover;
  border-radius: 50%;
  background-color: white;
}
.smallText {
  font-size: 10px;
}
</style>
