import LottieAnimation from 'lottie-web-vue'

export default {
  name: 'BillingPlansCards',
  components: { LottieAnimation },
  props: {
    dataCard: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
    }
  },
  methods: {
  },
  mounted () {
  }
}
