
























import MetricsService from '@/modules/metrics/application/services/MetricsService'
import { mapGetters } from 'vuex'
import operations from '@/utils/operations'

export default {
  name: 'KBSelectFlow',
  props: ['value', 'itemText', 'itemValue', 'returnObject', 'nameFlow', 'idFlow'],
  computed: {
    ...mapGetters('AppStore', ['getSelectedApp']),
    appUUID () {
      return this.getSelectedApp.uuid
    },
    loading: {
      get () {
        return this.$store.state.MetricsStore.loading || false
      },
      set (value) {
        this.$store.commit('MetricsStore/SET_EDITING_OBJECT', {
          key: 'loading',
          value
        })
      }
    },
    itemToShow () {
      if (this.itemValue) {
        const item = this.items.filter(item => item[this.itemValue] === this.selectedItem)
        if (item && item[0]) return item[0][this.itemText]
        else return ''
      } else if (this.returnObject) {
        return this.selectedItem[this.itemText]
      } else return this.selectedItem
    },
    flowFilter: {
      get () {
        return this.$store.state.MetricsStore.flowFilter || null
      },
      set (value) {
        this.$store.commit('MetricsStore/SET_EDITING_OBJECT', {
          key: 'flowFilter',
          value
        })
      }
    }
  },
  created () {
    if (this.items.length === 0) {
      this.getPipelines()
    }
    if (this.value) this.selectedItem = this.value
  },
  watch: {
    value (val) {
      this.selectedItem = val
    }
  },
  data () {
    return {
      items: [],
      selectedItem: null,
      selectedItemId: null,
      showSelector: false
    }
  },
  methods: {
    formatN (name) {
      return operations.checkTranslation(name)
    },
    async getPipelines () {
      try {
        const app = this.getSelectedApp?.uuid
        const { data } = await MetricsService.getAppFunnels(app)
        if (data) {
          this.formatName(data)
          this.flowFilter = {
            _id: this.idFlow,
            name: operations.checkTranslation(this.nameFlow)
          }
        }
      } catch (e) {
        console.error(e)
      }
    },
    formatName (data) {
      this.items = data.map(segment => {
        if (!segment?.name) return
        return {
          name: operations.checkTranslation(segment.name),
          _id: segment._id

        }
      })
    },
    selectItem (item) {
      if (this.returnObject) this.selectedItem = item
      else if (this.itemValue) this.selectedItem = item[this.itemValue]
      else {
        this.flowFilter = { _id: item._id, name: operations.checkTranslation(item.name) }
        this.selectedItem = item.name
        this.selectedItemId = item._id
      }
      this.showSelector = false
      this.$emit('sendData', { selectedItem: this.selectedItem, selectedItemId: this.selectedItemId })
    },
    isSelected (item) {
      if (this.itemValue) {
        const found = this.items.filter(i => i[this.itemValue] === this.selectedItem)[0]
        if (found) return true
      } else {
        return this.selectedItem === item
      }
    }
  }
}
