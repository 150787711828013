import axios from 'axios'
import store from '@/store'
import { RestResponse, RestResponseFactory } from '@/models/apis/RestResponse'
import { StatusCodes } from '@/models/apis/StatusCodes'
import { PlanPricing } from './paymentTypes'

export enum CURRENCY_TYPES {
  USD = 'USD',
  EUR = 'EUR',
  COP = 'COP'
}

export enum PAYMENT_TYPES {
  BALANCE = 'balance',
  PLAN = 'plan',
  OTHER = 'other',
  PACKAGE = 'package'
}

export enum TYPE_BUY_PACKAGE {
  CUSTOM = 'CUSTOM',
  NORMAL = 'NORMAL'
}
export interface IPaymentPackage {
  amount: number;
  id: number;
  chargeTypes: any;
  status: boolean;
  name: string;
  type: TYPE_BUY_PACKAGE;
}

export interface IPaymentPlan {
  id: number;
  title: string;
  subtitle?: any;
  active?: boolean;
  cost: number;
  unitPrice?: number;
}

export interface IPaymentIntent {
  type: PAYMENT_TYPES;
  total: number;
  plan?: IPaymentPlan;
  package?: IPaymentPackage;
  currency: CURRENCY_TYPES;
  uuid: string;
  description?: string;
  appUUID?: string;
  method?: string;
  percent?: number;
  months?: number;
  discount?: number;
}

export class PaymentService {
  /** *********************************************************************************** **
  * Obtener el modelo del nuevo precio para los planes. // BIKY-BILLING
  ** *********************************************************************************** **/
  static async payIntentBikyPlan (model: PlanPricing): Promise<RestResponse> {
    const token = store.getters['UserStore/getV4Token']
    const url = `${process.env.VUE_APP_PAYMENT_URL}/payment/create/biky`

    try {
      const response = await axios.post(url, model, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      return new RestResponseFactory().process({ response })
    } catch (e) {
      if (e.response && e.response.data) {
        const errorMessage = e.response.data.error || 'Unknown error occurred'
        const errorF = {
          code: 400,
          data: undefined,
          message: errorMessage,
          status: false
        }
        return errorF
      } else {
        return new RestResponseFactory().process({
          response: {
            status: StatusCodes.HTTP_500_INTERNAL_SERVER_ERROR,
            message: 'Internal server error'
          }
        })
      }
    }
  }
  /** *********************************************************************************** **/

  static async getCreditCards () {
    try {
      const token = store.getters['UserStore/getV4Token']
      const appUUID = store.getters['BillingStore/getAppUUID']
      const url = `${process.env.VUE_APP_PAYMENT_URL}/payment/methods/${appUUID}`
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      return new RestResponseFactory().process({ response })
    } catch (e) {
      return new RestResponseFactory().process({ response: { status: StatusCodes.HTTP_409_CONFLICT, message: 'Failed with transaction, try again' } })
    }
  }

  static async getConfig () {
    try {
      const token = store.getters['UserStore/getV4Token']
      const appUUID = store.getters['BillingStore/getAppUUID']
      const url = `${process.env.VUE_APP_PAYMENT_URL}/payment/config/${appUUID}`
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      return new RestResponseFactory().process({ response })
    } catch (e) {
      return new RestResponseFactory().process({ response: { status: StatusCodes.HTTP_409_CONFLICT, message: 'Failed with transaction, try again' } })
    }
  }

  static async getIntentBiky (payload: IPaymentIntent, type) {
    try {
      const token = store.getters['UserStore/getV4Token']
      const appUUID = store.getters['BillingStore/getAppUUID']
      const url = `${process.env.VUE_APP_PAYMENT_URL}/payment/intent/biky`
      if (!type) {
        // payload.uuid = appUUID
        payload.appUUID = appUUID
      }

      const response = await axios.post(url, payload, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      return new RestResponseFactory().process({ response })
    } catch (e) {
      return new RestResponseFactory().process({ response: { status: StatusCodes.HTTP_409_CONFLICT, message: 'Failed with transaction, try again' } })
    }
  }

  static async getIntent (payload: IPaymentIntent) {
    try {
      const token = store.getters['UserStore/getV4Token']
      const appUUID = store.getters['BillingStore/getAppUUID']
      const url = `${process.env.VUE_APP_PAYMENT_URL}/payment/intent`
      payload.uuid = appUUID
      payload.appUUID = appUUID

      const response = await axios.post(url, payload, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      return new RestResponseFactory().process({ response })
    } catch (e) {
      return new RestResponseFactory().process({ response: { status: StatusCodes.HTTP_409_CONFLICT, message: 'Failed with transaction, try again' } })
    }
  }

  static async applyPaymentPackage (payload: IPaymentIntent) {
    try {
      const token = store.getters['UserStore/getV4Token']
      const appUUID = store.getters['BillingStore/getAppUUID']
      const url = `${process.env.VUE_APP_PAYMENT_URL}/payment/create/package`
      payload.uuid = appUUID
      payload.appUUID = appUUID

      const response = await axios.post(url, payload, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      return new RestResponseFactory().process({ response })
    } catch (e) {
      return new RestResponseFactory().process({ response: { status: StatusCodes.HTTP_409_CONFLICT, message: 'Failed with transaction, try again' } })
    }
  }

  static async applyPayment (payload: IPaymentIntent) {
    try {
      const token = store.getters['UserStore/getV4Token']
      const appUUID = store.getters['BillingStore/getAppUUID']
      const url = `${process.env.VUE_APP_PAYMENT_URL}/payment/create`
      payload.uuid = appUUID
      payload.appUUID = appUUID

      const response = await axios.post(url, payload, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      return new RestResponseFactory().process({ response })
    } catch (e) {
      const errorMessage = e.response?.data?.error
      return { status: false, message: errorMessage || 'Error desconocido' }
    }
  }

  static async savePayment (payload: any) {
    try {
      const token = store.getters['UserStore/getV4Token']
      const appUUID = store.getters['BillingStore/getAppUUID']
      const url = `${process.env.VUE_APP_PAYMENT_URL}/payment/saved/${appUUID}`

      const response = await axios.post(url, payload, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      return new RestResponseFactory().process({ response })
    } catch (e) {
      return new RestResponseFactory().process({ response: { status: StatusCodes.HTTP_409_CONFLICT, message: 'Failed with transaction, try again' } })
    }
  }

  static async errorPayment (payload: any) {
    try {
      const token = store.getters['UserStore/getV4Token']
      const appUUID = store.getters['BillingStore/getAppUUID']
      const url = `${process.env.VUE_APP_PAYMENT_URL}/payment/error/${appUUID}`

      const response = await axios.post(url, payload, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      return new RestResponseFactory().process({ response })
    } catch (e) {
      return new RestResponseFactory().process({ response: { status: StatusCodes.HTTP_409_CONFLICT, message: 'Failed with transaction, try again' } })
    }
  }
}
