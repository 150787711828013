<template>
  <svg width="17"
       height="16"
       viewBox="0 0 17 16"
       fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <path d="M15.8895 11.5421C15.8895 12.527 15.4744 13.4204 14.8027 14.0652C14.1326 14.7116 13.2042 15.111 12.1808 15.111C10.1322 15.111 8.47208 13.5134 8.47208 11.5421C8.47208 10.5572 8.05704 9.6638 7.38531 9.01899C6.71524 8.37257 5.78682 7.97317 4.76338 7.97317C2.71485 7.97317 1.05469 6.37558 1.05469 4.40425C1.05469 3.41939 1.46973 2.52595 2.14146 1.88114C2.81153 1.23473 3.73995 0.835327 4.76338 0.835327C5.78682 0.835327 6.71524 1.23473 7.38531 1.88114C8.05704 2.52595 8.47208 3.41939 8.47208 4.40425C8.47208 6.37558 10.1322 7.97317 12.1808 7.97317C13.2042 7.97317 14.1326 8.37257 14.8027 9.01899C15.4744 9.6638 15.8895 10.5572 15.8895 11.5421Z"
          :fill="color"/>
    <path d="M10.6691 6.09163C9.94569 5.39549 9.58232 4.4812 9.58399 3.56852C9.58399 2.65584 9.94569 1.74155 10.6691 1.04541C12.1176 -0.348471 14.4661 -0.348471 15.9146 1.04541C16.638 1.74155 17.0014 2.65584 16.9997 3.56852C16.9997 4.4812 16.638 5.39549 15.9146 6.09163C15.1912 6.78777 14.2411 7.13744 13.2927 7.13584C12.3443 7.13584 11.3942 6.78777 10.6708 6.09163H10.6691Z"
          :fill="color"/>
    <path d="M1.08511 14.9538C0.361702 14.2577 0 13.3434 0 12.4307C0 11.518 0.361702 10.6037 1.08511 9.9076C2.53358 8.51371 4.88214 8.51371 6.33062 9.9076C7.05402 10.6037 7.41739 11.518 7.41573 12.4307C7.41573 13.3434 7.05402 14.2577 6.33062 14.9538C5.60722 15.65 4.65712 15.9996 3.7087 15.998C2.76027 15.998 1.81018 15.65 1.08677 14.9538H1.08511Z"
          :fill="color"/>
  </svg>
</template>

<script>
export default {
  name: 'BellIcon',
  props: {
    width: {
      type: Number,
      default: 16
    },
    height: {
      type: Number,
      default: 18
    },
    color: {
      type: String,
      default: '#B6BEC7'
    }
  }

}
</script>

<style>

</style>
