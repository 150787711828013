import { VEmojiPicker } from 'v-emoji-picker'
import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'
import Colors from '@/shared/domain/colors/Color'

export default {
  name: 'TemplateTextEditor',
  props: {
    headerLocation: {
      type: String,
      required: false
    },
    bodyLocation: {
      type: String,
      required: false
    },
    value: {
      type: String
    },
    placeholder: {
      type: String,
      default: ''
    },
    small: {
      type: Boolean,
      default: false
    },
    showEmojiPicker: {
      type: Boolean,
      default: true
    }
  },
  components: {
    KeybeIcon,
    VEmojiPicker
  },
  data () {
    return {
      text: '',
      textarea: null,
      KeybeIconType,
      Colors,
      openEmojiPicker: false
    }
  },
  watch: {
    value (value: string): void {
      this.text = value
    },
    text (value: string): void {
      this.$emit('input', value)
    }
  },
  mounted () {
    this.textarea =
      this.$refs['textarea' + this.headerLocation || this.bodyLocation]
    if (!this.textarea) return
    this.textarea.addEventListener('paste', this.onPaste.bind(this))
  },
  beforeDestroy () {
    if (!this.textarea) return
    this.textarea.removeEventListener('paste', this.onPaste.bind(this))
  },
  methods: {
    handleClick (location: string) {
      this.addParam()
      this.setLocation(location)
    },
    setLocation (location: string) {
      if (location === 'headerTextEditor') {
        this.headerLocation = location
        this.$emit('idChanged', this.headerLocation)
      }
      if (location === 'bodyTextEditor') {
        this.bodyLocation = location
        this.$emit('idChanged', this.bodyLocation)
      }
    },
    addParam (): void {
      const textarea: HTMLTextAreaElement = this.textarea
      if (!textarea) return

      // get caret position/selection
      const val: string = textarea.value
      const start: number = textarea.selectionStart
      const end: number = textarea.selectionEnd

      const message: string = ` [${this.$t('editableParam')}] `

      const newValue = val.substring(0, start) + message + val.substring(end)
      this.text = newValue
      this.openEmojiPicker = false
    },
    onPaste (event: ClipboardEvent) {
      const value: string = event.clipboardData.getData('text/plain') || ''
      // Split the text by the param [any text here]
      const params: string[] = value.split(/\[.*?\]/)
      if (params.length <= 1) return

      const location: string = this.headerLocation || this.bodyLocation

      for (let i: number = 0; i < params.length; i++) {
        this.setLocation(location)
      }

      setTimeout(() => {
        this.text = params.join(`[${this.$t('editableParam')}]`)
      }, 500)
    },
    onSelectEmoji (emoji: { [key: string]: any }): void {
      const textarea = this.textarea
      if (!textarea) return

      // get caret position/selection
      const val = textarea.value
      const start = textarea.selectionStart
      const end = textarea.selectionEnd

      const newValue = val.substring(0, start) + emoji.data + val.substring(end)
      this.text = newValue
    },
    insertNewLine (event) {
      if (event.key === 'Enter') {
        if (event.target instanceof HTMLTextAreaElement) {
          const textarea = event.target
          const { selectionStart, value } = textarea
          const linesBeforeCursor = value.substring(0, selectionStart).split('\n')
          let consecutiveNewlines = 0
          for (let i = linesBeforeCursor.length - 1; i >= 0; i--) {
            if (linesBeforeCursor[i] === '') {
              consecutiveNewlines++
            } else {
              break
            }
          }

          if (consecutiveNewlines >= 2) {
            event.preventDefault()
            return
          }
          const { selectionEnd } = textarea
          textarea.value = value.substring(0, selectionStart) + '\n' + value.substring(selectionEnd)
          textarea.selectionStart = textarea.selectionEnd = selectionStart + 1
          event.preventDefault()
        }
      }
    }

  }
}
