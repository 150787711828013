
import {
  IStepsController,
  dataToCreateStep,
  dataToUpdateStep,
  stepsUpdater,
  dataToDeleteStep,
  dataToGetSteps,
  stepsGetter,
  dataToGetStep,
  stepGetter,
  dataToGetStepAmount,
  stepsAmountGetter
} from '@/modules/Sales/steps/domain/StepsController'
import StepsRepository from '../repository/Steps.repository'

export default class StepsController implements IStepsController {
  repository: StepsRepository

  constructor () {
    this.repository = new StepsRepository()
  }

  abortAll (): void {
    this.repository.abortAll()
  }

  async getStep (data: dataToGetStep): Promise<stepGetter> {
    const response = await this.repository.getStep(data)
    return response
  }

  async getSteps (data: dataToGetSteps) : Promise<stepsGetter> {
    const response = await this.repository.getSteps(data)
    return response
  }

  async createStep (data: dataToCreateStep): Promise<stepsUpdater> {
    const response = await this.repository.createStep(data)
    return response
  }

  async updateStep (data: dataToUpdateStep): Promise<stepsUpdater> {
    const response = await this.repository.updateStep(data)
    return response
  }

  async deleteStep (data: dataToDeleteStep): Promise<stepsUpdater> {
    const response = await this.repository.deleteStep(data)
    return response
  }

  async getStepAmount (data: dataToGetStepAmount): Promise<stepsAmountGetter> {
    const response = await this.repository.getStepAmount(data)
    if (response.status) return { status: true, amount: response?.data?.total }
    return { status: response?.status, message: response?.message }
  }
}
