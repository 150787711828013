<template>
  <svg version="1.1"
       id="Capa_1"
       xmlns="http://www.w3.org/2000/svg"
       xmlns:xlink="http://www.w3.org/1999/xlink"
       x="0px"
       y="0px"
       viewBox="0 0 585 589"
       style="enable-background:new 0 0 585 589;"
       xml:space="preserve">
    <g>
      <path d="M56.75,408.34c2.87-11.54-2.24-18.98-10.7-26.71c-49.32-45.08-48.22-130.18,2.14-176.17c7.06-6.45,9.9-12.93,9.27-22.11
        C53.61,126.81,99,71.78,153.96,60.9c10.41-2.06,21.33-1.4,31.86-3.03c5.32-0.82,11.72-2.58,15.21-6.22
        c51.73-53.98,131.21-54.07,183.17-0.19c3.53,3.66,10.02,6.44,15.08,6.36c62.23-0.97,112.23,37.13,126.41,97.82
        c2.31,9.9,0.87,20.62,2.56,30.73c1.01,6.02,3.29,13.08,7.47,17.13c47.43,46.04,53.7,112.63,15.01,165.74
        c-13.36,18.34-19.9,34.13-23.39,56.5c-9.52,60.97-63.23,106.08-126.16,105.43c-8.06-0.08-13.38,1.95-18.98,8.09
        c-47.12,51.55-132.28,51.17-179.56-0.69c-4.96-5.44-9.74-7.5-17.07-7.4c-54.73,0.77-102.54-31.73-121.92-82.72
        c-2.6-6.84-4.04-14.19-5.3-21.43C57.27,420.87,57.24,414.55,56.75,408.34z M178.14,109.74c-15.64,0.1-29.19,5.81-40.91,15.2
        c-25.06,20.06-32.12,46.59-25.68,77.26c2.65,12.6-0.56,22.47-11.7,29.53c-4.67,2.96-9.37,6.04-13.44,9.74
        c-18.09,16.41-26.35,36.59-23.27,61.23c2.84,22.81,14.24,39.89,33.41,52.1c12.81,8.16,18.54,18.48,14.72,34.54
        c-4.47,18.75-2.82,37.57,8.21,54.26c18.03,27.32,42.97,39.83,75.71,32.56c17.16-3.81,29.58,1.28,38.63,15.95
        c1.74,2.82,3.7,5.55,5.92,8.01c17.02,18.92,38.17,26.9,63.41,23.24c20.89-3.03,37.29-13.44,48.65-31.3
        c8.62-13.56,19.8-18.98,36.45-16.55c12.43,1.81,26.38,2.27,38.07-1.54c38.77-12.61,55.29-43.36,47.57-86.46
        c-2.37-13.25,1.56-22.2,11.75-29.59c4.18-3.03,8.55-5.87,12.41-9.28c19.34-17.09,27.63-38.51,24.28-64.18
        c-3.02-23.19-15.39-40.28-35.07-52.29c-11.87-7.25-16.51-17.22-13.12-30.92c0.97-3.91,0.91-8.06,1.44-12.08
        c6.34-47.95-38.62-88.25-85.52-76.64c-18.46,4.57-25.81,1.85-36.78-13.57c-2.35-3.31-4.61-6.69-7.12-9.88
        c-15.04-19.11-35.72-25.46-58.79-23.96c-24.33,1.58-42.94,13.15-55.89,34.51c-8.97,14.81-18.11,17.25-34.84,13.26
        C190.54,111.46,184.3,110.77,178.14,109.74z"/>
      <path d="M441.15,189.72c-0.24-0.31-0.51-0.61-0.79-0.89c-5.84-5.84-13.61-9.06-21.88-9.06s-16.04,3.22-21.88,9.06L251.05,334.31
        l-61.84-61.79c-12.07-12.06-31.7-12.06-43.76,0c-5.84,5.84-9.06,13.61-9.06,21.88s3.22,16.04,9.06,21.88l83.7,83.7
        c6.03,6.03,13.96,9.05,21.88,9.05c7.92,0,15.85-3.02,21.88-9.05l167.39-167.39C452.07,220.81,452.35,201.84,441.15,189.72z"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'VerifyCloudIcon',
  props: {
    color: {
      type: String,
      default: '#B6BEC7'
    }
  }
}
</script>

<style>

</style>
