import { GenericResponse } from '@/models/apis/GenericResponse'
import { HeadersBuilder } from '@/shared/infrastructure/http/HeadersBuilder'
import { getOptions, nonGetOptions } from '@/shared/infrastructure/http/HttpClient'
import KeybeRepository from '@/shared/infrastructure/repositories/KeybeRepository'
import { dataToCreateConfig, dataToCreateUser, dataToGetConfig, dataToGetConfigUrl, dataToUpdateConfig } from '../../domain/VecindarioConfigController'

export class VecindarioConfigRepository extends KeybeRepository {
  baseUrl = process.env.VUE_APP_CONVERSATIONS_API_URL

  getConfigAbortController: AbortController
  createVecindarioConfigAbortController: AbortController
  getConfigUrlAbortController: AbortController
  getCalendarUrlAbortController: AbortController
  getUserConfigUrlAbortController: AbortController

  constructor () {
    super()

    this.getConfigAbortController = new AbortController()
    this.createVecindarioConfigAbortController = new AbortController()
    this.getConfigUrlAbortController = new AbortController()
    this.getCalendarUrlAbortController = new AbortController()
    this.getUserConfigUrlAbortController = new AbortController()
  }

  async getConfig (data: dataToGetConfig): Promise<GenericResponse> {
    const { appUUID, token } = data
    const headers = HeadersBuilder.buildSimpleAutorizationHeader({ token })
    const url = `${this.baseUrl}/ai/appointment`
    const params = { appUUID }

    const requestData: getOptions = {
      headers,
      query: params,
      url
    }

    return await this.client.get(requestData)
  }

  async createVecindarioConfig (data: dataToCreateConfig): Promise<GenericResponse> {
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    const url = `${this.baseUrl}/ai/appointment/project`

    const requestData: nonGetOptions = {
      headers,
      data,
      url
    }

    return await this.client.post(requestData)
  }

  async updateVecindarioConfig (data: dataToUpdateConfig): Promise<GenericResponse> {
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    const url = `${this.baseUrl}/ai/appointment/project`

    const requestData: nonGetOptions = {
      headers,
      data,
      url
    }

    return await this.client.put(requestData)
  }

  async getConfigUrl (data: dataToGetConfigUrl): Promise<GenericResponse> {
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    const url = `${this.baseUrl}/ai/appointment/project/config-url`

    const requestData: nonGetOptions = {
      headers,
      data,
      url
    }

    return await this.client.post(requestData)
  }

  async getCalendarUrl (data: dataToGetConfigUrl): Promise<GenericResponse> {
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    const url = `${this.baseUrl}/ai/appointment/project/calendar-url`

    const requestData: nonGetOptions = {
      headers,
      data,
      url
    }

    return await this.client.post(requestData)
  }

  async getUserSchedulingUrl (data: dataToCreateUser): Promise<GenericResponse> {
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    const url = `${this.baseUrl}/ai/appointment/project/user`

    const requestData: nonGetOptions = {
      headers,
      data,
      url
    }

    return await this.client.post(requestData)
  }

  // abort requests
  abortGetConfig () {
    this.getConfigAbortController.abort()
    this.getConfigAbortController = new AbortController()
  }

  abortCreateVecindarioConfig () {
    this.createVecindarioConfigAbortController.abort()
    this.createVecindarioConfigAbortController = new AbortController()
  }

  abortGetConfigUrl () {
    this.getConfigUrlAbortController.abort()
    this.getConfigUrlAbortController = new AbortController()
  }

  abortGetCalendarUrl () {
    this.getCalendarUrlAbortController.abort()
    this.getCalendarUrlAbortController = new AbortController()
  }

  abortGetUserConfigUrl () {
    this.getUserConfigUrlAbortController.abort()
    this.getUserConfigUrlAbortController = new AbortController()
  }

  abortAll () {
    this.abortGetConfig()
    this.abortCreateVecindarioConfig()
    this.abortGetConfigUrl()
    this.abortGetCalendarUrl()
    this.abortGetUserConfigUrl()
  }
}
