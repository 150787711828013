import KeybeRepository from '@/shared/infrastructure/repositories/KeybeRepository'
import { HeadersBuilder } from '@/shared/infrastructure/http/HeadersBuilder'
import { getOptions, nonGetOptions } from '@/shared/infrastructure/http/HttpClient'
import { dataToCreateStep, dataToDeleteStep, dataToGetStep, dataToGetStepAmount, dataToGetSteps, dataToUpdateStep } from '../../domain/StepsController'
import { GenericResponse } from '@/models/apis/GenericResponse'
import operations from '@/utils/operations'

export default class StepsRepository extends KeybeRepository {
  getSuggestionsAbortController: AbortController
  getStepsAbortController: AbortController

  constructor () {
    super()

    this.getSuggestionsAbortController = new AbortController()
    this.getStepsAbortController = new AbortController()
  }

  async getStep (data: dataToGetStep): Promise<GenericResponse> {
    const { app, stepId } = data
    const url = `${process.env.VUE_APP_FUNNELS_HTTP}/${app}/steps/${stepId}`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    const requestOptions: getOptions = {
      headers,
      url
    }

    return await this.client.get(requestOptions)
  }

  async getSteps (data: dataToGetSteps) {
    const { app, funnelId } = data
    const url = `${process.env.VUE_APP_FUNNELS_HTTP}/${app}/${funnelId}/steps`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    const requestOptions: getOptions = {
      headers,
      url,
      abortController: this.getSuggestionsAbortController
    }

    return await this.client.get(requestOptions)
  }

  async createStep (data: dataToCreateStep) {
    const { app, funnelId, createInput } = data
    const url = `${process.env.VUE_APP_FUNNELS_HTTP}/${app}/${funnelId}/steps`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    const requestOptions: nonGetOptions = {
      headers,
      data: createInput,
      url,
      abortController: this.getSuggestionsAbortController
    }

    return await this.client.post(requestOptions)
  }

  async updateStep (data: dataToUpdateStep) {
    const { app, funnelId, stepId, updateInput } = data

    const url = `${process.env.VUE_APP_FUNNELS_HTTP}/${app}/${funnelId}/steps/${stepId}`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    const requestOptions: nonGetOptions = {
      headers,
      data: updateInput,
      url,
      abortController: this.getSuggestionsAbortController
    }

    return await this.client.patch(requestOptions)
  }

  async deleteStep (data: dataToDeleteStep) {
    const { app, funnelId, stepId } = data
    const url = `${process.env.VUE_APP_FUNNELS_HTTP}/${app}/${funnelId}/steps/${stepId}`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    const requestOptions: nonGetOptions = {
      headers,
      url,
      abortController: this.getSuggestionsAbortController
    }

    return await this.client.delete(requestOptions)
  }

  async getStepAmount (data: dataToGetStepAmount): Promise<GenericResponse> {
    const { app, funnel, step, offset, limit, search, filters } = data

    const url = `${process.env.VUE_APP_FUNNELS_HTTP}/${app}/funnel/${funnel}/steps/${step}/cards/total`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    let formattedFilters = {}
    if (filters) {
      formattedFilters = operations.transformObject(filters)
    } else formattedFilters = {}
    const query = {
      offset,
      limit,
      search,
      ...formattedFilters
    }

    if (!query.search) delete query.search
    const requestOptions: getOptions = {
      headers,
      query,
      url,
      abortController: this.getSuggestionsAbortController
    }

    return await this.client.get(requestOptions)
  }

  abortGetSteps () {
    this.getStepsAbortController.abort()
    this.getStepsAbortController = new AbortController()
  }

  abortGetSuggestions () {
    this.getSuggestionsAbortController.abort()
    this.getSuggestionsAbortController = new AbortController()
  }

  abortAll () {
    this.abortGetSteps()
    this.abortGetSuggestions()
    // TODO: ABORT REST
  }
}
