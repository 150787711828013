







export default {
  name: 'TasksIcon',
  props: {
    color: {
      type: String,
      default: '#B6BEC7'
    },
    iconClass: {
      type: String,
      default: ''
    }
  }
}
