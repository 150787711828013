<template>
  <div :key="`select-${rerender}`"
       class="KBSelect"
       :class="{ 'KBSelect--open': showSelector,
                 'w-full': !transparent,
                 'w-auto': transparent }"
       :style="{ minWidth: minWidth }">
    <v-menu
      :location="location"
      offset-y
      v-model="showSelector"
      transition="slide-y-transition"
      style="z-index: 999999">
      <template v-slot:activator="{ attrs }">
        <div class="KBSelect__container--seamless"
             :class="{ 'cursor-default': disabled }"
             v-if="transparent"
             v-bind="attrs"
             @click.stop="openSelector">
          <div v-if="isStatus && selectedStatusColor"
               :style="{ background: selectedStatusColor }"
               class="status-dot mr-2"/>
          <span class="seamless-text"
                v-if="selectedItem">{{ $t(itemToShow) }}</span>
          <span v-else
                class="seamless-text">{{ placeholder }}</span>
          <span class="mdi mdi-menu-down"
                v-if="!loading"/>
          <lottie-animation
            v-else
            ref="anim"
            class="loaderAnimation"
            :loop="true"
            style="width: 30px"
            :animationData="require('@/shared/assets/animations/circleLoader.json')"
          />
        </div>
        <div class="KBSelect__container"
             v-else
             v-on="on"
             @click="openSelector"
             :class="{ 'KBSelect__container--transparent': seamless,
                       'KBSelect__container--transparent-open': showSelector && seamless,
                       'KBSelect__container--outlined': outlined,
                       'cursor-default': disabled }">
          <div v-if="isStatus && selectedStatusColor"
               :style="{ background: selectedStatusColor }"
               class="status-dot mr-2"/>
          <span v-if="selectedItem"
                :class="{ 'selected--dark': $vuetify.theme.dark }">{{ $t(itemToShow) }}</span>
          <span v-else
                class="placeholder">{{ placeholder }}</span>
          <span class="mdi mdi-menu-down"
                v-if="!loading"/>
          <lottie-animation
            v-else
            ref="anim"
            class="loaderAnimation"
            :loop="true"
            style="width: 30px"
            :animationData="require('@/shared/assets/animations/circleLoader.json')"
          />
        </div>
      </template>
      <v-list
        class="KBSelect__list newBackground"
        id="kbselect">
        <v-list-item
          v-for="(item, index) in items"
          :key="index"
          :value="index"
          id="kbselect"
          class="d-flex items-center list-item newBackground"
          @click="selectItem(item)"
        >
          <v-list-item-title
            class="d-flex items-center"
            id="kbselect">
            <div v-if="isStatus"
                 id="kbselect"
                 :style="{ background: item.mainColor ? item.mainColor : item.color ? item.color : '' }"
                 class="status-dot mr-2"/>
            {{ isExtra ? item.name ? $t(item.name) : $t(item) : itemText ? $t(item[itemText]) : $t(item) }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import LottieAnimation from 'lottie-web-vue'

export default {
  props: {
    items: {
      type: Array,
      default: () => []
    },
    icon: {
      type: String,
      default: ''
    },
    value: {
      type: [String, Object, Number],
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    itemText: {
      type: [String, Array],
      default: ''
    },
    itemValue: {
      type: String,
      default: ''
    },
    returnObject: {
      type: Boolean,
      default: false
    },
    outlined: {
      type: Boolean,
      default: false
    },
    seamless: {
      type: Boolean,
      default: false
    },
    autoSearch: {
      type: [Object, String],
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: false
    },
    forceOpen: {
      type: Boolean,
      default: false
    },
    forceValue: {
      type: Boolean,
      default: false
    },
    containerId: {
      type: String,
      default: ''
    },
    forceIgnoreSearch: {
      type: Boolean,
      default: false
    },
    transparent: {
      type: Boolean
    },
    isStatus: {
      type: Boolean
    },
    statusColor: {
      type: String
    },
    isExtra: {
      type: Boolean
    },
    bottom: {
      type: Boolean
    },
    minWidth: {
      type: String
    },
    location: {
      type: String,
      default: 'bottom'
    }
  },
  components: {
    LottieAnimation
  },
  computed: {
    ...mapGetters('AppStore', ['isCDPApp']),
    itemToShow () {
      if (this.itemValue) {
        const item = this.items.filter(item => item[this.itemValue] === this.selectedItem)
        if (item && item[0]) return item[0][this.itemText]
        else return this.placeholder
      } else if (this.returnObject) {
        return this.selectedItem[this.itemText] || this.placeholder
      } else if (this.itemText) return this.selectedItem[this.itemText] || this.placeholder
      else return this.selectedItem
    }
  },
  mounted () {
    setTimeout(() => {
      if (this.value) {
        if (this.returnObject) {
          if (this.isStatus && this.value) {
            let filter = []
            if (this.isCDPApp) filter = this.items.filter(item => item._id === this.value)
            else filter = this.items.filter(item => item.id === this.value)
            if (filter?.length > 0) this.selectedItem = filter[0]
            this.selectedStatusColor = this.statusColor
          } else if (this.isStatus && !this.value.id) this.selectedItem = null
          else if (!this.isStatus) this.selectedItem = this.value
        } else if (this.itemValue) this.selectedItem = this.value
        else this.selectedItem = this.value
        this.rerender++
      }
    }, 600)
  },
  data () {
    return {
      rerender: 0,
      selectedItem: null,
      showSelector: false,
      selectedStatusColor: ''
    }
  },
  methods: {
    selectItem (item) {
      if (this.returnObject) this.selectedItem = item
      else if (this.itemValue) this.selectedItem = item[this.itemValue]
      else this.selectedItem = item
      if (this.isStatus) {
        this.selectedStatusColor = this.isCDPApp ? item.color : item.mainColor
      }
      this.showSelector = false
      this.$emit('input', this.selectedItem)
      this.$emit('save', this.selectedItem)
    },
    isSelected (item) {
      if (this.itemValue) {
        const found = this.items.filter(i => i[this.itemValue] === this.selectedItem)[0]
        if (found) return true
      } else {
        return this.selectedItem === item
      }
    },
    openSelector () {
      if (!this.disabled) this.showSelector = !this.showSelector
    },
    close () {
      this.showSelector = false
    }
  },
  watch: {
    showSelector () {
      this.$emit('open', this.showSelector)
      if (!this.bottom) {
        if (!this.showSelector) return
        const list = this.$refs.list
        if (list) {
          // get list position
          const listPos = list?.getBoundingClientRect()
          // get window height
          const windowHeight = window?.innerHeight
          // get list height
          const listMaxHeight = 212
          // get list top position
          const listTop = listPos?.top
          // if list go out of window
          // console.log('listTop', listTop)
          // console.log('listMaxHeight', listMaxHeight)
          // console.log('windowHeight', windowHeight)
          const listBottom = listTop + listMaxHeight

          if (listBottom > windowHeight) {
            // set list position to top
            list.style.bottom = '39px'
          } else {
            list.style.bottom = 'auto'
          }
        }
      }
    }
  }
}
</script>
<style scoped lang="scss">
.KBSelect {
  box-shadow: rgba(182, 190, 199, 0);
  transition: box-shadow 0.5s;
  max-height: 265px;
  position: relative;
  z-index: 999;

  &__container {
    background: rgba(182, 190, 199, 0.2);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 30px;
    height: 53px;
    border-radius: 9px;
    cursor: pointer;

    .placeholder {
      color: $simple-grey;
    }
  }

  &__container--outlined {
    border-radius: 5px !important;
    border: 1px solid $simple-grey;
    background-color: transparent !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 30px;
    height: 53px;
    cursor: pointer;
  }

  &__container--transparent {
    background: rgba(182, 190, 199, 0.18);
    padding: 0 30px;
    cursor: pointer;
    transition: background 0.4s ease;
  }

  &__container--transparent:hover {
    background: rgba(182, 190, 199, 0.2);
  }

  &__container--transparent-open {
    padding: 0 30px;
    cursor: pointer;
    background: rgba(182, 190, 199, 0.2);
  }

  &__container--seamless {
    display: flex;
    align-items: center;
    width: auto !important;

    .placeholder {
      color: #B6BEC7;
    }

    .seamless-text {
      margin-right: 5px;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  &__list {
    overflow: hidden;
    width: inherit;
    max-height: 212px;
    overflow: auto;

    .list-item {
      height: 53px;
      padding: 0px 30px;
      cursor: pointer;
      display: flex;
      align-items: center;
      width: auto;
      white-space: nowrap;
      transition: background 0.4s;
    }

    .list-item--selected,
    .list-item:hover {
      background: rgba(182, 190, 199, 0.2) !important;
    }
  }

  &__list--open {
    overflow: scroll;
    overscroll-behavior: contain;
    max-height: 212px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  }
}

.KBSelect--open {
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
}

.selected--dark {
  color: $simple-grey !important;
}

.status-dot {
  height: 10px;
  width: 10px;
  min-height: 10px;
  min-width: 10px;
  border-radius: 50%;
}
</style>
