import { EventBus } from '@/eventBus'
import KeybeText from '@/modules/DesignSystem/infrastructure/components/KeybeText/KeybeText.vue'
import { currencyToUSD } from '@/utils/currencyToUSD'
import Loader from '@/shared/infrastructure/ui/Loader.vue'
import FeesUseCases from '@/modules/Fees/application/FeesUseCases'
import FeesController from '@/modules/Fees/infrastructure/controllers/Fees.controller'

export default {
  name: 'KBInvoicesPartners',
  components: {
    KeybeText,
    Loader
  },
  data () {
    return {
      loading: false,
      groupedData: [], // Agrupamos los datos aquí
      searchQuery: '', // Campo para la consulta de búsqueda
      feesController: new FeesController()
    }
  },
  mounted () {
    this.calculateSize(true)
  },
  beforeDestroy () {
    window.removeEventListener('resize', () => this.calculateSize())
  },
  computed: {
    filteredGroupedData () {
      if (!this.searchQuery) {
        return this.groupedData
      }

      const query = this.searchQuery.toLowerCase()
      return this.groupedData
        .map(group => {
          const filteredItems = group.items.filter(item =>
            item.name.toLowerCase().includes(query) ||
            item.cdpStatusName.toLowerCase().includes(query)
          )
          return { ...group, items: filteredItems }
        })
        .filter(group => group.items.length > 0)
    }
  },
  async created () {
    await this.getServicesMethod()
  },
  filters: {
    currencyUSD (value) {
      if (!value) return
      return currencyToUSD(value)
    }
  },
  watch: {
  },
  methods: {
    async getServicesMethod () {
      if (this.loading) return
      try {
        this.loading = true
        this.groupedData = []
        const data = {
          token: this.token
        }
        const response = await FeesUseCases.getFeesData(this.feesController, data)
        if (response.status) {
          const groupedData = response.data.reduce((acc, item) => {
            const name = item.responsibleName
            let group = acc.find(g => g.name === name)
            if (!group) {
              group = { name, items: [], expanded: false }
              acc.push(group)
            }
            group.items.push(item)
            return acc
          }, [])
          this.groupedData = groupedData
          this.totalItems = response.data.length
          console.log('groupedData', groupedData)
        }
      } catch (e) {
        console.error(e)
      } finally {
        this.loading = false
      }
    },
    toggleGroup (group) {
      group.expanded = !group.expanded
      this.$forceUpdate()
    },
    calculateSize (fullscreen = false) {
      if (fullscreen) {
        const clientHeight = document.documentElement.clientHeight
        const clientWidth = document.documentElement.clientWidth
        document.documentElement.style.setProperty(
          '--floating-screen-height',
          `${clientHeight - 30}px`
        )
        document.documentElement.style.setProperty(
          '--floating-screen-width',
          `${clientWidth - 50}px`
        )

        // set top and left to 0
        const floatingWindow = document.getElementById('KeybeFloatingBox')
        floatingWindow.style.top = '0px'
        floatingWindow.style.left = '0px'

        EventBus.$emit('FloatingWindow-resize')
        return
      }

      const clientHeight = document.documentElement.clientHeight
      this.totalHeight = clientHeight * 0.8097
      this.heightWithoutHeader = clientHeight * 0.8097 - 30
      document.documentElement.style.setProperty(
        '--floating-screen-height',
        `${this.heightWithoutHeader}px`
      )

      const clientWidth = document.documentElement.clientWidth
      this.width = clientWidth * 0.8
      document.documentElement.style.setProperty(
        '--floating-screen-width',
        `${this.width}px`
      )

      EventBus.$emit('FloatingWindow-resize')
    }
  }
}
