import { OutboundTabs } from '@/modules/outbound/infrastructure/enums/OutboundTabs'

import OutboundSidebar from '@/modules/outbound/infrastructure/ui/components/OutboundSidebar/OutboundSidebar.vue'
import OutboundAll from '@/modules/outbound/infrastructure/ui/components/OutboundAll/OutboundAll.vue'
import NewWhatsappPage from '@/modules/outbound/infrastructure/ui/components/NewWhatsappPage/NewWhatsappPage.vue'
import NewSmsPage from '@/modules/outbound/infrastructure/ui/components/NewSmsPage/NewSmsPage.vue'
import MyTemplate from '@/modules/outbound/infrastructure/ui/components/MyTemplate/MyTemplate.vue'
import ConversationsPage from '@/modules/metrics/infrastructure/ui/components/ConversationsPage/ConversationsPage.vue'
import SalesPage from '@/modules/metrics/infrastructure/ui/components/SalesPage/SalesPage.vue'
import OutBoundDetail from '@/modules/outbound/infrastructure/ui/components/OutBoundDetail/OutBoundDetail.vue'
import OustandingMetrics from '@/modules/outbound/infrastructure/ui/components/Detail/OustandingMetrics/OustandingMetrics.vue'
import segmentsMetrics from '@/modules/outbound/infrastructure/ui/components/Detail/segmentsMetrics/segmentsMetrics.vue'
import NewEmailPage from '@/modules/outbound/infrastructure/ui/components/NewEmailPage/NewEmailPage.vue'
import OutboundImport from '@/modules/outbound/infrastructure/ui/components/OutboundImport/OutboundImport.vue'
import TemplateList from '@/modules/createTemplates/infrastructure/ui/TemplatesList/TemplatesList.vue'

export default {
  name: 'OutboundScreen',
  props: {
    segment: {
      type: String
    }
  },
  components: {
    OutboundImport,
    NewSmsPage,
    MyTemplate,
    TemplateList,
    OutboundSidebar,
    OustandingMetrics,
    ConversationsPage,
    OutboundAll,
    NewEmailPage,
    SalesPage,
    OutBoundDetail,
    segmentsMetrics
  },
  data () {
    return {
      importStatus: false,
      dataComponent: null,
      OutboundTabs,
      selectedTab: OutboundTabs.HOME
    }
  },
  computed: {
    summaryData: {
      get () {
        return this.$store.state.CampaingsStore.summaryData || null
      },
      set (value) {
        this.$store.commit('CampaingsStore/SET_EDITING_OBJECT', {
          key: 'summaryData',
          value
        })
      }
    },
    currentSelectedTab: {
      get () {
        return this.$store.state.CampaingsStore.currentSelectedTab || null
      },
      set (value) {
        this.$store.commit('CampaingsStore/SET_EDITING_OBJECT', {
          key: 'currentSelectedTab',
          value
        })
      }
    },
    selectedTabComponent () {
      const components = {
        [OutboundTabs.NEW_WHATSAPP]: NewWhatsappPage,
        [OutboundTabs.NEW_SMS]: NewSmsPage,
        [OutboundTabs.NEW_EMAIL]: NewEmailPage,
        [OutboundTabs.HOME]: OutboundAll,
        [OutboundTabs.TEMPLATE]: MyTemplate,
        [OutboundTabs.SUMMARY]: OutBoundDetail,
        [OutboundTabs.OUTSTANDING]: OustandingMetrics,
        [OutboundTabs.SEGMENT]: segmentsMetrics,
        [OutboundTabs.TEMPLATE_WHATSAPP]: TemplateList
      }

      return components[this.selectedTab]
    }
  },
  watch: {
    summaryData: {
      deep: true,
      immediate: true,
      handler () {
        if (this.summaryData) {
          const { status } = this.summaryData
          status === 'importing' ? this.importStatus = true : this.importStatus = false
        }
      }
    },
    selectedTab () {
      this.currentSelectedTab = this.selectedTab
    }
  },
  methods: {
  },
  async created () {
    this.currentSelectedTab = this.selectedTab
  }
}
