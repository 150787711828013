import { Company } from '@/components/models/Company'

export const CompanyStore = {
  namespaced: true,
  state: {
    companies: [],
    selectedCompany: {},
    administrators: [],
    adminCount: null
  },
  mutations: {
    SET_SELECTED_COMPANY (state, company: Company) {
      state.selectedCompany = company
    },
    CLEAR_COMPANY_STATE (state) {
      state.companies = []
      state.selectedCompany = {}
    },
    SET_ADMINISTRATORS (state, administrators) {
      state.administrators = administrators
    },
    SET_ADMINISTRATORS_COUNT (state, count) {
      state.adminCount = count
    }
  },
  actions: {
    clearCompanyState (context) {
      context.commit('CLEAR_COMPANY_STATE')
    },
    setAdministrators (context, administrators) {
      context.commit('SET_ADMINISTRATORS', administrators)
    }
  },
  getters: {
    getSelectedCompany (state) {
      return state.selectedCompany
    },
    getAdministrators (state) {
      return state.administrators
    }
  }
}
