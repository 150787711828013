import { mapState } from 'vuex'
import AppsUseCases from '@/modules/Apps/application/AppsUseCases'
import AppsController from '@/modules/Apps/infrastructure/controllers/Apps.controller'
import Loader from '@/shared/infrastructure/ui/Loader.vue'
import KBExpansionPanel from '@/shared/infrastructure/ui/menus/KBExpansionPanel.vue'
import FunnelsFieldsConfigContainer from '../FunnelsFieldsConfigCard/FunnelsFieldsConfigCard.vue'
import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'
import { CDPFieldsEnums } from '@/modules/CDP/shared/contacts/domain/enums/CDPFieldsEnums'
export default {
  components: {
    Loader,
    KeybeIcon,
    KBExpansionPanel,
    FunnelsFieldsConfigContainer
  },
  computed: {
    ...mapState('AppStore', ['selectedApp']),
    ...mapState('UserStore', ['token']),
    iconColor () {
      return this.$vuetify.theme.dark ? 'white' : 'black'
    }
  },
  mounted () {
    this.getAppFields()
    this.getCDPFields()
  },
  data () {
    return {
      appFields: [],
      cdpFields: {},
      loading: false,
      appsController: new AppsController()
    }
  },
  methods: {
    panelIcon (key) {
      switch (key) {
        case 'primarydata':
          return KeybeIconType.ORGANIZATION
        case CDPFieldsEnums.PERSONALITY:
          return KeybeIconType.PERSONALITY_DETAILS
        case 'demographics':
          return KeybeIconType.DEMOGRAPHIC_DETAILS
        case 'transactional':
          return KeybeIconType.TRANSACTIONAL_DETAILS
        case 'invoicing':
          return KeybeIconType.TICKET
        case 'carCDP':
          return KeybeIconType.AUTO_DETAILS
        case 'motorcycleCDP':
          return KeybeIconType.MOTO_DETAILS
        case 'bicycleCDP':
          return KeybeIconType.BICYCLE_DETAILS
        case 'fashionCDP':
          return KeybeIconType.FASHION_DETAILS
        case 'beautyCDP':
          return KeybeIconType.BEAUTY_DETAILS
        case 'edtechCDP':
          return KeybeIconType.EDTECH_DETAILS
        case 'healthCDP':
          return KeybeIconType.HEALTH_DETAILS
        case 'carFunnels':
          return KeybeIconType.AUTO_DETAILS
        case 'motorcycleFunnels':
          return KeybeIconType.MOTO_DETAILS
        case 'bicycleFunnels':
          return KeybeIconType.BICYCLE_DETAILS
        case 'fashionFunnels':
          return KeybeIconType.FASHION_DETAILS
        case 'beautyFunnels':
          return KeybeIconType.BEAUTY_DETAILS
        case 'edtechFunnels':
          return KeybeIconType.EDTECH_DETAILS
        case 'healthFunnels':
          return KeybeIconType.HEALTH_DETAILS
        default:
          return KeybeIconType.LOGO
      }
    },
    async getAppFields () {
      try {
        const data = {
          app: this.selectedApp?.uuid,
          token: this.token
        }
        const response = await AppsUseCases.getAppFields(this.appsController, data)
        const appFields = response?.data

        delete appFields?.status

        Object.keys(appFields).forEach(key => {
          this.appFields.push(appFields[key])
        })
      } catch (e) {
        console.error(e)
      }
    },
    async getCDPFields () {
      try {
        this.loading = true
        const data = {
          app: this.selectedApp?.uuid,
          token: this.token
        }
        const response = await AppsUseCases.getCDPFields(this.appsController, data)
        const fields = response?.data
        delete fields?.status

        fields.dataPrimaryFunnel.forEach(data => {
          this.cdpFields[data.category] = data.fields
        })

        fields.dataExtendedFunnel.forEach(data => {
          this.cdpFields[`${data.category}Funnels`] = data.fields
        })

        setTimeout(() => {
          this.loading = false
        }, 1000)
      } catch (e) {
        console.error(e)
      }
    }
  }
}
