export const GENERAL_BALANCE_LIMIT = 0.0
export const WHITE_LIST_ALLOWED_PHONE = ['+13474583147', '+573136050421'] // Esto es por el momento acá quemado porque es una necesidad importante.

export const BillingStore = {
  namespaced: true,
  state: {
    appConfig: {},
    changeOption: null,
    changeOptionSave: null,
    tier: null
  },
  mutations: {
    SET_APP_CONFIG (state, appConfig) {
      state.appConfig = appConfig
    },
    SET_EDITING_OBJECT (state, payload) {
      state[payload.key] = payload.value
    },
    SET_TIER (state, tier) {
      state.tier = tier
    }
  },
  actions: {
    setAppConfig ({ commit }, appConfig) {
      const appConfigCp = {
        ...appConfig,
        hasCredit: true
      }
      commit('SET_APP_CONFIG', appConfigCp)
    },
    setTier ({ commit }, tier) {
      commit('SET_TIER', tier)
    }
  },
  getters: {
    getBalance (state: any) : number {
      if (!state.appConfig?.plan?.label) return GENERAL_BALANCE_LIMIT
      return state?.appConfig?.balanceUSD
    },
    getHasCredit (state: any): boolean {
      return state.appConfig?.hasCredit
    },
    getNamePlan (state: any) : string | null {
      return state.appConfig?.plan?.label
    },
    getAppUUID (state: any) : string | null {
      return state.appConfig?.appUUID || null
    },
    getTier (state: any) : string | null {
      return state.tier
    }
  }
}
