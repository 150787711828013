<template>
<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14"><path :fill="color" d="M.014 7c0-1.57-.02-3.14.007-4.71.02-1.154 1-2.177 2.154-2.242.977-.054 1.958-.034 2.938-.044.438-.004.876-.007 1.314 0 .43.008.664.348.499.717-.105.234-.318.27-.54.27-1.092.003-2.184 0-3.276.003-.257 0-.518-.017-.77.02-.878.13-1.358.714-1.36 1.634-.002 1.668 0 3.335 0 5.003v3.689c0 .383.072.746.306 1.06.31.416.745.605 1.247.606 2.976.008 5.951.009 8.927.001.934-.002 1.553-.668 1.557-1.646.004-1.216.001-2.432.002-3.648 0-.09 0-.18.01-.27a.46.46 0 0 1 .456-.43.475.475 0 0 1 .48.354c.018.06.03.123.03.185-.003 1.368.023 2.737-.02 4.105-.04 1.259-.955 2.177-2.21 2.308-.192.02-.387.033-.58.033-2.852.001-5.702.004-8.553 0-.838-.001-1.572-.257-2.105-.945-.383-.493-.53-1.059-.527-1.676C.005 9.918.002 8.46.002 7h.012Z"/><path :fill="color" d="M12.216.958c-.132-.005-.21-.01-.288-.01-.954-.008-1.907-.016-2.86-.02C8.855.926 8.666.873 8.57.69a.449.449 0 0 1 .032-.498c.128-.175.326-.19.525-.191C9.94-.001 10.755 0 11.57 0c.6 0 1.202-.002 1.803 0 .455.001.626.158.626.574v3.89c0 .357-.14.52-.456.556-.275.031-.488-.112-.535-.364a1.373 1.373 0 0 1-.02-.24l-.023-2.518c0-.071-.006-.143-.011-.264l-.212.194-4.714 4.317a1.45 1.45 0 0 1-.234.18c-.233.14-.44.116-.633-.06-.187-.17-.214-.373-.065-.58a1.42 1.42 0 0 1 .196-.216c2.809-2.575 1.886-1.73 4.696-4.303l.228-.209Z"/></svg>
</template>

<script>
export default {
  name: 'ExternalIcon',
  props: {
    color: {
      type: String,
      default: '#B6BEC7'
    }
  }
}
</script>

<style>

</style>
