import { Product, ProductVariation } from '../domain/models/Product'
import { IVariatonsController, dataToAddVariationToProduct, dataToGetVariations, dataToGetVariationsCreated, variationDTO } from '../domain/models/VariationsController'

export default class VariationUseCases {
  static getVariations (controller:IVariatonsController, data: dataToGetVariations): Promise<any[]> {
    return controller.getVariations(data)
  }

  static getVariationsCreated (controller:IVariatonsController, data: dataToGetVariationsCreated): Promise<any[]> {
    return controller.getVariationsCreated(data)
  }

  static addVariationToProduct (controller:IVariatonsController, data: dataToAddVariationToProduct): Promise<Product> {
    return controller.addVariationToProduct(data)
  }

  static addVariation (controller:IVariatonsController, data: variationDTO): Promise<ProductVariation> {
    return controller.addVariation(data)
  }

  static updateVariationInProduct (controller:IVariatonsController, data: dataToAddVariationToProduct): Promise<Product> {
    return controller.updateVariationInProduct(data)
  }
}
