import ApexCharts from 'apexcharts'
export default {
  name: 'ChartDetail',
  props: ['dataCompononts'],
  watch: {
    dataCompononts () {
      if (this.dataCompononts) {
        this.callServices()
      }
    }
  },
  mounted () {
    this.callServices()
  },
  data () {
    return {
      itemData: [],
      itemDataCSAT: 0,
      loading: false,
      chartConversation: null
    }
  },
  beforeDestroy () {
    if (this.chartConversation) {
      this.chartConversation.destroy()
    }
  },
  methods: {
    callServices () {
      if (this.dataCompononts) {
        if (this.chartConversation) {
          this.chartConversation.destroy()
        }
        this.setStateConversation()
      }
    },
    setStateConversation () {
      const { totalRead, totalRejected, totalSent, totalOpened, totalSpam, totalDelivered, totalClicked, totalCanceled } = this.dataCompononts

      const labels = [
        this.$t('sents'),
        this.$t('totalDeliveredN'),
        this.$t('readChart'),
        this.$t('rejected'),
        this.$t('totalOpened'),
        this.$t('totalClicked'),
        this.$t('totalSpam')
      ]
      const options = {
        series: [{
          data: [{
            x: this.$t('sents'),
            y: totalSent,
            fillColor: '#3AF69B'
          }, {
            x: this.$t('totalDeliveredN'),
            y: totalDelivered
          }, {
            x: this.$t('readChart'),
            y: totalRead,
            fillColor: '#FFB200'
          }, {
            x: this.$t('rejected'),
            y: totalRejected + totalCanceled,
            fillColor: '#F7685B'

          }, {
            x: this.$t('totalOpened'),
            y: totalOpened

          }, {
            x: this.$t('totalClicked'),
            y: totalClicked

          }, {
            x: this.$t('totalSpam'),
            y: totalSpam

          }]
        }],
        tooltip: {
          enabled: true,
          enabledOnSeries: undefined,
          shared: true,
          followCursor: false,
          intersect: false,
          inverseOrder: false,
          custom: undefined,
          fillSeriesColor: true,
          theme: false
        },
        chart: {
          height: 400,
          width: 280,
          type: 'bar',
          stacked: true,
          toolbar: {
            show: false
          },
          zoom: {
            enabled: false
          }
        },
        legend: {
          show: false
        },
        dataLabels: {
          enabled: false
        },
        labels: labels
      }
      this.chartConversation = new ApexCharts(document.querySelector('#OutBoundDetailGraph'), options)
      this.chartConversation.render()
    }

  }
}
