import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import Colors from '@/shared/domain/colors/Color'

// components
import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'
import KBCheck from '@/shared/infrastructure/ui/inputs/select/KBCheck.vue'
import KBSwitch from '@/shared/infrastructure/ui/inputs/select/KBSwitch.vue'
import ProductUseCases from '@/modules/catalog/application/ProductUseCases'
import ProductController from '../../controllers/Product.controller'
import { Product } from '@/modules/catalog/domain/models/Product'
import { mapState } from 'vuex'
import EmitToast from '@/utils/EmitToast'

export default {
  props: {
    product: {
      type: Object,
      required: true
    },
    hideCheck: {
      type: Boolean
    },
    hideDelete: {
      type: Boolean
    },
    deleting: {
      type: Boolean
    },
    isForm: {
      type: Boolean
    }
  },
  mounted () {
    // eslint-disable-next-line
    this.localProduct = structuredClone(this.product)
  },
  data () {
    return {
      KeybeIconType,
      Colors,
      selected: false,
      localProduct: {},
      productController: new ProductController()
    }
  },
  computed: {
    ...mapState('UserStore', ['token']),
    ...mapState('AppStore', ['selectedApp']),
    productImages () {
      return this.product?.images[0]?.url ? this.product?.images[0]?.url : this.product?.images[0]
    },
    productSku () {
      return this.product?.sku || ''
    },
    productId () {
      return this.product?.id || ''
    },
    productName () {
      return this.product?.product || ''
    },
    productDescription () {
      return this.product?.description || ''
    },
    productOption1 () {
      return this.product?.option1
    },
    productOption1Label () {
      return this.product?.option1?.label || ''
    },
    productOption1Items () {
      return this.product?.option1?.items || []
    },
    productOption2 () {
      return this.product?.option2
    },
    productOption2Label () {
      return this.product?.option2?.label || ''
    },
    productOption2Items () {
      return this.product?.option2?.items || []
    },
    productOption3 () {
      return this.product?.option3
    },
    productOption3Label () {
      return this.product?.option3?.label || ''
    },
    productOption3Items () {
      return this.product?.option3?.items || []
    },
    productCategory () {
      return this.product?.category.toString() || this.$t('withoutCategory')
    }
  },
  methods: {
    async toggleStock (e: boolean): Promise<void> {
      this.localProduct.hasStock = e
      if (this.localProduct.colors === '') this.localProduct.colors = []
      const data = {
        token: this.token,
        product: this.localProduct
      }
      const response: Product = await ProductUseCases.updateProduct(this.productController, data)
      if (response?.id) {
        this.$emit('getProducts')
      } else {
        EmitToast.emitErrors(this.$t('errorUpdatingProduct'))
      }
    }
  },
  components: {
    KBCheck,
    KeybeIcon,
    KBSwitch
  }
}
