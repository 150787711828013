import { render, staticRenderFns } from "./ExpandedScreen.vue?vue&type=template&id=af31b898&scoped=true&"
import script from "./ExpandedScreen.vue?vue&type=script&lang=ts&"
export * from "./ExpandedScreen.vue?vue&type=script&lang=ts&"
import style0 from "./ExpandedScreen.vue?vue&type=style&index=0&id=af31b898&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "af31b898",
  null
  
)

export default component.exports