import TemplateList from '@/modules/outbound/infrastructure/ui/components/forms/TemplateList/TemplateList.vue'
import Loader from '@/shared/infrastructure/ui/Loader.vue'
import EmailTemplateCreator from '@/modules/outbound/infrastructure/ui/components/forms/EmailTemplateCreator.vue'

export default {
  name: 'MyTemplate',
  components: {
    TemplateList,
    Loader,
    EmailTemplateCreator
  },
  mounted () {

  },
  watch: {
  },
  data () {
    return {
      campaignDetails: null,
      loading: false,
      count: 0,
      templateList: true,
      email: {
        from: '',
        html: '',
        subject: '',
        templateId: ''
      }
    }
  },
  created () {
  },
  computed: {
    actionDisabled () {
      return false
    }
  },
  methods: {
    returnForm () {
      this.templateList = true
    },
    openEditor (templateId) {
      if (templateId) {
        this.email.templateId = templateId
      } else {
        this.email.templateId = ''
      }
      this.templateList = false
    }
  }
}
