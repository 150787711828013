import { Product } from '../../domain/models/Product'
import {
  IProductController,
  dataToChangeProductStatus,
  dataToDeleteProduct,
  dataToGetProducts,
  productDTO,
  productGetter,
  SyncWithShopifyGetter, dataToSyncWithShopify, dataToGetProductVariations,
  Paginator,
  dataToGetProductsPaginator,
  dataToGenerateLongDescription,
  discountTypesGetter,
  dataToArchiveProducts,
  archiveGetter,
  dataToDeleteProducts,
  dataToExportCatalogCSV,
  dataToUploadProductPDFs
} from '../../domain/models/ProductsController'
import ProductRepository from '../repositories/Product.repository'
import { GenericResponse } from '@/shared/infrastructure/http/GenericResponse'

export default class ProductController implements IProductController {
    repository: ProductRepository

    constructor () {
      this.repository = new ProductRepository()
    }

    async getProducts (data: dataToGetProducts): Promise<productGetter> {
      const newData: dataToGetProductsPaginator = {
        token: data.token,
        appUUID: data.appUUID,
        limit: data.limit,
        product: data.product,
        category: data.category,
        getUncategorized: data.getUncategorized,
        getArchived: data.getArchived
      }
      if (data.get) {
        if (data.get === Paginator.NEXT) {
          newData.nextProduct = {
            sk: `appUUID:${data.appUUID}#id:${data.products[data.products.length - 1]?.id}`,
            gsi1sk: `appUUID:${data.appUUID}`,
            pk: 'Products#Products',
            gsi1pk: 'Products#Products'
          }
        } else if (data.get === Paginator.PREV) {
          newData.prevProduct = {
            gsi1pk: 'Products#Products',
            gsi1sk: `appUUID:${data.appUUID}`,
            pk: 'Products#Products',
            sk: `appUUID:${data.appUUID}#id:${data.products[0]?.id}`
          }
        }
      }
      const response = await this.repository.getProducts(newData)
      if (!response.status) {
        return { status: false, products: [] }
      }

      const obj: productGetter = { status: true, products: response?.data?.data || [] }
      if (response?.data?.next) obj.hasNext = true
      if (response?.data?.prev) obj.hasPrev = true
      return obj
    }

    async createProduct (data: productDTO): Promise<Product> {
      const response = await this.repository.createProduct(data)
      if (!response.status) {
        throw new Error('Error creating product')
      }
      return response?.data?.data
    }

    async updateProduct (data: productDTO): Promise<Product> {
      const response = await this.repository.updateProduct(data)
      if (!response.status) {
        return null
      }
      return response?.data?.data
    }

    async deleteProduct (data: dataToDeleteProduct): Promise<boolean> {
      const response = await this.repository.deleteProduct(data)
      return response.status
    }

    async syncWithShopify (data: dataToSyncWithShopify): Promise<SyncWithShopifyGetter> {
      const response = await this.repository.syncWithShopify(data)
      return {
        status: response.status || false,
        message: response.message || ''
      }
    }

    async getProductVariations (data: dataToGetProductVariations): Promise<Product> {
      const response: GenericResponse = await this.repository.getProductVariations(data)
      return response.data?.[0] || {}
    }

    changeProductStatus (data: dataToChangeProductStatus): Promise<Product> {
      throw new Error('Method not implemented.')
    }

    abortAll (): void {
      this.repository.abortAll()
    }

    async generateLongDescription (data: dataToGenerateLongDescription): Promise<SyncWithShopifyGetter> {
      const response = await this.repository.generateLongDescription(data)
      return {
        status: response.status || false,
        message: response.message || ''
      }
    }

    async getDiscountTypes (token: string): Promise<discountTypesGetter> {
      const response = await this.repository.getDiscountTypes(token)
      if (!response.status) {
        return { status: false, message: response.message }
      } else {
        return { status: true, data: response?.data?.result }
      }
    }

    async archiveProducts (data: dataToArchiveProducts): Promise<archiveGetter> {
      const response = await this.repository.archiveProducts(data)
      const success = response?.message?.includes('exitosamente')
      if (!success) {
        return { status: false, message: response.message }
      } else {
        return { status: true }
      }
    }

    async deleteProducts (data: dataToDeleteProducts): Promise<archiveGetter> {
      const response = await this.repository.deleteProducts(data)
      const success = response?.message?.includes('eliminado')
      if (!success) {
        return { status: false, message: response.message }
      } else {
        return { status: true }
      }
    }

    async exportCatalogCSV (data: dataToExportCatalogCSV): Promise<archiveGetter> {
      const response = await this.repository.exportCatalogCSV(data)
      const success = response?.message?.includes('iniciado')
      if (!success) {
        return { status: false, message: response.message }
      } else {
        return { status: true }
      }
    }

    async uploadProductPDFs (data: dataToUploadProductPDFs): Promise<any> {
      const response = await this.repository.uploadProductPDFs(data)
      return response
    }
}
