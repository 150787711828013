

































import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import { mapState } from 'vuex'
import Colors from '@/shared/domain/colors/Color'
import AdvisersRepository from '@/modules/conversations/infrastructure/http/Advisers.repository'
import KBText from '@/shared/infrastructure/ui/inputs/text/KBText.vue'
import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'
import TagConfigCard from '@/modules/configuration/infrastructure/ui/components/Autoassign/Tags/TagConfigCard.vue'

export default {
  name: 'AutoassignTagsConfig.vue',
  components: {
    KBText,
    KeybeIcon,
    TagConfigCard
  },
  props: {
    value: {
      required: true
    }
  },
  data () {
    return {
      KeybeIconType,
      Colors,
      newTag: '',
      clearInput: false
    }
  },
  computed: {
    ...mapState('AppStore', ['advisers'])
  },
  methods: {
    getAdvisersByTag (tag: string) {
      return this.advisers.filter(adviser => adviser?.tags?.includes(tag))
    },
    addNewTag () {
      if (!this.newTag) return
      this.$emit('input', {
        ...this.value,
        tags: [...this.value.tags, this.newTag]
      })
      this.newTag = ''
      this.clearInput = true
      this.$nextTick(() => {
        this.clearInput = false
      })
    },
    async removeTag (tag: string) {
      this.$emit('input', {
        ...this.value,
        tags: this.value.tags.filter(t => t !== tag)
      })
      const advisers = this.getAdvisersByTag(tag)
      for (const adviser of advisers) {
        const newAdviser = {
          ...adviser,
          tags: adviser.tags.filter(t => t !== tag)
        }
        const response = await AdvisersRepository.updateAdviser(newAdviser)
        if (response.status) {
          this.$store.dispatch('AppStore/updateAdviser', newAdviser)
        }
      }
    }
  }
}
