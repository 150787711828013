































import BikyConfigPersonality from './BikyConfigPersonality'

export default BikyConfigPersonality
