<template>
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 13 10"><path :fill="color" d="M6.368 10c-1.478-.029-2.75-.563-3.869-1.521C1.483 7.607.721 6.549.104 5.37c-.14-.267-.137-.496.001-.763.667-1.285 1.515-2.423 2.66-3.325C3.744.514 4.84.067 6.089.007a5.61 5.61 0 0 1 3.555 1.016c1.413.978 2.397 2.3 3.124 3.836a.284.284 0 0 1-.001.266c-.752 1.595-1.787 2.954-3.273 3.936a5.56 5.56 0 0 1-2.754.91c-.124.008-.248.02-.372.028V10ZM9.6 4.895C9.498 2.847 7.788 1.57 5.97 1.802c-1.767.227-2.947 1.736-2.786 3.5.162 1.776 1.714 3.109 3.601 2.877C8.522 7.966 9.621 6.536 9.6 4.896Z"/><path :fill="color" d="m4.302 4.578 2.088.421-.418-2.087c.682-.198 1.697.13 2.19.888a2.175 2.175 0 0 1-.27 2.714c-.711.714-1.837.816-2.713.247-.779-.507-1.066-1.46-.877-2.183Z"/></svg>
</template>

<script>
export default {
  name: 'EyeClockIcon',
  props: {
    color: {
      type: String,
      default: '#B6BEC7'
    }
  }

}
</script>

<style>

</style>
