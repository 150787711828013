import { render, staticRenderFns } from "./MusicNoteIcon.vue?vue&type=template&id=13d2a848&scoped=true&"
import script from "./MusicNoteIcon.vue?vue&type=script&lang=js&"
export * from "./MusicNoteIcon.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13d2a848",
  null
  
)

export default component.exports