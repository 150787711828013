<template>
  <svg width="40"
       height="49"
       viewBox="0 0 40 49"
       fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <path d="M38.1401 17.8912C36.1662 19.2662 34.8252 20.646 34.1155 22.0324C33.4059 23.4188 33.0502 24.9213 33.0502 26.5366C33.0502 27.8151 33.2607 28.984 33.6816 30.0451C34.1025 31.1061 34.6979 32.1132 35.4679 33.0664C36.2379 34.0195 37.5251 34.9661 39.331 35.9062C38.6328 37.9564 37.5496 40.0474 36.083 42.1826C34.6147 44.3161 33.2852 45.83 32.0943 46.7227C30.9033 47.6154 29.7663 48.0617 28.683 48.0617C27.8168 48.0617 26.6487 47.7854 25.1821 47.2295L24.4969 46.9761L23.3778 46.5053C22.5833 46.2649 21.7056 46.1439 20.7431 46.1439C19.7806 46.1439 18.6501 46.3614 17.4216 46.7946L16.5554 47.12L15.4721 47.5549C14.6059 47.8933 13.7396 48.0617 12.8734 48.0617C11.0446 48.0617 9.08367 46.7783 6.98898 44.2099C4.89592 41.6414 3.31349 38.8801 2.24167 35.9258C1.16986 32.9716 0.634766 29.9879 0.634766 26.9731C0.634766 23.0902 1.73595 19.8596 3.93831 17.2781C6.14067 14.6983 9.12935 13.4083 12.9076 13.4083C14.2796 13.4083 15.5668 13.6617 16.7707 14.1686L17.6011 14.4939L18.4674 14.8552C19.2374 15.1691 19.8508 15.3261 20.3075 15.3261C20.9813 15.3261 21.6551 15.1936 22.3288 14.9288L23.4838 14.4939L24.3142 14.1686C25.6617 13.6617 27.1528 13.4083 28.7907 13.4083C32.6652 13.4083 35.7812 14.9043 38.1401 17.8945V17.8912ZM28.9718 0.652832C29.0191 1.18417 29.0436 1.5929 29.0436 1.88228C29.0436 3.37821 28.6096 4.92156 27.7434 6.51232C26.8771 8.10307 25.7449 9.34069 24.3501 10.2203C22.9536 11.1015 21.4136 11.5413 19.73 11.5413C19.6583 10.9625 19.6224 10.5407 19.6224 10.2759C19.6224 8.99737 20.0008 7.65348 20.7594 6.24256C21.518 4.83164 22.5458 3.63162 23.846 2.64251C25.1462 1.65339 26.6128 1.02722 28.2491 0.760736C28.3943 0.737847 28.6341 0.700244 28.9718 0.652832Z"
          :fill="color"/>
  </svg>
</template>

<script>
export default {
  name: 'AppleStoreIcon',
  props: {
    width: {
      type: Number,
      default: 16
    },
    height: {
      type: Number,
      default: 18
    },
    color: {
      type: String,
      default: '#B6BEC7'
    }
  }

}
</script>

<style>

</style>
