import KeybeRepository from '@/shared/infrastructure/repositories/KeybeRepository'
import { HeadersBuilder } from '@/shared/infrastructure/http/HeadersBuilder'
import { GenericResponse } from '@/shared/infrastructure/http/GenericResponse'
import { dataToCreditCardsMethod, dataToDeletePaymentMethod } from '../../domain/PlanController'

export default class PaymentRepository extends KeybeRepository {
      // abort controllers
      getBookingAbortController: AbortController
      baseUrl: string

      constructor () {
        super()

        // instance abort controllers
        this.getBookingAbortController = new AbortController()

        this.baseUrl = process.env.VUE_APP_PAYMENT_URL
      }

      async removePaymentMethods (data: dataToDeletePaymentMethod): Promise<GenericResponse> {
        const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
        const url = `${this.baseUrl}/payment/methods/delete`

        const request = {
          url,
          data,
          headers,
          abortController: this.getBookingAbortController
        }

        return await this.client.post(request)
      }

      async getActiveBikyPlan (data: dataToCreditCardsMethod): Promise<GenericResponse> {
        const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
        const { appUUID } = data
        const url = `${process.env.VUE_APP_BILLING_URL}package/plan/${appUUID}/biky`

        const request = {
          url,
          headers,
          abortController: this.getBookingAbortController
        }

        return await this.client.get(request)
      }
}
