
import ModalSVG from '@/shared/assets/ModalSVG.vue'
import { CampaignType } from '@/modules/outbound/infrastructure/enums/CampaignTypes'
import SegmentsApolloProvider from '@/modules/people/Providers/SegmentsApolloProvider'
import { mapActions, mapGetters, mapState } from 'vuex'
import { EventBus } from '@/eventBus'
import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import { SegmentedMessage } from 'sms-segments-calculator'
import regex from '@/utils/validateRegex'
import KBTextarea from '@/shared/infrastructure/ui/inputs/text/KBTextarea.vue'
import KeybeEmojiPicker from '@/shared/infrastructure/ui/KeybeEmojiPicker.vue'
import { campaignsSms } from '@/modules/outbound/infrastructure/ui/components/methods/campaignSms'
import SegmentsController from '@/modules/CDP/shared/segments/infrastructure/controllers/Segments.controller'
import { EntityTypeEnum } from '@/modules/CDP/shared/contacts/domain/Contacts'
import SegmentsUseCases from '@/modules/CDP/shared/segments/application/SegmentsUseCases'
import operations from '@/utils/operations'

import Loader from '@/shared/infrastructure/ui/Loader.vue'
import TemplatePreview from '@/modules/outbound/infrastructure/ui/components/TemplatePreview/TemplatePreview.vue'
import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'
import TemplateSelector from '@/modules/outbound/infrastructure/ui/components/forms/TemplateSelector/TemplateSelector.vue'
import ParamsSelector from '@/modules/templates/infrastructure/ui/ParamsSelector/ParamsSelector.vue'
import { CDPFieldsEnums } from '@/modules/CDP/shared/contacts/domain/enums/CDPFieldsEnums'
import { SalesFieldsEnum } from '@/modules/Sales/shared/domain/SalesFieldsEnum'
import KeybeText from '@/modules/DesignSystem/infrastructure/components/KeybeText/KeybeText.vue'
import KeybeAutocomplete from '@/modules/DesignSystem/infrastructure/components/KeybeAutocomplete/KeybeAutocomplete/KeybeAutocomplete.vue'
import KeybeButton from '@/modules/DesignSystem/infrastructure/components/KeybeButton/KeybeButton.vue'
import KeybeSelect from '@/modules/DesignSystem/infrastructure/components/KeybeSelect/KeybeSelect/KeybeSelect.vue'
import LoaderSVG from '@/shared/infrastructure/ui/icons/SvgComponents/loaderSVG.vue'
import KBSwitch from '@/shared/infrastructure/ui/inputs/select/KBSwitch.vue'

export default {
  name: 'NewSmsPage',
  props: {
    disabledAction: {
      type: Boolean,
      default: false
    },
    setSegment: {
      type: String
    },
    selectedSegment: {
      type: [String, Object]
    }
  },
  components: {
    KBSwitch,
    LoaderSVG,
    ParamsSelector,
    KeybeEmojiPicker,
    KBTextarea,
    ModalSVG,
    KeybeIcon,
    TemplatePreview,
    KeybeText,
    KeybeSelect,
    KeybeAutocomplete,
    TemplateSelector,
    KeybeButton,
    Loader
  },
  mounted () {
    try {
      this.loadingLine = true
      this.getSegments(true)
      this.loadingLine = false
    } catch (error) {
      this.loadingLine = false
    }
  },
  watch: {
    changeParamsVisible () {
      if (this.changeParamsVisible) {
        this.openEmojiPicker = false
      }
    },
    openEmojiPicker () {
      if (this.openEmojiPicker) {
        this.changeParamsVisible = false
      }
    }
  },
  data () {
    return {
      creatingCampaign: false,
      items: [],
      segments: [],
      offset: 1,
      limit: 10,
      segmentsController: new SegmentsController(),
      loadingLine: false,
      campaingsName: null,
      segment: null,
      segmentValue: null,
      peopleListSearch: '',
      KeybeIconType,
      userSegments: [],
      openEmojiPicker: false,
      loading: false,
      messageText: null,
      rerender: 0,
      CampaignType,
      segmentSearch: '',
      changeParamsVisible: false,
      isBidirectionalSMS: false,
      userHost: '',
      message: '',
      SegmentsApolloProvider: SegmentsApolloProvider.getInstance().provider.defaultClient,
      CDPFieldsEnums,
      SalesFieldsEnum,
      loadingSegment: false
    }
  },
  created () {
    this.outboundName = null
    this.summaryMenu = false
  },
  computed: {
    ...mapState('UserStore', ['token']),
    ...mapState('ConversationStore', ['wpProviders', 'channels']),
    ...mapState('CampaingsStore', ['templates']),
    ...mapState('AppStore', ['selectedApp']),
    ...mapGetters('ChatConfigStore', ['hasInboxConfig']),
    canShowSMS () {
      return this.hasInboxConfig && this.channels?.sms?.hasSmsProvider
    },
    userHosts () {
      return (
          this.channels?.sms?.accounts.map(
            (account: { [key: string]: string | boolean }) => ({
              ...account,
              _id: '+' + account._id?.toString().split('+').join('').trim() || ''
            })
          ) || []
      )
    },
    currentSelectedTab: {
      get () {
        return this.$store.state.CampaingsStore.currentSelectedTab || null
      },
      set (value) {
        this.$store.commit('CampaingsStore/SET_EDITING_OBJECT', {
          key: 'currentSelectedTab',
          value
        })
      }
    },
    outboundName: {
      get () {
        return this.$store.state.CampaingsStore.outboundName || null
      },
      set (value) {
        this.$store.commit('CampaingsStore/SET_EDITING_OBJECT', {
          key: 'outboundName',
          value
        })
      }
    },
    summaryData: {
      get () {
        return this.$store.state.CampaingsStore.summaryData || false
      },
      set (value) {
        this.$store.commit('CampaingsStore/SET_EDITING_OBJECT', {
          key: 'summaryData',
          value
        })
      }
    },
    summaryMenu: {
      get () {
        return this.$store.state.CampaingsStore.summaryMenu || false
      },
      set (value) {
        this.$store.commit('CampaingsStore/SET_EDITING_OBJECT', {
          key: 'summaryMenu',
          value
        })
      }
    },
    smsCounter () {
      const segmentedMessage = new SegmentedMessage(this.message)
      return {
        current: this.message?.length,
        count: segmentedMessage?.segmentsCount
      }
    },
    allowAccentMarksAndEmojis () {
      const text = this.message
      return regex.hasEmoji(text) || regex.hasAccent(text)
    },
    hasCustomData () {
      const text = this.message
      return regex.isBetweenBrackets(text)
    }
  },
  methods: {
    campaignsSms,
    ...mapActions('CampaingsStore', ['setTemplates']),
    searchPersons (search) {
      this.loadingSegment = true
      const obj = this.items.find(o => o.name.toLowerCase().includes(search.toLowerCase()))
      if (obj) {
        this.loadingSegment = false
        return
      }
      this.segmentSearch = search
      try {
        this.getSegments()
      } catch (error) {
        this.loadingSegment = false
      }
    },
    onParams (customizedField) {
      if (this.message) {
        this.message = `${this.message} ${customizedField}`
      } else {
        this.message = customizedField
      }
      this.changeParamsVisible = false
    },
    formatName (data) {
      this.items = data.map(segment => {
        if (!segment?.name) return
        return {
          name: operations.checkTranslation(segment.name),
          _id: segment._id

        }
      })
    },
    async getSegments (mounted) {
      try {
        const data = {
          offset: this.offset,
          limit: this.limit,
          search: this.segmentSearch,
          typeEntity: EntityTypeEnum.PEOPLE,
          token: this.token,
          app: this.selectedApp?.uuid
        }
        const response = await SegmentsUseCases.getSegments(this.segmentsController, data)
        if (response?.status) {
          if (mounted) this.segments = []
          if (this.offset > 1) this.segments = this.segments.concat(response?.data?.data)
          else this.segments = response?.data?.data.reverse()
          this.formatName(this.segments)
        }
        this.setSegmentIn()
        this.loadingSegment = false
      } catch (e) {
        console.error(e)
        this.loading = false
      }
    },
    findElement (array, id) {
      const result = array.find(elemento => elemento.id === id)
      if (!result) return
      return {
        id: result._id,
        name: result.name
      }
    },
    async getSegmentsById (id) {
      try {
        const data = {
          typeEntity: EntityTypeEnum.PEOPLE,
          token: this.token,
          app: this.selectedApp?.uuid,
          id: id
        }
        const response = await SegmentsUseCases.getSegmentsById(this.segmentsController, data)
        if (response?.status) {
          this.segments.push(response?.data)
          this.formatName(this.segments)
          this.segment = response?.data?._id
        }
      } catch (e) {
        console.error(e)
        this.loading = false
      }
    },
    setSegmentIn () {
      const exists = this.findElement(this.segments, this.setSegment)
      if (exists) {
        this.segment = exists.id
      } else {
        this.getSegmentsById(this.setSegment)
      }
      this.loading = false
    },
    onSelectEmoji (emoji) {
      if (this.message) {
        this.message = `${this.message} ${emoji.data}`
      } else {
        this.message = emoji.data
      }
      this.openEmojiPicker = false
    },
    async callCreate () {
      if (this.creatingCampaign === true) {
        return
      }
      this.creatingCampaign = true
      try {
        const dataCreate = {
          message: this.message,
          allowAccentMarksAndEmojis: this.allowAccentMarksAndEmojis,
          segment: this.segment,
          isBidirectionalSMS: this.isBidirectionalSMS,
          userHost: this.userHost,
          campaignName: this.campaingsName
        }
        await this.campaignsSms(dataCreate)
          .then((response) => {
            if (response?.status === 400) {
              if (response?.errorDetails) {
                const { errorDetails } = response
                this.creatingCampaign = false
                return EventBus.$emit('toast', 'error', errorDetails[0]?.msg)
              }
              if (response?.errorMessage) {
                const { errorMessage } = response
                EventBus.$emit('toast', 'error', errorMessage)
              }
              this.creatingCampaign = false
              return
            }
            if (response?.campaign?._id) {
              this.campaignData = response?.campaign
              EventBus.$emit('toast', 'success', this.$t('resumeSubtitle'))
              this.goToDetail(this.campaignData)
            } else {
              EventBus.$emit('toast', 'error', this.$t('createCampaignError'))
            }
          }
          )
      } catch (error) {
        this.creatingCampaign = false
        EventBus.$emit('toast', 'error', this.$t('createCampaignError'))
      } finally {
        this.creatingCampaign = false
      }
    },
    goToDetail (item) {
      this.currentSelectedTab = 'summary'
      this.summaryMenu = true
      this.summaryData = item
      this.outboundName = item.name
    }
  }
}
