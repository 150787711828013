import { debounce } from 'lodash'
import { mapState } from 'vuex'
import KeybeButton from '@/modules/DesignSystem/infrastructure/components/KeybeButton/KeybeButton.vue'
import KBSearch from '@/shared/infrastructure/ui/inputs/text/KBSearch.vue'
import Loader from '@/shared/infrastructure/ui/Loader.vue'
import StatusCard from '../StatusCard/StatusCard.vue'
import CreateEditStatus from '../CreateEditStatus/CreateEditStatus.vue'
import StatusUseCases from '@/modules/Statuses/application/StatusUseCases'
import StatusController from '../../controllers/Status.controller'
import EmitToast from '@/utils/EmitToast'
import Colors from '@/utils/Colors'
export default {
  components: {
    Loader,
    KBSearch,
    KeybeButton,
    StatusCard,
    CreateEditStatus
  },
  computed: {
    ...mapState('AppStore', ['selectedApp']),
    ...mapState('UserStore', ['token', 'isUserReadonly']),
    buttonColor (): string {
      if (!this.create) return Colors.kbGreen
      return this.$vuetify.theme.dark ? Colors.inputDark : Colors.inputLight
    },
    buttonTextColor (): string {
      if (!this.create) return Colors.black
      return this.$vuetify.theme.dark ? Colors.white : Colors.black
    }
  },
  mounted () {
    this.getStatuses()
  },
  data () {
    return {
      search: '',
      statuses: [],
      create: false,
      loading: false,
      statusController: new StatusController()
    }
  },
  methods: {
    close () {
      this.create = false
      this.getStatuses()
    },
    updateInput: debounce(function (e) {
      this.search = e
      this.getStatuses()
    }, 300),
    async getStatuses () {
      try {
        this.loading = true
        const data = {
          app: this.selectedApp?.uuid,
          token: this.token,
          search: this.search,
          pagination: {
            offset: 0,
            limit: 200
          }
        }
        const response = await StatusUseCases.getStatuses(this.statusController, data)
        if (Array.isArray(response)) this.statuses = response
        else EmitToast.emitErrors(response)
        this.loading = false
      } catch (e) {
        console.error(e)
        this.loading = false
      }
    }
  },
  watch: {
    search (val) {
      this.getStatuses()
    }
  }
}
