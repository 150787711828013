import AdvisersService from '@/components/conversations/Services/AdvisersService'
import { EventBus } from '@/eventBus'
import { AdviserStatusEnum } from '../../domain/models/Advisers'

export default class AdviserStatusController {
  static async validate ({
    user,
    status,
    translates,
    advisers,
    isCoordinatorOrAbove,
    isTrainer,
    isSuperAdmin
  }) {
    if (isSuperAdmin) return true

    const adviserExist = advisers.find((a: any) => a.userId === user?.userId)
    if (!adviserExist) {
      EventBus.$emit(
        'toast',
        'error',
        translates?.youAreNotAnAdvisor || 'Not an adviser'
      )
      return false
    }

    if (isCoordinatorOrAbove || isTrainer || status === AdviserStatusEnum.ONLINE) {
      return true
    }

    return true
  }

  static async inactiveIfIsAdviser (isAgent: boolean) {
    if (isAgent) await AdvisersService.updateAdviserStatus(false)
  }
}
