import BillingValidations from '@/components/configuration/Billing/BillingValidations'
import BillingService from '@/components/configuration/Services/BillingService'
import { sendCampaign } from './sendCampaign'

interface CampaingsObj {
  totalUsers: number;
  name: string;
  type: string;
  status: string;
  createdAt: string;
  _id: string;
}

export const billCampaings = async (campaignData: CampaingsObj, scheduleDate, schedule) => {
  if (!BillingValidations.hasBalance()) return
  try {
    const appConfig = await BillingService.getAppConfig()
    if (!appConfig) { throw new Error('The App is not created in Billing') }
    const chargeTypeId = {
      SMS: 'message_sms_col',
      MASSIVE: 'message_sms_col',
      EMAIL: 'message_email',
      WHATSAPP: 'message_proactive'
    }
    let listQuantity = 0
    campaignData.totalUsers = 1
    if (campaignData.totalUsers !== 0) { listQuantity = campaignData.totalUsers } else {
      if (campaignData.name !== '' && campaignData.name !== null && campaignData.name !== undefined) {
        listQuantity = 1
      }
    }
    if (listQuantity === 0) throw new Error('Select at least 1 user to send the list')
    const dataCheckBalance = {
      chargeTypeName: chargeTypeId[campaignData.type.toUpperCase()],
      appConfig: appConfig.id,
      transactionTypeName: 'EXPENSE',
      quantity: listQuantity
    }
    const response = await BillingService.createInvoice('transactions/checkTransaction', dataCheckBalance)
    if (response.success) {
      if (campaignData.totalUsers > 0) {
        const campaignSent = await sendCampaign(campaignData, scheduleDate, schedule)
        if (campaignSent) {
          // And finally create the transaction associated to that if corresponds
          const billing = response
          const campaign = campaignSent
          return { billing, campaign }
        }
      }
    }
  } catch (error) {
    console.error('The App is not created in Billing')
    return error
  }
}
