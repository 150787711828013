import { GenericResponse } from '@/models/apis/GenericResponse'
import {
  dataToCountNotifications,
  dataToCreateNotification,
  dataToGetAllNotifications,
  dataToGetNotifications,
  dataToMarkNotificationAsViewed,
  INotificationController,
  notificationCounter,
  notificationGetter,
  notificationViewed
} from '../../domain/NotificationController'
import NotificationRepository from '../repository/Notification.repository'
import { Paginator, PaginatorUtil } from '@/shared/domain/Paginator'
import { Notification } from '../../domain/Notification'

export default class NotificationController implements INotificationController {
  repository: NotificationRepository

  constructor () {
    this.repository = new NotificationRepository()
  }

  abort (): void {
    this.repository.abortController()
  }

  canAskMoreNotifications (
    data: Notification[],
    paginator: Paginator,
    offset: number
  ): boolean {
    return data.length < paginator.totalDocs && offset < paginator.totalPages
  }

  async getNotifications (
    data: dataToGetNotifications
  ): Promise<notificationGetter> {
    const response: GenericResponse = await this.repository.getNotifications(
      data
    )

    if (!response.status) {
      return {
        data: [],
        paginator: PaginatorUtil.emptyPaginator
      }
    }

    return {
      data: response.data.data || [],
      paginator: response.data.paginator || PaginatorUtil.emptyPaginator
    }
  }

  async getAllNotifications (
    data: dataToGetAllNotifications
  ): Promise<notificationGetter> {
    const response: GenericResponse = await this.repository.getAllNotifications(
      data
    )

    if (!response.status) {
      return {
        data: [],
        paginator: PaginatorUtil.emptyPaginator
      }
    }

    return {
      data: response.data.data || [],
      paginator: response.data.paginator || PaginatorUtil.emptyPaginator
    }
  }

  async createNotification (data: dataToCreateNotification): Promise<any> {
    const response = await this.repository.createNotification(data)
    console.log(response)
    return {}
  }

  async markNotificationAsViewed (
    data: dataToMarkNotificationAsViewed
  ): Promise<notificationViewed> {
    const response: GenericResponse = await this.repository.markNotificationAsViewed(data)
    return {
      success: response.data.success || false,
      message: response.data.message || ''
    }
  }

  async countNotifications (data: dataToCountNotifications): Promise<notificationCounter> {
    const response: GenericResponse = await this.repository.countNotifications(data)
    return {
      pendingInUser: response.data.pendingInUser || 0,
      pendingInApp: response.data.pendingInApp || 0,
      pendingSystem: response.data.pendingSystem || 0,
      totalPending: response.data.totalPending || 0
    }
  }
}
