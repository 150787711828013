<template>
  <svg :width="width"
       :height="height"
       viewBox="0 0 9 9"
       fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <path d="M5.36386e-06 4.49859C-0.00281271 2.04593 1.97031 -0.00562456 4.50469 1.15854e-05C7.0527 0.00564773 9.00656 2.04405 9.0014 4.50658C8.99623 6.99118 7.01982 8.99671 4.50235 9C1.96702 9.00328 -0.00375207 6.95032 5.36386e-06 4.49859ZM0.551878 4.49578C0.573013 6.65301 2.24225 8.43591 4.49295 8.44108C6.70138 8.44671 8.44436 6.7183 8.44295 4.49766C8.44154 2.28124 6.70937 0.565974 4.51268 0.55752C2.31223 0.549536 0.582407 2.27842 0.551878 4.49578Z"
          fill="#171717"/>
    <path d="M4.21017 2.51964C4.21017 2.40739 4.20876 2.2787 4.21017 2.15001C4.21299 1.96214 4.32336 1.83297 4.48212 1.82828C4.65496 1.82311 4.77238 1.95039 4.77801 2.15095C4.77989 2.22938 4.78412 2.30782 4.77707 2.38579C4.76862 2.4816 4.79915 2.51636 4.9006 2.53561C5.13309 2.58023 5.36511 2.63706 5.58962 2.71174C5.72864 2.75777 5.78219 2.89257 5.74884 3.01234C5.70986 3.15183 5.60089 3.22886 5.44449 3.20678C5.32942 3.19035 5.21763 3.14854 5.1035 3.12412C4.75077 3.04944 4.39663 3.02361 4.05377 3.16263C3.94715 3.20584 3.8457 3.27301 3.75881 3.3491C3.60522 3.48389 3.62166 3.77416 3.80578 3.86668C4.06222 3.99537 4.33135 4.11467 4.60846 4.18372C4.95461 4.27014 5.27728 4.40024 5.58022 4.57965C5.81506 4.71868 5.96489 4.93379 6.01797 5.2048C6.1166 5.70923 5.83573 6.18408 5.33223 6.35316C5.15939 6.41093 4.97716 6.44146 4.77848 6.48984C4.77848 6.60397 4.7813 6.73125 4.77801 6.85854C4.77332 7.0342 4.71132 7.12062 4.57088 7.15866C4.40838 7.20281 4.25667 7.10888 4.23788 6.94214C4.2252 6.82989 4.23037 6.71575 4.22849 6.60209C4.22755 6.54808 4.22849 6.49407 4.22849 6.44992C3.9185 6.35974 3.62166 6.2766 3.32718 6.18596C3.11676 6.12114 3.03738 5.90274 3.15856 5.74305C3.2215 5.66039 3.30745 5.62281 3.40608 5.64724C3.5916 5.69326 3.77666 5.74258 3.95795 5.80364C4.27968 5.91166 4.60752 5.95628 4.93958 5.88489C5.07297 5.85624 5.21059 5.798 5.31955 5.71722C5.56379 5.53639 5.5356 5.17709 5.2693 5.03055C5.10585 4.94084 4.93066 4.86898 4.75406 4.80792C4.47413 4.71117 4.18246 4.64541 3.90723 4.53785C3.72077 4.46505 3.52679 4.37112 3.38307 4.23726C2.91715 3.80375 3.05335 3.04709 3.63904 2.75354C3.81611 2.66477 4.00586 2.60231 4.21111 2.51917L4.21017 2.51964Z"
          fill="#171717"/>
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: Number,
      default: 12
    },
    height: {
      type: Number,
      default: 12
    },
    color: {
      type: String,
      default: '#B6BEC7'
    }
  },
  name: 'SaleIcon'
}
</script>

<style>

</style>
