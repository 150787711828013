import { CDPFieldsEnums } from '@/modules/CDP/shared/contacts/domain/enums/CDPFieldsEnums'
import { BikyConfigTabs } from '@/modules/bky/domain/BikyConfigTabs'
import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'

export default {
  name: 'BikyConfigHeader',
  props: {
    tab: {
      type: String,
      default: ''
    }
  },
  components: {
    KeybeIcon
  },
  data () {
    return {
      KeybeIconType
    }
  },
  computed: {
    title (): string {
      const titles: {
        [key: string]: string
      } = {
        [BikyConfigTabs.SKILLS]: this.$t(CDPFieldsEnums.SKILLS),
        [BikyConfigTabs.PERSONALITY]: this.$t(CDPFieldsEnums.PERSONALITY)
      }

      return titles[this.tab] || this.$t('letsTrainBiky')
    },
    icon (): string {
      const icons: {
        [key: string]: string
      } = {
        [BikyConfigTabs.SKILLS]: KeybeIconType.PENCIL_RULE,
        [BikyConfigTabs.PERSONALITY]: KeybeIconType.MASKS
      }

      return icons[this.tab] || KeybeIconType.DUMBBELL
    }
  }
}
