import { App, AppConfig, Rol } from '@/components/models/App'
import { Business } from '@/components/models/Business'
import { Statistics } from '@/components/models/Statistics'
import AppUUIDsService from '@/services/AppUUIDsService'

export const AppStore = {
  namespaced: true,
  state: {
    showInternalConversationsTutorial: true,
    apps: [],
    selectedApp: null,
    advisers: [],
    statistics: {},
    appConfig: {
      balanceUSD: -2
    },
    appProvider: '',
    appProviderPhone: '',
    businesses: [],
    roles: [],
    customExtradata: [],
    journeys: [],
    language: '',
    showTermsDialog: false,
    showTutorial: false,
    tutorialStep: 1,
    tutorialPhone: '',
    successTutorial: false,
    showPopup: false,
    importTutorialSuccess: false,
    campaignTutorialSuccess: false,
    fromRoute: '',
    hasPendingTasks: false,
    editingAdmin: false,
    adminToEditOrCreate: null,
    showSeptemberPopup: true,
    showWebinarPopup: false,
    appModules: [],
    formDialogCompleted: false,
    formDialogSkipped: 0,
    kbFlowsRequestedInfo: false,
    failedChunkComponent: '',
    redirectRoute: '',
    showModalFScreen: false,
    showModalData: {},
    endReadon: [],
    forcedLanguage: false,
    isAndroid: false
  },
  mutations: {
    SET_FORCED_LANGUAGE (state, forcedLanguage: boolean) {
      state.forcedLanguage = forcedLanguage
    },
    SET_APPS (state, apps: App[]) {
      state.apps = apps
    },
    SET_SELECTED_APP (state, app: App) {
      state.selectedApp = app
    },
    SET_ADVISERS (state, advisers) {
      state.advisers = advisers
    },
    UPDATE_ADVISER (state, adviser) {
      const index = state.advisers.findIndex((a) => a._id === adviser._id)
      if (index >= 0) {
        const updatedAdviser = { ...state.advisers[index], ...adviser }
        state.advisers.splice(index, 1, updatedAdviser)
      }
    },
    SET_APP_STATISTICS (state, statistics: Statistics) {
      state.statistics = statistics
    },
    SET_APP_CONFIG (state, appConfig: AppConfig) {
      state.appConfig = appConfig
    },
    SET_APP_PROVIDER (state, appProvider: string) {
      state.appProvider = appProvider
    },
    SET_APP_PROVIDER_PHONE (state, appProviderPhone: string) {
      state.appProviderPhone = appProviderPhone
    },
    SET_APP_BUSINESSES (state, businesses: Business[]) {
      state.businesses = businesses
    },
    SET_APP_ROLES (state, roles: Rol[]) {
      state.roles = roles
    },
    SET_JOURNEYS (state, journeys: any[]) {
      state.journeys = journeys
    },
    CLEAR_APP_STATE (state) {
      state.apps = []
      state.selectedApp = null
      state.advisers = []
      state.statistics = {}
      state.appConfig = {}
      state.appProvider = ''
      state.businesses = []
      state.roles = []
      state.journeys = []
      state.showTermsDialog = false
      state.showTutorial = false
      state.tutorialStep = 1
      state.tutorialPhone = ''
      state.successTutorial = false
      state.showPopup = false
      state.importTutorialSuccess = false
      state.campaignTutorialSuccess = false
      state.hasPendingTasks = false
      state.editingAdmin = false
      state.adminToEditOrCreate = null
      state.showSeptemberPopup = true
      state.showWebinarPopup = false
      state.showInternalConversationsTutorial = true
      state.isAndroid = false
    },
    SET_APP_CUSTOM_EXTRADATA (state, customExtradata) {
      state.customExtradata = customExtradata
    },
    SET_APP_LANGUAGE (state, language) {
      state.language = language
    },
    SET_SHOW_TERMS_DIALOG (state, showTermsDialog: boolean) {
      state.showTermsDialog = showTermsDialog
    },
    SET_SHOW_TUTORIAL (state, showTutorial: boolean) {
      state.showTutorial = showTutorial
    },
    SET_TUTORIAL_STEP (state, tutorialStep: number) {
      state.tutorialStep = tutorialStep
    },
    SET_TUTORIAL_PHONE (state, tutorialPhone: string) {
      state.tutorialPhone = tutorialPhone
    },
    SET_SUCCESS_TUTORIAL (state, successTutorial: boolean) {
      state.successTutorial = successTutorial
    },
    SET_WS_POPUP (state, showPopup) {
      state.showPopup = showPopup
    },
    SET_SEPTEMBER_POPUP (state, show) {
      state.showSeptemberPopup = show
    },
    SET_IMPORT_TUTORIAL_SUCCESS (state, importTutorialSuccess) {
      state.importTutorialSuccess = importTutorialSuccess
    },
    SET_CAMPAIGN_TUTORIAL_SUCCESS (state, campaignTutorialSuccess) {
      state.campaignTutorialSuccess = campaignTutorialSuccess
    },
    SET_FROM_ROUTE (state, from) {
      state.fromRoute = from
    },
    SET_HAS_PENDING_TASKS (state, hasPendingTasks) {
      state.hasPendingTasks = hasPendingTasks
    },
    SET_EDITING_ADMIN (state, editingAdmin) {
      state.editingAdmin = editingAdmin
    },
    SET_ADMIN_TO_EDIT_OR_CREATE (state, adminToEditOrCreate) {
      state.adminToEditOrCreate = adminToEditOrCreate
    },
    SET_SHOW_WEBINAR_POP_UP (state, show) {
      state.showWebinarPopup = show
    },
    SET_APP_MODULES (state, modules) {
      state.appModules = modules
    },
    SET_INTERNAL_TUTORIAL (state, show) {
      state.showInternalConversationsTutorial = show
    },
    SET_FORM_DIALOG_COMPLETED (state, completed) {
      state.formDialogCompleted = completed
    },
    SET_FORM_DIALOG_SKIPPED (state, skipped) {
      state.formDialogSkipped = skipped
    },
    SET_KB_FLOWS_REQUESTED_INFO (state, requestedInfo) {
      state.kbFlowsRequestedInfo = requestedInfo
    },
    SET_FAILED_CHUNK_COMPONENT (state, component) {
      state.failedChunkComponent = component
    },
    SET_REDIRECT_ROUTE (state, route) {
      state.redirectRoute = route
    },
    SET_END_REASON (state, endReadon: string[]) {
      state.endReadon = endReadon
    },
    SET_IS_ANDROID (state, isAndroid: boolean) {
      state.isAndroid = isAndroid
    }
  },
  actions: {
    setIsAndroid (context, isAndroid: boolean) {
      context.commit('SET_IS_ANDROID', isAndroid)
    },
    updateAdviser (context, adviser) {
      context.commit('UPDATE_ADVISER', adviser)
    },
    setAdvisers (context, advisers) {
      context.commit('SET_ADVISERS', advisers)
    },
    getAdviserId (context, userId: string) {
      return context.state.advisers.find((a) => a.userId === userId)._id
    },
    getAdviserIdtoName (context, userId: string) {
      return context.state.advisers.find((a) => a.userId === userId).name
    },
    getAdviserStatus (context, userId: string) {
      if (context.state.advisers) {
        const advisers = context.state.advisers.find((a) => a.userId === userId)
        if (advisers !== undefined) {
          return advisers.status
        }
      }
    },
    setShowTermsDialog (context, show) {
      context.commit('SET_SHOW_TERMS_DIALOG', show)
    },
    setShowTutorial (context, show) {
      context.commit('SET_SHOW_TUTORIAL', show)
    },
    setTutorialStep (context, step) {
      context.commit('SET_TUTORIAL_STEP', step)
    },
    setTutorialPhone (context, phone) {
      context.commit('SET_TUTORIAL_PHONE', phone)
    },
    setSuccessTutorial (context, success) {
      context.commit('SET_SUCCESS_TUTORIAL', success)
    },
    clearAppState (context) {
      context.commit('CLEAR_APP_STATE')
    },
    setWsPopup (context, show) {
      context.commit('SET_WS_POPUP', show)
    },
    setSeptemberPopup (context, show) {
      context.commit('SET_SEPTEMBER_POPUP', show)
    },
    setImportTutorialSuccess (context, success) {
      context.commit('SET_IMPORT_TUTORIAL_SUCCESS', success)
    },
    setCampaignTutorialSuccess (context, success) {
      context.commit('SET_CAMPAIGN_TUTORIAL_SUCCESS', success)
    },
    setHasPendingTasks (context, hasPendingTasks) {
      context.commit('SET_HAS_PENDING_TASKS', hasPendingTasks)
    },
    setEditingAdmin (context, editingAdmin) {
      context.commit('SET_EDITING_ADMIN', editingAdmin)
    },
    setAdminToEditOrCreate (context, adminToEditOrCreate) {
      context.commit('SET_ADMIN_TO_EDIT_OR_CREATE', adminToEditOrCreate)
    },
    setWebinarPopup (context, show) {
      context.commit('SET_SHOW_WEBINAR_POP_UP', show)
    },
    setInternalTutorial ({ commit }, show: boolean) {
      commit('SET_INTERNAL_TUTORIAL', show)
    },
    setFormDialogCompleted ({ commit }, completed: boolean) {
      commit('SET_FORM_DIALOG_COMPLETED', completed)
    },
    setFormDialogSkipped ({ commit }, skipped: boolean) {
      commit('SET_FORM_DIALOG_SKIPPED', skipped)
    },
    setKbFlowsRequestedInfo ({ commit }, requestedInfo: boolean) {
      commit('SET_KB_FLOWS_REQUESTED_INFO', requestedInfo)
    },
    setFailedChunkComponent ({ commit }, component: string) {
      commit('SET_FAILED_CHUNK_COMPONENT', component)
    },
    setRedirectRoute ({ commit }, route: string) {
      commit('SET_REDIRECT_ROUTE', route)
    },
    setApps ({ commit }, apps) {
      commit('SET_APPS', apps)
    }
  },
  getters: {
    isCDPApp (state) {
      return state.selectedApp?.uuid === AppUUIDsService.MidOne() || state.selectedApp?.uuid === AppUUIDsService.CDPTest() || state.selectedApp?.version === 'v6'
    },
    isKeybeApp (state) {
      return state.selectedApp?.uuid === AppUUIDsService.appKeybeAi()
    },
    isGWMApp (state: { selectedApp: { uuid: string } }): boolean {
      return AppUUIDsService.appsGWM().includes(state.selectedApp?.uuid || '')
    },
    isPartnerApp (state: { selectedApp: { uuid: string } }): boolean {
      return AppUUIDsService.appsPartner().includes(state.selectedApp?.uuid || '')
    },
    getSelectedApp (state) {
      return state.selectedApp
    },
    getAdvisers (state) {
      return state.advisers
    },
    getLanguage (state) {
      return state.language
    },
    getApps (state) {
      return state.apps
    },
    getCustomExtradata (state) {
      return state.customExtradata
    },
    getJourneys (state) {
      return state.journeys
    },
    getAppModules (state) {
      return state.appModules
    },
    getShowInternalTutorial (state) {
      return state.showInternalConversationsTutorial
    },
    getRedirectRoute (state) {
      return state.redirectRoute
    },
    isBlockOrEnabled: (state) => (custom:string) => {
      if (!state.selectedApp?.config) {
        return false
      }
      const blockCopyConfig = state.selectedApp.config.find(item => item.field === custom)
      return blockCopyConfig?.value === '1'
    },
    getEndReason (state) {
      return state.endReadon
    },
    getCurrencyApp (state):string {
      const item = state.selectedApp?.additionalData?.find(obj => obj.field === 'app_currency')
      if (item && item.value !== undefined) {
        return item.value
      }
      return 'USD'
    }
  }
}
