<template>
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 56 56"><path :fill="color" d="M40.444 56H15.556A15.574 15.574 0 0 1 0 40.444V15.556A15.574 15.574 0 0 1 15.556 0h24.888A15.574 15.574 0 0 1 56 15.556v24.888A15.574 15.574 0 0 1 40.444 56ZM15.556 5.444A10.176 10.176 0 0 0 5.444 15.556v24.888a10.177 10.177 0 0 0 10.112 10.112h24.888a10.177 10.177 0 0 0 10.112-10.112V15.556A10.177 10.177 0 0 0 40.444 5.444H15.556ZM28 42a14 14 0 0 1 0-28h.031A14 14 0 0 1 28 42Zm0-22.556A8.555 8.555 0 1 0 36.556 28 8.568 8.568 0 0 0 28 19.444Zm14.778-3.11a3.111 3.111 0 1 1 0-6.223 3.111 3.111 0 0 1 0 6.222Z"/></svg>
</template>

<script>
export default {
  name: 'InstagramIcon',
  props: {
    color: {
      type: String,
      default: '#000000'
    }
  }
}
</script>

<style>

</style>
