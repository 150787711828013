
















export default {
  name: 'CancelIcon',
  props: {
    color: {
      type: String,
      default: '#B6BEC7'
    }
  }
}
