import KeybeRepository from '@/shared/infrastructure/repositories/KeybeRepository'
import { HeadersBuilder } from '@/shared/infrastructure/http/HeadersBuilder'
import { GenericResponse } from '@/shared/infrastructure/http/GenericResponse'
import { dataToGetBillingReport, dataToGetIntentSetup, dataToGetNewContactsPlans } from '../../domain/BillingController'
import store from '@/store'

export default class BillingRepository extends KeybeRepository {
  // abort controllers
  abortController: AbortController
  baseUrl: string
  paymentUrl: string

  constructor () {
    super()

    // instance abort controllers
    this.abortController = new AbortController()

    this.baseUrl = process.env.VUE_APP_V6_ANALYTICS_URL
    this.paymentUrl = process.env.VUE_APP_PAYMENT_URL
  }

  abort (): void {
    this.abortController.abort()
  }

  async getIntentSetup (
    data: dataToGetIntentSetup
  ): Promise<GenericResponse> {
    const appUUID = store.getters['BillingStore/getAppUUID']
    data.uuid = appUUID
    const { type, total, currency, uuid, description } = data
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    const url = `${this.paymentUrl}/payment/intent/setup`

    const request = {
      url,
      data: {
        type,
        total,
        currency,
        uuid,
        appUUID,
        description
      },
      headers,
      abortController: this.abortController
    }

    return await this.client.post(request)
  }

  async getBillingReport (
    data: dataToGetBillingReport
  ): Promise<GenericResponse> {
    const { companyUUID, appUUID, fromDate, toDate } = data
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    const url = `${this.baseUrl}/company/${companyUUID}/app/${appUUID}/billing/historic/consumptions?from_date=${fromDate}&to_date=${toDate}&export_mode=xlsx`

    const request = {
      url,
      data: {
        exportOptions: data.exportOptions
      },
      headers,
      abortController: this.abortController
    }

    return await this.client.post(request)
  }

  async getNewDailyContactsPlans (data: dataToGetNewContactsPlans): Promise<GenericResponse> {
    const { appId, tier } = data
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    const url = `${process.env.VUE_APP_BILLING_URL}v1/products/plans/${appId}/${tier}`

    const request = {
      url,
      headers,
      abortController: this.abortController
    }

    return await this.client.get(request)
  }
}
