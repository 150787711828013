














import HeatMap from './HeatMap'
export default HeatMap
