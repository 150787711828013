import { StatusCodes } from '@/shared/infrastructure/http/StatusCodes'
import {
  languagesGetter,
  timezonesGetter
} from '../../domain/LocationController'

export default class LocationService {
  static async getTimezones (): Promise<timezonesGetter> {
    try {
      const url = `${process.env.VUE_APP_STORAGE_URL}/files/locations/timezones.json`
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Access-Control-Allow-Origin': '*'
        }
      })
      if (response.status !== StatusCodes.HTTP_200_OK) {
        return {
          status: false,
          timezones: []
        }
      }
      const data = await response.json()
      if (data.length > 0) {
        return {
          status: true,
          timezones: data
        }
      }
    } catch (error) {
      console.error(error)
      return {
        status: false,
        timezones: []
      }
    }
  }

  static async getLanguages (): Promise<languagesGetter> {
    try {
      const url = `${process.env.VUE_APP_STORAGE_URL}/files/locations/languages.json`
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Access-Control-Allow-Origin': '*'
        }
      })
      if (response.status !== StatusCodes.HTTP_200_OK) {
        return {
          status: false,
          languages: []
        }
      }
      const data = await response.json()
      if (data.length > 0) {
        return {
          status: true,
          languages: data
        }
      }
    } catch (e) {
      console.error(e)
      return {
        status: false,
        languages: []
      }
    }
  }
}
