import Loader from '@/shared/infrastructure/ui/Loader.vue'
import EmailForm from '@/modules/outbound/infrastructure/ui/components/forms/EmailForm.vue'
import AlertMsg from '@/modules/outbound/infrastructure/ui/components/forms/AlertMsg/AlertMsg.vue'
import CampaignsService from '@/services/CampaignsService'

export default {
  name: 'NewEmailPage',
  props: {
    setSegment: {
      type: String
    }
  },
  components: {
    Loader,
    EmailForm,
    AlertMsg
  },
  mounted () {
    this.getEmailDomain()
  },
  watch: {

  },
  data () {
    return {
      validateDomain: false,
      alertDomain: false,
      loading: false,
      loadingLine: false,
      listDomains: []
    }
  },
  created () {
    this.summaryMenu = false
    this.outboundName = null
  },
  computed: {
    outboundName: {
      get () {
        return this.$store.state.CampaingsStore.outboundName || null
      },
      set (value) {
        this.$store.commit('CampaingsStore/SET_EDITING_OBJECT', {
          key: 'outboundName',
          value
        })
      }
    },
    summaryMenu: {
      get () {
        return this.$store.state.CampaingsStore.summaryMenu || false
      },
      set (value) {
        this.$store.commit('CampaingsStore/SET_EDITING_OBJECT', {
          key: 'summaryMenu',
          value
        })
      }
    }
  },
  methods: {
    async getEmailDomain () {
      try {
        const response = await CampaignsService.validateDomain()
        if (response) {
          if (Object.prototype.hasOwnProperty.call(response, 'domains')) {
            if (response.domains.length > 0) {
              this.listDomains = response.domains
              const domain = response.domains[0]
              domain.verified ? this.validateDomain = domain.domain : this.validateDomain = null
              this.validateDomain ? this.alertDomain = false : this.alertDomain = true
            } else {
              this.alertDomain = true
            }
          } else {
            this.alertDomain = true
          }
        } else {
          this.alertDomain = true
        }
      } catch (e) {
        console.log(e)
      }
    }
  },
  beforeDestroy () {
  }
}
