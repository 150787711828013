import CampaignsService from '@/services/CampaignsService'
import moment from 'moment'

export const campaignsSms = async (
  { message, isBidirectionalSMS, userHost, allowAccentMarksAndEmojis, segment, campaignName }
) => {
  try {
    const sms = {
      message: message,
      isBidirectionalSMS: isBidirectionalSMS,
      userHost: userHost,
      allowAccentMarksAndEmojis: allowAccentMarksAndEmojis
    }

    const campaign = {
      date: moment.now(),
      listId: segment,
      name: campaignName,
      status: 'draft',
      type: 'sms',
      sms
    }

    const campaignCreated = await CampaignsService.createCampaignV6(campaign)
    if (campaignCreated?.response) {
      return campaignCreated.response
    }
    await CampaignsService.linkCampaignV6({
      campaignId: campaignCreated?.campaign._id,
      createFromUser: segment === 'all'
    })
    return campaignCreated
  } catch (error) {
    console.error(error)
    return error
  }
}
