import Vue from 'vue'
import VueI18n from 'vue-i18n'
import store from '@/store/index'
import { Languages } from '@/components/shared/enums/Languages'
Vue.use(VueI18n)

const messages = {}

export const i18n = new VueI18n({
  locale: store.getters['AppStore/getLanguage'],
  silentTranslationWarn: true,
  messages
})

const loadedLanguages = [] // our default language that is preloaded

function setI18nLanguage (lang) {
  i18n.locale = lang
  document.querySelector('html').setAttribute('lang', lang)
  return lang
}

export async function loadLanguageAsync (lang: string) {
  // If the same language
  if (!lang) lang = Languages.EN
  if (i18n.locale === lang) {
    return Promise.resolve(setI18nLanguage(lang))
  }

  // If the language was already loaded
  if (loadedLanguages.includes(lang)) {
    return Promise.resolve(setI18nLanguage(lang))
  }

  // If the language hasn't been loaded yet
  let messages = null
  let imported = null
  switch (lang) {
    case 'es':
      imported = await import(/* webpackChunkName: "i18n-en" */ './locales/es/general')
      messages = {
        ...imported
      }
      break
    case 'en':
      imported = await import(/* webpackChunkName: "i18n-fr" */ './locales/en/general')
      messages = {
        ...imported
      }
      break
    case 'pt': {
      imported = await import(/* webpackChunkName: "i18n-pt" */ './locales/pt/general')
      messages = {
        ...imported
      }
      break
    }
  }

  if (imported != null) {
    i18n.setLocaleMessage(lang, messages.default)
    loadedLanguages.push(lang)
    return setI18nLanguage(lang)
  }
}
