import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'
import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import Colors from '@/utils/Colors'

export default {
  name: 'CollapsedScreen',
  components: {
    KeybeIcon
  },
  props: {
    dataTocreate: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      KeybeIconType,
      Colors
    }
  },

  computed: {
    typographyStyle () {
      return `font-family: ${this.dataTocreate.widgetConfig.typography};`
    },
    buttonStyle () {
      return `background-color: ${this.dataTocreate.widgetConfig.buttonColor}; color: ${this.dataTocreate.widgetConfig.buttonTextColor}; ${this.typographyStyle}`
    },
    titleStyle () {
      return `color: ${this.dataTocreate.widgetConfig.buttonTextColor}; font-size: ${this.dataTocreate.widgetConfig.textSize}; ${this.typographyStyle}`
    },
    subtitleStyle () {
      return `color: ${this.dataTocreate.widgetConfig.messageColor}; ${this.typographyStyle}`
    },
    chatContainerStyle () {
      return `background: ${this.dataTocreate.widgetConfig.formBackgroundColor}; ${this.typographyStyle}`
    }
  }
}
