import SmartOMeterUseCases from '@/modules/ai/application/SmartOMeterUseCases'
import { SmartOMeterTiers } from '@/modules/ai/domain/SmartOMeter/SmartOMeterTiers'
import BikyService from '@/modules/bky/domain/Biky.service'
import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'
import { debounce } from 'lodash'
import { mapActions, mapState } from 'vuex'
import SmartOMeterController from '../../controllers/SmartOMeter.controller'
import KnowledgeBaseFiles from '../KnowledgeBaseFiles/KnowledgeBaseFiles.vue'
import OldPlan from '../OldPlan/OldPlan.vue'

export default {
  name: 'SmartOMeterConfig',
  components: {
    KeybeIcon,
    KnowledgeBaseFiles,
    OldPlan
  },
  data () {
    return {
      KeybeIconType,
      tierNumber: 1,
      sliderLimit: 3,
      percentageTable: {
        1: 0,
        2: 50,
        3: 100
      },
      tier1Perks: [
        {
          text: this.$t('dataEnrichmentCDP'),
          strong: false
        },
        {
          text: this.$t('assistedDataEnrichmentCDP'),
          strong: false
        },
        {
          text: this.$t('automaticSalesOpportunities'),
          strong: false
        },
        {
          text: this.$t('automaticSupportTickets'),
          strong: false
        },
        {
          text: this.$t('prospectingBot'),
          strong: false
        },
        {
          text: this.$t('chatAutoassignment'),
          strong: false
        },
        {
          text: this.$t('salesAndAutomaticSupportTickets'),
          strong: false
        }
      ],
      tier2Perks: [
        {
          text: this.$t('allLevel1Included'),
          strong: false
        },
        {
          text: this.$t('kbNeural'),
          strong: true
        },
        {
          text: this.$t('sentimentAnalysis'),
          strong: false
        },
        {
          text: this.$t('essentialMessageSuggestions'),
          strong: false
        }
      ],
      tier3Perks: [
        {
          text: this.$t('allLevel2Included'),
          strong: false
        },
        {
          text: this.$t('kbCortex'),
          strong: true
        },
        {
          text: this.$t('sentimentalAnalysisWithWaitingTimes'),
          strong: false
        },
        {
          text: this.$t('advancedMessageSuggestions'),
          strong: false
        },
        {
          text: this.$t('smartChatLearningData'),
          strong: false
        }
      ],
      tier4Perks: [
        {
          text: this.$t('allLevel3Included'),
          strong: false
        },
        {
          text: this.$t('kbTesseract'),
          strong: true
        },
        {
          text: this.$t('improvedMessageSuggestions'),
          strong: false
        },
        {
          text: this.$t('bestAgentsLearning'),
          strong: false
        }
      ],
      smartOMeterController: new SmartOMeterController(),
      sliderWidth: 1
    }
  },
  mounted () {
    const tier = this.tier
    const tierNumber = tier.split(' ')[1]
    this.tierNumber = parseInt(tierNumber)
    if (tierNumber > 2) {
      this.tierNumber -= 1
    }
    document.documentElement.style.setProperty('--smart-o-meter-value', `${this.percentageTable[this.tierNumber]}%`)

    this.getSliderWidth()

    window.addEventListener('resize', this.getSliderWidth)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.getSliderWidth)
  },
  methods: {
    ...mapActions('BillingStore', ['setTier']),
    onTierChange (e) {
      const tier = e.target.value
      this.tierNumber = parseInt(tier)
      this.updateTier()
    },
    updateTier: debounce(async function () {
      let newTierNumber = this.tierNumber
      if (this.tierNumber >= 2) {
        newTierNumber += 1
      }
      const newTier = `TIER_${newTierNumber}`

      const level = SmartOMeterTiers[newTier]

      await SmartOMeterUseCases.updateTier(this.smartOMeterController, {
        appUUID: this.selectedApp?.uuid,
        level,
        token: this.token,
        user: this?.user.uuid
      })

      this.setTier(level)
    }, 500),
    getSliderWidth () {
      const sliderContainer = document.querySelector('.SmartOMeterConfig__sliderContainer--slider')
      const sliderContainerWidth = sliderContainer.clientWidth
      this.sliderWidth = sliderContainerWidth + 3
    },
    goBack () {
      this.tierNumber = this.tierNumber - 1
      this.updateTier()
    }
  },
  computed: {
    ...mapState('BillingStore', ['tier']),
    ...mapState('AppStore', ['selectedApp']),
    ...mapState('UserStore', ['token', 'user']),
    ruleIconWidth () {
      return this.sliderWidth / this.sliderLimit
    },
    tierInfo () {
      return this.$t(`tier${this.tierNumber}Info`)
    },
    isTier3 () {
      return this.tierNumber === 3
    },
    isTier4 () {
      return this.tier === SmartOMeterTiers.TIER_4
    },
    essentialIcon () {
      if (this.tierNumber === 1) {
        return KeybeIconType.SIMPLE_CHAT_GREEN
      }

      if (this.$vuetify.theme.dark) {
        return KeybeIconType.SIMPLE_CHAT_DARK
      }

      return KeybeIconType.SIMPLE_CHAT_LIGHT
    },
    cortexIcon () {
      if (this.tierNumber === 2) {
        return KeybeIconType.CORTEX_GREEN
      }

      if (this.$vuetify.theme.dark) {
        return KeybeIconType.CORTEX_DARK
      }

      return KeybeIconType.CORTEX_LIGHT
    },
    tesseractIcon () {
      if (this.tierNumber === 3) {
        return KeybeIconType.TESSERACT_GREEN
      }

      if (this.$vuetify.theme.dark) {
        return KeybeIconType.TESSERACT_DARK
      }

      return KeybeIconType.TESSERACT_LIGHT
    }
  },
  watch: {
    tierNumber () {
      if (this.tierNumber > 2 && !BikyService.isBikyApp(this.selectedApp?.uuid)) {
        this.tierNumber = 2
      }
      document.documentElement.style.setProperty('--smart-o-meter-value', `${this.percentageTable[this.tierNumber]}%`)
    }
  }
}
