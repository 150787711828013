import { render, staticRenderFns } from "./TemplateComposerProducts.vue?vue&type=template&id=950b4f82&scoped=true&"
import script from "./TemplateComposerProducts.vue?vue&type=script&lang=ts&"
export * from "./TemplateComposerProducts.vue?vue&type=script&lang=ts&"
import style0 from "./TemplateComposerProducts.vue?vue&type=style&index=0&id=950b4f82&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "950b4f82",
  null
  
)

export default component.exports