<template>
  <div class="image-cropper">
    <div class="d-flex justify-end -mt-3 mb-4">
      <v-btn icon
             @click="toggleModal()">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </div>
    <div>
      <v-row>
        <v-col cols="12"
               style="background: rgba(182, 190, 199, 0.2); min-height: 50vh"
               v-if="!imageUrl">
          <v-row class="fill-height flex-column"
                 align="center"
                 justify="center">
            <img v-if="savedImage"
                 :src="savedImage"/>
            <div class="upload-button cursor-pointer"
                 v-else
                 @click="onPickFile">
              <v-icon :color="Colors.verdeBird">mdi-upload</v-icon>
            </div>
            <input type="file"
                   style="display: none"
                   ref="fileInput"
                   accept="image/*"
                   @change="onFilePicked">
          </v-row>
        </v-col>
        <v-col cols="12"
               v-show="imageUrl"
               style="min-height: 50vh;">
          <vue-croppie
            style="max-height: 44vh"
            ref="croppieRef"
            :enableResize="false"
            :enableOrientation="true"
            @result="result"
            :viewport="{ width: 300, height: 300, type: 'circle'}">
          </vue-croppie>
        </v-col>
      </v-row>
    </div>

    <div class="d-flex items-center justify-center mt-10">
      <span @click="savedImage = ''; imageUrl = ''"
            class="cursor-pointer d-flex items-center mr-5">
        <v-icon color="black"
                class="mr-2">mdi-delete-circle-outline</v-icon>
        {{$t('delete')}}</span>
      <span @click="bind(data.image)"
            class="cursor-pointer mr-5 d-flex items-center">
        <v-icon color="black"
                class="mr-2">mdi-pencil-circle-outline</v-icon>
        {{$t('edit')}}</span>
      <span @click="onPickFile"
            class="cursor-pointer mr-5 d-flex items-center">
        <v-icon color="black"
                class="mr-2">mdi-upload</v-icon>
        {{$t('uploadImage')}}</span>
      <span @click="crop"
            :style="{ color: Colors.verdeBird }"
            class="cursor-pointer d-flex items-center">
        <v-icon :color="Colors.verdeBird"
                class="mr-2">mdi-check-circle-outline</v-icon>
        {{$t('save')}}
      </span>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import Colors from '@/utils/Colors'
import { EventBus } from '@/eventBus'
import 'croppie/croppie.css'

export default Vue.extend({
  data () {
    return {
      cropped: null,
      imageUrl: '',
      loading: false,
      savedImage: '',
      Colors
    }
  },
  mounted () {
    this.onPickFile()
    this.savedImage = this.data.image
  },
  methods: {
    dataURLtoFile (dataurl, filename) {
      const arr = dataurl.split(',')
      const mime = arr[0].match(/:(.*?);/)[1]
      const bstr = atob(arr[1])
      let n = bstr.length
      const u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new File([u8arr], filename, { type: mime })
    },
    onPickFile () {
      this.$refs.fileInput.click()
    },
    onFilePicked (event) {
      const files = event.target.files
      const filename = files[0].name
      if (filename.lastIndexOf('.') <= 0) {
        return window.alert('Please add a valid file')
      }
      // eslint-disable-next-line
        const fileReader = new FileReader()
      fileReader.addEventListener('load', () => {
        this.imageUrl = fileReader.result
        this.savedImage = this.imageUrl
        this.bind()
      })
      fileReader.readAsDataURL(files[0])
    },
    bind (image) {
      if (image) {
        this.$refs.croppieRef.bind({
          url: image.toString()
        })
        setTimeout(() => {
          this.imageUrl = image.toString()
        }, 500)
      } else {
        this.$refs.croppieRef.bind({
          url: this.imageUrl
        })
      }
    },
    crop () {
      this.loading = true

      const options = {
        format: 'jpeg',
        circle: false
      }

      this.$refs.croppieRef.result(options, (output) => {
        this.cropped = output
        const imageFile = this.dataURLtoFile(this.cropped, 'file.jpg')
        EventBus.$emit('croppedImage', imageFile)

        this.loading = false

        this.toggleModal()
      })
    },
    result (output) {
      this.cropped = output
    },
    rotate (rotationAngle) {
      this.$refs.croppieRef.rotate(rotationAngle)
    },
    toggleModal () {
      this.imageUrl = ''
      this.$emit('cancel')
    }
  },
  props: ['value', 'data']
})
</script>
<style scoped lang="scss">
.connect-button {
  background-color: #0faef6 !important;
  color: #FFFFFF;
  border-radius: 40px;
  font-family: 'Poppins' !important;
  text-transform: capitalize;
  font-size: 0.9rem;
  margin: 0 10px;
  width: 130px;
  letter-spacing: 0.5px;
  box-shadow: none;
}
.connect-button-outlined {
  background-color: transparent!important;
  color: #0faef6;
  border: 1px solid #0faef6;
  border-radius: 40px;
  font-family: 'Poppins' !important;
  text-transform: capitalize;
  font-size: 0.9rem;
  margin: 0 10px;
  width: 130px;
  letter-spacing: 0.5px;
  box-shadow: none;
}

.upload-button {
  border: 3px solid $verde-bird;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
