<template>
  <div class="KBSimpleTableCommissionConfig"
       style="position: relative;"
       :class="$vuetify.theme.dark ? 'ModeDarkConfig' : 'modeLight'">
    <Loader v-if="loading"/>
    <div style="justify-content: start;align-items: start;width: 90px;">
      <KBButton :text="$t('goBack')"
                :type="'flat'"
                :color="$vuetify.theme.dark ? 'white' : 'black'"
                :icon="'arrow-left'"
                @click="onDetail = !onDetail"
                class="pa-0"/>
    </div>
    <table class="KBSimpleTable__table"
           :class="$vuetify.theme.dark ? 'ModeDarkConfig' : 'modeLight'">
      <tbody>
        <tr class="KBSimpleTable__row"
            :class="selectItem === itemIndex && newDomainDialog ? 'selectItem': ''"
            v-for="(item, itemIndex) in usersDetail"
            :key="`KBSimpleTableCommissionConfig-item-${itemIndex}`">
          <!-- <td
            class="KBSimpleTable__item pr-0"
            style="width: 65px;"
          >
            <KBSwitch
              @input="saveConfig"
              class="AdvisorConfigCard__container--switch"
              color="#3AF69B"
              v-model="item.status"/>
          </td> -->
          <td class="KBSimpleTable__item"
              style="width: 55px;">
            <KeybeAvatar
              name="n"
              :size="20"
              :isKeybeProfile="false" />
          </td>
          <td class="KBSimpleTable__item">
            {{item.user_id ? item.user_id.name : ''}}
          </td>
          <td class="KBSimpleTable__item">
            {{item.date | moment('DD/MM/YYYY')}}
          </td>
          <td class="KBSimpleTable__item">
            {{ item.user_id ? item.category_id.name : '' }}
          </td>
        </tr>
      </tbody>
    </table>
    <div v-if="usersDetail.length === 0">
      <span class="pl-4">{{ $t('noresults') }}</span>
    </div>
    <CustomPagination
      v-if="usersDetail.length > 0"
      class="SegmentTable__footer pl-5 pr-3 mb-5"
      :count="count"
      :paginationSetted="{page:currentPage, limit: itemsPerPage}"
      @getItems="setPaginationUser($event)"
      :text="$t('users')"/>
    <div class="d-flex justify-end py-3 actionFormCommission"
         style="height: 50px;width: 100%;position: absolute;bottom: 10px;">
      <KBButton :text="$t('addTypeTask')"
                @click="visbleModal = !visbleModal"
                :type="'flat'"
                :color="$vuetify.theme.dark ? 'white' : 'black'"
                :icon="'add'"/>
    </div>
    <v-expand-transition>
      <div class="contentUsers"
           v-if="visbleModal">
        <div class="d-flex mb-3 mt-3 clickItem"
             @click="setDataUser ()">
          <div style="width: 40px;">
            <KeybeAvatar
              name="n"
              :size="20"
              :isKeybeProfile="false" />
          </div>
          <span>{{ $t('createPerson') }}</span>
        </div>
        <div class="contetnKBTest">
          <KBText
            v-model="search"
            :placeholder="$t('search')"
          />
        </div>
        <div class="scrollCommission"
             v-if="administrators.length > 0 || mapResponse.length > 0">
          <div v-for="item in resultQuery"
               class="d-flex my-1"
               :key="item.id">
            <div style="width: 40px;"
            >
              <div v-if="item.image"
                   class="circular--portrait">
                <img :src="item.image">
              </div>
              <KeybeAvatar
                v-else
                name="n"
                :size="20"
                :isKeybeProfile="false" />
            </div>
            <span class="clickItem"
                  @click="setDataUser(item)">{{ item.name }} {{ item.lastName ? item.lastName : '' }}  {{ item.surname ? item.surname : '' }} <b v-if="item.fees">*</b></span>
          </div>
        </div>
      </div>
    </v-expand-transition>
  </div>
</template>

<script>
import operation from '@/utils/operations'
import moment from 'moment'
import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import FeesServices from '@/modules/configuration/application/services/FeesServices'
// const KBSwitch from '@/shared/infrastructure/ui/inputs/select/KBSwitch.vue')
import CustomPagination from '@/components/shared/components/CustomPagination.vue'
import KeybeAvatar from '@/shared/infrastructure/ui/KeybeAvatar.vue'
import KBButton from '@/shared/infrastructure/ui/buttons/KBButton.vue'
import KBText from '@/shared/infrastructure/ui/inputs/text/KBText.vue'
import Loader from '@/shared/infrastructure/ui/Loader.vue'

export default {
  name: 'KBTableCommissionUsers',
  props: ['labels'],
  components: { KeybeAvatar, KBButton, KBText, CustomPagination, Loader },
  data () {
    return {
      count: 0,
      itemsPerPage: 10,
      currentPage: 1,
      search: '',
      visbleModal: false,
      administrators: [],
      activeItem: false,
      creating: false,
      KeybeIconType,
      newDomainDialog: false,
      selectItem: null,
      name: null,
      email: null,
      prefix: null,
      active: false,
      loading: false,
      loadingResponsibles: false,
      mapResponse: [],
      usersDetail: []
    }
  },
  watch: {
    onDetail () {
      if (this.onDetail) {
        this.getSingleFeesDetails(this.idCompany)
      }
    }
  },
  computed: {
    resultQuery () {
      if (this.search) {
        let final = []
        const adm = this.administrators ? this.searchIn(this.administrators, this.search) : []
        const user = this.mapResponse ? this.searchIn(this.mapResponse, this.search) : []
        if (adm.length > 0 && user.length > 0) {
          final = adm.concat(user)
          return final
        }
        if (adm.length > 0) {
          final = adm
          return final
        }
        if (user.length > 0) {
          final = user
        }
        return final || []
      } else {
        const arrayFilter = []
        if (this.administrators.length > 0) {
          this.administrators.forEach(administrator => {
            if (administrator) {
              if (Object.prototype.hasOwnProperty.call(administrator, 'id')) {
                arrayFilter.push(administrator)
              }
            }
          })
        }
        if (this.mapResponse.length > 0) {
          this.mapResponse.forEach(administrator => {
            if (administrator) {
              if (Object.prototype.hasOwnProperty.call(administrator, 'id')) {
                arrayFilter.push(administrator)
              }
            }
          })
        }
        return arrayFilter
      }
    },
    advisers: {
      get () {
        return this.$store.state.AppStore.advisers || null
      }
    },
    onCreate: {
      get () {
        return this.$store.state.CommissionStore.onCreate || false
      },
      set (value) {
        this.$store.commit('CommissionStore/SET_EDITING_OBJECT', {
          key: 'onCreate',
          value
        })
      }
    },
    onCreateData: {
      get () {
        return this.$store.state.CommissionStore.onCreateData || null
      },
      set (value) {
        this.$store.commit('CommissionStore/SET_EDITING_OBJECT', {
          key: 'onCreateData',
          value
        })
      }
    },
    onDetail: {
      get () {
        return this.$store.state.CommissionStore.onDetail || false
      },
      set (value) {
        this.$store.commit('CommissionStore/SET_EDITING_OBJECT', {
          key: 'onDetail',
          value
        })
      }
    },
    idCompany: {
      get () {
        return this.$store.state.CommissionStore.idCompany || []
      },
      set (value) {
        this.$store.commit('CommissionStore/SET_EDITING_OBJECT', {
          key: 'idCompany',
          value
        })
      }
    }
  },
  mounted () {
    this.onCreateData = null
    this.dataUser = this.data
    if (this.idCompany) {
      this.getAppAdmins(this.idCompany)
      this.getSingleFeesDetails(this.idCompany)
    }
  },
  methods: {
    async getSingleFeesDetails (uuid, pagination) {
      if (!pagination) {
        pagination = { page: 1, limit: 10 }
      }
      try {
        this.loading = true
        const response = await FeesServices.getSingleFees(uuid, pagination)
        if (response) {
          this.usersDetail = response.data.users
        }
        this.loading = false
      } catch (error) {
        console.error(error)
      }
    },
    setPaginationUser (pagination) {
      const paginationS = { page: pagination.page, limit: pagination.items }
      this.getSingleFeesDetails(this.idCompany, paginationS)
    },
    searchIn (array, search) {
      if (array.length > 0 && search != null) {
        return array.filter((item) => {
          if (item) {
            const name = item?.name ? (item?.surname ? `${item?.name} ${item?.surname}` : `${item?.name} ${item?.lastName}`) : ''
            return name.toLowerCase().includes(search.toLowerCase())
          }
        })
      } else {
        return []
      }
    },
    setDataUser (item) {
      this.onCreate = !this.onCreate
      if (item) {
        this.onCreateData = item
      }
    },
    async getAppAdmins (app) {
      try {
        this.loadingResponsibles = true
        const pagination = {
          page: 1,
          sortBy: '',
          descending: '',
          limit: 500
        }
        const response = await FeesServices.getAdministratorsUser(app, pagination)
        const responseUser = await FeesServices.getFeesUser(pagination)
        if (responseUser) {
          if (responseUser?.length < 1) {
            this.mapResponse = []
            return
          }
          this.mapResponse = responseUser.map(temp => {
            function capitalizarPrimeraLetra (str) {
              return str.charAt(0).toUpperCase() + str.slice(1)
            }
            return {
              ...temp,
              id: temp._id,
              lastName: '',
              name: temp.name ? capitalizarPrimeraLetra(temp.name.toLowerCase()) : '',
              phone: temp.phone ? temp.phone.slice(1, -1) : null
            }
          })
          // this.administrators = this.administrators.concat(mapResponse)
        }
        if (response) {
          // if (response.code === 500) {
          //   return
          // }
          this.administrators = this.administrators.concat(response.users)
          this.administrators.forEach(a => {
            this.advisers.forEach(b => {
              const temp = a?.email ? a.email.split(' ') : ''
              if (b.image) {
                if (temp) {
                  if (temp[0].includes(b.email.trim())) {
                    if (b.image.includes('https://s3.us-east-2.amazonaws.com')) {
                      a.image = b.image
                    } else {
                      a.image = null
                    }
                  }
                }
              }
            })
          })
          // this.itemData = this.administrators
          this.loadingResponsibles = false
        }
        this.loadingResponsibles = false
      } catch (e) {
        this.loadingResponsibles = false
        console.error(e)
      }
    },
    async saveConfig () {
      if (this.loading) {
      }
      // const response = await AdvisersService.updateAdviser(newAdviser)
      // if (response.status) {
      //   await this.$store.dispatch('AppStore/updateAdviser', newAdviser)
      // }
    },
    callModal (index, { name, email, prefix, inactive }) {
      this.name = name
      this.email = email
      this.prefix = prefix
      this.active = inactive
      this.selectItem = index
      this.newDomainDialog = !this.newDomainDialog
    },
    formatDate (date) {
      return moment(date).locale('es').format('ll')
    },
    areEqual (a, b) {
      return operation.areEqual(a, b)
    },
    positionClass (index, length) {
      if (index === 0) {
        return 'first-item'
      }
      return ''
    }
  }
}
</script>

  <style lang="scss">
  .config--avatar{
      width: 25px !important;
      height: 25px !important;
      span{
          padding-top: 1px;
          color: white;
          font-size: 8px !important;
      }
  }

  .KBSimpleTable__table {
    overflow: scroll;
    border-collapse: collapse;
    width: 100%;
  }

  .modeLight thead th {
      background-color: #F7F7F7;
  }

  .modeDark thead th {
      background-color: #0000 !important;
  }

  .KBSimpleTable__header {
    font-weight: 300;
    font-size: 11px;
    line-height: 0px;
    color: $simple-grey;
    height: 30px;
    text-align: start !important;
    padding-left: 8px;
  }

  .KBSimpleTable__item {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    padding: 10px;
    border-bottom: 1px solid #EBEFF2;
    text-align: start;
    min-width: 50px  !important;
  }

    .first-item {
      padding-left: 32px;
      max-width: 125px;

      // text elipsis
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .last-item {
      padding-right: 32px;
    }
    .textGray{
      color: #707683;
  }
  .smallFont{
      font-size: 11px;
  }
  .edit--content{
      position: relative;
  }
  .icon-edit{
      span{
          color: #707683 !important;
          font-size: 12px !important;
      }
  }
  table { border-collapse: collapse; width: 100%; }

  .KBSimpleTableCommissionConfig{
    height: 95%;
    // max-width: 1100px;
  }
  .KBSimpleTableCommissionConfig thead th {
    position: sticky;
    top: 0;
  }
  .dialog-edit__Light{
    background: white;
  }
  .dialog-edit__Dark{
    background: black;
  }
  .dialog-edit {
      z-index: 9;
      position: absolute;
      border-radius: 10px;
      padding: 10px 20px;
      width: 400px;
      box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1);
      &__postionTop{
          top: 40px;
          right: 0;
      }
      &__postionBottom{
          bottom: 40px;
          right: 0;
      }
  }
  // @media screen and (min-height: 600px) {
  // .KBSimpleTable{
  //   overflow: scroll;
  //   height: 335px;
  // }
  // }

  // @media screen and (min-height: 700px) {
  // .KBSimpleTable{
  //   overflow: scroll;
  //   height: 435px;
  // }
  // }
  // @media screen and (min-height: 750px) {
  // .KBSimpleTable{
  //   overflow: scroll;
  //   height: 465px;
  // }
  // }

  // @media screen and (min-height: 800px) {
  // .KBSimpleTable{
  //   overflow: scroll;
  //   height: 475px;
  // }
  // }
  // @media screen and (min-height: 900px) {
  // .KBSimpleTable{
  //   overflow: scroll;
  //   height: 515px;
  // }
  // }
  .selectItem{
      background-color: #cbd4e72e;
  }
  .KBSimpleTable__item{
      font-weight: 300 !important;
  }
  .contentUsers{
    position: absolute;
    bottom: 40px;
    height: 370px;
    overflow: hidden;
    width: 240px;
    background-color: white;
    right: 10px;
    border-radius: 5px;
    padding: 10px;
    border: 1px solid rgba(168, 167, 167, 0.288);
    z-index: 9;
    .contetnKBTest{
      padding-bottom: 5px;
      background: white;
    }
    .KBText {
      height: 35px !important;
    }
  }
  .scrollCommission{
    height: 280px;
    overflow-y: scroll;
    width: 300px;
    color: gray;
    padding-bottom: 40px;
  }
  .clickItem{
    cursor: pointer;
  }
  .circular--portrait { position: relative; width: 20px; height: 20px; overflow: hidden; border-radius: 50%; }
  .circular--portrait img { width: 100%; height: auto; }
  .border-box{
    border-top:1px solid #c9c6c6ab
  }
  .ModeDarkConfig{
    .contetnKBTest{
      background: #000000;
    }
  }
  </style>
