<template>
  <div class="metricTableGray"
       :class="$vuetify.theme.dark ? 'metricTableGrayDark' : ''"
       :style="itemData.length === 0 ? 'min-height: 40px;': ''">
    <div class="contentFilterTable"
         v-if="itemData.length !== 0">
      <div @click="closeModal = true"
           class="contentFilterTabletitle">
        <span
        >{{ $t('tableConversationsTakenByAgent') }}</span>
      </div>
    </div>
    <BaseImgTable v-if="itemData.length !== 0"
                  :labels="headers"
                  :data="itemData"
    />
    <div v-if="itemData.length === 0"
         @click="closeModal = true"
         class="py-6"
         style="margin-bottom:250px">
      <span style="margin-bottom:50px">{{ $t('msgEmptyConversation') }}</span>
    </div>
  </div>
</template>

<script>
import BaseImgTable from './BaseTable.vue'
import { CDPFieldsEnums } from '@/modules/CDP/shared/contacts/domain/enums/CDPFieldsEnums'
export default {
  name: 'MetricsTable',
  components: {
    BaseImgTable
  },
  props: [
    'itemData'
  ],
  data () {
    return {
      headers: [
        { text: this.$t(CDPFieldsEnums.NAME), field: 'advisorName' },
        { text: this.$t('status'), field: 'status' },
        { text: this.$t('En atención'), field: 'totalAnswered' },
        { text: this.$t('Sin respuesta'), field: 'totalUnanswered' },
        { text: this.$t('total'), field: 'total' }
      ]
    }
  },
  watch: {
  },
  computed: {
    closeModal: {
      get () {
        return this.$store.state.MetricsStore.closeModal || false
      },
      set (value) {
        this.$store.commit('MetricsStore/SET_EDITING_OBJECT', {
          key: 'closeModal',
          value
        })
      }
    }
  },
  methods: {
  },
  mounted () {
  }
}
</script>
<style lang='scss'>
.metricTableGray {
  border-radius: 10px;
  background-color: #d9d9d923;
  color: #000000;
  border: 1px solid #00000015;
  width: 98%;
  display: flex;
  align-items: flex-start;
  overflow: hidden;
  flex-direction: column;
  padding: 10px;
  margin: 15px 15px 20px 0px;
  max-width: 1075px;
  min-width: 90%;
  justify-content: space-between;

  span{
    padding-bottom: 10px;
  }
  .metricTableGrayDark {
  background-color: black !important;
  span {
    color: white;
  }
  .KBSimpleTable__itemDark{
    color: white;
  }
}
  .KBSimpleTable{
    margin-top: 10px;
    width: 97%;
     &__itemDark{
      font-style: normal;
      font-weight: 300;
      font-size: 12px;
      padding: 10px;
      border-bottom: 1px solid rgba(207, 205, 205, 0.493);
      text-align: start;
      min-width: 36px;
      span{
          font-size: 12px;
          font-weight: 300;
          }
    }
    &__item{
      font-style: normal;
      font-weight: 300;
      font-size: 12px;
      padding: 10px;
      border-bottom: 1px solid #EBEFF2;
      text-align: start;
      min-width: 36px;
      span{
        font-size: 12px;
        font-weight: 300;
          }
    }
  }
}
.contentFilterTable{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 97%;
    .contentFilterTabletitle{
      width: 100%;
    }
}
</style>
