<template>
  <svg width="46"
       height="46"
       viewBox="0 0 46 46"
       fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <path d="M23.0014 44.7084C34.9907 44.7084 44.7099 34.9892 44.7099 22.9999C44.7099 11.0107 34.9907 1.2915 23.0014 1.2915C11.0122 1.2915 1.29297 11.0107 1.29297 22.9999C1.29297 34.9892 11.0122 44.7084 23.0014 44.7084Z"
          fill="black"/>
    <path d="M23 46C10.3169 46 0 35.6831 0 23C0 10.3169 10.3169 0 23 0C35.6831 0 46 10.3169 46 23C46 35.6831 35.6831 46 23 46ZM23 2.58311C11.7428 2.58311 2.58311 11.7428 2.58311 23C2.58311 34.2572 11.7428 43.4169 23 43.4169C34.2572 43.4169 43.4169 34.2572 43.4169 23C43.4169 11.7428 34.2572 2.58311 23 2.58311Z"
          fill="#3AF69B"/>
    <circle cx="23"
            cy="23"
            r="11"
            fill="#3AF69B"/>
    <path d="M17.175 24.7016C16.5302 24.7016 16 24.1561 16 23.4926C16 22.8291 16.5302 22.2836 17.175 22.2836C19.8117 22.2836 21.9468 20.072 21.9468 17.3738V16.209C21.9468 15.5455 22.477 15 23.1219 15C23.7667 15 24.2969 15.5455 24.2969 16.209V17.3738C24.2969 21.4137 21.1014 24.7016 17.175 24.7016Z"
          fill="black"/>
    <path d="M29.0828 24.7016C25.1565 24.7016 21.9609 21.4137 21.9609 17.3738V16.209C21.9609 15.5455 22.4911 15 23.136 15C23.7808 15 24.311 15.5455 24.311 16.209V17.3738C24.311 20.0867 26.4605 22.2836 29.0828 22.2836C29.7276 22.2836 30.2578 22.8291 30.2578 23.4926C30.2578 24.1561 29.7276 24.7016 29.0828 24.7016Z"
          fill="black"/>
    <path d="M23.1219 32C22.477 32 21.9468 31.4545 21.9468 30.791V29.6262C21.9468 26.9133 19.7974 24.7164 17.175 24.7164C16.5302 24.7164 16 24.1708 16 23.5074C16 22.8439 16.5302 22.2983 17.175 22.2983C21.1014 22.2983 24.2969 25.5863 24.2969 29.6262V30.791C24.2969 31.4545 23.7667 32 23.1219 32Z"
          fill="black"/>
    <path d="M23.1223 32C22.4775 32 21.9473 31.4545 21.9473 30.791V29.6262C21.9473 25.5863 25.1428 22.2983 29.0691 22.2983C29.714 22.2983 30.2442 22.8439 30.2442 23.5074C30.2442 24.1708 29.714 24.7164 29.0691 24.7164C26.4325 24.7164 24.2973 26.928 24.2973 29.6262V30.791C24.2973 31.4545 23.7671 32 23.1223 32Z"
          fill="black"/>
  </svg>

</template>
<script>
export default {
  name: 'SingleSpartkIcon',
  props: {
    color: {
      type: String,
      default: '#000000'
    }
  }
}
</script>

<style>

</style>
