import { EventBus } from '@/eventBus'
import OnboardingUseCases from '@/modules/SignInOnboarding/application/OnboardingUseCases'
import { onboardingStatusEnum } from '@/modules/SignInOnboarding/domain/OnboardingController'
import OnboardingController from '@/modules/SignInOnboarding/infrastructure/controllers/Onboarding.controller'
import SmartOMeterUseCases from '@/modules/ai/application/SmartOMeterUseCases'
import { SmartOMeterFeatures } from '@/modules/ai/domain/SmartOMeter/SmartOMeterFeatures'
import SmartOMeterController from '@/modules/ai/infrastructure/controllers/SmartOMeter.controller'
import BikyConfigController from '@/modules/bky/infrastructure/controllers/BikyConfig.controller'
import BikyStoreController from '@/modules/bky/infrastructure/persistence/BikyStore.controller'
import { ConfigurationTabsEnum } from '@/modules/configuration/application/enums/ConfigurationTabsEnum'
import FeesServices from '@/modules/configuration/application/services/FeesServices'
import Colors from '@/shared/domain/colors/Color'
import { FloatingWindowType } from '@/shared/infrastructure/enums/Layout/FloatingWindowType'
import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import KeybeAvatar from '@/shared/infrastructure/ui/KeybeAvatar.vue'
import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'
import { mapActions, mapGetters, mapState } from 'vuex'
export default {
  components: {
    KeybeAvatar,
    KeybeIcon
  },
  props: {
    selectedTab: {
      type: String,
      required: true
    },
    bikyConfig: {
      type: Object,
      required: false,
      default: null
    }
  },
  data () {
    return {
      ConfigurationTabsEnum,
      isFeesAdmin: false,
      isAdmin: false,
      showOnboardingProgress: false,
      // bikyConfig: {},
      extradataAvailable: [
        // 'julian.munoz@keybe.ai',
        // 'sergio.pulgarin@keybe.ai',
        'hector.diaz@keybe.ai',
        'arianna@keybe.ai',
        'david.zambrano@keybe.ai',
        'diego.munoz@keybe.ai'
        // 'miguel.gutierrez@keybe.ai'
      ],
      Colors,
      onboardingProgress: 0,
      onboardingController: new OnboardingController(),
      smartOMeterController: new SmartOMeterController(),
      bikyConfigController: new BikyConfigController()
    }
  },
  computed: {
    ...mapState('UserStore', ['user', 'token', 'isUserReadonly']),
    ...mapState('AppStore', ['selectedApp']),
    ...mapGetters('UserStore', ['isSuperAdmin', 'isAdminOrAbove']),
    ...mapGetters('AppStore', ['isKeybeApp', 'isCDPApp']),
    ...mapState('BillingStore', ['tier']),
    ...mapState('ChatConfigStore', ['config']),
    onboardingThirdStep (): boolean {
      return this.onboardingStep === 3
    },
    bkyAvatar (): string {
      return BikyStoreController.getBikyImage()
    },
    appName (): string {
      return this.selectedApp?.name || ''
    },
    userName (): string {
      if (this.user?.lastName) {
        return `${this.user?.name} ${this.user?.lastName}`
      }

      return this.user?.name
    },
    userImg (): string {
      return this.user?.photo || ''
    },
    userType (): string {
      return this.user?.type || ''
    },
    itemsGroups (): any[] {
      if (this.isTrainer && this.isAllowedToUseBiky) {
        return [[
          {
            name: ConfigurationTabsEnum.BIKY,
            label: this.bikyConfig?.name || this.$t('bky')
          }
        ]]
      }
      const groups: {[key: string]: any}[][] = [
        [
          {
            name: ConfigurationTabsEnum.APP_INFO,
            label: this.$t('fashion_brand'),
            icon: KeybeIconType.BUSINESS_DETAILS,
            color: Colors.white,
            class: 'purple'
          },
          {
            name: ConfigurationTabsEnum.TEAM,
            label: this.$t('team'),
            icon: KeybeIconType.TEAM,
            color: Colors.white,
            class: 'purple'
          }
        ],
        [
          {
            name: ConfigurationTabsEnum.NEW_CHANNELS,
            label: this.$t(`configuration-${ConfigurationTabsEnum.CONNECT_CHANNELS}`),
            icon: KeybeIconType.ROCKET,
            class: 'verde-bird',
            color: Colors.white
          },
          {
            name: ConfigurationTabsEnum.SPACES,
            label: this.$t(`configuration-${ConfigurationTabsEnum.INBOX}`),
            icon: KeybeIconType.INBOX,
            color: Colors.white,
            class: 'blue-night'
          },
          {
            name: ConfigurationTabsEnum.TAGS,
            label: this.$t(`configuration-${ConfigurationTabsEnum.TAGS}`),
            icon: KeybeIconType.TAGS,
            class: 'pink',
            color: Colors.white
          },
          {
            name: ConfigurationTabsEnum.QUICK_RESPONSES,
            label: this.$t(`configuration-${ConfigurationTabsEnum.QUICK_RESPONSES}`),
            icon: KeybeIconType.QUICK_RESPONSES,
            class: 'blue',
            color: Colors.white
          },
          {
            name: ConfigurationTabsEnum.AUTOMATIC_CLOSING,
            label: this.$t(`configuration-${ConfigurationTabsEnum.AUTOMATIC_CLOSING}`),
            icon: KeybeIconType.FLAG,
            class: 'pink',
            color: Colors.white
          },
          {
            name: ConfigurationTabsEnum.AUTOASSIGN,
            label: this.$t(`configuration-${ConfigurationTabsEnum.AUTOASSIGN}`),
            icon: KeybeIconType.BALANCE,
            class: 'pink',
            color: Colors.white
          },
          {
            name: ConfigurationTabsEnum.SCHEDULE,
            label: this.$t(`configuration-${ConfigurationTabsEnum.SCHEDULE}`),
            icon: KeybeIconType.SCHEDULE,
            class: 'green2',
            color: Colors.white
          }
        ],
        [
          // {
          //   name: ConfigurationTabsEnum.WELCOME_MESSAGE,
          //   label: this.$t(`configuration-${ConfigurationTabsEnum.WELCOME_MESSAGE}`),
          //   icon: KeybeIconType.HAND,
          //   class: 'blue',
          //   color: Colors.white
          // },
          // {
          //   name: ConfigurationTabsEnum.SMARTOMETER,
          //   label: this.$t('smartOMeterTitle'),
          //   icon: KeybeIconType.THERMOMETER,
          //   color: Colors.white,
          //   class: 'grey',
          //   customStyle: 'width: 8px'
          // },
          {
            name: ConfigurationTabsEnum.KEYBE_AI,
            label: ConfigurationTabsEnum.KEYBE_AI,
            icon: KeybeIconType.CHAT_OUTLINED,
            class: 'blue-night',
            color: Colors.white
          }
        ],
        [
          {
            name: ConfigurationTabsEnum.CATALOG,
            label: this.$t(`configuration-${ConfigurationTabsEnum.CATALOG}`),
            icon: KeybeIconType.SHOPPING_CART,
            class: 'grey',
            color: Colors.white
          },
          {
            name: ConfigurationTabsEnum.TEMPLATES,
            class: 'blue-night',
            label: this.$t('templates'),
            icon: KeybeIconType.CHAT_OUTLINED
          },
          {
            name: ConfigurationTabsEnum.WEB_WIDGET,
            label: this.$t(`configuration-${ConfigurationTabsEnum.WEB_WIDGET}`),
            icon: KeybeIconType.CHAT_OUTLINED,
            class: 'verde-bird',
            color: Colors.white
          }
          // {
          //   name: ConfigurationTabsEnum.INBOX,
          //   label: this.$t(`configuration-${ConfigurationTabsEnum.INBOX}`),
          //   icon: KeybeIconType.INBOX,
          //   color: Colors.white,
          //   class: 'blue-night'
          // }
          // {
          //   name: ConfigurationTabsEnum.SPACES,
          //   label: this.$t(`configuration-${ConfigurationTabsEnum.SPACES}`),
          //   icon: KeybeIconType.INBOX,
          //   color: Colors.white,
          //   class: 'blue-night'
          // }
        ],
        [
          {
            name: ConfigurationTabsEnum.STATUSES,
            label: this.$t(`configuration-${ConfigurationTabsEnum.STATUSES}`),
            icon: KeybeIconType.LINKED_LIST,
            class: 'verde-bird',
            color: Colors.white
          },
          {
            name: ConfigurationTabsEnum.CDP_FIELDS,
            class: 'dark-green',
            label: this.$t(`configuration-${ConfigurationTabsEnum.CDP_FIELDS}`),
            icon: KeybeIconType.APP_FIELDS_CONFIG,
            color: Colors.white
          },
          {
            name: ConfigurationTabsEnum.FUNNEL_FIELDS,
            class: 'dark-green',
            label: this.$t(`configuration-${ConfigurationTabsEnum.FUNNEL_FIELDS}`),
            icon: KeybeIconType.APP_FIELDS_CONFIG,
            color: Colors.white
          },
          {
            name: ConfigurationTabsEnum.PRIVACY_SECURITY,
            class: 'dark-green',
            label: this.$t(`configuration-${ConfigurationTabsEnum.PRIVACY_SECURITY}`),
            icon: KeybeIconType.APP_PRIVACY_SECURITY,
            color: Colors.white
          }
        ],
        [
          {
            name: ConfigurationTabsEnum.API_KEYS,
            label: this.$t(`configuration-${ConfigurationTabsEnum.API_KEYS}`),
            icon: KeybeIconType.KEY,
            class: 'dark-green',
            color: Colors.white
          }
        ]
      ]

      if (this.user?.email && this.extradataAvailable.includes(this.user?.email)) {
        // @ts-ignore
        groups[3].push({
          name: ConfigurationTabsEnum.EXTRADATA,
          class: 'dark-green',
          label: this.$t(`configuration-${ConfigurationTabsEnum.EXTRADATA}`),
          icon: KeybeIconType.EXTRADATA,
          color: Colors.white
        })
      }
      if (this.isSuperAdmin || this.isAdmin) {
        const object =
          [
            {
              name: ConfigurationTabsEnum.DOMAINS,
              label: this.$t(`configuration-${ConfigurationTabsEnum.DOMAINS}`),
              icon: KeybeIconType.LOGO,
              color: Colors.black,
              class: 'green'
            }
          ]
        groups.push(object)

        const object2 = [
          {
            name: ConfigurationTabsEnum.ACTIVITY_LOGS,
            label: this.$t(`configuration-${ConfigurationTabsEnum.ACTIVITY_LOGS}`),
            icon: KeybeIconType.LOGO,
            color: Colors.white,
            class: 'purple'
          }
        ]

        groups.push(object2)
      }

      if (this.isSuperAdmin || this.isAdmin) {
        if (this.isKeybeApp) {
          let object = []
          if (this.isSuperAdmin) {
            object = [
              {
                name: ConfigurationTabsEnum.BRANDS,
                label: ConfigurationTabsEnum.BRANDS,
                icon: KeybeIconType.LOGO,
                color: Colors.black,
                class: 'green'
              },
              {
                name: ConfigurationTabsEnum.OLD_CHANNELS,
                label: ConfigurationTabsEnum.OLD_CHANNELS,
                icon: KeybeIconType.LOGO,
                color: Colors.black,
                class: 'green'
              }
            ]
          }
          if (this.isFeesAdmin) {
            object.push(
              {
                name: ConfigurationTabsEnum.COMMISSIONS,
                label: this.$t(`configuration-${ConfigurationTabsEnum.COMMISSIONS}`),
                icon: KeybeIconType.LOGO,
                class: 'green',
                color: Colors.black
              }
            )
          }
          groups.push(object)
        }
      }

      // if tier doesnt exist remove smartometer option, else remove keybe ai option
      if (this.tier) {
        const index = groups[2].findIndex((item) => item.name === ConfigurationTabsEnum.KEYBE_AI)
        groups[2].splice(index, 1)
      }

      if (!this.config?.inbox?.useInbox && !this.isSuperAdmin && !this.isAdmin) {
        const index = groups[1].findIndex((tab) => tab.name === ConfigurationTabsEnum.INBOX)
        groups[1].splice(index, 1)
      }

      if (this.config?.inbox?.useInbox) {
        const index2 = groups[1].findIndex((tab) => tab.name === ConfigurationTabsEnum.AUTOMATIC_CLOSING)
        groups[1].splice(index2, 1)
        const index3 = groups[1].findIndex((tab) => tab.name === ConfigurationTabsEnum.AUTOASSIGN)
        groups[1].splice(index3, 1)
        const index4 = groups[1].findIndex((tab) => tab.name === ConfigurationTabsEnum.SCHEDULE)
        groups[1].splice(index4, 1)
      }

      if (this.isAllowedToUseBiky) {
        // @ts-ignore
        groups[2].push({
          name: ConfigurationTabsEnum.BIKY,
          label: this.bikyConfig?.name || this.$t('bky')
        })
      }

      return groups.filter((group: {[key: string]: any}[][]) => group.length > 0)
    },
    isAllowedToUseBiky (): boolean {
      const hasPermissionByTier = SmartOMeterUseCases.hasPermission(this.smartOMeterController, SmartOMeterFeatures.BIKY_SIDEBAR_ITEM, this.tier)

      return hasPermissionByTier || this.isSuperAdmin || this.bikyConfig?.active
    },
    hasBiky (): boolean {
      return SmartOMeterUseCases.hasPermission(this.smartOMeterController, SmartOMeterFeatures.BIKY_CONFIG, this.tier) || BikyStoreController.isBikyActive()
    }
  },
  methods: {
    ...mapActions('FloatingWindowsStore', [
      'setMaximazedWindow'
    ]),
    /* async getBikyConfig () {
      this.bikyConfig = BikyStoreController.getConfig()
    }, */
    async checkOnboardingStatus (): Promise<void> {
      const status: string = OnboardingUseCases.checkOnboardingStatus(this.onboardingController)
      this.showOnboardingProgress = !(status === onboardingStatusEnum.FINISHED || status === onboardingStatusEnum.NONE)
    },
    async getOnboardingStep (): Promise<void> {
      this.onboardingStep = await OnboardingUseCases.getOnboardingStep(this.onboardingController)
    },
    isSelectedTab (tabName: string): boolean {
      return this.selectedTab === tabName
    },
    async getFeesUser () {
      const feesData = await FeesServices.isCommissionAgent()
      if (feesData?.status) {
        const user = feesData.data
        this.isFeesAdmin = user.superadmin
      }
    },
    getColor (tab: any): string {
      if (this.isSelectedTab(tab.name)) {
        return this.$vuetify.theme.dark ? Colors.white : Colors.black
      }

      return tab.color
    },
    isBikyTab (tab: any): boolean {
      return tab.name === ConfigurationTabsEnum.BIKY
    },
    userAdmin (): void {
      const appUUID = this.selectedApp?.uuid
      this.isAdmin = this.user?.userApps?.some((app: {[key: string]: any}) => app.role === 'admin' && app.appUUID === appUUID) || false
    },
    setOnboardingProgress (progress: number): void {
      this.onboardingProgress = Math.round(progress)
    },
    getOnboardingProgress (): void {
      const response = OnboardingUseCases.getOnboardingProgress(this.onboardingController)
      this.onboardingProgress = Math.round(response)
    },
    checkTabSelect (tab: string): void {
      if (tab === ConfigurationTabsEnum.BIKY) {
        if (this.hasBiky && !this.isUserReadonly) {
          const url: string = `${process.env.VUE_APP_BIKY_URL}?token=${this.token}&app=${this.selectedApp?.uuid}`
          window.open(url, '_blank')
        } else {
          if (this.isUserReadonly) return
          this.setMaximazedWindow(FloatingWindowType.BIKY_CONFIG)
        }
        return
      } else if (tab === ConfigurationTabsEnum.CATALOG) {
        this.setMaximazedWindow(FloatingWindowType.CATALOG)
        return
      }
      this.$emit('selectTab', tab)
    }
  },
  mounted () {
    this.getFeesUser()
    this.userAdmin()
    this.getOnboardingStep()
    this.getOnboardingProgress()
    this.checkOnboardingStatus()
    // this.getBikyConfig()
    EventBus.$on('setOnboardingProgress--onboarding', this.setOnboardingProgress.bind(this))
  },
  beforeDestroy () {
    EventBus.$off('setOnboardingProgress--onboarding', this.setOnboardingProgress.bind(this))
  },
  watch: {
    selectedTab (newValue: string) {
      if (newValue === ConfigurationTabsEnum.BIKY) {
        this.checkTabSelect(ConfigurationTabsEnum.BIKY)
      }
    }
  }
}
