











































import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import { EventBus } from '@/eventBus'
import { webAgentScript } from '@/components/conversations/components/channels/templateScripts'
import { mapState } from 'vuex'
import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'
import KBExpansionPanel from '@/shared/infrastructure/ui/menus/KBExpansionPanel.vue'

export default {
  name: 'AutoConnectWebConfig',
  components: {
    KeybeIcon,
    KBExpansionPanel
  },
  data () {
    return {
      KeybeIconType
    }
  },
  methods: {
    copyScript () {
      navigator.clipboard.writeText(this.code).then(() => {
        EventBus.$emit('toast', 'success', this.$t('scriptCopied'))
      }).catch(() => {
        EventBus.$emit('toast', 'error', this.$t('errorCopying'))
      })
    }
  },
  computed: {
    ...mapState('AppStore', ['selectedApp']),
    code () {
      const key = this.selectedApp.key
      return webAgentScript(key)
    }
  }
}
