import {
  IQuickResponsesController,
  dataToCreateQuickResponse,
  dataToGetQuickResponses,
  quickResponseCreator,
  quickResponsesGetter,
  dataToDeleteQuickResponse
} from '../domain/QuickResponsesController'

export default class QuickResponsesUseCases {
  static abort (controller: IQuickResponsesController) : void {
    controller.abort()
  }

  static getQuickResponses (controller: IQuickResponsesController, data: dataToGetQuickResponses) : Promise<quickResponsesGetter> {
    return controller.getQuickResponses(data)
  }

  static createQuickResponse (controller: IQuickResponsesController, data: dataToCreateQuickResponse) : Promise<quickResponseCreator> {
    return controller.createQuickResponse(data)
  }

  static deleteQuickResponse (controller: IQuickResponsesController, data: dataToDeleteQuickResponse) : Promise<quickResponseCreator> {
    return controller.deleteQuickResponse(data)
  }
}
