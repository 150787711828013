import { i18n } from '@/plugins/i18n/i18n'
export default class operations {
  static areEqual (a, b): boolean {
    if (a === b) return true
    else return false
  }

  static isJson (str: string | JSON): boolean {
    try {
      JSON.parse(String(str))
    } catch (e) {
      return false
    }

    return true
  }

  static validateEmail (email: string) {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
  }

  static checkTranslation (text) {
    if (text && text[0] === '$' && text[text.length - 1] === '$') {
      return i18n.t((text))
    } else if (text?.includes('$')) {
      const textArray = text?.split(' ')
      const newTextArray = []
      textArray.forEach(t => {
        const indexes = []
        for (let i = 0; i < t.length; i++) {
          if (t[i] === '$') indexes.push(i)
        }
        const word = t.substring(indexes[0] + 1, indexes[1])
        const newWord = t.replace(word, i18n.t((word))).replaceAll('$', '')
        newTextArray.push(newWord)
      })
      return newTextArray?.join(' ')
    } else return text
  }

  static transformObject (obj, parentKey = '') {
    const result = {}

    for (const key in obj) {
      const newKey = parentKey ? `${parentKey}[${key}]` : key

      if (Array.isArray(obj[key])) {
        if (obj[key].length === 0 || typeof obj[key][0] !== 'object') {
          result[`${newKey}[]`] = obj[key]
        } else {
          if (obj[key].length === 1 && obj[key][0] === null) {
            result[`${newKey}[]`] = obj[key]
          } else {
            for (let i = 0; i < obj[key].length; i++) {
              const nestedResults = operations.transformObject(obj[key][i], `${newKey}[${i}]`)
              for (const nestedKey in nestedResults) {
                result[nestedKey] = nestedResults[nestedKey]
              }
            }
          }
        }
      } else if (typeof obj[key] === 'object') {
        const nestedResults = operations.transformObject(obj[key], newKey)
        for (const nestedKey in nestedResults) {
          result[nestedKey] = nestedResults[nestedKey]
        }
      } else {
        result[newKey] = obj[key]
      }
    }

    return result
  }
}
