import BillingService from '@/components/configuration/Services/BillingService'
import BillingPlansCards from '@/modules/consumptions/infrastructure/ui/components/BillingPlansCards/BillingPlansCards.vue'
import Loader from '@/shared/infrastructure/ui/Loader.vue'
import { mapGetters, mapState } from 'vuex'
import BikyPlanSelector from '../BikyPlanSelector/BikyPlanSelector.vue'
import PlanHeader from '../PlanHeader/PlanHeader.vue'
import PlanSummary from '../PlanSummary/PlanSummary.vue'
import PlanUseCases from '@/modules/consumptions/application/PlanUseCases'
import { dataToCreditCardsMethod } from '@/modules/consumptions/domain/PlanController'
import PaymentController from '../../../controllers/Payment.controller'
import BillingPlansBikyCards from '../BillingPlansBikyCards/BillingPlansBikyCards.vue'
import BillingPlansTotalCards from '../BillingPlansTotalCards/BillingPlansTotalCards.vue'
import { EventBus } from '@/eventBus'
import KeybeButton from '@/modules/DesignSystem/infrastructure/components/KeybeButton/KeybeButton.vue'
import Colors from '@/utils/Colors'
import BillingUseCase from '@/modules/consumptions/application/BillingUseCase'
import BillingController from '../../../controllers/Billing.controller'

export default {
  name: 'BillingPlansView',
  components: {
    KeybeButton,
    BillingPlansBikyCards,
    Loader,
    BillingPlansCards,
    BillingPlansTotalCards,
    PlanHeader,
    BikyPlanSelector,
    PlanSummary
  },
  props: [],
  data () {
    return {
      Colors,
      dontActivePlan: true,
      BikyColor: null,
      totalAmount: 0,
      keybePlanToActivate: {},
      bikyPlanToActivate: {},
      mainView: true,
      summaryView: false,
      plansView: false,
      loading: true,
      tier: null,
      keybePlan: null,
      tierActive: '',
      tierBooking: '',
      dataCards: [],
      billingController: new BillingController(),
      planController: new PaymentController()
    }
  },

  watch: {
  },
  computed: {
    ...mapGetters('AppStore', ['getSelectedApp']),
    ...mapState('BillingStore', ['appConfig']),
    ...mapState('UserStore', ['token']),

    appUUID () {
      return this.getSelectedApp.uuid ? this.getSelectedApp.uuid : null
    }
  },
  methods: {
    calculateSize (fullscreen = false) {
      const clientHeight = document.documentElement.clientHeight
      const clientWidth = document.documentElement.clientWidth

      if (fullscreen) {
        document.documentElement.style.setProperty(
          '--floating-screen-height',
          `${clientHeight - 30}px`
        )
        document.documentElement.style.setProperty(
          '--floating-screen-width',
          `${clientWidth - 50}px`
        )

        // Verifica si el elemento existe antes de acceder a sus propiedades
        const floatingWindow = document.getElementById('KeybeFloatingBox')
        if (floatingWindow) {
          floatingWindow.style.top = '0px'
          floatingWindow.style.left = '0px'
        }

        EventBus.$emit('FloatingWindow-resize')
        return
      }

      this.totalHeight = clientHeight * 0.8097
      this.heightWithoutHeader = clientHeight * 0.8097 - 30
      document.documentElement.style.setProperty(
        '--floating-screen-height',
        `${this.heightWithoutHeader}px`
      )

      this.width = clientWidth * 0.8
      document.documentElement.style.setProperty(
        '--floating-screen-width',
        `${this.width}px`
      )

      EventBus.$emit('FloatingWindow-resize')
    },

    goToMain () {
      this.plansView = false
      this.mainView = true
      this.summaryView = false
    },
    changePlans () {
      this.plansView = true
      this.mainView = false
      this.summaryView = false
    },
    selectTier ({ tier, plan, color }) {
      this.tier = tier
      this.keybePlan = plan
      this.BikyColor = color
      this.mainView = false
      this.plansView = false
      this.summaryView = true
    },
    setOnDemand  () {
      this.loading = false
      this.changePlans()
    },

    setActivePlan (code: string) {
      code = code.toLowerCase()
      this.keybePlanToActivate = this.dataCards.find(plan => plan.code === code) || {}
      this.loading = false
    },

    async getActivePlan () {
      try {
        const data: dataToCreditCardsMethod = {
          token: this.token,
          appUUID: this.appUUID
        }
        const response = await PlanUseCases.getActiveBikyPlan(this.planController, data)
        if (response.status) {
          if (response?.data) {
            if (response.data.active === null || response.data.active === undefined) {
              this.setOnDemand()
            }
            this.dontActivePlan = false
            const { active, booking } = response.data
            this.setActivePlan(active.plan)
            this.loading = true
            this.tierActive = active.tierLabel ? active.tierLabel.toLowerCase() : ''
            this.tierBooking = booking.tierLabel ? booking.tierLabel.toLowerCase() : ''
            if (this.tierActive) {
              this.bikyPlanToActivate = {
                code: this.tierActive,
                cost: (active.suggestedPriceAfterTax - active.basePrice).toFixed(1),
                color: 'cardEssential'
              }
              this.totalAmount = active.totalPrice
            }
          }
          this.loading = false
        } else {
          this.setOnDemand()
        }
      } catch (error) {
        this.loading = false
      }
    },
    async getDailyContactsPlans (tier: string = '4'): Promise<void> {
      const response = await BillingUseCase.getNewDailyContactsPlans(this.billingController, {
        token: this.token,
        appId: this.getSelectedApp?.uuid,
        tier
      })
      this.dataCards = response.data.map((x) => {
        if (x.dailyContactName.toLowerCase()) {
          let subTitle = ''
          const translate = this.$t(`${x.dailyContactName.toLowerCase()}.subtitle`)
          if (x.dailyContactName.toLowerCase() === 'on_demand') {
            subTitle = `${translate} ${x.productAmountUSD} + Carrier`
          } else {
            subTitle = `${translate} ${x.productAmountUSD} ${this.$t('additional')}`
          }
          return {
            ...x,
            title: this.$t(`${x.dailyContactName.toLowerCase().replaceAll(' ', '_')}.title`),
            subtitle: subTitle,
            active: false,
            code: x.dailyContactName.toLowerCase()
          }
        }
      })
      this.getActivePlan()
    },
    async getPlan () {
      try {
        const response = await BillingService.getPlanNew()
        if (response.status) {
          if (response?.data) {
            this.dataCards = response.data.map((x) => {
              let subTitle = ''
              const translate = this.$t(`${x.title}.subtitle`)
              if (x.title === 'on_demand') {
                subTitle = `${translate} ${x.unitPrice} + Carrier`
              } else {
                subTitle = `${translate} ${x.unitPrice} ${this.$t('additional')}`
              }

              return {
                ...x,
                title: this.$t(`${x.title}.title`),
                subtitle: subTitle,
                active: false,
                code: x.title
              }
            })
            this.getActivePlan()
          }
        }
      } catch (error) {
        this.loading = false
      }
    }
  },
  mounted () {
    // this.getPlan()
    this.getDailyContactsPlans()
    this.calculateSize(true)
  },
  beforeDestroy () {
    window.removeEventListener('resize', () => this.calculateSize())
  }
}
