export const CampaingsStore = {
  namespaced: true,
  state: {
    sendNowSuccess: false,
    outboundStatus: null,
    openEmojiPicker: false,
    outboundName: null,
    summaryData: null,
    summaryMenu: false,
    changeParamsVisible: false,
    currentSelectedTab: 'allOutbound',
    contextMenuCampaings: {},
    templates: [],
    currentTemplate: null,
    messagePreview: null,
    messagePreviewCrop: null,
    lengthContent: false,
    templateParams: [],
    templateMediaUrl: null,
    buttonsParams: [],
    buttonsLabels: [],
    buttonsParamsLinks: [],
    message: '',
    programmingDate: {
      hours: null,
      day: null,
      month: null,
      year: null
    },
    insertCustomField: null,
    insertCustomFieldHtml: null,
    insertCustomFielEmoji: null,
    porcentaje: null
  },
  mutations: {
    SET_CONTECT_MENU_CAMPAINGS (state, campaings) {
      state.contextMenuCampaings = campaings
    },
    SET_TEMPLATES (state, templates) {
      state.templates = []
      state.templates = templates
    },
    SET_CURRENT_TEMPLATE (state, currentTemplate) {
      state.currentTemplate = []
      state.currentTemplate = currentTemplate
    },
    SET_CURRENT_LINK (state, buttonsParamsLinks) {
      state.buttonsParamsLinks = []
      state.buttonsParamsLinks = buttonsParamsLinks
    },
    SET_EDITING_OBJECT (state, payload) {
      state[payload.key] = payload.value
    }
  },
  actions: {
    setContextMenuCampaings (context, campaings) {
      context.commit('SET_CONTECT_MENU_CAMPAINGS', campaings)
    },
    setTemplates ({ commit }, templates) {
      commit('SET_TEMPLATES', templates)
    },
    setCurrentTemplate ({ commit }, currentTemplate) {
      commit('SET_CURRENT_TEMPLATE', currentTemplate)
    },
    setCurrentLink ({ commit }, buttonsParamsLinks) {
      commit('SET_CURRENT_LINK', buttonsParamsLinks)
    }
  },
  getters: {
    getTemplates (state) {
      return state.templates
    }
  }
}
