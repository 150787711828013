import store from '@/store'
import { PlansEnum } from '@/components/configuration/PlansEnum'
import { EventBus } from '@/eventBus'
import BillingService from '@/components/configuration/Services/BillingService'
import { GENERAL_BALANCE_LIMIT, WHITE_LIST_ALLOWED_PHONE } from '@/store/modules/BillingStore'

export default class BillingValidations {
  static isWhiteListNumbers (guest: string) {
    if (WHITE_LIST_ALLOWED_PHONE.includes(guest)) return true
    return this.hasBalance()
  }

  static hasBalanceEssential () {
    const balance = store.getters['BillingStore/getBalance']
    if (balance === null) return false
    return (this.isEssential() && balance > 0)
  }

  static hasCredit (mode: boolean = false) {
    const balance = store.getters['BillingStore/getHasCredit']
    return balance
  }

  static hasBalance (mode: boolean = false) {
    const balance = store.getters['BillingStore/getBalance']
    // const validation = ((this.isEssential() && balance > 0) || this.isProfessional() || this.isPremium() || this.isOld())
    // console.table({ balance, GENERAL_BALANCE_LIMIT, result: balance > GENERAL_BALANCE_LIMIT })
    const validation = (balance > GENERAL_BALANCE_LIMIT) || this.hasCredit()
    if (!validation && !mode) EventBus.$emit('Consumptions-no-balance', true)
    return validation
  }

  static hasBalanceProfessional () {
    const balance = store.getters['BillingStore/getBalance']
    if (balance === null) return true
    return (this.isProfessional() && balance !== 0)
  }

  static isProfessional () {
    const plan = store.getters['BillingStore/getNamePlan']
    if (!plan) return true
    return plan.toLocaleLowerCase().includes(PlansEnum.PROFESSIONAL.toLocaleLowerCase())
  }

  static isOld () {
    const plan = store.getters['BillingStore/getNamePlan']
    if (!plan) return true
    return plan.toLocaleLowerCase().includes(PlansEnum.OLD.toLocaleLowerCase())
  }

  static isEssential () {
    const plan = store.getters['BillingStore/getNamePlan']
    if (!plan) return true
    return plan.toLocaleLowerCase().includes(PlansEnum.ESSENTIAL.toLocaleLowerCase())
  }

  static isPremium () {
    const plan = store.getters['BillingStore/getNamePlan']
    if (!plan) return true
    return plan.toLowerCase().includes(PlansEnum.PREMIUM.toLocaleLowerCase())
  }

  // FIX: La funcionalidad quedaria mejor desde el back
  static async getProfessionalStablePlan () {
    const response = await BillingService.getPlansNew()
    if (!response || !response.status) return
    const plans = response.data
    const stable = plans.find((plan: any) => {
      const name = plan.name.toLowerCase()
      return name.includes(PlansEnum.STABLE_VERSION.toLocaleLowerCase()) && name.includes(PlansEnum.PROFESSIONAL.toLocaleLowerCase())
    })

    return stable
  }
}
