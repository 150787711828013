






















































import DragCardLostReason from './DragCardLostReason'
export default DragCardLostReason
