import { EventBus } from '@/eventBus'
import { BikyConfigUseCases } from '@/modules/bky/application/BikyConfigUseCases'
import { BikyConfig, BikyType, BikyTypes, defaultConfig } from '@/modules/bky/domain/BikyConfig'
import { BikyConfigTabs } from '@/modules/bky/domain/BikyConfigTabs'
import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import KBDropdown from '@/shared/infrastructure/ui/KBDropdown/KBDropdown.vue'
import { mapState } from 'vuex'
import BikyConfigController from '../../../controllers/BikyConfig.controller'
import BikyStoreController from '../../../persistence/BikyStore.controller'
import BikyConfigHeader from '../BikyConfigHeader/BikyConfigHeader.vue'
import BikyConfigPersonalitySummary from '../BikyConfigPersonalitySummary/BikyConfigPersonalitySummary.vue'

export default {
  name: 'BikyConfigPersonality',
  components: {
    BikyConfigHeader,
    BikyConfigPersonalitySummary,
    KBDropdown
  },
  data () {
    return {
      bikyConfigController: new BikyConfigController(),
      basicConfig: {
        ...defaultConfig
      },
      loading: false,
      BikyConfigTabs,
      personalities: [
        {
          label: this.$t('energeticSalesPerson'),
          value: BikyTypes.SALES,
          icon: KeybeIconType.MASKS,
          iconColor: 'black',
          iconBackgroundColor: 'rojoBird'
        },
        {
          label: this.$t('clientService'),
          value: BikyTypes.CLIENT_SUPPORT,
          icon: KeybeIconType.MASKS,
          iconColor: 'black',
          iconBackgroundColor: 'kbGreen'
        },
        {
          label: this.$t('generalAssistant'),
          value: BikyTypes.GENERAL_ASSISTANT,
          icon: KeybeIconType.MASKS,
          iconColor: 'black',
          iconBackgroundColor: 'yellow2'
        }
      ],
      selectedPersonality: {
        label: this.$t('clientService'),
        value: BikyTypes.CLIENT_SUPPORT,
        icon: KeybeIconType.MASKS,
        iconColor: 'black',
        iconBackgroundColor: 'kbGreen'
      }
    }
  },
  computed: {
    ...mapState('AppStore', ['selectedApp']),
    ...mapState('UserStore', ['token', 'user']),
    showSaveButton (): boolean {
      return this.selectedPersonality?.value !== this.basicConfig?.type
    }
  },
  methods: {
    async getConfig (): Promise<void> {
      this.$emit('start-loading')
      const config: BikyConfig | null = await BikyConfigUseCases.getBikyConfig(this.bikyConfigController, {
        appUUID: this.selectedApp?.uuid,
        token: this.token
      })
      this.$emit('stop-loading')

      if (!config) {
        this.basicConfig = {
          ...defaultConfig
        }
        return
      }

      this.basicConfig = {
        ...config
      }

      BikyStoreController.setConfig(config)

      const personality: BikyType = config?.type || BikyTypes.CLIENT_SUPPORT
      this.selectedPersonality = this.personalities.find((p) => p.value === personality)
    },
    async updateConfig (): Promise<void> {
      this.$emit('start-loading')
      const updated: boolean = await BikyConfigUseCases.updateBikyConfig(this.bikyConfigController, {
        token: this.token,
        appUUID: this.selectedApp?.uuid,
        updatedBy: this.user?.userId,
        type: this.selectedPersonality.value
      })
      this.$emit('stop-loading')

      if (!updated) {
        EventBus.$emit('toast', 'error', this.$t('errorUpdatingConfig'))
        return
      }

      this.basicConfig = {
        ...this.basicConfig,
        type: this.selectedPersonality.value
      }

      this.getConfig()
    }
  },
  watch: {
    showSaveButton (value) {
      EventBus.$emit('BikyConfig:show-save-button', value)
    }
  },
  mounted () {
    this.getConfig()
    // events
    EventBus.$on('BikyConfig:save', this.updateConfig)
  },
  beforeDestroy () {
    EventBus.$off('BikyConfig:save', this.updateConfig)
    BikyConfigUseCases.abortAll(this.bikyConfigController)
  }
}
