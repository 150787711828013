<template>
  <div class="metric-card"
       :class="$vuetify.theme.dark? 'metric-card__dark':''">
    <span class="metric-card__titleBold"
          :class="content ? '' : 'pb-4'">{{ title }}</span>
    <span v-if="!loading && subtitle"
          class="metric-card__subtitle">{{ subtitle }}</span>
    <LoadingNext v-if="loading"/>
    <span class="metric-card__contentValue my-3"
          v-if="!loading && !cash && !cashType  && !percentageType">{{ contentValue ? contentValue : 0  | numbers}}</span>
    <span class="metric-card__contentValue my-3"
          v-if="!loading && !cash && cashType">{{ contentValue ? `$${numbersFilter(contentValue)}` : `$${0}` }}</span>
    <span class="metric-card__contentValue my-3"
          v-if="!loading && !cash && percentageType">{{ contentValue ? `${numbersFilter(contentValue)}%` : `${0}%`}}</span>
    <div v-if="!loading && cash"
         class="metric-card__Cash my-3">
      <span class="metric-card__contentValue mr-3" >{{ contentValue.actualPeriodCount ? contentValue.actualPeriodCount : 0 }}</span>
      <span class="metric-card__contentValue mx-3" > =</span>

      <span class="metric-card__contentValueSmall " >USD </span>
      <span class="metric-card__contentValue  mx-1" >${{ contentValue.actualPeriodAmount ? contentValue.actualPeriodAmount : 0 }}</span>
      <div class="metric-card__Cash__Variation"
           v-if="contentValue.amountVariation">
        <span class="metric-card__contentValueSmall"
              v-if="contentValue.amountVariation !== 'noData'" >{{ contentValue.amountVariation }}</span>
      </div>
    </div>
    <span class="metric-card__contentTextLarge">{{ content }}</span>
    <div class="d-flex justify-center align-center mt-1"
         v-if="potencial">
      <span class="potenciaFont pr-2">{{ $t('earningPotential') }}</span>
      <div class="cardPotencial">
        <span class="potenciaFont potenciaFontDark px-2">{{ potencial ? `${potencial} aprox` : $t('loading')}} </span>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingNext from '@/modules/metrics/infrastructure/ui/components/generic/LoadingNext.vue'
import { CDPFieldsEnums } from '@/modules/CDP/shared/contacts/domain/enums/CDPFieldsEnums'
export default {
  name: 'MetricsCard',
  components: {
    LoadingNext
  },
  filters: {
    numbers: (value) => {
      if (typeof value !== 'number') return value
      const locale = Intl.NumberFormat('en-US')
      return locale.format(value)
    }
  },
  props: {
    cashType: {
      default: false,
      type: Boolean
    },
    percentageType: {
      default: false,
      type: Boolean
    },
    potencial: {
      type: [String, Number]
    },
    loading: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: 'title'
    },
    subtitle: {
      type: String,
      default: ''
    },
    content: {
      type: String,
      default: ''
    },
    contentValue: {
      type: [Number, String, Object],
      default: 0
    },
    cash: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      CDPFieldsEnums
    }
  },
  methods: {
    numbersFilter: (value) => {
      if (typeof value !== 'number') return value
      const locale = Intl.NumberFormat('en-US')
      return locale.format(value)
    }
  },
  watch: {
    contentValue () {
      if (this.contentValue) {

      }
    }
  }

}
</script>
<style lang='scss' scoped>
.metric-card {
  max-width: 527px;
  text-align: center;
  margin: 15px 15px 0px 0px;
  flex-grow: 6;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 10px;
  background-color: #d9d9d923;
  color: #000000;
  border: 1px solid #00000015;
  min-height: 209px;
  min-width: 45%;
  max-width: 49%;

  &__dark{
    background-color: black;
    span{
      color: white;
    }
  }

  &__titleBold {
    font-weight: 600;
    font-size: 15px;
    line-height: 21px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #000000;
    max-width: 200px;
    min-width: 200px;
    min-height: 30px;
  }
  &__subtitle {
    font-weight: 300;
    font-size: 12px;
    line-height: 17px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #000000;
    max-width: 200px;
  }
  .metric-card__Cash{
    display: flex;
    justify-content: center;
    align-items: center;

    &__Variation{
      justify-content: start;
      align-items: start;
      display: flex;
    }
  }

  &__contentValue {
    font-weight: 600;
    font-size: 28px;
    line-height: 100%;
    letter-spacing: -0.01em;
    color: #333333;
  }

  &__contentValueSmall {
    font-weight: 600;
    font-size: 12px;
    line-height: 100%;
    letter-spacing: -0.01em;
    color: #333333;
  }

  &__contentTextLarge{
    padding: 0 10px;
    font-weight: 300;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #000000;
    max-width: 350px;

  }

}
.cardPotencial{
  background-color: #3AF69B;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 33px;
  min-width: 144px;
}
.potenciaFont{
    font-weight: 600;
    font-size: 12px;
    line-height: 21px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #000000;
    max-width: 200px;
}
.potenciaFontDark{
  color: #000000 !important;
}

</style>
