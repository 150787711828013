import { ITaskController, dataToCreateTask, dataToDeleteTask, dataToGetTasks, dataToUpdateTask, taskGetter } from '@/modules/tasks/domain/TaskController'

export default class TaskUseCases {
  static async getTasks (controller: ITaskController, data: dataToGetTasks) : Promise<taskGetter> {
    return await controller.getTasks(data)
  }

  static async createTask (controller: ITaskController, data: dataToCreateTask) {
    return await controller.createTask(data)
  }

  static async updateTask (controller: ITaskController, data: dataToUpdateTask) {
    return await controller.updateTask(data)
  }

  static async deleteTask (controller: ITaskController, data: dataToDeleteTask) {
    return await controller.deleteTask(data)
  }
}
