<template>
  <svg width="15"
       height="15"
       viewBox="0 0 15 15"
       fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <rect x="5.29492"
          width="4.41176"
          height="4.41176"
          rx="1"
          fill="#D5D5D5"/>
    <rect width="4.41176"
          height="4.41176"
          rx="1"
          fill="#D5D5D5"/>
    <rect x="10.5879"
          width="4.41176"
          height="4.41176"
          rx="1"
          fill="#D5D5D5"/>
    <rect x="5.29492"
          y="5.29407"
          width="4.41176"
          height="4.41176"
          rx="1"
          fill="#D5D5D5"/>
    <rect y="5.29407"
          width="4.41176"
          height="4.41176"
          rx="1"
          fill="#D5D5D5"/>
    <rect x="10.5879"
          y="5.29407"
          width="4.41176"
          height="4.41176"
          rx="1"
          fill="#D5D5D5"/>
    <rect x="5.29492"
          y="10.5883"
          width="4.41176"
          height="4.41176"
          rx="1"
          fill="#D5D5D5"/>
    <rect y="10.5883"
          width="4.41176"
          height="4.41176"
          rx="1"
          fill="#D5D5D5"/>
    <rect x="10.5879"
          y="10.5883"
          width="4.41176"
          height="4.41176"
          rx="1"
          fill="#D5D5D5"/>
  </svg>

</template>

<script>
export default {
  name: 'GridIcon',
  props: {
    width: {
      type: Number,
      default: 16
    },
    height: {
      type: Number,
      default: 18
    },
    color: {
      type: String,
      default: '#B6BEC7'
    }
  }

}
</script>

<style>

</style>
