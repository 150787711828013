<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       fill="none"
       viewBox="0 0 10 10"><path :fill="color"
                                 d="M.981 2.16c.206-.24.378-.464.572-.666C2.586.407 3.853-.07 5.352.025 7.62.168 9.398 1.947 9.668 4.092c.116.927.146 1.866.21 2.8.029.408.076.817.055 1.224-.032.657-.4 1.06-1.045 1.191-1.1.224-2.2.46-3.305.647-.597.101-1.2.023-1.796-.107-.873-.19-1.75-.362-2.628-.524C.404 9.185.015 8.727.009 7.948-.006 5.498.003 3.048.005.599.004.165.176 0 .615 0c.152 0 .307-.003.455.023a.462.462 0 0 1 .402.458c.006.231-.118.39-.353.477-.054.02-.13.08-.132.122-.01.35-.006.7-.006 1.083V2.16Zm.935 1.572h6.146c-.45-1.373-1.58-2.237-3.084-2.232-1.51.004-2.66.907-3.062 2.232Zm-.152 1.13c-.098.316-.076.43.088.538 1.027.666 1.778 1.537 2.04 2.766.045.207.16.26.358.236 0-.982.001-1.952-.005-2.922 0-.049-.063-.13-.108-.14-.79-.165-1.583-.321-2.374-.48l.001.001Zm3.963 3.543c.225.023.342-.027.384-.268.21-1.187.957-2.004 1.904-2.667.285-.2.306-.213.234-.59-.013-.004-.027-.012-.038-.01-.775.152-1.548.308-2.324.453-.151.028-.161.11-.16.233.003.874.001 1.749.001 2.622v.227Z"/></svg>
</template>

<script>
export default {
  name: 'RolesIcon',
  props: {
    color: {
      type: String,
      default: '#fff'
    }
  }
}
</script>

<style>

</style>
