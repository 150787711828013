import { mapState } from 'vuex'
import link from '@/utils/links'
import operation from '@/utils/operations'
import { EventBus } from '@/eventBus'
import MicrositesService from '@/services/MicrositesService'
import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import { Socials } from '@/modules/microsites/infrastructure/utils/socials'
import ProductsController from '@/modules/catalog/infrastructure/controllers/Product.controller'
import ProductUseCases from '@/modules/catalog/application/ProductUseCases'
import MicrositeConfigController from '@/modules/microsites/infrastructure/controllers/MicrositeConfig.controller'
import MicrositeConfigUseCases from '@/modules/microsites/application/MicrositeConfigUseCases'
import CatalogUseCases from '@/modules/microsites/application/CatalogUseCases'
import CatalogController from '@/modules/microsites/infrastructure/controllers/Catalog.controller'
import Colors from '@/shared/domain/colors/Color'
import ConversationStoreController from '@/modules/conversations/infrastructure/persistence/controllers/ConversationStore.controller'
import ConversationsConfigController from '@/components/configuration/controllers/ConversationsConfigController'

import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'
import Microsite from '@/modules/microsites/infrastructure/ui/components/Microsite/Microsite.vue'
import MicrositeLinks from '@/modules/microsites/infrastructure/ui/components/MicrositeLinks.vue'
import MicrositesBackgroundManager from '@/modules/microsites/infrastructure/ui/components/MicrositeBackgroundManager.vue'
import KBButton from '@/shared/infrastructure/ui/buttons/KBButton.vue'
import KBText from '@/shared/infrastructure/ui/inputs/text/KBText.vue'
import KBTextArea from '@/shared/infrastructure/ui/inputs/text/KBTextarea.vue'
import { CDPFieldsEnums } from '@/modules/CDP/shared/contacts/domain/enums/CDPFieldsEnums'
export default {
  components: {
    Microsite,
    MicrositeLinks,
    KeybeIcon,
    MicrositesBackgroundManager,
    KBButton,
    KBText,
    KBTextArea
  },
  mounted () {
    this.init()
    // this.error = false
    // EventBus.$on('saveMSSocials', (newSocials) => {
    //   this.saveMicrosite()
    //   this.init()
    // })
  },
  beforeDestroy () {
    EventBus.$off('saveMSSocials')
    ProductUseCases.abortAll(this.productsController)
  },
  computed: {
    ...mapState('AppStore', ['selectedApp']),
    ...mapState('UserStore', ['token', 'isUserReadonly']),
    whatsAppIcon () {
      return (
        process.env.VUE_APP_STORAGE_URL +
        '/files/bluebird/conversation-module/whatsapp.svg'
      )
    },
    baseMicrositesUrl () {
      return process.env.VUE_APP_MICROSITES_BASE_URL
    },
    planetIcon () {
      return (
        process.env.VUE_APP_STORAGE_URL +
        '/files/bluebird/Microsites/planet-black.svg'
      )
    }
  },
  data () {
    return {
      CDPFieldsEnums,
      micrositeConfigController: new MicrositeConfigController(),
      catalogController: new CatalogController(),
      noConfig: false,
      productsController: new ProductsController(),
      pathKeybe: 'https://keybe.app/',
      msgSave: false,
      KeybeIconType,
      Colors,
      link,
      expanded: false,
      previewMainComponent: 'Microsite',
      colorPicker: false,
      error: false,
      loading: false,
      noCatalog: false,
      main: {
        mode: 'edit',
        original: {
          config: null,
          products: null,
          catalog: null,
          microsite: null,
          whatsapp: null
        },
        config: {
          name: '',
          photoProfile: '',
          greeting: 'Hello Stranger!!!',
          description: '',
          background: '#3AF69B',
          initialPrice: 0,
          finalPrice: 0,
          currency: 'COP', // COP, USD
          address: '',
          webSite: '',
          urlKeybe: '', // freshshake.keybe.ai
          whatsappKeybe: '',
          socialLinks: []
        },
        catalog: {
          microsite: true,
          whatsapp: false,
          products: []
        }
      }
    }
  },
  methods: {
    copyUrl () {
      const url = `${this.pathKeybe}${this.main.config.urlKeybe}`
      navigator.clipboard.writeText(url)
      EventBus.$emit('toast', 'success', this.$t('copiedToClipboard'))
    },
    copyWebsite (): void {
      navigator.clipboard.writeText(this.main.config.webSite)
      EventBus.$emit('toast', 'success', this.$t('copiedToClipboard'))
    },
    switchComponent (component) {
      this.previewMainComponent = component
    },
    areEqual (a, b): boolean {
      return operation.areEqual(a, b)
    },
    async init () {
      try {
        const config = await MicrositeConfigUseCases.getMicrositeConfig(
          this.micrositeConfigController,
          {
            appUUID: this.selectedApp?.uuid,
            token: this.token
          }
        )

        const validConfig = Object.keys(config).length > 0

        if (validConfig) {
          if (!config.socialLinks) config.socialLinks = []
          this.main.config = config
          if (!this._getProducts()) this.noCatalog = true
        } else {
          this.noConfig = true
          this.main.config.urlKeybe = this.selectedApp?.name
            .replace(/ /g, '')
            .toLowerCase()
        }
        this.validateWhatsapp()
      } catch {
        this.noConfig = true
        EventBus.$emit('toast', 'error', this.$t('errorGettingConfig'))
      }
    },
    async validateWhatsapp () {
      let channels = ConversationStoreController.getChannels()
      if (!channels) {
        await ConversationsConfigController.getChannels()
        channels = ConversationStoreController.getChannels()

        if (!channels) {
          return
        }
      }

      const whatsapp = channels?.whatsapp
      const userHost = whatsapp?.accounts[0]?.userHost
      this.main.config.whatsappKeybe = userHost
      if (!userHost) {
        const currentWhatsappButtonIndex =
          this.main.config.socialLinks.findIndex(
            (social) => social.id === Socials.WsKeybe
          )
        if (currentWhatsappButtonIndex !== -1) {
          this.main.config.socialLinks.splice(currentWhatsappButtonIndex, 1)
        }
        return
      }
      const currentWhatsappButton = this.main.config.socialLinks.find(
        (social) => social.id === Socials.WsKeybe
      )

      if (currentWhatsappButton) {
        currentWhatsappButton.url =
          link.whatsappLink + userHost.replace('+', '')
        const whatsappButtonIndex = this.main.config.socialLinks.findIndex(
          (social) => social.id === Socials.WsKeybe
        )
        this.main.config.socialLinks.splice(
          whatsappButtonIndex,
          1,
          currentWhatsappButton
        )
        return
      }

      const whatsappButton = {
        icon: this.whatsAppIcon,
        tipoIcon: false,
        description: this.$t('letsTalk'),
        id: Socials.WsKeybe,
        url: link.whatsappLink + userHost.replace('+', '')
      }

      this.main.config.socialLinks.unshift(whatsappButton)
    },
    changeBackgroundColor (color) {
      this.main.config.background = color
    },
    toggleColorPicker () {
      this.colorPicker = !this.colorPicker
    },
    async _getConfig () {
      try {
        const response = await MicrositesService.getConfig()

        if (!('data' in response)) {
          throw new Error()
        }

        const { data } = response

        if (this.areEqual(data.length, 0)) {
          throw new Error()
        }
        this.main.config = this.fixDataRecived(data[0])
        this.main.mode = 'edit'
        this.main.original.config = this.fixDataRecived(data[0])

        return true
      } catch (e) {
        console.error(e)
        return false
      }
    },
    fixDataRecived (config) {
      return {
        ...config,
        initialPrice: 0,
        finalPrice: 0
      }
    },
    fixProducts (products) {
      return products.map((product) => ({
        ...product,
        category:
          typeof JSON.stringify(product.category) === 'object'
            ? product.category
            : `${product.category}`.split(',')
      }))
    },
    async _getProducts () {
      try {
        const catalog = await CatalogUseCases.getCatalog(
          this.catalogController,
          {
            appUUID: this.selectedApp?.uuid,
            token: this.token
          }
        )

        const allProducts = await ProductUseCases.getProducts(
          this.productsController,
          {
            token: this.token,
            appUUID: this.selectedApp?.uuid
          }
        )

        this.main.catalog = {
          microsite: catalog.microsite,
          whatsapp: catalog.whatsapp,
          products: [...this.fixProducts(allProducts?.products)]
        }

        return true
      } catch (e) {
        console.error(e)
        return false
      }
    },
    editMicrosite () {
      this.$emit('switchMSS', 'Edit')
      // this.$router.push(link.micrositeEdit)
    },
    async addButton () {
      if (this.isUserReadonly) return
      this.main.config.socialLinks.push({
        icon: this.planetIcon,
        tipoIcon: false,
        description: '',
        id: Socials.Plt,
        url: ''
      })
    },
    async saveMicrosite () {
      try {
        if (this.isUserReadonly) return
        if (this.main?.config?.paymentMethods) {
          delete this.main.config.paymentMethods
        }
        if (this.noConfig) {
          await MicrositeConfigUseCases.createMicrositeConfig(
            this.micrositeConfigController,
            {
              appUUID: this.selectedApp?.uuid,
              token: this.token,
              config: this.main.config
            }
          )
        } else {
          await MicrositeConfigUseCases.updateMicrositeConfig(
            this.micrositeConfigController,
            {
              appUUID: this.selectedApp?.uuid,
              token: this.token,
              config: this.main.config
            }
          )
        }
        this.msgSave = true
        EventBus.$emit('toast', 'success', this.$t('successEditMicrosite'))
        // this.previewMainComponent = !this.previewMainComponent
      } catch {
        this.msgSave = false
        EventBus.$emit('toast', 'error', this.$t('errorEditMicrosite'))
      }
    }
  },
  watch: {
    selectedApp (newValue, oldValue) {
      if (newValue && !this.areEqual(newValue.uuid, oldValue.uuid)) {
        this.error = false
        this.init()
      }
    }
  }
}
