type AutoassignTypes = 'balanced' | 'linear'

export enum AutoassignType {
    BALANCED = 'balanced',
    LINEAR = 'linear',
}

export interface AutoassignConfig {
    isAutoassignActive: boolean,
    autoassignationType: AutoassignTypes,
    maxActiveConversationsNumberPerAdvisor: number,
    groups: Array<string>,
    tags: Array<string>
}

export interface AutoassignAdvisorConfig {
    userId: string,
    isActiveForAutoassign: boolean,
    maxActiveConversationsNumber: number,
    receiveRoomsEvenOfflineStatus: boolean,
    tags: Array<string>,
    groups: Array<string>
}
