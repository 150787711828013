import { mapActions, mapState, mapGetters } from 'vuex'
import RocketSVG from '@/shared/assets/RocketSVG.vue'
import { loadStripe } from '@stripe/stripe-js'
import { EventBus } from '@/eventBus'
import { PaymentService, IPaymentIntent } from '@/components/configuration/Services/PaymentService'
import BillingService from '@/components/configuration/Services/BillingService'
import EmitToast from '@/utils/EmitToast'
import AppsUseCases from '@/modules/Apps/application/AppsUseCases'
import AppsController from '@/modules/Apps/infrastructure/controllers/Apps.controller'
import link from '@/utils/links'

import CreditCardSelector from '@/modules/consumptions/infrastructure/ui/components/CreditCardSelector/CreditCardSelector.vue'
import KBCashCard from '@/modules/consumptions/infrastructure/ui/components/KBCashCard.vue'
import KBButton from '@/shared/infrastructure/ui/buttons/KBButton.vue'
import BillingPlansCardsInfo from '@/modules/consumptions/infrastructure/ui/components/BillingPlansCardsInfo/BillingPlansCardsInfo.vue'
import Loader from '@/shared/infrastructure/ui/Loader.vue'
import PlanUseCases from '@/modules/consumptions/application/PlanUseCases'
import { dataToGetPlan } from '@/modules/consumptions/domain/PlanController'
import PlanController from '../../../controllers/Plan.controller'
import KBSelect from '@/shared/infrastructure/ui/inputs/select/KBSmallSelector.vue'
import { GetPlanPricing, PlanPricing, tiers } from '@/components/configuration/Services/paymentTypes'
import FeesServices from '@/modules/configuration/application/services/FeesServices'
import { CDPFieldsEnums } from '@/modules/CDP/shared/contacts/domain/enums/CDPFieldsEnums'
import KeybeButton from '@/modules/DesignSystem/infrastructure/components/KeybeButton/KeybeButton.vue'
import BillingAddCard from '@/modules/consumptions/infrastructure/ui/components/BillingAddCard/BillingAddCard.vue'
import { appUpdater } from '@/modules/Apps/domain/AppsController'
import { dataToGetTermsByTeamMember } from '@/modules/Config/TeamConfiguration/domain/TeamController'
import TeamUseCases from '@/modules/Config/TeamConfiguration/application/TeamUseCases'
import UserInfoCompletion from '@/shared/infrastructure/UserInfoCompletion/UserInfoCompletion.vue'
import Colors from '@/utils/Colors'
import Dialog from '@/components/shared/components/Dialog.vue'
import TeamController from '@/modules/Config/TeamConfiguration/infrastructure/controllers/Team.controller'

export default {
  name: 'PlanSummaryPayment',
  components: {
    KeybeButton,
    BillingPlansCardsInfo,
    RocketSVG,
    KBButton,
    KBCashCard,
    CreditCardSelector,
    Loader,
    BillingAddCard,
    KBSelect,
    Dialog
  },
  props: ['itemDetail', 'tier'],
  data () {
    return {
      carrier: 0,
      initMonth: null,
      selectMonth: null,
      itemsMonth: [],
      loadingBooking: false,
      planId: 0,
      planController: new PlanController(),
      creditCards: [],
      stripe: null,
      elements: null,
      selectedCard: null,
      loading: true,
      error: '',
      balance: 0,
      hasCredit: false,
      applyPayment: false,
      paymentIntent: null,
      amountError: false,
      amount: 0,
      cardError: false,
      newCommission: false,
      subtotal: 0,
      discount: 0,
      activePlan: false,
      pending: 0,
      totalPrice: null,
      intent: null,
      isFeesAdmin: false,
      discountIntent: 0,
      discountIntentModel: '',
      addMethod: false,
      baseSuggestedPrice: 0,
      showUserInfoCompletion: false,
      teamController: new TeamController(),
      appsController: new AppsController()
    }
  },
  computed: {
    ...mapGetters('AppStore', ['getSelectedApp']),
    ...mapState('UserStore', ['token', 'user']),
    ...mapState('BillingStore', ['appConfig']),
    ...mapGetters('UserStore', ['isSuperAdmin']),
    UserInfoCompletionComponent () {
      return UserInfoCompletion
    },
    dialogBackground () {
      return this.$vuetify.theme.dark ? Colors.morningCoffee : Colors.white
    },
    appUUID () {
      return this.getSelectedApp.uuid ? this.getSelectedApp.uuid : null
    },
    endMonth () {
      if (this.selectMonth) {
        return this.getEndMonth(this.selectMonth)
      }
      return this.initMonth
    },
    changeOptionSave: {
      get () {
        return this.$store.state.BillingStore.changeOptionSave || null
      },
      set (value) {
        this.$store.commit('BillingStore/SET_EDITING_OBJECT', {
          key: 'changeOptionSave',
          value
        })
      }
    },
    changeOption: {
      get () {
        return this.$store.state.BillingStore.changeOption || null
      },
      set (value) {
        this.$store.commit('BillingStore/SET_EDITING_OBJECT', {
          key: 'changeOption',
          value
        })
      }
    },
    hasElement () {
      return !!this.element && !!this.stripe
    },
    montoFormat () {
      if (this.itemDetail?.cost) {
        const number = this.itemDetail.cost.toString().replace(/[.]/g, '')
        return parseInt(number)
      } else {
        return 0
      }
    },
    validateBalance () {
      return this.balance > this.totalPrice
    }
  },
  async created () {
    this.loading = true
    this.checkShowUserInfoCompletion()
    this.fillArray()
    this.initMonth = this.getInitMonth()
    if (this.itemsMonth.length > 0) {
      this.selectMonth = 1
    }
    await this.getCreditCards()
    this.loading = false
  },
  watch: {
    discountIntentModel (value) {
      this.validateDiscountIntent(value)
    },
    selectMonth () {
      if (this.selectMonth) {
        this.getIntentPlan()
      }
    },
    subtotal () {
      this.setAmount()
    }
  },
  methods: {
    ...mapActions('BillingStore', ['setAppConfig']),
    checkShowUserInfoCompletion (): void {
      const data: dataToGetTermsByTeamMember = {
        appUUID: this.selectedApp?.uuid,
        user: this.user
      }
      const acceptedTerms: boolean = TeamUseCases.areTermsAcceptedByApp(this.teamController, data)
      if (
        !this.selectedApp?.email ||
        !this.selectedApp?.phone ||
        this.selectedApp?.phone?.length < 5 ||
        !this.selectedApp?.photo ||
        !this.selectedApp?.timeZone ||
        !this.selectedApp?.lang ||
        !this.selectedApp?.country ||
        !this.selectedApp?.defaultCountryCode ||
        !acceptedTerms
      ) {
        this.showUserInfoCompletion = true
      }
    },
    getTier (name: string): string | null {
      for (const key in tiers) {
        if (key === name) {
          return tiers[key]
        }
      }
      return null
    },
    emitCallMain () {
      this.$emit('callMain')
    },
    emitCallError () {
      this.$emit('callError')
    },
    async getFeesUser () {
      const feesData = await FeesServices.isCommissionAgent()
      if (feesData?.status) {
        const user = feesData.data
        this.isFeesAdmin = user.superadmin
      }
    },
    setAmount (): void {
      this.$emit('setAmount', this.subtotal)
    },
    validateDiscountIntent (value) {
      // Primero, verifica si el valor es numérico y está en el rango deseado
      const numberValue = parseInt(value, 10)
      if (!isNaN(numberValue) && numberValue >= 1 && numberValue <= 100) {
        // Si el valor es un número válido dentro del rango, actualiza discountIntent
        this.discountIntent = numberValue.toString()
      } else {
        // Si la validación falla, limpia discountIntent
        this.discountIntent = ''
      }

      // Actualiza el modelo solo con el valor válido
      this.discountIntentModel = this.discountIntent
      if (this.isFeesAdmin) {
        this.intent.discount = this.discountIntentModel
      }
    },
    setDataIntent (intent) {
      const { balance, taxesAmount, baseSuggestedPrice, discount, totalPrice, type, discountBiky, hasCredit } = intent
      this.baseSuggestedPrice = baseSuggestedPrice
      this.activePlan = type === 'BOOKING'
      this.amount = this.formattedPrice(this.fixedAmount(baseSuggestedPrice))
      this.discount = discount
      this.carrier = discountBiky ? this.formattedPrice(this.fixedAmount(discountBiky)) : this.formattedPrice(discountBiky)
      this.subtotal = this.formattedPrice(this.fixedAmount(totalPrice))
      this.totalPrice = totalPrice
      this.pending = taxesAmount ? this.formattedPrice(this.fixedAmount(taxesAmount)) : this.formattedPrice(taxesAmount)
      this.balance = balance || 0
      this.hasCredit = hasCredit || false
    },
    fixedAmount (amount) {
      return amount.toFixed(2)
    },
    formattedPrice (amount) {
      // Utiliza 'en-US' para formato de EE.UU. con comas como separadores de miles y punto para decimales
      // Puedes cambiar 'en-US' por otro identificador de localización si necesitas otros formatos
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2, // Mínimo de dígitos fraccionarios, ajusta según necesites
        maximumFractionDigits: 2 // Máximo de dígitos fraccionarios, ajusta según necesites
      })
      return formatter.format(amount)
    },
    /** *********************************************************************************** **
    * Obtener el modelo del nuevo precio para los planes. // BIKY-BILLING
    ** *********************************************************************************** **/
    async getIntentPlan () {
      const model: GetPlanPricing = {
        plan: this.itemDetail.code,
        tier: this.getTier(this.tier),
        amount: this.selectMonth
      }
      const response = await BillingService.getIntentBikyPlan(model)
      if (response.status) {
        const intent: PlanPricing = response.data
        this.intent = intent
        this.setDataIntent(this.intent)
      }
    },
    async bookingPlan () {
      try {
        this.loadingBooking = true
        const response = await BillingService.bookingBikyPlan(this.intent)
        if (response.status) {
          this.loadingBooking = false
          this.$parent.goBack()
        } else {
          throw new Error('Ya tienes agendado este plan')
        }
      } catch (error) {
        this.loadingBooking = false
        EventBus.$emit('toast', 'error', error?.message)
      }
    },
    async payIntentPlan () {
      if (!this.intent) return
      if (this.loading) return
      this.startLoading()
      if (this.hasCredit) {
        const model = { ...this.intent }
        const response = await PaymentService.payIntentBikyPlan(model)
        if (response.status) {
          setTimeout(() => {
            this.showSuccess()
          }, 2000)
          return
        }
      }
      if (this.validateBalance) {
        const model = { ...this.intent }
        this.handlePayment(model)
        return
      }
      if (this.selectedCard) {
        const model = { ...this.intent, method: this.selectedCard?.id }
        this.handlePayment(model)
      }
    },
    /** *********************************************************************************** **/
    async handlePayment (model) {
      try {
        const response = await PaymentService.payIntentBikyPlan(model)
        if (response && response.status) {
          this.showSuccess()
        } else {
          if (response.code === 400) {
            this.showError(response.message)
            return
          }
          this.showError('Unexpected response format.')
        }
      } catch (error) {
        this.showError('Payment failed. Please try again.')
      }
    },
    fillArray (): void {
      this.itemsMonth = Array.from({ length: 36 }, (_, index) => index + 1)
    },
    getInitMonth (): string {
      const fechaActual = new Date()
      const mes = (fechaActual.getMonth() + 1).toString().padStart(2, '0')
      const anio = fechaActual.getFullYear().toString().slice(-2)

      return `${mes}/${anio}`
    },
    getEndMonth (month: number): string {
      const fechaActual = new Date()
      fechaActual.setMonth(fechaActual.getMonth() + month)

      const mes = (fechaActual.getMonth() + 1).toString().padStart(2, '0')
      const anio = fechaActual.getFullYear().toString().slice(-2)

      return `${mes}/${anio}`
    },
    async updateBillingInfo () {
      const appConfig = await BillingService.getAppConfig()
      this.setAppConfig(appConfig)
    },
    onAddBalance () {
      this.addMethod = false
      this.applyPayment = false
      this.loading = false
      this.getCreditCards()
    },
    startLoading () {
      this.loading = true
    },
    async showSuccess (message = 'paymentSuccess.plan') {
      EventBus.$emit('toast', 'success', this.$t(message))
      await this.updateBillingInfo()
      this.stopLoading()
      this.emitCallMain()
      this.setActiveApp()
    },
    showError (message = 'paymentError.noPlan') {
      EventBus.$emit('toast', 'error', this.$t(message))
      this.emitCallError()
      this.stopLoading()
    },
    stopLoading () {
      this.loading = false
    },
    async addPayment () {
      this.applyPayment = true
      this.addMethod = true
    },
    async payMethod () {
      if (this.loading) {
        return
      }
      if (this.intent.type === 'BOOKING') {
        this.bookingPlan()
        return
      }
      if (this.hasCredit) {
        const model = { ...this.intent }
        const response = await PaymentService.payIntentBikyPlan(model)
        if (response.status) {
          setTimeout(() => {
            this.showSuccess()
          }, 2000)
          return
        }
      }
      if (this.validateBalance) {
        const model = { ...this.intent }
        const response = await PaymentService.payIntentBikyPlan(model)
        if (response.status) {
          this.showSuccess()
          return
        }
        this.showError()
        return
      }

      if (this.selectedCard) {
        const model = { ...this.intent, method: this.selectedCard?.id }
        const response = await PaymentService.payIntentBikyPlan(model)
        if (response.status) {
          setTimeout(() => {
            this.showSuccess()
          }, 2000)
          return
        }
        this.showError()
        return
      }
      this.showError('paymentError.noMethod')
    },
    async continueIntentPay (payment: IPaymentIntent) {
      const appearance = {}
      const paymentOptions = {}
      const addressOptions = { mode: 'shipping' }
      const response = await PaymentService.getConfig()
      if (response.status) {
        const publicKey = response.data

        this.stripe = await loadStripe(publicKey)
        if (!this.stripe) {
          this.applyPayment = false
          this.loading = false
        }
        const paymentIntent = await PaymentService.getIntentBiky(payment, false)
        if (paymentIntent.status) {
          const clientSecret = paymentIntent.data.client_secret
          this.elements = await this.stripe.elements({ clientSecret, appearance })

          const paymentElement = this.elements.create('payment', paymentOptions)
          paymentElement.mount('#payment-element-plan')

          const addressElement = this.elements.create(CDPFieldsEnums.ADDRESS, addressOptions)
          addressElement.mount('#address-element-plan')
          setTimeout(() => {
            this.stopLoading()
          }, 3000)
          return
        } else {
          EventBus.$emit('toast', 'error', this.$t('intentError'))
        }
      }
      this.applyPayment = false
      this.stopLoading()
    },
    async continueIntent (payment: IPaymentIntent) {
      const appearance = {}
      const paymentOptions = {}
      const addressOptions = { mode: 'shipping' }
      const response = await PaymentService.getConfig()
      if (response.status) {
        const publicKey = response.data
        this.stripe = await loadStripe(publicKey)
        if (!this.stripe) {
          this.applyPayment = false
          this.loading = false
        } else {
          this.applyPayment = true
        }
        const paymentIntent = await PaymentService.getIntentBiky(payment, true)
        if (paymentIntent.status) {
          const clientSecret = paymentIntent.data.client_secret
          this.elements = await this.stripe.elements({ clientSecret, appearance })

          const paymentElement = this.elements.create('payment', paymentOptions)
          paymentElement.mount('#payment-element-plan')

          const addressElement = this.elements.create(CDPFieldsEnums.ADDRESS, addressOptions)
          addressElement.mount('#address-element-plan')
          setTimeout(() => {
            this.stopLoading()
          }, 3000)
          return
        } else {
          EventBus.$emit('toast', 'error', this.$t('intentError'))
        }
      }
      this.applyPayment = false
      this.stopLoading()
    },
    async onSubmit (event) {
      this.loading = true
      const response = await this.stripe.confirmPayment({
        elements: this.elements,
        redirect: 'if_required'
      })

      if (response?.error) {
        if (response?.error?.payment_intent) {
          await PaymentService.errorPayment({
            stripe: response,
            model: this.paymentIntent,
            biky: this.intent
          })
          EventBus.$emit('toast', 'error', response?.error?.message)
          this.paymentIntent = null
          this.stopLoading()
          this.$emit('close')
          return
        }
        this.stopLoading()
        return
      }

      await PaymentService.savePayment({
        stripe: response,
        model: this.paymentIntent,
        biky: this.intent
      })
      this.paymentIntent = null
      setTimeout(() => {
        this.showSuccess()
        this.applyPayment = false
      }, 2000)
    },
    goTo (link: string) {
      if (Object.keys(this.$route.query).length !== 0) {
        this.$router.replace({ path: this.$route.path, query: null })
      }
      if (this.$route.path === link) return

      this.$router.push(link)
    },
    async setActiveApp () {
      try {
        const app = {
          status: 'active',
          status_reason: this.itemDetail.title,
          uuid: this.appUUID
        }
        const data = {
          app: app,
          token: this.token
        }
        const response: appUpdater = await AppsUseCases.updateAppInfo(this.appsController, data)
        if (response?.message) {
          EmitToast.emitErrors(response)
        } else {
          this.goTo(link.conversations)
          EventBus.$emit('toast', 'success', this.$t('appUpdated'))
        }
      } catch (e) {
        console.error(e)
      }
    },
    callOnList () {
      this.$parent.onList()
    },
    async setNewBooking () {
      try {
        this.loadingBooking = true
        this.planId = this.itemDetail.id
        const data:dataToGetPlan = {
          token: this.token,
          planId: this.planId,
          appUUID: this.appUUID
        }
        const response = await PlanUseCases.setBooking(this.planController, data)
        if (response.status) {
          this.loadingBooking = false
          // EventBus.$emit('toast', 'success', this.$t('paymentSuccess.plan'))
          this.$emit('close')
        } else {
          throw new Error('Ya tienes agendado este plan')
        }
      } catch (error) {
        this.loadingBooking = false
        EventBus.$emit('toast', 'error', error?.message)
        this.$emit('close')
      }
    },
    async getCreditCards () {
      const response = await PaymentService.getCreditCards()
      if (response.status) {
        this.creditCards = response.data.map((card) => ({
          id: card.id,
          tokenResponse: {
            brand: card.card.brand,
            last4: card.card.last4
          }
        }))
      }
      if (this.creditCards.length > 0) {
        this.selectedCard = this.creditCards[0]
      } else {
        this.newCommission = true
      }
      this.loading = false
    }
  },
  mounted () {
    this.getIntentPlan()
    this.changeOption = null
    this.changeOptionSave = false
    this.amount = this.itemDetail.cost
    this.getCreditCards()
    this.getFeesUser()
  }
}
