import UserUseCases from '@/modules/Config/GeneralUserConfiguration/application/UserUseCases'
import { IOnboardingController, onboardingStatusEnum } from '../../domain/OnboardingController'
import store from '@/store'

import UserController from '@/modules/Config/GeneralUserConfiguration/infrastructure/controller/User.controller'
import { LoggedUser, LoggedUserExtradata } from '@/modules/Config/GeneralUserConfiguration/domain/LoggedUser'
import { EventBus } from '@/eventBus'
import EmitToast from '@/utils/EmitToast'
import { dataToGetUser, dataToUpdateUser } from '@/modules/Config/GeneralUserConfiguration/domain/UserController'

export default class OnboardingController implements IOnboardingController {
  userController = new UserController()
  startExtra = [{
    property: 'wizardOnboarding',
    value: JSON.stringify({
      started: true,
      step: 1,
      progress: 5,
      finished: false
    })
  }]

  checkOnboardingStatus (): string {
    // @ts-ignore
    const extradata: LoggedUserExtradata[] = structuredClone(store?.state?.UserStore?.userExtradata) // eslint-disable-line no-undef

    if (extradata.length === 0) return onboardingStatusEnum.NONE

    const foundOnboarding = extradata.find(extra => extra.property === 'wizardOnboarding') || null
    if (foundOnboarding) {
      const parsed = JSON.parse(foundOnboarding?.value)
      return parsed?.finished
        ? onboardingStatusEnum.FINISHED
        : parsed?.started
          ? onboardingStatusEnum.STARTED
          : onboardingStatusEnum.NONE
    } else return onboardingStatusEnum.NONE
  }

  async setOnboardingStarted () : Promise<LoggedUser> {
    // @ts-ignore
    const user: LoggedUser = structuredClone(store?.state?.UserStore?.user) // eslint-disable-line no-undef

    const status: string = this.checkOnboardingStatus()

    if (status === onboardingStatusEnum.NONE) {
      const extradata: LoggedUserExtradata[] = this.startExtra
      const response: boolean = await this.updateUserOnboarding(extradata)
      if (response) return user
    }
  }

  async setOnboardingStep (step: number) : Promise<any> {
    const status: string = this.checkOnboardingStatus()
    // eslint-disable-next-line no-undef
    const isAdminOrAbove: LoggedUser = structuredClone(store?.getters?.UserStore?.isAdminOrAbove)
    if (status === onboardingStatusEnum.STARTED) {
      // @ts-ignore
      let extradata: LoggedUserExtradata[] = structuredClone(store?.state?.UserStore?.userExtradata) // eslint-disable-line no-undef
      extradata = extradata.filter(extra => extra.property === 'wizardOnboarding')
      const parsed: {
        started: boolean,
        step: number,
        progress: number,
        finished: boolean
      } = JSON.parse(extradata[0]?.value)
      parsed.step = step
      if (isAdminOrAbove) {
        if (step < 12) parsed.progress = parsed.step * 8.33333333
        else if (step === 12) {
          parsed.progress = 100
          parsed.started = false
          parsed.finished = true
        }
      } else {
        if (step < 7) parsed.progress = parsed.step * 14.2857143
        else if (step === 7) {
          parsed.progress = 100
          parsed.started = false
          parsed.finished = true
        }
      }
      EventBus.$emit('setOnboardingProgress--onboarding', parsed.progress)
      const stringified: string = JSON.stringify(parsed)
      extradata[0].value = stringified
      await this.updateUserOnboarding(extradata)
    }
    return true
  }

  async updateUserOnboarding (extradata: LoggedUserExtradata[]): Promise<boolean> {
    const data: dataToUpdateUser = {
      // @ts-ignore
      token: store?.state?.UserStore?.token,
      // @ts-ignore
      uuid: store?.state?.UserStore?.user?.uuid,
      updateInput: {
        // @ts-ignore
        appUUID: store?.state?.AppStore?.selectedApp?.uuid,
        extradata: extradata
      }
    }
    const response = await UserUseCases.updateUser(this.userController, data)
    if (response) {
      EmitToast.emitErrors(response)
      return false
    } else return true
  }

  async getOnboardingStep () : Promise<number> {
    const status = await this.checkOnboardingStatus()
    const data: dataToGetUser = {
      // @ts-ignore
      uuid: store?.state?.UserStore?.user?.uuid,
      // @ts-ignore
      token: store?.state?.UserStore?.token
    }
    await UserUseCases.getUser(this.userController, data)

    if (status === onboardingStatusEnum.STARTED) {
      // @ts-ignore
      // const extradata: LoggedUserExtradata[] = structuredClone(store?.state?.UserStore?.userExtradata) // eslint-disable-line no-undef
      // const foundOnboarding: LoggedUserExtradata = extradata.find((extra: LoggedUserExtradata) => extra.property === 'wizardOnboarding')
      // const parsed = JSON.parse(foundOnboarding?.value)
      // const step = parsed?.step
      return null
    }
    return null
  }

  restartOnboarding () : void {
    const status = this.checkOnboardingStatus()
    if (status === onboardingStatusEnum.FINISHED) {
      const extradata = this.startExtra
      this.updateUserOnboarding(extradata)
    }
  }

  getOnboardingProgress () : number {
    const status = this.checkOnboardingStatus()
    if (status !== onboardingStatusEnum.NONE) {
      // @ts-ignore
      const extradata = structuredClone(store?.state?.UserStore?.userExtradata) // eslint-disable-line no-undef
      const foundOnboarding = extradata.find(extra => extra.property === 'wizardOnboarding')
      const parsed: {
        started: boolean,
        step: number,
        progress: number,
        finished: boolean
      } = JSON.parse(foundOnboarding?.value)
      const progress: number = parsed?.progress
      return progress
    }

    return 0
  }

  stopOnboarding () : void {
    const status: string = this.checkOnboardingStatus()
    if (status === onboardingStatusEnum.STARTED) {
      // @ts-ignore
      let extradata: LoggedUserExtradata[] = structuredClone(store?.state?.UserStore?.userExtradata) // eslint-disable-line no-undef
      extradata = extradata.filter((extra: LoggedUserExtradata) => extra.property === 'wizardOnboarding')
      const parsed: {
        started: boolean,
        step: number,
        progress: number,
        finished: boolean
      } = JSON.parse(extradata[0]?.value)

      parsed.started = false
      parsed.finished = true

      const stringified: string = JSON.stringify(parsed)
      extradata[0].value = stringified

      this.updateUserOnboarding(extradata)
    }
  }
}
