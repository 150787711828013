import { mapState } from 'vuex'
import Colors from '@/shared/domain/colors/Color'
import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'
import CreateEditExtraData from '../CreateEditExtraData/CreateEditExtraData.vue'
export default {
  props: {
    extradata: {
      type: Object
    },
    extradataFields: {
      type: Array
    }
  },
  components: {
    KeybeIcon,
    CreateEditExtraData
  },
  computed: {
    ...mapState('AppStore', ['selectedApp']),
    extradataName () {
      return this.localExtradata?.name || ''
    }
  },
  mounted () {
    this.localExtradata = this.extradata
  },
  data () {
    return {
      Colors,
      KeybeIconType,
      open: false,
      localExtradata: {}
    }
  },
  methods: {
    close () {
      this.open = false
      this.$emit('getRoles')
    },
    deleteExtradata () {}
  },
  watch: {
    extradata () {
      this.localExtradata = this.extradata
    }
  }
}
