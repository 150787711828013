<template>
  <div style="position: relative">
    <div
      v-click-outside="close"
      v-if="showColorPicker"
      class="KBColorPicker__picker">
      <v-color-picker
        v-model="color"
        dot-size="8"
        hide-inputs
        mode="hexa"
        swatches-max-height="215"
        :disabled="disabled"
      ></v-color-picker>
    </div>
    <div class="KBColorPicker"
         @click="showColorPicker = !showColorPicker">
      <div class="KBColorPicker__avatar"
           :style="`background-color: ${color}`"></div>
      <input v-model="color"
             :disabled="disabled"
             class="KBColorPicker__label" />
    </div>
  </div>
</template>

<script>

// import Vue from 'vue'

// Vue.directive('click-outside', {
//   bind: function (el, binding, vnode) {
//     this.event = function (event) {
//       if (!(el === event.target || el.contains(event.target))) {
//         vnode.context[binding.expression](event)
//       }
//     }
//     document.body.addEventListener('click', this.event)
//   },
//   unbind: function (el) {
//     document.body.removeEventListener('click', this.event)
//   }
// })

export default {
  name: 'KBColorPicker',
  props: {
    value: {
      type: String,
      default: '#000000'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      color: '#000000',
      showColorPicker: false
    }
  },
  methods: {
    close () {
      this.showColorPicker = false
    }
  },
  mounted () {
    this.color = this.value
  },
  watch: {
    value (val) {
      this.color = val
    },
    color (val) {
      this.$emit('input', val)
    }
  }
}
</script>

<style scoped lang="scss">
.KBColorPicker {
  display: flex;
  align-items: center;
  padding: 0 18px;
  position: relative;
  height: 53px;
  max-width: 185px;
  background-color: rgba(182, 190, 199, 0.2);

  &__avatar {
    width: 22px;
    height: 22px;
    border-radius: 50%;
    margin-right: 19px;
  }

  &__picker {
    height: 200px;
    width: 200px;
    position: absolute;
    top: 53px;
    left: 0;
    z-index: 9;
  }

  &__label {
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 21px;
    width: 80px;

    &:focus {
      outline: none;
    }
  }
}
</style>
