import KeybeRepository from '@/shared/infrastructure/repositories/KeybeRepository'
import { GenericResponse } from '@/shared/infrastructure/http/GenericResponse'
import { HeadersBuilder } from '@/shared/infrastructure/http/HeadersBuilder'
import { getOptions } from '@/shared/infrastructure/http/HttpClient'
import { dataToGetDocumentsData } from '../../domain/DocumentsController'

export default class DocumentsRepository extends KeybeRepository {
  getDocumentsAbortController: AbortController

  constructor () {
    super()

    this.getDocumentsAbortController = new AbortController()
  }

  async getDocuments (data: dataToGetDocumentsData): Promise<GenericResponse> {
    const { app, userId, search } = data

    const url = `${process.env.VUE_APP_DOCUMENTS}/${app}/documents/${userId}`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    delete data.token
    const requestOptions: getOptions = {
      headers,
      url,
      abortController: this.getDocumentsAbortController
    }

    if (search) {
      requestOptions.query = {
        search: search
      }
    }

    return await this.client.get(requestOptions)
  }

  cancelGetDocuments = () => {
    this.getDocumentsAbortController.abort()
    this.getDocumentsAbortController = new AbortController()
  }
}
