import {
  IEventsController,
  dataToSubscribeEvents
} from '@/shared/domain/Events/EventController'
import EventsRepository from '../repositories/EventsRepository'
import { EventSuscriptionGetter } from '@/shared/domain/Events/Event'
import { GraphQLResponse } from '../graphql/GraphqlResponse'

export default class EventsController implements IEventsController {
  repository: EventsRepository

  constructor () {
    this.repository = new EventsRepository()
  }

  subscribe (
    data: dataToSubscribeEvents,
    callback: (response: EventSuscriptionGetter) => void
  ) {
    this.repository.subscribe(data, (response: GraphQLResponse) => {
      // eslint-disable-next-line standard/no-callback-literal
      callback({
        data: {
          onUser: response?.data?.onUser
        },
        status: response.status
      })
    })
  }

  unsubscribe () {
    return this.repository.unsubscribe()
  }
}
