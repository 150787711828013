<template>
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14"><rect width="13" height="13" x=".5" y=".5" :stroke="color" rx="2.5"/></svg>
</template>

<script>
export default {
  name: 'UnselectedIcon',
  props: {
    color: {
      type: String,
      default: '#B6BEC7'
    }
  }

}
</script>

<style>

</style>
