import ActivityLogsRepository from '../repository/ActivityLogs.repository'
import { IActivityLogsController, dataToGetActivityLogs, dataToWriteActivityLog } from '../../domain/ActivityLogsController'

export default class ActivityLogsController implements IActivityLogsController {
  repository: ActivityLogsRepository

  constructor () {
    this.repository = new ActivityLogsRepository()
  }

  abortAll (): void {
    this.repository.abortAll()
  }

  async getActivityLogs (data: dataToGetActivityLogs): Promise<any> {
    const response = await this.repository.getActivityLogs(data)
    return response
  }

  async writeActivityLog (data: dataToWriteActivityLog): Promise<any> {
    const response = await this.repository.writeActivityLog(data)
    return response
  }
}
