








import { KeybeIconType } from '../../enums/icons/KeybeIconType'

// icons
import MailIcon from '@/shared/infrastructure/ui/icons/SvgComponents/MailIcon.vue'
import EmailIcon from '@/shared/infrastructure/ui/icons/SvgComponents/EmailIcon.vue'
import ChatIcon from '@/shared/infrastructure/ui/icons/SvgComponents/ChatIcon.vue'
import PeopleIcon from '@/shared/infrastructure/ui/icons/SvgComponents/PeopleIcon.vue'
import PlusIcon from '@/shared/infrastructure/ui/icons/SvgComponents/PlusIcon.vue'
import SalesIcon from '@/shared/infrastructure/ui/icons/SvgComponents/SalesIcon.vue'
import ToolIcon from '@/shared/infrastructure/ui/icons/SvgComponents/ToolIcon.vue'
import LogoIcon from '@/shared/infrastructure/ui/icons/SvgComponents/LogoIcon.vue'
import BellIcon from '@/shared/infrastructure/ui/icons/SvgComponents/BellIcon.vue'
import WriteIcon from '@/shared/infrastructure/ui/icons/SvgComponents/WriteIcon.vue'
import FiltersIcon from '@/shared/infrastructure/ui/icons/SvgComponents/FiltersIcon.vue'
import CampaingsIcon from '@/shared/infrastructure/ui/icons/SvgComponents/CampaingsIcon.vue'
import CashIcon from '@/shared/infrastructure/ui/icons/SvgComponents/CashIcon.vue'
import ExitIcon from '@/shared/infrastructure/ui/icons/SvgComponents/ExitIcon.vue'
import ExtensionsIcon from '@/shared/infrastructure/ui/icons/SvgComponents/ExtensionsIcon.vue'
import ExternalIcon from '@/shared/infrastructure/ui/icons/SvgComponents/ExternalIcon.vue'
import MetricsIcon from '@/shared/infrastructure/ui/icons/SvgComponents/MetricsIcon.vue'
import MomentsIcon from '@/shared/infrastructure/ui/icons/SvgComponents/MomentsIcon.vue'
import NumlistIcon from '@/shared/infrastructure/ui/icons/SvgComponents/NumlistIcon.vue'
import PlanetIcon from '@/shared/infrastructure/ui/icons/SvgComponents/PlanetIcon.vue'
import QuestionIcon from '@/shared/infrastructure/ui/icons/SvgComponents/QuestionIcon.vue'
import HelpIcon from '@/shared/infrastructure/ui/icons/SvgComponents/HelpIcon.vue'
import GroupIcon from '@/shared/infrastructure/ui/icons/SvgComponents/GroupIcon.vue'
import GroupRainbowIcon from '@/shared/infrastructure/ui/icons/SvgComponents/GroupRainbowIcon.vue'
import ArrowUpIcon from '@/shared/infrastructure/ui/icons/SvgComponents/ArrowUpIcon.vue'
import ArrowDownIcon from '@/shared/infrastructure/ui/icons/SvgComponents/ArrowDownIcon.vue'
import ArrowRightIcon from '@/shared/infrastructure/ui/icons/SvgComponents/ArrowRightIcon.vue'
import ArrowLeftIcon from '@/shared/infrastructure/ui/icons/SvgComponents/ArrowLeftIcon.vue'
import ArrowBackIcon from '@/shared/infrastructure/ui/icons/SvgComponents/ArrowBackIcon.vue'
import AddIcon from '@/shared/infrastructure/ui/icons/SvgComponents/AddIcon.vue'
import AddItemIcon from '@/shared/infrastructure/ui/icons/SvgComponents/AddItemIcon.vue'
import HeartIcon from '@/shared/infrastructure/ui/icons/SvgComponents/HeartIcon.vue'
import BrokenHeartIcon from '@/shared/infrastructure/ui/icons/SvgComponents/BrokenHeartIcon.vue'
import PaperPlaneIcon from '@/shared/infrastructure/ui/icons/SvgComponents/PaperPlaneIcon.vue'
import DocumentIcon from '@/shared/infrastructure/ui/icons/SvgComponents/DocumentIcon.vue'
import WhatsappIcon from '@/shared/infrastructure/ui/icons/SvgComponents/WhatsappIcon.vue'
import FacebookMessengerIcon from '@/shared/infrastructure/ui/icons/SvgComponents/FacebookMessengerIcon.vue'
import InstagramIcon from '@/shared/infrastructure/ui/icons/SvgComponents/InstagramIcon.vue'
import MagicIcon from '@/shared/infrastructure/ui/icons/SvgComponents/MagicIcon.vue'
import WorldIcon from '@/shared/infrastructure/ui/icons/SvgComponents/WorldIcon.vue'
import CloseIcon from '@/shared/infrastructure/ui/icons/SvgComponents/CloseIcon.vue'
import TransferIcon from '@/shared/infrastructure/ui/icons/SvgComponents/TransferIcon.vue'
import VideoCameraIcon from '@/shared/infrastructure/ui/icons/SvgComponents/VideoCameraIcon.vue'
import DeleteIcon from '@/shared/infrastructure/ui/icons/SvgComponents/DeleteIcon.vue'
import EditIcon from '@/shared/infrastructure/ui/icons/SvgComponents/EditIcon.vue'
import VerticalDotsIcon from '@/shared/infrastructure/ui/icons/SvgComponents/VerticalDotsIcon.vue'
import DuplicateIcon from '@/shared/infrastructure/ui/icons/SvgComponents/DuplicateIcon.vue'
import DragVerticalIcon from '@/shared/infrastructure/ui/icons/SvgComponents/DragVerticalIcon.vue'
import DefaultUserIcon from '@/shared/infrastructure/ui/icons/SvgComponents/DefaultUserIcon.vue'
import PinIcon from '@/shared/infrastructure/ui/icons/SvgComponents/PinIcon.vue'
import UnselectedIcon from '@/shared/infrastructure/ui/icons/SvgComponents/UnselectedIcon.vue'
import SelectedIcon from '@/shared/infrastructure/ui/icons/SvgComponents/SelectedIcon.vue'
import LogoV6Icon from '@/shared/infrastructure/ui/icons/SvgComponents/LogoV6Icon.vue'
import EyeOffIcon from '@/shared/infrastructure/ui/icons/SvgComponents/EyeOffIcon.vue'
import MinusIcon from '@/shared/infrastructure/ui/icons/SvgComponents/MinusIcon.vue'
import ListIcon from '@/shared/infrastructure/ui/icons/SvgComponents/ListIcon.vue'
import HomeIcon from '@/shared/infrastructure/ui/icons/SvgComponents/HomeIcon.vue'
import PointerIcon from '@/shared/infrastructure/ui/icons/SvgComponents/PointerIcon.vue'
import EyeClockIcon from '@/shared/infrastructure/ui/icons/SvgComponents/EyeClockIcon.vue'
import TrashIcon from '@/shared/infrastructure/ui/icons/SvgComponents/TrashIcon.vue'
import MasterCardIcon from '@/shared/infrastructure/ui/icons/SvgComponents/MasterCardIcon.vue'
import VisaIcon from '@/shared/infrastructure/ui/icons/SvgComponents/VisaIcon.vue'
import AmexIcon from '@/shared/infrastructure/ui/icons/SvgComponents/AmexIcon.vue'
import DinersClubIcon from '@/shared/infrastructure/ui/icons/SvgComponents/DinersClubIcon.vue'
import CheckedBoxIcon from '@/shared/infrastructure/ui/icons/SvgComponents/CheckedBoxIcon.vue'
import UncheckedBoxIcon from '@/shared/infrastructure/ui/icons/SvgComponents/UncheckedBoxIcon.vue'
import ClockIcon from '@/shared/infrastructure/ui/icons/SvgComponents/ClockIcon.vue'
import DialogIcon from '@/shared/infrastructure/ui/icons/SvgComponents/DialogIcon.vue'
import PdfIcon from '@/shared/infrastructure/ui/icons/SvgComponents/PdfIcon.vue'
import FileIcon from '@/shared/infrastructure/ui/icons/SvgComponents/FileIcon.vue'
import SmileFaceIcon from '@/shared/infrastructure/ui/icons/SvgComponents/SmileFaceIcon.vue'
import CurvedArrowLeftIcon from '@/shared/infrastructure/ui/icons/SvgComponents/CurvedArrowLeftIcon.vue'
import PencilIcon from '@/shared/infrastructure/ui/icons/SvgComponents/PencilIcon.vue'
import InfoIcon from '@/shared/infrastructure/ui/icons/SvgComponents/InfoIcon.vue'
import CopyIcon from '@/shared/infrastructure/ui/icons/SvgComponents/CopyIcon.vue'
import SendIcon from '@/shared/infrastructure/ui/icons/SvgComponents/SendIcon.vue'
import MicrophoneIcon from '@/shared/infrastructure/ui/icons/SvgComponents/MicrophoneIcon.vue'
import ClipIcon from '@/shared/infrastructure/ui/icons/SvgComponents/ClipIcon.vue'
import LightningIcon from '@/shared/infrastructure/ui/icons/SvgComponents/LightningIcon.vue'
import EndingIcon from '@/shared/infrastructure/ui/icons/SvgComponents/EndingIcon.vue'
import SaleIcon from '@/shared/infrastructure/ui/icons/SvgComponents/SaleIcon.vue'
import DocIcon from '@/shared/infrastructure/ui/icons/SvgComponents/DocIcon.vue'
import XlsIcon from '@/shared/infrastructure/ui/icons/SvgComponents/XlsIcon.vue'
import PptIcon from '@/shared/infrastructure/ui/icons/SvgComponents/PptIcon.vue'
import CalendarIcon from '@/shared/infrastructure/ui/icons/SvgComponents/CalendarIcon.vue'
import CrystalBallIcon from '@/shared/infrastructure/ui/icons/SvgComponents/CrystalBallIcon.vue'
import BalanceIcon from '@/shared/infrastructure/ui/icons/SvgComponents/BalanceIcon.vue'
import ChatOutlinedIcon from '@/shared/infrastructure/ui/icons/SvgComponents/ChatOutlinedIcon.vue'
import CompanyIcon from '@/shared/infrastructure/ui/icons/SvgComponents/CompanyIcon.vue'
import ExtradataIcon from '@/shared/infrastructure/ui/icons/SvgComponents/ExtradataIcon.vue'
import FlagIcon from '@/shared/infrastructure/ui/icons/SvgComponents/FlagIcon.vue'
import LinkedListIcon from '@/shared/infrastructure/ui/icons/SvgComponents/LinkedListIcon.vue'
import NoteIcon from '@/shared/infrastructure/ui/icons/SvgComponents/NoteIcon.vue'
import QuickResponsesIcon from '@/shared/infrastructure/ui/icons/SvgComponents/QuickResponsesIcon.vue'
import RocketIcon from '@/shared/infrastructure/ui/icons/SvgComponents/RocketIcon.vue'
import RolesIcon from '@/shared/infrastructure/ui/icons/SvgComponents/RolesIcon.vue'
import ScheduleIcon from '@/shared/infrastructure/ui/icons/SvgComponents/ScheduleIcon.vue'
import TagsIcon from '@/shared/infrastructure/ui/icons/SvgComponents/TagsIcon.vue'
import TeamIcon from '@/shared/infrastructure/ui/icons/SvgComponents/TeamIcon.vue'
import ShoppingCartIcon from '@/shared/infrastructure/ui/icons/SvgComponents/ShoppingCartIcon.vue'
import HalfSunIcon from '@/shared/infrastructure/ui/icons/SvgComponents/HalfSunIcon.vue'
import LockIcon from '@/shared/infrastructure/ui/icons/SvgComponents/LockIcon.vue'
import FlagFilledIcon from '@/shared/infrastructure/ui/icons/SvgComponents/FlagFilledIcon.vue'
import EyeIcon from '@/shared/infrastructure/ui/icons/SvgComponents/EyeIcon.vue'
import LaptopIcon from '@/shared/infrastructure/ui/icons/SvgComponents/LaptopIcon.vue'
import PhoneIcon from '@/shared/infrastructure/ui/icons/SvgComponents/PhoneIcon.vue'
import ImageIcon from '@/shared/infrastructure/ui/icons/SvgComponents/ImageIcon.vue'
import SearchIcon from '@/shared/infrastructure/ui/icons/SvgComponents/SearchIcon.vue'
import DownloadIcon from '@/shared/infrastructure/ui/icons/SvgComponents/DownloadIcon.vue'
import GridIcon from '@/shared/infrastructure/ui/icons/SvgComponents/GridIcon.vue'
import ExpandIcon from '@/shared/infrastructure/ui/icons/SvgComponents/ExpandIcon.vue'
import ShrinkIcon from '@/shared/infrastructure/ui/icons/SvgComponents/ShrinkIcon.vue'
import MusicNoteIcon from '@/shared/infrastructure/ui/icons/SvgComponents/MusicNoteIcon.vue'
import SoundIcon from '@/shared/infrastructure/ui/icons/SvgComponents/SoundIcon.vue'
import MinimizeIcon from '@/shared/infrastructure/ui/icons/SvgComponents/MinimizeIcon.vue'
import LogoWithTextIcon from '@/shared/infrastructure/ui/icons/SvgComponents/LogoWithTextIcon.vue'
import CollapseMenuIcon from '@/shared/infrastructure/ui/icons/SvgComponents/CollapseMenuIcon.vue'
import ExpandMenuIcon from '@/shared/infrastructure/ui/icons/SvgComponents/ExpandMenuIcon.vue'
import KeybeLogo from '@/shared/infrastructure/ui/icons/SvgComponents/KeybeLogo.vue'
import WizardTitleDecorationIcon from '@/shared/infrastructure/ui/icons/SvgComponents/WizardTitleDecorationIcon.vue'
import WizardTitleDecorationIconMobile from '@/shared/infrastructure/ui/icons/SvgComponents/WizardTitleDecorationIconMobile.vue'
import CheckIcon from '@/shared/infrastructure/ui/icons/SvgComponents/CheckIcon.vue'
import DollarIcon from '@/shared/infrastructure/ui/icons/SvgComponents/DollarIcon.vue'
import PinFilledIcon from '@/shared/infrastructure/ui/icons/SvgComponents/PinFilledIcon.vue'
import LoginDecorationIcon from '@/shared/infrastructure/ui/icons/SvgComponents/LoginDecorationIcon.vue'
import SimpleXLS from '@/shared/infrastructure/ui/icons/SvgComponents/SimpleXLS.vue'
import ShopifyIcon from '@/shared/infrastructure/ui/icons/SvgComponents/ShopifyIcon.vue'
import KBLogoWithGradient from '@/shared/infrastructure/ui/icons/SvgComponents/KBLogoWithGradient.vue'
import VideoIcon from '@/shared/infrastructure/ui/icons/SvgComponents/VideoIcon.vue'
import MicFilled from '@/shared/infrastructure/ui/icons/SvgComponents/MicFilled.vue'
import FileFilledIcon from '@/shared/infrastructure/ui/icons/SvgComponents/FileFilledIcon.vue'
import LocationIcon from '@/shared/infrastructure/ui/icons/SvgComponents/LocationIcon.vue'
import SmsIcon from '@/shared/infrastructure/ui/icons/SvgComponents/SmsIcon.vue'
import SimpleMailIcon from '@/shared/infrastructure/ui/icons/SvgComponents/SimpleMailIcon.vue'
import QuestionMarkIcon from '@/shared/infrastructure/ui/icons/SvgComponents/QuestionMarkIcon.vue'
import GooglePlayIcon from '@/shared/infrastructure/ui/icons/SvgComponents/GooglePlayIcon.vue'
import GooglePlayText from '@/shared/infrastructure/ui/icons/SvgComponents/GooglePlayText.vue'
import AppleStoreIcon from '@/shared/infrastructure/ui/icons/SvgComponents/AppleStoreIcon.vue'
import AppleStoreText from '@/shared/infrastructure/ui/icons/SvgComponents/AppleStoreText.vue'
import TasksIcon from '@/shared/infrastructure/ui/icons/SvgComponents/TasksIcon.vue'
import CreateTaskDecoration from '@/shared/infrastructure/ui/icons/SvgComponents/CreateTaskDecorationIcon.vue'
import PaperIcon from './SvgComponents/PaperIcon.vue'
import KeyIcon from '@/shared/infrastructure/ui/icons/SvgComponents/KeyIcon.vue'
import HeartFilledIcon from './SvgComponents/HeartFilledIcon.vue'
import HeartOutlinedIcon from './SvgComponents/HeartOutlinedIcon.vue'
import FireBallIconVue from './SvgComponents/FireBallIcon.vue'
import SentIcon from './SvgComponents/SentIcon.vue'
import RetryIcon from './SvgComponents/RetryIcon.vue'
import InboxIcon from '@/shared/infrastructure/ui/icons/SvgComponents/InboxIcon.vue'
import ForwardIcon from './SvgComponents/ForwardIcon.vue'

import SentSimpleIcon from './SvgComponents/SentSimpleIcon.vue'
import AutoDetailsIcon from './SvgComponents/AutoDetailsIcon.vue'
import BeautyDetailsIcon from './SvgComponents/BeautyDetailsIcon.vue'
import BicycleDetailsIcon from './SvgComponents/BicycleDetailsIcon.vue'
import BrandDetailsIcon from './SvgComponents/BrandDetailsIcon.vue'
import DemographicDetailsIcon from './SvgComponents/DemographicDetailsIcon.vue'
import FashionDetailsIcon from './SvgComponents/FashionDetailsIcon.vue'
import HabeasDataDetailsIcon from './SvgComponents/HabeasDataDetailsIcon.vue'
import HealthDetailsIcon from './SvgComponents/HealthDetailsIcon.vue'
import PersonalityDetailsIcon from './SvgComponents/PersonalityDetailsIcon.vue'
import PrimaryDetailsIcon from './SvgComponents/PrimaryDetailsIcon.vue'
import TransactionalDetailsIcon from './SvgComponents/TransactionalDetailsIcon.vue'
import SparkleDetailsIcon from './SvgComponents/SparkleDetailsIcon.vue'
import MotoDetailsIcon from './SvgComponents/MotoDetailsIcon.vue'
import EDTechDetailsIcon from './SvgComponents/EDTechDetailsIcon.vue'
import ContactsDetailsIcon from './SvgComponents/ContactsDetailsIcon.vue'
import BusinessDetailsIcon from './SvgComponents/BusinessDetailsIcon.vue'
import RolesDetailsIcon from './SvgComponents/RolesDetailsIcon.vue'
import NotesDetailsIcon from './SvgComponents/NotesDetailsIcon.vue'
import ResponsibleDetailsIcon from './SvgComponents/ResponsibleDetailsIcon.vue'
import SegmentsDetailsIcon from './SvgComponents/SegmentsDetailsIcon.vue'
import SalesDetailsIcon from './SvgComponents/SalesDetailsIcon.vue'
import StoreIcon from '@/shared/infrastructure/ui/icons/SvgComponents/StoreIcon.vue'
import HandIcon from './SvgComponents/HandIcon.vue'
import OfficesBusinessIcon from './SvgComponents/OfficesBusinessIcon.vue'
import BusinessOfficesIcon from './SvgComponents/BusinessOfficesIcon.vue'
import OfficesPersonsIcon from './SvgComponents/OfficesPersonsIcon.vue'
import PersonsOfficesIcon from './SvgComponents/PersonsOfficesIcon.vue'
import MegaphoneIcon from './SvgComponents/MegaphoneIcon.vue'
import SliderRuleIcon from './SvgComponents/SliderRuleIcon.vue'
import AppFieldsIcon from './SvgComponents/AppFieldsIcon.vue'
import AppPrivacyIcon from './SvgComponents/AppPrivacyIcon.vue'
import ThermometerIcon from './SvgComponents/ThermometerIcon.vue'
import SmartometerEmptyIconActive from './SvgComponents/SmartometerEmptyIconActive.vue'
import SmartometerEmptyIconBlack from './SvgComponents/SmartometerEmptyIconBlack.vue'
import SmartometerEmptyIconWhite from './SvgComponents/SmartometerEmptyIconWhite.vue'
import SmartometerFullIconActive from './SvgComponents/SmartometerFullIconActive.vue'
import SmartometerFullIconWhite from './SvgComponents/SmartometerFullIconWhite.vue'
import SmartometerFullIconBlack from './SvgComponents/SmartometerFullIconBlack.vue'
import ClassicSaleIcon from './SvgComponents/ClassicSaleIcon.vue'
import ListSaleIcon from './SvgComponents/ListSaleIcon.vue'
import TesseractIconActive from './SvgComponents/TesseractIconActive.vue'
import TesseractIconWhite from './SvgComponents/TesseractIconWhite.vue'
import TesseractIconBlack from './SvgComponents/TesseractIconBlack.vue'
import NeuralIconActive from './SvgComponents/NeuralIconActive.vue'
import NeuralIconBlack from './SvgComponents/NeuralIconBlack.vue'
import NeuralIconWhite from './SvgComponents/NeuralIconWhite.vue'
import ChatSparkleIcon from './SvgComponents/ChatSparkleIcon.vue'
import BlobIcon from './SvgComponents/BlobIcon.vue'
import StatusIcon from './SvgComponents/StatusIcon.vue'
import AgentIcon from './SvgComponents/AgentIcon.vue'
import AgentIconBlack from './SvgComponents/AgentBlackIcon.vue'
import AdminIcon from './SvgComponents/AdminIcon.vue'
import AdminBlackIcon from './SvgComponents/AdminBlackIcon.vue'
import CoordinatorIcon from './SvgComponents/CoordinatorIcon.vue'
import CoordinatorBlackIcon from './SvgComponents/CoordinatorBlackIcon.vue'
import PersonIcon from './SvgComponents/PersonIcon.vue'

import TicketIcon from './SvgComponents/TicketIcon.vue'
import OrgnizationIcon from './SvgComponents/OrgnizationIcon.vue'

import LinkIcon from '@/shared/infrastructure/ui/icons/SvgComponents/LinkIcon.vue'
import ChatFilledIcon from '@/shared/infrastructure/ui/icons/SvgComponents/ChatFilledIcon.vue'
import DumbbellIcon from '@/shared/infrastructure/ui/icons/SvgComponents/DumbbellIcon.vue'
import BrainIcon from '@/shared/infrastructure/ui/icons/SvgComponents/BrainIcon.vue'
import SaveIcon from '@/shared/infrastructure/ui/icons/SvgComponents/SaveIcon.vue'
import PlusBoldIcon from '@/shared/infrastructure/ui/icons/SvgComponents/PlusBoldIcon.vue'
import ShareIcon from './SvgComponents/ShareIcon.vue'
import MetaIcon from './SvgComponents/MetaIcon.vue'
import MainInbox from '@/shared/infrastructure/ui/icons/SvgComponents/MainInbox.vue'

import SuperadminUserIcon from './SvgComponents/SuperadminUserIcon.vue'
import AdminUserIcon from './SvgComponents/AdminUserIcon.vue'
import CoordinatorUserIcon from './SvgComponents/CoordinatorUserIcon.vue'
import AgentUserIcon from './SvgComponents/AgentUserIcon.vue'
import EyeInbox from '@/shared/infrastructure/ui/icons/SvgComponents/EyeInbox.vue'
import ThumbUpIcon from './SvgComponents/ThumbUpIcon.vue'
import ThumbDownIcon from './SvgComponents/ThumbDownIcon.vue'
import CloseBoldIcon from './SvgComponents/CloseBoldIcon.vue'
import UploadCloud from './SvgComponents/UploadCloud.vue'
import OlderConversationsIcon from './SvgComponents/OlderConversationsIcon.vue'
import RecentConversationsIcon from './SvgComponents/RecentConversationsIcon.vue'
import RetryArrowIcon from './SvgComponents/RetryArrowIcon.vue'
import LoadingIcon from './SvgComponents/LoadingIcon.vue'
import WarningIcon from './SvgComponents/WarningIcon.vue'
import EditSpacesIcon from './SvgComponents/EditSpacesIcon.vue'
import CancelIcon from './SvgComponents/CancelIcon.vue'
import GlassesIcon from './SvgComponents/GlassesIcon.vue'
import MasksIcon from './SvgComponents/MasksIcon.vue'
import PencilRuleIcon from './SvgComponents/PencilRuleIcon.vue'
import CreateTaskIcon from './SvgComponents/CreateTaskIcon.vue'
import VerifyCloudIcon from './SvgComponents/VerifyCloudIcon.vue'
import CycleMoneyIcon from './SvgComponents/CycleMoneyIcon.vue'
import ListFolderIcon from './SvgComponents/ListFolderIcon.vue'
import HandMoneyIcon from './SvgComponents/HandMoneyIcon.vue'

import SimpleChatDarkIcon from './SvgComponents/SimpleChatDarkIcon.vue'
import SimpleChatLightIcon from './SvgComponents/SimpleChatLight.vue'
import SimpleChatGreenIcon from './SvgComponents/SimpleChatGreenIcon.vue'
import CortexDarkIcon from './SvgComponents/CortexDarkIcon.vue'
import CortexLightIcon from './SvgComponents/CortexLightIcon.vue'
import CortexGreenIcon from './SvgComponents/CortexGreenIcon.vue'
import TesseractDarkIcon from './SvgComponents/TesseractDarkIcon.vue'
import TesseractLightIcon from './SvgComponents/TesseractLightIcon.vue'
import TesseractGreenIcon from './SvgComponents/TesseractGreenIcon.vue'

import BillIcon from './SvgComponents/BillIcon.vue'
import BillIcon2 from './SvgComponents/BillIcon2.vue'
import BillIcon3 from './SvgComponents/BillIcon3.vue'
import BillIcon4 from './SvgComponents/BillIcon4.vue'
import BillIcon5 from './SvgComponents/BillIcon5.vue'
import BillIcon6 from './SvgComponents/BillIcon6.vue'
import PeopleComissionsIcon from './SvgComponents/PeopleComissions.vue'
import ElipseIcon from './SvgComponents/ElipseIcon.vue'
import HandShakeIcon from './SvgComponents/HandShakeIcon.vue'
import UploadIcon from './SvgComponents/UploadIcon.vue'
import DiamondIcon from './SvgComponents/DiamondIcon.vue'
import DiamondSparkleIcon from './SvgComponents/DiamondSparkleIcon.vue'
import HandShakeOutlinedIcon from './SvgComponents/HandShakeOutlinedIcon.vue'
import ContractIcon from './SvgComponents/ContractIcon.vue'
import GraphUpIcon from './SvgComponents/GraphUpIcon.vue'
import GoogleMeetIcon from './SvgComponents/GoogleMeetIcon.vue'
import ZoomIcon from './SvgComponents/ZoomIcon.vue'
import MalletIcon from './SvgComponents/MalletIcon.vue'
import WhatsappIconBack from './SvgComponents/WhatsappIconBack.vue'
import SingleSparkleIcon from './SvgComponents/SingleSparkleIcon.vue'
import tierIcon from './SvgComponents/TierIcon.vue'
import DisconectIcon from './SvgComponents/DisconnectIcon.vue'
import RefreshIcon from './SvgComponents/RefreshIcon.vue'
import KBprimeIcon from './SvgComponents/KBprimeIcon.vue'
import ChevronLeft from './SvgComponents/ChevronLeft.vue'
import ChevronRight from './SvgComponents/ChevronRight.vue'
import PaymentStarsIcon from './SvgComponents/PaymentStarsIcon.vue'
export default {
  name: 'KeybeIcon',
  components: {
    ExpandIcon,
    MinimizeIcon,
    ChatIcon,
    EmailIcon,
    MailIcon,
    PeopleIcon,
    PlusIcon,
    SalesIcon,
    ToolIcon,
    LogoIcon,
    BellIcon,
    WriteIcon,
    FiltersIcon,
    GroupIcon,
    ArrowUpIcon,
    ArrowDownIcon,
    AddIcon,
    AddItemIcon,
    HeartIcon,
    BrokenHeartIcon,
    PaperPlaneIcon,
    GroupRainbowIcon,
    CampaingsIcon,
    CashIcon,
    ExitIcon,
    ExtensionsIcon,
    ExternalIcon,
    MetricsIcon,
    MomentsIcon,
    NumlistIcon,
    PlanetIcon,
    QuestionIcon,
    HelpIcon,
    DocumentIcon,
    WhatsappIcon,
    FacebookMessengerIcon,
    InstagramIcon,
    MagicIcon,
    WorldIcon,
    DeleteIcon,
    EditIcon,
    CloseIcon,
    TransferIcon,
    LogoV6Icon,
    EyeOffIcon,
    MinusIcon,
    ListIcon,
    HomeIcon,
    PointerIcon,
    EyeClockIcon,
    TrashIcon,
    MasterCardIcon,
    VisaIcon,
    AmexIcon,
    DinersClubIcon,
    ArrowRightIcon,
    CheckedBoxIcon,
    UncheckedBoxIcon,
    ClockIcon,
    DialogIcon,
    VerticalDotsIcon,
    ArrowLeftIcon,
    ArrowBackIcon,
    PdfIcon,
    FileIcon,
    SmileFaceIcon,
    CurvedArrowLeftIcon,
    DuplicateIcon,
    PencilIcon,
    InfoIcon,
    CopyIcon,
    DragVerticalIcon,
    SendIcon,
    MicrophoneIcon,
    ClipIcon,
    LightningIcon,
    EndingIcon,
    SaleIcon,
    DocIcon,
    XlsIcon,
    PptIcon,
    BalanceIcon,
    ChatOutlinedIcon,
    CompanyIcon,
    ExtradataIcon,
    FlagIcon,
    LinkedListIcon,
    NoteIcon,
    QuickResponsesIcon,
    RocketIcon,
    RolesIcon,
    ScheduleIcon,
    TagsIcon,
    TeamIcon,
    ShoppingCartIcon,
    HalfSunIcon,
    LockIcon,
    FlagFilledIcon,
    EyeIcon,
    LaptopIcon,
    PhoneIcon,
    ImageIcon,
    SearchIcon,
    DownloadIcon,
    GridIcon,
    ShrinkIcon,
    KeybeLogo,
    MusicNoteIcon,
    SoundIcon,
    LogoWithTextIcon,
    CollapseMenuIcon,
    ExpandMenuIcon,
    WizardTitleDecorationIcon,
    WizardTitleDecorationIconMobile,
    CheckIcon,
    DollarIcon,
    LoginDecorationIcon,
    PinFilledIcon,
    SimpleXLS,
    ShopifyIcon,
    KBLogoWithGradient,
    VideoIcon,
    MicFilled,
    FileFilledIcon,
    LocationIcon,
    QuestionMarkIcon,
    SmsIcon,
    SimpleMailIcon,
    GooglePlayIcon,
    GooglePlayText,
    AppleStoreIcon,
    AppleStoreText,
    TasksIcon,
    StoreIcon,
    CreateTaskDecoration,
    PaperIcon,
    InboxIcon,
    KeyIcon,
    HeartFilledIcon,
    HeartOutlinedIcon,
    FireBallIconVue,
    SentIcon,
    SentSimpleIcon,
    RetryIcon,
    ForwardIcon,
    AutoDetailsIcon,
    BicycleDetailsIcon,
    DemographicDetailsIcon,
    FashionDetailsIcon,
    HabeasDataDetailsIcon,
    HealthDetailsIcon,
    PersonalityDetailsIcon,
    PrimaryDetailsIcon,
    TransactionalDetailsIcon,
    SparkleDetailsIcon,
    MotoDetailsIcon,
    EDTechDetailsIcon,
    ContactsDetailsIcon,
    BusinessDetailsIcon,
    RolesDetailsIcon,
    NotesDetailsIcon,
    ResponsibleDetailsIcon,
    SegmentsDetailsIcon,
    SalesDetailsIcon,
    HandIcon,
    ThermometerIcon,
    SmartometerEmptyIconActive,
    SmartometerEmptyIconBlack,
    SmartometerEmptyIconWhite,
    SmartometerFullIconActive,
    SmartometerFullIconWhite,
    SmartometerFullIconBlack,
    OfficesBusinessIcon,
    BusinessOfficesIcon,
    OfficesPersonsIcon,
    PersonsOfficesIcon,
    MegaphoneIcon,
    SliderRuleIcon,
    AppFieldsIcon,
    AppPrivacyIcon,
    ClassicSaleIcon,
    ListSaleIcon,
    TesseractIconActive,
    TesseractIconWhite,
    TesseractIconBlack,
    NeuralIconActive,
    NeuralIconBlack,
    NeuralIconWhite,
    ChatSparkleIcon,
    BlobIcon,
    TicketIcon,
    OrgnizationIcon,
    StatusIcon,
    AgentIcon,
    AgentIconBlack,
    AdminIcon,
    AdminBlackIcon,
    CoordinatorIcon,
    CoordinatorBlackIcon,
    LinkIcon,
    ChatFilledIcon,
    DumbbellIcon,
    BrainIcon,
    SaveIcon,
    PlusBoldIcon,
    PersonIcon,
    ShareIcon,
    MetaIcon,
    SuperadminUserIcon,
    AdminUserIcon,
    CoordinatorUserIcon,
    AgentUserIcon,
    ThumbUpIcon,
    ThumbDownIcon,
    RetryArrowIcon,
    CloseBoldIcon,
    SimpleChatDarkIcon,
    SimpleChatLightIcon,
    SimpleChatGreenIcon,
    CortexDarkIcon,
    CortexLightIcon,
    CortexGreenIcon,
    TesseractDarkIcon,
    TesseractLightIcon,
    TesseractGreenIcon,
    EyeInbox,
    MainInbox,
    UploadCloud,
    OlderConversationsIcon,
    RecentConversationsIcon,
    LoadingIcon,
    WarningIcon,
    EditSpacesIcon,
    CancelIcon,
    PencilRuleIcon,
    GlassesIcon,
    MasksIcon,
    CreateTaskIcon,
    VerifyCloudIcon,
    CycleMoneyIcon,
    ListFolderIcon,
    HandMoneyIcon,
    BillIcon,
    BillIcon2,
    BillIcon3,
    BillIcon4,
    BillIcon5,
    BillIcon6,
    PeopleComissionsIcon,
    ElipseIcon,
    HandShakeIcon,
    UploadIcon,
    DiamondIcon,
    DiamondSparkleIcon,
    GraphUpIcon,
    GoogleMeetIcon,
    ZoomIcon,
    MalletIcon,
    WhatsappIconBack,
    SingleSparkleIcon,
    tierIcon,
    DisconectIcon,
    RefreshIcon
  },
  props: {
    icon: {
      required: true
    },
    color: {
      type: String
    },
    iconClass: {
      type: String,
      default: ''
    }
  },
  computed: {
    iconComponent () {
      const icons = {
        [KeybeIconType.CHAT]: ChatIcon,
        [KeybeIconType.EMAIL]: EmailIcon,
        [KeybeIconType.MAIL]: MailIcon,
        [KeybeIconType.PEOPLE]: PeopleIcon,
        [KeybeIconType.PLUS]: PlusIcon,
        [KeybeIconType.SALES]: SalesIcon,
        [KeybeIconType.TOOL]: ToolIcon,
        [KeybeIconType.LOGO]: LogoIcon,
        [KeybeIconType.BELL]: BellIcon,
        [KeybeIconType.WRITE]: WriteIcon,
        [KeybeIconType.FILTERS]: FiltersIcon,
        [KeybeIconType.CAMPAINS]: CampaingsIcon,
        [KeybeIconType.CASH]: CashIcon,
        [KeybeIconType.EXIT]: ExitIcon,
        [KeybeIconType.EXTENSIONS]: ExtensionsIcon,
        [KeybeIconType.EXTERNAL]: ExternalIcon,
        [KeybeIconType.METRICS]: MetricsIcon,
        [KeybeIconType.MOMENTS]: MomentsIcon,
        [KeybeIconType.NUMLIST]: NumlistIcon,
        [KeybeIconType.PLANET]: PlanetIcon,
        [KeybeIconType.QUESTION]: QuestionIcon,
        [KeybeIconType.GROUP]: GroupIcon,
        [KeybeIconType.RAINBOWGROUP]: GroupRainbowIcon,
        [KeybeIconType.ARROW_UP]: ArrowUpIcon,
        [KeybeIconType.ARROW_DOWN]: ArrowDownIcon,
        [KeybeIconType.ADD]: AddIcon,
        [KeybeIconType.ADD_ITEM]: AddItemIcon,
        [KeybeIconType.HEART]: HeartIcon,
        [KeybeIconType.BROKEN_HEART]: BrokenHeartIcon,
        [KeybeIconType.PAPER_PLANE]: PaperPlaneIcon,
        [KeybeIconType.HELP]: HelpIcon,
        [KeybeIconType.DOCUMENT]: DocumentIcon,
        [KeybeIconType.WHATSAPP]: WhatsappIcon,
        [KeybeIconType.FACEBOOK_MESSENGER]: FacebookMessengerIcon,
        [KeybeIconType.INSTAGRAM]: InstagramIcon,
        [KeybeIconType.MAGIC]: MagicIcon,
        [KeybeIconType.WORLD]: WorldIcon,
        [KeybeIconType.CLOSE]: CloseIcon,
        [KeybeIconType.TRANSFER]: TransferIcon,
        [KeybeIconType.VIDEO_CAMERA]: VideoCameraIcon,
        [KeybeIconType.DEFAULT_USER]: DefaultUserIcon,
        [KeybeIconType.DELETE]: DeleteIcon,
        [KeybeIconType.EDIT]: EditIcon,
        [KeybeIconType.PIN]: PinIcon,
        [KeybeIconType.UNSELECTED]: UnselectedIcon,
        [KeybeIconType.SELECTED]: SelectedIcon,
        [KeybeIconType.LOGO_V6]: LogoV6Icon,
        [KeybeIconType.EYE_OFF]: EyeOffIcon,
        [KeybeIconType.MINUS]: MinusIcon,
        [KeybeIconType.LIST]: ListIcon,
        [KeybeIconType.HOME]: HomeIcon,
        [KeybeIconType.POINTER]: PointerIcon,
        [KeybeIconType.EYE_CLOCK]: EyeClockIcon,
        [KeybeIconType.TRASH]: TrashIcon,
        [KeybeIconType.MASTER_CARD]: MasterCardIcon,
        [KeybeIconType.VISA]: VisaIcon,
        [KeybeIconType.AMEX]: AmexIcon,
        [KeybeIconType.DINERS_CLUB]: DinersClubIcon,
        [KeybeIconType.ARROW_RIGHT]: ArrowRightIcon,
        [KeybeIconType.ARROW_LEFT]: ArrowLeftIcon,
        [KeybeIconType.ARROW_BACK]: ArrowBackIcon,
        [KeybeIconType.CHECKED_BOX]: CheckedBoxIcon,
        [KeybeIconType.UNCHECKED_BOX]: UncheckedBoxIcon,
        [KeybeIconType.CLOCK]: ClockIcon,
        [KeybeIconType.DIALOG]: DialogIcon,
        [KeybeIconType.VERTICAL_DOTS]: VerticalDotsIcon,
        [KeybeIconType.PDF]: PdfIcon,
        [KeybeIconType.FILE]: FileIcon,
        [KeybeIconType.SMILE_FACE]: SmileFaceIcon,
        [KeybeIconType.CURVED_ARROW_LEFT]: CurvedArrowLeftIcon,
        [KeybeIconType.DUPLICATE]: DuplicateIcon,
        [KeybeIconType.PENCIL]: PencilIcon,
        [KeybeIconType.INFO]: InfoIcon,
        [KeybeIconType.COPY]: CopyIcon,
        [KeybeIconType.DRAG_VERTICAL]: DragVerticalIcon,
        [KeybeIconType.SEND]: SendIcon,
        [KeybeIconType.MICROPHONE]: MicrophoneIcon,
        [KeybeIconType.CLIP]: ClipIcon,
        [KeybeIconType.LIGHTNING]: LightningIcon,
        [KeybeIconType.SALE]: SaleIcon,
        [KeybeIconType.ENDING]: EndingIcon,
        [KeybeIconType.DOC]: DocIcon,
        [KeybeIconType.XLS]: XlsIcon,
        [KeybeIconType.PPT]: PptIcon,
        [KeybeIconType.CALENDAR]: CalendarIcon,
        [KeybeIconType.CRYSTAL_BALL]: CrystalBallIcon,
        [KeybeIconType.BALANCE]: BalanceIcon,
        [KeybeIconType.CHAT_OUTLINED]: ChatOutlinedIcon,
        [KeybeIconType.COMPANY]: CompanyIcon,
        [KeybeIconType.EXTRADATA]: ExtradataIcon,
        [KeybeIconType.FLAG]: FlagIcon,
        [KeybeIconType.LINKED_LIST]: LinkedListIcon,
        [KeybeIconType.NOTE]: NoteIcon,
        [KeybeIconType.QUICK_RESPONSES]: QuickResponsesIcon,
        [KeybeIconType.ROCKET]: RocketIcon,
        [KeybeIconType.ROLES]: RolesIcon,
        [KeybeIconType.SCHEDULE]: ScheduleIcon,
        [KeybeIconType.TAGS]: TagsIcon,
        [KeybeIconType.TEAM]: TeamIcon,
        [KeybeIconType.SHOPPING_CART]: ShoppingCartIcon,
        [KeybeIconType.HALF_SUN]: HalfSunIcon,
        [KeybeIconType.LOCK]: LockIcon,
        [KeybeIconType.FLAG_FILLED]: FlagFilledIcon,
        [KeybeIconType.EYE]: EyeIcon,
        [KeybeIconType.LAPTOP]: LaptopIcon,
        [KeybeIconType.PHONE]: PhoneIcon,
        [KeybeIconType.IMAGE]: ImageIcon,
        [KeybeIconType.SEARCH]: SearchIcon,
        [KeybeIconType.DOWNLOAD]: DownloadIcon,
        [KeybeIconType.GRID]: GridIcon,
        [KeybeIconType.EXPAND]: ExpandIcon,
        [KeybeIconType.SHRINK]: ShrinkIcon,
        [KeybeIconType.MUSIC_NOTE]: MusicNoteIcon,
        [KeybeIconType.SOUND]: SoundIcon,
        [KeybeIconType.MINIMIZE]: MinimizeIcon,
        [KeybeIconType.LOGO_WITH_TEXT]: LogoWithTextIcon,
        [KeybeIconType.COLLAPSE_MENU]: CollapseMenuIcon,
        [KeybeIconType.CHECK]: CheckIcon,
        [KeybeIconType.WIZARD_TITLE_DECORATION]: WizardTitleDecorationIcon,
        [KeybeIconType.WIZARD_TITLE_DECORATION_MOBILE]: WizardTitleDecorationIconMobile,
        [KeybeIconType.DOLLAR]: DollarIcon,
        [KeybeIconType.PIN_FILLED]: PinFilledIcon,
        [KeybeIconType.LOGIN_DECORATION]: LoginDecorationIcon,
        [KeybeIconType.SIMPLE_XLS]: SimpleXLS,
        [KeybeIconType.SHOPIFY]: ShopifyIcon,
        [KeybeIconType.LOGO_WITH_GRADIENT]: KBLogoWithGradient,
        [KeybeIconType.VIDEO]: VideoIcon,
        [KeybeIconType.MIC_FILLED]: MicFilled,
        [KeybeIconType.LOCATION]: LocationIcon,
        [KeybeIconType.FILE_FILLED]: FileFilledIcon,
        [KeybeIconType.QUESTION_MARK]: QuestionMarkIcon,
        [KeybeIconType.SMS]: SmsIcon,
        [KeybeIconType.SIMPLE_MAIL]: SimpleMailIcon,
        [KeybeIconType.GOOGLE_PLAY]: GooglePlayIcon,
        [KeybeIconType.GOOGLE_PLAY_TEXT]: GooglePlayText,
        [KeybeIconType.APPLE_STORE]: AppleStoreIcon,
        [KeybeIconType.APPLE_STORE_TEXT]: AppleStoreText,
        [KeybeIconType.TASKS]: TasksIcon,
        [KeybeIconType.CREATE_TASK_DECORATION]: CreateTaskDecoration,
        [KeybeIconType.PAPER]: PaperIcon,
        [KeybeIconType.INBOX]: InboxIcon,
        [KeybeIconType.STORE]: StoreIcon,
        [KeybeIconType.KEY]: KeyIcon,
        [KeybeIconType.HEART_OUTLINED]: HeartOutlinedIcon,
        [KeybeIconType.HEART_FILLED]: HeartFilledIcon,
        [KeybeIconType.SENT_SIMPLE]: SentSimpleIcon,
        [KeybeIconType.SENT]: SentIcon,
        [KeybeIconType.RETRY]: RetryIcon,
        [KeybeIconType.FORWARD]: ForwardIcon,
        [KeybeIconType.AUTO_DETAILS]: AutoDetailsIcon,
        [KeybeIconType.BEAUTY_DETAILS]: BeautyDetailsIcon,
        [KeybeIconType.BICYCLE_DETAILS]: BicycleDetailsIcon,
        [KeybeIconType.BRAND_DETAILS]: BrandDetailsIcon,
        [KeybeIconType.DEMOGRAPHIC_DETAILS]: DemographicDetailsIcon,
        [KeybeIconType.FASHION_DETAILS]: FashionDetailsIcon,
        [KeybeIconType.HABEAS_DETAILS]: HabeasDataDetailsIcon,
        [KeybeIconType.HEALTH_DETAILS]: HealthDetailsIcon,
        [KeybeIconType.PERSONALITY_DETAILS]: PersonalityDetailsIcon,
        [KeybeIconType.PRIMARY_DETAILS]: PrimaryDetailsIcon,
        [KeybeIconType.TRANSACTIONAL_DETAILS]: TransactionalDetailsIcon,
        [KeybeIconType.SPARKLE_DETAILS]: SparkleDetailsIcon,
        [KeybeIconType.MOTO_DETAILS]: MotoDetailsIcon,
        [KeybeIconType.EDTECH_DETAILS]: EDTechDetailsIcon,
        [KeybeIconType.CONTACT_DETAILS]: ContactsDetailsIcon,
        [KeybeIconType.BUSINESS_DETAILS]: BusinessDetailsIcon,
        [KeybeIconType.ROLES_DETAILS]: RolesDetailsIcon,
        [KeybeIconType.NOTES_DETAILS]: NotesDetailsIcon,
        [KeybeIconType.RESPONSILE_DETAILS]: ResponsibleDetailsIcon,
        [KeybeIconType.SEGMENTS_DETAILS]: SegmentsDetailsIcon,
        [KeybeIconType.SALES_DETAILS]: SalesDetailsIcon,
        [KeybeIconType.BUSINESS_OFFICE_DETAILS]: BusinessOfficesIcon,
        [KeybeIconType.OFFICE_BUSINESS_DETAILS]: OfficesBusinessIcon,
        [KeybeIconType.OFFICE_PERSON_DETAILS]: OfficesPersonsIcon,
        [KeybeIconType.PERSON_OFFICE_DETAILS]: PersonsOfficesIcon,
        [KeybeIconType.HAND]: HandIcon,
        [KeybeIconType.THERMOMETER]: ThermometerIcon,
        [KeybeIconType.SMARTOMETER_EMPTY_ACTIVE]: SmartometerEmptyIconActive,
        [KeybeIconType.SMARTOMETER_EMPTY_BLACK]: SmartometerEmptyIconBlack,
        [KeybeIconType.SMARTOMETER_EMPTY_WHITE]: SmartometerEmptyIconWhite,
        [KeybeIconType.SMARTOMETER_FULL_ACTIVE]: SmartometerFullIconActive,
        [KeybeIconType.SMARTOMETER_FULL_WHITE]: SmartometerFullIconWhite,
        [KeybeIconType.SMARTOMETER_FULL_BLACK]: SmartometerFullIconBlack,
        [KeybeIconType.MEGAPHONE]: MegaphoneIcon,
        [KeybeIconType.SLIDER_RULE]: SliderRuleIcon,
        [KeybeIconType.APP_FIELDS_CONFIG]: AppFieldsIcon,
        [KeybeIconType.APP_PRIVACY_SECURITY]: AppPrivacyIcon,
        [KeybeIconType.SALES_CLASSIC]: ClassicSaleIcon,
        [KeybeIconType.SALES_STACKED]: ListSaleIcon,
        [KeybeIconType.TESSERACT_ACTIVE]: TesseractIconActive,
        [KeybeIconType.TESSERACT_WHITE]: TesseractIconWhite,
        [KeybeIconType.TESSERACT_BLACK]: TesseractIconBlack,
        [KeybeIconType.NEURAL_ACTIVE]: NeuralIconActive,
        [KeybeIconType.NEURAL_BLACK]: NeuralIconBlack,
        [KeybeIconType.NEURAL_WHITE]: NeuralIconWhite,
        [KeybeIconType.EXPAND_MENU]: ExpandMenuIcon,
        [KeybeIconType.BLOB]: BlobIcon,
        [KeybeIconType.CHAT_SPARKLE]: ChatSparkleIcon,
        [KeybeIconType.TICKET]: TicketIcon,
        [KeybeIconType.ORGANIZATION]: OrgnizationIcon,
        [KeybeIconType.STATUS]: StatusIcon,
        [KeybeIconType.AGENT_ICON]: AgentIcon,
        [KeybeIconType.AGENTBLACK_ICON]: AgentIconBlack,
        [KeybeIconType.ADMINBLACK_ICON]: AdminBlackIcon,
        [KeybeIconType.ADMIN_ICON]: AdminIcon,
        [KeybeIconType.COORDINATOR_ICON]: CoordinatorIcon,
        [KeybeIconType.COORDINATORBLACK_ICON]: CoordinatorBlackIcon,
        [KeybeIconType.LINK]: LinkIcon,
        [KeybeIconType.CHAT_FILLED]: ChatFilledIcon,
        [KeybeIconType.DUMBBELL]: DumbbellIcon,
        [KeybeIconType.BRAIN]: BrainIcon,
        [KeybeIconType.SAVE]: SaveIcon,
        [KeybeIconType.PLUS_BOLD]: PlusBoldIcon,
        [KeybeIconType.PERSON_ICON]: PersonIcon,
        [KeybeIconType.SHARE]: ShareIcon,
        [KeybeIconType.META]: MetaIcon,
        [KeybeIconType.SUPERADMIN_USER]: SuperadminUserIcon,
        [KeybeIconType.ADMIN_USER]: AdminUserIcon,
        [KeybeIconType.COORDINATOR_USER]: CoordinatorUserIcon,
        [KeybeIconType.AGENT_USER]: AgentUserIcon,
        [KeybeIconType.THUMB_UP]: ThumbUpIcon,
        [KeybeIconType.THUMB_DOWN]: ThumbDownIcon,
        [KeybeIconType.RETRY_ARROW]: RetryArrowIcon,
        [KeybeIconType.CLOSE_BOLD]: CloseBoldIcon,
        [KeybeIconType.MASKS]: MasksIcon,
        [KeybeIconType.GLASSES]: GlassesIcon,
        [KeybeIconType.SIMPLE_CHAT_DARK]: SimpleChatDarkIcon,
        [KeybeIconType.SIMPLE_CHAT_LIGHT]: SimpleChatLightIcon,
        [KeybeIconType.SIMPLE_CHAT_GREEN]: SimpleChatGreenIcon,
        [KeybeIconType.CORTEX_DARK]: CortexDarkIcon,
        [KeybeIconType.CORTEX_LIGHT]: CortexLightIcon,
        [KeybeIconType.CORTEX_GREEN]: CortexGreenIcon,
        [KeybeIconType.TESSERACT_DARK]: TesseractDarkIcon,
        [KeybeIconType.TESSERACT_LIGHT]: TesseractLightIcon,
        [KeybeIconType.TESSERACT_GREEN]: TesseractGreenIcon,
        [KeybeIconType.EYE_INBOX]: EyeInbox,
        [KeybeIconType.MAIN_INBOX]: MainInbox,
        [KeybeIconType.UPLOAD_CLOUD]: UploadCloud,
        [KeybeIconType.OLD_CONVERSATIONS]: OlderConversationsIcon,
        [KeybeIconType.RECENT_CONVERSATIONS]: RecentConversationsIcon,
        [KeybeIconType.LOADING]: LoadingIcon,
        [KeybeIconType.WARNING]: WarningIcon,
        [KeybeIconType.EDITSPACES]: EditSpacesIcon,
        [KeybeIconType.CANCEL]: CancelIcon,
        [KeybeIconType.PENCIL_RULE]: PencilRuleIcon,
        [KeybeIconType.CREATE_TASK]: CreateTaskIcon,
        [KeybeIconType.VERIFY_CLOUD]: VerifyCloudIcon,
        [KeybeIconType.CYCLE_MONEY]: CycleMoneyIcon,
        [KeybeIconType.LIST_FOLDER]: ListFolderIcon,
        [KeybeIconType.HAND_MONEY]: HandMoneyIcon,
        [KeybeIconType.BILL]: BillIcon,
        [KeybeIconType.BILL2]: BillIcon2,
        [KeybeIconType.BILL3]: BillIcon3,
        [KeybeIconType.BILL4]: BillIcon4,
        [KeybeIconType.BILL5]: BillIcon5,
        [KeybeIconType.BILL6]: BillIcon6,
        [KeybeIconType.PEOPLE_COMISSIONS]: PeopleComissionsIcon,
        [KeybeIconType.ELIPSE]: ElipseIcon,
        [KeybeIconType.HAND_SHAKE]: HandShakeIcon,
        [KeybeIconType.UPLOAD]: UploadIcon,
        [KeybeIconType.DIAMOND]: DiamondIcon,
        [KeybeIconType.DIAMOND_SPARKLE]: DiamondSparkleIcon,
        [KeybeIconType.HAND_SHAKE_OUTLINE]: HandShakeOutlinedIcon,
        [KeybeIconType.CONTRACT]: ContractIcon,
        [KeybeIconType.GRAPH_UP]: GraphUpIcon,
        [KeybeIconType.GOOGLE_MEET]: GoogleMeetIcon,
        [KeybeIconType.ZOOM_MEET]: ZoomIcon,
        [KeybeIconType.MALLET]: MalletIcon,
        [KeybeIconType.WHATSAPP_BACK]: WhatsappIconBack,
        [KeybeIconType.SINGLE_SPARKLE]: SingleSparkleIcon,
        [KeybeIconType.TIER_ICON]: tierIcon,
        [KeybeIconType.DISCONNECT]: DisconectIcon,
        [KeybeIconType.REFRESH]: RefreshIcon,
        [KeybeIconType.KB_PRIME]: KBprimeIcon,
        [KeybeIconType.CHEVRON_LEFT]: ChevronLeft,
        [KeybeIconType.CHEVRON_RIGHT]: ChevronRight,
        [KeybeIconType.PAYMENT_STARS]: PaymentStarsIcon
      }
      return icons[this.icon]
    }
  }
}
