<template>
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 36 12"><path fill="#211C5A" d="M35.999 11.808h-2.71c-.1-.491-.206-.972-.29-1.457-.035-.202-.097-.28-.315-.276-1.087.014-2.173.01-3.26.002-.165-.002-.23.054-.28.209-.15.462-.313.92-.48 1.375-.024.062-.1.146-.15.147-.98.01-1.96.006-2.967.006.183-.456.357-.892.534-1.326l3.79-9.326c.273-.673.683-.955 1.39-.955h2.379c.078.379.158.752.234 1.127.622 3.072 1.241 6.143 1.864 9.215.08.394.175.784.262 1.176v.082l-.001.001Zm-3.547-4.122-.859-4.247-.035-.007-1.495 4.254h2.389ZM24.194 0c.358.069.718.125 1.072.209.323.077.64.184.984.285l-.508 2.448c-.453-.137-.886-.295-1.331-.396-.61-.139-1.233-.192-1.842-.017a1.848 1.848 0 0 0-.694.38c-.334.296-.339.721-.02 1.032.221.214.482.39.743.551.593.368 1.216.688 1.792 1.081.678.463 1.173 1.1 1.277 1.96.184 1.52-.362 2.719-1.6 3.567-.78.533-1.661.77-2.58.859a8.949 8.949 0 0 1-3.765-.44c-.082-.029-.162-.063-.242-.097-.024-.01-.045-.028-.082-.052l.525-2.534c.209.093.405.188.605.27.958.399 1.947.58 2.971.383.267-.052.539-.173.765-.33.49-.339.537-.976.09-1.374-.293-.262-.646-.455-.98-.665-.522-.329-1.077-.608-1.572-.975-.633-.471-1.092-1.099-1.179-1.933-.13-1.25.32-2.265 1.25-3.053C20.68.474 21.64.17 22.664.041c.057-.007.112-.027.168-.041h1.361ZM13.655.217l-.443 1.098c-1.395 3.432-2.791 6.863-4.18 10.298-.067.164-.148.207-.309.206-.88-.008-1.76-.01-2.64.002-.187.002-.248-.065-.291-.238-.746-3-1.505-5.997-2.243-9-.135-.55-.406-.926-.914-1.157C1.82 1.056.975.796.112.585L0 .556C.003.324.111.207.326.207h4.622c.73 0 1.258.438 1.396 1.184.391 2.112.774 4.226 1.16 6.34.017.092.036.184.062.314.04-.077.068-.121.086-.168.957-2.497 1.914-4.993 2.866-7.492.052-.137.116-.183.259-.182.893.008 1.787.004 2.68.004.059 0 .116.006.198.01ZM12.488 11.814 14.894.219h2.919l-.36 1.742c-.661 3.19-1.326 6.38-1.98 9.571-.045.222-.121.292-.344.288-.8-.016-1.6-.006-2.4-.006h-.24Z"/></svg>
</template>

<script>
export default {
  name: 'VisaIcon'
}
</script>

<style>

</style>
