
import moment from 'moment'
import { EventBus } from '@/eventBus'
import { textCamelize } from '@/utils/utils'
import { MetricsTabs } from '@/modules/metrics/infrastructure/enums/MetricsTabs'
import { mapState, mapGetters } from 'vuex'
import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import MetricsSVG from '@/shared/assets/MetricsSVG.vue'
import MetricsDarkSVG from '@/shared/assets/MetricsDarkSVG.vue'
import MetricsService from '@/modules/metrics/application/services/MetricsService'
import KeybeAvatar from '@/shared/infrastructure/ui/KeybeAvatar.vue'
import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'
import { User } from '@/components/models/User'
// import BannerSlidebar from '@/shared/infrastructure/ui/BannerSlidebar/BannerSlidebar.vue'

export default {
  name: 'MetricsSidebar',
  components: {
    KeybeAvatar,
    KeybeIcon,
    MetricsSVG,
    MetricsDarkSVG
    // BannerSlidebar
  },
  props: {
    selectedTab: {
      type: String,
      required: true
    }
  },
  watch: {
    bottomPage () {
      if (this.bottomPage) {
        if (!this.loading) {
          switch (this.currentSelectedTab) {
            case MetricsTabs.REALTIME:
              this.selectTab(MetricsTabs.CONVERSATIONS)
              break
            case MetricsTabs.CONVERSATIONS:
              this.selectTab(MetricsTabs.ENDING)
              break
            case MetricsTabs.ENDING:
              this.selectTab(MetricsTabs.SALES)
              break
            default:
              console.log(`Sorry, we are out of ${this.currentSelectedTab}.`)
          }
          this.loading = true
          this.bottomPage = false
        }
      }
    }
  },
  computed: {
    ...mapState('CompanyStore', ['selectedCompany']),
    ...mapState('AppStore', ['advisers']),
    ...mapState('AppStore', ['selectedApp']),
    ...mapState('BillingStore', ['appConfig']),
    ...mapGetters('UserStore', ['getToken', 'getUser', 'isCoordinatorOrAbove', 'getUserType']),
    loading: {
      get () {
        return this.$store.state.MetricsStore.loading || false
      },
      set (value) {
        this.$store.commit('MetricsStore/SET_EDITING_OBJECT', {
          key: 'loading',
          value
        })
      }
    },
    bottomPage: {
      get () {
        return this.$store.state.MetricsStore.bottomPage || null
      },
      set (value) {
        this.$store.commit('MetricsStore/SET_EDITING_OBJECT', {
          key: 'bottomPage',
          value
        })
      }
    },
    currentSelectedTab: {
      get () {
        return this.$store.state.MetricsStore.currentSelectedTab || null
      },
      set (value) {
        this.$store.commit('MetricsStore/SET_EDITING_OBJECT', {
          key: 'currentSelectedTab',
          value
        })
      }
    },
    appName () {
      return this.selectedApp?.name || ''
    },
    balanceComputed () {
      let value = this.appConfig.balanceUSD
      if (value) value = this.appConfig.balanceUSD
      return value
    }
  },
  methods: {
    goToMetrics () {
      const preUserParam: User = this.getUser
      const userParam = {
        customId: preUserParam.customId,
        email: preUserParam.email,
        id: preUserParam.id,
        isSuperAdmin: preUserParam.isSuperAdmin,
        lastName: preUserParam.lastName,
        name: preUserParam.name,
        photo: preUserParam.photo,
        role: this.getUserType || ''
      }
      const user = encodeURIComponent(JSON.stringify(userParam))
      const app = this.selectedApp?.uuid || ''
      const company = this.selectedCompany.uuid
      const url = `${process.env.VUE_APP_ANALYTICS_PROYECT}/?token=${this.getToken}&user=${user}&companyId=${company}&appId=${app}&appName=${this.appName}`
      window.open(url, '_blank')
    },
    async getFileMetrics () {
      if (!this.loadingSidebar) {
        this.loadingSidebar = true
        const params = {
          // periodStart: moment().subtract(1, 'weeks').format('YYYY-MM-DD'),
          periodStart: moment().subtract(1, 'months').format('YYYY-MM-DD'),
          periodEnd: moment().format('YYYY-MM-DD')
        }
        const response = await MetricsService.getFile(params)
        if (response) {
          try {
            const csvContent = atob(response.data)
            const blob = new Blob([csvContent], { type: 'data:application/octet-stream;base64' })
            const url = window.URL.createObjectURL(blob)
            const dlnk = document.createElement('a')
            dlnk.href = url
            dlnk.download = `Metrics ${params.periodStart} - ${params.periodEnd}.csv`
            dlnk.click()
            console.log('dlnk', dlnk)
            this.loadingSidebar = false
          } catch (error) {
            console.log('error', error)
            this.loadingSidebar = false
          }
        } else {
          this.loadingSidebar = false
          if (response?.data?.error) {
            EventBus.$emit('toast', 'error', this.$t(textCamelize(response.data.error)))
          } else {
            EventBus.$emit('toast', 'error', this.$t('unexpectedError'))
          }
        }
      }
    },
    isSelectedTab (tab) {
      return this.selectedTab === tab
    },
    selectTab (tab) {
      if (this.selectedTab === tab) return
      this.$emit('selectTab', tab)
    },
    lengthValue (value) {
      let term = 0
      if (value) {
        term = value.length
      }
      return `${term} Asesores`
    }
  },
  data () {
    return {
      loadingSidebar: false,
      MetricsTabs,
      KeybeIconType
    }
  }
}
