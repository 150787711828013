interface NotificationSoundTypes {
  url: string;
  name: string;
  play: () => void;
}

export default class NotificationSound implements NotificationSoundTypes {
  url
  name
  soundLists = {
    kbYeah: {
      url: `${process.env.VUE_APP_STORAGE_URL}/files/bluebird/sounds/kb_Yeah.wav`,
      name: 'Keybe Yeah'
    },
    kbCash: {
      url: `${process.env.VUE_APP_STORAGE_URL}/files/bluebird/sounds/kb_cash.mp3`,
      name: 'Keybe Cash'
    },
    kbClassic: {
      url: `${process.env.VUE_APP_STORAGE_URL}/files/bluebird/sounds/kb_classic.mp3`,
      name: 'Keybe Classic'
    },
    kbFeels: {
      url: `${process.env.VUE_APP_STORAGE_URL}/files/bluebird/sounds/kb_feels.wav`,
      name: 'Keybe Feels'
    },
    kbHappy: {
      url: `${process.env.VUE_APP_STORAGE_URL}/files/bluebird/sounds/kb_happy.wav`,
      name: 'Keybe Happy'
    },
    kbSky: {
      url: `${process.env.VUE_APP_STORAGE_URL}/files/bluebird/sounds/kb_sky.mp3`,
      name: 'Keybe Sky'
    },
    kbSynth: {
      url: `${process.env.VUE_APP_STORAGE_URL}/files/bluebird/sounds/kb_synth.wav`,
      name: 'Keybe Synth'
    },
    kbWhite: {
      url: `${process.env.VUE_APP_STORAGE_URL}/files/bluebird/sounds/kb_white.wav`,
      name: 'Keybe White'
    }
  }

  constructor (sound) {
    const soundObject = this.soundLists[sound]
    this.url = soundObject.url
    this.name = soundObject.name
  }

  play () {
    const audio = new Audio(this.url)
    audio.play()
  }
}
