





















import CreateQuickResponse from './CreateQuickResponse'
export default CreateQuickResponse
