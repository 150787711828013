



















































import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import Colors from '@/shared/domain/colors/Color'
import { mapState } from 'vuex'
import AdvisersRepository from '@/modules/conversations/infrastructure/http/Advisers.repository'
import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'
import KeybeAvatar from '@/shared/infrastructure/ui/KeybeAvatar.vue'
import UserSearch from '@/shared/infrastructure/ui/UserSearch.vue'

export default {
  name: 'TagConfigCard',
  props: {
    tag: {
      type: String,
      required: true
    },
    users: {
      type: Array,
      required: true
    }
  },
  components: {
    KeybeIcon,
    KeybeAvatar,
    UserSearch
  },
  data () {
    return {
      KeybeIconType,
      Colors,
      showAdvisers: false
    }
  },
  methods: {
    getAdviserName (adviser) {
      if (adviser?.lastName) {
        return `${adviser?.name} ${adviser?.lastName}`
      }
      return adviser?.name || ''
    },
    getAdviserPhoto (adviser) {
      return adviser?.image || ''
    },
    async addAdvisorToTag (adviser) {
      const hasTag = this.users.find(user => user.userId === adviser.userId)
      if (hasTag) {
        return
      }
      const newAdviser = {
        ...adviser,
        tags: [...adviser?.tags, this.tag]
      }
      const response = await AdvisersRepository.updateAdviser(newAdviser)
      if (response) {
        this.$store.dispatch('AppStore/updateAdviser', newAdviser)
      }
    },
    async removeAdvisorFromTag (adviser) {
      const newAdviser = {
        ...adviser,
        tags: adviser?.tags.filter(tag => tag !== this.tag)
      }
      const response = await AdvisersRepository.updateAdviser(newAdviser)
      if (response) {
        this.$store.dispatch('AppStore/updateAdviser', newAdviser)
      }
    }
  },
  computed: {
    ...mapState('AppStore', ['advisers'])
  }
}
