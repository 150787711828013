import { render, staticRenderFns } from "./DragCardLostReason.vue?vue&type=template&id=67e5837a&scoped=true&"
import script from "./DragCardLostReason.vue?vue&type=script&lang=ts&"
export * from "./DragCardLostReason.vue?vue&type=script&lang=ts&"
import style0 from "./DragCardLostReason.vue?vue&type=style&index=0&id=67e5837a&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "67e5837a",
  null
  
)

export default component.exports