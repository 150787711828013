<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       fill="none"
       viewBox="0 0 9 14"><path :fill="color"
                                d="m8.583 5.754-4.9 7.994a.532.532 0 0 1-.448.252h-.14a.358.358 0 0 1-.259-.112.343.343 0 0 1-.09-.266L3.136 9.1H.638a.532.532 0 0 1-.455-.266L.05 8.603a.343.343 0 0 1 0-.357L4.95.252A.532.532 0 0 1 5.412 0h.14a.357.357 0 0 1 .258.112.343.343 0 0 1 .09.266L5.504 4.9h2.5a.532.532 0 0 1 .454.266l.133.231a.343.343 0 0 1-.007.357Z"/></svg>
</template>

<script>
export default {
  name: 'LightningIcon',
  props: {
    color: {
      type: String,
      default: '#B6BEC7'
    }
  }
}
</script>

<style>

</style>
