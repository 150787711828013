<template>
  <svg :width="width" :height="height" viewBox="0 0 9 18" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M5.14337 15.8083C5.26281 15.6987 5.33491 15.6354 5.4027 15.5688C6.23448 14.7527 7.06626 13.9376 7.89481 13.1204C8.07989 12.9378 8.29187 12.8229 8.54689 12.9131C8.77394 12.9937 8.93965 13.158 8.98915 13.4103C9.04295 13.6842 8.888 13.8699 8.71583 14.0428C8.01425 14.7484 7.30837 15.4485 6.60464 16.1508C6.08491 16.6695 5.56626 17.1903 5.0433 17.7068C4.64839 18.0977 4.35355 18.0977 3.95972 17.7068C2.73411 16.4869 1.50957 15.266 0.287188 14.0428C-0.0506893 13.7046 -0.0851227 13.4318 0.155911 13.1247C0.365739 12.8584 0.684247 12.809 0.961867 13.0066C1.04903 13.0678 1.12865 13.1419 1.20505 13.217C2.00563 14.001 2.80405 14.7859 3.60355 15.5699C3.67134 15.6364 3.74344 15.6987 3.86288 15.8083L3.86288 15.4421C3.86288 10.5829 3.86288 5.72259 3.86396 0.863386C3.86396 0.738819 3.86934 0.612103 3.89516 0.490758C3.96618 0.16001 4.1674 0.00107874 4.4999 4.90116e-06C4.82163 -0.00106894 5.03684 0.167527 5.10571 0.491832C5.13153 0.613177 5.13907 0.739893 5.13907 0.863386C5.14014 5.714 5.14014 10.5646 5.14014 15.4152L5.14014 15.8083L5.14337 15.8083Z" :fill="color"/>
  </svg>

</template>

<script>
export default {
  name: 'ArrowDownIcon',
  props: {
    width: {
      type: Number,
      default: 16
    },
    height: {
      type: Number,
      default: 18
    },
    color: {
      type: String,
      default: '#B6BEC7'
    }
  }

}
</script>

<style>

</style>
