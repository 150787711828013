





















import DeleteUserWarning from './DeleteUserWarning'
export default DeleteUserWarning
