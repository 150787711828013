import ConfigCollapsedBiky from './ConfigCollapsedBiky/ConfigCollapsedBiky.vue'
import ConfigExpandedBiky from './ConfigExpandedBiky/ConfigExpandedBiky.vue'

export default {
  name: 'ConfigFormBiky',
  components: {
    ConfigCollapsedBiky,
    ConfigExpandedBiky
  },
  props: {
    dataTocreate: {
      type: Object,
      required: true
    },
    selectedType: {
      type: String,
      required: true
    }
  },
  methods: {
    changeTypeBiky (value: string):void {
      this.$emit('changeTypeBiky', value)
    },
    updateButtonColor (value: string):void {
      this.$emit('updateButtonColor', value)
    },
    updateTypography (value: string): void {
      this.$emit('updateTypography', value)
    },
    updateTextColor (value: string): void {
      this.$emit('updateTextColor', value)
    },
    updatebuttonTextColor (value: string): void {
      this.$emit('updatebuttonTextColor', value)
    },
    updateTextSize (value: string): void {
      this.$emit('updateTextSize', value)
    },
    updateButtonText (value: string): void {
      this.$emit('updateButtonText', value)
    },
    updateMessage (value: string):void {
      this.$emit('updateMessage', value)
    },
    updateFieldColor (value: string):void {
      this.$emit('updateFieldColor', value)
    },
    updateBackgroundColor (value:string):void {
      this.$emit('updateBackgroundColor', value)
    },
    updateButtonTextExpanded (value:string):void {
      this.$emit('updateButtonTextExpanded', value)
    },
    updateFormId (value: string): void {
      this.$emit('updateFormId', value)
    }
  }
}
