import { IWidgetTesseractController, deleteFormDTO, getAllFormsDTO, createFormDTO, updateFormDTO, getFormByUrlDTO, getFormByIdDTO } from '../domain/WidgetTesseractController'

export default class WidgetTesseractUseCases {
  static abortAll (controller: IWidgetTesseractController) : void {
    controller.abortAll()
  }

  static getAllForms (controller: IWidgetTesseractController, data: getAllFormsDTO) {
    return controller.getAllForms(data)
  }

  static getFormByUrl (controller: IWidgetTesseractController, data: getFormByUrlDTO) {
    return controller.getFormByUrl(data)
  }

  static getFormById (controller: IWidgetTesseractController, data: getFormByIdDTO) {
    return controller.getFormById(data)
  }

  static createForm (controller: IWidgetTesseractController, data: createFormDTO) {
    return controller.createForm(data)
  }

  static updateForm (controller: IWidgetTesseractController, data: updateFormDTO) {
    return controller.updateForm(data)
  }

  static deleteForm (controller: IWidgetTesseractController, data: deleteFormDTO) {
    return controller.deleteForm(data)
  }

  static processDataDefault (controller: IWidgetTesseractController) {
    return controller.processDataDefault()
  }
}
