import { mapState } from 'vuex'
import Colors from '@/shared/domain/colors/Color'
import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'

import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'
import CreateEditStatus from '../CreateEditStatus/CreateEditStatus.vue'
import StatusUseCases from '@/modules/Statuses/application/StatusUseCases'
import StatusController from '../../controllers/Status.controller'
import EmitToast from '@/utils/EmitToast'
import { ActivityActionTypeEnum, ActivityLogModuleTypeEnum, dataToWriteActivityLog } from '@/modules/Config/ActivityLogs/domain/ActivityLogsController'
import { CDPFieldsEnums } from '@/modules/CDP/shared/contacts/domain/enums/CDPFieldsEnums'
import ActivityLogsUseCases from '@/modules/Config/ActivityLogs/application/ActivityLogsUseCases'
import ActivityLogsController from '@/modules/Config/ActivityLogs/infrastructure/controllers/ActivityLogs.controller'
import operations from '@/utils/operations'
export default {
  props: {
    status: {
      type: Object
    },
    statuses: {
      type: Array
    }
  },
  components: {
    KeybeIcon,
    CreateEditStatus
  },
  computed: {
    ...mapState('AppStore', ['selectedApp']),
    ...mapState('UserStore', ['token', 'user', 'isUserReadonly']),
    statusName (): string {
      return this.localName
    }
  },
  mounted () {
    this.localStatus = this.status
    this.localName = operations.checkTranslation(this.status?.name)
  },
  data () {
    return {
      Colors,
      KeybeIconType,
      open: false,
      localStatus: {},
      localName: '',
      statusController: new StatusController(),
      activityLogsController: new ActivityLogsController()
    }
  },
  methods: {
    close () {
      this.open = false
      this.$emit('getStatuses')
    },
    async deleteStatus () {
      try {
        if (this.isUserReadonly) return
        const data = {
          app: this.selectedApp?.uuid,
          id: this.status?._id,
          token: this.token
        }
        const response = await StatusUseCases.deleteStatus(this.statusController, data)
        if (response.status) {
          setTimeout(() => {
            this.$emit('getStatuses')
            this.writeActivityLog()
          }, 400)
        } else EmitToast.emitErrors(response)
      } catch (e) {
        console.error(e)
      }
    },
    async writeActivityLog (): Promise<void> {
      const data: dataToWriteActivityLog = {
        token: this.token,
        updateInput: {
          appUUID: this.selectedApp?.uuid,
          module: ActivityLogModuleTypeEnum.CDP,
          action: ActivityActionTypeEnum.STATUS_DELETE,
          user: {
            id: this.user.uuid,
            name: this.user.name + ' ' + this.user.lastName
          },
          payload: {
            field: CDPFieldsEnums.NAME,
            newValue: this.localStatus?.name,
            oldValue: ''
          }
        }
      }
      await ActivityLogsUseCases.writeActivityLog(this.activityLogsController, data)
    }
  },
  watch: {
    status () {
      this.localStatus = this.status
    }
  }
}
