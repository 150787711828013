<template>
  <div class="KBIconButton"
       :style="styles"
       @click.stop="$emit('click')">
    <span v-if="mdiIcon"
          :class="`mdi ${icon}`"
          :style="`font-size:${fontSize};color:${color}`"></span>
    <KeybeIcon
      v-if="!mdiIcon"
      :color="color"
      :icon="icon"
      :size="fontSize"/>
  </div>
</template>
<script>
import Colors from '@/utils/Colors'
import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'

export default {
  components: {
    KeybeIcon
  },
  props: {
    color: {
      type: String,
      default: 'verdeBird'
    },
    size: {
      type: String,
      default: '16px'
    },
    icon: {
      type: String
    }
  },
  computed: {
    mdiIcon () {
      return this.icon.includes('mdi')
    },
    styles () {
      return `width:${this.size};height:${this.size};color:${Colors[this.color]}; padding: ${this.mdiIcon ? '10px' : '0px'}`
    },
    fontSize () {
      const size = this.size.replace('px', '')
      return `${+size - 10}px`
    }
  }
}
</script>
<style scoped lang="scss">
  .KBIconButton {
    border-radius: 50%;
    background: transparent;
    padding: 10px;
    display: flex;
    justify-content: center !important;
    align-items: center;
    cursor: pointer;
    transition: background 0.4s;
  }
  .KBIconButton:hover {
    background: rgba(255,255,255,0.1)
  }
</style>
