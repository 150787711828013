


















































































import ProductDiscounts from './ProductDiscounts'
export default ProductDiscounts
