import ApexCharts from 'apexcharts'
export default {
  name: 'BasicMetrics',
  props: ['dataCompononts'],
  watch: {
    dataCompononts () {
      if (this.dataCompononts) {
        this.callServices()
      }
    }
  },
  mounted () {
    this.callServices()
  },
  data () {
    return {
      itemData: [],
      itemDataCSAT: 0,
      loading: false,
      chartCSAT: null,
      chartConversation: null
    }
  },
  beforeDestroy () {
    if (this.chartCSAT) {
      this.chartCSAT.destroy()
    }
    if (this.chartConversation) {
      this.chartConversation.destroy()
    }
  },
  methods: {
    callServices () {
      if (this.dataCompononts) {
        if (this.chartCSAT) {
          this.chartCSAT.destroy()
        }
        if (this.chartConversation) {
          this.chartConversation.destroy()
        }
        this.setCSAT()
        this.setStateConversation()
      }
    },
    calculatePercentage (value, total) {
      let percentage = (value / total) * 100
      if (percentage) {
        percentage = Math.round(percentage)
      } else {
        percentage = 0
      }
      return percentage
    },
    setStateConversation () {
      const { totalRead, totalRejected, totalSent, type, totalClicked, totalOpened, totalCanceled } = this.dataCompononts
      let labels
      if (type === 'email') {
        labels = [
          this.$t('sents'),
          this.$t('totalOpened'),
          this.$t('totalClicked')
        ]
      } else {
        labels = [
          this.$t('sents'),
          this.$t('readChart'),
          this.$t('rejected')
        ]
      }
      const options = {
        series: [{
          data: [{
            x: this.$t('sents'),
            y: totalSent,
            fillColor: '#3AF69B'
          }, {
            x: type === 'email' ? this.$t('totalOpened') : this.$t('readChart'),
            y: type === 'email' ? totalOpened : totalRead,
            fillColor: '#FFB200'
          }, {
            x: type === 'email' ? this.$t('totalClicked') : this.$t('rejected'),
            y: type === 'email' ? totalClicked : totalRejected + totalCanceled,
            fillColor: '#F7685B'

          }]
        }],
        tooltip: {
          enabled: true,
          enabledOnSeries: undefined,
          shared: true,
          followCursor: false,
          intersect: false,
          inverseOrder: false,
          custom: undefined,
          fillSeriesColor: true,
          theme: false
        },
        chart: {
          height: 160,
          width: 280,
          type: 'bar',
          stacked: true,
          toolbar: {
            show: false
          },
          zoom: {
            enabled: false
          }
        },
        legend: {
          show: false
        },
        dataLabels: {
          enabled: false
        },
        labels: labels
      }
      this.chartConversation = new ApexCharts(document.querySelector('#StateConversationGraph'), options)
      this.chartConversation.render()
    },
    setCSAT () {
      const { totalUsers, totalSent } = this.dataCompononts

      let porcentaje = (100 * totalSent) / totalUsers
      if (!porcentaje || isNaN(porcentaje)) { porcentaje = 0 }
      const optionsCSAT = {
        series: [Math.round(porcentaje)],
        chart: {
          offsetY: -10,
          offsetX: -60,
          height: 220,
          width: 300,
          type: 'radialBar'
        },
        stroke: {
          lineCap: 'round'
        },
        fill: {
          type: 'solid',
          colors: ['#3AF69B']
        },
        plotOptions: {
          radialBar: {
            hollow: {
              size: '40%'
            },
            dataLabels: {
              name: {
                show: false,
                fontSize: '10px',
                color: '#636363',
                offsetY: 20
              },
              value: {
                offsetY: 5,
                fontWeight: 600,
                fontSize: '18px',
                color: '#636363'
              }
            }
          }
        },

        labels: ['CSAT']
      }
      this.chartCSAT = new ApexCharts(document.querySelector('#MetricsCSATGraph__graph'), optionsCSAT)
      this.chartCSAT.render()
    }

  }
}
