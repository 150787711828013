<template>
  <div class="ImporterContainer">
    <ImporterSegmentSelectionContainer
      v-if="step === 1"
      @segment="importer.segmentStatic = $event"
      @fileUploadData="importer.fileUploadData = $event"
      @headers="importer.headers = $event"
      @persons="importer.persons = $event"
      @createSegment="createSegment = $event"
      @hasSelectedSegment="hasSelectedSegment = $event"
      @segmentName="segmentName = $event"
      @defaultStatus="importer.defaultStatusForContacts = $event"
      @responsible="importer.responsible = $event"
      @typeEntity="importer.typeEntity = $event"/>
    <ImporterFieldSelectionContainer
      v-if="step === 2"
      :headers="importer.headers"
      :typeEntity="importer.typeEntity"
      @headers="importer.headers = $event"
      @hasErrors="hasErrors = $event"/>
    <ImporterPreview
      v-if="step === 3"
      :importer="importer"
      @countryCode="importer.defaultCountryCode = $event"/>
    <ImporterProgress
      v-if="step === 4"
      :importer="importer"
      :createSegment="createSegment"
      :segmentName="segmentName"
      :isCampaigns="isCampaigns"/>
    <ImporterFooter
      v-if="showFooter"
      :hasErrors="hasErrors"
      :step="step"
      :isCampaigns="isCampaigns"
      @nextStep="step++"
      @previousStep="previousStep"/>
  </div>
</template>
<script>
import ImporterContainer from './ImporterContainer'
export default ImporterContainer
</script>
<style scoped lang="scss">
@import './ImporterContainer.scss'
</style>
