import { mapState } from 'vuex'
import Colors from '@/shared/domain/colors/Color'
import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
// import RolesUseCases from '@/modules/roles/application/RolesUseCases'
// import RolesController from '../../../controllers/Roles.controller'
// import EmitToast from '@/utils/EmitToast'

import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'
import CreateEditRole from '@/modules/roles/infrastructure/ui/components/CreateEditRole/CreateEditRole.vue'
export default {
  props: {
    role: {
      type: Object
    },
    roles: {
      type: Array
    }
  },
  components: {
    KeybeIcon,
    CreateEditRole
  },
  computed: {
    ...mapState('AppStore', ['selectedApp']),
    roleName () {
      return this.localRole?.name || ''
    }
  },
  mounted () {
    // this.rolesController = new RolesController()
    this.localRole = this.role
  },
  data () {
    return {
      Colors,
      KeybeIconType,
      open: false,
      localRole: {},
      rolesController: null
    }
  },
  methods: {
    close () {
      this.open = false
      this.$emit('getRoles')
    }
    // async deleteRole () {
    //   try {
    //     this.loading = true
    //     const variables = {
    //       app: this.selectedApp?.uuid,
    //       removeRoleId: this.localRole?._id,
    //       token: ''
    //     }
    //     const response = await RolesUseCases.removeRole(this.rolesController, variables)
    //     if (response?.status) {
    //       setTimeout(() => {
    //         this.$emit('getRoles')
    //       }, 300)
    //     } else EmitToast.emitErrors(response)
    //     this.loading = false
    //   } catch (error) {
    //     this.loading = false
    //     console.error(error)
    //   }
    // }
  },
  watch: {
    role () {
      this.localRole = this.role
    }
  }
}
