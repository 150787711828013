import { MessageSendersEnum, MessageTypesEnum } from '@/modules/conversations/domain/models/Message/Message'
import KBMessageComposer from '@/shared/infrastructure/ui/KBMessageComposer/KBMessageComposer.vue'
import BikyConfigChatMessage from '../BikyConfigChatMessage/BikyConfigChatMessage.vue'
import BikyConfigHeader from '../BikyConfigHeader/BikyConfigHeader.vue'

export default {
  name: 'BikyConfigChat',
  components: {
    BikyConfigHeader,
    KBMessageComposer,
    BikyConfigChatMessage
  },
  data () {
    return {
      messages: [
        {
          text: 'Hola!',
          type: MessageTypesEnum.TEXT,
          sender: MessageSendersEnum.ADVISOR
        },
        {
          text: 'Hola, soy Biky, tu asistente virtual. ¿En qué puedo ayudarte?',
          type: MessageTypesEnum.TEXT,
          sender: MessageSendersEnum.BOT
        },
        {
          text: 'quienes son tus creadores?',
          type: MessageTypesEnum.TEXT,
          sender: MessageSendersEnum.ADVISOR
        },
        {
          text: 'Mis creadores son un equipo de desarrolladores y expertos en tecnología que trabajan para proporcionar soluciones innovadoras y eficientes a nuestros usuarios. Si tienes alguna pregunta específica sobre nuestros productos o servicios, estaré encantado de responderla. 🛠️✨',
          media: 'https://storage.keybeapi.com/chat/4xqa4b8khxrne0a/audios/bikyResponse1701727170340_1701727170340.mp3?original-name=bikyResponse_1701727170340.mp3',
          type: MessageTypesEnum.AUDIO,
          sender: MessageSendersEnum.BOT
        }
      ]
    }
  },
  methods: {
    getPrevMessage (index) {
      return this.messages[index - 1]?.text || ''
    }
  }
}
