<template>
  <svg width="24"
       height="30"
       viewBox="0 0 56 72"
       fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <path d="M39.0899 0H1.87596C0.839885 0 0 0.839885 0 2.46965V70.5247C0 70.9671 0.839885 71.807 1.87596 71.807H53.2615C54.2976 71.807 55.1375 70.9671 55.1375 70.5247V16.6413C55.1375 15.7488 55.0182 15.4616 54.8079 15.25L39.8875 0.329543C39.6759 0.119251 39.3887 0 39.0899 0Z"
          fill="#E9E9E0"/>
    <path d="M39.75 0.194092V15.3877H54.9436L39.75 0.194092Z"
          fill="#D9D7CA"/>
    <path d="M53.2615 71.8068H1.87596C0.839885 71.8068 0 70.967 0 69.9309V50.0083H55.1375V69.9309C55.1375 70.967 54.2976 71.8068 53.2615 71.8068Z"
          fill="#C43E1C"/>
    <path d="M29.3125 15C22.36 15.0203 16.6453 20.735 16.625 27.6875L32.6992 31.0742L29.3125 15Z"
          fill="#ED6C47"/>
    <path d="M29.3125 15C36.265 15.0203 41.9797 20.735 42 27.6875L35.6562 31.9994L29.3125 27.6875V15Z"
          fill="#FF8F6B"/>
    <path d="M29.3125 40.375C36.265 40.3547 41.9797 34.64 42 27.6875H16.625C16.6453 34.64 22.36 40.3547 29.3125 40.375Z"
          fill="#D35230"/>
    <path d="M28.104 19.5312H19.6206C15.3458 24.5684 15.7176 32.1548 20.4642 36.75H28.1049C28.7668 36.748 29.3109 36.2039 29.3129 35.542V20.7402C29.3109 20.0782 28.7668 19.5342 28.1049 19.5322L28.104 19.5312Z"
          fill="black"
          fill-opacity="0.098039"/>
    <path d="M27.1974 20.4375H18.9252C15.0676 25.9114 16.2122 33.5529 21.5043 37.6562H27.1974C27.8594 37.6543 28.4034 37.1101 28.4054 36.4483V21.6464C28.4034 20.9844 27.8594 20.4404 27.1974 20.4384V20.4375ZM27.1974 20.4375H18.9252C15.626 25.1151 15.913 31.4819 19.6193 35.8437H27.1965C27.8584 35.8418 28.4025 35.2976 28.4045 34.6358V21.6464C28.4025 20.9844 27.8584 20.4404 27.1965 20.4384L27.1974 20.4375ZM26.2912 20.4375H18.9252C15.626 25.1151 15.913 31.4819 19.6193 35.8437H26.2903C26.9521 35.8418 27.4963 35.2976 27.4982 34.6358V21.6464C27.4963 20.9844 26.9521 20.4404 26.2903 20.4384L26.2912 20.4375Z"
          fill="black"
          fill-opacity="0.2"/>
    <path d="M14.208 20.4375H26.2911C26.9537 20.4375 27.4991 20.9828 27.4991 21.6455V33.7286C27.4991 34.3912 26.9537 34.9366 26.2911 34.9366H14.208C13.5453 34.9366 13 34.3912 13 33.7286V21.6455C13 20.9828 13.5453 20.4375 14.208 20.4375Z"
          fill="#C43E1C"/>
    <path d="M20.2472 23.1562C21.1394 23.0962 22.0245 23.3547 22.744 23.8857C23.3415 24.419 23.6615 25.1989 23.6103 25.9982C23.6201 26.5103 23.4703 27.0131 23.1818 27.4365C22.8879 27.9048 22.4649 28.2785 21.9638 28.5121C21.3908 28.7779 20.7646 28.9089 20.1331 28.8954H18.4013V32.2178H16.625V23.1553L20.2472 23.1562ZM18.4013 27.4002H19.9301C20.4144 27.4356 20.8953 27.2924 21.2813 26.9978C21.5935 26.797 21.7696 26.4388 21.7381 26.0689C21.7381 25.0667 21.1544 24.5655 19.9872 24.5655H18.4013V27.4002Z"
          fill="#F9F7F7"/>
    <path d="M6.464 67V55.432H10.472C11.2987 55.432 12.0373 55.5733 12.688 55.856C13.344 56.1387 13.8587 56.5547 14.232 57.104C14.6107 57.6533 14.8 58.328 14.8 59.128C14.8 59.896 14.6267 60.552 14.28 61.096C13.9387 61.6347 13.4587 62.048 12.84 62.336C12.2267 62.6187 11.5147 62.76 10.704 62.76H8.208V67H6.464ZM8.2 61.36H10.656C11.392 61.36 11.992 61.1493 12.456 60.728C12.92 60.3013 13.152 59.736 13.152 59.032C13.152 58.3173 12.912 57.7707 12.432 57.392C11.952 57.008 11.3387 56.816 10.592 56.816H8.2V61.36ZM16.714 67V55.432H20.722C21.5487 55.432 22.2873 55.5733 22.938 55.856C23.594 56.1387 24.1087 56.5547 24.482 57.104C24.8607 57.6533 25.05 58.328 25.05 59.128C25.05 59.896 24.8767 60.552 24.53 61.096C24.1887 61.6347 23.7087 62.048 23.09 62.336C22.4767 62.6187 21.7647 62.76 20.954 62.76H18.458V67H16.714ZM18.45 61.36H20.906C21.642 61.36 22.242 61.1493 22.706 60.728C23.17 60.3013 23.402 59.736 23.402 59.032C23.402 58.3173 23.162 57.7707 22.682 57.392C22.202 57.008 21.5887 56.816 20.842 56.816H18.45V61.36ZM25.852 56.992V55.432H34.74V56.992H31.244V67H29.516V56.992H25.852ZM45.7704 67H43.6424L40.6584 62.392L37.7144 67H35.8344L39.7384 61.104L35.9704 55.432H38.0824L40.8104 59.664L43.5144 55.432H45.3704L41.7144 60.912L45.7704 67Z"
          fill="white"/>
  </svg>
</template>

<script>
export default {
  name: 'PPTXIcon',
  props: {
    width: {
      type: Number,
      default: 16
    },
    height: {
      type: Number,
      default: 18
    },
    color: {
      type: String,
      default: '#B6BEC7'
    }
  }

}
</script>

<style>

</style>
