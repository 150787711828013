<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       fill="none"
       viewBox="0 0 15 13"><path :fill="color"
                                 d="M2.5 0a.822.822 0 0 0-.833.813V3.25h1.666V.812A.822.822 0 0 0 2.5 0Zm5 0a.822.822 0 0 0-.833.813V3.25h1.666V.812A.822.822 0 0 0 7.5 0ZM.833 4.063A.822.822 0 0 0 0 4.875c0 .45.372.813.833.813V6.5c0 1.965 1.433 3.605 3.334 3.981v1.707c0 .449.372.812.833.812.46 0 .833-.363.833-.813v-1.706c.32-.063.628-.162.914-.292a4.313 4.313 0 0 1-.08-.845c0-2.04 1.4-3.758 3.315-4.296a.822.822 0 0 0-.815-.985H.833ZM15 9.343c0-.969-.395-1.899-1.098-2.585a3.799 3.799 0 0 0-2.652-1.07 3.8 3.8 0 0 0-2.652 1.07A3.61 3.61 0 0 0 7.5 9.344c0 .97.395 1.9 1.098 2.585A3.8 3.8 0 0 0 11.25 13c.995 0 1.948-.385 2.652-1.07A3.61 3.61 0 0 0 15 9.343Zm-1.667 0a.413.413 0 0 1-.416.407H9.583a.413.413 0 0 1-.416-.406c0-.224.187-.406.416-.406h3.334c.229 0 .416.182.416.406Z"/></svg>
</template>

<script>
export default {
  name: 'DisconectIcon',
  props: {
    color: {
      type: String,
      default: '#000'
    }
  }
}
</script>

    <style>

    </style>
