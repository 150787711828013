import Colors from '@/utils/Colors'
import { mapGetters } from 'vuex'
import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'

// Icons
import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'
import { NotificationsConstants } from '@/modules/NotificationsCenter/domain/Constants'

export default {
  components: {
    KeybeIcon
  },
  data () {
    return {
      unreadNotifications: '',
      filter: NotificationsConstants.MY_NOTIFICATIONS,
      KeybeIconType,
      Colors
    }
  },
  mounted () {
    // if (!this.isSuperAdmin) {
    //   this.filter = NotificationsConstants.MY_NOTIFICATIONS
    // }
  },
  computed: {
    ...mapGetters('UserStore', ['isSuperAdmin']),
    headerButtons (): { [key: string]: string }[] {
      const buttons: string[] = [NotificationsConstants.MY_NOTIFICATIONS, NotificationsConstants.APP_NOTIFICATIONS]

      // if (this.isSuperAdmin) {
      //   buttons.unshift(NotificationsConstants.ALL_NOTIFICATIONS)
      // }

      return buttons.map((button: string) => ({
        label: this.$t(button),
        key: button
      }))
    }
  },
  methods: {
    selectedFilter (filter: string): boolean {
      return this.filter === filter
    },
    selectFilter (filter: string): void {
      this.filter = filter
      this.$emit('filter', filter)
    }
  }
}
