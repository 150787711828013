<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       width="8"
       height="10"
       fill="none"><path d="M1.333 0C.598 0 0 .56 0 1.25v7.5C0 9.44.598 10 1.333 10h5.334C7.402 10 8 9.44 8 8.75V3.125H5.333c-.368 0-.666-.28-.666-.625V0H1.333Zm4 0v2.5H8L5.333 0ZM1.667 1.25H3c.183 0 .333.14.333.313 0 .171-.15.312-.333.312H1.667c-.184 0-.334-.14-.334-.313 0-.171.15-.312.334-.312Zm0 1.25H3c.183 0 .333.14.333.313 0 .171-.15.312-.333.312H1.667c-.184 0-.334-.14-.334-.313 0-.171.15-.312.334-.312Zm1.129 4.957c-.127.397-.517.668-.958.668h-.171c-.184 0-.334-.14-.334-.313 0-.171.15-.312.334-.312h.17c.148 0 .278-.09.32-.223l.31-.966A.555.555 0 0 1 3 5.938c.246 0 .462.15.533.373l.242.753a.902.902 0 0 1 .558-.189c.332 0 .634.176.782.453l.091.172h1.127c.184 0 .334.14.334.313 0 .171-.15.312-.334.312H5a.335.335 0 0 1-.298-.172l-.183-.346a.207.207 0 0 0-.184-.107.204.204 0 0 0-.183.107l-.183.346a.337.337 0 0 1-.327.172.33.33 0 0 1-.29-.22L3 6.815l-.204.641Z"
                         :fill="color"/></svg>
</template>
<script>
export default {
  name: 'HealthIcon',
  props: {
    width: {
      type: Number,
      default: 15
    },
    height: {
      type: Number,
      default: 14
    },
    color: {
      type: String,
      default: 'black'
    }
  }
}
</script>
