<template>
  <div
    class="OnboardingChannel__item">
    <div
      class="OnboardingChannel__item--avatar OnboardingChannel__item--avatar--whatsapp"
    >
      <div class="OnboardingChannel__item--avatar--icon">
        <KeybeIcon
          :icon="KeybeIconType.WHATSAPP"
          color="white"/>
      </div>
    </div>
    <span class="OnboardingChannel__item--label">WhatsApp</span>

    <div v-if="wpProviders.length > 0"
         class="OnboardingChannel__item--indicator OnboardingChannel__item--indicator--green"
    >
      <div></div>
    </div>

    <span v-if="wpProviders.length > 0"
          class="OnboardingChannel__item--label">{{ $t('active') }}</span>

    <WhatsappBspConfig
      v-if="wpProviders.length <= 0"
      @connectedWhatsapp="$emit('refresh')"
      class="ml-auto"/>
  </div>
</template>

<script>
import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import { mapState } from 'vuex'
import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'
import WhatsappBspConfig from '@/components/configuration/conversations/channels/WhatsappBspConfig.vue'

export default {
  name: 'OnboardingWhatsapp',
  components: {
    KeybeIcon,
    WhatsappBspConfig
  },
  data () {
    return {
      KeybeIconType
    }
  },
  computed: {
    ...mapState('ConversationStore', ['wpProviders'])
  }
}
</script>

<style scoped lang="scss">
@import "OnboardingChannels.scss";
</style>
