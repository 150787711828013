import { EventBus } from '@/eventBus'
import { mapState } from 'vuex'

import KBSimpleTable from '@/shared/infrastructure/ui/tables/KBSimpleTable.vue'
import KBTableDailyContact from '@/shared/infrastructure/ui/tables/KBTableDailyContact.vue'
import KBDateRangeMenu from '@/shared/infrastructure/ui/KBDateRangeMenu.vue'
import BillingController from '../../../controllers/Billing.controller'
import { billingDataGetter, dataToGetBillingReport } from '@/modules/consumptions/domain/BillingController'
import BillingUseCase from '@/modules/consumptions/application/BillingUseCase'
import { CDPFieldsEnums } from '@/modules/CDP/shared/contacts/domain/enums/CDPFieldsEnums'
// import KeybeButton from '@/modules/DesignSystem/infrastructure/components/KeybeButton/KeybeButton.vue'
import Colors from '@/shared/domain/colors/Color'
export default {
  name: 'ConsumptionsDetails',
  components: {
    // KeybeButton,
    KBDateRangeMenu,
    KBSimpleTable,
    KBTableDailyContact
  },
  props: {
    consumptions: {
      type: Array,
      default: () => []
    },
    dateString: {
      type: String,
      default: ''
    },
    dateFrom: {
      type: String,
      default: ''
    },
    dateTo: {
      type: String,
      default: ''
    },
    groups: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapState('UserStore', ['token', 'user']),
    ...mapState('CompanyStore', ['selectedCompany']),
    ...mapState('AppStore', ['selectedApp']),
    name () {
      if (!this.user?.lastName) {
        return this.user?.name
      }
      return `${this.user?.name} ${this.user?.lastName}`
    },
    consumptionsGroups () {
      const consumptionsGroups = []
      let total = 0
      for (const group of this.groups) {
        const consumptions = this.consumptions.filter(consumption => consumption.group === group)
        const localTotal = consumptions.reduce((acc, curr) => {
          const current = parseFloat(curr.priceTotal) || 0
          const amount = curr.count
          return {
            total: acc.total + current,
            amount: acc.amount + amount
          }
        }, { total: 0, amount: 0 })
        total += parseFloat(localTotal.total.toFixed(3))
        consumptionsGroups.push({
          name: group,
          total: localTotal.total,
          amount: localTotal.amount,
          consumptions
        })
      }
      this.total = total
      return consumptionsGroups
    }
  },
  data () {
    return {
      dateSelector: '',
      showingItem: null,
      total: 0,
      billinController: new BillingController(),
      loadingDownload: false,
      headers: [
        { text: this.$t('consumptionItem'), field: 'chargeType' },
        { text: this.$t('consumptionDate'), field: 'deltaTime' },
        { text: this.$t('consumptionPlan'), field: CDPFieldsEnums.PLAN },
        { text: this.$t('consumptionAmountItemShort'), field: 'count' },
        { text: this.$t('consumptionDiscountShort'), field: 'discount' },
        { text: this.$t('consumptionUnitShort'), field: 'priceUnit' },
        { text: this.$t('consumptionSubTotal'), field: 'priceSubTotal' },
        { text: this.$t('consumptionTotal'), field: 'priceTotal' },
        { text: this.$t(CDPFieldsEnums.PLAN), field: 'package' }
      ],
      headersContact: [
        { text: this.$t('consumptionItem'), field: 'chargeType' },
        { text: this.$t('consumptionDate'), field: 'deltaTime' },
        { text: this.$t('consumptionPlan'), field: CDPFieldsEnums.PLAN },
        { text: this.$t('consumptionAmountItemShort'), field: 'count' },
        { text: this.$t('consumptionDiscountShort'), field: 'discount' },
        { text: this.$t('consumptionUnitShort'), field: 'priceUnit' },
        { text: this.$t('consumptionSubTotal'), field: 'priceSubTotal' },
        { text: this.$t('consumptionTotal'), field: 'priceTotal' },
        { text: this.$t('factor'), field: 'factor' },
        { text: this.$t('tier'), field: 'tier' },
        { text: this.$t(CDPFieldsEnums.PLAN), field: 'package' }
      ],
      Colors
    }
  },
  methods: {
    showItem (item) {
      this.showingItem = item
    },
    toggleDetails (item) {
      if (this.showingItem === item) {
        this.showingItem = null
      } else {
        this.showingItem = item
      }
    },
    async downloadReport () {
      if (this.loadingDownload) {
        return
      }

      this.loadingDownload = true

      const data: dataToGetBillingReport = {
        companyUUID: this.selectedCompany?.uuid,
        appUUID: this.selectedApp?.uuid,
        fromDate: this.dateFrom,
        toDate: this.dateTo,
        token: this.token,
        exportOptions: {
          toEmail: this.user?.email,
          toName: this.name
        }
      }

      const response: billingDataGetter = await BillingUseCase.getBillingReport(this.billinController, data)

      if (!response.success) {
        return EventBus.$emit('toast', 'error', this.$t('errorDownloadingReport'))
      }

      try {
        const fileURL: string = response.downloadUrl
        if (fileURL) {
          const id: string = 'Analitics-billing-historic.xlsx'
          const link: HTMLAnchorElement = document.createElement('a')
          link.id = id
          link.href = fileURL
          link.target = '_blank'
          link.download = id
          document.body.appendChild(link)
          link.click()

          // remove link from DOM
          document.body.removeChild(link)
        } else {
          EventBus.$emit(
            'toast',
            'success',
            this.$t('successfullBillingExport')
          )
        }
        this.loadingDownload = false
      } catch (error) {
        console.log('error', error)
        this.loadingDownload = false
      }
    },
    calculateContainerHeight () {
      const headerHeight = document.querySelector('.ConsumptionsDetails__header').clientHeight
      const windowHeaderHeight = 30
      const floatingScreenHeight = parseInt(getComputedStyle(document.documentElement).getPropertyValue('--floating-screen-height'))
      document.documentElement.style.setProperty('--consumptions-container-height', `${floatingScreenHeight - headerHeight - windowHeaderHeight}px`)
    },
    formatCurrency (value: number, currency: string) {
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency
      })
      return formatter.format(value)
    }
  },
  mounted () {
    this.calculateContainerHeight()
    this.dateSelector = this.dateString

    EventBus.$on('FloatingWindow-resize', this.calculateContainerHeight)
  },
  beforeDestroy () {
    BillingUseCase.abort(this.billinController)
    EventBus.$off('FloatingWindow-resize')
  }
}
