export default [
  {
    country: 'Afghanistan',
    code: 'af',
    number: '93',
    mask: '### ### #### #####'
  },
  {
    country: 'Albania',
    code: 'al',
    number: '355',
    mask: '### ### #### #####'
  },
  {
    country: 'Algeria',
    code: 'dz',
    number: '213',
    mask: '#### ## ## ## #####'
  },
  {
    country: 'American Samoa',
    code: 'as',
    number: '1684',
    mask: '(###) ###-#### #####'
  },
  {
    country: 'Andorra',
    code: 'ad',
    number: '376',
    mask: '### ### #####'
  },
  {
    country: 'Angola',
    code: 'ao',
    number: '244',
    mask: '### ### ### #####'
  },
  {
    country: 'Anguilla',
    code: 'ai',
    number: '1264',
    mask: '(###) ###-#### #####'
  },
  {
    country: 'Antigua and Barbuda',
    code: 'ag',
    number: '1268',
    mask: '(###) ###-#### #####'
  },
  {
    country: 'Argentina',
    code: 'ar',
    number: '54',
    mask: '### ##-####-#### #####'
  },
  {
    country: 'Armenia',
    code: 'am',
    number: '374',
    mask: '### ###### #####'
  },
  {
    country: 'Aruba',
    code: 'aw',
    number: '297',
    mask: '### #### #####'
  },
  {
    country: 'Australia',
    code: 'au',
    number: '61',
    mask: '#### ### ### #####'
  },
  {
    country: 'Austria',
    code: 'at',
    number: '43',
    mask: '#### ###### #####'
  },
  {
    country: 'Azerbaijan',
    code: 'az',
    number: '994',
    mask: '### ### ## ## #####'
  },
  {
    country: 'Bahamas',
    code: 'bs',
    number: '1242',
    mask: '(###) ###-#### #####'
  },
  {
    country: 'Bahrain',
    code: 'bh',
    number: '973',
    mask: '#### #### #####'
  },
  {
    country: 'Bangladesh',
    code: 'bd',
    number: '880',
    mask: '#####-###### #####'
  },
  {
    country: 'Barbados',
    code: 'bb',
    number: '1246',
    mask: '(###) ###-#### #####'
  },
  {
    country: 'Belarus',
    code: 'by',
    number: '375',
    mask: '# ### ###-##-## #####'
  },
  {
    country: 'Belgium',
    code: 'be',
    number: '32',
    mask: '#### ## ## ## #####'
  },
  {
    country: 'Belize',
    code: 'bz',
    number: '501',
    mask: '### ### #### #####'
  },
  {
    country: 'Benin',
    code: 'bj',
    number: '229',
    mask: '### ### #### #####'
  },
  {
    country: 'Bermuda',
    code: 'bm',
    number: '1441',
    mask: '(###) ###-#### #####'
  },
  {
    country: 'Bhutan',
    code: 'bt',
    number: '975',
    mask: '## ## ## ## #####'
  },
  {
    country: 'Bolivia',
    code: 'bo',
    number: '591',
    mask: '######## #####'
  },
  {
    country: 'Bosnia and Herzegovina',
    code: 'ba',
    number: '387',
    mask: '### ### #### #####'
  },
  {
    country: 'Botswana',
    code: 'bw',
    number: '267',
    mask: '## ### ### #####'
  },
  {
    country: 'Brazil',
    code: 'br',
    number: '55',
    mask: '(##) #####-#### #####'
  },
  {
    country: 'British Indian Ocean Territory',
    code: 'io',
    number: '246',
    mask: '### #### #####'
  },
  {
    country: 'British Virgin Islands',
    code: 'vg',
    number: '1284',
    mask: '(###) ###-#### #####'
  },
  {
    country: 'Brunei',
    code: 'bn',
    number: '673',
    mask: '### #### #####'
  },
  {
    country: 'Bulgaria',
    code: 'bg',
    number: '359',
    mask: '### ### ### #####'
  },
  {
    country: 'Burkina Faso',
    code: 'bf',
    number: '226',
    mask: '## ## ## ## #####'
  },
  {
    country: 'Burundi',
    code: 'bi',
    number: '257',
    mask: '## ## ## ## #####'
  },
  {
    country: 'Cambodia',
    code: 'kh',
    number: '855',
    mask: '### ### ### #####'
  },
  {
    country: 'Cameroon',
    code: 'cm',
    number: '237',
    mask: '# ## ## ## #####'
  },
  {
    country: 'Canada',
    code: 'ca',
    number: '1',
    mask: '(###) ###-#### #####'
  },
  {
    country: 'Cape Verde',
    code: 'cv',
    number: '238',
    mask: '### ## ## #####'
  },
  {
    country: 'Caribbean Netherlands',
    code: 'bq',
    number: '599',
    mask: '### #### #####'
  },
  {
    country: 'Cayman Islands',
    code: 'ky',
    number: '1345',
    mask: '(###) ###-#### #####'
  },
  {
    country: 'Central African Republic',
    code: 'cf',
    number: '236',
    mask: '## ## ## ### #####'
  },
  {
    country: 'Chad',
    code: 'td',
    number: '235',
    mask: '## ## ## ## #####'
  },
  {
    country: 'Chile',
    code: 'cl',
    number: '56',
    mask: '(#) #### #### #####'
  },
  {
    country: 'China',
    code: 'cn',
    number: '86',
    mask: '### #### #### #####'
  },
  {
    country: 'Christmas Island',
    code: 'cx',
    number: '61',
    mask: '#### ### ### #####'
  },
  {
    country: 'Cocos (Keeling) Islands',
    code: 'cc',
    number: '61',
    mask: '#### ### ### #####'
  },
  {
    country: 'Colombia',
    code: 'co',
    number: '57',
    mask: '### ### #### #####'
  },
  {
    country: 'Comoros',
    code: 'km',
    number: '269',
    mask: '### ## ## #####'
  },
  {
    country: 'Congo',
    code: 'cd',
    number: '243',
    mask: '#### ### ### #####'
  },
  {
    country: 'Democratic Republic of the Congo',
    code: 'cg',
    number: '242',
    mask: '## ### #### #####'
  },
  {
    country: 'Cook Islands',
    code: 'ck',
    number: '682',
    mask: '### ### #####'
  },
  {
    country: 'Costa Rica',
    code: 'cr',
    number: '506',
    mask: '#### #### #####'
  },
  {
    country: 'Côte d’Ivoire',
    code: 'ci',
    number: '225',
    mask: '## ## ## ## #####'
  },
  {
    country: 'Croatia',
    code: 'hr',
    number: '385',
    mask: '### ### #### #####'
  },
  {
    country: 'Cuba',
    code: 'cu',
    number: '53',
    mask: '## ####### #####'
  },
  {
    country: 'Curaçao',
    code: 'cw',
    number: '599',
    mask: '# ### #### #####'
  },
  {
    country: 'Cyprus',
    code: 'cy',
    number: '357',
    mask: '## ###### #####'
  },
  {
    country: 'Czech Republic',
    code: 'cz',
    number: '420',
    mask: '### ### ### #####'
  },
  {
    country: 'Denmark',
    code: 'dk',
    number: '45',
    mask: '## ## ## ## #####'
  },
  {
    country: 'Djibouti',
    code: 'dj',
    number: '253',
    mask: '## ## ## ## #####'
  },
  {
    country: 'Dominica',
    code: 'dm',
    number: '1767',
    mask: '(###) ###-#### #####'
  },
  {
    country: 'Dominican Republic',
    code: 'do',
    number: '1',
    mask: '(###) ###-#### #####'
  },
  {
    country: 'Ecuador',
    code: 'ec',
    number: '593',
    mask: '### ### #### #####'
  },
  {
    country: 'Egypt',
    code: 'eg',
    number: '20',
    mask: '#### ### #### #####'
  },
  {
    country: 'El Salvador',
    code: 'sv',
    number: '503',
    mask: '#### #### #####'
  },
  {
    country: 'Equatorial Guinea',
    code: 'gq',
    number: '240',
    mask: '### ### ### #####'
  },
  {
    country: 'Eritrea',
    code: 'er',
    number: '291',
    mask: '## ### ### #####'
  },
  {
    country: 'Estonia',
    code: 'ee',
    number: '372',
    mask: '#### #### #####'
  },
  {
    country: 'Ethiopia',
    code: 'et',
    number: '251',
    mask: '### ### #### #####'
  },
  {
    country: 'Falkland Islands',
    code: 'fk',
    number: '500',
    mask: '##### #####'
  },
  {
    country: 'Faroe Islands',
    code: 'fo',
    number: '298',
    mask: '###### #####'
  },
  {
    country: 'Fiji',
    code: 'fj',
    number: '679',
    mask: '### #### #####'
  },
  {
    country: 'Finland',
    code: 'fi',
    number: '358',
    mask: '### ####### #####'
  },
  {
    country: 'France',
    code: 'fr',
    number: '33',
    mask: '## ## ## ## ## #####'
  },
  {
    country: 'French Guiana',
    code: 'gf',
    number: '594',
    mask: '#### ## ## ### #####'
  },
  {
    country: 'French Polynesia',
    code: 'pf',
    number: '689',
    mask: '## ## ## ## #####'
  },
  {
    country: 'Gabon',
    code: 'ga',
    number: '241',
    mask: '## ## ## ## #####'
  },
  {
    country: 'Gambia',
    code: 'gm',
    number: '220',
    mask: '### #### #####'
  },
  {
    country: 'Georgia',
    code: 'ge',
    number: '995',
    mask: '### ## ## ## #####'
  },
  {
    country: 'Germany',
    code: 'de',
    number: '49',
    mask: '###### ####### #####'
  },
  {
    country: 'Ghana',
    code: 'gh',
    number: '233',
    mask: '### ### #### #####'
  },
  {
    country: 'Gibraltar',
    code: 'gi',
    number: '350',
    mask: '######## #####'
  },
  {
    country: 'Greece',
    code: 'gr',
    number: '30',
    mask: '### ### #### #####'
  },
  {
    country: 'Greenland',
    code: 'gl',
    number: '299',
    mask: '## ## ## #####'
  },
  {
    country: 'Grenada',
    code: 'gd',
    number: '1473',
    mask: '(###) ###-#### #####'
  },
  {
    country: 'Guadeloupe',
    code: 'gp',
    number: '590',
    mask: '#### ## ## ## #####'
  },
  {
    country: 'Guam',
    code: 'gu',
    number: '1671',
    mask: '(###) ###-#### #####'
  },
  {
    country: 'Guatemala',
    code: 'gt',
    number: '502',
    mask: '#### #### #####'
  },
  {
    country: 'Guernsey',
    code: 'gg',
    number: '44',
    mask: '##### ###### #####'
  },
  {
    country: 'Guinea',
    code: 'gn',
    number: '224',
    mask: '### ## ## ## #####'
  },
  {
    country: 'Guinea-Bissau',
    code: 'gw',
    number: '245',
    mask: '### ### ### #####'
  },
  {
    country: 'Guyana',
    code: 'gy',
    number: '592',
    mask: '### #### #####'
  },
  {
    country: 'Haiti',
    code: 'ht',
    number: '509',
    mask: '## ## #### #####'
  },
  {
    country: 'Honduras',
    code: 'hn',
    number: '504',
    mask: '####-#### #####'
  },
  {
    country: 'Hong Kong',
    code: 'hk',
    number: '852',
    mask: '#### #### #####'
  },
  {
    country: 'Hungary',
    code: 'hu',
    number: '36',
    mask: '## ## ### #### #####'
  },
  {
    country: 'Iceland',
    code: 'is',
    number: '354',
    mask: '### #### #####'
  },
  {
    country: 'India',
    code: 'in',
    number: '91',
    mask: '###### ##### #####'
  },
  {
    country: 'Indonesia',
    code: 'id',
    number: '62',
    mask: '####-###-### #####'
  },
  {
    country: 'Iran',
    code: 'ir',
    number: '98',
    mask: '#### ### #### #####'
  },
  {
    country: 'Iraq',
    code: 'iq',
    number: '964',
    mask: '#### ### #### #####'
  },
  {
    country: 'Ireland',
    code: 'ie',
    number: '353',
    mask: '### ### #### #####'
  },
  {
    country: 'Isle of Man',
    code: 'im',
    number: '44',
    mask: '##### ###### #####'
  },
  {
    country: 'Israel',
    code: 'il',
    number: '972',
    mask: '###-###-#### #####'
  },
  {
    country: 'Italy',
    code: 'it',
    number: '39',
    mask: '### ### #### #####'
  },
  {
    country: 'Jamaica',
    code: 'jm',
    number: '1',
    mask: '(###) ###-#### #####'
  },
  {
    country: 'Japan',
    code: 'jp',
    number: '81',
    mask: '###-####-#### #####'
  },
  {
    country: 'Jersey',
    code: 'je',
    number: '44',
    mask: '##### ###### #####'
  },
  {
    country: 'Jordan',
    code: 'jo',
    number: '962',
    mask: '## #### #### #####'
  },
  {
    country: 'Kazakhstan',
    code: 'kz',
    number: '7',
    mask: '# (###) ### #### #####'
  },
  {
    country: 'Kenya',
    code: 'ke',
    number: '254',
    mask: '#### ###### #####'
  },
  {
    country: 'Kiribati',
    code: 'ki',
    number: '686',
    mask: '######## #####'
  },
  {
    country: 'Kosovo',
    code: 'xk',
    number: '383',
    mask: '### ### ### #####'
  },
  {
    country: 'Kuwait',
    code: 'kw',
    number: '965',
    mask: '### ##### #####'
  },
  {
    country: 'Kyrgyzstan',
    code: 'kg',
    number: '996',
    mask: '#### ### ### #####'
  },
  {
    country: 'Laos',
    code: 'la',
    number: '856',
    mask: '### ## ### ### #####'
  },
  {
    country: 'Latvia',
    code: 'lv',
    number: '371',
    mask: '## ### ### #####'
  },
  {
    country: 'Lebanon',
    code: 'lb',
    number: '961',
    mask: '## ### ### #####'
  },
  {
    country: 'Lesotho',
    code: 'ls',
    number: '266',
    mask: '#### #### #####'
  },
  {
    country: 'Liberia',
    code: 'lr',
    number: '231',
    mask: '### ### #### #####'
  },
  {
    country: 'Libya',
    code: 'ly',
    number: '218',
    mask: '###-####### #####'
  },
  {
    country: 'Liechtenstein',
    code: 'li',
    number: '423',
    mask: '### ### ### #####'
  },
  {
    country: 'Lithuania',
    code: 'lt',
    number: '370',
    mask: '(#-###) ##### #####'
  },
  {
    country: 'Luxembourg',
    code: 'lu',
    number: '352',
    mask: '### ### ### #####'
  },
  {
    country: 'Macau',
    code: 'mo',
    number: '853',
    mask: '#### #### #####'
  },
  {
    country: 'Macedonia',
    code: 'mk',
    number: '389',
    mask: '### ### ### #####'
  },
  {
    country: 'Madagascar',
    code: 'mg',
    number: '261',
    mask: '### ## ### ## #####'
  },
  {
    country: 'Malawi',
    code: 'mw',
    number: '265',
    mask: '#### ## ## ## #####'
  },
  {
    country: 'Malaysia',
    code: 'my',
    number: '60',
    mask: '###-### #### #####'
  },
  {
    country: 'Maldives',
    code: 'mv',
    number: '960',
    mask: '###-#### #####'
  },
  {
    country: 'Mali',
    code: 'ml',
    number: '223',
    mask: '## ## ## ## #####'
  },
  {
    country: 'Malta',
    code: 'mt',
    number: '356',
    mask: '#### #### #####'
  },
  {
    country: 'Marshall Islands',
    code: 'mh',
    number: '692',
    mask: '###-#### #####'
  },
  {
    country: 'Martinique',
    code: 'mq',
    number: '596',
    mask: '#### ## ## ## #####'
  },
  {
    country: 'Mauritania',
    code: 'mr',
    number: '222',
    mask: '## ## ## ## #####'
  },
  {
    country: 'Mauritius',
    code: 'mu',
    number: '230',
    mask: '#### #### #####'
  },
  {
    country: 'Mayotte',
    code: 'yt',
    number: '262',
    mask: '#### ## ## ### #####'
  },
  {
    country: 'Mexico',
    code: 'mx',
    number: '52',
    mask: '### ### #### #####'
  },
  {
    country: 'Micronesia',
    code: 'fm',
    number: '691',
    mask: '### #### #####'
  },
  {
    country: 'Moldova',
    code: 'md',
    number: '373',
    mask: '#### ## ### #####'
  },
  {
    country: 'Monaco',
    code: 'mc',
    number: '377',
    mask: '## ## ## ## #####'
  },
  {
    country: 'Mongolia',
    code: 'mn',
    number: '976',
    mask: '#### #### #####'
  },
  {
    country: 'Montenegro',
    code: 'me',
    number: '382',
    mask: '### ### ### #####'
  },
  {
    country: 'Poppins',
    code: 'ms',
    number: '1664',
    mask: '(###) ###-#### #####'
  },
  {
    country: 'Morocco',
    code: 'ma',
    number: '212',
    mask: '####-###### #####'
  },
  {
    country: 'Mozambique',
    code: 'mz',
    number: '258',
    mask: '## ### #### #####'
  },
  {
    country: 'Myanmar',
    code: 'mm',
    number: '95',
    mask: '## ### #### #####'
  },
  {
    country: 'Namibia',
    code: 'na',
    number: '264',
    mask: '### ### #### #####'
  },
  {
    country: 'Nauru',
    code: 'nr',
    number: '674',
    mask: '### #### #####'
  },
  {
    country: 'Nepal',
    code: 'np',
    number: '977',
    mask: '###-####### #####'
  },
  {
    country: 'Netherlands',
    code: 'nl',
    number: '31',
    mask: '## ######## #####'
  },
  {
    country: 'New Caledonia',
    code: 'nc',
    number: '687',
    mask: '##.##.## #####'
  },
  {
    country: 'New Zealand',
    code: 'nz',
    number: '64',
    mask: '### ### #### #####'
  },
  {
    country: 'Nicaragua',
    code: 'ni',
    number: '505',
    mask: '#### #### #####'
  },
  {
    country: 'Niger',
    code: 'ne',
    number: '227',
    mask: '## ## ## ## #####'
  },
  {
    country: 'Nigeria',
    code: 'ng',
    number: '234',
    mask: '#### ### #### #####'
  },
  {
    country: 'Niue',
    code: 'nu',
    number: '683',
    mask: '### #### #####'
  },
  {
    country: 'Norfolk Island',
    code: 'nf',
    number: '672',
    mask: '# ##### #####'
  },
  {
    country: 'North Korea',
    code: 'kp',
    number: '850',
    mask: '#### ### #### #####'
  },
  {
    country: 'Northern Mariana Islands',
    code: 'mp',
    number: '1670',
    mask: '(###) ###-#### #####'
  },
  {
    country: 'Norway',
    code: 'no',
    number: '47',
    mask: '### ## ### #####'
  },
  {
    country: 'Oman',
    code: 'om',
    number: '968',
    mask: '#### #### #####'
  },
  {
    country: 'Pakistan',
    code: 'pk',
    number: '92',
    mask: '#### ####### #####'
  },
  {
    country: 'Palau',
    code: 'pw',
    number: '680',
    mask: '### #### #####'
  },
  {
    country: 'Palestine',
    code: 'ps',
    number: '970',
    mask: '#### ### ### #####'
  },
  {
    country: 'Panama',
    code: 'pa',
    number: '507',
    mask: '####-#### #####'
  },
  {
    country: 'Papua New Guinea',
    code: 'pg',
    number: '675',
    mask: '#### #### #####'
  },
  {
    country: 'Paraguay',
    code: 'py',
    number: '595',
    mask: '#### ###### #####'
  },
  {
    country: 'Peru',
    code: 'pe',
    number: '51',
    mask: '### ### ### #####'
  },
  {
    country: 'Philippines',
    code: 'ph',
    number: '63',
    mask: '#### ### #### #####'
  },
  {
    country: 'Poland',
    code: 'pl',
    number: '48',
    mask: '##### ### ### #####'
  },
  {
    country: 'Portugal',
    code: 'pt',
    number: '351',
    mask: '### ### ### #####'
  },
  {
    country: 'Puerto Rico',
    code: 'pr',
    number: '1',
    mask: '(###) ###-#### #####'
  },
  {
    country: 'Qatar',
    code: 'qa',
    number: '974',
    mask: '##### #### #####'
  },
  {
    country: 'Réunion',
    code: 're',
    number: '262',
    mask: '#### ## ## ## #####'
  },
  {
    country: 'Romania',
    code: 'ro',
    number: '40',
    mask: '#### ### ### #####'
  },
  {
    country: 'Russia',
    code: 'ru',
    number: '7',
    mask: '8 (###) ###-##-## #####'
  },
  {
    country: 'Rwanda',
    code: 'rw',
    number: '250',
    mask: '#### ### ### #####'
  },
  {
    country: 'Saint Barthélemy',
    code: 'bl',
    number: '590',
    mask: '#### ## ## ## #####'
  },
  {
    country: 'Saint Helena',
    code: 'sh',
    number: '290',
    mask: '##### #####'
  },
  {
    country: 'Saint Kitts and Nevis',
    code: 'kn',
    number: '1869',
    mask: '(###) ###-#### #####'
  },
  {
    country: 'Saint Lucia',
    code: 'lc',
    number: '1758',
    mask: '(###) ###-#### #####'
  },
  {
    country: 'Saint Martin',
    code: 'mf',
    number: '590',
    mask: '#### ## ## ## #####'
  },
  {
    country: 'Saint Pierre and Miquelon',
    code: 'pm',
    number: '508',
    mask: '### ## ## #####'
  },
  {
    country: 'Saint Vincent and the Grenadines',
    code: 'vc',
    number: '1784',
    mask: '(###) ###-#### #####'
  },
  {
    country: 'Samoa',
    code: 'ws',
    number: '685',
    mask: '## ##### #####'
  },
  {
    country: 'San Marino',
    code: 'sm',
    number: '378',
    mask: '## ## ## ## #####'
  },
  {
    country: 'São Tomé and Príncipe',
    code: 'st',
    number: '239',
    mask: '### #### #####'
  },
  {
    country: 'Saudi Arabia',
    code: 'sa',
    number: '966',
    mask: '### ### #### #####'
  },
  {
    country: 'Senegal',
    code: 'sn',
    number: '221',
    mask: '## ### ## ## #####'
  },
  {
    country: 'Serbia',
    code: 'rs',
    number: '381',
    mask: '### ### #### #####'
  },
  {
    country: 'Seychelles',
    code: 'sc',
    number: '248',
    mask: '# ### ### #####'
  },
  {
    country: 'Sierra Leone',
    code: 'sl',
    number: '232',
    mask: '(###) ###### #####'
  },
  {
    country: 'Singapore',
    code: 'sg',
    number: '65',
    mask: '#### #### #####'
  },
  {
    country: 'Sint Maarten',
    code: 'sx',
    number: '1721',
    mask: '(###) ###-#### #####'
  },
  {
    country: 'Slovakia',
    code: 'sk',
    number: '421',
    mask: '#### ### ### #####'
  },
  {
    country: 'Slovenia',
    code: 'si',
    number: '386',
    mask: '### ### ### #####'
  },
  {
    country: 'Solomon Islands',
    code: 'sb',
    number: '677',
    mask: '## ##### #####'
  },
  {
    country: 'Somalia',
    code: 'so',
    number: '252',
    mask: '# ####### #####'
  },
  {
    country: 'South Africa',
    code: 'za',
    number: '27',
    mask: '### ### #### #####'
  },
  {
    country: 'South Korea',
    code: 'kr',
    number: '82',
    mask: '###-####-#### #####'
  },
  {
    country: 'South Sudan',
    code: 'ss',
    number: '211',
    mask: '#### ### ### #####'
  },
  {
    country: 'Spain',
    code: 'es',
    number: '34',
    mask: '### ## ## ## #####'
  },
  {
    country: 'Sri Lanka',
    code: 'lk',
    number: '94',
    mask: '### ### #### #####'
  },
  {
    country: 'Sudan',
    code: 'sd',
    number: '249',
    mask: '### ### #### #####'
  },
  {
    country: 'Suriname',
    code: 'sr',
    number: '597',
    mask: '###-#### #####'
  },
  {
    country: 'Svalbard and Jan Mayen',
    code: 'sj',
    number: '47',
    mask: '### ## ### #####'
  },
  {
    country: 'Swaziland',
    code: 'sz',
    number: '268',
    mask: '#### #### #####'
  },
  {
    country: 'Sweden',
    code: 'se',
    number: '46',
    mask: '###-### ## #####'
  },
  {
    country: 'Switzerland',
    code: 'ch',
    number: '41',
    mask: '### ### ## ## #####'
  },
  {
    country: 'Syria',
    code: 'sy',
    number: '963',
    mask: '#### ### ### #####'
  },
  {
    country: 'Taiwan',
    code: 'tw',
    number: '886',
    mask: '#### ### ### #####'
  },
  {
    country: 'Tajikistan',
    code: 'tj',
    number: '992',
    mask: '### ## #### #####'
  },
  {
    country: 'Tanzania',
    code: 'tz',
    number: '255',
    mask: '#### ### ### #####'
  },
  {
    country: 'Thailand',
    code: 'th',
    number: '66',
    mask: '### ### #### #####'
  },
  {
    country: 'Timor-Leste',
    code: 'tl',
    number: '670',
    mask: '#### #### #####'
  },
  {
    country: 'Togo',
    code: 'tg',
    number: '228',
    mask: '## ## ## ## #####'
  },
  {
    country: 'Tokelau',
    code: 'tk',
    number: '690',
    mask: '#### #####'
  },
  {
    country: 'Tonga',
    code: 'to',
    number: '676',
    mask: '### #### #####'
  },
  {
    country: 'Trinidad and Tobago',
    code: 'tt',
    number: '1868',
    mask: '(###) ###-#### #####'
  },
  {
    country: 'Tunisia',
    code: 'tn',
    number: '216',
    mask: '## ### ### #####'
  },
  {
    country: 'Turkey',
    code: 'tr',
    number: '90',
    mask: '#### ### ## ## #####'
  },
  {
    country: 'Turkmenistan',
    code: 'tm',
    number: '993',
    mask: '# ## ###### #####'
  },
  {
    country: 'Turks and Caicos Islands',
    code: 'tc',
    number: '1649',
    mask: '(###) ###-#### #####'
  },
  {
    country: 'Tuvalu',
    code: 'tv',
    number: '688',
    mask: '## #### #####'
  },
  {
    country: 'U.S. Virgin Islands',
    code: 'vi',
    number: '1340',
    mask: '(###) ###-#### #####'
  },
  {
    country: 'Uganda',
    code: 'ug',
    number: '256',
    mask: '#### ###### #####'
  },
  {
    country: 'Ukraine',
    code: 'ua',
    number: '380',
    mask: '### ### #### #####'
  },
  {
    country: 'United Arab Emirates',
    code: 'ae',
    number: '971',
    mask: '### ### #### #####'
  },
  {
    country: 'United Kingdom',
    code: 'gb',
    number: '44',
    mask: '##### ###### #####'
  },
  {
    country: 'United States',
    code: 'us',
    number: '1',
    mask: '(###) ###-#### #####'
  },
  {
    country: 'Uruguay',
    code: 'uy',
    number: '598',
    mask: '### ### ### #####'
  },
  {
    country: 'Uzbekistan',
    code: 'uz',
    number: '998',
    mask: '# ## ### ## ## #####'
  },
  {
    country: 'Vanuatu',
    code: 'vu',
    number: '678',
    mask: '### #### #####'
  },
  {
    country: 'Vatican City',
    code: 'va',
    number: '39',
    mask: '### ### #### #####'
  },
  {
    country: 'Venezuela',
    code: 've',
    number: '58',
    mask: '####-####### #####'
  },
  {
    country: 'Vietnam',
    code: 'vn',
    number: '84',
    mask: '### ### ## ## #####'
  },
  {
    country: 'Wallis and Futuna',
    code: 'wf',
    number: '681',
    mask: '## ## ## #####'
  },
  {
    country: 'Western Sahara',
    code: 'eh',
    number: '212',
    mask: '####-###### #####'
  },
  {
    country: 'Yemen',
    code: 'ye',
    number: '967',
    mask: '#### ### ### #####'
  },
  {
    country: 'Zambia',
    code: 'zm',
    number: '260',
    mask: '### ####### #####'
  },
  {
    country: 'Zimbabwe',
    code: 'zw',
    number: '263',
    mask: '### ### #### #####'
  },
  {
    country: 'Åland Islands',
    code: 'ax',
    number: '358',
    mask: '### ####### #####'
  }
]
