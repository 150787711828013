import { EventSuscriptionGetter } from '../domain/Events/Event'
import {
  IEventsController,
  dataToSubscribeEvents
} from '../domain/Events/EventController'

export default class EventsUseCases {
  static subscribe (
    controller: IEventsController,
    data: dataToSubscribeEvents,
    callback: (response: EventSuscriptionGetter) => void
  ): void {
    return controller.subscribe(data, callback)
  }

  static unsubscribe (controller: IEventsController): void {
    return controller.unsubscribe()
  }
}
