<template>
  <div class="DownloadApp">
    <div class="DownloadApp__logo">
      <KeybeIcon :icon="KeybeIconType.LOGO"
                 :color="Colors.kbGreen"/>
    </div>
    <div class="DownloadApp__titleDecoration">
      <KeybeIcon :icon="KeybeIconType.WIZARD_TITLE_DECORATION_MOBILE"/>
    </div>
    <span class="DownloadApp__title">
      {{ $t('downloadAppTitle') }}
    </span>
    <span class="DownloadApp__subtitle">
      {{ $t('downloadAppSubitle') }}
    </span>

    <div class="DownloadApp__download-button"
         @click="goToAppStore"
         v-if="isiOS">
      <KeybeIcon :icon="KeybeIconType.APPLE_STORE"
                 :color="$vuetify.theme.dark ? 'white' : 'black'"/>
      <div>
        <small>{{$t('availableOn')}}</small>
        <KeybeIcon :icon="KeybeIconType.APPLE_STORE_TEXT"
                   :color="$vuetify.theme.dark ? 'white' : 'black'"
                   class="mt-1"/>
      </div>
    </div>

    <div class="DownloadApp__download-button"
         @click="goToGooglePlay"
         v-else>
      <KeybeIcon :icon="KeybeIconType.GOOGLE_PLAY"/>
      <div>
        <small>{{$t('availableOn')}}</small>
        <KeybeIcon :icon="KeybeIconType.GOOGLE_PLAY_TEXT"
                   :color="$vuetify.theme.dark ? 'white' : 'black'"
                   class="mt-1"/>
      </div>
    </div>
    <LoginFooter class="DownloadApp__footer"/>
  </div>
</template>
<script>
import Colors from '@/shared/domain/colors/Color'
import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'
import LoginFooter from '@/modules/auth/infrastructure/ui/components/LoginFooter.vue'

export default {
  components: {
    KeybeIcon,
    LoginFooter
  },
  computed: {
    isiOS () {
      return [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod'
      ].includes(navigator.platform) ||
      (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
    }
  },
  data () {
    return {
      KeybeIconType,
      Colors,
      googlePlayUrl: 'https://play.google.com/store/apps/details?id=keybebluebird.keybe_blue_bird',
      appleStoreUrl: 'https://apps.apple.com/us/app/keybe/id1598929339'
    }
  },
  methods: {
    goToAppStore () {
      window.open(this.appleStoreUrl, '_blank')
    },
    goToGooglePlay () {
      window.open(this.googlePlayUrl, '_blank')
    }
  }
}
</script>
<style scoped lang="scss">
.DownloadApp {
  display: flex;
  flex-direction: column;
  height: 100vh;
  align-items: center;
  width: 100%;
  padding: 0 25px;
  padding-top: 17%;

  &__logo {
    margin-top: 100px;
    width: 50px;
    height: 22px;
  }

  &__titleDecoration {
    position: absolute;
    top: 200px;
    right: auto;
    left: auto;
  }

  &__title {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    margin-top: 50px;
  }

  &__subtitle {
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;

    @media screen and (max-width: 600px) {
      text-align: center;
      padding: 0 20px;
    }
  }

  &__download-button {
    height: 100px;
    border-radius: 15px;
    width: 90%;
    margin-top: 30%;
    background: var(--v-userDetails-base);
    box-shadow: 0px 0px 21px 20px rgba(73,255,155,0.2);
    display: flex;
    align-items: center;
    justify-content: center;

    div {
      margin-left: 20px;
      margin-top: -10px;

      small {
        font-weight: 700;
        font-size: 12px;
      }
    }
  }
}
</style>
