import {
  IDocumentsDataController,
  dataToGetDocumentsData,
  documentsDataGetter
} from '../../domain/DocumentsController'
import DocumentsRepository from '../repository/Documents.repository'

export default class DocumentsController implements IDocumentsDataController {
  repository: DocumentsRepository

  constructor () {
    this.repository = new DocumentsRepository()
  }

  async getDocuments (data: dataToGetDocumentsData) : Promise<documentsDataGetter> {
    const response = await this.repository.getDocuments(data)
    return response
  }

  cancelGetDocuments () {
    this.repository.cancelGetDocuments()
  }
}
