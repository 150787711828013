import { CatalogTabsEnum } from '@/modules/catalog/domain/models/CatalogTabsEnum'
import CatalogImportsList from '../CatalogImportsList/CatalogImportsList.vue'
import NewCatalogImport from '../NewCatalogImport/NewCatalogImport.vue'
export default {
  components: {
    CatalogImportsList,
    NewCatalogImport
  },
  computed: {
    selectedComponent () {
      const components = {
        [CatalogTabsEnum.IMPORTS_LIST]: CatalogImportsList,
        [CatalogTabsEnum.NEW_IMPORT]: NewCatalogImport
      }
      return components[this.selectedSection] || CatalogImportsList
    }
  },
  data () {
    return {
      selectedSection: CatalogTabsEnum.IMPORTS_LIST,
      noImports: false
    }
  },
  methods: {
    openNewImport () {
      this.selectedSection = CatalogTabsEnum.NEW_IMPORT
    },
    openList () {
      this.selectedSection = CatalogTabsEnum.IMPORTS_LIST
    }
  }
}
