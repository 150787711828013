<template>
  <div class="TemplateComposer__preview_sms">
    <div class="message-sms">
      <p class="message-content">{{ messagePreview }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TemplatePreview',
  props: ['messagePreview'],
  data () {
    return {
      phoneBackground: `${process.env.VUE_APP_STORAGE_URL}/files/bluebird/phone_outlined.svg`
    }
  },
  mounted () {
  },
  methods: {
  }
}
</script>

<style lang="scss">
.TemplateComposer__preview_sms {
  position: relative;
  width: 100%;
  // min-height: 400px;
  overflow: hidden;
  .TemplateComposer__preview_sms--background {
    position: absolute;
    top: 0;
    min-width: 378px;
  }
}

.TemplateComposer__preview_sms--header {
  position: absolute;
  width: 339px;
  height: 70px;
  left: 19px;
  top: 185px;
  display: flex;
  align-items: center;
  padding: 0 16px;
}

.TemplateComposer__preview_sms--headerImage {
  height: 48px;
  width:48px;
  border-radius: 50%;
  background-color:#B6BEC7;
  display: flex;
  align-items: center;
  justify-content:center;
  margin-left: 10px;

  img {
    height: 100%;
    width: 100%;
    border-radius: 50%;
    object-fit: cover;
  }
}

.TemplateComposer__preview_sms--headerText {
  display: flex;
  flex-direction: column;
  margin-left: 6px;

  strong {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
  }

  span {
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
  }
}

.TemplateComposer__preview_sms--message {
  width: 284px;
  position: absolute;
  top: 100px;
  left: 30px;
}

.TemplateComposer__preview_sms--messageText {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  padding: 10px;
  width: 284px;
  border-radius: 4px;
  img {
    margin-bottom: 10px;
  }
}

.TemplateComposer__preview_sms--buttonsContainer {
  display: flex;
  flex-direction: column;
  width: 284px;
  max-height: 500px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.TemplateComposer__preview_sms--button {
  margin-top: 10px;
  min-height: 44px;
  width: 284px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}

.containerSms {
    width: 100%;
    height: 100%;
    padding: 10px;
    align-items: start;
    display: flex;
    justify-content: center;
}

.message-sms {
    overflow-y: hidden;
    overflow-x: hidden;
    position: relative;
    margin-bottom: 10px;
    padding: 10px;
    background-color: rgb(15, 174, 246);
    width: 100%;
    height: auto;
    text-align: left;
    font: 400 .9em 'Open Sans', sans-serif;
    border: 1px solid #525252;
    border-radius: 10px;
}

.message-content {
    color:white;
    padding: 0;
    margin: 0;
}
.message-timestamp-left {
    position: absolute;
    font-size: .85em;
    font-weight: 300;
    bottom: 5px;
    left: 5px;
}

.message-sms:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-bottom: 15px solid rgb(15, 174, 246);
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    bottom: 0;
    right: -15px;
}

.message-sms:before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-bottom: 17px solid #e2e2e2;
    border-left: 16px solid transparent;
    border-right: 16px solid transparent;
    bottom: -1px;
    right: -17px;
}
</style>
