import { EntityType } from '@/modules/CDP/shared/contacts/domain/Contacts'
import { ContactSegment, Responsible } from '@/modules/CDP/shared/contacts/domain/Contact'

export type StatusType =
'created' |
'queued' |
'processing' |
'error' |
'finished' |
'expired'

export enum ImportRecordEnum {
  ROWS = 'rows',
  TOTAL = 'total',
  PROCESS = 'process',
  UPDATED = 'updated',
  NEWS = 'news',
  WRONGS = 'wrongs'
}

export interface Storage {
  provider: string,
  uri: string,
  url: string,
  bucket: string,
  folder: string
}

export interface File {
  name: string,
  internalName: string,
  ext: string,
  size: number,
  mimetype: string
}

export interface Header {
  column: string,
  field: string,
  fieldParent: string,
  category: string
}

export interface Records {
  rows: number,
  total: number,
  process: number,
  updated: number,
  news: number,
  wrongs: number
}

export interface ImporterCreator {
  userId: string,
  type: string,
  name: string,
  lastname: string,
  email: string
}
export interface Importer {
  app: string,
  typeEntity: EntityType,
  categories: string[],
  jobId: string,
  defaultCountryCode: string,
  status: StatusType,
  storage: Storage,
  file: File,
  headers: Header,
  records: Records,
  responsible: Responsible,
  segmentStatic: ContactSegment,
  statusMessage?: string,
  snsInfo?: string,
  createdBy: ImporterCreator,
  updatedBy: ImporterCreator,
  createdAt?: string,
  updatedAt?: string,
  startedAt?: string,
  finishedAt?: string
}

export interface FileUploadData {
  storage: Storage,
  file: File
}
