








export default {
  name: 'MasksIcon',
  props: {
    color: {
      type: String,
      default: '#959AA0'
    }
  }
}
