import KeybeRepository from '@/shared/infrastructure/repositories/KeybeRepository'
import {
  addUserToInboxDTO,
  dataToGetInbox,
  defaultInboxDTO,
  inboxCreateDTO,
  inboxDeleteDTO,
  inboxUpdateDTO,
  removeUserFromInboxDTO
} from '../domain/InboxController'
import { HeadersBuilder } from '@/shared/infrastructure/http/HeadersBuilder'
import { getOptions, nonGetOptions } from '@/shared/infrastructure/http/HttpClient'
import { GenericResponse } from '@/shared/infrastructure/http/GenericResponse'

export default class InboxRepository extends KeybeRepository {
  apiUrl: string

  constructor () {
    super()
    this.apiUrl = process.env.VUE_APP_CONVERSATIONS_API_URL
  }

  async getInbox (data: dataToGetInbox): Promise<GenericResponse> {
    const params = {
      appUUID: data.appUUID,
      userId: data.userId
    }

    if (!data.userId) {
      delete params.userId
    }

    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)

    const requestData: getOptions = {
      url: `${this.apiUrl}/inbox`,
      query: params,
      headers
    }

    return await this.client.get(requestData)
  }

  async createInbox (inbox: inboxCreateDTO): Promise<GenericResponse> {
    const data = {
      appUUID: inbox.appUUID,
      name: inbox.name,
      userId: inbox.userId
    }

    const headers = HeadersBuilder.buildSimpleAutorizationHeader(inbox)

    const requestData: nonGetOptions = {
      url: `${this.apiUrl}/inbox`,
      data,
      headers
    }

    return await this.client.post(requestData)
  }

  async updateInbox (inbox: inboxUpdateDTO): Promise<GenericResponse> {
    const data = {
      inboxId: inbox.inboxId,
      name: inbox.name,
      userId: inbox.userId,
      active: inbox.active,
      line: inbox.line,
      isAdmin: inbox.isAdmin
    }

    // if (!data.isAdmin) {
    //   delete data.isAdmin
    // }

    const headers = HeadersBuilder.buildSimpleAutorizationHeader(inbox)

    const requestData: nonGetOptions = {
      url: `${this.apiUrl}/inbox`,
      data,
      headers
    }

    return await this.client.put(requestData)
  }

  async deleteInbox (inbox: inboxDeleteDTO): Promise<GenericResponse> {
    const data = {
      inboxId: inbox.inboxId,
      userId: inbox.userId,
      isAdmin: inbox.isAdmin
    }

    // if (!data.isAdmin) {
    //   delete data.isAdmin
    // }

    const headers = HeadersBuilder.buildSimpleAutorizationHeader(inbox)

    const requestData: nonGetOptions = {
      url: `${this.apiUrl}/inbox`,
      data,
      headers
    }
    return await this.client.delete(requestData)
  }

  async addUserToInbox (inbox: addUserToInboxDTO): Promise<GenericResponse> {
    const data = {
      inboxId: inbox.inboxId,
      userId: inbox.userId,
      permission: inbox.permission,
      appUUID: inbox.appUUID,
      isAdmin: inbox.isAdmin
    }

    // if (!data.isAdmin) {
    //   delete data.isAdmin
    // }

    const headers = HeadersBuilder.buildSimpleAutorizationHeader(inbox)

    const requestData: nonGetOptions = {
      url: `${this.apiUrl}/inbox/user`,
      data,
      headers
    }

    return await this.client.post(requestData)
  }

  async removeUserFromInbox (inbox: removeUserFromInboxDTO): Promise<GenericResponse> {
    const data = {
      inboxId: inbox.inboxId,
      userId: inbox.userId,
      removeUser: inbox.removeUser,
      appUUID: inbox.appUUID,
      isAdmin: inbox.isAdmin
    }

    // if (!data.isAdmin) {
    //   delete data.isAdmin
    // }

    const headers = HeadersBuilder.buildSimpleAutorizationHeader(inbox)

    const requestData: nonGetOptions = {
      url: `${this.apiUrl}/inbox/user`,
      data,
      headers
    }

    return await this.client.delete(requestData)
  }

  async setDefaultInbox (data: defaultInboxDTO): Promise<GenericResponse> {
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)

    delete data.token

    const requestData: nonGetOptions = {
      url: `${this.apiUrl}/inbox/default`,
      data,
      headers
    }

    return await this.client.post(requestData)
  }
}
