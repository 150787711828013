export default {
  name: 'ScreenDesktop',
  props: {
    dataTocreate: {
      type: Object,
      required: true
    }
  },
  computed: {
    typographyStyle () {
      return `font-family: ${this.dataTocreate.landingConfig.desktop.typography};`
    },
    bubbleStyle () {
      return `background: ${this.dataTocreate.landingConfig.desktop.formFieldColor}; ${this.typographyStyle}`
    },
    buttonStyle () {
      return `background-color: ${this.dataTocreate.landingConfig.desktop.formButtonColor}; color: ${this.dataTocreate.landingConfig.desktop.formTextColor}; ${this.typographyStyle}`
    },
    titleStyle () {
      return `color: ${this.dataTocreate.landingConfig.desktop.titleColor}; text-align: ${this.dataTocreate.landingConfig.desktop.titleTextAlign}; ${this.typographyStyle}`
    },
    subtitleStyle () {
      return `color: ${this.dataTocreate.landingConfig.desktop.messageColor}; text-align: ${this.dataTocreate.landingConfig.desktop.titleTextAlign}; ${this.typographyStyle}`
    },
    chatContainerStyle () {
      return `background: ${this.dataTocreate.landingConfig.desktop.formBackgroundColor}; ${this.typographyStyle}`
    },
    containerPositionClass (): string {
      switch (this.dataTocreate.landingConfig.desktop.formPosition) {
        case 'Left':
          return 'ScreenDesktop__container--left'
        case 'Center':
          return 'ScreenDesktop__container--center'
        case 'Right':
          return 'ScreenDesktop__container--right'
        default:
          return 'ScreenDesktop__container--left'
      }
    },
    titleContainerPositionClass () {
      switch (this.dataTocreate.landingConfig.desktop.formPosition) {
        case 'Left':
          return 'ScreenDesktop__titleContainer--left'
        case 'Right':
          return 'ScreenDesktop__titleContainer--right'
        case 'Center':
          return 'ScreenDesktop__titleContainer--center'
        default:
          return 'ScreenDesktop__titleContainer--left'
      }
    }
  }
}
