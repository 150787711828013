import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'
import KBButton from '@/shared/infrastructure/ui/buttons/KBButton.vue'
import { dataToGetOutboundDuplicate } from '@/modules/outbound/domain/OutboundController'
import OutboundUseCases from '@/modules/outbound/application/OutboundUseCases'
import { mapState } from 'vuex'
import OutboundController from '@/modules/outbound/infrastructure/controllers/Outbound.controller'

export default {
  props: {
    contactCount: {
      type: Number,
      default: 0
    },
    summaryData: {
      type: Object
    }
  },
  name: 'OutBoundAlertDuplicate',
  components: {
    KBButton,
    KeybeIcon
  },
  data () {
    return {
      KeybeIconType,
      loading: false,
      outboundController: new OutboundController()
    }
  },
  computed: {
    ...mapState('UserStore', ['token']),
    getContactCount (): string {
      if (this.contactCount > 1) {
        return `${this.contactCount} ${this.$t('contactText')}s`
      }
      return `${this.contactCount} ${this.$t('contactText')}`
    }
  },
  methods: {
    callClose () {
      this.$parent.callClose()
    },
    async removeDuplicate () {
      try {
        this.loading = true
        const data:dataToGetOutboundDuplicate = {
          token: this.token,
          campaigns: this.summaryData.id ? this.summaryData.id : this.summaryData._id
        }
        const response = await OutboundUseCases.removeOutboundDuplicate(this.outboundController, data)
        if (response) {
          this.callClose()
          this.loading = false
        }
      } catch (error) {
        this.loading = false
      }
    }
  }
}
