export default {
  name: 'KBDrawer',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    forceWidth: {
      type: String,
      default: ''
    },
    drawerBody: {
      type: String,
      required: true,
      default: ''
    }
  },
  mounted () {
    if (this.forceWidth) this.style = `width: ${this.forceWidth}`
    else this.style = 'width: 445px'
  },
  data () {
    return {
      animateClass: '',
      style: '',
      contentStyle: ''
    }
  },
  methods: {
    close () {
      this.style = 'width: 0'
      this.contentStyle = 'display: none'
      setTimeout(() => {
        this.$emit('close')
      }, 500)
    }
  },
  watch: {
    value () {
      this.contentStyle = ''
      if (!this.value) {
        this.contentStyle = 'display: none'
        this.close()
      } else {
        if (this.forceWidth) this.style = `width: ${this.forceWidth}`
        else this.style = 'width: 445px'
      }
    }
  }
}
