<template>
  <div class="FacebookFormConfig">
    <div class="FacebookFormConfig__item">
      <span class="FacebookFormConfig__item--label">{{ $t('pageId') }}</span>
      <KBText
        :disabled="true"
        v-model="localConfig.pageId"
        :autofocus="false"/>
    </div>
    <div class="FacebookFormConfig__item">
      <span class="FacebookFormConfig__item--label">{{ $t('igId') }}</span>
      <KBText
        :disabled="true"
        v-model="localConfig.instagramId"
        :autofocus="false"/>
    </div>
    <div class="FacebookFormConfig__item">
      <span class="FacebookFormConfig__item--label">{{ $t('verificationToken') }}</span>
      <KBText v-model="localConfig.pageAccessToken"
              :autofocus="false"/>
    </div>
    <div class="FacebookFormConfig__buttonsContainer">
      <KBButton
        class="mr-4"
        :loading="loading"
        @click.native="updateInstagramInfo()"
        :text="$t('save')"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { EventBus } from '@/eventBus'
import ConversationsConfigService from '@/components/configuration/Services/ConversationsConfigService'
import KBText from '@/shared/infrastructure/ui/inputs/text/KBText.vue'
import KBButton from '@/shared/infrastructure/ui/buttons/KBButton.vue'

export default {
  name: 'InstagramFormConfig',
  components: {
    KBText,
    KBButton
  },
  props: {
    config: {
      required: false
    },
    edit: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      loading: false,
      localConfig: {
        instagramId: '',
        pageId: '',
        pageAccessToken: ''
      }
    }
  },
  computed: {
    ...mapState('AppStore', ['selectedApp']),
    ...mapState('CompanyStore', ['selectedCompany']),
    instagramConfigValid () {
      return this.localConfig.instagramId && this.localConfig.pageAccessToken && this.localConfig.pageId
    }
  },
  methods: {
    copy (data) {
      navigator.clipboard.writeText(data).then(() => {
        EventBus.$emit('toast', 'success', this.$t('copiedToClipboard'))
      }).catch(() => {
        EventBus.$emit('toast', 'error', this.$t('errorCopyingToClipboard'))
      })
    },
    async updateInstagramInfo () {
      if (!this.instagramConfigValid) {
        EventBus.$emit('toast', 'error', this.$t('allFieldsRequired'))
        return
      }
      try {
        const data = {
          ...this.localConfig,
          appUUID: this.selectedApp?.uuid,
          companyUUID: this.selectedCompany?.uuid
        }
        await ConversationsConfigService.updateInstagramConfig(data)
        EventBus.$emit('toast', 'success', this.$t('configSaved'))
        this.$emit('refresh')
      } catch {
        EventBus.$emit('toast', 'error', this.$t('errorSavingConfig'))
      }
    }
  },
  watch: {
    config: {
      immediate: true,
      handler () {
        if (!this.config) {
          this.localConfig = {
            instagramId: '',
            pageId: '',
            pageAccessToken: ''
          }
          return
        }
        this.localConfig = {
          instagramId: this.config.instagram_id,
          pageId: this.config.page_id,
          pageAccessToken: this.config.verification_token
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.FacebookFormConfig {
  &__item {
    height: 110px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__buttonsContainer {
    margin-top: 20px;
    display: flex;
    width: 256px;
    height: 70px;
  }
}
</style>
