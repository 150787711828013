
























import { VEmojiPicker } from 'v-emoji-picker'

export default {
  name: 'KeybeEmojiPicker',
  props: {
    open: {
      type: Boolean,
      default: false
    },
    position: {
      type: Object,
      default: () => ({ bottom: '80px', left: '70px' })
    }
  },
  components: {
    VEmojiPicker
  }
}
