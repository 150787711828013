import { SpacesAccessEnum, SpaceConfig, SpaceUser } from '@/modules/Spaces/domain/Spaces'
import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'
import KBSwitch from '@/shared/infrastructure/ui/inputs/select/KBSwitch.vue'
import KBText from '@/shared/infrastructure/ui/inputs/text/KBText.vue'
import { mapGetters, mapState } from 'vuex'
import KBButton from '@/shared/infrastructure/ui/buttons/KBButton.vue'
import ChatConfigController from '@/modules/ChatConfig/infrastructure/ChatConfig.controller'
import Loader from '@/shared/infrastructure/ui/Loader.vue'
import KeybeAvatar from '@/shared/infrastructure/ui/KeybeAvatar.vue'

export default {
  name: 'SpacesConfigList',
  props: {
    inbox: {
      type: Object,
      required: true
    },
    selectedInbox: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    config: {
      type: Object,
      required: true
    }
  },
  components: {
    KeybeIcon,
    KBSwitch,
    KBText,
    KBButton,
    Loader,
    KeybeAvatar
  },
  data () {
    return {
      KeybeIconType,
      hovered: '',
      isCreating: false,
      newInbox: '',
      editingInbox: null,
      editingInboxName: '',
      chatConfigController: new ChatConfigController(),
      ownerInbox: '',
      photo: ''
    }
  },
  methods: {
    isInboxHovered (inbox: SpaceConfig): boolean {
      return this.hovered === inbox?._id
    },
    isSelectedInbox (inbox: SpaceConfig): boolean {
      return this.selectedInboxId === inbox?._id
    },
    hoverInbox (inbox: SpaceConfig): void {
      this.hovered = inbox?._id
    },
    getInboxName (inbox: SpaceConfig): string {
      return inbox?.name || ''
    },
    isInboxActive (inbox): boolean {
      return inbox?.active || false
    },
    getAdviserName (): void {
      const users: SpaceUser[] = this.inbox?.users
      const owner: SpaceUser[] = users.filter(e => e.access === 'DELETE')
      const adviser: Object = this.advisers.filter(e => e.uuid === owner[0]?.userId)

      if ((this.inbox.key === 'taller' || this.inbox.key === 'ventas' || this.inbox.key === 'servicio_al_cliente') && owner.length === 0) {
        this.ownerInbox = 'Biky'
        this.photo = this.bkyAvatar
      } else {
        this.ownerInbox = adviser[0]?.name || ''
        this.photo = adviser[0]?.photo || adviser[0]?.image
      }
    },
    canEditInbox (inbox: SpaceConfig): boolean {
      const user: SpaceUser = inbox?.users?.find(user => user?.userId === this.user?.userId)
      if (this.isSuperAdmin) return true
      return ((user?.access === SpacesAccessEnum.EDIT || user?.access === SpacesAccessEnum.DELETE)) || false
    },
    canDeleteInbox (inbox: SpaceConfig): boolean {
      const user: SpaceUser = inbox?.users?.find(user => user?.userId === this.user?.userId)
      if (this.isSuperAdmin) return true
      return (user?.access === SpacesAccessEnum.DELETE && !inbox?.default) || false
    },
    isEditingInbox (inbox: SpaceConfig): boolean {
      return this.editingInbox?.key === inbox?.key
    },
    setEditingInbox (inbox: SpaceConfig): void {
      if (this.isUserReadonly) return
      this.editingInbox = inbox
      this.editingInboxName = inbox?.name || ''
    },
    updateInbox (): void {
      if (this.loading) return
      this.$emit('updateInbox', {
        ...this.editingInbox,
        name: this.editingInboxName
      })
    },
    setAsMain (inbox): void {
      if (this.isUserReadonly) return
      this.$emit('setAsMain', inbox)
    },
    deleteInbox (inbox: SpaceConfig): void {
      if (this.isUserReadonly) return
      this.$emit('deleteInbox', inbox)
    }
  },
  computed: {
    ...mapGetters('ChatConfigStore', ['hasInboxConfig', 'getConfig']),
    ...mapState('AppStore', ['advisers']),
    ...mapState('AppStore', ['selectedApp']),
    ...mapState('UserStore', ['user', 'token', 'isUserReadonly']),
    ...mapGetters('UserStore', ['isSuperAdmin', 'isAdminOrAbove']),
    bkyAvatar () {
      return `${process.env.VUE_APP_STORAGE_URL}/files/ai/biky_1.webp`
    },
    selectedInboxId (): string {
      return this.selectedInbox?._id || ''
    },
    filteredAdvisers () {
      const advisers = this.advisers.filter(adviser => {
        const matchingUser = this.inbox.users.find(user => user?.userId === adviser?.userId)
        return matchingUser
      })
      return advisers
    },
    advisersComputed () {
      return this.filteredAdvisers.filter(adviser => {
        let name
        if (adviser?.lastName) {
          name = `${adviser?.name} ${adviser?.lastName}`
        } else {
          name = adviser?.name || ''
        }
        return name.toLowerCase().includes(this.search.toLowerCase())
      })
    }
  },
  mounted () {
    this.getAdviserName()
  },
  watch: {
    loading (value) {
      if (!value) {
        this.isCreating = false
        this.newInbox = ''
        this.editingInbox = null
        this.editingInboxName = ''
      }
    }
  }
}
