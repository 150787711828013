
import { FloatingWindowsStore } from '@/shared/infrastructure/persistence/FloatingWindowsStore'
import { LayoutStore } from '@/shared/infrastructure/persistence/LayoutStore'
import { BannerStore } from '@/store/modules/BannerStore'
import { BillingStore } from '@/store/modules/BillingStore'
import { BusinessStore } from '@/store/modules/BusinessStore'
import { CampaingsStore } from '@/store/modules/CampaingsStore'
import { ConversationsFilterStore } from '@/store/modules/ConversationsFilterStore'
import { EventsStore } from '@/store/modules/EventsStore'
import { ExtensionStore } from '@/store/modules/ExtensionStore'
import { FormsStore } from '@/store/modules/FormsStore'
import { MetricsStore } from '@/store/modules/MetricsStore'
import { OpportunityStore } from '@/store/modules/OpportunityStore'
import { PipelinesStore } from '@/store/modules/PipelinesStore'
import { SegmentsStore } from '@/store/modules/SegmentsStore'
import { TriggersStore } from '@/store/modules/TriggersStore'
import { UsersStore } from '@/store/modules/UsersStore'
import Vue from 'vue'
import Vuex from 'vuex'
import { ConversationStore } from '../modules/conversations/infrastructure/persistence/store/ConversationStore'
import { RoomFiltersStore } from '../modules/conversations/infrastructure/persistence/store/RoomFiltersStore'
import { CommissionStore } from '@/store/modules/CommissionStore'
import { TasksStore } from '@/modules/tasks/infrastructure/persistence/TasksStore'
import { ChatConfigStore } from '@/modules/ChatConfig/infrastructure/persistence/ChatConfigStore'
import { PeopleStore } from '@/modules/CDP/people/infrastructure/persistence/PeopleStore'
import { BusinessesStore } from '@/modules/CDP/businesses/infrastructure/persistence/BusinessesStore'
import { PeopleFiltersStore } from '@/modules/CDP/people/infrastructure/persistence/PeopleFiltersStore'
import { BusinessesFiltersStore } from '@/modules/CDP/businesses/infrastructure/persistence/BusinessesFiltersStore'
import { SalesStore } from '@/modules/Sales/shared/infrastructure/persistence/SalesStore'
import { SalesFiltersStore } from '@/modules/Sales/shared/infrastructure/persistence/SalesFiltersStore'
import { OnboardingStore } from '@/modules/SignInOnboarding/infrastructure/persistence/OnboardingStore'
import { AppStore } from '../shared/infrastructure/persistence/AppStore'
import { CompanyStore } from '../shared/infrastructure/persistence/CompanyStore'
import { UserStore } from '../shared/infrastructure/persistence/UserStore'
import { BikyStore } from '@/modules/bky/infrastructure/persistence/BikyStore'
Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    UserStore,
    UsersStore,
    ConversationStore,
    AppStore,
    BusinessStore,
    CompanyStore,
    PipelinesStore,
    SegmentsStore,
    FormsStore,
    BillingStore,
    ConversationsFilterStore,
    TriggersStore,
    ExtensionStore,
    BannerStore,
    EventsStore,
    OpportunityStore,
    FloatingWindowsStore,
    LayoutStore,
    CampaingsStore,
    MetricsStore,
    RoomFiltersStore,
    CommissionStore,
    TasksStore,
    ChatConfigStore,
    PeopleStore,
    BusinessesStore,
    PeopleFiltersStore,
    BusinessesFiltersStore,
    SalesStore,
    SalesFiltersStore,
    OnboardingStore,
    BikyStore
  },
  mutations: {
    initialiseStore (state) {
      if (localStorage.getItem(process.env.VUE_APP_STORE_NAME)) {
        this.replaceState(
          Object.assign(state, JSON.parse(localStorage.getItem(process.env.VUE_APP_STORE_NAME)))
        )
      }
    }
  }
})
store.subscribe((mutation, state) => {
  localStorage.setItem(process.env.VUE_APP_STORE_NAME, JSON.stringify(state))
})

// @ts-ignore
window.initialState = store.state
export default store
