
























import { mapState } from 'vuex'
import { EventBus } from '@/eventBus'
import ConversationsConfigService from '@/components/configuration/Services/ConversationsConfigService'

import KBText from '@/shared/infrastructure/ui/inputs/text/KBText.vue'
import KBButton from '@/shared/infrastructure/ui/buttons/KBButton.vue'

export default {
  name: 'FacebookFormConfig',
  components: {
    KBText,
    KBButton
  },
  props: {
    config: {
      required: false
    },
    edit: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      loading: false,
      localConfig: {
        pageId: '',
        pageAccessToken: ''
      }
    }
  },
  computed: {
    ...mapState('AppStore', ['selectedApp']),
    ...mapState('CompanyStore', ['selectedCompany']),
    facebookConfigValid () {
      return this.localConfig.pageId && this.localConfig.pageAccessToken
    }
  },
  watch: {
    config: {
      immediate: true,
      handler () {
        if (!this.config) {
          this.localConfig = {
            pageId: '',
            pageAccessToken: ''
          }
          return
        }
        this.localConfig = {
          pageId: this.config?.page_id[0],
          pageAccessToken: this.config?.verification_token
        }
      }
    }
  },
  methods: {
    copy (data) {
      navigator.clipboard.writeText(data).then(() => {
        EventBus.$emit('toast', 'success', this.$t('copiedToClipboard'))
      }).catch(() => {
        EventBus.$emit('toast', 'error', this.$t('errorCopyingToClipboard'))
      })
    },
    async updateFacebookInfo () {
      if (!this.facebookConfigValid) {
        EventBus.$emit('toast', 'error', this.$t('allFieldsRequired'))
        return
      }
      try {
        const data = {
          ...this.localConfig,
          appUUID: this.selectedApp?.uuid,
          companyUUID: this.selectedCompany?.uuid
        }
        await ConversationsConfigService.updateFacebookConfig(data)
        this.$emit('refresh')
        EventBus.$emit('toast', 'success', this.$t('configSaved'))
      } catch (e) {
        EventBus.$emit('toast', 'error', this.$t('errorSavingConfig'))
      }
    }
  }
}
