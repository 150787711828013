export const OpportunityStore = {
  namespaced: true,
  state: {
    createdOpportunity: false
  },
  mutations: {
    SET_EDITING_OBJECT (state, payload) {
      state[payload.key] = payload.value
    }
  }
}
