import store from '@/store'
import axios from 'axios'
import { RestResponseFactory } from '@/models/apis/RestResponse'
import { StatusCodes } from '@/models/apis/StatusCodes'

export default class DevicesService {
  static async getDevices (pagination) {
    const token = store.getters['UserStore/getToken']
    const url = `${process.env.VUE_APP_AUTH_SERVICE}devices/logs`

    try {
      const response = await axios.get(url,
        {
          headers: {
            Authorization: 'Bearer ' + token
          },
          params: {
            limit: pagination.limit,
            page: pagination.page
          }
        }
      )
      return new RestResponseFactory().process({ response })
    } catch (e) {
      return new RestResponseFactory().process({ response: { status: StatusCodes.HTTP_500_INTERNAL_SERVER_ERROR } })
    }
  }

  static async getActiveSessions (pagination) {
    const token = store.getters['UserStore/getToken']
    const url = `${process.env.VUE_APP_AUTH_SERVICE}devices/active`

    try {
      const response = await axios.get(url,
        {
          headers: {
            Authorization: 'Bearer ' + token
          },
          params: {
            limit: pagination.limit,
            page: pagination.page
          }
        }
      )
      return new RestResponseFactory().process({ response })
    } catch (e) {
      return new RestResponseFactory().process({ response: { status: StatusCodes.HTTP_500_INTERNAL_SERVER_ERROR } })
    }
  }

  static async logoutDevice (uuid) {
    try {
      const token = store.getters['UserStore/getToken']
      const url = `${process.env.VUE_APP_AUTH_SERVICE}devices/${uuid}`
      const response = await axios.delete(url,
        {
          headers: {
            Authorization: 'Bearer ' + token
          }
        }
      )
      return new RestResponseFactory().process({ response })
    } catch (e) {
      return new RestResponseFactory().process({ response: { status: 500 } })
    }
  }

  static async getLocationByIP (ip) {
    try {
      const url = `https://pro.ip-api.com/json/${ip}`
      const response = await axios.get(url,
        {
          params: {
            key: 'rUKq4n5qe7lyd65'
          }
        }
      )
      return new RestResponseFactory().process({ response })
    } catch (e) {
      return new RestResponseFactory().process({ response: { status: StatusCodes.HTTP_500_INTERNAL_SERVER_ERROR } })
    }
  }
}
