





























import BikyConfigChatAudioMessage from './BikyConfigChatAudioMessage'

export default BikyConfigChatAudioMessage
