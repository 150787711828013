






























import ConfigFormBiky from './ConfigFormBiky'
export default ConfigFormBiky
