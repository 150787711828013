import { KeybeIconType } from '../icons/KeybeIconType'

export enum FloatingWindowType {
  MY_KEYBE = 'myKeybe',
  MOMENTS = 'moments',
  CAMPAIGNS = 'campaigns',
  CAMPAIGNSFORM = 'campaignsform',
  EXTENSION = 'extension',
  BILLING = 'billing',
  BILLING_KB = 'billingKb',
  METRICS = 'metrics',
  ONBOARDING = 'onboarding',
  FLOATING = 'floating',
  AI = 'ai',
  CONFIGURATION = 'configuration',
  TASK_CENTER = 'taskCenter',
  IMPORTS = 'imports',
  TASKS = 'tasks',
  WHATSAPP_MIGRATE = 'whatsappMigrate',
  CHAT_FILTERS = 'chatFilters',
  BIKY_CONFIG = 'bikyConfig',
  KB_DRIVE = 'kbDrive',
  PARTNERS = 'partners',
  CATALOG = 'catalog',
}

export const getFloatingWindowIcon = (window: FloatingWindowType) : string => {
  const icons = {
    [FloatingWindowType.MY_KEYBE]: KeybeIconType.PLANET,
    [FloatingWindowType.MOMENTS]: KeybeIconType.MOMENTS,
    [FloatingWindowType.CAMPAIGNS]: KeybeIconType.CAMPAINS,
    [FloatingWindowType.CAMPAIGNSFORM]: KeybeIconType.CAMPAINS,
    [FloatingWindowType.EXTENSION]: KeybeIconType.EXTENSIONS,
    [FloatingWindowType.BILLING]: KeybeIconType.CASH,
    [FloatingWindowType.BILLING_KB]: KeybeIconType.CASH,
    [FloatingWindowType.METRICS]: KeybeIconType.METRICS,
    [FloatingWindowType.ONBOARDING]: KeybeIconType.NUMLIST,
    [FloatingWindowType.FLOATING]: KeybeIconType.EXTERNAL,
    [FloatingWindowType.AI]: KeybeIconType.CRYSTAL_BALL,
    [FloatingWindowType.CONFIGURATION]: KeybeIconType.TOOL,
    [FloatingWindowType.TASK_CENTER]: KeybeIconType.BELL,
    [FloatingWindowType.IMPORTS]: KeybeIconType.ARROW_DOWN,
    [FloatingWindowType.TASKS]: KeybeIconType.TASKS,
    [FloatingWindowType.WHATSAPP_MIGRATE]: KeybeIconType.WHATSAPP,
    [FloatingWindowType.CHAT_FILTERS]: KeybeIconType.FILTERS,
    [FloatingWindowType.BIKY_CONFIG]: KeybeIconType.BRAIN,
    [FloatingWindowType.KB_DRIVE]: KeybeIconType.UPLOAD_CLOUD,
    [FloatingWindowType.CATALOG]: KeybeIconType.SHOPPING_CART
  }
  return icons[window]
}
