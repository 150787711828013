export const PipelinesStore = {
  namespaced: true,
  state: {
    pipelineId: null,
    opProducts: [],
    lastSelectedPipelineId: '',
    dragged: {},
    moveToStep: {},
    contextMenuPipeline: {},
    opportunitiesFilter: {},
    ticketsFilter: {},
    filterActive: false,
    timeFilter: '',
    oppenedOpportunity: {},
    creatingOpportunity: {},
    creatingUsers: []
  },
  mutations: {
    SET_SELECTED_PIPELINE (state, pipelineId) {
      state.pipelineId = pipelineId
    },
    SET_OPPORTUNITY_PRODUCTS (state, opProducts) {
      state.opProducts = opProducts
    },
    SET_DRAGGED_OPPORTUNITY (state, dragged) {
      state.dragged = dragged
    },
    SET_STEP_DROPPED (state, moveToStep) {
      state.moveToStep = moveToStep
    },
    SET_CONTECT_MENU_PIPELINE (state, pipeline) {
      state.contextMenuPipeline = pipeline
    },
    SET_OPPORTUNITIES_FILTER (state, filters) {
      state.opportunitiesFilter = filters
    },
    SET_TICKETS_FILTER (state, filters) {
      state.ticketsFilter = filters
    },
    SET_FILTER_ACTIVE (state, active) {
      state.filterActive = active
    },
    SET_TIME_FILTER (state, time) {
      state.timeFilter = time
    },
    SET_OPENED_OPPORTUNITY (state, op) {
      state.oppenedOpportunity = op
    },
    SET_CREATING_OPPORTUNITY (state, op) {
      state.creatingOpportunity = op
    },
    SET_CREATING_OPPORTUNITY_USERS (state, users) {
      state.creatingUsers = users
    }
  },
  actions: {
    setSelectedPipeline (context, pipelineId: String) {
      context.commit('SET_SELECTED_PIPELINE', pipelineId)
    },
    setContextMenuPipeline (context, pipeline) {
      context.commit('SET_CONTECT_MENU_PIPELINE', pipeline)
    }
  },
  getters: {
    getPipelineId (state) {
      return state.pipelineId
    }
  }
}
