<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       fill="none"
       viewBox="0 0 15 15"><rect width="15"
                                 height="15"
                                 :fill="color"
                                 rx="4"/><path fill="#fff"
                                               stroke="#fff"
                                               stroke-width=".343"
                                               d="M6.094 9.226 4.246 7.38l-.121-.121-.121.12-.656.657-.122.121.122.121 2.625 2.626.12.12.122-.12 5.625-5.626.121-.12-.121-.122-.656-.656-.121-.121-.122.12-4.847 4.848Z"/></svg>
</template>

<script>
export default {
  name: 'CheckedBoxIcon',
  props: {
    color: {
      type: String,
      default: '#3AF69B'
    }
  }

}
</script>

<style>

</style>
