<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       fill="none"
       viewBox="0 0 13 10"><path :fill="color"
                                 d="M1.731 4.41h1.327c.153-.483.274-.573.79-.57.353.003.707-.003 1.057.032.268.026.44.212.452.483.02.422.021.846 0 1.267-.014.297-.202.486-.523.509a9.757 9.757 0 0 1-1.218.002c-.324-.018-.51-.22-.542-.567H1.722c-.004.066-.01.122-.01.179v2.2c0 .266.065.328.334.329h1c.016-.02.027-.025.029-.034.09-.403.267-.544.686-.544h.93c.448 0 .677.224.68.667.003.326.003.653 0 .98-.004.425-.234.653-.657.656-.316.002-.63 0-.947 0-.427 0-.607-.144-.7-.578h-.18c-.39-.008-.783.003-1.17-.031C1.106 9.336.64 8.842.587 8.224a3.564 3.564 0 0 1-.013-.32c0-1.81-.002-3.619.002-5.428 0-.13-.021-.2-.17-.225a.467.467 0 0 1-.395-.446 19.576 19.576 0 0 1 0-1.331.45.45 0 0 1 .426-.45c.457-.028.918-.033 1.374-.006.28.018.451.212.464.488.02.422.021.846-.001 1.267-.015.263-.174.44-.447.497-.045.01-.11.077-.11.117-.007.653-.004 1.306-.003 1.96 0 .02.01.04.018.065L1.73 4.41ZM8.08 1.904H4.677c-.42 0-.67-.17-.79-.524a.746.746 0 0 1 .589-.989 1.45 1.45 0 0 1 .223-.018c2.253-.001 4.506 0 6.76-.002.352 0 .648.106.79.458.19.475-.057 1.075-.748 1.075H8.08ZM9.61 5.759c-.62 0-1.24.002-1.862 0-.403-.003-.648-.165-.773-.504a.747.747 0 0 1 .593-1.011c.069-.01.138-.017.207-.017h3.693c.34 0 .624.105.771.437.243.547-.118 1.087-.734 1.094-.631.006-1.263.001-1.894.001ZM9.612 9.613c-.621 0-1.242.002-1.863 0-.404-.003-.649-.165-.773-.503a.748.748 0 0 1 .59-1.012c.07-.01.139-.017.208-.017h3.692c.34 0 .625.103.773.435.244.548-.117 1.089-.733 1.096-.631.007-1.263.001-1.894.001Z"/></svg>
</template>

<script>
export default {
  name: 'LinkedListIcon',
  props: {
    color: {
      type: String,
      default: '#fff'
    }
  }
}
</script>

<style>

</style>
