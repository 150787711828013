<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       fill="none"
       viewBox="0 0 16 14"><path :fill="color"
                                 d="M16 4.253C16 1.908 14.122 0 11.814 0 10.12 0 8.658 1.027 8 2.5 7.342 1.027 5.88 0 4.187 0 1.877 0 0 1.908 0 4.253a4.28 4.28 0 0 0 1.482 3.243l6.386 6.444a.199.199 0 0 0 .285 0l6.623-6.684v-.001A4.272 4.272 0 0 0 16 4.253Z"/></svg>
</template>

<script>
export default {
  name: 'HeartFilledIcon',
  props: {
    color: {
      type: String,
      default: '#F7685B'
    }
  }
}
</script>

<style>

</style>
