import moment from 'moment'
import { EventBus } from '@/eventBus'
import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import DateTimePickerRow from '@/modules/outbound/infrastructure/ui/components/DateTimePickerRow.vue'
import { billCampaings } from '@/modules/outbound/infrastructure/ui/components/methods/billCampaings'
import CampaignsService from '@/services/CampaignsService'
import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'
import TitleDetail from '@/modules/metrics/infrastructure/ui/components/generic/TitleDetail.vue'
import KBCard from '@/shared/infrastructure/ui/KBCard.vue'
import KBButton from '@/shared/infrastructure/ui/buttons/KBButton.vue'
import USDCard from '@/modules/outbound/infrastructure/ui/components/cards/USDCard.vue'
import { mapState } from 'vuex'

import BasicMetrics from '@/modules/outbound/infrastructure/ui/components/Detail/BasicMetrics/BasicMetrics.vue'
import BasicCard from '@/modules/outbound/infrastructure/ui/components/cards/BasicCard.vue'
import TemplatePreviewOld from '@/modules/outbound/infrastructure/ui/components//TemplatePreview/TemplatePreview.vue'
import TemplatePreviewSms from '@/modules/outbound/infrastructure/ui/components/metrics/TemplatePriewSms.vue'
import ReadytoSend from '@/modules/outbound/infrastructure/ui/components/Detail/ReadytoSend/ReadytoSend.vue'
import Importing from '@/modules/outbound/infrastructure/ui/components/Detail/Importing/Importing.vue'
import Scheduled from '@/modules/outbound/infrastructure/ui/components/Detail/Scheduled/Scheduled.vue'
import TemplatePreview from '@/modules/templates/infrastructure/ui/TemplatePreview/TemplatePreview.vue'

import OutboundController from '@/modules/outbound/infrastructure/controllers/Outbound.controller'
import OutboundAlertDuplicate from '@/modules/outbound/infrastructure/ui/components/Detail/OutboundAlertDuplicate/OutboundAlertDuplicate.vue'
import { Outbound } from '@/modules/outbound/domain/models/Outbound'

export default {
  name: 'OutBoundDetail',
  components: {
    OutboundAlertDuplicate,
    Scheduled,
    Importing,
    ReadytoSend,
    BasicMetrics,
    DateTimePickerRow,
    KeybeIcon,
    USDCard,
    TitleDetail,
    KBCard,
    KBButton,
    BasicCard,
    TemplatePreview,
    TemplatePreviewOld,
    TemplatePreviewSms
  },
  mounted () {
    this.getCampaignId()
    this.asignData()
  },
  data () {
    return {
      contactDuplicateCount: null,
      contactDuplicate: false,
      outboundController: new OutboundController(),
      costData: {
        totalAmountToPay: 0,
        totalChannelsMultiparts: 0
      },
      campaignElement: null,
      moment,
      KeybeIconType,
      loading: false,
      cardLoading: false,
      dataText: null,
      decimal: null,
      USDCardContent: USDCard,
      BasicCard: BasicCard,
      provider: {},
      templateMediaUrl: null,
      messagePreview: null,
      buttonsLabels: null,
      status: null,
      statusId: null,
      disabledModal: false,
      requestCount: 1,
      templateVariables: {
        templateMessage: '',
        templateId: '',
        templateParams: [],
        buttonParams: [],
        mediaParams: [],
        headerParams: [],
        valid: false,
        error: ''
      }
    }
  },
  computed: {
    ...mapState('UserStore', ['token']),
    userHost () {
      if (!this.summaryData) {
        return
      }
      if (this.summaryData.whatsApp) {
        const host = Object.prototype.hasOwnProperty.call(this.summaryData.whatsApp, 'userHost')
        if (host) {
          return this.summaryData.whatsApp.userHost
        }
      }
    },
    sendNowSuccess: {
      get () {
        return this.$store.state.CampaingsStore.sendNowSuccess || null
      },
      set (value) {
        this.$store.commit('CampaingsStore/SET_EDITING_OBJECT', {
          key: 'sendNowSuccess',
          value
        })
      }
    },
    summaryData: {
      get () {
        return this.$store.state.CampaingsStore.summaryData || null
      },
      set (value) {
        this.$store.commit('CampaingsStore/SET_EDITING_OBJECT', {
          key: 'summaryData',
          value
        })
      }
    },
    outboundStatus: {
      get () {
        return this.$store.state.CampaingsStore.outboundStatus || null
      },
      set (value) {
        this.$store.commit('CampaingsStore/SET_EDITING_OBJECT', {
          key: 'outboundStatus',
          value
        })
      }
    },
    outboundName: {
      get () {
        return this.$store.state.CampaingsStore.outboundName || null
      },
      set (value) {
        this.$store.commit('CampaingsStore/SET_EDITING_OBJECT', {
          key: 'outboundName',
          value
        })
      }
    },
    hasComponents () {
      if (this.summaryData.type === 'whatsApp') {
        if (Object.hasOwn(this.summaryData.whatsApp, 'components')) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    },
    porcentaje: {
      get () {
        return this.$store.state.CampaingsStore.porcentaje || null
      },
      set (value) {
        this.$store.commit('CampaingsStore/SET_EDITING_OBJECT', {
          key: 'porcentaje',
          value
        })
      }
    }
  },
  // beforeDestroy () {
  //   this.outboundName = null
  //   this.outboundStatus = null
  //   this.summaryData = null
  //   this.sendNowSuccess = null
  // },
  watch: {
    sendNowSuccess () {
      if (this.sendNowSuccess) {
        this.requestCount++
        if (this.requestCount < 5) {
          this.callSendData()
        }
      }
    },
    summaryData () {
      if (this.summaryData) {
        this.asignData()
      }
    }
  },
  methods: {
    billCampaings,
    callClose () {
      this.contactDuplicate = false
      this.contactDuplicateCount = null
    },
    async getDuplicateOutbound (response: Outbound) : Promise<void> {
      try {
        if (response) {
          const count = response?.totalDeleteDuplicatedRecords
          this.contactDuplicateCount = count
          this.contactDuplicate = !!count
        }
      } catch (error) {
        console.error(error)
      }
    },
    callSendData () {
      if (this.status === 'inReview' || this.status === 'En revisión' || this.status === 'Enviando') {
        const time = 6000 * this.requestCount
        setTimeout(() => {
          this.getCampaignId()
        }, time)
      } else {
        this.sendNowSuccess = false
        // EventBus.$emit('toast', 'success', this.$t('campaignDataLoad'))
      }
    },
    callImportingData () {
      if (this.status === 'draft' || this.status === 'Borrador' || this.status === 'importing' || this.status === 'Importando' || this.status === 'Enviando') {
        const time = 6000 * this.requestCount
        setTimeout(() => {
          this.getCampaignId()
        }, time)
      } else {
        // EventBus.$emit('toast', 'success', this.$t('campaignDataLoad'))
      }
    },
    formatDate (date) {
      const newDate = new Date(date)
      return newDate.getHours() + ':' + newDate.getMinutes() + '-' + newDate.getDate() + '/' + ((newDate.getMonth() + 1) < 10 ? '0' + +(newDate.getMonth() + 1) : newDate.getMonth() + 1) + '/' + newDate.getFullYear()
    },
    numbersFilter: (value) => {
      if (typeof value !== 'number') return value
      const locale = Intl.NumberFormat('en-US')
      return locale.format(value)
    },
    getStatusMsg (type) {
      switch (type) {
        case 'sending':
          return this.$t('campaignsSending')
        case 'sent':
          return this.$t('campaignsSent')
        case 'inReview':
          return this.$t('campaignsInReview')
        case 'draft':
          return ''
        case 'scheduled':
          return this.$t('campaignsScheduled')
        case 'importing':
          return this.$t('campaignsImporting')
        default:
          return 'None'
      }
    },
    asignDataPreview () {
      if (this.summaryData?.whatsApp) {
        this.templateVariables = this.summaryData.whatsApp
        if (this.summaryData.whatsApp?.mediaParams) {
          this.templateMediaUrl = this.summaryData?.whatsApp.mediaParams[0]
        }
        if (this.summaryData.whatsApp?.buttonParams) {
          this.buttonsLabels = this.summaryData?.whatsApp.buttonParams
        }
      }
    },
    async getCampaignId (msg) {
      this.loading = true
      try {
        const response = await CampaignsService.getCampaignV6(this.summaryData.id ? this.summaryData.id : this.summaryData._id)
        this.loading = false
        if (response.campaign) {
          if (msg) {
            EventBus.$emit('toast', 'success', this.$t('campaignDataLoad'))
          }
          this.summaryData = response.campaign
          if (this.summaryData.type === 'sms') {
            const { sms } = this.summaryData
            this.costData = {
              totalAmountToPay: sms.totalAmountToPay ? sms.totalAmountToPay : 0,
              totalChannelsMultiparts: sms.totalChannelsMultiparts ? sms.totalChannelsMultiparts : 0
            }
          }
          this.asignDataPreview()
          this.outboundName = this.summaryData?.name
          this.validateDate(this.summaryData)
          const { totalSent, totalRejected, totalCanceled, totalErrored, totalUsers, totalDeletedDuplicatedRecords } = this.summaryData
          if (this.summaryData.status === 'inReview') {
            // this.getDuplicateOutbound()
            this.contactDuplicateCount = totalDeletedDuplicatedRecords
            this.contactDuplicate = totalDeletedDuplicatedRecords > 0
          }
          if (this.summaryData.status) {
            this.outboundStatus = this.summaryData.status
            this.status = this.$t(this.summaryData.status)
            this.requestCount++
            if (this.requestCount < 5) {
              this.callImportingData()
            }
          }
          if (!totalUsers) {
            return
          }
          this.porcentaje = ((totalSent + totalRejected + totalCanceled + totalErrored) / totalUsers) * 100
        }
      } catch (e) {
        console.error(e)
        this.loading = false
      }
    },
    validateDate (campaign) {
      const { date } = campaign
      const fechauno = new Date(date)
      const fechados = new Date()
      const resultado = fechauno.getTime() === fechados.getTime()
      this.disabledModal = resultado
    },
    asignData () {
      if (this.summaryData) {
        this.outboundStatus = this.summaryData.status
        this.validateDate(this.summaryData)
        if (this.summaryData.status) {
          this.status = this.$t(this.summaryData.status)
          if (this.requestCount < 3) {
            this.callImportingData()
          }
        }
        this.statusId = this.summaryData.status
        if (this.summaryData.type === 'sms') {
          const { sms } = this.summaryData
          this.costData = {
            totalAmountToPay: sms.totalAmountToPay ? sms.totalAmountToPay : 0,
            totalChannelsMultiparts: sms.totalChannelsMultiparts ? sms.totalChannelsMultiparts : 0
          }
        }
        if (this.summaryData.type === 'whatsApp') {
          this.asignDataPreview()
          if (this.summaryData[this.summaryData.type].parameters[0]) {
            let msg = ''
            this.summaryData.whatsApp.parameters.forEach((element, i) => {
              if (i + 1 === 1) {
                msg = this.summaryData.whatsApp.message.replace(`{{${i + 1}}}`, element)
              }
              if (this.summaryData.whatsApp.parameters.length > 1) {
                msg = msg.replace(`{{${i + 1}}}`, element)
              }
            })
            this.messagePreview = msg
            // this.messagePreview = temp
          } else {
            this.messagePreview = this.summaryData.whatsApp.message
          }
        }
        if (this.summaryData.type === 'sms') {
          const { sms } = this.summaryData
          if (Object.hasOwn(sms, 'totalAmountToPay')) {
            this.dataText = this.summaryData[this.summaryData.type].totalAmountToPay
          } else {
            this.dataText = this.$t('pending')
          }
          this.messagePreview = this.summaryData[this.summaryData.type].message
        }
        if (this.summaryData.type === 'email') {
          this.messagePreview = this.summaryData[this.summaryData.type].html
        }
      }
    }
  }
}
