export enum UserTypes {
  SUPER_ADMIN = 'superadmin',
  ADMIN = 'admin',
  TOKEN = 'token',
  PARTNER = 'partner',
  COORDINATOR = 'coordinator',
  AGENT = 'agent',
  CUSTOM = 'custom'
};

export type UserType = UserTypes.SUPER_ADMIN | UserTypes.ADMIN | UserTypes.TOKEN | UserTypes.PARTNER | UserTypes.COORDINATOR | UserTypes.AGENT | UserTypes.CUSTOM;
