export enum KeybeIconType {
    CHAT = 'chat',
    PEOPLE = 'people',
    PLUS = 'plus',
    SALES = 'sales',
    TOOL = 'tool',
    LOGO = 'logo',
    BELL = 'bell',
    WRITE = 'write',
    FILTERS = 'filters',
    CAMPAINS = 'campaings',
    CASH = 'cash',
    EXIT = 'exit',
    EXTENSIONS = 'extensions',
    EXTERNAL = 'external',
    METRICS = 'metrics',
    MOMENTS = 'moments',
    NUMLIST = 'numlist',
    PLANET = 'planet',
    QUESTION = 'question',
    HELP = 'help',
    DOCUMENT = 'document',
    WORLD = 'world',
    INSTAGRAM = 'instagram',
    FACEBOOK_MESSENGER = 'facebook-messenger',
    WHATSAPP = 'whatsapp',
    MAGIC = 'magic',
    CLOSE = 'close',
    TRANSFER = 'transfer',
    VIDEO_CAMERA = 'video-camera',
    DEFAULT_USER = 'default-user',
    GROUP = 'group',
    RAINBOWGROUP = 'rainbowGroup',
    ARROW_UP = 'arrow-up',
    ARROW_DOWN = 'arrow-down',
    ADD = 'add',
    ADD_ITEM = 'add-item',
    HEART = 'heart',
    BROKEN_HEART = 'broken-heart',
    PAPER_PLANE = 'paper-plane',
    DELETE = 'delete',
    EDIT = 'edit',
    SELECTED = 'selected',
    UNSELECTED = 'unselected',
    PIN = 'pin',
    LOGO_V6 = 'logo-v6',
    EYE_OFF = 'eye-off',
    MINUS = 'minus',
    LIST = 'list',
    HOME = 'home',
    POINTER = 'pointer',
    EYE_CLOCK = 'eye-clock',
    TRASH = 'trash',
    MASTER_CARD = 'master-card',
    VISA = 'visa',
    AMEX = 'amex',
    DINERS_CLUB = 'diners-club',
    DISCOVER = 'discover',
    JCB = 'jcb',
    UNIONPAY = 'unionpay',
    ARROW_RIGHT = 'arrow-right',
    CHECKED_BOX = 'checked-box',
    UNCHECKED_BOX = 'unchecked-box',
    CLOCK = 'clock',
    DIALOG = 'dialog',
    VERTICAL_DOTS = 'vertical-dots',
    ARROW_LEFT = 'arrow-left',
    ARROW_BACK = 'arrow-back',
    PDF = 'pdf',
    FILE = 'file',
    SMILE_FACE = 'smile-face',
    CURVED_ARROW_LEFT = 'curved-arrow-left',
    DUPLICATE = 'duplicate',
    COPY = 'copy',
    PENCIL = 'pencil',
    INFO = 'info',
    DRAG_VERTICAL = 'drag-vertical',
    SEND = 'send',
    CLIP = 'clip',
    MICROPHONE = 'microphone',
    LIGHTNING = 'lightning',
    EMAIL = 'email',
    MAIL = 'mail',
    SALE = 'sale',
    ENDING = 'ending',
    DOC = 'doc',
    PPT = 'ppt',
    XLS = 'xls',
    CALENDAR = 'calendar',
    CRYSTAL_BALL = 'crystal-ball',
    BALANCE = 'balance',
    CHAT_OUTLINED = 'chat-outlined',
    COMPANY = 'company',
    EXTRADATA = 'extradata',
    FLAG = 'flag',
    LINKED_LIST = 'linked-list',
    NOTE = 'note',
    QUICK_RESPONSES = 'quick-responses',
    ROCKET = 'rocket',
    ROLES = 'roles',
    SCHEDULE = 'schedule',
    TAGS = 'tags',
    TEAM = 'team',
    SHOPPING_CART = 'shopping-cart',
    HALF_SUN = 'half-sun',
    LOCK = 'lock',
    FLAG_FILLED = 'flag-filled',
    EYE = 'eye',
    LAPTOP = 'laptop',
    PHONE = 'phone',
    IMAGE = 'image',
    SEARCH = 'search',
    DOWNLOAD = 'download',
    GRID = 'grid',
    MINIMIZE = 'minimize',
    SHRINK = 'shrink',
    EXPAND = 'expand',
    KEYBE_LOGO = 'kbLogo',
    MUSIC_NOTE = 'music-note',
    SOUND = 'sound',
    LOGO_WITH_TEXT = 'logo-with-text',
    COLLAPSE_MENU = 'collapse-menu',
    EXPAND_MENU = 'expand-menu',
    WIZARD_TITLE_DECORATION = 'wizard-title-decoration',
    WIZARD_TITLE_DECORATION_MOBILE = 'wizard-title-decoration-mobile',
    CHECK = 'check',
    DOLLAR = 'dollar',
    PIN_FILLED = 'pin-filled',
    LOGIN_DECORATION = 'login-decoration',
    SIMPLE_XLS = 'simple-xls',
    SHOPIFY = 'shopify',
    LOGO_WITH_GRADIENT = 'logo-with-gradient',
    VIDEO = 'video',
    MIC_FILLED = 'mic-filled',
    LOCATION = 'location',
    FILE_FILLED = 'file-filled',
    SMS = 'sms',
    SIMPLE_MAIL = 'simple-mail',
    QUESTION_MARK = 'question-mark',
    GOOGLE_PLAY = 'google-play',
    GOOGLE_PLAY_TEXT = 'google-play-text',
    APPLE_STORE = 'apple-store',
    APPLE_STORE_TEXT = 'apple-store-text',
    TASKS = 'tasks',
    CREATE_TASK_DECORATION = 'create-task-decoration',
    PAPER = 'paper',
    INBOX = 'inbox',
    STORE = 'store',
    KEY = 'key',
    HEART_FILLED = 'heart-filled',
    HEART_OUTLINED = 'heart-outlined',
    SENT = 'sent',
    FIRE_BALL = 'fire-ball',
    SENT_SIMPLE = 'sent-simple',
    RETRY ='retry',
    FORWARD ='forward',
    AUTO_DETAILS = 'auto-details',
    BEAUTY_DETAILS = 'beauty-details',
    BICYCLE_DETAILS = 'bicycle-details',
    BRAND_DETAILS = 'brand-details',
    DEMOGRAPHIC_DETAILS = 'demographic-details',
    FASHION_DETAILS = 'fashion-details',
    HABEAS_DETAILS = 'habeas-details',
    HEALTH_DETAILS = 'health-details',
    PERSONALITY_DETAILS = 'personality-details',
    PRIMARY_DETAILS = 'primary-details',
    TRANSACTIONAL_DETAILS = 'transactional-details',
    SPARKLE_DETAILS = 'sparkle-details',
    MOTO_DETAILS = 'moto-details',
    EDTECH_DETAILS = 'edtech-details',
    CONTACT_DETAILS = 'contact-details',
    BUSINESS_DETAILS = 'business-details',
    ROLES_DETAILS = 'roles-details',
    NOTES_DETAILS = 'notes-details',
    RESPONSILE_DETAILS = 'responsible-details',
    SEGMENTS_DETAILS = 'segments-details',
    SALES_DETAILS = 'sales-details',
    OFFICE_BUSINESS_DETAILS = 'office-business-details',
    PERSON_OFFICE_DETAILS = 'person-office-details',
    OFFICE_PERSON_DETAILS = 'office-persons-details',
    BUSINESS_OFFICE_DETAILS = 'business-office-details',
    HAND = 'hand',
    THERMOMETER = 'thermometer',
    SMARTOMETER_EMPTY_ACTIVE = 'smartometer-empty-active',
    SMARTOMETER_EMPTY_BLACK = 'smartometer-empty-black',
    SMARTOMETER_EMPTY_WHITE = 'smartometer-empty-white',
    SMARTOMETER_FULL_ACTIVE = 'smartometer-full-active',
    SMARTOMETER_FULL_WHITE = 'smartometer-full-white',
    SMARTOMETER_FULL_BLACK = 'smartometer-full-black',
    MEGAPHONE = 'megaphone',
    SLIDER_RULE = 'slider-rule',
    APP_FIELDS_CONFIG = 'app-fields',
    APP_PRIVACY_SECURITY = 'app-privacy',
    SALES_CLASSIC = 'sales-classic',
    SALES_STACKED = 'sales-stacked',
    NEURAL_ACTIVE = 'neural-active',
    NEURAL_BLACK = 'neural-black',
    NEURAL_WHITE = 'neural-white',
    TESSERACT_ACTIVE = 'tesseract-active',
    TESSERACT_WHITE = 'tesseract-white',
    TESSERACT_BLACK = 'tesseract-black',
    BLOB = 'blob',
    CHAT_SPARKLE = 'chat_sparkle',
    TICKET = 'ticket',
    ORGANIZATION = 'organization',
    STATUS = 'status',
    AGENT_ICON = 'agent-icon',
    AGENTBLACK_ICON = 'agentblack-icon',
    ADMINBLACK_ICON = 'adminblack-icon',
    ADMIN_ICON = 'admin-icon',
    COORDINATOR_ICON = 'coordinador-icon',
    COORDINATORBLACK_ICON = 'coordinadorblack-icon',
    LINK = 'link',
    CHAT_FILLED = 'chat-filled',
    DUMBBELL = 'dumbbell',
    BRAIN = 'brain',
    PLUS_BOLD = 'plus-bold',
    SAVE = 'save',
    PERSON_ICON = 'personIcon',
    SHARE = 'shareIcon',
    META = 'metaIcon',
    SUPERADMIN_USER = 'superadmin-user',
    ADMIN_USER = 'admin-user',
    COORDINATOR_USER = 'coordinator-user',
    AGENT_USER = 'agent-user',
    THUMB_UP = 'thumb-up',
    THUMB_DOWN = 'thumb-down',
    RETRY_ARROW = 'retry-arrow',
    CLOSE_BOLD = 'close-bold',
    MASKS = 'masks',
    GLASSES = 'glasses',
    SIMPLE_CHAT_DARK = 'simple-chat-dark',
    SIMPLE_CHAT_LIGHT = 'simple-chat-light',
    SIMPLE_CHAT_GREEN = 'simple-chat-green',
    CORTEX_DARK = 'cortex-dark',
    CORTEX_LIGHT = 'cortex-light',
    CORTEX_GREEN = 'cortex-green',
    TESSERACT_DARK = 'tesseract-dark',
    TESSERACT_LIGHT = 'tesseract-light',
    TESSERACT_GREEN = 'tesseract-green',
    EYE_INBOX = 'eye-inbox',
    MAIN_INBOX = 'main-inbox',
    UPLOAD_CLOUD = 'upload-cloud',
    OLD_CONVERSATIONS = 'old-conversation',
    RECENT_CONVERSATIONS = 'recent-conversations',
    LOADING = 'loading',
    WARNING = 'warning',
    EDITSPACES = 'editSpaces',
    CANCEL = 'cancelIcon',
    PENCIL_RULE = 'pencil-rule',
    CREATE_TASK = 'create-task',
    VERIFY_CLOUD = 'verify-cloud',
    CYCLE_MONEY = 'cycle-money',
    LIST_FOLDER = 'list-folder',
    HAND_MONEY = 'hand-money',
    BILL = 'bill',
    BILL2 = 'bill2',
    BILL3 = 'bill3',
    BILL4 = 'bill4',
    BILL5 = 'bill5',
    BILL6 = 'bill6',
    PEOPLE_COMISSIONS = 'people-comissions-icon',
    ELIPSE = 'elipse',
    HAND_SHAKE = 'hand-shake',
    UPLOAD = 'upload',
    DIAMOND = 'diamond',
    DIAMOND_SPARKLE = 'diamond-sparkle',
    HAND_SHAKE_OUTLINE = 'hand-shake-outline',
    CONTRACT = 'contract',
    GRAPH_UP = 'graph-up',
    GOOGLE_MEET = 'google-meet',
    ZOOM_MEET = 'zoom-meet',
    MALLET = 'mallet',
    WHATSAPP_BACK = 'WhatsappIconBack',
    SINGLE_SPARKLE = 'single-sparkle',
    TIER_ICON = 'tierIcon',
    DISCONNECT = 'disconnectIcon',
    REFRESH = 'RefreshIcon',
    KB_PRIME = 'kb-prime',
    CHEVRON_RIGHT = 'chevron-right',
    CHEVRON_LEFT = 'chevron-left',
    PAYMENT_STARS = 'payment-stars',
 }
