import { APIKeyGetter, IAPIKeyController, dataToCreateAPIKey, dataToDeleteAPIKey, dataToGetAPIKeys } from '../domain/APIKeysController'

export default class APIKeysUseCases {
  static getAPIKeys (controller: IAPIKeyController, data: dataToGetAPIKeys) : Promise<APIKeyGetter> {
    return controller.getAPIKeys(data)
  }

  static createAPIKey (controller: IAPIKeyController, data: dataToCreateAPIKey) : Promise<APIKeyGetter> {
    return controller.createAPIKey(data)
  }

  static deleteAPIKey (controller: IAPIKeyController, data: dataToDeleteAPIKey) : Promise<APIKeyGetter> {
    return controller.deleteAPIKey(data)
  }
}
