import KBColorPicker from '@/shared/infrastructure/ui/inputs/KBColorPicker.vue'
import KeybeSelect from '@/modules/DesignSystem/infrastructure/components/KeybeSelect/KeybeSelect/KeybeSelect.vue'
import KeybeText from '@/modules/DesignSystem/infrastructure/components/KeybeText/KeybeText.vue'
import KeybeButton from '@/modules/DesignSystem/infrastructure/components/KeybeButton/KeybeButton.vue'
import { createFormDTO, updateFormDTO } from '@/modules/Config/WidgetTesseract/domain/WidgetTesseractController'
import WidgetTesseractUseCases from '@/modules/Config/WidgetTesseract/application/WidgetTesseractUseCases'
import WidgetTesseractController from '@/modules/Config/WidgetTesseract/infrastructure/WidgetTesseract.controller'
import { EventBus } from '@/eventBus'
import Colors from '@/utils/Colors'
import { mapState } from 'vuex'
import { v4 as uuidv4 } from 'uuid'

import {
  typographyOptions,
  sizeTextOptions,
  typeBikyOptions
} from '@/modules/Config/WidgetTesseract/domain/WidgetTesseract'

export default {
  name: 'ConfigCollapsedBiky',
  components: {
    KBColorPicker,
    KeybeSelect,
    KeybeText,
    KeybeButton
  },
  props: {
    dataTocreate: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      widgetTesseractController: new WidgetTesseractController(),
      typographyOptions: [
        { name: 'Roboto', value: typographyOptions.Roboto },
        { name: 'Nunito', value: typographyOptions.Helvetica },
        { name: 'Open Sans', value: typographyOptions.OpenSans },
        { name: 'Arial', value: typographyOptions.Arial },
        { name: 'Poppins', value: typographyOptions.Poppins }
      ],
      sizeTextOptions: [
        { name: this.$t('small'), value: sizeTextOptions.Small },
        { name: this.$t('medium'), value: sizeTextOptions.Medium },
        { name: this.$t('large'), value: sizeTextOptions.Large }
      ],
      typeBikyOptions: [
        { value: typeBikyOptions.FORMAL },
        { value: typeBikyOptions.CASUAL }
      ],
      Colors,
      formId: ''
    }
  },
  computed: {
    ...mapState('AppStore', ['selectedApp']),
    ...mapState('UserStore', ['token']),
    generateUrl () {
      const nameApp = this.selectedApp?.name.replace(/\s/g, '')
      return `Keybe.ai/${nameApp}`
    }
  },
  methods: {
    saveData (): void {
      if (this.dataTocreate?.formId?.length > 0) {
        this.updateForm()
      } else {
        this.createForm()
      }
    },
    async createForm (): Promise<void> {
      try {
        const data: createFormDTO = {
          ...(this.dataTocreate || {}),
          url: this.generateUrlForm(),
          appUUID: this.selectedApp?.uuid,
          companyUUID: this.selectedApp?.company?.uuid
        }
        const response = await WidgetTesseractUseCases.createForm(
          this.widgetTesseractController,
          data
        )
        if (response?.data?.form._id) {
          this.$emit('updateFormId', response?.data?.form._id)
        }
        if (response) {
          EventBus.$emit('toast', 'success', this.$t('formCreated'))
        }
        if (!response) {
          EventBus.$emit('toast', 'error', this.$t('errorCreatingForm'))
        }
      } catch (error) {
        console.error(error)
      }
    },
    async updateForm (): Promise<void> {
      try {
        const data: updateFormDTO = {
          ...(this.dataTocreate || {}),
          _id: this.dataTocreate?.formId,
          appUUID: this.selectedApp?.uuid,
          companyUUID: this.selectedApp?.company?.uuid
        }
        const response = await WidgetTesseractUseCases.updateForm(
          this.widgetTesseractController,
          data
        )

        if (response) {
          EventBus.$emit('toast', 'success', this.$t('formUpdated'))
        }
        if (!response) {
          EventBus.$emit('toast', 'error', this.$t('errorUpdatingForm'))
        }
      } catch (error) {
        console.error(error)
      }
    },
    generateUrlForm (): string {
      const uuidUrl = uuidv4()
      return `https://${this.generateUrl}/${uuidUrl}`
    },
    changeTypeBiky (value: string): void {
      this.$emit('changeTypeBiky', value)
    },
    updateButtonColor (value: string): void {
      this.$emit('updateButtonColor', value)
    },
    updateTypography (value: string): void {
      this.$emit('updateTypography', value)
    },
    updatebuttonTextColor (value: string): void {
      this.$emit('updatebuttonTextColor', value)
    },
    updateTextSize (value: string): void {
      this.$emit('updateTextSize', value)
    },
    updateButtonText (value: string): void {
      this.$emit('updateButtonText', value)
    }
  }
}
