






















import InstagramPreview from './InstagramPreview'

export default InstagramPreview
