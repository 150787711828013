import LottieAnimation from 'lottie-web-vue'

export default {
  name: 'BillingPackagesCards',
  components: { LottieAnimation },
  props: ['dataCard', 'info', 'name'],
  data () {
    return {
    }
  },
  methods: {
  },
  mounted () {
  }
}
