import store from '@/store'
import httpClient from '@/shared/infrastructure/http/http'
import { GenericResponse } from '@/shared/infrastructure/http/GenericResponse'
import PushNotificationsController from '@/controllers/PushNotificationsController'
import { UserStates } from '@/modules/conversations/domain/enums/UserStates'

export default class AdvisersRepository {
  static baseUrl: string = process.env.VUE_APP_CONVERSATIONS_API_URL

  static async getAdvisers (): Promise<GenericResponse> {
    const appId = store.getters['AppStore/getSelectedApp']?.uuid || ''

    const url = `${AdvisersRepository.baseUrl}/adviser?appUUID=${appId}`
    return await httpClient.get(url)
  }

  static async updateAdviser (data): Promise<GenericResponse> {
    const url = `${AdvisersRepository.baseUrl}/adviser`
    return await httpClient.put(url, data)
  }

  static getAdviserStatus (userId: string) {
    const advisers = store.getters['AppStore/getAdvisers']
    if (!advisers) {
      return false
    }
    if (advisers) {
      const adviser = advisers.find((a) => a.userId === userId)
      if (!adviser) {
        return false
      }
      return adviser.status
    }
  }

  static async updateAdviserStatus (value): Promise<boolean> {
    const status = value ? UserStates.Online : UserStates.Offline
    const user = store.getters['UserStore/getUser']
    const userId = user?.userId
    const selectedAppUuid = store.getters['AppStore/getSelectedApp']?.uuid
    const variables = {
      userId,
      status,
      appUUID: selectedAppUuid
    }
    const response = await AdvisersRepository.updateAdviser(variables)
    if (response.status) {
      const adviser = response?.data?.data?.adviser
      await store.dispatch('AppStore/updateAdviser', adviser)
      await PushNotificationsController.setOneSignalStatus({ active: value })
      return true
    } else {
      return false
    }
  }

  static async getAdviserList () {
    const response = await AdvisersRepository.getAdvisers()
    if (!response?.status) {
      return []
    }

    return response?.data?.sort(function (a, b) {
      if (!a.name || !b.name) return 0
      const nameA = a?.name?.toUpperCase() // ignore upper and lowercase
      const nameB = b?.name?.toUpperCase() // ignore upper and lowercase
      if (nameA < nameB) {
        return -1
      }
      if (nameA > nameB) {
        return 1
      }
      return 0
    })
  }
}
