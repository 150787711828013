














































import operation from '@/utils/operations'
import Colors from '@/utils/Colors'
import { EventBus } from '@/eventBus'
import MicrositesService from '@/services/MicrositesService'
import Dialog from '@/components/shared/components/Dialog.vue'
import ImageCropper from '@/components/shared/components/ImageCropper.vue'

export default {
  components: {
    Dialog
  },
  data () {
    return {
      Colors,
      localValue: '',
      loading: false,
      uploadPictureOpen: false,
      ImageCropper
    }
  },
  mounted () {
    this.localValue = this.value
    EventBus.$on('croppedImage', (img) => {
      this.uploadPictureOpen = false
      this.onFilePicked(img)
    })
  },
  methods: {
    openFileInput () {
      this.uploadPictureOpen = true
    },
    async onFilePicked (event) {
      this.loading = true
      const response = await MicrositesService.uploadImage(event)

      if (response.status) {
        this.$emit('input', response.data.url)
        EventBus.$emit('toast', 'success', this.$t('successUploadingPicture'))
      } else {
        EventBus.$emit('toast', 'error', this.$t('errorUploadingPicture'))
      }

      this.loading = false
    },
    areEqual (a, b) {
      return operation.areEqual(a, b)
    }
  },
  watch: {
    value: {
      handler () {
        if (this.areEqual(this.value, this.localValue)) return
        this.localValue = this.value
      }
    }
  },
  props: {
    value: {
      required: true,
      type: String
    }
  }
}
