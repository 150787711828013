<template>
  <div class="ImporterFileUpload">
    <input
      type="file"
      style="display: none"
      ref="fileInput"
      accept="*/*"
      id="file_uploader"
      @change="onFilePicked">
    <span>{{ $t('selectFile') }} *</span>
    <div
      v-if="!file"
      class="ImporterFileUpload__dragarea"
      @click="openFilePicker"
      @drop="onFileDropped"
      @dragover.prevent
      @dragleave.prevent>
      <span>{{ $t('selectOrDragFile') }}</span>
    </div>
    <span
      v-if="!file"
      class="ImporterFileUpload__subtitle">
      {{ $t('importerSubtitle') }}
    </span>
    <div
      class="ImporterFileUpload__file"
      v-else>
      <KeybeIcon
        class="ImporterFileUpload__file__icon"
        :icon="KeybeIconType.XLS"/>
      <div class="ImporterFileUpload__file__text">
        <span>{{ processFileNameUploaded }}</span>
        <small>{{ formatSize(file.size) }}</small>
      </div>
      <KBIconButton
        v-if="!loading"
        class="ImporterFileUpload__file__button"
        :icon="'mdi-close'"
        :color="'red'"
        :size="'30px'"
        @click="removeFile"
      />
    </div>
    <div class="ImporterFileUpload__progress"
         v-if="file && loading">
      <div class="ImporterFileUpload__progress__container">
        <v-progress-linear
          class="ImporterFileUpload__progress__container__loader"
          indeterminate
          height="10"
          :color="Colors.kbGreen"/>
      </div>
      <small>{{ $t('loadingFile') }}</small>
    </div>
  </div>
</template>
<script>
import ImporterFileUpload from './ImporterFileUpload'
export default ImporterFileUpload
</script>
<style scoped lang="scss">
@import './ImporterFileUpload.scss'
</style>
