<template>
  <div class="KBSimpleTable contentTableWithModal"
       :class="$vuetify.theme.dark ? 'contentTableWithModalBlack' : ''">
    <div v-if="newDomainDialog"
         class="dialog-edit">
      <div class="d-flex align-center mt-3">
        <span>{{ $t(CDPFieldsEnums.NAME) }}</span>
      </div>
      <KBText
        class="smalForm mb-3"
        v-model="name"
      />
      <div class="d-flex align-center mt-3">
        <span>{{ $t('email') }}</span>
      </div>
      <KBText
        class="smalForm mb-3"
        v-model="email"
      />
      <div class="d-flex align-center mt-3">
        <span>{{ $t('prefix') }}</span>
      </div>
      <KBText
        class="smalForm mb-3"
        v-model="prefix"
      />
      <!-- <div class="d-flex align-center mt-3">
        <span>{{ $t('active') }}</span>
      </div> -->
      <div class="d-flex justify-end align-end">
        <KBButton :text="$t('cancel')"
                  @click="newDomainDialog = !newDomainDialog"
                  class="mr-5"
                  color="gray">
        </KBButton>
        <KBButton :disabled="true"
                  :text="$t('editBrand')"
                  @click="newDomainDialog = !newDomainDialog"
                  :loading="creating">
        </KBButton>
      </div>
    </div>
    <table class="KBSimpleTable__table"
           :class="$vuetify.theme.dark ? 'ModeDark' : 'modeLight'">
      <thead>
        <tr>
          <th
            class="KBSimpleTable__header"
            :class="positionClass(labelIndex, labels.length)"
            v-for="(label, labelIndex) in labels"
            :key="`KBSimpleTable-${label.text}-${labelIndex}`"
          >
            {{ label.text }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr class="KBSimpleTable__row"
            :class="selectItem === itemIndex && newDomainDialog ? 'selectItem': ''"
            v-for="(item, itemIndex) in data"
            :key="`KBSimpleTable-item-${itemIndex}`">
          <td
            class="KBSimpleTable__item pr-0"
          >
            <div style="width: 40px">
              <div v-if="item.image"
                   style="background: #8898aa">
                <img :src="item.image">
              </div>
              <avatar class="config--avatar"
                      v-else
                      :username="item.name"/>
            </div>
          </td>
          <td class="KBSimpleTable__item">
            {{ item.name }}
          </td>
          <td class="KBSimpleTable__item">
            {{ item.email ? item.email : '-' }}
          </td>
          <td class="KBSimpleTable__item">
            {{ areEqual(item.inactive, 0) ? $t('active') : $t('inactive') }}
          </td>
          <td class="KBSimpleTable__item">
            {{ formatDate(item.created_at) }}
          </td>
          <td
            class="KBSimpleTable__item edit--content"
          >
            <KBIconButton icon="mdi-pencil"
                          class="icon-edit"
                          @click="callModal(itemIndex, item)"/>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import operation from '@/utils/operations'
import moment from 'moment'
import Avatar from 'vue-avatar'
import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import KBIconButton from '@/shared/infrastructure/ui/buttons/KBIconButton.vue'
import KBText from '@/shared/infrastructure/ui/inputs/text/KBText.vue'
import KBButton from '@/shared/infrastructure/ui/buttons/KBButton.vue'
import { CDPFieldsEnums } from '@/modules/CDP/shared/contacts/domain/enums/CDPFieldsEnums'
export default {
  name: 'KBTableBrands',
  props: ['labels', 'data'],
  components: { Avatar, KBIconButton, KBText, KBButton },
  data () {
    return {
      creating: false,
      KeybeIconType,
      newDomainDialog: false,
      selectItem: null,
      name: null,
      email: null,
      prefix: null,
      active: false,
      CDPFieldsEnums
    }
  },
  methods: {
    callModal (index, { name, email, prefix, inactive }) {
      this.name = name
      this.email = email
      this.prefix = prefix
      this.active = inactive
      this.selectItem = index
      this.newDomainDialog = !this.newDomainDialog
    },
    formatDate (date) {
      return moment(date).locale('es').format('ll')
    },
    areEqual (a, b) {
      return operation.areEqual(a, b)
    },
    positionClass (index, length) {
      if (index === 0) {
        return 'first-item'
      }
      return ''
    }
  }
}
</script>

<style lang="scss">
.config--avatar{
    width: 25px !important;
    height: 25px !important;
    span{
        padding-top: 1px;
        color: white;
        font-size: 8px !important;
    }
}

.KBSimpleTable__table {
  overflow: scroll;
  border-collapse: collapse;
  width: 100%;
}

.modeLight thead th {
    background-color: #F7F7F7;
}

.modeDark thead th {
    background-color: #0000 !important;
}

.KBSimpleTable__header {
  font-weight: 300;
  font-size: 11px;
  line-height: 0px;
  color: $simple-grey;
  height: 30px;
  text-align: start !important;
  padding-left: 8px;
}

.KBSimpleTable__item {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  padding: 10px;
  border-bottom: 1px solid #EBEFF2;
  text-align: start;
  min-width: 50px  !important;
}

  .first-item {
    padding-left: 32px;
    max-width: 125px;

    // text elipsis
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .last-item {
    padding-right: 32px;
  }
  .textGray{
    color: #707683;
}
.smallFont{
    font-size: 11px;
}
.edit--content{
    position: relative;
}
.icon-edit{
    span{
        color: #707683 !important;
        font-size: 12px !important;
    }
}
table { border-collapse: collapse; width: 100%; }

.KBSimpleTable{
  overflow: scroll;
  height: 455px;
}
.KBSimpleTable thead th {
  position: sticky;
  top: 0;
}
.dialog-edit__Light{
  background: white;
}
.dialog-edit__Dark{
  background: black;
}

@media screen and (min-height: 600px) {
.KBSimpleTable{
  overflow: scroll;
  height: 335px;
}
}

@media screen and (min-height: 700px) {
.KBSimpleTable{
  overflow: scroll;
  height: 435px;
}
}
@media screen and (min-height: 750px) {
.KBSimpleTable{
  overflow: scroll;
  height: 465px;
}
}

@media screen and (min-height: 800px) {
.KBSimpleTable{
  overflow: scroll;
  height: 475px;
}
}
@media screen and (min-height: 900px) {
.KBSimpleTable{
  overflow: scroll;
  height: 515px;
}
}
.selectItem{
    background-color: #cbd4e72e;
}
.KBSimpleTable__item{
    font-weight: 300 !important;
}
.contentTableWithModal{
  position: relative;
  .dialog-edit {
    background: white;
    z-index: 9;
    top: 30px;
    left: 50%;
    position: absolute;
    border-radius: 10px;
    padding: 10px 20px;
    width: 400px;
    box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1);
}
}
.contentTableWithModalBlack{
  .dialog-edit {
    border: 1px solid rgba(255, 255, 255, 0.493);
    background: black;
    z-index: 9;
    top: 50px;
    left: 30%;
    position: absolute;
    border-radius: 10px;
    padding: 10px 20px;
    width: 400px;
}
}
</style>
