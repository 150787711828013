import HttpClient from '@/shared/infrastructure/http/HttpClient'
import { GenericResponse } from '@/shared/infrastructure/http/GenericResponse'

export default class KeybeRepository {
    client: HttpClient

    constructor () {
      this.client = new HttpClient()
    }

    async requestWithTimeout ({
      request,
      cancel,
      timeout
    }: {
        request: () => Promise<GenericResponse>,
        cancel: () => void,
        timeout?: number
    }) {
      if (!timeout) {
        return await request()
      }

      const timer = setTimeout(() => {
        cancel()
      }, timeout)

      const response = await request()
      clearTimeout(timer)
      return response
    }
}
