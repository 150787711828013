import store from '@/store'
import { ChatConfig } from '../../domain/ChatConfig'

export default class ChatConfigStoreController {
  static getConfig (): ChatConfig {
    return store.getters['ChatConfigStore/getConfig']
  }

  static setConfig (config: ChatConfig) {
    store.dispatch('ChatConfigStore/setConfig', config)
  }
}
