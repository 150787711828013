






























































































import AutoConnectFacebookConfig from './AutoConnectFacebookConfig'
export default AutoConnectFacebookConfig
