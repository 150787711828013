import { BikyType, BikyTypes } from '@/modules/bky/domain/BikyConfig'
import Colors from '@/utils/Colors'
import 'echarts'
import VChart from 'vue-echarts'

export default {
  name: 'BikyConfigPersonalitySummary',
  props: {
    personality: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      key: 0
    }
  },
  components: {
    VChart
  },
  computed: {
    personalityValue (): BikyType {
      return this.personality?.value
    },
    graphOptions (): {
      [key: string]: any
      } {
      const colors: {
        [key: string]: string
      } = {
        [BikyTypes.CLIENT_SUPPORT]: Colors.kbGreen,
        [BikyTypes.SALES]: Colors.rojoBird,
        [BikyTypes.GENERAL_ASSISTANT]: Colors.yellow2
      }
      const color: string = colors[this.personalityValue]
      const values: {
        [key: string]: number[]
      } = {
        [BikyTypes.SALES]: [100, 40, 60, 80],
        [BikyTypes.CLIENT_SUPPORT]: [20, 85, 70, 65],
        [BikyTypes.GENERAL_ASSISTANT]: [20, 90, 85, 60]
      }
      const option = {
        backgroundColor: this.$vuetify?.theme?.dark ? '#181818' : '#f6f9fc',
        radar: {
          shape: 'circle',
          name: {
            textStyle: {
              color: this.$vuetify?.theme?.dark ? '#fff' : '#000'
            }
          },
          splitArea: {
            areaStyle: {
              color: 'transparent'
            }
          },
          axisLine: {
            lineStyle: {
              color: this.$vuetify?.theme?.dark ? '#fff' : '#000'
            }
          },
          indicator: [
            { name: this.$t('DISCDominant'), max: 100 },
            { name: this.$t('DISCStability'), max: 100 },
            { name: this.$t('DISCCompliance'), max: 100 },
            { name: this.$t('DISCInfluence'), max: 100 }

          ]
        },
        series: [
          {
            type: 'radar',
            areaStyle: {
              normal: {
                color
              }
            },
            lineStyle: {
              normal: {
                color: '#000',
                width: 0
              }
            },
            itemStyle: {
              normal: {
                color: this.$vuetify?.theme?.dark ? '#fff' : '#000'
              }
            },
            data: [
              {
                value: values[this.personalityValue]
              }
            ]
          }
        ]
      }

      return option
    },
    description (): string {
      const desctiptions = {
        [BikyTypes.CLIENT_SUPPORT]: this.$t('clientServiceDescription'),
        [BikyTypes.SALES]: this.$t('energeticSalesPersonDescription'),
        [BikyTypes.GENERAL_ASSISTANT]: this.$t('assistantDescription')
      }

      return desctiptions[this.personalityValue]
    }
  },
  watch: {
    personalityValue (newValue: BikyType): void {
      this.key++
    }
  }
}
