








export default {
  name: 'SimpleChatWhiteIcon'
}
