import Format from '@/factory/PrettySize'
import { FileData } from '@/modules/KBDrive/domain/Documents'
import TypeDocuments from '../TypeDocuments/TypeDocuments.vue'
import KeybeAvatar from '@/shared/infrastructure/ui/KeybeAvatar.vue'
import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'
import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import Colors from '@/utils/Colors'
import { Languages } from '@/components/shared/enums/Languages'
import { CDPFieldsEnums } from '@/modules/CDP/shared/contacts/domain/enums/CDPFieldsEnums'
export default {
  props: {
    documents: {
      type: Array,
      required: true
    },
    contact: {
      type: Object,
      required: true
    },
    search: {
      type: String
    }
  },
  components: {
    TypeDocuments,
    KeybeAvatar,
    KeybeIcon
  },
  computed: {
    contactImage (): string {
      return this.contact?.photo || ''
    }
  },
  data () {
    return {
      headers: [
        CDPFieldsEnums.NAME,
        'contact',
        'updatedAt',
        'size',
        'actions'
      ],
      KeybeIconType,
      Colors,
      CDPFieldsEnums
    }
  },
  methods: {
    documentType (document: FileData): string {
      return document.file.ext || ''
    },
    documentField (document: FileData, header: string): string {
      switch (header) {
        case CDPFieldsEnums.NAME:
          return document?.name || ' - '
        case 'contact':
          return `${this.contact?.name || ''} ${this.contact?.surname || ''}`
        case 'updatedAt':
          return this.formatDate(document?.updatedAt) || ' - '
        case 'size':
          return Format.prettySize(document?.file?.size) || ' - '
      }
    },
    formatDate (value: string): string {
      const dateFormat = new Date(value)
      const lang = this.language === Languages.EN ? 'en-US' : 'es-ES'
      const options = { month: 'short', day: 'numeric', year: 'numeric' }
      // @ts-ignore
      const formatted = dateFormat.toLocaleDateString(lang, options).split(' ')
      return `${formatted[0]} ${formatted[1]}, ${formatted[2]}`
    },
    openFile (document: FileData): void {
      let url = ''
      if (document.file.ext === 'pdf') {
        url = document.origin
      }
      if (this.isImage(document.file.ext) || this.isDocs(document.file.ext)) {
        url = document.storage.url
      }
      window.open(url, '_blank')
    },
    isDocs (ext: string): boolean {
      if (typeof ext === 'string') {
        const lowerCaseExt = ext.toLowerCase()
        return lowerCaseExt === 'docx' || lowerCaseExt === 'doc' || lowerCaseExt === 'xlsx' || lowerCaseExt === 'xls' || lowerCaseExt === 'ppt' || lowerCaseExt === 'pptx' || lowerCaseExt === 'ppx'
      }
      return false
    },
    isImage (ext: string): boolean {
      if (typeof ext === 'string') {
        const lowerCaseExt = ext.toLowerCase()
        return lowerCaseExt === 'jpg' || lowerCaseExt === 'jpeg' || lowerCaseExt === 'png'
      }
      return false
    }
  }
}
