import axios from 'axios'
import store from '@/store'
import { RestResponseFactory } from '@/models/apis/RestResponse'

export class UploadFilesService {
  static baseUrl: string = process.env.VUE_APP_CONVERSATIONS_URL
  static baseUrlUtils: string = process.env.VUE_APP_UTILS_PATH

  static async uploadFile (data) {
    try {
      const appId = store.getters['AppStore/getSelectedApp']?.uuid
      const companyId = store.getters['CompanyStore/getSelectedCompany']?.uuid
      const token = store.getters['UserStore/getToken']
      const { totalFiles, onUploadProgress, roomId } = data
      const url = `${UploadFilesService.baseUrl}/uploads/company/${companyId}/app/${appId}/room/${roomId}`
      const formData = new FormData()
      for (const file of totalFiles) {
        formData.append('files', file)
      }
      const response = await axios.post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + token
        },
        onUploadProgress
      })
      return new RestResponseFactory().process({ response })
    } catch (e) {
      return new RestResponseFactory().process({ response: { status: 500 } })
    }
  }

  static async uploadFileUtils (data) {
    try {
      const appUUID = store.getters['AppStore/getSelectedApp']?.uuid
      const token = store.getters['UserStore/getToken']
      const { totalFiles, onUploadProgress } = data
      const url = `${UploadFilesService.baseUrlUtils}/document/upload?component=catalog&appUUID=${appUUID}&subcomponent=products&targetChannel=whatsapp`
      const formData = new FormData()
      for (const file of totalFiles) {
        formData.append('file', file, '[PROXY]')
      }
      const response = await axios.post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + token
        },
        onUploadProgress
      })
      return new RestResponseFactory().process({ response })
    } catch (e) {
      return new RestResponseFactory().process({ response: { status: 500 } })
    }
  }
}
