







export default {
  name: 'BrainIcon',
  props: {
    color: {
      type: String,
      default: '#000'
    }
  }
}
