




































































import WatsonConfigForm from './WatsonConfigForm'
export default WatsonConfigForm
