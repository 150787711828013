import ProductUseCases from '@/modules/catalog/application/ProductUseCases'
import { dataToGetProducts, Paginator } from '@/modules/catalog/domain/models/ProductsController'
import { CDPFieldsEnums } from '@/modules/CDP/shared/contacts/domain/enums/CDPFieldsEnums'
import KeybeAutocomplete from '@/modules/DesignSystem/infrastructure/components/KeybeAutocomplete/KeybeAutocomplete/KeybeAutocomplete.vue'
import ProductController from '../../controllers/Product.controller'
import { mapState } from 'vuex'
import { Product } from '@/modules/catalog/domain/models/Product'
import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'
import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import Colors from '@/utils/Colors'
export default {
  components: {
    KeybeAutocomplete,
    KeybeIcon
  },
  props: {
    value: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapState('AppStore', ['selectedApp']),
    ...mapState('UserStore', ['token'])
  },
  mounted () {
    this.getProducts()
    if (this.value.length) this.selectedProducts = this.value
  },
  data () {
    return {
      products: [],
      selectedProducts: [],
      selectedProduct: {},
      limit: 25,
      loading: false,
      hasNext: false,
      hasPrev: false,
      loadingSelect: false,
      search: '',
      productController: new ProductController(),
      CDPFieldsEnums,
      KeybeIconType,
      Colors
    }
  },
  methods: {
    async getProducts (get: Paginator): Promise<void> {
      this.loading = true
      const data: dataToGetProducts = {
        token: this.token,
        appUUID: this.selectedApp?.uuid,
        limit: this.limit,
        get: get,
        products: this.products
      }

      if (this.search) data.product = this.search
      const response = await ProductUseCases.getProducts(this.productController, data)
      if (response?.products) {
        this.products = this.products.concat(response.products)
        this.hasNext = response.hasNext
        this.hasPrev = response.hasPrev
      }
      this.loading = false
    },
    getNext (): void {
      if (!this.loading && this.hasNext) this.getProducts(Paginator.NEXT)
    },
    setSelected (product: Product): void {
      this.loadingSelect = true
      if (product?.id) {
        this.selectedProduct = {}
        this.selectedProducts.push(product)
      }
      this.loadingSelect = false
      this.$emit('input', this.selectedProducts)
    },
    removeProduct (product: Product): void {
      this.selectedProducts = this.selectedProducts.filter((p: Product) => p.id !== product.id)
      this.$emit('input', this.selectedProducts)
    }
  }
}
