













import BillingPlansTotalCards from './BillingPlansTotalCards'
export default BillingPlansTotalCards
