<template>
  <div class="KBRadio"
       @click="select">
    <div class="KBRadio__radio"
         :style="{ width: radioWidth, height: radioHeight, borderRadius: radioHeight }"
         :class="{ 'KBRadio__radio--checked': checked }"></div>
    <span class="KBRadio__label"
          v-if="itemLabel">{{itemLabel}}</span>
  </div>
</template>

<script>
export default {
  props: ['value', 'itemValue', 'itemLabel', 'width', 'height', 'disabled'],
  computed: {
    checked () {
      if (this.itemValue) return this.value === this.itemValue
      else return this.value
    },
    radioWidth () {
      return this.width || '13px' // Valor por defecto: 13px si no se proporciona la prop 'width'
    },
    radioHeight () {
      return this.height || '13px' // Valor por defecto: 13px si no se proporciona la prop 'height'
    }
  },
  methods: {
    select () {
      if (this.disabled) return
      if (this.itemValue) this.$emit('input', this.itemValue)
      else this.$emit('input', this.value)
    }
  }
}
</script>

<style scoped lang="scss">
.KBRadio {
  display: flex;
  align-items: center;
  cursor: pointer;

  &__radio {
    background: #B6BEC7;
    transition: background 0.4s;
  }

  &__radio--checked {
    background: #3AF69B;
  }

  &__label {
    color: #B6BEC7;
    font-size: 14px;
    margin-left: 10px;
  }
}
</style>
