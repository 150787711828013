export enum OutboundTabs {
    NEW_WHATSAPP = 'newWhatsapp',
    NEW_SMS = 'mewSms',
    NEW_EMAIL = 'newEmail',
    HOME = 'allOutbound',
    TEMPLATE = 'meTemplate',
    NEW_TEMPLATE = 'newTemplate',
    SUMMARY = 'summary',
    OUTSTANDING = 'outstanding',
    SEGMENT = 'segment',
    DOWNLOAD = 'download',
    TEMPLATE_WHATSAPP = 'templateWhatsapp',
}
