import { GenericResponse } from '@/shared/infrastructure/http/GenericResponse'
import { HeadersBuilder } from '@/shared/infrastructure/http/HeadersBuilder'
import KeybeRepository from '@/shared/infrastructure/repositories/KeybeRepository'

export default class KnowledgeBaseRepository extends KeybeRepository {
  // eslint-disable-next-line no-useless-constructor
  constructor () {
    super()
  }

  async getFiles (data): Promise<GenericResponse> {
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    const url = `${process.env.VUE_APP_CONVERSATIONS_API_URL}/ai/knowledge-base/files`
    const params = {
      appUUID: data.appUUID
    }
    return await this.client.get({
      url,
      query: params,
      headers
    })
  }

  async uploadFile (data): Promise<GenericResponse> {
    const url = `${process.env.VUE_APP_CONVERSATIONS_API_URL}/ai/knowledge-base/files`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)

    const body = {
      appUUID: data.appUUID,
      _id: data._id,
      name: data.name,
      extension: data.extension
    }

    return await this.client.post({
      url,
      data: body,
      headers
    })
  }

  async deleteFile (data): Promise<GenericResponse> {
    const url = `${process.env.VUE_APP_CONVERSATIONS_API_URL}/ai/knowledge-base/files`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)

    const body = {
      _id: data._id
    }

    return await this.client.delete({
      url,
      data: body,
      headers
    })
  }
}
