import { render, staticRenderFns } from "./WebWidgetCollapsedConfig.vue?vue&type=template&id=3c0d1818&scoped=true&"
import script from "./WebWidgetCollapsedConfig.vue?vue&type=script&lang=ts&"
export * from "./WebWidgetCollapsedConfig.vue?vue&type=script&lang=ts&"
import style0 from "./WebWidgetCollapsedConfig.vue?vue&type=style&index=0&id=3c0d1818&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3c0d1818",
  null
  
)

export default component.exports