<template>
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 13 14"><path :fill="color" d="M5.584 6.99 4.361 5.601C2.971 4.023 1.58 2.446.19.866-.055.588-.052.328.183.133c.24-.2.502-.176.741.082.308.334.604.676.906 1.014l4.36 4.908c.023.026.048.05.082.084.593-.666 1.182-1.326 1.769-1.988 1.18-1.33 2.358-2.66 3.54-3.987.294-.33.671-.319.879.017.115.186.083.377-.118.608-.682.777-1.367 1.552-2.05 2.327L6.956 6.983c.03.043.052.086.083.12 1.717 1.95 3.437 3.9 5.155 5.85.071.08.146.156.208.242.187.257.158.504-.073.687-.229.18-.486.156-.707-.09-.66-.732-1.312-1.468-1.966-2.205-1.124-1.265-2.247-2.53-3.388-3.817L5.226 8.942c-1.42 1.599-2.84 3.199-4.263 4.796-.283.318-.563.344-.813.093-.175-.175-.206-.379-.045-.586.224-.29.475-.561.718-.837 1.554-1.765 3.11-3.528 4.663-5.293.032-.035.06-.075.098-.125Z"/></svg>
</template>

<script>
export default {
  name: 'CloseIcon',
  props: {
    color: {
      type: String,
      default: '#B6BEC7'
    }
  }
}
</script>

<style>

</style>
