import { mapActions, mapState } from 'vuex'
import RocketSVG from '@/shared/assets/RocketSVG.vue'
import { loadStripe } from '@stripe/stripe-js'
import { EventBus } from '@/eventBus'
import { PaymentService, IPaymentIntent, CURRENCY_TYPES, PAYMENT_TYPES, TYPE_BUY_PACKAGE } from '@/components/configuration/Services/PaymentService'
import { ConsumptionsTabs } from '@/modules/consumptions/infrastructure/enums/ConsumptionsTabs'

import BillingService from '@/components/configuration/Services/BillingService'
import CreditCardSelector from '@/modules/consumptions/infrastructure/ui/components/CreditCardSelector/CreditCardSelector.vue'
import KBCashCard from '@/modules/consumptions/infrastructure/ui/components/KBCashCard.vue'
import KBButton from '@/shared/infrastructure/ui/buttons/KBButton.vue'
import BillingPackagesCards from '@/modules/consumptions/infrastructure/ui/components/BillingPackagesCards/BillingPackagesCards.vue'
import Loader from '@/shared/infrastructure/ui/Loader.vue'
import { CDPFieldsEnums } from '@/modules/CDP/shared/contacts/domain/enums/CDPFieldsEnums'

const PAYMENT_FACTOR = 100

export default {
  name: 'BillingPackagesPayment',
  components: {
    BillingPackagesCards,
    RocketSVG,
    KBButton,
    KBCashCard,
    CreditCardSelector,
    Loader
  },
  props: ['itemDetail', 'isFeesAdmin'],
  data () {
    return {
      creditCards: [],
      stripe: null,
      elements: null,
      selectedCard: null,
      loading: true,
      error: '',
      balance: 0,
      amount: 0,
      percent: 0,
      hasCredit: false,
      applyPayment: false,
      paymentIntent: null,
      amountError: false,
      cardError: false,
      newCommission: false
    }
  },
  computed: {
    ...mapState('BillingStore', ['appConfig']),
    changeOptionSave: {
      get () {
        return this.$store.state.BillingStore.changeOptionSave || null
      },
      set (value) {
        this.$store.commit('BillingStore/SET_EDITING_OBJECT', {
          key: 'changeOptionSave',
          value
        })
      }
    },
    changeOption: {
      get () {
        return this.$store.state.BillingStore.changeOption || null
      },
      set (value) {
        this.$store.commit('BillingStore/SET_EDITING_OBJECT', {
          key: 'changeOption',
          value
        })
      }
    },
    hasElement () {
      return !!this.element && !!this.stripe
    }
    // montoFormat () {
    //   const number = this.itemDetail.cost.replace(/[.]/g, '')
    //   return parseInt(number)
    // },
    // validateBalance () {
    //   return this.balance >= (this.montoFormat * BALANCE_FACTOR_APPEND) || this.hasCredit
    // },
    // calculateBalanceNeeded () {
    //   const hasBalance = (this.montoFormat * BALANCE_FACTOR_VALUABLE) - this.balance
    //   return hasBalance < 0 ? 0 : hasBalance
    // }
  },
  async created () {
    this.loading = true
    await this.getCreditCards()
    this.loading = false
  },
  methods: {
    ...mapActions('BillingStore', ['setAppConfig']),
    async updateBillingInfo () {
      const appConfig = await BillingService.getAppConfig()
      this.setAppConfig(appConfig)
    },
    onAddBalance () {
      this.applyPayment = false
      this.loading = false
    },
    startLoading () {
      this.loading = true
    },
    async showSuccess (message = 'paymentSuccess.plan') {
      EventBus.$emit('toast', 'success', this.$t(message))
      await this.updateBillingInfo()
      this.stopLoading()
      this.changeOption = ConsumptionsTabs.PAYMENTS_METHODS
      // this.$emit('close')
    },
    showError (message = 'paymentError.noPlan') {
      EventBus.$emit('toast', 'error', this.$t(message))
      this.stopLoading()
    },
    stopLoading () {
      this.loading = false
    },
    async addPayment () {
      if (this.loading) {
        return
      }
      const total = this.amount * PAYMENT_FACTOR
      const payment: IPaymentIntent = {
        type: PAYMENT_TYPES.PACKAGE,
        package: {
          ...this.itemDetail,
          type: this.itemDetail.type || TYPE_BUY_PACKAGE.NORMAL
        },
        total: total,
        currency: CURRENCY_TYPES.USD,
        uuid: '',
        description: `buy ${total}${CURRENCY_TYPES.USD} to balance`
      }
      this.paymentIntent = payment
      this.applyPayment = true
      await this.continueIntent(payment)
    },
    async payMethod () {
      if (this.loading) {
        return
      }

      this.startLoading()

      this.amountError = false
      this.cardError = false
      this.error = ''

      const total = this.amount * PAYMENT_FACTOR
      const payment: IPaymentIntent = {
        type: PAYMENT_TYPES.PACKAGE,
        package: {
          ...this.itemDetail,
          type: this.itemDetail.type || TYPE_BUY_PACKAGE.NORMAL
        },
        total: total,
        currency: CURRENCY_TYPES.USD,
        uuid: '',
        description: `buy ${total}${CURRENCY_TYPES.USD} to package`
      }
      this.paymentIntent = payment
      if (this.selectedCard) {
        payment.method = this.selectedCard.id
        // TODO: Activar estas linea
        const response = await PaymentService.applyPayment(payment)
        // const model = { ...this.paymentIntent, method: this.selectedCard?.id }
        // const response = await PaymentService.payIntentBikyPlan(model)
        if (response.status) {
          setTimeout(() => {
            this.showSuccess()
          }, 2000)
          return
        }
        this.showError()
        return
      }
      this.applyPayment = true
      await this.continueIntentPay(payment)
    },
    async continueIntentPay (payment: IPaymentIntent) {
      const appearance = {}
      const paymentOptions = {}
      const addressOptions = { mode: 'shipping' }

      const response = await PaymentService.getConfig()
      if (response.status) {
        const publicKey = response.data

        this.stripe = await loadStripe(publicKey)
        if (!this.stripe) {
          this.applyPayment = false
          this.loading = false
        }
        // TODO: Activar estas linea
        const paymentIntent = await PaymentService.getIntent(payment)
        if (paymentIntent.status) {
          const clientSecret = paymentIntent.data.client_secret
          this.elements = await this.stripe.elements({ clientSecret, appearance })

          const paymentElement = this.elements.create('payment', paymentOptions)
          paymentElement.mount('#payment-element-package')

          const addressElement = this.elements.create(CDPFieldsEnums.ADDRESS, addressOptions)
          addressElement.mount('#address-element-package')
          setTimeout(() => {
            this.stopLoading()
          }, 3000)
          return
        }
      }
      this.applyPayment = false
      this.stopLoading()
    },
    // async continueIntent (payment: IPaymentIntent) {
    //   const appearance = {}
    //   const paymentOptions = {}
    //   const addressOptions = { mode: 'shipping' }

    //   const response = await PaymentService.getConfig()
    //   if (response.status) {
    //     const publicKey = response.data

    //     this.stripe = await loadStripe(publicKey)
    //     if (!this.stripe) {
    //       this.applyPayment = false
    //       this.loading = false
    //     }
    //     const paymentIntent = await PaymentService.getIntent(payment)
    //     if (paymentIntent.status) {
    //       const clientSecret = paymentIntent.data.client_secret
    //       this.elements = await this.stripe.elements({ clientSecret, appearance })

    //       const paymentElement = this.elements.create('payment', paymentOptions)
    //       paymentElement.mount('#payment-element-package')

    //       const addressElement = this.elements.create(CDPFieldsEnums.ADDRESS, addressOptions)
    //       addressElement.mount('#address-element-package')
    //       setTimeout(() => {
    //         this.stopLoading()
    //       }, 3000)
    //       return
    //     }
    //   }
    //   this.applyPayment = false
    //   this.stopLoading()
    // },
    async onSubmit (event) {
      this.loading = true
      const response = await this.stripe.confirmPayment({
        elements: this.elements,
        redirect: 'if_required'
      })

      if (response?.error) {
        if (response?.error?.payment_intent) {
          await PaymentService.errorPayment({ stripe: response, model: this.paymentIntent })
          EventBus.$emit('toast', 'error', response?.error?.message)
          this.paymentIntent = null
          this.stopLoading()
          this.$emit('close')
          return
        }
        this.stopLoading()
        return
      }

      await PaymentService.savePayment({ stripe: response, model: this.paymentIntent })
      this.paymentIntent = null
      setTimeout(() => {
        this.showSuccess()
        this.applyPayment = false
      }, 2000)
    },
    // async getBalance () {
    //   const response = await BillingService.getSimpleBalance()
    //   if (response.status) {
    //     this.balance = response.data.balanceUSD || 0
    //     this.hasCredit = response.data.hasCredit || false
    //   }
    // },
    callOnList () {
      this.$parent.onList()
    },
    async getCreditCards () {
      const response = await PaymentService.getCreditCards()
      if (response.status) {
        this.creditCards = response.data.map((card) => ({
          id: card.id,
          tokenResponse: {
            brand: card.card.brand,
            last4: card.card.last4
          }
        }))
      }
      if (this.creditCards.length > 0) {
        this.selectedCard = this.creditCards[0]
      } else {
        this.newCommission = true
      }
      this.loading = false
    }
  },
  mounted () {
    this.changeOption = null
    this.changeOptionSave = false
    this.amount = this.itemDetail.amount
    // this.getBalance()
    this.getCreditCards()
  }
}
