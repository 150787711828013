import KeybeRepository from '@/shared/infrastructure/repositories/KeybeRepository'
import { HeadersBuilder } from '@/shared/infrastructure/http/HeadersBuilder'
import { GenericResponse } from '@/shared/infrastructure/http/GenericResponse'
import store from '@/store/index'
import { dataToGetOutbound, dataToGetOutboundDuplicate } from '../../domain/OutboundController'
import { getOptions } from '@/shared/infrastructure/http/HttpClient'

export default class OutBoundRepository extends KeybeRepository {
      // abort controllers
      getOutboundAbortController: AbortController
      maxRetries = 1
      timeout = 15000

      baseUrl: string

      constructor () {
        super()

        // instance abort controllers
        this.getOutboundAbortController = new AbortController()

        this.baseUrl = process.env.VUE_APP_CAMPAIGNS_URL
      }

      async getOutbound (data: dataToGetOutbound, retryCount = 0): Promise<GenericResponse> {
        const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
        const companyId = store.getters['CompanyStore/getSelectedCompany']?.uuid || 'none'
        const appId = store.getters['AppStore/getSelectedApp']?.uuid || ''

        const url = `${this.baseUrl}/companies/${companyId}/apps/${appId}/campaigns`

        const query = {
          ...data
        }

        delete query.token

        const request = () => this.client.get({
          url,
          query,
          headers,
          abortController: this.getOutboundAbortController
        })

        const response = await this.requestWithTimeout({
          request,
          cancel: this.cancelGetOutbound,
          timeout: this.timeout
        })

        if (!response.status) {
          if (retryCount >= this.maxRetries || response.code === 20) {
            return response
          }
          return await this.getOutbound(data, retryCount + 1)
        }
        return response
      }

      async getDuplicate (data: dataToGetOutboundDuplicate): Promise<GenericResponse> {
        const companyId = store.getters['CompanyStore/getSelectedCompany']?.uuid || 'none'
        const appId = store.getters['AppStore/getSelectedApp']?.uuid || ''
        const { campaigns } = data
        const url = `${this.baseUrl}/companies/${companyId}/apps/${appId}/campaigns/${campaigns}/users/duplicates`
        const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
        const requestOptions: getOptions = {
          headers,
          url,
          abortController: this.getOutboundAbortController
        }
        return await this.client.get(requestOptions)
      }

      async removeDuplicate (data: dataToGetOutboundDuplicate): Promise<GenericResponse> {
        const companyId = store.getters['CompanyStore/getSelectedCompany']?.uuid || 'none'
        const appId = store.getters['AppStore/getSelectedApp']?.uuid || ''
        const { campaigns } = data
        const url = `${this.baseUrl}/companies/${companyId}/apps/${appId}/campaigns/${campaigns}/users/remove/duplicates`
        const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
        const requestOptions: getOptions = {
          headers,
          url,
          abortController: this.getOutboundAbortController
        }
        return await this.client.get(requestOptions)
      }

      cancelGetOutbound = () => {
        this.getOutboundAbortController.abort()
        this.getOutboundAbortController = new AbortController()
      }
}
