
























import BikyConfigPersonalitySummary from './BikyConfigPersonalitySummary'

export default BikyConfigPersonalitySummary
