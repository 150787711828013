import { mapState } from 'vuex'
import Loader from '@/shared/infrastructure/ui/Loader.vue'
import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'
import KBSwitch from '@/shared/infrastructure/ui/switch/KBSwitch.vue'
import { appUpdater, dataToGetApp } from '@/modules/Apps/domain/AppsController'
import AppsUseCases from '@/modules/Apps/application/AppsUseCases'
import { EventBus } from '@/eventBus'
import AppsController from '@/modules/Apps/infrastructure/controllers/Apps.controller'
import EmitToast from '@/utils/EmitToast'
import { ActivityActionTypeEnum, ActivityLogModuleTypeEnum, dataToWriteActivityLog } from '@/modules/Config/ActivityLogs/domain/ActivityLogsController'
import ActivityLogsUseCases from '@/modules/Config/ActivityLogs/application/ActivityLogsUseCases'
import ActivityLogsController from '@/modules/Config/ActivityLogs/infrastructure/controllers/ActivityLogs.controller'

export default {
  components: {
    Loader,
    KeybeIcon,
    KBSwitch
  },
  computed: {
    ...mapState('AppStore', ['selectedApp']),
    ...mapState('UserStore', ['token', 'isUserReadonly', 'user']),
    iconColor () {
      return this.$vuetify.theme.dark ? 'white' : 'black'
    }
  },
  mounted () {
    this.getConfigData()
  },
  data () {
    return {
      loading: false,
      app: {},
      appsController: new AppsController(),
      activityLogsController: new ActivityLogsController(),
      switchBlockScreen: false,
      switchBlockCopy: false,
      switchBlockData: false,
      privacyUrl: `${process.env.VUE_APP_KEYBE_CO_URL}docs/ayudas/acuerdo-de-privacidad-habeas-data/habeas-data/`
    }
  },
  methods: {
    setData (field:string, value:string) {
      if (this.isUserReadonly) return
      const active = value ? '1' : '0'
      this.addOrUpdateConfig(this.app, field, active)
    },
    addOrUpdateConfig (obj: any, field: string, value: string) {
      if (!obj.config) {
        obj.config = []
      }
      const existingConfigIndex = obj.config.findIndex(config => config.field === field)
      if (existingConfigIndex > -1) {
        obj.config[existingConfigIndex].value = value
      } else {
        obj.config.push({ field, value })
      }

      this.writeActivityLog(field, value)
      this.saveAppInformation()
    },
    async writeActivityLog (field: string, value: string): Promise<void> {
      const data: dataToWriteActivityLog = {
        token: this.token,
        updateInput: {
          appUUID: this.selectedApp?.uuid,
          module: ActivityLogModuleTypeEnum.BRAND,
          action: ActivityActionTypeEnum.PRIVACY_SECURITY_UPDATE,
          user: {
            id: this.user.uuid,
            name: this.user.name + ' ' + this.user.lastName
          },
          payload: {
            field: field,
            newValue: value ? 'active' : 'inactive',
            oldValue: value ? 'inactive' : 'active'
          }
        }
      }
      await ActivityLogsUseCases.writeActivityLog(this.activityLogsController, data)
    },
    checkPropertyWithValue (array: any[], propertyName: string, expectedValue: string) {
      const foundItem = array.find(item => item.field === propertyName)
      if (foundItem && foundItem.value === expectedValue) {
        return true
      } else {
        return false
      }
    },
    validateParams (obj:any, prop:string) {
      return Object.prototype.hasOwnProperty.call(obj, prop)
    },
    async getConfigData (): Promise<void> {
      try {
        this.loading = true
        const data:dataToGetApp = {
          uuid: this.selectedApp?.uuid,
          token: this.token
        }
        const response = await AppsUseCases.getApp(this.appsController, data)
        if (response?.data) {
          this.app = response.data
          if (this.validateParams(response.data, 'config')) {
            const { config } = response.data
            this.switchBlockScreen = this.checkPropertyWithValue(config, 'BlockScreen', '1')
            this.switchBlockCopy = this.checkPropertyWithValue(config, 'BlockCopy', '1')
            this.switchBlockData = this.checkPropertyWithValue(config, 'BlockData', '1')
          }
        }
      } catch (error) {
        EventBus.$emit('toast', 'error', error.message)
      } finally {
        this.loading = false
      }
    },
    async saveAppInformation (): Promise<void> {
      try {
        const data = {
          app: this.app,
          token: this.token
        }
        const response: appUpdater = await AppsUseCases.updateAppInfo(this.appsController, data)
        if (response?.message) {
          EmitToast.emitErrors(response)
        } else {
          EventBus.$emit('toast', 'success', this.$t('appUpdated'))
        }
      } catch (e) {
        console.error(e)
      }
    }
  }
}
