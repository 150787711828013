import { GenericResponse } from '@/shared/infrastructure/http/GenericResponse'
import {
  IStatusController,
  dataToCreateStatus,
  dataToDeleteStatus,
  dataToGetStatuses,
  dataToUpdateStatus,
  statusGetter
} from '../../domain/StatusController'
import StatusRepository from '../repository/Status.repository'
import { Status } from '../../domain/Status'

export default class StatusController implements IStatusController {
  repository: StatusRepository

  constructor () {
    this.repository = new StatusRepository()
  }

  dispose (): void {
    this.repository.dispose()
  }

  async getStatuses (data: dataToGetStatuses) : Promise<Status[]> {
    const response: GenericResponse = await this.repository.getStatuses(data)
    if (response?.status) {
      return response?.data?.data
    } else {
      return []
    }
  }

  async createStatus (data: dataToCreateStatus) : Promise<statusGetter> {
    const response = await this.repository.createStatus(data)
    if (response?.status) return response?.data
    return response
  }

  async updateStatus (data: dataToUpdateStatus) : Promise<statusGetter> {
    const response = await this.repository.updateStatus(data)
    return response
  }

  async deleteStatus (data: dataToDeleteStatus) : Promise<statusGetter> {
    const response = await this.repository.deleteStatus(data)
    return response
  }
}
