import { VecindarioConfig } from '../domain/VecindarioConfig'
import { IVecindarioConfigController, dataToCreateConfig, dataToCreateUser, dataToGetConfig, dataToGetConfigUrl, dataToUpdateConfig } from '../domain/VecindarioConfigController'

export class VecindarioConfigUseCases {
  static getConfig (controller:IVecindarioConfigController, data: dataToGetConfig): Promise<VecindarioConfig> {
    return controller.getConfig(data)
  }

  static createVecindarioConfig (controller:IVecindarioConfigController, data: dataToCreateConfig): Promise<VecindarioConfig> {
    return controller.createVecindarioConfig(data)
  }

  static updateVecindarioConfig (controller:IVecindarioConfigController, data: dataToUpdateConfig): Promise<VecindarioConfig> {
    return controller.updateVecindarioConfig(data)
  }

  static getConfigUrl (controller:IVecindarioConfigController, data: dataToGetConfigUrl): Promise<string> {
    return controller.getConfigUrl(data)
  }

  static getCalendarUrl (controller:IVecindarioConfigController, data: dataToGetConfigUrl): Promise<string> {
    return controller.getCalendarUrl(data)
  }

  static getUserConfigUrl (controller:IVecindarioConfigController, data: dataToCreateUser): Promise<string> {
    return controller.getUserConfigUrl(data)
  }

  static abortAll (controller:IVecindarioConfigController): void {
    controller.abortAll()
  }
}
