<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       fill="none"
       viewBox="0 0 159 151">
    <path :fill="color"
          d="M2.33 151H120.73c.766 0 1.485-.37 1.926-1.018C156.778 99.98 157.985 8.214 158.008 4.327a2.32 2.32 0 0 0-2.275-2.337L37.332 0c-.696.023-1.253.231-1.694.694a2.299 2.299 0 0 0-.674 1.69C36.937 73.301.612 146.927.24 147.667c-.348.717-.325 1.573.116 2.245A2.32 2.32 0 0 0 2.33 151Zm117.17-4.628H5.997C12.983 131.24 40.65 67.332 39.653 4.651L153.32 6.594c-.417 14.6-4.062 95.029-33.819 139.801v-.023Z" />
  </svg>
</template>

<script>
export default {
  name: 'PaperIcon',
  props: {
    color: {
      type: String,
      default: '#000000'
    }
  }
}
</script>
