// import AutoassignService from '@/modules/configuration/domain/services/Autoassign.service'
import { AutoassignType } from '@/modules/configuration/domain/models/Autoassign/Autoassign'
// import { Spaces } from '@/modules/Spaces/domain/Spaces'
import KBSwitch from '@/shared/infrastructure/ui/switch/KBSwitch.vue'
import KBRadio from '@/shared/infrastructure/ui/inputs/select/KBRadio.vue'
import AutoassignTagsConfig from '@/modules/configuration/infrastructure/ui/components/Autoassign/Tags/AutoassignTagsConfig.vue'
import AutoassignGroupsConfig from '@/modules/configuration/infrastructure/ui/components/Autoassign/Groups/AutoassignGroupsConfig.vue'
import AutoassignAdvisorsConfig from '@/modules/configuration/infrastructure/ui/components/Autoassign/Advisors/AutoassignAdvisorsConfig.vue'
import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'
import { mapState } from 'vuex'
import { debounce } from 'lodash'
import ChatConfigController from '@/modules/ChatConfig/infrastructure/ChatConfig.controller'
import ModalWarning from '@/modules/Spaces/infrastructure/ui/SpacesRules/ModalWarning/ModalWarning.vue'
import { EventBus } from '@/eventBus'

export default {
  name: 'AutoassignConfig',
  components: {
    KBSwitch,
    KBRadio,
    AutoassignTagsConfig,
    AutoassignGroupsConfig,
    AutoassignAdvisorsConfig,
    KeybeIcon,
    ModalWarning
  },
  props: {
    inboxList: {
      type: Array,
      required: true
    },
    index: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      limit: 0,
      config: null,
      localInbox: [],
      isBalancedMode: false,
      isLinearMode: false,
      AutoassignType,
      awaitingUpdate: false,
      useMemory: false,
      useMemoryOffline: false,
      KeybeIconType,
      chatConfigController: new ChatConfigController(),
      showInboxConfig: false,
      showWarning: false,
      messageWarning: ''
    }
  },
  methods: {
    async getAutoAssignConfig (): Promise<void> {
      const currentInbox = this.localInbox[this.index]
      if (!currentInbox || currentInbox.maxActiveConversationsNumberPerAdvisor === undefined || currentInbox.autoassignationType === undefined) {
        this.showWarning = true
        this.messageWarning = this.$t('messageWarning', { propsConfig: this.$t('spaceAutoassignRules') })
      } else {
        this.limit = parseInt(currentInbox.maxActiveConversationsNumberPerAdvisor) || 0
        this.isBalancedMode = currentInbox.autoassignationType === AutoassignType.BALANCED
        this.isLinearMode = currentInbox.autoassignationType === AutoassignType.LINEAR
      }
    },
    saveConfig: debounce(function (): void {
      if (this.isUserReadonly) return
      this.localInbox[this.index].maxActiveConversationsNumberPerAdvisor = parseInt(this.localInbox[this.index]?.maxActiveConversationsNumberPerAdvisor)

      this.$emit('inbox-updated', {
        maxActiveConversationsNumberPerAdvisor: this.localInbox[this.index]?.maxActiveConversationsNumberPerAdvisor,
        autoassignationType: this.localInbox[this.index]?.autoassignationType
      })
      EventBus.$emit('inboxUpdated--spaces', this.localInbox)
    }, 500),
    getConfig (): void {
      this.$emit('getConfig')
    },
    getSliderBackground (): string {
      const percent: number = (this.localInbox[this.index]?.maxActiveConversationsNumberPerAdvisor / 300) * 100
      return `linear-gradient(to right, rgba(58, 246, 155, 1) 0%, rgba(58, 246, 155, 1) ${percent}%, #B6BEC7 ${percent}%, #B6BEC7 100%)`
    },
    closeModal (): void {
      this.showWarning = false
      this.messageWarning = ''
    },
    setInbox (inbox): void {
      // eslint-disable-next-line no-undef
      this.localInbox = structuredClone(inbox)
      this.getAutoAssignConfig()
    }
  },
  async created () {
    await this.setInbox(this.inboxList)
    EventBus.$on('inboxUpdated--spaces', this.setInbox.bind(this))
    this.getAutoAssignConfig()
  },
  computed: {
    ...mapState('AppStore', ['selectedApp']),
    ...mapState('UserStore', ['token', 'isUserReadonly']),
    currentInbox (): any {
      return this.localInbox[this.index] || {}
    }

  },
  watch: {
    'localInbox.maxActiveConversationsNumberPerAdvisor' (newValue) {
      this.$el.style.setProperty('--slider-value', `${newValue}`)
      this.$el.style.setProperty('--slider-max', `${this.limit}`)
      this.$el.style.setProperty('--slider-percent', `${(newValue / this.limit) * 100}%`)
    }
  }
}
