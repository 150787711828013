import { OutboundTabs } from '@/modules/outbound/infrastructure/enums/OutboundTabs'
import { mapState } from 'vuex'
import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import FeesServices from '@/modules/configuration/application/services/FeesServices'
import EmailIcon from '@/shared/infrastructure/ui/icons/SvgComponents/EmailIcon.vue'
import CampaignsService from '@/services/CampaignsService'
import { EventBus } from '@/eventBus'

import KeybeAvatar from '@/shared/infrastructure/ui/KeybeAvatar.vue'
import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'

export default {
  name: 'OutboundSidebar',
  components: {
    EmailIcon,
    KeybeAvatar,
    KeybeIcon
  },
  props: {
    selectedTab: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      OutboundTabs,
      KeybeIconType,
      hasFees: false,
      loading: false
    }
  },
  computed: {
    ...mapState('AppStore', ['selectedApp']),
    ...mapState('UserStore', ['isUserReadonly']),
    ...mapState('BillingStore', ['appConfig']),
    appImage (): string {
      return this.selectedApp?.photo || ''
    },
    summaryData: {
      get () {
        return this.$store.state.CampaingsStore.summaryData || null
      },
      set (value) {
        this.$store.commit('CampaingsStore/SET_EDITING_OBJECT', {
          key: 'summaryData',
          value
        })
      }
    },
    outboundName: {
      get () {
        return this.$store.state.CampaingsStore.outboundName || null
      },
      set (value) {
        this.$store.commit('CampaingsStore/SET_EDITING_OBJECT', {
          key: 'outboundName',
          value
        })
      }
    },
    summaryMenu: {
      get () {
        return this.$store.state.CampaingsStore.summaryMenu || false
      },
      set (value) {
        this.$store.commit('CampaingsStore/SET_EDITING_OBJECT', {
          key: 'summaryMenu',
          value
        })
      }
    },
    currentSelectedTab: {
      get () {
        return this.$store.state.CampaingsStore.currentSelectedTab || null
      },
      set (value) {
        this.$store.commit('CampaingsStore/SET_EDITING_OBJECT', {
          key: 'currentSelectedTab',
          value
        })
      }
    },
    appName (): string {
      return this.selectedApp?.name || ''
    },
    balanceComputed () {
      let value = this.appConfig.balanceUSD
      if (value) value = this.appConfig.balanceUSD
      return value
    }
  },
  watch: {
    currentSelectedTab () {
      if (this.currentSelectedTab) {
        this.selectTab(this.currentSelectedTab)
      }
    }
  },
  methods: {
    async exportCampaign () {
      try {
        this.loading = true
        await CampaignsService.exportCampaign({
          campaignId: this.summaryData.id ? this.summaryData.id : this.summaryData._id,
          filename: this.outboundName
        })
        EventBus.$emit('toast', 'success', this.$t('successfullCampaignExport'))
        this.loading = false
      } catch {
        this.loading = false
        EventBus.$emit('toast', 'success', this.$t('unsuccesfullCampaignExport'))
      }
    },
    async getFeesUser () {
      const feesData = await FeesServices.isCommissionAgent()
      if (feesData?.status) this.hasFees = true
    },
    isSelectedTab (tab: OutboundTabs) {
      return this.selectedTab === tab
    },
    selectTab (tab: OutboundTabs) {
      if (this.selectedTab === tab) return
      if (this.isUserReadonly && tab !== OutboundTabs.HOME && tab !== OutboundTabs.TEMPLATE) return
      this.currentSelectedTab = tab
      this.$emit('selectTab', tab)
    },
    formatCurrency (value, currency) {
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency
      })
      return formatter.format(value)
    }
  },
  mounted () {
    this.getFeesUser()
    this.currentSelectedTab = this.selectedTab
  }
}
