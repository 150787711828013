export default class Colors {
  static dark1: string = '#4c535c'
  static dark2: string = '#8898aa'
  static dark3: string = '#e9eaed'
  static dark4: string = '#ecedf1'
  static light1: string = '#f6f9fc'
  static light2: string = '#f0f4fd'
  static light3: string = '#f9f9fb'
  static light4: string = '#eef1f5'
  static blue1: string = '#b3fdff'
  static blue2: string = '#00cff2'
  static blue3: string = '#3366ff'
  static blue4: string = '#0faef6'
  static facebook: string = '#1877f2'
  static bubble: string = '#258fff'
  static blueNight: string = '#162746'
  static green1: string = '#93ff80'
  static green2: string = '#00cc99'
  static green3: string = '#007d87'
  static yellow1: string = '#ffe9a4'
  static yellow2: string = '#ffcc00'
  static yellow3: string = '#a58500'
  static automate1: string = '#ff99cc'
  static automate2: string = '#fc3473'
  static automate3: string = '#88116d'
  static formDark: string = '#2f2f2fcc'
  static toogleOn: string = '#e2ffc7'
  static white: string = '#ffffff'
  static offWhite: string = this.white
  static black: string = '#000000'
  static grey: string = '#333538'
  static grey2: string = '#D9D9D9'
  static verdeBird: string = '#01cfe4'
  static gray: string = this.dark2
  static green4: string = '#3AF69B'
  static darkGreen: string = '#56BC99'
  static darkThemeDark4: string = '#262f38'
  static darkThemeWhite: string = '#333F4B'
  static darkThemeLight1: string = '#303A44'
  static darkThemeDark3: string = this.darkThemeLight1
  static darkThemeLight2: string = '#4c555d'
  static darkThemeLight3: string = this.darkThemeLight1
  static darkThemeOffWhite: string = '#3d424a'
  static darkThemeLight4: string = '#2d3741'
  static gauchoBlue: string = '#D8F2F8'
  static simpleGrey: string = '#B6BEC7'
  static offBlack: string = '#191a1e'
  static morningCoffee: string = '#171717'
  static rojoBird: string = '#F7685B'
  static kbGreen: string = this.green4
  static textLight: string = '#8898A9'
  static inputLight: string = '#EBECEE'
  static inputDark: string = '#252628'
  static inboxGreen: string = '#6aa84f'
  static inboxfuchsia: string = '#FC3473'
  static inboxYellow: string = '#FFCC00'
  static inboxCian: string = '#01CFE4'
  static inboxPurple: string = '#88116D'
  static inboxPink: string = '#FF99CC'
  static inboxOrange: string = '#FF4D00'
  static inboxAquamarine: string = '#0CFFD3'
  static inboxLightPeach: string = '#FFA8A8'
  static inboxBrilliantLavender: string = '#E499FF'

  static colors: string[] = [
    '#3A6AF6',
    '#3A9BF6',
    '#3ADEF6',
    '#3AE6F6',
    '#3AE6D0',
    '#3AE6A3',
    '#3AE675',
    '#3AE649',
    '#3AE61D',
    '#49E63A',
    '#75E63A',
    '#A3E63A',
    '#CDE63A',
    '#E6E63A',
    '#E6CD3A',
    '#E6A33A',
    '#E6753A',
    '#E6493A',
    '#E61D3A',
    '#F63A6A',
    '#F63A9B',
    '#F63ADE',
    '#F63AE6',
    '#F63ACD',
    '#F63AA3',
    '#F63A75',
    '#F63A49',
    '#F63A1D',
    '#6A3AF6',
    '#9B3AF6',
    '#DE3AF6',
    '#E63AF6',
    '#E6A3F6',
    '#E675F6',
    '#E649F6',
    '#E61DF6',
    '#6AF63A',
    '#9BF63A',
    '#DEF63A',
    '#E6F63A',
    '#E6D03A',
    '#E6A33A',
    '#E6753A',
    '#E6493A',
    '#E61D3A',
    '#6AF6E6',
    '#9BF6E6',
    '#DEF6E6',
    '#E6F6E6',
    '#E6D0E6',
    '#E6A3E6',
    '#E675E6',
    '#E649E6',
    '#E61DE6',
    '#6A3AE6',
    '#9B3AE6',
    '#DE3AE6',
    '#E63AE6',
    '#E6A3E6',
    '#E675E6',
    '#E649E6',
    '#E61DE6',
    '#3A6AE6',
    '#3A9BE6',
    '#3ADEE6',
    '#3AE6E6',
    '#3AE6D0',
    '#3AE6A3',
    '#3AE675',
    '#3AE649',
    '#3AE61D',
    '#6A3AF6',
    '#9B3AF6',
    '#DE3AF6',
    '#E63AF6',
    '#E6A3F6',
    '#E675F6',
    '#E649F6',
    '#E61DF6',
    '#6AF63A',
    '#9BF63A',
    '#DEF63A',
    '#E6F63A',
    '#E6D03A',
    '#E6A33A',
    '#E6753A',
    '#E6493A',
    '#E61D3A',
    '#6AF6E6',
    '#9BF6E6',
    '#DEF6E6',
    '#E6F6E6',
    '#E6D0E6',
    '#E6A3E6',
    '#E675E6',
    '#E649E6',
    '#E61DE6',
    '#6A3AE6',
    '#9B3AE6',
    '#DE3AE6',
    '#6aa84f',
    '#FC3473',
    '#FFCC00',
    '#01CFE4',
    '#88116D',
    '#FF99CC',
    '#FF4D00',
    '#0CFFD3',
    '#FFA8A8',
    '#E499FF'
  ]
}
