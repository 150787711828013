<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       fill="none"
       viewBox="0 0 10 15"><path :fill="color"
                                 d="M9.944 5.2a.72.72 0 0 0-.13-.762L5.948.22a.667.667 0 0 0-.988 0L1.097 4.438a.72.72 0 0 0-.131.762.683.683 0 0 0 .625.425h2.5v5.625a.923.923 0 0 1-.909.938H.91a.923.923 0 0 0-.909.937v.938c0 .518.406.937.91.937h2.272c2.009 0 3.637-1.679 3.637-3.75V5.625h2.5a.68.68 0 0 0 .625-.425Z"/></svg>
</template>

<script>
export default {
  name: 'tierIcon',
  props: {
    color: {
      type: String,
      default: '#000'
    }
  }
}
</script>

  <style>

  </style>
