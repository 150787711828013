<template>
  <div class="KBTextarea"
       :style="`${forceHeight ? 'height: ' + forceHeight + 'px' : ''}`"
       :class="{ 'KBTextarea--seamless': seamless,
                 'KBText--seamless-focused': focused }">
    <textarea type="text"
              rows="5"
              ref="input"
              :maxLength="maxLength"
              v-model="text"
              :placeholder="placeholder"
              @input="input"
              @focus="focused = true"
              @blur="focused = false"
              :disabled="disabled"/>
    <div class="KBTextarea__counter">
      <span v-if="maxLength">{{ counter }}/{{ maxLength }}</span>
    </div>
  </div>
</template>
<script>
export default {
  props: ['value', 'placeholder', 'maxLength', 'seamless', 'disabled', 'clear', 'focus', 'forceHeight'],
  mounted () {
    if (this.focus) this.$refs.input.focus()
    window.addEventListener('keydown', this.handleShortcuts)
  },
  data () {
    return {
      text: this.value,
      counter: 0,
      focused: false
    }
  },
  methods: {
    input () {
      this.counter = this.text.length
      this.$emit('input', this.text)
    },
    save () {
      if (this.focused) this.$emit('save', this.text)
    },
    handleShortcuts (e) {
      if (e.shiftKey && e.which === 13) {
        if (this.text) this.text = this.text + '\r\n'
      } else if (e.which === 13 && !e.shiftKey) {
        e.preventDefault()
        e.stopPropagation()
        this.save()
      }
    }
  },
  watch: {
    value () {
      if (this.value) {
        this.text = this.value
        this.counter = this.text?.length || 0
      }
    },
    clear () {
      if (this.clear) this.text = ''
    },
    focus () {
      if (this.focus) this.$refs.input.focus()
    }
  },
  beforeDestroy () {
    if (window) window.addEventListener('keydown', this.handleShortcuts)
  }
}
</script>
<style scoped lang="scss">
.KBTextarea {
  background: rgba(182, 190, 199, 0.2);
  height: 171px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 30px 10px 30px;
  caret-color: #01CFE4;

  textarea {
    height: 100%;
    width: 100%;
    outline: none;
    resize: none;
    font-size: 0.875rem;
  }

  &__counter {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    span {
      font-size: 0.688rem;
      color: #B6BEC7;
    }
  }
}

.KBTextarea--seamless {
  width: auto;
  background: rgba(182, 190, 199, 0.18);
  transition: background 0.4s;

  &:hover {
    background: rgba(182, 190, 199, 0.2);
  }
}

.KBTextarea--seamless-focused {
  background: rgba(182, 190, 199, 0.2);

  &:hover {
    background: rgba(182, 190, 199, 0.2);
  }
}
</style>
