import { EventBus } from '@/eventBus'
import { mapActions, mapState } from 'vuex'
import { Task } from '@/modules/tasks/domain/Task'
import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'

import KeybeAvatar from '@/shared/infrastructure/ui/KeybeAvatar.vue'
import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'
import { EntityTypeEnum } from '@/modules/CDP/shared/contacts/domain/Contacts'

export default {
  name: 'TaskInfo',
  components: {
    KeybeAvatar,
    KeybeIcon
  },
  props: {
    task: {
      required: true
    }
  },
  data () {
    return {
      KeybeIconType
    }
  },
  computed: {
    ...mapState('AppStore', ['advisers']),
    taskComputed () :Task {
      return this.task
    },
    responsibleName () :string {
      const name: string = this.taskComputed?.responsible?.name
      if (!name) return 'N/A'
      return name.replaceAll('undefined', '')
    },
    responsibleImg (): string {
      const adviserId = this.taskComputed?.responsible?.uuid
      const adviser = this.advisers.find((adviser: any) => adviser.uuid === adviserId)
      return adviser?.image || ''
    },
    taskTypeName () :string {
      return this.taskComputed?.taskType?.name || 'N/A'
    },
    contactName () :string {
      const name: string = this.taskComputed?.contact?.name
      if (!name) return 'N/A'
      return name.replaceAll('undefined', '')
    }
  },
  methods: {
    ...mapActions('FloatingWindowsStore', ['setMaximazedWindow', 'addMinimizedWindow']),
    openContact () {
      if (this.taskComputed?.contact?.uuid) {
        setTimeout(() => {
          EventBus.$emit('openDrawer--details', this.taskComputed?.contact?.uuid, EntityTypeEnum.PEOPLE)
          EventBus.$emit('openOpportunityDetails', { type: 'user', data: this.taskComputed?.contact?.uuid })
          this.setMaximazedWindow(null)
        }, 400)
      }
    }
  }
}
