import moment from 'moment'
import { EventBus } from '@/eventBus'
import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import DateTimePickerRow from '@/modules/outbound/infrastructure/ui/components/DateTimePickerRow.vue'
import { billCampaings } from '@/modules/outbound/infrastructure/ui/components/methods/billCampaings'
import CampaignsService from '@/services/CampaignsService'
import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'
import TitleDetail from '@/modules/metrics/infrastructure/ui/components/generic/TitleDetail.vue'
import KBCard from '@/shared/infrastructure/ui/KBCard.vue'
import KBButton from '@/shared/infrastructure/ui/buttons/KBButton.vue'
import USDCard from '@/modules/outbound/infrastructure/ui/components/cards/USDCard.vue'

import BasicMetrics from '@/modules/outbound/infrastructure/ui/components/Detail/BasicMetrics/BasicMetrics.vue'
import BasicCard from '@/modules/outbound/infrastructure/ui/components/cards/BasicCard.vue'
import TemplatePreview from '@/modules/outbound/infrastructure/ui/components//TemplatePreview/TemplatePreview.vue'
import TemplatePreviewSms from '@/modules/outbound/infrastructure/ui/components/metrics/TemplatePriewSms.vue'
import ReadytoSend from '@/modules/outbound/infrastructure/ui/components/Detail/ReadytoSend/ReadytoSend.vue'
import Importing from '@/modules/outbound/infrastructure/ui/components/Detail/Importing/Importing.vue'
import Scheduled from '@/modules/outbound/infrastructure/ui/components/Detail/Scheduled/Scheduled.vue'
import ChartDetail from '@/modules/outbound/infrastructure/ui/components/Detail/ChartDetail/ChartDetail.vue'

export default {
  name: 'OustandingMetrics',
  components: {
    ChartDetail,
    BasicMetrics,
    ReadytoSend,
    Importing,
    Scheduled,
    DateTimePickerRow,
    KeybeIcon,
    USDCard,
    TitleDetail,
    KBCard,
    KBButton,
    BasicCard,
    TemplatePreview,
    TemplatePreviewSms
  },
  mounted () {
    this.asignData()
  },
  data () {
    return {
      costData: {
        totalAmountToPay: 0,
        totalChannelsMultiparts: 0
      },
      campaignElement: null,
      moment,
      schedule: false,
      KeybeIconType,
      showDateTimePicker: false,
      loading: false,
      cardLoading: false,
      dataText: null,
      decimal: null,
      USDCardContent: USDCard,
      BasicCard: BasicCard,
      provider: {},
      templateMediaUrl: null,
      messagePreview: null,
      buttonsLabels: null,
      status: null,
      statusId: null,
      disabledModal: false,
      scheduleDate: ''
    }
  },
  computed: {
    userHost () {
      if (!this.summaryData) {
        return
      }
      if (this.summaryData.whatsApp) {
        const host = Object.prototype.hasOwnProperty.call(this.summaryData.whatsApp, 'userHost')
        if (host) {
          return this.summaryData.whatsApp.userHost
        }
      }
    },
    summaryData: {
      get () {
        return this.$store.state.CampaingsStore.summaryData || null
      },
      set (value) {
        this.$store.commit('CampaingsStore/SET_EDITING_OBJECT', {
          key: 'summaryData',
          value
        })
      }
    },
    programmingDate: {
      get () {
        return this.$store.state.CampaingsStore.programmingDate || null
      },
      set (value) {
        this.$store.commit('CampaingsStore/SET_EDITING_OBJECT', {
          key: 'programmingDate',
          value
        })
      }
    },
    porcentaje: {
      get () {
        return this.$store.state.CampaingsStore.porcentaje || null
      },
      set (value) {
        this.$store.commit('CampaingsStore/SET_EDITING_OBJECT', {
          key: 'porcentaje',
          value
        })
      }
    }
  },
  watch: {
    programmingDate: {
      handler (val) {
        if (this.programmingDate) {
          this.schedule = true
          const { hours, day, month, year } = this.programmingDate
          this.scheduleDate = `${year}-${month}-${day}T${hours}:00.000Z`
        }
      },
      deep: true
    },
    summaryData () {
      if (this.summaryData) {
        // this.summaryData = this.summaryData
        // this.campaingsId = this.summaryData.id
        this.asignData()
      }
    }
  },
  methods: {
    billCampaings,
    formatDate (date) {
      const newDate = new Date(date)
      return newDate.getHours() + ':' + newDate.getMinutes() + '-' + newDate.getDate() + '/' + ((newDate.getMonth() + 1) < 10 ? '0' + +(newDate.getMonth() + 1) : newDate.getMonth() + 1) + '/' + newDate.getFullYear()
    },
    numbersFilter: (value) => {
      if (typeof value !== 'number') return value
      const locale = Intl.NumberFormat('en-US')
      return locale.format(value)
    },
    async callBillCampaings () {
      try {
        this.loading = true
        if (this.summaryData.type === 'sms') {
          this.porcentaje = 0
          this.cardLoading = true
        }
        const scheduleDate = this.scheduleDate ? this.scheduleDate : this.summaryData.createdAt
        const result = await this.billCampaings(this.summaryData, scheduleDate, this.schedule)
        if (result) {
          const { billing, campaign } = result
          if (billing && billing.success) {
          } else {
            EventBus.$emit('toast', 'error', this.$t('noBalanceTitle'))
          }
          if (campaign) {
            if (this.scheduleDate) {
              EventBus.$emit('toast', 'success', this.$t('scheduledCampaign'))
            } else {
              EventBus.$emit('toast', 'success', this.$t('sendedCampaign'))
            }
            this.getCampaign()
            this.asignData()
          } else {
            EventBus.$emit('toast', 'error', this.$t('campaignError'))
          }
        } else {
          EventBus.$emit('toast', 'error', this.$t('campaignError'))
        }
        this.cardLoading = false
        this.loading = false
        this.showDateTimePicker = false
      } catch (error) {
        EventBus.$emit('toast', 'error', this.$t('campaignError'))
        this.loading = false
        this.showDateTimePicker = false
        this.cardLoading = false
      }
    },
    setScheduleDate (time) {
      this.schedule = true
      this.scheduleDate = time
    },
    getStatusMsg (type) {
      switch (type) {
        case 'sending':
          return this.$t('campaignsSending')
        case 'sent':
          return this.$t('campaignsSent')
        case 'inReview':
          return this.$t('campaignsInReview')
        case 'draft':
          return ''
        case 'scheduled':
          return this.$t('campaignsScheduled')
        case 'importing':
          return this.$t('campaignsImporting')
        default:
          return 'None'
      }
    },
    async getCampaignId () {
      this.loading = true
      try {
        const response = await CampaignsService.getCampaignV6(this.summaryData.id)
        this.loading = false
        if (response.campaign) {
          EventBus.$emit('toast', 'success', this.$t('campaignDataLoad'))
          this.campaignElement = response.campaign
          this.validateDate(this.campaignElement)
          const { totalOnDelivery, totalRejected, totalCanceled, totalErrored, totalUsers } = this.campaignElement
          if (!totalUsers) {
            return
          }
          if (this.campaignElement.status) {
            this.status = this.$t(this.campaignElement.status)
          }
          this.porcentaje = ((totalOnDelivery + totalRejected + totalCanceled + totalErrored) / totalUsers) * 100
        }
      } catch (e) {
        console.error(e)
      }
    },
    validateDate (campaign) {
      const { date } = campaign
      const fechauno = new Date(date)
      const fechados = new Date()
      const resultado = fechauno.getTime() === fechados.getTime()
      this.disabledModal = resultado
    },
    asignData () {
      if (this.summaryData) {
        this.validateDate(this.summaryData)
        if (this.summaryData.status) {
          this.status = this.$t(this.summaryData.status)
        }
        this.statusId = this.summaryData.status
        if (this.summaryData.type === 'whatsApp') {
          if (this.summaryData[this.summaryData.type].parameters[0]) {
            this.messagePreview = this.summaryData[this.summaryData.type].parameters[0]
          } else {
            this.messagePreview = this.summaryData.whatsApp.message
          }
        }
        if (this.summaryData.type === 'sms') {
          const { sms } = this.summaryData
          if (Object.hasOwn(sms, 'totalAmountToPay')) {
            this.dataText = this.summaryData[this.summaryData.type].totalAmountToPay
            this.costData = {
              totalAmountToPay: sms.totalAmountToPay ? sms.totalAmountToPay : 0,
              totalChannelsMultiparts: sms.totalChannelsMultiparts ? sms.totalChannelsMultiparts : 0
            }
          } else {
            this.dataText = this.$t('pending')
          }
          this.messagePreview = this.summaryData[this.summaryData.type].message
        }
        if (this.summaryData.type === 'email') {
          this.messagePreview = this.summaryData[this.summaryData.type].html
        }
      }
    }
  }
}
