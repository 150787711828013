// import { App } from '@/components/models/App'
import { mapActions, mapState } from 'vuex'
import PushNotificationsController from '@/controllers/PushNotificationsController'
import { UserStates } from '@/components/conversations/enums/UserStates'
import AppsUseCases from '@/modules/Apps/application/AppsUseCases'
import AppsController from '@/modules/Apps/infrastructure/controllers/Apps.controller'
import EmitToast from '@/utils/EmitToast'
import link from '@/utils/links'
import { App } from '@/modules/Apps/domain/Apps'
import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'

// Components
import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'
import KBMenu from '@/shared/infrastructure/ui/menus/KBMenu/KBMenu.vue'

export default {
  props: {
    centerClass: {
      type: Boolean,
      default: false
    }
  },
  components: {
    KeybeIcon,
    KBMenu
  },
  data () {
    return {
      show: false,
      search: '',
      loadingCompanies: false,
      filteredApps: [],
      closeOnClick: false,
      awaitingSearch: false,
      limit: 50,
      appsController: new AppsController(),
      KeybeIconType
    }
  },
  methods: {
    ...mapActions('AppStore', ['getAdviserStatus', 'setApps']),
    async _getCompanies () {
      try {
        this.loadingCompanies = true
        this.filteredApps = this.apps.slice(0, this.limit)
        this.loadingCompanies = false
      } catch (e) {
        console.error(e)
        this.loadingCompanies = false
      }
    },
    async filterApps () {
      if (!this.awaitingSearch) {
        setTimeout(async () => {
          const search = this.search ? this.search.toLowerCase() : ''
          const data = {
            token: this.token,
            search: search,
            offset: 0,
            limit: this.limit
          }
          const response = await AppsUseCases.getApps(this.appsController, data)
          if (Array.isArray(response)) this.setApps(response)
          else EmitToast.emitErrors(response)
          this.awaitingSearch = false
          if (this.apps.length > this.limit) {
            this.filteredApps = this.apps.slice(0, this.limit)
          } else {
            this.filteredApps = this.apps
          }
        }, 500)
      }
      this.awaitingSearch = true
    },
    goTo (link: string) {
      if (Object.keys(this.$route.query).length !== 0) {
        this.$router.replace({ path: this.$route.path, query: null })
      }
      if (this.$route.path === link) return

      this.$router.push(link)
    },
    async selectApp (app: App) {
      this.show = false
      // const data = V6RedirectionController.validateRedirect(window.location.origin, app)
      // if (data?.shouldRedirect) {
      //   await V6RedirectionController.setRedirectionExtradata(app)
      //   window.location.href = `${data.url}?tokenV6=${this.token}`
      //   return
      // }
      if (app.inactive) {
        return EmitToast.emitErrors({
          message: this.$t('inactiveApp'),
          status: false
        })
      }
      if (
        this.selectedApp &&
        this.selectedApp?.uuid &&
        app?.uuid !== this.selectedApp?.uuid
      ) {
        await PushNotificationsController.setOneSignalStatus({
          active: false,
          appUUID: this.selectedApp?.uuid,
          companyUUID: this.selectedCompany?.uuid
        })
      }
      const data = {
        name: app.name,
        uuid: app.uuid,
        token: this.token
      }
      await AppsUseCases.selectApp(this.appsController, data)
      const currentStatus = await this.getAdviserStatus(this.user?.userId)
      const value = currentStatus === UserStates.Online
      await PushNotificationsController.setOneSignalStatus({ active: value })
      this.callRedirect()
    },
    callRedirect () {
      if (!this.activeApp) {
        this.goTo(link.payment)
      } else {
        this.goTo(link.conversations)
      }
    }
  },
  watch: {
    inactiveApp () {
      this.callRedirect()
    }
  },
  computed: {
    ...mapState('AppStore', ['selectedApp', 'apps']),
    ...mapState('CompanyStore', ['selectedCompany']),
    ...mapState('UserStore', ['user', 'token']),
    storageRoute (): string {
      return process.env.VUE_APP_STORAGE_URL
    },
    activeApp (): boolean {
      const status = this.selectedApp?.status
      return status === 'active' || status == null || status === true || status === false || status === 200
    },
    appName () {
      return this.selectedApp?.name || this.$t('selectApp')
    }
  },
  created () {
    this._getCompanies()
  }
}
