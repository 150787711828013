










































































import KBInvoicesList from './KBInvoicesList'
export default KBInvoicesList
