import Colors from '@/utils/Colors'
import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import KeybeButton from '@/modules/DesignSystem/infrastructure/components/KeybeButton/KeybeButton.vue'
import KeybeText from '@/modules/DesignSystem/infrastructure/components/KeybeText/KeybeText.vue'
import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'
import EmitToast from '@/utils/EmitToast'
export default {
  components: {
    KeybeText,
    KeybeButton,
    KeybeIcon
  },
  props: {
    contact: {
      type: Object
    },
    field: {
      type: String
    },
    placeholder: {
      type: String,
      default: 'writeHere'
    },
    disabled: {
      type: Boolean
    },
    limit: {
      type: Number
    }
  },
  computed: {
    noValues () {
      return !this.contact || !this.contact[this.field] || this.contact[this.field]?.length === 0
    }
  },
  mounted () {
    if (!this.noValues) {
      this.contact[this.field].forEach(val => {
        this.values.push({
          value: val
        })
      })
    } else this.addValue()
  },
  data () {
    return {
      values: [],
      Colors,
      KeybeIconType
    }
  },
  methods: {
    setValues (e, i) {
      this.values[i] = {
        value: e
      }
      this.$emit('input', this.values.map(val => val.value))
    },
    removeValue (i) {
      if (!this.disabled) {
        this.values.splice(i, 1)
        this.$emit('input', this.values.map(val => val.value))
      }
    },
    addValue () {
      if (this.limit && this.values.length >= this.limit) {
        EmitToast.emitErrors(this.$t('limitIs', { limit: this.limit }))
        return
      }
      this.values.push({ value: '' })
    }
  }
}
