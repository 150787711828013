import { mapState } from 'vuex'
import APIKeysController from '../../controllers/APIKeys.controller'
import APIKeysUseCases from '@/modules/APIKeysConfig/application/APIKeysUseCases'
import EmitToast from '@/utils/EmitToast'
import KeybeDate from '@/modules/DesignSystem/infrastructure/components/KeybeDate/KeybeDate.vue'
import KeybeButton from '@/modules/DesignSystem/infrastructure/components/KeybeButton/KeybeButton.vue'
import { ActivityActionTypeEnum, ActivityLogModuleTypeEnum, dataToWriteActivityLog } from '@/modules/Config/ActivityLogs/domain/ActivityLogsController'
import ActivityLogsUseCases from '@/modules/Config/ActivityLogs/application/ActivityLogsUseCases'
import { CDPFieldsEnums } from '@/modules/CDP/shared/contacts/domain/enums/CDPFieldsEnums'
import ActivityLogsController from '@/modules/Config/ActivityLogs/infrastructure/controllers/ActivityLogs.controller'
import Colors from '@/utils/Colors'
export default {
  components: {
    KeybeDate,
    KeybeButton
  },
  computed: {
    ...mapState('AppStore', ['selectedApp']),
    ...mapState('UserStore', ['token', 'user'])
  },
  data () {
    return {
      expirationDate: '',
      loading: false,
      apiKeyController: new APIKeysController(),
      activityLogsController: new ActivityLogsController(),
      Colors
    }
  },
  methods: {
    async create () {
      try {
        this.loading = true
        const variables = {
          app: this.selectedApp?.uuid,
          token: this.token,
          createInput: {
            expired_at: this.expirationDate.substring(0, 10) + 'T23:59:00'
            // expired_at: this.expirationDate.sub + 'T23:59:00'
          }
        }
        if (!this.expirationDate) delete variables.createInput
        const response = await APIKeysUseCases.createAPIKey(this.apiKeyController, variables)
        if (response?.status) {
          this.$emit('close')
          this.writeActivityLog(response?.data?.key)
        } else EmitToast.emitErrors(response)
        this.loading = false
      } catch (error) {
        this.loading = false
        this.$emit('close')
        console.error(error)
      }
    },
    async writeActivityLog (key: string): Promise<void> {
      const data: dataToWriteActivityLog = {
        token: this.token,
        updateInput: {
          appUUID: this.selectedApp?.uuid,
          module: ActivityLogModuleTypeEnum.API,
          action: ActivityActionTypeEnum.API_KEY_DELETE,
          user: {
            id: this.user.uuid,
            name: this.user.name + ' ' + this.user.lastName
          },
          payload: {
            field: CDPFieldsEnums.KEY,
            newValue: key,
            oldValue: ''
          }
        }
      }
      await ActivityLogsUseCases.writeActivityLog(this.activityLogsController, data)
    }
  }
}
