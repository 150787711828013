import CreateFormLanding from '@/modules/Config/WidgetTesseract/infrastructure/ui/CreateFormLanding/CreateFormLanding.vue'
import CreateFormWhatsApp from '@/modules/Config/WidgetTesseract/infrastructure/ui/CreateFormWhatsApp/CreateFormWhatsApp.vue'
import WebWidgetConfig from '@/modules/Config/WidgetTesseract/infrastructure/ui/WebWidget/WebWidgetConfig/WebWidgetConfig.vue'
import WidgetTesseractUseCases from '@/modules/Config/WidgetTesseract/application/WidgetTesseractUseCases'
import WidgetTesseractController from '@/modules/Config/WidgetTesseract/infrastructure/WidgetTesseract.controller'
import APIKeysController from '@/modules/APIKeysConfig/infrastructure/controllers/APIKeys.controller'
import ConversationsConfigController from '@/components/configuration/controllers/ConversationsConfigController'
import CreateFormBiky from '@/modules/Config/WidgetTesseract/infrastructure/ui/CreateFormBiky/CreateFormBiky.vue'
import WebWidgetService from '@/modules/configuration/domain/services/WebWidget.service'
import KeybeSelect from '@/modules/DesignSystem/infrastructure/components/KeybeSelect/KeybeSelect/KeybeSelect.vue'
import { ConfigurationTabsEnum } from '@/modules/configuration/application/enums/ConfigurationTabsEnum'
import { EventBus } from '@/eventBus'
import APIKeysUseCases from '@/modules/APIKeysConfig/application/APIKeysUseCases'
import KeybeText from '@/modules/DesignSystem/infrastructure/components/KeybeText/KeybeText.vue'
import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'
import { typeFormEnum, typeBikyOptions } from '@/modules/Config/WidgetTesseract/domain/WidgetTesseract'
import { mapState } from 'vuex'
import KBSwitch from '@/shared/infrastructure/ui/switch/KBSwitch.vue'
import EmitToast from '@/utils/EmitToast'

export default {
  name: 'CreateFormConfig',
  components: {
    CreateFormLanding,
    WebWidgetConfig,
    CreateFormWhatsApp,
    CreateFormBiky,
    KeybeSelect,
    KBSwitch,
    KeybeText,
    KeybeIcon
  },
  computed: {
    ...mapState('AppStore', ['selectedApp']),
    ...mapState('UserStore', ['token']),
    generateUrl () {
      const nameApp = this.selectedApp?.name.replace(/\s/g, '')
      return `Keybe.ai/${nameApp}`
    }
  },
  data () {
    return {
      dataTocreate: {},
      KeybeIconType,
      types: [typeFormEnum.LandingPage, typeFormEnum.BikyForm],
      selectedType: '',
      optionsType: [],
      type: '',
      title: '',
      selectedApiKey: {},
      apiKeys: [],
      widgetTesseractController: new WidgetTesseractController(),
      apiKeysController: new APIKeysController(),
      config: {},
      chatConfig: {},
      allowMessagesOutsideBusinessHours: false,
      allowMultimediaMessages: false,
      formatKey: ''
    }
  },
  async mounted () {
    this.getDataDefault()
    await this.getAPIKeys()
    await this.getConfig()
  },
  methods: {
    getDataDefault () {
      this.dataTocreate = WidgetTesseractUseCases.processDataDefault(this.widgetTesseractController)
    },
    changeType () {
      this.type = this.dataTocreate?.type
      this.setOptionsPreview()
    },
    selectedTypeChange (type) {
      this.selectedType = type
    },
    setOptionsPreview () {
      if (this.type === typeFormEnum.LandingPage) {
        this.optionsType = []
        this.optionsType.push(this.$t('Desktop'), this.$t('Mobile'))
        this.selectedType = this.optionsType[0]
      }
      if (this.type === typeFormEnum.WhatsappForm) {
        this.optionsType = []
        this.optionsType.push(this.$t('Collapsed'), this.$t('Expanded'))
        this.selectedType = this.optionsType[0]
      }

      if (this.type === typeFormEnum.WebWidget) {
        this.optionsType = []
        this.optionsType.push(this.$t('Collapsed'), this.$t('Expanded'))
        this.selectedType = this.optionsType[0]
      }
      if (this.type === typeFormEnum.BikyForm) {
        this.optionsType = []
        this.optionsType.push(this.$t('Collapsed'), this.$t('Expanded'))
        this.selectedType = this.optionsType[0]
      }
    },
    async getAPIKeys () {
      try {
        this.loading = true
        const variables = {
          app: this.selectedApp?.uuid,
          token: this.token
        }
        const response = await APIKeysUseCases.getAPIKeys(this.apiKeysController, variables)
        if (response?.status) {
          Object.entries(response?.data).forEach(data => {
            const [key, value] = data
            if (key !== 'status') {
              this.apiKeys.push({ text: value.key, value: value, formatName: value?.key + ' - ' + value?.user?.name })
            }
          })
          if (this.apiKeys.length > 0) {
            this.dataTocreate.token = this.token
          }
        } else {
          EmitToast.emitErrors(response)
        }
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
      }
    },
    selectApiKey (event) {
      this.dataTocreate.apiKey = event
    },
    async getConfig () {
      const chatConfig = await ConversationsConfigController.getConfig()
      const config = WebWidgetService.buildWebWidgetConfig(chatConfig)
      this.config = config.widgetWebSetup
      this.chatConfig = chatConfig
      this.allowMessagesOutsideBusinessHours = !chatConfig?.workingHours?.blockMessageComposer || false
      this.allowMultimediaMessages = !config.disableMultimedia || false
    },
    goBackToChannels (): void {
      EventBus.$emit('setConfigurationTab', ConfigurationTabsEnum.NEW_CHANNELS)
    },
    updateFormFieldColor (value: string): void {
      this.dataTocreate.landingConfig.desktop.formFieldColor = value
    },
    updateFormTextColor (value: string): void {
      this.dataTocreate.landingConfig.desktop.formTextColor = value
    },
    updateFormButtonColor (value: string): void {
      this.dataTocreate.landingConfig.desktop.formButtonColor = value
    },
    updateFormBackgroundColor (value: string): void {
      this.dataTocreate.landingConfig.desktop.formBackgroundColor = value
    },
    updateFormButtonText (value: string): void {
      this.dataTocreate.landingConfig.desktop.formButtonText = value
    },
    updateFormLabelColor (value: string): void {
      this.dataTocreate.landingConfig.desktop.formLabelColor = value
    },
    updateFormFields (value: string): void {
      this.dataTocreate.formFields = value
    },
    updateContainerId (value: string): void {
      value = value.replace(/\s/g, '')
      this.dataTocreate.landingConfig.desktop.containerId = value
    },
    changeTypeBiky (value: string):void {
      if (value === typeBikyOptions.FORMAL) {
        this.dataTocreate.widgetConfig.image = `${process.env.VUE_APP_STORAGE_URL}/crm/prod/profile/3yp9vmj1lxc36spj_KEB9NWZK78.png`
        this.dataTocreate.widgetConfig.typeBiky = typeBikyOptions.FORMAL
      } else {
        this.dataTocreate.widgetConfig.image = `${process.env.VUE_APP_STORAGE_URL}/crm/prod/profile/7okccy1lxdpnqcr_KEB9NWZK78.png`
        this.dataTocreate.widgetConfig.typeBiky = typeBikyOptions.CASUAL
      }
    },
    updateButtonColor (value: string):void {
      this.dataTocreate.widgetConfig.buttonColor = value
    },
    updateTypography (value: string): void {
      this.dataTocreate.widgetConfig.typography = value
    },
    updateTextColor (value: string): void {
      this.dataTocreate.widgetConfig.textColor = value
    },
    updatebuttonTextColor (value: string): void {
      this.dataTocreate.widgetConfig.buttonTextColor = value
    },
    updateTextSize (value: string): void {
      this.dataTocreate.widgetConfig.textSize = value
    },
    updateButtonText (value: string): void {
      this.dataTocreate.widgetConfig.buttonText = value
    },
    updateMessage (value: string):void {
      this.dataTocreate.widgetConfig.message = value
    },
    updateFieldColor (value: string):void {
      this.dataTocreate.widgetConfig.fieldColor = value
    },
    updateBackgroundColor (value: string):void {
      this.dataTocreate.widgetConfig.backgroundColor = value
    },
    updateButtonTextExpanded (value: string):void {
      this.dataTocreate.widgetConfig.buttonTextExpanded = value
    },
    updateFormId (value: string): void {
      this.dataTocreate.formId = value
    },
    updateFormPosition (value) {
      this.dataTocreate.landingConfig.desktop.formPosition = value
    }
  }
}
