








































































































































































import KBTableCommission from './KBTableCommission'
export default KBTableCommission
