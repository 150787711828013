import { KeybeSelectTypeEnum } from '@/modules/DesignSystem/domain/models/KeybeSelectTypeEnum'
import { Component as VueComponent } from 'vue'
import KeybeSelectTextValue from '../KeybeSelectTextValue/KeybeSelectTextValue.vue'
import KeybeSelectTextObject from '../KeybeSelectTextObject/KeybeSelectTextObject.vue'
import KeybeSelectString from '../KeybeSelectString/KeybeSelectString.vue'
import KeybeSelectStatus from '../KeybeSelectStatus/KeybeSelectStatus.vue'

export default {
  props: {
    value: {
      type: [String, Number, Object]
    },
    itemText: {
      type: String
    },
    itemValue: {
      type: String
    },
    returnObject: {
      type: Boolean
    },
    items: {
      type: Array
    },
    loading: {
      type: Boolean
    },
    disabled: {
      type: Boolean
    },
    type: {
      type: String
    },
    placeholder: {
      type: String
    },
    scrollable: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    selectComponent (): VueComponent {
      let type: string = ''
      if (this.type === KeybeSelectTypeEnum.STATUS) type = KeybeSelectTypeEnum.STATUS
      else if (!this.itemText && !this.itemValue) type = KeybeSelectTypeEnum.NONE
      else if ((this.itemText && !this.itemValue) || this.returnObject) type = KeybeSelectTypeEnum.TEXT_OBJECT
      else if (this.itemText && this.itemValue) type = KeybeSelectTypeEnum.TEXT_VALUE
      const componentMap: { [key: string]: any } = {
        [KeybeSelectTypeEnum.TEXT_VALUE]: KeybeSelectTextValue,
        [KeybeSelectTypeEnum.TEXT_OBJECT]: KeybeSelectTextObject,
        [KeybeSelectTypeEnum.NONE]: KeybeSelectString,
        [KeybeSelectTypeEnum.STATUS]: KeybeSelectStatus
      }

      return componentMap[type] || KeybeSelectString
    }
  }
}
