<template>
  <div class="KBSearch">
    <input type="text"
           @focus="focused = true"
           @blur="focused = false"
           v-model="search"
           @input="save()"
           :placeholder="placeholder"/>
    <Transition name="fade">
      <div class="KBSearch__tooltip"
           v-if="tooltipConfig.show && focused">
        <span class="KBSearch__tooltip--text">
          {{ tooltipConfig.text || '' }}
        </span>
      </div>
    </Transition>

    <span class="mdi mdi-magnify"/>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: String,
      default: ''
    },
    tooltipConfig: {
      type: Object,
      default: () => ({})
    },
    placeholder: {
      type: String
    }
  },
  data () {
    return {
      search: this.value,
      focused: false
    }
  },
  methods: {
    save () {
      this.$emit('input', this.search)
    }
  },
  watch: {
    value (val) {
      this.search = val
    }
  }
}
</script>
<style scoped lang="scss">
  .KBSearch {
    height: 42px;
    border: 0.5px solid #C0C9D3;
    border-radius: 5px;
    padding: 0 10px;
    display: flex;
    align-items: center;
    width: 100%;
    position: relative;

    input {
      width:100%;
      outline: none;
      font-size: 14px;
    }

    span {
      color: #C0C9D3;
      font-size: 20px;
    }

   &__tooltip {
    width: 100%;
    padding: 10px 14px;
    border-radius: 6px;
    background-color: rgba(181, 189, 199, 0.9);
    position: absolute;
    top: 36px;
    z-index: 9;
    left: 0;

    &--text {
      font-size: 12px !important;
      color: rgba(255, 255, 255, 1);
    }
   }
  }
</style>
