import { EventBus } from '@/eventBus'
import S3UseCases from '@/modules/S3/application/S3UseCases'
import S3Controller from '@/modules/S3/infrastructure/S3.controller'
import { BikyConfigUseCases } from '@/modules/bky/application/BikyConfigUseCases'
import { BikyFile, BikyWebsiteStateEnum, CONFIG_FILES_PATH } from '@/modules/bky/domain/BikyConfig'
import { BikyFileTypes, createFileDTO } from '@/modules/bky/domain/BikyConfigController'
import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import FilesRepository from '@/shared/infrastructure/repositories/Files.repository'
import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'
import { mapState } from 'vuex'
import BikyConfigController from '../../../controllers/BikyConfig.controller'

export default {
  name: 'BikyConfigFileList',
  components: {
    KeybeIcon
  },
  data () {
    return {
      s3Controller: new S3Controller(),
      bikyConfigController: new BikyConfigController(),
      loading: false
    }
  },
  computed: {
    ...mapState('AppStore', ['selectedApp']),
    ...mapState('UserStore', ['token', 'user']),
    KeybeIconType () {
      return KeybeIconType
    }
  },
  methods: {
    async downloadFile (file: BikyFile): Promise<void> {
      try {
        this.loading = true
        this.$emit('start-loading')
        const blob: Blob = await FilesRepository.getFileBlob(file.url)

        if (blob) {
          const tempUrl: string = URL.createObjectURL(blob)
          const aTag = document.createElement('a')
          aTag.href = tempUrl
          aTag.download = file.url.replace(/^.*[\\/]/, '')
          aTag.click()
          aTag.remove()
          URL.revokeObjectURL(tempUrl)
        } else {
          console.warn('Error al descargar el archivo')
          window.open(file.url, '_blank')
        }

        this.$emit('stop-loading')

        this.loading = false
      } catch (error) {
        this.$emit('stop-loading')
        this.loading = false
        console.warn(error)
      }
    },
    getFileName (file) {
      return file?.name || ''
    },
    getFileIcon (file) {
      const icons = {
        PDF: KeybeIconType.PDF,
        DOCX: KeybeIconType.DOC
      }

      return icons[file.type] || KeybeIconType.FILE
    },
    handleDropData (e) {
      const files = e.dataTransfer.files
      console.log(files)
      const valid = this.validateFiles(files)
      if (!valid) {
        return
      }
      this.uploadFiles(files)
    },
    validateFiles (files) {
      // only pdf is supported
      const validTypes = ['application/pdf']
      let valid = true
      for (let i = 0; i < files.length; i++) {
        const file = files[i]
        if (validTypes.indexOf(file.type) === -1) {
          valid = false
          break
        }
      }
      if (!valid) {
        EventBus.$emit('toast', 'error', this.$t('onlyPdfSupported'))
      }
      for (let i = 0; i < files.length; i++) {
        const file = files[i]
        if (file.size > 50000000000) {
          valid = false
          break
        }
      }
      if (!valid) {
        EventBus.$emit('toast', 'error', this.$t('maxFileSize50kb'))
      }

      return valid
    },
    async uploadFiles (files) {
      for (let i = 0; i < files.length; i++) {
        const file = files[i]
        const route = `${CONFIG_FILES_PATH}/${this.selectedApp?.uuid}/`
        const data = {
          file,
          route
        }

        try {
          this.loading = true
          this.$emit('start-loading')
          const fileData = await S3UseCases.uploadFile(this.s3Controller, data)

          if (!fileData) {
            throw new Error('Error uploading file')
          }

          const fileTypes = {
            pdf: BikyFileTypes.PDF
          }

          const dataToSave: createFileDTO = {
            token: this.token,
            name: `${fileData.name}.${fileData.extension}`,
            url: fileData.url,
            appUUID: this.selectedApp?.uuid,
            type: fileTypes[fileData.extension] || BikyFileTypes.PDF
          }

          const uploaded = await BikyConfigUseCases.createFile(this.bikyConfigController, dataToSave)

          if (!uploaded) {
            throw new Error('Error uploading file')
          }

          EventBus.$emit('toast', 'success', this.$t('fileUploaded'))
          this.$emit('refresh-files')
          this.$emit('stop-loading')
          this.loading = false
        } catch (e) {
          this.loading = false
          EventBus.$emit('toast', 'error', this.$t('errorUploadingFile'))
        }
      }
    },
    async deleteFile (file) {
      this.loading = true
      this.$emit('start-loading')
      const deleted = await BikyConfigUseCases.deleteFile(this.bikyConfigController, {
        token: this.token,
        fileId: file._id
      })

      if (!deleted) {
        EventBus.$emit('toast', 'error', this.$t('errorDeletingFile'))
        this.loading = false
        return
      }

      this.$emit('refresh-files')
      this.$emit('stop-loading')
      this.loading = false
    },
    isPendingFile (file: BikyFile): boolean {
      return file?.state === BikyWebsiteStateEnum.PENDING
    },
    isSuccessFile (file: BikyFile): boolean {
      return file?.state === BikyWebsiteStateEnum.SUCCESS
    },
    isErrorFile (file: BikyFile): boolean {
      return file?.state === BikyWebsiteStateEnum.ERROR
    },
    getFileError (file: BikyFile): string {
      return file?.error || ''
    },
    getStateIcon (file: BikyFile): string {
      if (this.isPendingFile(file)) {
        return KeybeIconType.LOADING
      }
      if (this.isSuccessFile(file)) {
        return KeybeIconType.CHECK
      }
      if (this.isErrorFile(file)) {
        return KeybeIconType.WARNING
      }
      return ''
    },
    stateLabel (file: BikyFile): string {
      if (this.isPendingFile(file)) {
        return this.$t('syncing')
      }
      if (this.isSuccessFile(file)) {
        return this.$t('synced')
      }
      if (this.isErrorFile(file)) {
        return `${this.$t('syncError')}: ${this.$t(this.getFileError(file))}`
      }
      return ''
    }
  },
  props: {
    files: {
      required: true,
      type: Array
    }
  },
  mounted () {
    EventBus.$on('BikyConfigBasic:drop-data', this.handleDropData)
  },
  beforeDestroy () {
    EventBus.$off('BikyConfigBasic:drop-data', this.handleDropData)
    BikyConfigUseCases.abortAll(this.bikyConfigController)
  }
}
