// import RolesUseCases from '@/modules/roles/application/RolesUseCases'
import { mapState } from 'vuex'
// import EmitToast from '@/utils/EmitToast'
// import RolesController from '../../../controllers/Roles.controller'
import RoleCard from '@/modules/roles/infrastructure/ui/components/RoleCard/RoleCard.vue'
import Loader from '@/shared/infrastructure/ui/Loader.vue'
export default {
  components: {
    RoleCard,
    Loader
  },
  props: {
    search: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapState('AppStore', ['selectedApp']),
    filteredRoles () {
      return this.roles ? this.roles.filter(rol => rol?.name.toLowerCase().includes(this.search)) : []
    }
  },
  mounted () {
    // this.rolesController = new RolesController()
    this.getRoles()
  },
  data () {
    return {
      roles: [],
      loading: false,
      rolesController: null
    }
  },
  methods: {
    // async getRoles () {
    //   try {
    //     this.loading = true
    //     const variables = {
    //       app: this.selectedApp?.uuid,
    //       search: this.search,
    //       token: ''
    //     }
    //     const response = await RolesUseCases.getRoles(this.rolesController, variables)
    //     if (response?.status) {
    //       this.roles = response?.data?.RolesAll?.data
    //       this.$emit('roles', this.roles)
    //     } else {
    //       EmitToast.emitErrors(response)
    //     }
    //     this.loading = false
    //   } catch (e) {
    //     console.error(e)
    //     this.loading = false
    //   }
    // }
  },
  watch: {
    search () {
      this.getRoles()
    }
  }
}
