export interface InboxUser {
  userId: string,
  access: string,
}

export interface Inbox {
  _id: string,
  appUUID: string,
  key: string,
  name: string,
  default: boolean,
  active: boolean,
  users: InboxUser[],
  tags: string[],
  createdAt: string,
  updatedAt: string,
  line?: string,
}

export enum InboxAccessEnum {
  EDIT = 'EDIT',
  VIEW = 'VIEW',
  DELETE = 'DELETE',
}

export type InboxAccess = InboxAccessEnum.EDIT | InboxAccessEnum.VIEW | InboxAccessEnum.DELETE
