import { Task } from '@/modules/tasks/domain/Task'
import TaskTitle from '@/modules/tasks/infrastructure/ui/components/TaskTitle/TaskTitle.vue'

export default {
  name: 'TaskCardHeader',
  props: {
    task: {
      required: true
    },
    isCardExpanded: {
      required: true
    }
  },
  components: {
    TaskTitle
  },
  computed: {
    taskComputed (): Task {
      return this.task
    }
  }
}
