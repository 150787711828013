var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"AdvisorSwitch",style:({
    background: _vm.isOnline
      ? _vm.Colors.kbGreen
      : _vm.$vuetify.theme.dark
        ? _vm.Colors.black
        : _vm.Colors.lightGray,
  })},[_c('KBSwitch',{style:({
      opacity: !_vm.currentAdviserExist ? 0.5 : 1,
      cursor: !_vm.currentAdviserExist ? 'not-allowed !important' : 'pointer',
    }),attrs:{"color":"green","borderless":_vm.borderless,"disabled":!_vm.currentAdviserExist},on:{"input":_vm.handleStatusChange},model:{value:(_vm.isOnline),callback:function ($$v) {_vm.isOnline=$$v},expression:"isOnline"}}),_c('span',{staticClass:"AdvisorSwitch__label",style:({ color: _vm.isOnline ? _vm.Colors.black : _vm.Colors.lightGray2 })},[_vm._v(_vm._s(_vm.label))])],1)}
var staticRenderFns = []

export { render, staticRenderFns }