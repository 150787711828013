
import moment from 'moment'
import { EventBus } from '@/eventBus'
import CampaignsService from '@/services/CampaignsService'
import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import EmailIcon from '@/shared/infrastructure/ui/icons/SvgComponents/EmailIcon.vue'
import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'
import CustomPagination from '@/components/shared/components/CustomPagination.vue'
import Loader from '@/shared/infrastructure/ui/Loader.vue'
import LoadingNext from '@/modules/metrics/infrastructure/ui/components/generic/LoadingNext.vue'
import { debounce } from 'lodash'
import OutboundUseCases from '@/modules/outbound/application/OutboundUseCases'
import { dataToGetOutbound } from '@/modules/outbound/domain/OutboundController'
import { mapState } from 'vuex'
import OutboundController from '@/modules/outbound/infrastructure/controllers/Outbound.controller'
import { CDPFieldsEnums } from '@/modules/CDP/shared/contacts/domain/enums/CDPFieldsEnums'
import KeybeText from '@/modules/DesignSystem/infrastructure/components/KeybeText/KeybeText.vue'
import { ActivityActionTypeEnum, ActivityLogModuleTypeEnum, dataToWriteActivityLog } from '@/modules/Config/ActivityLogs/domain/ActivityLogsController'
import ActivityLogsUseCases from '@/modules/Config/ActivityLogs/application/ActivityLogsUseCases'
import ActivityLogsController from '@/modules/Config/ActivityLogs/infrastructure/controllers/ActivityLogs.controller'

export default {
  name: 'OutboundPage',
  props: [
    'dateString', 'range', 'dataComponent'
  ],
  components: {
    KeybeText,
    EmailIcon,
    KeybeIcon,
    CustomPagination,
    Loader,
    LoadingNext
  },
  data () {
    return {
      seeArchived: false,
      outboundController: new OutboundController(),
      activityLogsController: new ActivityLogsController(),
      searchCampaigns: '',
      testVisible: false,
      loading: false,
      moment,
      optionVisible: false,
      checkbox: [],
      activeModal: false,
      pageNumber: 1,
      itemsPerPage: 25,
      count: 1,
      KeybeIconType,
      itemData: [],
      options: {
        itemsPerPage: 25,
        page: 1,
        sortBy: ['createdAt'],
        sortDesc: [true]
      },
      campaignTable: {
        headers: [
          { text: '', value: '' },
          { text: '', value: '' },
          { text: this.$t(CDPFieldsEnums.NAME), value: CDPFieldsEnums.NAME },
          { text: this.$t(''), value: '' },
          { text: this.$t('people'), value: 'sms.totalMultiparts' },
          { text: this.$t('sentMessages'), value: CDPFieldsEnums.CODE },
          { text: this.$t('status'), value: 'type' },
          { text: this.$t('createdAt'), value: 'createdAt' }
        ],
        campaigns: []
      }
    }
  },
  watch: {
    checkbox () {
      if (this.checkbox.length > 0) {
        this.optionVisible = true
      } else {
        this.optionVisible = false
      }
    }
  },
  mounted () {
    this.outboundName = null
    this.summaryMenu = false
    this.summaryData = null
    this.outboundStatus = null
    this.getCampaigns()
  },
  computed: {
    ...mapState('UserStore', ['token', 'isUserReadonly', 'user']),
    ...mapState('AppStore', ['selectedApp']),
    outboundStatus: {
      get () {
        return this.$store.state.CampaingsStore.outboundStatus || null
      },
      set (value) {
        this.$store.commit('CampaingsStore/SET_EDITING_OBJECT', {
          key: 'outboundStatus',
          value
        })
      }
    },
    outboundName: {
      get () {
        return this.$store.state.CampaingsStore.outboundName || null
      },
      set (value) {
        this.$store.commit('CampaingsStore/SET_EDITING_OBJECT', {
          key: 'outboundName',
          value
        })
      }
    },
    currentSelectedTab: {
      get () {
        return this.$store.state.CampaingsStore.currentSelectedTab || null
      },
      set (value) {
        this.$store.commit('CampaingsStore/SET_EDITING_OBJECT', {
          key: 'currentSelectedTab',
          value
        })
      }
    },
    summaryMenu: {
      get () {
        return this.$store.state.CampaingsStore.summaryMenu || false
      },
      set (value) {
        this.$store.commit('CampaingsStore/SET_EDITING_OBJECT', {
          key: 'summaryMenu',
          value
        })
      }
    },
    summaryData: {
      get () {
        return this.$store.state.CampaingsStore.summaryData || false
      },
      set (value) {
        this.$store.commit('CampaingsStore/SET_EDITING_OBJECT', {
          key: 'summaryData',
          value
        })
      }
    }
  },
  methods: {
    callArchived () {
      this.seeArchived = !this.seeArchived
      this.getCampaigns()
    },
    debouncedSearch: debounce(async function () {
      this.getCampaigns()
    }, 300),
    callSort () {
      if (this.options.sortDesc[0] === true) {
        this.options.sortDesc[0] = false
      } else {
        this.options.sortDesc[0] = true
      }
      this.getCampaigns()
    },
    deleteArrayCampaign () {
      if (this.isUserReadonly) return
      if (this.checkbox.length > 0) {
        this.checkbox.forEach(element => {
          this.archiveCampaign(element)
          this.writeActivityLogArchive(element.name)
        })
        this.checkbox = []
      } else {
        EventBus.$emit('toast', 'error', this.$t('deleteArrayCampaign'))
      }
    },
    async writeActivityLogArchive (name: string): Promise<void> {
      const data: dataToWriteActivityLog = {
        token: this.token,
        updateInput: {
          appUUID: this.selectedApp?.uuid,
          module: ActivityLogModuleTypeEnum.OUTBOUND,
          action: ActivityActionTypeEnum.ARCHIVE_CAMPAIGN,
          user: {
            id: this.user.uuid,
            name: this.user.name + ' ' + this.user.lastName
          },
          payload: {
            field: CDPFieldsEnums.NAME,
            newValue: name,
            oldValue: ''
          }
        }
      }
      await ActivityLogsUseCases.writeActivityLog(this.activityLogsController, data)
    },
    exportArrayCampaign () : void {
      if (this.checkbox.length > 0) {
        this.checkbox.forEach(element => {
          this.exportCampaign(element)
          this.writeActivityLogExport(element.name)
        })
        this.checkbox = []
      } else {
        EventBus.$emit('toast', 'error', this.$t('exportArrayCampaign'))
      }
    },
    async writeActivityLogExport (name: string): Promise<void> {
      const data: dataToWriteActivityLog = {
        token: this.token,
        updateInput: {
          appUUID: this.selectedApp?.uuid,
          module: ActivityLogModuleTypeEnum.OUTBOUND,
          action: ActivityActionTypeEnum.EXPORT_CAMPAIGN,
          user: {
            id: this.user.uuid,
            name: this.user.name + ' ' + this.user.lastName
          },
          payload: {
            field: CDPFieldsEnums.NAME,
            newValue: name,
            oldValue: ''
          }
        }
      }
      await ActivityLogsUseCases.writeActivityLog(this.activityLogsController, data)
    },
    async exportCampaign ({ id }: { id: string }): Promise<void> {
      try {
        await CampaignsService.exportCampaign({
          campaignId: id,
          filename: ''
        })
        EventBus.$emit(
          'toast',
          'success',
          this.$t('successfullCampaignExport')
        )
      } catch (error) {
        console.error('Error al exportar campaña', error)
        EventBus.$emit(
          'toast',
          'error',
          this.$t('unsuccesfullCampaignExport')
        )
      }
    },
    async archiveCampaign ({ id, archived }) {
      try {
        await CampaignsService.archiveCampaignV6(id, !archived)
        EventBus.$emit('toast', 'success', this.$t('campaignArchived'))
        this.getCampaigns()
      } catch (error) {
        EventBus.$emit('toast', 'error', this.$t('campaignNotArchived'))
      }
    },
    getStatusText (type) {
      switch (type) {
        case 'sending':
          return this.$t('sending')
        case 'sent':
          return this.$t('sent')
        case 'inReview':
          return this.$t('inReview')
        case 'draft':
          return this.$t('draft')
        case 'scheduled':
          return this.$t('scheduled')
        case 'importing':
          return this.$t('importing')
        default:
          return 'None'
      }
    },
    async getCampaigns () {
      this.outboundController.cancelOutboundRequest()
      const data: dataToGetOutbound = {
        limit: this.options.itemsPerPage,
        descending: this.options.sortDesc[0],
        page: this.options.page,
        sortBy: this.options.sortBy[0],
        search: this.searchCampaigns,
        token: this.token,
        archivedCampaign: this.seeArchived
      }
      try {
        this.loading = true
        const response = await OutboundUseCases.getOutbound(this.outboundController, data)
        this.campaignTable.campaigns = []
        this.showNoInfo = false
        if (this.grid) {
          this.templateList = [...this.templateList, ...response.rows]
        } else {
          this.templateList = response.rows
        }
        this.count = response.count
        if (this.campaignTable.campaigns.length === 0) {
          this.templateList.forEach(element => {
            this.campaignTable.campaigns.push({
              name: element.name,
              totalUsers: element.totalUsers,
              'sms.totalMultiparts': this.sentMessages(element),
              code: element.status,
              createdAt: element.createdAt,
              type: element.type,
              id: element.id,
              totalSent: element.totalSent,
              archived: element.archived,
              ...element
            })
          })
        }
        this.loading = false
      } catch (e) {
        this.loading = false
        this.templateList = []
        this.totalTemplateList = 0
        console.log('error', e)
      }
    },
    sentMessages (item) {
      const campaignConstants = {
        sms: 'sms',
        massive: 'massive',
        email: 'email',
        sending: 'sending',
        inReview: 'inReview',
        sent: 'sent',
        draft: 'draft',
        schedule: 'schedule'
      }
      if (item.status === campaignConstants.sent) {
        if (item.type === campaignConstants.sms || item.type === campaignConstants.massive) {
          return item.sms.totalMultiparts
        } else {
          return item.totalSent
        }
      } else if (item.status === campaignConstants.inReview) {
        if (item.type === campaignConstants.sms || item.type === campaignConstants.massive) {
          return item.sms.totalMultiparts
        } else {
          return item.totalUsers
        }
      } else if (item.status === campaignConstants.sending) {
        return item.totalSent
      } else if (item.status === campaignConstants.draft) {
        return '0'
      }
    },
    goToDetail (item) {
      this.currentSelectedTab = 'summary'
      this.summaryMenu = true
      this.summaryData = item
      this.outboundName = item.name
      // this.$parent.visibleDetail(item)
    },
    changePage (type: string) {
      this.$parent.someMethod(type)
    },
    setPagination (pagination) {
      this.options.page = pagination.page
      this.options.itemsPerPage = pagination.items
      this.getCampaigns()
    }
  }
}
