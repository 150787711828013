import _ from 'lodash'
import CreateEditRole from '../components/CreateEditRole/CreateEditRole.vue'
import RolesHeader from '../components/RolesHeader/RolesHeader.vue'
import RolesList from '../components/RolesList/RolesList.vue'
export default {
  components: {
    CreateEditRole,
    RolesHeader,
    RolesList
  },
  data () {
    return {
      search: '',
      roles: [],
      create: false
    }
  },
  methods: {
    toggleCreate () {
      this.create = !this.create
    },
    updateInput: _.debounce(function (e) {
      this.search = e
    }, 300),
    close () {
      this.create = false
    }
  },
  watch: {
    search (val) {
    }
  }
}
