export default {
  props: {
    values: {
      type: Object,
      default: () => ({})
    },
    value: {
      type: String,
      default: ''
    },
    executeRerender: {
      type: Boolean,
      default: true
    },
    autofill: {
      type: Boolean,
      default: false
    }
  },
  mounted () {
    if (this.values && Object.keys(this.values).length > 0) {
      this.hour = this.values.hour
      this.minute = this.values.minute
    }
    if (this.autofill && this.value) {
      const separate = this.value.split(':')
      this.hour = separate[0]
      this.minute = separate[1]
    }
    window.addEventListener('keydown', this.handleKeySave)
  },
  data () {
    return {
      hour: '',
      minute: '',
      rerender: 0
    }
  },
  methods: {
    verify () {
      if (!this.minute) this.minute = this.values?.minute || '00'
      if (this.hour && this.minute) {
        const hourLength: number = this.hour.toString().length
        if (hourLength === 1) {
          this.hour = this.hour.toString().padStart(2, '0')
        }
        if (hourLength > 2) {
          this.hour = this.hour.toString().slice(1, hourLength)
        }

        const minuteLength: number = this.minute.toString().length
        if (minuteLength === 1) {
          this.minute = this.minute.toString().padStart(2, '0')
        }
        if (minuteLength > 2) {
          this.minute = this.minute.toString().slice(1, minuteLength)
        }

        const time = `${this.hour}:${this.minute}:00`
        this.$emit('input', time)
      }

      if (this.hour.toString() === '00') this.hour = ''
      if (this.minute.toString() === '00') this.minute = ''
    },
    handleKeySave (e: KeyboardEvent): void {
      e.stopPropagation()
      if (e.which === 13 && this.hour && this.minute) this.save()
    },
    save () {
      this.$emit('save')
    }
  },
  beforeDestroy () {
    if (window) window.removeEventListener('keydown', this.handleKeySave)
  },
  watch: {
    values () {
      if (this.values && Object.keys(this.values).length > 0) {
        this.hour = this.values.hour
        this.minute = this.values.minute
        this.rerender++
      }
    },
    value () {
      if (this.executeRerender) {
        this.rerender++
      }
    }
  }
}
