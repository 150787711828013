<template>
  <svg width="15"
       height="19"
       fill="none"
       xmlns="http://www.w3.org/2000/svg"><path d="M.004 10.953c0-1.823-.003-3.646 0-5.47.003-1.203.6-2.025 1.735-2.41a2.89 2.89 0 0 0 1.186-.756c.338-.376.78-.494 1.252-.519.203-.01.29-.106.377-.268C5.105.495 6.003-.008 7.147 0c1.141.008 2.031.512 2.585 1.552.058.107.214.221.332.228.718.05 1.257.35 1.612.995.066.12.287.197.446.217a2.41 2.41 0 0 1 1.695.983c.357.477.44 1.03.441 1.603.003 1.685 0 3.369 0 5.054 0 1.921-.002 3.844 0 5.766.001.86-.264 1.594-.973 2.125-.427.32-.916.47-1.443.471-3.132.004-6.263.012-9.396-.001C.999 18.987.014 17.95.006 16.45c-.011-1.832-.003-3.666-.003-5.499l.001.001zM2.35 4.191c-.729-.016-1.184.467-1.185 1.268-.005 3.676-.006 7.35.004 11.027 0 .249.064.518.174.74.241.482.703.611 1.202.612 1.972.006 3.944.003 5.916.003 1.1 0 2.2.01 3.3-.005.507-.006.979-.155 1.192-.67.098-.238.125-.52.125-.78.007-3.606.005-7.213.005-10.819 0-.119-.008-.237-.016-.356-.03-.49-.297-.804-.74-.977-.362-.142-.457-.07-.458.32-.006.936-.447 1.374-1.39 1.375H8.07c-1.457 0-2.913.003-4.37 0-.747-.003-1.156-.343-1.293-1.074-.041-.218-.041-.445-.059-.665h.001zm4.76.57h3.12c.316 0 .473-.153.473-.46 0-.217.004-.435-.002-.653-.012-.483-.213-.726-.688-.688-.679.055-1.055-.187-1.319-.841-.415-1.033-2.04-1.273-2.788-.446-.22.242-.363.559-.506.86-.117.244-.289.371-.544.395-.187.018-.375.017-.563.021-.514.012-.721.206-.747.718-.012.246.011.495-.008.742-.021.279.097.357.363.355 1.07-.01 2.14-.005 3.21-.005l-.001.002z"
                                                :fill="color"/><path d="M4.16 9.24c.482-.456.936-.898 1.403-1.324.29-.265.524-.247.776.02.212.225.243.497.03.719a71.237 71.237 0 0 1-1.846 1.851c-.25.24-.507.222-.768-.02a17.823 17.823 0 0 1-.866-.857c-.24-.254-.249-.55-.05-.773.22-.248.54-.272.825-.049.163.128.312.272.495.433zM8.331 14.253c-.584 0-1.166.007-1.75-.003-.41-.007-.614-.222-.602-.605.01-.313.201-.522.556-.525 1.187-.013 2.374-.008 3.56-.008.019 0 .039.004.058.008.322.041.527.267.522.573-.005.324-.194.543-.535.553-.603.016-1.206.004-1.81.004v.005l.001-.002zM8.925 10.687c-.377 0-.754.014-1.129-.003-.432-.02-.627-.216-.627-.593 0-.356.223-.576.641-.583a63.13 63.13 0 0 1 2.229.001c.392.007.634.242.637.566.005.368-.202.586-.622.607-.375.019-.753.003-1.128.003l-.001.002zM5.027 13.623c.005.518-.353.897-.852.904-.516.007-.871-.346-.877-.872a.875.875 0 0 1 .86-.876c.446-.004.865.404.869.844zM6.575 2.963c0-.341.213-.56.548-.565a.566.566 0 0 1 .584.573.594.594 0 0 1-.595.573c-.32-.01-.537-.245-.536-.581h-.001z"
                                                                     :fill="color"/></svg>
</template>

<script>
export default {
  name: 'ListFolderIcon',
  props: {
    color: {
      type: String,
      default: '#B6BEC7'
    }
  }
}
</script>

<style>

</style>
