export const formScript = (appKey, containerId, formId) => {
  return `
        <script src="${process.env.VUE_APP_STORAGE_URL}/keybejs/v6/${process.env.VUE_APP_KEYBEJS}"></script>
        <script>
        window.addEventListener('load', function () {
          var configChat = {
            apiKey: '${appKey}',
            containerId: '${containerId}',
            formId: '${formId}'
          }
          window.keybe.loadFormTesseract(configChat)
        })
        </script>
    `
}
