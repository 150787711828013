<template>
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 13"><path :fill="color" d="M8.76 4.35v-.531c0-.377.003-.754 0-1.131-.002-.405.162-.714.537-.873.389-.165.73-.037 1.014.243.744.735 1.481 1.478 2.22 2.218.383.382.767.764 1.149 1.148.423.426.429.968.008 1.39-1.12 1.124-2.243 2.246-3.367 3.366-.467.465-1.135.42-1.428-.107a1.231 1.231 0 0 1-.127-.538c-.017-.492-.006-.985-.007-1.478 0-.054-.005-.107-.01-.187H8.53c-1.204 0-2.408.003-3.612-.001-.452-.002-.852-.142-1.14-.509a1.325 1.325 0 0 1-.274-.85c.002-.244-.001-.488 0-.73.008-.856.55-1.398 1.408-1.404 1.203-.01 2.407-.018 3.61-.027h.239Zm.858.892H4.881c-.344.004-.502.152-.518.496-.01.225-.003.45-.002.676.002.45.141.59.588.59h4.673V9.55l3.435-3.434c-1.131-1.133-2.285-2.286-3.44-3.441v2.566h.001Z"/><path :fill="color" d="M.013 6.124C.013 4.749-.004 3.374.02 2 .036.988.893.09 1.901.038 2.854-.011 3.81.004 4.763 0c.282-.001.467.183.467.43 0 .258-.181.43-.481.432-.833.004-1.667-.004-2.5.004-.445.003-.835.152-1.114.52-.199.261-.277.565-.277.888 0 2.567-.004 5.133.002 7.699.002.863.564 1.41 1.425 1.41.808.002 1.617 0 2.426 0 .212 0 .398.05.486.275a.412.412 0 0 1-.29.565.938.938 0 0 1-.216.025c-.809.002-1.617.009-2.426 0-.855-.01-1.548-.33-1.985-1.101-.212-.375-.28-.784-.28-1.21.004-1.271.001-2.543.001-3.813h.012Z"/></svg>
</template>

<script>
export default {
  name: 'ExitIcon',
  props: {
    color: {
      type: String,
      default: '#B6BEC7'
    }
  }
}
</script>

<style>

</style>
