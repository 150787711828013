// create a type as an array with possible values
export const CoordinatorSections = [
  '/',
  '/payment',
  '/people',
  '/segment',
  '/business',
  '/connect/person',
  '/conversations',
  '/opportunities',
  '/pipeline',
  '/sales'
]
