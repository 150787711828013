














































































































































































import DomainsConfig from './DomainsConfig'
export default DomainsConfig
