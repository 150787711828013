import store from '@/store'
import { RestResponseFactory } from '@/models/apis/RestResponse'
import axios from 'axios'
import { UserStates } from '../enums/UserStates'
import PushNotificationsController from '@/controllers/PushNotificationsController'

export default class AdvisersService {
  static storeInstance = store
  static baseUrl: string = process.env.VUE_APP_CONVERSATIONS_API_URL

  static async getAdvisers () {
    const appId = this.storeInstance.getters['AppStore/getSelectedApp']?.uuid
    const token = this.storeInstance.getters['UserStore/getToken']
    const url = `${AdvisersService.baseUrl}/adviser?appUUID=${appId}`
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: 'Bearer ' + token
        }
      })
      return new RestResponseFactory().process({ response })
    } catch (e) {
      return new RestResponseFactory().process({ response: { status: 500 } })
    }
  }

  static async updateAdviser (data) {
    try {
      const token = this.storeInstance.getters['UserStore/getToken']
      const url = `${AdvisersService.baseUrl}/adviser`
      const response = await axios.put(url, data, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      return new RestResponseFactory().process({ response })
    } catch (error) {
      return new RestResponseFactory().process({ response: { status: 500 } })
    }
  }

  static getAdviserStatus (userId: string) {
    const advisers = this.storeInstance.getters['AppStore/getAdvisers']
    if (!advisers) {
      return false
    }
    if (advisers) {
      const adviser = advisers.find((a) => a.userId === userId)
      if (!adviser) { return false }
      return adviser.status
    }
  }

  static async updateAdviserStatus (value: any) : Promise<boolean> {
    const status = value ? UserStates.Online : UserStates.Offline
    const user = store.getters['UserStore/getUser']
    const userId = user?.userId
    const selectedAppUuid = store.getters['AppStore/getSelectedApp']?.uuid
    const variables = {
      userId,
      status,
      appUUID: selectedAppUuid
    }
    const response = await AdvisersService.updateAdviser(variables)
    if (response.status) {
      const adviser = response.data.data
      store.dispatch('AppStore/updateAdviser', adviser)
      await PushNotificationsController.setOneSignalStatus({ active: value })
      return true
    } else {
      return false
    }
  }
}
