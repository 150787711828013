import { GenericResponse } from '@/shared/infrastructure/http/GenericResponse'
import KeybeRepository from '@/shared/infrastructure/repositories/KeybeRepository'
import { dataToCreateTemplate, dataToGetTemplates, dataToDeleteTemplates } from '../domain/TemplateController'
import { RestResponseFactory } from '@/shared/infrastructure/http/RestResponse'
import { HeadersBuilder } from '@/shared/infrastructure/http/HeadersBuilder'
import { StatusCodes } from '@/shared/infrastructure/http/StatusCodes'

export default class TemplateRepository extends KeybeRepository {
  // eslint-disable-next-line no-useless-constructor
  constructor () {
    super()
  }

  async getTemplates (data: dataToGetTemplates): Promise<GenericResponse> {
    try {
      const { userHost, forceRefresh, appId, all } = data
      const url = `${process.env.VUE_APP_CONVERSATIONS_API_URL}/template`
      const params = {
        appUUID: appId,
        userHost: userHost,
        forceRefresh: forceRefresh,
        all: all
      }
      const response = await this.client.get({
        url,
        query: params,
        headers: HeadersBuilder.buildSimpleAutorizationHeader(data)
      })
      return new RestResponseFactory().process({ response })
    } catch (e) {
      return new RestResponseFactory().process({ response: { status: StatusCodes.HTTP_500_INTERNAL_SERVER_ERROR } })
    }
  }

  async createTemplate (data: dataToCreateTemplate): Promise<GenericResponse> {
    const url = `${process.env.VUE_APP_CONVERSATIONS_API_URL}/template`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    const postData = {
      ...data
    }
    // delete postData.token
    return await this.client.post({
      url,
      data: postData,
      headers
    })
  }

  async deleteTemplate (data: dataToDeleteTemplates): Promise<GenericResponse> {
    const { userHost, templateId, appUUID } = data
    const url = `${process.env.VUE_APP_CONVERSATIONS_API_URL}/template?appUUID=${appUUID}&userHost=%2B${userHost}&templateId=${templateId}`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    const response = await this.client.delete({
      url,
      headers
    })
    return response
  }
}
