





















































































import InboxConfigDetail from './InboxConfigDetail'

export default InboxConfigDetail
