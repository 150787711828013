import KeybeRepository from '@/shared/infrastructure/repositories/KeybeRepository'
import { dataToSubscribeEvents } from '@/shared/domain/Events/EventController'
import onUser from '@/components/shared/graphql/subscription-on-user.graphql'
import {
  GraphQLResponse,
  GraphQLResponseFactory,
  IGraphQLResponse
} from '../graphql/GraphqlResponse'
import EventsApolloProvider from '@/modules/event/Providers/EventsApolloProvider'

export default class EventsRepository extends KeybeRepository {
  eventApolloClient: any
  onEventSubscription: any
  factory: GraphQLResponseFactory

  constructor () {
    super()

    this.eventApolloClient =
      EventsApolloProvider.getInstance().provider.defaultClient
    this.factory = new GraphQLResponseFactory()
  }

  subscribe (
    data: dataToSubscribeEvents,
    callback: (response: GraphQLResponse) => void
  ): void {
    this.onEventSubscription = this.eventApolloClient
      .subscribe({
        query: onUser,
        variables: data
      })
      .subscribe((response: IGraphQLResponse) => {
        return callback(this.factory.process({ response }))
      })
  }

  unsubscribe (): void {
    if (this.onEventSubscription) {
      this.onEventSubscription.unsubscribe()
    }
  }
}
