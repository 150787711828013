







































import FacebookProvider from '@/components/configuration/Providers/FacebookProvider'
import ConversationsConfigService from '@/components/configuration/Services/ConversationsConfigService'
// import FacebookService from '@/components/configuration/Services/FacebookService'
import { EventBus } from '@/eventBus'
import FacebookLogin from '@/components/configuration/conversations/channels/FacebookLogin/FacebookLogin.vue'
import { ActivityLogModuleTypeEnum, ActivityActionTypeEnum, dataToWriteActivityLog, ActivitySubmoduleTypeEnum } from '@/modules/Config/ActivityLogs/domain/ActivityLogsController'
import ActivityLogsUseCases from '@/modules/Config/ActivityLogs/application/ActivityLogsUseCases'
import ActivityLogsController from '@/modules/Config/ActivityLogs/infrastructure/controllers/ActivityLogs.controller'
import { mapState } from 'vuex'
export default {
  components: { FacebookLogin },
  props: {
    anotherLine: {
      type: Boolean,
      default: false
    },
    migrate: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState('UserStore', ['user', 'token', 'isUserReadonly']),
    ...mapState('AppStore', ['selectedApp'])
  },
  data: function () {
    return {
      longLivedAccessToken: '',
      whatsappAppId: process.env.VUE_APP_WHATSAPP_APP_ID, // TODO: ENV VAR
      whatsappPermissions: [
        'business_management',
        'whatsapp_business_management',
        'catalog_management'
        // 'whatsapp_business_messaging'
      ],
      activityLogsController: new ActivityLogsController()
    }
  },
  methods: {
    async gotAccessToken (shortLivedAccessToken: string) {
      if (this.isUserReadonly) return
      await ConversationsConfigService.saveWhatsappAccessToken(
        shortLivedAccessToken
      )
      // await FacebookService.logout()
      await FacebookProvider.setBusySDK(false)
      await this.writeActivityLog()
      this.$emit('connectedWhatsapp')
      EventBus.$emit('toast', 'success', 'WhatsApp connected successfully')
    },
    async writeActivityLog (): Promise<void> {
      const data: dataToWriteActivityLog = {
        token: this.token,
        updateInput: {
          appUUID: this.selectedApp?.uuid,
          module: ActivityLogModuleTypeEnum.CHAT,
          submodule: ActivitySubmoduleTypeEnum.CHANNELS,
          action: ActivityActionTypeEnum.WHATSAPP_LINE_CONNECTED,
          user: {
            id: this.user.uuid,
            name: this.user.name + ' ' + this.user.lastName
          }
        }
      }
      await ActivityLogsUseCases.writeActivityLog(this.activityLogsController, data)
    }
  }
}
