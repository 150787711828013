<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       fill="none"
       viewBox="0 0 34 34">
    <path fill="#21A366"
          d="M21.25 2.125H9.917c-.777 0-1.417.64-1.417 1.417v6.02L21.25 17l6.375 2.69L34 17V9.562L21.25 2.125Z"/>
    <path fill="#107C41"
          fill-rule="evenodd"
          d="M8.5 9.563h12.75V17H8.5V9.562Z"
          clip-rule="evenodd"/>
    <path fill="#33C481"
          d="M32.583 2.125H21.25v7.438H34V3.542c0-.777-.64-1.417-1.417-1.417Z"/>
    <path fill="#185C37"
          d="M21.25 17H8.5v13.458c0 .777.64 1.417 1.417 1.417h22.667c.776 0 1.416-.64 1.416-1.417v-6.02L21.25 17Z"/>
    <path fill="#107C41"
          fill-rule="evenodd"
          d="M21.25 17H34v7.438H21.25V17Z"
          clip-rule="evenodd"/>
    <path fill="#000"
          fill-opacity=".094"
          d="M17.708 7.438H8.5v20.187h9.208a1.427 1.427 0 0 0 1.417-1.417V8.854a1.427 1.427 0 0 0-1.417-1.416Z"/>
    <path fill="#000"
          fill-opacity=".2"
          d="M16.646 8.5H8.5v20.188h8.146a1.427 1.427 0 0 0 1.416-1.417V9.917A1.427 1.427 0 0 0 16.646 8.5Z"/>
    <path fill="#000"
          fill-opacity=".2"
          d="M16.646 8.5H8.5v18.063h8.146a1.427 1.427 0 0 0 1.416-1.417V9.916A1.427 1.427 0 0 0 16.646 8.5Z"/>
    <path fill="#000"
          fill-opacity=".2"
          d="M15.583 8.5H8.5v18.063h7.083A1.427 1.427 0 0 0 17 25.145V9.916A1.427 1.427 0 0 0 15.583 8.5Z"/>
    <path fill="#107C41"
          d="M1.417 8.5h14.166C16.36 8.5 17 9.14 17 9.917v14.166c0 .777-.64 1.417-1.417 1.417H1.417C.64 25.5 0 24.86 0 24.083V9.917C0 9.14.64 8.5 1.417 8.5Z"/>
    <path fill="#fff"
          d="m3.754 22.313 3.438-5.328-3.15-5.297h2.535l1.719 3.385c.158.322.267.561.326.72h.022c.114-.257.232-.507.356-.75l1.837-3.355h2.327l-3.23 5.268 3.312 5.357H10.77l-1.986-3.72a3.124 3.124 0 0 1-.237-.496h-.03a2.35 2.35 0 0 1-.23.481l-2.045 3.735h-2.49Z"/>
  </svg>
</template>

<script>
export default {
  name: 'SimpleXLS'
}
</script>

<style scoped>

</style>
