<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       fill="none"
       viewBox="0 0 56 56">
    <path :fill="color"
          d="M11.186 56a2.773 2.773 0 0 1-2.8-2.8v-6.44a2.822 2.822 0 0 0-.812-1.96A25.654 25.654 0 0 1 0 26.6 27.33 27.33 0 0 1 27.306 0h1.388A27.325 27.325 0 0 1 56 26.6a27.328 27.328 0 0 1-27.306 26.6H28a28.53 28.53 0 0 1-6.412-.815 2.302 2.302 0 0 0-.616 0 3.054 3.054 0 0 0-1.092.224l-7.616 3.164A2.802 2.802 0 0 1 11.2 56h-.014ZM27.328 5.6A21.756 21.756 0 0 0 5.6 26.6a20.16 20.16 0 0 0 5.964 14.252A8.35 8.35 0 0 1 14 46.76v2.268l3.724-1.428a8.12 8.12 0 0 1 3.248-.812c.623.005 1.244.08 1.85.224A23.74 23.74 0 0 0 28 47.6h.672a21.756 21.756 0 0 0 21.728-21 21.756 21.756 0 0 0-21.728-21h-1.344Zm-13.72 28.608a1.201 1.201 0 0 1-.784-.28 1.4 1.4 0 0 1-.196-1.764l7.252-10.957a4.217 4.217 0 0 1 3.514-1.887h.647a4.164 4.164 0 0 1 2.503.84l6.44 4.928 8.568-6.552a1.2 1.2 0 0 1 1.568 0l.224-.028a1.2 1.2 0 0 1 .168 1.54l-7.336 11.2a4.155 4.155 0 0 1-3.5 1.904h-1.204a4.2 4.2 0 0 1-2.548-.868l-6.132-4.668-8.4 6.3a1.2 1.2 0 0 1-.784.292Z"/>
  </svg>
</template>

<script>
export default {
  name: 'FacebookMessengerIcon',
  props: {
    color: {
      type: String,
      default: '#000000'
    },
    width: {
      type: Number,
      default: 56
    },
    height: {
      type: Number,
      default: 56
    }
  }

}
</script>

<style>

</style>
