import { Task } from '@/modules/tasks/domain/Task'

// Generated by https://quicktype.io
export interface CreatedBy {
    userId: string;
    name?: string;
    lastname?: string;
    email?: string;
}

export enum NotificationTypeEnum {
    PUSH = 'push',
    POST = 'post',
    TOAST = 'toast',
    ALERT = 'alert'
}

export type NotificationType = NotificationTypeEnum.PUSH | NotificationTypeEnum.POST | NotificationTypeEnum.TOAST | NotificationTypeEnum.ALERT;

export enum AudienceTypeEnum {
    SYSTEM = 'system',
    APP = 'app',
    USER = 'user'
}

export type AudienceType = AudienceTypeEnum.SYSTEM | AudienceTypeEnum.APP | AudienceTypeEnum.USER;

export enum FileTypeEnum {
    IMAGE = 'image',
    VIDEO = 'video',
    PARAGRAPH = 'paragraph'
}

export type FileType = FileTypeEnum.IMAGE | FileTypeEnum.VIDEO | FileTypeEnum.PARAGRAPH;

export enum MetadataOriginEnum {
    TASK = 'tasks',
    CHAT_CHANNEL = 'chat_channel',
    FUNNEL = 'funnel'
}

export enum MetadataEventTypeEnum {
    TASK_CREATED = 'task-created',
    TASK_EXPIRED = 'task-expired',
    SALESCARD_ASSIGNED = 'salescard-assigned',
    CHAT_CHANNEL = 'chat-channel',
    SALESCARD_CHANGED_STEP = 'salescard-changed-step',
    SALESCARD_WON = 'salescard-won',
    SALESCARD_LOST = 'salescard-lost',
}

export type MetadataOriginType = MetadataOriginEnum.TASK | MetadataOriginEnum.CHAT_CHANNEL;

export interface Block {
    file: {
        url: string;
        caption: string;
    },
    text: string;
    type: FileType;
}

export interface ViewedBy {
    user: string;
    viewedAt: string;
}

export interface Metadata {
    eventType: string;
    origin: MetadataOriginType;
}

interface ChannelInfo {
    _id: string;
    appUUID: string;
    companyUUID: string;
    page_id: string[];
    verification_token: string;
    createdAt: string;
    updatedAt: string;
    connectionMethod: string;
    expiresAt: string;
    description: string;
}

export interface Notification {
    _id: string;
    type: NotificationType;
    title: string;
    publishAt: string;
    metadata: Metadata,
    language: string;
    payload: Task | ChannelInfo;
    app?: string;
    userId?: string;
    viewed?: boolean;
    viewedBy?: ViewedBy[];
    audience: AudienceType;
    blocks?: Block[];
    icon?: string;
    archived?: boolean;
    draft?: boolean;
    createdBy: CreatedBy;
    updatedBy: CreatedBy;
    createdAt: string;
    updatedAt: string;
}
