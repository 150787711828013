import APIKeysUseCases from '@/modules/APIKeysConfig/application/APIKeysUseCases'
import { mapState } from 'vuex'
import EmitToast from '@/utils/EmitToast'
import APIKeysController from '../../controllers/APIKeys.controller'
import APIKeyCard from '../APIKeyCard/APIKeyCard.vue'
import Loader from '@/shared/infrastructure/ui/Loader.vue'
export default {
  components: {
    Loader,
    APIKeyCard
  },
  props: {
    search: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapState('AppStore', ['selectedApp']),
    ...mapState('UserStore', ['token']),
    filteredAPIKeys () {
      return this.apiKeys ? this.apiKeys.filter(key => key.key.toLowerCase().includes(this.search)) : []
    }
  },
  mounted () {
    this.getAPIKeys()
  },
  data () {
    return {
      apiKeys: [],
      loading: false,
      apiKeysController: new APIKeysController()
    }
  },
  methods: {
    async getAPIKeys () {
      try {
        this.apiKeys = []
        this.loading = true
        const variables = {
          app: this.selectedApp?.uuid,
          token: this.token
        }
        const response = await APIKeysUseCases.getAPIKeys(this.apiKeysController, variables)
        if (response?.status) {
          Object.entries(response.data).forEach(data => {
            const [key, value] = data
            if (key !== 'status') {
              this.apiKeys.push(value)
            }
          })
          this.$emit('apiKeys', this.apiKeys)
        } else {
          EmitToast.emitErrors(response)
        }
        this.loading = false
      } catch (e) {
        console.error(e)
        this.loading = false
      }
    }
  }
}
