





















































































































import ChatFiltersConfigRules from './ChatFiltersConfigRules'

export default ChatFiltersConfigRules
