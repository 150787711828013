export const BannerStore = {
  namespaced: true,
  state: {
    halloweenStatus: false,
    newsBanner: true,
    news2023: false
  },
  mutations: {
    SET_HALLOWEEN_STATUS (state: any, value: boolean): void {
      state.halloweenStatus = value
    },
    SET_NEWS_BANNER (state: any, value: boolean): void {
      state.newsBanner = value
    },
    SET_NEWS_2023 (state: any, value: boolean): void {
      state.news2023 = value
    }
  },
  actions: {
    setHalloweenStatus ({ commit }, value: boolean): void {
      commit('SET_HALLOWEEN_STATUS', value)
    },
    setNewsBanner ({ commit }, value: boolean): void {
      commit('SET_NEWS_BANNER', value)
    },
    setNews2023 ({ commit }, value: boolean): void {
      commit('SET_NEWS_2023', value)
    }
  },
  getters: {
    getHalloweenStatus (state: any): boolean {
      return true
    },
    getNewsBanner (state: any): boolean {
      return true
    },
    getNews2023 (state: any): boolean {
      return true
    }
  }
}
