










































































import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import moment from 'moment/moment'
import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'
import InstagramConfig from '@/components/configuration/conversations/channels/InstagramConfig.vue'

export default {
  name: 'OnboardingInstagram',
  props: {
    configs: {
      required: true,
      type: Array
    }
  },
  components: {
    KeybeIcon,
    InstagramConfig
  },
  data () {
    return {
      KeybeIconType
    }
  },
  methods: {
    isConnectedWithManualMethod (config) {
      return config?.connectionMethod === 'old'
    },
    getTokenPercentage (config) {
      if (!config) {
        return 0
      }
      const today = moment()
      const dateStart = moment(config?.updatedAt)
      const dateEnd = moment(config?.expiresAt)

      const daysDifference = moment(dateEnd).diff(dateStart, 'days')
      const difference = today.diff(dateStart, 'days')

      return Math.round((difference / daysDifference) * 100)
    },
    getIndicatorClass (config) {
      if (this.getTokenPercentage(config) < 80) {
        return 'OnboardingChannel__item--indicator--green'
      }

      return 'OnboardingChannel__item--indicator--yellow'
    },
    getBackgroundClass (config) {
      if (this.getTokenPercentage(config) > 80) {
        return 'OnboardingChannel__item--red'
      }
      return ''
    },
    getDaysUntilExpiration (config) {
      if (!config) {
        return 0
      }
      const today = moment()
      const dateEnd = moment(config?.expiresAt)

      const daysDifference = moment(dateEnd).diff(today, 'days')

      return daysDifference
    },
    isExpired (config) {
      return this.getDaysUntilExpiration(config) < 0
    },
    getTokenExpirationLabel (config) {
      if (this.isExpired(config)) {
        return this.$t('tokenExpired')
      }
      const daysUntilExpiration = this.getDaysUntilExpiration(config)
      if (daysUntilExpiration === 0) {
        return this.$t('tokenExpiresToday')
      }
      if (daysUntilExpiration === 1) {
        return `${this.$t('tokenExpiresIn')} ${daysUntilExpiration} ${this.$t('day')}`
      }
      return `${this.$t('tokenExpiresIn')} ${daysUntilExpiration} ${this.$t('days')}`
    }
  }
}
