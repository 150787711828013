export interface Paginator {
  totalDocs?: number;
  limit: number;
  totalPages?: number;
  page: number;
  pagingCounter?: number;
  hasPrevPage?: boolean;
  hasNextPage?: boolean;
  prevPage?: number;
  nextPage?: number;
}

export class PaginatorUtil {
  static emptyPaginator: Paginator = {
    hasNextPage: false,
    hasPrevPage: false,
    limit: 0,
    nextPage: 0,
    page: 0,
    prevPage: 0,
    totalDocs: 0,
    totalPages: 0,
    pagingCounter: 0
  }
}
