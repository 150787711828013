import KeybeRepository from '@/shared/infrastructure/repositories/KeybeRepository'
import { GenericResponse } from '@/shared/infrastructure/http/GenericResponse'
import { HeadersBuilder } from '@/shared/infrastructure/http/HeadersBuilder'
import { getOptions, nonGetOptions } from '@/shared/infrastructure/http/HttpClient'
import { dataToCreateImporter, dataToDetectHeaders, dataToGetImports, dataToStartImporter, Query } from '../../domain/ImporterController'

export default class ImporterRepository extends KeybeRepository {
  getSuggestionsAbortController: AbortController

  constructor () {
    super()

    this.getSuggestionsAbortController = new AbortController()
  }

  async createImporter (data: dataToCreateImporter): Promise<GenericResponse> {
    const { app } = data

    const url = `${process.env.VUE_APP_IMPORTER}/${app}/importers`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    delete data.token
    const requestOptions: nonGetOptions = {
      headers,
      data,
      url,
      abortController: this.getSuggestionsAbortController
    }

    return await this.client.post(requestOptions)
  }

  async getImports (data: dataToGetImports): Promise<GenericResponse> {
    const { app, typeEntity, offset, limit } = data

    const url = `${process.env.VUE_APP_IMPORTER}/${app}/importers/${typeEntity}`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    const query: Query = {}

    if (offset && limit) {
      query.offset = offset
      query.limit = limit
    } else {
      Object.assign(query, data)
    }

    const requestOptions: getOptions = {
      headers,
      query,
      url,
      abortController: this.getSuggestionsAbortController
    }

    return await this.client.get(requestOptions)
  }

  async startImport (data: dataToStartImporter): Promise<GenericResponse> {
    const { app, typeEntity, id } = data

    const url = `${process.env.VUE_APP_IMPORTER}/${app}/importers/${typeEntity}/start/${id}`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)

    const requestOptions: getOptions = {
      headers,
      url,
      abortController: this.getSuggestionsAbortController
    }

    return await this.client.get(requestOptions)
  }

  async headerDetect (data: dataToDetectHeaders): Promise<GenericResponse> {
    const { app, file } = data

    const url = `${process.env.VUE_APP_IMPORTER}/${app}/importers/headers-detection`
    const headers = HeadersBuilder.buildFormDataAutorizationHeader(data)

    const fd = new FormData()
    fd.append('file', file)

    const requestOptions: nonGetOptions = {
      headers,
      url,
      data: fd,
      abortController: this.getSuggestionsAbortController,
      isFormData: true
    }

    return await this.client.post(requestOptions)
  }

  abortGetSuggestions () {
    this.getSuggestionsAbortController.abort()
    this.getSuggestionsAbortController = new AbortController()
  }
}
