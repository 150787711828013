import ApexCharts from 'apexcharts'
import LoadingNext from '@/modules/metrics/infrastructure/ui/components/generic/LoadingNext.vue'

export default {
  name: 'MetricsGraph',
  props: [
    'dataComponent', 'dataProactivo'
  ],
  components: {
    LoadingNext
  },
  data () {
    return {
      chartStatus: null,
      itemData: [],
      unansweredproactivity: 0,
      loadingConversationState: true
    }
  },
  beforeDestroy () {
    if (this.chartStatus) {
      this.chartStatus.destroy()
    }
  },
  watch: {
    dataProactivo () {
      if (this.dataProactivo) {
        this.unansweredproactivity = this.dataProactivo
      }
    },
    dataComponent () {
      if (this.dataComponent) {
        try {
          if (this.chartStatus) {
            this.chartStatus.destroy()
          }
          this.setStateConversation(this.dataComponent)
          this.loadingConversationState = false
        } catch (error) {
          this.loadingConversationState = false
        }
      }
    }
  },

  methods: {
    setStateConversation (item) {
      const labels = [
        this.$t('withAdvisor'),
        this.$t('withBot'),
        this.$t('readyAdvisor')
      ]
      const options = {
        dataLabels: {
          enabled: false
        },
        series: [
          parseInt(item.conversations_with_advisor),
          parseInt(item.conversations_with_bot),
          parseInt(item.conversations_ended)
        ],
        chart: {
          height: 180,
          width: 500,
          type: 'pie'
        },
        colors: ['#FC0', '#3AF69B', '#FF0000'],
        legend: {
          show: true,
          floating: true,
          fontSize: '14px',
          fontWeight: 600,
          position: 'left',
          horizontalAlign: 'left',
          offsetX: -30,
          offsetY: 0,
          labels: {
            useSeriesColors: false,
            colors: this.$vuetify.theme.dark ? '#ffffff' : '#000000'
          },
          markers: {
            size: 2
          },
          formatter: function (seriesName, opts) {
            const locale = Intl.NumberFormat('en-US')
            return seriesName + ':  ' + locale.format(opts.w.globals.series[opts.seriesIndex])
          },
          itemMargin: {
            vertical: 3
          }
        },
        responsive: [{
          breakpoint: 480,
          options: {
            legend: {
              show: false
            }
          }
        }],
        plotOptions: {
          radialBar: {
            dataLabels: {
              show: false
            },
            track: {
              strokeWidth: '97%'
            }
          }
        },
        labels: labels
      }
      this.chartStatus = new ApexCharts(document.querySelector('#StateConversationGraph'), options)
      this.chartStatus.render()
    }
  },
  computed: {
    closeModal: {
      get () {
        return this.$store.state.MetricsStore.closeModal || false
      },
      set (value) {
        this.$store.commit('MetricsStore/SET_EDITING_OBJECT', {
          key: 'closeModal',
          value
        })
      }
    },
    loading: {
      get () {
        return this.$store.state.MetricsStore.loading || false
      },
      set (value) {
        this.$store.commit('MetricsStore/SET_EDITING_OBJECT', {
          key: 'loading',
          value
        })
      }
    }
  },
  mounted () {
  }
}
