<template>
  <svg width="24"
       height="30"
       viewBox="0 0 24 30"
       fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <g id="Group 4480">
      <path id="Vector"
            d="M16.3311 0H0.783744C0.35089 0 0 0.35089 0 1.03178V29.4641C0 29.6489 0.35089 29.9998 0.783744 29.9998H22.2518C22.6847 29.9998 23.0355 29.6489 23.0355 29.4641V6.95245C23.0355 6.5796 22.9857 6.4596 22.8979 6.3712L16.6643 0.137678C16.576 0.0498211 16.456 0 16.3311 0Z"
            fill="#E9E9E0"/>
      <path id="Vector_2"
            d="M8.35547 0.081543V6.42918H14.7031L8.35547 0.081543Z"
            fill="#D9D7CA"/>
      <path id="Vector_3"
            d="M6.55388 17.8523C6.36745 17.8523 6.18852 17.7917 6.03585 17.6776C5.47817 17.2592 5.40317 16.7937 5.43853 16.4766C5.53603 15.6044 6.61441 14.6916 8.64476 13.7616C9.45047 11.9959 10.2171 9.82035 10.674 8.00268C10.1394 6.83912 9.61975 5.32949 9.9985 4.44396C10.1314 4.13378 10.2969 3.89593 10.606 3.79307C10.7281 3.75236 11.0367 3.70093 11.1503 3.70093C11.4203 3.70093 11.6576 4.0486 11.8258 4.26289C11.9838 4.46431 12.3422 4.89128 11.626 7.90679C12.3481 9.39821 13.3713 10.9175 14.3517 11.9578C15.054 11.8309 15.6583 11.766 16.1506 11.766C16.9895 11.766 17.4979 11.9616 17.7052 12.3644C17.8767 12.6976 17.8065 13.0871 17.4963 13.5216C17.1979 13.9389 16.7865 14.1596 16.307 14.1596C15.6556 14.1596 14.897 13.7482 14.0511 12.9355C12.5313 13.2532 10.7565 13.82 9.32189 14.4473C8.87404 15.3976 8.44494 16.1632 8.0453 16.7246C7.49619 17.4933 7.02263 17.8523 6.55388 17.8523ZM7.97994 15.1062C6.83513 15.7496 6.36852 16.2783 6.33477 16.5762C6.32942 16.6255 6.31495 16.7551 6.56566 16.9469C6.64548 16.9217 7.11155 16.709 7.97994 15.1062ZM15.2854 12.7266C15.722 13.0625 15.8286 13.2323 16.1142 13.2323C16.2395 13.2323 16.5968 13.2269 16.7624 12.996C16.8422 12.8841 16.8733 12.8123 16.8856 12.7737C16.8197 12.7389 16.7324 12.6682 16.2561 12.6682C15.9856 12.6687 15.6454 12.6805 15.2854 12.7266ZM11.2837 9.20053C10.9006 10.5259 10.3949 11.9568 9.85118 13.2526C10.9708 12.8182 12.1879 12.4389 13.3312 12.1705C12.6079 11.3305 11.8853 10.2816 11.2837 9.20053ZM10.9585 4.66735C10.906 4.68503 10.246 5.60859 11.0099 6.39019C11.5183 5.25717 10.9815 4.65985 10.9585 4.66735Z"
            fill="#CC4B4C"/>
      <path id="Vector_4"
            d="M22.2518 29.9999H0.783744C0.35089 29.9999 0 29.649 0 29.2162V20.8928H23.0355V29.2162C23.0355 29.649 22.6847 29.9999 22.2518 29.9999Z"
            fill="#CC4B4C"/>
      <g id="PDF">
        <path d="M5.625 27.946V23.113H7.29948C7.64485 23.113 7.95345 23.1721 8.22529 23.2902C8.49935 23.4083 8.71437 23.5821 8.87035 23.8116C9.02855 24.0411 9.10765 24.3229 9.10765 24.6572C9.10765 24.978 9.03523 25.2521 8.8904 25.4794C8.7478 25.7044 8.54726 25.8771 8.28879 25.9974C8.03255 26.1155 7.73509 26.1746 7.3964 26.1746H6.35362V27.946H5.625ZM6.35027 25.5897H7.37635C7.68384 25.5897 7.93451 25.5016 8.12836 25.3256C8.32221 25.1474 8.41914 24.9112 8.41914 24.6171C8.41914 24.3185 8.31887 24.0901 8.11833 23.9319C7.9178 23.7715 7.66156 23.6913 7.34961 23.6913H6.35027V25.5897Z"
              fill="white"/>
        <path d="M9.90728 23.113C9.92511 23.113 9.97747 23.1142 10.0644 23.1164C10.1535 23.1164 10.2605 23.1164 10.3852 23.1164C10.51 23.1142 10.637 23.1142 10.7662 23.1164C10.8977 23.1164 11.0147 23.1175 11.1172 23.1197C11.7077 23.1286 12.1878 23.2267 12.5577 23.4138C12.9276 23.601 13.1983 23.8706 13.3699 24.2227C13.5437 24.5725 13.6306 24.9992 13.6306 25.5028C13.6306 26.0264 13.5381 26.4709 13.3532 26.8363C13.1682 27.1995 12.8852 27.4758 12.5042 27.6652C12.1254 27.8524 11.6453 27.946 11.0637 27.946H9.90728V23.113ZM10.6359 23.7146V27.3444H11.0704C11.4358 27.3444 11.7533 27.2853 12.0229 27.1672C12.2948 27.0469 12.5053 26.853 12.6546 26.5857C12.8039 26.316 12.8786 25.9562 12.8786 25.5061C12.8786 25.0649 12.8073 24.714 12.6647 24.4533C12.5221 24.1904 12.3171 24.0021 12.0497 23.8884C11.7845 23.7726 11.4648 23.7146 11.0904 23.7146H10.6359Z"
              fill="white"/>
        <path d="M14.5878 23.113H17.676L17.6727 23.728H15.3164V25.222H17.412V25.8303H15.3164V27.946H14.5878V23.113Z"
              fill="white"/>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'PdfIcon',
  props: {
    width: {
      type: Number,
      default: 16
    },
    height: {
      type: Number,
      default: 18
    },
    color: {
      type: String,
      default: '#B6BEC7'
    }
  }

}
</script>

<style>

</style>
