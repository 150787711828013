<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       fill="none"
       viewBox="0 0 13 10"><path :fill="color"
                                 d="M6.895 7.175C6.94 5.535 8.15 4.377 9.71 4.39c1.66.015 2.802 1.246 2.788 2.818-.015 1.65-1.244 2.794-2.808 2.785-1.655-.01-2.777-1.27-2.796-2.819Zm2.403.254c-.031.061-.053.107-.078.151-.157.286-.316.57-.473.855-.08.145-.07.27.02.346.091.076.226.069.35-.024l1.771-1.33c.122-.093.17-.204.126-.314-.05-.127-.153-.165-.283-.162-.203.005-.405.001-.625.001.032-.058.053-.1.077-.14.163-.286.329-.572.492-.86.066-.117.05-.295-.03-.364-.074-.064-.232-.057-.341.025-.602.45-1.204.902-1.804 1.354-.102.076-.158.173-.11.304.045.127.145.16.27.159.206-.003.413 0 .638 0Z"/><path :fill="color"
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        d="m6.61 8.754.51.758c-1.276.697-3.507.766-5.213-.588C.16 7.54-.537 5.116.454 2.93A4.952 4.952 0 0 1 5.5.027c2.373.25 3.913 1.986 4.313 3.694-.281.034-.563.073-.846.1-.032.002-.084-.057-.104-.098-.149-.308-.262-.638-.443-.925C7.778 1.777 6.847 1.173 5.657.982c-1-.16-1.946.02-2.8.56-1.015.643-1.653 1.559-1.855 2.758-.17 1.008-.004 1.962.532 2.831A3.95 3.95 0 0 0 3.68 8.847a4.035 4.035 0 0 0 2.474.063c.157-.046.31-.106.456-.156Z"/><path :fill="color"
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                               d="M5.455 4.086c0 .561.002 1.122-.002 1.683 0 .084.023.137.097.178.574.324.737 1.115.253 1.69-.262.31-.863.418-1.265.227-.382-.181-.58-.49-.609-.902-.03-.422.132-.765.485-1.004.09-.06.114-.122.114-.222a910.137 910.137 0 0 1-.002-3.379c0-.266.146-.455.371-.492.316-.05.556.172.558.526.002.565 0 1.13 0 1.695ZM3.744 3.122a.6.6 0 0 1-.616.62c-.359.002-.62-.265-.616-.63a.598.598 0 0 1 .62-.614.6.6 0 0 1 .612.624ZM7.502 3.122a.6.6 0 0 1-.615.62.599.599 0 0 1-.617-.63.598.598 0 0 1 .62-.614.6.6 0 0 1 .612.624ZM2.807 5.005a.6.6 0 0 1-.62.616c-.361-.002-.618-.268-.613-.635a.598.598 0 0 1 .625-.609c.359.01.61.269.607.628Z"/></svg>
</template>

<script>
export default {
  name: 'QuickResponsesIcon',
  props: {
    color: {
      type: String,
      default: '#fff'
    }
  }
}
</script>

<style>

</style>
