<template>
  <div>
    <div class="item-link cursor-pointer"
         v-if="!edit">
      <div class="item-link__icon">
        <img :src="link.icon"
             class="white-filter" />
      </div>
      <div class="item-link__text">
        <span>{{ link.description }}</span>
      </div>
    </div>
    <div class="item-link__icon"
         v-else>
      <img
        :src="link.icon"
        class="white-filter input__icon cursor-pointer"
      />
      <input
        :placeholder="linkData.description"
        v-model="linkData.description"
        type="text"
        class="item-link__input"
      />
      <v-menu
        offset-x
        location="start"
        internal-activator
        :close-on-content-click="false"
      >
        <template>
          <span class="item-link__options"
          ><v-icon size="18">mdi-dots-vertical</v-icon></span
          >
          <!-- <span class="item-link__options"
                v-on="on"
          ><v-icon size="18">mdi-dots-vertical</v-icon></span
          > -->
        </template>
        <!-- <template v-slot:activator="{ on }">
          <span class="item-link__options"
                v-on="on"
          ><v-icon size="18">mdi-dots-vertical</v-icon></span
          >
        </template> -->
        <v-card min-width="300"
                class="px-3 py-5">
          <v-list>
            <p class="capitalize text-gray-500">{{ $t("link") }}</p>
            <input
              class="input__link"
              type="text"
              v-model="linkData.url"
              :placeholder="link.url"
            />
          </v-list>
        </v-card>
      </v-menu>
    </div>
  </div>
</template>

<script>
// import { EventBus } from '@/eventBus'

export default {
  props: {
    link: {
      required: true
    }
  },
  computed: {
    siteUrlFiltered () {
      if (this.link.description) return this.link.description
      return this.link.url.replace('https://', '').replace('http://', '')
    }
  },
  data () {
    return {
      linkData: {
        description: '',
        url: ''
      },
      edit: false
    }
  },
  methods: {
    // toggleEdit () {
    //   this.edit = !this.edit
    // },
    saveEdit () {
      this.toggleEdit()
      // [FIX] SAVING EDIT DIRECTLY FROM PREVIEW MICROSITE
      // this.$emit('editConfig', {
      //   editing: 'mainButton',
      //   ogDescription: this.link.description,
      //   newData: this.linkData
      // })
    }
    // socialIconsEdit () {
    //   EventBus.$emit('switchMSP', 'MicrositesIconSelector')
    // }
  },
  created () {
    this.linkData.description = this.siteUrlFiltered
  }
}
</script>

<style scoped lang="scss">
.white-filter {
  max-width: 20px;
  min-width: 20px;
  filter: invert(50%) sepia(6%) saturate(142%) hue-rotate(201deg)
    brightness(92%) contrast(92%);
}

.item-link {
  position: relative;
  $self: &;
  display: flex;
  width: 100%;
  // max-width: 256px;
  // max-width: calc(100% - 5vw);
  max-width: 300px;
  gap: 14px;
  border: 1px solid var(--main-color);
  border-radius: 30px;
  height: 39px;
  display: flex;
  transition: all 0.2s ease-in-out;
  padding: 0 15px;

  &:before {
    content: "";
    height: 1px;
    width: 50vw;
    position: absolute;
    background: var(--main-color);
    left: 100%;
    top: 50%;
  }

  &:focus {
    outline: none;
  }

  &:hover {
    outline: none;
    background-color: var(--main-color);

    #{$self}__text {
      span {
        color: black;
      }
    }

    #{$self}__icon {
      i,
      svg {
        color: black !important;
        fill: black !important;
      }
    }
  }

  &__options {
    cursor: pointer;
    margin: 9px 13px 13px 15px;
    position: absolute;
    right: 0;
  }

  &.inactive {
    cursor: default;
  }

  &__icon {
    display: grid;
    place-content: center;
    position: relative;

    i,
    svg {
      color: white;
      fill: white;
      transition: all 0.2s ease-in-out;
      font-size: 14px;
    }

    img {
      width: 14px;
      height: 15px;
    }
  }

  &__text {
    display: flex;
    align-items: center;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    span {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: white;
      text-decoration: none;
      transition: all 0.2s ease-in-out;
    }
  }

  &__input {
    font-size: 14px;
    color: #fff;
    display: flex;
    width: 100%;
    max-width: 300px;
    gap: 14px;
    border: 1px solid var(--main-color);
    border-radius: 30px;
    height: 39px;
    display: flex;
    padding: 0 15px 0 50px;
  }

  &__input:focus {
    outline: none;
  }
}

.input__icon {
  position: absolute;
  left: 0;
  margin: 13px 13px 13px 15px;
}

.input__link {
  background: #b6bec7;
  outline: none;
  border: none;
  min-height: 53px;
  padding: 11px;
}
</style>
