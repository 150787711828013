import { EventBus } from '@/eventBus'
import KnowledgeBaseUseCases from '@/modules/KnowledgeBase/application/KnowledgeBaseUseCases'
import { uploadFileDTO } from '@/modules/KnowledgeBase/domain/KnoledgeBase'
import KnowledgeBaseController from '@/modules/KnowledgeBase/infrastructure/KnowledgeBase.controller'
import S3UseCases from '@/modules/S3/application/S3UseCases'
import S3Controller from '@/modules/S3/infrastructure/S3.controller'
import SmartOMeterUseCases from '@/modules/ai/application/SmartOMeterUseCases'
import { SmartOMeterTiers } from '@/modules/ai/domain/SmartOMeter/SmartOMeterTiers'
import Colors from '@/shared/domain/colors/Color'
import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import Loader from '@/shared/infrastructure/ui/Loader.vue'
import NitroBox from '@/shared/infrastructure/ui/NitroBox/NitroBox.vue'
import KBButton from '@/shared/infrastructure/ui/buttons/KBButton.vue'
import KeybeDropbox from '@/shared/infrastructure/ui/dropbox/KeybeDropbox.vue'
import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'
import moment from 'moment'
import { mapActions, mapState } from 'vuex'
import SmartOMeterController from '../../controllers/SmartOMeter.controller'

export default {
  name: 'KnowledgeBaseFiles',
  components: {
    KeybeDropbox,
    Loader,
    KeybeIcon,
    KBButton,
    NitroBox
  },
  props: {
    tier: {
      required: true
    }
  },
  data () {
    return {
      KeybeIconType,
      Colors,
      s3Controller: new S3Controller(),
      knowledgeBaseController: new KnowledgeBaseController(),
      files: [],
      loading: false,
      fileHovered: '',
      smartOMeterController: new SmartOMeterController(),
      cortexUrl: `${process.env.VUE_APP_KEYBE_CO_URL}docs/ayudas/kb-cortex/que-es-kb-cortex/`
    }
  },
  computed: {
    ...mapState('AppStore', ['selectedApp']),
    ...mapState('UserStore', ['token', 'user']),
    title () {
      return this.$t('documentsForYourAi')
    }
  },
  methods: {
    ...mapActions('BillingStore', ['setTier']),
    goToTutorial () {
      const url = `${process.env.VUE_APP_KEYBE_CO_URL}docs/ayudas/kb-cortex/que-es-kb-cortex/`
      window.open(url, '_blank')
    },
    handleDropData (e) {
      const files = e.dataTransfer.files
      console.log(files)
      const valid = this.validateFiles(files)
      if (!valid) {
        return
      }
      this.uploadFiles(files)
    },
    validateFiles (files) {
      // only docx is supported
      const validTypes = ['application/vnd.openxmlformats-officedocument.wordprocessingml.document']
      let valid = true
      for (let i = 0; i < files.length; i++) {
        const file = files[i]
        if (validTypes.indexOf(file.type) === -1) {
          valid = false
          break
        }
      }
      if (!valid) {
        EventBus.$emit('toast', 'error', this.$t('onlyDocxSupported'))
      }
      // validate file size (max 50kb)
      for (let i = 0; i < files.length; i++) {
        const file = files[i]
        if (file.size > 50000) {
          valid = false
          break
        }
      }
      if (!valid) {
        EventBus.$emit('toast', 'error', this.$t('maxFileSize50kb'))
      }

      return valid
    },
    async uploadFiles (files) {
      for (let i = 0; i < files.length; i++) {
        const file = files[i]
        const route = `ai/knowledge-base/${this.selectedApp?.uuid}/`
        const data = {
          file,
          route
        }

        try {
          this.loading = true
          const fileData = await S3UseCases.uploadFile(this.s3Controller, data)

          if (!fileData) {
            throw new Error('Error uploading file')
          }

          const dataToSave: uploadFileDTO = {
            token: this.token,
            name: fileData.name,
            _id: fileData._id,
            extension: fileData.extension,
            appUUID: this.selectedApp?.uuid
          }

          const uploaded = await KnowledgeBaseUseCases.uploadFile(this.knowledgeBaseController, dataToSave)

          if (!uploaded) {
            throw new Error('Error uploading file')
          }

          EventBus.$emit('toast', 'success', this.$t('fileUploaded'))
          await this.getFiles()
          this.loading = false
        } catch (e) {
          this.loading = false
          EventBus.$emit('toast', 'error', this.$t('errorUploadingFile'))
        }
      }
    },
    async getFiles () {
      this.loading = true
      this.files = await KnowledgeBaseUseCases.getFiles(this.knowledgeBaseController, {
        token: this.token,
        appUUID: this.selectedApp?.uuid
      })

      this.loading = false
    },
    async deleteFile (file) {
      this.loading = true
      const deleted = await KnowledgeBaseUseCases.deleteFile(this.knowledgeBaseController, {
        token: this.token,
        _id: file._id
      })

      if (!deleted) {
        EventBus.$emit('toast', 'error', this.$t('errorDeletingFile'))
        this.loading = false
        return
      }

      await this.getFiles()
      this.loading = false
    },
    formatDate (date) {
      return moment(date).locale(this.$i18n.locale).format('ddd DD [de] MMM-YYYY')
    },
    openFileInput () {
      this.$refs.fileInput.click()
    },
    async handleFileInput (e) {
      const files = e.target.files
      const valid = this.validateFiles(files)
      if (!valid) {
        return
      }
      this.uploadFiles(files)
    },
    async updateTier (tierNumber) {
      const newTier = `TIER_${tierNumber}`

      const level = SmartOMeterTiers[newTier]

      await SmartOMeterUseCases.updateTier(this.smartOMeterController, {
        appUUID: this.selectedApp?.uuid,
        level,
        token: this.token,
        user: this?.user.uuid
      })

      this.setTier(level)
    }
  },
  async mounted () {
    console.log(this.tier)
    await this.getFiles()
  }
}
