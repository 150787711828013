<template>
  <div class="MediaSelector">
    <input type="file" id="MediaSelector__input" ref="MediaSelector__input" :accept="accept" hidden @change="onFilePicked">
    <div class="TemplateComposer__imageInput">
      <span class="inputLabel">{{$t(type)}}</span>
      <div @click="pickFile" class="TemplateComposer__imageInput--input">
        <input :placeholder="$t('addMediaUrl')" v-model="url" disabled type="text">
        <v-icon v-if="!showUploadProgress">mdi-magnify</v-icon>
        <div v-else class="d-flex align-center">
          <v-progress-circular
            :size="20"
            :width="2"
            :value="uploadProgress"
            class="mr-1"
          />
          {{uploadProgress}}%
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Colors from '@/utils/Colors'
import { TemplateTypes } from '@/components/conversations/enums/TemplateTypes'
import { EventBus } from '@/eventBus'
import { UploadFilesService } from '@/components/shared/services/UploadFilesService'
export default {
  name: 'MediaSelector',
  props: ['type', 'url'],
  data () {
    return {
      uploadProgress: 0,
      Colors
    }
  },
  methods: {
    async onFilePicked (event) {
      const file = event.target.files[0]
      if (!file) {
        return
      }
      const response = await UploadFilesService.uploadFile({
        totalFiles: [file],
        onUploadProgress: this.updateProgress,
        roomId: 'media_templates'
      })

      if (!response.status) {
        EventBus.$emit('toast', 'error', this.$t('errorUploadingFile'))
        return
      }

      this.uploadProgress = 100

      console.log(response)
      const url = response.data.data.urls[0]
      console.log(url)
      this.$emit('fileUploaded', url)
    },
    pickFile () {
      if (this.uploadProgress > 0 && this.uploadProgress < 100) {
        return
      }
      this.$refs.MediaSelector__input.click()
    },
    updateProgress (progressEvent) {
      this.uploadProgress = Math.round(
        (progressEvent.loaded / progressEvent.total) * 100 - 4
      )
      if (this.uploadProgress < 0) {
        this.uploadProgress = 0
      }

      console.log(this.uploadProgress)
    }
  },
  computed: {
    isImage () {
      return this.type === TemplateTypes.IMAGE
    },
    isVideo () {
      return this.type === TemplateTypes.VIDEO
    },
    isDocument () {
      return this.type === TemplateTypes.DOCUMENT
    },
    accept () {
      if (this.isImage) {
        return '.jpg, .jpeg, .png'
      }
      if (this.isVideo) {
        return '.mp4'
      }
      if (this.isDocument) {
        return '.pdf'
      }
      return ''
    },
    showUploadProgress () {
      return this.uploadProgress > 0 && this.uploadProgress < 100
    }
  }

}
</script>

<style lang="scss" scoped>
.TemplateComposer__imageInput {
  display: flex;
  flex-direction: column;
  margin-top: 10px;

  span {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;

    /* verde bird */

    color: #01CFE4;
  }

  .TemplateComposer__imageInput--input {
    margin-top: 10px;
    display: flex;
    align-items: center;
    width: 80%;
    height: 53px;
    max-width: 476px;
    border-radius: 10px;
    padding: 0 26px;
          font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;

    input {
      width: 100%;
      height: 100%;
      outline: none;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
    }
  }
}
</style>
