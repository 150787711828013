import { dataToGetCommissions, dataToGetPartnerUtmInformation, IPartnersController, partnerBrandsGetter, PartnerUtmInformationGetter } from '../domain/PartnersController'

export default class PartnersUseCases {
  static async getCommissions (controller: IPartnersController, data: dataToGetCommissions) : Promise<partnerBrandsGetter> {
    return controller.getCommissions(data)
  }

  static async getPartnerUrl (controller: IPartnersController, type: string): Promise<string> {
    return controller.getPartnerUrl(type)
  }

  static async getPartnerUtmInformation (controller: IPartnersController, data: dataToGetPartnerUtmInformation): Promise<PartnerUtmInformationGetter> {
    return controller.getPartnerUtmInformation(data)
  }
}
