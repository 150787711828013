<template>
  <svg :width="width"
       :height="height"
       viewBox="0 0 18 14"
       fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <rect width="14"
          height="4.28571"
          rx="2"
          transform="matrix(-5.08479e-08 -1 -1 3.75765e-08 4.28571 14)"
          :fill="color"/>
    <rect width="14"
          height="4.28571"
          rx="2"
          transform="matrix(-5.08479e-08 -1 -1 3.75765e-08 11.1429 14)"
          :fill="color"/>
    <rect width="14"
          height="4.28571"
          rx="2"
          transform="matrix(-5.08479e-08 -1 -1 3.75765e-08 18 14)"
          :fill="color"/>
  </svg>

</template>

<script>
export default {
  name: 'CalendarIcon',
  props: {
    width: {
      type: Number,
      default: 18
    },
    height: {
      type: Number,
      default: 14
    },
    color: {
      type: String,
      default: '#B6BEC7'
    }
  }

}
</script>

<style>

</style>
