import { ILocationController, citiesGetter, countriesGetter, dataToGetCities, dataToGetCountries, dataToGetStates, languagesGetter, statesGetter, timezonesGetter } from '../domain/LocationController'

export default class LocationUseCases {
  static async getCountries (controller: ILocationController, data: dataToGetCountries) : Promise<countriesGetter> {
    return await controller.getCountries(data)
  }

  static async getStates (controller: ILocationController, data: dataToGetStates) : Promise<statesGetter> {
    return await controller.getStates(data)
  }

  static async getCities (controller: ILocationController, data: dataToGetCities) : Promise<citiesGetter> {
    return await controller.getCities(data)
  }

  static async getTimezones (controller: ILocationController) : Promise<timezonesGetter> {
    return await controller.getTimezones()
  }

  static async getLanguages (controller: ILocationController) : Promise<languagesGetter> {
    return await controller.getLanguages()
  }
}
