


























import ChannelsConfig from './ChannelsConfig'
export default ChannelsConfig
