import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'
import KeybeAvatar from '@/shared/infrastructure/ui/KeybeAvatar.vue'
import KeybeButton from '@/modules/DesignSystem/infrastructure/components/KeybeButton/KeybeButton.vue'
import { mapState } from 'vuex'
import { Languages } from '@/components/shared/enums/Languages'
import { CDPFieldsEnums } from '@/modules/CDP/shared/contacts/domain/enums/CDPFieldsEnums'
export default {
  components: {
    KeybeIcon,
    KeybeAvatar,
    KeybeButton
  },
  props: {
    log: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapState('AppStore', ['language']),
    logTitle (): string {
      return this.log?.submodule ? `log_${this.log?.submodule}` : `log_${this.log?.module}`
    },
    logAction (): string {
      if (this.log?.action?.toUpperCase().includes('UPDATE')) {
        return `${this.$t(this.log?.action?.toUpperCase() || '')}
        ${this.log?.object ? `[${this.$t(this.log?.object)}]` : ''}
        ${this.$t('onField')} [${this.$t(this.log?.payload?.field)}]`
      } else {
        return `
        ${this.$t(this.log?.action?.toUpperCase() || '')}
        ${this.log?.object ? `[${this.$t(this.log?.object)}]` : ''}
      `
      }
    },
    logField (): string {
      return this.log?.payload?.field || ''
    },
    newValueString (): boolean {
      if (typeof this.log?.payload?.newValue === 'string') return true
      else return false
    },
    logNewValue (): string {
      const value = []
      if (this.log?.payload?.field === CDPFieldsEnums.WORKING_HOURS) {
        if (this.log?.payload?.newValue?.days) {
          this.log.payload.newValue.days.forEach((day: any, index: number) => {
            day.schedules.forEach((schedule: any) => {
              value.push(`${this.daysLabels[index]}: ${schedule?.from || ''} - ${schedule?.until || ''}`)
            })
          })
          return value.toString()
        }
      }

      return this.log?.payload?.newValue || ''
    },
    logOldValue (): string {
      const value = []
      if (this.log?.payload?.field === CDPFieldsEnums.WORKING_HOURS) {
        if (this.log?.payload?.newValue?.days) {
          this.log.payload.oldValue.days.forEach((day: any, index: number) => {
            day.schedules.forEach((schedule: any) => {
              value.push(`${this.daysLabels[index]}: ${schedule.from} - ${schedule.until}`)
            })
          })
          return value.toString()
        }
      }
      return this.log?.payload?.oldValue || ''
    },
    showBeforeAfter (): boolean {
      return this.log?.payload?.newValue && this.log?.payload?.oldValue
    },
    logResponsible (): string {
      return this.log?.user?.name || ''
    },
    createdAtHour (): string {
      const hour: string = new Date(this.log?.createdAt).toLocaleTimeString()
      const formattedHour: string[] = hour.split(' ')
      return `${formattedHour[0]?.slice(0, -3)} ${formattedHour[1]?.toUpperCase() || ''}`
    },
    createdAtDate (): string {
      const lang = this.language === Languages.EN ? 'en-US' : 'es-ES'
      const options = { weekday: 'short', day: 'numeric', month: 'long', year: 'numeric' }
      // @ts-ignore
      const date: string = new Date(this.log?.createdAt).toLocaleDateString(lang, options)
      const formattedDate: string[] = date.split(' ')
      const weekday: string = formattedDate[0]?.charAt(0)?.toUpperCase() + formattedDate[0]?.slice(1)?.slice(0, -1)
      return `
        ${weekday} ${formattedDate[1]} ${formattedDate[2]} ${formattedDate[3]} - ${formattedDate[5]}
      `
    }
  },
  data () {
    return {
      KeybeIconType,
      open: false,
      daysLabels: [this.$t('sunday'), this.$t('monday'), this.$t('tuesday'), this.$t('wednesday'), this.$t('thursday'), this.$t('friday'), this.$t('saturday')]
    }
  }
}
