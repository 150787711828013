export enum CDPFieldsEnums {
  DOCUMENT_TYPE = 'documentType',
  DOCUMENT = 'document',
  TAX_CODE = 'taxCode',
  PRIORITY = 'priority',
  TAX_GROUP = 'taxGroup',
  DESCRIPTION = 'description',
  DISIMBIGUATING_DESCRIPTION = 'disambiguatingDescription',
  ALIAS = 'alias',
  COUNTRY = 'country',
  PROVINCE = 'province',
  CITY = 'city',
  ZONE = 'zone',
  BIRTH_DATE = 'birthDate',
  BIRTH_PLACE_COUNTRY = 'birthPlaceCountry',
  BIRTH_PLACE_PROVINCE = 'birthPlaceProvince',
  BIRTH_PLACE_CITY = 'birthPlaceCity',
  DEATH_DATE = 'deathDate',
  DEATH_PLACE_COUNTRY = 'deathPlaceCountry',
  DEATH_PLACE_PROVINCE = 'deathPlaceProvince',
  DEATH_PLACE_CITY = 'deathPlaceCity',
  NATIONALITY = 'nationality',
  CHILDREN_NUMBER = 'childrenNumber',
  COLLEAGE = 'colleage',
  AFFILIATION = 'affiliation',
  AWARD = 'award',
  DUNS = 'duns',
  ESTABLISHMENT_DATE = 'establishmentDate',
  ESTABLISHMENT_COUNTRY = 'establishmentCountry',
  ESTABLISHMENT_PROVINCE = 'establishmentProvice',
  ESTABLISHMENT_CITY = 'establishmentCity',
  NPS = 'nps',
  TIME_ZONE = 'timeZone',
  CATEGORY = 'category',
  SUB_CATEGORY = 'subCategory',
  PROFESSION = 'profession',
  TAGS = 'tags',
  KNOWS = 'knows',
  HEAR_ABOUT = 'hearAbout',
  PREFERRED_STORE = 'preferredStore',
  PREFERRED_PRODUCT = 'preferredProduct',
  LEAD_GEN_ID = 'leadGenId',
  FORM_ID = 'formId',
  SOURCE = 'source',
  HABEAS_DATA_STATUS = 'habeasDataStatus',
  IDENTIFIER = 'identifier',
  HEADQUARTERS = 'headquarters',
  GENDER = 'gender',
  OCCUPATIONS = 'occupations',
  EDUCATIONAL_LEVEL = 'educationalLevel',
  ANNUAL_INCOME = 'annualIncome',
  MONTHLY_INCOME = 'monthlyIncome',
  ETHNICITY = 'ethnicity',
  CIVIL_STATUS = 'civilStatus',
  RELIGION = 'religion',
  SOCIOECONOMIC_STATUS = 'socioeconomicStatus',
  PREFERRED_LANGUAGE = 'preferredLanguage',
  CREDENTIALS = 'credentials',
  STATUS = 'status',
  REGISTER_CHANNEL = 'registerChannel',
  RESPONSIBLE = 'responsible',
  ROLES = 'roles',
  AUTH_ROLES = 'authRoles',
  CREATED_AT = 'createdAt',
  FUNNEL_STEP = 'funnelStep',
  EMAIL_EMPTY = 'emailEmpty',
  PHONE_EMPTY = 'phoneEmpty',
  EMAIL = 'email',
  PHONE = 'phone',
  BUSINESS_TYPE = 'businessType',
  ESTABLISHMENT = 'establishment',
  INACTIVE = 'inactive',
  INACTIVE_REASON = 'inactiveReason',
  UTM_MEDIUM = 'utmMedium',
  UTM_CAMPAIGN = 'utmCampaign',
  UTM_TERM = 'utmTerm',
  UTM_CONTENT = 'utmContent',
  UTM_PARTNER = 'utmPartner',
  CHANNEL = 'channel',
  CHANNEL_OTHER = 'channelOther',
  UTM_SOURCE = 'utmSource',
  UTM_OTHER = 'utmOther',
  URL_PUBLIC = 'urlPublic',
  PUBLIC_SOCIAL_MEDIA = 'publicSocialmedia',
  EXTENDED_DATA = 'extendedData',
  INACTIVE_AT = 'inactiveAt',
  LANGUAGE = 'language',
  MIDNAME = 'midname',
  BUSINESS_NAME = 'businessName',
  FRONTAL_DOCUMENT = 'validadorDocumentoFrontal',
  BACK_DOCUMENT = 'validadorDocumentoRespaldo',
  GIVEN_NAME = 'givenNames',
  BLOOD_GROUP = 'bloodGroup',
  DATE_OF_BIRTH = 'dateOfBirth',
  FIRST_ISSUE_DATE = 'firstIssueDate',
  HEIGHT = 'height',
  PLACE_OF_BIRTH = 'placeOfBirth',
  SEX = 'sex',
  PLACE_ISSUE = 'placeOfIssue',
  DOCUMENT_NUMBER = 'documentNumber',
  NAME = 'name',
  SURNAME = 'surname',
  MIN = 'min',
  MAX = 'max',
  UTM_VALUE = 'utmValue',
  TEAM = 'team',
  TEAM_BRANDS = 'team_brands',
  ACTIVE_TEAM = 'Active',
  INACTIVE_TEAM = 'Inactive',
  DEFAULT_COUNTRY_CODE = 'defaultCountryCode',
  UUID = 'uuid',
  LAST_NAME = 'lastName',
  VALUE = 'value',
  FULL_NAME = 'fullName',
  CONTACTS = 'contacts',
  PASSWORD = 'password',
  UPDATED_AT = 'updatedAt',
  TYPE = 'type',
  APP_UUID = 'appUUID',
  TYPIFICATION = 'typification',
  USE_MEMORY = 'useMemory',
  USE_MEMORY_OFFLINE = 'useMemoryOffline',
  MAX_ACTIVE_CONVERSATIONS_NUMBER = 'maxActiveConversationsNumber',
  WORKING_HOURS = 'workingHours',
  CLOSING_CONFIG = 'closingConfig',
  MESSAGE_ON_TRANSFER = 'messageOnTransfer',
  HIDDEN = 'hidden',
  KEY = 'key',
  LANDLINE = 'landline',
  BRAND = 'brand',
  BRANDS = 'brands',
  DOCUMENT_FIRST_ISSUE_DATE = 'documentFirstIssueDate',
  DOCUMENT_PLACE_OF_ISSUE = 'documentPlaceOfIssue',
  HEIGH_CM = 'heightCm',
  OTHER = 'other',
  PRINCIPAL_LOCATION = 'principalLocation',
  BIRTH_PLACE = 'birthPlace',
  DEATH_PLACE = 'deathPlace',
  ADDRESS = 'address',
  NATIVE_NAME = 'nativeName',
  CODE = 'code',
  PLAN = 'plan',
  CREDIT_SCORE = 'creditScore',
  PRODUCTS = 'products',
  ABANDONED_CART = 'abandonedCart',
  ORDERS = 'orders',
  PAYMENTS = 'payments',
  BANK_ACCOUNT = 'bankAccount',
  PERSONAL_REFERENCE = 'personalReference',
  PROOF_OF_BANK_ACCOUNT = 'proofOfBankAccount',
  PROOF_OF_BUSINESS = 'proofOfBusiness',
  PROOF_OF_LOCATION = 'proofOfLocation',
  PROOF_OF_ADDRESS = 'proofOfAddress',
  INTERESTS = 'interests',
  LIKES = 'likes',
  DISLIKES = 'dislikes',
  MUSIC = 'music',
  GENERAL = 'general',
  NATURAL_TALENTS = 'naturalTalents',
  PERSONALITY = 'personality',
  SKILLS = 'skills',
  PSYCHOLOGY = 'psychology',
  COLOR_GAMUT = 'colorGamut',
  COLOR_FAVORITE = 'colorFavorite',
  PREFERRED_CONTACT_TIME = 'preferredContactTime',
  PREFERRED_GREETING = 'preferredGreeting',
  PREFERRED_GOODBYE = 'preferredGoodbye',
  PREFERRED_PRONOUNS = 'preferredPronouns',
  PREFERRED_CULTURE = 'preferredCulture',
  PREFERRED_CHANNEL = 'preferredChannel',
  TREATMENT_PREFERRED = 'treatmentPreferred',
  TREATMENT_TYPE = 'treatmentType',
  KEYBE = 'keybe',
  KB_PLAN = 'kb_plan',
  KB_SYSTEM = 'kb_system',
  NUMBER = 'number',
  TEXT = 'text',
  PARTNER = 'partner',
  REFERALL = 'referall',
  COMMERTIAL = 'comertial',
  HABEAS_DATA_ACCEPTED = 'habeasDataAccepted',
  HABEAS_DATA_DATE = 'habeasDataDate',
  HABEAS_DATA_COUNTRY = 'habeasDataCountry',
  HABEAS_DATA_REMOVED = 'habeasDataRemoved',
  HABEAS_DATA_REMOVED_DATE = 'habeasDataRemovedDate',
  HABEAS_DATA_REMOVED_COUNTRY = 'habeasDataRemovedCountry',
  POINTS = 'points',
  QUOTE_DATE = 'quoteDate',
  MAIN = 'main',
  REFERRAL_PARTNER = 'kb_referral_partner',
  BRAND_PARTNER = 'kb_brand_partner',
  REASON = 'reason',
  _ID = '_id',
  LABEL = 'label',
  READONLY = 'readonly',
  CURRENCY = 'currency',
  LANG = 'lang',
  BUSINESS = 'business',
  ESTABLISHMENT_PLACE = 'establishmentPlace',
  FINANCING_DATE = 'financingDate',
  HABEAS_DATA_ACCEPTED_EMAIL = 'habeasDataAcceptedEmail',
  HABEAS_DATA_ACCEPTED_MAIL = 'habeasDataAcceptedMail',
  HABEAS_DATA_ACCEPTED_SMS = 'habeasDataAcceptedSms',
  HABEAS_DATA_ACCEPTED_PHONE = 'habeasDataAcceptedPhone',
  HABEAS_DATA_ACCEPTED_WHATSAPP = 'habeasDataAcceptedWhatsapp',
  ONLINE = 'online',
  ADVISOR_STATUS = 'advisorStatus',
  ALLOW_MESSAGES_OUTSIDE_BUSINESS_HOURS = 'allowMessagesOutsideBusinessHours',
  ALLOW_MULTIMEDIA_MESSAGES = 'allowMultimediaMessages',
  IS_WHATSAPP_BUTTON_ACTIVE = 'isWhatsappButtonActive',
  FORM_FIELDS = 'formFields',
  MAIN_COLOR = 'mainColor',
  THEME = 'theme',
  POSITION = 'position',
  BUBBLE_SIZE = 'bubbleSize',
  BUBBLE_MESSAGE = 'bubbleMessage',
  LOGO = 'logo',
  FONT_SIZE = 'fontSize',
  COLOR = 'color',
  TEXT_ALIGN = 'textAlign',
  TITLE = 'title',
  WIDGET_TITLE = 'widgetTitle',
  SCHEDULE_DATE = 'scheduleDate',
  CREATE = 'create',
  UPDATE = 'update',
  RULES = 'rules',
  STRING = 'string',
  OBJECT = 'object',
  POSTAL_CODE = 'postalCode',
  ADDRESS_COUNTRY = 'countryAddress',
  ADDRESS_PROVINCE = 'provinceAddress',
  ADDRESS_CITY = 'cityAddress',
  ADDRESS_ZONE = 'zoneAddress',
  ADDRESS_POSTAL_CODE = 'postalCodeAddress',
  ADDRESS_VALUE = 'valueAddress',
  KB_CARD_CLOSER = 'card_kb_closer',
  KB_CDP_CLOSER = 'kb_closer',
  IS_CLOSER = 'isCloser',
  ACTIVE = 'active',
  ADDRESS_CAPITALIZE = 'Address',
  PRODUCT_DATA = 'productsData',
  UPDATED_AT_STEP = 'updatedAtStep',
  PRODUCT = 'product',
  STATUS_AT_INIT = 'statusAtInit',
  STATUS_AT_END = 'statusAtEnd',
  STATUS_AT = 'statusAt',
}
