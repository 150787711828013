
import moment from 'moment'
import ApexCharts from 'apexcharts'
import { EventBus } from '@/eventBus'
import { textCamelize } from '@/utils/utils'
import MetricsService from '@/modules/metrics/application/services/MetricsService'
import LoadingNext from '@/modules/metrics/infrastructure/ui/components/generic/LoadingNext.vue'

export default {
  name: 'RadialBarCharts',
  props: {
    title: {
      type: String,
      default: 'title'
    },
    id: {
      type: String,
      default: '0'
    },
    type: {
      type: String
    },
    range: {
      type: [String, Object]
    }
  },
  components: {
    LoadingNext
  },
  data () {
    return {
      loadingWaitingTime: true,
      loadingTime: true,
      chartRangePieWaitingTime: null,
      chartRangeSessionTime: null
    }
  },
  watch: {
    range: {
      handler: function (newVal, oldVal) {
        if (this.range) {
          if ((newVal?.dateFrom && newVal?.dateTo) && (oldVal?.dateFrom && oldVal?.dateTo)) {
            if (newVal.dateFrom !== oldVal.dateFrom || newVal.dateTo !== oldVal.dateTo) {
              const params = {
                dateFrom: newVal.dateFrom,
                dateTo: newVal.dateTo
              }
              if (this.type === 'time') {
                this.getTime(params)
              } else {
                this.getWaitingTime(params)
              }
            }
          }
        }
      }
    }
  },
  mounted () {
    let params = {}
    if (this.range) {
      params = {
        dateFrom: this.range.dateFrom,
        dateTo: this.range.dateTo
      }
    } else {
      params = {
        dateFrom: moment().startOf('month').format('YYYY-MM-DD'),
        dateTo: moment().format('YYYY-MM-DD')
      }
    }
    if (this.type === 'time') {
      this.getTime(params)
    } else {
      this.getWaitingTime(params)
    }
  },
  methods: {
    async getWaitingTime (params) {
      this.loadingWaitingTime = true
      const { dateFrom, dateTo } = params
      if (dateFrom && dateTo) {
        params = {
          periodStart: dateFrom,
          periodEnd: dateTo
        }
      }
      const response = await MetricsService.getWaitingTimes(params)
      if (response) {
        this.setDataTime(response.data?.pie_duration_session, 'rangeSessionTime')
        this.loadingWaitingTime = false
      } else {
        this.loadingWaitingTime = false
        if (response?.data?.error) {
          EventBus.$emit('toast', 'error', this.$t(textCamelize(response.data.error)))
        } else {
          EventBus.$emit('toast', 'error', this.$t('unexpectedError'))
        }
      }
    },
    async getTime (params) {
      this.loadingTime = true
      const { dateFrom, dateTo } = params
      if (dateFrom && dateTo) {
        params = {
          periodStart: dateFrom,
          periodEnd: dateTo
        }
      }
      const response = await MetricsService.getConversationTimes(params)
      if (response) {
        this.setDataTime(response.data?.pie_waiting_time, 'rangePieWaitingTime')
        this.loadingTime = false
      } else {
        this.loadingTime = false
        if (response?.data?.error) {
          EventBus.$emit('toast', 'error', this.$t(textCamelize(response.data.error)))
        } else {
          EventBus.$emit('toast', 'error', this.$t('unexpectedError'))
        }
      }
    },
    setDataTime (items, type) {
      const labels = items.map(element => { return element[type] })
      const series = items.map(element => { return element.times_over_range })
      this.chartRadial(labels, series, type)
      // }
    },
    chartRadial (labels, series, type) {
      if (type === 'rangePieWaitingTime') {
        if (this.chartRangePieWaitingTime) {
          this.chartRangePieWaitingTime.destroy()
        }
      }
      if (type === 'rangeSessionTime') {
        if (this.chartRangeSessionTime) {
          this.chartRangeSessionTime.destroy()
        }
      }
      const options = {
        dataLabels: {
          enabled: false
        },
        series: series,
        chart: {
          height: 200,
          width: 390,
          type: 'pie'
        },
        colors: ['#3AF69B', '#FC0', '#3375B0', '#E67162', '#01cfe4'],
        legend: {
          show: true,
          floating: true,
          fontSize: '10px',
          position: 'right',
          offsetX: -60,
          offsetY: 0,
          labels: {
            useSeriesColors: false,
            colors: this.$vuetify.theme.dark ? '#ffffff' : '#000000'
          },
          markers: {
            size: 2
          },
          formatter: function (seriesName, opts) {
            const locale = Intl.NumberFormat('en-US')
            return seriesName + ':  ' + locale.format(opts.w.globals.series[opts.seriesIndex])
          },
          itemMargin: {
            vertical: 3
          }
        },
        responsive: [{
          breakpoint: 480,
          options: {
            legend: {
              show: false
            }
          }
        }],
        plotOptions: {
          radialBar: {
            dataLabels: {
              show: false
            },
            track: {
              strokeWidth: '97%'
            }
          }
        },
        labels: labels
      }
      if (type === 'rangePieWaitingTime') {
        this.chartRangePieWaitingTime = new ApexCharts(document.querySelector(`#ConversationsRadialGraph${this.id}`), options)
        this.chartRangePieWaitingTime.render()
      }
      if (type === 'rangeSessionTime') {
        this.chartRangeSessionTime = new ApexCharts(document.querySelector(`#ConversationsRadialGraph${this.id}`), options)
        this.chartRangeSessionTime.render()
      }
    }
  }

}
