import { mapState } from 'vuex'
import AppsUseCases from '@/modules/Apps/application/AppsUseCases'
import ImporterFieldSelection from '@/modules/imports/infrastructure/components/Importer/ImporterFieldSelection/ImporterFieldSelection.vue'
import EmitToast from '@/utils/EmitToast'
import ExtraDataUseCases from '@/modules/ExtraData/application/ExtraDataUseCases'
import ExtraDataController from '@/modules/ExtraData/infrastructure/controllers/ExtraData.controller'
import AppsController from '@/modules/Apps/infrastructure/controllers/Apps.controller'
import { extraDataGetter } from '@/modules/ExtraData/domain/ExtraDataController'
import { extendedFieldGetter } from '@/modules/Apps/domain/AppsController'
import { CDPFieldsEnums } from '@/modules/CDP/shared/contacts/domain/enums/CDPFieldsEnums'
export default {
  components: {
    ImporterFieldSelection
  },
  props: {
    headers: {
      type: Array
    },
    typeEntity: {
      type: String
    }
  },
  computed: {
    ...mapState('AppStore', ['selectedApp']),
    ...mapState('UserStore', ['token']),
    hasErrors () {
      let has = false
      this.localHeaders.forEach(head => {
        if (head.field === '') has = true
      })
      return has
    },
    errorCount () {
      let count = 0
      this.localHeaders.forEach(head => {
        if (head.field === '') count++
      })
      return count
    },
    hasCategories () {
      return this.selectedApp?.categories &&
      this.selectedApp?.categories?.length > 0
    }
  },
  mounted () {
    if (this.hasCategories) this.getExtendedFields()
    this.getExtraDataFields()
  },
  data () {
    return {
      localHeaders: this.headers || [],
      extendedFields: [],
      extradataFields: [],
      extraDataController: new ExtraDataController(),
      appsController: new AppsController()
    }
  },
  methods: {
    setField (field, index) {
      this.localHeaders[index].field = field
      this.$emit('headers', this.localHeaders)
    },
    setFieldParent (parent, index) {
      this.localHeaders[index].fieldParent = parent
      this.$emit('headers', this.localHeaders)
    },
    setFieldCategory (category, index) {
      this.localHeaders[index].category = category
      this.$emit('headers', this.localHeaders)
    },
    async getExtendedFields () {
      try {
        const data = {
          module: 'cdp',
          typeEntity: this.typeEntity,
          category: this.selectedApp?.categories.toString(),
          token: this.token
        }
        const response: extendedFieldGetter = await AppsUseCases.getExtendedFields(this.appsController, data)
        if (response?.status) {
          this.extendedFields = response?.data
        }
      } catch (e) {
        console.error(e)
      }
    },
    async getExtraDataFields () {
      try {
        const data = {
          typeEntity: this.typeEntity,
          app: this.selectedApp?.uuid,
          token: this.token
        }
        const response: extraDataGetter = await ExtraDataUseCases.getExtraDataFields(this.extraDataController, data)
        if (response?.status) {
          this.extradataFields = response?.data?.data || []
        } else EmitToast.emitErrors(response)
      } catch (e) {
        console.error(e)
      }
    }
  },
  watch: {
    localHeaders: {
      deep: true,
      immediate: true,
      handler () {
        const fields = this.localHeaders.map(header => header.field)
        const has = !(fields.includes(CDPFieldsEnums.NAME) && (fields.includes(CDPFieldsEnums.PHONE) || fields.includes(CDPFieldsEnums.EMAIL)))
        this.$emit('hasErrors', has)
      }
    }
  }
}
