import TaskInfo from '@/modules/tasks/infrastructure/ui/components/TaskInfo/TaskInfo.vue'
import TaskOptions from '@/modules/tasks/infrastructure/ui/components/TaskOptions/TaskOptions.vue'

export default {
  name: 'TaskCardExpandedContent',
  props: {
    task: {
      required: true
    }
  },
  components: {
    TaskInfo,
    TaskOptions
  }
}
