import { RestResponseFactory } from '@/models/apis/RestResponse'
import axios from 'axios'
import { dataToDeleteCategory, dataToUpdateCategory } from '../../domain/models/CategoryController'
import { GenericResponse } from '@/shared/infrastructure/http/GenericResponse'
import { HeadersBuilder } from '@/shared/infrastructure/http/HeadersBuilder'
import { nonGetOptions } from '@/shared/infrastructure/http/HttpClient'
import KeybeRepository from '@/shared/infrastructure/repositories/KeybeRepository'
export default class CategoryRepository extends KeybeRepository {
  getSuggestionsAbortController: AbortController

  constructor () {
    super()

    this.getSuggestionsAbortController = new AbortController()
  }

  abortGetSuggestions () {
    this.getSuggestionsAbortController.abort()
    this.getSuggestionsAbortController = new AbortController()
  }

  async getCategories ({
    appUUID,
    token
  }) {
    try {
      const response = await axios.get(
        `${process.env.VUE_APP_MICROSITES_URL}/microsites/category/all/${appUUID}`,
        {
          headers: {
            Authorization: 'Bearer ' + token
          }
        }
      )

      return new RestResponseFactory().process({ response })
    } catch (e) {
      return new RestResponseFactory().process({ response: { status: 500, message: e?.response?.data?.error } })
    }
  }

  static async createCategory ({
    token,
    appUUID,
    category
  }) {
    try {
      const response = await axios.post(
        `${process.env.VUE_APP_MICROSITES_URL}/microsites/category`,
        {
          appUUID,
          ...category
        },
        {
          headers: {
            Authorization: 'Bearer ' + token
          }
        }
      )

      // El backend no devuelve correctamente las respuestas
      if ('response' in response) throw new Error()

      return new RestResponseFactory().process({ response })
    } catch (e) {
      return e?.response?.data?.error || new RestResponseFactory().process({ response: { status: 500 } })
    }
  }

  async updateCategory (data: dataToUpdateCategory): Promise<GenericResponse> {
    const { updateInput } = data
    const url = `${process.env.VUE_APP_MICROSITES_URL}/microsites/category/update`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    const requestOptions: nonGetOptions = {
      headers,
      url,
      data: updateInput,
      abortController: this.getSuggestionsAbortController
    }

    return await this.client.put(requestOptions)
  }

  async deleteCategory (data: dataToDeleteCategory): Promise<GenericResponse> {
    const { id, appUUID } = data
    const url = `${process.env.VUE_APP_MICROSITES_URL}/microsites/category/${appUUID}/${id}`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    const requestOptions: nonGetOptions = {
      headers,
      url,
      abortController: this.getSuggestionsAbortController
    }

    return await this.client.delete(requestOptions)
  }

  static async updateCategoryStatus ({
    appUUID,
    token,
    status,
    id
  }) {
    try {
      const response = await axios.get(
        `${process.env.VUE_APP_MICROSITES_URL}/microsites/category/published/${appUUID}/${id}/${status}`,
        {
          headers: {
            Authorization: 'Bearer ' + token
          }
        }
      )
      return new RestResponseFactory().process({ response })
    } catch (e) {
      return new RestResponseFactory().process({ response: { status: 500 } })
    }
  }
}
