import { debounce } from 'lodash'
import LottieAnimation from 'lottie-web-vue'

import KBIconButton from '@/shared/infrastructure/ui/buttons/KBIconButton.vue'

export default {
  props: {
    items: {
      type: Array,
      default: () => []
    },
    icon: {
      type: String,
      default: ''
    },
    customZIndex: {
      type: String,
      default: ''
    },
    value: {
      type: [String, Object],
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    itemText: {
      type: [String, Array],
      default: ''
    },
    itemValue: {
      type: String,
      default: ''
    },
    returnObject: {
      type: Boolean,
      default: false
    },
    outlined: {
      type: Boolean,
      default: false
    },
    seamless: {
      type: Boolean,
      default: false
    },
    autoSearch: {
      type: [Object, String],
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    rerender: {
      type: [Number, String],
      default: 0
    },
    small: {
      type: Boolean,
      default: false
    },
    forceOpen: {
      type: Boolean,
      default: false
    },
    forceValue: {
      type: Boolean,
      default: false
    },
    containerId: {
      type: String,
      default: ''
    },
    forceIgnoreSearch: {
      type: Boolean,
      default: false
    }
  },
  components: {
    LottieAnimation,
    KBIconButton
  },
  computed: {
    itemsComputed () {
      if (this.ignoreSearch || this.forceIgnoreSearch) {
        return this.items
      } else {
        if (this.multipleText) {
          if (this.itemText[0] && this.itemText[1]) {
            return this.items.filter((item) =>
              (item[this.itemText[0]] + ' ' + item[this.itemText[1]])
                .toLowerCase()
                .includes(this.search.toLowerCase())
            )
          } else {
            return this.items.filter((item) =>
              item[this.itemText[0]]
                .toLowerCase()
                .includes(this.search.toLowerCase())
            )
          }
        } else {
          if (this.itemText) {
            return this.items.filter((item) =>
              item[this.itemText]
                .toLowerCase()
                .includes(this.search.toLowerCase())
            )
          } else {
            return this.items.filter((item) =>
              item.toLowerCase().includes(this.search.toLowerCase())
            )
          }
        }
      }
    },
    multipleText () {
      return Array.isArray(this.itemText)
    }
  },
  mounted () {
    if (this.forceValue && this.value) this.search = this.value
    if (this.value) this.setSearch()
    if (this.autoSearch) this.search = this.autoSearch
    if (this.forceOpen) this.showSelector = true
  },
  data () {
    return {
      selectedItem: null,
      showSelector: false,
      search: '',
      focused: false,
      rerenderLocal: 0,
      ignoreSearch: true
    }
  },
  methods: {
    close () {
      this.showSelector = false
    },
    keyType: debounce(function () {
      this.ignoreSearch = false
      this.sendSearch()
    }, 500),
    selectItem (item) {
      this.showSelector = false
      if (this.returnObject) this.selectedItem = item
      else if (this.itemValue) this.selectedItem = item[this.itemValue]
      else this.selectedItem = item
      this.showSelector = false
      if (this.multipleText) {
        this.search = item[this.itemText[0]] + ' ' + item[this.itemText[1]]
      } else if (this.itemText) {
        this.search = item[this.itemText]
      } else {
        this.search = item
      }
      this.$emit('input', this.selectedItem)
      this.$emit('save', this.selectedItem)
      this.showSelector = false
    },
    isSelected (item: string | { [key: string]: any }): boolean {
      if (this.itemValue) {
        const itemsFound: { [key: string]: any }[] = this.items.filter(
          (i: { [key: string]: any }) =>
            i[this.itemValue] === item[this.itemValue]
        )

        if (itemsFound.length <= 0) {
          return false
        }

        return itemsFound[0][this.itemValue] === this.selectedItem
      } else {
        return this.selectedItem === item
      }
    },
    setSearch () {
      if (this.returnObject) {
        if (this.multipleText) {
          if (this.itemText[0] && this.itemText[1]) {
            const search = this.items.filter(
              (item) =>
                item[this.itemText[0]] + ' ' + item[this.itemText[1]] ===
                this.value[this.itemText[0]] +
                  ' ' +
                  this.value[this.itemText[1]]
            )

            if (search.length > 0) {
              this.search =
                search[0][this.itemText[0]] + ' ' + search[0][this.itemText[1]]
              if (!this.ignoreSearch) this.$emit('search', this.search)
            }
          } else if (this.itemText[0] && !this.itemText[1]) {
            const search = this.items.filter(
              (item) => item[this.itemText[0]] === this.value[this.itemText[0]]
            )

            if (search.length > 0) {
              this.search = search[0][this.itemText]
              if (!this.ignoreSearch) this.$emit('search', this.search)
            }
          }
        } else {
          if (this.itemValue) {
            const search = this.items.filter(
              (item) => item[this.itemValue] === this.value
            )
            if (search.length > 0) {
              this.search = search[0][this.itemText]
              if (!this.ignoreSearch) this.$emit('search', this.search)
            }
          } else {
            const search = this.items.filter(
              (item) => item[this.itemText] === this.value[this.itemText]
            )
            if (search.length > 0) {
              this.search = search[0][this.itemText]
              if (!this.ignoreSearch) this.$emit('search', this.search)
            }
          }
        }
      } else {
        if (
          Array.isArray(this.itemText) &&
          this.itemText[0] &&
          this.itemText[1]
        ) {
          const search = this.items.filter(
            (item) => item[this.itemValue].toString() === this.value.toString()
          )
          if (search.length > 0) {
            if (this.multipleText) {
              this.search =
                search[0][this.itemText[0]] + ' ' + search[0][this.itemText[1]]
            } else {
              this.search = search[0][this.itemText]
            }
            this.$emit('search', this.search)
          }
        } else if (this.itemValue) {
          const search = this.items.find(
            (item: {[key: string]: any}) => item[this.itemValue].toString() === this.value.toString()
          )
          if (search) {
            if (this.multipleText) {
              this.search = search[this.itemText[0]]
            } else {
              this.search = search[this.itemText]
            }
            this.$emit('search', this.search)
          }
        } else {
          const search = this.items.find(
            (item) => item.toString() === this.value.toString()
          )
          if (search) this.search = search
          this.$emit('search', this.search)
        }
      }
      this.rerenderLocal = this.rerenderLocal++
    },
    openSelector () {
      if (!this.disabled) this.showSelector = !this.showSelector
    },
    sendSearch () {
      this.$emit('search', this.search)
    },
    clearSearch () {
      this.search = ''
      this.sendSearch()
      this.$emit('clear')
    }
  },
  watch: {
    search () {
      if (this.search === '') {
        this.clearSearch()
      }
    },
    value () {
      this.ignoreSearch = true
      if (this.value) {
        this.setSearch()
      } else {
        this.search = ''
      }
    },
    showSelector () {
      this.$emit('open', this.showSelector)
    },
    rerender: {
      immediate: true,
      deep: true,
      handler () {
        this.rerenderLocal++
        this.search = ''
      }
    }
  }
}
