
import KeybeRepository from '@/shared/infrastructure/repositories/KeybeRepository'
import { DataToGetConfig } from '../domain/ChatConfigController'
import { HeadersBuilder } from '@/shared/infrastructure/http/HeadersBuilder'

export default class ChatConfigRepository extends KeybeRepository {
  abortController: AbortController
  // eslint-disable-next-line no-useless-constructor
  constructor () {
    super()
    this.abortController = new AbortController()
  }

  dispose (): void {
    this.abortController.abort()
    this.abortController = new AbortController()
  }

  async getConfig (data: DataToGetConfig): Promise<any> {
    const header = HeadersBuilder.buildSimpleAutorizationHeader(data)
    let url = `${process.env.VUE_APP_CONVERSATIONS_API_URL}/config?appUUID=${data.appUUID}&findUserFinishOptions=''&findUserQuickReplies=''`
    if (data?.findUser) url += `&findUserFinishOptions=${data.findUser}&findUserQuickReplies=${data?.findUser}`

    return await this.client.get({
      url,
      headers: header
    })
  }

  async createFinishOptions (data: DataToGetConfig): Promise<any> {
    const header = HeadersBuilder.buildSimpleAutorizationHeader(data)
    const url = `${process.env.VUE_APP_CONVERSATIONS_API_URL}/config/finishOption`

    return await this.client.post({
      url,
      headers: header,
      data: data
    })
  }

  async deleteFinishOptions (data: DataToGetConfig): Promise<any> {
    const header = HeadersBuilder.buildSimpleAutorizationHeader(data)
    const url = `${process.env.VUE_APP_CONVERSATIONS_API_URL}/config/finishOption`

    return await this.client.delete({
      url,
      headers: header,
      data: data
    })
  }
}
