<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       fill="none"
       viewBox="0 0 14 14"><path :fill="color"
                                 d="m3.334 9.983-.184-.182C2.22 8.87 1.302 7.929.353 7.019c-.587-.563-.383-1.316.184-1.65.617-.36 1.222-.737 1.93-.905.67-.158 1.343-.217 2.024-.08.134.026.223-.012.322-.098 1.022-.88 2.047-1.754 3.071-2.63.121-.104.237-.213.378-.34-.175-.174-.344-.34-.51-.51A.441.441 0 0 1 7.638.33a.483.483 0 0 1 .399-.324c.2-.03.34.087.47.217.918.913 1.834 1.829 2.75 2.744.84.838 1.683 1.677 2.524 2.518.163.163.282.339.183.586a.464.464 0 0 1-.756.181c-.176-.16-.34-.335-.523-.52-.233.266-.454.512-.67.764-.774.9-1.548 1.8-2.318 2.704-.052.063-.099.168-.086.244.237 1.397-.12 2.655-.884 3.818-.137.208-.29.43-.49.567-.408.28-.838.21-1.198-.147-.5-.492-.994-.99-1.49-1.485l-1.375-1.372L4 10.649l-.19.19c-.926.924-1.852 1.849-2.779 2.772-.247.247-.43.291-.654.166-.265-.149-.338-.42-.176-.677.045-.072.107-.135.168-.195l2.782-2.74.183-.18v-.002Zm.68-.674.183-.183C4.773 8.55 5.349 7.975 5.926 7.4c.071-.07.143-.142.225-.196a.438.438 0 0 1 .612.135c.152.233.12.43-.124.674-.593.596-1.19 1.189-1.785 1.783l-.174.174 3.057 3.05c.017-.012.033-.015.04-.026.796-1.145 1.174-2.381.832-3.778-.02-.082-.005-.206.047-.266.858-1.004 1.724-2 2.588-3 .253-.292.501-.586.754-.881L8.931 2.01c-.033.022-.057.035-.077.052-1.26 1.083-2.52 2.166-3.776 3.253-.095.082-.175.113-.302.08-.901-.23-1.792-.185-2.646.188-.41.18-.794.417-1.21.64l3.092 3.085Z"/></svg>
</template>

<script>
export default {
  name: 'PinIcon',
  props: {
    color: {
      type: String,
      default: '#B6BEC7'
    }
  }
}
</script>

<style>

</style>
