import { render, staticRenderFns } from "./WebWidgetExpandedConfig.vue?vue&type=template&id=c9ae5218&scoped=true&"
import script from "./WebWidgetExpandedConfig.vue?vue&type=script&lang=ts&"
export * from "./WebWidgetExpandedConfig.vue?vue&type=script&lang=ts&"
import style0 from "./WebWidgetExpandedConfig.vue?vue&type=style&index=0&id=c9ae5218&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c9ae5218",
  null
  
)

export default component.exports