import KeybeRepository from '@/shared/infrastructure/repositories/KeybeRepository'
import { HeadersBuilder } from '@/shared/infrastructure/http/HeadersBuilder'
import { GenericResponse } from '@/shared/infrastructure/http/GenericResponse'
import { dataToGetAppConfig } from '../../domain/AppDataController'

export default class AppConfigController extends KeybeRepository {
      // abort controllers
      getAppConfigController: AbortController
      baseUrl: string

      constructor () {
        super()

        // instance abort controllers
        this.getAppConfigController = new AbortController()

        this.baseUrl = process.env.VUE_APP_BILLING_URL
      }

      async getAppConfig (data: dataToGetAppConfig): Promise<GenericResponse> {
        const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
        const { appId } = data

        if (!appId) return

        const url = `${this.baseUrl}app-config/${appId}`

        const request = {
          url,
          data,
          headers,
          abortController: this.getAppConfigController
        }

        return await this.client.get(request)
      }
}
