












export default {
  name: 'CortexWhiteIcon'
}
