<template>
  <div></div>
</template>
<script>
import Vue from 'vue'

// Components
import ToastSuccess from './ToastSuccess.vue'
import ToastError from './ToastError.vue'
import ToastInfo from './ToastInfo.vue'

// Utils
import { EventBus } from '../../../../eventBus'

export default Vue.extend({

  created () {
    EventBus.$on('toast', (type, message) => {
      this.showToast(type, message)
    })
  },
  beforeDestroy () {
    EventBus.$off('toast')
  },
  methods: {
    showToast (type, message) {
      // Define the content object with the component, props and listeners

      if (type === 'success') {
        const success = {
          component: ToastSuccess,
          // Any prop can be passed, but don't expect them to be reactive
          props: {
            message: message,
            icon: false
          }
        }
        this.$toast(success, { position: 'top-right', closeButton: false })
      } else if (type === 'error') {
        const error = {
          component: ToastError,
          // Any prop can be passed, but don't expect them to be reactive
          props: {
            message: message,
            icon: false
          }
        }
        this.$toast(error, { position: 'top-right', closeButton: false })
      } else if (type === 'info') {
        const info = {
          component: ToastInfo,
          // Any prop can be passed, but don't expect them to be reactive
          props: {
            message: message,
            icon: false
          }
        }
        this.$toast(info, { position: 'top-right', closeButton: false })
      }
    }
  }
})
</script>
