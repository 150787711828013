import { BikyCatalogConfig, BikyConfig, BikyFile, BikyKnowledgeFragment, BikyMessageError, BikyPronunciation, BikyWebsite } from '@/modules/bky/domain/BikyConfig'
import { CreateBikyMessageErrorDto, CreateWebsiteDto, IBikyConfigController, UpdateBikyMessageErrorDto, createFileDTO, createKnowledgeBaseDTO, createPronunciationDTO, dataToGetTestAudio, deleteBikyMessageErrorDTO, deleteFileDTO, deleteKnowledgeBaseDTO, deletePronunciationDTO, deleteWebsiteDTO, getBikyMessageErrorsDTO, getConfigDTO, getFilesDTO, getKnowledgeBaseDTO, getWebsitesDTO, updateConfigDTO, updateKnowledgeBaseDTO, updatePronunciationDTO } from '@/modules/bky/domain/BikyConfigController'
import { BikyConfigRepository } from '@/modules/bky/infrastructure/repositories/BikyConfig.repository'

export default class BikyConfigController implements IBikyConfigController {
    repository: BikyConfigRepository

    constructor () {
      this.repository = new BikyConfigRepository()
    }

    async getMyKeybeCatalogConfig (data: getConfigDTO): Promise<BikyCatalogConfig> {
      const response = await this.repository.getMyKeybeCatalogConfig(data)
      if (!response.status) {
        return null
      }

      return response?.data || null
    }

    async syncMyKeybe (data: getConfigDTO): Promise<boolean> {
      const response = await this.repository.syncMyKeybe(data)
      return response.status || false
    }

    async unSyncMyKeybe (data: getConfigDTO): Promise<boolean> {
      const response = await this.repository.unsyncMyKeybe(data)
      return response.status || false
    }

    async getAudioDemoUrl (data: dataToGetTestAudio): Promise<string> {
      const response = await this.repository.getAudioDemoUrl(data)
      if (!response.status) {
        return ''
      }
      return response?.data?.url || ''
    }

    async getBikyConfig (data: getConfigDTO): Promise<BikyConfig | null> {
      const response = await this.repository.getBikyConfig(data)
      if (!response.status) {
        return null
      }

      if (!response?.data?.config) {
        await this.createBikyDefaultConfig(data)
        const newResponse = await this.repository.getBikyConfig(data)
        if (!newResponse.status) {
          return null
        }
        return newResponse?.data?.config || null
      }

      return response?.data?.config || null
    }

    async createBikyDefaultConfig (data: getConfigDTO): Promise<boolean> {
      const response = await this.repository.createBikyDefaultConfig(data)
      return response.status || false
    }

    async updateBikyConfig (data: updateConfigDTO): Promise<boolean> {
      const response = await this.repository.updateBikyConfig(data)
      return response.status || false
    }

    async getPronunciations (data: getConfigDTO): Promise<BikyPronunciation[]> {
      const response = await this.repository.getPronunciations(data)
      if (!response.status) {
        return []
      }

      return response?.data?.config || []
    }

    async createPronunciation (data: createPronunciationDTO): Promise<boolean> {
      const response = await this.repository.createPronunciation(data)
      return response.status || false
    }

    async updatePronunciation (data: updatePronunciationDTO): Promise<boolean> {
      const response = await this.repository.updatePronunciation(data)
      return response.status || false
    }

    async deletePronunciation (data: deletePronunciationDTO): Promise<boolean> {
      const response = await this.repository.deletePronunciation(data)
      return response.status || false
    }

    async getWebsites (data: getWebsitesDTO): Promise<BikyWebsite[]> {
      const response = await this.repository.getWebsites(data)
      if (!response.status) {
        return []
      }

      return response?.data?.websites || []
    }

    async createWebsite (data: CreateWebsiteDto): Promise<boolean> {
      const response = await this.repository.createWebsite(data)
      return response.status || false
    }

    async deleteWebsite (data: deleteWebsiteDTO): Promise<boolean> {
      const response = await this.repository.deleteWebsite(data)
      return response.status || false
    }

    // files
    async getFiles (data: getFilesDTO): Promise<BikyFile[]> {
      const response = await this.repository.getFiles(data)
      if (!response.status) {
        return []
      }
      return response?.data?.files || []
    }

    async createFile (data: createFileDTO): Promise<boolean> {
      const response = await this.repository.createFile(data)
      return response.status || false
    }

    async deleteFile (data: deleteFileDTO): Promise<boolean> {
      const response = await this.repository.deleteFile(data)
      return response.status || false
    }

    // knowledge base
    async getKnowledgeBase (data: getKnowledgeBaseDTO): Promise<{
      documents: BikyKnowledgeFragment[],
      count: number,
  }> {
      const response = await this.repository.getKnowledgeBase(data)
      if (!response.status) {
        return {
          documents: [],
          count: 0
        }
      }
      const documents = response?.data?.documents || []
      const count = response?.data?.count || 0
      return {
        documents,
        count
      }
    }

    async createKnowledgeBase (data: createKnowledgeBaseDTO): Promise<boolean> {
      const response = await this.repository.createKnowledgeBase(data)
      return response.status || false
    }

    async updateKnowledgeBase (data: updateKnowledgeBaseDTO): Promise<boolean> {
      const response = await this.repository.updateKnowledgeBase(data)
      return response.status || false
    }

    async deleteKnowledgeBase (data: deleteKnowledgeBaseDTO): Promise<boolean> {
      const response = await this.repository.deleteKnowledgeBase(data)
      return response.status || false
    }

    // biky message errors
    async getBikyMessageErrors (data: getBikyMessageErrorsDTO): Promise<BikyMessageError[]> {
      const response = await this.repository.getBikyMessageErrors(data)
      if (!response.status) {
        return []
      }
      return response?.data?.bikyMessageErrors || []
    }

    async createBikyMessageError (data: CreateBikyMessageErrorDto): Promise<boolean> {
      const response = await this.repository.createBikyMessageError(data)
      return response.status || false
    }

    async updateBikyMessageError (data: UpdateBikyMessageErrorDto): Promise<boolean> {
      const response = await this.repository.updateBikyMessageError(data)
      return response.status || false
    }

    async deleteBikyMessageError (data: deleteBikyMessageErrorDTO): Promise<boolean> {
      const response = await this.repository.deleteBikyMessageError(data)
      return response.status || false
    }

    abortAll (): void {
      this.repository.abortAll()
    }
}
