















































import WebWidgetConfig from './WebWidgetConfig'
export default WebWidgetConfig
