import { GenericResponse } from '@/models/apis/GenericResponse'
import { SpaceConfig, Spaces } from '../domain/Spaces'
import { ISpacesController, addUserToSpaceDTO, dataToGetSpaces, defaultSpaceDTO, spaceCreateDTO, spaceDeleteDTO, spaceUpdateDTO, removeUserFromSpaceDTO } from '../domain/SpacesController'
import SpacesRepository from './Spaces.repository'

export default class SpacesController implements ISpacesController {
  repository: SpacesRepository

  constructor () {
    this.repository = new SpacesRepository()
  }

  dispose (): void {
    this.repository.dispose()
  }

  async setDefaultInbox (data: defaultSpaceDTO): Promise<boolean> {
    const response: GenericResponse = await this.repository.setDefaultInbox(data)
    return response.status || false
  }

  async getInbox (data: dataToGetSpaces): Promise<Spaces[]> {
    const response: GenericResponse = await this.repository.getInbox(data)
    if (!response.status) {
      return []
    }

    return response.data
  }

  async createInbox (inbox: spaceCreateDTO): Promise<Spaces> {
    const response: GenericResponse = await this.repository.createInbox(inbox)
    if (!response.status) {
      return null
    }

    return response.data
  }

  async updateInbox (inbox: spaceUpdateDTO): Promise<Spaces> {
    const response: GenericResponse = await this.repository.updateInbox(inbox)

    if (!response.status) {
      return null
    }
    return response.data
  }

  async deleteInbox (inbox: spaceDeleteDTO): Promise<boolean> {
    const response: GenericResponse = await this.repository.deleteInbox(inbox)
    return response.status
  }

  async addUserToInbox (inbox: addUserToSpaceDTO): Promise<Spaces> {
    const response: GenericResponse = await this.repository.addUserToInbox(inbox)
    if (!response.status) {
      return null
    }

    return response.data
  }

  async removeUserFromInbox (inbox: removeUserFromSpaceDTO): Promise<Spaces> {
    const response: GenericResponse = await this.repository.removeUserFromInbox(inbox)
    if (!response.status) {
      return null
    }

    return response.data
  }

  processSpaces (spaces: any[]): SpaceConfig[] {
    const resultArray: SpaceConfig[] = []

    spaces.forEach((space: any) => {
      const mergedObject: SpaceConfig = {
        _id: space._id,
        appUUID: space.appUUID,
        active: space.active,
        name: space.name,
        key: space.key,
        default: space.default,
        autoassignationType: space?.autoassignationType,
        isAutoassignActive: space?.isAutoassignActive,
        maxActiveConversationsNumberPerAdvisor: space?.maxActiveConversationsNumberPerAdvisor,
        autoClose: {
          afterMinutes: space?.autoClose?.afterMinutes,
          excludedChannels: space?.autoClose?.excludedChannels
        },
        reassignForAdviserInactiveMinutes: space?.reassignForAdviserInactiveMinutes,
        reassignForUserInactiveMinutesWithBot: space?.reassignForUserInactiveMinutesWithBot,
        users: space?.users,
        useMemory: space?.useMemory,
        useMemoryOffline: space?.useMemoryOffline,
        inactivitySeconds: space?.inactivitySeconds,
        inactivityMessage: space?.inactivityMessage,
        workingHours: {
          message: space?.workingHours?.message,
          days: space?.workingHours?.days
        },
        messageOnTransfer: space?.messageOnTransfer,
        line: space?.line
      }
      resultArray.push(mergedObject)
    })
    const uniqueResultArray: SpaceConfig[] = resultArray.filter((config, index, self) =>
      index === self.findIndex((c) => c.key === config.key)
    )

    return uniqueResultArray
  }

  getDefaultValues (space: SpaceConfig): SpaceConfig {
    // const defaultDays = Array.from({ length: 7 }, (_, i) => ({
    //   code: i,
    //   isClosed: false,
    //   schedules: [
    //     {
    //       from: '07:00',
    //       until: '20:00'
    //     }
    //   ],
    //   isOpen: true
    // }))
    return {
      ...space,
      autoassignationType: space?.autoassignationType,
      isAutoassignActive: space?.isAutoassignActive,
      maxActiveConversationsNumberPerAdvisor: space?.maxActiveConversationsNumberPerAdvisor,
      autoClose: {
        afterMinutes: space?.autoClose?.afterMinutes,
        excludedChannels: space?.autoClose?.excludedChannels
      },
      reassignForAdviserInactiveMinutes: space?.reassignForAdviserInactiveMinutes,
      reassignForUserInactiveMinutesWithBot: space.reassignForUserInactiveMinutesWithBot,
      users: space?.users,
      useMemory: space?.useMemory,
      useMemoryOffline: space?.useMemoryOffline,
      inactivitySeconds: space?.inactivitySeconds,
      inactivityMessage: space?.inactivityMessage,
      workingHours: {
        message: space?.workingHours?.message,
        days: space?.workingHours?.days
      }
    }
  }
}
