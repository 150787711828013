






















































































































import WebWidgetCollapsedConfig from './WebWidgetCollapsedConfig'
export default WebWidgetCollapsedConfig
