import { debounce } from 'lodash'
import { EventBus } from '@/eventBus'
import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import AppConfigService from '@/components/configuration/Services/AppConfigService'
import KBTableCommission from '../KBTableCommission/KBTableCommission.vue'
import KBTableCommissionUser from '../Users/KBTableCommissionUsers.vue'
import CreateUserCommission from '../Users/CreateUserCommission.vue'
import CreateCategoryCommission from '../Category/CreateCategoryCommission.vue'
import KBSearch from '@/shared/infrastructure/ui/inputs/text/KBSearch.vue'
import { CDPFieldsEnums } from '@/modules/CDP/shared/contacts/domain/enums/CDPFieldsEnums'

export default {
  name: 'CommissionsConfigMain',
  components: {
    KBTableCommissionUser,
    KBTableCommission,
    CreateUserCommission,
    CreateCategoryCommission,
    KBSearch
  },
  data () {
    return {
      search: '',
      KeybeIconType,
      loading: false,
      creating: false,
      newDomainDialog: false,
      headers: [
        { text: '', field: 'profilePic' },
        { text: this.$t(CDPFieldsEnums.NAME), field: CDPFieldsEnums.NAME },
        { text: this.$t('email'), field: 'email' },
        { text: this.$t('status'), field: 'active' },
        { text: this.$t('createdAt'), field: 'createdAt' },
        { text: '', field: 'actions' }
      ]
    }
  },
  computed: {
    onCategory: {
      get () {
        return this.$store.state.CommissionStore.onCategory || false
      },
      set (value) {
        this.$store.commit('CommissionStore/SET_EDITING_OBJECT', {
          key: 'onCategory',
          value
        })
      }
    },
    onCreate: {
      get () {
        return this.$store.state.CommissionStore.onCreate || false
      },
      set (value) {
        this.$store.commit('CommissionStore/SET_EDITING_OBJECT', {
          key: 'onCreate',
          value
        })
      }
    },
    onDetail: {
      get () {
        return this.$store.state.CommissionStore.onDetail || false
      },
      set (value) {
        this.$store.commit('CommissionStore/SET_EDITING_OBJECT', {
          key: 'onDetail',
          value
        })
      }
    }
  },
  async created () {
    this.onCreate = false
    this.onCategory = false
    this.onDetail = false
    this.search = null
  },
  watch: {
    onDetail () {
      this.search = null
    }
  },
  methods: {
    updateInput: debounce(function (e) {
      this.search = e
    }, 300),
    async getWizardReport () {
      this.loading = true
      const report = await AppConfigService.getWizardReport()
      if (report.statusCode === 200) {
        EventBus.$emit('toast', 'success', this.$t('wizardReportSuccess'))
      } else {
        EventBus.$emit('toast', 'error', this.$t('wizardReportError'))
      }
      this.loading = false
    }
  }
}
