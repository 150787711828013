import { mapState } from 'vuex'
import { EventBus } from '@/eventBus'
import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import KeybeText from '@/modules/DesignSystem/infrastructure/components/KeybeText/KeybeText.vue'
import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'
import KeybeButton from '@/modules/DesignSystem/infrastructure/components/KeybeButton/KeybeButton.vue'
import KBCheck from '@/shared/infrastructure/ui/inputs/select/KBCheck.vue'
import KeybeSelect from '@/modules/DesignSystem/infrastructure/components/KeybeSelect/KeybeSelect/KeybeSelect.vue'
import StatusUseCases from '@/modules/Statuses/application/StatusUseCases'
import StatusController from '../../controllers/Status.controller'
import EmitToast from '@/utils/EmitToast'
import { ActivityActionTypeEnum, ActivityLogModuleTypeEnum, dataToWriteActivityLog } from '@/modules/Config/ActivityLogs/domain/ActivityLogsController'
import { CDPFieldsEnums } from '@/modules/CDP/shared/contacts/domain/enums/CDPFieldsEnums'
import ActivityLogsUseCases from '@/modules/Config/ActivityLogs/application/ActivityLogsUseCases'
import ActivityLogsController from '@/modules/Config/ActivityLogs/infrastructure/controllers/ActivityLogs.controller'
export default {
  components: {
    KeybeText,
    KBCheck,
    KeybeButton,
    KeybeSelect,
    KeybeIcon
  },
  props: {
    statuses: {
      type: Array
    },
    status: {
      type: Object
    },
    localName: {
      type: String
    }
  },
  computed: {
    ...mapState('AppStore', ['selectedApp']),
    ...mapState('UserStore', ['token', 'user', 'isUserReadonly']),
    isUpdate () {
      return this.status && Object.keys(this.status).length > 0
    },
    statusName () {
      return this.status?.name
    },
    filteredStatuses () {
      if (this.isUpdate) return this.statuses.filter(stat => stat._id !== this.status._id)
      else return this.statuses
    }
  },
  mounted () {
    if (this.isUpdate) this.setStatus()
  },
  data () {
    return {
      KeybeIconType,
      name: '',
      dependencies: [],
      color: '',
      defaultRegister: false,
      showFilter: true,
      loading: false,
      type: 'hexa',
      dependency: {},
      selectedDependencies: [],
      rerender: 0,
      statusController: new StatusController(),
      activityLogsController: new ActivityLogsController(),
      CDPFieldsEnums
    }
  },
  methods: {
    setDefault (e) {
      let exists = false
      if (e) {
        this.statuses.forEach(status => {
          if (status.defaultRegister) exists = true
        })
        if (exists) {
          this.defaultRegister = false
          EventBus.$emit('toast', 'error', this.$t('thereIsAnotherStatusOnRegister'))
          this.rerender++
        }
      }
    },
    submit () {
      this.dependencies = this.selectedDependencies.map(depend => depend._id)
      if (this.isUpdate) this.updateStatus()
      else this.createStatus()
    },
    removeDependencies (e) {
      this.selectedDependencies = this.selectedDependencies.filter(depend => depend._id !== e._id)
    },
    setStatus () {
      // eslint-disable-next-line no-undef
      this.name = structuredClone(this.status.name)
      // eslint-disable-next-line no-undef
      this.color = structuredClone(this.status?.color)
      // eslint-disable-next-line no-undef
      this.defaultRegister = structuredClone(this.status?.defaultRegister)
      // eslint-disable-next-line no-undef
      this.showFilter = structuredClone(this.status?.showFilter)
      if (this.status?.dependencies?.length > 0) {
        // eslint-disable-next-line no-undef
        this.selectedDependencies = structuredClone(this.status?.dependencies)
      }
    },
    setDependencies (e) {
      if (!this.selectedDependencies.includes(e)) {
        this.selectedDependencies.push(e)
        this.dependency = {}
      }
    },
    async createStatus () {
      try {
        if (!this.name) {
          EventBus.$emit('toast', 'error', this.$t('nameRequired'))
          this.loading = false
          return
        }

        const data = {
          app: this.selectedApp?.uuid,
          token: this.token,
          createInput: {
            name: this.name,
            color: this.color || '#FFFFFF',
            defaultRegister: this.defaultRegister,
            showFilter: this.showFilter,
            inactive: false,
            dependencies: this.dependencies
          }
        }
        const response = await StatusUseCases.createStatus(this.statusController, data)
        //  @ts-ignore
        if (response?._id) {
          await this.writeActivityLogCreate()
          this.$emit('close')
        } else EmitToast.emitErrors(response)
      } catch (e) {
        console.error(e)
      }
    },
    async writeActivityLogCreate (): Promise<void> {
      const data: dataToWriteActivityLog = {
        token: this.token,
        updateInput: {
          appUUID: this.selectedApp?.uuid,
          module: ActivityLogModuleTypeEnum.CDP,
          action: ActivityActionTypeEnum.STATUS_CREATE,
          user: {
            id: this.user.uuid,
            name: this.user.name + ' ' + this.user.lastName
          },
          payload: {
            field: CDPFieldsEnums.NAME,
            newValue: this.name,
            oldValue: ''
          }
        }
      }
      this.writeActivityLog(data)
    },
    async writeActivityLog (data: dataToWriteActivityLog): Promise<void> {
      await ActivityLogsUseCases.writeActivityLog(this.activityLogsController, data)
    },
    async updateStatus () {
      try {
        if (!this.name) {
          EventBus.$emit('toast', 'error', this.$t('nameRequired'))
          this.loading = false
          return
        }

        const data = {
          app: this.selectedApp?.uuid,
          token: this.token,
          id: this.status?._id,
          updateInput: {
            id: this.status?._id,
            name: this.name,
            color: this.color || '#FFFFFF',
            defaultRegister: this.defaultRegister,
            showFilter: this.showFilter,
            inactive: false,
            dependencies: this.dependencies
          }
        }
        await this.writeActivityLogUpdate(data.updateInput)
        const response = await StatusUseCases.updateStatus(this.statusController, data)
        //  @ts-ignore
        if (response?._id) {
          this.$emit('close')
        } else EmitToast.emitErrors(response)
      } catch (e) {
        console.error(e)
      }
    },
    async writeActivityLogUpdate (updateInput): Promise<void> {
      Object.keys(updateInput).forEach(async (key: string) => {
        if (updateInput[key] !== this.status[key] && key !== 'id') {
          if (key === 'dependencies') {
            const diffDependencies = updateInput.dependencies.filter(dependency => !this.status.dependencies.includes(dependency))
            if (!diffDependencies.length) return
          }
          const data: dataToWriteActivityLog = {
            token: this.token,
            updateInput: {
              appUUID: this.selectedApp?.uuid,
              module: ActivityLogModuleTypeEnum.CDP,
              action: ActivityActionTypeEnum.STATUS_UPDATE,
              user: {
                id: this.user.uuid,
                name: this.user.name + ' ' + this.user.lastName
              },
              payload: {
                field: key,
                newValue: updateInput[key],
                oldValue: this.status[key]
              }
            }
          }
          this.writeActivityLog(data)
        }
      })
    }
  },
  watch: {
    localName () {
      if (this.localName) this.name = this.localName
    }
  }
}
