<template>
  <svg width="16"
       height="15"
       viewBox="0 0 16 15"
       fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <path d="M3.13394 5.82501C3.03688 5.91621 2.97829 5.96897 2.92321 6.02441C2.24729 6.70397 1.57138 7.38264 0.898086 8.0631C0.747689 8.21511 0.575431 8.31079 0.368197 8.23568C0.183698 8.16861 0.04904 8.03181 0.00881744 7.82168C-0.0349028 7.59367 0.0910114 7.43898 0.230916 7.29501C0.801027 6.70755 1.37464 6.12455 1.9465 5.53977C2.36883 5.10789 2.7903 4.67422 3.21526 4.24412C3.53616 3.91864 3.77575 3.91864 4.09578 4.24412C5.09173 5.25989 6.0868 6.27656 7.08012 7.29501C7.35468 7.57668 7.38267 7.80379 7.1868 8.05953C7.01629 8.28128 6.75747 8.32241 6.53187 8.15788C6.46104 8.10692 6.39634 8.04522 6.33425 7.98263C5.6837 7.32989 5.03489 6.67625 4.38521 6.02351C4.33012 5.96807 4.27154 5.91621 4.17448 5.82501L4.17448 6.12992C4.17448 10.176 4.17448 9.95097 4.1736 13.9971C4.1736 11.7262 4.16923 14.2063 4.14825 14.3073C4.09053 14.5827 3.92702 14.7151 3.65683 14.716C3.39538 14.7169 3.2205 14.5765 3.16454 14.3065C3.14356 14.2054 3.13743 14.0999 3.13743 13.9971C3.13656 9.95812 3.13656 10.1912 3.13656 6.15227L3.13656 5.82501L3.13394 5.82501Z"
          fill="#8898A9"/>
    <path d="M12.5698 13.1646C12.6668 13.0734 12.7254 13.0215 12.7805 12.9661C13.4302 12.3133 14.0798 11.6597 14.7287 11.0061C15.0434 10.6895 15.3163 10.6493 15.5445 10.8862C15.7972 11.1482 15.7666 11.3977 15.4395 11.7321C14.4611 12.7336 13.4818 13.7351 12.5016 14.7356C12.1605 15.0844 11.9454 15.0879 11.6114 14.7473C10.6155 13.7315 9.62039 12.7148 8.62794 11.6964C8.29742 11.3575 8.31665 11.0257 8.67079 10.8085C8.8308 10.7101 8.98907 10.7065 9.14297 10.812C9.22691 10.8701 9.30473 10.939 9.37731 11.0114C10.0795 11.716 10.7798 12.4215 11.5327 13.1798L11.5327 12.8758C11.5327 8.81446 11.531 4.75405 11.5388 0.692746C11.5388 0.512124 11.5834 0.282323 11.6962 0.159822C11.9769 -0.145983 12.4893 0.0140732 12.5444 0.411083C12.5584 0.513912 12.5698 0.617636 12.5698 0.721358C12.5706 4.76031 12.5706 8.79836 12.5706 12.8373L12.5706 13.1646L12.5698 13.1646Z"
          fill="#8898A9"/>
    <circle cx="3.5"
            cy="1.5"
            r="1.5"
            fill="#3AF69B"/>
  </svg>
</template>
<script>
export default {
  name: 'RecentConversationsIcon',
  props: {
    width: {
      type: Number,
      default: 17
    },
    height: {
      type: Number,
      default: 14
    },
    color: {
      type: String,
      default: '#B6BEC7'
    }
  }
}
</script>
