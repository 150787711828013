import store from '@/store'

export default class FilesRepository {
  static async getFileBlob (url: string): Promise<Blob | undefined> {
    try {
      const token = store.getters['UserStore/getToken']

      const response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      const blob = await response.blob()

      return blob
    } catch (error) {
      // throw new Error(error)
      return undefined
    }
  }

  static async getPng (url: string) {
    try {
      const response = await fetch(url)
      const blob = await response.blob() // jpg

      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')
      const img = document.createElement('img')
      img.src = URL.createObjectURL(blob) // image/jpeg blob: ....
      document.body.appendChild(img)
      img.onload = () => {
        canvas.width = img.width
        canvas.height = img.height
        ctx.drawImage(img, 0, 0)
        canvas.toBlob((blob) => {
          // copy the blob to clipboard
          // eslint-disable-next-line no-undef
          const item = new ClipboardItem({ 'image/png': blob }) // image/png blob: ....
          navigator.clipboard.write([item])
          document.body.removeChild(img)
        })
        return true
      }
    } catch {
      throw new Error('Error')
    }
  }
}
