import axios from 'axios'
import store from '@/store'
import { RestResponseFactory } from '@/models/apis/RestResponse'
import { StatusCodes } from '@/models/apis/StatusCodes'

interface IConsumptionInteface {
  appUUID?: string,
  dateFrom?: string,
  dateTo?: string,
  chargeType?: string,
  plan?: string
}

export default class ConsumptionService {
  static async getConsumption (payload: IConsumptionInteface) {
    try {
      const token = store.getters['UserStore/getV4Token']
      const { uuid } = store.getters['AppStore/getSelectedApp']
      payload.appUUID = uuid
      const url = `${process.env.VUE_APP_CONSUMPTION_URL}`
      const response = await axios.post(url, payload, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      return new RestResponseFactory().process({ response })
    } catch (error) {
      return new RestResponseFactory().process({ response: { status: StatusCodes.HTTP_409_CONFLICT, message: 'Failed with transaction, Try again' } })
    }
  }
}
