<template>
  <svg :width="width"
       :height="height"
       viewBox="0 0 12 11"
       fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <path d="M11.725 2.23008L10.4613 0.281445C10.3488 0.107422 10.1481 0 9.93416 0H2.06498C1.85105 0 1.65035 0.107422 1.53787 0.281445L0.271916 2.23008C-0.380909 3.23555 0.196929 4.63418 1.41656 4.79531C1.50478 4.80605 1.59521 4.8125 1.68343 4.8125C2.25906 4.8125 2.77073 4.56758 3.12141 4.18945C3.47208 4.56758 3.98375 4.8125 4.55938 4.8125C5.13502 4.8125 5.64669 4.56758 5.99736 4.18945C6.34804 4.56758 6.85971 4.8125 7.43534 4.8125C8.01318 4.8125 8.52265 4.56758 8.87332 4.18945C9.2262 4.56758 9.73567 4.8125 10.3113 4.8125C10.4017 4.8125 10.4899 4.80605 10.5782 4.79531C11.8022 4.63633 12.3823 3.2377 11.7272 2.23008H11.725ZM10.6686 5.47637H10.6664C10.5495 5.49141 10.4304 5.5 10.3091 5.5C10.0356 5.5 9.77316 5.45918 9.52835 5.38613V8.25H2.47079V5.38398C2.22377 5.45918 1.95911 5.5 1.68563 5.5C1.56433 5.5 1.44303 5.49141 1.32614 5.47637H1.32393C1.23351 5.46348 1.14529 5.44844 1.05927 5.42695V8.25V9.625C1.05927 10.3834 1.69225 11 2.47079 11H9.52835C10.3069 11 10.9399 10.3834 10.9399 9.625V8.25V5.42695C10.8516 5.44844 10.7634 5.46562 10.6686 5.47637Z"
          :fill="color"/>
  </svg>
</template>
<script>
export default {
  name: 'BrandIcon',
  props: {
    width: {
      type: Number,
      default: 12
    },
    height: {
      type: Number,
      default: 11
    },
    color: {
      type: String,
      default: '#B6BEC7'
    }
  }
}
</script>
