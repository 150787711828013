export const EventsStore = {
  namespaced: true,
  state: {
    ticketsValue: [],
    ticketsValueFormatted: []
  },
  mutations: {
    SET_TICKETS_VALUE (state, tickets) {
      state.ticketsValue = tickets
    },
    SET_TICKETS_VALUE_FORMATTED (state, tickets) {
      state.ticketsValueFormatted = tickets
    }
  },
  actions: {}
}
