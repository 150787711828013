<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       fill="none"
       viewBox="0 0 16 18"
       :class="iconClass"><path :fill="color"
                                d="M7.287 1.14c0-.193-.005-.373 0-.55.01-.334.249-.587.549-.59.314-.004.553.22.578.557.014.193.002.389.002.58.332.072.64.124.939.205 1.78.484 2.998 1.625 3.724 3.293.318.73.411 1.52.406 2.317-.006.861.01 1.719.27 2.552a5.74 5.74 0 0 0 1.506 2.444c.47.456.587 1.026.384 1.64-.19.577-.625.897-1.213 1.012-.113.023-.233.02-.35.02-4.143 0-8.286-.003-12.428.005-.658 0-1.146-.266-1.478-.82-.336-.562-.167-1.42.306-1.873.87-.834 1.452-1.85 1.63-3.042.122-.807.122-1.633.154-2.451.09-2.312 1.725-4.414 3.95-5.05.352-.101.712-.168 1.07-.25h.001Zm.577 12.37h6.284c.183-.003.339-.054.425-.235a.418.418 0 0 0-.056-.48c-.176-.21-.357-.414-.53-.625-.72-.879-1.261-1.857-1.437-2.983-.117-.753-.138-1.523-.158-2.287-.038-1.45-.508-2.696-1.66-3.622-1.418-1.141-3.022-1.364-4.668-.671-1.712.72-2.59 2.119-2.723 3.97-.04.552-.018 1.11-.055 1.663-.081 1.224-.44 2.362-1.146 3.373-.295.422-.637.81-.948 1.222-.214.284-.08.6.27.663.084.015.172.011.258.011h6.143ZM7.84 18c-1.004.018-1.788-.717-2.068-1.522-.098-.284.01-.532.261-.65.268-.128.538-.038.711.212.162.234.304.51.526.669.52.373 1.27.148 1.589-.423.111-.2.186-.428.458-.495.366-.089.727.184.666.553a1.65 1.65 0 0 1-.242.601c-.44.689-1.081 1.038-1.9 1.055Z"/></svg>
</template>

<script>
export default {
  name: 'BellIcon',
  props: {
    color: {
      type: String,
      default: '#B6BEC7'
    },
    iconClass: {
      type: String,
      default: ''
    }
  }

}
</script>

<style>

</style>
