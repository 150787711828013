import { render, staticRenderFns } from "./NewEmailPage.vue?vue&type=template&id=22f2183e&scoped=true&"
import script from "./NewEmailPage.vue?vue&type=script&lang=ts&"
export * from "./NewEmailPage.vue?vue&type=script&lang=ts&"
import style0 from "./NewEmailPage.vue?vue&type=style&index=0&id=22f2183e&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "22f2183e",
  null
  
)

export default component.exports