import LottieAnimation from 'lottie-web-vue'

export default {
  name: 'BillingPlansCardsInfo',
  components: { LottieAnimation },
  props: ['dataCard'],
  data () {
    return {
    }
  },
  methods: {
  },
  mounted () {
  }
}
