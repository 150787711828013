




























































































import AutoassignAdvisorsConfig from './AutoassignAdvisorsConfig'

export default AutoassignAdvisorsConfig
