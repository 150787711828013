import { ExtradataTypeEnum } from '@/modules/Apps/domain/enums/ExtradataTypeEnum'
export default [
  { text: `extradata-${ExtradataTypeEnum.TEXT}`, value: ExtradataTypeEnum.TEXT },
  { text: `extradata-${ExtradataTypeEnum.CHECKBOX}`, value: ExtradataTypeEnum.CHECKBOX },
  { text: `extradata-${ExtradataTypeEnum.SELECT}`, value: ExtradataTypeEnum.SELECT },
  { text: `extradata-${ExtradataTypeEnum.SELECT_DEPENDANT}`, value: ExtradataTypeEnum.SELECT_DEPENDANT },
  { text: `extradata-${ExtradataTypeEnum.NUMERIC}`, value: ExtradataTypeEnum.NUMERIC },
  { text: `extradata-${ExtradataTypeEnum.RADIO}`, value: ExtradataTypeEnum.RADIO },
  { text: `extradata-${ExtradataTypeEnum.TEXTLONG}`, value: ExtradataTypeEnum.TEXTLONG },
  { text: `extradata-${ExtradataTypeEnum.EMAIL}`, value: ExtradataTypeEnum.EMAIL },
  { text: `extradata-${ExtradataTypeEnum.PHONE}`, value: ExtradataTypeEnum.PHONE },
  { text: `extradata-${ExtradataTypeEnum.MONEY}`, value: ExtradataTypeEnum.MONEY },
  { text: `extradata-${ExtradataTypeEnum.DATE}`, value: ExtradataTypeEnum.DATE },
  { text: `extradata-${ExtradataTypeEnum.DATE_TIME}`, value: ExtradataTypeEnum.DATE_TIME },
  { text: `extradata-${ExtradataTypeEnum.LABEL}`, value: ExtradataTypeEnum.LABEL },
  { text: `extradata-${ExtradataTypeEnum.TEXT_TAG}`, value: ExtradataTypeEnum.TEXT_TAG },
  { text: `extradata-${ExtradataTypeEnum.TEXT_ARRAY}`, value: ExtradataTypeEnum.TEXT_ARRAY }
]
