
import moment from 'moment'
import ApexCharts from 'apexcharts'
import { EventBus } from '@/eventBus'
import { textCamelize } from '@/utils/utils'
import MetricsService from '@/modules/metrics/application/services/MetricsService'
import { setDataSeries } from '@/modules/metrics/infrastructure/ui/components/utils/setDataSeries'
import { fillArray } from '@/modules/metrics/infrastructure/ui/components/utils/fillArray'
import { orderDataHeatMap } from '@/modules/metrics/infrastructure/ui/components/utils/orderDataHeatMap'
import DateRange from '@/modules/metrics/infrastructure/ui/components/generic/DateRange.vue'

export default {
  props: [
    'dateString', 'range', 'dataComponent', 'advisors'
  ],
  name: 'HeatMap',
  components: {
    DateRange
  },
  data () {
    return {
      series: [],
      itemData: [],
      chart: null
    }
  },
  computed: {
    closeModal: {
      get () {
        return this.$store.state.MetricsStore.closeModal || false
      },
      set (value) {
        this.$store.commit('MetricsStore/SET_EDITING_OBJECT', {
          key: 'closeModal',
          value
        })
      }
    },
    loading: {
      get () {
        return this.$store.state.MetricsStore.loading || false
      },
      set (value) {
        this.$store.commit('MetricsStore/SET_EDITING_OBJECT', {
          key: 'loading',
          value
        })
      }
    }
  },
  methods: {
    async getHeatMap (advisorList) {
      this.loading = true
      let params = {
        periodStart: moment().subtract(1, 'weeks').format('YYYY-MM-DD'),
        periodEnd: moment().format('YYYY-MM-DD')
      }
      if (this.range) {
        const { dateFrom, dateTo } = this.range
        params = {
          periodStart: dateFrom,
          periodEnd: dateTo
        }
      }
      const response = await MetricsService.getAverageTalkVolume(params, advisorList)
      if (response) {
        const { histogram, stats } = response.data
        this.itemData = histogram
        this.formatData(stats)
        this.loading = false
      } else {
        if (response?.data?.error) {
          this.loading = false
          EventBus.$emit('toast', 'error', this.$t(textCamelize(response.data.error)))
        } else {
          this.loading = false
          EventBus.$emit('toast', 'error', this.$t('unexpectedError'))
        }
      }
    },
    formatData (stats) {
      this.series = [
        {
          name: this.$t('monday'),
          data: []
        },
        {
          name: this.$t('tuesday'),
          data: []
        },
        {
          name: 'Miercoles',
          data: []
        },
        {
          name: this.$t('thursday'),
          data: []
        },
        {
          name: this.$t('friday'),
          data: []
        },
        {
          name: 'Sabado',
          data: []
        },
        {
          name: this.$t('sunday'),
          data: []
        }
      ]

      const lunes = []
      const martes = []
      const miercoles = []
      const jueves = []
      const viernes = []
      const sabado = []
      const domingo = []

      const days = {
        lunes, martes, miercoles, jueves, viernes, sabado, domingo
      }

      this.itemData = orderDataHeatMap(this.itemData, days)
      fillArray(lunes)
      fillArray(martes)
      fillArray(miercoles)
      fillArray(jueves)
      fillArray(viernes)
      fillArray(sabado)
      fillArray(domingo)

      this.series = setDataSeries(this.series, days)

      this.setHeatMap(stats)
    },
    setHeatMap (stats) {
      if (this.chart) {
        this.chart.destroy()
      }
      const options = {
        plotOptions: {
          heatmap: {
            shadeIntensity: 0.7,
            colorScale: {
              ranges: [
                {
                  from: -1,
                  to: 0,
                  name: this.$t('out'),
                  color: this.$vuetify.theme.dark ? '#000000' : '#b8efd4'
                },
                {
                  from: 1,
                  to: 5,
                  name: this.$t('low'),
                  color: this.$vuetify.theme.dark ? '#01E39B' : '#3AF69B'
                },
                {
                  from: 6,
                  to: 10,
                  name: this.$t('medium'),
                  color: this.$vuetify.theme.dark ? '#F6C960' : '#FFB200'
                },
                {
                  from: 11,
                  to: stats.max,
                  name: this.$t('high'),
                  color: this.$vuetify.theme.dark ? '#F7685B' : '#F7685B'
                }
              ]
            }
          }
        },
        dataLabels: {
          enabled: false
        },
        tooltip: {
          enabled: true,
          enabledOnSeries: undefined,
          shared: true,
          followCursor: false,
          intersect: false,
          inverseOrder: false,
          custom: undefined,
          fillSeriesColor: true,
          theme: false,
          style: {
            fontSize: '12px',
            fontFamily: undefined
          },
          y: {
            formatter: undefined,
            title: {
              formatter: (seriesName) => seriesName + ':'
            }
          },
          onDatasetHover: {
            highlightDataSeries: false
          }
        },
        stroke: {
          colors: this.$vuetify.theme.dark ? ['#171717'] : ['white'],
          width: 1
        },
        xaxis: {
          labels: {
            show: false
          }
        },
        series: this.series,
        chart: {
          toolbar: {
            show: false
          },
          height: 250,
          type: 'heatmap'
        }
      }
      this.chart = new ApexCharts(document.querySelector('#ConversationHeatMap'), options)
      this.chart.render()
    },
    validateAdvisor () {
      if (this.advisors.length === 0) {
        this.getHeatMap()
        return
      }
      if (this.advisors) {
        this.getHeatMap(this.advisors)
      }
    }
  },
  watch: {
    '$vuetify.theme.dark' () {
      this.getHeatMap()
    },
    range: {
      handler: function (newVal, oldVal) {
        if (this.range) {
          if ((newVal?.dateFrom && newVal?.dateTo) && (oldVal?.dateFrom && oldVal?.dateTo)) {
            if (newVal.dateFrom !== oldVal.dateFrom || newVal.dateTo !== oldVal.dateTo) {
              this.validateAdvisor()
            }
          }
        }
      }
    },
    advisors () {
      this.validateAdvisor()
    }
  },
  mounted () {
    this.getHeatMap()
  }
}
