









export default {
  name: 'TitleDetail',
  props: {
    title: {
      type: String,
      default: 'title'
    }
  },
  computed: {
    closeModal: {
      get () {
        return this.$store.state.MetricsStore.closeModal || false
      },
      set (value) {
        this.$store.commit('MetricsStore/SET_EDITING_OBJECT', {
          key: 'closeModal',
          value
        })
      }
    }
  }
}
