<template>
  <div
    class="KBButton"
    :class="`KBButton--${type} ${small? 'KBButton--small' : ''} ${noWrap ? 'KBButton--noWrap' : ''}`"
    @mouseover="hovered = true"
    @mouseleave="hovered = false"
    :style="styles"
    @click.stop="setClick">
    <span
      v-if="mdiIcon && !loading && !invertIcon"
      :class="`mdi ${icon}`"></span>
    <KeybeIcon
      v-if="!mdiIcon && icon && !loading && !invertIcon"
      class="mr-2 KBButton__icon"
      :color="iconColor ? Colors[iconColor] : Colors[color]"
      :icon="icon"/>
    <span
      v-if="!loading"
      :style="
        disabled
          ? `color: ${$vuetify.theme.dark ? 'white' : 'black'}`
          : textColor
            ? `color: ${textColor}`
            : `color: ${defaultColor}`">
      {{ text }}
    </span>
    <span
      v-if="mdiIcon && !loading && invertIcon"
      :class="`mdi ${icon}`"></span>
    <KeybeIcon
      v-if="!mdiIcon && icon && !loading && invertIcon"
      class="ml-2 KBButton__icon"
      :color="iconColor ? Colors[iconColor] : Colors[color]"
      :icon="icon"/>
    <div
      v-if="loading"
      class="loader-5"></div>
  </div>
</template>
<script>
import Colors from '@/utils/Colors'
import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'

export default {
  components: { KeybeIcon },
  props: {
    text: {
      type: String
    },
    icon: {
      type: String
    },
    type: {
      type: String,
      default: 'outlined'
    },
    color: {
      type: String,
      default: 'kbGreen'
    },
    width: {
      type: String,
      default: 'auto'
    },
    loading: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: false
    },
    noWrap: {
      type: Boolean,
      default: false
    },
    invertIcon: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    textColor: {
      type: String,
      required: false
    },
    iconColor: {
      type: String
    }
  },
  computed: {
    defaultColor () {
      return this.$vuetify.theme.dark
        ? this.type === 'filled'
          ? 'black'
          : this.$vuetify.theme.dark ? 'white' : 'black'
        : 'black'
    },
    mdiIcon () {
      return this.icon && this.icon.includes('mdi')
    },
    styles () {
      if (this.disabled) {
        switch (this.type) {
          case 'outlined':
            return `width:${this.width};border-color:${Colors.simpleGrey};color:${Colors.simpleGrey} !important;background:transparent; cursor: default !important`
          case 'filled':
            return `width:${this.width};color:white !important;background:${Colors.simpleGrey}; cursor: default !important`
          case 'flat':
            return `width:${this.width};color:${Colors.simpleGrey} !important;background:transparent; cursor: default !important`
          default:
            return `width:${this.width};border-color:${Colors[this.color]};color:${Colors.simpleGrey} !important;background:transparent; cursor: default !important`
        }
      } else {
        if (this.hovered) {
          switch (this.type) {
            case 'outlined':
              return `width:${this.width};border-color:${Colors[this.color]};color:white;background:${Colors[this.color]};`
            case 'filled':
              return `width:${this.width};color:white;background:${Colors[this.color]};filter:brightness(110%)`
            case 'flat':
              return `width:${this.width};color:${Colors[this.color]};background:rgba(255,255,255, 0.05)`
            default:
              return `width:${this.width};border-color:${Colors[this.color]};color:white;background:${Colors[this.color]};`
          }
        } else {
          switch (this.type) {
            case 'outlined':
              return `width:${this.width};border-color:${Colors[this.color]};color:${Colors[this.color]};background:transparent`
            case 'filled':
              return `width:${this.width};color:white;background:${Colors[this.color]}`
            case 'flat':
              return `width:${this.width};color:${Colors[this.color]};background:transparent`
            default:
              return `width:${this.width};border-color:${Colors[this.color]};color:${Colors[this.color]};background:transparent`
          }
        }
      }
    }
  },
  data () {
    return {
      Colors,
      hovered: false
    }
  },
  methods: {
    setClick () {
      if (!this.disabled) this.$emit('click')
    }
  }
}
</script>
<style scoped lang="scss">
.KBButton {
  height: 39px;
  border-radius: 9px;
  padding: 2px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.4s;

  &__icon {
    width: 16px;
    height: 16px;
  }

  span {
    font-weight: 300;
    font-size: 0.85rem !important;
    overflow: visible;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: inherit;
  }

  .mdi {
    margin-right: 5px;
  }
}

.KBButton--outlined {
  border-width: 0.5px;
  border-style: solid;
}

.KBButton--small {
  height: 30px;
  padding: 2px 10px;

  span {
    font-size: 11px;
  }
}

/* Loader 5 */
.loader-5 {
  height: 32px;
  width: 32px;
  -webkit-animation: loader-5-1 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
  animation: loader-5-1 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
}

@-webkit-keyframes loader-5-1 {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes loader-5-1 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader-5::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: auto;
  margin: auto;
  width: 8px;
  height: 8px;
  background: #FFF;
  border-radius: 50%;
  -webkit-animation: loader-5-2 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
  animation: loader-5-2 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
}

@-webkit-keyframes loader-5-2 {
  0% {
    -webkit-transform: translate3d(0, 0, 0) scale(1);
  }
  50% {
    -webkit-transform: translate3d(24px, 0, 0) scale(.5);
  }
  100% {
    -webkit-transform: translate3d(0, 0, 0) scale(1);
  }
}

@keyframes loader-5-2 {
  0% {
    transform: translate3d(0, 0, 0) scale(1);
  }
  50% {
    transform: translate3d(24px, 0, 0) scale(.5);
  }
  100% {
    transform: translate3d(0, 0, 0) scale(1);
  }
}

.loader-5::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: auto;
  bottom: 0;
  right: 0;
  margin: auto;
  width: 8px;
  height: 8px;
  background: #FFF;
  border-radius: 50%;
  -webkit-animation: loader-5-3 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
  animation: loader-5-3 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
}

@-webkit-keyframes loader-5-3 {
  0% {
    -webkit-transform: translate3d(0, 0, 0) scale(1);
  }
  50% {
    -webkit-transform: translate3d(-24px, 0, 0) scale(.5);
  }
  100% {
    -webkit-transform: translate3d(0, 0, 0) scale(1);
  }
}

@keyframes loader-5-3 {
  0% {
    transform: translate3d(0, 0, 0) scale(1);
  }
  50% {
    transform: translate3d(-24px, 0, 0) scale(.5);
  }
  100% {
    transform: translate3d(0, 0, 0) scale(1);
  }
}

.loader-5 span {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  height: 32px;
  width: 32px;
}

.loader-5 span::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: auto;
  right: 0;
  margin: auto;
  width: 8px;
  height: 8px;
  background: #FFF;
  border-radius: 50%;
  -webkit-animation: loader-5-4 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
  animation: loader-5-4 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
}

@-webkit-keyframes loader-5-4 {
  0% {
    -webkit-transform: translate3d(0, 0, 0) scale(1);
  }
  50% {
    -webkit-transform: translate3d(0, 24px, 0) scale(.5);
  }
  100% {
    -webkit-transform: translate3d(0, 0, 0) scale(1);
  }
}

@keyframes loader-5-4 {
  0% {
    transform: translate3d(0, 0, 0) scale(1);
  }
  50% {
    transform: translate3d(0, 24px, 0) scale(.5);
  }
  100% {
    transform: translate3d(0, 0, 0) scale(1);
  }
}

.loader-5 span::after {
  content: "";
  display: block;
  position: absolute;
  top: auto;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  width: 8px;
  height: 8px;
  background: #FFF;
  border-radius: 50%;
  -webkit-animation: loader-5-5 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
  animation: loader-5-5 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
}

@-webkit-keyframes loader-5-5 {
  0% {
    -webkit-transform: translate3d(0, 0, 0) scale(1);
  }
  50% {
    -webkit-transform: translate3d(0, -24px, 0) scale(.5);
  }
  100% {
    -webkit-transform: translate3d(0, 0, 0) scale(1);
  }
}

@keyframes loader-5-5 {
  0% {
    transform: translate3d(0, 0, 0) scale(1);
  }
  50% {
    transform: translate3d(0, -24px, 0) scale(.5);
  }
  100% {
    transform: translate3d(0, 0, 0) scale(1);
  }
}

</style>
