import KeybeRepository from '@/shared/infrastructure/repositories/KeybeRepository'
import { GenericResponse } from '@/shared/infrastructure/http/GenericResponse'
import { HeadersBuilder } from '@/shared/infrastructure/http/HeadersBuilder'
import type { dataToGetAdvisorApp, dataToGetMeeting, dataToPutEntitySchedule, dataToPutScheduler } from '../../domain/MeetingController'
import { getOptions, nonGetOptions } from '@/shared/infrastructure/http/HttpClient'

export default class MeetingRepository extends KeybeRepository {
  getSuggestionsAbortController: AbortController

  constructor () {
    super()

    this.getSuggestionsAbortController = new AbortController()
  }

  abortGetSuggestions () {
    this.getSuggestionsAbortController.abort()
    this.getSuggestionsAbortController = new AbortController()
  }

  async getCalendarAuth (data: dataToGetMeeting): Promise<GenericResponse> {
    const { appUUID, entityUUID } = data
    const url = `${process.env.VUE_APP_API_URL}/scheduler/google-calendar/app/${appUUID}/advisor/${entityUUID}/auth`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    const requestOptions: getOptions = {
      headers,
      url,
      abortController: this.getSuggestionsAbortController
    }

    return await this.client.get(requestOptions)
  }

  async getMeetingByAdvisor (data: dataToGetMeeting): Promise<GenericResponse> {
    const { appUUID, entityUUID } = data
    const url = `${process.env.VUE_APP_API_URL}/scheduler/entity/app/${appUUID}/advisor/${entityUUID}/`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    const requestOptions: getOptions = {
      headers,
      url,
      abortController: this.getSuggestionsAbortController
    }

    return await this.client.get(requestOptions)
  }

  async getAdvisorByApp (data: dataToGetAdvisorApp): Promise<GenericResponse> {
    const { appUUID } = data
    const url = `${process.env.VUE_APP_API_URL}/scheduler/entity/app/${appUUID}`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    const requestOptions: getOptions = {
      headers,
      url,
      abortController: this.getSuggestionsAbortController
    }

    return await this.client.get(requestOptions)
  }

  async putEntitySchedule (data: dataToPutEntitySchedule): Promise<GenericResponse> {
    const { appUUID, entityUUID } = data
    const url = `${process.env.VUE_APP_API_URL}/scheduler/entity/app/${appUUID}/advisor/${entityUUID}/schedule`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)

    const requestOptions: nonGetOptions = {
      headers,
      url,
      data: data.schedule,
      abortController: this.getSuggestionsAbortController
    }

    return await this.client.post(requestOptions)
  }

  async getScheduler (data: dataToGetAdvisorApp): Promise<GenericResponse> {
    const { appUUID } = data
    const url = `${process.env.VUE_APP_API_URL}/scheduler/app-config/${appUUID}`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    const requestOptions: getOptions = {
      headers,
      url,
      abortController: this.getSuggestionsAbortController
    }

    return await this.client.get(requestOptions)
  }

  async putScheduler (data: dataToPutScheduler): Promise<GenericResponse> {
    const { appUUID } = data
    const url = `${process.env.VUE_APP_API_URL}/scheduler/app-config/${appUUID}/`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)

    const requestOptions: nonGetOptions = {
      headers,
      url,
      data: { status: data.status },
      abortController: this.getSuggestionsAbortController
    }

    return await this.client.post(requestOptions)
  }

  async syncScheduler (data: dataToGetAdvisorApp): Promise<GenericResponse> {
    const { appUUID } = data
    const url = `${process.env.VUE_APP_API_URL}/scheduler/app-config/${appUUID}/sync`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)

    const requestOptions: nonGetOptions = {
      headers,
      url,
      abortController: this.getSuggestionsAbortController
    }

    return await this.client.post(requestOptions)
  }

  async disconnectAuth (data: dataToGetMeeting): Promise<GenericResponse> {
    const { appUUID, entityUUID } = data
    const url = `${process.env.VUE_APP_API_URL}/scheduler/google-calendar/app/${appUUID}/advisor/${entityUUID}/disconnect`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    const requestOptions: getOptions = {
      headers,
      url,
      abortController: this.getSuggestionsAbortController
    }

    return await this.client.get(requestOptions)
  }
}
