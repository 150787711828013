import { SpaceConfig } from '../domain/Spaces'
import { ISpacesController, addUserToSpaceDTO, dataToGetSpaces, defaultSpaceDTO, spaceCreateDTO, spaceDeleteDTO, spaceUpdateDTO, removeUserFromSpaceDTO } from '../domain/SpacesController'

export default class SpacesUseCases {
  static dispose (controller: ISpacesController): void {
    return controller.dispose()
  }

  static async getInbox (controller: ISpacesController, data: dataToGetSpaces): Promise<SpaceConfig[]> {
    return controller.getInbox(data)
  }

  static async createInbox (controller: ISpacesController, inbox: spaceCreateDTO): Promise<SpaceConfig> {
    return controller.createInbox(inbox)
  }

  static async updateInbox (controller: ISpacesController, inbox: spaceUpdateDTO): Promise<SpaceConfig> {
    return controller.updateInbox(inbox)
  }

  static async deleteInbox (controller: ISpacesController, inbox: spaceDeleteDTO): Promise<boolean> {
    return controller.deleteInbox(inbox)
  }

  static async addUserToInbox (controller: ISpacesController, inbox: addUserToSpaceDTO): Promise<SpaceConfig> {
    return controller.addUserToInbox(inbox)
  }

  static async removeUserFromInbox (controller: ISpacesController, inbox: removeUserFromSpaceDTO): Promise<SpaceConfig> {
    return controller.removeUserFromInbox(inbox)
  }

  static async setDefaultInbox (controller: ISpacesController, data: defaultSpaceDTO): Promise<boolean> {
    return controller.setDefaultInbox(data)
  }

  static processSpaces (space: any[], controller: ISpacesController): SpaceConfig[] {
    return controller.processSpaces(space)
  }

  static getDefaultValues (space: any, controller: ISpacesController): SpaceConfig {
    return controller.getDefaultValues(space)
  }
}
