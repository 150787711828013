export const LayoutStore = {
  namespaced: true,
  state: {
    listBoxWidth: null,
    workBoxWidth: null
  },
  mutations: {
    SET_LIST_BOX_WIDTH (state, width: number) {
      state.listBoxWidth = width
    },
    SET_WORK_BOX_WIDTH (state, width: number) {
      state.workBoxWidth = width
    }
  },
  actions: {
    setListBoxWidth ({ commit }, width: number) {
      commit('SET_LIST_BOX_WIDTH', width)
    },
    setWorkBoxWidth ({ commit }, width: number) {
      commit('SET_WORK_BOX_WIDTH', width)
    }
  }
}
