<template>
  <svg :width="width"
       :height="height"
       viewBox="0 0 12 13"
       fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <path d="M1.18463 2.60918C1.43306 2.3175 1.64126 2.04795 1.8746 1.80354C3.12278 0.490992 4.65258 -0.0843151 6.46199 0.0303445C9.20074 0.202334 11.3471 2.35069 11.673 4.94059C11.8138 6.06003 11.85 7.19355 11.9274 8.32104C11.9616 8.81387 12.019 9.30871 11.9938 9.79954C11.9546 10.5931 11.51 11.0789 10.7315 11.2378C9.4029 11.5084 8.07627 11.792 6.74059 12.0183C6.02045 12.141 5.29226 12.0465 4.57312 11.8896C3.51906 11.6592 2.45997 11.452 1.39886 11.2569C0.48762 11.09 0.017918 10.5378 0.0118833 9.59637C-0.00823246 6.63835 0.00283102 3.68134 0.00484259 0.723329C0.00484259 0.199315 0.21304 0.00016907 0.743089 0.00016907C0.926142 0.00016907 1.11322 -0.00385211 1.29225 0.0273272C1.58896 0.0786223 1.77 0.299895 1.77704 0.580509C1.78408 0.860118 1.63522 1.05222 1.35058 1.15682C1.28621 1.18096 1.19368 1.25237 1.19167 1.30367C1.17859 1.7261 1.18463 2.14953 1.18463 2.61119V2.60918ZM2.31312 4.50609H9.73381C9.19169 2.84856 7.82583 1.80555 6.01039 1.81058C4.1869 1.81561 2.79892 2.90689 2.31312 4.50609ZM2.13007 5.86994C2.01138 6.25213 2.03854 6.39093 2.23668 6.51967C3.47581 7.3243 4.38303 8.37635 4.69985 9.85989C4.75316 10.1103 4.89196 10.1747 5.13133 10.1455C5.13133 8.95971 5.13334 7.78797 5.1253 6.61623C5.1253 6.55789 5.04986 6.45933 4.99555 6.44726C4.04106 6.24811 3.08355 6.06003 2.12906 5.86893L2.13007 5.86994ZM6.9156 10.1485C7.18615 10.1767 7.32797 10.1154 7.37926 9.82468C7.63172 8.39144 8.53391 7.40577 9.67748 6.60416C10.0215 6.36277 10.0466 6.34768 9.96011 5.89206C9.94502 5.88804 9.92793 5.87798 9.91384 5.881C8.97846 6.06405 8.04509 6.25213 7.10871 6.42714C6.92565 6.46134 6.91359 6.56091 6.91459 6.70876C6.91861 7.76383 6.9166 8.8199 6.9166 9.87497C6.9166 9.96549 6.9166 10.057 6.9166 10.1485H6.9156Z"
          :fill="color"/>
  </svg>
</template>
<script>
export default {
  name: 'PrimaryIcon',
  props: {
    width: {
      type: Number,
      default: 12
    },
    height: {
      type: Number,
      default: 13
    },
    color: {
      type: String,
      default: '#B6BEC7'
    }
  }
}
</script>
