



















































import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import Colors from '@/shared/domain/colors/Color'
import { mapState } from 'vuex'
import AdvisersRepository from '@/modules/conversations/infrastructure/http/Advisers.repository'
import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'
import KeybeAvatar from '@/shared/infrastructure/ui/KeybeAvatar.vue'
import UserSearch from '@/shared/infrastructure/ui/UserSearch.vue'

export default {
  name: 'GroupConfigCard',
  props: {
    group: {
      type: String,
      required: true
    },
    users: {
      type: Array,
      required: true
    }
  },
  components: {
    KeybeIcon,
    KeybeAvatar,
    UserSearch
  },
  data () {
    return {
      KeybeIconType,
      Colors,
      showAdvisers: false
    }
  },
  methods: {
    getAdviserName (adviser) {
      if (adviser?.lastName) {
        return `${adviser?.name} ${adviser?.lastName}`
      }
      return adviser?.name || ''
    },
    getAdviserPhoto (adviser) {
      return adviser?.image || ''
    },
    async addAdvisorToGroup (adviser) {
      const hasGroup = this.users.find(user => user?.userId === adviser?.userId)
      if (hasGroup) {
        return
      }
      const newAdviser = {
        ...adviser,
        groups: [...adviser?.groups, this.group]
      }
      const response = await AdvisersRepository.updateAdviser(newAdviser)
      if (response) {
        this.$store.dispatch('AppStore/updateAdviser', newAdviser)
      }
    },
    async removeAdvisorFromGroup (adviser) {
      const newAdviser = {
        ...adviser,
        groups: adviser?.groups.filter(group => group !== this.group)
      }
      const response = await AdvisersRepository.updateAdviser(newAdviser)
      if (response) {
        this.$store.dispatch('AppStore/updateAdviser', newAdviser)
      }
    }
  },
  computed: {
    ...mapState('AppStore', ['advisers'])
  }
}
