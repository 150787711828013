import { GenericResponse } from '@/models/apis/GenericResponse'
import { StatusCodes } from '@/models/apis/StatusCodes'

export class RestResponse extends GenericResponse {
  constructor ({
    status,
    message,
    data,
    code
  }: {
        status: boolean;
        message: string;
        data: any;
        code: number;
    }) {
    super({ status, message, data, code })
  }
}

export class RestResponseFactory {
  verify ({ response }: { response: any }): RestResponse {
    if (response?.data?.status === 'ERROR') {
      return new RestResponse({
        status: false,
        message: response?.data || '',
        code: StatusCodes.HTTP_500_INTERNAL_SERVER_ERROR,
        data: response.data
      })
    }
    return new RestResponse({
      status: true,
      message: '',
      code: response.status,
      data: response.data
    })
  }

  process ({ response }: { response: any }): RestResponse {
    const code: number = response.status || response.statusCode || StatusCodes.HTTP_520_UNKNOWN_ERROR
    switch (code) {
      case StatusCodes.HTTP_200_OK:
        return new RestResponse({
          status: true,
          message: '',
          code: code,
          data: response.data
        })
      case StatusCodes.HTTP_201_CREATED:
        return this.verify({ response })
      case StatusCodes.HTTP_400_BAD_REQUEST:
        return new RestResponse({
          status: false,
          message: response.message || '',
          code: code,
          data: response.data
        })
      case StatusCodes.HTTP_500_INTERNAL_SERVER_ERROR:
        return new RestResponse({
          status: false,
          message: response.message || 'Ha ocurrido un error inesperado',
          code: code,
          data: response.data
        })
      case StatusCodes.HTTP_409_CONFLICT:
        return new RestResponse({
          status: false,
          message: response.message,
          code: code,
          data: response.data
        })
      default:
        return new RestResponse({
          status: false,
          message: 'Ha ocurrido un error inesperado',
          code: code,
          data: response.data
        })
    }
  }

  processCatchedError (error: any): GenericResponse {
    if (error.message === StatusCodes.HTTP_499_CLIENT_CLOSE_REQUEST.toString()) {
      return this.process({ response: { status: StatusCodes.HTTP_200_OK } })
    }
    return this.process({ response: { status: StatusCodes.HTTP_500_INTERNAL_SERVER_ERROR } })
  }
}
