import { ILocationController, citiesGetter, countriesGetter, dataToGetCities, dataToGetCountries, dataToGetStates, statesGetter } from '../../domain/LocationController'
import LocationRepository from '../repositories/Location.repository'
import LocationService from '../services/LocationService'
export default class LocationController implements ILocationController {
  repository: LocationRepository

  constructor () {
    this.repository = new LocationRepository()
  }

  abort (): void {
    this.repository.abortGetSuggestions()
  }

  async getCountries (data: dataToGetCountries): Promise<countriesGetter> {
    const response = await this.repository.getCountries(data)
    if (response?.status) return { status: true, countries: response.data.data }
    else return { status: false, message: response?.message }
  }

  async getStates (data: dataToGetStates) : Promise<statesGetter> {
    const response = await this.repository.getStates(data)
    if (response?.status) return { status: true, states: response.data.data }
    else return { status: false, message: response?.message }
  }

  async getCities (data: dataToGetCities) : Promise<citiesGetter> {
    const response = await this.repository.getCities(data)
    if (response?.status) return { status: true, cities: response.data.data }
    else return { status: false, message: response?.message }
  }

  async getTimezones () : Promise<any> {
    const response = await LocationService.getTimezones()
    return response
  }

  async getLanguages () : Promise<any> {
    const response = await LocationService.getLanguages()
    return response
  }
}
