import store from '@/store'
import { BikyConfig } from '../../domain/BikyConfig'

export default class BikyStoreController {
  static getConfig (): BikyConfig {
    return store.getters['BikyStore/getConfig']
  }

  static getBikyImage (): string {
    return store.getters['BikyStore/getBikyImage']
  }

  static setConfig (config): void {
    store.dispatch('BikyStore/setConfig', config)
  }

  static isBikyActive (): boolean {
    return store.getters['BikyStore/isBikyActive']
  }

  static getBikyLines (): Array<string> {
    return store.getters['BikyStore/getBikyLines']
  }
}
