import LottieAnimation from 'lottie-web-vue'

export default {
  name: 'OutboundImport',
  props: {
    segment: {
      type: String
    }
  },
  components: {
    LottieAnimation
  },
  data () {
    return {
      text: 1
    }
  },
  watch: {
  },
  computed: {
  },
  methods: {
  },
  mounted () {
    setInterval(() => {
      if (this.text === 1) {
        this.text = 2
      } else {
        this.text = 1
      }
    }, 10000)
  }
}
