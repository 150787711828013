









export default {
  name: 'PinFilledIcon',
  props: {
    color: {
      type: String,
      default: '#fff'
    }
  }
}
