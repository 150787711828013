import { ChatFilter } from '../domain/ChatFilter'
import { IChatFilterController, chatFilterCreateDTO, chatFilterUpdateDTO, dataToGetChatFilters, deleteFilterDTO, favoriteActionsDTO } from '../domain/ChatFilterController'

export default class ChatFilterUseCases {
  static getChatFilters (controller:IChatFilterController, data: dataToGetChatFilters): Promise<ChatFilter[]> {
    return controller.getChatFilters(data)
  }

  static createChatFilter (controller:IChatFilterController, data: chatFilterCreateDTO): Promise<ChatFilter> {
    return controller.createChatFilter(data)
  }

  static updateChatFilter (controller:IChatFilterController, data: chatFilterUpdateDTO): Promise<ChatFilter> {
    return controller.updateChatFilter(data)
  }

  static deleteChatFilter (controller:IChatFilterController, data: deleteFilterDTO): Promise<boolean> {
    return controller.deleteChatFilter(data)
  }

  static favoriteChatFilter (controller:IChatFilterController, data: favoriteActionsDTO): Promise<ChatFilter> {
    return controller.favoriteChatFilter(data)
  }

  static unfavoriteChatFilter (controller:IChatFilterController, data: favoriteActionsDTO): Promise<ChatFilter> {
    return controller.unfavoriteChatFilter(data)
  }
}
