import KeybeRepository from '@/shared/infrastructure/repositories/KeybeRepository'
import { GenericResponse } from '@/shared/infrastructure/http/GenericResponse'
import { HeadersBuilder } from '@/shared/infrastructure/http/HeadersBuilder'
import { getOptions, nonGetOptions } from '@/shared/infrastructure/http/HttpClient'
import { dataToGetExtraData, dataToCreateExtraData, dataToUpdateExtraData, dataToDeleteExtraData } from '../../domain/ExtraDataController'

export default class ExtraDataRepository extends KeybeRepository {
  getSuggestionsAbortController: AbortController

  constructor () {
    super()

    this.getSuggestionsAbortController = new AbortController()
  }

  async getExtraDataFields (data: dataToGetExtraData): Promise<GenericResponse> {
    const { app, typeEntity, search } = data

    const url = `${process.env.VUE_APP_APPS_URL}/${app}/extradata-custom/${typeEntity}`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    delete data.token
    const requestOptions: getOptions = {
      headers,
      url,
      abortController: this.getSuggestionsAbortController
    }

    if (search) {
      requestOptions.query = {
        search: search
      }
    }

    return await this.client.get(requestOptions)
  }

  async createExtraData (data: dataToCreateExtraData): Promise<GenericResponse> {
    const { app, createInput } = data

    const url = `${process.env.VUE_APP_APPS_URL}/${app}/extradata-custom`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    const requestOptions: nonGetOptions = {
      headers,
      data: createInput,
      url,
      abortController: this.getSuggestionsAbortController
    }

    return await this.client.post(requestOptions)
  }

  async updateExtraData (data: dataToUpdateExtraData): Promise<GenericResponse> {
    const { app, updateInput, id } = data

    const url = `${process.env.VUE_APP_APPS_URL}/${app}/extradata-custom/${id}`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    const requestOptions: nonGetOptions = {
      headers,
      data: updateInput,
      url,
      abortController: this.getSuggestionsAbortController
    }

    return await this.client.patch(requestOptions)
  }

  async deleteExtraData (data: dataToDeleteExtraData): Promise<GenericResponse> {
    const { app, typeEntity, id } = data

    const url = `${process.env.VUE_APP_APPS_URL}/${app}/extradata-custom/${typeEntity}/${id}`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    delete data.token
    const requestOptions: nonGetOptions = {
      headers,
      url,
      abortController: this.getSuggestionsAbortController
    }

    return await this.client.delete(requestOptions)
  }
}
