import { User, UserList } from 'src/components/models/User'

export const BusinessStore = {
  namespaced: true,
  state: {
    lastSavedPage: null,
    scrollPosition: null,
    users: [],
    lists: [],
    businessCount: null,
    importer: {
      businessId: '',
      file: null,
      headers: [],
      listId: '',
      listName: '',
      importerSelection: '',
      newList: '',
      persons: [],
      step: 1
    },
    selectedBusinessHeaders: [],
    allBusinessHeaders: [],
    orderedExtradata: [],
    orderedTags: [],
    contextMenuBusiness: {},
    advancedSearchFilter: {},
    businessFiltersActive: false
  },
  mutations: {
    SET_USERS (state, users: User[]) {
      state.users = users
    },
    SET_USERS_LISTS (state, lists: UserList[]) {
      state.lists = lists
    },
    SET_SELECTED_HEADERS (state, headers) {
      state.selectedBusinessHeaders = headers
    },
    SET_HEADERS (state, headers) {
      state.allBusinessHeaders = headers
    },
    SET_ORDERED_EXTRADATA (state, ordered) {
      state.orderedExtradata = ordered
    },
    SET_ORDERED_TAGS (state, ordered) {
      state.orderedTags = ordered
    },
    SET_LAST_PAGE (state, lastPage) {
      state.lastSavedPage = lastPage
    },
    SET_SCROLL_POSITION (state, position) {
      state.scrollPosition = position
    },
    SET_CONTEXT_MENU_BUSINESS (state, business) {
      state.contextMenuBusiness = business
    },
    SET_ADVANCED_SEARCH (state, advancedSearch) {
      state.advancedSearchFilter = advancedSearch
    },
    SET_FILTERS_ACTIVE (state, filtersActive) {
      state.businessFiltersActive = filtersActive
    },
    SET_BUSINESS_COUNT (state, count) {
      state.businessCount = count
    }
  },
  actions: {
    setContextMenuBusiness (context, business) {
      context.commit('SET_CONTEXT_MENU_BUSINESS', business)
    }
  }
}
