import KeybeRepository from '@/shared/infrastructure/repositories/KeybeRepository'
import { GenericResponse } from '@/shared/infrastructure/http/GenericResponse'
import { HeadersBuilder } from '@/shared/infrastructure/http/HeadersBuilder'
import { getOptions, nonGetOptions } from '@/shared/infrastructure/http/HttpClient'
import { dataToCreateSegment, dataToGetSegmentByName, dataToGetSegmentById, dataToGetSegments, dataToUpdateDeleteSegment } from '../../domain/SegmentsController'

export default class SegmentsRepository extends KeybeRepository {
  getSuggestionsAbortController: AbortController

  constructor () {
    super()

    this.getSuggestionsAbortController = new AbortController()
  }

  async getSegments (data: dataToGetSegments): Promise<GenericResponse> {
    const { app, typeEntity, offset, limit, search } = data

    const url = `${process.env.VUE_APP_SEGMENTS_HTTP}/${app}/segments/${typeEntity}`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    const query = {
      offset,
      limit,
      search
    }

    if (!query.search) delete query.search
    delete data.token
    const requestOptions: getOptions = {
      headers,
      query,
      url,
      abortController: this.getSuggestionsAbortController
    }

    return await this.client.get(requestOptions)
  }

  async getSegmentsById (data: dataToGetSegmentById): Promise<GenericResponse> {
    const { app, typeEntity, id } = data

    const url = `${process.env.VUE_APP_SEGMENTS_HTTP}/${app}/segments/${typeEntity}/${id}`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)

    delete data.token
    const requestOptions: getOptions = {
      headers,
      url,
      abortController: this.getSuggestionsAbortController
    }

    return await this.client.get(requestOptions)
  }

  async getSegmentByName (data: dataToGetSegmentByName): Promise<GenericResponse> {
    const { app, typeEntity, name } = data

    const url = `${process.env.VUE_APP_SEGMENTS_HTTP}/${app}/segments/${typeEntity}/name/${name}`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    delete data.token
    const requestOptions: getOptions = {
      headers,
      url,
      abortController: this.getSuggestionsAbortController
    }

    return await this.client.get(requestOptions)
  }

  async createSegment (data: dataToCreateSegment): Promise<GenericResponse> {
    const { app, createInput } = data

    const url = `${process.env.VUE_APP_SEGMENTS_HTTP}/${app}/segments`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    const requestOptions: nonGetOptions = {
      headers,
      data: createInput,
      url,
      abortController: this.getSuggestionsAbortController
    }

    return await this.client.post(requestOptions)
  }

  async updateSegment (data: dataToUpdateDeleteSegment): Promise<GenericResponse> {
    const { app, id } = data

    const url = `${process.env.VUE_APP_SEGMENTS_HTTP}/${app}/segments/${id}`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    delete data.token
    const requestOptions: nonGetOptions = {
      headers,
      data: data.data,
      url,
      abortController: this.getSuggestionsAbortController
    }

    return await this.client.patch(requestOptions)
  }

  async deleteSegment (data: dataToUpdateDeleteSegment): Promise<GenericResponse> {
    const { app, id } = data

    const url = `${process.env.VUE_APP_SEGMENTS_HTTP}/${app}/segments/${data?.data?.typeEntity}/${id}`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    delete data.token
    const requestOptions: nonGetOptions = {
      headers,
      url,
      abortController: this.getSuggestionsAbortController
    }

    return await this.client.delete(requestOptions)
  }

  abortGetSuggestions () {
    this.getSuggestionsAbortController.abort()
    this.getSuggestionsAbortController = new AbortController()
  }
}
