




































import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
// import Vue from 'vue'

import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'
import KBTime from '@/shared/infrastructure/ui/pickers/KBTime.vue'
import KBText from '@/shared/infrastructure/ui/inputs/text/KBText.vue'

// Vue.directive('click-outside', {
//   bind: function (el, binding, vnode) {
//     this.event = function (event) {
//       if (!(el === event.target || el.contains(event.target))) {
//         vnode.context[binding.expression](event)
//       }
//     }
//     document.body.addEventListener('click', this.event)
//   },
//   unbind: function (el) {
//     document.body.removeEventListener('click', this.event)
//   }
// })

export default {
  name: 'KBTimeSelector',
  components: {
    KBTime,
    KBText,
    KeybeIcon
  },
  props: {
    value: {
      type: String
    },
    BackgroundColor: {
      type: String
    }
  },
  data () {
    return {
      dialogKBTime: false,
      hours: null,
      time: null,
      KeybeIconType
    }
  },
  methods: {
    closeMenu () {
      this.dialogKBTime = false
    }
  },
  watch: {
    time () {
      if (this.time) {
        this.hours = this.time
        this.dialogKBTime = false
        this.$emit('input', this.time)
      }
    }
  },
  created () {
    this.hours = this.value
  }
}
