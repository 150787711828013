



















import { EventBus } from '@/eventBus'
export default {
  name: 'KeybeDropbox',
  props: {
    enabled: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      dragging: false
    }
  },
  methods: {
    onDrop (e) {
      const eventData = e.dataTransfer.getData('text/plain')
      if (eventData) {
        const object = JSON.parse(eventData)
        if (object?.id === 'CortexSuggestionMessage' || object?.id === 'TesseractSuggestionMessage') {
          EventBus.$emit('CortexSuggestionRow:edit', object.suggestion)
        }
      }
      if (!this.enabled) return
      this.$emit('dropData', e)
      this.dragging = false
    },
    onDragOver (e) {
      if (!this.enabled) return
      this.dragging = true
    },
    onDragLeave (e) {
      this.dragging = false
    }
  }
}
