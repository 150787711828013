import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'
import KBSwitch from '@/shared/infrastructure/ui/switch/KBSwitch.vue'
import { mapState } from 'vuex'
import { EventBus } from '@/eventBus'
import { formScript } from './formScript'
import KeybeSelect from '@/modules/DesignSystem/infrastructure/components/KeybeSelect/KeybeSelect/KeybeSelect.vue'
import WidgetTesseractUseCases from '@/modules/Config/WidgetTesseract/application/WidgetTesseractUseCases'
import WidgetTesseractController from '@/modules/Config/WidgetTesseract/infrastructure/WidgetTesseract.controller'
import {
  positionOptions,
  textAlingOptions,
  sizeTextOptions,
  typographyOptions,
  horizontalPositionOptions,
  verticalPositionOptions,
  lenguageOptions
} from '@/modules/Config/WidgetTesseract/domain/WidgetTesseract'
import { createFormDTO } from '@/modules/Config/WidgetTesseract/domain/WidgetTesseractController'
import KBColorPicker from '@/shared/infrastructure/ui/inputs/KBColorPicker.vue'
import KeybeText from '@/modules/DesignSystem/infrastructure/components/KeybeText/KeybeText.vue'
import KeybeButton from '@/modules/DesignSystem/infrastructure/components/KeybeButton/KeybeButton.vue'
import { ConfigurationTabsEnum } from '@/modules/configuration/application/enums/ConfigurationTabsEnum'
import ScreenDesktop from './Screens/ScreenDesktop/ScreenDesktop.vue'
import ScreenMobile from './Screens/ScreenMobile/ScreenMobile.vue'
import FieldsForm from '../FieldsForm/FieldsForm.vue'
import Colors from '@/utils/Colors'
import UserService from '@/services/UserService'
import SalesCard from '../SalesCard/SalesCard.vue'
import Template from '../Template/Template.vue'
import { v4 as uuidv4 } from 'uuid'

export default {
  name: 'CreateFormLanding',
  components: {
    KeybeIcon,
    KBSwitch,
    KeybeSelect,
    KBColorPicker,
    KeybeText,
    KeybeButton,
    ScreenDesktop,
    ScreenMobile,
    FieldsForm,
    SalesCard,
    Template
  },
  props: {
    dataTocreate: {
      type: Object,
      required: true
    },
    selectedType: {
      type: String,
      required: true
    },
    selectedApiKey: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      Colors,
      KeybeIconType,
      widgetTesseractController: new WidgetTesseractController(),
      apiKeys: [],
      loading: false,
      positionOptions: [
        { name: this.$t('left'), value: positionOptions.Left },
        { name: this.$t('center'), value: positionOptions.Center },
        { name: this.$t('right'), value: positionOptions.Right },
        { name: this.$t('top'), value: positionOptions.Top },
        { name: this.$t('bottom'), value: positionOptions.Bottom }
      ],
      textAlingOptions: [
        { name: this.$t('left'), value: textAlingOptions.Left },
        { name: this.$t('center'), value: textAlingOptions.Center },
        { name: this.$t('right'), value: textAlingOptions.Right },
        { name: this.$t('top'), value: textAlingOptions.Top },
        { name: this.$t('bottom'), value: textAlingOptions.Bottom }
      ],
      typographyOptions: [
        { name: 'Roboto', value: typographyOptions.Roboto },
        { name: 'Nunito', value: typographyOptions.Helvetica },
        { name: 'Open Sans', value: typographyOptions.OpenSans },
        { name: 'Arial', value: typographyOptions.Arial },
        { name: 'Poppins', value: typographyOptions.Poppins }
      ],
      lenguageOptions: [{ name: this.$t('english'), value: lenguageOptions.English }, { name: this.$t('spanish'), value: lenguageOptions.Spanish }],
      selectedFunnel: '',
      selectedStep: '',
      steps: [],
      isDefaultTemplate: false,
      selectedProvider: {},
      sizeTextOptions: [
        { name: this.$t('small'), value: sizeTextOptions.Small },
        { name: this.$t('medium'), value: sizeTextOptions.Medium },
        { name: this.$t('large'), value: sizeTextOptions.Large }
      ],
      verticalPositionOptions: [
        { name: this.$t('top'), value: verticalPositionOptions.Top },
        { name: this.$t('bottom'), value: verticalPositionOptions.Bottom },
        { name: this.$t('center'), value: verticalPositionOptions.Center }
      ],
      horizontalPositionOptions: [
        { name: this.$t('left'), value: horizontalPositionOptions.Left },
        { name: this.$t('right'), value: horizontalPositionOptions.Right },
        { name: this.$t('center'), value: horizontalPositionOptions.Center }
      ],
      funnels: [],
      selectedTemplate: {},
      urlForm: '',
      containerId: '',
      formId: ''
    }
  },
  computed: {
    ...mapState('AppStore', ['selectedApp']),
    ...mapState('ConversationStore', ['wpProviders', 'inboxes']),
    ...mapState('UserStore', ['token']),
    generateUrl () {
      const nameApp = this.selectedApp?.name.replace(/\s/g, '')
      return `Keybe.ai/${nameApp}`
    }
  },
  methods: {
    async createForm (): Promise<void> {
      try {
        const data: createFormDTO = {
          ...(this.dataTocreate || {}),
          url: this.generateUrlForm(),
          appUUID: this.selectedApp?.uuid,
          companyUUID: this.selectedApp?.company?.uuid
        }
        const response = await WidgetTesseractUseCases.createForm(
          this.widgetTesseractController,
          data
        )
        this.formId = response?.data?.form._id
        if (response) {
          EventBus.$emit('toast', 'success', this.$t('formCreated'))
          this.goBackToChannels()
        }
        if (!response) {
          EventBus.$emit('toast', 'error', this.$t('errorCreatingForm'))
        }
      } catch (error) {
        console.error(error)
      }
    },
    goBackToChannels (): void {
      EventBus.$emit('setConfigurationTab', ConfigurationTabsEnum.NEW_CHANNELS)
    },
    openFileInput (): void{
      this.$refs.imageInput.click()
    },
    async onFilePicked (event): Promise<void> {
      try {
        const img = event.target.files[0]
        const response = await UserService.uploadImage(img)
        if (response.status) {
          this.dataTocreate.landingConfig.desktop.backgroundImage =
            response?.data?.data?.info?.location || ''
        }
      } catch (error) {
        console.error(error)
      }
    },
    generateUrlForm (): string {
      const uuidUrl = uuidv4()
      return `https://${this.generateUrl}/${uuidUrl}`
    },
    changeFields () {
      this.dataTocreate.formFields = this.formFields
    },

    generateCode (appKey:string, containerId: string, formId: string) {
      return formScript(appKey, containerId, formId)
    },
    async copyToClipboard (text:string) {
      try {
        await navigator?.clipboard?.writeText(text)
      } catch (error) {
        EventBus.$emit('toast', 'error', this.$t('scriptCopyError'))
      }
    },
    async copyScript (): Promise<void> {
      this.containerId = this.containerId?.replace(/\s/g, '')
      const scriptText: string = this.generateCode(this.dataTocreate?.apiKey, this.containerId, this.formId)
      await this.copyToClipboard(scriptText)
      EventBus.$emit('toast', 'success', this.$t('scriptCopied'))
    },
    updateFormFields (value): void {
      this.$emit('updateFormFields', value)
    },
    updateFormFieldColor (value:string): void{
      this.$emit('formFieldColor', value)
    },
    updateFormTextColor (value:string): void{
      this.$emit('formTextColor', value)
    },
    updateFormButtonColor (value:string): void{
      this.$emit('formButtonColor', value)
    },
    updateFormBackgroundColor (value:string): void{
      this.$emit('formBackgroundColor', value)
    },
    updateFormButtonText (value:string): void{
      this.$emit('formButtonText', value)
    },
    updateFormLabelColor (value:string): void{
      this.$emit('formLabelColor', value)
    },
    updateContainerId (value:string): void{
      this.$emit('updateContainerId', value)
    },
    updateFormPosition (value:string): void {
      this.$emit('formPosition', value)
    }
  },
  watch: {
    'dataTocreate.landingConfig.desktop.titleTextAlign': {
      handler (value) {
        this.dataTocreate.landingConfig.desktop.titleTextAlign = value
      }
    }
  },
  beforeDestroy () {
    WidgetTesseractUseCases.abortAll(this.widgetTesseractController)
    // FunnelsUseCases.abortAll(this.funnelsController)
    // StepsUseCases.abortAll(this.stepsController)
  }
}
