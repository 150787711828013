import { IRPaymentItemController, dataToCreditCardsMethod, dataToDeletePaymentMethod, planGetter } from '../../domain/PlanController'
import PaymentRepository from '../repository/Payment.repository'

export default class PaymentController implements IRPaymentItemController {
    repository: PaymentRepository

    constructor () {
      this.repository = new PaymentRepository()
    }

    async removePaymentMethods (data: dataToDeletePaymentMethod): Promise<planGetter> {
      const response = await this.repository.removePaymentMethods(data)
      return response
    }

    async getActiveBikyPlan (data: dataToCreditCardsMethod): Promise<planGetter> {
      const response = await this.repository.getActiveBikyPlan(data)
      return response
    }
}
