<template>
  <svg width="24"
       height="30"
       viewBox="0 0 56 72"
       fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <path d="M39.0899 0H1.87596C0.839885 0 0 0.839885 0 2.46965V70.5247C0 70.9671 0.839885 71.807 1.87596 71.807H53.2615C54.2976 71.807 55.1375 70.9671 55.1375 70.5247V16.6413C55.1375 15.7488 55.0182 15.4616 54.8079 15.25L39.8875 0.329543C39.6759 0.119251 39.3887 0 39.0899 0Z"
          fill="#E9E9E0"/>
    <path d="M39.75 0.194092V15.3877H54.9436L39.75 0.194092Z"
          fill="#D9D7CA"/>
    <path d="M53.2615 71.8068H1.87596C0.839885 71.8068 0 70.967 0 69.9309V50.0083H55.1375V69.9309C55.1375 70.967 54.2976 71.8068 53.2615 71.8068Z"
          fill="#185C37"/>
    <path d="M28.1251 16.8125H18.4584C17.7956 16.8125 17.25 17.3581 17.25 18.0207V23.1563L28.1251 29.5L33.5626 31.7943L39.0001 29.5V23.1563L28.1251 16.8125Z"
          fill="#21A366"/>
    <path fill-rule="evenodd"
          clip-rule="evenodd"
          d="M17.25 23.1562H28.1251V29.5H17.25V23.1562Z"
          fill="#107C41"/>
    <path d="M37.7918 16.8125H28.125V23.1563H39V18.0207C39 17.3581 38.4544 16.8125 37.7918 16.8125Z"
          fill="#33C481"/>
    <path d="M28.1251 29.5H17.25V40.9793C17.25 41.6419 17.7956 42.1875 18.4584 42.1875H37.7919C38.4545 42.1875 39.0001 41.6419 39.0001 40.9793V35.8438L28.1251 29.5Z"
          fill="#185C37"/>
    <path fill-rule="evenodd"
          clip-rule="evenodd"
          d="M28.125 29.5H39V35.8438H28.125V29.5Z"
          fill="#107C41"/>
    <path d="M25.1043 21.3438H17.25V38.5625H25.1043C25.7663 38.5607 26.3107 38.0163 26.3125 37.3543V22.552C26.3107 21.89 25.7663 21.3456 25.1043 21.3438Z"
          fill="black"
          fill-opacity="0.094118"/>
    <path d="M24.198 22.25H17.25V39.4688H24.198C24.86 39.4669 25.4044 38.9225 25.4063 38.2605V23.4582C25.4044 22.7963 24.86 22.2518 24.198 22.25Z"
          fill="black"
          fill-opacity="0.2"/>
    <path d="M24.198 22.25H17.25V37.6563H24.198C24.86 37.6544 25.4044 37.11 25.4063 36.448V23.4582C25.4044 22.7963 24.86 22.2518 24.198 22.25Z"
          fill="black"
          fill-opacity="0.2"/>
    <path d="M23.2918 22.25H17.25V37.6563H23.2918C23.9538 37.6544 24.4982 37.11 24.5 36.448V23.4582C24.4982 22.7963 23.9538 22.2518 23.2918 22.25Z"
          fill="black"
          fill-opacity="0.2"/>
    <path d="M11.2082 22.25H23.2918C23.9544 22.25 24.5 22.7956 24.5 23.4582V35.5418C24.5 36.2044 23.9545 36.75 23.2918 36.75H11.2082C10.5456 36.75 10 36.2044 10 35.5418V23.4582C10 22.7956 10.5456 22.25 11.2082 22.25Z"
          fill="#107C41"/>
    <path d="M13.2021 34.0313L16.1346 29.4873L13.4486 24.9688H15.6101L17.0763 27.8568C17.211 28.1308 17.3037 28.3353 17.3543 28.47H17.3733C17.47 28.251 17.5713 28.0381 17.6767 27.8316L19.2439 24.9688H21.2284L18.4729 29.462L21.2979 34.0313H19.187L17.4932 30.8588C17.4135 30.7239 17.3459 30.5821 17.291 30.4354H17.2658C17.2163 30.5792 17.1506 30.7169 17.0698 30.846L15.3256 34.0313H13.2021Z"
          fill="white"/>
    <path d="M22.2782 67H20.1502L17.1662 62.392L14.2222 67H12.3422L16.2462 61.104L12.4782 55.432H14.5902L17.3182 59.664L20.0222 55.432H21.8782L18.2222 60.912L22.2782 67ZM24.3548 55.432H26.0908V65.496H31.4348V67H24.3468L24.3548 55.432ZM37.9024 67.16C37.3531 67.16 36.8171 67.0907 36.2944 66.952C35.7718 66.8133 35.2971 66.608 34.8704 66.336C34.4438 66.0587 34.0891 65.7147 33.8064 65.304C33.5291 64.8933 33.3558 64.4133 33.2864 63.864H35.0704C35.1611 64.28 35.3398 64.6293 35.6064 64.912C35.8784 65.1947 36.2118 65.408 36.6064 65.552C37.0011 65.696 37.4304 65.768 37.8944 65.768C38.4064 65.768 38.8651 65.6987 39.2704 65.56C39.6811 65.416 40.0064 65.2133 40.2464 64.952C40.4864 64.6853 40.6064 64.3653 40.6064 63.992C40.6064 63.656 40.5238 63.3707 40.3584 63.136C40.1984 62.9013 39.9691 62.7067 39.6704 62.552C39.3771 62.392 39.0224 62.2667 38.6064 62.176L36.4144 61.68C35.5558 61.4987 34.8784 61.1627 34.3824 60.672C33.8918 60.176 33.6438 59.52 33.6384 58.704C33.6331 58.0213 33.8118 57.424 34.1744 56.912C34.5424 56.4 35.0438 56.0027 35.6784 55.72C36.3131 55.432 37.0358 55.288 37.8464 55.288C38.7904 55.288 39.5824 55.4453 40.2224 55.76C40.8678 56.0747 41.3558 56.4907 41.6864 57.008C42.0171 57.52 42.1851 58.0773 42.1904 58.68H40.4384C40.3958 58.1893 40.2518 57.8 40.0064 57.512C39.7611 57.224 39.4518 57.0187 39.0784 56.896C38.7104 56.768 38.3051 56.704 37.8624 56.704C37.5424 56.704 37.2358 56.744 36.9424 56.824C36.6491 56.904 36.3904 57.0213 36.1664 57.176C35.9424 57.3307 35.7638 57.5227 35.6304 57.752C35.5024 57.976 35.4384 58.2347 35.4384 58.528C35.4384 58.9227 35.5718 59.2427 35.8384 59.488C36.1051 59.7333 36.5958 59.944 37.3104 60.12L39.4544 60.608C40.0678 60.736 40.5638 60.9253 40.9424 61.176C41.3211 61.4213 41.6118 61.7013 41.8144 62.016C42.0171 62.3307 42.1558 62.6587 42.2304 63C42.3051 63.336 42.3424 63.6587 42.3424 63.968C42.3424 64.5653 42.1638 65.1067 41.8064 65.592C41.4491 66.072 40.9398 66.4533 40.2784 66.736C39.6171 67.0187 38.8251 67.16 37.9024 67.16Z"
          fill="white"/>
  </svg>
</template>

<script>
export default {
  name: 'XLSIcon',
  props: {
    width: {
      type: Number,
      default: 16
    },
    height: {
      type: Number,
      default: 18
    },
    color: {
      type: String,
      default: '#B6BEC7'
    }
  }

}
</script>

<style>

</style>
