<template>
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 15 14"><path :fill="color" d="M4.396 11.386h-.223c-.799 0-1.598-.003-2.397 0-.73.002-1.277-.317-1.582-.975a2.133 2.133 0 0 1-.183-.864C-.002 6.967.01 4.386 0 1.804a1.781 1.781 0 0 1 1.816-1.8 3085.6 3085.6 0 0 1 10.413 0c1.044.003 1.81.78 1.81 1.826.005 2.575.005 5.15 0 7.725 0 1.068-.768 1.828-1.84 1.83-1.226.003-2.452-.002-3.677.005-.106 0-.23.04-.314.102-1.08.795-2.156 1.596-3.232 2.398-.125.092-.246.153-.403.073-.148-.075-.177-.201-.177-.351V11.385Zm.858 1.254c.093-.067.148-.104.2-.144.746-.56 1.495-1.118 2.235-1.686.239-.184.495-.266.795-.264 1.227.006 2.453.003 3.679.002.695 0 1.018-.322 1.018-1.021.002-2.545.002-5.09-.001-7.634 0-.138-.01-.283-.05-.414-.125-.414-.43-.608-.93-.608H1.86c-.667 0-.998.328-1 1.002a4463.23 4463.23 0 0 0 .002 7.67c0 .132.01.27.047.397.116.395.421.606.874.607.982.003 1.964 0 2.946.002.356 0 .514.159.523.512.002.086 0 .17 0 .256v1.322h.002Z"/><path :fill="color" d="M6.969 3.493c-.268.016-.536.014-.8.05-.266.037-.41.22-.454.482a1.615 1.615 0 0 1-.046.196.428.428 0 0 1-.454.315c-.224-.03-.379-.193-.373-.424.013-.548.254-.97.724-1.254.261-.158.553-.223.856-.218.476.006.953-.004 1.425.035.64.053 1.162.553 1.312 1.202.148.643-.097 1.307-.639 1.648-.303.19-.63.345-.95.506-.09.045-.126.095-.121.194a2.3 2.3 0 0 1-.01.402c-.03.244-.205.398-.425.394-.24-.005-.4-.158-.414-.42-.013-.225 0-.452-.008-.677-.01-.25.102-.413.32-.525.363-.187.729-.37 1.08-.576a.683.683 0 0 0 .1-1.13c-.134-.109-.34-.15-.52-.178-.197-.03-.402-.006-.603-.006v-.015ZM6.488 8.255a.544.544 0 0 1 .533-.56c.278-.009.528.243.534.539a.532.532 0 0 1-.524.544.52.52 0 0 1-.543-.523Z"/></svg>
</template>

<script>
export default {
  name: 'QuestionIcon',
  props: {
    color: {
      type: String,
      default: '#B6BEC7'
    }
  }
}
</script>

<style>

</style>
