import { render, staticRenderFns } from "./BikyConfigChatTextMessage.vue?vue&type=template&id=15a66515&scoped=true&"
import script from "./BikyConfigChatTextMessage.vue?vue&type=script&lang=ts&"
export * from "./BikyConfigChatTextMessage.vue?vue&type=script&lang=ts&"
import style0 from "./BikyConfigChatTextMessage.vue?vue&type=style&index=0&id=15a66515&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15a66515",
  null
  
)

export default component.exports