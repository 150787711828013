import { render, staticRenderFns } from "./CDPFieldsConfigCard.vue?vue&type=template&id=489616b2&scoped=true&"
import script from "./CDPFieldsConfigCard.vue?vue&type=script&lant=ts&lang=js&"
export * from "./CDPFieldsConfigCard.vue?vue&type=script&lant=ts&lang=js&"
import style0 from "./CDPFieldsConfigCard.vue?vue&type=style&index=0&id=489616b2&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "489616b2",
  null
  
)

export default component.exports