<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 11 10">
    <path
      :fill="color"
      d="M0 3.451c.07-.127.123-.268.215-.377.14-.164.34-.218.56-.217.518.003 1.037.003 1.556-.002a.325.325 0 0 0 .183-.061C3.659 1.906 4.8 1.015 5.944.124c.117-.092.237-.155.392-.108a.353.353 0 0 1 .26.323l.001.084.001 9.152c0 .154-.034.283-.174.37-.146.089-.3.07-.464-.058L4.09 8.431c-.527-.412-1.055-.824-1.585-1.233a.29.29 0 0 0-.164-.055c-.48-.004-.96-.015-1.438.001-.43.015-.761-.122-.903-.596V3.45ZM11 5.111c-.02 1.246-.504 2.417-1.48 3.39-.168.167-.388.178-.544.031-.151-.143-.145-.365.02-.53.654-.653 1.075-1.423 1.212-2.327.208-1.366-.17-2.565-1.123-3.588-.034-.037-.07-.07-.104-.106-.15-.157-.152-.376-.005-.515.15-.143.37-.137.528.016.42.408.757.872 1.01 1.394.325.672.482 1.381.486 2.235Z"/>
    <path
      :fill="color"
      d="M9.535 5.034c.002.886-.336 1.75-1.057 2.466-.16.16-.385.169-.535.025-.15-.142-.149-.362.012-.522.454-.453.736-.99.816-1.62.113-.897-.142-1.68-.774-2.346a.633.633 0 0 1-.15-.233c-.05-.156.034-.313.177-.39a.372.372 0 0 1 .442.07c.346.337.613.725.798 1.166.17.408.256.833.271 1.384Z"/>
  </svg>
</template>

<script>
export default {
  name: 'SoundIcon',
  props: {
    color: {
      type: String,
      default: '#8898A9'
    }
  }
}
</script>

<style scoped>

</style>
