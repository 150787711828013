import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import { debounce } from 'lodash'

import Colors from '@/shared/domain/colors/Color'
import KBCheck from '@/shared/infrastructure/ui/inputs/select/KBCheck.vue'
import KBSmallSelector from '@/shared/infrastructure/ui/inputs/select/KBSmallSelector.vue'
import KBTimeSelector from '@/shared/infrastructure/ui/pickers/KBTimeSelector.vue'
import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'
import KBTextarea from '@/shared/infrastructure/ui/inputs/text/KBTextarea.vue'
import KBText from '@/shared/infrastructure/ui/inputs/text/KBText.vue'
import ModalWarning from '@/modules/Spaces/infrastructure/ui/SpacesRules/ModalWarning/ModalWarning.vue'
import KeybeButton from '@/modules/DesignSystem/infrastructure/components/KeybeButton/KeybeButton.vue'
import { mapState } from 'vuex'
import { EventBus } from '@/eventBus'

export default {
  name: 'outOffService',
  components: {
    KBSmallSelector,
    KBCheck,
    KBTimeSelector,
    KeybeIcon,
    KBTextarea,
    KBText,
    ModalWarning,
    KeybeButton
  },
  props: {
    inboxList: {
      type: Array,
      required: true
    },
    index: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      rerender: 0,
      chatConfig: null,
      KeybeIconType,
      outOfServiceMessage: '',
      inactivityMessage: '',
      loading: false,
      showWarning: false,
      messageWarning: '',
      localInbox: []

    }
  },
  async created () {
    EventBus.$on('inboxUpdated--spaces', this.setInbox.bind(this))
    await this.setInbox(this.inboxList)
    await this.getChatConfig()
    await this.getTimeZones
  },
  computed: {
    ...mapState('UserStore', ['user', 'token', 'isUserReadonly']),
    ...mapState('AppStore', ['selectedApp']),
    Colors () {
      return Colors
    }
  },
  methods: {
    setInbox (inbox): void {
      // eslint-disable-next-line no-undef
      this.localInbox = structuredClone(inbox)
    },
    async getChatConfig (): Promise<void> {
      if (this.localInbox[this.index]?.workingHours?.message === undefined) {
        this.showWarning = true
        this.messageWarning = this.$t('messageWarning', { propsConfig: this.$t('inactivityMessage') })
      }
    },
    saveConfig: debounce(function (): void {
      this.$emit('inbox-updated', { workingHours: this.localInbox[this.index]?.workingHours || [] })
    }, 500),
    closeModal (): void {
      this.showWarning = false
      this.messageWarning = ''
    }
  },
  watch: {
    inboxList: {
      handler (inbox) {
        this.inboxName = inbox[this.index]?.name || ''
        this.message = inbox[this.index]?.workingHours?.message || ''
        this.showSelector = false
        setTimeout(() => {
          this.showSelector = true
        }, 100)
      },
      immediate: true
    },
    inactivityMessage () {
      if (!this.awaitingInactivityMessage) {
        setTimeout(async () => {
          await this.saveConfig()
          this.awaitingSearch = false
        }, 800)
      }
      this.awaitingSearch = true
    },
    outOfServiceMessage () {
      if (!this.awaitingOutOfServiceMessage) {
        setTimeout(async () => {
          await this.saveConfig()
          this.awaitingOutOfServiceMessage = false
        }, 800)
      }
      this.awaitingOutOfServiceMessage = true
    }
  },
  beforeDestroy () {
    EventBus.$off('inboxUpdated--spaces', this.setInbox.bind(this))
  }
}
