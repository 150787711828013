export default class FacebookProvider {
  static busySDK = false

  static setBusySDK (busySDK: boolean): void {
    this.busySDK = busySDK
  }

  static getBusySDK (): boolean {
    return this.busySDK
  }
}
