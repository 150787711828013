import { IAppConfigController, dataToGetAppConfig, appConfigGetter } from '../../domain/AppDataController'
import AppConfigRepository from '../repository/AppConfig.repository'

export default class AppConfigController implements IAppConfigController {
    repository: AppConfigRepository

    constructor () {
      this.repository = new AppConfigRepository()
    }

    async getAppConfig (data: dataToGetAppConfig): Promise<appConfigGetter> {
      const response = await this.repository.getAppConfig(data)
      return response
    }
}
