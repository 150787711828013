import LottieAnimation from 'lottie-web-vue'
import { ListType } from '@/components/empathize/enums/listTypes'
import { ImportsTypesEnum } from '@/modules/imports/domain/enums/ImportTypesEnum'
import { EntityTypeEnum } from '@/modules/CDP/shared/contacts/domain/Contacts'
import EmitToast from '@/utils/EmitToast'
import { mapState } from 'vuex'
import { EventBus } from '@/eventBus'

import KeybeText from '@/modules/DesignSystem/infrastructure/components/KeybeText/KeybeText.vue'
import KeybeSelect from '@/modules/DesignSystem/infrastructure/components/KeybeSelect/KeybeSelect/KeybeSelect.vue'
import KeybeAutocomplete from '@/modules/DesignSystem/infrastructure/components/KeybeAutocomplete/KeybeAutocomplete/KeybeAutocomplete.vue'
import SegmentsUseCases from '@/modules/CDP/shared/segments/application/SegmentsUseCases'
import SegmentsController from '@/modules/CDP/shared/segments/infrastructure/controllers/Segments.controller'
import { SegmentType } from '@/modules/CDP/shared/segments/domain/Segment'
import { CDPFieldsEnums } from '@/modules/CDP/shared/contacts/domain/enums/CDPFieldsEnums'
import { SalesFieldsEnum } from '@/modules/Sales/shared/domain/SalesFieldsEnum'
export default {
  props: {
    typeEntity: {
      type: String
    }
  },
  components: {
    KeybeText,
    KeybeSelect,
    KeybeAutocomplete,
    LottieAnimation
  },
  computed: {
    ...mapState('AppStore', ['selectedApp']),
    ...mapState('UserStore', ['token']),
    importTypes () {
      if (this.typeEntity === EntityTypeEnum.BUSINESS) {
        return [
          { name: this.$t('allBusiness'), value: ImportsTypesEnum.ALL_BUSSINESS },
          { name: this.$t('newBusinessSegment'), value: ImportsTypesEnum.NEW_BUSINESS },
          { name: this.$t('existentBusinessSegment'), value: ImportsTypesEnum.EXISTENT_BUSINESS }
        ]
      } else {
        return [
          { name: this.$t('allPeople'), value: ImportsTypesEnum.ALL },
          { name: this.$t('existentSegment'), value: ImportsTypesEnum.EXISTENT },
          { name: this.$t('newSegment'), value: ImportsTypesEnum.NEW }
        ]
      }
    },
    hasSelectedSegment () {
      if (
        (
          this.importType === ImportsTypesEnum.NEW ||
          this.importType === ImportsTypesEnum.NEW_BUSINESS
        ) && this.segmentName
      ) return true
      else if (
        (
          this.importType === ImportsTypesEnum.EXISTENT ||
          this.importType === ImportsTypesEnum.EXISTENT_BUSINESS
        ) && this.selectedSegment?.id
      ) return true
      else if (
        this.importType === ImportsTypesEnum.ALL ||
        this.importType === ImportsTypesEnum.ALL_BUSSINESS
      ) return true
      else return false
    }
  },
  mounted () {
    this.emitValidation()
    EventBus.$on('selectSegment--imports', (segment) => {
      this.importType = this.typeEntity === EntityTypeEnum.PEOPLE ? ImportsTypesEnum.EXISTENT : ImportsTypesEnum.EXISTENT_BUSINESS
      this.setImportType(segment)
    })
  },
  data () {
    return {
      search: '',
      segmentName: '',
      rerender: 0,
      segments: [],
      selectedSegment: {},
      loadingNameCheck: false,
      loadingSegments: false,
      segmentsController: new SegmentsController(),
      importType: ImportsTypesEnum.ALL,
      ImportsTypesEnum,
      EntityTypeEnum,
      CDPFieldsEnums,
      SalesFieldsEnum
    }
  },
  methods: {
    setImportType (selectSegment) {
      if (
        this.importType === ImportsTypesEnum.EXISTENT_BUSINESS ||
        this.importType === ImportsTypesEnum.EXISTENT
      ) {
        this.getSegments(selectSegment)
        this.$emit('createSegment', false)
      } else if (this.importType === ImportsTypesEnum.NEW ||
        this.importType === ImportsTypesEnum.NEW_BUSINESS) {
        this.$emit('createSegment', true)
      } else this.$emit('createSegment', false)
      this.emitValidation()
    },
    setSegment (e) {
      this.selectedSegment = { id: e?._id, name: e?.name }
      this.emitValidation()
      this.$emit('segment', this.selectedSegment)
      this.rerender++
    },
    setSegmentSearch (e) {
      this.search = e
      this.getSegments()
    },
    async getSegments (selectSegment) {
      this.loadingSegments = true
      const variables = {
        app: this.selectedApp?.uuid || '',
        typeEntity: this.typeEntity,
        search: this.search,
        token: this.token,
        offset: 1,
        limit: 25
      }
      const response = await SegmentsUseCases.getSegments(this.segmentsController, variables)
      if (response?.status) {
        this.segments = response?.data?.data || []
        this.segments = this.segments.filter(segment => segment.type === SegmentType.Static)
        if (selectSegment) this.setFilteredSegment(selectSegment)
      } else EmitToast.emitErrors(response)
      this.loadingSegments = false
    },
    setFilteredSegment (selectSegment) {
      this.segments = this.segments.filter(seg =>
        seg?.name !== '$all$' &&
        seg?.type !== ListType.Dimanic &&
        seg?.type !== ListType.Dynamic &&
        seg?.type !== ListType.Dinamic
      )
      setTimeout(() => {
        if (selectSegment) this.setSegment(selectSegment)
      }, 300)
    },
    async checkSegmentName () {
      if (this.segmentName) {
        this.loadingNameCheck = true
        const variables = {
          app: this.selectedApp?.uuid || '',
          typeEntity: this.typeEntity,
          name: this.segmentName,
          token: this.token
        }
        const response = await SegmentsUseCases.getSegmentByName(this.segmentsController, variables)
        if (response?.data?._id) EmitToast.emitErrors({ message: this.$t('segmentNameExists') })
        else this.$emit('segmentName', this.segmentName)
        this.emitValidation()
        this.loadingNameCheck = false
      }
    },
    emitValidation () {
      this.$emit('hasSelectedSegment', this.hasSelectedSegment)
    }
  },
  watch: {
    typeEntity () {
      if (this.typeEntity === EntityTypeEnum.PEOPLE) this.importType = ImportsTypesEnum.ALL
      else this.importType = ImportsTypesEnum.ALL_BUSSINESS
    }
  }
}
