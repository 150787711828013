<template>
  <svg :width="width"
       :height="height"
       viewBox="0 0 14 14"
       fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <path d="M2 1.5C1.725 1.5 1.5 1.725 1.5 2V12C1.5 12.275 1.725 12.5 2 12.5H9V10C9 9.44687 9.44687 9 10 9H12.5V2C12.5 1.725 12.275 1.5 12 1.5H2ZM9 14H2C0.896875 14 0 13.1031 0 12V2C0 0.896875 0.896875 0 2 0H12C13.1031 0 14 0.896875 14 2V9V9.17188C14 9.70312 13.7906 10.2125 13.4156 10.5875L10.5875 13.4156C10.2125 13.7906 9.70312 14 9.17188 14H9Z"
          :fill="color"/>
  </svg>

</template>
<script>
export default {
  name: 'PrimaryIcon',
  props: {
    width: {
      type: Number,
      default: 14
    },
    height: {
      type: Number,
      default: 14
    },
    color: {
      type: String,
      default: '#B6BEC7'
    }
  }
}
</script>
