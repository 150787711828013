

















































import ConsumptionsDetails from './ConsumptionsDetails'
export default ConsumptionsDetails
