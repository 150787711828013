import { HttpLink } from 'apollo-link-http'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { ApolloLink, concat, split } from 'apollo-link'
import { getMainDefinition } from 'apollo-utilities'
import VueApollo from 'vue-apollo'
import { createApolloClient } from 'vue-cli-plugin-apollo/graphql-client'
import 'regenerator-runtime'
import { GraphqlVariables } from '@/Enums/GraphqlVariables'
import store from '@/store'

export default class ContactsApolloProvider {
  provider
  // Name of the localStorage item
  AUTH_TOKEN = 'apollo-token'

  // Http endpoint
  httpEndpoint = process.env.VUE_APP_SEGMENTS_GRAPHQL_HTTP
  // Files URL root
  filesRoot = process.env.VUE_APP_FILES_ROOT || this.httpEndpoint.substring(0, this.httpEndpoint.indexOf('/graphql'))

  // Config
  defaultOptions = {
    // You can use `https` for secure connection (recommended in production)
    httpEndpoint: this.httpEndpoint,
    // You can use `wss` for secure connection (recommended in production)
    // Use `null` to disable subscriptions
    wsEndpoint: null,
    // LocalStorage token
    tokenName: this.AUTH_TOKEN,
    // Enable Automatic Query persisting with Apollo Engine
    persisting: false,
    // Use websockets for everything (no HTTP)
    // You need to pass a `wsEndpoint` for this to work
    websocketsOnly: false,
    // Is being rendered on the server?
    ssr: false
  }

  createProvider (options = {}) {
    // Create apollo client
    const { apolloClient } = createApolloClient({
      ...this.defaultOptions,
      ...options
    })

    // Create vue apollo provider
    return new VueApollo({
      defaultClient: apolloClient,
      defaultOptions: {
        $query: {
          // fetchPolicy: 'network-only'
        }
      },
      errorHandler (error) {
        // eslint-disable-next-line no-console
        console.log('%cError',
          'background: red; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;',
          error.message)
      }
    })
  }

  constructor () {
    const httpLink = new HttpLink({
      // You should use an absolute URL here
      uri: process.env.VUE_APP_SEGMENTS_GRAPHQL_HTTP
    })

    // Create the subscription websocket link
    const authMiddleware = new ApolloLink((operation, forward) => {
      // add the authorization to the headers]
      const token = store.getters['UserStore/getToken']
      operation.setContext({
        headers: {
          authorization: `Bearer ${token}`
        }
      })
      return forward(operation)
    })

    // using the ability to split links, you can send data to each link
    // depending on what kind of operation is being sent
    const link = split(
      // split based on operation type
      ({ query }) => {
        const definition = getMainDefinition(query)
        return definition.kind === GraphqlVariables.OPERATION_DEFINITION &&
          definition.operation === GraphqlVariables.SUBSCRIPTION
      },
      httpLink
    )

    this.provider = this.createProvider({
      link: concat(authMiddleware, link),
      cache: new InMemoryCache(),
      connectToDevTools: true
    })
  }

  static getInstance () {
    // @ts-ignore
    if (!ContactsApolloProvider.instance) {
      // @ts-ignore
      ContactsApolloProvider.instance = new ContactsApolloProvider()
    }
    // @ts-ignore
    return ContactsApolloProvider.instance
  }
}
