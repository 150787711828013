









export default {
  name: 'ElipseIcon'
}
