import { Category } from '../../domain/models/Category'
import {
  ICategoryController,
  dataToCreateCategory,
  dataToGetCategories,
  dataToUpdateCategory,
  dataToUpdateCategoryStatus,
  dataToDeleteCategory, CategoryDeleted
} from '../../domain/models/CategoryController'
import CategoryRepository from '../repositories/Category.repository'
import { GenericResponse } from '@/shared/infrastructure/http/GenericResponse'

export default class CategoryController implements ICategoryController {
  repository: CategoryRepository
  constructor () {
    this.repository = new CategoryRepository()
  }

  async getCategories (data: dataToGetCategories): Promise<Category[]> {
    const response = await this.repository.getCategories(data)
    if (!response.status) {
      return []
    }

    return response?.data?.data || []
  }

  createCategory (data: dataToCreateCategory): Promise<Category> {
    throw new Error('Method not implemented.')
  }

  async updateCategory (data: dataToUpdateCategory): Promise<Category> {
    const response = await this.repository.updateCategory(data)
    return response.data
  }

  updateCategoryStatus (data: dataToUpdateCategoryStatus): Promise<Category> {
    throw new Error('Method not implemented.')
  }

  async deleteCategory (data: dataToDeleteCategory): Promise<CategoryDeleted> {
    const response: GenericResponse = await this.repository.deleteCategory(data)
    return {
      message: response.data.message || ''
    }
  }
}
