

































import { Room } from '@/modules/conversations/domain/models/Room/Room'
import { mapState } from 'vuex'
import { CDPFieldsEnums } from '@/modules/CDP/shared/contacts/domain/enums/CDPFieldsEnums'
export default {
  name: 'AiModuleScreen',
  data () {
    return {
      messageText: '',
      loadingAiResponse: false,
      responseText: '',
      messages: []
    }
  },
  computed: {
    ...mapState('ConversationStore', ['selectedRoom']),
    ...mapState('UserStore', ['user']),
    roomComputed (): Room {
      return this.selectedRoom
    },
    isRoomSelected (): boolean {
      return this.roomComputed !== null && this.roomComputed?._id
    },
    userName (): string {
      return this.user?.name || ''
    }
  },
  mounted () {
    document.documentElement.style.setProperty('--ai-editor-height', (40) + 'px')
    this.$refs.AiModuleScreen__textarea.focus()
  },
  methods: {
    pasteText (event) {
      event.preventDefault()
      const text = (event.clipboardData).getData(CDPFieldsEnums.TEXT)
      const formattedText = text.replace(/\n\s*\n\s*\n/g, '\n')

      const textarea = this.$refs.AiModuleScreen__textarea

      const selRange = textarea.selectionEnd
      const firstPart = this.messageText.substring(0, selRange)
      const lastPart = this.messageText.substring(selRange)
      this.messageText = firstPart + formattedText + lastPart
      textarea.focus()
      setTimeout(() => {
        textarea.selectionEnd = selRange + formattedText.length
      }, 50)
      this.handleResizePaste()
    },
    updateMessage () {
      this.$nextTick(() => {
        this.handleResize()
      })
    },
    handleResize () {
      const textarea = this.$refs.AiModuleScreen__textarea

      const actualHeight = textarea?.style?.height || '20px'
      const actualHeightNumber = parseInt(actualHeight.replace('px', ''))

      if (this.$refs && textarea) {
        if (textarea.scrollHeight > 20 && textarea.scrollHeight <= 100) {
          textarea.style.height = '1px'
          textarea.style.height = (10 + textarea.scrollHeight) + 'px'
        } else if (textarea.scrollHeight >= 100) {
          textarea.scrollTop = textarea.scrollHeight
        } else {
          textarea.style.height = '20px'
        }

        if (this.messageText.length === 0) {
          textarea.style.height = '20px'
        }
        const heightString = textarea.style.height
        const height = parseInt(heightString.substring(0, heightString.length - 2))
        const messageEditorHeight = getComputedStyle(document.documentElement).getPropertyValue('--ai-editor-height')
        const messageEditorHeightInt = parseInt(messageEditorHeight.substring(0, messageEditorHeight.length - 2))

        const value = height + messageEditorHeightInt - actualHeightNumber
        console.log('====================================')
        console.log(CDPFieldsEnums.VALUE, value)
        console.log('====================================')

        document.documentElement.style.setProperty('--ai-editor-height', (value || 40) + 'px')
      }
    },
    handleResizePaste () {
      const textarea = this.$refs.AiModuleScreen__textarea
      const actualHeight = textarea?.style?.height || '40px'
      const actualHeightNumber = parseInt(actualHeight.replace('px', ''))

      if (this.messageText.includes('\n')) {
        textarea.style.height = '160px'
      } else {
        if (this.screenSizeMd) {
          if (this.messageText.length > 90 && this.messageText.length <= 180) {
            textarea.style.height = '40px'
          } else if (this.messageText.length > 180 && this.messageText.length <= 270) {
            textarea.style.height = '80px'
          } else if (this.messageText.length > 270 && this.messageText.length <= 360) {
            textarea.style.height = '100px'
          } else if (this.messageText.length > 360) {
            textarea.style.height = '140px'
          }
        } else {
          if (this.messageText.length > 150 && this.messageText.length <= 250) {
            textarea.style.height = '40px'
          } else if (this.messageText.length > 250 && this.messageText.length <= 450) {
            textarea.style.height = '80px'
          } else if (this.messageText.length > 450 && this.messageText.length <= 600) {
            textarea.style.height = '100px'
          } else if (this.messageText.length > 600) {
            textarea.style.height = '140px'
          }
        }
      }
      textarea.scrollTop = textarea.scrollHeight

      const heightString = textarea.style.height
      const height = parseInt(heightString.substring(0, heightString.length - 2))
      const messageEditorHeight = getComputedStyle(document.documentElement).getPropertyValue('--ai-editor-height')
      const messageEditorHeightInt = parseInt(messageEditorHeight.substring(0, messageEditorHeight.length - 2))

      const value = height + messageEditorHeightInt - actualHeightNumber

      document.documentElement.style.setProperty('--ai-editor-height', (value || 40) + 'px')
    },
    copy (text) {
      navigator.clipboard.writeText(text)
    },
    send () {
      // create the sse connection
      const sse = new EventSource(`http://localhost:3000/ai/company/${this.roomComputed?.companyUUID}/app/${this.roomComputed.appUUID}/room/${this.roomComputed?._id}?userPromt=${this.messageText}&userName=${this.userName}`)

      this.messageText = ''
      this.responseText = ''
      this.loadingAiResponse = true

      // listen to incoming messages
      sse.addEventListener('message', ({ data }) => {
        const msgObj = JSON.parse(data)
        // validate if msgObj.text is a blank space or a new line and the messageText is empty to skip it
        if ((msgObj.text === ' ' || msgObj.text === '\r' || msgObj.text === '\n') && this.messageText === '') return
        this.responseText += msgObj.text
        this.handleResizePaste()
      })

      // listen to errors
      sse.addEventListener('error', () => {
        sse.close()
        this.messages.push(this.responseText)
        this.responseText = ''
        this.loadingAiResponse = false
      })
    }
  }
}
