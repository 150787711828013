export const webAgentScript = (appKey) => {
  return `
      <script src="${process.env.VUE_APP_STORAGE_URL}/keybejs/latest/keybe.js"></script>
      <script>
      window.addEventListener('load', function () {
        var configChat = {
          apiKey: '${appKey}'
        }
        window.keybe.webchatConversationsUiLoad(configChat)
      })
      </script>
  `
}
