import { EventBus } from '@/eventBus'

export default {
  props: ['position', 'toRight'],
  mounted () {
    EventBus.$on('closeKBMenu', () => {
      this.isOpen = false
    })
  },
  data () {
    return {
      isOpen: false
    }
  },
  methods: {
    open () {
      this.isOpen = !this.isOpen
    },
    close (e) {
      const ignoreClases: string[] = [
        'v-list-item__title',
        'v-list-item__content',
        'KeybeSelect__list__item',
        'KeybeSelect__list',
        'KeybeSelect__list__item--text',
        'KeybePhone__list__search__input',
        'KeybePhone__list__item',
        'KeybeAutocomplete__list__item-multiple',
        'KeybeAutocomplete__list__item',
        'KeybeAutocomplete__list',
        'KeybeAutocomplete__list__item--text',
        'KeybeAutocomplete__list__item-multiple--text'
      ]
      if (!ignoreClases.includes(e?.target?.className)) this.isOpen = false
    }
  },
  watch: {
    isOpen (val) {
      this.$emit('isOpen', val)
    }
  }
}
