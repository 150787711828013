import { EventBus } from '@/eventBus'
import { VecindarioConfigUseCases } from '@/modules/bky/application/VecindarioConfigUseCases'
import { CreateVecindarioConfigDto, VecindarioCurrencies, VecindarioLanguages } from '@/modules/bky/domain/VecindarioConfig'
import KBSwitch from '@/shared/infrastructure/ui/inputs/select/KBSwitch.vue'
import { mapState } from 'vuex'
import VecindarioConfigController from '../../../controllers/VecindarioConfig.controller'

export default {
  name: 'BikyConfigScheduleBasic',
  components: {
    KBSwitch
  },
  data () {
    return {
      center: {},
      currentPlace: null,
      markers: [],
      places: {},
      additionalInformation: '',
      show: false,
      digitalAppointments: true,
      physicalAppointments: false,
      vecindarioConfigController: new VecindarioConfigController()
    }
  },
  computed: {
    ...mapState('AppStore', ['selectedApp']),
    ...mapState('UserStore', ['user', 'token'])
  },
  methods: {
    setPlace (place): void {
      console.log('place', place)
      this.currentPlace = place
      this.center = place.geometry.location
      this.markers = []
      this.markers.push({
        position: place.geometry.location
      })
    },
    geolocate (): void {
      navigator.geolocation.getCurrentPosition(position => {
        this.center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        }

        this.markers.push({
          position: this.center
        })
      })
    },
    setMarkerOnClick (e): void {
      this.markers = []
      this.markers.push({
        position: e.latLng
      })
      this.center = e.latLng
      this.currentPlace = null
    },
    async save (): Promise<void> {
      this.$emit('start-loading')
      const created = await this.createConfig()

      if (!created) {
        EventBus.$emit('toast', 'error', this.$t('errorCreatingConfig'))
        this.$emit('stop-loading')
        return
      }

      setTimeout(() => {
        this.$emit('stop-loading')
        this.$emit('close')
      }, 500)
    },
    cancel (): void{
      console.log('cancel')
      this.$emit('close')
    },
    async createConfig (): Promise<boolean> {
      const data: CreateVecindarioConfigDto = {
        title: this.selectedApp?.name,
        language: VecindarioLanguages.ES,
        currency: VecindarioCurrencies.COP,
        appUUID: this.selectedApp?.uuid
      }

      if (this.physicalAppointments) {
        if (this.currentPlace?.formatted_address) {
          data.address = this.currentPlace.formatted_address
          data.lat = this.currentPlace.geometry.location.lat()
          data.lng = this.currentPlace.geometry.location.lng()
        } else {
          data.lat = this.center.lat()
          data.lng = this.center.lng()
        }
      }

      const response = await VecindarioConfigUseCases.createVecindarioConfig(this.vecindarioConfigController, {
        token: this.token,
        data
      })

      return !!response?._id
    }
  },
  mounted () {
    this.geolocate()
  }
}
