<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       fill="none"
       viewBox="0 0 10 10"><path :fill="color"
                                 d="M2.82.001c.523 0 1.048.005 1.571 0 .49-.006.896.16 1.243.51 1.286 1.297 2.58 2.586 3.87 3.878.644.646.647 1.557.004 2.202-.973.977-1.947 1.952-2.923 2.926-.519.518-1.207.629-1.826.287a1.67 1.67 0 0 1-.359-.28C3.093 8.22 1.788 6.913.482 5.607A1.545 1.545 0 0 1 0 4.448C.01 3.36.007 2.273.002 1.184-.002.45.487.005 1.188 0c.544-.002 1.088 0 1.632 0ZM1.094 1.092c-.004.057-.01.092-.01.127 0 1.078.001 2.157-.001 3.235 0 .159.064.278.174.388 1.303 1.3 2.604 2.603 3.906 3.905.227.227.42.228.645.003.983-.98 1.964-1.963 2.945-2.945.206-.207.207-.418 0-.626C7.45 3.873 6.143 2.57 4.84 1.262a.555.555 0 0 0-.422-.172c-1.053.004-2.105.002-3.158.002h-.165Z"/><path :fill="color"
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    d="M3.278 2.565a.702.702 0 0 1-.721.724c-.42.001-.726-.311-.721-.738.005-.42.313-.724.727-.717.419.007.716.31.715.73Z"/></svg>
</template>

<script>
export default {
  name: 'TagsIcon',
  props: {
    color: {
      type: String,
      default: '#fff'
    }
  }
}
</script>

<style>

</style>
