import { ExtendedData } from '@/modules/Apps/domain/ExtendedFields'
import { RegisterChannel } from '../../contacts/domain/Contacts'

export enum SegmentType {
  Static = 'static',
  Dynamic = 'dynamic'
}

export interface createdAtFilter {
  init: string,
  end: string
}
export interface SegmentFilter {
  registerChannel?: RegisterChannel[],
  extendedData?: ExtendedData[],
  segment?: string,
  status?: string[],
  roles?: string[],
  responsible?: string[],
  business?: string,
  office?: string,
  createdAt?: createdAtFilter,
  city?: string,
  zone?: string,
  funnelStep?: string
}

export interface Segment {
  totalUsers: Number,
  name: String,
  status: String,
  type: String,
  query: String,
  importedFrom: String,
  _id?: String,
  appName: String,
  appUUID: String,
  companyId: String,
  companyName: String,
  userId: String,
  userIdHash: String,
  createdAt: String,
  updatedAt: String,
  id?: String
}
