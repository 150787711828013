import KeybeRepository from '@/shared/infrastructure/repositories/KeybeRepository'
import { dataToGetActivityLogs, dataToWriteActivityLog } from '../../domain/ActivityLogsController'
import { GenericResponse } from '@/shared/infrastructure/http/GenericResponse'
import { HeadersBuilder } from '@/shared/infrastructure/http/HeadersBuilder'
import { getOptions, nonGetOptions } from '@/shared/infrastructure/http/HttpClient'

export default class ActivityLogsRepository extends KeybeRepository {
  getActivityLogsAbortContoller: AbortController
  writeActivityLogsAbortController: AbortController

  constructor () {
    super()

    this.getActivityLogsAbortContoller = new AbortController()
    this.writeActivityLogsAbortController = new AbortController()
  }

  async getActivityLogs (data: dataToGetActivityLogs): Promise<GenericResponse> {
    const url = `${process.env.VUE_APP_API_URL}/config/logs`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)

    const requestOptions: getOptions = {
      headers,
      url,
      query: data.params,
      abortController: this.getActivityLogsAbortContoller
    }

    return await this.client.get(requestOptions)
  }

  async writeActivityLog (data: dataToWriteActivityLog): Promise<GenericResponse> {
    const url = `${process.env.VUE_APP_API_URL}/config/logs`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)

    const requestOptions: nonGetOptions = {
      headers,
      url,
      data: data.updateInput,
      abortController: this.writeActivityLogsAbortController
    }

    return await this.client.post(requestOptions)
  }

  abortGetActivityLogs (): void {
    this.getActivityLogsAbortContoller.abort()
    this.getActivityLogsAbortContoller = new AbortController()
  }

  abortWriteActivityLog (): void {
    this.writeActivityLogsAbortController.abort()
    this.writeActivityLogsAbortController = new AbortController()
  }

  abortAll (): void {
    this.abortGetActivityLogs()
    this.abortWriteActivityLog()
  }
}
