<template>
  <svg width="41"
       height="28"
       viewBox="0 0 41 28"
       fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <path d="M9.225 28C4.13203 28 0 23.9688 0 19C0 15.075 2.57531 11.7375 6.16281 10.5063C6.15641 10.3375 6.15 10.1687 6.15 10C6.15 4.475 10.7369 0 16.4 0C20.1989 0 23.5109 2.0125 25.2855 5.0125C26.2592 4.375 27.438 4 28.7 4C32.0953 4 34.85 6.6875 34.85 10C34.85 10.7625 34.7027 11.4875 34.44 12.1625C38.1812 12.9 41 16.1313 41 20C41 24.4188 37.3292 28 32.8 28H9.225ZM14.2859 14.4375C13.6838 15.025 13.6838 15.975 14.2859 16.5562C14.8881 17.1375 15.8619 17.1437 16.4577 16.5562L18.9561 14.1187V22.5C18.9561 23.3312 19.6416 24 20.4936 24C21.3456 24 22.0311 23.3312 22.0311 22.5V14.1187L24.5295 16.5562C25.1317 17.1437 26.1055 17.1437 26.7012 16.5562C27.297 15.9688 27.3034 15.0188 26.7012 14.4375L21.5762 9.4375C20.9741 8.85 20.0003 8.85 19.4045 9.4375L14.2795 14.4375H14.2859Z"
          :fill="color"/>
  </svg>
</template>
<script>
export default {
  name: 'UploadCloud',
  props: {
    color: {
      type: String,
      default: '#B6BEC7'
    }
  }
}
</script>
