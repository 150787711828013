<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       fill="none"
       viewBox="0 0 14 11"><path :fill="color"
                                 d="M7.555 9.631H10.925c.31 0 .565.098.695.403a.672.672 0 0 1-.533.953 1.25 1.25 0 0 1-.186.012L2.83 11c-.266 0-.5-.06-.66-.296a.682.682 0 0 1 .456-1.058c.075-.011.152-.014.228-.014C3.9 9.63 4.946 9.63 5.993 9.63h.193v-.177c0-2.007 0-4.014.003-6.022 0-.109-.029-.17-.13-.223a1.61 1.61 0 0 1-.83-1.027c-.01-.041-.025-.081-.04-.129h-.865c-.512 0-1.023.002-1.534 0-.368-.002-.588-.15-.698-.46A.666.666 0 0 1 2.62.701c.075-.012.152-.016.228-.016.837-.002 1.673-.002 2.51 0a.259.259 0 0 0 .22-.097C6.235-.183 7.45-.198 8.12.557a.353.353 0 0 0 .294.13c.841-.005 1.682-.003 2.523-.002.299 0 .547.098.677.388.217.49-.104.974-.654.979-.669.006-1.338.017-2.007-.006-.284-.009-.45.032-.54.345-.106.377-.394.636-.74.817-.091.048-.128.105-.128.211.004 2.017.003 4.034.003 6.05 0 .048.004.095.008.163V9.63Z"/><path :fill="color"
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                 d="M2.609 8.932c-.595-.047-1.113-.115-1.596-.35-.62-.302-1.057-1.01-1.01-1.668a.882.882 0 0 1 .092-.327c.548-1.072 1.1-2.14 1.655-3.209.206-.398.537-.624.986-.624.444 0 .776.225.982.613A220.57 220.57 0 0 1 5.371 6.53c.224.44.113.88-.123 1.283a1.978 1.978 0 0 1-1.18.92c-.5.148-1.01.2-1.459.2ZM4.398 6.87 2.766 3.76l-.058.003-1.63 3.107h3.32ZM10.878 8.932c-.599-.045-1.122-.114-1.609-.351-.62-.302-1.055-1.01-1.008-1.67a.882.882 0 0 1 .092-.327c.548-1.07 1.101-2.14 1.656-3.208.206-.398.538-.623.987-.622.444.001.775.227.981.615a241.045 241.045 0 0 1 1.646 3.148c.228.446.12.889-.118 1.297a1.98 1.98 0 0 1-1.182.917c-.494.148-1 .197-1.445.2v.001Zm.15-5.164-.062-.004-1.629 3.103h3.317l-1.626-3.1v.001Z"/></svg>
</template>

<script>
export default {
  name: 'BalanceIcon',
  props: {
    color: {
      type: String,
      default: '#fff'
    }
  }
}
</script>

<style>

</style>
