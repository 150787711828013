<template>
  <svg :width="width"
       :height="height"
       viewBox="0 0 13 10"
       fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <path d="M5.63474 9.99903C4.30102 9.99903 2.9673 10.0016 1.6341 9.99746C1.11264 9.99589 0.658653 9.83114 0.328622 9.4033C0.0990127 9.1057 0.00120595 8.76468 0.00120595 8.39176C0.00172898 6.95344 -0.00297782 5.51511 0.00329851 4.07731C0.00643668 3.34925 0.362619 2.81785 1.0117 2.58144C1.19999 2.51293 1.41129 2.48102 1.61266 2.47945C2.83288 2.47056 4.0531 2.47422 5.27333 2.47527C5.61173 2.47527 5.82617 2.61963 5.91979 2.90049C6.04218 3.26713 5.81885 3.64476 5.44017 3.70648C5.36747 3.71851 5.29268 3.72269 5.21946 3.72269C4.03428 3.72374 2.84857 3.72321 1.66339 3.72321C1.348 3.72321 1.24287 3.82887 1.24287 4.14844C1.24287 5.53864 1.24287 6.92885 1.24287 8.31906C1.24287 8.65589 1.34016 8.75108 1.6817 8.75108C4.32299 8.75108 6.96375 8.75108 9.60505 8.75108C9.93665 8.75108 10.0439 8.64543 10.0439 8.31435C10.0439 7.54289 10.046 6.77142 10.0428 5.99995C10.0418 5.75518 10.0977 5.53969 10.3164 5.40057C10.5245 5.26824 10.7479 5.26772 10.9639 5.37441C11.1563 5.46961 11.2725 5.6354 11.2756 5.85298C11.284 6.45865 11.2829 7.06432 11.2845 7.66998C11.285 7.90535 11.2876 8.14071 11.284 8.37607C11.2761 8.89439 11.1035 9.34106 10.6882 9.67004C10.3817 9.91325 10.0188 9.99746 9.63591 9.99798C8.30218 10.0001 6.96846 9.99903 5.63527 9.99903H5.63474Z"
          :fill="color"/>
    <path d="M9.22704 1.52626C9.81754 2.09741 10.4028 2.66332 10.997 3.23813C10.953 3.28573 10.9264 3.31763 10.8965 3.3464C9.99433 4.23032 9.09681 5.11946 8.18465 5.99344C8.0267 6.14512 7.81644 6.25914 7.61036 6.34021C7.18985 6.50549 6.75626 6.63729 6.3279 6.78165C6.12183 6.85121 5.92883 6.83029 5.77349 6.6692C5.62809 6.51909 5.6192 6.33812 5.68405 6.14669C5.83155 5.71415 5.96439 5.27585 6.13019 4.85115C6.20603 4.65763 6.31848 4.4615 6.46232 4.314C7.3682 3.38458 8.28768 2.46824 9.20246 1.54718C9.2171 1.53254 9.23437 1.51946 9.22652 1.52626H9.22704Z"
          :fill="color"/>
    <path d="M9.73828 1.03816C9.98097 0.789195 10.2299 0.500484 10.5129 0.251523C10.8879 -0.078508 11.3387 -0.0826923 11.7274 0.232694C11.9418 0.406862 12.1395 0.609274 12.3126 0.824762C12.6013 1.18461 12.5537 1.67782 12.2227 2.04132C11.9821 2.30545 11.7394 2.56697 11.4852 2.84365C10.8733 2.21131 10.3052 1.62447 9.73828 1.03816Z"
          :fill="color"/>
    <path d="M3.12179 6.24926C3.11604 6.60649 2.84877 6.86696 2.4952 6.86016C2.13117 6.85336 1.87855 6.58661 1.88692 6.21893C1.89529 5.86536 2.15523 5.61169 2.50566 5.61378C2.8676 5.6164 3.12754 5.88419 3.12179 6.24874V6.24926Z"
          :fill="color"/>
    <path d="M5.00545 6.24101C5.00336 6.59876 4.74185 6.86028 4.38671 6.86028C4.0253 6.86028 3.76536 6.59249 3.77059 6.22637C3.77582 5.86757 4.04099 5.6071 4.39508 5.6139C4.7544 5.62122 5.00807 5.88117 5.00598 6.24101H5.00545Z"
          :fill="color"/>
  </svg>

</template>

<script>
export default {
  name: 'ArrowDownIcon',
  props: {
    width: {
      type: Number,
      default: 13
    },
    height: {
      type: Number,
      default: 10
    },
    color: {
      type: String,
      default: '#B6BEC7'
    }
  }

}
</script>

<style>

</style>
