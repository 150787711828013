














































































import ConfigCollapsedBiky from './ConfigCollapsedBiky'
export default ConfigCollapsedBiky
