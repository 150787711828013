



















































































import AutomaticClosingConfig from './AutomaticClosingConfig'
export default AutomaticClosingConfig
