import FieldSelector from '../FieldSelector/FieldSelector.vue'
import CitySelector from '../CitySelector/CitySelector.vue'
import AddOptions from '../AddOptions/AddOptions.vue'
import { CDPFieldsEnums } from '@/modules/CDP/shared/contacts/domain/enums/CDPFieldsEnums'
import KeybeButton from '@/modules/DesignSystem/infrastructure/components/KeybeButton/KeybeButton.vue'
import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'
import { typeField } from '@/modules/Config/WidgetTesseract/domain/WidgetTesseract'
// import { EventBus } from '@/eventBus'
import Colors from '@/utils/Colors'
import KBIconButton from '@/shared/infrastructure/ui/buttons/KBIconButton.vue'

export default {
  name: 'DataInputForm',
  components: {
    KBIconButton,
    FieldSelector,
    CitySelector,
    KeybeButton,
    KeybeIcon,
    AddOptions
  },
  props: {
    field: {
      type: Object,
      required: true
    },
    fieldIndex: {
      type: Number,
      required: true
    },
    customFields: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      showOptions: false,
      Colors,
      showLocation: false,
      IsEditCity: false,
      locations: [],
      KeybeIconType,
      city: {
        id: 0,
        city: '',
        state: '',
        country: ''
      }
    }
  },
  methods: {
    handleUpdateFieldName (newName:string):void {
      this.$emit('updateFieldName', newName)
      if (newName === CDPFieldsEnums.PRINCIPAL_LOCATION) {
        this.IsEditCity = true
        this.showOptions = false
        this.showLocation = true
        if (this.locations.length === 0) {
          // eslint-disable-next-line no-undef
          const newInput = structuredClone(this.city)
          const newCity = { ...newInput }
          this.locations.push(newCity)
        }
      } else {
        this.locations = []
        this.IsEditCity = false
        this.showLocation = false
      }
    },
    handleUpdateFieldType (newType:string):void {
      this.$emit('updateFieldType', newType)
      if (newType === typeField.SELECT && !this.showLocation) {
        this.showOptions = true
        this.showLocation = false
        this.locations = []
        this.IsEditCity = false
      } else if (newType === typeField.TEXTTAG) {
        this.showOptions = true
        this.showLocation = false
        this.locations = []
        this.IsEditCity = false
      } else {
        this.showOptions = false
      }
    },
    handleUpdateFieldLabel (index:number, newLabel:string):void {
      this.$emit('updateFieldLabel', index, newLabel)
    },
    handleUpdateFieldRequired (isRequired:boolean):void {
      this.$emit('updateFieldRequired', isRequired)
    },
    handleOptions (value:Array<string>):void {
      this.$emit('updateOptions', value)
    },
    handleupdateLocation (index:number, value:any):void {
      this.locations[index] = value
      this.$emit('updateLocation', this.locations)
    },
    addCity ():void {
      // eslint-disable-next-line no-undef
      const newInput = structuredClone(this.city)
      const uniqueId = Math.floor(Math.random() * 1000)
      const newField = { ...newInput, id: uniqueId }
      this.locations.push(newField)
    },
    removeCity (indexCity:number):void {
      this.locations.splice(indexCity, 1)
      this.$emit('updateLocation', this.locations)
    },
    removeField ():void {
      this.$emit('removeField')
    },
    validateFields (): boolean {
      return !(!this.selectedFieldItem || !this.optionFieldSelect || !this.selectedFieldLabel)
    }
  }
}
