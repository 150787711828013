<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       fill="none"
       viewBox="0 0 24 24"><path fill="#3AF69B"
                                 d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12Z"/><path fill="#000"
                                                                                                                     d="M11.995 20a7.984 7.984 0 0 1-4.417-1.33l-2.28.737h-.12a.408.408 0 0 1-.395-.31.392.392 0 0 1 .011-.218l.721-2.195A8 8 0 1 1 11.995 20ZM12 5.588a6.405 6.405 0 1 0 0 12.81 6.405 6.405 0 0 0 0-12.81Zm2.237 10.2a6.111 6.111 0 0 1-2.013-.552 8.334 8.334 0 0 1-3.362-2.978 3.817 3.817 0 0 1-.801-2.034 2.193 2.193 0 0 1 .737-1.65c.183-.155.416-.24.656-.24h.264c.204 0 .297 0 .433.337.136.336.553 1.361.6 1.457a.378.378 0 0 1 0 .368c-.057.114-.13.22-.216.313-.042.05-.088.096-.131.14a2.39 2.39 0 0 0-.165.18.32.32 0 0 0-.08.424c.297.505.667.963 1.097 1.361.467.426 1.01.76 1.6.985a.44.44 0 0 0 .481-.08c.19-.222.364-.457.52-.704a.384.384 0 0 1 .49-.136c.178.055 1.106.513 1.344.633l.103.05c.107.036.204.099.282.182.054.32.01.65-.129.945a1.852 1.852 0 0 1-1.32.952c-.129.031-.26.047-.392.047h.002Z"/></svg>
</template>

<script>
export default {
  name: 'WhatsappIconBack',
  props: {
    color: {
      type: String,
      default: '#000'
    }
  }
}
</script>

  <style>

  </style>
