<template>
  <svg :width="width"
       :height="height"
       viewBox="0 0 94 95"
       fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <path d="M6.75 1V12.48"
          :stroke="color"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"/>
    <path d="M1 6.73999H12.49"
          :stroke="color"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"/>
    <path d="M93.1 2H88V7.1H93.1V2Z"
          :fill="color"/>
    <path d="M23.1 89H18V94.1H23.1V89Z"
          :fill="color"/>
  </svg>

</template>
<script>
export default {
  name: 'SparkleIcon',
  props: {
    width: {
      type: Number,
      default: 105
    },
    height: {
      type: Number,
      default: 104
    },
    color: {
      type: String,
      default: 'black'
    }
  }
}
</script>
