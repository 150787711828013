import store from '@/store'
import { KeybeUserExtradata } from '@/models/KeybeUserExtradata'
import UserService from '@/services/UserService'
import AuthService from '@/services/AuthService'
import { EventBus } from '@/eventBus'
import AppUUIDsService from '@/services/AppUUIDsService'
import AppsUseCases from '@/modules/Apps/application/AppsUseCases'
import AppsController from '@/modules/Apps/infrastructure/controllers/Apps.controller'

export default class V6RedirectionController {
  static buildSelectedAppExtradata (selectedApp) {
    return {
      property: 'selectedApp',
      value: JSON.stringify(selectedApp)
    }
  }

  static isV6App (app) {
    if (!app?.version) {
      return false
    }
    return app?.version === 'v6'
  }

  static isV5App (app) {
    if (!app?.version) {
      return true
    }
    return app?.version === 'v5'
  }

  static getRedirectionExtradata () {
    const userExtradata = store.getters['UserStore/getUserExtradata']
    const extradata = new KeybeUserExtradata(userExtradata)

    const selectedApp = extradata.findExtradata('selectedApp')

    return {
      selectedApp: selectedApp ? JSON.parse(selectedApp.value) : null
    }
  }

  static async setRedirectionExtradata (selectedApp) {
    const selectedAppExtradata = this.buildSelectedAppExtradata(selectedApp)
    const response = await UserService.updateUserExtradata(selectedAppExtradata)

    if (!response.status) {
      return false
    }

    const userExtradata = store.getters['UserStore/getUserExtradata']
    const extradata = new KeybeUserExtradata(userExtradata)
    extradata.updateExtradata(selectedAppExtradata)
    const newExtradata = extradata.getExtradata()
    await store.dispatch('UserStore/setUserExtradata', newExtradata)
    return true
  }

  static validateRedirect (url, app) {
    const isV5Url = process.env.VUE_APP_V5_URL === url
    const isV6Url = process.env.VUE_APP_V6_URL === url

    const isV5App = V6RedirectionController.isV5App(app)
    const isV6App = V6RedirectionController.isV6App(app)

    console.log('isV5Url', isV5Url)
    console.log('isV6Url', isV6Url)

    console.log('isV5App', isV5App)
    console.log('isV6App', isV6App)

    const isKeybeApp = app?.uuid === AppUUIDsService.appKeybeAi()

    if (isV5Url && isV6App) {
      return {
        shouldRedirect: true,
        url: process.env.VUE_APP_V6_URL
      }
    }

    if (isV6Url && (isV5App && !isKeybeApp)) {
      return {
        shouldRedirect: true,
        url: `${process.env.VUE_APP_V5_URL}/admin`
      }
    }

    return {
      shouldRedirect: false,
      url: url
    }
  }

  static async handleRedirection (token: string, appId?: string) {
    await store.dispatch('UserStore/logout')
    store.commit('UserStore/SET_TOKEN', token, { root: true })
    store.commit('UserStore/SET_V4TOKEN', token, { root: true })
    const response = await AuthService.getInfoByToken()

    if (!response.status) {
      return false
    }

    const user = response?.data?.data?.user

    if (!user) {
      return false
    }

    store.commit('UserStore/SET_KEYBE_USER', user, { root: true })
    store.commit('UserStore/CHANGE_KEYBE_AUTH', true, { root: true })

    const userExtradata = new KeybeUserExtradata(user?.userData)
    await store.dispatch('UserStore/setUserExtradata', userExtradata.getExtradata(), { root: true })

    const data = this.getRedirectionExtradata()
    const { selectedApp } = data
    const appsController = new AppsController()
    if (!selectedApp && !appId) {
      const get = {
        token: token,
        search: '',
        offset: 0,
        limit: 50
      }
      if (store.getters['UserStore/isSuperAdmin']) {
        get.search = 'Keybe AI'
        await AppsUseCases.getApps(appsController, get)
        return true
      }
      await AppsUseCases.getApps(appsController, get)
      return true
    }
    let select = {
      name: '',
      uuid: '',
      token: ''
    }
    if (appId) {
      select = {
        name: '',
        uuid: appId,
        token: token
      }
    } else {
      select = {
        name: selectedApp?.name,
        uuid: selectedApp?.uuid,
        token: token
      }
    }

    await AppsUseCases.selectApp(appsController, select)
    EventBus.$emit('appModules')
    return true
  }
}
