














export default {
  name: 'statusIcon',
  props: {
    color: {
      type: String,
      default: '#D9D9D9'
    }
  }
}
