import { Task, TaskPriorityEnum } from '@/modules/tasks/domain/Task'
import Colors from '@/shared/domain/colors/Color'
import { mapState } from 'vuex'
import KeybeAvatar from '@/shared/infrastructure/ui/KeybeAvatar.vue'

export default {
  name: 'TaskTitle',
  props: {
    task: {
      required: true
    },
    isCardExpanded: {
      type: Boolean,
      default: false
    }
  },
  components: {
    KeybeAvatar
  },
  computed: {
    ...mapState('AppStore', ['advisers']),
    taskComputed (): Task {
      return this.task
    },
    title (): string {
      return this.taskComputed?.title || ''
    },
    priority (): string {
      return this.$t(`priority-${this.taskComputed?.priority}`)
    },
    priorityColor (): string {
      if (this.taskComputed?.priority === TaskPriorityEnum.LOW || this.taskComputed?.priority === TaskPriorityEnum.LOWEST) {
        return Colors.blue4
      }
      if (this.taskComputed?.priority === TaskPriorityEnum.HIGH || this.taskComputed?.priority === TaskPriorityEnum.HIGHEST) {
        return Colors.rojoBird
      }
      return Colors.kbGreen
    },
    responsibleName (): string {
      return this.taskComputed?.responsible?.name || ''
    },
    responsibleImg (): string {
      const adviserId = this.taskComputed?.responsible?.uuid
      const adviser = this.advisers.find((adviser: any) => adviser.uuid === adviserId)
      return adviser?.image || ''
    },
    contactName () :string {
      const name: string = this.taskComputed?.contact?.name
      if (!name) return 'N/A'
      return name.replaceAll('undefined', '')
    }
  }
}
