import { EventBus } from '@/eventBus'
import FeesUseCases from '@/modules/Fees/application/FeesUseCases'
import { RoleCommission } from '@/modules/Fees/domain/enum/CommissionRoles'
import { dataToGenerateCommission, dataToGetCommissionByUser, dataToGetUserPartners, dataToPayCommissionByUser, userPartnerGetter } from '@/modules/Fees/domain/FeesController'
import FeesController from '@/modules/Fees/infrastructure/controllers/Fees.controller'
import { currencyToUSD } from '@/utils/currencyToUSD'
import { DateTime } from 'luxon'
import { UserPartner } from '@/modules/Fees/domain/models/Fees'
import { mapState } from 'vuex'
import KeybeAutocomplete from '@/modules/DesignSystem/infrastructure/components/KeybeAutocomplete/KeybeAutocomplete/KeybeAutocomplete.vue'
import KeybeSelect from '@/modules/DesignSystem/infrastructure/components/KeybeSelect/KeybeSelect/KeybeSelect.vue'
import KeybeButton from '@/modules/DesignSystem/infrastructure/components/KeybeButton/KeybeButton.vue'
import Colors from '@/utils/Colors'
import Papa from 'papaparse'

export default {
  name: 'BillingCommissionsByUser',
  props: ['dateString'],
  components: {
    KeybeAutocomplete,
    KeybeSelect,
    KeybeButton
  },
  data: function () {
    return {
      Colors,
      feesController: new FeesController(),
      rerender: 0,
      totalHeight: 0,
      heightWithoutHeader: 0,
      width: 0,
      FeesData: [],
      userPartners: [],
      user: null,
      selectedMonth: null,
      generateCommissionMonth: null,
      generateCommissionYear: null,
      yearsArray: [
        { id: 2023, label: 2023 },
        { id: 2024, label: 2024 }
      ],
      monthsArray: [
        { id: 1, label: this.$t('nameMonths.january') },
        { id: 2, label: this.$t('nameMonths.february') },
        { id: 3, label: this.$t('nameMonths.march') },
        { id: 4, label: this.$t('nameMonths.april') },
        { id: 5, label: this.$t('nameMonths.may') },
        { id: 6, label: this.$t('nameMonths.june') },
        { id: 7, label: this.$t('nameMonths.july') },
        { id: 8, label: this.$t('nameMonths.august') },
        { id: 9, label: this.$t('nameMonths.september') },
        { id: 10, label: this.$t('nameMonths.october') },
        { id: 11, label: this.$t('nameMonths.november') },
        { id: 12, label: this.$t('nameMonths.december') }
      ],
      showGenerate: false
    }
  },
  computed: {
    ...mapState('UserStore', ['token']),
    searchPersons (search: string) {
      return this.userPartners.find((o: { [key: string]: any }) =>
        o.name.toLowerCase().includes(search.toLowerCase())
      )
    }
  },
  watch: {
    user () {
      if (this.user) {
        this.getFeesData()
      }
    },
    selectedMonth () {
      if (this.user) {
        this.getFeesData()
      }
    }
  },
  mounted () {
    this.calculateSize(true)
  },
  beforeDestroy () {
    window.removeEventListener('resize', () => this.calculateSize())
  },
  filters: {
    currencyUSD (value) {
      return currencyToUSD(value)
    }
  },
  methods: {
    async getUserPartners (): Promise<void> {
      try {
        const data: dataToGetUserPartners = {
          token: this.token
        }
        const response: userPartnerGetter = await FeesUseCases.getUserPartners(this.feesController, data)
        if (response.status) {
          const partners: UserPartner[] = response?.data || []
          this.userPartners = partners
        }
      } catch (e) {
        console.error(e)
      }
    },
    getMonthName (month) {
      const months = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
      ]
      const monthIndex = parseInt(month, 10) - 1 // Convertir a número y ajustar a índice
      return months[monthIndex].toLocaleLowerCase() || 'Unknown' // Devolver el mes correspondiente o "Unknown" si no es válido
    },
    getRoleLabel (role) {
      switch (role) {
        case RoleCommission.KB_KAM:
          return 'Key Account Manager'
        case RoleCommission.KB_CLOSER:
          return 'Closer'
        case RoleCommission.PARTNER_PRO:
          return 'Partner Pro'
        case RoleCommission.PARTNER_DEVELOPER:
          return 'Partner Developer'
        case RoleCommission.PARTNER_REFERRAL:
          return 'Partner Referral'
        case RoleCommission.PARTNER_REFERRAL_PRO:
          return 'Partner Referral Pro'
        default:
          return 'Desconocido'
      }
    },
    formatMonthName (dateString) {
      const date = DateTime.fromISO(dateString)
      return date.toFormat('MMMM').toLocaleLowerCase()
    },
    formatDate (dateString) {
      const date = new Date(dateString)
      const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`
      return formattedDate
    },
    callSearch () {
      if (!this.user) {
        EventBus.$emit('toast', 'error', this.$t('dialogAllFieldsAreRequired'))
        return
      }
      if (!this.selectedMonth) {
        EventBus.$emit('toast', 'error', this.$t('dialogAllFieldsAreRequired'))
        return
      }
      this.getFeesData()
    },
    async generateCommission (): Promise<void> {
      if (!this.generateCommissionMonth || !this.generateCommissionYear) {
        EventBus.$emit('toast', 'error', this.$t('dialogAllFieldsAreRequired'))
        return
      }
      this.FeesData = []
      try {
        const data:dataToGenerateCommission = {
          token: this.token,
          month: this.generateCommissionMonth,
          year: this.generateCommissionYear
        }
        const response = await FeesUseCases.generateCommission(this.feesController, data)
        if (response.status) {
          EventBus.$emit('toast', 'success', this.$t('commissionGeneratedSuccessfully'))
        }
      } catch (e) {
        console.error(e)
      }
    },
    async getFeesData (): Promise<void> {
      if (!this.selectedMonth || !this.user) {
        return
      }
      this.FeesData = []
      try {
        const data:dataToGetCommissionByUser = {
          token: this.token,
          userId: this.user,
          month: this.selectedMonth
        }
        const response = await FeesUseCases.getCommissionByUser(this.feesController, data)
        if (response.status) {
          this.FeesData = response.data
        } else {
          EventBus.$emit('toast', 'error', this.$t('tableEmpty'))
        }
      } catch (e) {
        EventBus.$emit('toast', 'error', this.$t('tableEmpty'))
        console.error(e)
      }
    },
    async getCommissionByUserCsv (): Promise<void> {
      if (!this.selectedMonth || !this.user) {
        EventBus.$emit('toast', 'error', this.$t('dialogAllFieldsAreRequired'))
        return
      }
      try {
        if (this.FeesData.invoices && this.FeesData.invoices.length > 0) {
          const csv = Papa.unparse(this.FeesData.invoices)
          const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.setAttribute('download', 'data.csv')
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
        } else {
          EventBus.$emit('toast', 'error', this.$t('tableEmpty'))
        }
        // const data:dataToGetCommissionByUser = {
        //   token: this.token,
        //   userId: this.user,
        //   month: this.selectedMonth
        // }
        // const response = await FeesUseCases.getCommissionByUserCsv(this.feesController, data)
        // if (response.status) {
        //   EventBus.$emit('toast', 'success', this.$t('updateDataSuccess'))
        // } else {
        //   EventBus.$emit('toast', 'error', this.$t('tableEmpty'))
        // }
      } catch (e) {
        EventBus.$emit('toast', 'error', this.$t('tableEmpty'))
        console.error(e)
      }
    },
    async payCommission (): Promise<void> {
      if (!this.FeesData) {
        return
      }
      try {
        const data:dataToPayCommissionByUser = {
          token: this.token,
          state: 'PAID',
          commissionId: this.FeesData._id
        }
        const response = await FeesUseCases.payCommissionByUser(this.feesController, data)
        if (response.status) {
          EventBus.$emit('toast', 'success', this.$t('updateDataSuccess'))
        }
      } catch (e) {
        console.error(e)
      }
    },
    calculateSize (fullscreen = false) {
      if (fullscreen) {
        const clientHeight = document.documentElement.clientHeight
        const clientWidth = document.documentElement.clientWidth
        document.documentElement.style.setProperty(
          '--floating-screen-height',
          `${clientHeight - 30}px`
        )
        document.documentElement.style.setProperty(
          '--floating-screen-width',
          `${clientWidth - 50}px`
        )

        // set top and left to 0
        const floatingWindow = document.getElementById('KeybeFloatingBox')
        floatingWindow.style.top = '0px'
        floatingWindow.style.left = '0px'

        EventBus.$emit('FloatingWindow-resize')
        return
      }

      const clientHeight = document.documentElement.clientHeight
      this.totalHeight = clientHeight * 0.8097
      this.heightWithoutHeader = clientHeight * 0.8097 - 30
      document.documentElement.style.setProperty(
        '--floating-screen-height',
        `${this.heightWithoutHeader}px`
      )

      const clientWidth = document.documentElement.clientWidth
      this.width = clientWidth * 0.8
      document.documentElement.style.setProperty(
        '--floating-screen-width',
        `${this.width}px`
      )

      EventBus.$emit('FloatingWindow-resize')
    }
  },
  created () {
    this.getUserPartners()
  }
}
