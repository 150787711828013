import { CDPFieldsEnums } from '@/modules/CDP/shared/contacts/domain/enums/CDPFieldsEnums'

export default {
  props: {
    value: {},
    placeholder: {
      type: String
    },
    type: {
      type: String,
      default: CDPFieldsEnums.TEXT
    },
    seamless: {
      type: Boolean,
      default: false
    },
    styles: {
      type: String
    },
    outlined: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String
    },
    centered: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    max: {
      type: Number
    },
    min: {
      type: Number
    },
    clear: {
      type: Boolean,
      default: false
    },
    focus: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: false
    },
    minWidth: {
      type: String
    },
    autofocus: {
      type: Boolean,
      default: true
    },
    transparent: {
      type: Boolean,
      default: false
    },
    limit: {
      type: Number,
      required: false
    },
    BackgroundColor: {
      type: String
    }
  },
  computed: {
    textLength () {
      return this.text?.length || 0
    }
  },
  mounted () {
    if (this.autofocus) {
      this.$refs.input.focus()
    }
  },
  data () {
    return {
      text: this.value,
      valid: true,
      focused: false
    }
  },
  methods: {
    input () {
      if (this.type === 'email') {
        const re =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        this.valid = this.text?.length && re.test(this.text)
      }

      if (this.max) {
        this.text = this.text.slice(0, this.max)
      }

      if (this.type === CDPFieldsEnums.NUMBER) {
        this.text = +this.text
      }
      this.$emit('input', this.text)
    },
    save () {
      if (this.focused) this.$emit('save', this.text)
    }
  },
  watch: {
    value () {
      if (this.value) {
        this.text = this.max ? this.value.slice(0, this.max) : this.value
      } else {
        this.text = ''
      }
    },
    focus () {
      if (this.focus) this.$refs.input.focus()
    },
    clear () {
      if (this.clear) this.text = ''
    }
  }
}
