<template>
  <div class="FieldFormOptions__fieldsContainer">
    <div class="FieldFormOptions__fieldsContainer--switchContainer">
      <span class="FieldFormOptions__fieldsContainer--label">{{ $t(field.name) }}</span>

      <KeybeText
        class="FieldFormOptions__fieldsContainer__message-box"
        v-model="localField.label"
        :seamless="true"
        :autofocus="false"/>
      <KBCheck
        class="FieldFormOptions__fieldsContainer--switch"
        :value="true"
        :disabled="true"
      />
    </div>
  </div>
</template>

<script>
import FieldFormOptions from './FieldFormOptions'
export default FieldFormOptions
</script>

<style lang="scss" scoped>
@import './FieldFormOptions.scss';
</style>
