import ClientsDashboardService from '../services/ClientsDashboardService'
// import { GenericResponse } from '@/models/apis/GenericResponse'

export class ClientsDashboardController {
  static async aliveTest () {
    console.warn('aliveTest')
    return 'alive'
  }

  static async getTotalConversations (weeks): Promise<any> {
    const response = await ClientsDashboardService.getTotalConversations(weeks)
    if (response) {
      console.warn('Total and average conversations', response.data.totalConversations)
      const filteredResult = response.data.totalConversations.filter(conversations => conversations.Period.includes('CurrentPeriod'))
      return filteredResult
    } else {
      console.error('Fix me', response)
    }
  }

  static async getClosedConversations (weeks): Promise<any> {
    const response = await ClientsDashboardService.getClosedConversations(weeks)
    if (response) {
      console.warn('Closed Conversations', response.data.closedConversations)
      const filteredResult = response.data.closedConversations.filter(conversations => conversations.Period.includes('CurrentPeriod'))
      return filteredResult
    } else {
      console.error('Fix me', response)
    }
  }

  static async getOpenConversations (weeks): Promise<any> {
    const response = await ClientsDashboardService.getOpenConversations(weeks)
    if (response) {
      // console.warn('Open Conversations', response.data)
      return response.data.openConversations
    } else {
      console.error('Fix me', response)
    }
  }

  static async getConversationsPerAgent (weeks): Promise<any> {
    const response = await ClientsDashboardService.getConversationsPerAgent(weeks)
    if (response) {
      // console.warn('Conversations Per Agent', response.data)
      return response.data.table
    } else {
      console.error('Fix me', response)
    }
  }

  static async getNewConversations (weeks): Promise<any> {
    const response = await ClientsDashboardService.getNewConversations(weeks)
    if (response) {
      // console.warn('New Conversations', response.data.historicNewConversations)
      return response.data
    } else {
      console.error('Fix me', response)
    }
  }

  static async getHistoricConversations (weeks): Promise<any> {
    const response = await ClientsDashboardService.getHistoricConversations(weeks)
    if (response) {
      // console.warn('Historic Conversations', response.data.historicNewConversations)
      return response.data
    } else {
      console.error('Fix me', response)
    }
  }

  static async getNewUsers (weeks): Promise<any> {
    const response = await ClientsDashboardService.getNewUsers(weeks)
    if (response) {
      return response.data
    } else {
      console.error('Fix me', response)
    }
  }

  static async getConversationsVolume (weeks): Promise<any> {
    const response = await ClientsDashboardService.getConversationsVolume(weeks)
    if (response) {
      // console.warn('New Conversations', response.data.historicNewConversations)
      return response.data.histogram
    } else {
      console.error('Fix me', response)
    }
  }

  static async getConversationsPerChannel (weeks): Promise<any> {
    const response = await ClientsDashboardService.getConversationsPerChannel(weeks)
    if (response) {
      // console.warn('Conversations Per Channel', response.data)
      return response.data
    } else {
      console.error('Fix me', response)
    }
  }

  // Events
  static async getEventList (): Promise<any> {
    const response = await ClientsDashboardService.getEventList()
    if (response) {
      // console.warn('Events: ', response.data.events.rows)
      return response.data.events.rows
    } else {
      console.error('Fix me', response)
    }
  }

  static async getAssistancePercentage (eventId): Promise<any> {
    const response = await ClientsDashboardService.getAssistancePercentage(eventId)
    if (response) {
      // console.log('Getting assistance percentage', response.data.eventAssistance)
      return response.data.eventAssistance
    } else {
      console.error('Fix me', response)
    }
  }

  static async getPublicEvents (): Promise<any> {
    const response = await ClientsDashboardService.getPublicEvents()
    if (response) {
      // console.log('Getting public events', response.data.publicEvents)
      return response.data.publicEvents
    } else {
      console.error('Fix me', response)
    }
  }

  static async getEventAssistanceTimeline (eventId): Promise<any> {
    const response = await ClientsDashboardService.getEventAssistanceTimeline(eventId)
    if (response) {
      // console.log('Getting event assistants timeline checkin', response.data.data)
      return response.data.data
    } else {
      console.error('Fix me', response)
    }
  }

  static async getTicketRewards (eventId): Promise<any> {
    const response = await ClientsDashboardService.getTicketRewards(eventId)
    if (response) {
      // console.log('Getting ticket rewards', response.data.eventPayment)
      return response.data.eventPayment
    } else {
      console.error('Fix me', response)
    }
  }

  static async getEventsAssistants (eventId): Promise<any> {
    const response = await ClientsDashboardService.getEventsAssistants(eventId)
    if (response) {
      // console.log('Getting events assistants', response.data.eventAssistance)
      return response.data.eventAssistance
    } else {
      console.error('Fix me', response)
    }
  }

  static async getCentralMetrics (): Promise<any> {
    const response = await ClientsDashboardService.getCentralMetrics()
    if (response) {
      // console.log('Getting new metrics response', response.data)
      return response.data
    } else {
      console.error('Fix me', response)
    }
  }

  static async getTableCentralMetrics (): Promise<any> {
    const response = await ClientsDashboardService.getCentralMetrics()
    if (response) {
      // console.log('Getting new metrics response', response.data.advisors)
      return response.data.advisors
    } else {
      console.error('Fix me', response)
    }
  }

  static async getConversationsState (): Promise<any> {
    const advisorList = null
    const response = await ClientsDashboardService.getConversationsState(advisorList)
    if (response) {
      // console.log('Getting new metrics response', response.data.conversationsPerState)
      return response.data.conversationsPerState
    } else {
      console.error('Fix me', response)
    }
  }

  static async getAverageTalkVolume (data): Promise<any> {
    const response = await ClientsDashboardService.getAverageTalkVolume(data)
    if (response) {
      // console.log('Getting new metrics response', response.data)
      return response.data
    } else {
      console.error('Fix me', response)
    }
  }

  static async getGlobalTimeseV5 (weeks): Promise<any> {
    const response = await ClientsDashboardService.getGlobalTimeseV5(weeks)
    if (response) {
      return response.data
    } else {
      console.error('Fix me', response)
    }
  }

  static async getConversationTimeV5 (weeks): Promise<any> {
    const response = await ClientsDashboardService.getConversationTimeV5(weeks)
    if (response) {
      return response.data
    } else {
      console.error('Fix me', response)
    }
  }

  static async getFirstTimeV5 (weeks): Promise<any> {
    const response = await ClientsDashboardService.getFirstTimeV5(weeks)
    if (response) {
      return response.data
    } else {
      console.error('Fix me', response)
    }
  }

  static async getWaitingTimeV5 (weeks): Promise<any> {
    const response = await ClientsDashboardService.getWaitingTimeV5(weeks)
    if (response) {
      return response.data
    } else {
      console.error('Fix me', response)
    }
  }

  static async getV4Metrics (weeks): Promise<any> {
    const response = await ClientsDashboardService.getV4Metrics(weeks)
    if (response) {
      // console.log('Getting v4 metrics response', response.data)
      return response.data
    } else {
      console.error('Fix me', response)
    }
  }

  static async getBarEndReason (weeks): Promise<any> {
    const response = await ClientsDashboardService.getBarEndReason(weeks)
    if (response) {
      // console.log('Getting v4 end reasons bar', response.data)
      return response.data
    } else {
      console.error('Fix me', response)
    }
  }

  static async getConversationsReport (data): Promise<any> {
    const response = await ClientsDashboardService.getConversationsReport(data)
    if (response) {
      // console.log('Getting v4 metrics report', response.data)
      return response.data
    } else {
      console.error('Fix me', response)
    }
  }

  static async getMessagesReport (data): Promise<any> {
    const response = await ClientsDashboardService.getMessagesReport(data)
    if (response) {
      console.log('Getting messages report', response.data)
      return response.data
    } else {
      console.error('Fix me', response)
    }
  }

  static async getAdvisorsPerformanceDetails (data): Promise<any> {
    const response = await ClientsDashboardService.getAdvisorsPerformanceDetails(data)
    if (response) {
      // console.log('Getting v4 table report', response.data.response)
      return response.data.response
    } else {
      console.error('Fix me', response)
    }
  }

  static async getDeals (data): Promise<any> {
    const response = await ClientsDashboardService.getDeals(data)
    if (response) {
      return response.data.results
    } else {
      console.error('Fix me', response)
    }
  }

  static async getFunnelCRM (data): Promise<any> {
    const response = await ClientsDashboardService.getFunnelCRM(data)
    if (response) {
      return response.data
    } else {
      console.error('Fix me', response)
    }
  }

  static async getCRMBenchmark (data): Promise<any> {
    const response = await ClientsDashboardService.getCRMBenchmark(data)
    if (response) {
      return response.data.results[0]
    } else {
      console.error('Fix me', response)
    }
  }

  static async getCRMDealsWon (data): Promise<any> {
    const response = await ClientsDashboardService.getCRMDealsWon(data)
    if (response) {
      return response.data.results[0]
    } else {
      console.error('Fix me', response)
    }
  }

  static async getSalesPerformance (data): Promise<any> {
    const response = await ClientsDashboardService.getSalesPerformance(data)
    if (response) {
      return response.data.results
    } else {
      console.error('Fix me', response)
    }
  }

  static async getSalesByAdvisor (data): Promise<any> {
    const response = await ClientsDashboardService.getSalesByAdvisor(data)
    if (response) {
      return response.data.results
    } else {
      console.error('Fix me', response)
    }
  }

  static async getCSAT (data): Promise<any> {
    const response = await ClientsDashboardService.getCSAT(data)
    if (response) {
      return response.data.data
    } else {
      console.error('Fix me', response)
    }
  }

  static async getAppPipelines (): Promise<any> {
    const response = await ClientsDashboardService.getAppPipelines()
    if (response) {
      return response.data.results
    } else {
      console.error('Fix me', response)
    }
  }

  static async getFirstPipeline (): Promise<any> {
    const response = await ClientsDashboardService.getAppPipelines()
    if (response) {
      if (response.data.results) return response.data.results[0]
    } else {
      console.error('Fix me', response)
    }
  }
}
