export enum QuickResponseTypeEnum {
  ALL = 'all',
  TEXT = 'text',
  IMAGE = 'image',
  VIDEO = 'video',
  PDF = 'pdf'
}

export type QuickResponseType =
QuickResponseTypeEnum.ALL |
QuickResponseTypeEnum.TEXT |
QuickResponseTypeEnum.IMAGE |
QuickResponseTypeEnum.VIDEO |
QuickResponseTypeEnum.PDF

export interface QuickResponse {
  _id: string,
  appUUID: string,
  name: string,
  value: string,
  mimetype: string,
  type: string,
  user: string,
  createdAt: string,
  active: boolean
}
