import axios from 'axios'
import store from '@/store/index'
import { RestResponseFactory } from '@/models/apis/RestResponse'

export default class BillingService {
  static async getPackage () {
    try {
      const token = store.getters['UserStore/getToken']
      const appId = store.getters['AppStore/getSelectedApp']?.uuid
      const response = await axios.get(
        `${process.env.VUE_APP_BILLING_URL}package/plan/${appId}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
          }
        }
      )
      return new RestResponseFactory().process({ response })
    } catch (e) {
      const errorResponse = new RestResponseFactory().process({ response: { status: 500 } })
      errorResponse.data = e.response?.data
      return errorResponse
    }
  }

  static async getPlans () {
    try {
      const token = store.getters['UserStore/getToken']
      const response = await axios.get(
         `${process.env.VUE_APP_BILLING_URL}plans`,
         {
           headers: {
             Authorization: 'Bearer ' + token
           }
         }
      )
      return new RestResponseFactory().process({ response })
    } catch (e) {
      const errorResponse = new RestResponseFactory().process({ response: { status: 500 } })
      errorResponse.data = e.response.data
      return errorResponse
    }
  }

  static async getAppConfig () {
    try {
      const token = store.getters['UserStore/getToken']
      const appId = store.getters['AppStore/getSelectedApp']?.uuid

      if (!appId) return

      const response = await axios.get(
        `${process.env.VUE_APP_BILLING_URL}app-config/${appId}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
          }
        }
      )
      return new RestResponseFactory().process({ response })
    } catch (e) {
      const errorResponse = new RestResponseFactory().process({ response: { status: 500 } })
      errorResponse.data = e.response?.data
      return errorResponse
    }
  }
}
