import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import LocationUseCases from '@/shared/infrastructure/ui/details/sections/Location/application/LocationUseCases'
import LocationController from '@/shared/infrastructure/ui/details/sections/Location/infrastructure/controllers/Location.controller'
// TODO: migrate to new structure
import ConversationsConfigController from '@/components/configuration/controllers/ConversationsConfigController'

import Colors from '@/shared/domain/colors/Color'
import { EventBus } from '@/eventBus'
import moment from 'moment/moment'
import ConversationsConfigService from '@/components/configuration/Services/ConversationsConfigService'
import KBCheck from '@/shared/infrastructure/ui/inputs/select/KBCheck.vue'
import KBSmallSelector from '@/shared/infrastructure/ui/inputs/select/KBSmallSelector.vue'
import KBTimeSelector from '@/shared/infrastructure/ui/pickers/KBTimeSelector.vue'
import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'
import KBTextarea from '@/shared/infrastructure/ui/inputs/text/KBTextarea.vue'
import KBText from '@/shared/infrastructure/ui/inputs/text/KBText.vue'
import InboxUseCases from '@/modules/inbox/application/InboxUseCases'
import InboxController from '@/modules/inbox/infrastructure/Inbox.controller'
import { mapState } from 'vuex'
import { dataToGetCountries } from '@/shared/infrastructure/ui/details/sections/Location/domain/LocationController'
import { CDPFieldsEnums } from '@/modules/CDP/shared/contacts/domain/enums/CDPFieldsEnums'
const defaultWorkingHours = {
  days: [
    {
      code: 0,
      isClosed: false,
      isOpen: true,
      schedules: [
        {
          from: '00:00',
          until: '00:00'
        }
      ]
    },
    {
      code: 1,
      isClosed: false,
      isOpen: true,
      schedules: [
        {
          from: '00:00',
          until: '00:00'
        }
      ]
    },
    {
      code: 2,
      isClosed: false,
      isOpen: true,
      schedules: [
        {
          from: '00:00',
          until: '00:00'
        }
      ]
    },
    {
      code: 3,
      isClosed: false,
      isOpen: true,
      schedules: [
        {
          from: '00:00',
          until: '00:00'
        }
      ]
    },
    {
      code: 4,
      isClosed: false,
      isOpen: true,
      schedules: [
        {
          from: '00:00',
          until: '00:00'
        }
      ]
    },
    {
      code: 5,
      isClosed: false,
      isOpen: true,
      schedules: [
        {
          from: '00:00',
          until: '00:00'
        }
      ]
    },
    {
      code: 6,
      isClosed: false,
      isOpen: true,
      schedules: [
        {
          from: '00:00',
          until: '00:00'
        }
      ]
    }
  ]
}

export default {
  name: 'ScheduleConfig',
  components: {
    KBSmallSelector,
    KBCheck,
    KBTimeSelector,
    KeybeIcon,
    KBTextarea,
    KBText
  },
  data () {
    return {
      timeZones: [],
      timeZone: { label: 'America/Bogota', offset: '−05:00', title: 'America/Bogota GMT-05:00' },
      rerender: 0,
      chatConfig: null,
      daysLabels: [this.$t('sunday'), this.$t('monday'), this.$t('tuesday'), this.$t('wednesday'), this.$t('thursday'), this.$t('friday'), this.$t('saturday')],
      workingDays: [],
      KeybeIconType,
      outOfServiceMessage: '',
      inactivityMessage: '',
      awaitingInactivityMessage: false,
      awaitingOutOfServiceMessage: false,
      inactivitySeconds: '0',
      loading: false,
      inboxController: new InboxController(),
      locationController: new LocationController(),
      inboxList: [],
      selectedInbox: null,
      allInboxes: false,
      useInboxConfig: false,
      countrySearch: '',
      CDPFieldsEnums
    }
  },
  async mounted () {
    await this.getChatConfig()
    await this.getTimeZones()
  },
  computed: {
    ...mapState('UserStore', ['user', 'token']),
    ...mapState('AppStore', ['selectedApp']),
    Colors () {
      return Colors
    }
  },
  methods: {
    selectTimeZone (zone) {
      this.timezone = { label: zone.label, offset: zone.offset }
    },
    setInboxConfig () {
      this.loading = true
      setTimeout(() => {
        const currentInbox = this.chatConfig?.inbox?.rules?.find(inbox => inbox.key === this.selectedInbox?.key) || null

        let days = currentInbox?.workingHours?.days || defaultWorkingHours.days

        if (days.length === 0) {
          days = [...defaultWorkingHours.days]
        }

        this.workingDays = days.map(day => {
          const newDay = { ...day }
          newDay.isOpen = !day.isClosed
          if (!day?.schedules || day?.schedules.length <= 0) {
            newDay.schedules = [{ from: '00:00', until: '00:00' }]
          }
          return newDay
        })

        this.loading = false
      }, 100)
    },
    setNoInboxConfig () {
      this.workingDays = this.chatConfig?.workingHours?.days.map(day => {
        const newDay = { ...day }
        newDay.isOpen = !day.isClosed
        if (!day?.schedules || day?.schedules.length <= 0) {
          newDay.schedules = [{ from: '00:00', until: '00:00' }]
        }
        return newDay
      })

      if (this.chatConfig?.inactivityMessage) {
        this.inactivityMessage = this.chatConfig?.inactivityMessage
      }

      if (this.chatConfig?.inactivitySeconds) {
        this.inactivitySeconds = this.chatConfig?.inactivitySeconds.toString()
      }

      if (this.chatConfig?.workingHours?.message) {
        this.outOfServiceMessage = this.chatConfig?.workingHours?.message
      }
    },
    async getChatConfig () {
      this.$emit('start-loading')
      await this.getInboxes()
      this.chatConfig = await ConversationsConfigController.getConfig()
      if (this.chatConfig.inbox) {
        this.useInboxConfig = this.chatConfig?.inbox?.useInbox
      }
      if (this.useInboxConfig) {
        this.setInboxConfig()
      } else {
        this.setNoInboxConfig()
      }
      const timeZoneConfig = this.chatConfig?.timeZone
      if (timeZoneConfig) {
        this.timeZone = {
          label: timeZoneConfig.label,
          offset: timeZoneConfig.offset,
          title: timeZoneConfig.title || `${timeZoneConfig.label} GMT${timeZoneConfig.offset}`
        }
      }
      this.rerender++
      this.$emit('stop-loading')
    },
    addSchedule (day) {
      day.schedules.push({ from: '00:00', until: '00:00' })
    },
    removeSchedule (day, scheduleIndex) {
      day.schedules.splice(scheduleIndex, 1)
      this.saveConfig()
    },
    isBefore (from, until) {
      if (!from || !until) return true
      const fromMoment = moment(from, 'HH:mm')
      const untilMoment = moment(until, 'HH:mm')
      return fromMoment.isBefore(untilMoment)
    },
    checkSchedule (day) {
      // check schedule
      let badSchedule = false
      if (day.isClosed) {
        return day.schedules.forEach((schedule, index) => {
          if (!this.isBefore(index > 0 ? day.schedules[index - 1].until : '00:00', schedule.from)) badSchedule = true
          if (!this.isBefore(schedule.from, schedule.until)) badSchedule = true
        })
      }
      if (badSchedule) {
        EventBus.$emit('toast', 'error', this.$t('badSchedule'))
      }
      return !badSchedule
    },
    updateSchedule (day) {
      if (this.checkSchedule(day)) {
        this.saveConfig()
      }
    },
    async saveConfig () {
      if (this.loading) return

      const newWorkingDays = this.workingDays.map(day => {
        const newDay = { ...day }
        newDay.isClosed = !day.isOpen
        delete newDay.isOpen
        return newDay
      })

      let dataToUpdate = {}

      if (this.useInboxConfig) {
        // get index of current inbox in rules array
        const index = this.chatConfig?.inbox?.rules?.findIndex(inbox => inbox.key === this.selectedInbox?.key)
        const newConfig = {
          name: this.selectedInbox?.name,
          key: this.selectedInbox?.key,
          workingHours: {
            days: newWorkingDays
          }
        }

        const newRules = [...this.chatConfig?.inbox?.rules]
        newRules[index] = newConfig

        dataToUpdate = {
          timeZone: this.timeZone,
          inbox: {
            useInbox: this.useInboxConfig,
            rules: newRules
          }
        }

        this.chatConfig = {
          ...this.chatConfig,
          inbox: {
            ...this.chatConfig?.inbox,
            rules: newRules
          }
        }
      } else {
        dataToUpdate = {
          inactivityMessage: this.inactivityMessage,
          timeZone: this.timeZone,
          inactivitySeconds: parseFloat(this.inactivitySeconds),
          workingHours: {
            message: this.outOfServiceMessage,
            days: newWorkingDays
          }
        }
      }
      await ConversationsConfigService.updateConversationsConfigV5(dataToUpdate)
    },
    async getInboxes () {
      const data = {
        token: this.token,
        appUUID: this.selectedApp?.uuid
      }

      const inboxes = await InboxUseCases.getInbox(this.inboxController, data)

      this.inboxList = inboxes
      this.selectedInbox = inboxes[0]
    },
    async getTimeZones () {
      const data: dataToGetCountries = {
        token: this.token,
        app: this.selectedApp?.uuid,
        query: {
          offset: 0,
          limit: 20,
          search: this.countrySearch
        }
      }
      const response = await LocationUseCases.getCountries(this.locationController, data)
      if (response?.status) {
        this.countries = response?.countries

        this.timeZones = this.countries.map(country => {
          const titleWithGMT = `${country.timezones[0].zoneName} ${country.timezones[0].gmtOffsetName}`
          return {
            label: country.timezones[0].zoneName,
            offset: country.timezones[0].gmtOffsetName,
            title: titleWithGMT.replace('UTC', 'GMT')
          }
        })
        this.timeZones.sort((a, b) => a.title.localeCompare(b.title))
      }
    },
    async handleTimeZoneChange (selectedTimeZone) {
      this.$emit('start-loading')
      await this.saveConfig()
      this.$emit('stop-loading')
    }
  },
  watch: {
    inactivityMessage () {
      if (!this.awaitingInactivityMessage) {
        setTimeout(async () => {
          await this.saveConfig()
          this.awaitingSearch = false
        }, 800)
      }
      this.awaitingSearch = true
    },
    outOfServiceMessage () {
      if (!this.awaitingOutOfServiceMessage) {
        setTimeout(async () => {
          await this.saveConfig()
          this.awaitingOutOfServiceMessage = false
        }, 800)
      }
      this.awaitingOutOfServiceMessage = true
    },
    selectedInbox () {
      if (!this.useInboxConfig) {
        return
      }
      this.setInboxConfig()
    }
  }
}
