export const CommissionStore = {
  namespaced: true,
  state: {
    onDetail: false,
    onCreate: false,
    onCategory: false,
    onCreateData: null,
    idCompany: null,
    Commissiondate: null
  },
  mutations: {
    SET_EDITING_OBJECT (state, payload) {
      state[payload.key] = payload.value
    }
  }
}
