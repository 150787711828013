<template>
  <svg width="62"
       height="54"
       viewBox="0 0 62 54"
       fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <path
      d="M30.9639 53.9971C23.421 53.9971 15.878 54.0004 8.33507 53.9939C6.75406 53.9939 5.21508 53.727 3.84746 52.8875C1.74268 51.5946 0.439717 49.7388 0.0905361 47.2751C0.02264 46.7991 0.0129405 46.3134 0.0129405 45.8309C0.00970735 33.2874 0.02264 20.747 7.89778e-06 8.20663C-0.00322525 5.47278 0.986119 3.26318 3.15233 1.57783C4.57492 0.474641 6.23676 0.0243589 7.98266 0.0243589C23.3369 -0.00137155 38.6911 -0.0142368 54.0454 0.0243589C58.7173 0.0372241 61.9892 3.49153 61.9925 8.25809C62.0022 19.5409 61.9957 30.8205 61.9957 42.1033C61.9957 43.364 62.0054 44.6216 61.9957 45.8824C61.9472 50.6651 58.5718 53.9939 53.7544 53.9971C46.1565 54.0036 38.5618 53.9971 30.9639 53.9971ZM23.9059 35.7929C23.8186 35.7446 23.7572 35.7318 23.7281 35.6932C22.7873 34.5224 21.8658 33.3324 20.9088 32.1777C19.4215 30.3863 17.3491 30.3798 15.8909 32.1745C13.437 35.1946 11.0121 38.2405 8.56139 41.267C7.73047 42.293 7.41039 43.4252 7.99236 44.628C8.59049 45.8599 9.70269 46.3263 11.0542 46.3263C24.3909 46.3198 37.7277 46.3166 51.0644 46.3327C52.3221 46.3327 53.2985 45.8824 53.8773 44.8082C54.4851 43.6825 54.3461 42.56 53.6024 41.4793C48.9014 34.6479 44.2134 27.8132 39.5382 20.9625C38.1965 18.9973 35.6843 18.4473 34.1001 20.9528C32.768 23.0595 31.284 25.0729 29.8711 27.1281C27.8827 30.0164 25.8943 32.9078 23.9059 35.7929ZM13.5049 19.2997C16.6152 19.335 19.4022 16.6912 19.3472 13.5425C19.289 10.1654 17.0517 7.9043 13.5986 7.71132C10.4657 7.53443 7.71431 10.3197 7.70461 13.4878C7.69491 16.6301 10.3267 19.2643 13.5049 19.2997Z"
      :fill="color"/>
  </svg>
</template>
<script>
export default {
  name: 'ImageIcon',
  props: {
    color: {
      type: String,
      default: '#8898A9'
    }
  }
}
</script>
<style>
</style>
