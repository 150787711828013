import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'
import PlanHeader from '../PlanHeader/PlanHeader.vue'
import PlanSummaryPayment from '../PlanSummaryPayment/PlanSummaryPayment.vue'
import BillingPlansCards from '@/modules/consumptions/infrastructure/ui/components/BillingPlansCards/BillingPlansCards.vue'
// import KBButton from '@/shared/infrastructure/ui/buttons/KBButton.vue'
import BillingPlansBikyCards from '../BillingPlansBikyCards/BillingPlansBikyCards.vue'
import BillingPlansTotalCards from '../BillingPlansTotalCards/BillingPlansTotalCards.vue'
import ClosedIcon from '@/shared/infrastructure/ui/icons/SvgComponents/ClosedIcon.vue'
import KeybeButton from '@/modules/DesignSystem/infrastructure/components/KeybeButton/KeybeButton.vue'
import BikyStoreController from '@/modules/bky/infrastructure/persistence/BikyStore.controller'

export default {
  name: 'PlanSummary',
  components: {
    PlanSummaryPayment,
    PlanHeader,
    KeybeIcon,
    // KBButton,
    BillingPlansBikyCards,
    BillingPlansTotalCards,
    BillingPlansCards,
    ClosedIcon,
    KeybeButton
  },
  props: {
    tier: {
      type: String,
      required: true
    },
    color: {
      type: String
    },
    plan: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      openError: false,
      openDetail: false,
      colorModal: '#3AF69B',
      colorError: '#F7685B',
      totalAmount: 0,
      itemDetail: null,
      keybePlanToActivate: {},
      bikyPlanToActivate: {},
      KeybeIconType
    }
  },
  mounted () {
    this.bikyPlanToActivate = {
      code: this.tier,
      cost: '',
      color: this.color
    }
    this.keybePlanToActivate = this.plan
  },
  methods: {
    callMain () {
      this.openDetail = true
    },
    callError () {
      this.openError = true
    },
    goToKeybeMain () {
      this.$emit('goToMain')
    },
    goBack () {
      this.$emit('goToPlans')
    },
    setAmount (amount) {
      this.totalAmount = amount
    }
  },
  computed: {
    planTitle (): string {
      return this.plan?.title || ''
    },
    planDescription (): string {
      return this.plan?.subtitle || ''
    },
    changePlanButton (): string {
      return this.$t('changePlan') || ''
    },
    tierTitle (): string {
      const titles: {
        [key: string]: string
      } = {
        essential: `Biky - ${this.$t('essential')}`,
        cortex: `Biky - ${this.$t('cortex')}`,
        tesseract: `Biky - ${this.$t('tesseract')}`,
        'tesseract-plus': `Biky - ${this.$t('tesseract-plus')}`
      }

      return titles[this.tier]
    },
    carrierLabel (): string {
      const labels: {
        [key: string]: string
      } = {
        essential: this.$t('carrierNotIncluded'),
        cortex: this.$t('carrierNotIncluded'),
        tesseract: this.$t('carrierIncluded'),
        'tesseract-plus': this.$t('carrierIncluded')
      }

      return labels[this.tier]
    },
    descriptionTitle (): string {
      return this.$t('bikyGenerativeAi')
    },
    descriptionList (): string[] {
      const lists: {
      [key: string]: string[]
      } = {
        essential: [
          this.$t('essentialAutomaticContactCreation'),
          this.$t('essentialAutomaticAssisted'),
          this.$t('essentialAutomaticDetection'),
          this.$t('essentialQuestionsProspecting'),
          this.$t('essentialAutomacticChatsAssignment')
        ],
        cortex: [
          this.$t('cortexIn'),
          this.$t('cortexSentiment'),
          this.$t('cortexAdvanced')
        ],
        tesseract: [
          this.$t('tesseractIn'),
          this.$t('tesseractAutonomous'),
          this.$t('tesseractLearning'),
          this.$t('tesseractAutomatic'),
          this.$t('tesseractConfigure')
        ],
        'tesseract-plus': [
          this.$t('tesseractPlusIn'),
          this.$t('tesseractPlusFoundational'),
          this.$t('tesseractPlusBiky'),
          this.$t('tesseractPlusVoice'),
          this.$t('tesseractPlusCustomize')
        ]
      }

      return lists[this.tier]
    },
    tierIconContainerClass (): string {
      const classes: {
      [key: string]: string
      } = {
        essential: 'PlanSummary__tierCard__avatar__iconContainer__blue',
        cortex: 'PlanSummary__tierCard__avatar__iconContainer__kbGreen',
        tesseract: 'PlanSummary__tierCard__avatar__iconContainer__green',
        'tesseract-plus': 'PlanSummary__tierCard__avatar__iconContainer__yellow'
      }

      return classes[this.tier]
    },
    tierIcon (): string {
      const icons: {
      [key: string]: string
      } = {
        essential: KeybeIconType.SMARTOMETER_EMPTY_ACTIVE,
        cortex: KeybeIconType.SMARTOMETER_FULL_ACTIVE,
        tesseract: KeybeIconType.TESSERACT_ACTIVE,
        'tesseract-plus': KeybeIconType.TESSERACT_ACTIVE
      }

      return icons[this.tier]
    },
    bikyAvatar (): string {
      return BikyStoreController.getBikyImage()
    },
    summaryTitle (): string {
      return this.$t('totalToPay') || ''
    },
    summaryText (): string {
      return 'USD $xxxx + Carrier'
    },
    payButton (): string {
      return this.$t('pay') || ''
    },
    title (): string {
      return this.$t('pay') || ''
    }
  }
}
