import { render, staticRenderFns } from "./GeneralUserConfigurationUserInfo.vue?vue&type=template&id=024a09f6&scoped=true&"
import script from "./GeneralUserConfigurationUserInfo.vue?vue&type=script&lang=ts&"
export * from "./GeneralUserConfigurationUserInfo.vue?vue&type=script&lang=ts&"
import style0 from "./GeneralUserConfigurationUserInfo.vue?vue&type=style&index=0&id=024a09f6&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "024a09f6",
  null
  
)

export default component.exports