export type WebWidgetTheme = 'light' | 'dark';

export enum WebWidgetThemeEnum {
    LIGHT = 'light',
    DARK = 'dark',
}

export type WebWidgetPosition = 'left' | 'right';

export enum WebWidgetPositionEnum {
    LEFT = 'left',
    RIGHT = 'right',
}

export type WebWidgetTextAlign = 'left' | 'center' | 'right';

export enum WebWidgetTextAlignEnum {
    LEFT = 'left',
    CENTER = 'center',
    RIGHT = 'right',
}

export interface WebWidgetSetup {
disableMultimedia: any;
    bubbleSize: number,
    showBubble: boolean,
}

export interface WebWidgetWelcomeForm {
    text: string,
    color: string,
    fontSize: number,
    textAlign: WebWidgetTextAlign,
}

export interface WebWidgetConfig {
    theme: WebWidgetTheme,
    mainColor: string,
    widgetWebSetup: WebWidgetSetup,
    position: WebWidgetPosition,
    logo: string,
    hideBubbleMessage: boolean,
    bubbleMessage: string,
    disableMultimedia: boolean,
    isWhatsappButtonActive: boolean,
    widgetTitle: string,
    welcomeForm: WebWidgetWelcomeForm,
    language: string,
    authMethod: string,
    formFields: string[],
}
