
import KBButton from '@/shared/infrastructure/ui/buttons/KBButton.vue'
import { EventBus } from '@/eventBus'
import { FloatingWindowType } from '@/shared/infrastructure/enums/Layout/FloatingWindowType'
import { ConfigurationTabsEnum } from '@/modules/configuration/application/enums/ConfigurationTabsEnum'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'AlertMsg',
  components: {
    KBButton
  },
  props: {
    msg: {
      type: String,
      default: ''
    },
    msgBtn: {
      type: String,
      default: ''
    }
  },
  mounted () {
  },
  computed: {
    ...mapGetters('UserStore', ['isSuperAdmin'])
  },
  methods: {
    ...mapActions('FloatingWindowsStore', ['setMaximazedWindow']),
    goTo () {
      this.setMaximazedWindow(FloatingWindowType.CONFIGURATION)
      setTimeout(() => {
        EventBus.$emit('setConfigurationTab', ConfigurationTabsEnum.DOMAINS)
      }, 300)
    }
  },
  data () {
    return {
    }
  }
}
