import KeybeSelect from '@/modules/DesignSystem/infrastructure/components/KeybeSelect/KeybeSelect/KeybeSelect.vue'
import KeybeText from '@/modules/DesignSystem/infrastructure/components/KeybeText/KeybeText.vue'
import KBIconButton from '@/shared/infrastructure/ui/buttons/KBIconButton.vue'
import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import { CDPFieldsEnums } from '@/modules/CDP/shared/contacts/domain/enums/CDPFieldsEnums'
import KeybeButton from '@/modules/DesignSystem/infrastructure/components/KeybeButton/KeybeButton.vue'
import KBCheck from '@/shared/infrastructure/ui/inputs/select/KBCheck.vue'
import { typeField } from '@/modules/Config/WidgetTesseract/domain/WidgetTesseract'
import { EntityTypeEnum } from '@/modules/CDP/shared/contacts/domain/Contacts'
import { EventBus } from '@/eventBus'
import AppsUseCases from '@/modules/Apps/application/AppsUseCases'
import AppsController from '@/modules/Apps/infrastructure/controllers/Apps.controller'
import Colors from '@/utils/Colors'
import { mapState } from 'vuex'

export default {
  name: 'FieldSelector',
  components: {
    KeybeSelect,
    KeybeText,
    KBIconButton,
    KeybeButton,
    KBCheck
  },
  props: {
    field: {
      type: Object,
      required: true
    },
    fieldIndex: {
      type: Number,
      required: true
    },
    showLocation: {
      type: Boolean,
      required: true
    },
    customFields: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      selectedFieldItem: '',
      optionFieldSelect: '',
      selectedFieldLabel: '',
      isRequired: false,
      appsController: new AppsController(),
      KeybeIconType,
      fieldsCDP: [
        {
          name: this.$t(CDPFieldsEnums.PRINCIPAL_LOCATION),
          value: CDPFieldsEnums.PRINCIPAL_LOCATION,
          typeField: typeField.LOCATION
        }
      ],
      optionsFieldsTypes: [
        { name: this.$t('text'), value: typeField.TEXT },
        { name: this.$t('lists'), value: typeField.SELECT }
      ],
      showOptions: false,
      options: [],
      option: '',
      Colors,
      category: '',
      showSelector: false
    }
  },
  computed: {
    ...mapState('AppStore', ['selectedApp']),
    ...mapState('UserStore', ['token'])
  },
  async mounted () {
    if (this.field) {
      this.selectedFieldItem = this.field?.name
    }
    await this.getExtendedFields()
  },
  methods: {
    async getExtendedFields () {
      this.category = this.selectedApp?.categories[0]
      try {
        const data = {
          module: 'cdp',
          typeEntity: EntityTypeEnum.PEOPLE,
          category: this.category,
          token: this.token
        }
        const response = await AppsUseCases.getExtendedFields(this.appsController, data)
        if (response?.status) {
          this.extendedFields = response?.data[0]?.fields
          if (this.extendedFields && this.extendedFields.length > 0) {
            const field = this.extendedFields[0]
            this.fieldsCDP.push({
              name: this.$t(field?.field),
              value: field?.field,
              category: this.category,
              valueArray: [],
              typeField: field?.typeField
            })
          }
        }
        // if (response?.status) {
        //   this.extendedFields = response?.data[0]?.fields
        //   if (this.extendedFields) {
        //     this.extendedFields.forEach(field => {
        //       this.fieldsCDP.push({
        //         name: this.$t(field.field),
        //         value: field.field,
        //         category: this.category,
        //         valueArray: []
        //       })
        //     })
        //   }
        // }
      } catch (e) {
        console.error(e)
      }
    },
    onCdpChange (): void {
      const fieldExist = this.customFields.some(field => field?.name === this.selectedFieldItem)
      if (fieldExist) {
        EventBus.$emit('toast', 'error', this.$t('fieldExist'))
      } else {
        if (this.selectedFieldItem === CDPFieldsEnums.PRINCIPAL_LOCATION) {
          this.optionFieldSelect = typeField.LOCATION
          this.showSelector = false
          this.$emit('updateFieldType', this.optionFieldSelect)
        } else if (this.selectedFieldItem === 'car_interests') {
          this.optionFieldSelect = typeField.TEXTTAG
          this.$emit('updateFieldType', this.optionFieldSelect)
        } else {
          this.optionFieldSelect = ''
          this.showSelector = true
        }
        this.$emit('updateFieldName', this.selectedFieldItem)
      }
    },
    onTypeChange (): void {
      if (this.optionFieldSelect === typeField.SELECT) {
        this.showOptions = true
      } else {
        this.showOptions = false
      }
      this.$emit('updateFieldType', this.optionFieldSelect)
    },
    onRequiredChange (): void {
      this.isRequired = !this.isRequired
      this.$emit('updateFieldRequired', this.isRequired)
    }
  },
  watch: {
    selectedFieldLabel: function (value) {
      if (value) {
        this.$emit('updateFieldLabel', value)
      }
    }
  }
}
