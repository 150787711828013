<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 11 12">
    <path
      :fill="color"
      d="M10.997 0c0 2.738.007 5.477-.002 8.215a2.078 2.078 0 0 1-2.06 2.09c-1.006.014-1.917-.776-2.053-1.782-.151-1.117.502-2.103 1.584-2.364.632-.153 1.213-.01 1.732.386l.1.078V3.186c-.445.114-.885.226-1.324.34-1.569.403-3.138.807-4.708 1.207-.1.026-.131.064-.13.17.003 1.645.001 3.29.002 4.934 0 .739-.284 1.333-.871 1.77-1.235.92-3.05.132-3.248-1.409-.144-1.119.54-2.12 1.63-2.354.617-.133 1.184.008 1.688.396l.083.063c.002.001.007-.002.024-.007v-.131c0-2.027 0-4.055-.003-6.082 0-.112.032-.152.136-.179C5.99 1.288 8.4.668 10.812.047c.047-.012.093-.031.139-.047h.046Z"/>
  </svg>
</template>

<script>
export default {
  name: 'MusicNoteIcon',
  props: {
    color: {
      type: String,
      default: '#8898A9'
    }
  }
}
</script>

<style scoped>

</style>
