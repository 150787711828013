import { IS3Controller, updateToS3DTO } from '../domain/S3Controller'

export default class S3UseCases {
  static getId (controller: IS3Controller, length: number): string {
    return controller.getId(length)
  }

  static uploadFile (controller: IS3Controller, data: updateToS3DTO): Promise<{
    url: string,
    name: string,
    extension: string,
    _id: string
  }> {
    return controller.uploadFile(data)
  }
}
