import { render, staticRenderFns } from "./ConfigFormBiky.vue?vue&type=template&id=7573b777&scoped=true&"
import script from "./ConfigFormBiky.vue?vue&type=script&lang=ts&"
export * from "./ConfigFormBiky.vue?vue&type=script&lang=ts&"
import style0 from "./ConfigFormBiky.vue?vue&type=style&index=0&id=7573b777&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7573b777",
  null
  
)

export default component.exports