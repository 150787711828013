import { mapState } from 'vuex'
import Colors from '@/utils/Colors'
import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import { ImportsTabsEnum } from '@/modules/imports/domain/enums/ImportsTabsEnum'
import KeybeAvatar from '@/shared/infrastructure/ui/KeybeAvatar.vue'
import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'
export default {
  components: {
    KeybeAvatar,
    KeybeIcon
  },
  props: {
    selectedTab: {
      type: String
    }
  },
  computed: {
    ...mapState('AppStore', ['selectedApp']),
    ...mapState('UserStore', ['user']),
    itemsGroups () {
      const groups = [
        [
          {
            name: ImportsTabsEnum.IMPORTS_NEW,
            class: 'green',
            label: this.$t(ImportsTabsEnum.IMPORTS_NEW),
            icon: KeybeIconType.ARROW_UP,
            color: Colors.black
          },
          {
            name: ImportsTabsEnum.IMPORTS_PEOPLE,
            class: 'green',
            label: this.$t(ImportsTabsEnum.IMPORTS_PEOPLE),
            icon: KeybeIconType.PEOPLE,
            color: Colors.black
          },
          {
            name: ImportsTabsEnum.IMPORTS_BUSINESS,
            class: 'green',
            label: this.$t(ImportsTabsEnum.IMPORTS_BUSINESS),
            icon: KeybeIconType.COMPANY,
            color: Colors.black
          }
        ]
      ]
      return groups
    }
  },
  methods: {
    isSelectedTab (tabName) {
      return this.selectedTab === tabName
    },
    selectTab (tab) {
      this.$emit('selectTab', tab.name)
    }
  }
}
