import { mapState } from 'vuex'
import LottieAnimation from 'lottie-web-vue'
import { ImportsTypesEnum } from '@/modules/imports/domain/enums/ImportTypesEnum'
import { EntityTypeEnum } from '@/modules/CDP/shared/contacts/domain/Contacts'
import { EventBus } from '@/eventBus'

import KeybeButton from '@/modules/DesignSystem/infrastructure/components/KeybeButton/KeybeButton.vue'
import KeybeText from '@/modules/DesignSystem/infrastructure/components/KeybeText/KeybeText.vue'
import KeybeSelect from '@/modules/DesignSystem/infrastructure/components/KeybeSelect/KeybeSelect/KeybeSelect.vue'
import KeybeAutocomplete from '@/modules/DesignSystem/infrastructure/components/KeybeAutocomplete/KeybeAutocomplete/KeybeAutocomplete.vue'
import ImporterFileUpload from '@/modules/imports/infrastructure/components/Importer/ImporterFileUpload/ImporterFileUpload.vue'
import ImporterSegmentSelection from '@/modules/imports/infrastructure/components/Importer/ImporterSegmentSelection/ImporterSegmentSelection.vue'
import StatusController from '@/modules/Statuses/infrastructure/controllers/Status.controller'
import StatusUseCases from '@/modules/Statuses/application/StatusUseCases'
import { dataToGetStatuses } from '@/modules/Statuses/domain/StatusController'
import { Status } from '@/modules/Statuses/domain/Status'
import { CDPFieldsEnums } from '@/modules/CDP/shared/contacts/domain/enums/CDPFieldsEnums'
import Colors from '@/utils/Colors'
import { TeamMember } from '@/modules/Config/TeamConfiguration/domain/models/TeamMember'
import { dataToGetTeamMembers, teamMemberGetter } from '@/modules/Config/TeamConfiguration/domain/TeamController'
import TeamUseCases from '@/modules/Config/TeamConfiguration/application/TeamUseCases'
import TeamController from '@/modules/Config/TeamConfiguration/infrastructure/controllers/Team.controller'
import operations from '@/utils/operations'
export default {
  components: {
    KeybeText,
    KeybeButton,
    KeybeSelect,
    KeybeAutocomplete,
    LottieAnimation,
    ImporterFileUpload,
    ImporterSegmentSelection
  },
  computed: {
    ...mapState('AppStore', ['selectedApp']),
    ...mapState('UserStore', ['token']),
    biggerScreen () {
      return window.innerWidth > 1000
    }
  },
  mounted () {
    this.initData()
    EventBus.$on('selectType--imports', (type: string) => {
      setTimeout(() => {
        this.selectEntityType(type)
        this.rerender++
      }, 200)
    })
  },
  beforeDestroy () {
    StatusUseCases.dispose(this.statusController)
  },
  data () {
    return {
      EntityTypeEnum,
      ImportsTypesEnum,
      administrators: [],
      statuses: [],
      selectedSegmentId: '',
      statusController: new StatusController(),
      teamController: new TeamController(),
      typeEntity: EntityTypeEnum.PEOPLE,
      rerender: 0,
      CDPFieldsEnums
    }
  },
  methods: {
    async initData (): Promise<void> {
      await this.getAdministrators()
      await this.getStatuses()
    },
    selectEntityType (type: string): void {
      this.typeEntity = type
      this.$emit('typeEntity', this.typeEntity)
    },
    buttonColor (type: string): string {
      if (type === this.typeEntity) return Colors.kbGreen
      else return this.$vuetify.theme.dark ? Colors.inputDark : Colors.inputLight
    },
    buttonTextColor (type: string): string {
      if (type === this.typeEntity) return Colors.black
      else return this.$vuetify.theme.dark ? Colors.white : Colors.black
    },
    setDefaultStatus (e: {[key: string]: any}): void {
      if (e?._id) {
        this.$emit('defaultStatus', e?._id)
      }
    },
    setResponsible (e: {[key: string]: any}): void {
      const responsible = {
        uuid: e?.uuid,
        name: e?.name && e.lastName ? e?.name + ' ' + e?.lastName : e?.name,
        email: e?.email,
        phone: e?.phone
      }
      this.$emit('responsible', responsible)
    },
    async getStatuses (): Promise<void> {
      const data: dataToGetStatuses = {
        app: this.selectedApp?.uuid,
        token: this.token,
        pagination: {
          offset: 0,
          limit: 200
        }
      }
      const response: Status[] = await StatusUseCases.getStatuses(this.statusController, data)

      this.statuses = response

      this.statuses.forEach((status: Status) => {
        status.name = operations.checkTranslation(status.name)
      })
    },
    async getAdministrators () {
      try {
        const data: dataToGetTeamMembers = {
          page: 0,
          limit: 200,
          appId: this.selectedApp?.uuid,
          token: this.token,
          search: '',
          inactive: 0
        }
        const response: teamMemberGetter = await TeamUseCases.getTeamMembersFilterBiky(this.teamController, data)
        const teamMembers: TeamMember[] = response?.team || []
        this.administrators = teamMembers
        this.administrators.forEach((admin: TeamMember) => {
          let fullName: string = ''
          fullName = admin.name && admin.lastName ? admin.name + ' ' + admin.lastName : admin.name
          admin.fullName = fullName
        })
      } catch (error) {
        console.error(error)
      }
    }
  }
}
