// Nota: esta funcion se usa para obtener los parametros de los botones en templates
// Por limitaciones del proveedor nos toca trabajar con esta logica un poco enredada

export const getButtonParams = (templateMessage: string) : {
  buttonLabels: string[],
  buttonParams: string[],
  templateMessage: string
} => {
  if (!templateMessage) {
    return ({
      buttonLabels: [],
      buttonParams: [],
      templateMessage: ''
    })
  }

  const templateMessageSplitted = templateMessage.split(' | ')
  const buttonLabels: string[] = []
  const buttonParams: string[] = []
  const templateMessageString: string = templateMessageSplitted[0]

  if (templateMessageSplitted.length <= 1) {
    return {
      buttonLabels: [],
      buttonParams: [],
      templateMessage: templateMessageSplitted[0]
    }
  }
  templateMessageSplitted.shift()
  templateMessageSplitted.forEach((button: string) => {
    let text = button.replace('[', '')
    text = text.replace(']', '')
    text = text.trim()
    const textArray = text.split(',')
    if (textArray.length > 1) {
      textArray.reverse()
      buttonParams.push(textArray[0])
      textArray.shift()
      textArray.reverse()
      buttonLabels.push(textArray.join(','))
    } else {
      buttonLabels.push(textArray[0])
    }
  })

  return {
    buttonLabels,
    buttonParams,
    templateMessage: templateMessageString
  }
}

export const mediaTemplateValidation = (templateMessage: string) : string | boolean => {
  if (!templateMessage) {
    return false
  }
  // validate if the templateMessage has {{1}} in the beginning of the string, else validate if the templateMessage only has one param (a param seems like {{number}}), if not return false
  const regex = /\{\{1\}\}/g
  const match = templateMessage.match(regex)
  if (match && match.length > 0) {
    // remove the {{1}} from the string
    return templateMessage.replace('{{1}}', '')
  } else {
    const regex2 = /\{\{(.*?)\}\}/g
    const match2 = templateMessage.match(regex2)
    if (match2 && match2.length > 1) {
      return false
    }
    return templateMessage.replace('{{1}}', '')
  }
}

export const hasParams = (templateMessage: string) : boolean | number => {
  if (!templateMessage) {
    return false
  }
  const regex = /\{\{(.*?)\}\}/g
  const match = templateMessage.match(regex)
  if (match && match.length > 0) {
    return match.length
  }
  return false
}
