<template>
<svg :width="width" :height="height" viewBox="0 0 9 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M3.85663 2.19174C3.73719 2.30128 3.66509 2.36463 3.5973 2.43121C2.76552 3.24734 1.93374 4.0624 1.10519 4.87961C0.920108 5.06216 0.708127 5.17706 0.453105 5.08686C0.22606 5.00632 0.0603493 4.84202 0.0108513 4.58967C-0.0429509 4.31583 0.111999 4.13005 0.284166 3.95716C0.985746 3.25164 1.69163 2.55148 2.39536 1.84918C2.91509 1.33051 3.43374 0.809689 3.9567 0.293163C4.35161 -0.097721 4.64645 -0.097721 5.04028 0.293163C6.26589 1.51307 7.49043 2.73404 8.71281 3.95716C9.05069 4.29543 9.08512 4.56819 8.84409 4.87531C8.63426 5.14163 8.31575 5.19103 8.03813 4.99344C7.95097 4.93223 7.87135 4.85813 7.79495 4.78296C6.99437 3.99904 6.19595 3.21405 5.39645 2.43014C5.32866 2.36356 5.25656 2.30128 5.13712 2.19174L5.13712 2.55793C5.13712 7.41713 5.13712 12.2774 5.13604 17.1366C5.13604 17.2612 5.13066 17.3879 5.10484 17.5092C5.03382 17.84 4.8326 17.9989 4.5001 18C4.17837 18.0011 3.96316 17.8325 3.89429 17.5082C3.86847 17.3868 3.86093 17.2601 3.86093 17.1366C3.85986 12.286 3.85986 7.43539 3.85986 2.58477L3.85986 2.19174L3.85663 2.19174Z" :fill="color"/>
</svg>

</template>

<script>
export default {
  name: 'ArrowUpIcon',
  props: {
    width: {
      type: Number,
      default: 16
    },
    height: {
      type: Number,
      default: 18
    },
    color: {
      type: String,
      default: '#B6BEC7'
    }
  }

}
</script>

<style>

</style>
