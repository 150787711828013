import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'
import Colors from '@/utils/Colors'
import { KeybeIconType } from '../../enums/icons/KeybeIconType'

export interface KBDropdownItem {
    label: string;
    value: string;
    icon?: string;
    iconColor?: string;
    iconBackgroundColor?: string;
}

export default {
  name: 'KBDropdown',
  props: {
    value: {
      type: Object,
      default: null
    },
    items: {
      type: Array,
      default: () => []
    },
    showSelectedIcon: {
      type: Boolean,
      default: false
    },
    iconInList: {
      type: Boolean,
      default: false
    },
    width: {
      type: String,
      default: '100%'
    }
  },
  components: {
    KeybeIcon
  },
  data () {
    return {
      localValue: null,
      KeybeIconType
    }
  },
  watch: {
    value: {
      handler (newValue: KBDropdownItem | null): void {
        this.localValue = newValue
      },
      immediate: true
    }
  },
  computed: {
    itemsComputed (): KBDropdownItem[] {
      return this.items
    },
    iconContainerStyle (): string {
      const color: string = Colors[this.localValue?.iconBackgroundColor || 'rojoBird']
      return `background-color: ${color};`
    },
    containerStyle (): string {
      return `width: ${this.width};`
    },
    selectedIconColor (): string {
      return this.localValue?.iconColor || 'black'
    },
    selectedIcon (): string {
      return this.localValue?.icon || KeybeIconType.KEYBE_LOGO
    },
    selectedLabel (): string {
      return this.localValue?.label || ''
    }
  },
  methods: {
    onItemClick (item: KBDropdownItem): void {
      this.localValue = item
      this.$emit('input', item)
    },
    getIconContainerStyle (item: KBDropdownItem): string {
      const color: string = Colors[item.iconBackgroundColor || 'rojoBird']
      return `background-color: ${color};`
    }
  }
}
