import { render, staticRenderFns } from "./ExtraDataConfig.vue?vue&type=template&id=b8457e18&scoped=true&"
import script from "./ExtraDataConfig.vue?vue&type=script&lang=ts&"
export * from "./ExtraDataConfig.vue?vue&type=script&lang=ts&"
import style0 from "./ExtraDataConfig.vue?vue&type=style&index=0&id=b8457e18&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b8457e18",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VExpandTransition } from 'vuetify/lib/components/transitions';
installComponents(component, {VExpandTransition})
