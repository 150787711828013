import { BikyFileType } from './BikyConfigController'

export enum BikyConfigLanguageTypeEnum {
    VERY_FORMAL = 'VERY_FORMAL',
    FORMAL = 'FORMAL',
    INFORMAL = 'INFORMAL',
    VERY_INFORMAL = 'VERY_INFORMAL'
}

export enum BikyTypes {
    CLIENT_SUPPORT = 'CLIENT_SUPPORT',
    SALES = 'SALES',
    GENERAL_ASSISTANT = 'GENERAL_ASSISTANT',
}

export type BikyType = BikyTypes.CLIENT_SUPPORT | BikyTypes.SALES | BikyTypes.GENERAL_ASSISTANT;

export type BikyConfigLanguageType = BikyConfigLanguageTypeEnum.VERY_FORMAL | BikyConfigLanguageTypeEnum.FORMAL | BikyConfigLanguageTypeEnum.INFORMAL | BikyConfigLanguageTypeEnum.VERY_INFORMAL;

export enum BikyDefaultLanguageEnum {
    ES = 'es',
    EN = 'en',
    PT = 'pt'
}

export enum BikyConfigSlangEnum {
    ES_CO = 'ES-CO',
    EN_US = 'EN-US',
    EN_GB_CASUAL = 'EN-GB-CASUAL',
    EN_GB_CLASSY = 'EN-GB-CLASSY',
    EN_GB_PLEASANT = 'EN-GB-PLEASANT',
    EN_GB_POLITE = 'EN-GB-POLITE',
    EN_US_INTROSPECTIVE = 'EN-US-INTROSPECTIVE',
    EN_US_NARRATIVE = 'EN-US-NARRATIVE',
    EN_US_NEUTRAL = 'EN-US-NEUTRAL',
    PT_BR = 'PT-BR'
}

export type BikyConfigSlang = BikyConfigSlangEnum.ES_CO | BikyConfigSlangEnum.EN_US | BikyConfigSlangEnum.EN_GB_CASUAL | BikyConfigSlangEnum.EN_GB_CLASSY | BikyConfigSlangEnum.EN_GB_PLEASANT | BikyConfigSlangEnum.EN_GB_POLITE | BikyConfigSlangEnum.EN_US_INTROSPECTIVE | BikyConfigSlangEnum.EN_US_NARRATIVE | BikyConfigSlangEnum.EN_US_NEUTRAL;

export type BikyDefaultLanguage = BikyDefaultLanguageEnum.ES | BikyDefaultLanguageEnum.EN | BikyDefaultLanguageEnum.PT;

export interface BikySkills {
    manageSalesCard?: boolean;
}
export interface BikyConfig {
    _id: string;
    appUUID: string;
    active: boolean;
    name: string;
    languageType: BikyConfigLanguageType;
    useEmojis: boolean;
    useSlang: boolean;
    slang: BikyConfigSlang;
    createdAt: Date;
    updatedAt: Date;
    defaultLanguage?: BikyDefaultLanguage;
    type?: BikyType;
    audioMessagesLimit?: number;
    wordsLimit?: number;
    skills?: BikySkills;
}

export interface BikyPronunciation {
    _id: string;
    appUUID: string;
    active: boolean;
    name: string;
    pronunciation: string;
    createdAt: Date;
    updatedAt: Date;
}

export enum BikyWebsiteStateEnum {
    PENDING = 'PENDING',
    ERROR = 'ERROR',
    SUCCESS = 'SUCCESS',
}

export type BikyWebsiteState = BikyWebsiteStateEnum.PENDING | BikyWebsiteStateEnum.ERROR | BikyWebsiteStateEnum.SUCCESS;

export interface BikyWebsite {
    _id: string;
    appUUID: string;
    active: boolean;
    url: string;
    state?: BikyWebsiteState;
    error?: string;
    createdAt: Date;
    updatedAt: Date;
}

export const defaultConfig = {
  name: 'Biky Montes',
  useEmojis: true,
  slang: BikyConfigSlangEnum.ES_CO,
  languageType: BikyConfigLanguageTypeEnum.VERY_INFORMAL,
  defaultLanguage: BikyDefaultLanguageEnum.ES,
  type: BikyTypes.CLIENT_SUPPORT,
  audioMessagesLimit: 2
}

export interface BikyFile {
    _id: string;
    appUUID: string;
    active: boolean;
    url: string;
    name: string;
    type: BikyFileType;
    createdAt: string;
    updatedAt: string;
    state?: BikyWebsiteState;
    error?: string;
}

export enum KnowledgeBaseTypeEnum {
    DOCX = 'DOCX',
    PDF = 'PDF',
    CHAT = 'CHAT',
    USER = 'USER',
    WEB = 'WEB',
    MY_KEYBE = 'MY_KEYBE'
}

export type KnowledgeBaseType = KnowledgeBaseTypeEnum.DOCX | KnowledgeBaseTypeEnum.PDF | KnowledgeBaseTypeEnum.CHAT | KnowledgeBaseTypeEnum.USER | KnowledgeBaseTypeEnum.WEB | KnowledgeBaseTypeEnum.MY_KEYBE;

export interface BikyKnowledgeFragment {
    _id: string;
    appUUID: string;
    embedding: number[];
    text: string;
    type: KnowledgeBaseType;
    typeId: string;
    createdAt: string;
    updatedAt: string;
}

export const CONFIG_FILES_PATH = 'ai/knowledge-base'

export enum BikyMessageErrorStatusEnum {
    PENDING = 'PENDING',
    RESOLVED = 'RESOLVED',
  }

export type BikyMessageErrorStatus = BikyMessageErrorStatusEnum.PENDING | BikyMessageErrorStatusEnum.RESOLVED;

export interface BikyMessageError {
    _id: string;
    appUUID: string;
    createdByAdviserId: string;
    fixedByAdviserId?: string;
    previousMessage: any;
    bikyMessage: any;
    status: BikyMessageErrorStatus;
    createdAt?: Date;
    updatedAt?: Date;
}

export enum BikyCatalogConfigStatusEnum {
    SYNCING = 'SYNCING',
    SYNCED = 'SYNCED',
    ERROR = 'ERROR',
    PENDING = 'PENDING',
  }

export interface BikyWidgetConfig {
    apiKey : string;
    user : {
        id: string;
        name: string;
        email: string;
        phone: string;
        userToken: string;
        userId: string;
    };
    containerId : string;
    theme : string;
    language : string;
}

export type BikyCatalogConfigStatus = BikyCatalogConfigStatusEnum.SYNCING | BikyCatalogConfigStatusEnum.SYNCED | BikyCatalogConfigStatusEnum.ERROR | BikyCatalogConfigStatusEnum.PENDING;
export interface BikyCatalogConfig {
    _id: string;
    appUUID: string;
    active: boolean;
    status: BikyCatalogConfigStatus;
}
