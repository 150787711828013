





































































































import QuickResponseCard from './QuickResponseCard'
export default QuickResponseCard
