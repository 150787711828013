// import PermissionTypes from './PermissionTypes.vue'
import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'
import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'

export default {
  name: 'PermissionTypes',
  components: {
    KeybeIcon,
    KeybeIconType
  },
  data () {
    return {
      KeybeIconType,
      items: [
        { subtitle: this.$t('agent'), image: '', columnDescription: '', texts: [] },
        { subtitle: this.$t('coordinator'), image: '', columnDescription: '', texts: [] },
        { subtitle: this.$t('admin'), image: '', columnDescription: '', texts: [] }
      ]
    }
  },
  mounted () {
    this.assignValues()
  },
  methods: {
    assignValues () {
      this.items.forEach(item => {
        switch (item.subtitle) {
          case this.$t('agent'):
            item.image = KeybeIconType.AGENT_ICON
            item.imgBlack = KeybeIconType.AGENTBLACK_ICON
            item.columnDescription = this.$t('agentDescriptionPermission')
            item.texts = [this.$t('chatsPermissions'), this.$t('people'), this.$t('sales'), this.$t('ownMetrics'), this.$t('support'), this.$t('customConfigurations')]
            break
          case this.$t('coordinator'):
            item.image = KeybeIconType.COORDINATOR_ICON
            item.imgBlack = KeybeIconType.COORDINATORBLACK_ICON
            item.columnDescription = this.$t('coordinatorDescriptionPermission')
            item.texts = [this.$t('chatsPermissions'), this.$t('people'), this.$t('sales'), this.$t('totalMetrics'), this.$t('support'), this.$t('massMessages'), this.$t('partialSettings')]
            break
          case this.$t('admin'):
            item.image = KeybeIconType.ADMIN_ICON
            item.imgBlack = KeybeIconType.ADMINBLACK_ICON
            item.columnDescription = this.$t('adminDescriptionPermission')
            item.texts = [this.$t('chatsPermissions'), this.$t('people'), this.$t('sales'), this.$t('totalMetrics'), this.$t('support'), this.$t('massMessages'), this.$t('downloads'), this.$t('totalSettings'), this.$t('kbFlows'), this.$t('consumptions')]
            break
        }
      })
    }
  }
}
