import { FiltersType } from '@/modules/conversations/domain/models/Filters'

const initialState = {
  size: 15,
  order: -1,
  orderBy: 'updatedAt',
  offset: 0,
  search: '',
  advisorsIds: [],
  sort: 0,
  filters: {
    all: true,
    mine: false,
    unanswered: false,
    waiting: false,
    withBot: false,
    wizard: false,
    ended: false,
    whatsapp: false,
    facebook: false,
    instagram: false,
    web: false,
    older: false,
    newer: true,
    closedByAi: false,
    closedByUser: false,
    advisor: false,
    internal: false,
    favorite: false
  }
}

const defaultFilters = {
  all: false,
  mine: true,
  waiting: false,
  withBot: false,
  wizard: false,
  ended: false,
  whatsapp: false,
  facebook: false,
  instagram: false,
  internal: false,
  web: false,
  closedByAi: false,
  closedByUser: false,
  advisor: false,
  older: false,
  newer: true,
  unanswered: false,
  favorite: false
}

const allFiltersOff = {
  all: false,
  mine: false,
  waiting: false,
  withBot: false,
  wizard: false,
  ended: false,
  whatsapp: false,
  facebook: false,
  instagram: false,
  internal: false,
  web: false,
  older: false,
  newer: true,
  closedByAi: false,
  closedByUser: false,
  advisor: false,
  unanswered: false,
  favorite: false
}

export const RoomFiltersStore = {
  namespaced: true,
  state: initialState,
  mutations: {
    SET_SIZE (state: any, size: number) {
      state.size = size
    },
    SET_ORDER (state: any, order: number) {
      state.order = order
    },
    SET_ORDER_BY (state: any, orderBy: string) {
      state.orderBy = orderBy
    },
    SET_OFFSET (state: any, offset: number) {
      state.offset = offset
    },
    SET_SEARCH (state: any, search: string) {
      state.search = search
    },
    SET_ADVISORS_IDS (state: any, advisorsIds: string[]) {
      state.advisorsIds = advisorsIds
    },
    ADD_ADVISOR_ID (state: any, advisorId: string) {
      state.advisorsIds.push(advisorId)
    },
    REMOVE_ADVISOR_ID (state: any, advisorId: string) {
      state.advisorsIds = state.advisorsIds.filter((id: string) => id !== advisorId)
    },
    SET_FILTERS (state: any, filters: any) {
      state.filters = filters
    },
    CLEAN_STATE (state: any) {
      state = initialState
    },
    SET_SORT (state: any, sort: number) {
      state.sort = sort
    }
  },
  actions: {
    setSize ({ commit }: any, size: number) {
      commit('SET_SIZE', size)
    },
    setOrder ({ commit }: any, order: number) {
      commit('SET_ORDER', order)
    },
    setOrderBy ({ commit }: any, orderBy: string) {
      commit('SET_ORDER_BY', orderBy)
    },
    setOffset ({ commit }: any, offset: number) {
      commit('SET_OFFSET', offset)
    },
    setSearch ({ commit }: any, search: string) {
      commit('SET_SEARCH', search)
    },
    setAdvisorsIds ({ commit }: any, advisorsIds: string[]) {
      commit('SET_ADVISORS_IDS', advisorsIds)
    },
    addAdvisorId ({ commit }: any, advisorId: string) {
      commit('ADD_ADVISOR_ID', advisorId)
    },
    removeAdvisorId ({ commit }: any, advisorId: string) {
      commit('REMOVE_ADVISOR_ID', advisorId)
    },
    setFilters ({ commit }: any, filters: any) {
      commit('SET_FILTERS', filters)
    },
    cleanState ({ commit }: any) {
      commit('CLEAN_STATE')
    },
    setDefaultFilters ({ commit, dispatch }: any) {
      dispatch('setAdvisorsIds', [])
      commit('SET_FILTERS', defaultFilters)
    },
    setSort ({ commit, state }: any) {
      let sort = 0
      if (state.sort) sort = 0
      else sort = 1
      commit('SET_SORT', sort)
    },
    handleChangeFilter ({ commit, state, getters, dispatch }, { filter, value }: any) {
      dispatch('setOffset', 0)
      switch (filter) {
        // Type conversations filter
        // All conversations filter
        case FiltersType.ALL:
          if (value) {
            commit('SET_FILTERS', {
              ...state.filters,
              all: true,
              mine: false,
              waiting: false,
              withBot: false,
              wizard: false,
              ended: false,
              whatsapp: false,
              facebook: false,
              internal: false,
              instagram: false,
              web: false,
              closedByAi: false,
              closedByUser: false,
              advisor: false,
              unanswered: false
            })
          } else {
            commit('SET_FILTERS', {
              ...state.filters,
              all: false,
              mine: true,
              waiting: true,
              withBot: false,
              internal: false,
              wizard: false,
              ended: false,
              whatsapp: false,
              facebook: false,
              instagram: false,
              web: false,
              closedByAi: false,
              closedByUser: false,
              advisor: false,
              unanswered: false
            })
          }
          break
        case FiltersType.UNANSWERED:
          commit('SET_ORDER', 1)
          commit('SET_FILTERS', {
            ...state.filters,
            all: false,
            mine: false,
            waiting: false,
            withBot: false,
            internal: false,
            ended: false,
            closedByAi: false,
            closedByUser: false,
            advisor: false,
            unanswered: value
          })
          break
          // Mine conversations filter
        case FiltersType.MINE:
          commit('SET_FILTERS', {
            ...state.filters,
            all: false,
            unanswered: false,
            mine: value
          })
          if (getters.isNoneTypeFilterSelected) {
            setTimeout(() => {
              commit('SET_FILTERS', defaultFilters)
            }, 200)
          }
          break
          // Waiting conversations filter
        case FiltersType.WAITING:
          commit('SET_FILTERS', {
            ...state.filters,
            all: false,
            unanswered: false,
            waiting: value
          })
          if (getters.isNoneTypeFilterSelected) {
            setTimeout(() => {
              commit('SET_FILTERS', defaultFilters)
            }, 100)
          }
          break
          // With bot conversations filter
        case FiltersType.WITH_BOT:
          commit('SET_FILTERS', {
            ...state.filters,
            all: false,
            unanswered: false,
            withBot: value
          })
          if (getters.isNoneTypeFilterSelected) {
            commit('SET_FILTERS', defaultFilters)
          }
          break
          // Ended conversations filter
        case FiltersType.ENDED:
          commit('SET_FILTERS', {
            ...state.filters,
            all: false,
            unanswered: false,
            ended: value
          })
          if (getters.isNoneTypeFilterSelected) {
            commit('SET_FILTERS', defaultFilters)
          }
          break
          // closed by ai conversations filter
        case FiltersType.CLOSED_BY_AI:
          commit('SET_FILTERS', {
            ...state.filters,
            all: false,
            unanswered: false,
            closedByAi: value
          })
          if (getters.isNoneTypeFilterSelected) {
            commit('SET_FILTERS', defaultFilters)
          }
          break
          // closed by user conversations filter
        case FiltersType.CLOSED_BY_USER:
          commit('SET_FILTERS', {
            ...state.filters,
            all: false,
            unanswered: false,
            closedByUser: value
          })
          if (getters.isNoneTypeFilterSelected) {
            commit('SET_FILTERS', defaultFilters)
          }
          break
        case FiltersType.OPEN:
          commit('SET_FILTERS', {
            ...state.filters,
            all: false,
            unanswered: false,
            open: value
          })
          if (getters.isNoneTypeFilterSelected) {
            commit('SET_FILTERS', defaultFilters)
          }
          break
        case FiltersType.INTERNAL:
          commit('SET_FILTERS', {
            ...state.filters,
            all: false,
            internal: value
          })
          break
        case FiltersType.FAVORITE:
          commit('SET_FILTERS', {
            ...state.filters,
            all: false,
            favorite: value
          })
          break
          // Channel conversations filter
        case FiltersType.WHATSAPP:
          commit('SET_FILTERS', {
            ...state.filters,
            all: false,
            whatsapp: value
          })
          break
        case FiltersType.FACEBOOK:
          commit('SET_FILTERS', {
            ...state.filters,
            all: false,
            facebook: value
          })
          break
        case FiltersType.INSTAGRAM:
          commit('SET_FILTERS', {
            ...state.filters,
            all: false,
            instagram: value
          })
          break
        case FiltersType.WEB:
          commit('SET_FILTERS', {
            ...state.filters,
            all: false,
            web: value
          })
          break
        case FiltersType.WIZARD:
          commit('SET_FILTERS', {
            ...state.filters,
            all: false,
            wizard: value
          })
          break
          // Advisor filter
        case FiltersType.ADVISOR:
          commit('SET_FILTERS', {
            ...state.filters,
            ...allFiltersOff,
            advisor: value
          })
          break
          // order filters
        case FiltersType.OLDER:
          commit('SET_ORDER', -1)
          commit('SET_FILTERS', {
            ...state.filters,
            older: value,
            newer: !value
          })
          break
        case FiltersType.NEWER:
          commit('SET_ORDER', 1)
          commit('SET_FILTERS', {
            ...state.filters,
            newer: value,
            older: !value
          })
      }
    },
    handleChangeQuickFilter ({ commit, state, dispatch }, { filter, value }: any) {
      commit('SET_FILTERS', {
        ...state.filters,
        ...allFiltersOff
      })
      dispatch('handleChangeFilter', { filter, value })
    }
  },
  getters: {
    isNoneTypeFilterSelected: (state: any) => {
      return !state.filters.all && !state.filters.mine && !state.filters.waiting && !state.filters.ended && !state.filters.closedByAi && !state.filters.closedByUser && !state.filters.withBot
    },
    isNoneChannelFilterSelected: (state: any) => {
      return !state.filters.whatsapp && !state.filters.facebook && !state.filters.instagram && !state.filters.web && !state.filters
    },
    filters: (state: any) => {
      return state.filters
    },
    advisorsIds: (state: any) => {
      return state.advisorsIds
    },
    size: (state: any) => {
      return state.size
    },
    order: (state: any) => {
      return state.order
    },
    offset: (state: any) => {
      return state.offset
    },
    search: (state: any) => {
      return state.search
    },
    isOnlyAllConversationsSelected: (state: any) => {
      return state.filters.all && !state.filters.mine && !state.filters.waiting && !state.filters.ended && !state.filters.closedByAi && !state.filters.closedByUser && !state.filters.withBot && !state.filters.whatsapp && !state.filters.facebook && !state.filters.instagram && !state.filters.web && !state.filters.wizard && !state.filters.advisor && !state.filters.internal && !state.filters.favorite
    },
    isOnlyMineConversationsSelected: (state: any) => {
      return !state.filters.all && state.filters.mine && !state.filters.waiting && !state.filters.ended && !state.filters.closedByAi && !state.filters.closedByUser && !state.filters.withBot && !state.filters.whatsapp && !state.filters.facebook && !state.filters.instagram && !state.filters.web && !state.filters.wizard && !state.filters.advisor && !state.filters.internal && !state.filters.favorite
    },
    isOnlyWaitingConversationsSelected: (state: any) => {
      return !state.filters.all && !state.filters.mine && state.filters.waiting && !state.filters.ended && !state.filters.closedByAi && !state.filters.closedByUser && !state.filters.withBot && !state.filters.whatsapp && !state.filters.facebook && !state.filters.instagram && !state.filters.web && !state.filters.wizard && !state.filters.advisor && !state.filters.internal && !state.filters.favorite
    },
    isOnlyEndedByAiConversationsSelected: (state: any) => {
      return !state.filters.all && !state.filters.mine && !state.filters.waiting && !state.filters.ended && state.filters.closedByAi && !state.filters.closedByUser && !state.filters.withBot && !state.filters.whatsapp && !state.filters.facebook && !state.filters.instagram && !state.filters.web && !state.filters.wizard && !state.filters.advisor && !state.filters.internal && !state.filters.favorite
    },
    isOnlyEndedByUserConversationsSelected: (state: any) => {
      return !state.filters.all && !state.filters.mine && !state.filters.waiting && !state.filters.ended && !state.filters.closedByAi && state.filters.closedByUser && !state.filters.withBot && !state.filters.whatsapp && !state.filters.facebook && !state.filters.instagram && !state.filters.web && !state.filters.wizard && !state.filters.advisor && !state.filters.internal && !state.filters.favorite
    },
    isOnlyInternalConversationsSelected: (state: any) => {
      return !state.filters.all && !state.filters.mine && !state.filters.waiting && !state.filters.ended && !state.filters.closedByAi && !state.filters.closedByUser && !state.filters.withBot && !state.filters.whatsapp && !state.filters.facebook && !state.filters.instagram && !state.filters.web && !state.filters.wizard && !state.filters.advisor && state.filters.internal && !state.filters.favorite
    },
    isOnlyFavoriteConversationsSelected: (state: any) => {
      return !state.filters.all && !state.filters.mine && !state.filters.waiting && !state.filters.ended && !state.filters.closedByAi && !state.filters.closedByUser && !state.filters.withBot && !state.filters.whatsapp && !state.filters.facebook && !state.filters.instagram && !state.filters.web && !state.filters.wizard && !state.filters.advisor && !state.filters.internal && state.filters.favorite
    }
  }
}
