<template>
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 13 10"><path :fill="color" d="M7.911 1.24h-4.08c-.317 0-.48-.133-.49-.39-.01-.22.099-.364.313-.413a.747.747 0 0 1 .171-.02c2.72 0 5.441-.002 8.162 0 .179 0 .346.03.442.211.133.25.01.529-.267.592a1 1 0 0 1-.223.021H7.911ZM7.892 5.413H3.846c-.33 0-.496-.13-.506-.393-.008-.217.1-.362.316-.411a.86.86 0 0 1 .189-.02c2.709-.001 5.418-.002 8.126 0 .177 0 .348.02.45.198.146.255.027.54-.26.604-.072.016-.148.021-.223.021-1.348.002-2.697 0-4.046 0ZM7.904 9.584H3.875a1.07 1.07 0 0 1-.257-.03c-.18-.045-.273-.175-.278-.37-.005-.197.091-.342.272-.393a.895.895 0 0 1 .239-.03h8.126c.178 0 .348.023.447.202a.411.411 0 0 1-.015.444c-.104.168-.274.177-.443.177H7.903ZM1.642.822c.002.478-.334.824-.812.834-.476.01-.831-.345-.83-.83C.001.346.345 0 .819 0c.472 0 .82.347.823.823ZM1.642 5.002a.8.8 0 0 1-.82.827c-.479.003-.827-.353-.822-.84.005-.477.355-.824.826-.817.476.006.816.353.816.83ZM1.642 9.165c.003.483-.35.84-.827.836-.476-.005-.816-.35-.815-.83 0-.48.342-.824.817-.827a.8.8 0 0 1 .825.82Z"/></svg>
</template>

<script>
export default {
  name: 'ListIcon',
  props: {
    color: {
      type: String,
      default: '#B6BEC7'
    }
  }
}
</script>

<style>

</style>
