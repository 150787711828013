

































import AlertModalFullScreen from './AlertModalFullScreen'
export default AlertModalFullScreen
