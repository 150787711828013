import { TokenModel } from '@/shared/domain/TokenModel'
import {
  IFeesController,
  commissionByUserGetter,
  dataToAddUserToBrand,
  dataToGenerateCommission,
  dataToGetCommissionByUser,
  dataToGetInvoices,
  dataToGetPartnerCategories,
  dataToGetUserPartners,
  dataToPayCommissionByUser,
  dataToRemoveUserFromBrand,
  datatoCreateDailyContactV2,
  datatoCreatePlanV2,
  datatoCreateProducttV2,
  datatoUpdateDailyContactV2,
  datatoUpdatePlanV2,
  datatoUpdateProductV2,
  invoicesrGetter,
  partnerCategoriesGetter,
  userPartnerGetter
} from '../domain/FeesController'

export default class FeesUseCases {
  static async getUserPartners (controller: IFeesController, data: dataToGetUserPartners) : Promise<userPartnerGetter> {
    return await controller.getUserPartners(data)
  }

  static async getPartnerCategories (controller: IFeesController, data: dataToGetPartnerCategories) : Promise<partnerCategoriesGetter> {
    return await controller.getPartnerCategories(data)
  }

  static async addUserToBrand (controller: IFeesController, data: dataToAddUserToBrand) : Promise<boolean> {
    return await controller.addUserToBrand(data)
  }

  static async removeUserFromBrand (controller: IFeesController, data: dataToRemoveUserFromBrand) : Promise<boolean> {
    return await controller.removeUserFromBrand(data)
  }

  static async getFeesData (controller: IFeesController, data: TokenModel) : Promise<userPartnerGetter> {
    return await controller.getFeesData(data)
  }

  static async getInvoices (controller: IFeesController, data: dataToGetInvoices) : Promise<userPartnerGetter> {
    return await controller.getInvoices(data)
  }

  static async getCommissionByUser (controller: IFeesController, data: dataToGetCommissionByUser) : Promise<commissionByUserGetter> {
    return await controller.getCommissionByUser(data)
  }

  static async getCommissionByUserCsv (controller: IFeesController, data: dataToGetCommissionByUser) : Promise<commissionByUserGetter> {
    return await controller.getCommissionByUserCsv(data)
  }

  static async payCommissionByUser (controller: IFeesController, data: dataToPayCommissionByUser) : Promise<commissionByUserGetter> {
    return await controller.payCommissionByUser(data)
  }

  static async generateCommission (controller: IFeesController, data: dataToGenerateCommission) : Promise<commissionByUserGetter> {
    return await controller.generateCommission(data)
  }

  static async getPlansV2 (controller: IFeesController, data: TokenModel) : Promise<invoicesrGetter> {
    return await controller.getPlansV2(data)
  }

  static async postPlansV2 (controller: IFeesController, data: datatoCreatePlanV2) : Promise<invoicesrGetter> {
    return await controller.postPlansV2(data)
  }

  static async patchPlansV2 (controller: IFeesController, data: datatoUpdatePlanV2) : Promise<invoicesrGetter> {
    return await controller.patchPlansV2(data)
  }

  static async getDailyContactV2 (controller: IFeesController, data: TokenModel) : Promise<invoicesrGetter> {
    return await controller.getDailyContactV2(data)
  }

  static async postDailyContactV2 (controller: IFeesController, data: datatoCreateDailyContactV2) : Promise<invoicesrGetter> {
    return await controller.postDailyContactV2(data)
  }

  static async patchDailyContactV2 (controller: IFeesController, data: datatoUpdateDailyContactV2) : Promise<invoicesrGetter> {
    return await controller.patchDailyContactV2(data)
  }

  static async getProductsV2 (controller: IFeesController, data: TokenModel) : Promise<invoicesrGetter> {
    return await controller.getProductsV2(data)
  }

  static async postProductsV2 (controller: IFeesController, data: datatoCreateProducttV2) : Promise<invoicesrGetter> {
    return await controller.postProductsV2(data)
  }

  static async patchProductsV2 (controller: IFeesController, data: datatoUpdateProductV2) : Promise<invoicesrGetter> {
    return await controller.patchProductsV2(data)
  }

  static async getCountryV2 (controller: IFeesController, data: TokenModel) : Promise<invoicesrGetter> {
    return await controller.getCountryV2(data)
  }
}
