<template>
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 15"><path :fill="color" d="M4.174 11.943C1.882 10.428.89 8.332 1.505 5.649c.51-2.224 1.993-3.672 4.208-4.16 2.661-.587 4.742.419 6.23 2.693.519-.744.929-1.55 1.164-2.436.04-.155.028-.328.022-.49-.01-.254-.14-.405-.39-.387a3.49 3.49 0 0 0-.854.16c-.357.117-.697.29-1.044.436-.09.038-.18.08-.274.099a.394.394 0 0 1-.441-.216.43.43 0 0 1 .09-.518.484.484 0 0 1 .103-.075c.67-.336 1.345-.664 2.106-.744.58-.06 1.24.114 1.477.75.134.36.115.731.037 1.1-.169.81-.535 1.538-.948 2.245-.178.304-.381.595-.582.885-.076.11-.1.208-.053.336.188.52.276 1.065.273 1.614-.005.917-.175 1.807-.59 2.633a5.56 5.56 0 0 1-2.475 2.507 5.779 5.779 0 0 1-2.165.61 5.454 5.454 0 0 1-2.197-.26c-.068-.023-.176.012-.242.055-.755.497-1.539.941-2.38 1.276-.483.193-.984.319-1.515.246a1.205 1.205 0 0 1-1.007-.907c-.117-.48-.047-.95.113-1.4.159-.447.355-.88.55-1.312.13-.288.363-.38.605-.272.228.1.301.359.178.646-.158.37-.338.73-.472 1.108-.093.26-.144.54-.172.817-.029.277.133.445.413.44.247-.003.506-.014.738-.09a8.02 8.02 0 0 0 2.163-1.094v-.001ZM2.165 7.196a4.72 4.72 0 0 0 .33 1.568c.458 1.135 1.224 1.991 2.328 2.54.13.066.209.056.321-.027a28.69 28.69 0 0 0 3.264-2.818c1.051-1.046 2.032-2.155 2.896-3.364.034-.048.059-.139.036-.184a4.819 4.819 0 0 0-1.746-1.975c-1.126-.72-2.351-.941-3.659-.652-1.012.225-1.852.738-2.549 1.512-.875.973-1.24 2.114-1.221 3.4Zm3.85 4.497c1.066.314 3.016.07 4.328-1.243 1.235-1.237 1.651-2.715 1.366-4.421-1.648 2.137-3.559 4.001-5.694 5.664Z"/></svg>
</template>

<script>
export default {
  name: 'PlanetIcon',
  props: {
    color: {
      type: String,
      default: '#B6BEC7'
    }
  }
}
</script>

<style>

</style>
