













export default {
  name: 'LoadingIcon'
}
