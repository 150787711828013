import store from '@/store'
import { Room } from '@/modules/conversations/domain/models/Room/Room'
import { roomsGetter } from '@/modules/conversations/domain/models/Room/RoomUseCases'

export default class ConversationStoreController {
  static getRooms () {
    return store.getters['ConversationStore/getRooms'] || []
  }

  static getPinnedRooms () {
    return store.getters['ConversationStore/getPinnedRooms'] || []
  }

  static setRooms (rooms: Room[]) {
    store.commit('ConversationStore/SET_ROOMS', rooms)
  }

  static setPinnedRooms (rooms: Room[]) {
    store.commit('ConversationStore/SET_PINNED_ROOMS', rooms)
  }

  static setTotalRooms (total: number) {
    store.commit('ConversationStore/SET_TOTAL_ROOMS', total)
  }

  static setCanGetMoreRooms (canGetMoreRooms: boolean) {
    store.commit('ConversationStore/SET_CAN_GET_MORE_ROOMS', canGetMoreRooms)
  }

  static setRoomsError (error: boolean) {
    store.commit('ConversationStore/SET_ERROR_LOADING_ROOMS', error)
  }

  static getChannels () {
    return store.getters['ConversationStore/getChannels'] || []
  }

  static setRoomsData (roomsData: roomsGetter) {
    const offset = store.getters['RoomFiltersStore/offset']
    const { rooms, total } = roomsData
    if (offset > 0) {
      const actualRooms = ConversationStoreController.getRooms()
      ConversationStoreController.setRooms([...actualRooms, ...rooms])
    } else {
      ConversationStoreController.setRooms(rooms)
      ConversationStoreController.setTotalRooms(total)
    }

    ConversationStoreController.setCanGetMoreRooms(roomsData.canGetMoreRooms)
    ConversationStoreController.setRoomsError(roomsData.error)
  }

  static getInboxes () {
    return store.getters['ConversationStore/getInboxes'] || []
  }

  static getSelectedInbox () {
    return store.getters['ConversationStore/getSelectedInbox'] || {}
  }

  static setInboxes (inboxes: any[]) {
    store.commit('ConversationStore/SET_INBOXES', inboxes)
  }

  static getChatFilters () {
    return store.getters['ConversationStore/getChatFilters'] || []
  }

  static setChatFilters (chatFilters: any[]) {
    store.commit('ConversationStore/SET_CHAT_FILTERS', chatFilters)
  }

  static getSelectedFilter () {
    return store.getters['ConversationStore/getSelectedFilter'] || {}
  }

  static getMineFilter () {
    const filters = store.getters['ConversationStore/getChatFilters'] || []
    const mineFilter = filters.find(filter => filter.rules?.some(rule => rule?.category === 'MINE'))
    return mineFilter || { name: 'defaultFilter', rules: [] }
  }

  static getAllFilter () {
    const filters = store.getters['ConversationStore/getChatFilters'] || []

    // get filter that has ALL rule
    const allFilter = filters.find(filter => filter.rules.some(rule => rule.category === 'ALL'))
    return allFilter
  }

  static getSentimentalAnalysisEnabled () {
    return store.getters['ConversationStore/getSentimentalAnalysisEnabled']
  }

  static getActionSuggestionsEnabled () {
    return store.getters['ConversationStore/getActionSuggestionsEnabled']
  }

  static getMessageSuggestionsEnabled () {
    return store.getters['ConversationStore/getMessageSuggestionsEnabled']
  }

  static setSentimentalAnalysisEnabled (enabled: boolean) {
    store.dispatch('ConversationStore/setSentimentalAnalysisEnabled', enabled)
  }

  static setActionSuggestionsEnabled (enabled: boolean) {
    store.dispatch('ConversationStore/setActionSuggestionsEnabled', enabled)
  }

  static setMessageSuggestionsEnabled (enabled: boolean) {
    store.dispatch('ConversationStore/setMessageSuggestionsEnabled', enabled)
  }

  // selected Room functions
  static getSelectedRooms () {
    return store.getters['ConversationStore/getSelectedRooms'] || []
  }

  static addSelectedRoom (room: Room) {
    store.dispatch('ConversationStore/addSelectedRoom', room)
  }

  static removeSelectedRoom (room: Room) {
    store.dispatch('ConversationStore/removeSelectedRoom', room)
  }

  static clearSelectedRooms () {
    store.dispatch('ConversationStore/clearSelectedRooms')
  }

  static isSelectedRoom (room: Room) {
    const rooms = ConversationStoreController.getSelectedRooms()
    return rooms.some(selectedRoom => selectedRoom._id === room._id)
  }

  static selectAllRooms () {
    const rooms = ConversationStoreController.getRooms()
    ConversationStoreController.clearSelectedRooms()
    rooms.forEach(room => {
      ConversationStoreController.addSelectedRoom(room)
    })
  }

  static areAllRoomsSelected () {
    const rooms = ConversationStoreController.getRooms()

    if (!rooms || rooms.length === 0) {
      return false
    }

    const allSelected = rooms.every(room => {
      return ConversationStoreController.isSelectedRoom(room)
    })

    return allSelected
  }

  static totalSelectedRooms () {
    const rooms = ConversationStoreController.getSelectedRooms()
    return rooms.length
  }
}
