
import { EventBus } from '@/eventBus'
import { mapState } from 'vuex'
import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import KBSimpleTable from '@/shared/infrastructure/ui/tables/KBSimpleTable.vue'
import KBButton from '@/shared/infrastructure/ui/buttons/KBButton.vue'
import KBText from '@/shared/infrastructure/ui/inputs/text/KBText.vue'
import AppsUseCases from '@/modules/Apps/application/AppsUseCases'
import AppsController from '@/modules/Apps/infrastructure/controllers/Apps.controller'
import { dataToGetApp, dataToSetDomain, dataToDeleteDomain, dataToVerifiedDomain, dataToCheckDomain } from '@/modules/Apps/domain/AppsController'
import Colors from '@/shared/domain/colors/Color'
import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'
import { ActivityActionTypeEnum, ActivityLogModuleTypeEnum, dataToWriteActivityLog } from '@/modules/Config/ActivityLogs/domain/ActivityLogsController'
import { CDPFieldsEnums } from '@/modules/CDP/shared/contacts/domain/enums/CDPFieldsEnums'
import ActivityLogsUseCases from '@/modules/Config/ActivityLogs/application/ActivityLogsUseCases'
import ActivityLogsController from '@/modules/Config/ActivityLogs/infrastructure/controllers/ActivityLogs.controller'

export default {
  name: 'DomainsConfig',
  components: {
    KBButton,
    KBText,
    KBSimpleTable,
    KeybeIcon
  },
  data () {
    return {
      Colors,
      domainsLink: `${process.env.VUE_APP_KEYBE_CO_URL}docs/ayudas/configura/cambio-de-dominio/`,
      search: '',
      KeybeIconType,
      count: 1,
      itemsPerPage: 10,
      currentPage: 1,
      loading: false,
      creating: false,
      newDomain: null,
      newDomainDialog: false,
      headers: [
        { text: this.$t('DomainName'), field: 'domain' },
        { text: this.$t('verifieddomain'), field: 'verified' },
        // { text: this.$t('dkinsettings'), field: CDPFieldsEnums.PLAN },
        // { text: this.$t('spfsettings'), field: 'count' },
        { text: this.$t('actions'), field: 'actions' }
      ],
      domains: [],
      appsController: new AppsController(),
      activityLogsController: new ActivityLogsController(),
      verifed: null,
      verifedTxt: null,
      domainSeleted: null,
      helpDomain: `${process.env.VUE_APP_HELPDOCS}/configura/cambio-de-dominio/`
    }
  },
  computed: {
    ...mapState('UserStore', ['token', 'user', 'isUserReadonly']),
    ...mapState('AppStore', ['selectedApp'])
  },
  async created () {
    await this.getDomains()
  },
  watch: {
    search () {
      this.searchDomain()
    }
  },
  methods: {
    async checkDomain (item) {
      const { domain } = item
      this.domainSeleted = null
      try {
        this.loading = true
        const data:dataToCheckDomain = {
          domain: domain.split(' ').join(''),
          uuid: this.selectedApp?.uuid,
          token: this.token
        }
        const response = await AppsUseCases.checkDomain(this.appsController, data)
        if (response) {
          if (!response?.status) {
            const { message } = response.data
            this.loading = false
            if (message) {
              EventBus.$emit('toast', 'error', message)
            }
            return
          }
          const { message, result } = response.data
          if (result.verified_at || message === 'El dominio ha sido verificado') {
            this.verifiedDomain(item)
            this.loading = false
            return
          }
          if (result) {
            this.domainSeleted = item.domain
            this.verifed = result.verified_at === null
            this.verifedTxt = result.verify_txt_key ? `mandrill_verify.${result.verify_txt_key}` : null
            this.loading = false
            return
          }
          if (message) {
            EventBus.$emit('toast', 'success', message)
          }
          this.loading = false
        }
      } catch (error) {
        this.loading = false
        EventBus.$emit('toast', 'error', error.message)
      }
    },
    async verifiedDomain (item) {
      const { domain } = item
      try {
        this.loading = true
        const data:dataToVerifiedDomain = {
          domain: domain.split(' ').join(''),
          uuid: this.selectedApp?.uuid,
          token: this.token
        }
        const response = await AppsUseCases.verifiedDomain(this.appsController, data)
        if (response) {
          if (!response?.status) {
            const { message } = response.data
            this.loading = false
            if (message) {
              EventBus.$emit('toast', 'error', message)
            }
            return
          }
          const { message } = response.data
          if (message) {
            EventBus.$emit('toast', 'success', message)
          }
          await this.getDomains()
          this.loading = false
        }
      } catch (error) {
        this.loading = false
        EventBus.$emit('toast', 'error', error.message)
      }
    },
    async removeDomain (domain) {
      try {
        if (this.isUserReadonly) return
        this.loading = true
        const data:dataToDeleteDomain = {
          domain: domain.split(' ').join(''),
          uuid: this.selectedApp?.uuid,
          token: this.token
        }
        const response = await AppsUseCases.deleteDomain(this.appsController, data)
        if (response) {
          if (!response?.status) {
            const { message } = response
            this.loading = false
            EventBus.$emit('toast', 'success', message)
            return
          }
          EventBus.$emit('toast', 'error', this.$t('domainSuccessfullyDeleted'))
          await this.getDomains()
          await this.writeActivityLogDelete(domain)
          this.loading = false
        }
      } catch (error) {
        this.loading = false
        EventBus.$emit('toast', 'error', error.message)
      }
    },
    positionClass (index, length) {
      if (index === 0) {
        return 'first-item'
      }
      if (index === length - 1) {
        return 'last-item'
      }
      return ''
    },
    async createDomain () {
      try {
        if (!this.newDomain) {
          return
        }
        this.creating = true
        const data:dataToSetDomain = {
          domain: {
            domain: this.newDomain.split(' ').join(''),
            verified: true
          },
          uuid: this.selectedApp?.uuid,
          token: this.token
        }
        const response = await AppsUseCases.setDomain(this.appsController, data)
        if (response) {
          EventBus.$emit('toast', 'error', this.$t('domainSuccessfullyCreated'))
          await this.writeActivityLogCreate()
          this.newDomain = null
          await this.getDomains()
          this.creating = false
          this.newDomainDialog = false
        }
      } catch (error) {
        this.creating = false
        EventBus.$emit('toast', 'error', error.message)
      }
    },
    async writeActivityLogCreate (): Promise<void> {
      const data: dataToWriteActivityLog = {
        token: this.token,
        updateInput: {
          appUUID: this.selectedApp?.uuid,
          module: ActivityLogModuleTypeEnum.OUTBOUND,
          action: ActivityActionTypeEnum.DOMAIN_CREATE,
          user: {
            id: this.user.uuid,
            name: this.user.name + ' ' + this.user.lastName
          },
          payload: {
            field: CDPFieldsEnums.NAME,
            newValue: this.newDomain,
            oldValue: ''
          }
        }
      }
      this.writeActivityLog(data)
    },
    async writeActivityLogDelete (domain: string): Promise<void> {
      const data: dataToWriteActivityLog = {
        token: this.token,
        updateInput: {
          appUUID: this.selectedApp?.uuid,
          module: ActivityLogModuleTypeEnum.OUTBOUND,
          action: ActivityActionTypeEnum.DOMAIN_DELETE,
          user: {
            id: this.user.uuid,
            name: this.user.name + ' ' + this.user.lastName
          },
          payload: {
            field: CDPFieldsEnums.NAME,
            newValue: domain,
            oldValue: ''
          }
        }
      }
      this.writeActivityLog(data)
    },
    async writeActivityLog (data: dataToWriteActivityLog): Promise<void> {
      await ActivityLogsUseCases.writeActivityLog(this.activityLogsController, data)
    },
    async searchDomain () {
      if (!this.search) {
        this.getDomains()
        return
      }
      if (!this.domains) {
        return
      }
      const domainsFiltered = this.domains.filter(domain => {
        return domain.domain.toLowerCase().includes(this.search.toLowerCase())
      })
      this.count = domainsFiltered.length
      this.domains = domainsFiltered
    },
    async getDomains () {
      try {
        this.newDomain = null
        this.loading = true
        const data:dataToGetApp = {
          uuid: this.selectedApp?.uuid,
          token: this.token
        }
        const response = await AppsUseCases.getApp(this.appsController, data)
        if (response) {
          const { domains } = response?.data
          if (domains) {
            this.domains = domains
            this.count = response.length
          } else {
            this.domains = []
          }
          this.loading = false
        }
      } catch (error) {
        this.loading = false
        EventBus.$emit('toast', 'error', error.message)
      }
    }
  }
}
