








export default {
  name: 'EditSpacesIcon'
}
