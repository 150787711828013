import { EventBus } from '@/eventBus'
import FacebookService from '@/components/configuration/Services/FacebookService'
import FacebookProvider from '@/components/configuration/Providers/FacebookProvider'
import ConversationsConfigService from '@/components/configuration/Services/ConversationsConfigService'

import FacebookLogin from '@/components/configuration/conversations/channels/FacebookLogin/FacebookLogin.vue'
import KBButton from '@/shared/infrastructure/ui/buttons/KBButton.vue'
import KBSmallSelector from '@/shared/infrastructure/ui/inputs/select/KBSmallSelector.vue'
import {
  ActivityLogModuleTypeEnum,
  ActivityActionTypeEnum,
  dataToWriteActivityLog,
  ActivitySubmoduleTypeEnum
} from '@/modules/Config/ActivityLogs/domain/ActivityLogsController'
import ActivityLogsUseCases from '@/modules/Config/ActivityLogs/application/ActivityLogsUseCases'
import { mapState } from 'vuex'

import { CDPFieldsEnums } from '@/modules/CDP/shared/contacts/domain/enums/CDPFieldsEnums'
const facebookWebhooks = ['messages', 'feed']

export default {
  components: { FacebookLogin, KBButton, KBSmallSelector },
  props: ['hasFacebook', 'config', 'tokenPercentage', 'type'],
  computed: {
    ...mapState('UserStore', ['user', 'token', 'isUserReadonly']),
    ...mapState('AppStore', ['selectedApp'])
  },
  data: function () {
    return {
      longLivedAccessToken: '',
      facebookAppId: process.env.VUE_APP_FACEBOOK_MESSENGER_APP_ID,
      facebookPermissions: [
        'public_profile',
        'pages_messaging',
        'pages_show_list',
        'pages_manage_metadata',
        'business_management'
        // 'pages_manage_engagement',
        // 'pages_read_user_content',
        // 'pages_read_engagement'
      ],
      pages: [],
      selectedPage: undefined,
      CDPFieldsEnums
    }
  },
  methods: {
    async getPages (shortLivedAccessToken: string): Promise<void> {
      try {
        if (this.isUserReadonly) return
        this.longLivedAccessToken =
          await FacebookService.getLongLivedAccessToken(
            'facebookMessenger',
            shortLivedAccessToken
          )
        const userAccounts = await FacebookService.getUserAccounts(
          this.longLivedAccessToken
        )
        if (!userAccounts?.data.length) {
          throw new Error(this.$t('unexpectedError'))
        }
        this.pages = userAccounts.data
        this.selectedPage = this.pages[0]
      } catch (error) {
        FacebookProvider.setBusySDK(false)
        console.error('An error has occurred while trying to get pages', error)
        EventBus.$emit('toast', 'error', error.message)
      }
    },
    async connect (): Promise<void> {
      try {
        const pageAccessToken = this.selectedPage.access_token
        await FacebookService.subscribeToWebhooks(
          this.selectedPage.id,
          facebookWebhooks,
          pageAccessToken
        )
        try {
          await ConversationsConfigService.createFacebookConfig({
            pageId: this.selectedPage.id,
            pageName: this.selectedPage?.name || '',
            pageAccessToken
          })
        } catch (error) {
          if (
            error.message ===
            'Error creating a Facebook Messenger configuration: The page is already connected to a Keybe App'
          ) {
            throw new Error(this.$t('pageIsAlreadyConnected'))
          } else {
            throw new Error(this.$t('unexpectedError'))
          }
        }
        await FacebookService.logout()
        FacebookProvider.setBusySDK(false)
        EventBus.$emit(
          'toast',
          'success',
          this.$t('facebookMessengerConnected')
        )
        this.pages = []
        this.writeActivityLog()
        this.$emit('connectedFacebook')
      } catch (error) {
        console.error('An error has occurred while trying to connect', error)
        EventBus.$emit('toast', 'error', error.message)
      }
    },
    async writeActivityLog (): Promise<void> {
      const data: dataToWriteActivityLog = {
        token: this.token,
        updateInput: {
          appUUID: this.selectedApp?.uuid,
          module: ActivityLogModuleTypeEnum.CHAT,
          submodule: ActivitySubmoduleTypeEnum.CHANNELS,
          action: ActivityActionTypeEnum.FACEBOOK_CONNECTED,
          user: {
            id: this.user.uuid,
            name: this.user.name + ' ' + this.user.lastName
          }
        }
      }
      await ActivityLogsUseCases.writeActivityLog(
        this.activityLogsController,
        data
      )
    },
    async refreshToken (shortLivedAccessToken: string): Promise<void> {
      try {
        const longLivedAccessToken =
          await FacebookService.getLongLivedAccessToken(
            'facebookMessenger',
            shortLivedAccessToken
          )
        const userAccounts = await FacebookService.getUserAccounts(
          longLivedAccessToken
        )
        if (!userAccounts?.data.length) {
          throw new Error('Ha ocurrido un error, por favor inténtalo más tarde')
        }
        const pages = userAccounts.data
        const pageId = this.config.page_id[0]
        const page = pages.find((p) => p.id === pageId)
        if (!page) {
          throw new Error(
            'El usuario no tiene permisos suficientes en la página'
          )
        }
        const pageAccessToken = page.access_token
        try {
          await ConversationsConfigService.updateFacebookConfig({
            pageId,
            pageAccessToken,
            pageName: page?.name || ''
          })
        } catch (error) {
          throw new Error('Ha ocurrido un error, por favor inténtalo más tarde')
        }
        await FacebookService.logout()
        FacebookProvider.setBusySDK(false)
        EventBus.$emit(
          'toast',
          'success',
          'Conexión extendida satisfactoriamente'
        )
        this.$emit('refreshedToken')
      } catch (error) {
        FacebookProvider.setBusySDK(false)
        console.error(
          'An error has occurred while trying to refresh the token',
          error
        )
        EventBus.$emit('toast', 'error', error.message)
      }
    },
    goToPage () {
      window.open(`https://facebook.com/${this.config.page_id[0]}`, '_blank')
    }
  }
}
