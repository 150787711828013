import axios from 'axios'
import store from '@/store/index'
import { RestResponseFactory } from '@/models/apis/RestResponse'
import { RestResponse } from '@/shared/infrastructure/http/RestResponse'

export default class MetricsService {
  static async getRealTimeProactivity (advisorList) {
    const companyId: string = store.getters['CompanyStore/getSelectedCompany'].uuid
    const appId: string = store.getters['AppStore/getSelectedApp']?.uuid
    const token = store.getters['UserStore/getMetricsToken']
    let path = ''
    if (advisorList) {
      path = `company/${companyId}/app/${appId}/conversations/proactivity?advisorList=${advisorList.toString()}`
    } else {
      path = `company/${companyId}/app/${appId}/conversations/proactivity`
    }

    const url: string = `${process.env.VUE_APP_V6_ANALYTICS_URL}/${path}`

    const response = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    })

    return new RestResponseFactory().process({ response })
  }

  static async getNewCSAT (weeks) {
    const { periodEnd, periodStart } = weeks
    const companyId: string = store.getters['CompanyStore/getSelectedCompany'].uuid
    const appId: string = store.getters['AppStore/getSelectedApp']?.uuid
    const token = store.getters['UserStore/getMetricsToken']
    const path = `company/${companyId}/app/${appId}/csat/summary?from_date=${periodStart}&to_date=${periodEnd}`

    const url: string = `${process.env.VUE_APP_V6_ANALYTICS_URL}/${path}`

    const response = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    })

    return new RestResponseFactory().process({ response })
  }

  static async getRealTimeAdvisor (advisorList) {
    const companyId: string = store.getters['CompanyStore/getSelectedCompany'].uuid
    const appId: string = store.getters['AppStore/getSelectedApp']?.uuid
    const token = store.getters['UserStore/getMetricsToken']
    let path = ''
    if (advisorList) {
      path = `company/${companyId}/app/${appId}/conversations/advisor?advisorList=${advisorList.toString()}`
    } else {
      path = `company/${companyId}/app/${appId}/conversations/advisor`
    }

    const url: string = `${process.env.VUE_APP_V6_ANALYTICS_URL}/${path}`

    const response = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    })

    return new RestResponseFactory().process({ response })
  }

  static async getRealTimeSessions (advisorList) {
    const companyId: string = store.getters['CompanyStore/getSelectedCompany'].uuid
    const appId: string = store.getters['AppStore/getSelectedApp']?.uuid
    const token = store.getters['UserStore/getMetricsToken']
    let path = ''
    if (advisorList) {
      path = `company/${companyId}/app/${appId}/conversations/sessions?advisorList=${advisorList.toString()}`
    } else {
      path = `company/${companyId}/app/${appId}/conversations/sessions`
    }

    const url: string = `${process.env.VUE_APP_V6_ANALYTICS_URL}/${path}`

    const response = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    })

    return new RestResponseFactory().process({ response })
  }

  static async getRealTimeMetrics (advisorList) {
    const companyId: string = store.getters['CompanyStore/getSelectedCompany'].uuid
    const appId: string = store.getters['AppStore/getSelectedApp']?.uuid
    const token = store.getters['UserStore/getMetricsToken']
    let path = ''
    if (advisorList) {
      path = `company/${companyId}/app/${appId}/conversations/realtime-v2?advisorList=${advisorList.toString()}`
    } else {
      path = `company/${companyId}/app/${appId}/conversations/realtime-v2`
    }

    // const path = `company/${companyId}/app/${appId}/conversations/realtime`
    const url: string = `${process.env.VUE_APP_V6_ANALYTICS_URL}/${path}`

    const response = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    })

    return new RestResponseFactory().process({ response })
  }

  static async getPotencialG (data) {
    const companyId: string = store.getters['CompanyStore/getSelectedCompany'].uuid
    const appId: string = store.getters['AppStore/getSelectedApp']?.uuid
    const token = store.getters['UserStore/getMetricsToken']
    const { periodEnd, periodStart, pipelineId } = data
    let path = ''
    if (pipelineId) {
      path = `company/${companyId}/app/${appId}/pipelines/average_conversion_metrics?from_date=${periodStart}&to_date=${periodEnd}&funnel_id=${pipelineId}`
    } else {
      path = `company/${companyId}/app/${appId}/pipelines/average_conversion_metrics?from_date=${periodStart}&to_date=${periodEnd}`
    }
    const url: string = `${process.env.VUE_APP_V6_ANALYTICS_URL}/${path}`

    const response = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    })

    return new RestResponseFactory().process({ response })
  }

  static async getConversationByAdvisors (advisorList) {
    try {
      const token = store.getters['UserStore/getToken']
      const companyId = store.getters['CompanyStore/getSelectedCompany'].uuid
      const appId = store.getters['AppStore/getSelectedApp'].uuid
      let path = ''
      if (advisorList) {
        path = `company/${companyId}/app/${appId}/conversations/advisors/current/state?advisorList=${advisorList.toString()}`
      } else {
        path = `company/${companyId}/app/${appId}/conversations/advisors/current/state`
      }
      const response = await axios.get(
        `${process.env.VUE_APP_V6_ANALYTICS_URL}/${path}`,
        {
          headers: {
            Authorization: 'Bearer ' + token
          }
        }
      )
      return { response }
    } catch (e) {
      const errorResponse = new RestResponseFactory().process({ response: { status: 500 } })
      errorResponse.data = e.response.data
      return errorResponse
    }
  }

  static async getAverageTalkVolume (data, advisorList) {
    const companyId: string = store.getters['CompanyStore/getSelectedCompany'].uuid
    const appId: string = store.getters['AppStore/getSelectedApp']?.uuid
    const token = store.getters['UserStore/getMetricsToken']
    const { periodEnd, periodStart } = data
    let path = ''
    if (advisorList) {
      path = `company/${companyId}/app/${appId}/conversations/historic/heatmap/v2?from_date=${periodStart}&to_date=${periodEnd}&advisorList=${advisorList.toString()}`
    } else {
      path = `company/${companyId}/app/${appId}/conversations/historic/heatmap/v2?from_date=${periodStart}&to_date=${periodEnd}`
    }
    const url: string = `${process.env.VUE_APP_V6_ANALYTICS_URL}/${path}`

    const response = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    })

    return new RestResponseFactory().process({ response })
  }

  static async getFirstTimeV6 (data) {
    const companyId: string = store.getters['CompanyStore/getSelectedCompany'].uuid
    const appId: string = store.getters['AppStore/getSelectedApp']?.uuid
    const token = store.getters['UserStore/getMetricsToken']
    const { periodEnd, periodStart } = data
    const url: string = `${process.env.VUE_APP_V6_ANALYTICS_URL}/company/${companyId}/app/${appId}/conversations/historic/range_first_answer_time?from_date=${periodStart}&to_date=${periodEnd}`

    const response = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    })

    return new RestResponseFactory().process({ response })
  }

  static async getHistoricConversations (weeks, advisorList) {
    const companyId: string = store.getters['CompanyStore/getSelectedCompany'].uuid
    const appId: string = store.getters['AppStore/getSelectedApp']?.uuid
    let path = ''
    if (advisorList) {
      path = `company/${companyId}/app/${appId}/conversations/historic/totals_by_channel/v2?from_date=${weeks.periodStart}&to_date=${weeks.periodEnd}&advisorList=${advisorList.toString()}`
    } else {
      path = `company/${companyId}/app/${appId}/conversations/historic/totals_by_channel/v2?from_date=${weeks.periodStart}&to_date=${weeks.periodEnd}`
    }
    const url: string = `${process.env.VUE_APP_V6_ANALYTICS_URL}/${path}`
    const token = store.getters['UserStore/getMetricsToken']

    const response = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    })

    return new RestResponseFactory().process({ response })
  }

  static async getAdvisorsPerformanceDetails ({ periodStart, periodEnd }, advisorList) {
    const companyId: string = store.getters['CompanyStore/getSelectedCompany'].uuid
    const appId: string = store.getters['AppStore/getSelectedApp']?.uuid
    let path = ''
    if (advisorList) {
      path = `company/${companyId}/app/${appId}/conversations/advisors/performance?from_date=${periodStart}&to_date=${periodEnd}&advisorList=${advisorList.toString()}`
    } else {
      path = `company/${companyId}/app/${appId}/conversations/advisors/performance?from_date=${periodStart}&to_date=${periodEnd}`
    }
    const url: string = `${process.env.VUE_APP_V6_ANALYTICS_URL}/${path}`
    const token = store.getters['UserStore/getMetricsToken']

    const response = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    })

    return new RestResponseFactory().process({ response })
  }

  static async getBarEndReason ({ periodStart, periodEnd }, advisorList) {
    const companyId: string = store.getters['CompanyStore/getSelectedCompany'].uuid
    const appId: string = store.getters['AppStore/getSelectedApp']?.uuid
    const token = store.getters['UserStore/getMetricsToken']
    let path = ''
    if (advisorList) {
      path = `company/${companyId}/app/${appId}/conversations/historic/ended_reasons?from_date=${periodStart}&to_date=${periodEnd}&advisorList=${advisorList.toString()}`
    } else {
      path = `company/${companyId}/app/${appId}/conversations/historic/ended_reasons?from_date=${periodStart}&to_date=${periodEnd}`
    }
    const url: string = `${process.env.VUE_APP_V6_ANALYTICS_URL}/${path}`

    const response = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    })

    return new RestResponseFactory().process({ response })
  }

  static async getCRMBenchmark (data) {
    const companyId: string = store.getters['CompanyStore/getSelectedCompany'].uuid
    const appId: string = store.getters['AppStore/getSelectedApp']?.uuid
    const token = store.getters['UserStore/getMetricsToken']
    const { endDate, startDate, pipelineId } = data
    const request = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      params: {
        pipeline_id: pipelineId,
        from_date: startDate,
        to_date: endDate
      }
    }
    const url = `${process.env.VUE_APP_V6_ANALYTICS_URL}/company/${companyId}/app/${appId}/pipelines/deals/lost_vs_win`
    const response = await axios.get(url, request)

    return new RestResponseFactory().process({ response })
  }

  static async getSalesByUser (data) {
    const companyId: string = store.getters['CompanyStore/getSelectedCompany'].uuid
    const appId: string = store.getters['AppStore/getSelectedApp']?.uuid
    const token = store.getters['UserStore/getMetricsToken']
    const { endDate, startDate, pipelineId } = data
    const request = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    }
    const url = `${process.env.VUE_APP_V6_ANALYTICS_URL}/company/${companyId}/app/${appId}/pipelines/new_sales_by_user?from_date=${startDate}&to_date=${endDate}&funnel_id=${pipelineId}`
    const response = await axios.get(url, request)
    return new RestResponseFactory().process({ response })
  }

  static async getAppFunnels (app) {
    try {
      const url = `${process.env.VUE_APP_FUNNELS_HTTP}/${app}/funnel`
      const token = store.getters['UserStore/getToken']
      const response = await axios.get(
        url,
        {
          headers: {
            Authorization: 'Bearer ' + token
          }
        }
      )
      return new RestResponseFactory().process({ response })
    } catch (e) {
      console.log(e)
    }
  }

  static async getAppPipelines () {
    const companyId: string = store.getters['CompanyStore/getSelectedCompany'].uuid
    const appId: string = store.getters['AppStore/getSelectedApp']?.uuid
    const token = store.getters['UserStore/getMetricsToken']
    const request = {
      headers: {
        'Content-Type': 'application/json',
        authorization: 'Bearer ' + token
      }
    }
    const url = `${process.env.VUE_APP_V6_ANALYTICS_URL}/company/${companyId}/app/${appId}/pipelines`
    const response = await axios.get(url, request)

    return new RestResponseFactory().process({ response })
  }

  static async getSalesPerformance (data) {
    const companyId: string = store.getters['CompanyStore/getSelectedCompany'].uuid
    const appId: string = store.getters['AppStore/getSelectedApp']?.uuid
    const token = store.getters['UserStore/getMetricsToken']
    const { endDate, startDate } = data
    const request = {
      headers: {
        'Content-Type': 'application/json',
        authorization: 'Bearer ' + token
      }
    }
    const url = `${process.env.VUE_APP_V6_ANALYTICS_URL}/company/${companyId}/app/${appId}/pipelines/sales/performance?from_date=${startDate}&to_date=${endDate}`
    const response = await axios.get(url, request)
    return new RestResponseFactory().process({ response })
  }

  static async getPeopleStatus (data) {
    const companyId: string = store.getters['CompanyStore/getSelectedCompany'].uuid
    const appId: string = store.getters['AppStore/getSelectedApp']?.uuid
    const token = store.getters['UserStore/getMetricsToken']
    const { endDate, startDate } = data
    const request = {
      headers: {
        'Content-Type': 'application/json',
        authorization: 'Bearer ' + token
      }
    }
    const url = `${process.env.VUE_APP_V6_ANALYTICS_URL}/company/${companyId}/app/${appId}/pipelines/people_status?from_date=${startDate}&to_date=${endDate}&funnel_id=${data?.pipelineId}`
    const response = await axios.get(url, request)
    return new RestResponseFactory().process({ response })
  }

  static async getFunnelCRM (data) {
    const companyId: string = store.getters['CompanyStore/getSelectedCompany'].uuid
    const appId: string = store.getters['AppStore/getSelectedApp']?.uuid
    const token = store.getters['UserStore/getMetricsToken']
    const { endDate, startDate, pipelineId } = data
    const request = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    }
    const url = `${process.env.VUE_APP_V6_ANALYTICS_URL}/company/${companyId}/app/${appId}/pipelines/funnel/sales_cards?from_date=${startDate}&to_date=${endDate}&funnel_id=${pipelineId}`
    const response = await axios.get(url, request)
    return new RestResponseFactory().process({ response })
  }

  static async getDeals (data) {
    const companyId: string = store.getters['CompanyStore/getSelectedCompany'].uuid
    const appId: string = store.getters['AppStore/getSelectedApp']?.uuid
    const token = store.getters['UserStore/getMetricsToken']
    const { periodEnd, periodStart, pipelineId } = data
    const request = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    }
    const url = `${process.env.VUE_APP_V6_ANALYTICS_URL}/company/${companyId}/app/${appId}/pipelines/new_deals?from_date=${periodStart}&to_date=${periodEnd}&funnel_id=${pipelineId}`
    const response = await axios.get(url, request)
    return new RestResponseFactory().process({ response })
  }

  static async getCRMDealsWon ({ endDate, startDate, pipelineId }) {
    const companyId: string = store.getters['CompanyStore/getSelectedCompany'].uuid
    const appId: string = store.getters['AppStore/getSelectedApp']?.uuid
    const token = store.getters['UserStore/getMetricsToken']
    const request = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    }
    const url = `${process.env.VUE_APP_V6_ANALYTICS_URL}/company/${companyId}/app/${appId}/pipelines/sales_cards/lost_vs_win?from_date=${startDate}&to_date=${endDate}&funnel_id=${pipelineId}`
    const response = await axios.get(url, request)

    return new RestResponseFactory().process({ response })
  }

  static async getFileRealTime () {
    try {
      const token = store.getters['UserStore/getToken']
      const companyId = store.getters['CompanyStore/getSelectedCompany'].uuid
      const appId = store.getters['AppStore/getSelectedApp'].uuid
      const user = store.getters['UserStore/getUser']

      let userName = user?.name
      if (user?.name && user?.lastName) userName = user?.name + ' ' + user?.lastName

      const url = `${process.env.VUE_APP_V6_ANALYTICS_URL}/company/${companyId}/app/${appId}/conversations/advisor/v2?export_mode=xlsx`
      const response = await axios.post(
        url,
        {
          exportOptions: {
            toEmail: user?.email,
            toName: userName
          }
        },
        {
          headers: {
            Authorization: 'Bearer ' + token
          }
        }
      )
      return new RestResponseFactory().process({ response })
    } catch (e) {
      const errorResponse = new RestResponseFactory().process({ response: { status: 500 } })
      errorResponse.data = e.response?.data
      return errorResponse
    }
  }

  static async getFile (weeks) {
    try {
      const token = store.getters['UserStore/getToken']
      const companyId = store.getters['CompanyStore/getSelectedCompany'].uuid
      const appId = store.getters['AppStore/getSelectedApp'].uuid
      const response = await axios.get(
        `${process.env.VUE_APP_V6_ANALYTICS_URL}/company/${companyId}/app/${appId}/export/conversations?from_date=${weeks.periodStart}&to_date=${weeks.periodEnd}`,
        {
          headers: {
            Authorization: 'Bearer ' + token
          }
        }
      )
      return new RestResponseFactory().process({ response })
    } catch (e) {
      const errorResponse = new RestResponseFactory().process({ response: { status: 500 } })
      errorResponse.data = e.response.data
      return errorResponse
    }
  }

  static async getFileChats (weeks: {periodStart: string, periodEnd: string}, condition: boolean): Promise<RestResponse> {
    try {
      const token = store.getters['UserStore/getToken']
      const companyId = store.getters['CompanyStore/getSelectedCompany'].uuid
      const appId = store.getters['AppStore/getSelectedApp'].uuid
      let url = `${process.env.VUE_APP_V6_ANALYTICS_URL}/company/${companyId}/app/${appId}/export/conversations/v2?from_date=${weeks.periodStart}&to_date=${weeks.periodEnd}`
      if (condition) {
        url = `${url}&export_mode=xlsx`
      }
      const response = await axios.get(
        url,
        {
          headers: {
            Authorization: 'Bearer ' + token
          }
        }
      )
      return new RestResponseFactory().process({ response })
    } catch (e) {
      const errorResponse = new RestResponseFactory().process({ response: { status: 500 } })
      errorResponse.data = e.response.data
      return errorResponse
    }
  }

  static async getFileChatsV2 (weeks: {periodStart: string, periodEnd: string}, condition: boolean): Promise<RestResponse> {
    try {
      const token = store.getters['UserStore/getToken']
      const companyId = store.getters['CompanyStore/getSelectedCompany'].uuid
      const appId = store.getters['AppStore/getSelectedApp'].uuid
      const user: {email: string, name: string} = store.getters['UserStore/getUser']
      let url = `${process.env.VUE_APP_V6_ANALYTICS_URL}/company/${companyId}/app/${appId}/export/conversations/v2?from_date=${weeks.periodStart}&to_date=${weeks.periodEnd}`
      if (condition) {
        url = `${url}&export_mode=xlsx`
      }
      const response = await axios.post(
        url,
        {
          exportOptions: {
            toEmail: user?.email,
            toName: user?.name
          }
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      )
      return new RestResponseFactory().process({ response })
    } catch (e) {
      const errorResponse = new RestResponseFactory().process({ response: { status: 500 } })
      errorResponse.data = e.response.data
      return errorResponse
    }
  }

  static async getCSAT (weeks) {
    try {
      const token = store.getters['UserStore/getToken']
      const appId = store.getters['AppStore/getSelectedApp'].uuid
      const { periodEnd, periodStart } = weeks

      const responseFlows = await axios.get(
        `${process.env.VUE_APP_MAKE_URL}/csat/${appId}/metrics?from=${periodStart}&to=${periodEnd}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
          }
        }
      )
      if (responseFlows?.data?.success && responseFlows?.data?.message !== 'No data found') return new RestResponseFactory().process({ response: responseFlows })

      const identifier = {
        appUUID: appId,
        dateInit: periodStart,
        dateEnd: periodEnd
      }
      const response = await axios.post(
        `${process.env.VUE_APP_FLOWS_URL}/stats/general`, identifier,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
          }
        }
      )
      return new RestResponseFactory().process({ response })
    } catch (e) {
      const errorResponse = new RestResponseFactory().process({ response: { status: 500 } })
      errorResponse.data = e.response?.data
      return errorResponse
    }
  }

  static async getConversationsState (advisorList) {
    const companyId: string = store.getters['CompanyStore/getSelectedCompany'].uuid
    const appId: string = store.getters['AppStore/getSelectedApp']?.uuid
    let path = ''
    if (advisorList) {
      path = `company/${companyId}/app/${appId}/conversations/current/state?advisorList=${advisorList.toString()}`
    } else {
      path = `company/${companyId}/app/${appId}/conversations/current/state`
    }
    const url: string = `${process.env.VUE_APP_V6_ANALYTICS_URL}/${path}`
    const token = store.getters['UserStore/getMetricsToken']

    const response = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    })
    return new RestResponseFactory().process({ response })
  }

  static async getStatus () {
    try {
      const token = store.getters['UserStore/getToken']
      const companyId = store.getters['CompanyStore/getSelectedCompany'].uuid
      const appId = store.getters['AppStore/getSelectedApp'].uuid
      const response = await axios.get(
        `${process.env.VUE_APP_V6_ANALYTICS_URL}/company/${companyId}/app/${appId}/conversations/statuses`,
        {
          headers: {
            Authorization: 'Bearer ' + token
          }
        }
      )
      return new RestResponseFactory().process({ response })
    } catch (e) {
      const errorResponse = new RestResponseFactory().process({ response: { status: 500 } })
      errorResponse.data = e.response?.data
      return errorResponse
    }
  }

  static async getWaitingTimes (weeks) {
    try {
      const token = store.getters['UserStore/getToken']
      const companyId = store.getters['CompanyStore/getSelectedCompany'].uuid
      const appId = store.getters['AppStore/getSelectedApp'].uuid
      const response = await axios.get(
        `${process.env.VUE_APP_V6_ANALYTICS_URL}/company/${companyId}/app/${appId}/conversations/historic/pie_duration_session?from_date=${weeks.periodStart}&to_date=${weeks.periodEnd}`,
        {
          headers: {
            Authorization: 'Bearer ' + token
          }
        }
      )
      return new RestResponseFactory().process({ response })
    } catch (e) {
      const errorResponse = new RestResponseFactory().process({ response: { status: 500 } })
      errorResponse.data = e.response.data
      return errorResponse
    }
  }

  static async getConversationTimes (weeks) {
    try {
      const token = store.getters['UserStore/getToken']
      const companyId = store.getters['CompanyStore/getSelectedCompany'].uuid
      const appId = store.getters['AppStore/getSelectedApp'].uuid
      const response = await axios.get(
        `${process.env.VUE_APP_V6_ANALYTICS_URL}/company/${companyId}/app/${appId}/conversations/historic/pie_waiting_time?from_date=${weeks.periodStart}&to_date=${weeks.periodEnd}`,
        {
          headers: {
            Authorization: 'Bearer ' + token
          }
        }
      )
      return new RestResponseFactory().process({ response })
    } catch (e) {
      const errorResponse = new RestResponseFactory().process({ response: { status: 500 } })
      errorResponse.data = e.response.data
      return errorResponse
    }
  }

  static async getDataCountConversations (data, advisorList) {
    const companyId: string = store.getters['CompanyStore/getSelectedCompany'].uuid
    const appId: string = store.getters['AppStore/getSelectedApp']?.uuid
    const token = store.getters['UserStore/getMetricsToken']
    const { periodEnd, periodStart } = data
    let path = ''
    if (advisorList) {
      path = `company/${companyId}/app/${appId}/conversations/historic/count_conversations?from_date=${periodStart}&to_date=${periodEnd}&advisorList=${advisorList.toString()}`
    } else {
      path = `company/${companyId}/app/${appId}/conversations/historic/count_conversations?from_date=${periodStart}&to_date=${periodEnd}`
    }
    const url: string = `${process.env.VUE_APP_V6_ANALYTICS_URL}/${path}`

    const response = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    })

    return new RestResponseFactory().process({ response })
  }

  static async getDataCountClosedSessions (data, advisorList) {
    const companyId: string = store.getters['CompanyStore/getSelectedCompany'].uuid
    const appId: string = store.getters['AppStore/getSelectedApp']?.uuid
    const token = store.getters['UserStore/getMetricsToken']
    const { periodEnd, periodStart } = data
    let path = ''
    if (advisorList) {
      path = `company/${companyId}/app/${appId}/conversations/historic/count_closed_sessions?from_date=${periodStart}&to_date=${periodEnd}&advisorList=${advisorList.toString()}`
    } else {
      path = `company/${companyId}/app/${appId}/conversations/historic/count_closed_sessions?from_date=${periodStart}&to_date=${periodEnd}`
    }
    const url: string = `${process.env.VUE_APP_V6_ANALYTICS_URL}/${path}`

    const response = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    })

    return new RestResponseFactory().process({ response })
  }

  static async getDataCountClosedSessionsIA (data, advisorList) {
    const companyId: string = store.getters['CompanyStore/getSelectedCompany'].uuid
    const appId: string = store.getters['AppStore/getSelectedApp']?.uuid
    const token = store.getters['UserStore/getMetricsToken']
    const { periodEnd, periodStart } = data
    let path = ''
    if (advisorList) {
      path = `company/${companyId}/app/${appId}/conversations/historic/count_closed_sessions_IA?from_date=${periodStart}&to_date=${periodEnd}&advisorList=${advisorList.toString()}`
    } else {
      path = `company/${companyId}/app/${appId}/conversations/historic/count_closed_sessions_IA?from_date=${periodStart}&to_date=${periodEnd}`
    }
    const url: string = `${process.env.VUE_APP_V6_ANALYTICS_URL}/${path}`

    const response = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    })

    return new RestResponseFactory().process({ response })
  }

  static async getDataMeanSessionTime (data, advisorList) {
    const companyId: string = store.getters['CompanyStore/getSelectedCompany'].uuid
    const appId: string = store.getters['AppStore/getSelectedApp']?.uuid
    const token = store.getters['UserStore/getMetricsToken']
    const { periodEnd, periodStart } = data
    let path = ''
    if (advisorList) {
      path = `company/${companyId}/app/${appId}/conversations/historic/mean_session_time?from_date=${periodStart}&to_date=${periodEnd}&advisorList=${advisorList.toString()}`
    } else {
      path = `company/${companyId}/app/${appId}/conversations/historic/mean_session_time?from_date=${periodStart}&to_date=${periodEnd}`
    }
    const url: string = `${process.env.VUE_APP_V6_ANALYTICS_URL}/${path}`

    const response = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    })

    return new RestResponseFactory().process({ response })
  }

  static async getDataMeanWaitingTime (data, advisorList) {
    const companyId: string = store.getters['CompanyStore/getSelectedCompany'].uuid
    const appId: string = store.getters['AppStore/getSelectedApp']?.uuid
    const token = store.getters['UserStore/getMetricsToken']
    const { periodEnd, periodStart } = data
    let path = ''
    if (advisorList) {
      path = `company/${companyId}/app/${appId}/conversations/historic/mean_waiting_time?from_date=${periodStart}&to_date=${periodEnd}&advisorList=${advisorList.toString()}`
    } else {
      path = `company/${companyId}/app/${appId}/conversations/historic/mean_waiting_time?from_date=${periodStart}&to_date=${periodEnd}`
    }
    const url: string = `${process.env.VUE_APP_V6_ANALYTICS_URL}/${path}`

    const response = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    })

    return new RestResponseFactory().process({ response })
  }

  static async getDataChat (data, advisorList) {
    const companyId: string = store.getters['CompanyStore/getSelectedCompany'].uuid
    const appId: string = store.getters['AppStore/getSelectedApp']?.uuid
    const token = store.getters['UserStore/getMetricsToken']
    const { periodEnd, periodStart } = data
    let path = ''
    if (advisorList && advisorList.length > 0) {
      path = `company/${companyId}/app/${appId}/conversations/historic/detail_by_adisor/v2?from_date=${periodStart}&to_date=${periodEnd}&advisorList=${advisorList.toString()}`
    } else {
      path = `company/${companyId}/app/${appId}/conversations/historic/detail_by_adisor/v2?from_date=${periodStart}&to_date=${periodEnd}`
    }
    const url: string = `${process.env.VUE_APP_V6_ANALYTICS_URL}/${path}`

    const response = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    })

    return new RestResponseFactory().process({ response })
  }

  static async TotalDailyChat (weeks) {
    try {
      const token = store.getters['UserStore/getToken']
      const companyId = store.getters['CompanyStore/getSelectedCompany'].uuid
      const appId = store.getters['AppStore/getSelectedApp'].uuid
      const response = await axios.get(
        `${process.env.VUE_APP_V6_ANALYTICS_URL}/company/${companyId}/app/${appId}/conversations/total_daily_chats?from_date=${weeks.periodStart}&to_date=${weeks.periodEnd}`,
        {
          headers: {
            Authorization: 'Bearer ' + token
          }
        }
      )
      return new RestResponseFactory().process({ response })
    } catch (e) {
      const errorResponse = new RestResponseFactory().process({ response: { status: 500 } })
      errorResponse.data = e.response.data
      return errorResponse
    }
  }

  static async TotalChat (weeks) {
    try {
      const token = store.getters['UserStore/getToken']
      const companyId = store.getters['CompanyStore/getSelectedCompany'].uuid
      const appId = store.getters['AppStore/getSelectedApp'].uuid
      const response = await axios.get(
        `${process.env.VUE_APP_V6_ANALYTICS_URL}/company/${companyId}/app/${appId}/conversations/total_conversations?from_date=${weeks.periodStart}&to_date=${weeks.periodEnd}`,
        {
          headers: {
            Authorization: 'Bearer ' + token
          }
        }
      )
      return new RestResponseFactory().process({ response })
    } catch (e) {
      const errorResponse = new RestResponseFactory().process({ response: { status: 500 } })
      errorResponse.data = e.response.data
      return errorResponse
    }
  }

  static async TotalChatEnd (weeks) {
    try {
      const token = store.getters['UserStore/getToken']
      const companyId = store.getters['CompanyStore/getSelectedCompany'].uuid
      const appId = store.getters['AppStore/getSelectedApp'].uuid
      const response = await axios.get(
        `${process.env.VUE_APP_V6_ANALYTICS_URL}/company/${companyId}/app/${appId}/conversations/total_conversations_ended?from_date=${weeks.periodStart}&to_date=${weeks.periodEnd}`,
        {
          headers: {
            Authorization: 'Bearer ' + token
          }
        }
      )
      return new RestResponseFactory().process({ response })
    } catch (e) {
      const errorResponse = new RestResponseFactory().process({ response: { status: 500 } })
      errorResponse.data = e.response.data
      return errorResponse
    }
  }

  static async TotalChatEndIA (weeks) {
    try {
      const token = store.getters['UserStore/getToken']
      const companyId = store.getters['CompanyStore/getSelectedCompany'].uuid
      const appId = store.getters['AppStore/getSelectedApp'].uuid
      const response = await axios.get(
        `${process.env.VUE_APP_V6_ANALYTICS_URL}/company/${companyId}/app/${appId}/conversations/total_conversations_ended_by_ia?from_date=${weeks.periodStart}&to_date=${weeks.periodEnd}`,
        {
          headers: {
            Authorization: 'Bearer ' + token
          }
        }
      )
      return new RestResponseFactory().process({ response })
    } catch (e) {
      const errorResponse = new RestResponseFactory().process({ response: { status: 500 } })
      errorResponse.data = e.response.data
      return errorResponse
    }
  }

  static async getGlobalTimes (weeks) {
    try {
      const token = store.getters['UserStore/getToken']
      const companyId = store.getters['CompanyStore/getSelectedCompany'].uuid
      const appId = store.getters['AppStore/getSelectedApp'].uuid
      const response = await axios.get(
        `${process.env.VUE_APP_V6_ANALYTICS_URL}/company/${companyId}/app/${appId}/conversations/global_times?from_date=${weeks.periodStart}&to_date=${weeks.periodEnd}`,
        {
          headers: {
            Authorization: 'Bearer ' + token
          }
        }
      )
      return new RestResponseFactory().process({ response })
    } catch (e) {
      const errorResponse = new RestResponseFactory().process({ response: { status: 500 } })
      errorResponse.data = e.response.data
      return errorResponse
    }
  }

  static async getAverageTimeCard (weeks) {
    try {
      const token = store.getters['UserStore/getToken']
      const companyId = store.getters['CompanyStore/getSelectedCompany'].uuid
      const appId = store.getters['AppStore/getSelectedApp'].uuid
      const response = await axios.get(
        `${process.env.VUE_APP_V6_ANALYTICS_URL}/company/${companyId}/app/${appId}/conversations/historic/range_first_answer_time/v2?from_date=${weeks.periodStart}&to_date=${weeks.periodEnd}`,
        {
          headers: {
            Authorization: 'Bearer ' + token
          }
        }
      )
      return new RestResponseFactory().process({ response })
    } catch (e) {
      const errorResponse = new RestResponseFactory().process({ response: { status: 500 } })
      errorResponse.data = e.response.data
      return errorResponse
    }
  }

  static async getNewConversationPerChannel (weeks) {
    try {
      const token = store.getters['UserStore/getToken']
      const companyId = store.getters['CompanyStore/getSelectedCompany'].uuid
      const appId = store.getters['AppStore/getSelectedApp'].uuid
      const response = await axios.get(
         `${process.env.VUE_APP_V6_ANALYTICS_URL}/company/${companyId}/app/${appId}/conversations/historic/new_prospect/v2?from_date=${weeks.periodStart}&to_date=${weeks.periodEnd}`,
         {
           headers: {
             Authorization: 'Bearer ' + token
           }
         }
      )
      return new RestResponseFactory().process({ response })
    } catch (e) {
      const errorResponse = new RestResponseFactory().process({ response: { status: 500 } })
      errorResponse.data = e.response.data
      return errorResponse
    }
  }
}
