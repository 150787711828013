<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       fill="none"
       viewBox="0 0 11 16"><path :fill="color"
                                 d="M4.313 8.003c-.021-1.849 1.417-3.512 3.352-3.5 1.951.014 3.34 1.65 3.333 3.505-.006 1.9-1.44 3.488-3.347 3.489-1.928.002-3.355-1.653-3.337-3.494h-.001ZM1.652 8.736c-.333 0-.669.03-.998-.008C.225 8.68.002 8.403 0 8.003c-.002-.398.216-.698.648-.724.677-.042 1.359-.04 2.034.001.42.026.665.374.64.759-.027.411-.32.687-.752.707h-.92v-.01h.002ZM3.996 5.02c-.112-.067-.312-.141-.454-.28a36.586 36.586 0 0 1-1.26-1.307c-.44-.475-.28-1.12.311-1.287.276-.077.504.049.69.233.432.432.855.876 1.27 1.327.227.246.322.538.171.877-.127.283-.357.393-.728.436ZM3.986 10.985c.387.035.616.155.744.447.139.314.059.598-.148.826-.429.476-.87.94-1.325 1.387-.305.3-.638.303-.962.058a.724.724 0 0 1-.206-.841c.052-.117.131-.227.218-.32.4-.427.798-.857 1.217-1.264.147-.144.35-.224.462-.293ZM8.368 1.743c0 .313.007.626-.001.94-.012.425-.21.681-.573.766a.664.664 0 0 1-.825-.62c-.028-.415-.014-.834-.017-1.251-.001-.272-.007-.543.003-.815.02-.56.327-.758.7-.763.363-.005.675.192.71.763.02.325.002.654.003.98ZM8.367 14.28c0 .313.007.626-.001.939-.012.416-.21.677-.557.76a.666.666 0 0 1-.84-.62c-.029-.444-.016-.89-.018-1.336 0-.271-.008-.544.01-.813.028-.41.319-.689.697-.69.35-.002.652.265.69.654.031.317.015.639.02.959v.146h-.002.001Z"/></svg>
</template>

<script>
export default {
  name: 'HalfSunIcon',
  props: {
    color: {
      type: String,
      default: '#8898A9'
    }
  }
}
</script>

<style>

</style>
