import { Template, TemplateButton, TemplateButtonTypesEnum, TemplateComponent, TemplateHeader, TemplateHeaderFormatEnum } from './Template'

export default class TemplateService {
  static defaultTemplates: string[] = ['Hola {{1}}.', 'Hola {{1}}', '¡Hola! {{1}}']

  static findDefaultTemplate (templates:Template[]) {
    return templates?.find(template => TemplateService.defaultTemplates.includes(template?.templateMessage))
  }

  static isDefaultTemplate (template: Template) {
    return TemplateService.defaultTemplates.includes(template?.templateMessage)
  }

  static findProviderByUserHost (userHost: string, providers) {
    return providers?.find(provider => provider?.userHost === userHost)
  }

  static getTemplateByMessage (message, templates) {
    return templates?.find(template => template?.templateMessage === message)
  }

  static getTemplateComponents (template) {
    if (!template) return []
    if (!template?.components) return []

    return JSON.parse(template.components)
  }

  static getTemplateComponent (template, component:TemplateComponent) {
    const components = TemplateService.getTemplateComponents(template)
    return components.find(componentObject => componentObject.type === component)
  }

  // header validations
  static isImageHeader (header:TemplateHeader) {
    return header?.format === TemplateHeaderFormatEnum.IMAGE
  }

  static isDocumentHeader (header:TemplateHeader) {
    return header?.format === TemplateHeaderFormatEnum.DOCUMENT
  }

  static isVideoHeader (header:TemplateHeader) {
    return header?.format === TemplateHeaderFormatEnum.VIDEO
  }

  static isTextHeader (header:TemplateHeader) {
    return header?.format === TemplateHeaderFormatEnum.TEXT
  }

  // get params
  static getParams (templateMessage) {
    const params = []
    const regex = /\{\{(.*?)\}\}/g
    let match
    while ((match = regex.exec(templateMessage)) !== null) {
      params.push({
        id: match[1],
        value: '',
        name: `{{${match[1]}}}`
      })
    }
    return params
  }

  // button validations
  static isURLButton (button:TemplateButton) {
    return button?.type === TemplateButtonTypesEnum.URL
  }

  static canEditButton (button:TemplateButton) {
    if (!button) return false
    if (!TemplateService.isURLButton(button)) return false

    const params = TemplateService.getParams(button.url)
    return params.length > 0
  }

  static cleanTemplateMessage (templateMessage) {
    return templateMessage.replace(/\{\{(.*?)\}\}/g, '')
  }

  // template preview
  static getTemplateMessagePreview (template:Template, templateParams: string[]) {
    if (!template) return ''
    if (!template?.templateMessage) return ''

    let templateMessage = template.templateMessage
    templateParams.forEach((param, index) => {
      templateMessage = templateMessage.replace(`{{${index + 1}}}`, param)
    })

    return templateMessage
  }

  static getHeaderPreview (header:TemplateHeader, templateParams: string[]) {
    if (!header) return ''
    if (!header?.text) return ''

    let headerText = header.text
    templateParams.forEach((param, index) => {
      headerText = headerText.replace(`{{${index + 1}}}`, param)
    })

    return headerText
  }

  static buildUrl (button:TemplateButton, param) {
    return button.url.replace('{{1}}', param)
  }
}
