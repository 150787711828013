<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       fill="none"
       viewBox="0 0 11 14">
    <path :fill="color"
          d="M6.093 4.39h.289c1.284 0 2.568.003 3.852-.005.185 0 .237.054.236.235-.004 3.05-.004 6.1 0 9.152 0 .161-.031.228-.216.228-3.386-.005-6.77-.004-10.157-.004-.028 0-.056-.003-.097-.006V0H5.799c.294 0 .295 0 .295.304V4.39h-.001Z"/>
    <path :fill="color"
          d="m7.005.122 3.366 3.355H7.005V.122Z"/>
  </svg>
</template>

<script>
export default {
  name: 'FileFilledIcon',
  props: {
    color: {
      type: String,
      default: '#8898A9'
    }
  }
}
</script>

<style scoped>

</style>
