import MetricsService from '@/modules/metrics/application/services/MetricsService'
import moment from 'moment'
import ApexCharts from 'apexcharts'
import { EventBus } from '@/eventBus'
import { textCamelize } from '@/utils/utils'
import LoadingNext from '@/modules/metrics/infrastructure/ui/components/generic/LoadingNext.vue'
import DateRange from '@/modules/metrics/infrastructure/ui/components/generic/DateRange.vue'
export default {
  name: 'NewProspect',
  props: ['dateString', 'range', 'dataComponent'],
  components: {
    DateRange,
    LoadingNext
  },
  data () {
    return {
      loadingTotalConversation: true,
      loadingNewDeals: true,
      loadingFirstTime: true,
      WaitingTime: [],
      series: [],
      chartMulti: null,
      chartSingle: null,
      msgServiceError: false,
      msgServiceErrorTwo: false,
      resultado: []
    }
  },
  watch: {
    range: {
      handler: function (newValue) {
        if (this.range) {
          this.getNewConversationPerChannel()
        }
      },
      deep: true
    },
    loadingTotalConversation () {
      if (!this.loadingTotalConversation) {
        this.checkLoading()
      }
    },
    loadingNewDeals () {
      if (!this.loadingNewDeals) {
        this.checkLoading()
      }
    },
    loadingFirstTime () {
      if (!this.loadingFirstTime) {
        this.checkLoading()
      }
    }
  },
  computed: {
    closeModal: {
      get () {
        return this.$store.state.MetricsStore.closeModal || false
      },
      set (value) {
        this.$store.commit('MetricsStore/SET_EDITING_OBJECT', {
          key: 'closeModal',
          value
        })
      }
    },
    loading: {
      get () {
        return this.$store.state.MetricsStore.loading || false
      },
      set (value) {
        this.$store.commit('MetricsStore/SET_EDITING_OBJECT', {
          key: 'loading',
          value
        })
      }
    }
  },
  methods: {
    compararElementos (a, b) {
      const nombreA = a.name.toLowerCase()
      const nombreB = b.name.toLowerCase()

      // Comprobar si 'wp' o 'whatsapp' está en el nombre y dar prioridad
      if (nombreA.includes('wp') || nombreA.includes('whatsapp')) {
        return -1
      } else if (nombreB.includes('wp') || nombreB.includes('whatsapp')) {
        return 1
      }

      // Si ninguno de los nombres contiene 'wp' o 'whatsapp', ordenar alfabéticamente
      if (nombreA < nombreB) {
        return -1
      } else if (nombreA > nombreB) {
        return 1
      } else {
        return 0
      }
    },
    checkLoading () {
      if (
        !this.loadingTotalConversation &&
        !this.loadingNewDeals &&
        !this.loadingFirstTime
      ) {
        this.loading = false
      }
    },
    mapKeys (item) {
      if (item === 'lt5MINS') return '<= 5 min'
      if (item === 'lt10MINS') return '> 5 min y <= 10 min'
      if (item === 'lt15MINS') return '> 10 min y <= 15 min'
      if (item === 'lt20MINS') return '> 15 min y <= 20 min'
      if (item === 'lt25MINS') return '> 20 min y <= 25 min'
      if (item === 'lt30MINS') return '> 25 min y <= 30 min'
      if (item === 'lt60MINS') return '> 30 min y <= 60 min'
      if (item === 'gt60MINS') return '> 60 min'
    },
    async getNewConversationPerChannel () {
      try {
        this.loadingNewDeals = true
        let params = {
          periodStart: moment().subtract(1, 'weeks').format('YYYY-MM-DD'),
          periodEnd: moment().format('YYYY-MM-DD')
        }
        if (this.range) {
          const { dateFrom, dateTo } = this.range
          params = {
            periodStart: dateFrom,
            periodEnd: dateTo
          }
        }
        const response = await MetricsService.getNewConversationPerChannel(
          params
        )
        if (response) {
          const { historyPerChannel, channelCount, dailyTotal } =
            response.data.stats
          this.chartNewMultiLine(
            response.data.stats.x_axis,
            historyPerChannel,
            dailyTotal,
            channelCount
          )
          this.loadingNewDeals = false
        } else {
          this.loadingNewDeals = false
          if (response?.data?.error) {
            EventBus.$emit(
              'toast',
              'error',
              this.$t(textCamelize(response.data.error))
            )
          } else {
            EventBus.$emit('toast', 'error', this.$t('unexpectedError'))
          }
        }
      } catch (error) {
        this.loadingNewDeals = false
        this.msgServiceError = true
      }
    },
    totalCalculate (dailyTotal) {
      // Calcular el total
      let total = 0
      for (const elemento of this.resultado) {
        total += elemento.data.reduce((a, b) => a + b, 0) // Sumar todos los valores en 'data'
      }

      // Agregar el elemento total al objeto resultado
      this.resultado.push({
        name: 'Total ' + total,
        data: dailyTotal
      })
    },
    chartNewMultiLine (axis, item, dailyTotal, totalLabel) {
      if (this.chartMulti) {
        this.chartMulti.destroy()
        this.resultado = []
      }
      this.resultado = []

      for (const key in item) {
        if (Object.prototype.hasOwnProperty.call(item, key)) {
          const nombre = key.charAt(0).toUpperCase() + key.slice(1) // Convertir la primera letra en mayúscula
          const datos = item[key]
          const valor = totalLabel[key]
          this.resultado.push({
            name: `${nombre === 'Keybe_wp' ? 'Whatsapp' : nombre} ${valor}`,
            data: datos
          })
        }
      }
      this.resultado.sort(this.compararElementos)
      this.totalCalculate(dailyTotal)
      const options = {
        chart: {
          toolbar: {
            show: false
          },
          height: 250,
          type: 'line'
        },
        dataLabels: {
          enabled: false
        },
        tooltip: {
          enabled: true,
          enabledOnSeries: undefined,
          shared: true,
          followCursor: false,
          intersect: false,
          inverseOrder: false,
          custom: undefined,
          fillSeriesColor: true,
          theme: false,
          style: {
            fontSize: '12px',
            fontFamily: undefined
          },
          y: {
            formatter: undefined,
            title: {
              formatter: (seriesName) => seriesName + ':'
            }
          },
          onDatasetHover: {
            highlightDataSeries: false
          }
        },
        colors: ['#3AF69B', '#247BA0', '#F7685B', '#FFB200', '#a18262', 'gray'],
        series: this.resultado,
        stroke: {
          width: [2, 2, 2, 2, 2, 2],
          curve: 'smooth'
        },
        xaxis: {
          type: 'datetime',
          categories: axis
        }
      }

      this.chartMulti = new ApexCharts(
        document.querySelector('#NewConversationsMultiLineGraph'),
        options
      )
      this.chartMulti.render()
    }
  },
  mounted () {
    this.getNewConversationPerChannel()
  }
}
