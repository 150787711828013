import { environmentBotEnum } from '@/components/configuration/conversations/channels/environmentBotEnum'
import ConversationsConfigService from '@/components/configuration/Services/ConversationsConfigService'
import Dialog from '@/components/shared/components/Dialog.vue'
import DisconnectWarning from './DisconnectWarning/DisconnectWarning.vue'
import { EventBus } from '@/eventBus'
import { mapState } from 'vuex'
import KBText from '@/shared/infrastructure/ui/inputs/text/KBText.vue'
import KBButton from '@/shared/infrastructure/ui/buttons/KBButton.vue'
import KBSelect from '@/shared/infrastructure/ui/inputs/select/KBSelect.vue'
import Colors from '@/utils/Colors'
import { CDPFieldsEnums } from '@/modules/CDP/shared/contacts/domain/enums/CDPFieldsEnums'
export default {
  name: 'WatsonConfigForm.vue',
  components: {
    KBText,
    KBButton,
    KBSelect,
    Dialog
  },
  props: {
    config: {
      required: false
    }
  },
  data () {
    return {
      localConfig: {
        name: '',
        appKey: '',
        url: {
          url: '',
          title: '',
          appKey: ''
        },
        version: '',
        assistantId: ''
      },
      showDisconnectBotWarning: false,
      CDPFieldsEnums
    }
  },
  computed: {
    ...mapState('UserStore', ['user', 'token']),
    watsonFormSelectors () {
      return {
        urls: [
          {
            url: environmentBotEnum.Url1,
            title: this.$t('keybeEnvironment'),
            appKey: 'pj9Red1kz8UY6_ib9w8Qi5Qp43dbHBv1DYKjrXwvSWwt'
          },
          {
            url: environmentBotEnum.UrlNipu,
            title: this.$t('nipuEnvironment'),
            appKey: 'C3vS8K4qqczXupY26L_Kh8vKwvgOIaN7YjA7hpnh4am0'
          },
          {
            url: environmentBotEnum.Url,
            title: this.$t('constructionEnvironment'),
            appKey: 'VVnnBb6vFBtFZsuOZZCpOPXbuXP7YrnmyDlL_gThM_7M'
          },
          {
            url: environmentBotEnum.Url,
            title: this.$t('educationEnvironment'),
            appKey: 'Cj73ADg9JLz6Rr-8F7YNBq3uPYzB-A8XwXw2cFptjlJS'
          },
          {
            url: environmentBotEnum.Url,
            title: this.$t('KB1Environment'),
            appKey: 'Pp1pTnMlVBawitTrfK2_18TZzo8IBTzg0LjgXcux5lzk'
          },
          {
            url: environmentBotEnum.Url,
            title: this.$t('KB12Environment'),
            appKey: '0eXONRHVi_u4vhC-YieYf10cut1IX7ndRxGAxfSLk9dB'
          },
          {
            url: environmentBotEnum.Url,
            title: this.$t('KB13Environment'),
            appKey: 'ISqQc5bbde4bi3W-etrMvdJ9FbTWxBCSL3lXFo6vBhkG'
          },
          {
            url: environmentBotEnum.Url1,
            title: this.$t('leisureEnvironment'),
            appKey: 'ScxmDbxd4C_j8-zw09p3vkky2cdWqJaVZwztI5zapxdS'
          },
          {
            url: environmentBotEnum.Url,
            title: this.$t('retailEnvironment'),
            appKey: '6fEeC644Ophatqjd1p1Do5TyptfKSrjr9IYkiI1OAuuQ'
          },
          {
            url: environmentBotEnum.Url,
            title: this.$t('retailEnvironment1'),
            appKey: '4yVb1EKbt73WUOwOoyyCwQV1s9j2iecxrTjx0BaqiTmK'
          },
          {
            url: environmentBotEnum.Url,
            title: this.$t('retailEnvironment2'),
            appKey: 'n_S_rouFsHlBpi4j_uIPWTOFPU5CMQDhR-6IDWdBJf5Z'
          },
          {
            url: environmentBotEnum.Url,
            title: this.$t('retailEnvironment3'),
            appKey: 'lh0yI-tyrSSTb13B6p6GcY8E3jolOTiWijcrwCrr_29g'
          },
          {
            url: environmentBotEnum.Url,
            title: this.$t('retailEnvironment4'),
            appKey: 'BYHSTGe5ANIM-N1GfTgdUlumC7ywXfbivF3Cvx1_2G8S'
          },
          {
            url: environmentBotEnum.Url,
            title: this.$t('healthEnvironment'),
            appKey: 'i-dkoOn1yQkmNAPMcuc6uO_nG4TxDyHmuEF9EowH42Sj'
          },
          {
            url: environmentBotEnum.Url,
            title: this.$t('healthEnvironment2'),
            appKey: 'xU6Ll_M0L1CzAwWY9JOeZUfyC-vOV6oFr29QIVCAuMpK'
          },
          {
            url: environmentBotEnum.Url,
            title: this.$t('turismEnvironment'),
            appKey: 'EX321MyxMWGwSExXrCy38a-HMj1lqfDPlg72ocJ2Ydqq'
          },
          {
            url: environmentBotEnum.Url,
            title: this.$t('automotiveEnvironment'),
            appKey: '_gYbckDpRtw27gCsAA1qGu-B7kQxWhNSJ9_O7WxWGaFW'
          },
          {
            url: environmentBotEnum.Url2,
            title: this.$t('legalEnvironment'),
            appKey: '38rZwIVdZBpm5v2D3oibiTIftSq3bmSYxOhNCrV288Ih'
          },
          {
            url: environmentBotEnum.Url,
            title: this.$t('coworkingEnvironment'),
            appKey: '9N6FFZhPE1VuVdxk-V96TkvdsWdxr4gD7MPWVMd50F6V'
          },
          {
            url: environmentBotEnum.Url,
            title: this.$t('financialEnvironment'),
            appKey: 'NXEZaHeOoxboEy1Xrl7b4w73kOaTNvIWGN9a90AhKnUM'
          },
          {
            url: environmentBotEnum.Url2,
            title: this.$t('bellpiExternalEnvironment'),
            appKey: 'q19iBDBE0Z_8qhSSOWUo1RySlU8QKrRlAbxjsXe_Dsch'
          },
          {
            url: environmentBotEnum.Url,
            title: this.$t('educationEnvironment1'),
            appKey: 'nPuyKJhlNwM0XQ_NQRwzERVSF2ZW0ONw4Tgp5Xe04KND'
          },
          {
            url: environmentBotEnum.Url,
            title: this.$t('healthEnvironment1'),
            appKey: '25pOPAYR8sfjkxko6DV-ur5wPlnmwaWBQm62ksRMSPGH'
          },
          {
            url: environmentBotEnum.Url,
            title: this.$t('retailEnvironment5'),
            appKey: '89Hx3fSX-_9NW97upulAYwBkZZTjzfUB086aeu0pCDNF'
          },
          {
            url: environmentBotEnum.Url,
            title: this.$t('retailEnvironment6'),
            appKey: 'gklyPTMWiSUtjp8hdJgaAor_ZMo2M-h5Wnxh7xXj-2za'
          },
          {
            url: environmentBotEnum.Url,
            title: this.$t('retailEnvironment7'),
            appKey: '01WL7wUprPjLLqp4nrjTzZDhn_LyBIy6GeXgQst7Lrbv'
          },
          {
            url: environmentBotEnum.Url,
            title: this.$t('automotiveEnvironment1'),
            appKey: 'DCmh2mV3hvyAIPWhGSR5WEMgQkbST6YEIzbR4bLdJ1_p'
          },
          {
            url: environmentBotEnum.Url,
            title: this.$t('constructionAndRealEstateEnvironment1'),
            appKey: 'ggnqzEw1Wx5EDt6xlV-G4e4ZtlOUvUqizG5lQbhZEMWo'
          },
          {
            url: environmentBotEnum.Url,
            title: this.$t('leisureAndEntertaimentEnvironment1'),
            appKey: 'h5O5EgG_Yl6sYFj7wQfMBiTLTQ4pZfpz_8M5Nzz8E3HO'
          },
          {
            url: environmentBotEnum.Url,
            title: this.$t('arbustaEnvironment'),
            appKey: 'Z_yLpkgrcMYf1bFuk_oJWAZ8id929vq9dZ_wxYmT5XfC'
          },
          {
            url: `${environmentBotEnum.Url}/instances/6eb1fdf0-abe3-4662-8c15-3fe1cd3e682`,
            title: this.$t('rocioBertoneEnvironment'),
            appKey: 'GN3sNqx2vurTvpeBX3EtAhzijKa5rSe4vCEtmpSKKU0K'
          },
          {
            url: `${environmentBotEnum.Url}/instances/d534fc24-6b35-4e79-a3a9-54df7d14c941`,
            title: this.$t('retailEnvironment8'),
            appKey: 'UDXP3xvvxAX0rG3-YA5ql7kqOZa9Ro9aEgk7HKzQ5UGn'
          },
          {
            url: `${environmentBotEnum.Url}/instances/fbfa7b0a-fbb4-43c0-9a4b-4d6bc0b30a8e`,
            title: this.$t('retailEnvironment9'),
            appKey: 'XguRHaoqitpdN-M_qGfATHP25sSoxFfo6ohzCz-CKbN4'
          },
          {
            url: `${environmentBotEnum.Url}/instances/e82a12c7-6c4b-4825-94fd-4af9f06c8a0c`,
            title: this.$t('starSolucionesEnvironment'),
            appKey: '4XEb8v_Kp9mQ85cUmina-EVwglu0-jruZVNYxT_PqNg3'
          },
          {
            url: `${environmentBotEnum.Sico}/instances/1e77096f-4d0b-4048-a210-4a6ad694a9e4`,
            title: this.$t('sicoEnvironment'),
            appKey: 'mFZgL0GktXy4PG-e_7lLXJGYne1Fa1ErfZV1RbTTynDD'
          },
          {
            url: `${environmentBotEnum.Sico}/instances/1e77096f-4d0b-4048-a210-4a6ad694a9e4`,
            title: this.$t('sico1Environment'),
            appKey: '05HX4lDCXurbGL0r0fR_q6ZQSU4xokbiggPcglbDIln0'
          },
          {
            url: `${environmentBotEnum.Url}/instances/9ac1a168-e7ec-4264-ab6f-b639e929da66`,
            title: this.$t('B2Wenvironment'),
            appKey: '9tpumec1j3DWEgpvKWHpl4JGmOiHy9gNLHJE-aWZufV7'
          },
          {
            url: `${environmentBotEnum.Url}/instances/627cba15-e090-4a57-8b05-d15387aefd3e`,
            title: this.$t('ivisaEnvironment'),
            appKey: 'SP7nIT2FfWwcmey8QESdouK0ZCcpk-qPhDoqxBydyGoC'
          },
          {
            url: `${environmentBotEnum.Url}/instances/32cf7a77-d517-493e-b8d4-500b9f4f9029`,
            title: this.$t('RepuestosDarioEnvironment'),
            appKey: 'BjtHa2NN-XyBBRbqxFIzI4yszrBxFKEgaZlLQMF-z93t'
          },
          {
            url: `${environmentBotEnum.KBMex}/instances/b1375813-cb71-4ab5-88c7-0aaf8a4ac6b2`,
            title: this.$t('kbmexEnvironment'),
            appKey: 'NiVayo9F9Y0RmtryGYMsz4HEDoQaYRN4L7BwTQ8ELAZh'
          },
          {
            url: `${environmentBotEnum.Url}/instances/7188b634-559c-49c7-9c8b-32985563862c`,
            title: this.$t('lagilcoEnvironment'),
            appKey: '0y0sL1wFHyI5j7_8m43TF89npWo0pyXwq5Wwqq32bhF9'
          },
          {
            url: `${environmentBotEnum.Url}/instances/6ff4a6f3-157e-49d6-864d-28fa9ab221ab`,
            title: this.$t('bmartEnvironment'),
            appKey: 'O-E4HISv44lQfrcnyZl-IR9WoxHwFdxDqYNVTs8e4kwk'
          }
        ],
        version: ['2020-04-01']
      }
    },
    isSuperAdmin () {
      return this.user.isSuperAdmin
    },
    DeleteSpaceWarningDialog () {
      return DisconnectWarning
    },
    dialogBackground () {
      return Colors.rojoBird
    }
  },
  methods: {
    checkDeleteBot () {
      // this.handleDisconnect()
      this.showDisconnectBotWarning = true
    },
    cancelDeletion () {
      this.showDisconnectBotWarning = false
    },
    async handleSaveWatson () {
      const newConfig = {
        type: 'KEYBE_WATSON',
        name: this.localConfig.name.trim(),
        config: {
          appKey: this.localConfig.appKey.trim(),
          assistantId: this.localConfig.assistantId.trim(),
          url: this.localConfig.url.url.trim(),
          version: this.localConfig.version.trim()
        }
      }
      const result =
        await ConversationsConfigService.updateConversationsConfigV5({
          botConfig: newConfig
        })
      result?.status
        ? EventBus.$emit('toast', 'success', result.data.message)
        : EventBus.$emit('toast', 'error', result.data.message)
      this.$emit('refresh')
    },
    async handleContextRestoring () {
      const response = await ConversationsConfigService.restoreWatsonContext()
      if (!response.status) {
        EventBus.$emit('toast', 'error', response.message)
        return
      }
      EventBus.$emit('toast', 'success', this.$t('contextsRestored'))
    },
    async handleDisconnect () {
      this.showDisconnectBotWarning = false
      const newConfig = {
        type: '',
        name: '',
        config: {
          appKey: '',
          assistantId: '',
          url: '',
          version: ''
        }
      }
      const response = await ConversationsConfigService.updateConversationsConfigV5({
        botConfig: newConfig
      })
      response?.status
        ? EventBus.$emit('toast', 'success', this.$t('disconnectedBot'))
        : EventBus.$emit('toast', 'error', response.data.message)
      this.$emit('refresh')
    }
  },
  watch: {
    localConfig: {
      handler (value) {
        this.localConfig.appKey = value.url.appKey
      },
      deep: true
    }
  },
  async mounted () {
    for (const key of Object.keys(this.localConfig)) {
      if (key === 'url') {
        continue
      }
      if (key === 'appKey') {
        const objFinded = this.watsonFormSelectors.urls.find(
          (e) => e.appKey === this.config[key]
        )
        this.localConfig.url = objFinded || ''
      } else {
        this.localConfig[key] = this.config[key]
      }
    }
    this.localConfig.version = this.watsonFormSelectors.version[0]
    EventBus.$on('deleteBot--configuration', this.handleDisconnect.bind(this))
  },

  beforeDestroy () {
    EventBus.$off('deleteBot--configuration', this.handleDisconnect.bind(this))
  }
}
