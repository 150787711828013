import { Task } from '@/modules/tasks/domain/Task'
import TaskStatus from '@/modules/tasks/infrastructure/ui/components/TaskStatus/TaskStatus.vue'

export default {
  name: 'TaskCardContent',
  props: {
    task: {
      required: true
    },
    isCardExpanded: {
      required: true,
      default: false,
      type: Boolean
    }
  },
  data () {
    return {
      maxLengthDescription: 20
    }
  },
  components: {
    TaskStatus
  },
  computed: {
    taskCardContentClasses (): string {
      const classes = ['TaskCardContent']
      if (this.isCardExpanded) {
        classes.push('TaskCardContent__expanded')
      }
      return classes.join(' ')
    },
    taskComputed (): Task {
      return this.task
    },
    description (): string {
      const textDescription: string = this.task?.description || ''
      if (
        textDescription.length > this.maxLengthDescription &&
        !this.isCardExpanded
      ) {
        return `${textDescription.substring(0, this.maxLengthDescription)}...`
      }
      return textDescription || ''
    }
  }
}
