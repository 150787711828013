import store from '@/store'
import {
  IAppsController,
  dataToGetExtendedFields,
  extendedFieldGetter,
  dataToGetAppFields,
  dataToHideAppField,
  appFieldCreator,
  dataToGetApps,
  dataToSelectApp,
  appGetter,
  companyGetter,
  dataToUpdateApp,
  appUpdater,
  dataToSetDomain,
  dataToDeleteDomain,
  dataToCreateAppByCompany,
  appCreatorCompany,
  categoriesGetter,
  dataToVerifiedDomain,
  dataToCheckDomain,
  dataToCheckUserOwner,
  dataToGetApp
} from '../../domain/AppsController'
import AppsRepository from '../repository/Apps.repository'
import { App } from '../../domain/Apps'
import AppUUIDsService from '@/services/AppUUIDsService'
import { LoggedUser, UserApp } from '@/modules/Config/GeneralUserConfiguration/domain/LoggedUser'
import { Languages } from '@/components/shared/enums/Languages'

export default class AppsController implements IAppsController {
  repository: AppsRepository

  constructor () {
    this.repository = new AppsRepository()
  }

  abort (): void {
    this.repository.abortGetSuggestions()
  }

  async getExtendedFields (data: dataToGetExtendedFields) : Promise<extendedFieldGetter> {
    const response = await this.repository.getExtendedFields(data)
    return response
  }

  async getAppFields (data: dataToGetAppFields) : Promise<any> {
    const response = await this.repository.getAppFields(data)
    return response
  }

  async hideField (data: dataToHideAppField) : Promise<appFieldCreator> {
    const response = await this.repository.hideField(data)
    return response
  }

  async getCDPFields (data: dataToGetAppFields) : Promise<any> {
    const response = await this.repository.getCDPFields(data)
    return response
  }

  async getApps (data: dataToGetApps) : Promise<any> {
    const { token } = data
    const response = await this.repository.getApps(data)
    if (response?.status) {
      const user: LoggedUser = store.getters['UserStore/getUser']
      const apps: App[] = response?.data
      let activeApps = apps
      const inactiveApps = user.userApps.filter((userApp: UserApp) => userApp.inactive).map((userApp: UserApp) => userApp.appUUID)
      activeApps = apps.filter((app: App) => { return !inactiveApps.includes(app.uuid) })
      store.commit('AppStore/SET_APPS', activeApps)

      // @ts-ignore
      const selectedApp = store.state.AppStore.selectedApp || null
      if (!selectedApp) {
        await this.selectFirstActiveApp(activeApps, token)
      }

      return activeApps
    } else return response
  }

  async selectFirstActiveApp (apps: App[], token: string) : Promise<void> {
    const firstActiveApp: App = apps.find((app: App) => !app.inactive)
    if (firstActiveApp) {
      const data: dataToSelectApp = {
        name: firstActiveApp?.name,
        uuid: firstActiveApp?.uuid,
        token: token
      }
      await this.selectApp(data)
    }
  }

  async getApp (data: dataToSelectApp) : Promise<appGetter> {
    const response = await this.repository.getApp(data)
    return response
  }

  async setDomain (data: dataToSetDomain) : Promise<appUpdater> {
    const response = await this.repository.setDomain(data)
    return response
  }

  async deleteDomain (data: dataToDeleteDomain) : Promise<appGetter> {
    const response = await this.repository.deleteDomain(data)
    return response
  }

  async verifiedDomain (data: dataToVerifiedDomain) : Promise<appGetter> {
    const response = await this.repository.verifiedDomain(data)
    return response
  }

  async checkDomain (data: dataToCheckDomain) : Promise<appGetter> {
    const response = await this.repository.checkDomain(data)
    return response
  }

  async getCompany (data: dataToSelectApp) : Promise<companyGetter> {
    const response = await this.repository.getCompany(data)
    // @ts-ignore
    if (!response.status) return { status: true, data: { uuid: 'mock' } }
    return response
  }

  async selectApp (data: dataToSelectApp) : Promise<any> {
    const app = await this.getApp(data)
    if (app?.data) {
      store.commit('AppStore/SET_SELECTED_APP', app?.data)
      // @ts-ignore
      store.commit('CompanyStore/SET_SELECTED_COMPANY', app?.data?.company)
      const userApps = store.getters['UserStore/getUserApps'] || []
      // @ts-ignore
      const userApp = userApps.find((a) => a?.appUUID === app?.data?.uuid)
      if (userApp) {
        store.dispatch('UserStore/setSelectedUserApp', userApp)
      }
      await store.dispatch('ConversationStore/setTemplates', [], { root: true })
    } else {
      return app
    }
  }

  async updateAppInfo (data: dataToUpdateApp) : Promise<appUpdater> {
    const response = await this.repository.updateAppInfo(data)
    // @ts-ignore
    if (!response._id) return response
    else {
      const app = response
      if (!data.app?.inactive) store.commit('AppStore/SET_SELECTED_APP', app)
    }
  }

  async createAppByCompany (data: dataToCreateAppByCompany) : Promise<appCreatorCompany> {
    const response = await this.repository.createAppByCompany(data)
    if (response.status) return { status: true, data: response.data }
    else return { status: false, message: response.message }
  }

  async getCategories () : Promise<categoriesGetter> {
    const response = await this.repository.getCategories()
    if (response.status) return { data: response.data, status: true }
    return { message: response.message, status: false }
  }

  async canVerifyProfile (app: string) : Promise<boolean> {
    return app === AppUUIDsService.appKeybeAi() || app === AppUUIDsService.appAlqueria()
  }

  isUserOwner (data: dataToCheckUserOwner) : boolean {
    const userApp = data?.user?.userApps?.find((userApp: UserApp) => userApp.appUUID === data.appUUID) || { isOwner: 0 }
    return userApp?.isOwner === 1
  }

  isUserReadonly (data: dataToCheckUserOwner) : boolean {
    const userApp = data?.user?.userApps?.find((userApp: UserApp) => userApp.appUUID === data.appUUID) || { readonly: 0 }
    const userReadonly: boolean = userApp?.readonly === 1
    store.commit('UserStore/SET_USER_READ_ONLY', userReadonly)
    return userReadonly
  }

  async appHasFlows1 (data: dataToGetApp) : Promise<boolean> {
    const response = await this.repository.appHasFlows1(data)
    if (response.status && response?.data?.client?._id) return true
    else return false
  }

  getServiceAgreementUrl (language: string) : string {
    const labelMap: {[key: string]: string} = {
      [Languages.ES]: `${process.env.VUE_APP_KEYBE_CO_URL}/legales/#acuerdo-de-servicio`,
      [Languages.EN]: `${process.env.VUE_APP_KEYBE_US_URL}/legal/#service-agreement`
    }
    return labelMap[language] || `${process.env.VUE_APP_KEYBE_US_URL}/legales/#service-agreement`
  }
}
