import axios from 'axios'
import store from '@/store/index'
const cancelToken = axios.CancelToken

export default class ConfigurationService {
  static source = cancelToken.source()

  static async getBrands (search, pagination) {
    try {
      const url = `${process.env.VUE_APP_AUTH_SERVICE}apps/list`
      const token = store.getters['UserStore/getToken']
      const response = await axios.get(url,
        {
          headers: {
            Authorization: 'Bearer ' + token
          },
          params: {
            page: pagination.page,
            limit: pagination.limit,
            search
          }
        }
      )
      return response.data.data
    } catch (e) {
      console.error(e)
    }
  }

  static async updateBrand (data) {
    try {
      const url = `${process.env.VUE_APP_AUTH_SERVICE}administrator/apps`
      const token = store.getters['UserStore/getToken']
      const response = await axios.put(url,
        data,
        {
          headers: {
            Authorization: 'Bearer ' + token
          }
        }
      )
      return response
    } catch (e) {
      console.error(e)
    }
  }

  static async deleteAdministrator (userId) {
    try {
      const companyId = store.getters['CompanyStore/getSelectedCompany'].uuid
      const token = store.getters['UserStore/getV4Token']
      const url = `${process.env.VUE_APP_BASE_URL}/administrator/companies/${companyId}/userId/${userId}`
      const response = await axios.delete(url,
        {
          headers: {
            Authorization: 'Bearer ' + token
          }
        }
      )
      return response
    } catch (e) {
      console.error(e)
    }
  }
}
