












export default {
  name: 'CortexDarkIcon'
}
