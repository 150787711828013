<template>
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><path :fill="color" d="M0 11.905C-.007 5.415 5.214-.015 11.92 0c6.744.015 11.914 5.41 11.9 11.926-.013 6.575-5.243 11.882-11.905 11.89C5.205 23.827-.01 18.394 0 11.906Zm1.46-.008c.056 5.709 4.474 10.427 10.43 10.44 5.844.016 10.456-4.558 10.453-10.435-.004-5.865-4.588-10.404-10.401-10.427-5.823-.02-10.4 4.554-10.482 10.422Z"/><path :fill="color" d="M11.137 6.667c0-.297-.004-.637 0-.978.007-.497.3-.839.72-.851.457-.014.768.323.783.853.005.208.016.416-.003.622-.022.253.058.345.327.396.615.118 1.23.269 1.823.467.368.121.51.478.422.795-.104.37-.392.573-.806.515-.304-.044-.6-.155-.902-.22-.933-.197-1.87-.265-2.778.103-.282.114-.55.292-.78.493-.407.357-.364 1.125.124 1.37.678.34 1.39.656 2.124.839.916.229 1.77.573 2.571 1.048a2.4 2.4 0 0 1 1.159 1.654c.26 1.335-.482 2.591-1.815 3.039-.457.153-.94.233-1.465.362 0 .302.007.638-.001.975-.013.465-.177.694-.549.794-.43.117-.831-.131-.88-.573-.034-.297-.02-.599-.026-.9-.002-.142 0-.285 0-.402-.82-.239-1.606-.459-2.385-.699-.557-.171-.767-.75-.446-1.172.167-.218.394-.318.655-.253.49.122.98.252 1.46.414.852.285 1.72.404 2.598.215.353-.076.717-.23 1.006-.444a1.071 1.071 0 0 0-.133-1.817 8.83 8.83 0 0 0-1.364-.59c-.74-.255-1.513-.43-2.24-.714-.494-.193-1.008-.441-1.388-.795-1.233-1.148-.872-3.15.677-3.927.47-.235.971-.4 1.514-.62l-.002.001Z"/></svg>
</template>

<script>
export default {
  name: 'SalesIcon',
  props: {
    color: {
      type: String,
      default: '#B6BEC7'
    }
  }
}
</script>

<style>

</style>
