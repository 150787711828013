<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       fill="none"
       viewBox="0 0 312 14"><path stroke="#B6BEC7"
                                  stroke-linecap="round"
                                  d="M1 1v12M311 1v12M9 1v6M18 1v6M27 1v6M36 1v6M45 1v6M54 1v6M62 1v6M71 1v6M80 1v6M89 1v6M98 1v6M107 1v6M116 1v6M125 1v6M134 1v6M143 1v6M151 1v6M160 1v6M169 1v6M178 1v6M187 1v6M195 1v6M204 1v6M213 1v6M222 1v6M231 1v6M239 1v6M248 1v6M257 1v6M266 1v6M275 1v6M284 1v6M293 1v6M302 1v6"
                                  opacity=".2"/></svg>
</template>

<script>
export default {
  name: 'SliderRuleIcon'
}
</script>

<style>

</style>
