<template>
  <div class="KBPhone"
       :class="{ 'KBPhone--open': countryPicker }"
       :key="rerender">
    <div class="KBPhone__countries newBackground inverted"
         v-if="invert"
         :class="{ 'KBPhone__countries--open': countryPicker }">
      <div class="search userDetails">
        <input type="text"
               v-model="search"
               :placeholder="$t('search')"/>
      </div>
      <div class="list newBackground">
        <div v-for="(country, index) in countriesComputed"
             :key="index"
             class="country"
             @click="select(country)">
          <img class="iti-flag"
               :class="country.code.toLowerCase()"/>
          <span>(+{{ country.number }}) {{ country.country }}</span>
        </div>
      </div>
    </div>
    <div class="KBPhone__container"
         :key="`${rerender}-input`"
         :class="{ 'KBPhone__container--incorrect': !valid,
                   'KBPhone__container--outlined': outlined,
                   'KBPhone__container--outlined-incorrect': outlined && !valid,
                   'KBPhone__container--seamless': seamless,
                   'KBPhone__container--seamless-incorrect': seamless && !valid,
                   'KBPhone__container--focused': focused }">
      <img class="iti-flag"
           :class="`${outlined? '-ml-4 mr-3' : ''} ${phoneNumber.code.toLowerCase()}`"
           @click="countryPicker = !countryPicker"/>
      <span class="cursor-default">(+{{ phoneNumber.number }})</span>
      <input @paste="paste($event)"
             type="number"
             ref="phoneInput"
             v-model="phoneNumber.phone"
             :placeholder="placeholder"
             :disabled="disabled"
             @input="input"
             :autofocus="autofocus"
             @keyup.enter="save"
             @focus="focused = true"
             @blur="focused = false"/>
    </div>
    <div class="KBPhone__countries newBackground"
         v-if="!invert"
         :class="{ 'KBPhone__countries--open': countryPicker }">
      <div class="search userDetails">
        <input type="text"
               v-model="search"
               :placeholder="$t('search')"/>
      </div>
      <div class="list newBackground">
        <div v-for="(country, index) in countriesComputed"
             :key="index"
             class="country"
             @click="select(country)">
          <img class="iti-flag"
               :class="country.code"/>
          <span>(+{{ country.number }}) {{ country.country }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { SpecialCountries } from '@/components/conversations/enums/SpecialCountries'
import { isValidPhoneNumber, parsePhoneNumber } from 'libphonenumber-js'
import countries from '@/utils/countries'
import timezoneWithCountry from '@/utils/timezoneWithCountry'
import '@/assets/css/countries.scss'
import ContactsUseCases from '@/modules/CDP/shared/contacts/application/ContactsUseCases'
import ContactsController from '@/modules/CDP/shared/contacts/infrastructure/controllers/Contacts.controller'
import { CDPFieldsEnums } from '@/modules/CDP/shared/contacts/domain/enums/CDPFieldsEnums'
export default {
  props: {
    value: {
      type: [String, Object]
    },
    placeholder: {
      type: String
    },
    outlined: {
      type: Boolean,
      default: false
    },
    seamless: {
      type: Boolean,
      default: false
    },
    invert: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    isExtra: {
      type: Boolean,
      default: false
    },
    autofocus: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState('AppStore', ['selectedApp']),
    countriesComputed () {
      return countries.filter(c => c.country.toLowerCase().includes(this.search.toLowerCase()))
    },
    valid () {
      return isValidPhoneNumber('+' + this.phoneNumber.number + this.phoneNumber.phone)
    }
  },
  mounted () {
    if (this.autofocus) this.$refs.phoneInput.focus()
    this.setDefaultCountry()
    setTimeout(() => {
      if (this.value && Object.keys(this.value).length > 0 && this.value?.phone) this.phoneNumber = this.value
      else if (this.value && this.value.length > 5) this.setPhone()
    }, 300)
  },
  data () {
    return {
      countries,
      search: '',
      phoneNumber: {
        code: '',
        number: '',
        phone: '',
        countryName: '',
        valid: false
      },
      countryPicker: false,
      rerender: 0,
      focused: false,
      inputText: '',
      parsed: false,
      contactsController: new ContactsController()
    }
  },
  methods: {
    async setDefaultCountry () {
      const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone
      if (this.selectedApp?.defaultCountryCode) {
        const country = countries.find(country => country.number === this.selectedApp?.defaultCountryCode)
        if (country) {
          this.phoneNumber.code = country.code.toUpperCase()
          this.phoneNumber.number = country.number
          this.phoneNumber.countryName = country.country
        }
      } else if (currentTimezone) {
        for (const [key, value] of Object.entries(timezoneWithCountry)) {
          let zone = ''
          if (value.zones.length > 0) zone = value.zones.filter(zone => zone === currentTimezone)[0]
          if (zone) {
            const countryCode = key.toLowerCase()
            const countryInfo = countries.filter(c => c.code.toLowerCase() === countryCode)[0]
            if (countryInfo) {
              this.phoneNumber.code = countryInfo.code.toUpperCase()
              this.phoneNumber.number = countryInfo.number
              this.phoneNumber.countryName = countryInfo.country
              return
            }
          }
        }
        this.phoneNumber.valid = await this.checkValid()
      } else {
        this.phoneNumber = {
          code: 'US',
          number: '1',
          phone: '',
          countryName: 'United States'
        }
      }
    },
    select (country) {
      this.phoneNumber.code = country.code
      this.phoneNumber.number = country.number
      this.phoneNumber.countryName = country.country
      this.countryPicker = false
      this.save()
      this.input()
    },
    async input (e) {
      if (!this.phoneNumber.phone) {
        this.inputText = ''
        this.parsed = false
      }
      this.countryPicker = false
      this.inputText = this.inputText.concat(e?.data?.toString())
      if (this.inputText.length > 8 && !this.parsed && this.inputText.includes('+')) {
        this.parse(this.inputText)
      }
      this.phoneNumber.valid = await this.checkValid()
      this.$emit('valid', this.phoneNumber.valid)
      this.$emit('input', this.phoneNumber)
    },
    async save () {
      this.validateSpecial()
      this.phoneNumber.valid = await this.checkValid()
      this.$emit('save', this.phoneNumber)
    },
    async checkValid () {
      if (this.phoneNumber?.phone?.length > 6) {
        const data = {
          phone: this.phoneNumber?.number + this.phoneNumber?.phone
        }
        const response = await ContactsUseCases.validatePhone(this.contactsController, data)
        return response?.status
      } else return false
    },
    async setPhone () {
      if (this.value && this.value !== '+' && this.value.phone) {
        if (this.value.phone.includes('+') && isValidPhoneNumber(this.value.phone)) this.parse(this.value.phone)
        else if (isValidPhoneNumber('+' + this.value.phone)) {
          const countryName = countries.filter(c => c.code.toLowerCase() === this.value?.code?.toLowerCase())[0]?.country || ''
          this.phoneNumber = {
            code: this.value.code.toUpperCase(),
            number: this.value.number,
            phone: this.value.phone,
            countryName: countryName
          }
        }
        this.phoneNumber.valid = await this.checkValid()
      } else if (this.value && this.value !== '+' && isValidPhoneNumber(this.value)) {
        const value = this.value.replaceAll(' ', '')
        this.parse(value)
        this.phoneNumber.valid = await this.checkValid()
        this.$emit('input', this.phoneNumber)
      } else if (this.value && this.value !== '+' && !isValidPhoneNumber(this.value)) {
        if (this.value.includes('+') && this.value.substring(0, 3) === '+57') {
          const newValue = this.value.substring(0, 3) + '60' + this.value.substring(3, this.value.length)
          this.parse(newValue, true)
        }
      }
    },
    parse (value, removeCode) {
      let phone = ''
      if (value.includes('+')) phone = parsePhoneNumber(value) ? parsePhoneNumber(value) : ''
      else {
        phone = parsePhoneNumber('+' + this.phoneNumber.number + value)
      }
      if (phone) {
        const countryName = countries.filter(c => c.code.toLowerCase() === phone?.country?.toLowerCase())[0]?.country || ''
        const firstCharacters = phone?.nationalNumber.slice(0, phone?.countryCallingCode.length)
        if (firstCharacters === phone?.countryCallingCode) phone.nationalNumber = phone?.nationalNumber.slice(phone?.countryCallingCode?.length)
        if (removeCode) {
          const newNumber = phone?.nationalNumber.substring(2, phone.nationalNumber.length)
          this.phoneNumber = {
            code: phone?.country !== undefined ? phone?.country?.toUpperCase() : 'US',
            number: phone?.countryCallingCode,
            phone: newNumber,
            countryName: countryName
          }
        } else {
          this.phoneNumber = {
            code: phone?.country !== undefined ? phone?.country?.toUpperCase() : 'US',
            number: phone?.countryCallingCode,
            phone: phone?.nationalNumber,
            countryName: countryName
          }
        }
        this.parsed = true
      }
      this.rerender++
      setTimeout(() => {
        if (this.$refs.phoneInput) this.$refs.phoneInput.focus()
      }, 300)
    },
    paste (e) {
      e.preventDefault()
      const phone = (e.clipboardData).getData(CDPFieldsEnums.TEXT).replaceAll(' ', '').replaceAll('-', '').replaceAll('(', '').replaceAll(')', '')
      this.parse(phone)
      this.input()
    },
    validateSpecial () {
      const specialCountry = Object.values(SpecialCountries)
      const formattedNumber = '+' + this.phoneNumber.number + this.phoneNumber.phone
      if (this.phoneNumber.countryName && specialCountry.includes(this.phoneNumber.countryName.toLowerCase()) && formattedNumber.length === 10) {
        if (this.phoneNumber.number === '54' || this.phoneNumber.number === '52') {
          if (this.phoneNumber.number === '54' && formattedNumber.substr(0, 1) !== '9') this.phoneNumber.phone = '9' + this.phoneNumber.phone
          if (this.phoneNumber.number === '52' && formattedNumber.substr(0, 1) !== '1') this.phoneNumber.phone = '1' + this.phoneNumber.phone
        }
      }
    },
    close () {
      this.countryPicker = false
    }
  },
  watch: {
    value () {
      if (this.value && this.value.length > 5) this.setPhone()
    }
  }
}
</script>
<style scoped lang="scss">
.KBPhone {
  &__container {
    background: rgba(182, 190, 199, 0.2);
    height: 53px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 30px;
    caret-color: #01CFE4;
    transition: background 0.3s;
    position: relative;

    img {
      margin-right: 10px;
      min-width: 20px;
      cursor: pointer;
    }

    span {
      margin-right: 10px;
      color: #a2aab3;
    }

    input {
      height: 31px;
      width: 100%;
      outline: none;
      font-size: 0.875rem;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input[type=number] {
      -moz-appearance: textfield;
    }
  }

  &__container--seamless {
    background: rgba(182, 190, 199, 0.18);
    transition: background 0.4s;

    &:hover {
      background: rgba(182, 190, 199, 0.2);
    }
  }

  &__container--seamless-incorrect {
    background: rgb(255 132 132 / 10%) !important;
    transition: background 0.4s;

    &:hover {
      background: rgb(255 132 132 / 20%) !important;
    }
  }

  &__container--focused {
    background: rgba(182, 190, 199, 0.2);
  }

  &__container--incorrect {
    background: rgb(255 132 132 / 20%) !important;
  }

  &__container--outlined {
    width: 100%;
    height: 40px;
    border: 1px solid $green-4 !important;
    border-radius: 5px;
    background: transparent;
  }

  &__container--outlined-incorrect {
    width: 100%;
    height: 40px;
    border: 1px solid $rojo-bird !important;
    border-radius: 5px;
    background: transparent;
  }

  &__countries--open {
    overflow: scroll;
    max-height: 212px !important;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  }
  &--open {
    position: relative
  }

  &__countries {
    background: white;
    width: inherit;
    overflow-x: hidden;
    max-height: 0;
    transition: max-height 0.4s ease;
    position: absolute;
    z-index: 1500;

    .list {
      max-height: 156px;
      overflow: scroll;
      overflow-x: hidden;
    }

    .search {
      height: 53px;

      input {
        outline: none;
        height: 100%;
        padding: 5px 30px;
        font-size: 0.875rem;
      }
    }

    .country {
      height: 53px;
      display: flex;
      align-items: center;
      padding-left: 30px;
      cursor: pointer;

      img {
        min-width: 20px;
      }

      span {
        font-size: 0.875rem;
        margin-left: 10px;
        max-width: 300px;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    .country:hover {
      background: rgba(182, 190, 199, 0.15);
    }
  }
}
.inverted {
  top: -186px !important;
}
</style>
