











import FacebookProvider from '@/components/configuration/Providers/FacebookProvider'
import ConversationsConfigController from '@/components/configuration/controllers/ConversationsConfigController'
import { mapState } from 'vuex'
import OnboardingWhatsapp from '@/modules/onboarding/infrastructure/ui/components/channels/OnboardingWhatsapp.vue'
import OnboardingInstagram from '@/modules/onboarding/infrastructure/ui/components/channels/OnboardingInstagram.vue'
import OnboardingFacebook from '@/modules/onboarding/infrastructure/ui/components/channels/OnboardingFacebook.vue'
import OnboardingWeb from '@/modules/onboarding/infrastructure/ui/components/channels/OnboardingWeb.vue'

export default {
  name: 'OnboardingChannels',
  components: {
    OnboardingWhatsapp,
    OnboardingInstagram,
    OnboardingFacebook,
    OnboardingWeb
  },
  data () {
    return {
      facebookConfig: [],
      instagramConfig: []
    }
  },
  created () {
    FacebookProvider.setBusySDK(false)
    this.getChannels(true)
  },
  computed: {
    ...mapState('CompanyStore', ['selectedCompany']),
    ...mapState('ConversationStore', ['channels'])
  },
  methods: {
    async getChannels (force = false) {
      this.$emit('start-loading')
      if (!this.channels || Object.keys(this.channels).length === 0 || force) {
        await ConversationsConfigController.getChannels()
        if (this.channels?.facebookMessenger) {
          this.facebookConfig = this.channels.facebookMessenger
        }
        // Instagram
        if (this.channels?.instagram) {
          this.instagramConfig = this.channels.instagram
        }
      }
      this.$emit('stop-loading')
    }
  },
  watch: {
    selectedCompany: {
      async handler () {
        await this.getChannels(true)
        this.whatsappConfig = false
      }
    }
  }
}
