import { APIKeyGetter, IAPIKeyController, dataToCreateAPIKey, dataToDeleteAPIKey, dataToGetAPIKeys } from '../../domain/APIKeysController'
import APIKeysRepository from '../repository/APIKeys.repository'

export default class APIKeysController implements IAPIKeyController {
  repository: APIKeysRepository

  constructor () {
    this.repository = new APIKeysRepository()
  }

  async getAPIKeys (data: dataToGetAPIKeys) : Promise<APIKeyGetter> {
    const response = await this.repository.getAPIKeys(data)
    return response
  }

  async createAPIKey (data: dataToCreateAPIKey) : Promise<APIKeyGetter> {
    const response = await this.repository.createAPIKey(data)
    return response
  }

  async deleteAPIKey (data: dataToDeleteAPIKey) : Promise<APIKeyGetter> {
    const response = await this.repository.deleteAPIKey(data)
    return response
  }
}
