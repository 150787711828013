<template>
  <svg :width="width"
       :height="height"
       viewBox="0 0 16 13"
       fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <path d="M0.774194 0C0.345161 0 0 0.334375 0 0.75V2.25C0 2.66563 0.345161 3 0.774194 3H2.32258C2.75161 3 3.09677 2.66563 3.09677 2.25V0.75C3.09677 0.334375 2.75161 0 2.32258 0H0.774194ZM5.67742 0.5C5.10645 0.5 4.64516 0.946875 4.64516 1.5C4.64516 2.05312 5.10645 2.5 5.67742 2.5H14.9677C15.5387 2.5 16 2.05312 16 1.5C16 0.946875 15.5387 0.5 14.9677 0.5H5.67742ZM5.67742 5.5C5.10645 5.5 4.64516 5.94688 4.64516 6.5C4.64516 7.05312 5.10645 7.5 5.67742 7.5H14.9677C15.5387 7.5 16 7.05312 16 6.5C16 5.94688 15.5387 5.5 14.9677 5.5H5.67742ZM5.67742 10.5C5.10645 10.5 4.64516 10.9469 4.64516 11.5C4.64516 12.0531 5.10645 12.5 5.67742 12.5H14.9677C15.5387 12.5 16 12.0531 16 11.5C16 10.9469 15.5387 10.5 14.9677 10.5H5.67742ZM0 5.75V7.25C0 7.66563 0.345161 8 0.774194 8H2.32258C2.75161 8 3.09677 7.66563 3.09677 7.25V5.75C3.09677 5.33437 2.75161 5 2.32258 5H0.774194C0.345161 5 0 5.33437 0 5.75ZM0.774194 10C0.345161 10 0 10.3344 0 10.75V12.25C0 12.6656 0.345161 13 0.774194 13H2.32258C2.75161 13 3.09677 12.6656 3.09677 12.25V10.75C3.09677 10.3344 2.75161 10 2.32258 10H0.774194Z"
          :fill="color"/>
  </svg>

</template>
<script>
export default {
  name: 'PrimaryIcon',
  props: {
    width: {
      type: Number,
      default: 16
    },
    height: {
      type: Number,
      default: 13
    },
    color: {
      type: String,
      default: '#B6BEC7'
    }
  }
}
</script>
