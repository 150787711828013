









































import CommissionsConfigMain from './CommissionsConfigMain'
export default CommissionsConfigMain
