import { ChatConfig } from '../domain/ChatConfig'
import {
  IChatConfigController,
  DataToGetConfig
} from '../domain/ChatConfigController'

export default class ChatConfigUseCases {
  static dispose (controller: IChatConfigController): void {
    return controller.dispose()
  }

  static async getConfig (
    controller: IChatConfigController,
    data: DataToGetConfig
  ): Promise<ChatConfig> {
    return controller.getConfig(data)
  }

  static async getAndSetConfig (
    controller: IChatConfigController,
    data: DataToGetConfig
  ): Promise<ChatConfig> {
    return controller.getAndSetConfig(data)
  }
}
