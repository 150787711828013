<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       fill="none"
       viewBox="0 0 8 15"><path :fill="color"
                                d="M1.306 4.995c0 .622-.022 1.245.008 1.865.017.384-.108.671-.351.946-.475.537-.752 1.17-.888 1.885a4.06 4.06 0 0 0 .38 2.648c.588 1.165 1.538 1.862 2.806 2.122.946.195 1.836.05 2.667-.42 1.033-.586 1.708-1.466 1.944-2.665.064-.326.135-.66.128-.99-.017-.78-.207-1.52-.648-2.17-.16-.237-.366-.444-.522-.683-.09-.135-.17-.308-.171-.466-.013-1.485.002-2.971-.01-4.457-.01-1.043-.65-2.035-1.625-2.392-.302-.11-.627-.222-.94-.218-.69.008-1.334.187-1.87.69-.65.608-.883 1.377-.905 2.236-.017.69-.003 1.38-.003 2.07Zm.893.033-.032-.001c.032-.874.057-1.75.101-2.624.02-.403.239-.737.515-.992.415-.381.918-.572 1.493-.468.43.078.79.27 1.08.622.304.37.405.79.404 1.257-.004 1.394-.008 2.789.002 4.184.003.404.091.791.376 1.108.161.179.313.372.443.575a3.19 3.19 0 0 1 .499 2.148c-.054.457-.175.883-.414 1.262-.373.592-.861 1.059-1.522 1.314-.49.19-.982.268-1.512.19-.399-.06-.79-.142-1.128-.35-.608-.373-1.092-.88-1.37-1.563a3.206 3.206 0 0 1-.188-1.828c.118-.645.42-1.18.857-1.653.242-.261.395-.58.396-.952v-2.23Z"/><path :fill="color"
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    d="M3.521 6.4c0 .705-.008 1.411.006 2.116.004.188-.07.286-.226.34-.725.253-1.145 1.014-1.118 1.694A1.868 1.868 0 0 0 3.7 12.33c.922.193 1.846-.482 2.065-1.369.219-.882-.245-1.81-1.056-2.1-.188-.067-.285-.173-.282-.413.015-1.425.008-2.852.006-4.278 0-.205.003-.428-.246-.5-.382-.112-.653.053-.662.567-.013.721-.003 1.442-.003 2.162V6.4Zm.5 5.047c-.447.042-.95-.375-.93-.898.025-.648.4-.909.935-.937.497-.027.883.401.885.918.002.543-.46.956-.89.917Z"/></svg>
</template>

<script>
export default {
  name: 'ThermometerIcon',
  props: {
    color: {
      type: String,
      default: '#010101'
    }
  }
}
</script>

<style>

</style>
