





























































import BikyConfigChatCorrectResponse from './BikyConfigChatCorrectResponse'

export default BikyConfigChatCorrectResponse
