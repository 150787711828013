<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       fill="none"
       viewBox="0 0 18 18"
       :class="iconClass"><path :fill="color"
                                d="M5.908 2.265A3.004 3.004 0 0 0 3 0C1.346 0 0 1.346 0 3s1.346 3 3 3c1.4 0 2.58-.965 2.908-2.265h11.725v-1.47H5.908ZM3 4.775c-.98 0-1.776-.795-1.776-1.775a1.777 1.777 0 0 1 3.551 0c0 .98-.795 1.776-1.775 1.776ZM8.755 5.98c-1.4 0-2.58.964-2.908 2.264H0v1.47h5.847a3.004 3.004 0 0 0 2.908 2.265c1.4 0 2.58-.965 2.908-2.265h5.97v-1.47h-5.97A3.004 3.004 0 0 0 8.755 5.98Zm0 4.775a1.777 1.777 0 0 1 0-3.551c.98 0 1.776.796 1.776 1.775 0 .98-.796 1.776-1.776 1.776ZM14.633 12c-1.401 0-2.58.965-2.908 2.265H0v1.47h11.725A3.004 3.004 0 0 0 14.633 18c1.654 0 3-1.346 3-3s-1.346-3-3-3Zm0 4.776a1.777 1.777 0 0 1 0-3.551c.98 0 1.775.795 1.775 1.775 0 .98-.796 1.776-1.775 1.776Z"/></svg>
</template>

<script>
export default {
  name: 'FiltersIcon',
  props: {
    color: {
      type: String,
      default: '#B6BEC7'
    },
    iconClass: {
      type: String,
      default: ''
    }
  }
}
</script>

<style>

</style>
