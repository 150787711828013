import Colors from '@/shared/domain/colors/Color'

import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'
import AdvisorSwitch from '@/shared/infrastructure/ui/layout/AdvisorSwitch.vue'
import LottieAnimation from 'lottie-web-vue'

export default {
  name: 'KBListMenu',
  // props: ['items', 'small'],
  props: {
    items: {
      type: Array,
      default: () => []
    },
    small: {
      type: Boolean,
      default: false
    }
  },
  components: { KeybeIcon, AdvisorSwitch, LottieAnimation },
  data () {
    return {
      Colors
    }
  },
  methods: {
    callAction (item: {[key: string]: any}) {
      if (item.callback) {
        item.callback()
      }
    },
    kbListClass (index) {
      const classes = ['KBListMenu__item']
      if (this.small) {
        classes.push('KBListMenu__item--small')
      }
      if (index === this.items.length - 1) {
        classes.push('KBListMenu__item--last')
      }
      if (this.items[index].animation) {
        classes.push('KBListMenu__item--animated')
      }
      return classes.join(' ')
    }
  }
}
