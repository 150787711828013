<template>
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 9 10"><path :fill="color" d="M3.738 3.438c.348.011.677-.052.99.072.122.048.239.12.345.2a.27.27 0 0 0 .224.06c.264-.04.528-.037.788.034.272.073.484.232.635.468.046.072.096.104.187.105.663.012 1.174.468 1.197 1.13.025.715.026 1.435-.021 2.148-.075 1.133-.985 2.094-2.08 2.287a3.573 3.573 0 0 1-.583.054c-.7.006-1.399 0-2.098.004a2.67 2.67 0 0 1-1.317-.308c-.438-.238-.884-.465-1.311-.722-.453-.274-.68-.697-.69-1.227C-.002 7.391 0 7.04.006 6.688c.008-.425.172-.788.49-1.07.226-.2.471-.379.74-.593 0 .215-.011.395.004.573.013.16-.035.265-.176.351-.328.203-.463.513-.455.894.008.312.003.625.013.937.011.325.187.556.46.709.448.249.895.5 1.356.722.195.094.423.154.638.159.812.019 1.625.022 2.437.006a1.987 1.987 0 0 0 1.97-1.905c.034-.658.02-1.32.003-1.979-.006-.287-.25-.488-.539-.498-.04-.002-.079-.004-.117 0-.262.024-.458-.042-.573-.314-.091-.218-.276-.334-.533-.315-.18.014-.36-.005-.535.078-.136.064-.288.01-.378-.123-.138-.203-.332-.263-.561-.266-.234-.004-.45.014-.649.172-.187.147-.441.014-.469-.224-.005-.051-.005-.104-.005-.156 0-.882.003-1.764-.004-2.646a.916.916 0 0 0-.085-.361c-.073-.16-.286-.248-.45-.216-.23.045-.365.171-.396.373a1.588 1.588 0 0 0-.018.232L2.159 6.43c0 .065-.006.13-.02.194-.034.155-.16.253-.306.244-.157-.01-.261-.108-.284-.27-.007-.042-.006-.086-.006-.13.007-1.764.015-3.527.018-5.292.001-.493.182-.877.647-1.077.71-.305 1.468.132 1.516.906.032.511.012 1.025.014 1.538v.896Z"/></svg>
</template>

<script>
export default {
  name: 'PointerIcon',
  props: {
    color: {
      type: String,
      default: '#B6BEC7'
    }
  }

}
</script>

<style>

</style>
