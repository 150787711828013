<template>
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><path :fill="color" d="M0 11.922C-.017 5.465 5.164-.004 11.907 0 18.61.004 23.823 5.358 23.82 11.917c-.003 6.54-5.193 11.887-11.892 11.907C5.255 23.844 0 18.447 0 11.922Zm1.46-.01c.045 5.702 4.603 10.441 10.447 10.439a10.398 10.398 0 0 0 10.438-10.44c0-5.827-4.671-10.436-10.438-10.438-5.82-.003-10.4 4.713-10.448 10.439Z"/><path :fill="color" d="M11.908 7.426h-1.243c-1.089.004-1.714.613-1.754 1.702-.007.195-.012.395-.058.583a.69.69 0 0 1-.694.507c-.365-.016-.638-.241-.68-.6-.216-1.846.816-3.456 2.689-3.597 1.212-.092 2.45-.123 3.653.02 1.971.237 2.817 2.152 2.453 3.718-.22.946-.804 1.594-1.638 2.034-.824.433-1.655.852-2.49 1.266-.182.09-.268.194-.254.403.015.237.014.477-.01.713-.044.439-.33.71-.721.707-.415-.002-.687-.273-.715-.738a9.945 9.945 0 0 1-.003-.839c.018-.724.385-1.228 1.015-1.548.839-.428 1.69-.833 2.526-1.27.615-.32.927-.858.9-1.544-.025-.715-.42-1.217-1.093-1.428-.326-.102-.695-.071-1.044-.086-.28-.013-.56-.003-.839-.003ZM12.258 17.146c-.01.585-.524 1.127-1.094 1.086-.648-.047-1.09-.392-1.084-1.111.008-.742.495-1.064 1.108-1.081.587-.018 1.078.529 1.068 1.106h.002Z"/></svg>
</template>

<script>
export default {
  name: 'HelpIcon',
  props: {
    color: {
      type: String,
      default: '#B6BEC7'
    }
  }

}
</script>

<style>

</style>
