<template>
  <svg :width="width"
       :height="height"
       viewBox="0 0 18 18"
       fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <path d="M5.5 18C4.04131 18 2.64236 17.4205 1.61091 16.3891C0.579463 15.3576 0 13.9587 0 12.5C0 11.0413 0.579463 9.64236 1.61091 8.61091C2.64236 7.57946 4.04131 7 5.5 7H5.506C6.26428 6.9997 7.01404 7.15988 7.706 7.47L14.89 0.29C15.0767 0.105979 15.3278 0.00195512 15.59 0H17C17.2652 0 17.5196 0.105357 17.7071 0.292893C17.8946 0.48043 18 0.734784 18 1V3.5C18 3.63261 17.9473 3.75979 17.8536 3.85355C17.7598 3.94732 17.6326 4 17.5 4H15.63C15.559 4.00508 15.4901 4.02562 15.4279 4.06016C15.3657 4.09471 15.3118 4.14244 15.27 4.2H15.22C15.0798 4.34083 15.0007 4.53126 15 4.73V6.49C15 6.62261 14.9473 6.74979 14.8536 6.84355C14.7598 6.93732 14.6326 6.99 14.5 6.99H12.72C12.6215 6.98998 12.524 7.0094 12.4331 7.04715C12.3421 7.08491 12.2595 7.14025 12.19 7.21L11.931 7.47C11.7905 7.61064 11.7114 7.8012 11.711 8V8.72C11.709 8.98217 11.605 9.23326 11.421 9.42L10.541 10.299C10.9065 11.1361 11.0578 12.051 10.9812 12.9611C10.9047 13.8713 10.6026 14.7481 10.1024 15.5123C9.60212 16.2765 8.91943 16.9041 8.11592 17.3384C7.31241 17.7727 6.41338 18.0001 5.5 18ZM4.44 11.56C4.04444 11.56 3.65776 11.6773 3.32886 11.8971C2.99996 12.1168 2.74362 12.4292 2.59224 12.7946C2.44087 13.1601 2.40126 13.5622 2.47843 13.9502C2.5556 14.3381 2.74608 14.6945 3.02579 14.9742C3.30549 15.2539 3.66186 15.4444 4.04982 15.5216C4.43778 15.5987 4.83991 15.5591 5.20537 15.4078C5.57082 15.2564 5.88318 15 6.10294 14.6711C6.3227 14.3422 6.44 13.9556 6.44 13.56C6.44 13.0296 6.22929 12.5209 5.85421 12.1458C5.47914 11.7707 4.97043 11.56 4.44 11.56Z"
          :fill="color"/>
  </svg>
</template>
<script>
export default {
  name: 'KeyIcon',
  props: {
    width: {
      type: Number,
      default: 11
    },
    height: {
      type: Number,
      default: 16
    },
    color: {
      type: String,
      default: '#B6BEC7'
    }
  }

}
</script>
<style>

</style>
