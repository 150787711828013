import FeesServices from '@/modules/configuration/application/services/FeesServices'
import { EventBus } from '@/eventBus'
import KeybeText from '@/modules/DesignSystem/infrastructure/components/KeybeText/KeybeText.vue'
import { currencyToUSD } from '@/utils/currencyToUSD'
import Loader from '@/shared/infrastructure/ui/Loader.vue'
import KeybeButton from '@/modules/DesignSystem/infrastructure/components/KeybeButton/KeybeButton.vue'
import { debounce } from 'lodash'
import KeybeAutocomplete from '@/modules/DesignSystem/infrastructure/components/KeybeAutocomplete/KeybeAutocomplete/KeybeAutocomplete.vue'
import { appUpdater } from '@/modules/Apps/domain/AppsController'
import AppsUseCases from '@/modules/Apps/application/AppsUseCases'
import EmitToast from '@/utils/EmitToast'
import AppsController from '@/modules/Apps/infrastructure/controllers/Apps.controller'
import { mapState } from 'vuex'
import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'

export default {
  name: 'KBInvoicesBrands',
  components: {
    KeybeText,
    Loader,
    KeybeButton,
    KeybeAutocomplete,
    KeybeIcon
  },
  props: {
  },
  data () {
    return {
      typeAction: null,
      uuidSelected: null,
      reason: '',
      showModal: false,
      totalItems: 0,
      itemsPerPage: 8,
      currentPage: 1,
      loading: false,
      columns: [
        { key: 'appUUID', label: 'uuid' },
        { key: 'appName', label: 'appName' },
        { key: 'plan.label', label: 'plan' },
        { key: 'type', label: 'isTest' },
        { key: 'isPrime', label: 'isPrime' },
        { key: 'createdAt', label: 'createdAt', filter: 'date' },
        { key: 'creditAmount', label: 'creditAmount' },
        { key: 'invoiceDay', label: 'invoiceDay' },
        { key: 'balanceUSD', label: 'balance' },
        { key: 'currency', label: 'currency' },
        { key: 'biller', label: 'biller' },
        { key: 'statusApp', label: 'feesStatus' },
        { key: 'statusReason', label: '' },
        { key: 'quickbooks', label: 'quickbooks' },
        { key: 'email', label: 'email' }
      ],
      data: [],
      rerender: 0,
      searchQuery: '',
      reasonType: null,
      reasonTypeItems: [
        { label: this.$t('active_status'), id: 'active' },
        { label: this.$t('removal'), id: 'removal' },
        { label: this.$t('blocked_by_debt'), id: 'blocked_by_debt' },
        { label: this.$t('blocked_by_plan'), id: 'blocked_by_plan' },
        { label: this.$t('blocked_by_balance'), id: 'blocked_by_balance' }
      ],
      appsController: new AppsController(),
      app: null
    }
  },
  mounted () {
    this.calculateSize(true)
  },
  beforeDestroy () {
    window.removeEventListener('resize', () => this.calculateSize())
  },
  computed: {
    ...mapState('UserStore', ['token']),
    totalPages () {
      return Math.ceil(this.totalItems / this.itemsPerPage)
    },
    searchPersons (search: string) {
      return this.reasonTypeItems.find((o: { [key: string]: any }) =>
        o.toLowerCase().includes(search.toLowerCase())
      )
    }
  },
  async created () {
    await this.getApps()
  },
  filters: {
    currencyUSD (value) {
      return currencyToUSD(value)
    }
  },
  watch: {
  },
  methods: {
    debouncedSearch: debounce(async function () {
      this.getApps()
    }, 300),
    selectItem (item: any, type: boolean) {
      this.uuidSelected = item
      // this.getApp()
      this.typeAction = type
      this.showModal = true
    },
    closeModal () {
      this.reason = ''
      this.showModal = false
      this.reasonType = null
    },
    async prevPage () {
      if (this.currentPage > 1) {
        this.currentPage--
        await this.getApps()
      }
    },
    async nextPage () {
      if (this.currentPage * this.itemsPerPage < this.totalItems) {
        this.currentPage++
        await this.getApps()
      }
    },
    getValue (item, key) {
      const keys = key.split('.')
      let value = item
      for (const k of keys) {
        if (value && k in value) {
          value = value[k]
        } else {
          return null // Retorna null o algún valor por defecto si la clave no existe
        }
      }
      return value
    },
    formatDate (dateString) {
      const date = new Date(dateString)
      const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`
      return formattedDate
    },
    // async setDisabledApp () {
    //   if (!this.reasonType) {
    //     EventBus.$emit('toast', 'error', this.$t('dialogAllFieldsAreRequired'))
    //     return
    //   }
    //   const model = {
    //     inactive: this.typeAction,
    //     reason: this.reason,
    //     uuid: this.uuidSelected.appUUID
    //   }
    //   const response = await FeesServices.updateInactive(model)
    //   if (response.status === 200) {
    //     const data = await FeesServices.updateInactiveConfig(model)
    //     if (data.status === 200) {
    //       if (this.typeAction) {
    //         EventBus.$emit('toast', 'success', this.$t('blockedBrand'))
    //       } else {
    //         EventBus.$emit('toast', 'success', this.$t('brandActive'))
    //       }
    //       this.getApps()
    //       this.closeModal()
    //       return
    //     }
    //   }
    //   EventBus.$emit('toast', 'error', this.$t('errorSavingForm'))
    // },
    // async getApp () {
    //   try {
    //     this.loading = true
    //     const data:dataToGetApp = {
    //       uuid: this.uuidSelected.appUUID,
    //       token: this.token
    //     }
    //     const response = await AppsUseCases.getApp(this.appsController, data)
    //     if (response) {
    //       this.app = response?.data
    //       this.loading = false
    //     }
    //   } catch (error) {
    //     this.loading = false
    //     EventBus.$emit('toast', 'error', error.message)
    //   }
    // },
    async getApps () {
      if (this.loading) return
      try {
        this.loading = true
        this.data = []
        const pagination = {
          page: this.currentPage,
          limit: this.itemsPerPage
        }
        const searchMain = this.searchQuery ? this.searchQuery.trim() : ''
        const response = await FeesServices.getCompany(searchMain, pagination)
        if (response) {
          const { data, count } = response.data
          this.data = data
          this.totalItems = count
        }
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
      }
    },
    async updateApp (item, isPrime = null, isTest = null): Promise<void> {
      try {
        const app = {
          uuid: item.appUUID,
          ...(isPrime !== null && { isPrime }), // Solo añade isPrime si se pasa
          ...(isTest !== null && { isTest }) // Solo añade type si se pasa
        }

        const data = {
          app: app,
          token: this.token
        }

        const response: appUpdater = await AppsUseCases.updateAppInfo(this.appsController, data)
        if (response?.message) {
          EmitToast.emitErrors(response)
        } else {
          EventBus.$emit('toast', 'success', this.$t('appUpdated'))
          this.showModal = false
          this.getApps()
        }
      } catch (e) {
        console.error(e)
        this.showModal = false
        this.getApps()
      }
    },
    async setDisabledApp (): Promise<void> {
      try {
        if (!this.reasonType) {
          EventBus.$emit('toast', 'error', this.$t('dialogAllFieldsAreRequired'))
          return
        }
        const app = {
          status: this.reasonType,
          status_reason: this.reason,
          uuid: this.uuidSelected.appUUID
        }
        const data = {
          app: app,
          token: this.token
        }
        const response: appUpdater = await AppsUseCases.updateAppInfo(this.appsController, data)
        if (response?.message) {
          EmitToast.emitErrors(response)
        } else {
          EventBus.$emit('toast', 'success', this.$t('appUpdated'))
          this.showModal = false
          this.getApps()
        }
      } catch (e) {
        console.error(e)
        this.showModal = false
        this.getApps()
      }
    },
    calculateSize (fullscreen = false) {
      if (fullscreen) {
        const clientHeight = document.documentElement.clientHeight
        const clientWidth = document.documentElement.clientWidth
        document.documentElement.style.setProperty(
          '--floating-screen-height',
          `${clientHeight - 30}px`
        )
        document.documentElement.style.setProperty(
          '--floating-screen-width',
          `${clientWidth - 50}px`
        )

        // set top and left to 0
        const floatingWindow = document.getElementById('KeybeFloatingBox')
        floatingWindow.style.top = '0px'
        floatingWindow.style.left = '0px'

        EventBus.$emit('FloatingWindow-resize')
        return
      }

      const clientHeight = document.documentElement.clientHeight
      this.totalHeight = clientHeight * 0.8097
      this.heightWithoutHeader = clientHeight * 0.8097 - 30
      document.documentElement.style.setProperty(
        '--floating-screen-height',
        `${this.heightWithoutHeader}px`
      )

      const clientWidth = document.documentElement.clientWidth
      this.width = clientWidth * 0.8
      document.documentElement.style.setProperty(
        '--floating-screen-width',
        `${this.width}px`
      )

      EventBus.$emit('FloatingWindow-resize')
    }
  }
}
