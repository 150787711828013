// import operations from '@/utils/operations'
import KeybeRepository from '@/shared/infrastructure/repositories/KeybeRepository'
import { GenericResponse } from '@/shared/infrastructure/http/GenericResponse'
import { HeadersBuilder } from '@/shared/infrastructure/http/HeadersBuilder'
import { getOptions, nonGetOptions } from '@/shared/infrastructure/http/HttpClient'
import {
  dataToCreateContact,
  dataToDeleteContact,
  dataToGetContact,
  dataToGetContacts,
  dataToUpdateContact,
  dataToGetContactRoom,
  dataToCreateTransactionalData,
  dataToGetTransactionalData,
  dataToUpdateTransactionalData,
  dataToBlacklistContact,
  dataToValidatePhone,
  dataToGetVerificationLogs,
  dataToGetContactLists,
  dataToGetEnrichment,
  dataToMergeContacts,
  dataToGetHabeasDataPDF,
  dataToBulkUpdate
} from '../../domain/ContactsController'
import operations from '@/utils/operations'
import { RestResponseFactory } from '@/models/apis/RestResponse'

export default class ContactsRepository extends KeybeRepository {
  getContactsAbortController: AbortController
  getContactAbortController: AbortController
  createContactAbortController: AbortController
  updateContactAbortController: AbortController
  deleteContactAbortController: AbortController
  getContactRoomAbortController: AbortController
  getTransactionalDataAbortController: AbortController
  createTransactionalDataAbortController: AbortController
  updateTransactionalDataAbortController: AbortController
  blacklistContactAbortController: AbortController
  validatePhoneAbortController: AbortController
  blockContactAbortController: AbortController
  unblockContactAbortController: AbortController
  getVerificationLogsAbortController: AbortController
  getContactListsAbortController: AbortController
  getDataEnrichmentAbortController: AbortController
  mergeContactsAbortController: AbortController
  getHabeasDataPDFAbortController: AbortController

  constructor () {
    super()

    this.getContactsAbortController = new AbortController()
    this.getContactAbortController = new AbortController()
    this.createContactAbortController = new AbortController()
    this.updateContactAbortController = new AbortController()
    this.deleteContactAbortController = new AbortController()
    this.getContactRoomAbortController = new AbortController()
    this.getTransactionalDataAbortController = new AbortController()
    this.createTransactionalDataAbortController = new AbortController()
    this.updateTransactionalDataAbortController = new AbortController()
    this.blacklistContactAbortController = new AbortController()
    this.validatePhoneAbortController = new AbortController()
    this.blockContactAbortController = new AbortController()
    this.unblockContactAbortController = new AbortController()
    this.getVerificationLogsAbortController = new AbortController()
    this.getContactListsAbortController = new AbortController()
    this.getDataEnrichmentAbortController = new AbortController()
    this.mergeContactsAbortController = new AbortController()
    this.getHabeasDataPDFAbortController = new AbortController()
  }

  async getContactsElasticSearch (data: dataToGetContacts): Promise<GenericResponse> {
    const { app, typeEntity, offset, limit, search } = data

    const query = {
      offset: offset.toString(),
      limit: limit.toString(),
      search: search
    }

    let url = `${process.env.VUE_APP_CONTACTS_HTTP}/${app}/contacts/${typeEntity}/elastic/filter`
    url = url + '?' + new URLSearchParams(query).toString()
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)

    delete data.token
    const response = await fetch(url, {
      method: 'GET',
      headers: headers || undefined,
      signal: this.getContactsAbortController.signal
    })
    const parsedResponse = await response.json()

    return new RestResponseFactory().process({ response: { status: response.status, data: parsedResponse } })
  }

  async getContacts (data: dataToGetContacts): Promise<GenericResponse> {
    const { app, typeEntity, offset, limit, search, filters } = data

    const url = `${process.env.VUE_APP_CONTACTS_HTTP}/${app}/contacts/${typeEntity}`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    let formattedFilters = {}
    if (filters) {
      formattedFilters = operations.transformObject(filters, 'filters')
    }
    const query = {
      offset,
      limit,
      search,
      ...formattedFilters
    }

    if (!query.search) delete query.search
    delete data.token
    const requestOptions: getOptions = {
      headers,
      query,
      url
    }

    return await this.client.get(requestOptions)
  }

  async getContact (data: dataToGetContact): Promise<GenericResponse> {
    const { app, typeEntity, uuid } = data

    const url = `${process.env.VUE_APP_CONTACTS_HTTP}/${app}/contacts/${typeEntity}/${uuid}`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    delete data.token
    const requestOptions: getOptions = {
      headers,
      url,
      abortController: this.getContactAbortController
    }

    return await this.client.get(requestOptions)
  }

  async createContact (data: dataToCreateContact): Promise<GenericResponse> {
    const { app, createInput } = data

    const url = `${process.env.VUE_APP_CONTACTS_HTTP}/${app}/contacts`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    // const requestOptions: nonGetOptions = {
    //   headers,
    //   data: createInput,
    //   url,
    //   abortController: this.getSuggestionsAbortController
    // }

    // return await this.client.post(requestOptions)

    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(createInput),
      headers: headers || undefined,
      signal: this.createContactAbortController.signal
    })
    const parsedResponse = await response.json()

    return new RestResponseFactory().process({ response: { status: response.status, data: parsedResponse } })
  }

  async updateContact (data: dataToUpdateContact): Promise<GenericResponse> {
    const { app, updateInput } = data

    const url = `${process.env.VUE_APP_CONTACTS_HTTP}/${app}/contacts/${updateInput.uuid}`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    // const requestOptions: nonGetOptions = {
    //   headers,
    //   data: updateInput,
    //   url,
    //   abortController: this.getSuggestionsAbortController
    // }

    // return await this.client.patch(requestOptions)

    const response = await fetch(url, {
      method: 'PATCH',
      body: JSON.stringify(updateInput),
      headers: headers || undefined,
      signal: this.updateContactAbortController.signal
    })
    const parsedResponse = await response.json()

    return new RestResponseFactory().process({ response: { status: response.status, data: parsedResponse } })
  }

  async deleteContact (data: dataToDeleteContact): Promise<GenericResponse> {
    const { app, uuid } = data

    const url = `${process.env.VUE_APP_CONTACTS_HTTP}/${app}/contacts/${data?.typeEntity}/${uuid}`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    delete data.token
    const requestOptions: nonGetOptions = {
      headers,
      url,
      signal: this.deleteContactAbortController.signal
    }

    return await this.client.delete(requestOptions)
  }

  async getContactRoom (data: dataToGetContactRoom): Promise<GenericResponse> {
    const { app, uuid } = data

    const variables = {
      appUUID: app,
      crmUserId: uuid
    }
    const url = `${process.env.VUE_APP_CONVERSATIONS_URL}/rooms/roomByUser`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    const requestOptions: getOptions = {
      headers,
      query: variables,
      url,
      signal: this.getContactRoomAbortController.signal
    }

    return await this.client.get(requestOptions)
  }

  async getTransactionalData (data: dataToGetTransactionalData): Promise<GenericResponse> {
    const { app, contactUUID } = data

    const url = `${process.env.VUE_APP_CONTACTS_HTTP}/${app}/transactionals/${contactUUID}`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    const requestOptions: getOptions = {
      headers,
      url,
      signal: this.getTransactionalDataAbortController.signal
    }

    return await this.client.get(requestOptions)
  }

  async createTransactionalData (data: dataToCreateTransactionalData): Promise<GenericResponse> {
    const { app, contactUUID, createInput } = data

    const url = `${process.env.VUE_APP_CONTACTS_HTTP}/${app}/transactionals/${contactUUID}`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    const requestOptions: nonGetOptions = {
      headers,
      data: createInput,
      url,
      signal: this.createTransactionalDataAbortController.signal
    }

    return await this.client.post(requestOptions)
  }

  async updateTransactionalData (data: dataToUpdateTransactionalData): Promise<GenericResponse> {
    const { app, updateInput } = data

    const url = `${process.env.VUE_APP_CONTACTS_HTTP}/${app}/transactionals/${updateInput.contactUUID}/${updateInput._id}`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    const requestOptions: nonGetOptions = {
      headers,
      data: updateInput,
      url,
      signal: this.updateTransactionalDataAbortController.signal
    }

    return await this.client.patch(requestOptions)
  }

  async blacklistContact (data: dataToBlacklistContact): Promise<GenericResponse> {
    const { app, company, userGuest } = data

    const url = `${process.env.VUE_APP_CONVERSATIONS_API_URL}/proactivity-blacklist/company/${company}/app/${app}`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    const requestOptions: nonGetOptions = {
      headers,
      data: { userGuest: userGuest },
      url,
      signal: this.blacklistContactAbortController.signal
    }

    return await this.client.post(requestOptions)
  }

  async validatePhone (data: dataToValidatePhone): Promise<GenericResponse> {
    const { phone } = data

    const url = `${process.env.VUE_APP_CONTACTS_HTTP}/utils/validate-phone`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    const requestOptions: nonGetOptions = {
      headers,
      data: { phone: phone },
      url,
      signal: this.validatePhoneAbortController.signal
    }

    return await this.client.post(requestOptions)
  }

  async getVerificationLogs (data: dataToGetVerificationLogs): Promise<GenericResponse> {
    const { app, uuid } = data
    const url = `${process.env.VUE_APP_CONTACTS_HTTP}/${app}/verified-identity-logs/${uuid}`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)

    const query = {
      offset: 0,
      limit: 25
    }
    const requestOptions: getOptions = {
      headers,
      query,
      url,
      signal: this.getVerificationLogsAbortController.signal
    }

    return await this.client.get(requestOptions)
  }

  async getContactLists (data: dataToGetContactLists): Promise<GenericResponse> {
    const { app, uuid, typeEntity, document } = data
    const url = `${process.env.VUE_APP_CONTACTS_HTTP}/${app}/contacts/${typeEntity}/legalstatus/${uuid}/${document}`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)

    const response = await fetch(url, {
      method: 'GET',
      headers: headers || undefined,
      signal: this.getContactListsAbortController.signal
    })
    const parsedResponse = await response.json()

    return new RestResponseFactory().process({ response: { status: response.status, data: parsedResponse } })
  }

  async getDataEnrichment (data: dataToGetEnrichment): Promise<GenericResponse> {
    const { app, uuid, typeEntity, phone } = data
    const url = `${process.env.VUE_APP_CONTACTS_HTTP}/${app}/contacts/${typeEntity}/dataenrichment/${uuid}/${phone}`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)

    const requestOptions: getOptions = {
      headers,
      url,
      signal: this.getContactListsAbortController.signal
    }

    return await this.client.get(requestOptions)
  }

  async mergeContacts (data: dataToMergeContacts): Promise<GenericResponse> {
    const { app, updateInput } = data

    const url = `${process.env.VUE_APP_CONTACTS_HTTP}/${app}/merges/simple`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    const requestOptions: nonGetOptions = {
      headers,
      data: updateInput,
      url,
      signal: this.mergeContactsAbortController.signal
    }
    return await this.client.post(requestOptions)
  }

  async getHabeasDataPDF (data: dataToGetHabeasDataPDF): Promise<GenericResponse> {
    const { app, uuid, typeEntity } = data

    const url = `${process.env.VUE_APP_CONTACTS_HTTP}/habeasdata/${app}/${typeEntity}/download-pdf/${uuid}`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    const requestOptions: nonGetOptions = {
      headers,
      url,
      signal: this.getHabeasDataPDFAbortController.signal
    }

    return await this.client.post(requestOptions)
  }

  async bulkUpdateContacts (data: dataToBulkUpdate): Promise<GenericResponse> {
    const { app, updateInput } = data
    const url = `${process.env.VUE_APP_CONTACTS_HTTP}/${app}/contacts/bulk`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    const response = await fetch(url, {
      method: 'PATCH',
      body: JSON.stringify(updateInput),
      headers: headers || undefined,
      signal: this.updateContactAbortController.signal
    })
    const parsedResponse = await response.json()

    return new RestResponseFactory().process({ response: { status: response.status, data: parsedResponse } })
  }

  abortGetContacts (): void {
    this.getContactsAbortController.abort()
    this.getContactsAbortController = new AbortController()
  }

  abortGetContact (): void {
    this.getContactAbortController.abort()
    this.getContactAbortController = new AbortController()
  }

  abortCreateContact (): void {
    this.createContactAbortController.abort()
    this.createContactAbortController = new AbortController()
  }

  abortUpdateContact (): void {
    this.updateContactAbortController.abort()
    this.updateContactAbortController = new AbortController()
  }

  abortDeleteContact (): void {
    this.deleteContactAbortController.abort()
    this.deleteContactAbortController = new AbortController()
  }

  abortGetContactRoom (): void {
    this.getContactRoomAbortController.abort()
    this.getContactRoomAbortController = new AbortController()
  }

  abortGetTransactionalData (): void {
    this.getTransactionalDataAbortController.abort()
    this.getTransactionalDataAbortController = new AbortController()
  }

  abortCreateTransactionalData (): void {
    this.createTransactionalDataAbortController.abort()
    this.createTransactionalDataAbortController = new AbortController()
  }

  abortUpdateTransactionalData (): void {
    this.updateTransactionalDataAbortController.abort()
    this.updateTransactionalDataAbortController = new AbortController()
  }

  abortBlacklistContact (): void {
    this.blacklistContactAbortController.abort()
    this.blacklistContactAbortController = new AbortController()
  }

  abortValidatePhone (): void {
    this.validatePhoneAbortController.abort()
    this.validatePhoneAbortController = new AbortController()
  }

  abortBlockContact (): void {
    this.blockContactAbortController.abort()
    this.blockContactAbortController = new AbortController()
  }

  abortUnblockContact (): void {
    this.unblockContactAbortController.abort()
    this.unblockContactAbortController = new AbortController()
  }

  abortGetVerificationLogs (): void {
    this.getVerificationLogsAbortController.abort()
    this.getVerificationLogsAbortController = new AbortController()
  }

  abortGetContactLists (): void {
    this.getContactListsAbortController.abort()
    this.getContactListsAbortController = new AbortController()
  }

  abortGetDataEnrichment (): void {
    this.getDataEnrichmentAbortController.abort()
    this.getDataEnrichmentAbortController = new AbortController()
  }

  abortMergeContacts (): void {
    this.mergeContactsAbortController.abort()
    this.mergeContactsAbortController = new AbortController()
  }

  abortHabeasDataPDF (): void {
    this.getHabeasDataPDFAbortController.abort()
    this.getHabeasDataPDFAbortController = new AbortController()
  }

  abortAll (): void {
    this.abortGetContacts()
    this.abortGetContact()
    this.abortCreateContact()
    this.abortUpdateContact()
    this.abortDeleteContact()
    this.abortGetContactRoom()
    this.abortGetTransactionalData()
    this.abortCreateTransactionalData()
    this.abortUpdateTransactionalData()
    this.abortBlacklistContact()
    this.abortValidatePhone()
    this.abortBlockContact()
    this.abortUnblockContact()
    this.abortGetVerificationLogs()
    this.abortGetContactLists()
    this.abortGetDataEnrichment()
    this.abortMergeContacts()
    this.abortHabeasDataPDF()
  }
}
