<template>
  <div class="ContentGraphGray"
       :class="$vuetify.theme.dark ? 'ContentGraphGrayDark' : ''">
    <span class="pt-3 pb-1">{{ $t('newDeals') }} ({{ nameFlow }})</span>
    <DateRange :range="range"/>
    <div id="SalesLineGraphNew"
         class="full-width"></div>
  </div>
</template>

<script>
import ApexCharts from 'apexcharts'
import DateRange from '@/modules/metrics/infrastructure/ui/components/generic/DateRange.vue'

export default {
  props: {
    range: {
      type: Object,
      required: false
    },
    nameFlow: {
      type: [String, Number, Object],
      required: false
    },
    dataRequest: {
      type: Object
    }
  },
  name: 'NewDeals',
  components: {
    DateRange
  },
  data () {
    return {
      chartSingle: null
    }
  },
  watch: {
    dataRequest () {
      if (this.dataRequest) {
        const { axis, deals } = this.dataRequest
        if (deals) {
          const { actualCount, previousCount } = deals
          this.chartLine(axis, actualCount, previousCount)
        }
      }
    }
  },
  mounted () {
    if (this.dataRequest) {
      const { axis, deals } = this.dataRequest
      if (deals) {
        const { actualCount, previousCount } = deals
        this.chartLine(axis, actualCount, previousCount)
      }
    }
  },
  computed: {
  },
  methods: {
    chartLine (axis, actualCount, previousCount) {
      if (this.chartSingle) {
        this.chartSingle.destroy()
      }
      const options = {
        chart: {
          toolbar: {
            show: false
          },
          height: 250,
          type: 'line'
        },
        tooltip: {
          enabled: true,
          enabledOnSeries: undefined,
          shared: true,
          followCursor: false,
          intersect: false,
          inverseOrder: false,
          custom: undefined,
          fillSeriesColor: true,
          theme: false,
          style: {
            fontSize: '12px',
            fontFamily: undefined
          },
          y: {
            formatter: undefined,
            title: {
              formatter: (seriesName) => seriesName + ':'
            }
          },
          onDatasetHover: {
            highlightDataSeries: false
          }
        },
        colors: ['#3AF69B', '#FFB200'],
        series: [
          {
            name: this.$t('currentPeriod'),
            data: actualCount
          },
          {
            name: this.$t('previousPeriod'),
            data: previousCount
          }
        ],
        stroke: {
          width: [2, 2],
          curve: 'smooth'
        },
        xaxis: {
          type: 'datetime',
          categories: axis
        }
      }
      this.chartSingle = new ApexCharts(document.querySelector('#SalesLineGraphNew'), options)
      this.chartSingle.render()
    }
  }
}
</script>
<style lang='scss'>
.full-width{
  width: 100%;
}
.MetricsConversation__dark{
  max-width: 1500px;
}

</style>
