import { EventBus } from '@/eventBus'

export default {
  name: 'ImageModal',
  data () {
    return {
      show: false,
      image: '',
      images: [],
      isZoomActive: false
    }
  },
  mounted () {
    EventBus.$on('showModalImage', this.onShowModalImage.bind(this))
  },
  beforeDestroy () {
    this.removeEventListeners()
    EventBus.$off('showModalImage', this.onShowModalImage.bind(this))
  },
  methods: {
    onShowModalImage ({ show, image, images }): void {
      console.log('show', image)
      this.image = image
      document.documentElement.style.setProperty('--modalImageHeight', `${image.h}px`)
      document.documentElement.style.setProperty('--modalImageWidth', `${image.w}px`)
      this.images = images || [image]
      this.show = show
    },
    close () {
      this.show = false
      this.image = ''
    },
    selectImage (image) {
      this.image = image
      document.documentElement.style.setProperty('--modalImageHeight', `${image.h}px`)
      document.documentElement.style.setProperty('--modalImageWidth', `${image.w}px`)
    },
    isSelected (image) {
      return this.image?.src === image?.src
    },
    addEventListeners () {
      document.addEventListener('keydown', this.handleKeyDown)
    },
    handleKeyDown (event) {
      if (event.key === 'Escape') {
        this.close()
      }

      // handle arrow keys
      if (event.key === 'ArrowRight') {
        this.nextImage()
      }

      if (event.key === 'ArrowLeft') {
        this.previousImage()
      }
    },
    nextImage () {
      const index = this.images?.findIndex(image => image.src === this.image.src)
      if (index === this.images?.length - 1) {
        this.selectImage(this.images[0])
      } else {
        this.selectImage(this.images[index + 1])
      }
    },
    previousImage () {
      const index = this.images?.findIndex(image => image.src === this.image.src)
      if (index === 0) {
        this.image = this.images[this.images?.length - 1]
      } else {
        this.image = this.images[index - 1]
      }
    },
    removeEventListeners () {
      document.removeEventListener('keydown', this.handleKeyDown)
    },
    zoom (e: {[key: string]: any}): void {
      const offsetX: number = e.offsetX || (e.touches || [])[0]?.pageX || 0
      const offsetY: number = e.offsetY || (e.touches || [])[0]?.pageY || 0

      const zoomer: HTMLImageElement = e.currentTarget
      const x: number = (offsetX / zoomer.offsetWidth) * 100
      const y: number = (offsetY / zoomer.offsetHeight) * 100

      zoomer.style.backgroundPosition = x + '% ' + y + '%'
      zoomer.style.backgroundSize = this.isZoomActive ? '150%' : '100%'
    },
    toggleZoom () {
      this.isZoomActive = !this.isZoomActive

      const zoomer: HTMLElement = document.getElementById('figure-principal-image-zoomer')
      zoomer.style.backgroundSize = this.isZoomActive ? '150%' : '100%'
    }
  },
  watch: {
    show (val: boolean): void {
      if (val) {
        this.addEventListeners()
      } else {
        this.removeEventListeners()
      }
    },
    image (val: { src: string }): void {
      this.isZoomActive = false
      if (val) {
        document.documentElement.style.setProperty('--modalImage', `url(${val.src})`)
      }
    }
  }
}
