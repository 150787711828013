import { Message } from '@/modules/conversations/domain/models/Message/Message'
import { TokenModel } from '@/shared/domain/TokenModel'
import { BikyCatalogConfig, BikyConfig, BikyConfigLanguageType, BikyFile, BikyKnowledgeFragment, BikyMessageError, BikyMessageErrorStatus, BikyPronunciation, BikyType, BikyWebsite, KnowledgeBaseType } from './BikyConfig'

export interface getConfigDTO extends TokenModel{
    appUUID: string;
}

export interface updateConfigDTO extends TokenModel {
    appUUID: string;
    name?: string;
    languageType?: BikyConfigLanguageType;
    useEmojis?: boolean;
    useSlang?: boolean;
    slang?: string;
    active?: boolean;
    lines?: string[];
    updatedBy?: string;
    type?:BikyType;
    audioMessagesLimit?: number;
}

export interface createPronunciationDTO extends TokenModel {
    appUUID: string;
    name: string;
    pronunciation: string;
}

export interface updatePronunciationDTO extends TokenModel {
    pronunciationId: string;
    name?: string;
    pronunciation?: string;
}

export interface deletePronunciationDTO extends TokenModel {
    pronunciationId: string;
}

// websites

export interface getWebsitesDTO extends TokenModel {
    appUUID: string;
}
export interface CreateWebsiteDto extends TokenModel {
    appUUID: string;
    url: string;
  }

export interface deleteWebsiteDTO extends TokenModel {
    websiteId: string;
  }

// files

export interface getFilesDTO extends TokenModel {
    appUUID: string;
}

export enum BikyFileTypes {
    PDF = 'PDF',
}

export type BikyFileType = BikyFileTypes.PDF

export interface createFileDTO extends TokenModel {
    appUUID: string,
    name: string,
    url: string,
    type: BikyFileType,
}

export interface deleteFileDTO extends TokenModel {
    fileId: string,
}

// knowledge base
export interface getKnowledgeBaseDTO extends TokenModel {
    appUUID: string;
    type?: KnowledgeBaseType;
    offset?: number;
    limit?: number;
    search?: string;
}

export interface createKnowledgeBaseDTO extends TokenModel {
    text: string;
    appUUID: string;
    type: KnowledgeBaseType;
    typeId: string;
}

export interface updateKnowledgeBaseDTO extends TokenModel {
    text: string;
    knowledgeBaseDocumentId: string;
}

export interface deleteKnowledgeBaseDTO extends TokenModel {
    documentId: string;
}

// biky message errors
export interface getBikyMessageErrorsDTO extends TokenModel {
    appUUID: string;
}

export interface CreateBikyMessageErrorDto extends TokenModel {
    appUUID: string;
    createdByAdviserId: string;
    previousMessage: Message;
    bikyMessage: Message;
  }

export interface UpdateBikyMessageErrorDto extends TokenModel {
    status: BikyMessageErrorStatus;
    _id: string;
    fixedByAdviserId: string;
  }

export interface deleteBikyMessageErrorDTO extends TokenModel {
    bikyMessageErrorId: string;
}

export interface dataToGetTestAudio extends TokenModel {
    text: string,
    language: string,
    appUUID: string,
    slang?: string,
}

export interface IBikyConfigController {
    // config
    getBikyConfig(data: getConfigDTO): Promise<BikyConfig | null>;
    createBikyDefaultConfig(data: getConfigDTO): Promise<boolean>;
    updateBikyConfig(data: updateConfigDTO): Promise<boolean>;
    // pronunciations
    getPronunciations(data: getConfigDTO): Promise<BikyPronunciation[]>;
    createPronunciation(data: createPronunciationDTO): Promise<boolean>;
    updatePronunciation(data: updatePronunciationDTO): Promise<boolean>;
    deletePronunciation(data: deletePronunciationDTO): Promise<boolean>;
    // websites
    getWebsites(data: getWebsitesDTO): Promise<BikyWebsite[]>;
    createWebsite(data: CreateWebsiteDto): Promise<boolean>;
    deleteWebsite(data: deleteWebsiteDTO): Promise<boolean>;
    // files
    getFiles(data: getFilesDTO): Promise<BikyFile[]>;
    createFile(data: createFileDTO): Promise<boolean>;
    deleteFile(data: deleteFileDTO): Promise<boolean>;
    // knowledge base
    getKnowledgeBase(data: getKnowledgeBaseDTO): Promise<{
        documents: BikyKnowledgeFragment[],
        count: number,
    }>;
    createKnowledgeBase(data: createKnowledgeBaseDTO): Promise<boolean>;
    updateKnowledgeBase(data: updateKnowledgeBaseDTO): Promise<boolean>;
    deleteKnowledgeBase(data: deleteKnowledgeBaseDTO): Promise<boolean>;
    // biky message errors
    getBikyMessageErrors(data: getBikyMessageErrorsDTO): Promise<BikyMessageError[]>;
    createBikyMessageError(data: CreateBikyMessageErrorDto): Promise<boolean>;
    updateBikyMessageError(data: UpdateBikyMessageErrorDto): Promise<boolean>;
    deleteBikyMessageError(data: deleteBikyMessageErrorDTO): Promise<boolean>;
    // get audio demo
    getAudioDemoUrl(data:dataToGetTestAudio): Promise<string>;
    // my keybe catalog config
    getMyKeybeCatalogConfig(data: getConfigDTO): Promise<BikyCatalogConfig | null>;
    syncMyKeybe(data: getConfigDTO): Promise<boolean>;
    unSyncMyKeybe(data: getConfigDTO): Promise<boolean>;
    // abort all
    abortAll(): void;
}
