import TemplateTextEditor from '../TemplateTextEditor/TemplateTextEditor.vue'
import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'
import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import KeybeSelect from '@/modules/DesignSystem/infrastructure/components/KeybeSelect/KeybeSelect/KeybeSelect.vue'
import { TemplateButtonTypesEnum } from '@/modules/createTemplates/domain/Template'
import { CDPFieldsEnums } from '@/modules/CDP/shared/contacts/domain/enums/CDPFieldsEnums'
import KeybeText from '@/modules/DesignSystem/infrastructure/components/KeybeText/KeybeText.vue'
import { mapState } from 'vuex'
import KeybeButton from '@/modules/DesignSystem/infrastructure/components/KeybeButton/KeybeButton.vue'
import Colors from '@/utils/Colors'
export default {
  components: {
    TemplateTextEditor,
    KeybeIcon,
    KeybeSelect,
    KeybeText,
    KeybeButton
  },
  computed: {
    ...mapState('ConversationStore', ['channels']),
    userHostList () {
      const whatsapp = this.channels?.whatsapp
      const list = whatsapp?.accounts?.map(account => account.userHost) || []
      return list
    }
  },
  data () {
    return {
      Colors,
      KeybeIconType,
      CDPFieldsEnums,
      TemplateButtonTypesEnum,
      buttonTypes: [
        { text: this.$t('PHONE_NUMBER'), value: TemplateButtonTypesEnum.PHONE_NUMBER },
        { text: this.$t('URL'), value: TemplateButtonTypesEnum.URL },
        { text: this.$t('QUICK_REPLY'), value: TemplateButtonTypesEnum.QUICK_REPLY },
        { text: this.$t('COPY_CODE'), value: TemplateButtonTypesEnum.COPY_CODE }
      ],
      template: {
        text: '',
        cards: [
          {
            text: '',
            buttons: [
              {
                text: '',
                type: ''
              }
            ]
          }
        ]
      }
    }
  },
  methods: {
    setButtonType (indexCard: number, indexButton: number, event: string): void {
      this.template.cards[indexCard].buttons[indexButton].type = event

      switch (event) {
        case TemplateButtonTypesEnum.PHONE_NUMBER:
          this.template.cards[indexCard].buttons[indexButton].phone_number = ''
          break
        case TemplateButtonTypesEnum.URL:
          this.$set(this.template.cards[indexCard].buttons[indexButton], 'url', '')
          break
        default:
          this.$set(this.template.cards[indexCard].buttons[indexButton], 'url', '')
          break
      }
    },
    addButton (indexCard: number): void {
      this.template.cards[indexCard].buttons.push({
        text: '',
        type: ''
      })
    },
    addCard (): void {
      this.template.cards.push({
        text: '',
        buttons: [
          {
            text: '',
            type: ''
          }
        ]
      })
    },
    removeCard (indexCard: number): void {
      if (this.template.cards.length > 1) this.template.cards = this.template.cards.splice(indexCard, 1)
    },
    removeButton (indexCard: number, indexButton: number): void {
      if (this.template.cards[indexCard].buttons.length > 1) this.template.cards[indexCard].buttons = this.template.cards[indexCard].buttons.splice(indexButton, 1)
    }
  },
  watch: {
    template: {
      handler (value: string): void {
        this.$emit('input', value)
      },
      deep: true
    }
  }
}
