<template>
  <div class="FloatingSidebar"
       :class="$vuetify.theme.dark? 'FloatingSidebar__dark':''">
    <div class="FloatingSidebar__topBox"
         @click="$emit('selectTab', 'Imports')">
      <KeybeAvatar
        :name="'KB'"
        :size="50"
        :isKeybeProfile="true"/>
      <div class="FloatingSidebar__topBox--info">
        <strong class="FloatingSidebar__topBox--info--title">{{selectedApp.name}}</strong>
        <span class="FloatingSidebar__topBox--info--subtitle">{{$t('catalog')}}</span>
      </div>
    </div>
    <div
      v-for="(group, index) in itemsGroups"
      :key="`ConfigurationSidebar__group-${index}`"
      class="FloatingSidebar__box"
    >
      <div
        v-for="(tab, index) in group"
        class="FloatingSidebar__box--tab"
        :key="`ConfigurationSidebar__tab-${index}`"
        :class="`${(index === (group.length-1)) ?
          'FloatingSidebar__box--tab--last':
          index === 0 ?
            'FloatingSidebar__box--tab--first' :
            ''}`"
        @click="selectTab(tab)"
      >
        <div
          :class="`FloatingSidebar__box--tab--iconContainer FloatingSidebar__box--tab--iconContainer--${tab.class}`"
        >
          <div class="FloatingSidebar__box--tab--icon">
            <KeybeIcon
              :icon="tab.icon"
              :color="tab.color"/>
          </div>
        </div>
        <span
          :class="isSelectedTab(tab.name)?'FloatingSidebar__box--tab--text--selected':''"
          class="FloatingSidebar__box--tab--text">{{$t(tab.label)}}</span>
      </div>
    </div>
  </div>
</template>
<script>
import CatalogSidebar from './CatalogSidebar'
export default CatalogSidebar
</script>
<style lang='scss'>
@import '@/shared/infrastructure/ui/styles/Floating.scss';
</style>
