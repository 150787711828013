<template>
  <svg :width="width" :height="height" viewBox="0 0 57 48" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M28.3273 5.81156C29.6848 4.70797 30.9027 3.54582 32.287 2.62664C35.4358 0.530681 38.9826 -0.324096 42.7374 0.126711C48.9488 0.873177 53.3393 4.1869 55.8197 9.82785C57.1119 12.761 57.2218 15.8786 56.7287 19.0255C56.1405 22.7783 54.2572 25.8403 51.5094 28.3988C44.9802 34.4817 38.4153 40.5266 31.8533 46.5745C30.3294 47.9767 28.5649 48.4275 26.6014 47.5522C26.0726 47.3151 25.5795 46.9492 25.1518 46.554C18.5898 40.5061 12.0427 34.4437 5.48378 28.39C2.50729 25.6354 0.632877 22.3099 0.142736 18.2966C-0.986071 9.09016 4.69362 1.77772 13.1686 0.276004C18.7443 -0.710502 23.6903 0.958069 27.6381 5.11193C27.8074 5.2905 27.9738 5.472 28.1431 5.64763C28.2114 5.71789 28.2887 5.77936 28.3273 5.81156ZM24.1358 22.1226C24.7151 22.8281 25.2112 23.434 25.7102 24.0371C27.6025 26.3233 29.5006 28.6037 31.3869 30.8958C31.886 31.5017 32.2662 32.1633 31.8177 32.9654C31.1404 34.1744 29.8066 34.271 28.8412 33.1381C26.0103 29.8156 23.1942 26.4784 20.384 23.1384C19.1869 21.7186 19.3622 20.8199 20.9722 19.9183C24.1121 18.1619 27.2519 16.4026 30.3918 14.6433C30.6146 14.5174 30.8255 14.371 31.075 14.213C30.8493 13.9495 30.6918 13.7622 30.5285 13.5807C28.9095 11.7687 27.3203 9.9303 25.6568 8.15927C24.8399 7.28986 23.9546 6.44972 22.9833 5.7618C19.882 3.57217 16.4273 2.98085 12.7557 3.94686C7.99092 5.19975 4.89561 8.22368 3.80839 12.9542C2.63503 18.0624 4.0698 22.4856 7.99983 26.0891C14.4845 32.0374 20.9514 38.0062 27.4272 43.9633C28.3124 44.7771 28.7075 44.783 29.5779 43.9838C36.0715 38.0091 42.5681 32.0345 49.0528 26.054C50.3628 24.845 51.4292 23.4574 52.2045 21.8474C54.4948 17.0905 53.666 10.5041 49.6231 6.76294C46.207 3.60144 40.2956 2.6003 36.0566 4.57038C34.0188 5.51591 32.4474 6.99127 30.873 8.58374C32.2246 10.1586 33.5287 11.7833 34.9516 13.3026C36.0151 14.4354 35.6824 15.6942 34.3456 16.4143C32.8069 17.2457 31.2889 18.118 29.7621 18.9728C27.9173 20.0061 26.0756 21.0424 24.1388 22.1284L24.1358 22.1226Z" :fill="color"/>
  </svg>
</template>

<script>
export default {
  name: 'BrokenHeartIcon',
  props: {
    width: {
      type: Number,
      default: 16
    },
    height: {
      type: Number,
      default: 18
    },
    color: {
      type: String,
      default: '#B6BEC7'
    }
  }

}
</script>

<style>

</style>
