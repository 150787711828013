import {
  AutoassignAdvisorConfig,
  AutoassignConfig,
  AutoassignType
} from '@/modules/configuration/domain/models/Autoassign/Autoassign'

export default class AutoassignService {
  static buildAutoassignConfigFromObject (config: {
        [key: string]: any
    }): AutoassignConfig {
    const autoassignConfig: AutoassignConfig = {
      isAutoassignActive: config.isAutoassignActive || false,
      autoassignationType: config.autoassignationType || AutoassignType.BALANCED,
      maxActiveConversationsNumberPerAdvisor: config.maxActiveConversationsNumberPerAdvisor || 0,
      groups: config.groups || [],
      tags: config.tags || []
    }
    return autoassignConfig
  }

  static buildAutoassignAdvisorConfigFromObject (config: {
        [key: string]: any
    }): AutoassignAdvisorConfig {
    const autoassignAdvisorConfig: AutoassignAdvisorConfig = {
      userId: config.userId || '',
      isActiveForAutoassign: config.isActiveForAutoassign || false,
      maxActiveConversationsNumber: config.maxActiveConversationsNumber || 0,
      receiveRoomsEvenOfflineStatus: config.receiveRoomsEvenOfflineStatus || false,
      tags: config.tags || [],
      groups: config.groups || []
    }
    return autoassignAdvisorConfig
  }
}
