























import BikyConfigSkillBubble from './BikyConfigSkillBubble'

export default BikyConfigSkillBubble
