import { mapState } from 'vuex'
import KeybeButton from '@/modules/DesignSystem/infrastructure/components/KeybeButton/KeybeButton.vue'
import KBSearch from '@/shared/infrastructure/ui/inputs/text/KBSearch.vue'
import Loader from '@/shared/infrastructure/ui/Loader.vue'
import EmitToast from '@/utils/EmitToast'
import { EntityTypeEnum } from '@/modules/CDP/shared/contacts/domain/Contacts'
import ExtraDataCard from '../ExtraDataCard/ExtraDataCard.vue'
import CreateEditExtraData from '../CreateEditExtraData/CreateEditExtraData.vue'
import ExtraDataUseCases from '@/modules/ExtraData/application/ExtraDataUseCases'
import ExtraDataController from '../../controllers/ExtraData.controller'
import Colors from '@/utils/Colors'
export default {
  components: {
    KeybeButton,
    KBSearch,
    Loader,
    ExtraDataCard,
    CreateEditExtraData
  },
  computed: {
    ...mapState('AppStore', ['selectedApp']),
    ...mapState('UserStore', ['token', 'isUserReadonly']),
    buttonColor (): string {
      if (!this.create) return Colors.kbGreen
      return this.$vuetify.theme.dark ? Colors.inputDark : Colors.inputLight
    }
  },
  mounted () {
    this.getExtraDataFields()
  },
  data () {
    return {
      extradataFields: [],
      create: false,
      loading: false,
      typeEntity: EntityTypeEnum.PEOPLE,
      rerender: 0,
      search: '',
      extraDataController: new ExtraDataController(),
      EntityTypeEnum,
      Colors
    }
  },
  methods: {
    setSearch (e) {
      this.search = e
      this.getExtraDataFields()
    },
    buttonColorOption (type: string) {
      if (type === this.typeEntity) return Colors.kbGreen
      else return this.$vuetify.theme.dark ? Colors.inputDark : Colors.inputLight
    },
    buttonTextColorOption (type: string) {
      if (type === this.typeEntity) return Colors.black
      else return this.$vuetify.theme.dark ? Colors.white : Colors.black
    },
    selectEntityType (type) {
      this.typeEntity = type
      this.rerender++
      this.getExtraDataFields()
    },
    closeCreate () {
      this.create = false
      this.getExtraDataFields()
    },
    async getExtraDataFields () {
      try {
        this.loading = true
        const data = {
          typeEntity: this.typeEntity,
          app: this.selectedApp?.uuid,
          search: this.search,
          token: this.token
        }
        const response = await ExtraDataUseCases.getExtraDataFields(this.extraDataController, data)
        if (response?.status) {
          this.extradataFields = response?.data?.data || []
        } else EmitToast.emitErrors(response)
        this.loading = false
      } catch (e) {
        this.loading = false
        console.error(e)
      }
    }
  }
}
