import KeybeRepository from '@/shared/infrastructure/repositories/KeybeRepository'
import { GenericResponse } from '@/shared/infrastructure/http/GenericResponse'
import { HeadersBuilder } from '@/shared/infrastructure/http/HeadersBuilder'
import { getOptions, nonGetOptions } from '@/shared/infrastructure/http/HttpClient'
import { dataToDeleteDomain, dataToSetDomain, dataToGetExtendedFields, dataToGetAppFields, dataToHideAppField, dataToGetApps, dataToSelectApp, dataToUpdateApp, dataToCreateAppByCompany, dataToVerifiedDomain, dataToCheckDomain, dataToGetApp } from '../../domain/AppsController'

export default class AppsRepository extends KeybeRepository {
  getSuggestionsAbortController: AbortController

  constructor () {
    super()

    this.getSuggestionsAbortController = new AbortController()
  }

  abortGetSuggestions () {
    this.getSuggestionsAbortController.abort()
    this.getSuggestionsAbortController = new AbortController()
  }

  async getExtendedFields (data: dataToGetExtendedFields): Promise<GenericResponse> {
    const { module, category, typeEntity } = data

    const url = `${process.env.VUE_APP_APPS_URL}/extended-fields/${module}/${typeEntity}/group-by-categories/${category}`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    delete data.token
    const requestOptions: getOptions = {
      headers,
      url,
      abortController: this.getSuggestionsAbortController
    }

    return await this.client.get(requestOptions)
  }

  async getAppFields (data: dataToGetAppFields): Promise<GenericResponse> {
    const { app } = data

    const url = `${process.env.VUE_APP_APPS_URL}/${app}/fields`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    delete data.token
    const requestOptions: getOptions = {
      headers,
      url,
      abortController: this.getSuggestionsAbortController
    }

    return await this.client.get(requestOptions)
  }

  async hideField (data: dataToHideAppField): Promise<GenericResponse> {
    const { app, updateInput } = data

    const url = `${process.env.VUE_APP_APPS_URL}/${app}/fields`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    const requestOptions: nonGetOptions = {
      headers,
      data: updateInput,
      url,
      abortController: this.getSuggestionsAbortController
    }

    return await this.client.post(requestOptions)
  }

  async getCDPFields (data: dataToGetAppFields): Promise<GenericResponse> {
    const { app } = data

    const url = `${process.env.VUE_APP_APPS_URL}/${app}/fields-crm`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    delete data.token
    const requestOptions: getOptions = {
      headers,
      url,
      abortController: this.getSuggestionsAbortController
    }

    return await this.client.get(requestOptions)
  }

  async getApps (data: dataToGetApps): Promise<GenericResponse> {
    const { offset, limit, search } = data
    const url = `${process.env.VUE_APP_APPS_URL}/apps`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    const query = {
      offset,
      limit,
      search
    }
    const requestOptions: getOptions = {
      headers,
      url,
      query,
      abortController: this.getSuggestionsAbortController
    }

    return await this.client.get(requestOptions)
  }

  async getApp (data: dataToSelectApp): Promise<GenericResponse> {
    const { uuid } = data
    const url = `${process.env.VUE_APP_APPS_URL}/apps/${uuid}`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    const requestOptions: getOptions = {
      headers,
      url,
      abortController: this.getSuggestionsAbortController
    }

    return await this.client.get(requestOptions)
  }

  async setDomain (data: dataToSetDomain): Promise<GenericResponse> {
    const { uuid } = data
    const domain = { domain: data.domain }
    const url = `${process.env.VUE_APP_APPS_URL}/apps/${uuid}/domain`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    const response = await fetch(url, {
      method: 'PATCH',
      body: JSON.stringify(domain),
      headers: headers || undefined,
      signal: this.getSuggestionsAbortController?.signal
    })
    const parsedResponse = await response.json()
    return parsedResponse
  }

  async deleteDomain (data: dataToDeleteDomain): Promise<GenericResponse> {
    const { uuid } = data
    const domain = { domain: data.domain }
    const url = `${process.env.VUE_APP_APPS_URL}/apps/${uuid}/domain`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    const requestOptions: nonGetOptions = {
      headers,
      data: { domain: domain },
      url,
      abortController: this.getSuggestionsAbortController
    }

    return await this.client.delete(requestOptions)
  }

  async verifiedDomain (data: dataToVerifiedDomain): Promise<GenericResponse> {
    const { uuid, domain } = data
    const url = `${process.env.VUE_APP_API_URL}/apigw/campaigns/v1/settings/verify-domain`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    const requestOptions: nonGetOptions = {
      headers,
      data: {
        appUUID: uuid,
        domain: domain
      },
      url,
      abortController: this.getSuggestionsAbortController
    }

    return await this.client.post(requestOptions)
  }

  async checkDomain (data: dataToCheckDomain): Promise<GenericResponse> {
    const { uuid, domain } = data
    const url = `${process.env.VUE_APP_API_URL}/apigw/campaigns/v1/settings/check-domain`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    const requestOptions: nonGetOptions = {
      headers,
      data: {
        appUUID: uuid,
        domain: domain
      },
      url,
      abortController: this.getSuggestionsAbortController
    }

    return await this.client.post(requestOptions)
  }

  async getCompany (data: dataToSelectApp): Promise<GenericResponse> {
    const url = `${process.env.VUE_APP_APPS_URL}/apps/find-by-company`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    const requestOptions: getOptions = {
      headers,
      url,
      abortController: this.getSuggestionsAbortController
    }

    return await this.client.get(requestOptions)
  }

  async updateAppInfo (data: dataToUpdateApp): Promise<GenericResponse> {
    const { app } = data

    const url = `${process.env.VUE_APP_APPS_URL}/apps/${app?.uuid}`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    // const requestOptions: nonGetOptions = {
    //   headers,
    //   data: app,
    //   url,
    //   abortController: this.getSuggestionsAbortController
    // }

    const response = await fetch(url, {
      method: 'PATCH',
      body: JSON.stringify(app),
      headers: headers || undefined,
      signal: this.getSuggestionsAbortController?.signal
    })
    const parsedResponse = await response.json()
    return parsedResponse
  }

  async createAppByCompany (data: dataToCreateAppByCompany): Promise<GenericResponse> {
    const { createInput } = data
    const url = `${process.env.VUE_APP_APPS_URL}/wizard-by-company`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    const requestOptions: nonGetOptions = {
      headers,
      data: createInput,
      url,
      abortController: this.getSuggestionsAbortController
    }
    return await this.client.post(requestOptions)
  }

  async getCategories (): Promise<GenericResponse> {
    const url = `${process.env.VUE_APP_APPS_URL}/extended-fields/cdp/categories`
    const headers = {
      'content-type': 'application/json',
      Accept: 'application/json'
    }
    const requestOptions: getOptions = {
      headers,
      url,
      abortController: this.getSuggestionsAbortController
    }

    return await this.client.get(requestOptions)
  }

  async appHasFlows1 (data: dataToGetApp): Promise<GenericResponse> {
    const url = `${process.env.VUE_APP_MAKE_URL}/find-keybe-client`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    const requestOptions: getOptions = {
      headers,
      url,
      query: {
        appUUID: data.uuid
      },
      abortController: this.getSuggestionsAbortController
    }

    return await this.client.get(requestOptions)
  }
}
