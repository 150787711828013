import KeybeButton from '@/modules/DesignSystem/infrastructure/components/KeybeButton/KeybeButton.vue'
import KBSwitch from '@/shared/infrastructure/ui/inputs/select/KBSwitch.vue'
import KeybeText from '@/modules/DesignSystem/infrastructure/components/KeybeText/KeybeText.vue'
import KeybeTextarea from '@/modules/DesignSystem/infrastructure/components/KeybeTextarea/KeybeTextarea.vue'
import KBMultipleText from '@/shared/infrastructure/ui/inputs/select/KBMultipleText/KBMultipleText.vue'
import FacebookService from '@/components/configuration/Services/FacebookService'
import FacebookProvider from '@/components/configuration/Providers/FacebookProvider'
import { EventBus } from '@/eventBus'
import ChannelsUseCases from '@/modules/Config/Channels/shared/application/ChannelsUseCases'
import ChannelsController from '@/modules/Config/Channels/shared/infrastructure/controllers/Channels.controller'
import { channelDisconnect, dataToDisconnectChannel, dataToUpdateWhatsAppBusinessProfile, dataToUpdateWhatsAppBusinessProfilePhoto, whatsappBusinessProfile } from '@/modules/Config/Channels/shared/domain/ChannelsController'
import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import TemplateUseCases from '@/modules/templates/application/TemplateUseCases'
import TemplateController from '@/modules/templates/infrastructure/Template.controller'
import { mapState } from 'vuex'
import { ChannelsEnum } from '@/modules/Config/Channels/shared/domain/ChannelsEnum'
import EmitToast from '@/utils/EmitToast'
import KeybeSelect from '@/modules/DesignSystem/infrastructure/components/KeybeSelect/KeybeSelect/KeybeSelect.vue'
import { CDPFieldsEnums } from '@/modules/CDP/shared/contacts/domain/enums/CDPFieldsEnums'
import { VerticalWhatsAppEnum } from '@/modules/Config/Channels/shared/domain/WhatsAppBusiness'
import DisconnectWarning from '../DisconnectWarning/DisconnectWarning.vue'
import KBButtonLoader from '@/shared/infrastructure/ui/progress/KBButtonLoader/KBButtonLoader.vue'
export default {
  components: {
    KeybeButton,
    KBSwitch,
    KeybeText,
    KeybeTextarea,
    KBMultipleText,
    KeybeSelect,
    DisconnectWarning,
    KBButtonLoader
  },
  props: {
    config: {
      type: Object
    }
  },
  computed: {
    ...mapState('AppStore', ['selectedApp']),
    ...mapState('UserStore', ['token']),
    phoneId (): string {
      return this.config?.config?.phoneId || ''
    },
    tier (): string {
      return this.config?.config?.messaging_limit_tier?.replace(/_/g, ' ') || this.$t('noTier')
    },
    brandTypes (): { text: string, value: string }[] {
      return [
        { text: this.$t(VerticalWhatsAppEnum.AUTO), value: VerticalWhatsAppEnum.AUTO },
        { text: this.$t(VerticalWhatsAppEnum.BEAUTY), value: VerticalWhatsAppEnum.BEAUTY },
        { text: this.$t(VerticalWhatsAppEnum.APPAREL), value: VerticalWhatsAppEnum.APPAREL },
        { text: this.$t(VerticalWhatsAppEnum.EDU), value: VerticalWhatsAppEnum.EDU },
        { text: this.$t(VerticalWhatsAppEnum.ENTERTAIN), value: VerticalWhatsAppEnum.ENTERTAIN },
        { text: this.$t(VerticalWhatsAppEnum.EVENT_PLAN), value: VerticalWhatsAppEnum.EVENT_PLAN },
        { text: this.$t(VerticalWhatsAppEnum.FINANCE), value: VerticalWhatsAppEnum.FINANCE },
        { text: this.$t(VerticalWhatsAppEnum.GROCERY), value: VerticalWhatsAppEnum.GROCERY },
        { text: this.$t(VerticalWhatsAppEnum.GOVT), value: VerticalWhatsAppEnum.GOVT },
        { text: this.$t(VerticalWhatsAppEnum.HOTEL), value: VerticalWhatsAppEnum.HOTEL },
        { text: this.$t(VerticalWhatsAppEnum.HEALTH), value: VerticalWhatsAppEnum.HEALTH },
        { text: this.$t(VerticalWhatsAppEnum.NONPROFIT), value: VerticalWhatsAppEnum.NONPROFIT },
        { text: this.$t(VerticalWhatsAppEnum.PROF_SERVICES), value: VerticalWhatsAppEnum.PROF_SERVICES },
        { text: this.$t(VerticalWhatsAppEnum.RETAIL), value: VerticalWhatsAppEnum.RETAIL },
        { text: this.$t(VerticalWhatsAppEnum.TRAVEL), value: VerticalWhatsAppEnum.TRAVEL },
        { text: this.$t(VerticalWhatsAppEnum.RESTAURANT), value: VerticalWhatsAppEnum.RESTAURANT },
        { text: this.$t(VerticalWhatsAppEnum.OTHER), value: VerticalWhatsAppEnum.OTHER }
      ]
    }
  },
  async mounted () {
    await FacebookProvider.setBusySDK(false)
  },
  data () {
    return {
      whatsappAppId: process.env.VUE_APP_WHATSAPP_APP_ID,
      channelsController: new ChannelsController(),
      templateController: new TemplateController(),
      data: {
        profile_picture_url: '',
        vertical: '',
        description: '',
        address: '',
        email: '',
        websites: [],
        about: ''
      },
      loading: false,
      changed: false,
      loadingSave: false,
      loadingSaveImage: false,
      showErrorMessage: false,
      showDisconnectWarning: false,
      loadingDisconnect: false,
      KeybeIconType,
      CDPFieldsEnums
    }
  },
  methods: {
    async initFacebook (): Promise<void> {
      try {
        if (!FacebookService.existsSDK()) {
          await FacebookService.injectSDK()
        }
        FacebookProvider.setBusySDK(true)
        await FacebookService.initializeSDK(this.whatsappAppId)
        this.getWhatsAppBusinessProfile()
      } catch (error) {
        this.loadingSDK = false
        EventBus.$emit('toast', 'error', this.$t('errorLoadingFacebookSDK'))
      }
    },
    async getWhatsAppBusinessProfile (): Promise<void> {
      this.loading = true
      const response: whatsappBusinessProfile = await ChannelsUseCases.getWhatsAppBusinessProfile(this.channelsController, {
        phoneNumberId: this.phoneId,
        fields: 'about,address,description,email,profile_picture_url,websites,vertical',
        token: process.env.VUE_APP_META_ACCESS_TOKEN
      })
      if (response.status) {
        this.data = response.data
      }
      FacebookProvider.setBusySDK(false)
      this.loading = false
    },
    async refreshTemplates () {
      await TemplateUseCases.getTemplates(this.templateController, {
        userHost: this.config?.userHost,
        forceRefresh: 1,
        token: this.token,
        appId: this.selectedApp?.uuid
      })
      EventBus.$emit('toast', 'success', this.$t('templatesRefreshed'))
    },
    async disconnectWhatsapp (): Promise<void> {
      this.loadingDisconnect = true
      const id = this.config.config?._id
      const data: dataToDisconnectChannel = {
        appUUID: this.selectedApp?.uuid,
        token: this.token,
        type: ChannelsEnum.WHATSAPP,
        id: id
      }
      const response: channelDisconnect = await ChannelsUseCases.disconnectChannel(this.channelsController, data)
      if (!response.status) EmitToast.emitErrors(response.message)
      else {
        this.$emit('refresh')
      }
      this.loadingDisconnect = false
    },
    async saveChanges (): Promise<void> {
      this.loadingSave = true
      let formattedWebsites = []
      if (this.data?.websites && this.data?.websites?.length > 0) {
        formattedWebsites = this.data.websites.map(website => {
          if (!website.includes('http://') && !website.includes('https://')) return `https://${website}`
          else return website
        })
      }
      const data: dataToUpdateWhatsAppBusinessProfile = {
        updateInput: {
          about: this.data.about,
          address: this.data.address,
          description: this.data.description,
          vertical: this.data.vertical,
          email: this.data.email,
          websites: formattedWebsites
        },
        userHost: this.config?.userHost,
        appId: this.selectedApp?.uuid,
        token: this.token
      }

      const response = await ChannelsUseCases.updateWhatsAppBusinessProfile(this.channelsController, data)
      if (response.status) {
        EmitToast.emitSuccess(this.$t('profileUpdated'))
        this.getWhatsAppBusinessProfile()
      } else {
        EmitToast.emitErrors(response.message)
      }
      this.loadingSave = false
    },
    async saveProfilePicture (event): Promise<void> {
      const image: File = event?.target?.files[0] || ''
      if (!image) return
      this.loadingSaveImage = true
      const data: dataToUpdateWhatsAppBusinessProfilePhoto = {
        img: image,
        userHost: this.config?.userHost,
        appId: this.selectedApp?.uuid,
        token: this.token
      }
      const response = await ChannelsUseCases.updateWhatsAppBusinessProfilePhoto(this.channelsController, data)
      if (response.status) {
        EmitToast.emitSuccess(this.$t('profilePictureUpdated'))
        this.getWhatsAppBusinessProfile()
      } else {
        EmitToast.emitErrors(response.message)
      }
      this.loadingSaveImage = false
    },
    openFileInput (): void {
      const inputElement = document.getElementById('imageProfile')
      if (inputElement) {
        inputElement.click()
      }
    }
  },
  watch: {
    phoneId: {
      immediate: true,
      handler () {
        if (this.phoneId) {
          this.showErrorMessage = false
          this.initFacebook()
        } else {
          this.showErrorMessage = true
        }
      }
    }
  }
}
