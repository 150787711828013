<template>
  <svg :width="width"
       :height="height"
       viewBox="0 0 13 14"
       fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <path d="M6.125 7C5.19674 7 4.3065 6.63125 3.65013 5.97487C2.99375 5.3185 2.625 4.42826 2.625 3.5C2.625 2.57174 2.99375 1.6815 3.65013 1.02513C4.3065 0.368749 5.19674 0 6.125 0C7.05326 0 7.9435 0.368749 8.59987 1.02513C9.25625 1.6815 9.625 2.57174 9.625 3.5C9.625 4.42826 9.25625 5.3185 8.59987 5.97487C7.9435 6.63125 7.05326 7 6.125 7ZM5.71758 9.82188L5.20898 8.97422C5.03398 8.68164 5.24453 8.3125 5.58359 8.3125H6.125H6.66367C7.00273 8.3125 7.21328 8.68438 7.03828 8.97422L6.52969 9.82188L7.44297 13.2098L8.42734 9.19297C8.48203 8.97148 8.69531 8.82656 8.9168 8.88398C10.8336 9.36523 12.25 11.0988 12.25 13.1605C12.25 13.6254 11.8727 14 11.4105 14H7.80664C7.74922 14 7.69727 13.9891 7.64805 13.9699L7.65625 14H4.59375L4.60195 13.9699C4.55273 13.9891 4.49805 14 4.44336 14H0.839453C0.377344 14 0 13.6227 0 13.1605C0 11.0961 1.41914 9.3625 3.3332 8.88398C3.55469 8.8293 3.76797 8.97422 3.82266 9.19297L4.80703 13.2098L5.72031 9.82188H5.71758Z"
          :fill="color"/>
  </svg>

</template>
<script>
export default {
  name: 'PrimaryIcon',
  props: {
    width: {
      type: Number,
      default: 13
    },
    height: {
      type: Number,
      default: 14
    },
    color: {
      type: String,
      default: '#B6BEC7'
    }
  }
}
</script>
