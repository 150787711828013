
import MetricsService from '@/modules/metrics/application/services/MetricsService'
import moment from 'moment'
import ApexCharts from 'apexcharts'
import { EventBus } from '@/eventBus'
import { textCamelize } from '@/utils/utils'
import LoadingNext from '@/modules/metrics/infrastructure/ui/components/generic/LoadingNext.vue'
import DateRange from '@/modules/metrics/infrastructure/ui/components/generic/DateRange.vue'
import RadialBarCharts from '@/modules/metrics/infrastructure/ui/components/conversations/RadialBarCharts/RadialBarCharts.vue'
export default {
  name: 'FirstRespondeTime',
  props: [
    'range', 'dataComponent'
  ],
  components: {
    RadialBarCharts,
    DateRange,
    LoadingNext
  },
  data () {
    return {
      loadingTotalConversation: true,
      loadingNewDeals: true,
      loadingFirstTime: true,
      WaitingTime: [],
      series: [],
      chartMulti: null,
      chartSingle: null,
      msgServiceError: false,
      msgServiceErrorTwo: false
    }
  },
  watch: {
    range: {
      handler: function (newVal, oldVal) {
        if (this.range) {
          if ((newVal?.dateFrom && newVal?.dateTo) && (oldVal?.dateFrom && oldVal?.dateTo)) {
            if (newVal.dateFrom !== oldVal.dateFrom || newVal.dateTo !== oldVal.dateTo) {
              this.getFirstTime()
            }
          }
        }
      }
    },
    loadingTotalConversation () {
      if (!this.loadingTotalConversation) {
        this.checkLoading()
      }
    },
    loadingNewDeals () {
      if (!this.loadingNewDeals) {
        this.checkLoading()
      }
    },
    loadingFirstTime () {
      if (!this.loadingFirstTime) {
        this.checkLoading()
      }
    }
  },
  computed: {
    closeModal: {
      get () {
        return this.$store.state.MetricsStore.closeModal || false
      },
      set (value) {
        this.$store.commit('MetricsStore/SET_EDITING_OBJECT', {
          key: 'closeModal',
          value
        })
      }
    },
    loading: {
      get () {
        return this.$store.state.MetricsStore.loading || false
      },
      set (value) {
        this.$store.commit('MetricsStore/SET_EDITING_OBJECT', {
          key: 'loading',
          value
        })
      }
    }
  },
  methods: {
    checkLoading () {
      if (!this.loadingTotalConversation && !this.loadingNewDeals && !this.loadingFirstTime) {
        this.loading = false
      }
    },
    async getFirstTime () {
      try {
        this.loadingFirstTime = true
        this.msgServiceErrorTwo = false
        let params = {
          periodStart: moment().subtract(1, 'weeks').format('YYYY-MM-DD'),
          periodEnd: moment().format('YYYY-MM-DD')
        }
        if (this.range) {
          const { dateFrom, dateTo } = this.range
          params = {
            periodStart: dateFrom,
            periodEnd: dateTo
          }
        }
        const response = await MetricsService.getFirstTimeV6(params)
        if (response) {
          const data = response.data.range_first_answer_time
          const propertyNames = data.map(element => { return element.rangeFirstAnswer })
          const propertyValues = data.map(element => { return element.times_over_range })
          this.chartFirstTimeMethod(propertyNames, propertyValues)
          this.loadingFirstTime = false
        } else {
          this.loadingFirstTime = false
          if (response?.data?.error) {
            EventBus.$emit('toast', 'error', this.$t(textCamelize(response.data.error)))
          } else {
            EventBus.$emit('toast', 'error', this.$t('unexpectedError'))
          }
        }
      } catch (error) {
        this.loadingFirstTime = false
        this.msgServiceErrorTwo = true
      }
    },
    chartFirstTimeMethod (axis, item) {
      if (this.chartFirstTime) {
        this.chartFirstTime.destroy()
      }
      const options = {
        series: [{
          data: item
        }],
        chart: {
          type: 'bar',
          height: 350,
          toolbar: {
            show: false
          },
          zoom: {
            enabled: false
          }
        },
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 5,
            borderRadiusOnAllStackedSeries: true
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['white']
        },
        xaxis: {
          categories: axis
        },
        fill: {
          opacity: 1
        },
        colors: ['#3AF69B'],
        tooltip: {
          enabled: true,
          enabledOnSeries: undefined,
          shared: true,
          followCursor: false,
          intersect: false,
          inverseOrder: false,
          custom: undefined,
          fillSeriesColor: true,
          theme: false,
          style: {
            fontSize: '12px',
            fontFamily: undefined
          },
          y: {
            formatter: undefined,
            title: {
              formatter: (seriesName) => ''
            }
          },
          onDatasetHover: {
            highlightDataSeries: false
          }
        }
      }

      this.chartFirstTime = new ApexCharts(document.querySelector('#FirstTimeGraph'), options)
      this.chartFirstTime.render()
    }
  },
  mounted () {
    this.getFirstTime()
  }
}
