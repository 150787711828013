<template>
  <svg width="17"
       height="16"
       viewBox="0 0 17 16"
       fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <path d="M15.8895 11.5425C15.8895 12.5273 15.4744 13.4208 14.8027 14.0656C14.1326 14.712 13.2042 15.1114 12.1808 15.1114C10.1322 15.1114 8.47208 13.5138 8.47208 11.5425C8.47208 10.5576 8.05704 9.66416 7.38531 9.01935C6.71524 8.37294 5.78682 7.97354 4.76338 7.97354C2.71485 7.97354 1.05469 6.37594 1.05469 4.40462C1.05469 3.41975 1.46973 2.52632 2.14146 1.88151C2.81153 1.23509 3.73995 0.835693 4.76338 0.835693C5.78682 0.835693 6.71524 1.23509 7.38531 1.88151C8.05704 2.52632 8.47208 3.41975 8.47208 4.40462C8.47208 6.37594 10.1322 7.97354 12.1808 7.97354C13.2042 7.97354 14.1326 8.37294 14.8027 9.01935C15.4744 9.66416 15.8895 10.5576 15.8895 11.5425Z"
          fill="#01CFE4"/>
    <path d="M10.6691 6.092C9.94569 5.39585 9.58232 4.48157 9.58399 3.56889C9.58399 2.65621 9.94569 1.74192 10.6691 1.04578C12.1176 -0.348105 14.4661 -0.348105 15.9146 1.04578C16.638 1.74192 17.0014 2.65621 16.9997 3.56889C16.9997 4.48157 16.638 5.39585 15.9146 6.092C15.1912 6.78814 14.2411 7.13781 13.2927 7.13621C12.3443 7.13621 11.3942 6.78814 10.6708 6.092H10.6691Z"
          fill="#FFCC00"/>
    <path d="M1.08511 14.9542C0.361702 14.258 0 13.3438 0 12.4311C0 11.5184 0.361702 10.6041 1.08511 9.90796C2.53358 8.51408 4.88214 8.51408 6.33062 9.90796C7.05402 10.6041 7.41739 11.5184 7.41573 12.4311C7.41573 13.3438 7.05402 14.258 6.33062 14.9542C5.60722 15.6503 4.65712 16 3.7087 15.9984C2.76027 15.9984 1.81018 15.6503 1.08677 14.9542H1.08511Z"
          fill="#F7685B"/>
  </svg>

</template>

<script>
export default {
  name: 'BellIcon',
  props: {
    width: {
      type: Number,
      default: 16
    },
    height: {
      type: Number,
      default: 18
    },
    color: {
      type: String,
      default: '#B6BEC7'
    }
  }

}
</script>

<style>

</style>
