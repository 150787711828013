import KBButton from '@/shared/infrastructure/ui/buttons/KBButton.vue'
import LottieAnimation from 'lottie-web-vue'
import { billCampaings } from '@/modules/outbound/infrastructure/ui/components/methods/billCampaings'
import { EventBus } from '@/eventBus'
import KBSelect from '@/shared/infrastructure/ui/inputs/select/KBSelect.vue'
import CampaignsService from '@/services/CampaignsService'
import Sending from '@/modules/outbound/infrastructure/ui/components/Detail/Sending/Sending.vue'
import Send from '@/modules/outbound/infrastructure/ui/components/Detail/Send/Send.vue'
import ConversationsConfigService from '@/components/configuration/Services/ConversationsConfigService'
import { DateTime } from 'luxon'
import { ActivityActionTypeEnum, ActivityLogModuleTypeEnum, dataToWriteActivityLog } from '@/modules/Config/ActivityLogs/domain/ActivityLogsController'
import ActivityLogsUseCases from '@/modules/Config/ActivityLogs/application/ActivityLogsUseCases'
import { CDPFieldsEnums } from '@/modules/CDP/shared/contacts/domain/enums/CDPFieldsEnums'
import ActivityLogsController from '@/modules/Config/ActivityLogs/infrastructure/controllers/ActivityLogs.controller'
import { mapState } from 'vuex'

export default {
  name: 'ReadytoSend',
  props: {
    campaingsId: {
      type: String,
      required: true
    },
    userHost: {
      type: String,
      required: true,
      default: ''
    }
  },
  components: {
    Send,
    Sending,
    KBButton,
    LottieAnimation,
    KBSelect
  },
  computed: {
    ...mapState('AppStore', ['selectedApp']),
    ...mapState('UserStore', ['token', 'user']),
    outboundName: {
      get () {
        return this.$store.state.CampaingsStore.outboundName || null
      },
      set (value) {
        this.$store.commit('CampaingsStore/SET_EDITING_OBJECT', {
          key: 'outboundName',
          value
        })
      }
    },
    currentSelectedTab: {
      get () {
        return this.$store.state.CampaingsStore.currentSelectedTab || null
      },
      set (value) {
        this.$store.commit('CampaingsStore/SET_EDITING_OBJECT', {
          key: 'currentSelectedTab',
          value
        })
      }
    },
    summaryMenu: {
      get () {
        return this.$store.state.CampaingsStore.summaryMenu || false
      },
      set (value) {
        this.$store.commit('CampaingsStore/SET_EDITING_OBJECT', {
          key: 'summaryMenu',
          value
        })
      }
    },
    outboundStatus: {
      get () {
        return this.$store.state.CampaingsStore.outboundStatus || null
      },
      set (value) {
        this.$store.commit('CampaingsStore/SET_EDITING_OBJECT', {
          key: 'outboundStatus',
          value
        })
      }
    },
    summaryData: {
      get () {
        return this.$store.state.CampaingsStore.summaryData || null
      },
      set (value) {
        this.$store.commit('CampaingsStore/SET_EDITING_OBJECT', {
          key: 'summaryData',
          value
        })
      }
    },
    sendNowSuccess: {
      get () {
        return this.$store.state.CampaingsStore.sendNowSuccess || false
      },
      set (value) {
        this.$store.commit('CampaingsStore/SET_EDITING_OBJECT', {
          key: 'sendNowSuccess',
          value
        })
      }
    }
  },
  async mounted () {
    this.setValues()
    this.getYears()
    this.getDays()
    this.getHours()
    this.getMn()
    await this.getTier()
  },
  data () {
    return {
      activityLogsController: new ActivityLogsController(),
      progress: 0,
      schedule: false,
      scheduleDateSuccess: false,
      sendNowSuccessBool: 0,
      loading: false,
      porcentaje: 0,
      cardLoading: false,
      scheduleDate: '',
      showDateTimePicker: false,
      tier: [],
      tierNumber: '',
      tierLevel: '',
      formDate: {
        DD: null,
        MM: null,
        YY: null,
        HH: null,
        Mm: null

      },
      itemsDD: [],
      itemsMM: [
        1, 2, 3, 4, 5, 6,
        7, 8, 9, 10, 11, 12
      ],
      itemsYY: [],
      itemsHH: [],
      itemsMn: [],
      diffDateValue: null
    }
  },
  methods: {
    billCampaings,
    countSimulation () {
      this.progress = 0
      while (this.progress < 100) {
        setTimeout(() => {
          this.progress++
        }, 10)
      }
    },
    diffDate () {
      if (this.summaryData) {
        const fechaInicio = new Date().getTime()
        if (this.summaryData?.schedule) {
          const { nextRunAt } = this.summaryData?.schedule
          const fechaFin = new Date(nextRunAt).getTime()
          const diff = fechaFin - fechaInicio
          this.diffDateValue = diff / (1000 * 60 * 60 * 24)
        }
        this.diffDateValue = 0
      }
    },
    async cancelShipment () {
      try {
        const response = await CampaignsService.cancelCampaign(this.summaryData.id ? this.summaryData.id : this.summaryData._id)
        if (response) {
          this.goToDetail(response.data?.displayCampaign)
        }
      } catch (e) {
        console.log(e)
      }
    },
    goToDetail (item) {
      this.currentSelectedTab = 'summary'
      this.summaryMenu = true
      this.summaryData = item
      this.outboundStatus = item.status
      this.outboundName = item.name
    },
    returnSummary () {
      this.formDate = {
        DD: null,
        MM: null,
        YY: null,
        HH: null,
        Mm: null

      }
      this.showDateTimePicker = false
    },
    setValues () {
      this.sendNowSuccess = false
      const date = new Date()
      this.formDate.DD = date.getDate() + 1
      this.formDate.MM = (date.getMonth() + 1) < 10 ? '0' + +(date.getMonth() + 1) : date.getMonth() + 1
      this.formDate.YY = date.getFullYear()
      this.formDate.HH = '12'
      this.formDate.Mm = '30'
    },
    getYears () {
      const currentYear = new Date().getFullYear()
      let startYear = currentYear
      const stopYear = currentYear + 10
      while (startYear <= stopYear) {
        this.itemsYY.push(startYear++)
      }
    },
    getHours () {
      const HH = 24
      let startHH = 0
      while (this.itemsHH.length < HH) {
        const insert = (startHH++).toString()
        this.itemsHH.push(insert)
      }
    },
    getMn () {
      const Mn = 60
      let startMn = 0
      while (this.itemsMn.length < Mn) {
        const insert = (startMn++).toString()
        this.itemsMn.push(insert)
      }
    },
    getDays () {
      const year = +this.formDate.YY || new Date().getFullYear()
      const month = this.formDate.MM ? +this.formDate.MM : 2
      const days = new Date(year, month, 0).getDate()
      let startDay = 1
      while (this.itemsDD.length < days) {
        this.itemsDD.push(startDay++)
      }
    },
    validateDateTime (date) {
      const { DD, MM, YY, HH, Mm } = date
      if (!DD) {
        EventBus.$emit('toast', 'error', this.$t('allParametersMustBeFilled') + ` (${this.$t('day')})`)
        return false
      }
      if (!MM) {
        EventBus.$emit('toast', 'error', this.$t('allParametersMustBeFilled') + ` (${this.$t('month')})`)
        return false
      }
      if (!YY) {
        EventBus.$emit('toast', 'error', this.$t('allParametersMustBeFilled') + ` (${this.$t('card_car_year')})`)
        return false
      }
      if (!HH) {
        EventBus.$emit('toast', 'error', this.$t('allParametersMustBeFilled') + ` (${this.$t('hour')})`)
        return false
      }
      if (!Mm) {
        EventBus.$emit('toast', 'error', this.$t('allParametersMustBeFilled') + ` (${this.$t('minutes')})`)
        return false
      }
      return true
    },
    validateFormat (item) {
      let result = '00'
      item.length === 1 ? result = `0${item}` : result = item
      return result
    },
    async callBillCampaings () {
      if (this.showDateTimePicker) {
        const response = this.validateDateTime(this.formDate)
        if (!response) {
          return
        } else {
          const { DD, MM, YY, HH, Mm } = this.formDate
          this.scheduleDate = `${YY}-${this.validateFormat(MM.toString())}-${this.validateFormat(DD.toString())}T${this.validateFormat(HH.toString())}:${this.validateFormat(Mm.toString())}`
          const dateLuxon = DateTime.fromISO(this.scheduleDate).toUTC()
          this.scheduleDate = dateLuxon
          this.schedule = true
        }
      }
      try {
        this.loading = true
        if (this.summaryData.type === 'sms') {
          this.porcentaje = 0
          this.cardLoading = true
        }
        const scheduleDate = this.scheduleDate ? this.scheduleDate : this.summaryData.createdAt
        const result = await this.billCampaings(this.summaryData, scheduleDate, this.schedule)
        if (result) {
          const { billing, campaign } = result
          if (billing && billing.success) {
          } else {
            EventBus.$emit('toast', 'error', this.$t('noBalanceTitle'))
          }
          if (campaign) {
            if (this.scheduleDate) {
              EventBus.$emit('toast', 'success', this.$t('scheduledCampaign'))
              this.scheduleDateSuccess = true
              this.outboundStatus = 'scheduled'
              this.writeActivityLogSchedule()
            } else {
              EventBus.$emit('toast', 'success', this.$t('sendedCampaign'))
              this.sendNowSuccess = true
              this.sendNowSuccessBool = 1
              this.writeActivityLogSend()
              // this.countSimulation()
            }
            // this.getCampaign()
          } else {
            EventBus.$emit('toast', 'error', this.$t('campaignError'))
          }
        } else {
          EventBus.$emit('toast', 'error', this.$t('campaignError'))
        }
        this.cardLoading = false
        this.loading = false
      } catch (error) {
        EventBus.$emit('toast', 'error', this.$t('campaignError'))
        this.loading = false
        this.showDateTimePicker = false
        this.cardLoading = false
      }
    },
    async writeActivityLogSend (): Promise<void> {
      const data: dataToWriteActivityLog = {
        token: this.token,
        updateInput: {
          appUUID: this.selectedApp?.uuid,
          module: ActivityLogModuleTypeEnum.OUTBOUND,
          action: ActivityActionTypeEnum.SEND_CAMPAIGN,
          user: {
            id: this.user.uuid,
            name: this.user.name + ' ' + this.user.lastName
          },
          payload: {
            field: '',
            newValue: '',
            oldValue: ''
          }
        }
      }
      await ActivityLogsUseCases.writeActivityLog(this.activityLogsController, data)
    },
    async writeActivityLogSchedule (): Promise<void> {
      const data: dataToWriteActivityLog = {
        token: this.token,
        updateInput: {
          appUUID: this.selectedApp?.uuid,
          module: ActivityLogModuleTypeEnum.OUTBOUND,
          action: ActivityActionTypeEnum.SCHEDULE_CAMPAIGN,
          user: {
            id: this.user.uuid,
            name: this.user.name + ' ' + this.user.lastName
          },
          payload: {
            field: CDPFieldsEnums.SCHEDULE_DATE,
            newValue: this.scheduleDate,
            oldValue: ''
          }
        }
      }
      await ActivityLogsUseCases.writeActivityLog(this.activityLogsController, data)
    },
    async getTier () {
      const response = await ConversationsConfigService.getChannels()

      if (response && response.data && response.data.data && response.data.data.whatsapp) {
        const whatsappData = response.data.data.whatsapp

        const foundAccount = whatsappData.accounts.find(account => account.userHost === this.userHost)

        if (foundAccount) {
          this.tier = foundAccount.config.messaging_limit_tier
          this.tierLevel = this.tier.split('_')[1]
          this.tierNumber = this.tier.match(/\d+/)[0]
        }
      }
    }
  }
}
