




























import BikyConfigChat from './BikyConfigChat'

export default BikyConfigChat
