import store from '@/store'

export default class AppStoreController {
  static getSelectedApp () {
    return store.getters['AppStore/getSelectedApp']
  }

  static getSelectedAppUUID () {
    return store.getters['AppStore/getSelectedApp']?.uuid || ''
  }

  static isKeybeApp () {
    return store.getters['AppStore/isKeybeApp']
  }

  // redirect route functions
  static setRedirectRoute (route: string) {
    store.dispatch('AppStore/setRedirectRoute', route)
  }

  static getRedirectRoute () {
    return store.getters['AppStore/getRedirectRoute']
  }
}
