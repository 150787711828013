<template>
  <svg width="21"
       height="16"
       fill="none"
       xmlns="http://www.w3.org/2000/svg"><path d="M2.482 5.98v1.086c0 .559.002 1.118-.002 1.677 0 .079-.004.184-.052.233-.451.464-.913.917-1.403 1.404-.008-.115-.017-.185-.017-.256 0-2.002.012-4.006-.006-6.008-.01-1.07.673-1.829 1.485-2.035.232-.058.477-.085.717-.085 4.64-.005 9.279-.004 13.918-.004h.351c-.25-.247-.455-.445-.653-.65-.242-.249-.373-.531-.226-.883A.713.713 0 0 1 17.69.145c.082.058.156.126.227.196C18.51.93 19.1 1.519 19.69 2.11c.436.439.44.82.009 1.255-.6.604-1.203 1.204-1.805 1.806-.19.19-.41.322-.688.298a.72.72 0 0 1-.565-1.093c.058-.099.134-.19.214-.274.184-.192.377-.377.601-.6H4.989c.035.701-.08 1.363-.597 1.89-.517.526-1.17.632-1.91.585v.002zM17.534 9.995V8.42c0-.384-.003-.768.003-1.152.001-.078.011-.18.06-.231.44-.464.89-.92 1.385-1.426.015.145.027.214.027.282 0 1.986.003 3.972 0 5.958-.003 1.292-.866 2.147-2.157 2.147H2.545c.249.255.453.457.65.667.214.23.356.487.243.816-.181.527-.791.699-1.216.298a60.894 60.894 0 0 1-2.035-2.035c-.27-.28-.239-.738.04-1.02.639-.647 1.28-1.292 1.928-1.931.291-.289.6-.348.91-.2.253.12.46.433.398.715-.043.2-.162.396-.29.562-.158.203-.359.371-.541.556-.01.01-.013.027-.028.063h12.414c-.061-1.698.79-2.52 2.517-2.496v.002z"
                                                :fill="color"/><path d="M7.021 8c-.004-1.642 1.346-3.014 2.985-2.997a3 3 0 0 1 2.987 2.977c.024 1.625-1.347 3.014-2.981 3.01A2.99 2.99 0 0 1 7.022 8z"
                                                                     :fill="color"/></svg>
</template>

<script>
export default {
  name: 'CycleMoneyIcon',
  props: {
    color: {
      type: String,
      default: '#B6BEC7'
    }
  }
}
</script>

<style>

</style>
