




























import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import Colors from '@/shared/domain/colors/Color'
import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'

export default {
  name: 'OnboardingHeader.vue',
  props: {
    title: {
      type: String,
      default: 'OnboardingHeader'
    },
    subtitle: {
      type: String,
      default: 'OnboardingHeader'
    },
    backArrow: {
      type: Boolean,
      default: false
    },
    omit: {
      type: Boolean,
      default: false
    }
  },
  components: {
    KeybeIcon
  },
  data () {
    return {
      KeybeIconType,
      Colors
    }
  }
}
