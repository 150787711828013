<template>
  <svg width="40"
       height="40"
       viewBox="0 0 40 40"
       fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <path d="M20 38.8769C30.4254 38.8769 38.8769 30.4254 38.8769 20C38.8769 9.57453 30.4254 1.12305 20 1.12305C9.57453 1.12305 1.12305 9.57453 1.12305 20C1.12305 30.4254 9.57453 38.8769 20 38.8769Z"
          fill="black"/>
    <path d="M22.7268 24.7621H17.2304L16.3499 27.3632H12.5942L17.9222 12.6372H22.0754L27.4033 27.3632H23.6073L22.7268 24.7621ZM19.9797 16.6017L18.176 21.9926H21.8058L19.9797 16.6017Z"
          fill="#3AF69B"/>
    <path d="M20 40C8.97125 40 0 31.0288 0 20C0 8.97125 8.97125 0 20 0C31.0288 0 40 8.97125 40 20C40 31.0288 31.0288 40 20 40ZM20 2.24618C10.2111 2.24618 2.24618 10.2111 2.24618 20C2.24618 29.7889 10.2111 37.7538 20 37.7538C29.7889 37.7538 37.7538 29.7889 37.7538 20C37.7538 10.2111 29.7889 2.24618 20 2.24618Z"
          fill="#3AF69B"/>
  </svg>

</template>

<script>
export default {
  name: 'AdminUserIcon',
  props: {
    color: {
      type: String,
      default: '#8898A9'
    }
  }
}
</script>

<style scoped>

</style>
