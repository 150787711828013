// Componets
import NotificationsCenterHeader from '@/modules/NotificationsCenter/infrastructure/ui/components/NotificationsCenterHeader/NotificationsCenterHeader.vue'
import NotificationsCenterCard from '@/modules/NotificationsCenter/infrastructure/ui/components/NotificationsCenterCard/NotificationsCenterCard.vue'
import KeybeListBoxLoader from '@/shared/infrastructure/ui/layout/KeybeListBoxLoader.vue'

import { NotificationsConstants } from '@/modules/NotificationsCenter/domain/Constants'
import NotificationController from '@/modules/NotificationsCenter/infrastructure/controllers/Notification.controller'
import NotificationsUseCases from '@/modules/NotificationsCenter/application/NotificationUseCases'
import {
  dataToGetAllNotifications,
  dataToGetNotifications,
  notificationGetter
} from '@/modules/NotificationsCenter/domain/NotificationController'
import { AudienceTypeEnum, Notification } from '@/modules/NotificationsCenter/domain/Notification'
import { mapGetters, mapState } from 'vuex'
import LottieAnimation from 'lottie-web-vue'

export default {
  components: {
    NotificationsCenterHeader,
    NotificationsCenterCard,
    LottieAnimation,
    KeybeListBoxLoader
  },
  data () {
    return {
      selectedFilter: NotificationsConstants.MY_NOTIFICATIONS,
      notificationsContainer: null,
      notificationController: new NotificationController(),
      loading: false,
      limit: 20,
      offset: 1,
      notifications: [],
      paginator: {}
    }
  },
  async mounted () {
    // if (!this.isSuperAdmin) {
    //   this.selectedFilter = NotificationsConstants.MY_NOTIFICATIONS
    // }

    this.loading = true
    await this.getNotifications()

    this.notificationsContainer = document.querySelector(
      '#NotificationsCenterContainer__cards'
    )

    if (this.notificationsContainer) {
      this.notificationsContainer.addEventListener(
        'scroll',
        this.onScroll.bind(this)
      )
    }
  },
  computed: {
    ...mapState('AppStore', ['selectedApp']),
    ...mapState('UserStore', ['token', 'user']),
    ...mapGetters('UserStore', ['isSuperAdmin'])
  },
  beforeDestroy () {
    if (this.notificationsContainer) {
      this.notificationsContainer.removeEventListener(
        'scroll',
        this.onScroll.bind(this)
      )
    }
    return NotificationsUseCases.abort(this.notificationController)
  },
  methods: {
    onScroll (): void {
      const canAskMoreNotifications: boolean =
        NotificationsUseCases.canAskMoreNotifications(
          this.notificationController,
          this.notifications,
          this.paginator,
          this.offset
        )

      if (
        this.notificationsContainer.scrollTop +
          this.notificationsContainer.clientHeight >=
          this.notificationsContainer.scrollHeight - 50 &&
        canAskMoreNotifications
      ) {
        return this.getNextNotifications()
      }
    },
    setSelectedFilter (filter: string) {
      this.selectedFilter = filter
      return this.resetNotifications()
    },
    resetNotifications () {
      this.offset = 1
      this.paginator = {}
      this.notifications = []
      NotificationsUseCases.abort(this.notificationController)
      this.loading = true
      return this.getNotifications()
    },
    markAsViewed (notification: Notification) {
      // eslint-disable-next-line no-undef
      const notificationCloned: Notification = structuredClone(notification)
      notificationCloned.viewed = true

      const notificationIndex: number = this.notifications.findIndex(
        (el: Notification) => el._id === notificationCloned._id
      )

      if (notificationIndex !== -1) {
        this.notifications.splice(notificationIndex, 1, notificationCloned)
      }
    },
    getNextNotifications () {
      this.offset++
      return this.getNotifications()
    },
    async getNotifications () {
      let notifications: notificationGetter
      if (this.selectedFilter === NotificationsConstants.ALL_NOTIFICATIONS) {
        const data: dataToGetAllNotifications = {
          token: this.token,
          limit: this.limit,
          offset: this.offset
        }
        notifications = await NotificationsUseCases.getAllNotifications(
          this.notificationController,
          data
        )
      } else {
        const audienceMap: { [key: string]: AudienceTypeEnum } = {
          [NotificationsConstants.MY_NOTIFICATIONS]: AudienceTypeEnum.USER,
          [NotificationsConstants.APP_NOTIFICATIONS]: AudienceTypeEnum.APP
        }

        const data: dataToGetNotifications = {
          token: this.token,
          limit: this.limit,
          offset: this.offset,
          app: this.selectedApp?.uuid,
          audience: audienceMap[this.selectedFilter] || AudienceTypeEnum.USER
        }

        if (this.selectedFilter === NotificationsConstants.MY_NOTIFICATIONS) {
          data.userId = this.user?.userId
        }

        notifications = await NotificationsUseCases.getNotifications(
          this.notificationController,
          data
        )
      }

      this.loading = false
      this.notifications = this.notifications.concat(notifications.data)
      this.paginator = notifications.paginator
    }
  }
}
