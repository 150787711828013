




import moment from 'moment'
export default {
  name: 'DateRange',
  props: ['range'],
  filters: {
    moment: function (date) {
      if (date) {
        return `${moment(date.dateFrom).format('MMMM DD')} - ${moment(date.dateTo).format('MMM DD, YYYY')}`
      }
    }
  }
}
