export default class AppUUIDsService {
  static appKeybeAi () {
    return '4xqa4b8khxrofef'
  }

  static appKBLive () {
    return '4xqaosvkamm5sm4'
  }

  static appKeybe () {
    return 'bisovsddqjmtl2w5k'
  }

  static appAguaBendita () {
    return '4xqaj4tkpcr37yr'
  }

  static appMaga () {
    return '4xqa5onkm3v6f3z'
  }

  static appPilatos () {
    return '4xqa5onkm3hvrnl'
  }

  static appFundacionElede () {
    return '4xqa6y0kqty8xrp'
  }

  static appMegaShopTv () {
    return '3nx5flie9ktnlmvvs'
  }

  static appAgrofacil () {
    return '4xqa32nkgrb7fhs'
  }

  static appMiEslabon () {
    return 'l6k7uekuslgua8'
  }

  static appXHobbies () {
    return '4xqagg4krl7vkd3'
  }

  static appParirCo () {
    return 'bxynm7zkusnmeuh'
  }

  static appGoRigoGo () {
    return '171xeml22l0h7ork9'
  }

  static appCosco () {
    return 'lyw1l2i5tr3d'
  }

  static appClinicaSomos () {
    return '5hxlg1l2hucfjv'
  }

  static appVeneersOfficial () {
    return 'cq0d9m1l362hr6l'
  }

  static appFarmaciasHidalgo () {
    return '5fwa91l3j1pg0r'
  }

  static appCityCar () {
    return '3ye8v1l38yk8q9'
  }

  static appBellezaIntegral () {
    return '171xemh07kww874t6'
  }

  static appTrendyShopBTA () {
    return '171xeml22l0if7nzm'
  }

  static appKualam () {
    return '1lqthi1l2gc4xik'
  }

  static appCeludMovil () {
    return '171xemcb9l1dlgqij'
  }

  static appRpoSportWear () {
    return '171xemapnl27l4wfx'
  }

  static appKbAcademy () {
    return '23k11l2tun8qp'
  }

  static appConectamos () {
    return '12vb2e1l3xao3c7'
  }

  static appMedellinStyle () {
    return 'bisovs8kwjtk8iyxi'
  }

  static appMedellinMusicWeek () {
    return 'bisovsg0fjsaptnk9'
  }

  static appBreakfast () {
    return '4xqap8nkkgzshvo'
  }

  static appSalonAmador () {
    return '1i651l3ng6'
  }

  static appb2W () {
    return '1gs8dk1l4hg9vvp'
  }

  static appComfamaCreo20 () {
    // "comfama creo 2.0"
    return 'elvour1l3lpcq5q'
  }

  static appAlionLoyalquo () {
    // "ALION - LOYALQUO"
    return '171xem2t0kvi9p294'
  }

  static appAlionMaestrosEnBici () {
    // "alion - maestros en bici"
    return 'f7srkw1l5qxkuby'
  }

  static appComfamaEmpleo () {
    // "Comfama Empleo"
    return '4xqa4y3kcm78g8c'
  }

  static appMaky () {
    return 'dkcj1l4t1oz18'
  }

  static appTestCaleb33 () {
    return '2s5g91l6fttai3'
  }

  static appLamusicaFM () {
    // lamusica.FM
    return '4xqabxukljo9gj8'
  }

  static appElectronicRepublik () {
    return '4qi11l89novbx'
  }

  static appRate () {
    return '1wugv1l8uquipb'
  }

  static appInduretros () {
    // Induretros
    return 'l6k7gn1kxetfcej'
  }

  static appTrendy () {
    // Trendy Shop
    return '171xeml22l0if7nzm'
  }

  static appMegaShop () {
    return '3nx5flie9ktnlmvvs'
  }

  static appLaBodegaEconomica () {
    return '1gxsk5t1l7v4jja3'
  }

  static appClickGreen () {
    return '5afcf1l3os6yjb'
  }

  static appComfamaPortalento () {
    return '2qq65461l8uh6li7'
  }

  static appSadri () {
    return '12k9cu1l3avsfyz'
  }

  static appLigaContraCancerRisaralda () {
    return '45kgkb1l9ps04yd'
  }

  static appLigaContraElCancer () {
    return '45kgkb1l9nnuzss'
  }

  static appVivell () {
    return '171xemnhskyswz7dq'
  }

  static appHakunaMovilidad () {
    return 'v4ql81l2sd2f64'
  }

  static appLikeU () {
    return '4xqadhlkq2od4ro'
  }

  static appCodigoAbierto () {
    return 'l6k76zmkwmg97lb'
  }

  static appPasosAlExito () {
    return '4xqaf8pkhmcjeqi'
  }

  static appLaIberica () {
    return '4xqag3ako96uzte'
  }

  static appLikeMe () {
    return '3nx5flie9ktul5bk7'
  }

  static appLikeUComercial () {
    return '330cp1l9g7l9g3'
  }

  static appLikeUSura1 () {
    return '95f21l4cunvok'
  }

  static appLikeUSura2 () {
    return '5wuw1l4ypigbz'
  }

  static appCutix () {
    return '171xem662l0l2tgd3'
  }

  static appICEBPS () {
    return '2htw71l4bsbw28'
  }

  static appYout () {
    return 'bxynonikyp3lr2t'
  }

  static appAquashield () {
    return '9tiiz71l62e5ptx'
  }

  static appAquashieldBogota () {
    return 'drh5571lb80ydqy'
  }

  static appRubinstein () {
    return '1g217h1l6oh8dvi'
  }

  static appGrupoEspumados () {
    return '14mhog1l3enzg31'
  }

  static appUdeM () {
    return '4xqan2skius6md3'
  }

  static appICAAbastible () {
    return 'h51l29giej7'
  }

  static appQualityGB () {
    return 'hkouq1dmktivel6e'
  }

  static appFinzi () {
    return '171xem4sgl1fi2ob6'
  }

  static appUbikeMoto () {
    return 'c90lel1l5slslj6'
  }

  static appRodriguezAsociados () {
    return '5e84c1l3p1xd3w'
  }

  static appEnyoi () {
    return 'xgfxd1la04semo'
  }

  static appVivance () {
    return '4xqaokbkhfbj8ox'
  }

  static appOralCenter () {
    return '4xqac7akppljcoa'
  }

  static appLulu () {
    return '1fhejf1lalb3314'
  }

  static appKeila () {
    return '10ul5581lalay5y1'
  }

  static appAleja () {
    return 'a88ogt1l9om1ddj'
  }

  static appDivenus () {
    return '1fhejf1lalb4hli'
  }

  static appBking () {
    return '45kgkb1l9omlvy2'
  }

  static appBoutuqie () {
    return '1fhejf1lald4xjc'
  }

  static appBarril () {
    return '1j0b2g1l74wldp6'
  }

  static appSanJose () {
    return '4xqa4avkdvtw9tg'
  }

  static extensionSanJose () {
    return '1gxsk5t1l7yz8fzc'
  }

  static Dahusa () {
    return 'b0ekej1l7xo6vpw'
  }

  static MandalaCases () {
    return '7rg6f41l6fnpmxs'
  }

  static MidOne () {
    return '3isy63e1l34jsvbw'
  }

  static CDPTest () {
    return '4uxcw1lehfm8rx'
  }

  static Acuarela () {
    return '302gscf1l9k1ear4'
  }

  static AlquileSuFinca () {
    return '1d43vo1l2pls3ss'
  }

  static SamSara () {
    return '1517bm1l658w4le'
  }

  static DGroupe () {
    return 'bxynk91l0u7omyw'
  }

  static Doblacero () {
    return 'l6k7gn1kxepaz3x'
  }

  static Servienvia () {
    return '5gjr1l8gofgg0'
  }

  static laMusica () {
    return '4xqabxukljo9gj8'
  }

  static juanferQunintero () {
    return 'bxyneul0wib64z'
  }

  static Parir () {
    return 'bxynm7zkusnmeuh'
  }

  static simettricaMedical () {
    return 'fyhpg1l5wwl5xo'
  }

  static bellaLab () {
    return '171xemmafkwb4tky0'
  }

  static bmart () {
    return '2ura5651ldcakk3b'
  }

  static cleanPro () {
    return '171xem4bxkziwhl2f'
  }

  static hortiOrganic () {
    return '352jc1l2gzadxz'
  }

  static industriasEstra () {
    return '171xem7pikx54q1eg'
  }

  static motoMellos () {
    return 'p2ymb1lci7vmql'
  }

  static teva () {
    return '45kgkb1l9q0p3vt'
  }

  static casalab () {
    return '1az01l35t1nf0'
  }

  static motoCampo () {
    return 'a88ogt1l9iiuohr'
  }

  static donChurro () {
    return 'd3p0p1l4iufloz'
  }

  static benditoFrizz () {
    return '1losbp1l96eqq39'
  }

  static bubbleSkyGlamping () {
    return 'a88ogt1l9ip5th3'
  }

  static hopco () {
    return 'z63i1lg0z827e'
  }

  static repeat () {
    return 'wg3mf1l2tsc8cr'
  }

  static acrecer () {
    return '10ul5581la8ngp1i'
  }

  static appAuteco () {
    return '18u1l30bzqql'
  }

  static cicloc () {
    return 'cdsg5o1l2rlcncz'
  }

  static appInexmoda () {
    return 'bisovsjfsjn2bifil'
  }

  static appAutolarte () {
    return '4xqalilk7bzu6iv'
  }

  static appSoloChevrolet () {
    return '4xqajdwkagu2lym'
  }

  static appNutribara () {
    return '4xqa8a1kbidgu6w'
  }

  static appAutozen () {
    return '4xqamjkkc3uzlai'
  }

  static appAlemautosMedellin () {
    return '4xqacfekcajq8jj'
  }

  static appInvesa () {
    return '4xqaolxkcotusr2'
  }

  static appTotalCar () {
    return '4xqackpke3e4jlf'
  }

  static appAutolarteLidera () {
    return '4xqackpke3ebysc'
  }

  static appAlvaroCorrea () {
    return '4xqa32nkgrextql'
  }

  static appReindustrias () {
    return '4xqaokbkhgtrwiq'
  }

  static appGetincloud () {
    return '4xqaokbkhguqjdf'
  }

  static appMoevo () {
    return '4xqap8nkkdw8mn4'
  }

  static appDeportivoI () {
    return '4xqa5n2kmc2t67o'
  }

  static appCEUTecuida () {
    return '4xqakq7kl8gv1y4'
  }

  static appIpCom () {
    return '4xqakq7kl8kix5f'
  }

  static appArus () {
    return '4xqa5n2kmao0qbp'
  }

  static appAquaterra () {
    return '4xqa5n2kmc8huet'
  }

  static appHomeCapital () {
    return '4xqahutkmglbjxo'
  }

  static appLujosdetrapiche () {
    return '4xqaf6iknkf9joz'
  }

  static appBosi () {
    return '4xqajp6knui0s7q'
  }

  static appLamour () {
    return '4xqa957ko38bcpg'
  }

  static appConversionesg12 () {
    return '4xqa2kzkoa7s08d'
  }

  static appHenkel () {
    return '4xqa2kzkoa7zp5q'
  }

  static appHenkelACC () {
    return '4xqa2kzkoa858kk'
  }

  static appMesadeservicios () {
    return '4xqa40okpyq3rju'
  }

  static appCasaToro () {
    return '4xqanekqgxpqft'
  }

  static appInalde () {
    return '4xqa6y0kqi4k2td'
  }

  static appBive () {
    return '4xqa6y0kqi4q4e3'
  }

  static appSuplemania () {
    return '4xqa6y0kqjkkeb2'
  }

  static appAlemautosVC () {
    return '4xqai83kr6cs993'
  }

  static appIpcomdemo () {
    return '4xqa9nukrw71iqt'
  }

  static appPromotec () {
    return 'etxyk2ccks0em884'
  }

  static appMallasMetalicas () {
    return '3nx5flglfks7ox5nl'
  }

  static appAlumni () {
    return 'hkouq1dmktj94p6p'
  }

  static appAlumniConexion () {
    return '3nx5flb4hktj95r10'
  }

  static appEnfocadosInalde () {
    return '3nx5flb4hktj97m0f'
  }

  static appEdimeInalde () {
    return 'etxykce1ktj9835l'
  }

  static appEdime () {
    return 'hkouq1dmktj98j2r'
  }

  static appEuroSupermercados () {
    return 'hkouqb0hksjdttti'
  }

  static appFirForAll () {
    return 'etxykuqkskm9n54'
  }

  static appTecnologicoAntioquia () {
    return 'hkouq8qgkt4fbnu8'
  }

  static appCedit () {
    return 'hkouqfuoku338v5r'
  }

  static alumniEdime () {
    return 'bxynm7zkusp1ht4'
  }

  static admisionesMBA () {
    return 'l6k7uekusp2mtf'
  }

  static appSuseguridad () {
    return '171xeminukuygsm0u'
  }

  static appFelafayette () {
    return 'bxynm7zkuyrnmqb'
  }

  static appServitractor () {
    return 'l6k7uekv41sbwx'
  }

  static appAutozenMDE () {
    return 'bxynf6akya7p0ax'
  }

  static appDermalife () {
    return 'bxynonikynd7d7o'
  }

  static appTaxindividual () {
    return 'bxyne2ql1b85k4g'
  }

  static appFullmoto () {
    return '171xem4sgl1fi55l4'
  }

  static appEsicMedellin () {
    return 'l6k75d5l1nwfw8v'
  }

  static appSixFlags () {
    return 'l6k7hoxl1wjaw4o'
  }

  static appPlenamente () {
    return '5d8d1l2ewjujr'
  }

  static appCasaToroVW () {
    return '3yzxj1l2g9u11i'
  }

  static appVisiong12 () {
    return '14uer1l2jbrsk0'
  }

  static appIdeasa () {
    return 'cx1u1l2jc2o5u'
  }

  static appSapian () {
    return 'cx1u1l2kh93q4'
  }

  static appSeteren () {
    return '4xi11l2kmk43k'
  }

  static appAlcides () {
    return '2syvb1l2v19gbu'
  }

  static appCasaToroMazda () {
    return '29p311l325s2z7'
  }

  static appTalleresBonaparte () {
    return '4bpcr1l3276nex'
  }

  static appSuraback () {
    return '1b9xguh1l33mi8r0'
  }

  static appFeriaBrasier () {
    return 'r4x1l4cct1yj'
  }

  static appMentoriaExp () {
    return '4lris1l4h6fu40'
  }

  static appComfamaMentoria () {
    return 'dnft51l4suusgc'
  }

  static appBonaparte () {
    return '4l1eju1l3ddgvr7'
  }

  static appNoventa () {
    return '35hko1l589o57z'
  }

  static appZona1708 () {
    return 'czl91l59q6vcw'
  }

  static appZona1706 () {
    return 'fq1l59q7wm1'
  }

  static appZona1711 () {
    return '4kpbu1l59qag7d'
  }

  static appRentarte () {
    return 'bw1c1l5iih4rp'
  }

  static appEnerbit () {
    return 'afb1l5vk6asn'
  }

  static appMonastery () {
    return '9tiiz71l62bij6k'
  }

  static appAndar () {
    return '4e5byc1l6flkxem'
  }

  static appAxcis () {
    return '2h81l7j779if'
  }

  static appMedcloud () {
    return '1gxsk5t1l7uq9qnt'
  }

  static appTododrogas () {
    return '1i5251l896d5ht'
  }

  static appBioreferencia () {
    return '4qi11l8dbwjvv'
  }

  static appZendere () {
    return '4w7x1l8luwa1j'
  }

  static appSuraBack25 () {
    return 'a46sh21l8m4pt31'
  }

  static appSuraBack10 () {
    return 'a46sh21l8m4ttqh'
  }

  static appSuraBack23 () {
    return 'a46sh21l8m4vyhz'
  }

  static appSuraBack59 () {
    return '5gjr1l8m4wuma'
  }

  static appSuraBack34 () {
    return '3u3cx1l8m4xy6c'
  }

  static appSuraBack37 () {
    return '3u3cx1l8m4zbvt'
  }

  static appSuraBack610 () {
    return 'a46sh21l8m52qaq'
  }

  static appSuraBack33 () {
    return '5gjr1l8m548bm'
  }

  static appMrBoost () {
    return '45kgkb1l9ok8imd'
  }

  static appEccentric () {
    return 'a88ogt1l9okjonr'
  }

  static appDistriboost () {
    return '302gscf1l9okoi81'
  }

  static appDIstriboostIntl () {
    return '302gscf1l9okqpe2'
  }

  static appLipop () {
    return '302gscf1l9omgnsq'
  }

  static appLipoblue () {
    return '302gscf1l9omirbv'
  }

  static appBluma () {
    return '302gscf1l9omnd7i'
  }

  static appUSA () {
    return 'a88ogt1l9ompamm'
  }

  static appLipobluegoal () {
    return 'a88ogt1l9omqx66'
  }

  static appMarilynOquendo () {
    return 'a88ogt1l9omsg8e'
  }

  static appOrganicNails () {
    return '302gscf1l9pns5cq'
  }

  static appComfamaInter () {
    return '13has41l9ragwyi'
  }

  static appComfamaInterAlianza () {
    return 'azazm81l9zphod4'
  }

  static appMicelu () {
    return '3sa58aa1lb2r76pg'
  }

  static appLasnegras () {
    return 'wshzi1lbnul9tk'
  }

  static appDismerca () {
    return '1lc8w1lc6ea4x9'
  }

  static appNaturalSlim () {
    return 'e8781lc81g8ao'
  }

  static appMarrocar () {
    return '2b461le4fqiz3'
  }

  static appGestionarte () {
    return '9qz9n01leg4cv4o'
  }

  static appSempli () {
    return '69x1lfk65ow2'
  }

  static appLipopIntl () {
    return '69x1lfr17ox4'
  }

  static appDigitalPos () {
    return '3b9mn11lgi18fnl'
  }

  static appSpirito () {
    return '4k44s1lgme7oph'
  }

  static appRubyRose () {
    return 'erzzk1lh0yemjq'
  }

  static appCreemos () {
    return 'cpu4on1lhapaha5'
  }

  static ernestoAndrade () {
    return 'z63i1lfwyrr8h'
  }

  static grupoNova () {
    return 'l6k7kajl0059xke'
  }

  static appAlqueria () {
    return '1xufl3b1ljed6hw2'
  }

  static appBikeHouse (): string {
    return '3eu1li0ja7xt'
  }

  static appsGWM (): string[] {
    return ['4m3wtp1ln0ifseh']
  }

  static appsPartner (): string[] {
    // Rocío Bertone, Sapian, OnexMedia SAS, Edgar Rodríguez Partner, Keyrus MX
    return ['3nx5fl1krks99vxr2', 'cx1u1l2kh93q4', 'jxs21lnourcny', '34tpr1ltq0al4x', '3zh681lu77ezic']
  }
}
