<template>
  <div class="contentCreate"
       :class="$vuetify.theme.dark ? 'contentDark' : 'modeLight'">
    <div style="justify-content: start;align-items: start;width: 90px;">
      <KBButton :text="$t('goBack')"
                :type="'flat'"
                :color="$vuetify.theme.dark ? 'white' : 'black'"
                :icon="'arrow-left'"
                @click="onCategory = !onCategory"
                class="pa-0"/>
    </div>
    <div class="contentCategoryForm">
      <span style="font-weight: 600;">
        {{ $t('createCategory') }}
      </span>
      <div class="d-flex pb-2">
        <div class="contentFormCommission mr-4">
          <span>
            {{ $t(CDPFieldsEnums.NAME) }}
          </span>
          <KBText
            class="KBform smalForm"
            v-model="name"/>
        </div>
        <div class="contentFormCommission">
          <!-- <span>
            {{ $t('status') }}
          </span>
          <KBText
            class="KBform smalForm"
            v-model="email"/> -->
        </div>
      </div>
      <div class="scrollContentitemCommissionCategory"
           v-if="monthArray.length > 0">
        <div class="contentitemCommission"
             v-for=" item in monthArray"
             :key="item.id">
          <div class="headerCommission"
               @click="item.active = ! item.active">
            <span style="font-weight: 600;">
              {{ $t('month') }} {{ item.m }}
            </span>
            <button @click="removeItem(item)">{{ $t('remove') }}</button>
          </div>
          <v-expand-transition>
            <div v-if="item.active">
              <div class="itemCommission pa-0">
                <span>
                  {{ $t('month') }}
                </span>
                <KBText
                  class="KBform smalForm itemFormText"
                  v-model="item.m"/>
              </div>
              <div class="itemCommission">
                <span>
                  {{ $t('percentage') }}
                </span>
                <div class="d-flex">
                  <v-slider
                    class="sliderCommission"
                    max="50"
                    min="0"
                    v-model="item.p"
                  ></v-slider>
                  {{ item.p }}%
                </div>
              </div>
              <div class="itemCommission">
                <span>
                  {{ $t('Extra') }}
                </span>
                <div class="d-flex">
                  <v-slider
                    class="sliderCommission"
                    max="36"
                    min="0"
                    v-model="item.x"
                  ></v-slider>
                  {{ item.x }}%
                </div>
              </div>
            </div>
          </v-expand-transition>
        </div>
        <button class="px-1 py-3"
                @click="orderArray()">{{ $t('sort') }}</button>
      </div>
    </div>
    <div class="d-flex justify-space-between py-3 actionFormCommission">
      <KBButton :text="$t('addMonth')"
                :type="'flat'"
                :color="$vuetify.theme.dark ? 'white' : 'black'"
                :icon="'add'"
                @click="insertMonth()"/>
      <KBButton :text="$t('save')"
                :type="'flat'"
                :color="$vuetify.theme.dark ? 'white' : 'black'"
                :icon="'add'"
                @click="createCategory()"/>
    </div>
  </div>
</template>

<script>
import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import FeesServices from '@/modules/configuration/application/services/FeesServices'
import { EventBus } from '@/eventBus'
import KBButton from '@/shared/infrastructure/ui/buttons/KBButton.vue'
import KBText from '@/shared/infrastructure/ui/inputs/text/KBText.vue'
import { CDPFieldsEnums } from '@/modules/CDP/shared/contacts/domain/enums/CDPFieldsEnums'
export default {
  name: 'CreateCategoryCommission',
  props: ['labels', 'data'],
  components: { KBText, KBButton },
  data () {
    return {
      count: 1,
      KeybeIconType,
      name: null,
      categoryData: [],
      monthArray: [
        // {
        //   id: 1,
        //   active: true,
        //   m: 4,
        //   p: 70,
        //   x: 30
        // }
      ],
      CDPFieldsEnums
    }
  },
  created () {
    for (let index = 0; index < 6; index++) {
      this.insertMonth()
    }
  },
  computed: {
    onCategory: {
      get () {
        return this.$store.state.CommissionStore.onCategory || false
      },
      set (value) {
        this.$store.commit('CommissionStore/SET_EDITING_OBJECT', {
          key: 'onCategory',
          value
        })
      }
    }
  },
  methods: {
    removeItem (item) {
      const index = this.monthArray.indexOf(item)
      if (index > -1) { // only splice array when item is found
        this.monthArray.splice(index, 1) // 2nd parameter means remove one item only
        if (this.count > 0) { this.count = this.count - 1 }
      }
    },
    orderArray () {
      this.monthArray = this.monthArray.sort((x, y) => x.id - y.id)
    },
    insertMonth () {
      const validate = this.monthArray.find(element => element.id === this.count)
      if (!validate) {
        const template = {
          id: this.count,
          active: this.count === 1,
          m: parseInt(this.count),
          p: 0,
          x: 0
        }
        this.monthArray.push(template)
        this.count++
      } else {
        this.count = this.count + 1
        this.insertMonth()
      }
    },
    async createCategory () {
      let pricing = null
      if (!this.name) {
        EventBus.$emit('toast', 'error', this.$t('mustCompleteAllFields'))
        return
      }
      if (this.monthArray.length > 0) {
        pricing = this.monthArray.map((category) => ({
          M: parseInt(category.m),
          P: category.p,
          E: category.x
        })
        )
        const param = {
          name: this.name,
          status: true,
          pricing: pricing
        }
        await FeesServices.postCategoryFees(param).then((response) => {
          if (response) {
            if (response.code === 200 || response.code === 201) {
              EventBus.$emit('toast', 'success', this.$t('createCategoryMsg'))
              this.onCategory = !this.onCategory
            } else {
              EventBus.$emit('toast', 'error', response.message)
            }
            console.log('response', response)
          } else {
            EventBus.$emit('toast', 'error', this.$t('errorCreateCategory'))
          }
        })
      }
    }
  }
}
</script>
<style scoped lang="scss">
  .config--avatar{
      width: 25px !important;
      height: 25px !important;
      span{
          padding-top: 1px;
          color: white;
          font-size: 8px !important;
      }
  }

  .KBSimpleTable__table {
    overflow: scroll;
    border-collapse: collapse;
    width: 100%;
  }

  .modeLight thead th {
      background-color: #F7F7F7;
  }

  .modeDark thead th {
      background-color: #0000 !important;
  }

  .KBSimpleTable__header {
    font-weight: 300;
    font-size: 11px;
    line-height: 0px;
    color: $simple-grey;
    height: 30px;
    text-align: start !important;
    padding-left: 8px;
  }

  .KBSimpleTable__item {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    padding: 10px;
    border-bottom: 1px solid #EBEFF2;
    text-align: start;
    min-width: 50px  !important;
  }

    .first-item {
      padding-left: 32px;
      max-width: 125px;

      // text elipsis
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .last-item {
      padding-right: 32px;
    }
    .textGray{
      color: #707683;
  }
  .smallFont{
      font-size: 11px;
  }
  .edit--content{
      position: relative;
  }
  .icon-edit{
      span{
          color: #707683 !important;
          font-size: 12px !important;
      }
  }
  table { border-collapse: collapse; width: 100%; }

  .KBSimpleTable{
    overflow: scroll;
    height: 455px;
  }
  .KBSimpleTable thead th {
    position: sticky;
    top: 0;
  }
  .dialog-edit__Light{
    background: white;
  }
  .dialog-edit__Dark{
    background: black;
  }
  .dialog-edit {
      z-index: 9;
      position: absolute;
      border-radius: 10px;
      padding: 10px 20px;
      width: 400px;
      box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1);
      &__postionTop{
          top: 40px;
          right: 0;
      }
      &__postionBottom{
          bottom: 40px;
          right: 0;
      }
  }
  @media screen and (min-height: 600px) {
  .KBSimpleTable{
    overflow: scroll;
    height: 335px;
  }
  }

  @media screen and (min-height: 700px) {
  .KBSimpleTable{
    overflow: scroll;
    height: 435px;
  }
  }
  @media screen and (min-height: 750px) {
  .KBSimpleTable{
    overflow: scroll;
    height: 465px;
  }
  }

  @media screen and (min-height: 800px) {
  .KBSimpleTable{
    overflow: scroll;
    height: 475px;
  }
  }
  @media screen and (min-height: 900px) {
  .KBSimpleTable{
    overflow: scroll;
    height: 515px;
  }
  }
  .selectItem{
      background-color: #cbd4e72e;
  }
  .KBSimpleTable__item{
      font-weight: 300 !important;
  }
  .contentFormCommission{
    margin-top: 10px;
    width: 48%;
    max-width: 500px;
    .KBform{
      width: 100%;
    }
  }
  .contentitemCommission{
    margin-top: 10px;
    padding: 15px;
    width: 98%;
    background-color: #e9edf2;
  }
  .itemCommission{
    padding: 10px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .headerCommission{
    cursor: pointer;
    display: flex;
    justify-content: space-between;
  }
  .sliderCommission{
    max-width: 550px;
    min-width: 400px;
    width: 45%;
    height: 25px;
  }
  .contentCreate {
    position: relative;
    height: 100%;
  }
  .scrollContentitemCommissionCategory{
    height: 75%;
    padding-bottom: 50px;
    overflow-y: scroll;
    max-width: 1100px;
  }
  .itemFormText{
    width: 40px !important;
    padding: 0 10px !important;
  }
  .actionFormCommission{
    // max-width: 1100px;
    height: 50px;
    width: 100%;
    position: absolute;
    bottom: 0;
  }

::v-deep .vti__dropdown-list.above {
  top: 33px !important;
  bottom: unset !important;
}

::v-deep ul.vti__dropdown-list {
  margin-top: 10px;
  border: none !important;
  padding-top: 10px !important;
  width: 250px !important;
  margin-left: -8px;
}

::v-deep ul.vti__dropdown-list::-webkit-scrollbar-track {
  background-color: #e9edf2 !important;
}

::v-deep ul.vti__dropdown-list::-webkit-scrollbar {
  width: 6px;
  background-color: #e9edf2 !important;
}

::v-deep ul.vti__dropdown-list::-webkit-scrollbar-thumb {
  background-color: #e9edf2 !important;
}

::v-deep ul.vti__dropdown-list li.vti__dropdown-item {
  padding-right: 10px !important;
  margin-left: -25px;
}

::v-deep .vue-tel-input:focus-within {
  box-shadow: unset !important;
}

::v-deep .vti__dropdown:hover {
  background-color: unset !important;
}

.vue-tel-input {
  height: 40px;
  background-color: #e9edf2;
  border: unset;
}

::v-deep .vti__selection {
  flex-direction: row-reverse !important;
}
.contentCategoryForm{
  height: 95%;
  overflow-y: hidden;
}
</style>
