<template>
  <div v-if="activeModal"
       class="emailTemplate_container">
    <div class="container__editor">
      <EmailEditor
        ref="emailEditor"
        min-height="50%"
        @load="loadDefaultDesign"
        :tools="tools"
        :appearance="appearance"
      />
    </div>
  </div>
</template>

<script>
import { EmailEditor } from 'vue-email-editor'
import sampleKB from '@/assets/sampleKB.json'
import { EventBus } from '@/eventBus'
import TemplateService from '@/services/TemplateService'
import link from '@/utils/links'

export default {
  components: {
    EmailEditor
  },
  props: {
    activeModal: {
      type: Boolean,
      default: false
    },
    id: {
      type: [String, Number]
    }
  },
  data () {
    return {
      appearance: {
        panels: {
          tools: {
            collapsible: true
          }
        }
      },
      tools: {
        enabled: true,
        // disable image tool
        image: {
          enabled: true
        }
      },
      template: {},
      templateName: '',
      loadingSubmit: false,
      buttonCustomizeColor: '#8898AA',
      editName: false,
      actionName: '',
      link
    }
  },
  computed: {
    isDisabled () {
      return !this.template.name
    }
  },
  activated () {
    this.checkEdition()
  },
  mounted () {
    this.checkEdition()
  },
  watch: {
    id () {
      if (this.activeModal) {
        this.checkEdition()
        // const { style } = document.querySelector('iframe')
        // style.minWidth = '400px'
      }
    }

  },
  methods: {
    loadDefaultDesign () {
      this.$refs.emailEditor.editor.loadDesign(sampleKB)
    },
    checkEdition () {
      if (this.id) {
        const templateId = this.id

        if (!this.$route.query.createFromTemplate) {
          this.templateId = templateId
        }
        this.loadDesign(templateId)
      }
    },
    async loadDesign (templateId) {
      if (templateId === '0' || templateId === 0) {
        this.loadDefaultDesign()
      } else {
        await this.getTemplate(templateId)
        const vue = this

        if (
          this.template &&
            typeof this.template === 'object' &&
            this.template.template
        ) {
          this.templateName = this.template.name
          this.editName = true
          vue.$refs.emailEditor.editor.loadDesign(this.template.template)
        } else {
          EventBus.$emit('toast', 'error', this.$t('notSavedTemplate'))
        }
      }
    },
    async getTemplate (templateId) {
      try {
        const response = await TemplateService.getTemplateByIdV6(templateId)
        this.template = response
      } catch (e) {
        this.template = {}
      }
    },
    submit () {
      if (this.templateName) {
        this.templateId ? this.updateTemplate() : this.createTemplate()
      } else {
        EventBus.$emit('toast', 'success', this.$t('titleRequired'))
      }
    },
    updateTemplate () {
      const vue = this
      this.getTemplateHtml(function (html) {
        vue.loadingSubmit = true
        vue.$refs.emailEditor.editor.saveDesign(async (template) => {
          try {
            const templateId = vue.templateId
            const name = vue.templateName
            await TemplateService.updateTemplateV6({
              template,
              templateId,
              html,
              name
            })

            EventBus.$emit(
              'toast',
              'success',
              vue.$t('templateSuccessfullyUpdated')
            )
            vue.loadingSubmit = false
            setTimeout(() => {
              vue.$router.push(link.templates)
            }, 1000)
          } catch (e) {
            console.log(e)
            vue.loadingSubmit = false
            EventBus.$emit('toast', 'error', e.message)
          }
        })
      })
    },
    getTemplateHtml (cb) {
      this.$refs.emailEditor.editor.exportHtml((data) => {
        cb(data.html)
      })
    },
    createTemplate () {
      const vue = this
      this.getTemplateHtml(function (html) {
        vue.loadingSubmit = true
        vue.$refs.emailEditor.editor.saveDesign(async (template) => {
          try {
            const name = vue.templateName
            const response = await TemplateService.saveTemplateV6({
              template,
              name,
              html
            })

            if (response.success) {
              EventBus.$emit(
                'toast',
                'success',
                vue.$t('templateSuccessfullySaved')
              )
              vue.templateId = response.template.id
              setTimeout(() => {
                vue.$router.push(link.templates)
              }, 1000)
            }

            vue.loadingSubmit = false
          } catch (e) {
            vue.loadingSubmit = false
          }
        })
      })
    },
    updateName (name) {
      this.editName = false
      this.templateName = name
    }
  }
}
</script>

  <style scoped>
  .emailTemplate_container {
    height: 90% !important;
    width: 650px;
    overflow-x: scroll;
    /* overflow-x: hidden !important; */
        cursor: not-allowed;
      user-select: none;
      pointer-events: none;
  }
  .emailTemplate_container > iframe{
      width: 80% !important;
      min-width: 800px !important;
    }

  .blockbuilder-preferences-content{
      display: none !important;
      background: red;
    }
  .container__editor {
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;
  }

  .unlayer-editor{
    height: 100%;
  }
  .inline--margin {
    margin: 0 !important;
  }
  iframe{
    height: 350px !important;
  }
  </style>
