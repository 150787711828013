<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       fill="none"
       viewBox="0 0 11 13"><path :fill="color"
                                 d="M1.547 1.217 2.721.92C3.267.78 3.811.643 4.382.663c.63.022 1.215.196 1.779.47.443.214.904.352 1.396.391.803.064 1.549-.123 2.253-.512.393-.217.819-.142 1.043.219.086.138.14.324.14.489.01 2.052.006 4.104.006 6.156 0 .384-.17.667-.51.809-.55.23-1.1.464-1.695.558a4.184 4.184 0 0 1-2.25-.26c-.467-.185-.932-.39-1.415-.52-.82-.22-1.636-.081-2.441.137-.312.084-.623.17-.938.24-.15.032-.2.092-.198.255.008.984.002 1.968.005 2.952.001.316-.074.592-.35.768-.453.289-1.147.073-1.198-.595A3.137 3.137 0 0 1 0 11.985V.955C0 .716.013.485.17.287a.79.79 0 0 1 .845-.253c.3.089.512.352.53.674.01.16.002.322.002.51Z"/></svg>
</template>

<script>
export default {
  name: 'FlagFilledIcon',
  props: {
    color: {
      type: String,
      default: '#8898A9'
    }
  }
}
</script>

<style>

</style>
