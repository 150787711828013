import { debounce } from 'lodash'
import CreateAPIKey from '../CreateAPIKey/CreateAPIKey.vue'
import APIKeysHeader from '../APIKeysHeader/APIKeysHeader.vue'
import APIKeysList from '../APIKeysList/APIKeysList.vue'
export default {
  components: {
    CreateAPIKey,
    APIKeysHeader,
    APIKeysList
  },
  data () {
    return {
      search: '',
      apiKeys: [],
      create: false
    }
  },
  methods: {
    toggleCreate () {
      this.create = !this.create
    },
    updateInput: debounce(function (e) {
      this.search = e
    }, 300),
    close () {
      this.create = false
    }
  },
  watch: {
    search (val) {
    }
  }
}
