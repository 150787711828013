<template>
  <svg width="98"
       height="67"
       fill="none"
       xmlns="http://www.w3.org/2000/svg"><path d="M5.606 17.96v6.714M2.22 21.314h6.768M92.399 14.606v6.708M89.012 17.96h6.769"
                                                stroke="#3AF69B"
                                                stroke-width="4.15"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"/><path d="M81.624 62.335H77.76v3.833h3.864v-3.833ZM25.206 0h-3.004v2.98h3.004V0Z"
                                                                               fill="#3AF69B"/></svg>
</template>
<script>
export default {
  name: 'DialogIcon',
  props: {
    width: {
      type: Number,
      default: 16
    },
    height: {
      type: Number,
      default: 18
    },
    color: {
      type: String,
      default: '#B6BEC7'
    }
  }
}
</script>
