<template>
  <div class="AudioRecorder"
       id="AudioRecorder">
    <canvas id="canvas"></canvas>
    <div
      @click="cancel"
      class="AudioRecorder__icon--trash">
      <KeybeIcon
        :icon="KeybeIconType.TRASH"
        :color="Colors.rojoBird"
      />
    </div>
    <div class="d-flex align-center">
      <div class="AudioRecorder__light"></div>
      <time class="AudioRecorder__time">
        {{ timer.text }}
      </time>
    </div>
    <div class="AudioRecorder__icon--send"
         @click="send">
      <KeybeIcon
        :icon="KeybeIconType.SEND"
        :color="Colors.verdeBird"
      />
    </div>
  </div>
</template>

<script>
import Recorder from 'opus-recorder'
import { EventBus } from '@/eventBus'
import Colors from '@/shared/domain/colors/Color'
import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'

import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'

export default {
  name: 'AudioRecorder',
  components: {
    KeybeIcon
  },
  props: {
    isRecording: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      timer: {
        hours: 0,
        minutes: 0,
        seconds: 0,
        text: '00:00',
        time: null
      },
      isRecordingSupported: false,
      readyForSend: false,
      recorder: null,
      uploadProgress: 0,
      obj: {
        bars: []
      },
      timeOffset: 30,
      now: parseInt(performance.now()) / 30,
      Colors,
      KeybeIconType
    }
  },
  mounted () {
    if (this.isRecording === true) {
      this.start()
    }
    EventBus.$on('roomChanged', this.cancel.bind(this))
  },
  beforeDestroy () {
    EventBus.$off('roomChanged', this.cancel.bind(this))
    this.cancel()
  },
  methods: {
    stopRecording () {
      this.clearTime()
      this.recorder.stop()
      this.recorder.close()
      this.$emit('stopRecording')
    },

    checkPermission () {
      navigator.mediaDevices
        .getUserMedia({ audio: true, video: false })
        .then(() => {
          this.isRecordingSupported = true
        })
        .catch(() => {
          this.isRecordingSupported = false
        })
    },

    clearTime () {
      clearTimeout(this.timer.time)
      this.timer.text = '00:00'
      this.timer.seconds = 0
      this.timer.minutes = 0
      this.timer.hours = 0
    },

    send () {
      this.readyForSend = true
      this.stop()
    },

    async start () {
      this.initRecorder()
      await this.checkPermission()
      if (!this.isRecordingSupported) {
        console.error(
          'No se encontraron dispositivos de entrada o debes dar permisos en el navegador'
        )
        this.$emit('stop')
        return
      }
      this.timerCount()
      this.recorder.start()
      navigator.mediaDevices.getUserMedia({ audio: true }).then(this.soundAllowed).catch(() =>
        console.log('error')
      )
    },

    stop () {
      this.clearTime()
      this.recorder.stop()
      this.$emit('stop')
    },

    timerCount () {
      this.timer.time = setTimeout(this.audioTimer, 1000)
    },

    audioTimer () {
      this.timer.seconds++
      if (this.timer.seconds >= 60) {
        this.timer.seconds = 0
        this.timer.minutes++
        if (this.timer.minutes >= 60) {
          this.timer.minutes = 0
          this.timer.hours++
        }
      }
      this.timer.text =
          (this.timer.minutes
            ? this.timer.minutes > 9
              ? this.timer.minutes
              : '0' + this.timer.minutes
            : '00') +
          ':' +
          (this.timer.seconds > 9
            ? this.timer.seconds
            : '0' + this.timer.seconds)

      this.timerCount()
    },

    initRecorder () {
      this.recorder = new Recorder({
        bufferSize: 4096,
        numberOfChannels: 1, // Mono, consume menos recursos que el estéreo
        encoderSampleRate: 16000, // Frecuencia de muestreo más baja para reducir el tamaño del archivo
        encoderBitRate: 16000, // Tasa de bits más baja para una calidad aceptable y un tamaño de archivo más pequeño
        encoderApplication: 2048,
        encoderPath: 'https://storage.googleapis.com/keybe/files/bluebird/Utils/audioEncoder.js'
      })
      this.isRecordingSupported = Recorder.isRecordingSupported()
      this.recorder.ondataavailable = (array) => {
        const file = new File([array], 'audio.ogg', {
          type: 'audio/ogg',
          lastModified: +(new Date()).getTime()
        })
        this.$emit('input', file)
        if (this.readyForSend) {
          this.$emit('send')
          this.readyForSend = false
        }
      }
    },
    // waveforms
    init () {
      const audioRecorder = document.getElementById('AudioRecorder')
      this.obj.canvas = document.getElementById('canvas')
      this.obj.ctx = this.obj.canvas.getContext('2d')
      this.obj.width = audioRecorder.offsetWidth
      this.obj.height = audioRecorder.offsetHeight
      this.obj.canvas.width = this.obj.width * window.devicePixelRatio
      this.obj.canvas.height = this.obj.height * window.devicePixelRatio
      this.obj.canvas.style.width = (this.obj.width * 0.43) + 'px'
      this.obj.canvas.style.height = '40px'
      this.obj.ctx.scale(window.devicePixelRatio, window.devicePixelRatio)

      audioRecorder.appendChild(this.obj.canvas)
    },
    randomInteger (max = 256) {
      return Math.floor(Math.random() * max)
    },
    loop () {
      // console.log('arr', this.obj.frequencyArray)
      this.obj.ctx.clearRect(0, 0, this.obj.canvas.width, this.obj.canvas.height)
      let max = 0

      if (parseInt(performance.now() / this.timeOffset) > this.now) {
        this.now = parseInt(performance.now() / this.timeOffset)
        this.obj.analyser.getFloatTimeDomainData(this.obj.frequencyArray)
        for (var i = 0; i < this.obj.frequencyArray.length; i++) {
          if (this.obj.frequencyArray[i] > max) {
            max = this.obj.frequencyArray[i]
          }
        }

        var freq = Math.floor(max * 200)
        this.obj.bars.push({
          x: this.obj.width,
          y: (this.obj.height / 2) - (freq / 2),
          height: freq <= 0 ? 2 : freq,
          width: 2
        })
      }
      this.draw()
      requestAnimationFrame(this.loop)
    },
    draw () {
      for (let i = 0; i < this.obj.bars.length; i++) {
        const bar = this.obj.bars[i]
        this.obj.ctx.fillStyle = Colors.gray
        this.obj.ctx.fillRect(bar.x, bar.y, bar.width, bar.height)
        bar.x = bar.x - 2

        if (bar.x < 1) {
          this.obj.bars.splice(i, 1)
        }
      }
    },
    soundAllowed (stream) {
      const AudioContext = (window.AudioContext || window.webkitAudioContext)
      const audioContent = new AudioContext()
      const streamSource = audioContent.createMediaStreamSource(stream)

      this.obj.analyser = audioContent.createAnalyser()
      streamSource.connect(this.obj.analyser)
      this.obj.analyser.fftSize = 512
      this.obj.frequencyArray = new Float32Array(this.obj.analyser.fftSize)
      this.init()
      this.loop()
    },
    cancel () {
      this.stop()
      this.stopRecording()
    }
  }

}
</script>

<style lang='scss'>
  .AudioRecorder {
    display: flex;
    align-items: center;
    height: 80px;
    width: 100%;
    position: relative;
    justify-content: space-between;
    padding-left: 18px;
    padding-right: 32px;

    canvas {
      position: absolute;
      top: 20px;
      right: 74px;
    }

      &__time {
    margin-right: 20px;
  }

      &__light {
    background: #F7685B;
    border-radius: 50%;
    box-shadow: 0 0 0 0 #F7685B;
    margin: 10px;
    height: 10px;
    width: 10px;
    margin-left: 20px;
    transform: scale(1);
    animation: pulseCircle 2s infinite;
    font-size: 35px;
  }

    &__icon {
      &--trash {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 24px;
      }

      &--send {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 16px;
        width: 26px;
        height: 24px;
        cursor: pointer;
      }
    }
  }

  @keyframes pulseCircle {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 #F7685B9f;
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}
</style>
