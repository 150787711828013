<template>
   <svg width="9" height="10" viewBox="0 0 9 10" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M3.73905 1.89884V0.618661C3.34811 0.618661 2.96656 0.619703 2.58501 0.617618C2.51621 0.617618 2.44532 0.610321 2.37964 0.591556C2.25611 0.556633 2.16802 0.416939 2.17167 0.27881C2.17479 0.170391 2.27591 0.0499838 2.39476 0.0207942C2.4448 0.00828433 2.49744 0.000986918 2.54853 0.000986918C3.54775 -5.55693e-05 4.54697 -0.000576813 5.54568 0.000986918C5.83444 0.000986918 5.99082 0.208442 5.88605 0.447693C5.82402 0.589471 5.69684 0.617097 5.56183 0.618139C5.21416 0.620746 4.86702 0.619182 4.51935 0.619182C4.47243 0.619182 4.425 0.619182 4.36871 0.619182V1.89779C4.83366 1.95461 5.28401 2.05834 5.71247 2.24703C6.13833 2.43467 6.51363 2.69634 6.86859 3.0169C7.08178 2.80319 7.29184 2.59209 7.50295 2.38203C7.54881 2.33616 7.59625 2.29029 7.64942 2.25328C7.77139 2.16832 7.91212 2.18448 8.01429 2.2882C8.12062 2.39662 8.14251 2.53632 8.0539 2.65412C7.97884 2.7542 7.88762 2.84229 7.80058 2.93298C7.627 3.11438 7.45134 3.2942 7.27725 3.47351C8.57566 5.16755 8.18786 7.32185 7.20792 8.49778C6.01949 9.9239 4.20035 10.3242 2.64183 9.74303C0.943616 9.11024 -0.112424 7.50898 0.00954734 5.65283C0.075224 4.65361 0.467199 3.79199 1.17713 3.07737C1.88394 2.36587 2.7539 2.00882 3.73905 1.89831V1.89884ZM0.592298 5.9244C0.583437 7.80088 2.08618 9.36878 4.01895 9.3792C5.9267 9.38963 7.4847 7.91347 7.48939 5.94473C7.49408 4.03229 5.99655 2.51807 4.05231 2.50296C2.18053 2.48836 0.606893 3.99059 0.592298 5.9244Z" fill="black"/>
<path d="M4.35766 5.17256C4.35766 5.52023 4.36183 5.86738 4.35609 6.21505C4.35192 6.47358 4.15698 6.61745 3.9318 6.53978C3.80462 6.496 3.72904 6.38862 3.72852 6.22339C3.72591 5.51554 3.72591 4.80769 3.72852 4.09984C3.72904 3.90177 3.85935 3.76155 4.03136 3.7579C4.21901 3.75373 4.35401 3.89134 4.35662 4.10453C4.36079 4.46054 4.35766 4.81655 4.35766 5.17308V5.17256Z" fill="black"/>
</svg>
  </template>

<script>
export default {
  name: 'ClockIcon',
  props: {
    width: {
      type: Number,
      default: 16
    },
    height: {
      type: Number,
      default: 18
    },
    color: {
      type: String,
      default: '#B6BEC7'
    }
  }

}
</script>

<style>

</style>
