import {
  ICatalogImporterController,
  fileUploadResponse,
  dataToUploadCSV,
  CSVExampleResponse,
  dataToGetCSVExample,
  dataToStartCSVImport,
  importsResponse
} from '../domain/models/CatalogImporterController'

export default class CatalogImporterUseCases {
  static uploadCSVFile (controller: ICatalogImporterController, data: dataToUploadCSV): Promise<fileUploadResponse> {
    return controller.uploadCSVFile(data)
  }

  static getCSVFileExample (controller: ICatalogImporterController, data: dataToGetCSVExample): Promise<CSVExampleResponse> {
    return controller.getCSVFileExample(data)
  }

  static startCSVImport (controller: ICatalogImporterController, data: dataToStartCSVImport): Promise<fileUploadResponse> {
    return controller.startCSVImport(data)
  }

  static getAllImports (controller: ICatalogImporterController, data: dataToGetCSVExample): Promise<importsResponse> {
    return controller.getAllImports(data)
  }
}
