export enum ConsumptionsTabs {
  ADD_PAYMENT = 'add-payment',
  CONSUMPTIONS = 'consumptions',
  DETAILS = 'details',
  INVOICES = 'invoices',
  PAYMENTS_METHODS = 'payments-methods',
  PLANS = 'plans',
  COMMISSIONS = 'commissions',
  PACKAGES = 'packages',
  BRANDS = 'brands',
  SERVICES = 'services',
  NEW_INVOICES = 'new_invoices',
  PARTNERS = 'partners',
  CONFIG_PLANS = 'config-plans',
  DAILY_CONTACT = 'daily-contact',
  PRODUCTS = 'products'
}
