




















































































































































































































































































































































































import OutBoundDetail from './OutBoundDetail'
export default OutBoundDetail
