<template>
  <svg :width="width"
       :height="height"
       viewBox="0 0 17 15"
       fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <path d="M0.9775 5.3125H16.0225C16.5617 5.3125 17 4.85596 17 4.29427C17 4.09229 16.9416 3.89583 16.8353 3.72982L14.8272 0.592122C14.5908 0.221354 14.195 0 13.7673 0H3.23266C2.80766 0 2.40922 0.221354 2.17281 0.592122L0.164687 3.72705C0.0584375 3.89583 0 4.09229 0 4.2915C0 4.85596 0.438281 5.3125 0.9775 5.3125ZM1.7 6.19792V10.625V12.8385C1.7 13.5718 2.27109 14.1667 2.975 14.1667H8.925C9.62891 14.1667 10.2 13.5718 10.2 12.8385V10.625V6.19792H8.5V10.625H3.4V6.19792H1.7ZM13.6 6.19792V13.2812C13.6 13.771 13.9798 14.1667 14.45 14.1667C14.9202 14.1667 15.3 13.771 15.3 13.2812V6.19792H13.6Z"
          :fill="color"/>
  </svg>

</template>
<script>
export default {
  name: 'BusinessOffices',
  props: {
    width: {
      type: Number,
      default: 7
    },
    height: {
      type: Number,
      default: 11
    },
    color: {
      type: String,
      default: '#B6BEC7'
    }
  }
}
</script>
