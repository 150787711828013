import ConversationStoreController from '@/modules/conversations/infrastructure/persistence/controllers/ConversationStore.controller'
import { ISmartOMeterController, dataToGetAiConfig, dataToGetTier, dataToUpdateTier } from '../../domain/SmartOMeter/SmartOMeterController'
import { SmartOMeterFeature, SmartOMeterFeatures } from '../../domain/SmartOMeter/SmartOMeterFeatures'
import { SmartOMeterTier } from '../../domain/SmartOMeter/SmartOMeterTiers'
import { SmartOMeterPermissions } from '../../domain/SmartOMeter/SmartOMeterPermission'
import SmartOMeterRepository from '../repositories/SmartOMeter.repository'
import KeybeAiConfigController from './KeybeAiConfig.controller'
import KeybeAiConfigUseCases from '../../application/KeybeAiConfigUseCases'
import { KeybeAiConfigStatesEnum } from '../../domain/KeybeAiConfig'
import op from '@/utils/operations'

export default class SmartOMeterController implements ISmartOMeterController {
  repository: SmartOMeterRepository

  constructor () {
    this.repository = new SmartOMeterRepository()
  }

  hasPermission (feature: SmartOMeterFeature, tier?: SmartOMeterTier): boolean {
    if (!tier) {
      const sentimetalAnalisysEnabled = ConversationStoreController.getSentimentalAnalysisEnabled()
      const actionSuggestionsEnabled = ConversationStoreController.getActionSuggestionsEnabled()
      const messageSuggestionsEnabled = ConversationStoreController.getMessageSuggestionsEnabled()

      const features = {
        [SmartOMeterFeatures.SENTIMET_ANALYSIS]: sentimetalAnalisysEnabled,
        [SmartOMeterFeatures.ACTION_SUGGESTIONS]: actionSuggestionsEnabled,
        [SmartOMeterFeatures.NEURAL_SUGGESTIONS]: messageSuggestionsEnabled
      }

      return features[feature] || false
    }

    const tiers = SmartOMeterPermissions[feature] || []

    return tiers.includes(tier) || false
  }

  async getTier (data: dataToGetTier): Promise<SmartOMeterTier | null> {
    const response = await this.repository.getTier(data)
    if (!response.status) {
      return null
    }
    return response?.data?.tier || null
  }

  async updateTier (data: dataToUpdateTier): Promise<boolean> {
    const response = await this.repository.updateTier(data)
    return response.status
  }

  async getAiConfig (data: dataToGetAiConfig) {
    const tier = await this.getTier(data)
    if (tier) {
      return tier
    }

    const keybeAiConfigController = new KeybeAiConfigController()

    await KeybeAiConfigUseCases.setActionSuggestionsConfig(keybeAiConfigController, {
      appId: data.appUUID,
      state: KeybeAiConfigStatesEnum.ENABLED,
      token: data.token,
      advisorName: data.advisorName,
      advisorId: data.advisorId
    })
    ConversationStoreController.setActionSuggestionsEnabled(true)

    const [sentimentalResponse, answersResponse] = await Promise.all([
      await KeybeAiConfigUseCases.getSentimentsConfig(keybeAiConfigController, {
        appId: data.appUUID,
        advisorName: data.advisorName,
        advisorId: data.advisorId,
        token: data.token
      }),
      await KeybeAiConfigUseCases.getMessageSuggestionsConfig(keybeAiConfigController, {
        appId: data.appUUID,
        advisorName: data.advisorName,
        advisorId: data.advisorId,
        token: data.token
      })
    ])

    ConversationStoreController.setSentimentalAnalysisEnabled(op.areEqual(sentimentalResponse.state, KeybeAiConfigStatesEnum.ENABLED))
    ConversationStoreController.setMessageSuggestionsEnabled(op.areEqual(answersResponse.state, KeybeAiConfigStatesEnum.ENABLED))

    return null
  }
}
