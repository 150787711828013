<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       fill="none"
       viewBox="0 0 9 10"><path :fill="color"
                                d="M2.082 1.25V.638c0-.066 0-.13.004-.196.023-.265.212-.442.468-.443.238 0 .44.171.462.422.02.22.008.442.011.663v.157h2.894V.639c0-.066-.001-.13.004-.196.023-.265.211-.442.467-.443.239-.001.441.17.463.421.019.22.008.443.011.664v.164c.258 0 .497-.004.736.001.299.007.582.071.823.254.345.262.52.612.52 1.037.002 2.055.005 4.109-.001 6.163-.003.754-.568 1.294-1.338 1.294-2.089.003-4.177.003-6.265 0-.775 0-1.335-.533-1.337-1.29-.006-2.055-.005-4.109 0-6.163 0-.63.362-1.086.978-1.248.127-.033.262-.042.395-.046.229-.007.459-.002.705-.002ZM.948 3.751V8.64c0 .33.1.428.437.428h6.169c.34 0 .447-.104.447-.435V3.752H.948Z"/></svg>
</template>

<script>
export default {
  name: 'ScheduleIcon',
  props: {
    color: {
      type: String,
      default: '#fff'
    }
  }
}
</script>

<style>

</style>
