







export default {
  name: 'SentIcon',
  props: {
    color: {
      type: String,
      default: '#B6BEC7'
    }
  }

}
