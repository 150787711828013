import axios from 'axios'
import store from '@/store/index'
import { RestResponseFactory } from '@/models/apis/RestResponse'

export default class UserService {
  static async changePasswordLogged (currentPassword: string, newPassword: string) {
    const token = store.getters['UserStore/getToken']

    const data = {
      currentPassword,
      newPassword
    }

    try {
      const response = await axios.post(
                `${process.env.VUE_APP_AUTH_SERVICE}auth/change-password`,
                data,
                {
                  headers: {
                    Authorization: 'Bearer ' + token
                  }
                }
      )

      return new RestResponseFactory().process({ response })
    } catch (error) {
      return new RestResponseFactory().process({ response: { status: 500 } })
    }
  }

  static async uploadImage (img) {
    const url = `${process.env.VUE_APP_AUTH_SERVICE}user/image`
    const token = store.getters['UserStore/getToken']
    const file = new FormData()
    if (img) {
      file.append('file', img)
    }
    try {
      const response = await axios.post(url, file, {
        headers: {
          Authorization: 'Bearer ' + token,
          contentType: 'multipart/form-data'
        }
      })
      return new RestResponseFactory().process({ response })
    } catch (error) {
      return new RestResponseFactory().process({ response: { status: 500 } })
    }
  }

  static async updateProfile (data) {
    const appId = store.getters['AppStore/getSelectedApp'].uuid
    const url = `${process.env.VUE_APP_AUTH_SERVICE}user/profile/${appId}`
    const token = store.getters['UserStore/getToken']
    try {
      const response = await axios.patch(url, data, {
        headers: {
          Authorization: 'Bearer ' + token
        }
      })
      return new RestResponseFactory().process({ response })
    } catch (error) {
      return new RestResponseFactory().process({ response: { status: 500 } })
    }
  }

  static async updatePhotoProfile (data) {
    try {
      const appId: string = store.getters['AppStore/getSelectedApp']?.uuid
      const token: string = store.getters['UserStore/getToken']
      const { img, userHost } = data
      const url: string = `${process.env.VUE_APP_CONVERSATIONS_URL}/config/whatsapp/${appId}/phoneInfo/${userHost}`
      const file: FormData = new FormData()
      if (img) {
        file.append('file', img)
      }
      const response = await axios.put(url, file, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + token
        },
        data: file
      })
      return new RestResponseFactory().process({ response })
    } catch (e) {
      return new RestResponseFactory().process({ response: { status: 500 } })
    }
  }

  static async getRoles () {
    const userId = store.getters['UserStore/getUser'].userId
    const token = store.getters['UserStore/getToken']
    try {
      const response = await axios.get(
                `${process.env.VUE_APP_AUTH_SERVICE}user/${userId}`,
                {
                  headers: {
                    Authorization: 'Bearer ' + token
                  }
                }
      )
      const roles = response.data.data.user.permissionNames
      store.commit('UserStore/SET_ROLES', roles)
      return new RestResponseFactory().process({ response }).data.data.user.permissionNames
    } catch (error) {
      return new RestResponseFactory().process({ response: { status: 500 } })
    }
  }

  static async updateUserExtradata (data) {
    const userUUID = store.getters['UserStore/getUser'].uuid
    const token = store.getters['UserStore/getToken']
    const url = `${process.env.VUE_APP_AUTH_SERVICE}user/${userUUID}`
    const appId = store.getters['AppStore/getSelectedApp']?.uuid
    try {
      const response = await axios.put(url, {
        appUUID: appId,
        extradata: [
          data
        ]
      }, {
        headers: {
          Authorization: 'Bearer ' + token
        }
      })
      return new RestResponseFactory().process({ response })
    } catch (error) {
      return new RestResponseFactory().process({ response: { status: 500 } })
    }
  }
}
