<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       fill="none"
       viewBox="0 0 10 10"><path :fill="color"
                                 d="M.002 4.992c0-1.156.005-2.313-.002-3.469-.003-.508.183-.917.578-1.23.264-.208.577-.289.904-.29C3.824 0 6.166-.001 8.507.004c.846.002 1.473.615 1.478 1.465.011 1.687.004 3.375.003 5.062 0 .418-.155.772-.45 1.067-.645.646-1.291 1.29-1.936 1.937A1.533 1.533 0 0 1 6.468 10c-1.652-.002-3.305 0-4.957-.001-.715 0-1.268-.402-1.456-1.066a1.842 1.842 0 0 1-.05-.486C-.001 7.295 0 6.144 0 4.993l.001-.001Zm6.431 3.943v-.197c0-.495-.002-.992.001-1.488.001-.108.003-.22.033-.323.096-.328.342-.49.727-.492.52-.003 1.041-.001 1.562-.001h.17c.004-.064.008-.113.008-.161V1.539c0-.345-.133-.474-.48-.474H1.55c-.373 0-.49.116-.49.487v6.892c0 .378.112.49.484.49H6.434Z"/></svg>
</template>

<script>
export default {
  name: 'NoteIcon',
  props: {
    color: {
      type: String,
      default: '#fff'
    }
  }
}
</script>

<style>

</style>
