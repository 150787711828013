import Colors from '@/utils/Colors'
import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'
import KeybeButton from '@/modules/DesignSystem/infrastructure/components/KeybeButton/KeybeButton.vue'
import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import OnboardingUseCases from '@/modules/SignInOnboarding/application/OnboardingUseCases'
import OnboardingController from '../../controllers/Onboarding.controller'
export default {
  components: {
    KeybeIcon,
    KeybeButton
  },
  props: {
    arrowPosition: {
      type: String,
      default: 'bottom'
    },
    boxPosition: {
      type: String
    },
    buttonText: {
      type: String,
      default: 'continue'
    },
    headerText: {
      type: String,
      default: 'letsStart'
    },
    subtitleText: {
      type: String,
      default: 'onboardingPercentage'
    },
    hideButton: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    arrowPositionClass (): string {
      const labelMap: {[key: string]: string} = {
        left: 'SignInOnboardingTutorialBox__container--left',
        right: 'SignInOnboardingTutorialBox__container--right',
        top: 'SignInOnboardingTutorialBox__container--top',
        bottom: 'SignInOnboardingTutorialBox__container--bottom'
      }
      return labelMap[this.arrowPosition] || 'SignInOnboardingTutorialBox__container--left'
    },
    boxPositionStyle (): string {
      return this.boxPosition
    }
  },
  data () {
    return {
      rerender: 0,
      onboardingStep: null,
      KeybeIconType,
      Colors,
      onboardingController: new OnboardingController()
    }
  },
  methods: {
    clicked () {
      this.$emit('clicked', this.onboardingStep)
    },
    stopTutorial (): void {
      OnboardingUseCases.stopOnboarding(this.onboardingController)
      setTimeout(() => {
        this.$emit('close')
      }, 500)
    }
  },
  watch: {
    boxPositionStyle () {
      this.rerender++
    }
  }
}
