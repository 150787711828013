<template>
  <div>
    <span class="textCard__subText"
          v-if="dataText !== $t('pending')">USD</span>
    <span class="textCard">
      {{ dataText ? dataText : 0 }}
    </span>
    <span class="textCard__subText"
          v-if="decimal">.{{ decimal }}</span>
  </div>
</template>

<script>
export default {
  name: 'USDCard',
  props: {
    dataText: {
      type: String
    },
    decimal: {
      type: String
    }
  },
  data () {
    return {
    }
  },
  watch: {
  }

}
</script>
<style lang='scss'>
  .textCard{
    font-family: Poppins;
    font-size: 28px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: -0.01em;
    text-align: left;

    &__subText{
      font-family: Poppins;
      font-size: 14px;
      font-weight: 600;
      line-height: 14px;
      letter-spacing: -0.01em;
      text-align: left;
    }
  }
</style>
