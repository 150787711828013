type AutoassignTypes = 'balanced' | 'linear'

export interface Schedule {
  from: string;
  until: string;
}

export interface DayConfig {
  code: number;
  isClosed: boolean;
  isOpen: boolean;
  schedules: Schedule[];
}

export interface WorkingHours {
  message?: string;
  days: DayConfig[];
}
export interface SpaceUser {
    userId: string,
    access: string,
    maxActiveConversationsNumber?: number,
    hasConversationsLimit?: boolean
  }

export interface Spaces {
    _id: string,
    appUUID: string,
    key: string,
    name: string,
    active: boolean,
    users?: SpaceUser[],
    tags?: string[],
    createdAt?: string,
    updatedAt?: string,
    line?: string[],
    default?: boolean
  }

export interface messageOnTransfer {
    message: string,
    channel: string,
    active: boolean
  }
export interface SpaceConfig extends Spaces {
  autoassignationType?: AutoassignTypes,
  isAutoassignActive?: boolean,
  maxActiveConversationsNumberPerAdvisor?: number,
  autoClose?: {
    afterMinutes: number,
    excludedChannels: string[]
  },
  reassignForAdviserInactiveMinutes?: number,
  reassignForUserInactiveMinutesWithBot?: number,
  useMemory?: boolean,
  useMemoryOffline?: boolean,
  inactivitySeconds?: string,
  inactivityMessage?: string,
  workingHours?: WorkingHours,
  messageOnTransfer?: messageOnTransfer[],
  line?: string[]
}

export interface updateInboxConfigDTO {
  name: string,
  key: string,
  autoassignationType: AutoassignTypes,
  isAutoassignActive: boolean,
  maxActiveConversationsNumberPerAdvisor: number,
  autoClose: {
    afterMinutes: number,
    excludedChannels: string[]
  },
  reassignForAdviserInactiveMinutes: number,
  reassignForUserInactiveMinutesWithBot: number,
  useMemory: boolean,
  useMemoryOffline?: boolean,
  inactivitySeconds: string,
  inactivityMessage: string,
  workingHours: WorkingHours,
  messageOnTransfer: messageOnTransfer[],
  line: string[],
}

export enum SpacesAccessEnum {
    EDIT = 'EDIT',
    VIEW = 'VIEW',
    DELETE = 'DELETE',
  }

export interface DataToCreateInbox {
  userId: string,
  token: string,
  appUUID: string,
  name: string
}

export type SpacesAccess = SpacesAccessEnum.EDIT | SpacesAccessEnum.VIEW | SpacesAccessEnum.DELETE
