var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{key:("select-" + _vm.rerender),staticClass:"KBSelect",class:{ 'KBSelect--open': _vm.showSelector,
               'w-full': !_vm.transparent,
               'w-auto': _vm.transparent },style:({ minWidth: _vm.minWidth })},[_c('v-menu',{staticStyle:{"z-index":"999999"},attrs:{"location":_vm.location,"offset-y":"","transition":"slide-y-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
               var attrs = ref.attrs;
return [(_vm.transparent)?_c('div',_vm._b({staticClass:"KBSelect__container--seamless",class:{ 'cursor-default': _vm.disabled },on:{"click":function($event){$event.stopPropagation();return _vm.openSelector.apply(null, arguments)}}},'div',attrs,false),[(_vm.isStatus && _vm.selectedStatusColor)?_c('div',{staticClass:"status-dot mr-2",style:({ background: _vm.selectedStatusColor })}):_vm._e(),(_vm.selectedItem)?_c('span',{staticClass:"seamless-text"},[_vm._v(_vm._s(_vm.$t(_vm.itemToShow)))]):_c('span',{staticClass:"seamless-text"},[_vm._v(_vm._s(_vm.placeholder))]),(!_vm.loading)?_c('span',{staticClass:"mdi mdi-menu-down"}):_c('lottie-animation',{ref:"anim",staticClass:"loaderAnimation",staticStyle:{"width":"30px"},attrs:{"loop":true,"animationData":require('@/shared/assets/animations/circleLoader.json')}})],1):_c('div',_vm._g({staticClass:"KBSelect__container",class:{ 'KBSelect__container--transparent': _vm.seamless,
                     'KBSelect__container--transparent-open': _vm.showSelector && _vm.seamless,
                     'KBSelect__container--outlined': _vm.outlined,
                     'cursor-default': _vm.disabled },on:{"click":_vm.openSelector}},_vm.on),[(_vm.isStatus && _vm.selectedStatusColor)?_c('div',{staticClass:"status-dot mr-2",style:({ background: _vm.selectedStatusColor })}):_vm._e(),(_vm.selectedItem)?_c('span',{class:{ 'selected--dark': _vm.$vuetify.theme.dark }},[_vm._v(_vm._s(_vm.$t(_vm.itemToShow)))]):_c('span',{staticClass:"placeholder"},[_vm._v(_vm._s(_vm.placeholder))]),(!_vm.loading)?_c('span',{staticClass:"mdi mdi-menu-down"}):_c('lottie-animation',{ref:"anim",staticClass:"loaderAnimation",staticStyle:{"width":"30px"},attrs:{"loop":true,"animationData":require('@/shared/assets/animations/circleLoader.json')}})],1)]}}]),model:{value:(_vm.showSelector),callback:function ($$v) {_vm.showSelector=$$v},expression:"showSelector"}},[_c('v-list',{staticClass:"KBSelect__list newBackground",attrs:{"id":"kbselect"}},_vm._l((_vm.items),function(item,index){return _c('v-list-item',{key:index,staticClass:"d-flex items-center list-item newBackground",attrs:{"value":index,"id":"kbselect"},on:{"click":function($event){return _vm.selectItem(item)}}},[_c('v-list-item-title',{staticClass:"d-flex items-center",attrs:{"id":"kbselect"}},[(_vm.isStatus)?_c('div',{staticClass:"status-dot mr-2",style:({ background: item.mainColor ? item.mainColor : item.color ? item.color : '' }),attrs:{"id":"kbselect"}}):_vm._e(),_vm._v(" "+_vm._s(_vm.isExtra ? item.name ? _vm.$t(item.name) : _vm.$t(item) : _vm.itemText ? _vm.$t(item[_vm.itemText]) : _vm.$t(item))+" ")])],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }