





















import PartnersScreen from './PartnersScreen'
export default PartnersScreen
