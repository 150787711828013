<template>
  <Transition name="fade">
    <div class="Loader"
         :style="loaderStyle"
         :class="$vuetify.theme.dark? 'Loader__dark' :''">
      <div class="loader-1 center"><span></span></div>
    </div>
  </Transition>
</template>

<script>
export default {
  name: 'Loader',
  props: {
    absolute: {
      type: Boolean,
      default: true
    },
    fullHeight: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    loaderStyle () {
      let style = {}
      if (this.fullHeight) {
        style = {
          height: '100vh'
        }
      }
      if (this.absolute) {
        style = {
          ...style,
          position: 'absolute'
        }
      }
      return style
    }
  }
}
</script>

<style lang="scss">
.Loader {
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 200;
  animation: appear 0.4s ease;

  &__dark {
    background-color: rgba(0, 0, 0, 0.5);
  }

  .loaderAnimation {
    max-width: 200px;
    max-height: 200px;
  }

  /* Loader 1 */
.loader-1 {
  height: 32px;
  width: 32px;
  -webkit-animation: loader-1-1 4.8s linear infinite;
  animation: loader-1-1 4.8s linear infinite;
}
@-webkit-keyframes loader-1-1 {
  0%   { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}
@keyframes loader-1-1 {
  0%   { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.loader-1 span {
  display: block;
  position: absolute;
  top: 0; left: 0;
  bottom: 0; right: 0;
  margin: auto;
  height: 46px;
  width: 46px;
  clip: rect(0, 46px, 46px, 23px);
  -webkit-animation: loader-1-2 1.2s linear infinite;
  animation: loader-1-2 1.2s linear infinite;
}
@-webkit-keyframes loader-1-2 {
  0%   { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(220deg); }
}
@keyframes loader-1-2 {
  0%   { transform: rotate(0deg); }
  100% { transform: rotate(220deg); }
}
.loader-1 span::after {
  content: "";
  position: absolute;
  top: 0; left: 0;
  bottom: 0; right: 0;
  margin: auto;
  height: 46px;
  width: 46px;
  clip: rect(0, 46px, 46px, 23px);
  border: 4px solid #3AF69B;
  border-radius: 50%;
  -webkit-animation: loader-1-3 1.2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
  animation: loader-1-3 1.2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
}
@-webkit-keyframes loader-1-3 {
  0%   { -webkit-transform: rotate(-140deg); }
  50%  { -webkit-transform: rotate(-160deg); }
  100% { -webkit-transform: rotate(140deg); }
}
@keyframes loader-1-3 {
  0%   { transform: rotate(-140deg); }
  50%  { transform: rotate(-160deg); }
  100% { transform: rotate(140deg); }
}
}

@keyframes appear {
  0% {
    opacity: 0
  }
  100% {
    opacity: 1
  }
}
</style>
