<template>
  <div class="ContentGraphGray"
       :class="$vuetify.theme.dark ? 'ContentGraphGrayDark' : ''">
    <span class="pt-3 pb-1">{{ $t('funnel') }} ({{ nameFlow }})</span>
    <DateRange :range="range"/>
    <div class="cardFunnel"
         v-if="dataGraphVisible">
      <v-chart :option="option" />
    </div>
    <div v-if="dataRequestList.length > 0"
         class="cardFunnelTable">
      <table class="KBSimpleTable__table ">
        <thead>
          <tr>
            <th
              class="KBSimpleTable__header smallTable__header"
              v-for="(label, labelIndex) in headers"
              :key="`KBSimpleTable-${label.text}-${labelIndex}`"
            >
              <td>
                {{ label.text }}
              </td>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            class="KBSimpleTable__row"
            v-for="(item, itemIndex) in dataRequestList"
            :key="`KBSimpleTable-item-${itemIndex}`">
            <td :class="$vuetify.theme.dark? 'KBSimpleTable__itemDark': 'KBSimpleTable__item'">
              {{ item.stepName ? item.stepName : '-' }}
            </td>
            <td :class="$vuetify.theme.dark? 'KBSimpleTable__itemDark': 'KBSimpleTable__item'">
              {{ item.totalAmount ? `$${numbersFilter(item.totalAmount)}` : '$0' }}
            </td>
            <td :class="$vuetify.theme.dark? 'KBSimpleTable__itemDark': 'KBSimpleTable__item'">
              {{ item.totalCount ? item.totalCount : '0' }}
            </td>
            <td :class="$vuetify.theme.dark? 'KBSimpleTable__itemDark': 'KBSimpleTable__item'">
              {{ item.runningTotalCount ? item.runningTotalCount : '0' }}
            </td>
            <td :class="$vuetify.theme.dark? 'KBSimpleTable__itemDark': 'KBSimpleTable__item'">
              {{ item.runningTotalAmount ? `$${numbersFilter(item.runningTotalAmount)}` : '$0' }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import VChart from 'vue-echarts'
import 'echarts'
import Colors from '@/utils/Colors'
import DateRange from '@/modules/metrics/infrastructure/ui/components/generic/DateRange.vue'
import operations from '@/utils/operations'
import { CDPFieldsEnums } from '@/modules/CDP/shared/contacts/domain/enums/CDPFieldsEnums'
export default {
  props: {
    nameFlow: {
      type: [String, Number, Object],
      required: false
    },
    id: {
      type: [String, Number],
      default: 0
    },
    firstPipeline: {
      type: Object,
      required: true
    },
    dataRequest: {
      type: Array
    },
    range: {
      type: Object,
      required: false
    }
  },
  name: 'TrapexiumGraph',
  components: {
    DateRange,
    VChart
  },
  data () {
    return {
      dataRequestList: [],
      headers: [
        { text: this.$t(CDPFieldsEnums.NAME) },
        { text: this.$t('totalAmount') },
        { text: this.$t('totalCount') },
        { text: this.$t('runningTotalCount') },
        { text: this.$t('runningTotalAmount') }
      ],
      itemData: [],
      funnelData: [],
      legend: [],
      dataGraphVisible: false,
      Colors
    }
  },
  watch: {
    dataRequest () {
      if (this.dataRequest.length > 0) {
        this.dataRequestList = this.dataRequest.map(segment => {
          if (!segment.stepName) return
          return { ...segment, stepName: operations.checkTranslation(segment.stepName) }
        })
        this.dataRequestList.sort((a, b) => (a.stepOrder > b.stepOrder) ? 1 : ((b.stepOrder > a.stepOrder) ? -1 : 0))
        this.legend = this.dataRequest.map(r => {
          return operations.checkTranslation(r.stepName)
        })
        this.funnelData = this.dataRequestList.map(r => {
          return {
            value: r.runningTotalCount, name: operations.checkTranslation(r.stepName)
          }
        })
        this.dataGraphVisible = true
      }
    }
  },
  computed: {
    option () {
      return {
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c}%'
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        legend: {
          data: this.legend,
          textStyle: {
            color: this.$vuetify.theme.dark ? 'FFFFFF' : 'black'
          }
        },
        series: [
          {
            name: 'Funnel',
            type: 'funnel',
            left: '10%',
            top: 60,
            bottom: 60,
            width: '80%',
            min: 0,
            max: 100,
            minSize: '0%',
            maxSize: '100%',
            sort: 'none',
            gap: 2,
            label: {
              show: true,
              // position: 'inside',
              formatter: (params) => {
                const length = 18
                const trimmedString = params.name.length > length
                  ? params.name.substring(0, length - 3) + '...'
                  : params.name
                return trimmedString
              }
            },
            labelLine: {
              length: 10,
              lineStyle: {
                width: 1,
                type: 'solid'
              }
            },
            emphasis: {
              textStyle: {
                fontSize: 20,
                color: this.$vuetify.theme.dark ? 'white' : 'black'
              }
            },
            data: this.funnelData
          }
        ]
      }
    }
  },
  methods: {
    numbersFilter: (value) => {
      if (typeof value !== 'number') return value
      const locale = Intl.NumberFormat('en-US')
      return locale.format(value)
    }
  }
}
</script>
<style lang='scss'>
.full-width{
  width: 100%;
}
.cardFunnel {
  width: 100%;
  height: 504px;
  padding: 32px 32px 0 32px;
}
.cardFunnelTable {
  width: 100%;
  padding-bottom: 10px;
}

// .contentTableFunnel{
//   display: flex;
//   flex-direction: column;
//   width: 250px;
//   &__One{
//     width: 100%;
//   }
//   &__Two{
//     flex-direction: column;
//     display: flex;
//     justify-content: space-between;
//     border: solid 1px #fff;
//       width: 100%;
//       overflow-x: hidden;
//   }
// }
.ContentGraphGrayDark{
  .KBSimpleTable__itemDark{
    color: white;
  }
}
</style>
