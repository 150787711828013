var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.close),expression:"close"}],staticClass:"d-flex flex-column relative w-full"},[_c('div',{key:_vm.rerenderLocal,staticClass:"KBAutocompleteSmall",class:{ 'KBAutocompleteSmall--open': _vm.showSelector,
                 'KBAutocompleteSmall--outlined': _vm.outlined,
                 'KBAutocompleteSmall--small': _vm.small
       }},[_c('div',{staticClass:"KBAutocompleteSmall__container",class:{ 'KBAutocompleteSmall__container--outlined': _vm.outlined,
                   'KBAutocompleteSmall__container--seamless': _vm.seamless,
                   'KBAutocompleteSmall__container--focused': _vm.focused,
                   'KBAutocompleteSmall__conainer--small': _vm.small
         }},[(_vm.icon)?_c('span',{class:("mdi " + _vm.icon + " icon")}):_vm._e(),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search),expression:"search"}],staticClass:"search",attrs:{"type":"text","placeholder":_vm.placeholder,"autocomplete":"text","disabled":_vm.disabled},domProps:{"value":(_vm.search)},on:{"keypress":_vm.keyType,"focus":function($event){_vm.focused = true},"blur":function($event){_vm.focused = false},"click":function($event){_vm.showSelector = true},"input":function($event){if($event.target.composing){ return; }_vm.search=$event.target.value}}})])]),(_vm.showSelector)?_c('div',{staticClass:"KBAutocompleteSmall__list newBackground",class:{
         'KBAutocompleteSmall__list--small': _vm.small
       },style:(("top: " + (_vm.outlined ? '40px' : _vm.small ? '30px' : '40px')))},_vm._l((_vm.itemsComputed),function(item,index){return _c('div',{key:index,staticClass:"list-item",class:{ 'list-item--selected': _vm.isSelected(item) },on:{"click":function($event){return _vm.selectItem(item)}}},[_vm._v(" "+_vm._s(_vm.itemText ? _vm.multipleText ? item[_vm.itemText[0]] && item[_vm.itemText[1]] ? (item[_vm.itemText[0]] + ' ' + (item[_vm.itemText[1]] ? item[_vm.itemText[1]] : '')) : item[_vm.itemText[0]] : item[_vm.itemText] : item)+" ")])}),0):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }