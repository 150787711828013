import {
  IDocumentsDataController,
  dataToGetDocumentsData,
  documentsDataGetter
} from '@/modules/KBDrive/domain/DocumentsController'

export default class DocumentsUseCases {
  static getDocuments (controller: IDocumentsDataController, data: dataToGetDocumentsData) : Promise<documentsDataGetter> {
    return controller.getDocuments(data)
  }
}
