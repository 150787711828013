<template>
  <img
    v-if="showImage"
    :style="{
      minWidth: size+'px',
      minHeight: size+'px',
      maxWidth: size+'px',
      maxHeight: size+'px',
      objectFit: 'cover'
    }"
    :src="isKeybeProfile? keybeLogo: img"
    alt=""
    class="KeybeAvatar-img"
    @error="imgUrl=''"/>
  <div v-else
       :class="{smallText: small}"
       :style="style">{{ nameInitials }}</div>
</template>

<script>
import Colors from '../../../utils/Colors'

export default {
  name: 'KeybeAvatar',
  props: {
    size: {
      type: Number,
      default: 40
    },
    name: {
      type: String,
      default: '',
      required: true
    },
    img: {
      type: String,
      default: null,
      required: false
    },
    isKeybeProfile: {
      type: Boolean,
      default: false,
      required: false
    },
    small: {
      type: Boolean,
      default: false,
      required: false
    },
    fontSize: {
      type: Number,
      default: 16
    }
  },
  data () {
    return {
      colors: [Colors.yellow2, Colors.automate2, Colors.blue2, Colors.green1, Colors.dark2],
      keybeLogo: `${process.env.VUE_APP_STORAGE_URL}/files/bluebird/conversation-module/LogoKeybeChat.svg`,
      imgUrl: ''
    }
  },
  methods: {
    randomBackgroundColor (seed, colors) {
      return colors[seed % (colors.length)]
    }
  },
  computed: {
    backgroundColor () {
      return this.randomBackgroundColor(this.name.length, this.colors)
    },
    style () {
      return {
        display: 'flex',
        backgroundColor: this.backgroundColor,
        borderRadius: `${this.size / 2}px`,
        minHeight: `${this.size}px`,
        minWidth: `${this.size}px`,
        maxHeight: `${this.size}px`,
        maxWidth: `${this.size}px`,
        lineHeight: `${(this.size + Math.floor(this.size / 20))}px`,
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        userSelect: 'none',
        color: '#fff',
        fontWeight: 400,
        fontSize: `${this.fontSize}px`
      }
    },
    nameInitials () {
      // remove all - and . from name
      const name = this.name.replace(/[-.]/g, ' ')
      return name.split(' ').map(word => word[0]).join('').slice(0, 2).toUpperCase()
    },
    showImage () {
      return this.imgUrl || this.isKeybeProfile
    }
  },
  mounted () {
    if (this.img) {
      this.imgUrl = this.img
    }
  }
}
</script>

<style scoped>
.KeybeAvatar-img {
  object-fit: cover;
  border-radius: 50%;
  background-color: white;
}
.smallText {
  font-size: 10px;
}
</style>
