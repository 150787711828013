




















































import { EmailEditor } from 'vue-email-editor'
import sampleKB from '@/assets/sampleKB.json'
import { EventBus } from '@/eventBus'
import TemplateService from '@/services/TemplateService'
import CampaignsService from '@/services/CampaignsService'
import KBButton from '@/shared/infrastructure/ui/buttons/KBButton.vue'
import KBText from '@/shared/infrastructure/ui/inputs/text/KBText.vue'
import ButtonCustomizeField from '@/modules/outbound/infrastructure/ui/components/forms/ButtonCustomizeField/ButtonCustomizeField.vue'
import { ActivityActionTypeEnum, ActivityLogModuleTypeEnum, dataToWriteActivityLog } from '@/modules/Config/ActivityLogs/domain/ActivityLogsController'
import ActivityLogsUseCases from '@/modules/Config/ActivityLogs/application/ActivityLogsUseCases'
import { CDPFieldsEnums } from '@/modules/CDP/shared/contacts/domain/enums/CDPFieldsEnums'
import ActivityLogsController from '@/modules/Config/ActivityLogs/infrastructure/controllers/ActivityLogs.controller'
import { mapState } from 'vuex'
export default {
  components: {
    EmailEditor,
    KBButton,
    KBText,
    ButtonCustomizeField
  },
  props: {
    myTemplate: {
      type: Boolean,
      default: false
    },
    templateId: {
      type: String,
      default: ''
    },
    campaignDetails: {
      type: Object,
      default: () => ({})
    },
    fromTriggers: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      changeParamsVisible: false,
      appearance: {
        theme: 'dark'
      },
      template: {},
      templateName: '',
      loadingSubmit: false,
      buttonCustomizeColor: '#8898AA',
      editName: false,
      actionName: '',
      activityLogsController: new ActivityLogsController()
    }
  },
  computed: {
    ...mapState('AppStore', ['selectedApp']),
    ...mapState('UserStore', ['token', 'user']),
    outboundName: {
      get () {
        return this.$store.state.CampaingsStore.outboundName || null
      },
      set (value) {
        this.$store.commit('CampaingsStore/SET_EDITING_OBJECT', {
          key: 'outboundName',
          value
        })
      }
    },
    currentSelectedTab: {
      get () {
        return this.$store.state.CampaingsStore.currentSelectedTab || null
      },
      set (value) {
        this.$store.commit('CampaingsStore/SET_EDITING_OBJECT', {
          key: 'currentSelectedTab',
          value
        })
      }
    },
    summaryData: {
      get () {
        return this.$store.state.CampaingsStore.summaryData || false
      },
      set (value) {
        this.$store.commit('CampaingsStore/SET_EDITING_OBJECT', {
          key: 'summaryData',
          value
        })
      }
    },
    summaryMenu: {
      get () {
        return this.$store.state.CampaingsStore.summaryMenu || false
      },
      set (value) {
        this.$store.commit('CampaingsStore/SET_EDITING_OBJECT', {
          key: 'summaryMenu',
          value
        })
      }
    },
    isDisabled () {
      return !this.template.name
    },
    sampleJson () {
      return `${process.env.VUE_APP_STORAGE_URL}/files/bluebird/campaigns/templates/sample.json`
    }
  },
  activated () {
    this.checkEdition()
  },
  mounted () {
    this.checkEdition()
  },
  methods: {
    onParams (customizedField) {
      if (this.templateName) {
        this.templateName = `${this.templateName} ${customizedField}`
      } else {
        this.templateName = customizedField
      }
      this.changeParamsVisible = false
    },
    goBack () {
      EventBus.$emit('showTemplatesList')
      this.$emit('showTemplatesList')
    },
    loadDefaultDesign () {
      this.$refs.emailEditor.editor.loadDesign(sampleKB)
    },
    checkEdition () {
      if (this.templateId) {
        const templateId = this.templateId
        this.loadDesign(templateId)
      }
    },
    async loadDesign (templateId) {
      if (templateId === '0' || templateId === 0) {
        this.loadDefaultDesign()
      } else {
        await this.getTemplate(templateId)
        const vue = this

        if (
          this.template &&
          typeof this.template === 'object' &&
          this.template.template
        ) {
          this.templateName = this.template.name
          this.editName = true
          vue.$refs.emailEditor.editor.loadDesign(this.template.template)
        } else {
          EventBus.$emit('toast', 'error', this.$t('notSavedTemplate'))
        }
      }
    },
    async getTemplate (templateId) {
      try {
        const response = await TemplateService.getTemplateByIdV6(templateId)
        this.template = response
      } catch (e) {
        this.template = {}
      }
    },
    submit () {
      if (this.templateName) {
        this.templateId ? this.updateTemplate() : this.createTemplate()
      } else {
        EventBus.$emit('toast', 'success', this.$t('titleRequired'))
      }
    },
    returnForm () {
      this.$parent.returnForm()
    },
    async writeActivityLog (type: string): Promise<void> {
      const data: dataToWriteActivityLog = {
        token: this.token,
        updateInput: {
          appUUID: this.selectedApp?.uuid,
          module: ActivityLogModuleTypeEnum.OUTBOUND,
          action: type === CDPFieldsEnums.UPDATE
            ? ActivityActionTypeEnum.TEMPLATE_UPDATE
            : ActivityActionTypeEnum.TEMPLATE_CREATE,
          user: {
            id: this.user.uuid,
            name: this.user.name + ' ' + this.user.lastName
          },
          payload: {
            field: CDPFieldsEnums.NAME,
            newValue: this.templateName,
            oldValue: ''
          }
        }
      }
      await ActivityLogsUseCases.writeActivityLog(this.activityLogsController, data)
    },
    updateTemplate () {
      const vue = this
      this.getTemplateHtml(function (html) {
        vue.loadingSubmit = true
        vue.$refs.emailEditor.editor.saveDesign(async (template) => {
          try {
            const templateId = vue.templateId
            const name = vue.templateName
            // We need to consume the endpoints Eduardo Piña left on the campaign service to prepare the launch of a campaign
            const response = await TemplateService.updateTemplateV6({
              template,
              templateId,
              html,
              name
            })
            EventBus.$emit('toast', 'success', vue.$t('templateSuccessfullyUpdated')
            )
            if (response.success) {
              vue.$parent.returnForm()
              vue.writeActivityLog(CDPFieldsEnums.UPDATE)
            }
            vue.loadingSubmit = false
          } catch (e) {
            console.log(e)
            vue.loadingSubmit = false
            EventBus.$emit('toast', 'error', e.message)
          }
        })
      })
    },
    updateTemplateSend () {
      const vue = this
      this.getTemplateHtml(function (html) {
        vue.loadingSubmit = true
        vue.$refs.emailEditor.editor.saveDesign(async (template) => {
          try {
            const templateId = vue.templateId
            const name = vue.templateName
            // We need to consume the endpoints Eduardo Piña left on the campaign service to prepare the launch of a campaign
            await TemplateService.updateTemplateV6({
              template,
              templateId,
              html,
              name
            })
            const finalDetails = vue.campaignDetails
            finalDetails.email.html = html
            finalDetails.checkEmailDomain = true
            const campaignCreated = await CampaignsService.createCampaignV6(finalDetails)
            if (campaignCreated?.response) {
              const { response } = campaignCreated
              if (response?.status === 400) {
                if (response?.errorDetails) {
                  const { errorDetails } = response
                  vue.loadingSubmit = false
                  return EventBus.$emit('toast', 'error', errorDetails[0]?.msg)
                }
                if (response?.errorMessage) {
                  const { errorMessage } = response
                  EventBus.$emit('toast', 'error', errorMessage)
                }
                vue.loadingSubmit = false
                return
              }
            }
            await CampaignsService.linkCampaignV6({
              campaignId: campaignCreated?.campaign._id,
              createFromUser: finalDetails.all === 'all'
            })
            try {
              if (vue.campaignDetails.email.attachments) {
                const arrayOfPromises = vue.campaignDetails.email.attachments.map(file => {
                  CampaignsService.uploadFileToCampaignV6(campaignCreated?.campaign._id, file)
                })
                await Promise.all(arrayOfPromises)
              }
            } catch (e) {
              EventBus.$emit('toast', 'error', e.message)
            }
            EventBus.$emit(
              'toast',
              'success',
              vue.$t('templateSuccessfullyUpdated')
            )
            if (campaignCreated) {
              vue.goToDetail(campaignCreated.campaign)
            }
            vue.loadingSubmit = false
          } catch (e) {
            console.log(e)
            vue.loadingSubmit = false
            EventBus.$emit('toast', 'error', e.message)
          }
        })
      })
    },
    onOption () {
      this.$parent.onOption()
    },
    goToDetail (item) {
      this.currentSelectedTab = 'summary'
      this.summaryMenu = true
      this.summaryData = item
      this.outboundName = item.name
    },
    getTemplateHtml (cb) {
      this.$refs.emailEditor.editor.exportHtml((data) => {
        cb(data.html)
      })
    },
    createTemplate () {
      const vue = this
      this.getTemplateHtml(function (html) {
        vue.loadingSubmit = true
        vue.$refs.emailEditor.editor.saveDesign(async (template) => {
          try {
            const name = vue.templateName
            const response = await TemplateService.saveTemplateV6({
              template,
              name,
              html
            })

            if (response.success) {
              EventBus.$emit(
                'toast',
                'success',
                vue.$t('templateSuccessfullySaved')
              )
              // vue.templateId = response.template.id
              // this.goToDetail(response.campaign)
              vue.writeActivityLog(CDPFieldsEnums.CREATE)
              vue.$parent.returnForm()
            }

            vue.loadingSubmit = false
            vue.$emit('savedTemplate', { templateId: vue.templateId, html: html })
          } catch (e) {
            vue.loadingSubmit = false
          }
        })
      })
    },
    updateName (name) {
      this.editName = false
      this.templateName = name
    }
  }
}
