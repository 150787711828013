export enum SmartOMeterTiers {
  TIER_1 = 'Tier 1',
  TIER_2 = 'Tier 2',
  TIER_3 = 'Tier 3',
  TIER_4 = 'Tier 4',
  TIER_5 = 'Tier 5',
  TIER_6 = 'Tier 6',
}

export type SmartOMeterTier = SmartOMeterTiers.TIER_1 | SmartOMeterTiers.TIER_2 | SmartOMeterTiers.TIER_3 | SmartOMeterTiers.TIER_4 | SmartOMeterTiers.TIER_5 | SmartOMeterTiers.TIER_6;
