




















































































import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import Colors from '@/shared/domain/colors/Color'
import { OnboardingSteps } from '@/modules/onboarding/domain/enums/OnboardingSteps'
import { mapGetters, mapState } from 'vuex'
import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'
export default {
  name: 'OnboardingWelcome',
  components: {
    KeybeIcon
  },
  data () {
    return {
      KeybeIconType,
      Colors,
      OnboardingSteps
    }
  },
  computed: {
    ...mapState('UserStore', ['user']),
    ...mapGetters('UserStore', ['isAgent'])
  }
}
