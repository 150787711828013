export enum FilterQuerys {
    AllConversations = 'allConversations',
    NotAttendedConversations = 'notAttendedConversations',
    InAttentionConversations = 'inAttentionConversations',
    AttendedConversations = 'attendedConversations',
    ALL = 'all',
    SELF = 'self',
    WAITING = 'waiting',
    CLOSED_BY_AI = 'closedByAi',
    CLOSED_BY_ADVISOR = 'closedByAdvisor',
    TEAM = 'team',
}
