








export default {
  name: 'SimpleChatLightIcon'
}
