export class GenericResponse {
    status: boolean
    message: string
    data: any
    code: number

    constructor ({ status, message, data, code }: {status: boolean, message: string, data: any, code?: number}) {
      this.status = status
      this.message = message
      this.data = data
      this.code = code
    }
}
