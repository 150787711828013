<template>
  <div class="dialog"
       id="'kbdialog'"
       v-if="value">
    <div :class="$vuetify.theme.dark ? 'dialog__overlay-dark' : 'dialog__overlay'"
         @click="close"></div>
    <div class="dialog__content"
         :style="`${maxWidth ? `max-width: ${maxWidth};`: ''} background-color: ${background}; padding: ${noPadding ? '0' : '20px'}; border: ${border ? border : ''}`">
      <component @cancel="cancel"
                 @close="$emit('close')"
                 @data="$emit('data')"
                 :data="data"
                 :is="dialogComponent"></component>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Dialog',
  // props: [CDPFieldsEnums.VALUE, 'dialogComponent', 'maxWidth'],
  props: {
    value: {
      type: Boolean,
      default: false,
      required: true
    },
    dialogComponent: {
      required: true
    },
    maxWidth: {
      type: String,
      default: null,
      required: false
    },
    persistent: {
      type: Boolean,
      default: false,
      required: false
    },
    background: {
      type: String,
      default: 'white',
      required: false
    },
    noPadding: {
      type: Boolean,
      default: false,
      required: false
    },
    data: {
      type: Object,
      required: false
    },
    border: {
      type: String,
      required: false
    }
  },
  methods: {
    close () {
      if (this.persistent) {
        return
      }
      this.$emit('close')
    },
    cancel () {
      this.$emit('cancel')
    }
  }
}
</script>

<style scoped lang="scss">
.dialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  // Please Verify stack position in other elements to avoid hide if you increase this value
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dialog__overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%);
  backdrop-filter: blur(5px);
}

.dialog__overlay-dark {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 100%);
  backdrop-filter: blur(5px);
}

.dialog__content {
  position: relative;
  width: 80%;
  border-radius: 15px;
  animation-name: zoom;
  animation-duration: 0.4s;
  box-shadow: 0px 0px 34px rgba(0, 0, 0, 0.25);

  @keyframes zoom {
    from {transform: scale(0.1)}
    to {transform: scale(1)}
  }
}
</style>
