import { render, staticRenderFns } from "./SignInOnboardingTutorialBox.vue?vue&type=template&id=3252fcf0&scoped=true&"
import script from "./SignInOnboardingTutorialBox.vue?vue&type=script&lang=ts&"
export * from "./SignInOnboardingTutorialBox.vue?vue&type=script&lang=ts&"
import style0 from "./SignInOnboardingTutorialBox.vue?vue&type=style&index=0&id=3252fcf0&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3252fcf0",
  null
  
)

export default component.exports