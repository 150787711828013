import { CDPFieldsEnums } from '@/modules/CDP/shared/contacts/domain/enums/CDPFieldsEnums'

export const BusinessesFiltersStore = {
  namespaced: true,
  state: {
    businessesFilters: {
      registerChannel: [],
      responsible: [],
      createdAt: {
        init: '',
        end: ''
      },
      inactive: null,
      inactiveAt: {
        init: '',
        end: ''
      },
      inactiveReason: '',
      identifier: '',
      businessType: '',
      documentType: '',
      document: '',
      taxCode: '',
      priority: '',
      taxGroup: '',
      description: '',
      alias: '',
      city: '',
      zone: '',
      nationality: '',
      affiliation: '',
      award: '',
      duns: '',
      urlPublic: '',
      publicSocialmedia: '',
      establishmentDate: '',
      establishmentCountry: '',
      establishmentProvince: '',
      establishmentCity: '',
      nps: '',
      timeZone: '',
      category: '',
      subCategory: '',
      tags: [],
      knows: [],
      hearAbout: '',
      preferredStore: '',
      preferredProduct: '',
      leadGenId: '',
      formId: '',
      source: {
        utmSource: '',
        utmMedium: '',
        utmCampaign: '',
        utmTerm: '',
        utmContent: '',
        utmPartner: '',
        channel: '',
        channelOther: ''
      },
      habeasDataStatus: '',
      headquarters: '',
      preferredLanguage: '',
      credentials: [],
      status: [],
      extendedData: [],
      emailEmpty: null,
      phoneEmpty: null,
      address: {
        country: '',
        value: '',
        province: '',
        zone: '',
        postalCode: ''
      }
    },
    businessesFiltersButtons: [
      { name: CDPFieldsEnums.BUSINESS_TYPE, value: '' },
      { name: CDPFieldsEnums.IDENTIFIER, value: '' },
      { name: CDPFieldsEnums.INACTIVE_AT, value: '' },
      { name: CDPFieldsEnums.INACTIVE_REASON, value: '' },
      { name: CDPFieldsEnums.REGISTER_CHANNEL, value: [] },
      { name: CDPFieldsEnums.RESPONSIBLE, value: [] },
      { name: CDPFieldsEnums.CREATED_AT, value: '' },
      { name: CDPFieldsEnums.INACTIVE, value: '' },
      { name: CDPFieldsEnums.AUTH_ROLES, value: [] },
      { name: CDPFieldsEnums.STATUS, value: [] },
      { name: CDPFieldsEnums.HABEAS_DATA_STATUS, value: '' },
      { name: CDPFieldsEnums.DOCUMENT_TYPE, value: '' },
      { name: CDPFieldsEnums.DOCUMENT, value: '' },
      { name: CDPFieldsEnums.TAX_CODE, value: '' },
      { name: CDPFieldsEnums.PRIORITY, value: '' },
      { name: CDPFieldsEnums.TAX_GROUP, value: '' },
      { name: CDPFieldsEnums.DESCRIPTION, value: '' },
      { name: CDPFieldsEnums.ALIAS, value: '' },
      { name: CDPFieldsEnums.CITY, value: '' },
      { name: CDPFieldsEnums.ZONE, value: '' },
      { name: CDPFieldsEnums.AFFILIATION, value: '' },
      { name: CDPFieldsEnums.AWARD, value: '' },
      { name: CDPFieldsEnums.DUNS, value: '' },
      { name: CDPFieldsEnums.URL_PUBLIC, value: '' },
      { name: CDPFieldsEnums.PUBLIC_SOCIAL_MEDIA, value: '' },
      { name: CDPFieldsEnums.ESTABLISHMENT_DATE, value: '' },
      { name: CDPFieldsEnums.ESTABLISHMENT_COUNTRY, value: '' },
      { name: CDPFieldsEnums.ESTABLISHMENT_PROVINCE, value: '' },
      { name: CDPFieldsEnums.ESTABLISHMENT_CITY, value: '' },
      { name: CDPFieldsEnums.NPS, value: '' },
      { name: CDPFieldsEnums.TIME_ZONE, value: '' },
      { name: CDPFieldsEnums.CATEGORY, value: '' },
      { name: CDPFieldsEnums.SUB_CATEGORY, value: '' },
      { name: CDPFieldsEnums.TAGS, value: [] },
      { name: CDPFieldsEnums.KNOWS, value: [] },
      { name: CDPFieldsEnums.HEAR_ABOUT, value: '' },
      { name: CDPFieldsEnums.PREFERRED_STORE, value: '' },
      { name: CDPFieldsEnums.PREFERRED_PRODUCT, value: '' },
      { name: CDPFieldsEnums.LEAD_GEN_ID, value: '' },
      { name: CDPFieldsEnums.FORM_ID, value: '' },
      { name: CDPFieldsEnums.UTM_SOURCE, value: '' },
      { name: CDPFieldsEnums.UTM_MEDIUM, value: '' },
      { name: CDPFieldsEnums.UTM_CAMPAIGN, value: '' },
      { name: CDPFieldsEnums.UTM_TERM, value: '' },
      { name: CDPFieldsEnums.UTM_CONTENT, value: '' },
      { name: CDPFieldsEnums.UTM_PARTNER, value: '' },
      { name: CDPFieldsEnums.CHANNEL, value: '' },
      { name: CDPFieldsEnums.CHANNEL_OTHER, value: '' },
      { name: CDPFieldsEnums.HEADQUARTERS, value: '' },
      { name: CDPFieldsEnums.SOCIOECONOMIC_STATUS, value: '' },
      { name: CDPFieldsEnums.PREFERRED_LANGUAGE, value: '' },
      { name: CDPFieldsEnums.CREDENTIALS, value: [] },
      { name: CDPFieldsEnums.EXTENDED_DATA, value: [] },
      { name: CDPFieldsEnums.EMAIL, value: '' },
      { name: CDPFieldsEnums.PHONE, value: '' },
      { name: CDPFieldsEnums.ADDRESS_COUNTRY, value: '' },
      { name: CDPFieldsEnums.ADDRESS_PROVINCE, value: '' },
      { name: CDPFieldsEnums.ADDRESS_CITY, value: '' },
      { name: CDPFieldsEnums.ADDRESS_ZONE, value: '' },
      { name: CDPFieldsEnums.ADDRESS_POSTAL_CODE, value: '' },
      { name: CDPFieldsEnums.ADDRESS_VALUE, value: '' }
    ]
  },
  mutations: {
    SET_ACTIVE_FILTERS (state: any, filters: any) {
      state.businessesFilters = filters
    },
    SET_ACTIVE_FILTERS_BUTTONS (state: any, buttons: any) {
      state.businessesFilters = buttons
    },
    SET_FILTERS_ACTIVE (state: any, active: boolean) {
      state.filtersActiveBusinesses = active
    },
    CLEAR_FILTERS (state: any) {
      state.businessesFilters = {
        registerChannel: [],
        responsible: [],
        createdAt: {
          init: '',
          end: ''
        },
        inactive: 0,
        inactiveAt: {
          init: '',
          end: ''
        },
        inactiveReason: '',
        identifier: '',
        businessType: '',
        documentType: '',
        document: '',
        taxCode: '',
        priority: '',
        taxGroup: '',
        description: '',
        alias: '',
        city: '',
        zone: '',
        nationality: '',
        affiliation: '',
        award: '',
        duns: '',
        urlPublic: '',
        publicSocialmedia: '',
        establishmentDate: '',
        establishmentCountry: '',
        establishmentProvince: '',
        establishmentCity: '',
        nps: '',
        timeZone: '',
        category: '',
        subCategory: '',
        tags: [],
        knows: [],
        hearAbout: '',
        preferredStore: '',
        preferredProduct: '',
        leadGenId: '',
        formId: '',
        source: {
          utmSource: '',
          utmMedium: '',
          utmCampaign: '',
          utmTerm: '',
          utmContent: '',
          utmPartner: '',
          channel: '',
          channelOther: ''
        },
        habeasDataStatus: '',
        headquarters: '',
        preferredLanguage: '',
        credentials: [],
        status: [],
        extendedData: [],
        emailEmpty: null,
        phoneEmpty: null,
        address: {
          country: '',
          value: '',
          province: '',
          zone: '',
          postalCode: ''
        }
      }

      state.businessesFiltersButtons = [
        { name: CDPFieldsEnums.BUSINESS_TYPE, value: '' },
        { name: CDPFieldsEnums.IDENTIFIER, value: '' },
        { name: CDPFieldsEnums.INACTIVE, value: '' },
        { name: CDPFieldsEnums.INACTIVE_AT, value: '' },
        { name: CDPFieldsEnums.INACTIVE_REASON, value: '' },
        { name: CDPFieldsEnums.REGISTER_CHANNEL, value: [] },
        { name: CDPFieldsEnums.RESPONSIBLE, value: [] },
        { name: CDPFieldsEnums.CREATED_AT, value: '' },
        { name: CDPFieldsEnums.AUTH_ROLES, value: [] },
        { name: CDPFieldsEnums.STATUS, value: [] },
        { name: CDPFieldsEnums.HABEAS_DATA_STATUS, value: '' },
        { name: CDPFieldsEnums.DOCUMENT_TYPE, value: '' },
        { name: CDPFieldsEnums.DOCUMENT, value: '' },
        { name: CDPFieldsEnums.TAX_CODE, value: '' },
        { name: CDPFieldsEnums.PRIORITY, value: '' },
        { name: CDPFieldsEnums.TAX_GROUP, value: '' },
        { name: CDPFieldsEnums.DESCRIPTION, value: '' },
        { name: CDPFieldsEnums.ALIAS, value: '' },
        { name: CDPFieldsEnums.CITY, value: '' },
        { name: CDPFieldsEnums.ZONE, value: '' },
        { name: CDPFieldsEnums.AFFILIATION, value: '' },
        { name: CDPFieldsEnums.AWARD, value: '' },
        { name: CDPFieldsEnums.DUNS, value: '' },
        { name: CDPFieldsEnums.URL_PUBLIC, value: '' },
        { name: CDPFieldsEnums.PUBLIC_SOCIAL_MEDIA, value: '' },
        { name: CDPFieldsEnums.ESTABLISHMENT_DATE, value: '' },
        { name: CDPFieldsEnums.ESTABLISHMENT_COUNTRY, value: '' },
        { name: CDPFieldsEnums.ESTABLISHMENT_PROVINCE, value: '' },
        { name: CDPFieldsEnums.ESTABLISHMENT_CITY, value: '' },
        { name: CDPFieldsEnums.NPS, value: '' },
        { name: CDPFieldsEnums.TIME_ZONE, value: '' },
        { name: CDPFieldsEnums.CATEGORY, value: '' },
        { name: CDPFieldsEnums.SUB_CATEGORY, value: '' },
        { name: CDPFieldsEnums.TAGS, value: [] },
        { name: CDPFieldsEnums.KNOWS, value: [] },
        { name: CDPFieldsEnums.HEAR_ABOUT, value: '' },
        { name: CDPFieldsEnums.PREFERRED_STORE, value: '' },
        { name: CDPFieldsEnums.PREFERRED_PRODUCT, value: '' },
        { name: CDPFieldsEnums.LEAD_GEN_ID, value: '' },
        { name: CDPFieldsEnums.FORM_ID, value: '' },
        { name: CDPFieldsEnums.UTM_SOURCE, value: '' },
        { name: CDPFieldsEnums.UTM_MEDIUM, value: '' },
        { name: CDPFieldsEnums.UTM_CAMPAIGN, value: '' },
        { name: CDPFieldsEnums.UTM_TERM, value: '' },
        { name: CDPFieldsEnums.UTM_CONTENT, value: '' },
        { name: CDPFieldsEnums.UTM_PARTNER, value: '' },
        { name: CDPFieldsEnums.CHANNEL, value: '' },
        { name: CDPFieldsEnums.CHANNEL_OTHER, value: '' },
        { name: CDPFieldsEnums.HEADQUARTERS, value: '' },
        { name: CDPFieldsEnums.SOCIOECONOMIC_STATUS, value: '' },
        { name: CDPFieldsEnums.PREFERRED_LANGUAGE, value: '' },
        { name: CDPFieldsEnums.CREDENTIALS, value: [] },
        { name: CDPFieldsEnums.EXTENDED_DATA, value: [] },
        { name: CDPFieldsEnums.EMAIL, value: '' },
        { name: CDPFieldsEnums.PHONE, value: '' },
        { name: CDPFieldsEnums.ADDRESS_COUNTRY, value: '' },
        { name: CDPFieldsEnums.ADDRESS_PROVINCE, value: '' },
        { name: CDPFieldsEnums.ADDRESS_CITY, value: '' },
        { name: CDPFieldsEnums.ADDRESS_ZONE, value: '' },
        { name: CDPFieldsEnums.ADDRESS_POSTAL_CODE, value: '' },
        { name: CDPFieldsEnums.ADDRESS_VALUE, value: '' }
      ]
    }
  },
  actions: {
    setActiveFiltersBusinesses ({ commit }, filters: any) {
      commit('SET_ACTIVE_FILTERS', filters)
    },
    setFiltersActiveBusinesses ({ commit }, active: boolean) {
      commit('SET_FILTERS_ACTIVE', active)
    },
    clearBusinessesFilters ({ commit }) {
      commit('CLEAR_FILTERS')
    }
  }
}
