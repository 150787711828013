import { EventBus } from '@/eventBus'
import { ConfigurationTabsEnum } from '@/modules/configuration/application/enums/ConfigurationTabsEnum'
import ConfigurationSidebar from '@/modules/configuration/infrastructure/ui/components/ConfigurationSidebar/ConfigurationSidebar.vue'
import GeneralUserConfigurationContainer from '@/modules/Config/GeneralUserConfiguration/infrastructure/components/GeneralUserConfigurationContainer/GeneralUserConfigurationContainer.vue'
import CatalogConfig from '@/modules/catalog/infrastructure/ui/CatalogConfig/CatalogConfig.vue'
import DomainsConfig from '@/modules/configuration/infrastructure/ui/components/domains/DomainsConfig.vue'
import BrandsConfig from '@/modules/configuration/infrastructure/ui/components/brands/BrandsConfig.vue'
import ScheduleConfig from '@/modules/configuration/infrastructure/ui/components/schedule/ScheduleConfig/ScheduleConfig.vue'
import AutomaticClosingConfig from '@/modules/configuration/infrastructure/ui/components/AutomaticClosing/AutomaticClosingConfig.vue'
import TagsConfiguration from '@/modules/Config/TagsConfiguration/TagsConfiguration.vue'
import QuickResponsesConfiguration from '../../../../../Config/QuickResponsesConfiguration/infrastructure/components/QuickResponsesConfiguration/QuickResponsesConfiguration.vue'
import AutoassignConfig from '@/modules/configuration/infrastructure/ui/components/Autoassign/AutoassignConfig.vue'
import WebWidgetConfig from '@/modules/configuration/infrastructure/ui/components/WebWidget/WebWidgetConfig.vue'
import ChannelsConfig from '@/modules/configuration/infrastructure/ui/components/Channels/ChannelsConfig.vue'
import NewChannelsConfig from '@/modules/configuration/infrastructure/ui/components/NewChannels/ChannelsConfig.vue'
import OldChannelsConfig from '@/modules/configuration/infrastructure/ui/components/OldChannels/OldChannelsConfig.vue'
import TeamConfigurationContainer from '@/modules/Config/TeamConfiguration/infrastructure/components/TeamConfigurationContainer/TeamConfigurationContainer.vue'
// import TeamConfig from '../../components/team/TeamConfig/TeamConfig.vue'
import KeybeAiConfig from '@/modules/configuration/infrastructure/ui/components/KeybeAi/KeybeAiConfig.vue'
import APIKeysContainer from '@/modules/APIKeysConfig/infrastructure/components/APIKeysContainer/APIKeysContainer.vue'
import ExtraDataConfig from '@/modules/ExtraData/infrastructure/components/ExtraDataConfig/ExtraDataConfig.vue'
import SmartOMeterConfig from '@/modules/ai/infrastructure/ui/SmartOMeterConfig/SmartOMeterConfig.vue'
import BrandConfigurationContainer from '@/modules/Config/BrandConfiguration/infrastructure/BrandConfigurationContainer/BrandConfigurationContainer.vue'
import BikyConfigController from '@/modules/bky/infrastructure/controllers/BikyConfig.controller'
import CDPFieldsConfigContainer from '@/modules/configuration/infrastructure/ui/components/AppFieldsConfig/CDP/CDPFieldsConfigContainer/CDPFieldsConfigContainer.vue'
import FunnelsFieldsConfigContainer from '@/modules/configuration/infrastructure/ui/components/AppFieldsConfig/Funnels/FunnelsFieldsConfigContainer/FunnelsFieldsConfigContainer.vue'
import PermissionTypes from '@/modules/Config/TeamConfiguration/infrastructure/components/PermissionTypes/PermissionTypes.vue'
import AppPrivacySecurity from '@/modules/configuration/infrastructure/ui/components/AppPrivacySecurity/AppPrivacySecurity.vue'
import CommissionsConfigMain from '@/modules/configuration/infrastructure/ui/components/CommissionsConfig/CommissionsConfigMain/CommissionsConfigMain.vue'
import StatusConfig from '@/modules/Statuses/infrastructure/components/StatusConfig/StatusConfig.vue'
import TemplateList from '@/modules/createTemplates/infrastructure/ui/TemplatesList/TemplatesList.vue'
import SpacesConfig from '@/modules/Spaces/infrastructure/ui/SpacesConfig/SpacesConfig.vue'
import InboxConfig from '@/modules/inbox/infrastructure/ui/InboxConfig/InboxConfig.vue'
import { VueConstructor } from 'vue'
import { mapGetters, mapState } from 'vuex'
// import WelcomeMessageConfig from '../../components/WelcomeMessage/WelcomeMessageConfig/WelcomeMessageConfig.vue'
import RolesContainer from '@/modules/roles/infrastructure/ui/RolesContainer/RolesContainer'
import ActivityLogs from '@/modules/Config/ActivityLogs/infrastructure/components/ActivityLogs/ActivityLogs.vue'
import CreateFormConfig from '@/modules/Config/WidgetTesseract/infrastructure/ui/CreateFormConfig.vue'

import AlertModalFullScreen from '@/modules/AlertModalFullScreen/AlertModalFullScreen.vue'
import { BikyConfig } from '@/modules/bky/domain/BikyConfig'
import { BikyConfigUseCases } from '@/modules/bky/application/BikyConfigUseCases'
import BikyStoreController from '@/modules/bky/infrastructure/persistence/BikyStore.controller'
export default {
  name: 'ConfigurationScreen',
  components: {
    ConfigurationSidebar,
    AlertModalFullScreen
  },
  mounted () {
    this.getBikyConfig()
    EventBus.$on('setConfigurationTab', this.setConfigurationTab)
  },
  beforeDestroy () {
    this.showModalFScreen = false
    this.showModalData = {}
    EventBus.$off('setConfigurationTab', this.setConfigurationTab)
  },
  data () {
    return {
      selectedTab: ConfigurationTabsEnum.GENERAL,
      ConfigurationTabsEnum,
      bikyConfig: null,
      bikyConfigController: new BikyConfigController()
    }
  },
  methods: {
    selectTab (tab: string): void {
      if (this.selectedTab === tab) {
        return
      }

      if (this.selectedTab === ConfigurationTabsEnum.MY_QUICK_RESPONSES && tab === ConfigurationTabsEnum.QUICK_RESPONSES) {
        this.selectedTab = ConfigurationTabsEnum.GENERAL
        this.$nextTick(() => {
          this.selectedTab = tab
        })
        return
      }

      this.selectedTab = tab
    },
    setConfigurationTab (tab: string): void {
      this.selectedTab = tab
    },
    async getBikyConfig (): Promise<void> {
      const config: BikyConfig = await BikyConfigUseCases.getBikyConfig(this.bikyConfigController, {
        token: this.token,
        appUUID: this.selectedApp?.uuid
      })

      BikyStoreController.setConfig(config)

      this.bikyConfig = config
    }
  },
  computed: {
    ...mapState('UserStore', ['token']),
    ...mapState('AppStore', ['selectedApp']),
    ...mapGetters('UserStore', ['isAdmin', 'isSuperAdmin', 'isTrainer']),
    selectedTabComponent (): VueConstructor {
      const components = {
        [ConfigurationTabsEnum.GENERAL]: GeneralUserConfigurationContainer,
        [ConfigurationTabsEnum.CATALOG]: CatalogConfig,
        [ConfigurationTabsEnum.DOMAINS]: DomainsConfig,
        [ConfigurationTabsEnum.BRANDS]: BrandsConfig,
        [ConfigurationTabsEnum.SCHEDULE]: ScheduleConfig,
        [ConfigurationTabsEnum.AUTOMATIC_CLOSING]: AutomaticClosingConfig,
        [ConfigurationTabsEnum.TAGS]: TagsConfiguration,
        [ConfigurationTabsEnum.APP_INFO]: BrandConfigurationContainer,
        [ConfigurationTabsEnum.QUICK_RESPONSES]: QuickResponsesConfiguration,
        [ConfigurationTabsEnum.MY_QUICK_RESPONSES]: QuickResponsesConfiguration,
        [ConfigurationTabsEnum.AUTOASSIGN]: AutoassignConfig,
        [ConfigurationTabsEnum.STATUSES]: StatusConfig,
        [ConfigurationTabsEnum.WEB_WIDGET]: WebWidgetConfig,
        [ConfigurationTabsEnum.CONNECT_CHANNELS]: ChannelsConfig,
        [ConfigurationTabsEnum.NEW_CHANNELS]: NewChannelsConfig,
        [ConfigurationTabsEnum.OLD_CHANNELS]: OldChannelsConfig,
        [ConfigurationTabsEnum.TEAM]: TeamConfigurationContainer,
        [ConfigurationTabsEnum.KEYBE_AI]: KeybeAiConfig,
        [ConfigurationTabsEnum.COMMISSIONS]: CommissionsConfigMain,
        [ConfigurationTabsEnum.INBOX]: InboxConfig,
        [ConfigurationTabsEnum.ROLES_V6]: RolesContainer,
        // [ConfigurationTabsEnum.WELCOME_MESSAGE]: WelcomeMessageConfig,
        [ConfigurationTabsEnum.API_KEYS]: APIKeysContainer,
        [ConfigurationTabsEnum.CDP_FIELDS]: CDPFieldsConfigContainer,
        [ConfigurationTabsEnum.FUNNEL_FIELDS]: FunnelsFieldsConfigContainer,
        [ConfigurationTabsEnum.PRIVACY_SECURITY]: AppPrivacySecurity,
        [ConfigurationTabsEnum.EXTRADATA]: ExtraDataConfig,
        [ConfigurationTabsEnum.SMARTOMETER]: SmartOMeterConfig,
        [ConfigurationTabsEnum.TEMPLATES]: TemplateList,
        [ConfigurationTabsEnum.PERMISSION_TYPES]: PermissionTypes,
        [ConfigurationTabsEnum.SPACES]: SpacesConfig,
        [ConfigurationTabsEnum.ACTIVITY_LOGS]: ActivityLogs,
        [ConfigurationTabsEnum.WIDGET_TESSERACT]: CreateFormConfig
      }

      return components[this.selectedTab]
    },
    showSidebar (): boolean {
      return this.isAdmin || this.isSuperAdmin || (this.isTrainer && this.bikyConfig?.active)
    },
    showModalFScreen: {
      get () {
        return this.$store.state.AppStore.showModalFScreen
      },
      set (_) {
        // this.$store.commit('AppStore/SHOW_MODAL_FULL_SCREEN', value)
      }
    },
    showModalData: {
      get () {
        return this.$store.state.AppStore.showModalData
      },
      set (_) {
        // this.$store.commit('AppStore/UPDATE_MODAL_FULL_SCREEN', value)
      }
    }
  }
}
