import { Paginator } from '@/shared/domain/Paginator'
import { Notification } from '../domain/Notification'
import {
  INotificationController,
  dataToCountNotifications,
  dataToCreateNotification,
  dataToGetAllNotifications,
  dataToGetNotifications,
  dataToMarkNotificationAsViewed,
  notificationCounter,
  notificationGetter,
  notificationViewed
} from '../domain/NotificationController'

export default class NotificationsUseCases {
  static canAskMoreNotifications (
    controller: INotificationController,
    data: Notification[],
    paginator: Paginator,
    offset: number
  ): boolean {
    return controller.canAskMoreNotifications(data, paginator, offset)
  }

  static abort (controller: INotificationController): void {
    controller.abort()
  }

  static async getAllNotifications (
    controller: INotificationController,
    data: dataToGetAllNotifications
  ): Promise<notificationGetter> {
    return await controller.getAllNotifications(data)
  }

  static async getNotifications (
    controller: INotificationController,
    data: dataToGetNotifications
  ): Promise<notificationGetter> {
    return await controller.getNotifications(data)
  }

  static async createNotification (
    controller: INotificationController,
    data: dataToCreateNotification
  ): Promise<Notification> {
    return await controller.createNotification(data)
  }

  static async markNotificationAsViewed (
    controller: INotificationController,
    data: dataToMarkNotificationAsViewed
  ): Promise<notificationViewed> {
    return await controller.markNotificationAsViewed(data)
  }

  static async countNotifications (
    controller: INotificationController,
    data: dataToCountNotifications
  ): Promise<notificationCounter> {
    return await controller.countNotifications(data)
  }
}
