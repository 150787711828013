import { NotificationsSoundKeys } from '@/components/conversations/enums/NotificationsSoundKeys'

export class KeybeUserExtradata {
  static extradata = [];

  constructor (extradata) {
    KeybeUserExtradata.extradata = extradata || []
  }

  getExtradata () {
    return KeybeUserExtradata.extradata
  }

  setExtradata (extradata) {
    KeybeUserExtradata.extradata = extradata
  }

  hasExtradata () {
    return KeybeUserExtradata.extradata.length > 0
  }

  findExtradata (key) {
    return KeybeUserExtradata.extradata.find(extradata => extradata.property === key)
  }

  addExtradata (extradata) {
    KeybeUserExtradata.extradata.push(extradata)
  }

  updateExtradata (extradata) {
    if (!this.hasExtradata()) {
      return this.addExtradata(extradata)
    }
    const actualExtradata = this.findExtradata(extradata.property)
    if (!actualExtradata) {
      return this.addExtradata(extradata)
    }
    actualExtradata.value = extradata.value
  }

  removeExtradata (key) {
    const index = KeybeUserExtradata.extradata.findIndex(extradata => extradata.property === key)
    if (index === -1) {
      return
    }
    KeybeUserExtradata.extradata.splice(index, 1)
  }

  isBusinessOwner () {
    if (!this.hasExtradata()) {
      return false
    }
    const extradata = this.findExtradata('isBusinessOwner')
    if (!extradata) {
      return false
    }
    if (extradata.value !== '1') {
      return false
    }
    return true
  }

  getOnboardingData () {
    const defaultData = {
      appTour: false,
      firstTemplate: false,
      channelsConnected: false,
      teamInvited: false,
      contactsUploaded: false,
      databaseUploaded: false,
      massMailingTour: false
    }
    if (!this.hasExtradata()) {
      return defaultData
    }
    const extradata = this.findExtradata('onboarding')
    if (!extradata) {
      return defaultData
    }
    const data = JSON.parse(extradata.value)
    if (Object.keys(data).length === 0) {
      return defaultData
    }

    return data
  }

  getLanguage () {
    const data = this.findExtradata('lang')
    if (!data) {
      return ''
    }
    return data.value
  }

  getMode () {
    const data = this.findExtradata('mode')
    if (!data) {
      return 'light'
    }
    return data.value
  }

  getTone () {
    const data = this.findExtradata('tone')
    if (!data) {
      return NotificationsSoundKeys.KB_CLASSIC
    }
    return data.value
  }
}
