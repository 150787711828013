























import OutboundScreen from './OutboundScreen'
export default OutboundScreen

