













import WhatsappMigrateScreen from './WhatsappMigrateScreen'

export default WhatsappMigrateScreen
