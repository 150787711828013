import { EventBus } from '@/eventBus'
import KeybeButton from '@/modules/DesignSystem/infrastructure/components/KeybeButton/KeybeButton.vue'
import KeybeSelect from '@/modules/DesignSystem/infrastructure/components/KeybeSelect/KeybeSelect/KeybeSelect.vue'
import KeybeTextarea from '@/modules/DesignSystem/infrastructure/components/KeybeTextarea/KeybeTextarea.vue'
import Loader from '@/shared/infrastructure/ui/Loader.vue'
import Colors from '@/utils/Colors'
import { dataToGetEndReaseon } from '@/modules/Sales/cards/domain/models/CardsController'
import CardsUseCases from '@/modules/Sales/cards/application/CardsUseCases'
import { mapState } from 'vuex'
import CardsController from '@/modules/Sales/cards/infrastructure/controllers/Cards.controller'
import { CDPFieldsEnums } from '@/modules/CDP/shared/contacts/domain/enums/CDPFieldsEnums'
export default {
  props: {
    data: {
      type: Object
    }
  },
  components: {
    KeybeButton,
    KeybeTextarea,
    KeybeSelect,
    Loader
  },
  computed: {
    ...mapState('AppStore', ['selectedApp']),
    ...mapState('UserStore', ['token']),
    isArrayNotEmpty () {
      return this.reasonOptions && this.reasonOptions.length > 0
    },
    toStepName () {
      return this.data?.toStep?.name || ''
    },
    isWin (): boolean {
      return this.data?.toStep?.winProbability === 100
    }
  },
  mounted () {
    this.getReason()
  },
  data () {
    return {
      loading: false,
      reason: '',
      reasonOptions: [],
      Colors,
      cardsController: new CardsController(),
      selectedOther: false,
      CDPFieldsEnums
    }
  },
  methods: {
    async getReason (): Promise<void> {
      const data: dataToGetEndReaseon = {
        app: this.selectedApp?.uuid,
        token: this.token
      }
      const response = await CardsUseCases.getTerminationReason(this.cardsController, data)
      if (response && response?.data && response?.data[0]?.options) {
        const { options } = response.data[0]
        this.reasonOptions = options
        this.reasonOptions.push(this.$t(CDPFieldsEnums.OTHER))
      } else {
        this.reasonOptions = []
      }
    },
    selectReason (reason: string) {
      if (reason === this.$t(CDPFieldsEnums.OTHER)) {
        this.selectedOther = true
        return
      }
      this.reason = reason
      EventBus.$emit(
        'selectLostReason--sales',
        reason,
        this.data?.toStep,
        this.data?.fromStep,
        this.data?.card,
        this.data?.index
      )
      this.$emit('close')
    }
  }
}
