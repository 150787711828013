import store from '@/store'
import axios from 'axios'
import { RestResponseFactory } from '@/models/apis/RestResponse'
import { StatusCodes } from '@/models/apis/StatusCodes'

export default class AppConfigService {
  static async getPartnerConfig (appUUID: string) {
    try {
      const token = store.getters['UserStore/getToken']
      const url = `${process.env.VUE_APP_AUTH_SERVICE}apps/${appUUID}`
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      return new RestResponseFactory().process({ response })
    } catch (e) {
      return new RestResponseFactory().process({ response: { status: 500 } })
    }
  }

  static async getConfig () {
    try {
      const appId = store.getters['AppStore/getSelectedApp']?.uuid
      const token = store.getters['UserStore/getToken']
      const url = `${process.env.VUE_APP_AUTH_SERVICE}apps/${appId}`
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      return new RestResponseFactory().process({ response })
    } catch (e) {
      return new RestResponseFactory().process({ response: { status: 500 } })
    }
  }

  static async updateConfig (config) {
    try {
      const token = store.getters['UserStore/getToken']
      const url = `${process.env.VUE_APP_AUTH_SERVICE}apps`
      const response = await axios.put(url, config, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      return new RestResponseFactory().process({ response })
    } catch (e) {
      return e?.response?.data?.error || new RestResponseFactory().process({ response: { status: 500 } })
    }
  }

  //  Public Key
  static async getPublicKey () {
    try {
      const token = store.getters['UserStore/getV4Token']
      const url = `${process.env.VUE_APP_BASE_URL}/users/genpublickey`
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      return response.data
    } catch (e) {
      console.log(e)
    }
  }

  static async generateKeys (data) {
    try {
      const token = store.getters['UserStore/getV4Token']
      const url = `${process.env.VUE_APP_BASE_URL}/users/genpublickey`
      const response = await axios.post(url, data, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      return response.data
    } catch (e) {
      console.log(e)
    }
  }

  static async updateApp (data) {
    try {
      const token = store.getters['UserStore/getV4Token']
      const url = `${process.env.VUE_APP_AUTH_SERVICE}apps`
      const response = await axios.put(url, data, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      return new RestResponseFactory().process({ response })
    } catch (e) {
      return new RestResponseFactory().process({ response: { status: StatusCodes.HTTP_500_INTERNAL_SERVER_ERROR } })
    }
  }

  static async getBillingByApp (data) {
    try {
      const token = store.getters['UserStore/getV4Token']
      const companyId = store.getters['CompanyStore/getSelectedCompany']?.uuid
      const appId = store.getters['AppStore/getSelectedApp']?.uuid
      const url = `${process.env.VUE_APP_V4_CONVERSATIONS_API}billing/company/${companyId}/app/${appId}/billing`
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`
        },
        params: {
          fromDate: data.fromDate + 'T00:00:00',
          toDate: data.toDate + 'T23:59:00'
        }
      })
      return response.data
    } catch (e) {
      console.log(e)
    }
  }

  static async getWizardReport () {
    try {
      const token = store.getters['UserStore/getToken']
      const url = `${process.env.VUE_APP_AUTH_SERVICE}wizard/report`
      const response = await axios.post(url, {}, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      return response.data
    } catch (e) {
      console.log(e)
    }
  }
}
