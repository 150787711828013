import KeybeButton from '@/modules/DesignSystem/infrastructure/components/KeybeButton/KeybeButton.vue'
import Colors from '@/utils/Colors'
import { mapState } from 'vuex'
export default {
  props: {
    hasErrors: {
      type: Boolean
    },
    step: {
      type: Number
    },
    isCampaigns: {
      type: Boolean
    }
  },
  components: {
    KeybeButton
  },
  data () {
    return {
      Colors
    }
  },
  computed: {
    ...mapState('UserStore', ['isUserReadonly']),
    buttonText () {
      return this.step === 1 || this.step === 2 ? this.$t('continueImportation') : this.$t('allOkContinueImport')
    },
    buttonDisabled () {
      if (this.isUserReadonly) return true
      return this.step === 2 && this.hasErrors
    }
  }
}
