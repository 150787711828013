<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 14 15"><path
      :fill="color"
      d="M0 6.993C0 3.159 3.09-.014 7.013 0A6.966 6.966 0 0 1 14 7a6.966 6.966 0 0 1-7.004 7.002c-3.96 0-7.007-3.212-6.996-7.01Zm1.316-.234a5.66 5.66 0 0 0 5.554 5.937c2.964.073 5.776-2.163 5.821-5.662.039-3.057-2.303-5.625-5.555-5.726a5.654 5.654 0 0 0-5.82 5.451Z"/><path
        :fill="color"
        d="M6.343 9.198V7.454c-.142-.02-.275-.03-.404-.062-.282-.071-.454-.307-.456-.607-.002-.297.169-.579.448-.605.42-.038.848-.036 1.267 0 .265.024.43.296.447.614.01.189.004.377.004.566v1.625c0 .065.005.13.008.2.18.01.344.003.504.03.374.067.593.326.576.659-.017.352-.274.615-.66.623-.717.015-1.436.014-2.153 0-.281-.005-.525-.123-.622-.42-.084-.256-.06-.517.157-.683.143-.11.348-.147.532-.192.107-.026.227-.005.35-.005l.002.001ZM7.865 4.366c.002.502-.354.866-.855.874-.5.01-.873-.365-.87-.875.002-.504.364-.867.862-.866a.842.842 0 0 1 .863.866v.001Z"/></svg>
</template>

<script>
export default {
  name: 'InfoIcon',
  props: {
    color: {
      type: String,
      default: '#B6BEC7'
    }
  }
}
</script>

<style>

</style>
