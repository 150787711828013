export enum SalesFieldsEnum {
  FUNNEL = 'funnel',
  STEP = 'step',
  CARD = 'card',
  RESPONSIBLE = 'responsible',
  _ID = '_id',
  ID = 'id',
  BUSINESS = 'business',
  PRIORITY = 'priority',
  UTM_VALUE = 'utmValue',
  UTM_PARTNER = 'utmPartner',
  UTM_SOURCE = 'utmSource',
  FORM_ID = 'formId',
  LEAD_GEN_ID = 'leadGenId',
  QUOTE_STATUS = 'quoteStatus',
  PRODUCTS = 'products',
  PRODUCT = 'product',
  FINANCING = 'financing',
  FINANCING_DATE_INIT = 'financingDateInit',
  FINANCING_DATE_END = 'financingDateEnd',
  REASON = 'reason',
  CREATED_AT_INIT = 'createdAtInit',
  CREATED_AT_END = 'createdAtEnd',
  EXTENDED_DATA = 'extendedData',
  SALES_FUNNEL = 'salesFunnel',
  SALES_STEP ='salesStep',
  SALES_RESPONSIBLE ='salesResponsible',
  SALES_BUSINESS ='salesBusiness',
  SALES_PRIORITY ='salesPriority',
  SALES_UTM_VALUE ='salesUtmValue',
  SALES_UTM_PARTNER ='salesUtmPartner',
  SALES_UTM_SOURCE ='salesUtmSource',
  SALES_FORM_ID ='salesFormId',
  SALES_LEAD_GEN_ID ='salesLeadGenId',
  SALES_QUOTE_STATUS ='salesQuoteStatus',
  SALES_PRODUCTS ='salesProducts',
  SALES_FINANCING ='salesFinancing',
  SALES_DATE_INIT ='salesFinancingDateInit',
  SALES_DATE_END ='salesFinancingDateEnd',
  SALES_REASON ='salesReason',
  SALES_CREATED_AT_INIT ='salesCreatedAtInit',
  SALES_CREATED_AT_END ='salesCreatedAtEnd',
  SALES_EXTEDED_DATA ='salesExtendedData',
  SALES_CARDS = 'salesCards',
  QUOTE_DATE = 'quoteDate',
}
