import {
  CategoryDeleted,
  dataToCreateCategory, dataToDeleteCategory,
  dataToGetCategories,
  ICategoryController
} from '../domain/models/CategoryController'

export default class CategoryUseCases {
  static async getCategories (controller, data: dataToGetCategories) {
    return await controller.getCategories(data)
  }

  static async createCategory (controller, data: dataToCreateCategory) {
    return await controller.createCategory(data)
  }

  static async updateCategory (controller, data) {
    return await controller.updateCategory(data)
  }

  static async updateCategoryStatus (controller, data) {
    return await controller.updateCategoryStatus(data)
  }

  //
  // This method is for deleting a category
  // - It receives a controller and data to delete a category
  // - It returns a promise with the deleted category
  // @param controller: ICategoryController
  // @param data: dataToDeleteCategory
  // @returns Promise<CategoryDeleted>
  //
  static async deleteCategory (controller: ICategoryController, data: dataToDeleteCategory): Promise<CategoryDeleted> {
    return await controller.deleteCategory(data)
  }
}
