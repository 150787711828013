<template>
  <div v-click-outside="close"
       class="KBSelect"
       :class="{ 'KBSelect--open': showSelector,
                 'w-full': !transparent,
                 'w-auto': transparent }"
       :style="{ minWidth: minWidth }">
    <div class="KBSelect__container--seamless"
         :class="{ 'cursor-default': disabled }"
         v-if="transparent"
         @click="openSelector">
      <span class="seamless-text"
            v-if="selectedItem">{{ itemToShow }}</span>
      <span v-else
            class="seamless-text">{{ placeholder }}</span>
      <span class="mdi mdi-menu-down"/>
    </div>
    <div class="KBSelect__container"
         v-else
         @click="openSelector"
         :class="{ 'KBSelect__container--transparent': seamless,
                   'KBSelect__container--transparent-open': showSelector && seamless,
                   'KBSelect__container--seamlessTransparent': seamlessTransparent,
                   'KBSelect__container--darkText': darkText,
                   'cursor-default': disabled }">
      <span v-if="selectedItem"
            :class="{ 'selected--dark': $vuetify.theme.dark }">{{ itemToShow }}</span>
      <span v-else
            class="placeholder">{{ placeholder }}</span>
      <span class="mdi mdi-menu-down"/>
    </div>
    <div class="KBSelect__list newBackground"
         :class="{ 'KBSelect__list--open': showSelector }">
      <div v-for="(item, index) in items"
           :key="index"
           class="list-item"
           :class="{ 'list-item--selected': isSelected(item) }"
           @click="selectItem(item)">
        {{ itemText ? item[itemText] : item }}
      </div>
    </div>
  </div>
</template>
<script>
// import Vue from 'vue'

// Vue.directive('click-outside', {
//   bind: function (el, binding, vnode) {
//     this.event = function (event) {
//       if (!(el === event.target || el.contains(event.target))) {
//         vnode.context[binding.expression](event)
//       }
//     }
//     document.body.addEventListener('click', this.event)
//   },
//   unbind: function (el) {
//     document.body.removeEventListener('click', this.event)
//   }
// })
export default {
  props: ['items', 'value', 'placeholder', 'itemText', 'itemValue', 'returnObject', 'seamless', 'transparent', 'disabled', 'minWidth', 'seamlessTransparent', 'darkText'],
  computed: {
    itemToShow () {
      if (this.itemValue) {
        const item = this.items.filter(item => item[this.itemValue] === this.selectedItem)
        if (item && item[0]) return item[0][this.itemText]
        else return ''
      } else if (this.returnObject) {
        return this.selectedItem[this.itemText]
      } else return this.selectedItem
    }
  },
  mounted () {
    if (this.value) {
      if (this.returnObject) this.selectedItem = this.value
      else if (this.itemValue) this.selectedItem = this.value[this.itemValue]
      else this.selectedItem = this.value
    }
  },
  data () {
    return {
      selectedItem: null,
      showSelector: false
    }
  },
  methods: {
    selectItem (item) {
      if (this.returnObject) this.selectedItem = item
      else if (this.itemValue) this.selectedItem = item[this.itemValue]
      else this.selectedItem = item
      this.showSelector = false
      this.$emit('input', this.selectedItem)
      this.$emit('save', this.selectedItem)
    },
    isSelected (item) {
      if (this.itemValue) {
        const found = this.items.filter(i => i[this.itemValue] === this.selectedItem)[0]
        if (found) return true
      } else {
        return this.selectedItem === item
      }
    },
    openSelector () {
      if (!this.disabled) this.showSelector = !this.showSelector
    },
    close () {
      this.showSelector = false
    }
  }

}
</script>
<style scoped lang="scss">
.KBSelect {
  box-shadow: rgba(182, 190, 199, 0);
  transition: box-shadow 0.5s;
  max-height: 265px;
  position: relative;

  &__container {
    background: rgba(182, 190, 199, 0.2) !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 10px;
    height: 40px;
    cursor: pointer;
    color: $verde-bird !important;

    .placeholder {
      color: $simple-grey;
    }
  }

  &__container--transparent {
    background: rgba(182, 190, 199, 0.8);
    // padding: 0 30px;
    padding-left: 10px;
    cursor: pointer;
    transition: background 0.4s ease;
  }

  &__container--transparent:hover {
    background: rgba(182, 190, 199, 0.2);
  }

  &__container--transparent-open {
    //padding: 0 30px;
    padding-left: 10px;
    cursor: pointer;
    background: rgba(182, 190, 199, 0.2);
  }

  &__container--seamless {
    display: flex;
    align-items: center;
    width: auto !important;

    .placeholder {
      color: #B6BEC7;
    }

    .seamless-text {
      margin-right: 5px;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  &__list {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.4s ease;
    position: absolute;
    z-index: 10;
    width: inherit;

    .list-item {
      height: 40px;
      // padding: 0px 30px;
      padding-left: 10px;
      cursor: pointer;
      display: flex;
      align-items: center;
    }

    .list-item--selected,
    .list-item:hover {
      background: rgba(182, 190, 199, 0.2);
    }
  }

  &__list--open {
    overflow: scroll;
    max-height: 212px;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
  }
}

.KBSelect--open {
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
}

.selected--dark {
  color: $simple-grey !important;
}

.KBSelect__container--seamlessTransparent {
  background: transparent !important;
  padding-left: 10px;
  cursor: pointer;
  transition: background 0.4s ease;

  .selected--dark {
    color: var(--v-black-base) !important;
  }

}

.KBSelect__container--darkText {
    color: $morning-coffee !important;

    .mdi-menu-down {
      color: var(--v-black-base) !important;
    }

  .selected--dark {
  color: $simple-grey !important;
}
}
</style>
