import { AuthFormFields } from '@/components/configuration/conversations/AuthFormFields'
import KeybeText from '@/modules/DesignSystem/infrastructure/components/KeybeText/KeybeText.vue'
import KBSwitch from '@/shared/infrastructure/ui/switch/KBSwitch.vue'

export default {
  name: 'WebWidgetForm',
  components: {
    KeybeText,
    KBSwitch

  },
  props: {
    config: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      formFields: [
        {
          name: this.$t('identificationType'),
          value: AuthFormFields.IdentificationType
        },
        {
          name: this.$t('idDocument'),
          value: AuthFormFields.IdDocument
        },
        {
          name: this.$t('authName'),
          value: AuthFormFields.Name
        },
        {
          name: this.$t('authEmail'),
          value: AuthFormFields.Email
        },
        {
          name: this.$t('authPhone'),
          value: AuthFormFields.Phone
        },
        {
          name: this.$t('landlinePhone'),
          value: AuthFormFields.LandlinePhone
        }
      ]
    }
  },
  methods: {
    isSelected (field) {
      return this.config?.formFields?.includes(field?.value)
    },
    toggleField (field) {
      if (field?.value === AuthFormFields.Phone) {
        return
      }
      if (this.isSelected(field)) {
        this.config.formFields = this.config?.formFields?.filter(item => item !== field?.value)
      } else {
        this.config.formFields.push(field?.value)
      }
    }
  }
  // watch: {
  //   value: {
  //     handler () {
  //       this.config = this.value

  //       if (!this.isSelected(AuthFormFields.Phone)) {
  //         this.config.formFields.push(AuthFormFields.Phone)
  //         this.$emit('input', this.config)
  //       }
  //     },
  //     immediate: true
  //   },
  //   config () {
  //     this.$emit('input', this.config)
  //   }
  // }
}
