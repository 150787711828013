import { mapState } from 'vuex'
import KeybeSelect from '@/modules/DesignSystem/infrastructure/components/KeybeSelect/KeybeSelect/KeybeSelect.vue'
import KeybeAutocomplete from '@/modules/DesignSystem/infrastructure/components/KeybeAutocomplete/KeybeAutocomplete/KeybeAutocomplete.vue'
import { EntityTypeEnum } from '@/modules/CDP/shared/contacts/domain/Contacts'
import { CDPFieldsEnums } from '@/modules/CDP/shared/contacts/domain/enums/CDPFieldsEnums'
export default {
  components: {
    KeybeSelect,
    KeybeAutocomplete
  },
  props: {
    header: {
      type: Object
    },
    headers: {
      type: Array
    },
    typeEntity: {
      type: String
    },
    extendedFields: {
      type: Array
    },
    extradataFields: {
      type: Array
    }
  },
  computed: {
    ...mapState('AppStore', ['selectedApp']),
    biggerScreen () {
      return window.innerWidth > 1000
    },
    mediumScreen () {
      return window.innerWidth < 1700
    },
    mediumSmallScreen () {
      return window.innerWidth > 1000 && window.innerWidth < 1600
    },
    columnCostumStyle () {
      return this.selectedField?.multiple || this.mediumSmallScreen
        ? 'width: 30%'
        : this.mediumScreen
          ? 'width: 40%'
          : 'width: 50%'
    },
    selectorsCostumStyle () {
      return this.selectedField?.multiple || this.mediumSmallScreen
        ? 'width: 75%'
        : this.mediumScreen
          ? 'width: 60%'
          : 'width: 50%'
    },
    hasCategories () {
      return (
        this.selectedApp?.categories && this.selectedApp?.categories?.length > 0
      )
    },
    keybeFieldTypes () {
      let items = []
      if (this.typeEntity === EntityTypeEnum.PEOPLE) {
        items = [
          { name: this.$t('primaryData'), value: 'primary' },
          { name: this.$t('demographicsData'), value: 'demographic' },
          { name: this.$t('personalityData'), value: CDPFieldsEnums.PERSONALITY },
          { name: this.$t('extradataFields'), value: 'extradata' }
        ]
      } else if (this.typeEntity === EntityTypeEnum.BUSINESS) {
        items = [
          { name: this.$t('primaryData'), value: 'primary' },
          { name: this.$t('demographicsData'), value: 'demographic' },
          { name: this.$t('extradataFields'), value: 'extradata' }
        ]
      }
      if (this.hasCategories) {
        this.selectedApp.categories.forEach((cat) => {
          items.push({
            name: this.$t(`${cat}Data`),
            value: cat
          })
        })
      }
      return items
    },
    selectedFieldTypeFields () {
      const items = {
        primary: this.filteredData(this.primaryData),
        demographic: this.filteredData(this.demographicsData),
        personality: this.filteredData(this.personalityData),
        extradata: this.extradataFields,
        car: this.filteredData(this.car),
        motorcycle: this.filteredData(this.motorcycle),
        bicycle: this.filteredData(this.bicycle),
        fashion: this.filteredData(this.fashion),
        beauty: this.filteredData(this.beauty),
        edtech: this.filteredData(this.edtech),
        health: this.filteredData(this.health),
        fintech: this.filteredData(this.fintech)
      }
      return (
        items[this.selectedFieldType] || [
          { name: this.$t('mustSelectAFieldType'), value: '' }
        ]
      )
    },
    primaryData () {
      let items = []
      if (this.typeEntity === EntityTypeEnum.PEOPLE) {
        items = [
          { name: this.$t(CDPFieldsEnums.NAME), value: CDPFieldsEnums.NAME },
          { name: this.$t(CDPFieldsEnums.MIDNAME), value: CDPFieldsEnums.MIDNAME },
          { name: this.$t('lastName'), value: CDPFieldsEnums.SURNAME },
          { name: this.$t(CDPFieldsEnums.PHONE), value: CDPFieldsEnums.PHONE },
          { name: this.$t(CDPFieldsEnums.LANDLINE), value: CDPFieldsEnums.LANDLINE },
          { name: this.$t('email'), value: CDPFieldsEnums.EMAIL },
          { name: this.$t(CDPFieldsEnums.DOCUMENT_TYPE), value: CDPFieldsEnums.DOCUMENT_TYPE },
          { name: this.$t('documentNumber'), value: CDPFieldsEnums.DOCUMENT },
          { name: this.$t(CDPFieldsEnums.REGISTER_CHANNEL), value: CDPFieldsEnums.REGISTER_CHANNEL },
          { name: this.$t('businessName'), value: CDPFieldsEnums.BUSINESS_NAME },
          { name: this.$t(CDPFieldsEnums.TAGS), value: CDPFieldsEnums.TAGS },
          { name: this.$t(CDPFieldsEnums.TAX_CODE), value: CDPFieldsEnums.TAX_CODE },
          { name: this.$t(CDPFieldsEnums.TAX_GROUP), value: CDPFieldsEnums.TAX_GROUP },
          { name: this.$t(CDPFieldsEnums.IDENTIFIER), value: CDPFieldsEnums.IDENTIFIER },
          { name: this.$t(CDPFieldsEnums.PRIORITY), value: CDPFieldsEnums.PRIORITY },
          { name: this.$t(CDPFieldsEnums.DESCRIPTION), value: CDPFieldsEnums.DESCRIPTION },
          { name: this.$t(CDPFieldsEnums.BRAND), value: CDPFieldsEnums.BRAND },
          {
            name: this.$t(CDPFieldsEnums.DISIMBIGUATING_DESCRIPTION),
            value: CDPFieldsEnums.DISIMBIGUATING_DESCRIPTION
          },
          { name: this.$t(CDPFieldsEnums.ALIAS), value: CDPFieldsEnums.ALIAS },
          { name: this.$t(CDPFieldsEnums.CITY), value: CDPFieldsEnums.CITY },
          { name: this.$t(CDPFieldsEnums.ZONE), value: CDPFieldsEnums.ZONE },
          { name: this.$t('birthday'), value: CDPFieldsEnums.BIRTH_DATE },
          { name: this.$t(CDPFieldsEnums.BIRTH_PLACE_COUNTRY), value: CDPFieldsEnums.BIRTH_PLACE_COUNTRY },
          { name: this.$t(CDPFieldsEnums.BIRTH_PLACE_PROVINCE), value: CDPFieldsEnums.BIRTH_PLACE_PROVINCE },
          { name: this.$t(CDPFieldsEnums.BIRTH_PLACE_CITY), value: CDPFieldsEnums.BIRTH_PLACE_CITY },
          { name: this.$t(CDPFieldsEnums.DEATH_DATE), value: CDPFieldsEnums.DEATH_DATE },
          { name: this.$t(CDPFieldsEnums.DOCUMENT_FIRST_ISSUE_DATE), value: CDPFieldsEnums.DOCUMENT_FIRST_ISSUE_DATE },
          { name: this.$t(CDPFieldsEnums.DEATH_PLACE_COUNTRY), value: CDPFieldsEnums.DEATH_PLACE_COUNTRY },
          { name: this.$t(CDPFieldsEnums.DEATH_PLACE_PROVINCE), value: CDPFieldsEnums.DEATH_PLACE_PROVINCE },
          { name: this.$t(CDPFieldsEnums.DEATH_PLACE_CITY), value: CDPFieldsEnums.DEATH_PLACE_CITY },
          { name: this.$t(CDPFieldsEnums.NATIONALITY), value: CDPFieldsEnums.NATIONALITY },
          { name: this.$t(CDPFieldsEnums.CHILDREN_NUMBER), value: CDPFieldsEnums.CHILDREN_NUMBER },
          { name: this.$t('college'), value: CDPFieldsEnums.COLLEAGE },
          { name: this.$t(CDPFieldsEnums.AFFILIATION), value: CDPFieldsEnums.AFFILIATION },
          { name: this.$t(CDPFieldsEnums.AWARD), value: CDPFieldsEnums.AWARD },
          { name: this.$t(CDPFieldsEnums.DUNS), value: CDPFieldsEnums.DUNS },
          { name: this.$t(CDPFieldsEnums.URL_PUBLIC), value: CDPFieldsEnums.URL_PUBLIC },
          { name: this.$t(CDPFieldsEnums.PUBLIC_SOCIAL_MEDIA), value: CDPFieldsEnums.PUBLIC_SOCIAL_MEDIA },
          { name: this.$t(CDPFieldsEnums.ESTABLISHMENT_DATE), value: CDPFieldsEnums.ESTABLISHMENT_DATE },
          {
            name: this.$t(CDPFieldsEnums.ESTABLISHMENT_COUNTRY),
            value: CDPFieldsEnums.ESTABLISHMENT_COUNTRY
          },
          {
            name: this.$t(CDPFieldsEnums.ESTABLISHMENT_PROVINCE),
            value: CDPFieldsEnums.ESTABLISHMENT_PROVINCE
          },
          { name: this.$t(CDPFieldsEnums.ESTABLISHMENT_CITY), value: CDPFieldsEnums.ESTABLISHMENT_CITY },
          { name: this.$t(CDPFieldsEnums.NPS), value: CDPFieldsEnums.NPS },
          { name: this.$t(CDPFieldsEnums.TIME_ZONE), value: CDPFieldsEnums.TIME_ZONE },
          { name: this.$t(CDPFieldsEnums.CATEGORY), value: CDPFieldsEnums.CATEGORY },
          { name: this.$t(CDPFieldsEnums.SUB_CATEGORY), value: CDPFieldsEnums.SUB_CATEGORY },
          { name: this.$t(CDPFieldsEnums.PROFESSION), value: CDPFieldsEnums.PROFESSION },
          { name: this.$t(CDPFieldsEnums.KNOWS), value: CDPFieldsEnums.KNOWS },
          { name: this.$t(CDPFieldsEnums.HEAR_ABOUT), value: CDPFieldsEnums.HEAR_ABOUT },
          { name: this.$t(CDPFieldsEnums.PREFERRED_STORE), value: CDPFieldsEnums.PREFERRED_STORE },
          { name: this.$t(CDPFieldsEnums.PREFERRED_PRODUCT), value: CDPFieldsEnums.PREFERRED_PRODUCT },
          { name: this.$t(CDPFieldsEnums.LEAD_GEN_ID), value: CDPFieldsEnums.LEAD_GEN_ID },
          { name: this.$t(CDPFieldsEnums.FORM_ID), value: CDPFieldsEnums.FORM_ID },
          { name: this.$t(CDPFieldsEnums.SOURCE), value: CDPFieldsEnums.SOURCE }
        ]
      } else if (this.typeEntity === EntityTypeEnum.BUSINESS) {
        items = [
          { name: this.$t(CDPFieldsEnums.BRAND), value: CDPFieldsEnums.BRAND },
          { name: this.$t(CDPFieldsEnums.NAME), value: CDPFieldsEnums.NAME },
          { name: this.$t(CDPFieldsEnums.PHONE), value: CDPFieldsEnums.PHONE },
          { name: this.$t('email'), value: CDPFieldsEnums.EMAIL },
          { name: this.$t(CDPFieldsEnums.IDENTIFIER), value: CDPFieldsEnums.IDENTIFIER },
          { name: this.$t(CDPFieldsEnums.CATEGORY), value: CDPFieldsEnums.CATEGORY },
          { name: this.$t(CDPFieldsEnums.DOCUMENT_TYPE), value: CDPFieldsEnums.DOCUMENT_TYPE },
          { name: this.$t('documentNumber'), value: CDPFieldsEnums.DOCUMENT },
          { name: this.$t(CDPFieldsEnums.TAGS), value: CDPFieldsEnums.TAGS },
          { name: this.$t('taxID'), value: 'taxID' },
          { name: this.$t(CDPFieldsEnums.DESCRIPTION), value: CDPFieldsEnums.DESCRIPTION },
          { name: this.$t(CDPFieldsEnums.ALIAS), value: CDPFieldsEnums.ALIAS },
          { name: this.$t(CDPFieldsEnums.AFFILIATION), value: CDPFieldsEnums.AFFILIATION },
          { name: this.$t(CDPFieldsEnums.DUNS), value: CDPFieldsEnums.DUNS },
          { name: this.$t(CDPFieldsEnums.URL_PUBLIC), value: CDPFieldsEnums.URL_PUBLIC },
          { name: this.$t(CDPFieldsEnums.PUBLIC_SOCIAL_MEDIA), value: CDPFieldsEnums.PUBLIC_SOCIAL_MEDIA },
          { name: this.$t(CDPFieldsEnums.CITY), value: CDPFieldsEnums.CITY },
          { name: this.$t(CDPFieldsEnums.ZONE), value: CDPFieldsEnums.ZONE },
          {
            name: this.$t(CDPFieldsEnums.ESTABLISHMENT_COUNTRY),
            value: CDPFieldsEnums.ESTABLISHMENT_COUNTRY
          },
          {
            name: this.$t(CDPFieldsEnums.ESTABLISHMENT_PROVINCE),
            value: CDPFieldsEnums.ESTABLISHMENT_PROVINCE
          },
          { name: this.$t(CDPFieldsEnums.ESTABLISHMENT_CITY), value: CDPFieldsEnums.ESTABLISHMENT_CITY },
          { name: this.$t(CDPFieldsEnums.TIME_ZONE), value: CDPFieldsEnums.TIME_ZONE },
          { name: this.$t(CDPFieldsEnums.BUSINESS_TYPE), value: CDPFieldsEnums.BUSINESS_TYPE }
        ]
      }
      return items
    },
    demographicsData () {
      let items = []
      if (this.typeEntity === EntityTypeEnum.PEOPLE) {
        items = [
          { name: this.$t('location'), value: CDPFieldsEnums.ADDRESS },
          { name: this.$t(CDPFieldsEnums.HEADQUARTERS), value: CDPFieldsEnums.HEADQUARTERS },
          { name: this.$t(CDPFieldsEnums.GENDER), value: CDPFieldsEnums.GENDER },
          { name: this.$t(CDPFieldsEnums.OCCUPATIONS), value: CDPFieldsEnums.OCCUPATIONS },
          { name: this.$t(CDPFieldsEnums.CREDENTIALS), value: CDPFieldsEnums.CREDENTIALS },
          { name: this.$t(CDPFieldsEnums.EDUCATIONAL_LEVEL), value: CDPFieldsEnums.EDUCATIONAL_LEVEL },
          { name: this.$t(CDPFieldsEnums.ANNUAL_INCOME), value: CDPFieldsEnums.ANNUAL_INCOME },
          { name: this.$t(CDPFieldsEnums.MONTHLY_INCOME), value: CDPFieldsEnums.MONTHLY_INCOME },
          { name: this.$t('languages'), value: CDPFieldsEnums.LANGUAGE },
          { name: this.$t(CDPFieldsEnums.PREFERRED_LANGUAGE), value: CDPFieldsEnums.PREFERRED_LANGUAGE },
          { name: this.$t(CDPFieldsEnums.ETHNICITY), value: CDPFieldsEnums.ETHNICITY },
          { name: this.$t(CDPFieldsEnums.RELIGION), value: CDPFieldsEnums.RELIGION },
          {
            name: this.$t(CDPFieldsEnums.SOCIOECONOMIC_STATUS),
            value: CDPFieldsEnums.SOCIOECONOMIC_STATUS
          },
          { name: this.$t(CDPFieldsEnums.CIVIL_STATUS), value: CDPFieldsEnums.CIVIL_STATUS }
        ]
      } else if (this.typeEntity === EntityTypeEnum.BUSINESS) {
        items = [
          { name: this.$t('location'), value: CDPFieldsEnums.ADDRESS },
          { name: this.$t(CDPFieldsEnums.CREDENTIALS), value: CDPFieldsEnums.CREDENTIALS },
          { name: this.$t('languages'), value: CDPFieldsEnums.LANGUAGE },
          { name: this.$t(CDPFieldsEnums.PREFERRED_LANGUAGE), value: CDPFieldsEnums.PREFERRED_LANGUAGE }
        ]
      }
      return items
    },
    personalityData () {
      const items = [
        { name: this.$t(CDPFieldsEnums.INTERESTS), value: 'CDPFieldsEnums.INTERESTS' },
        { name: this.$t(CDPFieldsEnums.LIKES), value: 'CDPFieldsEnums.LIKES' },
        { name: this.$t(CDPFieldsEnums.DISLIKES), value: 'disCDPFieldsEnums.LIKES' },
        { name: this.$t(CDPFieldsEnums.MUSIC), value: CDPFieldsEnums.MUSIC },
        { name: this.$t(CDPFieldsEnums.GENERAL), value: CDPFieldsEnums.GENERAL },
        { name: this.$t(CDPFieldsEnums.NATURAL_TALENTS), value: CDPFieldsEnums.NATURAL_TALENTS },
        { name: this.$t(CDPFieldsEnums.PERSONALITY), value: CDPFieldsEnums.PERSONALITY },
        { name: this.$t(CDPFieldsEnums.SKILLS), value: CDPFieldsEnums.SKILLS },
        { name: this.$t(CDPFieldsEnums.PSYCHOLOGY), value: CDPFieldsEnums.PSYCHOLOGY },
        { name: this.$t(CDPFieldsEnums.COLOR_GAMUT), value: CDPFieldsEnums.COLOR_GAMUT },
        { name: this.$t(CDPFieldsEnums.COLOR_FAVORITE), value: CDPFieldsEnums.COLOR_FAVORITE },
        {
          name: this.$t(CDPFieldsEnums.PREFERRED_CONTACT_TIME),
          value: CDPFieldsEnums.PREFERRED_CONTACT_TIME
        },
        { name: this.$t(CDPFieldsEnums.PREFERRED_GREETING), value: CDPFieldsEnums.PREFERRED_GREETING },
        { name: this.$t(CDPFieldsEnums.PREFERRED_GOODBYE), value: CDPFieldsEnums.PREFERRED_GOODBYE },
        { name: this.$t(CDPFieldsEnums.PREFERRED_PRONOUNS), value: CDPFieldsEnums.PREFERRED_PRONOUNS },
        { name: this.$t(CDPFieldsEnums.PREFERRED_CULTURE), value: CDPFieldsEnums.PREFERRED_CULTURE },
        { name: this.$t(CDPFieldsEnums.PREFERRED_CHANNEL), value: CDPFieldsEnums.PREFERRED_CHANNEL },
        { name: this.$t(CDPFieldsEnums.TREATMENT_PREFERRED), value: CDPFieldsEnums.TREATMENT_PREFERRED },
        { name: this.$t(CDPFieldsEnums.TREATMENT_TYPE), value: CDPFieldsEnums.TREATMENT_TYPE }
      ]
      return items
    },
    selectedFieldName () {
      return this.selectedField?.name || ''
    },
    isMultiple () {
      return this.selectedField?.multiple || false
    },
    isIncorrect () {
      return (
        (!this.selectedField?.value && !this.selectedField?.nameSlug) ||
        (this.selectedField &&
          this.selectedField?.multiple &&
          !this.selectedFieldChild)
      )
    }
  },
  mounted () {
    if (this.header?.field !== '') {
      this.selectedFieldType = 'primary'
      this.selectedField = this.primaryData.find(
        (field: {[key: string]: any}) => field?.value === this.header?.field
      )
      this.setSelectedField()
    } else {
      this.selectedFieldType = ''
    }
  },
  data () {
    return {
      selectedFieldType: '',
      selectedField: {},
      selectedFieldChild: '',
      car: [],
      motorcycle: [],
      bicycle: [],
      fashion: [],
      beauty: [],
      edtech: [],
      health: [],
      fintech: [],
      rerender: 0,
      CDPFieldsEnums
    }
  },
  methods: {
    filteredData (data: { [key: string]: any }) {
      const selected =
        this.headers.map((head: { [key: string]: any }) => head.field) || []
      const items = data.filter(
        (i: { [key: string]: any }) => !selected.includes(i.value)
      )
      return items
    },
    setSelectedFieldType () {
      this.selectedField = {}
      this.rerender++
    },
    setSelectedField () {
      if (!this.selectedField?.multiple) {
        if (this.selectedFieldType === 'extradata') {
          this.$emit('field', this.selectedField?.nameSlug)
        } else this.$emit('field', this.selectedField?.value)
      } else {
        this.$emit('fieldParent', this.selectedField?.value)
      }
      if (this.selectedField?.category) {
        this.$emit(CDPFieldsEnums.CATEGORY, this.selectedField.category)
      }
      this.selectedFieldChild = ''
      this.rerender++
    },
    clearSelectedField () {
      this.selectedField = {}
      this.selectedFieldChild = ''
      this.$emit('field', {})
      this.$emit('fieldParent', '')
    },
    setSelectedfieldChild () {
      this.$emit('field', this.selectedFieldChild)
    },
    setExtendedFields () {
      this.extendedFields.forEach((data) => {
        data.fields.forEach((field) => {
          field.name = this.$t(field.field)
          field.value = field.field
          field.category = data.category

          if (field?.children && field?.children?.length > 0) {
            field.children.forEach((child) => {
              child.name = this.$t(child.field)
              child.value = child.field
            })
          }

          this.$data[data.category].push(field)
        })
      })
    }
  },
  watch: {
    extendedFields: {
      immediate: true,
      handler () {
        if (this.extendedFields.length > 0) this.setExtendedFields()
      }
    }
  }
}
