











































import operation from '@/utils/operations'
import Colors from '@/utils/Colors'

// components
import KBButton from '@/shared/infrastructure/ui/buttons/KBButton.vue'

export default {
  components: { KBButton },
  mounted () {
    this.valueLocal = { ...JSON.parse(JSON.stringify(this.data)) }

    if (this.valueLocal.config.background) {
      this.backgroundColor = this.valueLocal.config.background
    }
  },
  data () {
    return {
      Colors,
      backgroundColor: '',
      valueLocal: null
    }
  },
  watch: {
    backgroundColor: {
      handler (val) {
        this.valueLocal.config.background = val
      }
    },
    data: {
      handler (val) {
        this.valueLocal = { ...JSON.parse(JSON.stringify(val)) }
      },
      deep: true
    }
  },
  methods: {
    toggleModal (save = false) {
      this.$emit('toggleModal')

      if (!save) {
        this.backgroundColor = this.data.config.background
      }
    },
    areEqual (a, b): boolean {
      return operation.areEqual(a, b)
    },
    save () {
      this.$emit('changeBackgroundColor', this.backgroundColor)
      this.toggleModal(true)
    }
  },
  props: {
    data: {
      required: true,
      type: Object
    },
    modal: {
      type: Boolean
    }
  }
}
