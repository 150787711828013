import { IRPlanItemController, dataToGetPlan, planGetter } from '../../domain/PlanController'
import PlanRepository from '../repository/Plan.repository'

export default class PlanController implements IRPlanItemController {
    repository: PlanRepository

    constructor () {
      this.repository = new PlanRepository()
    }

    async setBooking (data: dataToGetPlan): Promise<planGetter> {
      const response = await this.repository.setBooking(data)
      return response
    }
}
