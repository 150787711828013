import CatalogImporterUseCases from '@/modules/catalog/application/CatalogImporterUseCases'
import { dataToGetCSVExample } from '@/modules/catalog/domain/models/CatalogImporterController'
import { mapState } from 'vuex'
import CatalogImporterController from '../../../controllers/CatalogImporter.controller'
import KeybeButton from '@/modules/DesignSystem/infrastructure/components/KeybeButton/KeybeButton.vue'
import Colors from '@/utils/Colors'
import EmitToast from '@/utils/EmitToast'
import { CatalogImport } from '@/modules/catalog/domain/models/CatalogImport'
import EventsApolloProvider from '@/modules/event/Providers/EventsApolloProvider'
import SubscriptionOnCatalogImport from '@/modules/catalog/domain/graphql/subscription-on-catalog-import.graphql'
import Loader from '@/shared/infrastructure/ui/Loader.vue'
export default {
  components: {
    KeybeButton,
    Loader
  },
  computed: {
    ...mapState('AppStore', ['selectedApp']),
    ...mapState('UserStore', ['token'])
  },
  mounted () {
    this.getAllImports()
  },
  data () {
    return {
      imports: [],
      headers: [
        'id',
        'prodsImported',
        'importStatus',
        'date',
        'hour',
        'file'
      ],
      catalogImporterController: new CatalogImporterController(),
      eventsApolloClient: EventsApolloProvider.getInstance().provider.defaultClient,
      importProgress: null,
      loading: false,
      Colors
    }
  },
  methods: {
    setImportStatus (catalogImport: CatalogImport): void {
      if (catalogImport === null || !catalogImport || !catalogImport?.id) return
      this.importProgress = this.eventsApolloClient
        .subscribe({
          query: SubscriptionOnCatalogImport,
          variables: {
            importId: catalogImport?.id
          }
        })
        .subscribe((res) => {
          if (res?.data?.onCatalogImport?.import?.success) {
            EmitToast.emitSuccess(this.$t('importIdSuccess', { id: catalogImport?.id }))
            this.importProgress.unsubscribe()
            this.getAllImports()
          }
        })
    },
    async getAllImports (): Promise<void> {
      this.loading = true
      const data: dataToGetCSVExample = {
        appUUID: this.selectedApp?.uuid,
        token: this.token
      }
      const response = await CatalogImporterUseCases.getAllImports(this.catalogImporterController, data)
      if (response.status) {
        this.imports = response.data
        this.imports = this.imports.filter((item: CatalogImport) => item !== null)
        const importIsNotSuccess = this.imports.find((item: CatalogImport) => !item?.success || false)
        if (importIsNotSuccess) this.setImportStatus(importIsNotSuccess)
      } else {
        EmitToast.emitErrors(response.message)
      }
      this.loading = false
    },
    openNewImport (): void {
      this.$emit('newImport')
    },
    itemValue (item: CatalogImport, header: string): string {
      switch (header) {
        case 'importStatus':
          return item && item?.success ? this.$t('finished') : this.$t('importing')
        case 'date':
          return item?.finishedAt?.split(', ')[0] || ' - '
        case 'hour':
          return item?.finishedAt?.split(', ')[1] || ' - '
        case 'file':
          return item.csvUrl || ' - '
        default:
          return item[header] || ' - '
      }
    },
    downloadFile (item: CatalogImport): void {
      window.open(item.csvUrl, '_blank')
    }
  },
  beforeDestroy () {
    if (this.importProgress) this.importProgress.unsubscribe()
  }
}
