import KeybeButton from '@/modules/DesignSystem/infrastructure/components/KeybeButton/KeybeButton.vue'
import Colors from '@/utils/Colors'
export default {
  components: {
    KeybeButton
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    cancel (): void {
      this.$emit('cancel')
    },
    disconnect (): void {
      console.log('Disconnecting')
      this.$emit('disconnect')
    }
  },
  data () {
    return {
      Colors
    }
  }
}
