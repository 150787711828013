import { IKeybePhoneController, dataToParsePhone, defaultCountryGetter, formattedPhone } from '../domain/controllers/KeybePhoneController'

export default class KeybePhoneUseCases {
  static getDefaultCountry (controller: IKeybePhoneController) : defaultCountryGetter {
    return controller.getDefaultCountry()
  }

  static async setPhoneFromString (controller: IKeybePhoneController, phone: string) : Promise<formattedPhone> {
    return controller.setPhoneFromString(phone)
  }

  static checkIfContainsPlus (controller: IKeybePhoneController, phone: string) : boolean {
    return controller.checkIfContainsPlus(phone)
  }

  static checkIfContainsCountryCode (controller: IKeybePhoneController, data: dataToParsePhone) : boolean {
    return controller.checkIfContainsCountryCode(data)
  }

  static async parsePhone (controller: IKeybePhoneController, data: dataToParsePhone) : Promise<formattedPhone> {
    return controller.parsePhone(data)
  }

  static validateSpecial (controller: IKeybePhoneController, phone: formattedPhone) : formattedPhone {
    return controller.validateSpecial(phone)
  }
}
