


















































import BikyConfigChatCorrectPronunciation from './BikyConfigChatCorrectPronunciation'

export default BikyConfigChatCorrectPronunciation
