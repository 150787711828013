<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       fill="none"
       viewBox="0 0 16 14"><path :stroke="color"
                                 d="M7.543 2.704 8 3.726l.456-1.022C9.04 1.401 10.328.5 11.814.5 13.838.5 15.5 2.177 15.5 4.253a3.771 3.771 0 0 1-1.08 2.651l-.161.164-6.248 6.306-6.174-6.23-.014-.014-.015-.013A3.767 3.767 0 0 1 .71 5.5 3.78 3.78 0 0 1 .5 4.253C.5 2.177 2.161.5 4.187.5c1.485 0 2.774.901 3.356 2.204Z"/></svg>
</template>

<script>
export default {
  name: 'HeartOutlinedIcon',
  props: {
    color: {
      type: String,
      default: '#B6BEC7'
    }
  }
}
</script>

<style>

</style>
