import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import Colors from '@/utils/Colors'
import tiktokIcon from '@/assets/icons/microsites/tiktok.svg'
import moneySignIcon from '@/assets/icons/microsites/money-sign.svg'
import telegramIcon from '@/assets/icons/microsites/telegram.svg'
import galleryIcon from '@/assets/icons/conversations/chat/autocomplete/gallery.svg'
import headphonesIcon from '@/assets/icons/conversations/chat/autocomplete/headphones.svg'
import pdfIcon from '@/assets/icons/conversations/chat/autocomplete/pdf.svg'
import shoppingIcon from '@/assets/icons/conversations/chat/autocomplete/shopping.svg'
import textIcon from '@/assets/icons/conversations/chat/autocomplete/text.svg'
import videoIcon from '@/assets/icons/conversations/chat/autocomplete/video.svg'
import pipelinesStandardIcon from '@/assets/icons/pipelines/pipelines-standard.svg'
import pipelinesTicketIcon from '@/assets/icons/pipelines/pipelines-ticket.svg'
import pipelinesFlareIcon from '@/assets/icons/pipelines/pipelines-flare.svg'
import discordIcon from '@/assets/icons/microsites/discord.svg'
import twitchIcon from '@/assets/icons/microsites/twitch.svg'
import transferRoomIcon from '@/assets/icons/conversations/chat/actions/transfer-room.svg'
import closeRoomIcon from '@/assets/icons/conversations/chat/actions/close-room.svg'
import salesIcon from '@/assets/icons/conversations/chat/actions/sales.svg'

Vue.use(Vuetify)

export default new Vuetify({
  icons: {
    values: {
      tiktok: {
        // @ts-ignore
        component: tiktokIcon,
        props: {
          name: 'tiktok'
        }
      },
      money: {
        // @ts-ignore
        component: moneySignIcon,
        props: {
          name: 'money'
        }
      },
      telegram: {
        // @ts-ignore
        component: telegramIcon,
        props: {
          name: 'telegram'
        }
      },
      gallery: {
        // @ts-ignore
        component: galleryIcon,
        props: {
          name: 'gallery'
        }
      },
      headphones: {
        // @ts-ignore
        component: headphonesIcon,
        props: {
          name: 'headphones'
        }
      },
      pdf: {
        // @ts-ignore
        component: pdfIcon,
        props: {
          name: 'pdf'
        }
      },
      shopping: {
        // @ts-ignore
        component: shoppingIcon,
        props: {
          name: 'shopping'
        }
      },
      text: {
        // @ts-ignore
        component: textIcon,
        props: {
          name: 'text'
        }
      },
      video: {
        // @ts-ignore
        component: videoIcon,
        props: {
          name: 'video'
        }
      },
      pipelinesStandard: {
        // @ts-ignore
        component: pipelinesStandardIcon,
        props: {
          name: 'pipelinesStandard'
        }
      },
      pipelinesTicket: {
        // @ts-ignore
        component: pipelinesTicketIcon,
        props: {
          name: 'pipelinesTicket'
        }
      },
      pipelinesFlare: {
        // @ts-ignore
        component: pipelinesFlareIcon,
        props: {
          name: 'pipelinesFlare'
        }
      },
      discord: {
        // @ts-ignore
        component: discordIcon,
        props: {
          name: 'discord'
        }
      },
      twitch: {
        // @ts-ignore
        component: twitchIcon,
        props: {
          name: 'twitch'
        }
      },
      transferRoom: {
        // @ts-ignore
        component: transferRoomIcon,
        props: {
          name: 'transferRoom'
        }
      },
      closeRoom: {
        // @ts-ignore
        component: closeRoomIcon,
        props: {
          name: 'closeRoom'
        }
      },
      sales: {
        // @ts-ignore
        component: salesIcon,
        props: {
          name: 'sales'
        }
      }
    }
  },
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: Colors.verdeBird,
        secondary: Colors.dark2,
        accent: Colors.blue4,
        error: Colors.automate2,
        dark4: Colors.dark4,
        details: Colors.white,
        component: Colors.light1,
        background: Colors.light2,
        light3: Colors.light3,
        base: Colors.dark3,
        highlight: Colors.white,
        black: Colors.black,
        text: Colors.black,
        light: Colors.light3,
        offWhite: Colors.offWhite,
        offBlack: Colors.offBlack,
        light4: Colors.light4,
        newBackground: '#F7F7F7',
        floating: Colors.light1,
        userDetails: Colors.white,
        label: Colors.simpleGrey,
        blackWhite: Colors.white,
        playbackRate: Colors.grey2,
        kbGreen: Colors.kbGreen,
        rojoBird: Colors.rojoBird,
        verdeWeb: Colors.green4,
        textLight: Colors.textLight,
        hover: Colors.grey2,
        input: Colors.inputLight
      },
      dark: {
        primary: Colors.verdeBird,
        secondary: Colors.light3,
        accent: Colors.blue4,
        error: Colors.automate2,
        dark4: Colors.darkThemeDark4,
        details: Colors.darkThemeDark4,
        light3: Colors.darkThemeDark4,
        component: Colors.darkThemeLight1,
        background: Colors.darkThemeDark4,
        base: Colors.darkThemeDark3,
        highlight: Colors.darkThemeWhite,
        black: Colors.white,
        text: Colors.white,
        light: Colors.darkThemeLight3,
        offWhite: Colors.darkThemeOffWhite,
        light4: Colors.darkThemeLight4,
        offBlack: Colors.offBlack,
        newBackground: Colors.black,
        floating: Colors.morningCoffee,
        userDetails: Colors.morningCoffee,
        label: Colors.black,
        blackWhite: Colors.black,
        playbackRate: Colors.black,
        kbGreen: Colors.kbGreen,
        rojoBird: Colors.rojoBird,
        verdeWeb: Colors.green4,
        textLight: Colors.textLight,
        hover: Colors.darkThemeLight1,
        input: Colors.inputDark
      }
    }
  }
})
