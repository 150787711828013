export default {
  AD: {
    name: 'Andorra',
    abbr: 'AD',
    zones: [
      'Europe/Andorra'
    ],
    languages: ['ca']
  },
  AE: {
    name: 'United Arab Emirates',
    abbr: 'AE',
    zones: [
      'Asia/Dubai'
    ],
    languages: ['ar']
  },
  AF: {
    name: 'Afghanistan',
    abbr: 'AF',
    zones: [
      'Asia/Kabul'
    ],
    languages: ['ps', 'uz', 'tk']
  },
  AG: {
    name: 'Antigua & Barbuda',
    abbr: 'AG',
    zones: [
      'America/Puerto_Rico',
      'America/Antigua'
    ],
    languages: ['en']
  },
  AI: {
    name: 'Anguilla',
    abbr: 'AI',
    zones: [
      'America/Puerto_Rico',
      'America/Anguilla'
    ],
    languages: ['en']
  },
  AL: {
    name: 'Albania',
    abbr: 'AL',
    zones: [
      'Europe/Tirane'
    ],
    languages: ['sq']
  },
  AM: {
    name: 'Armenia',
    abbr: 'AM',
    zones: [
      'Asia/Yerevan'
    ],
    languages: ['hy', 'ru']
  },
  AO: {
    name: 'Angola',
    abbr: 'AO',
    zones: [
      'Africa/Lagos',
      'Africa/Luanda'
    ],
    languages: ['pt']
  },
  AQ: {
    name: 'Antarctica',
    abbr: 'AQ',
    zones: [
      'Antarctica/Casey',
      'Antarctica/Davis',
      'Antarctica/Mawson',
      'Antarctica/Palmer',
      'Antarctica/Rothera',
      'Antarctica/Troll',
      'Asia/Urumqi',
      'Pacific/Auckland',
      'Pacific/Port_Moresby',
      'Asia/Riyadh',
      'Antarctica/McMurdo',
      'Antarctica/DumontDUrville',
      'Antarctica/Syowa',
      'Antarctica/Vostok'
    ],
    languages: []
  },
  AR: {
    name: 'Argentina',
    abbr: 'AR',
    zones: [
      'America/Argentina/Buenos_Aires',
      'America/Argentina/Cordoba',
      'America/Argentina/Salta',
      'America/Argentina/Jujuy',
      'America/Argentina/Tucuman',
      'America/Argentina/Catamarca',
      'America/Argentina/La_Rioja',
      'America/Argentina/San_Juan',
      'America/Argentina/Mendoza',
      'America/Argentina/San_Luis',
      'America/Argentina/Rio_Gallegos',
      'America/Argentina/Ushuaia'
    ],
    languages: ['es', 'gn']
  },
  AS: {
    name: 'Samoa (American)',
    abbr: 'AS',
    zones: [
      'Pacific/Pago_Pago'
    ],
    languages: ['en', 'sm']
  },
  AT: {
    name: 'Austria',
    abbr: 'AT',
    zones: [
      'Europe/Vienna'
    ],
    languages: ['de']
  },
  AU: {
    name: 'Australia',
    abbr: 'AU',
    zones: [
      'Australia/Lord_Howe',
      'Antarctica/Macquarie',
      'Australia/Hobart',
      'Australia/Melbourne',
      'Australia/Sydney',
      'Australia/Broken_Hill',
      'Australia/Brisbane',
      'Australia/Lindeman',
      'Australia/Adelaide',
      'Australia/Darwin',
      'Australia/Perth',
      'Australia/Eucla'
    ],
    languages: ['en']
  },
  AW: {
    name: 'Aruba',
    abbr: 'AW',
    zones: [
      'America/Puerto_Rico',
      'America/Aruba'
    ],
    languages: ['nl', 'pa']
  },
  AX: {
    name: 'Åland Islands',
    abbr: 'AX',
    zones: [
      'Europe/Helsinki',
      'Europe/Mariehamn'
    ],
    languages: ['sv']
  },
  AZ: {
    name: 'Azerbaijan',
    abbr: 'AZ',
    zones: [
      'Asia/Baku'
    ],
    languages: ['az']
  },
  BA: {
    name: 'Bosnia & Herzegovina',
    abbr: 'BA',
    zones: [
      'Europe/Belgrade',
      'Europe/Sarajevo'
    ],
    languages: ['bs', 'hr', 'sr']
  },
  BB: {
    name: 'Barbados',
    abbr: 'BB',
    zones: [
      'America/Barbados'
    ],
    languages: ['en']
  },
  BD: {
    name: 'Bangladesh',
    abbr: 'BD',
    zones: [
      'Asia/Dhaka'
    ],
    languages: ['bn']
  },
  BE: {
    name: 'Belgium',
    abbr: 'BE',
    zones: [
      'Europe/Brussels'
    ],
    languages: ['nl', 'fr', 'de']
  },
  BF: {
    name: 'Burkina Faso',
    abbr: 'BF',
    zones: [
      'Africa/Abidjan',
      'Africa/Ouagadougou'
    ],
    languages: ['fr']
  },
  BG: {
    name: 'Bulgaria',
    abbr: 'BG',
    zones: [
      'Europe/Sofia'
    ],
    languages: ['bg']
  },
  BH: {
    name: 'Bahrain',
    abbr: 'BH',
    zones: [
      'Asia/Qatar',
      'Asia/Bahrain'
    ],
    languages: ['ar']
  },
  BI: {
    name: 'Burundi',
    abbr: 'BI',
    zones: [
      'Africa/Maputo',
      'Africa/Bujumbura'
    ],
    languages: ['fr', 'rn']
  },
  BJ: {
    name: 'Benin',
    abbr: 'BJ',
    zones: [
      'Africa/Lagos',
      'Africa/Porto-Novo'
    ],
    languages: ['fr']
  },
  BL: {
    name: 'St Barthelemy',
    abbr: 'BL',
    zones: [
      'America/Puerto_Rico',
      'America/St_Barthelemy'
    ],
    languages: ['fr']
  },
  BM: {
    name: 'Bermuda',
    abbr: 'BM',
    zones: [
      'Atlantic/Bermuda'
    ],
    languages: ['en']
  },
  BN: {
    name: 'Brunei',
    abbr: 'BN',
    zones: [
      'Asia/Kuching',
      'Asia/Brunei'
    ],
    languages: ['ms']
  },
  BO: {
    name: 'Bolivia',
    abbr: 'BO',
    zones: [
      'America/La_Paz'
    ],
    languages: ['es', 'ay', 'qu']
  },
  BQ: {
    name: 'Caribbean NL',
    abbr: 'BQ',
    zones: [
      'America/Puerto_Rico',
      'America/Kralendijk'
    ],
    languages: ['nl']
  },
  BR: {
    name: 'Brazil',
    abbr: 'BR',
    zones: [
      'America/Noronha',
      'America/Belem',
      'America/Fortaleza',
      'America/Recife',
      'America/Araguaina',
      'America/Maceio',
      'America/Bahia',
      'America/Sao_Paulo',
      'America/Campo_Grande',
      'America/Cuiaba',
      'America/Santarem',
      'America/Porto_Velho',
      'America/Boa_Vista',
      'America/Manaus',
      'America/Eirunepe',
      'America/Rio_Branco'
    ],
    languages: ['pt']
  },
  BS: {
    name: 'Bahamas',
    abbr: 'BS',
    zones: [
      'America/Toronto',
      'America/Nassau'
    ],
    languages: ['en']
  },
  BT: {
    name: 'Bhutan',
    abbr: 'BT',
    zones: [
      'Asia/Thimphu'
    ],
    languages: ['dz']
  },
  BW: {
    name: 'Botswana',
    abbr: 'BW',
    zones: [
      'Africa/Maputo',
      'Africa/Gaborone'
    ],
    languages: ['en', 'tn']
  },
  BY: {
    name: 'Belarus',
    abbr: 'BY',
    zones: [
      'Europe/Minsk'
    ],
    languages: ['be', 'ru']
  },
  BZ: {
    name: 'Belize',
    abbr: 'BZ',
    zones: [
      'America/Belize'
    ],
    languages: ['en', 'es']
  },
  CA: {
    name: 'Canada',
    abbr: 'CA',
    zones: [
      'America/St_Johns',
      'America/Halifax',
      'America/Glace_Bay',
      'America/Moncton',
      'America/Goose_Bay',
      'America/Toronto',
      'America/Iqaluit',
      'America/Winnipeg',
      'America/Resolute',
      'America/Rankin_Inlet',
      'America/Regina',
      'America/Swift_Current',
      'America/Edmonton',
      'America/Cambridge_Bay',
      'America/Inuvik',
      'America/Dawson_Creek',
      'America/Fort_Nelson',
      'America/Whitehorse',
      'America/Dawson',
      'America/Vancouver',
      'America/Panama',
      'America/Puerto_Rico',
      'America/Phoenix',
      'America/Blanc-Sablon',
      'America/Atikokan',
      'America/Creston'
    ],
    languages: ['en', 'fr']
  },
  CC: {
    name: 'Cocos (Keeling) Islands',
    abbr: 'CC',
    zones: [
      'Asia/Yangon',
      'Indian/Cocos'
    ],
    languages: ['en']
  },
  CD: {
    name: 'Congo (Dem. Rep.)',
    abbr: 'CD',
    zones: [
      'Africa/Maputo',
      'Africa/Lagos',
      'Africa/Kinshasa',
      'Africa/Lubumbashi'
    ],
    languages: ['fr', 'ln', 'kg', 'sw', 'lu']
  },
  CF: {
    name: 'Central African Rep.',
    abbr: 'CF',
    zones: [
      'Africa/Lagos',
      'Africa/Bangui'
    ],
    languages: ['fr', 'sg']
  },
  CG: {
    name: 'Congo (Rep.)',
    abbr: 'CG',
    zones: [
      'Africa/Lagos',
      'Africa/Brazzaville'
    ],
    languages: ['fr', 'ln']
  },
  CH: {
    name: 'Switzerland',
    abbr: 'CH',
    zones: [
      'Europe/Zurich'
    ],
    languages: ['de', 'fr', 'it', 'rm']
  },
  CI: {
    name: "Côte d'Ivoire",
    abbr: 'CI',
    zones: [
      'Africa/Abidjan'
    ],
    languages: ['fr']
  },
  CK: {
    name: 'Cook Islands',
    abbr: 'CK',
    zones: [
      'Pacific/Rarotonga'
    ],
    languages: ['en', 'mi']
  },
  CL: {
    name: 'Chile',
    abbr: 'CL',
    zones: [
      'America/Santiago',
      'America/Punta_Arenas',
      'Pacific/Easter'
    ],
    languages: ['es']
  },
  CM: {
    name: 'Cameroon',
    abbr: 'CM',
    zones: [
      'Africa/Lagos',
      'Africa/Douala'
    ],
    languages: ['fr', 'en']
  },
  CN: {
    name: 'China',
    abbr: 'CN',
    zones: [
      'Asia/Shanghai',
      'Asia/Urumqi'
    ],
    languages: ['zh']
  },
  CO: {
    name: 'Colombia',
    abbr: 'CO',
    zones: [
      'America/Bogota'
    ],
    languages: ['es']
  },
  CR: {
    name: 'Costa Rica',
    abbr: 'CR',
    zones: [
      'America/Costa_Rica'
    ],
    languages: ['es']
  },
  CU: {
    name: 'Cuba',
    abbr: 'CU',
    zones: [
      'America/Havana'
    ],
    languages: ['es']
  },
  CV: {
    name: 'Cape Verde',
    abbr: 'CV',
    zones: [
      'Atlantic/Cape_Verde'
    ],
    languages: ['pt']
  },
  CW: {
    name: 'Curaçao',
    abbr: 'CW',
    zones: [
      'America/Puerto_Rico',
      'America/Curacao'
    ],
    languages: ['nl', 'en']
  },
  CX: {
    name: 'Christmas Island',
    abbr: 'CX',
    zones: [
      'Asia/Bangkok',
      'Indian/Christmas'
    ],
    languages: ['en']
  },
  CY: {
    name: 'Cyprus',
    abbr: 'CY',
    zones: [
      'Asia/Nicosia',
      'Asia/Famagusta'
    ],
    languages: ['el', 'tr', 'hy']
  },
  CZ: {
    name: 'Czech Republic',
    abbr: 'CZ',
    zones: [
      'Europe/Prague'
    ],
    languages: ['cs']
  },
  DE: {
    name: 'Germany',
    abbr: 'DE',
    zones: [
      'Europe/Zurich',
      'Europe/Berlin',
      'Europe/Busingen'
    ],
    languages: ['de']
  },
  DJ: {
    name: 'Djibouti',
    abbr: 'DJ',
    zones: [
      'Africa/Nairobi',
      'Africa/Djibouti'
    ],
    languages: ['fr', 'ar', 'so']
  },
  DK: {
    name: 'Denmark',
    abbr: 'DK',
    zones: [
      'Europe/Berlin',
      'Europe/Copenhagen'
    ],
    languages: ['da']
  },
  DM: {
    name: 'Dominica',
    abbr: 'DM',
    zones: [
      'America/Puerto_Rico',
      'America/Dominica'
    ],
    languages: ['en']
  },
  DO: {
    name: 'Dominican Republic',
    abbr: 'DO',
    zones: [
      'America/Santo_Domingo'
    ],
    languages: ['es']
  },
  DZ: {
    name: 'Algeria',
    abbr: 'DZ',
    zones: [
      'Africa/Algiers'
    ],
    languages: ['ar']
  },
  EC: {
    name: 'Ecuador',
    abbr: 'EC',
    zones: [
      'America/Guayaquil',
      'Pacific/Galapagos'
    ],
    languages: ['es']
  },
  EE: {
    name: 'Estonia',
    abbr: 'EE',
    zones: [
      'Europe/Tallinn'
    ],
    languages: ['et']
  },
  EG: {
    name: 'Egypt',
    abbr: 'EG',
    zones: [
      'Africa/Cairo'
    ],
    languages: ['ar']
  },
  EH: {
    name: 'Western Sahara',
    abbr: 'EH',
    zones: [
      'Africa/El_Aaiun'
    ],
    languages: ['ar']
  },
  ER: {
    name: 'Eritrea',
    abbr: 'ER',
    zones: [
      'Africa/Nairobi',
      'Africa/Asmara'
    ],
    languages: ['aa', 'ar', 'ti']
  },
  ES: {
    name: 'Spain',
    abbr: 'ES',
    zones: [
      'Europe/Madrid',
      'Africa/Ceuta',
      'Atlantic/Canary'
    ],
    languages: ['es', 'eu', 'ca', 'gl', 'oc']
  },
  ET: {
    name: 'Ethiopia',
    abbr: 'ET',
    zones: [
      'Africa/Nairobi',
      'Africa/Addis_Ababa'
    ],
    languages: ['am', 'en', 'om', 'ti', 'so', 'sid']
  },
  FI: {
    name: 'Finland',
    abbr: 'FI',
    zones: [
      'Europe/Helsinki'
    ],
    languages: ['fi', 'sv']
  },
  FJ: {
    name: 'Fiji',
    abbr: 'FJ',
    zones: [
      'Pacific/Fiji'
    ],
    languages: ['en', 'fj', 'hi', 'ur']
  },
  FK: {
    name: 'Falkland Islands',
    abbr: 'FK',
    zones: [
      'Atlantic/Stanley'
    ],
    languages: ['en']
  },
  FM: {
    name: 'Micronesia',
    abbr: 'FM',
    zones: [
      'Pacific/Kosrae',
      'Pacific/Port_Moresby',
      'Pacific/Guadalcanal',
      'Pacific/Chuuk',
      'Pacific/Pohnpei'
    ],
    languages: ['en']
  },
  FO: {
    name: 'Faroe Islands',
    abbr: 'FO',
    zones: [
      'Atlantic/Faroe'
    ],
    languages: ['fo', 'da']
  },
  FR: {
    name: 'France',
    abbr: 'FR',
    zones: [
      'Europe/Paris'
    ],
    languages: ['fr']
  },
  GA: {
    name: 'Gabon',
    abbr: 'GA',
    zones: [
      'Africa/Lagos',
      'Africa/Libreville'
    ],
    languages: ['fr']
  },
  GB: {
    name: 'Britain (UK)',
    abbr: 'GB',
    zones: [
      'Europe/London'
    ],
    languages: ['en', 'cy', 'gd', 'kw']
  },
  GD: {
    name: 'Grenada',
    abbr: 'GD',
    zones: [
      'America/Puerto_Rico',
      'America/Grenada'
    ],
    languages: ['en']
  },
  GE: {
    name: 'Georgia',
    abbr: 'GE',
    zones: [
      'Asia/Tbilisi'
    ],
    languages: ['ka']
  },
  GF: {
    name: 'French Guiana',
    abbr: 'GF',
    zones: [
      'America/Cayenne'
    ],
    languages: ['fr']
  },
  GG: {
    name: 'Guernsey',
    abbr: 'GG',
    zones: [
      'Europe/London',
      'Europe/Guernsey'
    ],
    languages: ['en', 'fr']
  },
  GH: {
    name: 'Ghana',
    abbr: 'GH',
    zones: [
      'Africa/Abidjan',
      'Africa/Accra'
    ],
    languages: ['en']
  },
  GI: {
    name: 'Gibraltar',
    abbr: 'GI',
    zones: [
      'Europe/Gibraltar'
    ],
    languages: ['en']
  },
  GL: {
    name: 'Greenland',
    abbr: 'GL',
    zones: [
      'America/Nuuk',
      'America/Danmarkshavn',
      'America/Scoresbysund',
      'America/Thule'
    ],
    languages: ['kl', 'da']
  },
  GM: {
    name: 'Gambia',
    abbr: 'GM',
    zones: [
      'Africa/Abidjan',
      'Africa/Banjul'
    ],
    languages: ['en']
  },
  GN: {
    name: 'Guinea',
    abbr: 'GN',
    zones: [
      'Africa/Abidjan',
      'Africa/Conakry'
    ],
    languages: ['fr']
  },
  GP: {
    name: 'Guadeloupe',
    abbr: 'GP',
    zones: [
      'America/Puerto_Rico',
      'America/Guadeloupe'
    ],
    languages: ['fr']
  },
  GQ: {
    name: 'Equatorial Guinea',
    abbr: 'GQ',
    zones: [
      'Africa/Lagos',
      'Africa/Malabo'
    ],
    languages: ['es', 'fr', 'pt']
  },
  GR: {
    name: 'Greece',
    abbr: 'GR',
    zones: [
      'Europe/Athens'
    ],
    languages: ['el']
  },
  GS: {
    name: 'South Georgia & the South Sandwich Islands',
    abbr: 'GS',
    zones: [
      'Atlantic/South_Georgia'
    ],
    languages: ['en']
  },
  GT: {
    name: 'Guatemala',
    abbr: 'GT',
    zones: [
      'America/Guatemala'
    ],
    languages: ['es']
  },
  GU: {
    name: 'Guam',
    abbr: 'GU',
    zones: [
      'Pacific/Guam'
    ],
    languages: ['en', 'ch', 'es']
  },
  GW: {
    name: 'Guinea-Bissau',
    abbr: 'GW',
    zones: [
      'Africa/Bissau'
    ],
    languages: ['pt']
  },
  GY: {
    name: 'Guyana',
    abbr: 'GY',
    zones: [
      'America/Guyana'
    ],
    languages: ['en']
  },
  HK: {
    name: 'Hong Kong',
    abbr: 'HK',
    zones: [
      'Asia/Hong_Kong'
    ],
    languages: ['zh', 'en']
  },
  HN: {
    name: 'Honduras',
    abbr: 'HN',
    zones: [
      'America/Tegucigalpa'
    ],
    languages: ['es']
  },
  HR: {
    name: 'Croatia',
    abbr: 'HR',
    zones: [
      'Europe/Belgrade',
      'Europe/Zagreb'
    ],
    languages: ['hr']
  },
  HT: {
    name: 'Haiti',
    abbr: 'HT',
    zones: [
      'America/Port-au-Prince'
    ],
    languages: ['fr', 'ht']
  },
  HU: {
    name: 'Hungary',
    abbr: 'HU',
    zones: [
      'Europe/Budapest'
    ],
    languages: ['hu']
  },
  ID: {
    name: 'Indonesia',
    abbr: 'ID',
    zones: [
      'Asia/Jakarta',
      'Asia/Pontianak',
      'Asia/Makassar',
      'Asia/Jayapura'
    ],
    languages: ['id']
  },
  IE: {
    name: 'Ireland',
    abbr: 'IE',
    zones: [
      'Europe/Dublin'
    ],
    languages: ['ga', 'en']
  },
  IL: {
    name: 'Israel',
    abbr: 'IL',
    zones: [
      'Asia/Jerusalem'
    ],
    languages: ['he', 'ar']
  },
  IM: {
    name: 'Isle of Man',
    abbr: 'IM',
    zones: [
      'Europe/London',
      'Europe/Isle_of_Man'
    ],
    languages: ['en', 'gv']
  },
  IN: {
    name: 'India',
    abbr: 'IN',
    zones: [
      'Asia/Kolkata'
    ],
    languages: ['hi', 'en']
  },
  IO: {
    name: 'British Indian Ocean Territory',
    abbr: 'IO',
    zones: [
      'Indian/Chagos'
    ],
    languages: ['en']
  },
  IQ: {
    name: 'Iraq',
    abbr: 'IQ',
    zones: [
      'Asia/Baghdad'
    ],
    languages: ['ar', 'ku']
  },
  IR: {
    name: 'Iran',
    abbr: 'IR',
    zones: [
      'Asia/Tehran'
    ],
    languages: ['fa']
  },
  IS: {
    name: 'Iceland',
    abbr: 'IS',
    zones: [
      'Africa/Abidjan',
      'Atlantic/Reykjavik'
    ],
    languages: ['is']
  },
  IT: {
    name: 'Italy',
    abbr: 'IT',
    zones: [
      'Europe/Rome'
    ],
    languages: ['it', 'de', 'fr', 'sc', 'sl']
  },
  JE: {
    name: 'Jersey',
    abbr: 'JE',
    zones: [
      'Europe/London',
      'Europe/Jersey'
    ],
    languages: ['en', 'fr']
  },
  JM: {
    name: 'Jamaica',
    abbr: 'JM',
    zones: [
      'America/Jamaica'
    ],
    languages: ['en']
  },
  JO: {
    name: 'Jordan',
    abbr: 'JO',
    zones: [
      'Asia/Amman'
    ],
    languages: ['ar']
  },
  JP: {
    name: 'Japan',
    abbr: 'JP',
    zones: [
      'Asia/Tokyo'
    ],
    languages: ['ja']
  },
  KE: {
    name: 'Kenya',
    abbr: 'KE',
    zones: [
      'Africa/Nairobi'
    ],
    languages: ['en', 'sw']
  },
  KG: {
    name: 'Kyrgyzstan',
    abbr: 'KG',
    zones: [
      'Asia/Bishkek'
    ],
    languages: ['ky', 'ru']
  },
  KH: {
    name: 'Cambodia',
    abbr: 'KH',
    zones: [
      'Asia/Bangkok',
      'Asia/Phnom_Penh'
    ],
    languages: ['km']
  },
  KI: {
    name: 'Kiribati',
    abbr: 'KI',
    zones: [
      'Pacific/Tarawa',
      'Pacific/Kanton',
      'Pacific/Kiritimati'
    ],
    languages: ['en']
  },
  KM: {
    name: 'Comoros',
    abbr: 'KM',
    zones: [
      'Africa/Nairobi',
      'Indian/Comoro'
    ],
    languages: ['ar', 'fr']
  },
  KN: {
    name: 'St Kitts & Nevis',
    abbr: 'KN',
    zones: [
      'America/Puerto_Rico',
      'America/St_Kitts'
    ],
    languages: ['en']
  },
  KP: {
    name: 'Korea (North)',
    abbr: 'KP',
    zones: [
      'Asia/Pyongyang'
    ],
    languages: ['ko']
  },
  KR: {
    name: 'Korea (South)',
    abbr: 'KR',
    zones: [
      'Asia/Seoul'
    ],
    languages: ['ko', 'en']
  },
  KW: {
    name: 'Kuwait',
    abbr: 'KW',
    zones: [
      'Asia/Riyadh',
      'Asia/Kuwait'
    ],
    languages: ['ar']
  },
  KY: {
    name: 'Cayman Islands',
    abbr: 'KY',
    zones: [
      'America/Panama',
      'America/Cayman'
    ],
    languages: ['en']
  },
  KZ: {
    name: 'Kazakhstan',
    abbr: 'KZ',
    zones: [
      'Asia/Almaty',
      'Asia/Qyzylorda',
      'Asia/Qostanay',
      'Asia/Aqtobe',
      'Asia/Aqtau',
      'Asia/Atyrau',
      'Asia/Oral'
    ],
    languages: ['kk', 'ru']
  },
  LA: {
    name: 'Laos',
    abbr: 'LA',
    zones: [
      'Asia/Bangkok',
      'Asia/Vientiane'
    ],
    languages: ['lo']
  },
  LB: {
    name: 'Lebanon',
    abbr: 'LB',
    zones: [
      'Asia/Beirut'
    ],
    languages: ['ar', 'fr']
  },
  LC: {
    name: 'St Lucia',
    abbr: 'LC',
    zones: [
      'America/Puerto_Rico',
      'America/St_Lucia'
    ],
    languages: ['en']
  },
  LI: {
    name: 'Liechtenstein',
    abbr: 'LI',
    zones: [
      'Europe/Zurich',
      'Europe/Vaduz'
    ],
    languages: ['de']
  },
  LK: {
    name: 'Sri Lanka',
    abbr: 'LK',
    zones: [
      'Asia/Colombo'
    ],
    languages: ['si', 'ta', 'en']
  },
  LR: {
    name: 'Liberia',
    abbr: 'LR',
    zones: [
      'Africa/Monrovia'
    ],
    languages: ['en']
  },
  LS: {
    name: 'Lesotho',
    abbr: 'LS',
    zones: [
      'Africa/Johannesburg',
      'Africa/Maseru'
    ],
    languages: ['en', 'st']
  },
  LT: {
    name: 'Lithuania',
    abbr: 'LT',
    zones: [
      'Europe/Vilnius'
    ],
    languages: ['lt']
  },
  LU: {
    name: 'Luxembourg',
    abbr: 'LU',
    zones: [
      'Europe/Brussels',
      'Europe/Luxembourg'
    ],
    languages: ['lb', 'de', 'fr']
  },
  LV: {
    name: 'Latvia',
    abbr: 'LV',
    zones: [
      'Europe/Riga'
    ],
    languages: ['lv']
  },
  LY: {
    name: 'Libya',
    abbr: 'LY',
    zones: [
      'Africa/Tripoli'
    ],
    languages: ['ar']
  },
  MA: {
    name: 'Morocco',
    abbr: 'MA',
    zones: [
      'Africa/Casablanca'
    ],
    languages: ['ar', 'fr']
  },
  MC: {
    name: 'Monaco',
    abbr: 'MC',
    zones: [
      'Europe/Paris',
      'Europe/Monaco'
    ],
    languages: ['fr']
  },
  MD: {
    name: 'Moldova',
    abbr: 'MD',
    zones: [
      'Europe/Chisinau'
    ],
    languages: ['ro', 'ru', 'gag', 'tr']
  },
  ME: {
    name: 'Montenegro',
    abbr: 'ME',
    zones: [
      'Europe/Belgrade',
      'Europe/Podgorica'
    ],
    languages: ['sr', 'bs', 'sq', 'hr']
  },
  MF: {
    name: 'St Martin (French)',
    abbr: 'MF',
    zones: [
      'America/Puerto_Rico',
      'America/Marigot'
    ],
    languages: ['fr']
  },
  MG: {
    name: 'Madagascar',
    abbr: 'MG',
    zones: [
      'Africa/Nairobi',
      'Indian/Antananarivo'
    ],
    languages: ['mg', 'fr']
  },
  MH: {
    name: 'Marshall Islands',
    abbr: 'MH',
    zones: [
      'Pacific/Tarawa',
      'Pacific/Kwajalein',
      'Pacific/Majuro'
    ],
    languages: ['en', 'mh']
  },
  MK: {
    name: 'North Macedonia',
    abbr: 'MK',
    zones: [
      'Europe/Belgrade',
      'Europe/Skopje'
    ],
    languages: ['mk']
  },
  ML: {
    name: 'Mali',
    abbr: 'ML',
    zones: [
      'Africa/Abidjan',
      'Africa/Bamako'
    ],
    languages: ['fr']
  },
  MM: {
    name: 'Myanmar (Burma)',
    abbr: 'MM',
    zones: [
      'Asia/Yangon'
    ],
    languages: ['my']
  },
  MN: {
    name: 'Mongolia',
    abbr: 'MN',
    zones: [
      'Asia/Ulaanbaatar',
      'Asia/Hovd',
      'Asia/Choibalsan'
    ],
    languages: ['mn']
  },
  MO: {
    name: 'Macau',
    abbr: 'MO',
    zones: [
      'Asia/Macau'
    ],
    languages: ['zh', 'pt']
  },
  MP: {
    name: 'Northern Mariana Islands',
    abbr: 'MP',
    zones: [
      'Pacific/Guam',
      'Pacific/Saipan'
    ],
    languages: ['fil', 'en', 'ch', 'es']
  },
  MQ: {
    name: 'Martinique',
    abbr: 'MQ',
    zones: [
      'America/Martinique'
    ],
    languages: ['fr']
  },
  MR: {
    name: 'Mauritania',
    abbr: 'MR',
    zones: [
      'Africa/Abidjan',
      'Africa/Nouakchott'
    ],
    languages: ['ar', 'fr']
  },
  MS: {
    name: 'Montserrat',
    abbr: 'MS',
    zones: [
      'America/Puerto_Rico',
      'America/Montserrat'
    ],
    languages: ['en']
  },
  MT: {
    name: 'Malta',
    abbr: 'MT',
    zones: [
      'Europe/Malta'
    ],
    languages: ['mt', 'en']
  },
  MU: {
    name: 'Mauritius',
    abbr: 'MU',
    zones: [
      'Indian/Mauritius'
    ],
    languages: ['en', 'fr']
  },
  MV: {
    name: 'Maldives',
    abbr: 'MV',
    zones: [
      'Indian/Maldives'
    ],
    languages: ['dv', 'en']
  },
  MW: {
    name: 'Malawi',
    abbr: 'MW',
    zones: [
      'Africa/Maputo',
      'Africa/Blantyre'
    ],
    languages: ['en', 'ny']
  },
  MX: {
    name: 'Mexico',
    abbr: 'MX',
    zones: [
      'America/Mexico_City',
      'America/Cancun',
      'America/Merida',
      'America/Monterrey',
      'America/Matamoros',
      'America/Chihuahua',
      'America/Ciudad_Juarez',
      'America/Ojinaga',
      'America/Mazatlan',
      'America/Bahia_Banderas',
      'America/Hermosillo',
      'America/Tijuana'
    ],
    languages: ['es']
  },
  MY: {
    name: 'Malaysia',
    abbr: 'MY',
    zones: [
      'Asia/Kuching',
      'Asia/Singapore',
      'Asia/Kuala_Lumpur'
    ],
    languages: ['ms']
  },
  MZ: {
    name: 'Mozambique',
    abbr: 'MZ',
    zones: [
      'Africa/Maputo'
    ],
    languages: ['pt', 'ny']
  },
  NA: {
    name: 'Namibia',
    abbr: 'NA',
    zones: [
      'Africa/Windhoek'
    ],
    languages: ['en', 'af', 'de']
  },
  NC: {
    name: 'New Caledonia',
    abbr: 'NC',
    zones: [
      'Pacific/Noumea'
    ],
    languages: ['fr']
  },
  NE: {
    name: 'Niger',
    abbr: 'NE',
    zones: [
      'Africa/Lagos',
      'Africa/Niamey'
    ],
    languages: ['fr', 'ha']
  },
  NF: {
    name: 'Norfolk Island',
    abbr: 'NF',
    zones: [
      'Pacific/Norfolk'
    ],
    languages: ['en']
  },
  NG: {
    name: 'Nigeria',
    abbr: 'NG',
    zones: [
      'Africa/Lagos'
    ],
    languages: ['en']
  },
  NI: {
    name: 'Nicaragua',
    abbr: 'NI',
    zones: [
      'America/Managua'
    ],
    languages: ['es']
  },
  NL: {
    name: 'Netherlands',
    abbr: 'NL',
    zones: [
      'Europe/Brussels',
      'Europe/Amsterdam'
    ],
    languages: ['nl']
  },
  NO: {
    name: 'Norway',
    abbr: 'NO',
    zones: [
      'Europe/Berlin',
      'Europe/Oslo'
    ],
    languages: ['no', 'nb', 'nn', 'se', 'fi']
  },
  NP: {
    name: 'Nepal',
    abbr: 'NP',
    zones: [
      'Asia/Kathmandu'
    ],
    languages: ['ne']
  },
  NR: {
    name: 'Nauru',
    abbr: 'NR',
    zones: [
      'Pacific/Nauru'
    ],
    languages: ['na', 'en']
  },
  NU: {
    name: 'Niue',
    abbr: 'NU',
    zones: [
      'Pacific/Niue'
    ],
    languages: ['ni', 'en']
  },
  NZ: {
    name: 'New Zealand',
    abbr: 'NZ',
    zones: [
      'Pacific/Auckland',
      'Pacific/Chatham'
    ],
    languages: ['en', 'mi']
  },
  OM: {
    name: 'Oman',
    abbr: 'OM',
    zones: [
      'Asia/Dubai',
      'Asia/Muscat'
    ],
    languages: ['ar']
  },
  PA: {
    name: 'Panama',
    abbr: 'PA',
    zones: [
      'America/Panama'
    ],
    languages: ['es']
  },
  PE: {
    name: 'Peru',
    abbr: 'PE',
    zones: [
      'America/Lima'
    ],
    languages: ['es', 'qu']
  },
  PF: {
    name: 'French Polynesia',
    abbr: 'PF',
    zones: [
      'Pacific/Tahiti',
      'Pacific/Marquesas',
      'Pacific/Gambier'
    ],
    languages: ['fr']
  },
  PG: {
    name: 'Papua New Guinea',
    abbr: 'PG',
    zones: [
      'Pacific/Port_Moresby',
      'Pacific/Bougainville'
    ],
    languages: ['en', 'ho', 'tpi']
  },
  PH: {
    name: 'Philippines',
    abbr: 'PH',
    zones: [
      'Asia/Manila'
    ],
    languages: ['en', 'tl']
  },
  PK: {
    name: 'Pakistan',
    abbr: 'PK',
    zones: [
      'Asia/Karachi'
    ],
    languages: ['ur', 'en']
  },
  PL: {
    name: 'Poland',
    abbr: 'PL',
    zones: [
      'Europe/Warsaw'
    ],
    languages: ['pl']
  },
  PM: {
    name: 'St Pierre & Miquelon',
    abbr: 'PM',
    zones: [
      'America/Miquelon'
    ],
    languages: ['fr']
  },
  PN: {
    name: 'Pitcairn',
    abbr: 'PN',
    zones: [
      'Pacific/Pitcairn'
    ],
    languages: ['en']
  },
  PR: {
    name: 'Puerto Rico',
    abbr: 'PR',
    zones: [
      'America/Puerto_Rico'
    ],
    languages: ['es', 'en']
  },
  PS: {
    name: 'Palestine',
    abbr: 'PS',
    zones: [
      'Asia/Gaza',
      'Asia/Hebron'
    ],
    languages: ['ar']
  },
  PT: {
    name: 'Portugal',
    abbr: 'PT',
    zones: [
      'Europe/Lisbon',
      'Atlantic/Madeira',
      'Atlantic/Azores'
    ],
    languages: ['pt']
  },
  PW: {
    name: 'Palau',
    abbr: 'PW',
    zones: [
      'Pacific/Palau'
    ],
    languages: ['en']
  },
  PY: {
    name: 'Paraguay',
    abbr: 'PY',
    zones: [
      'America/Asuncion'
    ],
    languages: ['es', 'gn']
  },
  QA: {
    name: 'Qatar',
    abbr: 'QA',
    zones: [
      'Asia/Qatar'
    ],
    languages: ['ar']
  },
  RE: {
    name: 'Réunion',
    abbr: 'RE',
    zones: [
      'Asia/Dubai',
      'Indian/Reunion'
    ],
    languages: ['fr']
  },
  RO: {
    name: 'Romania',
    abbr: 'RO',
    zones: [
      'Europe/Bucharest'
    ],
    languages: ['ro']
  },
  RS: {
    name: 'Serbia',
    abbr: 'RS',
    zones: [
      'Europe/Belgrade'
    ],
    languages: ['sr', 'hu', 'bs', 'ro', 'hr']
  },
  RU: {
    name: 'Russia',
    abbr: 'RU',
    zones: [
      'Europe/Kaliningrad',
      'Europe/Moscow',
      'Europe/Simferopol',
      'Europe/Kirov',
      'Europe/Volgograd',
      'Europe/Astrakhan',
      'Europe/Saratov',
      'Europe/Ulyanovsk',
      'Europe/Samara',
      'Asia/Yekaterinburg',
      'Asia/Omsk',
      'Asia/Novosibirsk',
      'Asia/Barnaul',
      'Asia/Tomsk',
      'Asia/Novokuznetsk',
      'Asia/Krasnoyarsk',
      'Asia/Irkutsk',
      'Asia/Chita',
      'Asia/Yakutsk',
      'Asia/Khandyga',
      'Asia/Vladivostok',
      'Asia/Ust-Nera',
      'Asia/Magadan',
      'Asia/Sakhalin',
      'Asia/Srednekolymsk',
      'Asia/Kamchatka',
      'Asia/Anadyr'
    ],
    languages: ['ru']
  },
  RW: {
    name: 'Rwanda',
    abbr: 'RW',
    zones: [
      'Africa/Maputo',
      'Africa/Kigali'
    ],
    languages: ['rw', 'en', 'fr']
  },
  SA: {
    name: 'Saudi Arabia',
    abbr: 'SA',
    zones: [
      'Asia/Riyadh'
    ],
    languages: ['ar']
  },
  SB: {
    name: 'Solomon Islands',
    abbr: 'SB',
    zones: [
      'Pacific/Guadalcanal'
    ],
    languages: ['en']
  },
  SC: {
    name: 'Seychelles',
    abbr: 'SC',
    zones: [
      'Asia/Dubai',
      'Indian/Mahe'
    ],
    languages: ['fr', 'en']
  },
  SD: {
    name: 'Sudan',
    abbr: 'SD',
    zones: [
      'Africa/Khartoum'
    ],
    languages: ['ar', 'en']
  },
  SE: {
    name: 'Sweden',
    abbr: 'SE',
    zones: [
      'Europe/Berlin',
      'Europe/Stockholm'
    ],
    languages: ['sv']
  },
  SG: {
    name: 'Singapore',
    abbr: 'SG',
    zones: [
      'Asia/Singapore'
    ],
    languages: ['en', 'ms', 'ta', 'zh']
  },
  SH: {
    name: 'St Helena',
    abbr: 'SH',
    zones: [
      'Africa/Abidjan',
      'Atlantic/St_Helena'
    ],
    languages: ['en']
  },
  SI: {
    name: 'Slovenia',
    abbr: 'SI',
    zones: [
      'Europe/Belgrade',
      'Europe/Ljubljana'
    ],
    languages: ['sl']
  },
  SJ: {
    name: 'Svalbard & Jan Mayen',
    abbr: 'SJ',
    zones: [
      'Europe/Berlin',
      'Arctic/Longyearbyen'
    ],
    languages: ['no']
  },
  SK: {
    name: 'Slovakia',
    abbr: 'SK',
    zones: [
      'Europe/Prague',
      'Europe/Bratislava'
    ],
    languages: ['sk']
  },
  SL: {
    name: 'Sierra Leone',
    abbr: 'SL',
    zones: [
      'Africa/Abidjan',
      'Africa/Freetown'
    ],
    languages: ['en']
  },
  SM: {
    name: 'San Marino',
    abbr: 'SM',
    zones: [
      'Europe/Rome',
      'Europe/San_Marino'
    ],
    languages: ['it']
  },
  SN: {
    name: 'Senegal',
    abbr: 'SN',
    zones: [
      'Africa/Abidjan',
      'Africa/Dakar'
    ],
    languages: ['fr']
  },
  SO: {
    name: 'Somalia',
    abbr: 'SO',
    zones: [
      'Africa/Nairobi',
      'Africa/Mogadishu'
    ],
    languages: ['so', 'ar']
  },
  SR: {
    name: 'Suriname',
    abbr: 'SR',
    zones: [
      'America/Paramaribo'
    ],
    languages: ['nl']
  },
  SS: {
    name: 'South Sudan',
    abbr: 'SS',
    zones: [
      'Africa/Juba'
    ],
    languages: ['en']
  },
  ST: {
    name: 'Sao Tome & Principe',
    abbr: 'ST',
    zones: [
      'Africa/Sao_Tome'
    ],
    languages: ['pt']
  },
  SV: {
    name: 'El Salvador',
    abbr: 'SV',
    zones: [
      'America/El_Salvador'
    ],
    languages: ['es']
  },
  SX: {
    name: 'St Maarten (Dutch)',
    abbr: 'SX',
    zones: [
      'America/Puerto_Rico',
      'America/Lower_Princes'
    ],
    languages: ['nl', 'en']
  },
  SY: {
    name: 'Syria',
    abbr: 'SY',
    zones: [
      'Asia/Damascus'
    ],
    languages: ['ar']
  },
  SZ: {
    name: 'Eswatini (Swaziland)',
    abbr: 'SZ',
    zones: [
      'Africa/Johannesburg',
      'Africa/Mbabane'
    ],
    languages: ['en', 'ss']
  },
  TC: {
    name: 'Turks & Caicos Is',
    abbr: 'TC',
    zones: [
      'America/Grand_Turk'
    ],
    languages: ['en']
  },
  TD: {
    name: 'Chad',
    abbr: 'TD',
    zones: [
      'Africa/Ndjamena'
    ],
    languages: ['fr', 'ar']
  },
  TF: {
    name: 'French S. Terr.',
    abbr: 'TF',
    zones: [
      'Asia/Dubai',
      'Indian/Maldives',
      'Indian/Kerguelen'
    ],
    languages: ['fr']
  },
  TG: {
    name: 'Togo',
    abbr: 'TG',
    zones: [
      'Africa/Abidjan',
      'Africa/Lome'
    ],
    languages: ['fr']
  },
  TH: {
    name: 'Thailand',
    abbr: 'TH',
    zones: [
      'Asia/Bangkok'
    ],
    languages: ['th']
  },
  TJ: {
    name: 'Tajikistan',
    abbr: 'TJ',
    zones: [
      'Asia/Dushanbe'
    ],
    languages: ['tg', 'ru']
  },
  TK: {
    name: 'Tokelau',
    abbr: 'TK',
    zones: [
      'Pacific/Fakaofo'
    ],
    languages: ['tkl', 'en']
  },
  TL: {
    name: 'East Timor',
    abbr: 'TL',
    zones: [
      'Asia/Dili'
    ],
    languages: ['pt', 'tet']
  },
  TM: {
    name: 'Turkmenistan',
    abbr: 'TM',
    zones: [
      'Asia/Ashgabat'
    ],
    languages: ['tk', 'ru']
  },
  TN: {
    name: 'Tunisia',
    abbr: 'TN',
    zones: [
      'Africa/Tunis'
    ],
    languages: ['ar', 'fr']
  },
  TO: {
    name: 'Tonga',
    abbr: 'TO',
    zones: [
      'Pacific/Tongatapu'
    ],
    languages: ['to', 'en']
  },
  TR: {
    name: 'Turkey',
    abbr: 'TR',
    zones: [
      'Europe/Istanbul'
    ],
    languages: ['tr']
  },
  TT: {
    name: 'Trinidad & Tobago',
    abbr: 'TT',
    zones: [
      'America/Puerto_Rico',
      'America/Port_of_Spain'
    ],
    languages: ['en']
  },
  TV: {
    name: 'Tuvalu',
    abbr: 'TV',
    zones: [
      'Pacific/Tarawa',
      'Pacific/Funafuti'
    ],
    languages: ['tvl', 'en']
  },
  TW: {
    name: 'Taiwan',
    abbr: 'TW',
    zones: [
      'Asia/Taipei'
    ],
    languages: ['zh']
  },
  TZ: {
    name: 'Tanzania',
    abbr: 'TZ',
    zones: [
      'Africa/Nairobi',
      'Africa/Dar_es_Salaam'
    ],
    languages: ['sw', 'en']
  },
  UA: {
    name: 'Ukraine',
    abbr: 'UA',
    zones: [
      'Europe/Simferopol',
      'Europe/Kyiv'
    ],
    languages: ['uk', 'ru']
  },
  UG: {
    name: 'Uganda',
    abbr: 'UG',
    zones: [
      'Africa/Nairobi',
      'Africa/Kampala'
    ],
    languages: ['en', 'sw']
  },
  UM: {
    name: 'US minor outlying islands',
    abbr: 'UM',
    zones: [
      'Pacific/Pago_Pago',
      'Pacific/Tarawa',
      'Pacific/Midway',
      'Pacific/Wake'
    ],
    languages: ['en']
  },
  US: {
    name: 'United States',
    abbr: 'US',
    zones: [
      'America/New_York',
      'America/Detroit',
      'America/Kentucky/Louisville',
      'America/Kentucky/Monticello',
      'America/Indiana/Indianapolis',
      'America/Indiana/Vincennes',
      'America/Indiana/Winamac',
      'America/Indiana/Marengo',
      'America/Indiana/Petersburg',
      'America/Indiana/Vevay',
      'America/Chicago',
      'America/Indiana/Tell_City',
      'America/Indiana/Knox',
      'America/Menominee',
      'America/North_Dakota/Center',
      'America/North_Dakota/New_Salem',
      'America/North_Dakota/Beulah',
      'America/Denver',
      'America/Boise',
      'America/Phoenix',
      'America/Los_Angeles',
      'America/Anchorage',
      'America/Juneau',
      'America/Sitka',
      'America/Metlakatla',
      'America/Yakutat',
      'America/Nome',
      'America/Adak',
      'Pacific/Honolulu'
    ],
    languages: ['en']
  },
  UY: {
    name: 'Uruguay',
    abbr: 'UY',
    zones: [
      'America/Montevideo'
    ],
    languages: ['es']
  },
  UZ: {
    name: 'Uzbekistan',
    abbr: 'UZ',
    zones: [
      'Asia/Samarkand',
      'Asia/Tashkent'
    ],
    languages: ['uz', 'kaa']
  },
  VA: {
    name: 'Vatican City',
    abbr: 'VA',
    zones: [
      'Europe/Rome',
      'Europe/Vatican'
    ],
    languages: ['it', 'la']
  },
  VC: {
    name: 'St Vincent',
    abbr: 'VC',
    zones: [
      'America/Puerto_Rico',
      'America/St_Vincent'
    ],
    languages: ['en']
  },
  VE: {
    name: 'Venezuela',
    abbr: 'VE',
    zones: [
      'America/Caracas'
    ],
    languages: ['es']
  },
  VG: {
    name: 'Virgin Islands (UK)',
    abbr: 'VG',
    zones: [
      'America/Puerto_Rico',
      'America/Tortola'
    ],
    languages: ['en']
  },
  VI: {
    name: 'Virgin Islands (US)',
    abbr: 'VI',
    zones: [
      'America/Puerto_Rico',
      'America/St_Thomas'
    ],
    languages: ['en']
  },
  VN: {
    name: 'Vietnam',
    abbr: 'VN',
    zones: [
      'Asia/Bangkok',
      'Asia/Ho_Chi_Minh'
    ],
    languages: ['vi']
  },
  VU: {
    name: 'Vanuatu',
    abbr: 'VU',
    zones: [
      'Pacific/Efate'
    ],
    languages: ['bi', 'en', 'fr']
  },
  WF: {
    name: 'Wallis & Futuna',
    abbr: 'WF',
    zones: [
      'Pacific/Tarawa',
      'Pacific/Wallis'
    ],
    languages: ['wls', 'fud', 'fr']
  },
  WS: {
    name: 'Samoa (western)',
    abbr: 'WS',
    zones: [
      'Pacific/Apia'
    ],
    languages: ['sm', 'en']
  },
  YE: {
    name: 'Yemen',
    abbr: 'YE',
    zones: [
      'Asia/Riyadh',
      'Asia/Aden'
    ],
    languages: ['ar']
  },
  YT: {
    name: 'Mayotte',
    abbr: 'YT',
    zones: [
      'Africa/Nairobi',
      'Indian/Mayotte'
    ],
    languages: ['fr']
  },
  ZA: {
    name: 'South Africa',
    abbr: 'ZA',
    zones: [
      'Africa/Johannesburg'
    ],
    languages: ['zu', 'xh', 'af', 'nso', 'en', 'tn', 'st', 'ts', 'ss', 've']
  },
  ZM: {
    name: 'Zambia',
    abbr: 'ZM',
    zones: [
      'Africa/Maputo',
      'Africa/Lusaka'
    ],
    languages: ['en']
  },
  ZW: {
    name: 'Zimbabwe',
    abbr: 'ZW',
    zones: [
      'Africa/Maputo',
      'Africa/Harare'
    ],
    languages: ['en', 'sn', 'nd']
  }
}
