export default {
  name: 'BillingPlansTotalCards',
  props: {
    totalAmount: {
      type: [String, Number],
      required: true
    }
  },
  data () {
    return {
    }
  },
  methods: {
    contieneSignoDolar (valor: string): boolean {
      return valor.startsWith('$')
    },
    removeDollarSign (input: string | number): string {
      if (typeof input === 'string') {
        if (this.contieneSignoDolar(input)) {
          return input.substring(1)
        }
      }
      let inputAsString: string = String(input)
      inputAsString = inputAsString.replace(/\$/g, '')
      const result: string = parseFloat(inputAsString).toFixed(2)
      return result
    }
  },
  mounted () {
  }
}
