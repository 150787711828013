import PartnersUseCases from '@/modules/Partners/application/PartnersUseCases'
import { dataToGetCommissions } from '@/modules/Partners/domain/PartnersController'
import { mapState } from 'vuex'
import PartnersController from '../../../controller/Partners.controller'
import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'
import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
export default {
  components: {
    KeybeIcon
  },
  computed: {
    ...mapState('UserStore', ['user', 'token'])
  },
  mounted () {
    this.getCommissions()
  },
  data () {
    return {
      commissions: [],
      partnersController: new PartnersController(),
      KeybeIconType
    }
  },
  methods: {
    async getCommissions (): Promise<void> {
      const data: dataToGetCommissions = {
        email: this.user.email,
        token: this.token
      }
      const response = await PartnersUseCases.getCommissions(this.partnersController, data)
      if (response.status) {
        this.commissions = response.data
      }
    }
  }
}
