import { EventBus } from '@/eventBus'
import { BikyConfigUseCases } from '@/modules/bky/application/BikyConfigUseCases'
import { KnowledgeBaseTypeEnum } from '@/modules/bky/domain/BikyConfig'
import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import KeybeAvatar from '@/shared/infrastructure/ui/KeybeAvatar.vue'
import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'
import { mapState } from 'vuex'
import BikyConfigController from '../../../controllers/BikyConfig.controller'
import BikyStoreController from '@/modules/bky/infrastructure/persistence/BikyStore.controller'

export default {
  name: 'BikyConfigChatCorrectResponse',
  components: {
    KeybeAvatar,
    KeybeIcon
  },
  props: {
    message: {
      type: Object,
      default: ''
    },
    prevMessage: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      KeybeIconType,
      text: '',
      bikyConfigController: new BikyConfigController(),
      loading: false
    }
  },
  computed: {
    ...mapState('AppStore', ['selectedApp']),
    ...mapState('UserStore', ['token', 'user']),
    messageText () {
      return this.message?.text || ''
    },
    image () {
      return BikyStoreController.getBikyImage()
    }
  },
  methods: {
    close () {
      this.$emit('close')
    },
    async save () {
      if (this.loading) return

      this.loading = true
      if (!this.text) {
        EventBus.$emit('toast', 'error', this.$t('writeTheCorrectAnswer'))
      }

      const knowledgeFragment = {
        text: `TITLE: ${this.prevMessage} \nCONTENT: ${this.text}`,
        type: KnowledgeBaseTypeEnum.USER,
        typeId: this.user?.userId || '',
        appUUID: this.selectedApp?.uuid || ''
      }

      const created = await BikyConfigUseCases.createKnowledgeBase(this.bikyConfigController, {
        ...knowledgeFragment,
        token: this.token
      })

      this.loading = false

      if (!created) {
        EventBus.$emit('toast', 'error', this.$t('correctAnswerNotSaved'))
        return
      }

      EventBus.$emit('toast', 'success', this.$t('correctAnswerSaved'))
      this.close()
    }
  }
}
