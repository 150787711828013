import { Adviser } from '../Advisers'
import { Message, MessageSenders } from '../Message/Message'
import { User } from '../Users'
import { SentimentData, SentimentType } from '../SentimentData/SentimentData'

export enum RoomStateEnum {
    REGISTERED = 'registered',
    READY_FOR_ADVISOR = 'readyForAdvisor',
    WITH_ADVISOR = 'withAdvisor',
    ENDED = 'ended',
    WITH_BOT = 'withBot',
    WITH_BOT_AUTHORIZE = 'withBotAuthorize',
}

export type RoomState = RoomStateEnum;

export enum RoomChannelsEnum {
    WHATSAPP = 'whatsApp',
    WEB = 'web',
    SMS = 'sms',
    SUPPORT = 'support',
    FACEBOOK_MESSENGER = 'facebookMessenger',
    SMOOCH_WP = 'smooch_wp',
    GUPSHUP_WP = 'gupshup_wp',
    INSTAGRAM = 'instagram',
    GMAIL = 'gmail',
    WIZARD = 'wizard',
    HIBOT_WP = 'hibot_wp',
    RBM = 'rbm',
    KEYBE = 'keybe',
    KEYBE_WP = 'keybe_wp'
}

export type RoomChannels = RoomChannelsEnum;

export interface cdpUser {
    uuid: string;
    fullName: string;
    photo?: string;
}

export interface Room {
    _id: string;
    state: RoomState;
    lastMessageFrom: MessageSenders;
    channel: RoomChannels;
    companyUUID: string;
    appUUID: string;
    closedByUserId?: string;
    name: string;
    crmUserId: string;
    userGuest: string;
    currentSesionId?: string;
    lastSesionId?: string;
    hasNewMessage?: boolean;
    createdAt?: Date;
    companyName?: string;
    lastFullChat?: Date;
    updatedAt?: Date;
    lastMessage?: Message;
    helpBot?: boolean;
    advisorId?: string;
    userHost?: string;
    userGuestLocal?: string;
    appName?: string;
    options?: string;
    lastUserMessage?: string;
    lastBotMessage?: string;
    lastAdviserMessage?: string;
    lastStatusMessage?: string;
    favorite: string[];
    pinned: number;
    tags?: Array<string>;
    user?: User;
    adviser?: Adviser;
    sentiment?: SentimentData;
    sentimentV2?: SentimentType;
    users?: Array<Adviser>;
    lastInteraction?: number;
    readInteractions?: { [key: string]: number };
    unreadMentions?: { [key: string]: number };
    cdpUser?: cdpUser,
    pageName?: string,
    hasError?: boolean,
}
