
































import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import { mapState } from 'vuex'
import Colors from '@/shared/domain/colors/Color'
import AdvisersRepository from '@/modules/conversations/infrastructure/http/Advisers.repository'
import KBText from '@/shared/infrastructure/ui/inputs/text/KBText.vue'
import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'
import GroupConfigCard from '@/modules/configuration/infrastructure/ui/components/Autoassign/Groups/GroupConfigCard.vue'

export default {
  name: 'AutoassignGroupsConfig.vue',
  components: {
    KBText,
    KeybeIcon,
    GroupConfigCard
  },
  props: {
    value: {
      required: true
    }
  },
  data () {
    return {
      KeybeIconType,
      Colors,
      newGroup: '',
      clearInput: false
    }
  },
  computed: {
    ...mapState('AppStore', ['advisers'])
  },
  methods: {
    getAdvisersByGroup (group: string) {
      return this.advisers.filter(adviser => adviser?.groups?.includes(group))
    },
    addNewGroup () {
      if (!this.newGroup) return

      this.$emit('input', {
        ...this.value,
        groups: [...this.value.groups, this.newGroup]
      })
      this.newGroup = ''
      this.clearInput = true
      this.$nextTick(() => {
        this.clearInput = false
      })
    },
    async removeGroup (group: string) {
      console.log('removeGroup', group)
      this.$emit('input', {
        ...this.value,
        groups: this.value.groups.filter(item => item !== group)
      })
      const advisers = this.getAdvisersByGroup(group)
      for (const adviser of advisers) {
        const newAdviser = {
          ...adviser,
          groups: adviser?.groups.filter(g => g !== group) || []
        }
        const response = await AdvisersRepository.updateAdviser(newAdviser)
        if (response.status) {
          this.$store.dispatch('AppStore/updateAdviser', newAdviser)
        }
      }
    }
  }
}
