






















import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import Colors from '@/shared/domain/colors/Color'
import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'

export default {
  name: 'KBCashCard',
  props: ['itemIcon'],
  components: {
    KeybeIcon
  },
  data () {
    return {
      itemIconVisible: false,
      KeybeIconType,
      Colors
    }
  },
  watch: {
    itemIcon () {
      if (this.itemIcon) {
        this.itemIconVisible = true
      }
    }
  },
  methods: {
    getIcon (card) {
      if (!card) return ''

      const icons = {
        visa: KeybeIconType.VISA,
        mastercard: KeybeIconType.MASTER_CARD,
        amex: KeybeIconType.AMEX,
        diners: KeybeIconType.DINERS_CLUB,
        discover: KeybeIconType.DISCOVER,
        jcb: KeybeIconType.JCB,
        unionpay: KeybeIconType.UNIONPAY
      }
      return icons[card.tokenResponse.brand.toLowerCase()]
    }
  }
}
