












export default {
  name: 'TesseractDarkIcon'
}
