export default {
  props: {
    customVideoStyleContainer: {
      type: Object,
      default: () => ({})
    },
    customVideoStyle: {
      type: Object,
      default: () => ({})
    },
    src: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      playing: false,
      muted: false,
      progressTime: '00:00',
      totalTime: '00:00',
      isStoped: true
    }
  },
  methods: {
    play () {
      this.isStoped = false
      this.playing = true
      this.$refs.videoPlayer.play()
    },
    pause () {
      this.playing = false
      this.$refs.videoPlayer.pause()
    },
    mute () {
      this.muted = true
      this.$refs.videoPlayer.muted = true
    },
    unmute () {
      this.muted = false
      this.$refs.videoPlayer.muted = false
    },
    togglePlay () {
      if (this.playing) {
        this.pause()
      } else {
        this.play()
      }
    },
    toggleMute () {
      if (this.muted) {
        this.unmute()
      } else {
        this.mute()
      }
    }
  },
  mounted () {
    this.$refs.videoPlayer.addEventListener('ended', () => {
      this.playing = false
      this.isStoped = true
    })
    // get total time and set it to totalTime
    this.$refs.videoPlayer.addEventListener('loadedmetadata', () => {
      const duration = this.$refs.videoPlayer.duration
      const minutes = Math.floor(duration / 60)
      const seconds = Math.floor(duration % 60)
      let secondsString = seconds.toString()
      if (seconds < 10) {
        secondsString = `0${seconds}`
      }
      this.totalTime = `${minutes}:${secondsString}`
    })
    // get current time and set it to progressTime
    this.$refs.videoPlayer.addEventListener('timeupdate', () => {
      const currentTime = this.$refs.videoPlayer.currentTime
      const minutes = Math.floor(currentTime / 60)
      const seconds = Math.floor(currentTime % 60)
      let secondsString = seconds.toString()
      if (seconds < 10) {
        secondsString = `0${seconds}`
      }
      this.progressTime = `${minutes}:${secondsString}`
    })
    // muted event
    this.$refs.videoPlayer.addEventListener('volumechange', () => {
      if (this.$refs.videoPlayer.muted) {
        this.muted = true
      } else {
        this.muted = false
      }
    })
  }
}
