import axios from 'axios'
import { StatusCodes } from '@/models/apis/StatusCodes'
import store from '@/store'
import { RestResponseFactory } from '@/shared/infrastructure/http/RestResponse'

export default class AuthService {
  static async login (data: { email: string; password: string, device_type: string }) {
    const url = `${process.env.VUE_APP_AUTH_SERVICE}auth/email`
    try {
      const requestData = {
        ...data,
        device_type: 'web'
      }
      const response = await axios.post(url, requestData)
      return new RestResponseFactory().process({ response })
    } catch (e) {
      return new RestResponseFactory().process({
        response: {
          status: StatusCodes.HTTP_500_INTERNAL_SERVER_ERROR,
          message: e.response.data.message
        }
      })
    }
  }

  static async resetEmailPassword (email: string) {
    try {
      const url = `${process.env.VUE_APP_AUTH_SERVICE}auth/reset/email`
      const response = await axios.post(url, { email })
      return new RestResponseFactory().process({ response })
    } catch (e) {
      const response: {[key: string]: any} = {
        status: e.status || StatusCodes.HTTP_500_INTERNAL_SERVER_ERROR,
        message: e?.response?.data?.message.toString() || ''
      }
      return new RestResponseFactory().process({ response })
    }
  }

  static async resetPassword (password: string, code: string) {
    try {
      const url = `${process.env.VUE_APP_AUTH_SERVICE}auth/reset-password`
      const response = await axios.post(url, { code, password, confirmPassword: password })
      return new RestResponseFactory().process({ response })
    } catch (e) {
      const response: {[key: string]: any} = {
        status: e.status || StatusCodes.HTTP_500_INTERNAL_SERVER_ERROR,
        message: e?.response?.data?.message.toString() || ''
      }
      return new RestResponseFactory().process({ response })
    }
  }

  static async validateV4Token (token: string) {
    try {
      const url = `${process.env.VUE_APP_AUTH_SERVICE}auth/v4User/${token}`
      const response = await axios.get(url)
      return new RestResponseFactory().process({ response })
    } catch (e) {
      return new RestResponseFactory().process({ response: { status: StatusCodes.HTTP_500_INTERNAL_SERVER_ERROR } })
    }
  }

  static async logout () {
    try {
      const token = store.getters['UserStore/getToken']
      const deviceToken = store.getters['UserStore/getDeviceToken']
      const url = `${process.env.VUE_APP_AUTH_SERVICE}auth/logout/${deviceToken}`
      const response = await axios.get(url, {
        headers: {
          Authorization: 'Bearer ' + token
        }
      })
      return new RestResponseFactory().process({ response })
    } catch (e) {
      return new RestResponseFactory().process({ response: { status: StatusCodes.HTTP_500_INTERNAL_SERVER_ERROR } })
    }
  }

  static async getInfoByToken () {
    const token = store.getters['UserStore/getToken']
    const url = `${process.env.VUE_APP_AUTH_SERVICE}auth/validate`

    try {
      const response = await axios.post(url, {}, {
        headers: {
          Authorization: 'Bearer ' + token
        }
      })
      return new RestResponseFactory().process({ response })
    } catch (e) {
      return new RestResponseFactory().process({ response: { status: StatusCodes.HTTP_500_INTERNAL_SERVER_ERROR } })
    }
  }

  static async validateToken () {
    const token = store.getters['UserStore/getToken']
    const url = `${process.env.VUE_APP_AUTH_SERVICE}auth/validate`
    try {
      const response = await axios.post(url, {}, {
        headers: {
          Authorization: 'Bearer ' + token
        }
      })
      return new RestResponseFactory().process({ response })
    } catch (e) {
      return new RestResponseFactory().process({ response: { status: StatusCodes.HTTP_500_INTERNAL_SERVER_ERROR } })
    }
  }
}
