<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       fill="none"
       viewBox="0 0 16 11">
    <path :fill="color"
          d="M.016 5.645c0-1.223-.02-2.447.006-3.67.018-.828.71-1.52 1.54-1.613.177-.02.356-.031.534-.031C4.307.329 6.519.329 8.73.329c1.164 0 1.93.762 1.93 1.925.002 2.28.002 4.56 0 6.84 0 .77-.31 1.374-1.017 1.708-.259.122-.57.187-.857.19-2.298.014-4.596.005-6.894.008C.766 11.002-.016 10.165 0 9.112.02 7.957.005 6.8.005 5.645h.01ZM16 5.671V9.12c-.002.487-.21.789-.63.943-.29.106-.553.034-.792-.125-.956-.636-1.907-1.28-2.864-1.917a.287.287 0 0 1-.145-.27c.004-1.39 0-2.781.007-4.171 0-.082.05-.197.114-.242a245.23 245.23 0 0 1 2.89-1.944.897.897 0 0 1 .968-.045c.328.18.451.492.452.857V5.67Z"/>
  </svg>
</template>

<script>
export default {
  name: 'VideoIcon.vue',
  props: {
    color: {
      type: String,
      default: '#8898A9'
    }
  }
}
</script>

<style scoped>

</style>
