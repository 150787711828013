import { Company } from './Company'

export enum PartnerTypEnum {
  PARTNER_PRO = 'partner_pro',
  PARTNER_REFERRAL = 'partner_referral',
  REFERRAL_OF_PARTNER = 'referral_of_partner'
}
/* eslint-disable camelcase */
export interface App {
    uuid?: string;
    name?: string;
    baseUrl?: string;
    client_key?: string;
    color?: string;
    companyId?: number;
    deletedAt?: string;
    email?: string;
    habeasDataURL?: string;
    inactive?: boolean;
    inactiveAt?: string;
    mandrillKey?: string;
    microsite_parameters?: string;
    photo?: string;
    prefix?: string;
    redirectUrl?: string;
    slug?: string;
    welcome_email?: boolean;
    company?: Company;
    partnerResponsible?: string;
    partnerType?: PartnerTypEnum;
}

export interface ExtraData {
    access?: string;
    field?: string;
    value?: string;
}

export interface AppInfo {
    uuid?: string;
    name?: string;
    baseUrl?: string;
    client_key?: string;
    color?: string;
    companyUUID?: string;
    deletedAt?: any;
    extra_data?: Array<ExtraData>;
    habeasDataURL?: string;
    inactive?: boolean;
    inactiveAt?: any;
    photo?: string;
    prefix?: string;
    redirectUrl?: string;
    slug?: string;
    welcome_email?: boolean;
    welcome_sms?: boolean;
}

export interface AppConfig {
    alegraId?: string;
    appName?: string;
    appUUID?: string;
    biller?: string;
    companyUUID?: string;
    createdAt?: string;
    currency?: string;
    email?: string;
    id?: number;
    invoiceDay?: number;
    qboId?: string;
    updatedAt?: string;
}

export interface Rol {
    id?: number;
    code?: string;
    name?: string;
}
