import { render, staticRenderFns } from "./ImporterFieldSelection.vue?vue&type=template&id=5c5ae836&scoped=true&"
import script from "./ImporterFieldSelection.vue?vue&type=script&lang=ts&"
export * from "./ImporterFieldSelection.vue?vue&type=script&lang=ts&"
import style0 from "./ImporterFieldSelection.vue?vue&type=style&index=0&id=5c5ae836&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c5ae836",
  null
  
)

export default component.exports