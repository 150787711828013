import { MetricsTabs } from '@/modules/metrics/infrastructure/enums/MetricsTabs'
import moment from 'moment'

import MetricsSidebar from '@/modules/metrics/infrastructure/ui/components/MetricsSidebar/MetricsSidebar.vue'
import MetricsPage from '@/modules/metrics/infrastructure/ui/components/MetricsPage/MetricsPage.vue'
import ConversationsPage from '@/modules/metrics/infrastructure/ui/components/ConversationsPage/ConversationsPage.vue'
import EndingPage from '@/modules/metrics/infrastructure/ui/components/EndingPage/EndingPage.vue'
import SalesPage from '@/modules/metrics/infrastructure/ui/components/SalesPage/SalesPage.vue'

// import Loader from '@/shared/infrastructure/ui/Loader.vue'
const FORMAT_DATE = 'YYYY-MM-DD'

export default {
  name: 'MetricsScreen',
  components: {
    MetricsSidebar,
    ConversationsPage,
    // Loader,
    MetricsPage,
    EndingPage,
    SalesPage
  },
  data () {
    return {
      dataComponent: null,
      MetricsTabs,
      selectedTab: MetricsTabs.REALTIME,
      groups: [],
      consumptions: [],
      invoices: [],
      totalConsumption: 0,
      dateString: '',
      dateFrom: null,
      dateTo: null,
      cardsValue: null,
      deals: null,
      idFlow: null,
      nameFlow: null,
      range: null,
      dataRequest: {}
    }
  },
  computed: {
    advisorFilterArrayId: {
      get () {
        return this.$store.state.MetricsStore.advisorFilterArrayId || []
      },
      set (value) {
        this.$store.commit('MetricsStore/SET_EDITING_OBJECT', {
          key: 'advisorFilterArrayId',
          value
        })
      }
    },
    advisorFilterArray: {
      get () {
        return this.$store.state.MetricsStore.advisorFilterArray || []
      },
      set (value) {
        this.$store.commit('MetricsStore/SET_EDITING_OBJECT', {
          key: 'advisorFilterArray',
          value
        })
      }
    },
    advisorFilter: {
      get () {
        return this.$store.state.MetricsStore.advisorFilter || null
      },
      set (value) {
        this.$store.commit('MetricsStore/SET_EDITING_OBJECT', {
          key: 'advisorFilter',
          value
        })
      }
    },
    selectedTabComponent () {
      const components = {
        [MetricsTabs.REALTIME]: MetricsPage,
        [MetricsTabs.CONVERSATIONS]: ConversationsPage,
        [MetricsTabs.ENDING]: EndingPage,
        [MetricsTabs.SALES]: SalesPage
      }

      return components[this.selectedTab]
    },
    // loading: {
    //   get () {
    //     return this.$store.state.MetricsStore.loading || false
    //   },
    //   set (value) {
    //     this.$store.commit('MetricsStore/SET_EDITING_OBJECT', {
    //       key: 'loading',
    //       value
    //     })
    //   }
    // },
    currentSelectedTab: {
      get () {
        return this.$store.state.MetricsStore.currentSelectedTab || null
      },
      set (value) {
        this.$store.commit('MetricsStore/SET_EDITING_OBJECT', {
          key: 'currentSelectedTab',
          value
        })
      }
    }
  },
  watch: {
    selectedTab () {
      this.dateString = this.$t('thisMonth')
      const dateFrom = moment().startOf('month').format(FORMAT_DATE)
      const dateTo = moment().format(FORMAT_DATE)
      if (this.selectedTab !== MetricsTabs.REALTIME && this.selectedTab !== MetricsTabs.SALES) {
        this.handleDateChange({ dateString: this.$t('thisMonth'), dateFrom, dateTo })
      }
      this.currentSelectedTab = this.selectedTab
    }
  },
  methods: {
    async handleFlowChange (data) {
      const { id, name } = data
      this.idFlow = id
      this.nameFlow = name
      this.handleDateChange()
    },
    async handleDateChange (data) {
      const params = {
        dateFrom: null,
        dateTo: null,
        dateString: null
      }
      if (data) {
        try {
          // this.loading = true
          const { dateString, dateFrom, dateTo } = data
          params.dateFrom = dateFrom
          params.dateTo = dateTo
          params.dateString = dateString
          this.dateFrom = dateFrom
          this.dateTo = dateTo
          this.dateString = dateString
        } catch (error) {
          // this.loading = false
        }
      } else {
        params.dateFrom = this.dateFrom
        params.dateTo = this.dateTo
        params.dateString = this.dateString
      }
      if (params.dateFrom > params.dateTo) {
      } else {
        this.range = {
          dateTo: params.dateTo,
          dateFrom: params.dateFrom
        }
      }
      if (this.selectedTab === MetricsTabs.SALES) {
      }
      if (this.selectedTab === MetricsTabs.ENDING) {
      }
      if (this.selectedTab === MetricsTabs.CONVERSATIONS) {
      }
    }
  },
  async created () {
    this.currentSelectedTab = this.selectedTab
  }
}
