var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"metricTableGray pt-1",class:_vm.$vuetify.theme.dark ? 'metricTableGrayDark' : '',style:(_vm.agents.length === 0 ? 'min-height: 40px;' : '')},[_c('span',{staticClass:"pt-3 pb-1"},[_vm._v(_vm._s(_vm.$t('salesByAdvisor'))+" ("+_vm._s(_vm.nameFlow)+")")]),_c('DateRange',{attrs:{"range":_vm.range}}),(_vm.agents.length > 0)?_c('div',{staticClass:"KBSimpleTable smallTable"},[_c('table',{staticClass:"KBSimpleTable__table"},[_c('thead',[_c('tr',_vm._l((_vm.headers),function(label,labelIndex){return _c('th',{key:("KBSimpleTable-" + (label.text) + "-" + labelIndex),staticClass:"KBSimpleTable__header smallTable__header"},[_c('div',{staticClass:"tooltip"},[_c('p',[_vm._v(_vm._s(label.text))]),_c('span',{staticClass:"tooltiptext",style:({
                  width: label.text.length * 10 + 'px',
                  left:
                    labelIndex >= 5
                      ? '-' + labelIndex * 25 + 'px'
                      : '-' + labelIndex * 5 + 'px',
                })},[_vm._v(_vm._s(label.text))])])])}),0)]),_c('tbody',_vm._l((_vm.agents),function(item,itemIndex){return _c('tr',{key:("KBSimpleTable-item-" + itemIndex),staticClass:"KBSimpleTable__row"},[_c('td',{class:_vm.$vuetify.theme.dark
                ? 'KBSimpleTable__itemDark'
                : 'KBSimpleTable__item'},[_vm._v(" "+_vm._s(item.name ? item.name : '-')+" ")]),_c('td',{class:_vm.$vuetify.theme.dark
                ? 'KBSimpleTable__itemDark'
                : 'KBSimpleTable__item'},[_vm._v(" "+_vm._s(item.deals)+" ")]),_c('td',{class:_vm.$vuetify.theme.dark
                ? 'KBSimpleTable__itemDark'
                : 'KBSimpleTable__item'},[_c('div',{staticClass:"flex justify-between"},[_vm._v(" "+_vm._s(item.total)+" ")])])])}),0)])]):_c('div',{staticClass:"msgServiceError"},[_vm._v(_vm._s(_vm.$t('noDataFound')))])],1)}
var staticRenderFns = []

export { render, staticRenderFns }