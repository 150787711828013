import { DateTime } from 'luxon'

export default class DateTimeUtil {
  static toLocaleDateFormatted (
    utcDate?: string,
    format: string = 'yyyy-MM-dd HH:mm:ss'
  ): string {
    const dateSplitted: string[] = (
      utcDate || DateTime.now().toISODate()
    ).split('.')
    const date: DateTime = DateTime.fromISO(
      dateSplitted[0] || DateTime.now().toISO(),
      { locale: 'UTC', zone: 'utc' }
    )
    const dateLocale: DateTime = date.toLocal()
    return dateLocale.toFormat(format)
  }
}
