var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.close),expression:"close"}],staticClass:"d-flex flex-column relative w-full"},[_c('div',{key:_vm.rerenderLocal,staticClass:"KBAutocomplete",class:{
      'KBAutocomplete--open': _vm.showSelector,
      'KBAutotomplete--outlined': _vm.outlined,
      'KBAutocomplete--small': _vm.small,
    }},[_c('div',{staticClass:"KBAutocomplete__container",class:{
        'KBAutocomplete__container--outlined': _vm.outlined,
        'KBAutocomplete__container--seamless': _vm.seamless,
        'KBAutocomplete__container--focused': _vm.focused,
        'KBAutocomplete__conainer--small': _vm.small,
      }},[(_vm.icon)?_c('span',{class:("mdi " + _vm.icon + " icon")}):_vm._e(),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search),expression:"search"}],staticClass:"search",attrs:{"type":"text","placeholder":_vm.placeholder,"autocomplete":"text","disabled":_vm.disabled},domProps:{"value":(_vm.search)},on:{"keypress":_vm.keyType,"focus":function($event){_vm.focused = true},"blur":function($event){_vm.focused = false},"click":function($event){_vm.showSelector = true},"input":function($event){if($event.target.composing){ return; }_vm.search=$event.target.value}}}),(!_vm.loading)?_c('div',{staticClass:"d-flex items-center",on:{"click":_vm.openSelector}},[(_vm.search)?_c('KBIconButton',{staticClass:"mr-2",attrs:{"icon":'mdi-close',"size":'22px'},on:{"click":_vm.clearSearch}}):_vm._e(),_c('span',{staticClass:"mdi mdi-magnify search-icon"})],1):_c('lottie-animation',{ref:"anim",staticClass:"loaderAnimation",staticStyle:{"width":"30px"},attrs:{"loop":true,"animationData":require('@/shared/assets/animations/circleLoader.json')}})],1)]),(_vm.showSelector)?_c('div',{staticClass:"KBAutocomplete__list newBackground",class:{
      'KBAutocomplete__list--small': _vm.small,
    },style:(("top: " + (_vm.outlined ? '40px' : _vm.small ? '30px' : '53px') + "; " + (_vm.customZIndex ? ("z-index: " + _vm.customZIndex) : '')))},_vm._l((_vm.itemsComputed),function(item,index){return _c('div',{key:index,staticClass:"list-item",class:{ 'list-item--selected': _vm.isSelected(item) },on:{"click":function($event){return _vm.selectItem(item)}}},[_vm._v(" "+_vm._s(_vm.itemText ? _vm.multipleText ? item[_vm.itemText[0]] && item[_vm.itemText[1]] ? item[_vm.itemText[0]] + " " + (item[_vm.itemText[1]] ? item[_vm.itemText[1]] : "") : item[_vm.itemText[0]] : item[_vm.itemText] : item)+" ")])}),0):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }