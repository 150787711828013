import { GenericResponse } from '@/models/apis/GenericResponse'
import { dataToCreateCatalog, dataToGetCatalog, dataToUpdateCatalog } from '../../domain/CatalogController'
import axios from 'axios'
import { RestResponseFactory } from '@/models/apis/RestResponse'

export default class CatalogRepository {
  async getCatalog ({ token, appUUID }: dataToGetCatalog) : Promise<GenericResponse> {
    try {
      const response = await axios.get(
        `${process.env.VUE_APP_MICROSITES_URL}/microsites/front/catalog/${appUUID}`,
        {
          headers: {
            Authorization: 'Bearer ' + token
          }
        }
      )
      return new RestResponseFactory().process({ response })
    } catch (e) {
      return new RestResponseFactory().process({ response: { status: 500 } })
    }
  }

  async createCatalog ({ token, data }: dataToCreateCatalog): Promise<GenericResponse> {
    try {
      const response = await axios.post(
        `${process.env.VUE_APP_MICROSITES_URL}/microsites/catalog`,
        data,
        {
          headers: {
            Authorization: 'Bearer ' + token
          }
        }
      )
      return new RestResponseFactory().process({ response })
    } catch (e) {
      return new RestResponseFactory().process({ response: { status: 500 } })
    }
  }

  async updateCatalog ({ token, data }: dataToUpdateCatalog): Promise<GenericResponse> {
    try {
      const response = await axios.put(
        `${process.env.VUE_APP_MICROSITES_URL}/microsites/catalog/update`,
        data,
        {
          headers: {
            Authorization: 'Bearer ' + token
          }
        }
      )
      return new RestResponseFactory().process({ response })
    } catch (e) {
      return new RestResponseFactory().process({ response: { status: 500 } })
    }
  }
}
