import FeesServices from '@/modules/configuration/application/services/FeesServices'
import { EventBus } from '@/eventBus'
import KeybeText from '@/modules/DesignSystem/infrastructure/components/KeybeText/KeybeText.vue'
import { currencyToUSD } from '@/utils/currencyToUSD'
import { formatCurrency } from '@/utils/currencyFormatter'
import Loader from '@/shared/infrastructure/ui/Loader.vue'

export default {
  name: 'KBInvoicesServices',
  components: {
    KeybeText,
    Loader
  },
  props: {
  },
  data () {
    return {
      throttledTimer: null,
      lastExecution: 0,
      throttleInterval: 300,
      totalItems: 0,
      itemsPerPage: 9,
      currentPage: 1,
      loading: false,
      columns: [
        { key: 'type', label: 'code' },
        { key: 'SKU', label: 'SKU' },
        { key: 'amountCOP', label: 'amount' },
        { key: 'description', label: 'description' },
        { key: 'createdAt', label: 'createdAt', filter: 'date' },
        { key: 'charge', label: 'type' },
        { key: 'periodicity', label: 'card_fintech_frequency' },
        { key: 'alegraId', label: 'Alegra' },
        { key: 'qboId', label: 'Qbo' }
      ],
      data: [
      ],
      sortKey: '',
      sortOrder: '',
      searchQuery: ''
    }
  },
  mounted () {
    this.calculateSize(true)
  },
  beforeDestroy () {
    window.removeEventListener('resize', () => this.calculateSize())
  },
  computed: {
    totalPages () {
      return Math.ceil(this.totalItems / this.itemsPerPage)
    },
    filteredData () {
      return this.data.filter(item => {
        return Object.values(item).some(val =>
          String(val).toLowerCase().includes(this.searchQuery.toLowerCase())
        )
      })
    },
    filteredAndSortedData () {
      const data = [...this.filteredData]
      if (this.sortKey) {
        data.sort((a, b) => {
          const itemA = a[this.sortKey]
          const itemB = b[this.sortKey]
          if (this.sortOrder === 'asc') {
            return itemA > itemB ? 1 : -1
          } else {
            return itemA < itemB ? 1 : -1
          }
        })
      }
      if (data.length === 0 && this.searchQuery) {
        console.log('////////////')
        this.throttledGetCompany()
        // this.debouncedGetCompany()
      }
      return data
    }
  },
  async created () {
    await this.getServicesMethod()
  },
  filters: {
    currencyUSD (value) {
      if (!value) return
      return currencyToUSD(value)
    }
  },
  watch: {
    // currentPag (newVal, oldVal) {
    //   if (newVal !== oldVal) {
    //     this.getServicesMethod()
    //   }
    // },
    searchQuery (newVal, oldVal) {
      if (newVal !== oldVal) {
        if (!this.searchQuery) {
          this.getServicesMethod()
        }
      }
    }
  },
  methods: {
    formatCurrency,
    throttledGetCompany () {
      const now = Date.now()
      if (now - this.lastExecution > this.throttleInterval) {
        if (this.throttledTimer) {
          clearTimeout(this.throttledTimer)
          this.throttledTimer = null
        }
        this.lastExecution = now
        this.getServicesMethod(true)
      } else if (!this.throttledTimer) {
        this.throttledTimer = setTimeout(() => {
          this.throttledGetCompany()
        }, this.throttleInterval - (now - this.lastExecution))
      }
    },
    async prevPage () {
      if (this.currentPage > 1) {
        this.currentPage--
        await this.getServicesMethod()
      }
    },
    async nextPage () {
      if (this.currentPage * this.itemsPerPage < this.totalItems) {
        this.currentPage++
        await this.getServicesMethod()
      }
    },
    getValue (item, key) {
      const keys = key.split('.')
      let value = item
      for (const k of keys) {
        if (value && k in value) {
          value = value[k]
        } else {
          return null // Retorna null o algún valor por defecto si la clave no existe
        }
      }
      return value
    },
    formatDate (dateString) {
      const date = new Date(dateString)
      const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`
      return formattedDate
    },
    sortData (key) {
      if (this.sortKey === key) {
        this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc'
      } else {
        this.sortKey = key
        this.sortOrder = 'asc'
      }
    },
    async getServicesMethod (search) {
      if (this.loading) return
      try {
        this.loading = true
        this.data = []
        const pagination = {
          page: this.currentPage,
          limit: this.itemsPerPage
        }
        const searchMain = search ? this.searchQuery.trim() : ''
        const response = await FeesServices.getServices(searchMain, pagination)
        if (response) {
          // const { data, count } = response.data
          this.data = response.data
          // this.totalItems = count
        }
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
      }
    },
    calculateSize (fullscreen = false) {
      if (fullscreen) {
        const clientHeight = document.documentElement.clientHeight
        const clientWidth = document.documentElement.clientWidth
        document.documentElement.style.setProperty(
          '--floating-screen-height',
          `${clientHeight - 30}px`
        )
        document.documentElement.style.setProperty(
          '--floating-screen-width',
          `${clientWidth - 50}px`
        )

        // set top and left to 0
        const floatingWindow = document.getElementById('KeybeFloatingBox')
        floatingWindow.style.top = '0px'
        floatingWindow.style.left = '0px'

        EventBus.$emit('FloatingWindow-resize')
        return
      }

      const clientHeight = document.documentElement.clientHeight
      this.totalHeight = clientHeight * 0.8097
      this.heightWithoutHeader = clientHeight * 0.8097 - 30
      document.documentElement.style.setProperty(
        '--floating-screen-height',
        `${this.heightWithoutHeader}px`
      )

      const clientWidth = document.documentElement.clientWidth
      this.width = clientWidth * 0.8
      document.documentElement.style.setProperty(
        '--floating-screen-width',
        `${this.width}px`
      )

      EventBus.$emit('FloatingWindow-resize')
    }
  }
}
