import axios from 'axios'
import store from '@/store/index'
import { RestResponseFactory } from '@/models/apis/RestResponse'
const cancelToken = axios.CancelToken
let source = cancelToken.source()
export default class CampaignsService {
  static async getCampaigns (
    pagination: { page: any; sortBy: any; descending: any; itemsPerPage: any },
    search: any,
    archivedCampaign: any
  ) {
    try {
      const token = store.getters['UserStore/getV4Token']
      const companyId = store.getters['CompanyStore/getSelectedCompany']?.uuid || 'none'
      const appId = store.getters['AppStore/getSelectedApp']?.uuid || ''

      const response = await axios.get(
        `${process.env.VUE_APP_BASE_URL}/companies/${companyId}/apps/${appId}/campaigns`,
        {
          headers: {
            Authorization: 'Bearer ' + token
          },
          params: {
            page: pagination.page || 1,
            sortBy: pagination.sortBy || '',
            descending: pagination.descending ?? '',
            limit: pagination.itemsPerPage || 200,
            search,
            archivedCampaign: archivedCampaign
          }
          // cancelToken: sourceCampaigns.token
        }
      )
      return new RestResponseFactory().process({ response }).data.campaigns
    } catch (error) {
      console.log('eeror')
      return new RestResponseFactory().process({ response: { status: 500 } })
    }
  }

  static async getCampaign (campaignId: any) {
    try {
      const token = store.getters['UserStore/getV4Token']
      const companyId = store.getters['CompanyStore/getSelectedCompany']?.uuid || 'none'
      const appId = store.getters['AppStore/getSelectedApp']?.uuid || ''

      const response = await axios.get(
        `${process.env.VUE_APP_BASE_URL}/companies/${companyId}/apps/${appId}/campaigns/${campaignId}`,
        {
          headers: {
            Authorization: 'Bearer ' + token
          }
        }
      )
      return new RestResponseFactory().process({ response }).data
    } catch (error) {
      return new RestResponseFactory().process({ response: { status: 500 } })
    }
  }

  static async getCampaignUsers (
    campaignId: string,
    pagination: {
      page: number,
      itemsPerPage: number,
      search: string,
      status: string
    }
  ) {
    try {
      source.cancel('Request canceled')
      source = cancelToken.source()
      const { page, itemsPerPage, search, status } = pagination
      const token = store.getters['UserStore/getV4Token']
      const companyId = store.getters['CompanyStore/getSelectedCompany']?.uuid || 'none'
      const appId = store.getters['AppStore/getSelectedApp']?.uuid || ''

      const response = await axios.get(
        `${process.env.VUE_APP_BASE_URL}/companies/${companyId}/apps/${appId}/campaigns/${campaignId}/users?page=${page}&limit=${itemsPerPage}&search=${search}&status=${status}`,
        {
          headers: {
            Authorization: 'Bearer ' + token
          },
          cancelToken: source.token
        }
      )
      return new RestResponseFactory().process({ response }).data
    } catch (error) {
      return new RestResponseFactory().process({ response: { status: 500 } })
    }
  }

  static async getCampaignUsersList (
    campaignId: any,
    pagination: any,
    search: any,
    advancedSearch: any
  ) {
    try {
      const token = store.getters['UserStore/getV4Token']
      const companyId = store.getters['CompanyStore/getSelectedCompany']?.uuid || 'none'
      const appId = store.getters['AppStore/getSelectedApp']?.uuid || ''

      const response = await axios.get(
        `${process.env.VUE_APP_BASE_URL}/companies/${companyId}/apps/${appId}/campaigns/${campaignId}/users`,
        {
          headers: {
            Authorization: 'Bearer ' + token
          },
          params: {
            page: pagination.page || 1,
            sortBy: pagination.sortBy || '',
            descending: pagination.descending ?? '',
            limit: pagination.itemsPerPage || 200,
            search: search,
            status: advancedSearch.status
          }
        }
      )
      return new RestResponseFactory().process({ response }).data.users
    } catch (error) {
      return new RestResponseFactory().process({ response: { status: 500 } })
    }
  }

  static async getPeopleList (pagination, search: '') {
    try {
      source.cancel('Request canceled')
      source = cancelToken.source()
      const token = store.getters['UserStore/getToken']
      // console.log('TOKEN PEOPLE LIST', token)
      const companyId = store.getters['CompanyStore/getSelectedCompany']?.uuid || 'none'
      const appId = store.getters['AppStore/getSelectedApp']?.uuid || ''

      const response = await axios({
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + token
        },
        cancelToken: source.token,
        params: {
          page: pagination.page || 1,
          sortBy: pagination.sortBy || '',
          descending: pagination.descending || '',
          limit: pagination.itemsPerPage || 100,
          search
        },
        url: `${process.env.VUE_APP_BASE_URL}/companies/${companyId}/apps/${appId}/lists`
      })
      return new RestResponseFactory().process({ response }).data
    } catch (error) {
      return new RestResponseFactory().process({ response: { status: 500 } })
    }
  }

  static async getSpecificPeopleList (listId) {
    try {
      const token = store.getters['UserStore/getToken']
      const companyId = store.getters['CompanyStore/getSelectedCompany']?.uuid || 'none'
      const appId = store.getters['AppStore/getSelectedApp']?.uuid || ''

      const response = await axios({
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + token
        },
        url: `${process.env.VUE_APP_BASE_URL}/companies/${companyId}/apps/${appId}/lists/${listId}`
      })
      return new RestResponseFactory().process({ response }).data.list
    } catch (error) {
      return new RestResponseFactory().process({ response: { status: 500 } })
    }
  }

  static async createCampaign (data) {
    const { name, type, date, listId, email, sms, push, whatsApp } = data
    const token = store.getters['UserStore/getV4Token']
    const companyId = store.getters['CompanyStore/getSelectedCompany']?.uuid || 'none'
    const appId = store.getters['AppStore/getSelectedApp']?.uuid || ''
    const url = `${process.env.VUE_APP_CAMPAIGNS_URL}/companies/${companyId}/apps/${appId}/campaigns`
    try {
      const response = await axios({
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + token
        },
        url,
        data: {
          name,
          type,
          date,
          listId,
          email,
          sms,
          push,
          whatsApp,
          status: 'draft'
        }
      })
      return new RestResponseFactory().process({ response }).data
    } catch (error) {
      console.log('error', error)
      return new RestResponseFactory().process({ response: { status: 500 } })
    }
  }

  static async linkCampaign (data: {
    campaignId: string,
    createFromUser: boolean
  }) {
    const { campaignId, createFromUser } = data
    const token = store.getters['UserStore/getV4Token']
    const companyId = store.getters['CompanyStore/getSelectedCompany']?.uuid || 'none'
    const appId = store.getters['AppStore/getSelectedApp']?.uuid || ''
    const url = `${process.env.VUE_APP_BASE_URL}/companies/${companyId}/apps/${appId}/campaigns/${campaignId}/users/create/list`
    try {
      const response = await axios({
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + token
        },
        url,
        data: { createFromUser }
      })
      return new RestResponseFactory().process({ response }).data
    } catch (error) {
      return new RestResponseFactory().process({ response: { status: 500 } })
    }
  }

  // static async deleteCampaignFile (data) {
  //   const { campaignId, fileName } = data
  //   const token = store.getters['UserStore/getV4Token']
  //   const companyId = store.getters['CompanyStore/getSelectedCompany'].uuid
  //   const appId = store.getters['AppStore/getSelectedApp'].uuid
  //   const url = `${process.env.VUE_APP_BASE_URL}/companies/${companyId}/apps/${appId}/campaigns/${campaignId}/remove/attachment`
  //   try {
  //     const response = await axios({
  //       method: 'PUT',
  //       headers: {
  //         Authorization: 'Bearer ' + token
  //       },
  //       data: { fileName },
  //       url
  //     })
  //     return new RestResponseFactory().process({ response }).data
  //   } catch (error) {
  //     return new RestResponseFactory().process({ response: { status: 500 } })
  //   }
  // }

  // static async downloadCampaignFile (data) {
  //   const { campaignId, fileName } = data
  //   const token = store.getters['UserStore/getV4Token']
  //   const companyId = store.getters['CompanyStore/getSelectedCompany'].uuid
  //   const appId = store.getters['AppStore/getSelectedApp'].uuid
  //   const url = `${process.env.VUE_APP_BASE_URL}/companies/${companyId}/apps/${appId}/campaigns/${campaignId}/download/attachment`
  //   try {
  //     const response = await axios({
  //       method: 'GET',
  //       responseType: 'blob',
  //       headers: {
  //         Authorization: 'Bearer ' + token
  //       },
  //       params: { fileName },
  //       url
  //     })
  //     return new RestResponseFactory().process({ response }).data
  //   } catch (error) {
  //     return new RestResponseFactory().process({ response: { status: 500 } })
  //   }
  // }

  static async exportCampaign (data: { campaignId: string; filename: string }) {
    const { campaignId, filename } = data
    const token = store.getters['UserStore/getV4Token']
    const companyId = store.getters['CompanyStore/getSelectedCompany']?.uuid || 'none'
    const appId = store.getters['AppStore/getSelectedApp']?.uuid || ''
    const url = `${process.env.VUE_APP_CAMPAIGNS_URL}/companies/${companyId}/apps/${appId}/campaigns/${campaignId}/export/bin`
    try {
      const response = await axios({
        method: 'GET',
        responseType: 'blob',
        headers: {
          Authorization: 'Bearer ' + token
        },
        params: { filename },
        url
      })
      return new RestResponseFactory().process({ response }).data
    } catch (error) {
      return new RestResponseFactory().process({ response: { status: 500 } })
    }
  }

  static async sendCampaign (data: {
    campaignId: string,
    schedule: string,
    scheduleDate: string
  }) {
    const { campaignId, schedule, scheduleDate } = data
    const token = store.getters['UserStore/getV4Token']
    const companyId = store.getters['CompanyStore/getSelectedCompany']?.uuid || 'none'
    const appId = store.getters['AppStore/getSelectedApp']?.uuid || ''
    const url = `${process.env.VUE_APP_CAMPAIGNS_URL}/companies/${companyId}/apps/${appId}/campaigns/${campaignId}/users/send`
    try {
      const response = await axios({
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + token
        },
        data: { schedule, scheduleDate },
        url
      })
      return new RestResponseFactory().process({ response })
    } catch (error) {
      return new RestResponseFactory().process({ response: { status: 500 } })
    }
  }

  static async archiveCampaign (campaignId, archiveCampaign) {
    const token = store.getters['UserStore/getV4Token']
    const companyId = store.getters['CompanyStore/getSelectedCompany']?.uuid || 'none'
    const appId = store.getters['AppStore/getSelectedApp']?.uuid || ''

    try {
      const response = await axios({
        method: 'PUT',
        headers: {
          Authorization: 'Bearer ' + token
        },
        url: `${process.env.VUE_APP_BASE_URL}/companies/${companyId}/apps/${appId}/campaigns/${campaignId}/archive`,
        data: {
          archiveCampaign: archiveCampaign.toString()
        }
      })
      return new RestResponseFactory().process({ response })
    } catch (error) {
      return new RestResponseFactory().process({ response: { status: 500 } })
    }
  }

  static async cancelCampaign (campaignId) {
    const token = store.getters['UserStore/getV4Token']
    const companyId = store.getters['CompanyStore/getSelectedCompany']?.uuid || 'none'
    const appId = store.getters['AppStore/getSelectedApp']?.uuid || ''
    try {
      const response = await axios({
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`
        },
        url: `${process.env.VUE_APP_CAMPAIGNS_URL}/companies/${companyId}/apps/${appId}/campaigns/${campaignId}/users/send/schedule/cancel`
      })
      return new RestResponseFactory().process({ response })
    } catch (error) {
      return new RestResponseFactory().process({ response: { status: 500 } })
    }
  }

  static async validateDomain () {
    try {
      const token = store.getters['UserStore/getV4Token']
      const appId = store.getters['AppStore/getSelectedApp']?.uuid || ''

      const response = await axios.get(
        `${process.env.VUE_APP_APPS_URL}/apps/${appId}/`,
        {
          headers: {
            Authorization: 'Bearer ' + token
          }
        }
      )
      return new RestResponseFactory().process({ response }).data
    } catch (error) {
      return new RestResponseFactory().process({ response: { status: 500 } })
    }
  }

  static async getDomain () {
    try {
      const token = store.getters['UserStore/getV4Token']
      const companyId = store.getters['CompanyStore/getSelectedCompany']?.uuid || 'none'
      const appId = store.getters['AppStore/getSelectedApp']?.uuid || ''

      const response = await axios.get(
        `${process.env.VUE_APP_BASE_URL}/companies/${companyId}/apps/${appId}/emailSender`,
        {
          headers: {
            Authorization: 'Bearer ' + token
          }
        }
      )
      return new RestResponseFactory().process({ response }).data
    } catch (error) {
      return new RestResponseFactory().process({ response: { status: 500 } })
    }
  }

  //
  // V6 Services
  //

  static async getCampaignsV6 (
    pagination: { page: any; sortBy: any; descending: any; itemsPerPage: any },
    search: any
  ) {
    try {
      const token = store.getters['UserStore/getV4Token']
      const companyId = store.getters['CompanyStore/getSelectedCompany']?.uuid || 'none'
      const appId = store.getters['AppStore/getSelectedApp']?.uuid || ''
      // sourceCampaigns.cancel('Operation canceled')
      // sourceCampaigns = cancelToken.source()
      const response = await axios.get(
        `${process.env.VUE_APP_CAMPAIGNS_URL}/companies/${companyId}/apps/${appId}/campaigns`,
        {
          headers: {
            Authorization: 'Bearer ' + token
          },
          params: {
            page: pagination.page || 1,
            sortBy: pagination.sortBy || '',
            descending: pagination.descending ?? '',
            limit: pagination.itemsPerPage || 200,
            search
          }
          // cancelToken: sourceCampaigns.token
        }
      )
      return new RestResponseFactory().process({ response }).data.campaigns
    } catch (error) {
      console.log('eeror')
      return new RestResponseFactory().process({ response: { status: 500 } })
    }
  }

  static async getCampaignV6 (campaignId: string) {
    try {
      const token = store.getters['UserStore/getV4Token']
      const companyId = store.getters['CompanyStore/getSelectedCompany']?.uuid || 'none'
      const appId = store.getters['AppStore/getSelectedApp']?.uuid || ''

      const response = await axios.get(
        `${process.env.VUE_APP_CAMPAIGNS_URL}/companies/${companyId}/apps/${appId}/campaigns/${campaignId}`,
        {
          headers: {
            Authorization: 'Bearer ' + token
          }
        }
      )
      return new RestResponseFactory().process({ response }).data
    } catch (error) {
      return new RestResponseFactory().process({ response: { status: 500 } })
    }
  }

  static async getCampaignUsersV6 (
    campaignId: string,
    pagination: {
      page: number,
      itemsPerPage: number,
      search: string,
      status: string,
      responseStatus: string
    }
  ) {
    try {
      source.cancel('Request canceled')
      source = cancelToken.source()
      const { page, itemsPerPage, search, status, responseStatus } = pagination
      const token = store.getters['UserStore/getV4Token']
      const companyId = store.getters['CompanyStore/getSelectedCompany']?.uuid || 'none'
      const appId = store.getters['AppStore/getSelectedApp']?.uuid || ''

      const response = await axios.get(
        `${process.env.VUE_APP_CAMPAIGNS_URL}/companies/${companyId}/apps/${appId}/campaigns/${campaignId}/users?page=${page}&limit=${itemsPerPage}&search=${search}&status=${status}&responseStatus=${responseStatus}`,
        {
          headers: {
            Authorization: 'Bearer ' + token
          },
          cancelToken: source.token
        }
      )
      return new RestResponseFactory().process({ response }).data
    } catch (error) {
      return new RestResponseFactory().process({ response: { status: 500 } })
    }
  }

  static async getPeopleListV6 (pagination: {[key: string]: any}, search: string = '') {
    try {
      source.cancel('Request canceled')
      source = cancelToken.source()
      const token = store.getters['UserStore/getToken']
      // console.log('TOKEN PEOPLE LIST', token)
      const companyId = store.getters['CompanyStore/getSelectedCompany']?.uuid || 'none'
      const appId = store.getters['AppStore/getSelectedApp']?.uuid || ''

      const response = await axios({
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + token
        },
        cancelToken: source.token,
        params: {
          page: pagination.page || 1,
          sortBy: pagination.sortBy || '',
          descending: pagination.descending || '',
          limit: pagination.itemsPerPage || 100,
          search
        },
        url: `${process.env.VUE_APP_CAMPAIGNS_URL}/companies/${companyId}/apps/${appId}/lists`
      })
      return new RestResponseFactory().process({ response }).data
    } catch (error) {
      return new RestResponseFactory().process({ response: { status: 500 } })
    }
  }

  static async createCampaignV6 (data: {[key: string]: any}) {
    const {
      name,
      type,
      date,
      listId,
      email,
      sms,
      push,
      whatsApp,
      checkEmailDomain,
      expiresAt
    } = data

    const token = store.getters['UserStore/getV4Token']
    const companyId = store.getters['CompanyStore/getSelectedCompany']?.uuid || 'none'
    const appId = store.getters['AppStore/getSelectedApp']?.uuid || ''
    const language =
      store.getters['AppStore/getLanguage'] === 'es'
        ? 'es-CO'
        : 'en-US,en;q=0.5'

    const url = `${process.env.VUE_APP_CAMPAIGNS_URL}/companies/${companyId}/apps/${appId}/campaigns`
    try {
      const response = await axios({
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + token,
          'accept-language': language
        },
        url,
        data: {
          name,
          type,
          date,
          listId,
          email,
          sms,
          push,
          whatsApp,
          status: 'draft',
          checkEmailDomain,
          expiresAt
        }
      })
      return new RestResponseFactory().process({ response }).data
    } catch (error) {
      if (error.response) {
        // Accede a la respuesta de error de la excepción
        const errorResponse = error.response.data

        // Puedes acceder a los campos del objeto de error, por ejemplo:
        const errorMessage = errorResponse.error
        const errorDetails = errorResponse.errors

        // Realiza cualquier otra manipulación de error necesaria
        return { response: { status: 400, errorMessage, errorDetails } }
      } else {
        // Si la excepción no contiene una respuesta de error, maneja la excepción de otra manera
        console.error('Error desconocido:', error)
      }

      return new RestResponseFactory().process({
        response: { status: 500, error }
      })
    }
  }

  static async archiveCampaignV6 (campaignId: string, archiveCampaign: boolean) {
    const token = store.getters['UserStore/getV4Token']
    const companyId = store.getters['CompanyStore/getSelectedCompany']?.uuid || 'none'
    const appId = store.getters['AppStore/getSelectedApp']?.uuid || ''

    try {
      const response = await axios({
        method: 'PUT',
        headers: {
          Authorization: 'Bearer ' + token
        },
        url: `${process.env.VUE_APP_CAMPAIGNS_URL}/companies/${companyId}/apps/${appId}/campaigns/${campaignId}/archive`,
        data: {
          archiveCampaign: archiveCampaign.toString()
        }
      })
      return new RestResponseFactory().process({ response })
    } catch (error) {
      return new RestResponseFactory().process({ response: { status: 500 } })
    }
  }

  static async linkCampaignV6 (data: {
    campaignId: string,
    createFromUser: boolean
  }) {
    const { campaignId, createFromUser } = data
    const token = store.getters['UserStore/getV4Token']
    const companyId = store.getters['CompanyStore/getSelectedCompany']?.uuid || 'none'
    const appId = store.getters['AppStore/getSelectedApp']?.uuid || ''
    const url = `${process.env.VUE_APP_CAMPAIGNS_URL}/companies/${companyId}/apps/${appId}/campaigns/${campaignId}/users/create/list`
    try {
      const response = await axios({
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + token
        },
        url,
        data: { createFromUser }
      })
      return new RestResponseFactory().process({ response }).data
    } catch (error) {
      return new RestResponseFactory().process({ response: { status: 500 } })
    }
  }

  static async createSegmentFromFilterV6 (data: {
    campaignId: string,
    status: string,
    responseStatus: string,
    segmentName?: string
  }) {
    const { campaignId, segmentName, status, responseStatus } = data
    const token = store.getters['UserStore/getV4Token']
    const companyId = store.getters['CompanyStore/getSelectedCompany']?.uuid || 'none'
    const appId = store.getters['AppStore/getSelectedApp']?.uuid || ''
    const url = `${process.env.VUE_APP_CAMPAIGNS_URL}/companies/${companyId}/apps/${appId}/campaigns/${campaignId}/users/export/segment`
    try {
      const response = await axios({
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + token
        },
        url,
        data: { status, responseStatus, segmentName }
      })
      return new RestResponseFactory().process({ response })
    } catch (error) {
      return new RestResponseFactory().process({ response: { status: 500 } })
    }
  }

  static async uploadFileToCampaignV6 (campaignId: string, file: File) {
    const token = store.getters['UserStore/getV4Token']
    const companyId = store.getters['CompanyStore/getSelectedCompany']?.uuid || 'none'
    const appId = store.getters['AppStore/getSelectedApp']?.uuid || ''
    const url = `${process.env.VUE_APP_CAMPAIGNS_URL}/companies/${companyId}/apps/${appId}/campaigns/${campaignId}/upload/attachment`
    const fd = new FormData()
    fd.append('attachments', file)
    try {
      const response = await axios({
        method: 'PUT',
        headers: {
          Authorization: 'Bearer ' + token
        },
        data: fd,
        url
      })
      // console.log('uploadFileToCampaign', response)
      return new RestResponseFactory().process({ response })
    } catch (error) {
      return new RestResponseFactory().process({ response: { status: 500 } })
    }
  }
}
