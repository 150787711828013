



















import ColumnGraph from './ColumnGraph'
export default ColumnGraph
