import LottieAnimation from 'lottie-web-vue'

export default {
  name: 'Send',
  components: {
    LottieAnimation
  },
  mounted () {
  },
  data () {
    return {
    }
  }
}
