<template>
  <svg :width="width"
       :height="height"
       viewBox="0 0 13 12"
       fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <path d="M5.03125 1.3H8.96875C9.08906 1.3 9.1875 1.3975 9.1875 1.51667V2.6H4.8125V1.51667C4.8125 1.3975 4.91094 1.3 5.03125 1.3ZM3.5 1.51667V2.6H1.75C0.784766 2.6 0 3.37729 0 4.33333V6.93333H5.25H8.75H14V4.33333C14 3.37729 13.2152 2.6 12.25 2.6H10.5V1.51667C10.5 0.679792 9.81367 0 8.96875 0H5.03125C4.18633 0 3.5 0.679792 3.5 1.51667ZM14 7.8H8.75V8.66667C8.75 9.14604 8.35898 9.53333 7.875 9.53333H6.125C5.64102 9.53333 5.25 9.14604 5.25 8.66667V7.8H0V11.2667C0 12.2227 0.784766 13 1.75 13H12.25C13.2152 13 14 12.2227 14 11.2667V7.8Z"
          :fill="color"/>
  </svg>

</template>
<script>
export default {
  name: 'PrimaryIcon',
  props: {
    width: {
      type: Number,
      default: 13
    },
    height: {
      type: Number,
      default: 12
    },
    color: {
      type: String,
      default: '#B6BEC7'
    }
  }
}
</script>
