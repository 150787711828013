
import { mapActions, mapState } from 'vuex'
import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import { getButtonParams, mediaTemplateValidation } from '@/components/conversations/utils/TemplateUtils'
import { TemplateTypes } from '@/components/conversations/enums/TemplateTypes'
import { EventBus } from '@/eventBus'
import { WpProviders } from '@/components/conversations/models/WpProviders'
import ButtonCustomizeField from '@/modules/outbound/infrastructure/ui/components/forms/ButtonCustomizeField/ButtonCustomizeField.vue'
import MediaSelector from '@/components/conversations/components/chat/NewConversation/MediaSelector.vue'
import KeybeEmojiPicker from '@/shared/infrastructure/ui/KeybeEmojiPicker.vue'

export default {
  name: 'CampaingsMessages',
  components: {
    KeybeEmojiPicker,
    MediaSelector,
    ButtonCustomizeField
  },
  props: ['defaultTemplate', 'template', 'provider', 'campaignType'],
  data () {
    return {
      buttonCustomizeColor: '#e2e6e9',
      showTemplateLabel: true,
      KeybeIconType,
      messageText: null,
      parsedTemplateMessage: '',
      paramSelected: null,
      buttonsParamsLinksInputs: []
    }
  },
  mounted () {
    this.mountComponent()
    this.insertCustomFieldHtml = null
    this.insertCustomFielEmoji = null
    this.insertCustomField = null
    this.message = null
    this.messagePreviewCrop = null
  },
  computed: {
    ...mapState('ConversationStore', ['wpProviders']),
    ...mapState('CampaingsStore', ['currentTemplate', 'templates']),
    changeParamsVisible: {
      get () {
        return this.$store.state.CampaingsStore.changeParamsVisible || false
      },
      set (value) {
        this.$store.commit('CampaingsStore/SET_EDITING_OBJECT', {
          key: 'changeParamsVisible',
          value
        })
      }
    },
    openEmojiPicker: {
      get () {
        return this.$store.state.CampaingsStore.openEmojiPicker || null
      },
      set (value) {
        this.$store.commit('CampaingsStore/SET_EDITING_OBJECT', {
          key: 'openEmojiPicker',
          value
        })
      }
    },
    buttonsParamsLinks: {
      get () {
        return this.$store?.state?.CampaingsStore?.buttonsParamsLinks || []
      },
      set (value) {
        this.$store.commit('CampaingsStore/SET_EDITING_OBJECT', {
          key: 'buttonsParamsLinks',
          value
        })
      }
    },
    buttonsParams: {
      get () {
        return this.$store?.state?.CampaingsStore?.buttonsParams || []
      },
      set (value) {
        this.$store.commit('CampaingsStore/SET_EDITING_OBJECT', {
          key: 'buttonsParams',
          value
        })
      }
    },
    buttonsLabels: {
      get () {
        return this.$store?.state?.CampaingsStore?.buttonsLabels || []
      },
      set (value) {
        this.$store.commit('CampaingsStore/SET_EDITING_OBJECT', {
          key: 'buttonsLabels',
          value
        })
      }
    },
    bspButtons () {
      if (!this.isBSP) {
        return false
      }
      if (!this.template?.components) {
        return false
      }
      const components = JSON.parse(this.template?.components)
      return components.find((component) => component.type === 'BUTTONS')
    },
    messagePreviewCrop: {
      get () {
        return this.$store.state.CampaingsStore.messagePreviewCrop
      },
      set (value) {
        this.$store.commit('CampaingsStore/SET_EDITING_OBJECT', {
          key: 'messagePreviewCrop',
          value
        })
      }
    },
    insertCustomField: {
      get () {
        return this.$store.state.CampaingsStore.insertCustomField
      },
      set (value) {
        this.$store.commit('CampaingsStore/SET_EDITING_OBJECT', {
          key: 'insertCustomField',
          value
        })
      }
    },
    insertCustomFieldHtml: {
      get () {
        return this.$store.state.CampaingsStore.insertCustomFieldHtml
      },
      set (value) {
        this.$store.commit('CampaingsStore/SET_EDITING_OBJECT', {
          key: 'insertCustomFieldHtml',
          value
        })
      }
    },
    insertCustomFielEmoji: {
      get () {
        return this.$store.state.CampaingsStore.insertCustomFielEmoji
      },
      set (value) {
        this.$store.commit('CampaingsStore/SET_EDITING_OBJECT', {
          key: 'insertCustomFielEmoji',
          value
        })
      }
    },
    templateMediaUrl: {
      get () {
        return this.$store.state.CampaingsStore.templateMediaUrl || null
      },
      set (value) {
        this.$store.commit('CampaingsStore/SET_EDITING_OBJECT', {
          key: 'templateMediaUrl',
          value
        })
      }
    },
    isImageHeader () {
      return this.header?.format === TemplateTypes.IMAGE || this.header?.format === TemplateTypes.VIDEO || this.header?.format === TemplateTypes.DOCUMENT
    },
    header () {
      if (!this.isBSP) {
        return false
      }

      if (!this.template?.components) {
        return false
      }

      const components = JSON.parse(this.template?.components)

      return components.find((component) => component.type === 'HEADER')
    },
    showMediaSelector () {
      if (!this.isTextTemplate) {
        return true
      }

      return this.isImageHeader
    },
    fileType () {
      if (!this.isBSP) {
        return ''
      }

      return this.header?.format || ''
    },
    lengthContent: {
      get () {
        return this.$store.state.CampaingsStore.lengthContent
      },
      set (value) {
        this.$store.commit('CampaingsStore/SET_EDITING_OBJECT', {
          key: 'lengthContent',
          value
        })
      }
    },
    currentTemplate: {
      get () {
        return this.$store.state.CampaingsStore.currentTemplate || null
      },
      set (value) {
        this.$store.commit('CampaingsStore/SET_EDITING_OBJECT', {
          key: 'currentTemplate',
          value
        })
      }
    },
    message: {
      get () {
        return this.$store.state.CampaingsStore.message || null
      },
      set (value) {
        this.$store.commit('CampaingsStore/SET_EDITING_OBJECT', {
          key: 'message',
          value
        })
      }
    },
    messagePreview: {
      get () {
        return this.$store.state.CampaingsStore.messagePreview || null
      },
      set (value) {
        this.$store.commit('CampaingsStore/SET_EDITING_OBJECT', {
          key: 'messagePreview',
          value
        })
      }
    },
    templateParams: {
      get () {
        return this.$store.state.CampaingsStore.templateParams || []
      },
      set (value) {
        this.$store.commit('CampaingsStore/SET_EDITING_OBJECT', {
          key: 'templateParams',
          value
        })
      }
    },
    isBSP () {
      return this.template?.provider === WpProviders.WpKeybe
    },
    templateMessage () {
      if (this.defaultTemplate) {
        return this.template.templateMessage
      } else {
        let message = this.template.templateMessage
        if (message) {
          for (let i = 0; i < this.templateParams.length; i++) {
            message = message.replace(this.templateParams[i].name, `<span style="color:${this.$vuetify.theme.dark ? '#0FAEF6' : '#ffff'}; cursor: pointer" id="${'Param' + i}">${this.templateParams[i].value || this.$t('yourTextHere')}</span>`)
          }
          return `<span style="color: #4C535C; font-size: 0.9rem;">${message}</span>`
        } else return ''
      }
    },
    templateLabel () {
      return this.template?.templateMessage?.replace('{{1}}', '') || ''
    },
    isTextTemplate () {
      return this.template?.type === TemplateTypes.TEXT
    }
  },
  beforeDestroy () {
    this.message = ''
    this.currentTemplate = null
    this.messagePreview = null
    this.buttonsParams = []
    this.buttonsLabels = []
  },
  methods: {
    ...mapActions('CampaingsStore', ['setTemplates', 'setCurrentLink']),
    onSelectEmoji (emoji) {
      this.insertCustomFielEmoji ? this.insertCustomFielEmoji = `${this.insertCustomFielEmoji} ${emoji.data}` : this.insertCustomFielEmoji = emoji.data
      if (this.message) {
        this.message = `${this.messagePreview} ${emoji.data}`
      } else {
        this.message = emoji.data
      }
      if (this.messagePreview) {
        this.messagePreview = `${this.messagePreview} ${emoji.data}`
      } else {
        this.messagePreview = emoji.data
      }
    },
    processButtonLinkLabel (string) {
      const regex = /\{\{(.*?)\}\}/g
      let match
      while ((match = regex.exec(string)) !== null) {
        string = string.replace(match[0], '')
      }
      return string
    },
    validateButtonUrl ({ label, param }) {
      if (!param) {
        EventBus.$emit('toast', 'error', this.$t('allParametersMustBeFilled'))
        return
      }
      const url = label.replace('{{1}}', param)
      window.open(url, '_blank')
    },
    mountComponent () {
      this.getMessagePreview()
      this.validateTemplateButtons()
      if (!this.isTextTemplate && !this.isBSP) {
        this.processImageTemplate()
      }
      this.templateParams = this.getParams(this.parsedTemplateMessage ? this.parsedTemplateMessage : this.template.templateMessage)
      if (!this.defaultTemplate) {
        setTimeout(() => {
          this.createParamsEventListener()
        }, 500)
      }
      this.getMessagePreview()
    },
    selectCustomField (field) {
      try {
        const selRange = this.$refs.messageEditor.selectionEnd
        const firstPart = this.message.substring(0, selRange)
        const lastPart = this.message.substring(selRange)
        this.message = firstPart + field + lastPart
        this.messageText = this.message
        this.$refs.messageEditor.focus()
        setTimeout(() => {
          this.$refs.messageEditor.selectionEnd = selRange + field.length
        }, 50)
      } catch (error) {
        console.error(error)
      }
    },
    getParams (templateMessage) {
      if (templateMessage) {
        const params = []
        const regex = /\{\{(.*?)\}\}/g
        let match
        while ((match = regex.exec(templateMessage)) !== null) {
          params.push({
            id: match[1],
            value: '',
            name: `{{${match[1]}}}`
          })
        }
        return params
      }
    },
    editTemplate (text, input, index) {
      this.templateParams[index].value = text
      if (text.length === 0) {
        input.style.width = '104px'
        return
      }
      let newWidth = this.lengthContent ? text.length * 8.5 : text.length * 9.2
      // get punctuation signs and add 2px
      // eslint-disable-next-line no-useless-escape
      const punctuation = input.value.match(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g)
      // if there is punctuation signs reduce matches in text.length
      if (punctuation) {
        newWidth -= punctuation.length * 2
      }
      input.style.width = `${newWidth}px`
      this.getMessagePreview()
    },
    validateTemplateButtons () {
      if (!this.template?.templateMessage) {
        return
      }
      if (this.isBSP) {
        this.buttonsLabels = []
        this.buttonsParams = []
        const buttons = this.bspButtons?.buttons
        if (buttons?.length >= 1) {
          buttons.forEach((button) => {
            if (button.type === 'URL') {
              const params = this.getParams(button.url)
              // if (Object.prototype.hasOwnProperty.call(button, 'example')) {
              if (params.length > 0) {
                this.buttonsLabels.push(button?.text)
                this.buttonsParams.push(button.url)
                const paramRegex = /\{\{(.*?)\}\}/g
                const match = paramRegex.exec(button.url)
                if (match) {
                  this.buttonsParamsLinks.push('')
                  this.buttonsParamsLinksInputs.push('')
                } else {
                  this.buttonsParamsLinks.push(null)
                  this.buttonsParamsLinksInputs.push(null)
                }
              }
            }
          })
          return
        }
      }
      const data = getButtonParams(this.template.templateMessage)
      this.buttonsLabels = data.buttonLabels
      this.buttonsParams = data.buttonParams
      this.buttonsParamsLinks = data.buttonParams.map((param) => {
        const paramRegex = /\{\{(.*?)\}\}/g
        const match = paramRegex.exec(param)
        if (match) {
          return ''
        }
        return null
      })
      if (this.buttonsParams.length > 0) {
        this.parsedTemplateMessage = data.templateMessage
      }
    },
    callTwo (templateMessage) {
      const vue = this
      for (let i = 0; i < this.templateParams.length; i++) {
        const nodeList = document.querySelectorAll(`[id="${'Param' + i}"]`)
        for (const node of nodeList) {
          node.addEventListener('click', function () {
            vue.paramSelected = i
          })
        }
      }
    },
    parseTemplateMessage (templateMessage) {
      if (templateMessage) {
        if (this.templateParams) {
          for (let i = 0; i < this.templateParams.length; i++) {
            templateMessage = templateMessage.replace(this.templateParams[i].name, `<input size="1" class="TemplateEditorInput__textfield" style="color: ${this.$vuetify.theme.dark ? '#fff' : '#000'}; font-weight: 500; outline: none; max-width: 95%" id="${'Param' + i}" placeholder="${this.$t('yourTextHere')}"/>`)
          }
          setTimeout(() => {
            this.callTwo()
          }, 500)
          return `<span style="color: ${this.$vuetify.theme.dark ? 'rgba(255,255,255,0.5)' : 'rgba(0,0,0,0.5)'}; font-size: 0.9rem;">${templateMessage}</span>`
        }
      }
    },
    processImageTemplate () {
      const data = mediaTemplateValidation(this.parsedTemplateMessage ? this.parsedTemplateMessage : this.template.templateMessage)
      if (!data) {
        return
        // EventBus.$emit('toast', 'error', this.$t('errorTemplateFormat'))
      }
      this.parsedTemplateMessage = data
    },
    getMessagePreview () {
      if (this.defaultTemplate) {
        const text = this.template.templateMessage
        this.messagePreview = text.replace('{{1}}', this.message)
        return
      }
      let text = this.parsedTemplateMessage ? this.parsedTemplateMessage : this.template.templateMessage
      if (this.templateParams && text) {
        this.templateParams.forEach((param, index) => {
          if (param.value && text) {
            text = text.replace(param.name, param.value)
          }
        })
        this.messagePreview = text
      }
    },
    createParamsEventListener () {
      if (this.templateParams) {
        this.templateParams.forEach((param, index) => {
          const input = document.getElementById('Param' + index)
          if (input) {
            if (Object.prototype.hasOwnProperty.call(input, 'style')) {
              input.style.width = '104px'
            }
            input.addEventListener('input', (event) => {
              this.editTemplate((event.target).value || '', input, index)
            })
          }
        })
      }
    },
    handleScroll ({ srcElement }) {
      this.showTemplateLabel = srcElement.scrollTop < 10
    }
  },
  watch: {
    buttonsParamsLinksInputs () {
      if (!this.buttonsParamsLinksInputs) {
        return
      }
      if (this.buttonsParams?.length > 0 && this.buttonsParamsLinksInputs?.length > 0) {
        this.setCurrentLink(this.buttonsParamsLinksInputs)
      }
    },
    template () {
      if (this.template) {
        this.preview = null
        this.messagePreviewCrop = null
        if (this.template.templateMessage.length > 300) {
          this.lengthContent = true
        }
        this.mountComponent()
      }
    },
    insertCustomField: {
      immediate: true,
      deep: true,
      handler () {
        if (this.insertCustomField) {
          this.message = `${this.message ? this.message + ' ' : ''}${this.insertCustomField}`
          this.getMessagePreview()
        }
      }
    },
    templateParams: {
      immediate: true,
      deep: true,
      handler () {
        this.getMessagePreview()
      }
    },
    openEmojiPicker () {
      if (this.openEmojiPicker) {
        this.changeParamsVisible = false
      }
    },
    changeParamsVisible () {
      if (this.changeParamsVisible) {
        this.openEmojiPicker = false
      }
    },
    insertCustomFieldHtml () {
      if (this.insertCustomFieldHtml) {
        if (this.defaultTemplate) {
          if (this.message) {
            this.message = `${this.message} ${this.insertCustomFieldHtml}`
          } else {
            this.message = this.insertCustomFieldHtml
          }
          if (this.messagePreview) {
            this.messagePreview = `${this.messagePreview} ${this.insertCustomFieldHtml}`
          } else {
            this.messagePreview = this.insertCustomFieldHtml
          }
          this.changeParamsVisible = false
          return
        }
        if (this.templateParams) {
          if (!this.paramSelected) {
            this.paramSelected = 0
          }
        }
        document.getElementById(`Param${this.paramSelected}`).value = this.insertCustomFieldHtml
        this.templateParams[this.paramSelected].value = this.insertCustomFieldHtml
        if (this.messagePreviewCrop) {
          this.messagePreviewCrop = this.messagePreviewCrop.replace(`{{${this.paramSelected + 1}}}`, this.insertCustomFieldHtml)
        } else {
          this.messagePreviewCrop = this.messagePreview.replace(`{{${this.paramSelected + 1}}}`, this.insertCustomFieldHtml)
        }
      }
    }
  }
}
